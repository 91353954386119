import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-button',
  template: `
    <div class="mt-3"
    *ngIf="
     (model['paymentType'] === 'Credit Card') && !model['authCode']
     && model['paymentAmount'] > 0
     ">
      <button type="button" [ngClass]="'btn btn-' + to.btnType" (click)="onClick($event)">
        {{ to.text }}
      </button>
    </div>
    <div class="mt-1 "
    *ngIf="model['authCode']">
    <button type="button" class="btn btn-5" (click)="onClickProcessed($event)">
        Processed
    </button>
    </div>
  `,
})
// field.model['paymentType'] === 'Amex' ||
// field.model['paymentType'] === 'Visa' ||
// field.model['paymentType'] === 'Master Card' ||
// field.model['paymentType'] === 'Discover'
export class FormlyFieldButton extends FieldType {
  onClick($event) {
    if (this.to.onClick) {
      this.to.onClick($event, this.model);
    }
  }
  onClickProcessed($event) {
    if (this.to.onClickProcessed) {
      this.to.onClickProcessed($event, this.model);
    }
  }
}