
    <div class="tw-flex tw-justify-between" > 
      
      <div class="d-flex align-items-between mb-2">
      
        <div class="tw-col-span-5 md:tw-col-span-10 lg:tw-col-span-10 " style="margin-left: auto">
          <span class="m-0 title-1" *ngIf="salesOrder?.status === 'Draft'">Parked:   
            <strong class="text-black tw-font-semibold">{{ salesOrder?.DraftNum }}</strong>
          </span>
          <span class="m-0 title-1" *ngIf="salesOrder?.status !== 'Draft'">SO:    
            <strong class="text-black">{{ salesOrder?.SONumber }} ({{salesOrder?.status?.trim() == 'Draft' ? 'Parked' : (salesOrder?.status=='Order'?'Estimate':salesOrder?.status)}})</strong>
          </span>
        </div> 

       
      </div>

      <div class="tw-ml-auto">

        <div *ngIf="showform && !canSelect" class="sale-order-dropdown-container-header-icons-mobile">
          <span *ngIf="!show" class="material-icons mr-1" style="font-size: 1.8rem" (click)="showForm()"> check_circle </span>
        </div>
       
        <div *ngIf="!showform && !canSelect" class="tw-flex tw-justify-between">
          <svg class="sale-order-dropdown-container-header-icons-mobile" id="Component_59_102" data-name="Component 59 – 102" (click)="showForm()" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25.674" height="25.681" viewBox="0 0 25.674 25.681">
            <defs>
              <style>
                .cls-15 {
                  fill: #00adee;
                }
          
             
              </style>
              <clipPath id="clip-path">
                <rect id="Rectangle_156" data-name="Rectangle 156" class="cls-15" width="25.674" height="25.681"/>
              </clipPath>
            </defs>
            <g id="Group_63" data-name="Group 63" class="cls-2">
              <path id="Path_76" data-name="Path 76" class="cls-15" d="M25.674,4.884a5.154,5.154,0,0,1-1.642,2.877C21,10.767,17.983,13.8,14.961,16.819Q12.306,19.475,9.65,22.13c-.313.313-.633.618-.946.931a1.42,1.42,0,0,1-.578.335Q4.861,24.481,1.6,25.572c-.095.032-.186.073-.279.109h-.6a1.094,1.094,0,0,1-.594-1.576q1.09-3.266,2.177-6.534a1.567,1.567,0,0,1,.389-.631Q10.514,9.121,18.337,1.3A4.289,4.289,0,0,1,25.3,2.465a5.738,5.738,0,0,1,.377,1.216ZM7.95,20.871c.089-.02.135-.1.192-.153q6.486-6.483,12.975-12.964c.166-.165.138-.246-.011-.392Q19.716,5.988,18.343,4.6c-.159-.162-.245-.167-.41,0q-2.648,2.667-5.312,5.319-3.827,3.827-7.657,7.651c-.133.133-.16.211-.012.357.946.933,1.881,1.875,2.821,2.813.052.052.1.118.176.136M23.641,4.2c0-.013-.014-.1-.025-.178A2.1,2.1,0,0,0,22.107,2.18,2.2,2.2,0,0,0,19.73,2.8c-.1.1-.1.171,0,.27q1.454,1.447,2.9,2.9c.086.086.155.1.252.009A2.349,2.349,0,0,0,23.641,4.2M5.976,21.876a.9.9,0,0,0-.07-.088q-1-1-2-2c-.1-.1-.147-.078-.188.048-.11.341-.226.681-.339,1.021q-.321.961-.641,1.923c-.02.06-.073.127-.024.189s.124.016.183,0q1.473-.488,2.944-.98c.05-.017.121-.017.135-.108" transform="translate(0 0)"/>
            </g>
          </svg>
        </div>        
      </div>

    </div>




  
  <div style="background: #f5f5f5;" *ngIf="showform">
  
    <form [formGroup]="salesOrderForm">
      <div role="form" class="sale-order-detail-content-form">
       
        <div class="tw-text-base sm:tw-text-lg md:tw-text-lg lg:tw-text-lg tw-font-sans tw-text-gray-500 tw-font-bold">How did you hear about us?</div>
        <div class="sale-order-detail-content-form-input-container">
          <div class="select-container">
            <!-- <input *ngIf="canSelect" type="text" [readonly]="canSelect" class="form-control" formControlName="soSource" > -->
            <div *ngIf="canSelect" class="tw-text-lg">{{salesOrderForm.get('soSource').value}}</div>
            <select
            [ngClass]="{ 'input-header-error': ((salesOrderForm.get('soSource').value == '' || salesOrderForm.get('soSource').value == null) && checkReferral == true ), 'input-header' : ((salesOrderForm.get('soSource').value != '' || salesOrderForm.get('soSource').value != null)) }" 
            *ngIf="!canSelect" formControlName="soSource">
              <option disabled value="null"  selected>Choose Referral</option>
              <option [ngValue]="referral" *ngFor="let referral of referralTypes">
                {{ referral }}
              </option>
            </select>
            <div class="invalid-feedback">Please choose a Referral</div>
          </div>
          <div class="input-container">
            <div *ngIf="canSelect" class="tw-text-lg">{{salesOrderForm.get('referralName').value != '' && salesOrderForm.get('referralName').value != null ? salesOrderForm.get('referralName').value:  'No Referral Name'}}</div>
            <input *ngIf="!canSelect" type="text" class="input-header" formControlName="referralName"
            [ngClass]="{
              'is-invalid':
                salesOrderForm.get('referralName').errors &&
                salesOrderForm.get('referralName').touched &&
                salesOrderForm.get('soSource').value === 'Other'
            }" placeholder="Referral Name if any"/>
          <div class="invalid-feedback">Please add a Referral Name</div>
          </div>
        </div>



       <div style="width:100%; height:2px;background: #8f8f8f;" class="my-3 sm:my-3 md:my-4 lg:my-4"></div>


        <div class="tw-grid tw-grid-cols-2" >
          
          <!-- first comlumn -->
          <div class="">
            <div class="tw-text-base sm:tw-text-lg md:tw-text-lg lg:tw-text-lg tw-font-sans tw-text-gray-500 tw-font-bold">Sales Person:</div>

            <div *ngIf="canSelect" class="tw-text-lg">{{salesOrderForm.get('salesPerson').value}}</div>

            <div *ngIf="!isIOS && !canSelect" class="sale-order-detail-content-form-input-container">
              <ng-template
                #customItemTemplate
                let-model="item"
                let-index="index"
                class=""
              >
                {{ model }}
              </ng-template>
              
              <input
                [readonly]="canSelect"
                formControlName="salesPerson"
                [typeahead]="salesPersons"
                [typeaheadOptionsLimit]="20"
                [typeaheadMinLength]="0"
                placeholder="Sales Person"
                [typeaheadItemTemplate]="customItemTemplate"
                class="form-control sale-input input-header"
                style="text-transform: none;"
                [ngClass]="{
                  'is-invalid': salesOrderForm.get('salesPerson').errors
                }"
              />
              <div class="invalid-feedback">Please choose a Sales Person</div>
            </div>
    
    

            <div class=""  *ngIf="isIOS && !canSelect">
              <div  *ngIf="!showSelectSP" class="tw-flex tw-border tw-border-aedgrayl tw-rounded-md">
                <input 
                  formControlName="salesPerson"
                  [readonly]="canSelect"
                  placeholder="Write the Sales Person name"
                  class="tw-bg-aedgrayback tw-text-aedgraydark tw-text-base tw-rounded-l-md tw-w-full tw-pl-3"
                  [ngClass]="{'is-invalid': salesOrderForm.get('salesPerson').errors}"
                />
                <button 
                *ngIf="!canSelect"
                class="tw-h-6 tw-bg-aedgrayback tw-text-aedgraydark tw-text-base tw-rounded-r-md "
                (click)="selectFromStorage()">
                  <span class="material-icons"> keyboard_arrow_down </span>
                </button>            
              </div>
    
            <select (change)="changeselectSP()" *ngIf="showSelectSP" visibility="hidden" id="idselectsalesperson" class="input-header" [ngClass]="{'is-invalid': salesOrderForm.get('salesPerson').errors}" formControlName="salesPerson">
              <option [ngValue]="salesp" *ngFor="let salesp of salesPersons">
                {{ salesp }}
              </option>
              <option value="Other">Other</option>
            </select>
            </div>
          </div>
          <div class="tw-block sm:tw-hidden  md:tw-hidden  lg:tw-hidden"></div>

          <!-- 2 column -->
          <div  class="tw-ml-0 sm:tw-ml-2 md:tw-ml-2 lg:tw-ml-2 tw-mt-2 sm:tw-mt-0 md:tw-mt-0 lg:tw-mt-0">
            <div class="tw-text-base sm:tw-text-lg md:tw-text-lg lg:tw-text-lg tw-font-sans tw-text-gray-500 tw-font-bold">Delivery status:</div>
            
            <div  *ngIf="!canSelect" btnRadioGroup formControlName="deliveryRequest" role="group"
            class="tw-toggle" >
             
              <div  btnRadio="Pickup"
              class="tw-rounded-l-lg"
              [ngClass]="salesOrderForm.value.deliveryRequest == 'Pickup' ? 'tw-toggle-active' : 'tw-toggle-inactive'">
                Pickup
              </div>         
              <div btnRadio="Delivery" 
              class="tw-rounded-r-lg"
              [ngClass]="salesOrderForm.value.deliveryRequest == 'Delivery' ? 'tw-toggle-active' : 'tw-toggle-inactive'">
                Delivery
              </div>
               
            </div>
            <div *ngIf="canSelect" class="tw-text-lg">{{salesOrderForm.get('deliveryRequest').value}}</div>            
          </div>

          <!-- 3 column -->
          <div class="tw-ml-2 sm:tw-ml-0 md:tw-ml-0 lg:tw-ml-0 tw-mt-2">
            <div class="tw-text-base sm:tw-text-lg md:tw-text-lg lg:tw-text-lg tw-font-sans tw-text-gray-500 tw-font-bold">Taxes:</div>
            <div class="">
              <input
                *ngIf="!canSelect"
                [readonly]="canSelect"
                class="input-header"
                type="number"
                formControlName="taxNumber"
              />
              <div *ngIf="canSelect" class="tw-text-lg">{{salesOrderForm.get('taxNumber').value}}</div>          
            </div>
          </div>   
          
          <!-- 4 column -->
          <div  class="tw-ml-0 tw-col-span-2 sm:tw-col-span-1 sm:tw-ml-2 md:tw-ml-2 lg:tw-ml-2 tw-mt-2" *ngIf="fundraiser.length > 1">
            
            <div class="tw-text-base sm:tw-text-lg md:tw-text-lg lg:tw-text-lg tw-font-sans tw-text-gray-500 tw-font-bold">Teams:</div>
            <div class="input-container" *ngIf="fundraiser.length > 1">
              <div class="tw-flex tw-flex-wrap" *ngIf="selctedFundraiser.length == 0">
                <div class="tw-mx-2 tw-my-2 tw-flex tw-items-center" *ngFor="let fund of fundraiser">      
                  <input class="tw-w-4 tw-h-4 tw-text-center" type="checkbox" value="{{fund.checked}}" style="margin-right: 5px;" (change)="teamsChange($event,fund.team)" [checked]="fund.checked" > <span>{{fund.team}}</span>           
                </div>
              </div> 
              <div class="tw-flex tw-flex-wrap" *ngIf="selctedFundraiser.length > 0">
                <div class="tw-mx-2 tw-my-2 tw-flex tw-items-center" *ngFor="let fund of selctedFundraiser">      
                  <input [disabled]="canSelect" class="tw-w-4 tw-h-4" type="checkbox" value="{{fund.checked}}" style="margin-right: 5px;"  (change)="teamsChange($event,fund.team)" [checked]="fund.checked" > <span>{{fund.team}}</span>           
                </div>
              </div>
          
            </div>             

          </div>

          <div *ngIf="showform && !canSelect" class="tw-col-span-2 tw-flex tw-justify-end sale-order-dropdown-container-header-icons-mobile">
            <span *ngIf="!show" class="material-icons mr-1" style="font-size: 1.8rem" (click)="showForm()"> check_circle </span>
          </div>

        </div>

      </div>



    </form>
  </div>


  <div  *ngIf="!showform">
    <div class="tw-text-base sm:tw-text-base  md:tw-text-lg  lg:tw-text-lg tw-grid tw-grid-cols-3">

        <div class="tw-col-span-2">

          <div>          
          <strong>Sales Person: </strong> 
          {{salesOrderForm.get('salesPerson').value}}
          </div>

          <div>          
            <strong>How did you hear about us? </strong> <br>
            {{salesOrderForm.get('soSource').value}}
          </div> 

          <div *ngIf="selctedFundraiser.length > 0">  
            <strong>Teams: </strong>
              <span *ngFor="let fund of selctedFundraiser"  > <span *ngIf="fund.checked">{{fund.team}} </span></span>      
          </div> 

        </div>


 
        <div  class="tw-col-span-1"> 

          <div>          
            <strong>Delivery status:</strong> 
          {{salesOrderForm.get('deliveryRequest').value}}
          </div> 

          <div> 
            <strong>Taxes:</strong> 
          {{salesOrderForm.get('taxNumber').value}}
          </div> 
          
          <div *ngIf="salesOrderForm.get('referralName').value !=  null">          
            <strong>Referal Name:</strong> 
          {{salesOrderForm.get('referralName').value}}
          </div>        

        </div>

    </div>
 
   

  </div>
  
  