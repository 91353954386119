import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { SettingsService } from 'src/app/_services/settings.service';
import 'rxjs/add/operator/takeUntil';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SendemailComponent } from '../sendemail/sendemail.component';
import { GlobalsService } from 'src/app/_services/globals.service';
import { AuthService } from 'src/app/_services/auth.service';
import { saveAs } from 'file-saver';
import { Alert } from 'selenium-webdriver';
import { take, takeUntil } from 'rxjs/operators';
import { ReportsService } from 'src/app/_services/reports.service';
import { Subject } from 'rxjs';
import { isThisMinute } from 'date-fns';
import { UserService } from 'src/app/_services/user.service';
import { environment } from '../../../environments/environment';
import isOnline from 'is-online';
import { SendemailLocalComponent } from '../sendemail-local/sendemail-local.component';
import { TemplatemessagesService } from 'src/app/_services/templatemessages.service';
import { EventsService } from 'src/app/_services/events.service';

declare let Stimulsoft: any;
declare let Print: any;
declare var iswebKit: any;

@Component({
  selector: 'app-invoicereport',
  templateUrl: './invoicereport.component.html',
  styleUrls: ['./invoicereport.component.scss']
})
export class InvoicereportComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  // viewer: any = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
  // report: any = new Stimulsoft.Report.StiReport();
  viewer:any
  report:any
  downloadUrl = '';
  so: any;
  salesorder: any;
  sponsor:any = '';
  fromcomponent:any = '';
  resultHtml: any;
  contactinfo: any;
  public attachmentStream: any;
  datastream: any;
  settings: any;
  userName = '';
  platform = '';
  showMobile = false;
  isIOS = false;
  isAndroid = false;
  showEmail = false;
  emailwasent = false

  constructor(private http: HttpClient, private salesService: SalesOrderService,
    private alertify: AlertifyService,
    private settingsService: SettingsService,
    private modalService: BsModalService,
    private userService: UserService,
    public bsModalRef: BsModalRef,
    private globals: GlobalsService,
    public authService: AuthService,
    private reportService: ReportsService,
    private templateMeSvc:TemplatemessagesService,
    private eventService: EventsService
    ) { }

  ngOnInit() {


    this.platform = navigator.userAgent;
    if (this.platform.match(/Android/i)) {
      this.showMobile = true;
      this.isAndroid = true;
    }
    if (this.platform.match(/iPhone|iPad|iPod/i)) {
      this.showMobile = true;
      this.isIOS = true;
    }

    if (this.platform.includes('Mac')) {
      this.showMobile = false;
    }

    if (this.platform.includes('Win')) {
      this.showMobile = false;
    }
    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
      this.isIOS = true;
    }


    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
       this.isIOS = true; 
    } 

    //actualizo los json despues de haber mandado el email
    this.reportService.SOIDemailsentSo.subscribe(
      (res:any)=>{          
        if(res != ''){   
            this.emailwasent = true          
            this.reportService.SOIDemailsentSo.next('');
        }
      })

    //actualizo los json despues de haber mandado el email
    this.reportService.SOIDemailsent.subscribe(
      (res:any)=>{          
        if(res != ''){   
            this.emailwasent = true          
            this.reportService.SOIDemailsent.next('');
        }
      })

    //conpruebo si el email fue enviado
    if(this.so['notifications'] != null && this.so['notifications'] != undefined && this.so['notifications'] != ""){
      if(JSON.parse(this.so['notifications']).emailsent != undefined){ 
        if(JSON.parse(this.so['notifications']).emailsent.length > 0){
          this.emailwasent = true                
        }
      }
    }

    this.loadreport()
  }


 async loadreport(){
  let wait = await this.globals.loadScript();
  if(wait){
    this.viewer =new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
    this.report = new Stimulsoft.Report.StiReport();

    this.settingsService.getSettings(0)
    //.takeUntil(componentDestroyed(this))
    .pipe(take(1))
    .subscribe( data => {
      this.settings = data[0];
        this.contactinfo = (this.settings['contactInfo']);
        this.contactinfo = this.contactinfo != null ? (this.contactinfo.toString().replace(/\r/g, '<br>' )) : "";
        this.contactinfo =  this.contactinfo != null ?(this.contactinfo.toString().replace(/\n/g, '<br>' )) : "";
        
    // Stimulsoft.Base.StiLicense.key = this.globals.stimulSoft;

 


    this.salesService.getInvoice(this.so['SOID'])
   //.takeUntil(componentDestroyed(this)) 
   .pipe(take(1))
    .subscribe((datasale: Response) => {
    this.http.get('reports/Invoice.mrt')
    //.takeUntil(componentDestroyed(this))
    .pipe(take(1))
    .subscribe((data: Response) => {

      this.report.loadFile('reports/Invoice.mrt');
      setTimeout(
       () => {


        if (datasale['SalesOrder']['disc1exp']) {
        datasale['SalesOrder']['disc1exp'] = atob(datasale['SalesOrder']['disc1exp']);
        }
        if (datasale['SalesOrder']['disc2exp']) {
        datasale['SalesOrder']['disc2exp'] = atob(datasale['SalesOrder']['disc2exp']);
        }
        if (datasale['SalesOrder']['disc3exp']) {
        datasale['SalesOrder']['disc3exp'] = atob(datasale['SalesOrder']['disc3exp']);
        }
        if (datasale['SalesOrder']['disc4exp']) {
        datasale['SalesOrder']['disc4exp'] = atob(datasale['SalesOrder']['disc4exp']);
        }
        if (datasale['SalesOrder']['orderSigexp']) {
        datasale['SalesOrder']['orderSigexp'] = atob(datasale['SalesOrder']['orderSigexp']);
        }
        datasale['SalesOrder']['contactInfo'] = this.contactinfo;


        datasale['delivery'].forEach(element => {
          if (this.so['SOID'] !== element['foreignSOID']) {
          //  console.log(this.so['SOID']);
          //  console.log(element['foreignSOID']);
          }
        });
        datasale['delivery'] = datasale['delivery'].filter(x => x['foreignSOID'] === this.so['SOID']);
        datasale['Payments'] = datasale['Payments'].filter(x => x['foreignSOID'] === this.so['SOID']);

        // datasale['SOItems'].forEach(item => {
        //   if(item['itemDelivery'] == 'Delivery NTO' || item['itemDelivery'] == 'Delivery In Stock'){
        //     console.log(item)
        //     datasale['SOItems'].forEach(item2 => {
        //       if(item2['itemDelivery'] == 'Delivery NTO' || item2['itemDelivery'] == 'Delivery In Stock'){
        //       if(item['itemDelivery'] == item2['itemDelivery']){
        //         if( item['deliveryAttn']==item2['deliveryAttn'] && 
        //             item['deliveryPhone']==item2['deliveryPhone'] && 
        //             item['deliveryAddress1']==item2['deliveryAddress1'] && 
        //             item['deliveryAddress2']==item2['deliveryAddress2'] && 
        //             item['deliveryCity']==item2['deliveryCity'] && 
        //             item['deliveryState']==item2['deliveryState'] && 
        //             item['deliveryZip']==item2['deliveryZip'] && 
        //             item['deliveryEmail']==item2['deliveryEmail']
        //             ){
        //               item['sameShipping'] = item['SOItemsID'];
        //               item2['sameShipping'] = item['SOItemsID'];
        //         }
        //       }
        //     }
        //     });
        //   }
        // });
        
          const dssalesorder = new Stimulsoft.System.Data.DataSet();
        this.report.dictionary.databases.clear();

        // console.log(JSON.stringify(datasale))
        dssalesorder.readJson(datasale);
        // console.log(dssalesorder)
        this.report.regData('salesorder', null, dssalesorder);
        this.viewer.report = this.report;
        this.viewer.width = '100%';
        this.viewer.options.toolbar.showAboutButton = false;

        this.viewer.options.toolbar.showEmailButton = true;
        this.viewer.options.exports.showExportDialog = false;
        this.viewer.options.toolbar.printDestination = Stimulsoft.Viewer.StiPrintDestination.Direct;
        if (this.showMobile === true || this.isIOS) {
          this.viewer.options.toolbar.show = true;
          this.viewer.options.toolbar.showOpenButton = false;
          this.viewer.options.toolbar.showPrintButton = false;
          this.viewer.options.toolbar.showSaveButton = true;
          this.viewer.options.toolbar.showFindButton = false;
          this.viewer.options.toolbar.showBookmarksButton = false;
          this.viewer.options.toolbar.showParametersButton = false;
          this.viewer.options.toolbar.showResourcesButton = false;
          this.viewer.options.toolbar.showEditorButton = false;
          this.viewer.options.toolbar.showFullScreenButton = true;
          this.viewer.options.toolbar.showRefreshButton = false;
          this.viewer.options.toolbar.showZoomButton = true;
          this.viewer.options.toolbar.showViewModeButton = false;
          this.viewer.options.toolbar.showDesignButton = false;
          this.viewer.options.toolbar.showAboutButton = false;
          this.viewer.options.toolbar.showExportToPDF = false;
          this.viewer.options.toolbar.showPinToolbarButton = false;
          this.viewer.options.toolbar.showExportToExcel  = false;
          this.viewer.options.toolbar.showExportToRtf = false;
          this.viewer.options.toolbar.showExportToExcel2007 = false;
          this.viewer.options.toolbar.showExportToExcelXML = false;
          this.viewer.options.toolbar.zoom = Stimulsoft.Viewer.StiZoomMode.PageWidth;  

          this.viewer.options.appearance.scrollbarsMode = false;
          this.viewer.options.appearance.fullScreenMode = false;
          this.viewer.options.appearance.interfaceType = Stimulsoft.Viewer.StiInterfaceType.Touch;
      }






        this.viewer.options.appearance.scrollbarsMode = true;
        this.viewer.options.appearance.showTooltipsHelp = false;
        this.viewer.options.appearance.showFormsHelp = false;
        this.viewer.options.appearance.showFormsHints = false;
        this.viewer.options.exports.showExportToHtml = false;
        this.viewer.options.exports.showExportToHtml5 = false;
        this.viewer.options.exports.showExportToDataFile = false;
        this.viewer.options.exports.showExportToDocument = false;
        // this.report.renderAsync();
        this.viewer.renderHtml('viewerContent');
        const settingsrep = new Stimulsoft.Report.Export.StiPdfExportSettings();
        const service = new Stimulsoft.Report.Export.StiPdfExportService();
        const stream = new Stimulsoft.System.IO.MemoryStream();
        service.exportTo(this.report, stream, settingsrep);
        this.datastream = stream.toArray();
        this.attachmentStream = Stimulsoft.System.Convert.toBase64String(this.datastream);   
        

        //console.log('Size '+ this.reportService.roughSizeOfObject(this.attachmentStream));
        if (this.reportService.roughSizeOfObject(this.attachmentStream) > 0) {
        this.showEmail = true;
        datasale = null;
        }
          } , 1
        );

     });
    });
  }, error => {
    this.alertify.error(error);
    });
}
  }

 async showSendEmail() {

  let wait = await this.globals.loadScript(); 
    if(wait){ 
      
    let online = await isOnline();

    this.reportService.dataStream.next('');
    this.reportService.getInvoice(this.so['SOID'])
    .pipe(take(1)).
    subscribe(
      (res) => {
        if (res !== '') {
          const model = {};
          let stringTeams:any = 'All Teams  ';//2 space for the slice
                    
          if(this.so['fundraiser'] != '' && this.so['fundraiser'] != null){
            stringTeams = '';
            JSON.parse(this.so['fundraiser']).forEach(element => {            
              stringTeams = stringTeams+element.team+', ';             
            });
          }

          this.templateMeSvc.getTemplateMesSection('Email For Sales Order')
          .toPromise()
          .then((result) => {          
            
            var Obj = {
              "<<OrderNumber>>": this.so['SONumber'],
              "<<FirstName>>": this.so['firstName'],
              "<<LastName>>": this.so['lastName'],
              "<<Sponsor>>": this.sponsor,
              "<<Teams>>": stringTeams.slice(0,-2),
              "<<Dealer>>": this.authService.decodeToken('dealername').dealername,

            };
            let subject:any = '';
            let msg:any = '';

            if (result[0].messages != '' && result[0].messages != null && result[0].messages != undefined) {
              if(JSON.parse(result[0].messages).length > 0){              
                JSON.parse(result[0].messages).forEach(element => {
                  if(element.title.toLowerCase() == 'subject'){
                    subject = element.content.replace(/<<OrderNumber>>|<<FirstName>>|<<LastName>>|<<Sponsor>>|<<Teams>>|<<Dealer>>/gi, function(matched){
                      return Obj[matched];
                    });
                  }else if(element.title.toLowerCase() == 'message'){
                    msg = element.content.replace(/<<OrderNumber>>|<<FirstName>>|<<LastName>>|<<Sponsor>>|<<Teams>>|<<Dealer>>/gi, function(matched){
                      return Obj[matched];
                    });
                  } 
                });
              }
            }

            if (this.reportService.roughSizeOfObject(res) > 0) {

              if(subject == ''){
                subject = 'Receipt for Order#' + this.so['SONumber'];
              }
              if(msg == ''){
                msg = 'Please find attached Order#' + this.so['SONumber'];
              }   

                //new array notifications
                // console.log(this.so);
                
                let notifications:any = {};
                if(this.so['notifications'] != null && this.so['notifications'] != undefined && this.so['notifications'] != ""){
                  notifications = JSON.parse(this.so['notifications'])
  
                  if(JSON.parse(this.so['notifications']).emailsent != undefined){
                    if(notifications.emailsent.length == 0){
                      notifications.emailsent = [{"emailSentAt":""+(this.globals.dNow())+""}];
                      this.so['notifications'] = JSON.stringify(notifications)
                    }else{
                      notifications.emailsent.push({"emailSentAt":""+(this.globals.dNow())+""});   
                      this.so['notifications'] = JSON.stringify(notifications)
                    }
                  }else{                  
                    notifications.emailsent = [{"emailSentAt":""+(this.globals.dNow())+""}];
                    this.so['notifications'] = JSON.stringify(notifications)
                  }     
                }else{
                  notifications.emailsent =  [{"emailSentAt":""+(this.globals.dNow())+""}];
                  this.so['notifications'] = JSON.stringify(notifications)
                }
              

              model['email'] = this.so['email'];
              model['subject'] = subject;
              model['message'] = msg;
              model['SOID'] = this.so['SOID'];
              model['notifications'] = JSON.stringify(notifications);
              model['fromcomponent'] = this.fromcomponent;
              model['attachmentbase64'] =  res;
              model['attachmentfiletype'] =  'application/pdf';
              model['attachmentfilename'] = 'Order ' + this.so['SONumber'] + '.pdf';
    
              const initialState = {
                  model: model
                };
                  if(online){
                    this.bsModalRef = this.modalService.show(SendemailComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true}); 
                  }else{
                    if(environment.apiUrl == 'http://aedpay.api/api/'){ 
                      model['typeEmail'] = "Order";
                      model['eventId'] = this.so['eventId'];
                      model['SOID'] = this.so['SOID'];                  
                      const initialState = {
                        model: model
                      };       
                      this.bsModalRef = this.modalService.show(SendemailLocalComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true, backdrop : false});
                      }
                  }
               
              }

          }).catch((err) => {
            // console.log(err);
          });          

      }
    }, (error) => {
      this.alertify.error('There was an error');
    }    
    );  
    
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Send By Email from Preview',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});

    // const model = {};
    // model['email'] = this.so['email'];
    // model['subject'] = 'Receipt for Order#' + this.so['SONumber'];
    // model['message'] = 'Please find attached Order#' + this.so['SONumber'] ;
    // model['attachmentbase64'] =  this.attachmentStream;
    // model['attachmentfiletype'] =  'application/pdf';
    // model['attachmentfilename'] = 'Order ' + this.so['SONumber'] + '.pdf';
    // const initialState = {
    //     model: model
    // };
    // this.bsModalRef = this.modalService.show(SendemailComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true});
  }
}

 async exportReportHtml() {
    let wait = await this.globals.loadScript(); 
    if(wait){ 
      this.report = new Stimulsoft.Report.StiReport();    
     // Create an HTML settings instance. You can change export settings.
    const settings = new Stimulsoft.Report.Export.StiHtmlExportSettings();
    // Create an HTML service instance.
    const service = new Stimulsoft.Report.Export.StiHtmlExportService();

    // Create a text writer objects.
    const textWriter = new Stimulsoft.System.IO.TextWriter();
    const htmlTextWriter = new Stimulsoft.Report.Export.StiHtmlTextWriter(textWriter);
    // Export HTML using text writer.
    service.exportTo(this.report, htmlTextWriter, settings);

    // Write HTML text to DIV element.
    const container = document.getElementById('htmlContainer');
    container.innerHTML = textWriter.getStringBuilder().toString();
    }
}

 async showPrint() {

  let wait = await this.globals.loadScript(); 
    if(wait){ 

    this.reportService.getInvoice(this.so["SOID"])
    .subscribe(
      (res)=>{

        if(this.isIOS){
          if(iswebKit == "isWebKit"){
            // if new aplication
            (document.getElementById('base64pdf') as HTMLInputElement).value = res;
            (document.getElementById('sendenbase64') as HTMLInputElement).click(); 
            setTimeout(() => {           
            (document.getElementById('base64pdf') as HTMLInputElement).value = "";
            }, 1000);
          }else{
          // if old aplication
          window.location.href = "APPReport://data:application/pdf;base64," + res;
          }
        }

        if(this.isAndroid){
          const byteCharacters = atob(res);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          } 
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {type: 'application/pdf'});
          var fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl);
      }
    });
  } 

  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    document.body.removeAttribute("style");
  }

}
