import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { Router } from '@angular/router';
import { GlobalsService } from 'src/app/_services/globals.service';


@Component({
  selector: 'app-salesorder-searchtype',
  templateUrl: './salesorder-searchtype.component.html',
  styleUrls: ['./salesorder-searchtype.component.scss']
})
export class SalesorderSearchtypeComponent implements OnInit {
salesorders: any = [];
  @Input() passSalesOrders: any = [];
  @Input() passType: any;
  @Input() passSize: any;
  SalesPrice = 0;
  wholeSalePrice = 0;
  QtyTotal = 0;
  constructor(private bsModalRef: BsModalRef,
    private salesService: SalesOrderService,
    private globals: GlobalsService,
    private router: Router) { }

  ngOnInit() {
    this.salesorders = this.passSalesOrders;
    
    this.salesorders.forEach(element => {
      this.SalesPrice = this.SalesPrice + this.globals.pNumber(element['SalesPrice']);
      this.wholeSalePrice = this.wholeSalePrice + this.globals.pNumber(element['WholeSale']);
      this.QtyTotal = this.QtyTotal + this.globals.pNumber(element['Qty']);
    });
    // this.SalesPrice = this.salesorders.Sum(x => x.SalesPrice);
    // this.wholeSalePrice = this.salesorders.Sum(x => x.WholeSale);
    
  }

  close() {
    this.bsModalRef.hide();
  }

}
