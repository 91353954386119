import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-wait-st',
  templateUrl: './wait-st.component.html',
  styleUrls: ['./wait-st.component.scss']
})
export class WaitSTComponent implements OnInit {

  point:string = '.';

  constructor() { }

  ngOnInit(): void {
  this.loadpoints();
  }

loadpoints(){
  if(this.point.length == 1){
    this.point = '..'
  }else if(this.point.length == 2){
    this.point = '...'
  }else if(this.point.length == 3){
    this.point = '.'
  }
  setTimeout(() => {
    this.loadpoints();  
  }, 1000);
}
    


}
