
<div class="bg-white p-0">
  <form #orderForm="ngForm">
    <select
      [(ngModel)]="selectedManMod"
      name="manufacturer"
      required
      class="custom-select mb-2"
      id="companyselect"
      
    >
      <option disabled value="" selected>Choose Manufacturer</option>
      <option [ngValue]="man?.SID" *ngFor="let man of manufacturers">{{
        man?.companyName
      }}</option> </select
    >
    <select
      [(ngModel)]="selectedStyleMod"
      name="style"
      class="custom-select mb-3"
      id="styleselect"
      
    >
      <option disabled value="" selected>Choose Style</option>
      <option [ngValue]="sty?.styleId" *ngFor="let sty of styles">{{
        sty?.styleName
      }}</option> 
    </select>
    <div class="d-flex">
      <button
      type="button"
      [disabled]="!orderForm.valid"
      class="btn btn-3 mr-2"
      (click)="loadStyleItems();"
      >
       Search
      </button>
      
      <button
      type="button"
      [disabled]="!orderForm.valid && styleItems.length > 0"
      class="btn btn-3 mr-2"
      (click)="addStoreItems()"
      >
       Add to Store
      </button>
      <button
        class="btn btn-3"
        [disabled]="!orderForm.valid"
        (click)="clearFilter()"
      >
        Show All
      </button>
    </div>
  </form>
</div>

<div class="customer-content mt-4" >
  <div class="row">
    <div class="col-12 col-sm-6 col-md-6" *ngFor="let si of styleItems">
      <div class="card-customer-mobile-record border">
        <div class="card-customer-mobile-record-base-info">
          <div class="row">
            <!-- Type -->
            <div class="col-6">
              <p class="m-0"><strong>{{ si.Type }}</strong></p>
            </div>
            <!-- Size -->
            <div class="col-6">
              <p class="m-0 large-text-card">{{ si.Size }}</p>
            </div>
            <!-- product -->
            <div class="col-6">
              <p class="m-0">{{ si.product }}</p>
            </div>
            <!-- Quantity Per Set -->
            <div class="col-6">
              <p class="m-0" *ngIf="si.minQty > 1">Qty per set: {{ si.minQty }}</p>
            </div>
            <!-- SKU -->
            <div class="col-6">
              <p class="m-0">SKU: {{ si.VITEMID }}</p>
            </div>
            <!-- Whole Sale -->
            <div class="col-6"  *ngIf="userRole!=='Manager'">
              <p class="m-0">Whole Sale: {{ si.Price | number }}</p>
            </div>
            
          </div>
        </div>
      </div>
    </div>
    
  </div>
</div>


<div class="pagination-container">
  <pagination
    [boundaryLinks]="true"
    [totalItems]="pagination.totalItems"
    [(ngModel)]="pagination.currentPage"
    [itemsPerPage]="pagination.itemsPerPage"
    (pageChanged)="pageChanged($event)"
    [maxSize]="4"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"
  >
  </pagination>
</div>