import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DefaultService } from 'src/app/_services/default.service';


@Component({
  selector: 'app-history-cr',
  templateUrl: './history-cr.component.html',
  styleUrls: ['./history-cr.component.scss']
})
export class HistoryCRComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  closesCR:any = [];
  showForm:boolean = false
  p:any = 1;
  totalsCRSelected: any;
  @Input() users: any = [];
  @Input() devices:any = [];

  constructor( private defaultSvc:DefaultService
  ) { }

  ngOnInit(): void {

    this.getClosesCR();
  }

  getClosesCR(){
    this.defaultSvc.getClosesCR({type:'cashRegister',from:'defaults',devices:(this.devices.length > 0?0:1), users:(this.users.length > 0?0:1)})
    .pipe(takeUntil(this.destroy$))   
    .subscribe((res:any) => {
      if(this.devices.length == 0){
        this.devices = res.devices;
      }
      if (this.users.length == 0) {
        this.users = res.users;
      }
      this.closesCR = res.closesCR;
    },(err)=>{
      console.log(err);      
    });  
  }

  getName(type:any, uuid:any){
    if(type == 'user'){
      let user = this.users.find((x:any)=>x.uuid == uuid);
      return user.name
    }else{
      let device = this.devices.find((x:any)=>x.uuid == uuid);
      return device.deviceName
    }
  }

  selectOpenCR(e){
    console.log(e);    
    let json = JSON.parse(JSON.stringify(e))
    json.payments =  JSON.parse(json.payments)
    this.totalsCRSelected = json
    this.showForm = true
  }


}
