import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LocationService } from './location.service';
import { AlertifyService } from './alertify.service';
import { SettingsService } from './settings.service';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {
  lng: number;
  lat: number;

  constructor(
    private settingsService: SettingsService,
    private translate: TranslateService,
    private locationService: LocationService,
    private alertify: AlertifyService,
  ) { }

  getSettingsLanguage(): Observable<any> {
    return this.settingsService.getSettingsLanguage()
  }

  getUserLanguage(userId: string): Observable<any> {
    return this.settingsService.getUserLanguage(userId)
  }

  updateUserLanguage(language: string, userId: string): Observable<any> {
    return this.settingsService.updateUserLanguage(language, userId)
  }

  setLanguage(language?: string, userId?: string): void {
    if (language && !userId) {
      this.saveLanguage(language);
    }
    if (language && userId) {
      this.updateUserLanguage(language, userId).subscribe((res: any) => {
        this.alertify.success(this.translate.instant('LANGUAGE_UPDATED'));
      });
      this.saveLanguage(language);
    } if (!language && userId !== undefined && userId !== null && userId !== '') { 
        this.getUserLanguage(userId).subscribe((res: any) => {
          const language = res.language;
          // si la respuesta es null, es porque el usuario no tiene idioma asignado, 
          // entonces se obtiene el idioma de settings
          if(language){
            this.saveLanguage(language);
          } else {
            this.languageFromSettings();
          }
        });
    } if (!language && !userId) {      
      this.languageFromSettings();
    }
  }

  languageFromSettings(){
    this.getSettingsLanguage().subscribe((res: any) => {
      const language = res.language;
      //si en settings no hay idioma, se obtiene el idioma de la ubicación del usuario
      if(!language) {
        this.locationService.getLocation()
        .then((response) => {
          this.lng = response['lng'];
          this.lat = response['lat'];
          this.locationService.getCountry(this.lng, this.lat).subscribe((countryCode: string) => {
            // Map the country code to a language code
            const languageMap = {
              'US': 'en',
              'GB': 'en',
              'AU': 'en',
              'NZD': 'en',
              'CA': 'en',
              'IE': 'en',
              'AL': 'en',
              'HN': 'es',
              'MX': 'es',
              'AR': 'es',
              'CO': 'es',
              'ES': 'es',
              'PE': 'es',
              'CL': 'es',
              'VE': 'es',
              'EC': 'es',
              'GT': 'es',
              'CU': 'es',
              'DO': 'es',
              'BO': 'es',
              'PY': 'es',
              'SV': 'es',
              'NI': 'es',
              'CR': 'es',
              'UY': 'es',
              'PA': 'es',
              'PR': 'es',
            };
            
            const language = languageMap[countryCode.trim()] || 'en'; // Default to 'en' if the country code is not in the map

           this.saveLanguage(language);
          })
        })
        .catch((error) => { 
          console.log(error);
        });
      } else {
        this.saveLanguage(language);
      }
    });
  }

  saveLanguage(language:string): void {
    this.translate.setDefaultLang(language);
    this.translate.use(language);
    localStorage.setItem('language', language);
  }
}