import { Component, OnInit, Input, NgZone, OnDestroy } from '@angular/core';
import { TransactionpaymentsService } from 'src/app/_services/transactionpayments.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { CardReaderService } from 'src/app/_services/cardreader.service';
import { DevicesService } from 'src/app/_services/devices.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cardprocessed',
  templateUrl: './cardprocessed.component.html',
  styleUrls: ['./cardprocessed.component.scss']
})
export class CardprocessedComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  cardno = '';
  lastfour = '';
  expmonth = '';
  expyear = '';
  cardname = '';
  cardexpdate = '';
  cardType = 0;
  @Input() amount = 1;
  respose: any;
  payment = {};
  @Input() sopayment = {};
  mac = '';
  deviceid = '';
  devicemac = '';
  macresponse:any ;
  authcode: any;
  paymentstatus: any;
  readerStatus: any;
  responseerror: any;
  validateMessage = '';
  cardDate: any;
  todayDate: any;
  removeCard = false;

  constructor(    private tpayments: TransactionpaymentsService,
    private globals: GlobalsService,
    private cardReaderService: CardReaderService,
    private devicesService: DevicesService,
    private _ngZone: NgZone,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private alertify: AlertifyService) { }

  ngOnInit() {

    this.tpayments.getTransactionPayment(this.sopayment['transactionPayment'])
    .pipe(takeUntil(this.destroy$)) 
    .subscribe(
      (res: any) => {
        this.respose = JSON.parse(res[0].response).transaction;
        
        this.cardname = res[0]['AccountName_tx'];
        this.lastfour = this.respose['last_four'];
        this.cardType = res[0]['cardType'];
        this.amount = res[0]['PaymentAmount_num'];
        this.expyear = res[0]['AccountExpDateMonth_tx'].toString().substr(2, 2);
        this.expmonth = res[0]['AccountExpDateMonth_tx'].toString().substr(0, 2);
          this.authcode = this.respose['auth_code'];
      }, error => {
        this.alertify.error(error);
      });

  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();    
  }

}
