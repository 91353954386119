

<div class="">
    <!-- <div class="modal-body-header">
      <h4>Sales Order Address</h4>
    </div> -->
    <!-- <div *ngIf="form?.dirty" class="alert alert-warning">
      <strong>Information: </strong> You made changes. Any unsaved changes will
      be lost!
    </div> -->

    <form [formGroup]="form" class="form-container"> 
  
       
        <div class="form-half">
          <h1 class="tw-text-base sm:tw-text-base  md:tw-text-lg  lg:tw-text-lg tw-font-sans tw-text-gray-500 tw-font-bold">Billing Address</h1>          
          <form [formGroup]="form">
            <formly-form [form]="form" [fields]="fields" [model]="so"  [options]="optionsBilling">
            </formly-form>
          </form>  
        </div>

        <div class="form-half">
          <div class="tw-mb-2 tw-flex">
            <div class="tw-flex tw-text-base sm:tw-text-base  md:tw-text-lg  lg:tw-text-lg tw-font-sans tw-text-gray-500 tw-font-bold">
              <span class="tw-my-auto">Shipping Address</span> 
            </div>
            <button class="btn-blue-so tw-ml-auto" type="button" (click)="copyAddressToShipping()">Same as Billing</button>
          </div>
         
          <form [formGroup]="formShipping">
            <formly-form [form]="formShipping" [fields]="fieldsShipping" [model]="so" [options]="optionsShipping">             
            </formly-form>
          </form>  
        </div>

        <div class="md:tw-flex tw-grid tw-grid-cols-2  tw-gap-2">
          <button class="tw-bg-aedblue tw-text-white tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-flex tw-items-center tw-justify-center tw-w-28" 
            type="button" (click)="updateSO(so)">
          <i class="material-icons mr-2">check</i>
            Save
          </button>
          <button class="tw-bg-aedblue tw-text-white tw-mx-4  tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-flex tw-items-center tw-justify-center tw-w-28" (click)="cancelForm()"  type="button" >
            <i class="material-icons mr-2">close</i>
            Cancel
          </button>
          <button *ngIf="storeSettings?.avaTax == 1 || storeSettings?.avaTax == true" class="tw-bg-aedblue tw-text-white tw-w-56 tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-flex tw-items-center tw-justify-center" (click)="validateAddress()" type="button">
            <span class="material-icons"> check </span> 
            Validate Address
          </button>
        </div>
    </form>
  </div>

  <ng-template #popTemplateiOs >
    <div class="tw-flex tw-flex-col tw-w-full tw-justify-center tw-pt-3 tw-px-3">
      <div class="tw-flex tw-justify-between">
        <p class="tw-text-xl tw-text-aedorangel tw-font-semibold tw-text-center">Avalara Suggested Address(es)</p>
        <img src="assets/avalara.png"  style="height: 30px;">
      </div>
      <p class="tw-text-base tw-text-aedgrays tw-font-base tw-text-center">Select an address below</p>
    </div>
    <div class="tw-p-4 md:tw-grid  tw-flex-col md:tw-grid-cols-3 tw-gap-3" >
      <!-- Customer shipping -->
      <div class="tw-border-2 tw-text-base tw-flex tw-flex-col hover:tw-shadow-lg tw-cursor-pointer tw-border-gray-400 tw-rounded-lg tw-p-2" (click)="setAddressFromAvalara(null)">
        <span class="tw-font-bold tw-break-words" >Current Shipping Address</span>
        <span >{{so?.deliveryAddress1}} </span>
        <span *ngIf="customer?.deliveryAddress2 != ''" >{{so?.deliveryAddress2}} </span>
        <div class="tw-flex tw-space-x-1">
          <span >{{so?.deliveryState}}</span> 
          <span>{{so?.deliveryCity}}</span>  
          <span>{{so?.deliveryZip}}</span>
        </div>
      </div>
      <!-- From Avalara -->
      <div class="tw-border-2 tw-text-base tw-flex tw-flex-col hover:tw-shadow-lg tw-cursor-pointer tw-border-gray-400 tw-rounded-lg tw-p-2" *ngFor="let address of validatedAddress" (click)="setAddressFromAvalara(address)">
        <span class="tw-font-bold tw-break-words" >{{address?.addressType}} </span>
        <span [ngClass]="{'tw-text-green-500': address.line1 != so?.deliveryAddress1}" >{{address?.line1}} </span>
        <span [ngClass]="{'tw-text-green-500': address.line2 != so?.deliveryAddress2}" *ngIf="address?.line2 != ''" >{{address?.line2}} </span>
        <div class="tw-flex tw-space-x-1">
          <span [ngClass]="{'tw-text-green-500': address.region != so?.deliveryState}" >{{address?.region}}</span> 
          <span [ngClass]="{'tw-text-green-500': address.city != so?.deliveryCity}">{{address?.city}} </span> 
          <span [ngClass]="{'tw-text-green-500': address?.postalCode?.substring(0,5) != so?.deliveryZip}">{{address?.postalCode?.substring(0,5)}}</span>
        </div>
      </div>
    </div>
  </ng-template>