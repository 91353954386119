import { Pipe, PipeTransform, Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
 //import * as moment from 'moment';
//import moment from 'moment';


@Pipe({
  name: 'safeHtml'
})
export class SafeHtmlPipe implements PipeTransform {

  constructor(private sanitizer: DomSanitizer) {}

  transform(html) {
   
     return this.sanitizer.bypassSecurityTrustHtml(html);
   }
}

@Pipe({name: 'dateTime'})
@Injectable()
export class DateTimeFormatPipe implements PipeTransform {
transform(date: any, format: string): any {

  const monthNames = ["January", "February", "March", "April", "May", "June",
  "July", "August", "September", "October", "November", "December"
];


        if (date) {

          date = new Date(date);
          if (format === 'dd') {
          date = date.getDate();
          }
          if (format === 'MM') {
            date = date.getMonth() + 1;
          }

          if (format === 'MM') {
            date = date.getMonth() + 1;
          }

          if (format === 'MMM') {
            date = monthNames[date.getMonth()].substr(0,3);
          }

          if (format === 'yyyy') {
            date = date.getFullYear();
          }

          if (format === undefined || format ===' full') {
            date = date.toDateString();
          }

         return date.toString();
        }
    }
}
