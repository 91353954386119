import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject } from 'rxjs/Subject';
import { takeUntil } from 'rxjs/operators/takeUntil';
import { startWith } from 'rxjs/operators/startWith';
import { filter } from 'rxjs/operators/filter';
import { debounceTime } from 'rxjs/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/operators/distinctUntilChanged';
import { switchMap } from 'rxjs/operators/switchMap';
import { EventsService } from 'src/app/_services/events.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';


@Component({
  selector: 'formly-field-typeahead',
  template: `
    <!-- <ng-select [items]="options$ | async"
      [placeholder]="to.placeholder"
      [typeahead]="search$"
      [typeahead]="search$" 
    
      [formControl]="formControl">
    </ng-select> -->
    <ng-select 
    [items]="events" [placeholder]="to.placeholder"
    bindLabel="sponsor"
      dropdownPosition="auto"
      bindValue="CID"
      placeholder="Select an Event"
      [virtualScroll]="true"
    formControlName="eventId">
    <ng-template ng-label-tmp ng-option-tmp let-item="item" let-search="searchTerm">
   <div>
    {{item.sponsor}} {{item.program1}} {{item.ShowDate| date}}
   </div>
</ng-template>
    </ng-select>
  `,
})
export class FormlyFieldTypeahead extends FieldType implements OnInit,OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  onDestroy$ = new Subject<void>();
  search$ = new EventEmitter();
  options$;
  events: any[];
  pagination: any[];
  storeId: string;

  constructor(private eventService: EventsService,
    private authService: AuthService,
    private alertify: AlertifyService) {
    super();
  }
  ngOnInit() {
    this.storeId = JSON.parse(this.authService.getStoreId());
     this.getEvents();
    //this.options$.subscribe();
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.onDestroy$.complete();
  }

  getEvents() {
    this.eventService.getEvents(1, 200, this.search$)
    .pipe(takeUntil(this.destroy$))   
    .subscribe(
      (res: any) => {
        this.events = res.result;
        this.pagination = res.pagination;
        const addevent : any = {};
        addevent['CID'] = this.storeId['storeId'];
        addevent['dbDatets'] = new Date();
        addevent['Status'] = 'Confirmed';
        addevent['sponsor'] = 'Non Event';
        addevent['program1'] = '';
        addevent['program2'] = '';
        addevent['defaultTaxes'] = 0;
        addevent['eventCity '] = '';
        addevent['eventState'] = '';
        addevent['eventCounty'] = '';
    }, error => {
      this.alertify.error(error);
    });
  
}


}