<div [ngClass]="salesOrderMode === true ? 'modal-body modal-body-white' : ''">
  
  <div class="nav-display-options-mobile" [ngClass]="{ 'no-fixed': salesOrderMode }" >
    <div class="w-100 d-flex justify-content-between align-items-center">
      <h6 class="ml-2" >Store Catalog: {{ totalitems }}</h6>
      <div style="display: flex;">
        <div class="btn btn-3 mr-2 mb-2" *ngIf="showSave" (click)="onSalesPriceChange('', '', true)">Save</div>
        <div class="btn btn-3 mr-2 mb-2" (click)="openFilterMenu()">Filter</div>
      </div>
    </div>

    <div class="selecter-filter-container" [ngClass]="{ 'active': searched }"> 
      <div class="selecter-filter-container-title store-filter-container row pr-0 pl-0" [ngClass]="{'fullheight': filters > 2 }">
        <div class="col-12 col-md-6" *ngIf="selectedManMod && searched">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Manufacturer:</h6>
              <span class="selecter-filter-container-title-select-event">{{manuFilter}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="selectedStyleMod && searched">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Style:</h6>
              <span class="selecter-filter-container-title-select-event">{{styleFilter}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="selectedSizeMod && searched">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Size:</h6>
              <span class="selecter-filter-container-title-select-event">{{selectedSizeMod}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="selectedProductMod && searched">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Product:</h6>
              <span class="selecter-filter-container-title-select-event">{{selectedProductMod}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="selecter-filter-container" [ngClass]="{ 'active': searchedInput }"> 
      <div class="selecter-filter-container-title store-filter-container row pr-0 pl-0" [ngClass]="{'fullheight': filters > 2 }">
        <div class="col-12 col-md-6" *ngIf="filterManu">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Manufacturer:</h6>
              <span class="selecter-filter-container-title-select-event">{{filterManu}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="filterStyle">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Style:</h6>
              <span class="selecter-filter-container-title-select-event">{{filterStyle}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="filterSize">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Size:</h6>
              <span class="selecter-filter-container-title-select-event">{{filterSize}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="filterProduct">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Product:</h6>
              <span class="selecter-filter-container-title-select-event">{{filterProduct}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="selecter-filter-container selecter-filter-container-tabs" [ngClass]="{ active: filter }"> 

      <div class="selecter-filter-container-title">
        <label class="pointer" (click)="openTabFilter()" [ngClass]="{ 'active': !tabFilterNav }">Filter</label>
        <label class="pointer" (click)="openTabFilter()" [ngClass]="{ 'active': tabFilterNav }">Search</label>
      </div>

      <div class="row" *ngIf="!tabFilterNav">
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2 pl-0">
              <label for="Manufacture" class="mb-0 mr-2">Manufacturer</label></div>
            <select [(ngModel)]="selectedManMod" class="form-control" (ngModelChange)="loadSelectFilters($event,'MFGID')">
              <option disabled value="" selected>Please choose manufacturer</option>
              <option *ngFor="let com of companies" [ngValue]="com?.MFGID">{{ com?.companyName }}</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2 pl-0">
              <label for="Manufacture" class="mb-0 mr-2 ">Style</label>
            </div>
            <select
              [(ngModel)]="selectedStyleMod"
              (ngModelChange)="loadSelectFilters($event,'styleId')"
              name="style"
              class="form-control"
              id="styleselect"
            >
              <option disabled value="" selected>Please choose Style</option>
              <option [ngValue]="sty?.styleId" *ngFor="let sty of styles">{{ sty?.styleName }}</option>
             
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2 pl-0"><label for="Manufacture" class="mb-0 mr-2">Size</label></div>
            <select [(ngModel)]="selectedSizeMod" class="form-control" (ngModelChange)="loadSelectFilters($event,'sizeName')">
              <option disabled value="" selected>Please choose Size</option>
              <option *ngFor="let size of sizes" [ngValue]="size?.sizeName">{{ size?.sizeName }}</option>
             
            </select>
          </div>
        </div>
        <div class="col-12 ">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2 pl-0">
              <label for="Manufacture" class="mb-0 mr-2">Product</label>
            </div>
            <select [(ngModel)]="selectedProductMod" (ngModelChange)="loadSelectFilters($event,'product')" name="product" class="form-control" id="sizeselect">
                <option disabled value="" selected>Please choose Product</option>
                <option [ngValue]="product?.product" *ngFor="let product of products">{{ product?.product }}</option>
              
              </select>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center">
          <div class="form-group mt-3 mb-3 d-flex">
            <button (click)="this.filter = false;" class="btn btn-3 mr-1">Filter</button>
            <button (click)="clearALL()" class="btn btn-3 mr-1">Clear</button>
            <button (click)="clearALL();this.filter = false; " class="btn btn-3 mr-1">Show All</button>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="tabFilterNav">
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2 pl-0"><label for="Manufacture" class="mb-0 mr-2">Manufacturer</label></div>
            <input class="form-control" type="text" [(ngModel)]="filterManu">
            <!-- (input)="searchinput($event.target.value,'companyName')"  -->
          </div>
        </div>
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2 pl-0">
              <label for="Manufacture" class="mb-0 mr-2">Style</label>
            </div>
            <input class="form-control" type="text" [(ngModel)]="filterStyle">
            <!-- (input)="searchinput($event.target.value,'styleName')" -->
          </div>
        </div>
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2 pl-0"><label for="Manufacture" class="mb-0 mr-2">Size</label></div>
            <input class="form-control" type="text" [(ngModel)]="filterSize">
            <!-- (input)="searchinput($event.target.value,'sizeName')" -->
          </div>
        </div>
        <div class="col-12 ">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2 pl-0">
              <label for="Manufacture" class="mb-0 mr-2">Product</label>
            </div>
            <input class="form-control" type="text" [(ngModel)]="filterProduct">
            <!-- (input)="searchinput($event.target.value,'product')" -->
          </div>
        </div>
        <div class="col-12 d-flex align-items-center">
          <div class="form-group mt-3 mb-3 d-flex">
            <button (click)="searchInputApply(); this.filter = false" class="btn btn-3 mr-1">Search</button>
            <button (click)="clearsearchInputApply();this.searchedInput = false" class="btn btn-3 mr-1">Clear</button>
            <button (click)="clearsearchInputApply();this.filter = false;this.searchedInput = false" class="btn btn-3 mr-1">Show All</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  
  

  <div class="acordion-dropdown-container store-scroll-top" [ngClass]="{ 'scroll-top-store-catalog': searched || searchedInput }" *ngIf="!salesOrderMode">
    <div class="acordion-dropdown-container-header">
      <div class="acordion-dropdown-container-header-title">
        <p>Store Catalog</p>
      </div>
      <div class="acordion-dropdown-container-header-icons">
        <app-collapse-control [isCollapsed]="isCollapseDec1" (open)="onOpenDic1($event)"></app-collapse-control>
      </div>
    </div>
    <div class="acordion-dropdown-container-items" [ngClass]="{ active: isCollapseDec1 === true, '': isCollapseDec1 }">
      
      <div class="customer-content">
        <div class="card-customer-mobile-record border"  *ngFor="let sp of recordProducts | paginate: { itemsPerPage: 10, currentPage: p }; trackBy:trackBy">
          <div class="card-customer-mobile-record-base-info">
            <div class="row">
              <!-- Manufacture -->
              <div class="col-12">
                <p class="m-0"><strong>{{ sp.companyName }}</strong></p>
              </div>
              <!-- Style -->
              <div class="col-12">
                <p class="m-0 large-text-card">{{ sp.styleName }}</p>
              </div>
              <!-- Size -->
              <div class="col-6">
                <p class="m-0">{{ sp.sizeName }}</p>
              </div>
              <!-- Type -->
              <div class="col-6">
                <p class="m-0">Type: {{ sp.Type }}</p>
              </div>
              <!-- Sale Price -->
              <div class="col-6">
                <span style="width: 80px;">Sale Price</span>
                <input
                    *ngIf="!salesOrderMode"
                    class="form-control form-control-sm text-right"
                    type="number"
                    name=""
                    (blur)="onSalesPriceChange(sp.productsId, sp, false)"
                    required
                    style="max-width: 100% !important;"
                    [(ngModel)]="sp.salesPriceNum"
                  />
                <div *ngIf="salesOrderMode">{{ sp.salesPriceNum | currency }}</div>
    
              </div>
              <!-- WholeSale -->
              <div class="col-6" *ngIf="isDealer">
                <p class="m-0">WholeSale: {{sp.wholeSalePriceNum}}</p>
              </div>
              <!-- Buttons -->
              <div class="col-12">
                <div class="  w-100">
                  <div>
                    <ng-template #template>
                      <app-addstockitem
                        [sp]="sp"
                        (qtyStockAdded)="qtyStockAdded($event)"
                      ></app-addstockitem>
                    </ng-template>
                    <!-- <button
                      *ngIf="!salesOrderMode"
                      class="btn btn-add mr-2"
                      (click)="addStockItems(sp.id, sp, template)"
                    >
                      <span class="material-icons"> add </span>
                    </button> -->
                  </div>
                  <div class="" style="display:flex">
                    <!-- Uncomment this to active qr mobile -->
                    <!-- <button *ngIf="!salesOrderMode" class="btn btn-delete" (click)="addToExpQRC(sp)">
                      <span class="material-icons">qr_code</span>
                    </button> -->
                    <button style="margin-left: auto;"
                      *ngIf="!salesOrderMode"
                      class="btn btn-delete mr-3"
                      (click)="deleteStoreProduct(sp.productsId)">
                      <span class="material-icons"> delete </span>
                    </button>
                    <button
                      *ngIf="salesOrderMode"
                      class="btn btn-add"
                      (click)="addSoItem(sp)"
                    >
                      <span class="material-icons">
                        add
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="pagination-container mb-2" *ngIf="!salesOrderMode">
        <pagination-controls  (pageChange)="p = $event" maxSize="5" previousLabel="" nextLabel="" autoHide="true" ></pagination-controls>
      </div>
    </div>
  </div>





                    <!-- Uncomment this to active qr mobile table -->

<!-- 
  <div *ngIf="recordProductsToQRC.length > 0 && !salesOrderMode" class="acordion-dropdown-container store-scroll-top mt-2" [ngClass]="{ 'scroll-top-store-catalog': searched || searchedInput }" >
    <div class="acordion-dropdown-container-header">
      <div class="acordion-dropdown-container-header-title">    
        <p>Products to export QRC </p> 
      </div>
     
      <div class="acordion-dropdown-container-header-icons">
        <app-collapse-control [isCollapsed]="isCollapseDec3" (open)="onOpenDic3($event)"></app-collapse-control>
      </div>
    </div>
    <div class="acordion-dropdown-container-items" [ngClass]="{ active: isCollapseDec3 === true, '': isCollapseDec3 }">
     
  <div class="customer-content" >

    <div style="display:flex;"  class="py-2">  
      <button class="btn btn-3 ml-auto"  (click)="clearToExpQRC()">Clear</button>
      <button class="btn btn-3 "  (click)="ExportToQRPDF()">Export</button>
      <button class="btn btn-3 " *ngIf="toSaveQR" (click)="saveToExpQRC()">Save</button>
    </div>

    <div class="card-customer-mobile-record border"  *ngFor="let sp of recordProductsToQRC">
      <div class="card-customer-mobile-record-base-info">
        <div class="row">
          <div class="col-12">
            <p class="m-0"><strong>{{ sp.companyName }}</strong></p>
          </div>
          <div class="col-12">
            <p class="m-0 large-text-card">{{ sp.styleName }}</p>
          </div>
          <div class="col-6">
            <p class="m-0">{{ sp.sizeName }}</p>
          </div>
          <div class="col-6">
            <p class="m-0">Type: {{ sp.Type }}</p>
          </div>
          <div class="col-6">
            <span style="width: 80px;">Sale Price</span>    
            <div >{{ sp.salesPriceNum | currency }}</div>

          </div>
          <div class="col-6" *ngIf="isDealer">
            <p class="m-0">WholeSale: {{sp.wholeSalePriceNum}}</p>
          </div>
          <div class="col-12">
            <div class="  w-100">               
              <div class="" style="display:flex">                   
                <button style="margin-left: auto;"
                  class="btn btn-delete mr-3"
                  (click)="deleteToExpQRC(sp.productsId)">
                  <span class="material-icons"> delete </span>
                </button>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div> -->


























  <div class="acordion-dropdown-container my-2" *ngIf="!salesOrderMode">
    <div class="acordion-dropdown-container-header">
      <div class="acordion-dropdown-container-header-title">
        <p>DDS ORDER FORMS</p>
      </div>
      <div class="acordion-dropdown-container-header-icons">
        <app-collapse-control [isCollapsed]="isCollapseDec2" (open)="onOpenDic2($event)"></app-collapse-control>
      </div>
    </div>

    <div class="acordion-dropdown-container-items" [ngClass]="{ active: isCollapseDec2 === true, '': isCollapseDec2 }">
      <app-styleitemsmobile></app-styleitemsmobile>
    </div>
  </div>

  
  
  <div class="customer-content p-3" *ngIf="salesOrderMode" >
    <div class="card-customer-mobile-record border" *ngFor="let sp of this.recordProducts">
      <div class="card-customer-mobile-record-base-info">
        <div class="row">
          <!-- Manufacture -->
          <div class="col-12">
            <p class="m-0"><strong>{{ sp.companyName }}</strong></p>
          </div>
          <!-- Style -->
          <div class="col-12">
            <p class="m-0 large-text-card">{{ sp.styleName }}</p>
          </div>
          <!-- Size -->
          <div class="col-6">
            <p class="m-0">{{ sp.sizeName }}</p>
          </div>
          <!-- Type -->
          <div class="col-6">
            <p class="m-0">Type: {{ sp.Type }}</p>
          </div>
          <!-- Sale Price -->
          <div class="col-6">
            <input
                *ngIf="!salesOrderMode"
                class="form-control form-control-sm text-right"
                type="number"
                name=""
                (blur)="onSalesPriceChange(sp.productsId, sp, false)"
                required
                [(ngModel)]="sp.salesPriceNum"
              />
            <div *ngIf="salesOrderMode">{{ sp.salesPriceNum | currency }}</div>

          </div>
          <!-- WholeSale -->
          <div class="col-6" *ngIf="isDealer">
            <p class="m-0">WholeSale: {{sp.wholeSalePriceNum}}</p>
          </div>
          <!-- Buttons -->
          <div class="col-12">
            <div class="d-flex justify-content-end w-100">
              <div>
                <ng-template #template>
                  <app-addstockitem
                    [sp]="sp"
                    (qtyStockAdded)="qtyStockAdded($event)"
                  ></app-addstockitem>
                </ng-template>
                <!-- <button
                  *ngIf="!salesOrderMode"
                  class="btn btn-add mr-2"
                  (click)="addStockItems(sp.id, sp, template)"
                >
                  <span class="material-icons"> add </span>
                </button> -->
              </div>
              <div class="pl-3">
                <button
                  *ngIf="!salesOrderMode"
                  class="btn btn-delete mr-3"
                  (click)="deleteStoreProduct(sp.productsId)">
                  <span class="material-icons"> delete </span>
                </button>
                <button
                  *ngIf="salesOrderMode"
                  class="btn btn-add"
                  (click)="addSoItem(sp)"
                >
                  <span class="material-icons">
                    add
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- [(ngModel)]="searchBarText"  blurr-->
  <!-- <div class="mt-5" *ngIf="!salesOrderMode">
    <label for="">Manufacture</label>
    <input type="text" [(ngModel)]="filterManu" (input)="searchinput($event.target.value,'companyName')" >
  </div>
  <div class="mt-5" *ngIf="!salesOrderMode">
    <Label>Style</Label>
    <input type="text" [(ngModel)]="filterStyle" (input)="searchinput($event.target.value,'styleName')" >
  </div>
  <div class="mt-5" *ngIf="!salesOrderMode">
    <label for="">Size</label>
    <input type="text" [(ngModel)]="filterSize" (input)="searchinput($event.target.value,'sizeName')" >
  </div>
  <div class="mt-5" *ngIf="!salesOrderMode">
    <label for="">product</label>
    <input type="text" [(ngModel)]="filterProduct" (input)="searchinput($event.target.value,'product')" >
  </div> -->

</div>
