import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateTimeFormatPipe, SafeHtmlPipe } from 'src/app/_directives/safeHtml.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PopoverConfig, PopoverModule } from 'ngx-bootstrap/popover';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDropdownConfig, BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsetConfig, TabsModule } from 'ngx-bootstrap/tabs';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { BsDatepickerConfig, BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PaginationConfig, PaginationModule } from 'ngx-bootstrap/pagination';
import { TooltipConfig, TooltipModule } from 'ngx-bootstrap/tooltip';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { TypeaheadConfig, TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { RouterModule } from '@angular/router';
import { SeriesPipe } from 'src/app/series.pipe';
import { PanelComponent } from 'src/app/controls/panel/panel.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { DashcountComponent } from 'src/app/controls/dashcount/dashcount.component';

@NgModule({
  declarations: [       
    PanelComponent, 
    DashcountComponent,        
    DateTimeFormatPipe, 
    SafeHtmlPipe, 
    SeriesPipe],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgApexchartsModule,
    

    CollapseModule.forRoot(),
    PopoverModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ButtonsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    TabsModule.forRoot(),
    TooltipModule.forRoot(),
    TypeaheadModule.forRoot(),
  ],
  providers: [

    PaginationConfig,
    TooltipConfig,
    PopoverConfig,
    BsDropdownConfig,
    TabsetConfig,
    BsDatepickerConfig,
    TypeaheadConfig],
  exports: [
    PanelComponent,
    DashcountComponent,  
    DateTimeFormatPipe, SafeHtmlPipe,
    SeriesPipe,
    FormsModule,
    ReactiveFormsModule,
    NgApexchartsModule,

  CollapseModule,
  PopoverModule,
  CalendarModule,
  ModalModule,
  BsDropdownModule,
  TabsModule,
  ButtonsModule,
  BsDatepickerModule,
  PaginationModule,
  TabsModule,
  TooltipModule,
  TypeaheadModule,
  RouterModule
]

})
export class SharedModule {


 }
