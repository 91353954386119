import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { UserService } from 'src/app/_services/user.service';
import { take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InvoicereportComponent } from 'src/app/reports/invoicereport/invoicereport.component';
import { EventsService } from 'src/app/_services/events.service';
import { Router } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { Pagination } from '../../../_modules/pagination';
import { ReportsService } from 'src/app/_services/reports.service';
import { SendemailComponent } from 'src/app/reports/sendemail/sendemail.component';
import { FormControl, FormGroup  } from '@angular/forms';
import { SettingsService } from 'src/app/_services/settings.service';
import { SendemailLocalComponent } from 'src/app/reports/sendemail-local/sendemail-local.component';
import { environment } from '../../../../environments/environment';
import isOnline from 'is-online';
import { GlobalsService } from 'src/app/_services/globals.service';
import { TemplatemessagesService } from 'src/app/_services/templatemessages.service';

@Component({
  selector: 'app-sales-person-list-so',
  templateUrl: './sales-person-list-so.component.html',
  styleUrls: ['./sales-person-list-so.component.scss']
})
export class SalesPersonListSOComponent implements OnInit,OnDestroy {

  destroy$: Subject<boolean> = new Subject<boolean>();
     //knowinf thr device  
     radioEventsSearch = new FormControl('all');
     platform = '';
     showMobile = false;
     isIOS = false;
     isCashier = '';
     isAndroid = false;
     showpagination:Boolean = false;
     salesorders:any =[]
     event:any = []
     dateFilter = '';
     show = 'Parked';
     originalSalesOrder :any;
     role = this.authService.decodeToken('role').role
     userlogged = this.authService.decodeToken('username').username
     excludedFilter = '';
     statusFilter = '';
     pagination: Pagination;
     yo:any
     emailLoading = false;
     emailSalesOrder = '';
    showListSos:boolean = false
    msgData = '';    
  salesordersAutoSave: any = [];
  showcardevent:boolean = false
  showChooseEvent:boolean  = false
  loadSOEvent:boolean  = true
  showsearchbar:boolean  = false
  hidetabsSearch:boolean  = false
  hidetabAutosaved:boolean  = true

  //pagination Basic Search
  searchString:string = '';
  numpageParked = []
  numpageParkedFOR = []
  numpageProcessed = []
  numpageProcessedFOR = []  
  salesordersfiltered:any
  historialpage:any
  disablednextpag:any
  disabledbeforepag:any
  countParked:any
  countProcessed:any

  //SEARCH ADVANCE
  showtabsearch:string = 'Basic'
  sectionAdvS:string = 'General'
  npagbasicPark:number = 1
  npagbasicProcessed:number = 1
  npagAdv:number = 1
  reqAdvanceSearch:any = []
  SOAdvanceSearch:any = []
  SOAdvanceSearchhtml:any = []
  SOIDdropdown:string = '';
  itemsdropdown:any = [];
  deliveriesdropdown:any = [];
  paymentsdropdown:any = [];
  numSOAdv:number = 0;
  
  
  
  width:number = 0;
  oninputAC:string = ''
  arrayautocomplete:any = [];
  selectedautocomplete:string = ''
  indexSearch:any = {
    "sizeName":[],
    "MFG":[],
    "Type":[],
    "styleName":[],
    "inventory":['NTO','Taken','Direct Shipment','In Stock'],
    "payments":['Cash','Check','Master Card','Visa','Amex','Discover','Financing'],
    "deliveries":['Delivery','Recycle','Removal']
};


  msgNoSOs:string = 'Filter to show Sales Orders';
  //pagination Advance Search
  numpageAdvance = []
  numpageAdvanceFOR = [] 
  historialpageAdv:any
  disablednextpagAdv:any
  storeSettings:any
  disabledbeforepagAdv:any
  //form advance
  searchAdvForm = new FormGroup({
    condition: new FormControl('and'),
    event: new FormControl(0),

    SONumber: new FormControl(null),
    firstName: new FormControl(null), 
    lastName: new FormControl(null),
    email: new FormControl(null), 
    phone1: new FormControl(null), 
    salesPerson: new FormControl(null),
    soSource: new FormControl(null),
    city: new FormControl(null), 
    billingAddress1: new FormControl(null),
    dbDatets: new FormControl(null),

    deliveryType: new FormControl(null),
    deliveryNotes: new FormControl(null), 
    deliveryCode: new FormControl(null),

    paymentType: new FormControl(null), 
    paymentDetail: new FormControl(null),
    Note: new FormControl(null),
    
    size: new FormControl(null),
    MFG: new FormControl(null),
    Type: new FormControl(null),
    styleName: new FormControl(null),
    itemDelivery: new FormControl(null),
  });



  constructor(  public userService: UserService,  public authService: AuthService,
    public bsModalRef: BsModalRef,  private modalService: BsModalService,
    private eventService: EventsService,   private alertify: AlertifyService,private router: Router,
    private salesService: SalesOrderService, private settingsService: SettingsService,
    private reportService: ReportsService,private globals: GlobalsService,
    private templateMeSvc:TemplatemessagesService) { }

    numEventsSearch(){
      if(this.radioEventsSearch.value == 'all'){
      }else {     
        if(this.role != 'Sales Person'){
        this.radioEventsSearch.setValue('single')
        this.searchString = ''
        this.loadSalesOrder(-1)
      } 
      }
    }

    searchPnIndexs(index?:any,formcontrol?:any,iddiv?:any,e?:any){        
      
      this.width = document.getElementById(iddiv).clientWidth;
      this.oninputAC = iddiv;

      if(e?.key == 'ArrowDown' || e?.key == 'ArrowUp' || e?.key == 'Enter' ){
        this.moveinlist(e?.key,formcontrol);
      }else{
        let indextofilter:any = []
          if(this.searchAdvForm.get(formcontrol).value !=''){
            let filtered = [];
            indextofilter = this.indexSearch[index]
            filtered = this.indexSearch[index].filter(x => x?.toLowerCase().includes(this.searchAdvForm.get(formcontrol).value?.toLowerCase()));         
            this.arrayautocomplete = filtered.slice(0, 4);
            this.selectedautocomplete = this.arrayautocomplete[0]
          }else{
          this.arrayautocomplete = []
          this.selectedautocomplete =''        
          }
      }
    }
    quitAutocomplete(){
      setTimeout(() => {
        this.oninputAC = '';        
      }, 200);
    }

    moveinlist(key?:any,formcontrol?:any){
      if(key == 'ArrowDown'){
        if(this.arrayautocomplete.length >1){
          let ia = this.arrayautocomplete.indexOf(this.selectedautocomplete);
          if(ia+1 < this.arrayautocomplete.length){
            this.selectedautocomplete = this.arrayautocomplete[ia +1];
          }else{
            this.selectedautocomplete = this.arrayautocomplete[0];            
          }          
        }
      }else if(key == 'ArrowUp'){
        if(this.arrayautocomplete.length >1){
          let ia = this.arrayautocomplete.indexOf(this.selectedautocomplete);
          if(ia > 0){
            this.selectedautocomplete = this.arrayautocomplete[ia -1];
          }else{
            this.selectedautocomplete = this.arrayautocomplete[this.arrayautocomplete.length-1];            
          }          
        }
      }else if(key == 'Enter'){
        this.searchAdvForm.controls[formcontrol].setValue(this.selectedautocomplete)
        this.arrayautocomplete = []
        this.selectedautocomplete =''
      }
    }

    optionAC(value?:any,formcontrol?:any){
      this.searchAdvForm.controls[formcontrol].setValue(value)
      this.arrayautocomplete = []
      this.selectedautocomplete =''
    }

  showSearchBar(){
    this.showsearchbar = true 
    this.salesService.indexsearchadvance()
    .toPromise()
    .then((res:any)=>{
      
      res[0].forEach((element,i) => {
        // this.indexSearch.sizeName.push({"id":i,"name":element['sizeName']});
        this.indexSearch.sizeName.push(element['sizeName']);
      });
      // console.log(this.indexSearch.sizeName)
      res[1].forEach(element => {
        this.indexSearch.MFG.push(element['MFG']);
      });
      res[2].forEach(element => {
        this.indexSearch.Type.push(element['Type']);
      });
      res[3].forEach(element => {
        this.indexSearch.styleName.push(element['styleName']);
      });
    })
    .catch((err:any)=>{console.log(err)})    
  }

  searchAdvance(){
    this.searchAdvForm.controls["event"].setValue(this.event.CID);

    this.salesService.advancesearchform.next(this.searchAdvForm.value);
    this.salesService.normalOrAdvsearch.next('advance');
    this.salesService.eventsearch.next(this.event);

    this.salesService.searchAdvanceSO(this.searchAdvForm.value)
    .toPromise()
    .then((res:any)=>{
      if(res !=  0){        
        this.reqAdvanceSearch = res[0];
        this.SOAdvanceSearch = res[1];
        this.SOIDdropdown = ''

          let numpag = Math.ceil(this.SOAdvanceSearch.length / 10) 
          this.numpageAdvance = [] 
          for (let index = 1; index <= numpag; index++) {
            this.numpageAdvance.push(index)        
          }
          this.customPaginatorAdvance(this.npagAdv)

          this.showListSos = true
          this.numSOAdv = this.SOAdvanceSearch.length

        if(this.SOAdvanceSearch.length == 0){
          this.clearAdvanceArrays();
          this.msgNoSOs = 'No SOs with filters'; 
        }

      }else{
        this.alertify.error('Enter at least a field');
      }
      // console.log(res)
    })
    .catch((err:any)=>{
      // console.log(err)
    })  
  }



  showSOdetailsAdv(soid){
    if(this.SOIDdropdown != soid){
      this.itemsdropdown = []
      this.deliveriesdropdown = []
      this.paymentsdropdown = []
      this.salesService.searchAdvanceSOdetails(soid)
      .toPromise()
      .then((res:any)=>{
        if(res != null && res != 0 && res.length > 0){
          this.itemsdropdown = res[0];
          this.deliveriesdropdown = res[1];
          this.paymentsdropdown = res[2];
        }
      })
      .catch((err)=>{console.log(err)});

      this.SOIDdropdown = soid
    }else{
      this.SOIDdropdown = '';
      this.itemsdropdown = []
      this.deliveriesdropdown = []
      this.paymentsdropdown = []
    }

  }
  clearAdvance(){
    this.searchAdvForm.reset()
    this.searchAdvForm.controls['condition'].setValue('and');
  }

  clearAdvanceArrays(){
    this.SOAdvanceSearch = []
    this.itemsdropdown = []
    this.deliveriesdropdown = []
    this.paymentsdropdown = []
    this.SOIDdropdown = ''
  }
  toggleCondition(condition:any){
    this.searchAdvForm.controls['condition'].setValue(condition);   
  }

  toggleSalesPerson(){
    // if(localStorage.getItem("actlike") == undefined){
    //   localStorage.setItem('actlike', 'Sales Person');
    // }else{
    //   localStorage.removeItem("actlike")
    // }

    if(this.role == 'Cashier'){
      let data = {
        username : this.authService.decodeToken('username').username,
        role : 'Sales Person',
        user : this.authService.decodeToken('user').user,
        cfsat : this.authService.decodeToken('cfsat').cfsat,
        DID : this.authService.decodeToken('DID').DID,
        dbServer : this.authService.decodeToken('dbServer').dbServer,
      }
      // console.log(data)
      this.authService.switchRole(data)
        .subscribe(res => {
          // console.log(res)
          this.role = this.authService.decodeToken('role').role
          this.router.navigate(['salesorder'])
          this.alertify.success('User Role Changed')
        })    
      }

      if(this.role == 'Sales Person'){
        let data = {
          username : this.authService.decodeToken('username').username,
          role : 'Cashier',
          user : this.authService.decodeToken('user').user,
          cfsat : this.authService.decodeToken('cfsat').cfsat,
          DID : this.authService.decodeToken('DID').DID,
          dbServer : this.authService.decodeToken('dbServer').dbServer,
        }
        // console.log(data)
        this.authService.switchRole(data)
          .subscribe(res => {
            // console.log(res)
            this.role = this.authService.decodeToken('role').role
            this.router.navigate(['salesorder'])
            this.alertify.success('User Role Changed')
          })    
        }

  }

  ngOnInit(): void {

  //obtengo las saes order del dia
  const currentDate: Date = new Date();
  const year: number = currentDate.getFullYear();
  const month: any = currentDate.getMonth() + 1; // Los meses comienzan desde 0 (enero), por lo que sumamos 1
  const day: any = currentDate.getDate();  
  const date = year+'-'+(month < 10?'0'+month:month)+'-'+(day <10?'0'+day:day)  
  this.salesService.getSalesOrderDay(date)
  .pipe(takeUntil(this.destroy$))   
  .subscribe( data => {
    console.log(data);
    this.showListSos = true
    this.salesorders = data;
    this.salesordersfiltered = data;
    this.originalSalesOrder = data
  }, error => {
    this.alertify.error(error);
  });

  this.salesService.isCollapse.next(true)
  
  document.getElementById('bodymainhtml').style.backgroundColor = "white"; 
  document.getElementById('mainWithSideBar').style.backgroundColor = "#fff"  
  if(this.role == 'Sales Person' || this.role == 'Cashier'){  
    document.getElementById('sidebar').style.display = "none"; 
    document.getElementById('mainWithSideBar').className = "body-page-content-SalesPerson";
  }else{
    document.getElementById('mainWithSideBar').className = "body-page-content";      
  }

  this.salesorders = []
  this.showListSos = false  

  if(this.role == 'Cashier'){
  this.isCashier = 'yeah';
  localStorage.setItem('isCashier', 'yeah') 
  }
  if(localStorage.getItem('isCashier') == 'yeah'){
  this.isCashier = 'yeah'
  }

  this.userlogged = JSON.parse(this.authService.decodeToken('user').user).name
    
  //get events
  this.eventService.loadsalesOrderEvent
  .pipe(takeUntil(this.destroy$))  
  .subscribe((res:any)=>{
  this.loadSOEvent = res
  });



  let specificFields = {
    values: [
        "cashierStatus"
    ]
  }
    
  this.settingsService.getSpecificSettings(specificFields)
  .pipe(takeUntil(this.destroy$))   
  .subscribe( data => {
    // console.log(data)
    this.storeSettings = data[0];
  }, error => {
    this.alertify.error(error);
  });

  this.pagination =  {
    totalItems: 10,
    totalPages: 10,
    currentPage: 1,
    itemsPerPage: 2000
  };

  //get platform device
  this.platform = navigator.userAgent;
  if (this.platform.match(/Android/i)) {
    this.showMobile = true;
    this.isAndroid = true;
  }
  if (this.platform.match(/iPhone|iPad|iPod/i)) {
    this.showMobile = true;
    this.isIOS = true;
  }
  if (this.platform.includes('Mac')) {
    this.showMobile = false;
  }
  if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
    this.showMobile = true;
    this.isIOS = true;
  }
  if (this.platform.includes('Win')) {
    this.showMobile = false;
  }
  
  // this.getSOsAutoSaved();  



  if(this.salesService.normalOrAdvsearch.value != ''){    
    this.event = this.salesService.eventsearch.value


    if(this.salesService.normalOrAdvsearch.value == 'normal'){
      this.show = this.salesService.showParkedOrProcessed.value
      this.npagbasicPark = this.salesService.pagNormalSearchParked.value
      this.npagbasicProcessed = this.salesService.pagNormalSearchProcessed.value
      this.searchString = this.salesService.normalsearch.value
      this.loadSalesOrder('search');    
      this.showListSos = true
      this.showsearchbar = true
      this.showtabsearch = 'Basic'
    }
    
    if(this.salesService.normalOrAdvsearch.value == 'advance'){  

      this.npagAdv = this.salesService.pagAdvSearch.value

      let form = this.salesService.advancesearchform.value;
      this.searchAdvForm.controls['condition'].setValue(form.condition)
      this.searchAdvForm.controls['event'].setValue(form.event)

      this.searchAdvForm.controls['SONumber'].setValue(form.SONumber)
      this.searchAdvForm.controls['firstName'].setValue(form.firstName)
      this.searchAdvForm.controls['lastName'].setValue(form.lastName)
      this.searchAdvForm.controls['email'].setValue(form.email)
      this.searchAdvForm.controls['phone1'].setValue(form.phone1)
      this.searchAdvForm.controls['salesPerson'].setValue(form.salesPerson)
      this.searchAdvForm.controls['soSource'].setValue(form.soSource)
      this.searchAdvForm.controls['city'].setValue(form.city)
      this.searchAdvForm.controls['billingAddress1'].setValue(form.billingAddress1)
      this.searchAdvForm.controls['dbDatets'].setValue(form.dbDatets)

      this.searchAdvForm.controls['deliveryType'].setValue(form.deliveryType)
      this.searchAdvForm.controls['deliveryNotes'].setValue(form.deliveryNotes)
      this.searchAdvForm.controls['deliveryCode'].setValue(form.deliveryCode)

      this.searchAdvForm.controls['paymentType'].setValue(form.paymentType)
      this.searchAdvForm.controls['paymentDetail'].setValue(form.paymentDetail)
      this.searchAdvForm.controls['Note'].setValue(form.Note)

      this.searchAdvForm.controls['size'].setValue(form.size)
      this.searchAdvForm.controls['MFG'].setValue(form.MFG)
      this.searchAdvForm.controls['Type'].setValue(form.Type)
      this.searchAdvForm.controls['styleName'].setValue(form.styleName)
      this.searchAdvForm.controls['itemDelivery'].setValue(form.itemDelivery)
      this.searchAdvance();
      this.showListSos = true
      this.showsearchbar = true
      this.showtabsearch = 'Advance'  
    }

  }

  //actualizo los json despues de haber mandado el email
  this.reportService.SOIDemailsent.subscribe(
    (res:any)=>{
      // console.log("estamos actualizando el sobresito de los emails");
      
      if(res != ''){        
        var elementoAEditar = this.salesorders.find((x:any) => x.SOID == res.SOID);
        var elementoAEditarO = this.originalSalesOrder.find((x:any) => x.SOID == res.SOID);
        if (elementoAEditar) {
            elementoAEditar.emailsent = true;
            elementoAEditar.notifications = res.notifications;
        }        
        if (elementoAEditarO) {
          elementoAEditarO.emailsent = true;
          elementoAEditarO.notifications = res.notifications;
        }
        this.reportService.SOIDemailsent.next('');
      }
  })

} 

loadSalesOrder(a:any){
let cid;
let search;
if(a == 'search'){
    // check if only search in a event
    if(this.eventService.salesOrderEvent.value == '0'){
      cid = null; 
      search = this.searchString;
    }else{
      cid = this.event['CID']; 
      search = this.searchString;
    }
}else if(a == 'clear'){
       // check if only search in a event
       if(this.eventService.salesOrderEvent.value == '0'){
        this.salesorders = []
        this.searchString = ''
        this.showListSos = false
        return ;
      }else{
        cid = this.event['CID']; 
        search = '';
        this.searchString = ''
      }
      //cuando el paramaetro es dos, es desde el boton Show SalesOrder que le aparece solo al Sales Person
}else  if(a == 2){
        if (this.event != null && this.event != undefined && this.event != '0') {
          cid = this.event['CID']; 
          search = '';          
        }else{
          this.alertify.error('Choose a Event')
          return;
        }
}else if(a == -1){
    if (this.event != null && this.event != undefined && this.event != '0') {
      cid = this.event['CID']; 
      search = '';   
      this.showListSos = true      
    }else{
      return ;           
    }
}

    let specificFields:any = {
        values: [
            "SOID","eventId","SONumber","status", "firstName", "middleInitial", "lastName", "email", "SOID", "createdBy", "salesPersonOther", "salesPerson","fundraiser","notifications"
        ]
    }
    this.salesService.getSalesOrdersSpecific(this.pagination.currentPage, this.pagination.itemsPerPage, search, cid,
     'dbDatets', 'desc', '', this.statusFilter, this.excludedFilter,'', specificFields)
      .pipe(takeUntil(this.destroy$))  
      .subscribe(
      (res: any) => {

          this.salesorders =  [];

          if(this.role != 'Sales Person' ){

            //conpruebo si el email fue enviado
            res.result.forEach(element => {
              if(element.notifications != null && element.notifications != undefined && element.notifications != ""){
                element.emailsent = false                
                if(JSON.parse(element.notifications).emailsent != undefined){
                  if((JSON.parse(element.notifications).emailsent).length > 0){
                    element.emailsent = true
                  }
                }
              }else{
                element.emailsent = false
              }
            });

            this.salesorders  = res.result
            this.originalSalesOrder = res.result

              this.salesorders.forEach(so => {
                so.salesPersonOther != 'Sales Person' ? so.show = false : so.show = true
              });
            
              if(this.salesorders.length >0){            
                let cpar = this.originalSalesOrder.filter( so => so?.status == 'Draft')
                let cpro = this.originalSalesOrder.filter( so => so?.status != 'Draft')
                this.countParked = cpar.length
                this.countProcessed = cpro.length

                if(this.salesService.showParkedOrProcessed.value == ''){
                  if(this.countParked > 0){
                    this.show = 'Parked'
                  }else{
                    this.show = 'Processed'
                  } 
                }else{

                  if(this.salesService.showParkedOrProcessed.value == 'Parked'){
                    if(this.countParked > 0){
                      this.show = this.salesService.showParkedOrProcessed.value
                    }else{
                      this.show = 'Processed'
                    }                    
                  }
                  if(this.salesService.showParkedOrProcessed.value == 'Processed'){
                    if(this.countProcessed > 0){
                      this.show = this.salesService.showParkedOrProcessed.value
                    }else{
                      this.show = 'Parked'
                    } 
                  }
                }
                  
                
                
   
                if(this.show == 'Parked'){
                  this.filterSalesOrderBy('Draft',this.npagbasicPark)
                }else if(this.show == 'Processed'){
                  this.filterSalesOrderBy('original',this.npagbasicProcessed)
                }
                this.showListSos = true
                this.showpagination = true          
              }else{
                this.countParked = 0
                this.countProcessed = 0
              }
              
          }else{

              let spName = JSON.parse(this.authService.decodeToken('user').user).name  
              let spEmail = JSON.parse(this.authService.decodeToken('user').user).userName 
              //make the filter with email ad naeme in SO             
              this.salesorders  = res.result.filter(x => 
              x['salesPerson'] == spName ||
              (x['createdBy'] != null ? (x['createdBy'].toLowerCase()).includes(spEmail.toLowerCase()) :'')
              ); 

              if(this.salesorders.length >0){
                this.showpagination = true   
                this.showListSos = true    
                this.show = 'Processed'                          
             
              }else{ 
                  this.countParked = 0
                  this.countProcessed = 0                  
                  this.showListSos = true   
                  this.msgData = "You don't have sales order yet";                              
              }                     
          }
        
    }, error => {
      this.alertify.error(error);
    });
}

  filterSalesOrderBy(status:string,numpagg?:any){

    if(status == 'original'){
      this.salesService.showParkedOrProcessed.next('Processed')
      let result = this.originalSalesOrder.filter( so => so.status != 'Draft')
      let numpag = Math.ceil(result.length / 10)  

      this.numpageParked = [] 
      this.numpageProcessed = [] 
      for (let index = 1; index <= numpag; index++) {
        this.numpageProcessed.push(index)        
      }
      this.salesordersfiltered = result
      this.customPaginator(numpagg)

    }else{
      this.salesService.showParkedOrProcessed.next('Parked')
      let result = this.originalSalesOrder.filter( so => so.status == 'Draft')
      let numpag2 = Math.ceil(result.length / 10)   

      this.numpageProcessed = [] 
      this.numpageParked = [] 
      for (let index = 1; index <= numpag2 ; index++){
        this.numpageParked.push(index)       
      }
      this.salesordersfiltered = result
      this.customPaginator(numpagg)
      // this.salesorders = result
    }
  }

  deselectEvent(){
    this.eventService.salesOrderEvent.next('0')
    this.salesService.normalOrAdvsearch.next('')
    this.salesService.eventsearch.next('')
    // this.radioEventsSearch.setValue('all')
  }

  customPaginatorAdvance(numpag:any){   
    
   if(this.SOAdvanceSearch.length > 10){
      
      if(numpag == 'first'){
        numpag = 1;
      }else if(numpag == 'before'){
        numpag = this.historialpageAdv - 1
      }else if(numpag == 'next'){
        numpag = this.historialpageAdv + 1                   
      }else if(numpag == 'last'){
        numpag = Math.ceil(this.SOAdvanceSearch.length /10)
      }

      if(numpag == Math.ceil(this.SOAdvanceSearch.length /10)){
        this.disablednextpagAdv = true
      }else{
        this.disablednextpagAdv = false
      }
      if(numpag == 1){
        this.disabledbeforepagAdv = true
      }else{
        this.disabledbeforepagAdv = false
      }

      this.salesService.pagAdvSearch.next(numpag);
      this.historialpageAdv = numpag

      let index = (numpag*10) -10
      let sum = 0;
      if((numpag*10) > this.SOAdvanceSearch.length){
        sum = this.SOAdvanceSearch.length - (numpag*10) +10
      }else{
        sum = 10
      }
      let filteredpagination = []
      for(let i = index; i < index+sum; i++){
        filteredpagination.push(this.SOAdvanceSearch[i])
      }          
    
      this.SOAdvanceSearchhtml = filteredpagination   
      

        //asigno los numeros que va a por ver en el pagination
            if(this.numpageAdvance.length > 6){
              // if(numpag > 3){
                this.numpageAdvanceFOR = []
                if(numpag < this.numpageAdvance.length-5){
                  this.numpageAdvanceFOR.push(this.numpageAdvance[numpag-1])
                  this.numpageAdvanceFOR.push(this.numpageAdvance[numpag])
                  this.numpageAdvanceFOR.push(this.numpageAdvance[numpag+1])
                }else{
                  this.numpageAdvanceFOR.push(this.numpageAdvance[this.numpageAdvance.length-6])
                  this.numpageAdvanceFOR.push(this.numpageAdvance[this.numpageAdvance.length-5])
                  this.numpageAdvanceFOR.push(this.numpageAdvance[this.numpageAdvance.length-4])
                }

                this.numpageAdvanceFOR.push(this.numpageAdvance[this.numpageAdvance.length-3])
                this.numpageAdvanceFOR.push(this.numpageAdvance[this.numpageAdvance.length-2])
                this.numpageAdvanceFOR.push(this.numpageAdvance[this.numpageAdvance.length-1])
              // }
            }else{
              this.numpageAdvanceFOR = this.numpageAdvance;
            }   

    
   }else{
    this.SOAdvanceSearchhtml = this.SOAdvanceSearch
   }
  }


  customPaginator(numpag:any){  

    if(this.salesordersfiltered.length > 10){
      
        if(numpag == 'first'){
          numpag = 1;
        }else if(numpag == 'before'){
          numpag = this.historialpage - 1
        }else if(numpag == 'next'){
          numpag = this.historialpage + 1                   
        }else if(numpag == 'last'){
          numpag = Math.ceil(this.salesordersfiltered.length /10)
        }

        if(numpag == Math.ceil(this.salesordersfiltered.length /10)){
          this.disablednextpag = true
        }else{
          this.disablednextpag = false
        }
        if(numpag == 1){
          this.disabledbeforepag = true
        }else{
          this.disabledbeforepag = false
        }
        
        if(this.show== 'Parked'){
          this.salesService.pagNormalSearchParked.next(numpag)
          this.npagbasicPark = numpag
        }

        if(this.show== 'Processed'){
          this.salesService.pagNormalSearchProcessed.next(numpag)
          this.npagbasicProcessed = numpag
        }

        this.historialpage = numpag

        let index = (numpag*10) -10
        let sum = 0;
        if((numpag*10) > this.salesordersfiltered.length){
          sum = this.salesordersfiltered.length - (numpag*10) +10
        }else{
          sum = 10
        }
        let filteredpagination = []
        for(let i = index; i < index+sum; i++){
          filteredpagination.push(this.salesordersfiltered[i])
        }

            
        
            
      
          this.salesorders = filteredpagination
        
          if(this.role != 'Sales Person' && this.salesorders.length ==0 ){
            if(this.show == 'Processed'){
              this.msgData = "You don't have sales order Processed yet";
            }else if(this.show == 'Parked'){
              this.msgData = "You don't have sales order Parked";
            }
            return;
          }
        

          //asigno los numeros que va a por ver en el pagination
          if(this.numpageParked.length == 0){
              if(this.numpageProcessed.length > 6){
                // if(numpag > 3){
                  this.numpageProcessedFOR = []
                  if(numpag < this.numpageProcessed.length-5){
                    this.numpageProcessedFOR.push(this.numpageProcessed[numpag-1])
                    this.numpageProcessedFOR.push(this.numpageProcessed[numpag])
                    this.numpageProcessedFOR.push(this.numpageProcessed[numpag+1])
                  }else{
                    this.numpageProcessedFOR.push(this.numpageProcessed[this.numpageProcessed.length-6])
                    this.numpageProcessedFOR.push(this.numpageProcessed[this.numpageProcessed.length-5])
                    this.numpageProcessedFOR.push(this.numpageProcessed[this.numpageProcessed.length-4])
                  }

                  this.numpageProcessedFOR.push(this.numpageProcessed[this.numpageProcessed.length-3])
                  this.numpageProcessedFOR.push(this.numpageProcessed[this.numpageProcessed.length-2])
                  this.numpageProcessedFOR.push(this.numpageProcessed[this.numpageProcessed.length-1])
                // }
              }else{
                this.numpageProcessedFOR = this.numpageProcessed;
              }
          }

          //asigno los numeros que va a por ver en el pagination
          if(this.numpageProcessed.length == 0){
              if(this.numpageParked.length > 6){
                // if(numpag > 3){
                  this.numpageParkedFOR = []
                  if(numpag < this.numpageParked.length-5){
                    this.numpageParkedFOR.push(this.numpageParked[numpag-1])
                    this.numpageParkedFOR.push(this.numpageParked[numpag])
                    this.numpageParkedFOR.push(this.numpageParked[numpag+1])
                  }else{
                    this.numpageParkedFOR.push(this.numpageParked[this.numpageParked.length-6])
                    this.numpageParkedFOR.push(this.numpageParked[this.numpageParked.length-5])
                    this.numpageParkedFOR.push(this.numpageParked[this.numpageParked.length-4])
                  }

                  this.numpageParkedFOR.push(this.numpageParked[this.numpageParked.length-3])
                  this.numpageParkedFOR.push(this.numpageParked[this.numpageParked.length-2])
                  this.numpageParkedFOR.push(this.numpageParked[this.numpageParked.length-1])
                // }
              }else{
                this.numpageParkedFOR = this.numpageParked;
              }            
          }

      
    }else{
      this.salesorders = this.salesordersfiltered
      if(this.role != 'Sales Person' && this.salesorders.length == 0 ){
        if(this.show == 'Processed'){
          this.msgData = "You don't have sales order Processed yet";
        }else if(this.show == 'Parked'){
          this.msgData = "You don't have sales order Parked";
        }
        return;
      }
    }
 
  }

  nextShowAlert(){          
    this.salesService.normalOrAdvsearch.next('')
    this.salesService.sofrom.next('landing')  
    this.authService.showunsavedSO.next('Yes')
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Add New SO',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }

  previewReport(salesorder: any) {
    this.openPreview(salesorder);
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Preview SO(print)',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }

  async openPreview(salesorder) {
    let wait = await this.globals.loadScript();
    if(wait){
      const initialState = {
        salesOrderMode: true,
        so: salesorder,
        sponsor:salesorder['sponsor'],
        fromcomponent:"salespersonlist"
      };
      this.bsModalRef = this.modalService.show(InvoicereportComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true});
    }
  }

  showSalesOrder(salesorder: any) {
    
    this.salesService.sofrom.next('landing')
    this.eventService.touchchooseevent.next(false);
    this.salesService.editSalesOrder.next(salesorder);
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Show SO',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
    this.router.navigate(['/salesorder/' + salesorder['SOID']+'/edit']);
  }
  
  showSosList(id:any){ 

    if(!this.showListSos){
    this.loadSalesOrder(2);
    setTimeout(() => {
      // let el = document.getElementById(id);
      // window.scroll({top: el.offsetTop, behavior: 'smooth'});           
    }, 1000);
    }else{
      this.showListSos = false
      this.salesorders = []
    }
  }

  shearchSosList(){     
    this.salesService.normalOrAdvsearch.next('normal');
    this.salesService.normalsearch.next(this.searchString);
    this.npagbasicPark = 1
    this.npagbasicProcessed = 1
    this.salesService.eventsearch.next(this.event);
    
    if(this.searchString == ''){
    this.loadSalesOrder('clear'); 
    }else{      
    this.pagination.currentPage = 1
    this.loadSalesOrder('search'); 
    }
  }

  clearsearch(){
    this.clearAdvanceArrays();
    this.showtabsearch = 'Basic'
    this.salesService.normalOrAdvsearch.next('')
    this.salesService.eventsearch.next('')
    this.salesService.normalsearch.next('')
    this.salesService.advancesearchform.next('')
    this.loadSalesOrder('clear'); 
    this.showsearchbar = false   
  }


 async emailReport(salesOrder) {

   let wait = await this.globals.loadScript(); 
   if(wait){
    
  
    let online = await isOnline();

    this.emailLoading = true;
    this.emailSalesOrder = salesOrder['SOID'];
    // if (this.reportService.dataStream.getValue() != null) {
    this.reportService.dataStream.next('');
    // }
    this.reportService.getInvoice(salesOrder['SOID'])
    .pipe(take(1)).
    //.takeUntil(componentDestroyed(this))
    subscribe(
      (res) => {
        if (res !== '') {
          const model = {};
          let stringTeams:any = 'All Teams  ';//2 space for the slice
          
          if(salesOrder['fundraiser'] != '' && salesOrder['fundraiser'] != null){
            stringTeams = '';
            JSON.parse(salesOrder['fundraiser']).forEach(element => {            
              stringTeams = stringTeams+element.team+', ';             
            });
          }
          
          this.templateMeSvc.getTemplateMesSection('Email For Sales Order')
          .toPromise()
          .then((result) => {          
            
            var Obj = {
              "<<OrderNumber>>": salesOrder['SONumber'],
              "<<FirstName>>": salesOrder['firstName'],
              "<<LastName>>": salesOrder['lastName'],
              "<<Sponsor>>": salesOrder['sponsor'],
              "<<Teams>>": stringTeams.slice(0,-2),        
              "<<Dealer>>": this.authService.decodeToken('dealername').dealername,

            };
            let subject:any = '';
            let msg:any = '';

            if (result[0].messages != '' && result[0].messages != null && result[0].messages != undefined) {
              if(JSON.parse(result[0].messages).length > 0){              
                JSON.parse(result[0].messages).forEach(element => {
                  if(element.title.toLowerCase() == 'subject'){
                    subject = element.content.replace(/<<OrderNumber>>|<<FirstName>>|<<LastName>>|<<Sponsor>>|<<Teams>>|<<Dealer>>/gi, function(matched){
                      return Obj[matched];
                    });
                  }else if(element.title.toLowerCase() == 'message'){
                    msg = element.content.replace(/<<OrderNumber>>|<<FirstName>>|<<LastName>>|<<Sponsor>>|<<Teams>>|<<Dealer>>/gi, function(matched){
                      return Obj[matched];
                    });
                  } 
                });
              }
            }

            if (this.reportService.roughSizeOfObject(res) > 0) {
              // if (this.reportService.roughSizeOfObject(this.reportService.dataStream.getValue()) > 0) {
              if(subject == ''){
                subject = 'Receipt for Order#' + salesOrder['SONumber'];
              }
              if(msg == ''){
                msg = 'Please find attached Order#' + salesOrder['SONumber'];
              }  
              
              //new array notifications
              let notifications:any = {};
              if(salesOrder['notifications'] != null && salesOrder['notifications'] != undefined && salesOrder['notifications'] != ""){
                notifications = JSON.parse(salesOrder['notifications'])

                if(JSON.parse(salesOrder['notifications']).emailsent != undefined){
                  if(notifications.emailsent.length == 0){
                    notifications.emailsent = [{"emailSentAt":""+(this.globals.dNow())+""}];
                  }else{
                    notifications.emailsent.push({"emailSentAt":""+(this.globals.dNow())+""});                 
                  }
                }else{                  
                  notifications.emailsent = [{"emailSentAt":""+(this.globals.dNow())+""}];
                }     
              }else{
                notifications.emailsent =  [{"emailSentAt":""+(this.globals.dNow())+""}];
              }

              model['email'] = salesOrder['email'];
              model['subject'] = subject;
              model['message'] = msg;
              model['SOID'] = salesOrder['SOID'];
              model['notifications'] = JSON.stringify(notifications);
              model['fromcomponent'] = "salespersonlist";
              // model['attachmentbase64'] =  this.reportService.dataStream.getValue();
              model['attachmentbase64'] =  res;
              model['attachmentfiletype'] =  'application/pdf';
              model['attachmentfilename'] = 'Order ' + salesOrder['SONumber'] + '.pdf';
  
              const initialState = {
                model: model
              };
              if (this.modalService.getModalsCount() === 0 && model['attachmentbase64']) { 
                
                
                  if(online){                  
                    this.emailLoading = false;
                    this.emailSalesOrder = '';    
                    this.bsModalRef = this.modalService.show(SendemailComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true, backdrop : false});
                  }else{
                    if(environment.apiUrl == 'http://aedpay.api/api/'){ 
                      model['typeEmail'] = "Order";
                      model['SOID'] = salesOrder['SOID'];
                      model['eventId'] = salesOrder['eventId'];
                      const initialState = {
                        model: model
                      };          
                      this.emailLoading = false;
                      this.emailSalesOrder = '';         
                      this.bsModalRef = this.modalService.show(SendemailLocalComponent, {initialState, class: 'modal-sm', ignoreBackdropClick: true, backdrop : false});
                    }
                  }     
                  
                  




                  
              }
            }

          }).catch((err) => {
            // console.log(err);
          });
      }
    }, (error) => {
      this.alertify.error('There was an error');
      this.emailLoading = false;
      this.emailSalesOrder = '';
    }
    
    );

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Email SO',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }
  }

  selectSalesOrder(salesorder: any) { 

    this.salesService.sofrom.next('landing')
    this.eventService.touchchooseevent.next(false);        
  
    this.salesService.editSalesOrder.next(salesorder);

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Edit SO',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
    this.router.navigate(['/salesorder/' + salesorder['SOID']]);
  }


  // SALES ORDERS AUTO SAVED
  selectSalesOrderAutoSaved(id: any) {
    this.salesService.sofrom.next('landing')    
    this.eventService.touchchooseevent.next(false);
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Edit SO auto saved',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});

    // console.log(id)
    this.salesService.idautosaved.next(id)    
    this.salesService.fromautosavedlist.next(true) 
    this.router.navigate(['/salesorder/add']);  
  }

  
  refreshSO(){
  this.loadSalesOrder(-1) 
  }

  deleteSOAutoSaved(id){  
    
    this.alertify.confirm('Are you sure to delete this order ',()=>{
 
    this.salesService.deleteSOsAutoSaved(id)
    .pipe(takeUntil(this.destroy$)) 
    .subscribe(
      (res)=>{
       let newfilter = this.salesordersAutoSave.filter(x => x['id'] != id)
       this.salesordersAutoSave = newfilter

    },
    (err)=>{console.log(err)}
    )
   }
   ,()=>{
   })
  }

  getSOsAutoSaved(){

    let spName = JSON.parse(this.authService.decodeToken('user').user).name  
    let spEmail = JSON.parse(this.authService.decodeToken('user').user).userName 
    if(spEmail == undefined){
      spEmail = JSON.parse(this.authService.decodeToken('user').user).username
    }
    let userjson = {
      "name": spName,
      "username":spEmail
    }
    // this.salesService.getSOsAutoSaved(userjson)
    // .toPromise()
    // .then((res)=>{
    //   this.salesordersAutoSave = res
    //   // console.log(this.salesordersAutoSave)
    // })
    // .catch((err)=>{console.log(err)})
  }

  async logout() {
    
    this.yo = true;
  
      if(  this.router.url.slice(0, 12) == '/salesorder'  ||  this.router.url.slice(0, 12)  =='/storeconfig'){
      
        await new Promise((resolve,reject)=>{
                          
                this.alertify.confirm("Are you sure you want to logout?",
                   ()=>{
                      resolve(this.yo = true) 
                   }
                   ,()=>{
                   }
                );     
  
          setTimeout(() => {  
             reject(this.yo = false) 
          }, 10000)    
    });
  
      }else{
        this.yo = true
      }
  
  
      // let data = {
      //   userId: '123',
      //   userName: this.authService.decodeToken('username').username,
      //   section: 'Nav',
      //   action: 'logout',
      //   eventId: '*',
      //   device: localStorage.getItem('device'),
      // }
      // this.userService.logTracking(data).subscribe(a => {
      //   // console.log(a)
      // });
  
    if(this.yo){
      this.authService.showunsavedSO.next('No')
      this.authService.showunsavedSett.next('No')
     localStorage.removeItem('auth_token');
     localStorage.removeItem('isCashier');
     localStorage.removeItem('products');
      this.authService.decodedToken = null;
      this.authService.currentUser = null;
      this.alertify.message('logged out');
      this.modalService.hide()
      this.bsModalRef.hide()
      // this.salesService.showSidebar.next(true)
      // this.router.navigate(['/login']);
      window.location.href =environment.linkLogin;  

      }    
  
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    document.getElementById('mainWithSideBar').style.backgroundColor = "#f0f3f4"
    document.getElementById('bodymainhtml').style.backgroundColor = "#F0F3F4";    
  }

}
