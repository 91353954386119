import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ProductTypeService } from 'src/app/_services/product-type.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GlobalsService } from 'src/app/_services/globals.service';
import { take, takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-product-type',
  templateUrl: './product-type.component.html',
  styleUrls: ['./product-type.component.scss']
})
export class ProductTypeComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  modalRef: BsModalRef;
  productTypes: any[];
  p: number = 1;
  editType: any = null;
  type: FormGroup;
  showModal:boolean = false
  @ViewChild('popTemplate', { static: false }) popTemplateiOs: TemplateRef<any>;

  constructor(
    private router: Router,
    private productTypeService: ProductTypeService,
    private fb: FormBuilder,
    private modalService: BsModalService,
    private bsModalRef: BsModalRef,
    private globals: GlobalsService,
    private alertify: AlertifyService,
		private translate: TranslateService,

  ) { }

  ngOnInit(): void {
   
    this.getProductTypes()

    this.type = this.fb.group({
      productType: ['', [Validators.required]],
      taxable: [0, []],
      taxNumber: [0, []],
      uuid: ['', []]
    })
  }

  gotoStockItems() {
    this.router.navigate(['stockitems'])
  }

  getProductTypes() {
    this.productTypeService.getTypes()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data: any)=>{
        this.productTypes = data;
      //console.log(this.productTypes);
    })
  }

  openModal(template: TemplateRef<any>) {
    this.resetForm();
		this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
    this.showModal = true
	}

  closeModal(noShow?:boolean) {
		this.modalRef.hide();
    this.editType = null;
		this.resetForm();
    this.showModal = false
	}

  saveType() {
    if (this.type.valid) {
			this.type.controls['productType'].setValue(this.type.get('productType').value);
			this.type.controls['taxable'].setValue(this.type.get('taxable').value);
			this.type.controls['taxNumber'].setValue(this.type.get('taxNumber').value);
			this.type.controls['uuid'].setValue(this.globals.guid());

      if (this.type.get('taxable').value != 1){
        this.type.controls['taxable'].setValue(0)
      }

			this.productTypeService
				.addType(this.type.value)
				.pipe(take(1))
				.subscribe(
					() => {
						this.resetForm();
						this.alertify.success(this.translate.instant('PRODUCT_TYPE_ADDED'));
            this.getProductTypes();
						this.closeModal();
					},
					(error) => {
						this.alertify.error(error);
					}
				);
		}
    this.productTypes
  }

  editProductType(type: any, tem?: TemplateRef<any>, add?:any) {
    this.editType = type
		this.type.controls['productType'].setValue(type.productType);
    this.type.controls['taxable'].setValue(type.taxable);
    this.type.controls['taxNumber'].setValue(type.taxNumber);
    this.type.controls['uuid'].setValue(type.uuid);
		this.modalRef = this.modalService.show(tem, { class: 'modal-lg', ignoreBackdropClick: true });		
    this.showModal = true
	}

  updateType() {
    if (this.type.valid) {
			this.type.controls['productType'].patchValue(this.type.get('productType').value);
			this.type.controls['taxable'].patchValue(this.type.get('taxable').value);
			this.type.controls['taxNumber'].patchValue(this.type.get('taxNumber').value);

      if (this.type.get('taxable').value != 1){
        this.type.controls['taxable'].patchValue(0)
      }

      this.productTypeService.updateType(this.type.get('uuid').value, this.type.value)
      .pipe(takeUntil(this.destroy$))
				.subscribe(
					(res: any) => {
						this.alertify.success(this.translate.instant('PRODUCT_TYPE_UPDATED'));
						this.modalRef.hide();
						this.editType = null;
						this.resetForm();
						this.getProductTypes();
					},
					(error) => {
						this.alertify.error(error);
					}
				);
    }
  }

  deleteType(id?: string) {
		let msg = this.translate.instant('DELETE_PRODUCT_TYPE_CONFIRMATION')

		this.alertify.confirm(
			msg,
			() => {
				this.productTypeService.deleteType(id)
					.pipe(takeUntil(this.destroy$))
					.subscribe(
						(res) => {
              if (res == 405){
                this.alertify.error(this.translate.instant('CANNOT_DELETE_PRODUCT_TYPE'));
              } else {
                this.alertify.success(this.translate.instant('PRODUCT_TYPE_DELETED'));
              }
							this.getProductTypes()
						},
						(error) => {
							this.alertify.error(error);
						}
					);
			},
			() => {}
		);
	}

  resetForm() {
    this.type.controls['productType'].setValue('');
    this.type.controls['taxable'].setValue('');
    this.type.controls['taxNumber'].setValue('');
    this.type.controls['uuid'].setValue('');
	}

}
