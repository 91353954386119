<table id="salestable" class="table table-hover table-sm  z-depth-2" cellspacing="0">
  <thead class="bg-primary text-white">
  <tr (click)="search()" btnRadioGroup [(ngModel)]="heads" class="p-0 m-0 rounded">
      <th class="" uncheckable (click)="changeDirection(head)" btnRadio="head"  *ngFor="let head of headers"
      tabindex="0" role="button">{{head}}
      <label  ><i *ngIf="orderDir=='' && orderField=='SONumber'" class="fa fa-arrow-up"></i>
        <i *ngIf="orderDir=='desc' && orderField=='SONumber'" class="fa fa-arrow-down"></i>
      </label>
    </th>
    </tr>
</thead>
<tbody>
<tr class="table-light z-depth-1 m-1 p-1"
(click)="selectSalesOrder(salesorder)" *ngFor="let dat of data; let i = index">
<td *ngFor="let da of dat[i]" >{{da}}</td>
</tr>
</tbody>
</table>
