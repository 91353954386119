import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-dashcount',
  templateUrl: './dashcount.component.html',
  styleUrls: ['./dashcount.component.scss']
})
export class DashcountComponent implements OnInit {
@Input() text = '';
@Input() color = 'blue';
@Input() quantity = '';
@Input() icon = '';
@Input() type = '$';
@Input() size = 'normal';
@Input() duration = 1;
@Input() for = '';
@Input() array = [];
counto = 0;
  constructor() { }

  ngOnInit() {
  }

}
