import { Component, OnInit, OnDestroy } from '@angular/core';
import { DevicesService } from 'src/app/_services/devices.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { AdddevicesComponent } from './adddevices/adddevices.component';
import { AuthService } from 'src/app/_services/auth.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-devices',
  templateUrl: './devices.component.html',
  styleUrls: ['./devices.component.scss']
})
export class DevicesComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  devices = [];
  step = 0;
  chooseDevice = false;
  constructor(
    private devicesService: DevicesService, private alertify: AlertifyService,
    private globals: GlobalsService,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private authService: AuthService,
		private translate: TranslateService
    ) { }

  ngOnInit() {
    this.loadDevices();

    this.devicesService.editDevice.subscribe(
      (res) => {
        this.loadDevices();
      });


  }

  loadDevices() {
    this.devicesService.getDevices(1, 100, '')
    .pipe(takeUntil(this.destroy$))   
    .subscribe(
      (res: any) => {
        this.devices = res.result;
    }, error => {
      this.alertify.error(error);
    });
  }



  addDevice() {
    const initialState = {
      device: '',
      devices: this.devices
    };
    this.bsModalRef = this.modalService.show(AdddevicesComponent, {initialState, class: ''});
  }


  deleteDevice(device) {

    this.alertify.confirm(this.translate.instant('DELETE_DEVICE_CONFIRMATION') + device['Label_tx'], () => {
    this.devicesService.deleteDevice(device['yy_DeviceID_tx'])
    .pipe(takeUntil(this.destroy$))   
    .subscribe(() => {
      this.alertify.success('Device Deleted');
      this.loadDevices();
    }, error => {
        this.alertify.error(error);
    });
  }
  ,()=>{
  });

}

  selectDevice(device) {
    localStorage.setItem('BTDevice', device);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();    
  }

}
