import { Component, OnInit, OnDestroy, NgZone, Input } from '@angular/core';
import { TransactionpaymentsService } from 'src/app/_services/transactionpayments.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { CardReaderService } from 'src/app/_services/cardreader.service';
import { DevicesService } from 'src/app/_services/devices.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CardsignatureComponent } from './cardsignature/cardsignature.component';
import { CardpinpadComponent } from './cardpinpad/cardpinpad.component';
import { AuthService } from 'src/app/_services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
//import { ElectronService } from 'ngx-electron'; 
declare let electron: any;
@Component({
  selector: 'app-cardpayment',
  templateUrl: './cardpayment.component.html',
  styleUrls: ['./cardpayment.component.scss']
})
export class CardpaymentComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  cardno = '';
  lastfour = '';
  expmonth = '';
  expyear = '';
  cardname = '';
  cardexpdate = '';
  cardType = 1;
  @Input() amount = 1;
  respose: any;
  payment = {};
  @Input() sopayment = {};
  mac = '';
  deviceid = '';
  devicemac = '';
  macresponse:any ;
  authcode: any;
  paymentstatus: any;
  readerStatus: any;
  responseerror: any;
  validateMessage = '';
  cardDate: any;
  todayDate: any;
  removeCard = false;
  user = {};
  testuser = 0;

  constructor(
    private tpayments: TransactionpaymentsService,
    private globals: GlobalsService,
    public cardReaderService: CardReaderService,
    private devicesService: DevicesService,
    private _ngZone: NgZone,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    //private _electronService: ElectronService,
    public authService: AuthService,
    private alertify: AlertifyService) {
  }

  getDeviceID(deviceid, macreader) {
    this.mac = macreader;
    this.deviceid = deviceid;

  }

  setCreditCard(cardNo, cardName, cardExp) {
    this.cardno = cardNo;
    this.cardname = cardName;
    this.cardexpdate = cardExp;
  }

  ngOnInit() {
    
    if (this.authService.currentUser['userName'] === 'marco' &&
    this.authService.decodedToken('DID').DID === 'ROID1000') {
      this.testuser = 1;
    }
    
    


    this.cardReaderService.setDeviceID();
    this.cardReaderService.cardName.next('');
    this.cardReaderService.cardNo.next('');
    this.cardReaderService.cardExp.next('');
    this.cardno = '';
    this.cardname = '';
    this.cardexpdate = '';
    this.amount = this.sopayment['paymentAmount'];
   //this.cardReaderService.deviceid.next('aaaa');
    this.cardReaderService.deviceid
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (res: any) => {
        if (res.length > 0) {
          if (!res.includes('null')) {
          this.deviceid = res;
          this.connect();
          }
        }
      }, error => {
        this.alertify.error(error);
      });

      this.cardReaderService.deviceStatus
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
            this.readerStatus = res;
        }, error => {
          this.alertify.error(error);
        });


        this.cardReaderService.cardExp
        .pipe(takeUntil(this.destroy$))   
        .subscribe(
          (res: any) => {
            this.validateMessage = '';
            if (this.cardReaderService.cardName.value != null) {

            this.cardname = this.cardReaderService.cardName.value.toString();
            this.cardno = this.cardReaderService.cardNo.value.toString();
            if (this.cardno.length > 4) {
            this.lastfour = this.cardno.substr(this.cardno.length - 4);
            } else {
              this.lastfour = '';
            }
            this.cardexpdate = res;
            this.expmonth = this.cardexpdate.substr(this.cardexpdate.length - 2);
            this.expyear = this.cardexpdate.substr(0,2);

            this.todayDate = new Date();
            this.cardDate = new Date();
            let fullyear = (parseInt(this.expyear) < 90) ? 2000 + parseInt(this.expyear) : 1900 
            + parseInt(this.expyear);
            this.cardDate.setFullYear( fullyear, parseInt(this.expmonth) , 0);
            if (this.cardDate < this.todayDate) {
              this.validateMessage = 'Card has already expired..';
            } else {
              this.validateMessage = '';
            }

            if (/\s/.test(this.cardname)) {
            } else {
              if (this.cardno.length > 0) {
                this.validateMessage = 'There has been an error. Please try again...';
              }
            }


          }

        }, error => {
          this.alertify.error(error);
        });


  }

  loadElectron() {
    
    
  //   this._electronService.ipcRenderer.on('pong', (event, arg) => {
  //     console.log(
  //         'RECEIVED RESPONSE FROM ELECTRON TO ANGULAR APP',
  //         event,
  //         arg
  //     );
  // });
  // this._electronService.ipcRenderer.send(
  //   'ping',
  //   'wow wow wow');

  }

/* synchronous messaging (blocks execution process till
     a response is received - use this with caution)  */

  //prints "pong"

  loadDemoData() {
    
    this.cardReaderService.cardName.next('MARCO SOMOZA PINEDA');
    this.cardReaderService.cardNo.next('4565419325239482');
    this.cardReaderService.cardExp.next('2009');
    this.cardno = '4565419325239482';
    this.cardname = 'MARCO SOMOZA PINEDA';
    this.cardexpdate = '2009';
    this.amount = 1;
  }

  processPayment() {

    this.payment['yy_TransactionPayment_tx'] = this.globals.guid();
    this.payment['foreignSOID'] = this.sopayment['foreignSOID'];
    this.payment['cardType'] = this.cardType;
    this.payment['AccountName_tx'] = (document.getElementById('cardname') as HTMLInputElement).value;
    this.payment['AccountNumber_tx'] = (document.getElementById('cardno')  as HTMLInputElement).value;
    this.payment['PaymentAmount_num'] = this.amount;
    this.payment['AccountExpDateMonth_tx'] = this.cardexpdate.substr(2, 2) +  this.cardexpdate.substr(0, 2);
    this.payment['AccountExpDateYear_tx'] = this.cardexpdate;
    this.tpayments.addTransactionPayment(this.payment)
    .pipe(takeUntil(this.destroy$))   
    .subscribe(
      (res: any) => {
        this.respose = res;
        if (res.request.error ) {
          this.responseerror =JSON.parse(res.request).error;
          this.alertify.error(this.responseerror);
          return;
        } 
        if (this.respose.errors) {
          this.responseerror =JSON.parse(this.respose.errors);
          this.alertify.error(this.responseerror);
          return;
        }
        this.respose = JSON.parse(res.request).transaction;
        this.cardReaderService.transactionResponse.next(this.respose);
        this.cardReaderService.accountType.next(this.respose['account_type']);
        this.cardReaderService.cardAuthCode.next(this.respose['auth_code']);
        this.cardReaderService.cardAuthStatus.next(this.respose['status_id']);
        if (this.respose['status_id'] == '101') {
          this.paymentstatus = 'Approved';
          this.authcode = this.respose['auth_code'];
          //this.signature();
          this.sopayment['authCode'] = this.authcode;
          this.sopayment['cardType'] = this.respose['account_type'];
          this.sopayment['cardDateSubmitted'] = this.respose['created_ts'];
          this.sopayment['CardTransactionType_tx'] = this.respose['payment_mehtod'];
          this.sopayment['cardLastFour'] = this.respose['last_four'];
          this.sopayment['transactionPayment'] = this.payment['yy_TransactionPayment_tx'];
          //this.bsModalRef.hide();
          this.cardReaderService.soPayment.next(this.sopayment);
          this.removeCard = true;
          
        } else {
          this.paymentstatus = 'Denied';
          this.cardReaderService.cardError.next(this.responseerror);
          this.removeCard = false;
        }
      }, error => {
        this.alertify.error(error);
        this.responseerror = error;
      });
  }

  ngOnDestroy(): void {    
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
      this.cardReaderService.deviceDisconnect();
      this.cardReaderService.cardName.next(null);
      this.cardReaderService.cardNo.next(null);
      this.cardReaderService.cardExp.next(null);
      this.cardno = null;
      this.cardname = null;
      this.cardexpdate = null;
      this.deviceid = null;
      this.devicemac = null;
      this.cardReaderService.devicemac.next('');
      this.cardReaderService.deviceid.next('');
  }

  getCardStatus() {

  }
  manual() {
  }

  connect() {
  this.devicesService.getDevice(this.deviceid)
  .pipe(takeUntil(this.destroy$))   
  .subscribe(
    (res2: any) => {
      this.macresponse = res2;
      if(this.deviceid.length > 0) {
        if (res2[0]) {
          this.devicemac = res2[0]['MacReader_tx'];
          this.cardReaderService.readCard(this.devicemac);
        }
      }
    }, error => {
      this.alertify.error(error);
    });

  }

  addData() {
  }

  signature() {
    const initialState = {
      cardPayment: this.payment
    };
    this.bsModalRef = this.modalService.show(CardsignatureComponent, {initialState, class: 'modal-xl'});
  }

  pinpad() {
    const initialState = {
      cardPayment: this.payment
    };
    this.bsModalRef = this.modalService.show(CardpinpadComponent, {initialState, class: ''});
  }

  retry() {
    this.cardReaderService.setDeviceID();
    this.cardReaderService.cardName.next('');
    this.cardReaderService.cardNo.next('');
    this.cardReaderService.cardExp.next('');
    this.cardno = '';
    this.cardname = '';
    this.cardexpdate = '';
  }

  cancelForm() {
     if (this.bsModalRef !== null) {
       this.bsModalRef.hide();
     }
   //  debugger
   // this.modalService.hide(this.modalService.getModalsCount());
  }

}

