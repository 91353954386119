import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ReportsService } from 'src/app/_services/reports.service';
import 'rxjs/add/operator/takeUntil';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { NrnValidators } from 'src/app/nrn.validators';
import { SettingsService } from 'src/app/_services/settings.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sendemail',
  templateUrl: './sendemail.component.html',
  styleUrls: ['./sendemail.component.scss']
})
export class SendemailComponent implements OnInit, OnDestroy   {
  @ViewChild('attachmentlink', {static: false}) atlink: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();
  size = 0;
  form = new FormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldGroup: [

        {
          key: 'email',
            type: 'input',
            className: 'col-md-12',
            templateOptions: {
              type: 'text',
              placeholder: 'Email',
              label: 'Email',
              required: true,
              pattern: NrnValidators.FORMATS.email
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Email`,
              },
            },
        },
{
  key: 'subject',
    type: 'input',
    className: 'col-md-12',
    templateOptions: {
      type: 'email',
      label: 'Subject',
      placeholder: '',
      require: true
    }

},
{
  key: 'message',
  type: 'textarea',
  className: 'col-md-12',
  templateOptions: {
    label: 'Message',
    placeholder: '',
    rows: 4,
  },
}
  ]},
 ];
  constructor(private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private reportService: ReportsService,
    private alertify: AlertifyService,
    private salesService: SalesOrderService,
    private settingsService: SettingsService,
    private reportsService: ReportsService
    ) {

  }

  ngOnInit() {


    this.settingsService.getSettings(0)
    .pipe(takeUntil(this.destroy$),debounceTime(500))   
    .subscribe( data => {
      const settings = data[0];
      if (settings) {
        this.model['from'] = (settings['username']);
        this.model['store'] = (settings['companyName']);
        this.model['name'] = (settings['companyName']);
        this.loadAttach();
      }
    }, error => {
      this.alertify.error(error);
    });
      

  }
loadAttach(){
      const linkSource = 'data:application/pdf;base64,' + this.model['attachmentbase64'];
    const downloadLink = document.getElementById('attachmentlink');
    const fileName = this.model['attachmentfilename'];
    this.atlink.nativeElement.href = linkSource;
    this.atlink.nativeElement.download = fileName;
    this.atlink.nativeElement.innerHTML = fileName;
    this.size = this.reportService.roughSizeOfObject(this.model['attachmentbase64']);
}
  submit(model) {

    this.reportService.emailReport(model)
    .pipe(takeUntil(this.destroy$))   
    .subscribe(res => {
      this.alertify.success('The Email was sent');
      this.reportService.dataStream.next('');
      
      // console.log(model['fromcomponent']);
      

      //To update email sent status
      if(model['fromcomponent'] == 'salespersonlist'){
        this.reportService.SOIDemailsent.next({"SOID":model['SOID'],"notifications":model['notifications']});
      }
      if(model['fromcomponent'] == 'salesordermobile' || model['fromcomponent'] == 'salesorderdesktop'){
        this.reportService.SOIDemailsentSo.next({"SOID":model['SOID'],"notifications":model['notifications']});
        this.reportService.loadNotitemsMobile.next('load')
      }
      if ( model['fromcomponent'] == 'salesordersummary') {
        this.reportService.SOIDemailsentSo.next({"SOID":model['SOID'],"notifications":model['notifications']});
      }

      this.bsModalRef.hide();
    }, error => {
      this.alertify.error('The email could not be sent. Please review that the email is valid and try again later.');
    });
    //
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  cancelForm() {
    this.bsModalRef.hide();
  }

}
