import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';
import { PaginatedResult } from '../_modules/pagination';



@Injectable({
  providedIn: 'root'
})
export class SponsorContributionService {
  baseUrl = environment.apiUrl;
  laravel = environment.apiLaravel;
  salesOrderEvent = new BehaviorSubject<string>('0');
  editEvent = new BehaviorSubject<string>('0');

constructor(private http: HttpClient) { }

getSponsorContributions(event?) {
  let params = new HttpParams();
  if (event != null) {
    params = params.append('event', event);
  }

   return this.http.get<any []>(this.laravel + 'sponsorscontributions', {observe: 'response', params})
   .pipe(
    map( response => {

      return response.body;
    })
  );
  }

  getSponsorContribution(id): Observable<any> {
          return this.http.get<any>(this.laravel + 'sponsorscontributions/' + id);
  }



  addSponsorContribution(sponsorcontribution: any) {
    return this.http.post(this.laravel + 'sponsorscontributions', sponsorcontribution);
  }

  updateSponsorContribution(id,sponsorcontribution: any) {
    return this.http.patch(this.laravel + 'sponsorscontributions/' + id, sponsorcontribution);
  }

  deleteSponsorContribution(id) {
    return this.http.delete(this.laravel + 'sponsorscontributions/' + id);
  }

  getCommissions(eventId){
    return this.http.get(this.laravel + 'getCommissionsDefaults/'+eventId);
  }

  saveCommissions(data){
    return this.http.post(this.laravel + 'saveCommissionsDefaults',data);
  }

}
