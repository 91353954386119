import { Injectable } from '@angular/core';
import { ReplaySubject } from 'rxjs';
import { SettingsService } from './settings.service';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {
  private currency = new ReplaySubject<string>(1);
  public currentCurrencyValue: string = 'USD'; 
  currentCurrency = this.currency.asObservable();

  constructor(
    private settingsService: SettingsService,
  ) { 
    this.initCurrency();
  }

  initCurrency() {
    const storedCurrency = localStorage.getItem('currency');
    if (storedCurrency) {
      this.changeCurrency(storedCurrency);
    } else {
      this.settingsService.getCurrency().subscribe((data: any) => {
        if (data.currency != null && data.currency != undefined && data.currency != '')  {
          this.changeCurrency(data.currency);
        } else {
          this.changeCurrency('USD');
        }
      });
    }
  }

  changeCurrency(currency: string) {
    this.currency.next(currency);
    this.currentCurrencyValue = currency;
    this.getCurrencySymbol();
    localStorage.setItem('currency', currency);
  }

  getCurrencySymbol(): string {
    switch (this.currentCurrencyValue) {
      case 'USD':
        return '$';
      case 'HNL':
        return 'L.';
      default:
        return '';
    }
  }
}