import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';
import { PaginatedResult } from '../_modules/pagination';



@Injectable({
  providedIn: 'root'
})
export class StoreUsersService {
  baseUrl = environment.apiUrl;
  laravel = environment.apiLaravel;
  salesOrderEvent = new BehaviorSubject<number>(0);
  editStoreUser = new BehaviorSubject<string>('');

  editStoreUser$ = this.editStoreUser.asObservable();

  confirmEditedUser(storeUser) {
    this.editStoreUser.next(storeUser);
  }

constructor(private http: HttpClient) { }

getStoreUsers(page?, itemsPerPage?, searchStoreUser?) {
  let params = new HttpParams();
  const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();

  if ( page != null && itemsPerPage != null) {
    params = params.append('page', page);
    params = params.append('per_page', itemsPerPage);

  }

  if (searchStoreUser != null) {
    params = params.append('searchStoreUser', searchStoreUser);
  }

  return this.http.get<any>(this.laravel + 'storeusers', {observe: 'response', params})
  .pipe(
    map( response => {
      paginatedResult.result = (response.body.data);
      if (response.body.total != null) {
        paginatedResult.pagination = {
          totalItems: JSON.parse(response.body.total),
          totalPages: JSON.parse(response.body.last_page),
          currentPage: JSON.parse(response.body.current_page),
          itemsPerPage: JSON.parse(response.body.per_page)
        };
      }
      return paginatedResult;
    })
  );
  }

  getStoreUsersSpecific(page?, itemsPerPage?, searchStoreUser?, specificFields?:any) {
    let params = new HttpParams();
    const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();
  
    if ( page != null && itemsPerPage != null) {
      params = params.append('page', page);
      params = params.append('per_page', itemsPerPage);
  
    }
  
    if (searchStoreUser != null) {
      params = params.append('searchStoreUser', searchStoreUser);
    }
  
    return this.http.post<any>(this.laravel + 'getSpecificStoreUsers', specificFields, {observe: 'response', params})
    .pipe(
      map( response => {
        paginatedResult.result = (response.body.data);
        if (response.body.total != null) {
          paginatedResult.pagination = {
            totalItems: JSON.parse(response.body.total),
            totalPages: JSON.parse(response.body.last_page),
            currentPage: JSON.parse(response.body.current_page),
            itemsPerPage: JSON.parse(response.body.per_page)
          };
        }
        return paginatedResult;
      })
    );
    }

  getStoreUser(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'storeusers/' + id);
  }  
  
  getStoreUserDBOrigin(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'getStoreUserDBOrigin/' + id);
  }

  addStoreUser(storeuser: any) {
    return this.http.post(this.laravel + 'storeusers', storeuser);
  }

  updateStoreUser(id: number, storeuser: any) {
    return this.http.patch(this.laravel + 'storeusers/' + id, storeuser);
  }

  deleteStoreUser(id: number) {
    return this.http.delete(this.laravel + 'storeusers/' + id);
  }

  editedStoreUser(id: string) {
    this.editStoreUser.next(id);
  }





}
