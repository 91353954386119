import {
  Component,
  OnInit,
  IterableDiffers,
  Input,
  DoCheck,
  AfterContentInit,
  NgZone,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  OnDestroy,
  SimpleChanges,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { FormGroup, FormArray } from "@angular/forms";
import { FormlyFormOptions, FormlyFieldConfig } from "@ngx-formly/core";
import { AlertifyService } from "src/app/_services/alertify.service";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { GlobalsService } from "src/app/_services/globals.service";
import { EventsSMService } from "src/app/_services/eventssm.service";
import { ActivatedRoute } from "@angular/router";
import { NgLocalization } from "@angular/common";
import "rxjs/add/operator/takeUntil";
import { SettingsService } from "src/app/_services/settings.service";
import { take } from "rxjs/operators/take";
import { StoreproductsService } from "src/app/_services/storeproducts.service";
import { SponsorContributionService } from "src/app/_services/sponsorcontribution.service";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { UserService } from "src/app/_services/user.service";
import { AuthService } from "src/app/_services/auth.service";
import { HttpClient } from "@angular/common/http";
import { NrnValidators } from "src/app/nrn.validators";
import { SalesOrderService } from "src/app/_services/salesorder.service";
import { Address } from "src/app/_modules/address";
import { trim } from "jquery";

@Component({
  selector: "app-sponsorscontributions",
  templateUrl: "./sponsorscontributions.component.html",
  styleUrls: ["./sponsorscontributions.component.scss"],
})
export class SponsorscontributionsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  numberOfTicks = 0;
  @Input() eventid = "";
  @Input() results: any;
  @Input() datah;
  @Input() settings: any;
  username = "";
  password = "";
  productsSelect:any = [];  
  products:any;
  companiesandsizes: any;
  companies: any;
  itemLayoutDefault: any;
  floorItemsDefault: any;
  styles: any;
  sizes: any;
  show = 'Sponsors';
  changeDataWSA:boolean = true
  // show = 'Sponsors';
  // show = 'TemplateMessages';  
  iterableDiffer: any;
  myjson: any = JSON;
  model = {
    sponsorscontributions: [],
    taxes:[],    
    warehouseaddress: [] 
  };
  loadsponsorscontributions:any = false
  form = new FormGroup({});
  options:  FormlyFormOptions = {
    formState: {
      submitted : false,
    },
  };
  //fields: FormlyFieldConfig[] = [{}];
  globalsizes = [];
  ROID:any  = this.authService.decodeToken('DID').DID

  states:any = this.getStates()
  @ViewChild('popTemplateAddres', {static: false}) popTemplateAddres: TemplateRef<any>;
  validatedAddress: Address[];
  indexToCheck:any = 0;
  addresToCheck = {
    deliveryAddress1: '',
    deliveryAddress2: '',
    deliveryCity: '',
    deliveryState: '',
    deliveryZip:''
  }
  
  fields: FormlyFieldConfig[] = [
    {
      key: "sponsorscontributions",
      type: "repeatsmall",
      wrappers: ["formwrap"],
      templateOptions: { label: "" },
      fieldGroupClassName: "row ",
      fieldArray: {
        fieldGroupClassName: "row ",
        templateOptions: {
          btnText: "Add Payout",
          add: () => {},
          afterAdd: () => {},
          track: () => {},
          removePayment: () =>{}

        },

        fieldGroup: [
          {
            key: "product",
            type: "select",
            className: "col-md-2",
            templateOptions: {
              required: true,
              options: this.productsSelect,
              valueProp: "product",
              labelProp: "product",
            }      
          },

          {
            key: "size",
            type: "select",
            className: "col-md-3",
            defaultValue: "ALL SIZES",
            templateOptions: {
              options: [],
              valueProp: "sizeName",
              labelProp: "sizeName",
            },
            lifecycle: {
              onInit: (form, field) => {
                let sizes = Object.assign([], this.companiesandsizes);
            
                sizes.push({
                  product: form.get("product").value,
                  sizeName: "ALL SIZES",
                });
                sizes = sizes.filter(
                  (x) => x.product === form.get("product").value
                );
                
                sizes.forEach( element => {

                  
                  element['sizeName'] = element['sizeName'].toString().trim().toUpperCase();
                  
                });
                
                field.templateOptions.options = sizes;

                form.get("product").valueChanges.subscribe((v) => {
                  let sizes = Object.assign([], this.companiesandsizes);
                  sizes.push({
                    product: form.get("product").value,
                    sizeName: "ALL SIZES",
                  });
                  sizes = sizes.filter(
                    (x) => x.product === form.get("product").value
                  );

                  sizes.forEach(element => {
                    if (element['sizeName']) {
                    element['sizeName'] = element['sizeName'].toString().trim().toUpperCase();
                    }
                  });
                  field.templateOptions.options = sizes;
                  
                });
              },
            },
          },
          {
            key: "fromQtyNum",
            type: "input",
            className: "col-md-2",
            defaultValue: 1,
            templateOptions: {
              type: "number",
              required: true,
            },
          },

          {
            key: "toQtyNum",
            type: "input",
            className: "col-md-2",
            defaultValue: 1,
            hideExpression: (model, fomrstate) =>
              this.settings.sponsorByRanges === false,
            templateOptions: {
              type: "number",
              required: true,
            },
          },

          {
            key: "amountNum",
            type: "input",
            className: "col-md-2",
            defaultValue: 0,
            templateOptions: {
              type: "number",        
            },
          },
          // ,
          // {
          //   key: 'partialB',
          //     type: 'checkbox',
          //     className: 'col-md-1',
          //     defaultValue: 0
          //   }
        ],
      },
    },
  ];

  formTaxes = new FormGroup({});
  modelTaxes: any = {recyclePreTax: 0, removalPretax: 0, deliveryPreTax: 0, defaultTaxes: 0, defaultCounty: 0};
  fieldsTaxes: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'defaultTaxes',
            type: 'input',
            className: 'col-6',
            templateOptions: {
              label: 'Default Tax',
              type: 'number',
              placeholder: 'Set Tax Rate',
              max: 30,
              min: 0,
              require: true
            },
            validation: {
              show: true,
              messages: {
                max: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Tax Rate`,
                min: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Tax Rate the minimum is 0  `,
              },
            },
        },
        {
          key: 'defaultCounty',
          type: 'input',
          className: 'col-6',

          templateOptions: {
            label: 'Default County',
            require: true

          },
        },

// {
//   key: 'recyclePreTax',
//   type: 'checkbox',
//   className: 'col-md-3',

//   templateOptions: {
//     label: 'Recycle Taxable',
//     indeterminate: false

//   },
// },
// {
//   key: 'removalPretax',
//   type: 'checkbox',
//   className: 'col-md-3',

//   templateOptions: {
//     label: 'Removal Taxable',
//     indeterminate: false

//   },
// },


  ]},
 ];

 
 formwh = new FormGroup({});
 optionswh: FormlyFormOptions = {
  formState: {
    submitted : false,
  },
};
 fieldswh: FormlyFieldConfig[] = [
  {
    key: "warehouseaddress",
    type: "repeatsmall",
    wrappers: ["formwrap"],
    templateOptions: { label: "" },
    fieldGroupClassName: "row",
    
    fieldArray: {
      fieldGroupClassName: "row tw-py-5 ",
      templateOptions: {
        btnText: "Add WH Address",
        add: () => {},
        afterAdd: () => {}, 
        removePayment: () => {}, 
      },

      fieldGroup: [
        {
          key: "AddressWHName",
          type: "input",
          className: "col-md-4",
          templateOptions: {
            label: "Address Name",
            placeholder:"Address name",
            required: true,
          }, 
        },   
        {
          key: "AddressWH",
          type: "textarea",
          className: "col-md-3",
          hide: true,
          templateOptions: {
            placeholder:"WholeAddress",
            rows: 5,
            hidden: true//Show and hide main field 
          },         
        },   
        {
          key: "line1",
          type: "input",
          className: "col-md-4",
          templateOptions: {
            type: 'text',
            label: "Line 1",
            placeholder:"Address 1",
            required: true,
            change : (field, $event)=> this.setWholeAddress()
          },         
        },   
        {
          key: "line2",
          type: "input",
          className: "col-md-4",
          templateOptions: {
            type: 'text',
            label: "Line 2",
            placeholder:"Address 2",
            change : (field, $event)=> this.setWholeAddress()
          },         
        },

        {
          key: "city",
          type: "input",
          className: "col-md-4",
          templateOptions: {
            type: 'text',
            label: "City",
            placeholder:"City",
            required: true,
            change : (field, $event)=> this.setWholeAddress()

          },         
        }, 
        {
          key: 'region',
            type: 'select',
            className: 'col-md-4',
            templateOptions: {
              label: "State",
              type: 'text',
              required: true,
              // options: this.getStates(),
              options: this.states,
              valueProp: 'abbreviation',
              labelProp: 'name',
              change : (field, $event)=> this.setWholeAddress()

            }
        },   
        {
          key: 'postalCode',
            type: 'input',
            className: 'col-md-4',
            templateOptions: {
              type: 'text',
              label: "Zip",
              placeholder: 'Zip',
              maxLength: 5,
              minLength: 5,
              required: true,
              change : (field, $event)=> this.setWholeAddress()

            },
            validation: {
            messages: {
                minlength: (error, field: FormlyFieldConfig) => `Zip code must be ${error.requiredLength} digits.`
              },
            },
        },
        {
            key: 'phone',
            type: 'phone',
            className: 'col-md-4',
            templateOptions: {
              label: "Phone",
              // required: true,
              placeholder: 'Phone',
              pattern: NrnValidators.FORMATS.maskphone,
              change : (field, $event)=> this.setWholeAddress()
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Phone Number`,
              },
            },
        },  
        {
          key: "comment",
          type: "textarea",
          className: "col-md-4",
          templateOptions: {
            type: 'text',
            label: "Comment",
            change : (field, $event)=> this.setWholeAddress()
          },         
        },         
        {
          key: "default",
          type: "checkbox",
          hideExpression: (model, fomrstate) => this.model.warehouseaddress.length == 1, 
          className: "col-md-1",
          templateOptions: {
            label: "Default",
            change : (field, $event)=> this.changeDeafaultWA(field,$event)
          }      
        } 
      ],
    },
  },
];

changeDeafaultWA(field:any,e:any){
  if(e.target.checked){
    this.eventSMService.changeDefaultWHA.next(field.id) 
  }
}

  constructor(
    private alertify: AlertifyService,
    private bsModalRef: BsModalRef,
    private global: GlobalsService,
    private _iterableDiffers: IterableDiffers,
    private userService: UserService,
    private eventSMService: EventsSMService,
    private sponsorContributionService: SponsorContributionService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private storeProductsService: StoreproductsService,
    public zone: NgZone,
    private ref: ChangeDetectorRef,
    private authService: AuthService,
    private salesOrderService:SalesOrderService,    
    private modalService: BsModalService,
  ) {}

  ngOnInit() {

    
    this.eventSMService.indexDefaultWHA
    .pipe(takeUntil(this.destroy$))  
    .subscribe(
      (index:any)=>{
        if(index != ''){

          for (let i = 0; i < this.model.warehouseaddress.length; i++) {
            if(i != index){
              this.model.warehouseaddress[i].default = 0               
            } 
          }      
          setTimeout(() => {            
          this.changeDataWSA = false   
          }, 10);       
          setTimeout(() => {
            this.changeDataWSA = true
          }, 15);
          setTimeout(() => {
            this.eventSMService.indexDefaultWHA.next('');          
          }, 1000);
        }
      })

    this.eventSMService.changeAddressWHA
    .pipe(takeUntil(this.destroy$))  
    .subscribe(
      (res:any)=>{
        if(res != ''){
          //console.log(res);       
          this.validateAddress(JSON.parse(res))
          setTimeout(() => {
            this.eventSMService.changeAddressWHA.next('');          
          }, 1000);
        }
      })

    this.settingsService
      .getSettings(0)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (data) => {
          this.settings = data[0];
          this.username = data[0]["username"];
          this.password = data[0]["password"];
          if(data[0]["itemLayout"] != null){
            this.itemLayoutDefault = data[0]["itemLayout"]
          }else{
            this.itemLayoutDefault = 1;
          }

          if(data[0]["warehouseAddress"] != null){
            this.model.warehouseaddress = JSON.parse(data[0]["warehouseAddress"]);
            this.changeDataWSA = true
          }          
          
          if(data[0]["taxes"] == null){
            this.modelTaxes = {recyclePreTax: 0, removalPretax: 0, deliveryPreTax: 0}
          }else{
            var taxes = JSON.parse(data[0]["taxes"]);
            this.modelTaxes = taxes
          }

          // if (this.settings.sponsorByRanges !== "1") {
            this.settings.sponsorByRanges = false;
          // }
        },
        (error) => {
          this.alertify.error(error);
        }
      );
    this.loadStoreProducts();

    this.loadSponsorsContributions();

    this.loadStoreProductsLocal()

  }

  validateAddress(index:any){
    if(!this.formwh.valid){
      this.alertify.error('Complete all fields')
      return;
    }

      this.indexToCheck = index
      let info = this.model.warehouseaddress[index];

      this.addresToCheck.deliveryAddress1 = info.line1 != undefined ? info.line1 : ''
      this.addresToCheck.deliveryAddress2 = info.line2 != undefined ? info.line2 : ''
      this.addresToCheck.deliveryCity = info.city != undefined ? info.city : ''
      this.addresToCheck.deliveryState = info.region != undefined ? info.region : ''
      this.addresToCheck.deliveryZip = info.postalCode != undefined ? info.postalCode : ''

      this.salesOrderService.validateAddress({customer:this.addresToCheck, device: localStorage.getItem('device')})
      .pipe(
        takeUntil(this.destroy$))   
      .subscribe((a:any) =>{
        if(a?.messages){
          let show:string = '';
          a.messages.forEach(msg => {
            show = show+msg.summary +'<br> '
          });
          this.alertify.alert(show, ()=>{})
        }else if(a?.taxAuthorities){
          this.validatedAddress = a?.validatedAddresses as Address[];
          this.bsModalRef = this.modalService.show(this.popTemplateAddres, { class: 'modal-lg'});
        }
      })
  }

  setAddressFromAvalara(address:any){

    if(address == null){
      this.modalService.hide()
      return;
    }    

    this.changeDataWSA = false     
    setTimeout(() => {      
      this.model.warehouseaddress[this.indexToCheck].line1 = address.line1;
      this.model.warehouseaddress[this.indexToCheck].line2 = address.line2;
      this.model.warehouseaddress[this.indexToCheck].city = address.city;
      this.model.warehouseaddress[this.indexToCheck].region = address.region;
      this.model.warehouseaddress[this.indexToCheck].postalCode = address.postalCode.substring(0,5);;
      this.changeDataWSA = true
      this.modalService.hide()
    }, 100);
  }

  setWholeAddress():void{
    this.model.warehouseaddress.forEach((a) =>{
      a.AddressWH = (a?.line1 == undefined? "": a?.line1.trim()+"\n")+(a?.line2 == undefined  || a?.line2 == "" ? "": a.line2.trim()+"\n")+a?.region+" "+a?.city+" "+a?.postalCode+(a?.phone == undefined? "": "\n"+a.phone.trim())+((a?.comment != null && a?.comment != undefined && a?.comment != "") ? "\n"+a.comment.trim() : "");
    })
  }
  getStates(): Observable<any[]> {
    return this.http.get<any[]>('assets/states.json');
  }
  loadStoreProductsLocal() {
    this.storeProductsService.getStoreProducts(1,
      10000, '', ''
      , '', '')
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
          localStorage.setItem('products', JSON.stringify(res));
        }, error => {
          this.alertify.error(error);
        });
  }

  loadStoreProducts() {
    this.storeProductsService
      .getStoreProducts(1, 1, "", "", "", "")
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {

          this.companies = res.result.companies;
          this.companiesandsizes = res.result.companiesandsizes;

          this.companiesandsizes.forEach(element => {
            element['sizeName'] = element['sizeName'].toString().trim().toUpperCase();
          });

          this.styles = res.result.styles;
          this.sizes = res.result.sizes;
          this.sizes.forEach(element => {
            element['sizeName'] = element['sizeName'].toString().toUpperCase();
          });
          this.products = res.result.products;
          this.productsSelect = res.result.products;
            this.fields[0].fieldArray.fieldGroup[2].templateOptions.options = this.sizes;
            this.fields[0].fieldArray.fieldGroup[0].templateOptions.options = this.products;

        },
        (error) => {
          this.alertify.error(error);
        }
      );
  } 

  loadSponsorsContributions() {
    this.sponsorContributionService
      .getSponsorContributions()
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res) => {
          this.model.sponsorscontributions = res;
          this.loadsponsorscontributions = true

        },
        (error) => {
          this.alertify.error(error);
        }
      );
  }

  updateFutureEvents() {

    let counter = 0;
    for (const value of this.model.sponsorscontributions) {
      counter++;
      if (!value.hasOwnProperty("yy_SponsorContribution_tx")) {
        value["yy_SponsorContribution_tx"] = this.global.guid();
      }
      value["OrderNum"] = counter.toString();
    }
    counter = 0;
    this.model.taxes = [];
    this.model.taxes = this.modelTaxes
    this.sponsorContributionService
      .updateSponsorContribution('future', this.model)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res) => {
          this.alertify.success(
            "Contributions to Sponsor were saved for future events."
          );
          this.saveSettings();
        },
        (error) => {
          this.alertify.error(error);
        }
      );

      // let data = {
      //   userId: '123',
      //   userName: this.authService.decodeToken('username').username,
      //   section: 'Sponsors',
      //   action: 'Update Future Events',
      //   eventId: '*',
      //   device: localStorage.getItem('device'),
      // }
      // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
      // .subscribe(a => {});

  }

  submit(model: any) {
    if(this.form.valid){      
    let counter = 0;
    for (const value of this.model.sponsorscontributions) {
      counter++;
      if (!value.hasOwnProperty("yy_SponsorContribution_tx")) {
        value["yy_SponsorContribution_tx"] = this.global.guid();
      }
      value["OrderNum"] = counter.toString();
    }
    counter = 0;
    if (this.validateAllSizesOnProducts() === true) {
    this.sponsorContributionService
      .updateSponsorContribution(0, this.model)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res) => {
          this.alertify.success(
            "Contributions to Sponsor were saved successfully"
          );
          this.saveSettings();
        },
        (error) => {
          this.alertify.error(error);
        }
      );
    }
    }else{      
      this.options.formState.submitted = true;
    }

  }

  saveTaxes(){
    let TaxesToSend = {
      taxes : JSON.stringify(this.modelTaxes)
    }
    // console.log(this.modelTaxes)
    this.settingsService.updateDefaultTaxes(TaxesToSend)
    .pipe(takeUntil(this.destroy$))   
      .subscribe(
        () => {
          this.alertify.success("Default Taxes has been updated");
        },
        (error) => {
          this.alertify.error(error);
        }
      );


    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Sponsors',
    //   action: 'Save Sponsors',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});

  }

  saveSettings() {
    this.settings["dis1exp"] = btoa(this.settings["disc1"]);
    this.settings["dis2exp"] = btoa(this.settings["disc2"]);
    this.settings["dis33exp"] = btoa(this.settings["disc3"]);
    this.settings["dis4exp"] = btoa(this.settings["disc4"]);
    delete this.settings["disc1"];
    delete this.settings["disc2"];
    delete this.settings["disc3"];
    delete this.settings["disc4"];
    delete this.settings["MFRM"];
    this.settings["markup"] = "SETTINGS";
    this.settingsService
      .updateSettings(this.settings["storeId"], this.settings)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        () => {
          //this.alertify.success('Settings were updated');
          //this.form.markAsPristine();

          this.settings["disc1"] = atob(this.settings["dis1exp"]);
          this.settings["disc2"] = atob(this.settings["dis2exp"]);
          this.settings["disc3"] = atob(this.settings["dis33exp"]);
          this.settings["disc4"] = atob(this.settings["dis4exp"]);
        },
        (error) => {
          this.alertify.error(error);
        }
      );

      
  }

  loadWHAddress(){
    this.show = 'WHAddress';    
    if(this.settings["warehouseAddress"] != null){
      this.model.warehouseaddress = JSON.parse(this.settings["warehouseAddress"]);
      this.changeDataWSA = true
    } 
  }
  saveWHAddress(){
    if(this.formwh.valid){
      let countdefault = 0
      if(this.model.warehouseaddress.length > 1){

        this.model.warehouseaddress.forEach(e => {
          if(e.default == true || e.default == 1){
            countdefault++;
          }
        });
        if(countdefault == 0){
          this.alertify.error('You need a default Warehouse Address')
          return
        }else if(countdefault > 1){
          this.alertify.error("Don't have more than a default Warehouse Address")
          return
        }
      }else if(this.model.warehouseaddress.length == 1){
        this.model.warehouseaddress[0].default = true;
      }
    this.settingsService.updateWHAddress({"DID":this.settings.DID,"warehouseAddress":JSON.stringify(this.model.warehouseaddress)})
    .pipe(takeUntil(this.destroy$))   
      .subscribe(() => {
        this.settings["warehouseAddress"] = JSON.stringify(this.model.warehouseaddress);
        this.alertify.success('Warehouse address were updated');
      }, error => {
        this.alertify.error(error);
      });
    }else{      
      this.optionswh.formState.submitted = true;
    }
  }

   validateAllSizesOnProducts(): boolean {
    let errors = '';
    let valid = true;
    this.model.sponsorscontributions.forEach(element => {
      let found = this.model.sponsorscontributions.filter(x => x.product === element['product']);
      let count = 0;
      found.forEach(element2 => {
        if (element['size'] === element2['size'])
        {
          count++;
          if (count > 1 && valid === true) {
            errors = element['product'] + ' has two contributions for size ' + element['size'] + '. Each size is only allowed to have one contribution per product.';
            this.alertify.error(errors);
            valid = false;
            return false;
          }
        }
      });
    });
    return valid;
    
  }

  reload(){
    this.settingsService
      .getSettings(0)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (data) => {
          if(data[0]["itemLayout"] != null){
            this.itemLayoutDefault = data[0]["itemLayout"]
          }else{
            this.itemLayoutDefault = 1;
          }
        },
        (error) => {
          this.alertify.error(error);
        }
      );
  }

  cancelForm() {}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();}
}
