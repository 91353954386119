<div class="modal-body">
  <app-closebutton *ngIf="!showAddress"></app-closebutton>
  <div class="">
    <h4 class="tw-text-aedgrays tw-text-lg tw-font-semibold">
        {{salesOrder?.created_at <= '2023-05-18' ? "Direct Shipment Address" : item?.itemAddress != 1 ? "Item will be ship to" : "Direct Shipment Address"}}  
    </h4>
  </div>


  <div class="text-xl" *ngIf="salesOrder?.created_at > '2023-05-18' && item?.itemAddress != 1">
    <div class="tw-grid tw-grid-cols-2">
      <div>
      <div>{{salesOrder?.fName+' '+salesOrder?.lName}}</div>
      <div>{{salesOrder?.deliveryAddress1}}</div>
      <div>{{salesOrder?.deliveryAddress2}}</div>
      </div>

      <div>
        <div>{{salesOrder?.deliveryCity}} {{salesOrder?.deliveryState}} {{salesOrder?.deliveryZip}} </div>
      <div>{{salesOrder?.phone3}}</div>
      <div>{{salesOrder?.deliveryEmail}}</div>        
      </div>
    </div>

    <div class="tw-flex tw-mt-4">
      <button class="btn-blue-so tw-h-fit tw-mr-4" (click)="closeAddressFunct()">Close</button>
      <button class="btn-blue-so tw-h-fit" (click)="useDifAddress()">Use a Diferent Address</button>
    </div> 

  </div>

  <small *ngIf="form?.dirty" class="text-warning bg-white m-1 ml-0 mb-1 p-1">
    <strong> <i class="fa fa-warning"></i>Information: </strong> The Address has been modified, if you leave without saving all your changes wil be lost.
  </small>
  
  <!-- <div *ngIf="item?.itemAddress == 1" class="tw-flex flex-wrap pb-2 pt-2 "> -->
    <!-- <button class="btn-blue-so tw-h-fit tw-mt-1 tw-mr-2" (click)="copyFromBilling()">From Billing</button>
    <button class="btn-blue-so tw-h-fit tw-mt-1 tw-mr-2" (click)="copyFromShipping()">From Shipping</button>
    <button class="btn-blue-so tw-h-fit tw-mt-1 tw-mr-2" (click)="copyFromPrevious()">From Previous Item</button> -->
  <!-- </div> -->

  <form *ngIf="salesOrder?.created_at <= '2023-05-18' ? true : item?.itemAddress == 1" [formGroup]="form" (ngSubmit)="saveDelivery()" class="form-container">
    <!-- <button class="btn-blue-so tw-h-fit tw-mt-1" (click)="clearForm()">Clear</button> -->
    <formly-form [form]="form" [fields]="fields" [model]="item" [options]="options" >

      <div class="form-buttons-container">        
        <button class="btn-blue-so" type="button" (click)="saveDelivery()" >
          <span class="material-icons">check </span> Done
        </button>

        <button *ngIf="!showAddress" class="btn btn-6" (click)="cancelForm()" type="button" >
          Cancel
        </button>     
        <button *ngIf="storeSettings?.avaTax == 1 || storeSettings?.avaTax == true" class="btn-blue-so tw-ml-3" (click)="validateAddress()" type="button">
          <span class="material-icons"> check </span> 
          Validate Address
        </button>   
      </div>
      
    </formly-form>
  </form>
</div>

<ng-template #popTemplateiOs >
  <div class="tw-flex tw-flex-col tw-w-full tw-justify-center tw-pt-3 tw-px-3">
    <div class="tw-flex tw-justify-between">
      <p class="tw-text-xl tw-text-aedorangel tw-font-semibold tw-text-center">Avalara Suggested Address(es)</p>
      <img src="assets/avalara.png"  style="height: 30px;">
    </div>
    <p class="tw-text-base tw-text-aedgrays tw-font-base tw-text-center">Select an address below</p>
  </div>
  <div class="tw-p-4 tw-grid tw-grid-cols-3 tw-space-x-3" >
    <!-- Customer shipping -->
    <div class="tw-border-2 tw-text-base tw-flex tw-flex-col hover:tw-shadow-lg tw-cursor-pointer tw-border-gray-400 tw-rounded-lg tw-p-2" (click)="setAddressFromAvalara(null)">
      <span class="tw-font-bold" >Current Shipping Address</span>
      <span >{{item?.deliveryAddress1}} </span>
      <span *ngIf="customer?.deliveryAddress2 != ''" >{{item?.deliveryAddress2}} </span>
      <div class="tw-flex tw-space-x-1">
        <span >{{item?.deliveryState}}</span> 
        <span>{{item?.deliveryCity}}</span>  
        <span>{{item?.deliveryZip}}</span>
      </div>
    </div>
    <!-- From Avalara -->
    <div class="tw-border-2 tw-text-base tw-flex tw-flex-col hover:tw-shadow-lg tw-cursor-pointer tw-border-gray-400 tw-rounded-lg tw-p-2" *ngFor="let address of validatedAddress" (click)="setAddressFromAvalara(address)">
      <span class="tw-font-bold" >{{address?.addressType}} </span>
      <span [ngClass]="{'tw-text-green-500': address.line1 != item?.deliveryAddress1}" >{{address?.line1}} </span>
      <span [ngClass]="{'tw-text-green-500': address.line2 != item?.deliveryAddress2}" *ngIf="address?.line2 != ''" >{{address?.line2}} </span>
      <div class="tw-flex tw-space-x-1">
        <span [ngClass]="{'tw-text-green-500': address.region != item?.deliveryState}" >{{address?.region}}</span> 
        <span [ngClass]="{'tw-text-green-500': address.city != item?.deliveryCity}">{{address?.city}} </span> 
        <span [ngClass]="{'tw-text-green-500': address?.postalCode?.substring(0,5) != item?.deliveryZip}">{{address?.postalCode?.substring(0,5)}}</span>
      </div>
    </div>
  </div>
</ng-template>