<div class="table-responsive table-event-summary mt-3">
  <table
    id="salestable"
    class="table table-light table-custom"
    cellspacing="0"
  >
    <thead class="table-custom-header">
      <tr>
        <th class=""> Sales Order </th>
        <th class=""> Style </th>
        <th class=""> Product </th>
        <th class=""> Size </th>
        <th class=""> Qty </th>
        <th class=""> (Qty in SOs) </th>
        <th class=""> Qty per Unit </th>
        <th class=""> Price </th>
        <th class=""> Total </th>
        <th></th>
    </thead>


    <tbody *ngFor="let poitem of poitems">
      <tr class="m-1 p-1">
      
        <td>{{ poitem.SONumber | uppercase }}</td>
        <td>{{ poitem.styleName }}</td>
        <td>{{ poitem.Type }}</td>
        <td>{{ poitem.size }}</td>
        <td>{{ poitem.Qty }}</td>
        <td>{{ poitem.soQtyNum }}</td>
        <td>{{ poitem.minQtyNum }}</td>
        <td>{{ poitem.Price | number: "1.2" }}</td>
        <td>{{ poitem.Qty * poitem.Price | number: "1.2"}}</td>  <td>
           
          <div style="cursor: pointer;" (click)="filterItems(poitem.POITID)" *ngIf="filterIdPoItem != poitem.POITID && poitem.soQtyNum > 1"> 
            <span class="material-icons"> arrow_backward </span>
          </div>

          <div style="cursor: pointer;" (click)="filterItems(null)" *ngIf="filterIdPoItem == poitem.POITID && poitem.soQtyNum > 1">
            <span class="material-icons">arrow_downward</span>
          </div>

          <button (click)="deletePOItem(poitem.POITID)" class="btn btn-blue" *ngIf="(poitem.soQtyNum == 1 && UploadStatus != 'Uploaded') || editstate">
            <span class="material-icons">
              delete
              </span>
          </button> 

        </td>
      </tr> 


<!-- poitemsItemsSelected.length > 0  -->
      <tr *ngIf="poitem.POITID == filterIdPoItem">      
       <td  colspan="9" >
             <table id="salestable" class="table table-hover table-sm rounded " cellspacing="0" >
                  <thead class="">
                      <tr>
                          <th>SONumber</th>
                          <th>Style</th>
                          <th>Product</th>
                          <th>Size</th>
                          <th>Qty</th>
                          <th>Price</th>  
                          <th>Total</th>    
                          <th></th>                       
                      </tr>
                    </thead>
                  <tbody>
                    <tr class="table-light my-2"  *ngFor="let poitemitem of poitemsItemsSelected">
                      <td>{{poitemitem.SONumber}}</td>
                      <td>{{poitemitem.styleName}}</td>
                      <td>{{poitemitem.Type}}</td>
                      <td>{{poitemitem.size}}</td>
                      <td>{{poitemitem.quantityNum}}</td>
                      <td>{{poitemitem.wholeSalePrice}}</td> 
                      <td>{{ poitemitem.wholeSalePrice * poitemitem.quantityNum | number: "1.2"}}</td>
                      <td>
                        <button *ngIf="UploadStatus != 'Uploaded' || editstate" (click)="deleteItem(poitemitem.SOItemsID,poitemitem.POITID,poitemitem.quantityNum)" class="btn btn-blue">
                          <span class="material-icons">
                            delete
                            </span>
                        </button>             
                      </td>
               
                    </tr>
                  </tbody>
              </table>  
       </td>
          
      </tr>
    </tbody>

    <div *ngIf="poitems.length === 0">
      No Data Found
    </div>

    <!-- <tfoot class="">
      <tr class="text-bigger bg-white">
        <th></th>
        <th></th>
        <th></th>
        <th class="text-right"><p style="min-width: max-content;">{{ QtyTotal | number: "1.0" }}</p></th>
        <th class="text-right"><p style="min-width: max-content;">$ {{ wholeSalePrice | number: "1.2-2" }}</p></th>
        <th class="text-right"><p style="min-width: max-content;">$ {{ SalesPrice | number: "1.2-2" }}</p></th>
      </tr>
    </tfoot> -->
  </table>
</div>