import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import 'rxjs/add/operator/takeUntil';
import { takeUntil } from 'rxjs/operators';
import { GlobalsService } from 'src/app/_services/globals.service';

@Component({
  selector: 'app-titlebar',
  templateUrl: './titlebar.component.html',
  styleUrls: ['./titlebar.component.scss']
})
export class TitlebarComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  title: String;
  urlf: string;
  constructor(private globals: GlobalsService, private router: Router) { }

  ngOnInit() {
    this.globals.getTitle().subscribe(appTitle => this.title = appTitle);
//    this.url = this.router.url;

    this.router.events
    .pipe(takeUntil(this.destroy$))   
    .subscribe(() => { this.urlf = this.router.url.replace('/',''); });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


}
