<!-- <div class="" *ngIf="searchMode" style="margin-bottom: -20px;">
    <div *ngIf="showHeader">
      <h4>Choose Customer</h4>
    </div>
  </div> -->
  
    <!-- <div class="main-navbar-info-page">
      <span class="mr-1" style="font-size: 14px;">Customers:</span>
      <strong class="text-dark" style="font-size: 12px;">{{ pagination.totalItems }}</strong>
    </div> -->
  
    <div style="background-color:  #f5f5f5 !important;">

    <div  style="background-color:  #f5f5f5 !important;" >  

     

        <input
          placeholder=" &#xf002; Search Customers"
          style="background-color:  #f5f5f5"
          class="input-header tw-font-sans input-place-icon tw-w-full tw-text-lg tw-text-aedgrayl my-2 "
          type="text"
          [formControl]="searchCustomer"
          (input)="search()"
        />    

        <div class="tw-flex" style="display: flex;">
          <button class="btn-blue-so tw-w-24" (click)="search()">Search</button> 
          <button style="display: flex;" class="btn-blue-so tw-w-24 ml-3" (click)="cancelSearch()"> <span class="material-icons">close</span> Cancel</button>
        </div>
<!--        
      <button class="btn btn-3 ml-1"
      
      id="btnAddCustomer"
      (click)="addCustomer()">
        <span class="material-icons">
          add
        </span>
        Add Customer
      </button> -->
    </div>

<!--   
  <div class="nav-display-options-mobile">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <h6 class="ml-2">Customers: <strong class="text-black" 
        counto
        [step]="30"
        [countTo]="pagination.totalItems"
        [countFrom]="0"
        [duration]="1"
        (countoChange)="counto = $event">{{ pagination.totalItems }}</strong> </h6>
      <div [ngClass]="inputSearchCssmobile">
        <span class="material-icons" *ngIf="!specialcha"> search </span>
        <span class="material-icons" style="color: #ffc107;width: 1.5rem;" *ngIf="specialcha">filter_list_of</span>
        <input
          type="text"
          [formControl]="searchCustomer"
          (input)="search()"
          placeholder="Search Customer"
        />
      </div>
    </div>
    <div class="nav-links-contrainer-filter">
      <div class="btn btn-3 ml-1" id="btnAddCustomerMov" (click)="addCustomer()">
        <span class="material-icons"> add </span> Add Customer
      </div>
    </div>
    <app-event-info-mobile [searchBy]="'All'" [fromEvent]="true"  class="event-nav-display-options-mobile" ></app-event-info-mobile>
    
  </div> -->
  
  <!-- <div class="customer-content mobile-view"> -->
    <div *ngIf="viewType === 'List' && searchCustomer.value != '' " class="table-responsive" style="padding-top: 4px;padding-bottom :4px;">
      <table id="salestable" class="table table-light table-custom store-catalog-table" style="margin-bottom:0px" cellspacing="0">
        <thead class="table-custom-header">
        </thead>
        <tbody style="color:#747070;">
          <tr *ngFor="let customer of customers">
            <td *ngIf="viewType === 'List'"  >
              <h1 class="tw-text-base tw-text-aedgrayl">{{ customer?.firstName }} {{ customer?.lastName }}</h1> 
            </td>
            <!-- <td *ngIf="viewType === 'List'">
              <h1 class="tw-text-base tw-text-aedgrayl">{{ customer?.phoneType }} {{ customer?.phone1 }}</h1>
            </td> -->
            <td *ngIf="viewType === 'List'"><a class="tw-text-base tw-text-aedgrayl" href="mailto:#{{ customer?.email }}">{{ customer?.email }}</a></td>
            <td *ngIf="viewType === 'List' && searchMode == false ">
              <div class="d-flex">
                <p
                  class="customer-list-container-records-item-info-orders mr-1"
                  *ngIf="customer.salesCount > 0"
                  (click)="loadSalesOrder(customer.Id)"
                >
                
                  <span class="material-icons mr-1">local_grocery_store</span> Order: <span class="customer-list-container-records-item-info-orders-counter">{{ customer.salesCount }}</span>
                </p>
  
                <button
                  class="btn btn-blue mr-1"
                  (click)="editCustomer(customer.Id)"
                  type="button"
                >
                  <span class="material-icons">
                    create
                  </span>
                </button>
  
                <!-- <button
                  *ngIf="customer.salesCount == 0"
                  class="btn btn-delete"
                  (click)="deleteCustomer(customer.Id)"
                  type="button"
                >
                 <span class="material-icons">
                    delete
                  </span>
                </button>  -->
              </div>
            </td>
            <td *ngIf="viewType === 'List' && searchMode == true ">
              <button
              (click)="selectCustomer(customer.Id,customer)"
              type="button"
            >
              <i class="fa fa-check tw-text-aedblue fa-3xl fa-3x"></i>
            </button>
            </td>
          </tr>
        </tbody>
      </table>

      <pagination *ngIf="showPagination && searchCustomer.value != ''" style="background-color:  #f5f5f5 !important;margin: 0px;"
      [disabled]="paginationMode"
      [boundaryLinks]="true"
      [totalItems]="pagination.totalItems"
      [(ngModel)]="pagination.currentPage"
      [itemsPerPage]="pagination.itemsPerPage"
      (pageChanged)="pageChanged($event)"
      [maxSize]="maxsize"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
      firstText="&laquo;"
      lastText="&raquo;"
    >
    </pagination>
    </div>
    
    
    <!-- <div *ngIf="viewType === 'Card'" class="customer-records-container">
      <div
        [ngClass]="!searchMode ? 'custom-card' : 'col-lg-6'"
        *ngFor="let customer of customers"
        class="customer-records-container-item"
      >
      <div class="custom-card-content-item-body">
        <h5 class="custom-card-content-item-body-name">{{ customer?.firstName }} {{ customer?.lastName }}</h5>
        <div class="custom-card-content-item-body-addres">
          <p class="m-0">{{ customer?.billingAddress1 }}</p>
          <p class="m-0" *ngIf="customer?.billingAddress2">{{ customer?.billingAddress2 }}</p>
          <p class="m-0">{{ customer?.city }} {{ customer?.state }} {{ customer?.zip}}</p>
        </div>
        <div class="custom-card-content-item-body-phones">
          <p class="m-0">{{ customer?.phoneType }} :</p> <p class="m-0">{{ customer?.phone1 }}</p>
        </div>
        <a class="custom-card-content-item-body-email" href="mailto:#">{{ customer?.email }}</a>
        <div class="custom-card-content-item-body-badge" *ngIf="customer.comments">comments: {{ customer?.comments }}</div>
      </div>
      </div>
    </div>
     -->
  <!-- </div> -->
  
  



    </div>
  