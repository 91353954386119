import { Component, OnInit, OnDestroy,Inject, AfterViewInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Router } from '@angular/router';
import 'rxjs/add/operator/takeUntil'; 
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyService } from 'src/app/_services/currency.service';
import { LanguageService } from 'src/app/_services/language.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit
// , OnDestroy
{
  model: any = {};
  photoUrl: string;
  isCollapsed = false;
  selectedImage = [];
  imagesArray = [];
  styleForImage = {};
  usernameByURL: any;
  passwordByURL: any;
  platform = '';
  errorLoading = '';
  device = ''
  version :string= environment.appVersion;
  showMobile = false;
  isIOS = false;
  isAndroid = false;
  isLogged = false;

  constructor(public authService: AuthService,
    private alertify: AlertifyService,
    private router: Router,
    private translate: TranslateService,
    private currencyService: CurrencyService,
    private languageService: LanguageService,
    ) { }


  ngAfterViewInit(): void {
      var str= document.location.href;  
      var splitted3 = str.split("#/login/",3 );   
      if(splitted3.length > 0){
        this.authService.loadToken(splitted3[1])
      }

      let json = this.authService.decodeToken('version') != (null && undefined) ? this.authService.decodeToken('version') : null ;
      let version = json != null?json.version:'0'  
      let user = JSON.parse(this.authService.decodeToken('user').user);       
      const username = user.username;
      const role = this.authService.decodeToken('role').role;


      if(version != environment.appVersion){        
        //Save the credentials temporarily in the localStorage, and then match the version.
        localStorage.setItem('username', this.model.username)
        localStorage.setItem('password', this.model.password)
        this.authService.matchVersion.next(true)
      }else{   
        //this.alertify.success('logged in successfully');
        this.currencyService.initCurrency();
        if (role !== 'Dealer') {
            this.languageService.setLanguage(undefined, username);
        } else {
          this.languageService.setLanguage();
        }
        setTimeout(() => {
          this.alertify.success(this.translate.instant('LOGGED_IN_SUCCESSFULLY'));
        }, 3000);
        this.router.navigate(['salesorder']);
      }  
  }

  ngOnInit() {
    this.imagesArray = ['assets/login.png', 'assets/login2.png'];
    this.setImage();
    this.translate.get('LOGGED_IN_SUCCESSFULLY')

    const token = localStorage.getItem('token');  
    if (token != null || token != undefined) { 
    this.model.username = this.authService.decodeToken('username').username;
    }   
    if(localStorage.getItem('username') != null && localStorage.getItem('username') != undefined){
      this.model.username = localStorage.getItem('username')
    }  

    this.device = this.getMobileOperatingSystem();
    
    this.platform = navigator.userAgent;
    if (this.platform.match(/Android/i)) {
      this.showMobile = true;
     this.isAndroid = true;
     this.device = 'Android'
    }
    if (this.platform.match(/iPhone|iPad|iPod/i)) {
      this.showMobile = true;
       this.isIOS = true;
      this.device = 'iOS'
    }
  
    if (this.platform.includes('Mac')) {
      this.showMobile = false;
      this.device = 'Macbook'
    }
  
    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
       this.isIOS = true;
       this.device = 'iOS';
    }
  
    if (this.platform.includes('Win')) {
      this.showMobile = false;
      this.device = 'Windows';
    }
  
    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
      this.isIOS = true;
      this.device = 'iOS';
    }

    // if(this.isIOS){
    //   this.iOSversion();
    // }

    localStorage.setItem('device', this.device);
    this.isLogged = this.authService.loggedIn()
    if(this.isLogged){
      this.router.navigate(['salesorder']);
    }

  }

  setImage() {
    const num = Math.floor(Math.random() * 2);

    this.selectedImage = this.imagesArray[num];
    this.styleForImage = {
      'background': 'url(\'' + this.selectedImage + ' \') no-repeat center center fixed',
      'background-size': 'cover',
      'height': '100%',
      'width': '100%',
      'z-index:': '-1',
      'position': 'fixed'
    };

  }

  getMobileOperatingSystem() {
    var userAgent = navigator.userAgent || navigator.vendor;

    // Windows Phone must come first because its UA also contains "Android"
    if (/windows phone/i.test(userAgent)) {
        return "Windows Phone";
    }

    if (/android/i.test(userAgent)) {
        return "Android";
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(userAgent)) {
        return "iOS";
    }

    return "unknown";
  } 



  loggedIn() {
    const token = localStorage.getItem('token');
    
    return !!token;
  }

  logout() {
    localStorage.removeItem('isCashier');
    localStorage.removeItem('token');
    localStorage.removeItem('auth_token');
    localStorage.removeItem('isCashier');
    this.authService.decodedToken = null;
    this.authService.currentUser = null;
    this.alertify.message(this.translate.instant('LOGGED_OUT'));
    // this.router.navigate(['/login']);
    window.location.href =environment.linkLogin;  

  }

}