<app-closebutton></app-closebutton>
<div class="container justify-content-around bg-white p-1 w-100 mt-2 ">
  <h5 class="text-center">Setup Bluetooth Card Reader</h5>
  <br>
  <div *ngIf="deviceError == null">
    <div class="text-center border-secondary border-top mb-3"></div>

    <div *ngIf="step===0" class="mx-auto text-center wizbod">
        <i class="fa fa-power-off fa-3x text-success"></i>    
        <br>
        <br>
        <h6>Please turn on your device by pressing the power <br> until the first green light is turned on.</h6> 
    </div>

    <div *ngIf="step===1" class="text-center wizbod">
        <i class="fa fa-cog fa-spin fa-3x text-secondary" *ngIf="devicefound.length == 0"  ></i>
        <i class="fa fa-information fa-3x text-secondary" *ngIf="devicefound.length >0"  ></i>
        <br>
        <br>
        <h6>Searching for card reader ...</h6> 
        <div *ngIf="devicefound.length >0">
            The Card Reader has been already setted with label <br>
            <h2 class=" ">  <b>{{devicefound[0].Label_tx}}</b></h2> 
        </div>
        <br>
    </div>

    <div *ngIf="step===2" class="text-center wizbod">
        <div *ngIf="device?.MacDevice_tx && device?.MacReader_tx">
            <i class="fa fa-exclamation fa-3x text-success" *ngIf=""></i>
            <br>
            <br>
            <h6>Card Reader Found</h6> 
            <br>
            Device: {{device?.MacDevice_tx}} <br>
            Card Reader: {{device?.MacReader_tx}} <br>
        </div>

    </div>


    <div *ngIf="step===3" class="text-center wizbod">
        <h6>Please label your card reader with letter</h6> 
        <br>
        <h1 class=" ">  <b>{{letterToUse}}</b></h1> 
        <br>
    </div>
    <div class="text-center border-secondary border-top mt-2">
        <div *ngIf="devicefound.length === 0">
            <button class="btn btn-outline-primary btn-sm" *ngIf="step > 0 " (click)="step = step - 1">Back</button>
            <button class="btn btn-outline-primary btn-sm"  *ngIf="step < 3 " (click)="next()">Next</button>
            <button class="btn btn-outline-primary btn-sm"  *ngIf="step === 3" (click)="create()">Finish</button>
        </div>
        <button class="btn btn-outline-primary btn-sm"  *ngIf="devicefound.length > 0" (click)="finish()">Finish</button>
    </div>

</div>


<div *ngIf="deviceError != null"  >
    <div class="text-center wizbod">
        <i class="fa fa-stop fa-3x text-danger" *ngIf=""></i>
        <br>
        <br>
        <h6>Card Reader Not Found</h6> <br>
        Please check if the Card Reader is powered on!
        <br>
    </div>
    <div class="text-center border-secondary border-top mt-2">
        <button class="btn btn-primary btn-sm" (click)="retry()" > <i class="fa fa-recycle"></i> Retry</button>
        <button class="btn btn-primary btn-sm" (click)="finish()" >  Cancel</button>
    </div>
    <br>
</div>


</div>