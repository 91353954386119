
<!-- <div class="pull-right" *ngIf="size==='normal'">
  <div class=" text-secondary text-right">{{text}} <br></div>
    <h6 class="text-primary text-right" counto [step]="30" [countTo]="quantity" [countFrom]="0" [duration]="duration" (countoChange)="counto = $event">
      <div *ngIf="type==='$'">  {{type}} {{counto | number: '1.2-2'}}</div>
      <div *ngIf="type!=='$'">  {{type}} {{counto | number: '1.0-0'}}</div>
    </h6>
</div> -->

<div class="row" *ngIf="size==='small'">
  <div class="col-6 text-secondary d-inline">{{text}}</div>
   <span class="d-inline col-6" counto [step]="30" [countTo]="quantity" [countFrom]="0" [duration]="duration" (countoChange)="counto = $event">
      <span *ngIf="type==='$'">  {{type}} {{quantity | number: '1.2-2'}}</span>
      <span *ngIf="type!=='$'">  {{type}} {{quantity | number: '1.0-0'}}</span>
    </span>
</div>


  <div class="card-event-summary" *ngIf="size==='normal' && for !=='Fundraiser'">
    <div class="card-event-summary-header d-flex" style="flex-direction: row; align-items: center;">
      <p>{{text}}</p>
    </div>
    <div class="card-event-summary-info">
      <div class="card-event-summary-info-number" counto [step]="30" [countTo]="quantity" [countFrom]="0" [duration]="duration" (countoChange)="counto = $event" >
        <p *ngIf="type==='$'"><span class="material-icons" *ngIf="type==='$'"> attach_money </span> {{quantity | number: '1.2-2'}}</p>
        <p *ngIf="type!=='$'"><span class="material-icons" *ngIf="type==='$'"> attach_money </span> {{quantity | number: '1.0-0'}}</p>
      </div>
      <img *ngIf="color === 'blue'" src="assets/eventsuma/blue.svg" class="" />
      <img *ngIf="color === 'bluelight'" src="assets/eventsuma/bluelight.svg" class="" />
      <img *ngIf="color === 'green'" src="assets/eventsuma/green.svg" class="" />
      <img *ngIf="color === 'yellow'" src="assets/eventsuma/yellow.svg" class="" />
      <img *ngIf="color === 'purple'" src="assets/eventsuma/purple.svg" class="" />
    </div>
  </div>


  <div class="card-event-summary" style="height: auto !important;" *ngIf=" for ==='Fundraiser'">
    <div class="card-event-summary-header d-flex" style="flex-direction: row; align-items: center;">
      <p class="tw-font-bold tw-text-aedgraydark">{{text}}</p>
    </div>
    <div class="card-event-summary-info" *ngFor="let item of array">

      <div class="card-event-summary-info-number"  [step]="30" >
        <p > {{item.team | slice : 0 : 15 }} </p>
      </div>
      <div class="card-event-summary-info-number"  [step]="30" >
        <p >  <span class="material-icons" *ngIf="type==='$'"> attach_money </span> {{item.amount | number: '1.2-2'}}</p>
      </div>
      
    </div>
  </div>