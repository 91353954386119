<div class="tw-mb-3 tw-px-5  tw-flex-col">

  <form [formGroup]="salesOrderForm">
    <div role="form" class="tw-flex tw-justify-between"  style="flex-flow: wrap" >

      <div class="tw-mr-2">
        <div
          btnRadioGroup
          formControlName="deliveryRequest"
          class="tw-flex tw-flex-col"
        >
          <p class="title-3">Set For</p>
          <div *ngIf="!canSelect" class="tw-toggle">
            <div
              class="tw-rounded-l-lg"
              [ngClass]=" salesOrderForm.get('deliveryRequest').value  == 'Delivery' ? 'tw-toggle-inactive' : 'tw-toggle-active'"
              id="v-pills-profile-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
              btnRadio="Pickup"
            >
              Pickup
            </div>
            <div
              class="tw-rounded-r-lg"
              [ngClass]="salesOrderForm.get('deliveryRequest').value == 'Pickup' ? 'tw-toggle-inactive' : 'tw-toggle-active'"                
              id="v-pills-home-tab"
              data-toggle="pill"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
              btnRadio="Delivery"
            >
              Delivery
            </div>
          </div>
          <div *ngIf="canSelect"  class="">
            {{salesOrderForm.get('deliveryRequest').value}}
          </div>  
        </div>
      </div>

      <div *ngIf="salesOrder['avalaraActive'] == false || salesOrder['avalaraActive'] == undefined || salesOrder['avalaraActive'] == null"  class="tw-mr-2">
      <div class="">
        <div class="">
          <p class="title-3">Taxes</p>
        </div>
        <div class="">
          <input
            *ngIf="!canSelect"
            class="input-header-white"
            type="number"
            formControlName="taxNumber"
          />
          <div *ngIf="canSelect" >
            {{salesOrderForm.get('taxNumber').value}}
          </div>
        </div>
        <!-- <app-salesorder-taxes class="col-md-12" [salesOrder]="salesOrder" ></app-salesorder-taxes> -->
      </div>
      </div>

      <div  class="tw-mr-2">       
      <p class="title-3">How did you hear about us?</p>
      <div class="tw-flex">
        <div class="tw-flex">
          <div *ngIf="canSelect" >
            {{salesOrderForm.get('soSource').value}}
          </div>
          <select id="input-referral" *ngIf="!canSelect" class="input-header-white" 
          [ngClass]="{'input-header-white-error': salesOrderForm.get('soSource').errors && salesOrderForm.get('soSource').touched }" formControlName="soSource">
            <option disabled value="null"  selected>Choose Referral</option>
            <option [ngValue]="referral" *ngFor="let referral of referralTypes">
              {{ referral }}
            </option>
          </select>
          <!-- <div class="invalid-feedback">Please choose a Referral</div> -->
        </div>

        <div class="ml-auto input-container">
          <input *ngIf="!canSelect" type="text" class="input-header-white tw-ml-2" formControlName="referralName"
          [ngClass]="{
            'is-invalid':
              salesOrderForm.get('referralName').errors &&
              salesOrderForm.get('referralName').touched &&
              salesOrderForm.get('soSource').value === 'Other'
          }" placeholder="Referral Name if any"/>
          <div *ngIf="canSelect" >
            {{salesOrderForm.get('referralName').value}}
          </div>
        </div>
      </div>
      </div>

      <div  class="tw-mr-2">
      <p class="title-3">Sales Person</p>

      <div *ngIf="!isIOS" class="sale-order-detail-content-form-input-container"> 
        <div class="select-container" style="width: 100% !important;">
          <ng-template  #customItemTemplate let-model="item"  let-index="index"   class="">
            {{ model }}
          </ng-template>
          
          <input *ngIf="!canSelect"  formControlName="salesPerson"  [typeahead]="salesPersons"  [typeaheadOptionsLimit]="20"  [typeaheadMinLength]="0"
            placeholder="Sales Person"  [typeaheadItemTemplate]="customItemTemplate"  class="input-header-white"  style="text-transform: none;"
            [ngClass]="{'input-header-error': salesOrderForm.get('salesPerson').errors}"/>
          <div *ngIf="canSelect" >
            {{salesOrderForm.get('salesPerson').value}}
          </div>
        </div>
      </div>
      </div>

      <div *ngIf="fundraiser.length > 1">
      <div class="">          
      <p class="title-3">Teams</p>
      <div class="input-container tw-flex tw-flex-row tw-flex-wrap tw-space-x-2" *ngIf="fundraiser.length > 1">
        <div class="tw-flex tw-items-center tw-text-lg" *ngFor="let fund of fundraiser">      
          <input [disabled]="canSelect" style="height: auto !important" type="checkbox" value="{{fund.checked}}"  (change)="teamsChange($event,fund.team)" [checked]="fund.checked" >&nbsp; {{fund.team}}           
        </div>
      </div>          
      </div>
      </div>

    </div>
  </form>
</div>

