<div class="tw-flex tw-p-4">
    <div class="tw-text-aedgraydark tw-font-bold tw-text-2xl">{{pdfName}}</div>
    <div class="tw-ml-auto">
            <span (click)="close()" class="material-icons tw-text-aedoranges tw-cursor-pointer"> cancel </span>
    </div>
</div>

<div class="tw-my-4 tw-px-4 tw-flex">
    <pdf-viewer class="tw-mx-auto"
    [src]="pdfUrl"
    [render-text]="true"
    [original-size]="false"
    style="width: 400px; height: 500px"
    ></pdf-viewer>    
</div>