import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
@Input() salesOrder:any;
@Input() mobile:boolean = false;
@Input() canSelect = true;

  constructor() { }

  ngOnInit() {
  }

}
