import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs/Subscription';
import { Pagination } from 'src/app/_modules/pagination';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ReportingService } from 'src/app/_services/reporting.service';
import { SalesOrderItemsService } from 'src/app/_services/salesorderitems.service';
import { StockItemsService } from 'src/app/_services/stockitems.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { AddsalesorderitemComponent } from '../../../salesorder/addsalesorderitem/addsalesorderitem.component';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-stockitemslistmobile',
  templateUrl: './stockitemslistmobile.component.html',
  styleUrls: ['./stockitemslistmobile.component.scss']
})
export class StockitemslistmobileComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() salesOrderMode = false;
  stockItems: any = [];

  companies: any = [''];
  styles: any = [];
  sizes: any = [];
  products: any = [];
  //Never Change
  companiesDefault: any = [''];
  stylesDefault: any = [];
  sizesDefault: any = [];
  productsDefault: any = [];
  pagination: Pagination;
  totalItems = 0;
  subscriptions: Subscription[] = [];
  selectedStyle = '';
  selectedMan = '';
  selectedSize = '';
  selectedProduct = '';
  data: any = [];
  storeExcel: any = [];
  toSaveStockItems: any = [];
  showSave = false;
  isDealer = false;
  maxsize = 10;
  searched = false;
  filter = false;
  isCollapseDec1 = false;
  //Search Bar
  tabFilterNav = false;
  searchBarText = '';

  //Variables filtros
  manuFilter = '';
  styleFilter = '';
  //State
  recordItems: any[];
  recordItemsDefault: any[];
  //FilterInputs
  filterManu = '';
  filterStyle = '';
  filterSize = '';
  filterProduct = '';
  //InputsFilters
  inputManu = '';
  //PaginateJS
  p: number = 1;
  //Abre el filtro ya seleccionado
  searchedInput = false;
  //SI el arreglo es mayor A 2 se hace mas grande el friltro Menu
  filters = 0 ;
  //Total de Itesmen numeros
  totalitems = 0;
  radioUpdateBy = new FormControl('dollar');

  constructor(private stockItemsService: StockItemsService,
    private alertify: AlertifyService,
    private chRef: ChangeDetectorRef,
    private router: Router,
    private authService: AuthService,
    private reportingService: ReportingService,
    private itemsService: SalesOrderItemsService,
    private bsModalRef: BsModalRef,
    private el: ElementRef,
    private globals: GlobalsService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.pagination = {
      totalItems: 10,
      totalPages: 10,
      currentPage: 1,
      itemsPerPage: 10
    };
    if (this.globals.isMobile()) {
      this.maxsize = 4;
    }
    if (this.router.url.toString().includes('stockitems')
      && this.authService.decodeToken('role').role !== 'Manager'
    ) {
      this.isDealer = true;
    }
    //this.loadstockItems();
    this.loadStockItemsLocal();
  }

  loadstockItems() {
    this.stockItemsService.getStockItems(this.pagination.currentPage,
      this.pagination.itemsPerPage, this.selectedMan, this.selectedStyle
      , this.selectedSize, this.selectedProduct)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          this.stockItems = res.result.data;

          this.stockItems.forEach(element => {
            element['salesPriceNum'] = element['salesPriceNum'].toString().replace('.00', '');
          });

          this.pagination = res.pagination;
          this.totalItems = this.pagination.totalItems;
          if (this.salesOrderMode) {
            this.stockItems = this.stockItems.filter(stockItem => stockItem['Stock'] > 0);
          }
          this.companies = res.result.companies;
          this.styles = res.result.styles;
          this.sizes = res.result.sizes;
          this.products = res.result.products;

        }, error => {
          this.alertify.error(error);
        });
  }

  loadStockItemsLocal() {
    this.stockItemsService.getStockItems(this.pagination.currentPage,
      100000, this.selectedMan, this.selectedStyle
      , this.selectedSize, this.selectedProduct)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {

          this.recordItems = res.result.data;
          this.recordItemsDefault = res.result.data;

          this.stockItems.forEach(element => {
            element['salesPriceNum'] = element['salesPriceNum'].toString().replace('.00', '');
          });

          //this.pagination = res.pagination;
          this.totalItems = this.pagination.totalItems;
          if (this.salesOrderMode) {
            this.stockItems = this.stockItems.filter(stockItem => stockItem['Stock'] > 0);
          }

          this.companies = res.result.companies;
          this.styles = res.result.styles;
          this.sizes = res.result.sizes;
          this.products = res.result.products;

          this.totalitems = this.recordItems.length;

          this.companiesDefault = res.result.companies;
          this.stylesDefault = res.result.styles;
          this.sizesDefault = res.result.sizes;
          this.productsDefault = res.result.products;
         
        }, error => {
          this.alertify.error(error);
        });
  }

  //Carga Fors de los Select Filtros
  loadSelectFilters(query:any,field: any) {

    var manuArray = [];
    var styleArray = [];
    var sizeArray = [];
    var prodArray = [];

    //console.log(this.recordItems)

    this.searchSelectApply(true);

    this.recordItems.forEach(element => {

      var manu = manuArray.findIndex(x => x.MFGID==element.MFGID);
      var style = styleArray.findIndex(x => x.styleName==element.styleName);
      var size = sizeArray.findIndex(x => x.sizeName==element.sizeName);
      var prod = prodArray.findIndex(x => x.product==element.product);
      
      
      if (manu === -1 ) {
        manuArray.push({ MFGID: element.MFGID, companyName: element.companyName });
      }
      
      if (style === -1 ) {
        styleArray.push({ styleId:element.styleId, styleName: element.styleName });
      }
      if (size === -1 ) {
        sizeArray.push({ sizeName: element.sizeName });
      }
      if (prod === -1 ) {
        prodArray.push({ product: element.product });
      }

    });

    if (field != 'MFGID') {
      this.companies = manuArray;
    }else {
      manuArray = [];
      manuArray.push(this.companies);
    }

    if (field != 'styleId') {
      this.styles = styleArray;
    }else {
      styleArray = [];
      styleArray.push(this.styles);
    }

    if (field != 'sizeName') {
      this.sizes = sizeArray;
    }else {
      sizeArray = [];
      sizeArray.push(this.sizes);
    }

    if (field != 'product') {
      this.products = prodArray;
    }else {
      prodArray = [];
      prodArray.push(this.products);
    }

  }


  applyFilterSelect () {
    this.loadStockItemsLocal();
    var filters = []
    if (this.selectedMan) {
      //console.log('SI push campo seleccionado', this.filterManu)
      filters.push({ text: this.filterManu, field: 'companyName' });
    }
    if (this.selectedStyle) {
      //console.log('SI push campo seleccionado', this.filterStyle)
      filters.push({ text: this.filterStyle, field: 'styleName' });
    }
    if (this.selectedSize) {
      //console.log('SI push campo seleccionado', this.filterSize)
      filters.push({ text: this.filterSize, field: 'sizeName' });
    }
    if (this.selectedProduct) {
      //console.log('SI push campo seleccionado', this.filterProduct)
      filters.push({ text: this.filterProduct, field: 'product' });
    }
    this.filters = 1; 
    if (filters.length > 2) {
      this.filters = 3; 
    }
    if ( !this.selectedMan && !this.selectedStyle && !this.selectedSize && !this.selectedProduct ) {
      this.searched = false;
      return 0;
    }

    this.searched = true;
  }
  searchSelectApply(include = false ) {
    var find = include;

    this.recordItems = this.recordItemsDefault;
    var filters = []

    if (this.selectedManMod) {
      filters.push({ text: this.selectedManMod, field: 'MFGID' });
    }
    if (this.selectedStyle) {
      filters.push({ text: this.selectedStyle, field: 'styleId' });
    }
    if (this.selectedSize) {
      filters.push({ text: this.selectedSize, field: 'sizeName' });
    }
    if (this.selectedProduct) {
      filters.push({ text: this.selectedProduct, field: 'product' });
    }

    this.filters = 1; 
    if (filters.length > 2) {
      this.filters = 3; 
    }
    

    if ( !this.selectedManMod && !this.selectedStyle && !this.selectedSize && !this.selectedProduct ) {
      this.searched = false;
      return 0;
    }
    
   if (filters.length >= 1) {
    this.searched = true;
      var records: any;

      let search = this.recordItems;
      
      if (find) {
        filters.forEach(element => {
          search = this.recordItems;
          this.recordItems = this.findWordFilter(element.text, element.field, search);
        });
      }else{
        filters.forEach(element => {
          search = this.recordItems;
          this.recordItems = this.findWord(element.text, element.field, search);
        });
      }
      
      this.totalitems = this.recordItems.length;
      return 0;
    }

    //Si no hay nada que buscar se ceirra el filtro 
    this.searchedInput = false;
  }
  
  searchInputApply( ) {
    this.recordItems = this.recordItemsDefault;
    var filters = []

    if (this.filterManu) {
      //console.log('SI push campo seleccionado', this.filterManu)
      filters.push({ text: this.filterManu, field: 'companyName' });
    }
    if (this.filterStyle) {
      //console.log('SI push campo seleccionado', this.filterStyle)
      filters.push({ text: this.filterStyle, field: 'styleName' });
    }
    if (this.filterSize) {
      //console.log('SI push campo seleccionado', this.filterSize)
      filters.push({ text: this.filterSize, field: 'sizeName' });
    }
    if (this.filterProduct) {
      //console.log('SI push campo seleccionado', this.filterProduct)
      filters.push({ text: this.filterProduct, field: 'product' });
    }

    this.filters = 1; 
    if (filters.length > 2) {
      this.filters = 3; 
    }

   if (filters.length >= 1) {
    this.searchedInput = true;
      var records: any;

      let search = this.recordItems;

      filters.forEach(element => {
        search = this.recordItems;
        this.recordItems = this.findWord(element.text, element.field, search);
      });

      return 0;
    }
    this.searchedInput = false;
  }

  findWord(query: any, field: any, search: any) {
    if (field == 'companyName') {
      return search.filter(function (e) {
        return e.companyName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'styleName') {
      return search.filter(function (e) {
        return e.styleName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'sizeName') {
      return search.filter(function (e) {
        return e.sizeName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'product') {
      //console.log('Se Busco en product');
      return search.filter(function (e: any) {
        return e.product.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'MFGID') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.MFGID.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'styleId') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.styleId.toLowerCase().includes(query.toLowerCase());
      });
    }
  }
  findWordFilter(query: any, field: any, search: any) {
    if (field == 'companyName') {
      return search.filter(function (e) {
        return e.companyName.toLowerCase().find(query.toLowerCase());
      });
    }
    if (field == 'styleName') {
      return search.filter(function (e) {
        return e.styleName.toLowerCase().find(query.toLowerCase());
      });
    }
    if (field == 'sizeName') {
      return search.filter(function (e) {
        return e.sizeName.toLowerCase().find(query.toLowerCase());
      });
    }
    if (field == 'product') {
      //console.log('Se Busco en product');
      return search.filter(function (e: any) {
        return e.product.toLowerCase().find(query.toLowerCase());
      });
    }
    if (field == 'MFGID') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.MFGID.toLowerCase().find(query.toLowerCase());
      });
    }
    if (field == 'styleId') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.styleId.toLowerCase().find(query.toLowerCase());
      });
    }
  }

  /**
   * Clean Input Filters and renew array Products
   * To do Hacer lo mismo en Items in Stock
   **/
  
  clearsearchInputApply( ) {
    //Inputs Apply
    this.filterManu = '';
    this.filterStyle = '';
    this.filterSize = '';
    this.filterProduct = '';
    this.searchedInput = false;
    this.recordItems = this.recordItemsDefault;
  }

  openTabFilter(){
    this.clearALL();
    this.clearsearchInputApply();
    this.tabFilterNav = !this.tabFilterNav;
  }

  onOpenDic1(data: boolean) {
    this.isCollapseDec1 = data;
  }

  openFilterMenu() {
    this.filter = !this.filter;
  }

  /**
   * Clean Input Filters and renew array Products
   * To do Hacer lo mismo en Items in Stock
   **/
  clearALL() {
    this.selectedMan = '';
    this.selectedStyle = '';
    this.selectedSize = '';
    this.selectedProduct = '';
    this.searched = false;
    this.recordItems = this.recordItemsDefault;
    this.defaultFilters();
    this.totalitems = this.recordItems.length;
  }
  clearFilter() {
    this.selectedMan = '';
    this.selectedStyle = '';
    this.selectedSize = '';
    this.selectedProduct = '';
    this.searched = false;
    this.totalitems = this.recordItems.length;
  }
  /**
   * Reset SelectFilter Default Content
   * To do Hacer lo mismo en Items in Stock
   **/
  defaultFilters() {
    this.companies = this.companiesDefault;
    this.styles = this.stylesDefault;
    this.sizes = this.sizesDefault;
    this.products = this.productsDefault;
  }
  // deleteStockItem(id?: string) {
  //   let msg = 'Are you sure that you want to delete this Stock Item?';
  //   if (!id) {
  //     id = 'all';
  //     msg = 'Are you sure you want to delete all the found Stock Item?';
  //   }

  //   this.alertify.confirm(msg, () => {
  //     this.stockItemsService.deleteStockItem(id)
  //       .pipe(takeUntil(this.destroy$))
  //       .subscribe(() => {
  //         this.alertify.success('Stock Item(s) Deleted');
  //         this.clearFilter();
  //         this.loadstockItems();
  //       }, error => {
  //         this.alertify.error(error);
  //       });
  //   }
  //   ,()=>{
  //   });
  // }

  removeStockItem(id: string, stockItem: any) {

    const msg = 'Do you want to clear the inventory for this item?';
    this.alertify.confirm(msg, () => {
      this.stockItemsService.removeStockItem(id, stockItem)
      .pipe(takeUntil(this.destroy$))
        .subscribe(() => {
          this.alertify.success('Stock Item(s) Invetory is Cleared');
          this.clearFilter();
          this.loadstockItems();
        }, error => {
          this.alertify.error(error);
        });
    }
    ,()=>{
    });
  }

  get selectedStyleMod() {
    return this.selectedStyle;
  }

  set selectedStyleMod(value) {
    const style = this.styles.find(sty => sty.styleId === value);
    this.styleFilter = style.styleName;
    this.selectedStyle = value;
    //this. loadstockItems();
  }

  get selectedManMod() {

    return this.selectedMan;
  }

  set selectedManMod(value) {

    const manufacturer = this.companies.find(manu => manu.MFGID === value);

    this.manuFilter = manufacturer.companyName;
    this.selectedMan = value;
  

    //this.loadstockItems();
  }

  get selectedSizeMod() {
    return this.selectedSize;
  }

  set selectedSizeMod(value) {
    this.selectedSize = value;
    //this.loadstockItems();
  }

  get selectedProductMod() {
    return this.selectedProduct;
  }

  set selectedProductMod(value) {
    this.selectedProduct = value;
    //this.loadstockItems();

  }

  editQuantity(id: string, stockItem: any, save?: false) {
    this.showSave = save;
    if (this.showSave) {

      this.toSaveStockItems.forEach(element => {

        this.stockItemsService.updateStockItems(element)
        .pipe(takeUntil(this.destroy$))
          .subscribe(() => {
            this.showSave = false;
          }, error => {
            this.alertify.error(error);
          });
      });
      this.alertify.success('Sales Price(s) were updated');
      this.toSaveStockItems = [];


    } else {
      this.showSave = true;
      this.toSaveStockItems.push(stockItem);
    }

  }

  addSoItem(storeproduct: any) {

    storeproduct['itemDelivery'] = 'In Stock';


    const initialState = {
      salesOrderMode: true,
      item: storeproduct

    };
    this.bsModalRef = this.modalService.show(AddsalesorderitemComponent, { initialState, class: '', backdrop: true, ignoreBackdropClick: true  });

  }

  exportToCSV(data: any = []) {
    this.reportingService.exportToCSV(data, 'StockItems.csv');
  }

  hideModal() {
    this.bsModalRef.hide();
  }

  loadStockItemsForExcel() {

    let data: any = [];
    this.stockItemsService.getStockItems(1,
      1, this.selectedMan, this.selectedStyle
      , this.selectedSize, this.selectedProduct, 1)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          data = res.result;
          this.exportToCSV(data);
        }, error => {
          this.alertify.error(error);
        });

    return data;
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadstockItems();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
