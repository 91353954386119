import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from "@angular/core";
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";


import { AlertifyService } from "src/app/_services/alertify.service";
import { StoreproductsComponent } from "../store/storeproducts/storeproducts.component";
import { SalesOrderItemsService } from "src/app/_services/salesorderitems.service";
import { GlobalsService } from "src/app/_services/globals.service";

import { FileHandle } from "src/app/_directives/dragDrop.directive";
import { EventsService } from "src/app/_services/events.service";

import { StoreproductsService } from "src/app/_services/storeproducts.service";
import {CdkDrag, CdkDragDrop, CdkDragEnter, CdkDropList, CdkDropListGroup, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { SettingsService } from "src/app/_services/settings.service";
import { UserService } from "src/app/_services/user.service";
import { AuthService } from "src/app/_services/auth.service";
import { SizesIndex } from "../../_resolvers/SizeSyno";
import { Router } from "@angular/router";

@Component({
  selector: "app-itemmenu",
  templateUrl: "./itemmenu.component.html",
  styleUrls: ["./itemmenu.component.scss"],
})
export class ItemmenuComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  list = [];
  rows = 4;
  columns = 4;
  color:any;
  setItemx = 0;
  setItemy = 0;
  selectedItem = {};
  itemEdited = {};
  eventid = "";
  event = {};
  isOpen = false;
  @Input() fromSalesOrder = 0;
  @Input() fromcomponet = '';
  private _insertAfter: boolean;
  @Input() itemLayoutDefault: any = null;

  @Input() showFloor: boolean = false;
  @Output() showFloorChange = new EventEmitter<boolean>();

  platform = '';
  showMobile = false;
  isIOS = false;
  isAndroid = false;
  modalRef: BsModalRef[]=[];
  constructor(
    private modalService: BsModalService,
    private itemsService: SalesOrderItemsService,
    public bsModalRef: BsModalRef,
    private storeProductsService: StoreproductsService,
    private eventService: EventsService,
    private settingsService: SettingsService,
    private router: Router,
    private global: GlobalsService,
    private alertify: AlertifyService,
    private authService: AuthService
    
  ) {
    this.target = null;
    this.source = null;
  }
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;

  public colors: Array<string> = ["PaleGreen" ,"MediumOrchid" , "LightSkyBlue", "LightGreen" , "Tomato", "Aquamarine"];

  public target: CdkDropList;
  public targetIndex: number;
  public source: CdkDropList;
  public sourceIndex: number;

  files: FileHandle[] = [];

  filesDropped(files: FileHandle[], item): void {
    var base64;
    const max_height = 200;
    const max_width = 200;
    let reader = new FileReader();

    reader.onload = () => {
      if (files[0].file.size > 2097152) {
        this.alertify.error("Maximum file size allowed is 1 mb");
        item.file = "";
      }

      const image = new Image();
      image.src = reader.result.toString();
      image.onload = (rs) => {
        const img_height = rs.currentTarget["height"];
        const img_width = rs.currentTarget["width"];

        if (img_height > max_height && img_width > max_width) {
          this.alertify.error(
            "Maximum image size allowed is " +
              max_height +
              "*" +
              max_width +
              "px"
          );
          item.file = "";
        } else {
          item.file = reader.result;
        }
      };
    };
    base64 = reader.readAsDataURL(files[0].file);

  }

  close(){
    this.showFloorChange.emit(false)
  }

  ngOnInit() {
    if(this.itemLayoutDefault != null && this.itemLayoutDefault != 1){
      var layout = JSON.parse(this.itemLayoutDefault.toString());
      //console.log(layout)
      var sizes = JSON.parse(layout[1]["sizes"]);
      this.rows = sizes["rows"];
      this.columns = sizes["columns"];
      var list = layout[0];

      this.list = JSON.parse(list["items"]);
    }else{
    if (this.fromSalesOrder == 1) {

      this.eventService.salesOrderEvent
      .pipe(takeUntil(this.destroy$))   
        .subscribe((res) => {
          this.eventid = res;
          if (this.eventid !== "0" ) {
            let specificFields = {
              values: [
                  "itemLayout"
              ]
             }
            this.eventService
              .getEventSpecific(this.eventid, specificFields)
              .pipe(takeUntil(this.destroy$))   
              .subscribe(
                (data) => {
                  this.event = data[0];
                  if (this.event["itemLayout"]) {
                      var layout = JSON.parse(this.event["itemLayout"]);
                      var sizes = JSON.parse(layout[1]["sizes"]);
                      var list = layout[0];

                    if(JSON.parse(list["items"]).length > 0){                     
                      this.rows = sizes["rows"];
                      this.columns = sizes["columns"];
                      this.list = JSON.parse(list["items"]);
                    }else{
                    this.DefaultLayout();
                    }          
                  }else{
                    this.DefaultLayout();                   
                  }
                },
                (error) => {
                  this.alertify.error(error);
                }
              );
          }
        });
        //Load layout item from settings if the event layout is undefined
        

    } else {
      this.eventService.editEvent
      .pipe(takeUntil(this.destroy$))   
        .subscribe((res) => {
          this.eventid = res;

          if (this.eventid !== "0") {
            this.eventService
              .getEvent(this.eventid)
              .pipe(takeUntil(this.destroy$))   
              .subscribe(
                (data) => {
                  this.event = data[0];
                  if (this.event["itemLayout"]) {
                    var layout = JSON.parse(this.event["itemLayout"]);
                    var sizes = JSON.parse(layout[1]["sizes"]);
                    this.rows = sizes["rows"];
                    this.columns = sizes["columns"];
                    var list = layout[0];

                    this.list = JSON.parse(list["items"]);
                  }
                },
                (error) => {
                  this.alertify.error(error);
                }
              );
          }
        });
    }

  }
    
    this.itemsService.salesItemEvent
    .pipe(takeUntil(this.destroy$))   

      .subscribe((res) => {
        if (this.fromSalesOrder == 0) {
          const soItem = this.itemsService.salesOrderItems.value;
          let soItemNew: any = [];
          soItemNew = Object.assign({}, soItem);
          
          this.selectedItem["item"] = soItemNew;
          
          var results = this.storeProductsService.allStoreProducts.filter(
            (x) =>
              x.MFGID == soItemNew["MFGID"] &&
              x.styleId == soItemNew["styleId"] &&
              x.product == soItemNew["product"]
          );

          var ordered = [];
          var twin = [];
          var twinxl = [];
          var full = [];
          var queen = [];
          var king = [];
          var cking = [];
          var other = [];
          
          results.forEach((style:any)=>{
            if( (SizesIndex.TWIN.toLowerCase()).includes(style["sizeName"].toLowerCase())) {
              twin.push(style)
              return;
            }
            if( (SizesIndex["TWIN XL"].toLowerCase()).includes(style["sizeName"].toLowerCase())) {
              twinxl.push(style)
              return;
            }
            if( (SizesIndex.FULL.toLowerCase()).includes(style["sizeName"].toLowerCase())) {
              full.push(style)
              return;
            }
            if( (SizesIndex.QUEEN.toLowerCase()).includes(style["sizeName"].toLowerCase())){ 
              queen.push(style)
              return;
            }
            if( (SizesIndex.KING.toLowerCase()).includes(style["sizeName"].toLowerCase())) {
              king.push(style)
              return;
            }
            if( (SizesIndex["CAL KING"].toLowerCase()).includes(style["sizeName"].toLowerCase())){ 
              cking.push(style)
              return;
            }

            other.push(style)

          })

          twin.forEach(element => ordered.push(element));
          twinxl.forEach(element => ordered.push(element));
          full.forEach(element => ordered.push(element));
          queen.forEach(element => ordered.push(element));
          king.forEach(element => ordered.push(element));
          cking.forEach(element => ordered.push(element));
          other.forEach(element => ordered.push(element));

          this.selectedItem["item"]["options"] = ordered;

          this.modalService.hide(this.modalService.getModalsCount() - 1);
        }
      });

      this.eventService.salesOrderEvent
      .pipe(takeUntil(this.destroy$))   

      .subscribe((res) => {
        if (this.fromSalesOrder == 0) {
        if (this.eventid !== "0") {
          this.eventService
            .getEvent(res)
            .pipe(takeUntil(this.destroy$))   
            .subscribe(
              (data) => {
                let event = data[0];
                if(event != undefined){
                  if (event["itemLayout"]) {
                    var layout = JSON.parse(event["itemLayout"]);
                    var sizes = JSON.parse(layout[1]["sizes"]);
                    this.rows = sizes["rows"];
                    this.columns = sizes["columns"];
                    var list = layout[0];
               
                    this.list = JSON.parse(list["items"]);
                    this.eventService.salesOrderEvent.next('0')
  
                  }
                }
              },
              (error) => {
                this.alertify.error(error);
              }
            );
        }
      }
      });

    
      this.platform = navigator.userAgent;
      if (this.platform.match(/Android/i)) {
        this.showMobile = true;
        this.isAndroid = true;
      }
      if (this.platform.match(/iPhone|iPad|iPod/i)) {
        this.showMobile = true;
        this.isIOS = true;
  
      }
  
      if (this.platform.includes('Mac')) {
        this.showMobile = false;
  
      }

      if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
        this.showMobile = true;
        this.isIOS = true;
      }
  
      if (this.platform.includes('Win')) {
        this.showMobile = false;
  
      }

     
  }

  loadDefaultLayout(){
    if(this.list.length > 0){
      this.alertify.confirm("You have a Layout Items setted, Are you sure to load Default Layout Items ",()=>{
        this.DefaultLayout()
      }
      ,()=>{
      })
    }else{
        this.DefaultLayout()
    }

  }
DefaultLayout(){
  if (this.router.url.toString().includes('salesorder') ){
    this.settingsService
    .SettingsEvent$
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (data) => {
        if (data[0]["itemLayout"] != null) {
          var layout = JSON.parse(data[0]["itemLayout"]);
          var sizes = JSON.parse(layout[1]["sizes"]);
          this.rows = sizes["rows"];
          this.columns = sizes["columns"];
          var list = layout[0];

          this.list = JSON.parse(list["items"]);
        }
      },
      (error) => {
        this.alertify.error(error);
      }
    );
  }else{
    let specificFields = {
      values: [
        "itemLayout", "DID", "password"
      ]
     }
    this.settingsService
    .getSpecificSettings(specificFields)
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (data) => {
        if (data[0]["itemLayout"] != null) {
          var layout = JSON.parse(data[0]["itemLayout"]);
          var sizes = JSON.parse(layout[1]["sizes"]);
          this.rows = sizes["rows"];
          this.columns = sizes["columns"];
          var list = layout[0];

          this.list = JSON.parse(list["items"]);
        }
      },
      (error) => {
        this.alertify.error(error);
      }
    );
  }
     
}
  


  openModal(template: TemplateRef<any>) {
    this.modalRef.push(this.modalService.show(template));
 }

  clearList() {
    this.list = [];
  }

  generates() {
    var total = this.rows * this.columns;

    if (this.list.length < total) {
      var diff = total - this.list.length;
      for (let index = 0; index < diff; index++) {
        var butmap = {};
        butmap = { item: "" };
        this.list.push(butmap);
      }
    } else if (total > this.list.length) {
      var diff = this.list.length - total;
      for (let index = 0; index < diff; index++) {
        this.list.pop();
      }
    }
  }

  openStore(x, y) {
    this.setItemx = x;
    this.setItemy = y;
    const initialState = {
      salesOrderMode: true,
    };
    this.bsModalRef = this.modalService.show(StoreproductsComponent, {
      initialState,
      class: "modal-xl w-100",
      backdrop: true, ignoreBackdropClick: true    });
  }

  openStoreItem(item) {
    this.selectedItem = item;
    const initialState = {
      salesOrderMode: true,
      fromEvent : true
    };
    this.bsModalRef = this.modalService.show(StoreproductsComponent, {
      initialState,
      class: "modal-xl w-100",
      backdrop: true, ignoreBackdropClick: true
    });
  }

  save() {
    var sizes = { rows: this.rows, columns: this.columns };
    var layout = [];
    layout.push({ items: JSON.stringify(this.list) });
    layout.push({ sizes: JSON.stringify(sizes) });
    this.event["itemLayout"] = JSON.stringify(layout);
    if(this.itemLayoutDefault == 1 || this.itemLayoutDefault != null){
      let layoutToSend = {
        layout : this.event["itemLayout"]
      }
      this.settingsService.updateDefaultLayout(layoutToSend)
      .pipe(takeUntil(this.destroy$))   
        .subscribe(
          () => {
            this.alertify.success("Default Layout Item has been updated");
          },
          (error) => {
            this.alertify.error(error);
          }
        );
    }else{
      this.eventService
        .updateEvent(this.event["CID"], this.event)
        .pipe(takeUntil(this.destroy$))   
        .subscribe(
          () => {
            this.alertify.success("Event has been updated");
          },
          (error) => {
            this.alertify.error(error);
          }
        );
    }

  }

  updateFutureEventsLayout() {
    var sizes = { rows: this.rows, columns: this.columns };
    var layout = [];
    layout.push({ items: JSON.stringify(this.list) });
    layout.push({ sizes: JSON.stringify(sizes) });
    this.event["itemLayout"] = JSON.stringify(layout);

    let layoutToSend = {
      layout : this.event["itemLayout"]
    }

    this.eventService.updateFutureEventsLayout(layoutToSend)
      .pipe(takeUntil(this.destroy$))   
        .subscribe(
          () => {
            this.alertify.success("Future Events have been updated");
          },
          (error) => {
            this.alertify.error(error);
          }
        );
  }
  select(item) {
    let data;
    if ("item" in item) {
      delete item["item"]["options"];

      this.storeProductsService.validateStoreItem(item["item"].supItemID)
      .toPromise()
      .then((data:any)=>{
      if(data.length == 0){
        this.alertify.error("This product doesn't exists")
        return;
      }
      //verification if is active in Customers
      if(data[0].inactive == null || data[0].inactive == "" ){
        //verification if is active in Location
        if(data[0].active == 1 ){
  
          //updateJson of DB, Customers and Location
          item["item"].salesPriceNum = data[0].salesPriceNum
          item["item"].priceNum = data[0].salesPriceNum
          item["item"].wholeSalePriceNum = data[0].Price
          item["item"].quantityNum = 1
          item["item"].itemDelivery = ''
          // console.log(data[0].itemDelivery)
          
          this.itemsService.raiseSoItemEvent(item["item"]);
          this.alertify.success('Item Added');          
        
          
        }else{
          this.alertify.error("This product is inactive")
        }
      }else{
        this.alertify.error("This product is inactive in DDS")
      }
      })
      .catch((err)=>console.log(err));

      data = {
        userId: '123',
        userName: this.authService.decodeToken('username').username,
        section: 'SO',
        action: 'Add main Floor Item',
        eventId: '*',
        device: localStorage.getItem('device'),
      }
    } else {
      item = this.convertItem(item);

      this.storeProductsService.validateStoreItem(item.supItemID)
      .toPromise()
      .then((data:any)=>{
      if(data.length == 0){
        this.alertify.error("This product doesn't exists")
        return;
      }
      //verification if is active in Customers
      if(data[0].inactive == null || data[0].inactive == "" ){
        //verification if is active in Location
        if(data[0].active == 1 ){
  
          //updateJson of DB, Customers and Location
          item.salesPriceNum = data[0].salesPriceNum
          item.priceNum = data[0].salesPriceNum
          item.wholeSalePriceNum = data[0].Price
          item.quantityNum = 1
          item.itemDelivery = '';

          this.itemsService.raiseSoItemEvent(item);
          this.alertify.success('Item Added');          
        
          
        }else{
          this.alertify.error("This product is inactive")
        }
      }else{
        this.alertify.error("This product is inactive in DDS")
      }
      })
      .catch((err)=>console.log(err));

      data = {
        userId: '123',
        userName: this.authService.decodeToken('username').username,
        section: 'SO',
        action: 'Add other Floor Items',
        eventId: '*',
        device: localStorage.getItem('device'),
      }
    }
    this.modalRef.forEach(modal => modal.hide());
    this.bsModalRef.hide();
    this.showFloorChange.emit(false)
  }

  pickColor(item, color) {
    if ("item" in item) {
      item["item"]["color"] = color;
    }
    this.alertify.success("color changed");
  }

  convertItem(soItem) {
    let soItemNew: any = [];
    
    soItemNew = Object.assign({}, soItem);
    soItemNew["quantityNum"] = 1;
    if (soItemNew["priceNum"] == undefined) {
      soItemNew["priceNum"] = soItem['salesPriceNum'];
    } else if (soItem['salesPriceNum'] == undefined ) {
      soItem['salesPriceNum'] = soItemNew["priceNum"];
    }
    soItemNew["priceNum"] = soItem['salesPriceNum'];
    soItemNew["status"] = 'insert';
    
    return soItemNew;
  }

  createNewItem(item:any){
    this.selectedItem = item;
    let soItemNew: any = [];
    soItemNew = Object.assign({}, {"styleName":"", "file":null});
    this.selectedItem["item"] = soItemNew;
    this.enableEditMode(item)
  }

  enableEditMode(item:any){
    this.selectedItem = item;
    this.itemEdited = item;
  }



  disableEditMode(item:any){
    this.selectedItem = item;
    this.itemEdited = {};
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  
  dragEntered(event: CdkDragEnter<number>) {
    const drag = event.item;
    const dropList = event.container;
    const dragIndex = drag.data;
    const dropIndex = dropList.data;

    const phContainer = dropList.element.nativeElement;
    const phElement = phContainer.querySelector('.cdk-drag-placeholder');
    phContainer.removeChild(phElement);
    phContainer.parentElement.insertBefore(phElement, phContainer);

    moveItemInArray(this.list, dragIndex, dropIndex);
  }


}