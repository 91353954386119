<form [formGroup]="taxesForm" >

    <div class="tw-flex tw-mb-2">
        <div class="tw-mr-4">
            <div>{{ 'DEFAULT_TAX' | translate }}</div>
            <input class="input-header" formControlName="defaultTaxes" placeholder="{{ 'DEFAULT_TAX' | translate }}" type="number"/>
        </div>
        
        <div>
            <div>{{ 'DEFAULT_COUNTY' | translate }}</div>
            <input class="input-header" formControlName="defaultCounty" placeholder="{{ 'DEFAULT_COUNTY' | translate }}" type="text"/>
        </div>
    </div>


    <button class="btn-blue-so-square" (click)="saveTax()">
        {{ 'SAVE' | translate }}
    </button>
</form>