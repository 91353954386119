export const CloverSVG = {
    stationWhite : {
        svg :"<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"120\" height=\"120\" viewBox=\"0 0 120 120\" fill=\"none\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M97 68.4489V31H16.1892L35.2102 39.8146C35.8151 40.0949 35.3946 41.0023 34.7898 40.7219L15 31.5511V69H95.8108L76.7898 60.1854C76.1849 59.9051 76.6054 58.9977 77.2102 59.2781L97 68.4489ZM78 80V96H85.5C86.6046 96 87.5 96.8954 87.5 98C87.5 99.1046 86.6046 100 85.5 100H28.5C27.3954 100 26.5 99.1046 26.5 98C26.5 96.8954 27.3954 96 28.5 96H35.5909V80H9C6.23858 80 4 77.7614 4 75V25C4 22.2386 6.23858 20 9 20H108V80H78ZM116 31L114 33V51L116 53V75C116 77.7614 113.761 80 111 80H109V20H111C113.761 20 116 22.2386 116 25V31ZM97 69V31H18.2157C16.4397 31 15 32.1733 15 33.6207V66.3793C15 67.8267 16.4397 69 18.2157 69H97ZM15 31H97V69H15V31ZM47.5518 60H44.4668V44.4482H40.709V42.1758L47.5518 41.5156V60ZM65.0078 53.4111H67.3438V55.8994H65.0078V60H61.9355V55.8994H54.001L53.9248 54.0078L61.834 41.5156H65.0078V53.4111ZM56.9717 53.4111H61.9355V45.9082L61.8594 45.8828L61.6055 46.4287L56.9717 53.4111ZM72.4346 42.6582L70.3779 47.1016H68.8672L69.6289 42.6836V40.1953H72.4346V42.6582ZM76.7129 42.6582L74.6562 47.1016H73.1455L73.9072 42.6836V40.1953H76.7129V42.6582Z\" fill=\"white\"/></svg>",
        name: "Station Solo"
    },
    flexWhite: {
        svg : "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"120\" height=\"120\" viewBox=\"0 0 120 120\" fill=\"none\" style=\"&#10;    color: tomato;&#10;\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M76.8229 101.657C76.2581 101.879 75.6432 102 75 102H45C44.3484 102 43.726 101.875 43.1552 101.649L44.689 98.9921C44.792 98.9973 44.8957 99 45 99H75C75.0955 99 75.1904 98.9978 75.2848 98.9934L76.8229 101.657ZM77.7137 101.2L76.3547 98.8464C77.8696 98.4968 79.165 97.5736 80 96.3178V97C80 98.7611 79.0896 100.309 77.7137 101.2ZM42.2668 101.187C40.9018 100.295 40 98.7527 40 97V96.3178C40.8306 97.567 42.1169 98.4872 43.6216 98.8409L42.2668 101.187ZM45 18H75C77.7614 18 80 20.2386 80 23V93C80 95.7614 77.7614 98 75 98H45C42.2386 98 40 95.7614 40 93V23C40 20.2386 42.2386 18 45 18ZM45 39V93H75V39H45Z\" fill=\"white\"/></svg>",
        name : "Flex"
    },
    miniWhite: {
        svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"120\" height=\"120\" viewBox=\"0 0 120 120\" fill=\"none\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17 26.5238V27H102.476H103V26.5238C103 24.0254 100.975 22 98.4762 22H92L89 25H71L68 22H21.5238C19.0254 22 17 24.0254 17 26.5238ZM103 28H17C14.2386 28 12 30.2386 12 33V98H103C105.761 98 108 95.7614 108 93V33C108 30.2386 105.761 28 103 28ZM23 75V39H97V75H23ZM62 34.0025C62 35.1084 61.1046 36.005 60 36.005C58.8954 36.005 58 35.1084 58 34.0025C58 32.8965 58.8954 32 60 32C61.1046 32 62 32.8965 62 34.0025Z\" fill=\"white\"/></svg>",
        name: "Mini"
    },
    stationDuoWhite: {
        svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"120\" height=\"120\" viewBox=\"0 0 120 120\" fill=\"none\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M99 46V29H15V65H48V87.4658L44.0108 94H48V98H18C16.8954 98 16 97.1046 16 96C16 94.8954 16.8954 94 18 94H26.6113L37.0034 77H10C7.23858 77 5 74.7614 5 72V22C5 19.2386 7.23858 17 10 17H107C108.105 17 109 17.8954 109 19V48.0852C108.943 48.0839 108.885 48.0833 108.828 48.0833H107.889L107.739 47.9222C106.604 46.6966 105.009 46 103.338 46H99Z\" fill=\"white\"/><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M52.5 102.5H112C113.381 102.5 114.5 101.381 114.5 100V59.4167C114.5 58.036 113.381 56.9167 112 56.9167H55C53.6193 56.9167 52.5 58.036 52.5 59.4167V102.5ZM60.1034 88.4167V63.4167H106.897V88.4167H60.1034ZM84 62.2474C82.8481 62.2474 81.9138 61.3155 81.9138 60.1653C81.9138 59.0152 82.8481 58.0833 84 58.0833C85.1519 58.0833 86.0862 59.0152 86.0862 60.1653C86.0862 61.3155 85.1519 62.2474 84 62.2474ZM86.4304 51.5833H57.6753C56.7897 51.5833 56.0444 52.1839 55.8245 53H112.262C112.042 52.1839 111.296 51.5833 110.411 51.5833H107.41L106.452 52.1542L104.439 49.9805C104.155 49.6742 103.756 49.5 103.338 49.5H90.817C90.3993 49.5 90.0005 49.6742 89.7167 49.9805L87.5909 52.2749L86.4304 51.5833Z\" fill=\"white\"/></svg>",
        name: "Station Duo"
    },
    mobileWhite: {
        svg: "<svg xmlns=\"http://www.w3.org/2000/svg\" width=\"120\" height=\"120\" viewBox=\"0 0 120 120\" fill=\"none\"><path fill-rule=\"evenodd\" clip-rule=\"evenodd\" d=\"M17 33.5238V34H102.476H103V33.5238C103 31.0254 100.975 29 98.4762 29H92L89 32H71L68 29H21.5238C19.0254 29 17 31.0254 17 33.5238ZM103 35H17H12V86C12 88.7614 14.2386 91 17 91H103C105.761 91 108 88.7614 108 86V40C108 37.2386 105.761 35 103 35ZM22 81V45H98V81H22Z\" fill=\"white\"/></svg>",
        name: "Mobile"
    }
}
