

<div class="">
  <div class="row"  >
      <div class="col-md-1 text-right">
      </div>
      <div class="col-md-10 ">
          <br>
          <span *ngIf="cardType === 1"> Credit Card</span>
          <span *ngIf="cardType === 2"> Debit Card</span>
          <br>
          
          <div *ngIf="cardType !== 0">
            <div *ngIf="cardname.length > 0">
                <small>Card Number</small> 
                <br>
                <h5 *ngIf="lastfour.length > 0">***************{{lastfour}}</h5> 
                    <input class="form-control d-none"
                    type="text" id="cardno" name="cardno" placeholder="Card Holder" required [(ngModel)]="cardno" >
                    <small>Name on Card</small> <br>
                    <h5>{{cardname}} </h5> 
                    <input class="form-control d-none" 
                    type="text" id="cardname" name="cardname" placeholder="Name" required [(ngModel)]="cardname" >
                    <div class="row">
                        <div class="col-sm-12">
                            <small>Expiration Date</small> <br> 
                            
                        <h5> {{expmonth}} / {{expyear}}</h5>
                        </div>
                        <div class="col-sm-12">
                            <small>Amount</small> <br> 
                        <h4> {{amount | currency }}</h4>
                            <br>
                            <p>
                        </div>
                    </div>
                </div>
      </div>
      <div class="col-md-1"></div>
  </div>
</div>

