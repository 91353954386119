import { Injectable } from '@angular/core';
import { User } from '../_modules/user';
import { UserService} from '../_services/user.service';
import { AlertifyService } from '../_services/alertify.service';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators/catchError';
import { AuthService } from '../_services/auth.service';
import { Message } from '../_modules/message';

@Injectable()
export class MessagesResolver implements Resolve<Message []> {

    pageNumber = 1;
    pageSize = 5;
    messageContainer = 'Unread';

    constructor(private userService: UserService, private router: Router, private alertify: AlertifyService
    ,private authService: AuthService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Message []> {
        return this.userService.getMessages(this.authService.decodedToken.nameid, this.pageNumber,
          this.pageSize, this.messageContainer).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving messages');
                this.router.navigate(['/home']);
                return of(null);
            })
        );
    }
}
