<div *ngIf="viewType === 'Card'" class="custom-card-content">

  <div *ngIf="!salesOrder" class="custom-card-content-item">

    <div class="custom-card-content-item-body">
      <h5 class="custom-card-content-item-body-name">{{ customer?.firstName }} {{ customer?.lastName }}</h5>
      <div class="custom-card-content-item-body-addres">
        <p class="m-0">{{ customer?.billingAddress1 }}</p>
        <p class="m-0" *ngIf="customer?.billingAddress2">{{ customer?.billingAddress2 }}</p>
        <p class="m-0">{{ customer?.city }} {{ customer?.state }} {{ customer?.zip}}</p>
      </div>
      <div class="custom-card-content-item-body-phones">
        <p class="m-0">{{ customer?.phoneType }} </p> <p class="m-0">{{ customer?.phone1 }}</p>
      </div>
      <a class="custom-card-content-item-body-email" href="mailto:#">{{ customer?.email }}</a>
      <div class="custom-card-content-item-body-badge" *ngIf="customer.comments">comments: {{ customer?.comments }}</div>
    </div>


    <div *ngIf="!search" class="custom-card-content-item-footer">
      <div
        class="custom-card-content-item-footer-orders pointer"
        *ngIf="salesorders > 0"
        (click)="salesOrderByCustomer(customer.Id)"
        >
         <span class="material-icons">local_grocery_store</span>
          Order<span *ngIf="salesorders > 1">s</span>: <strong>{{ salesorders }}</strong>
      </div>

      <div class="custom-card-content-item-footer-custom">
        <div class="d-flex">
          <p
            class="custom-card-content-item-footer-custom-options-item "
            [ngClass]="{ 'mr-3': salesorders == 0 }"
            id="btnEditCustomer"
            (click)="editCustomer(customer.Id)"
          >
            <span class="material-icons">create</span>
          </p>
          <p
            *ngIf="salesorders == 0"
            class="custom-card-content-item-footer-custom-options-item"
            (click)="deleteCustomer(customer?.Id)"
          >
            <span class="material-icons">delete</span>
          </p>
          <div *ngIf="search" class="">
            <button
              class="btn btn-outline-primary btn-sm"
              (click)="selectCustomer(customer.Id)"
              type="button"
            >no se
              <i class="fa fa-check"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="search" class="custom-card-content-item-footer">
      <div
        class="custom-card-content-item-footer-orders"

        (click)="selectCustomer(customer.Id)"
        >
          <span class="material-icons"> done </span>
      </div>
    </div>

  </div>



  <div *ngIf="salesOrder">
    <div class="border-bottom border-primary pl-2">
      {{ 'CUSTOMER' | translate }}
      <button
        class="btn btn-outline-primary btn-sm align-middle"
        (click)="openModalWithComponent()"
      >
        <i class="fa fa-search-plus fa-lg"></i>
      </button>
    </div>
    <div
      class="showAddress p-1 mb-2 z-depth-1 bg-white rounded"
      (click)="selectCustomer(customer.Id)"
    >
      <img
        class="img pull-right"
        src="https://ui-avatars.com/api/?background=0D8ABC&color=fff&rounded=true&name={{
          customer.firstName
        }}+{{ customer.lastName }}"
        alt=""
      />
      <address class="row">
        <div class="col-md-6">
          <small>BILLING</small>
          <br />
          <strong
            >{{ customer?.firstName }} {{ customer?.lastName }}</strong
          ><br />
          {{ customer?.billingAddress1 }}
          <div *ngIf="customer?.billingAddress2">{{ customer?.billingAddress2 }}</div>

          {{ customer?.city }} {{ customer?.state }} {{ customer?.zip
          }}<br />
          <abbr title="Phone">{{ customer?.phoneType }}</abbr>
          {{ customer?.phone1 }}<br />
          <a href="mailto:#">{{ customer?.email }}</a
          ><br />
        </div>
        <div class="col-md-6" *ngIf="delivery === 'Delivery'">
          <small>SHIPPING</small>
          <br />
          <strong>{{ customer?.fName }} {{ customer?.lName }}</strong
          ><br />
          {{ customer?.deliveryAddress1 }}
          <div *ngIf="customer?.deliveryAddress2">{{ customer?.deliveryAddress2 }}</div>

          {{ customer?.deliveryCity }} {{ customer?.deliveryState }}
          {{ customer?.deliveryZip }}<br />
          <abbr title="Phone">{{ customer?.phone3Type }}</abbr>
          {{ customer?.phone3 }}<br />
          <a href="mailto:#">{{ customer?.deliveryEmail }}</a
          ><br />
          <span class="badge" *ngIf="customer.comments"
            >comments: {{ customer?.comments }}</span
          >
        </div>

        <div *ngIf="!search">
          <button
            *ngIf="salesorders == 0"
            class="btn btn-danger btn-sm z-depth-1 pull-right"
            (click)="deleteCustomer(customer?.Id)"
            type="button"
          >
            <i class="fa fa-trash"></i>
          </button>
          <button
            class="btn btn-primary btn-sm z-depth-1 pull-right"
            (click)="editCustomer(customer.Id)"
            type="button"
          >
            <i class="fa fa-edit"></i>
          </button>
        </div>
      </address>
      <button
        class="btn btn-outline-dark"
        *ngIf="salesorders > 0"
        (click)="loadSalesOrder()"
      >
        <i class="fa fa-eye"></i> Order {{ salesorders }}
      </button>
    </div>
  </div>
</div>
<div *ngIf="search" class="mb-2"></div>

<div *ngIf="viewType === 'List'" class="customer-list-container-records-item">
  <div class="customer-list-container-records-item-name">
    <p class="m-0">{{ customer?.firstName }} {{ customer?.lastName }}</p>
  </div>
  <div class="customer-list-container-records-item-phone">
    <p class="m-0">{{ customer?.phoneType }} {{ customer?.phone1 }}</p>
  </div>
  <div class="customer-list-container-records-item-email">
    <a href="mailto:#{{ customer?.email }}">{{ customer?.email }}</a>
  </div>

  <div class="customer-list-container-records-item-info" *ngIf="!search">
    <p
      class="customer-list-container-records-item-info-orders"
      *ngIf="salesorders > 0"
      (click)="salesOrderByCustomer(customer.Id)"
    >

      <span class="material-icons mr-1">local_grocery_store</span> Order: <span class="customer-list-container-records-item-info-orders-counter">{{ salesorders }}</span>
    </p>
    <button
      class="btn btn-outline-primary btn-sm"
      (click)="editCustomer(customer.Id)"
      type="button"
    >
      <i class="fa fa-edit"></i>
    </button>
    <button
      *ngIf="salesorders == 0"
      class=""
      (click)="deleteCustomer(customer?.Id)"
      type="button"
    >
      <i class="fa fa-trash"></i>
    </button>


  </div>
  <div *ngIf="search" class="p-1 d-flex flex-row-reverse">
    <button
      class="btn btn-outline-primary btn-sm"
      (click)="selectCustomer(customer.Id)"
      type="button"
    >
      <i class="fa fa-check"></i>
    </button>
  </div>
</div>
