<div *ngIf="!showEditDelivery">
    <div class="sale-order-dropdown-container-items-item tw-border-2 tw-border-aedgraydark tw-rounded-md" *ngFor="let deliver of delivery">
        <div class="sale-order-dropdown-container-items-item-content">
          <p class="item-type tw-font-semibold tw-text-lg">{{deliver.Type}}</p>
          <p class="item-eta tw-font-semibold tw-text-lg">{{deliver.deliveryCode}}</p>
          <p class="item-amount tw-font-semibold tw-text-lg">$ {{deliver.deliveryAmountNum | number:'1.2-2'}}</p>
        </div>
        <div class="sale-order-dropdown-container-items-item-note" *ngIf="deliver.deliveryNotes">
          <div class="note-title">
            <p class="tw-font-semibold tw-text-lg">Note: {{deliver.deliveryNotes}}</p>
          </div>
          <!-- <p class="tw-font-normal tw-text-base">{{deliver.deliveryNotes}}</p> -->
        </div>
      </div>
</div>



  <div *ngIf="showEditDelivery" class="">
   <!-- Delivery Info -->
   <section *ngIf="deliveryinfo?.length > 0" class="tw-px-2 tw-pb-2 tw-border-2 tw-bg-white tw-border-aedgrays tw-rounded-xl tw-mx-auto">
    <div  class="d-flex mt-3 tw-text-start">
      <div class="col-3 tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base  tw-text-aedgrayl tw-font-semibold ">Description</div>
      <div class="col-3 tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-text-aedgrayl tw-font-semibold "> 
         <div>Product/</div>
         <div>Size</div>  
      </div>
      <div class="col-2 tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-text-aedgrayl tw-font-semibold" >Type</div>
      <div class="col-2 tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-text-aedgrayl tw-font-semibold">Price</div>
      <div class="col-2 tw-text-sm sm:tw-text-sm md:tw-text-base lg:tw-text-base tw-text-aedgrayl tw-font-semibold"></div>
    </div>
    <div class="d-flex tw-space-y-4  tw-text-start tw-items-center tw-py-2" *ngFor="let p of deliveryinfo; let even = even; let odd = odd"
      [ngClass]="{ 'tw-bg-aedgrayback': odd}">
      <div class="col-3 mt-0">{{p.method}}</div>
      <div class="col-3 mt-0">
        <div >{{p.product}} {{p.product != null && p.product != '' ? '/' : '' }}</div>
        <div>{{p.size}}</div>        
      </div>
      <div class="col-2 mt-0">{{p.type}}</div>
      <div class="col-2 mt-0">{{p.price | currency}}</div>
      <div class="col-2 mt-0 tw-flex tw-justify-center">
        <button
        class="btn btn-add-mobile"
        (click)="addDelivery(p)"
      >
        <span style="font-size: 30px; width: 30px;" class="material-icons fs-1">
          add
        </span>
      </button>
      </div>

    </div>
  </section>



<!-- end Delivery Info -->
  <div class="event-form-payouts2" >
    <form *ngIf="newFormData" [formGroup]="form" (ngSubmit)="submit()" class="form-container">
      <formly-form class="tw-w-full" [form]="form" [fields]="fields" [model]="model" [options]="options" >
        <!-- <div class="form-buttons-container">
          <button
          class="btn btn-5 mr-2"
          [disabled]="!form.valid" 
          type="sumbit"
          
        >
        <span class="material-icons">
          save
          </span> Save
          </button>
          <button
            class="btn btn-6"
            (click)="cancelForm()"
            type="button"
          >
            Cancel
          </button>
        </div> -->
      </formly-form>
    </form>
  </div>
  </div>
  