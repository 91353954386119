<div class="tw-grid tw-grid-cols-1 sm:tw-grid sm:tw-grid-cols-1 md:tw-grid md:tw-grid-cols-1 xl:tw-grid xl:tw-grid-cols-2">
   
    <!-- first column -->
    <div class="tw-mb-4" >
        <div class="tw-bg-white tw-p-4">

            <div class="tw-h-10 tw-flex tw-justify-between">
                <div class="tw-flex">
                  <button class="btn-blue-so-square tw-mr-4" (click)="hideHistoryPO()">{{ 'GO_BACK' | translate }}</button>       
                </div>
                <div style="display: flex;"> <span class="tw-text-aedblue tw-my-auto tw-text-xl">{{ 'LISTS' | translate }}: {{pagination.totalItems}}</span> </div>
            </div>
        </div>
    
        <div  class="customer-content tw-px-4">
            <div class="table-responsive">
                <table class="table-so table table-light table-custom overflow-auto">
                    <thead class="tw-font-bold tw-text-aedblue">
                        <tr style="font-size: small;background-color: #fafafa;">                
                          <td colspan="1" class=" tw-text-base tw-text-aedblue tw-font-semibold ">{{ 'DATE' | translate }}</td>  
                          <td colspan="1" class=" tw-text-base tw-text-aedblue tw-font-semibold ">{{ 'QUANTITY' | translate }}</td>
                          <td colspan="1" class=" tw-text-base tw-text-aedblue tw-font-semibold ">{{ 'SHIPPING_INFO' | translate }}</td>
                          <td colspan="1" class=" tw-text-base tw-text-aedblue tw-font-semibold ">{{ 'SHIPPING_ADDRESS' | translate }}</td>
                          <td colspan="1" class="tw-text-base tw-text-aedblue tw-font-semibold"></td>
                        </tr>
                    </thead>
            
                    <tbody class="tw-py-2 tw-text-aedgraydark">
                    <tr *ngFor="let sp of recordList;  let odd = odd;"
                    [ngClass]="odd ? 'tw-bg-aedgrayback' :'tw-bg-aedgrayback100'">                        
                    
                        <td colspan="1" class="tdeventlist">{{ sp.created_at | date:"short" }}</td>
                        <td colspan="1" class="tdeventlist">{{ sp.QTY }}</td>
                        <td colspan="1" class="tdeventlist">{{ sp.dshipName + (sp.dshipEMail != null ? ', '+sp.dshipEMail: '') }} <br>
                            {{'Phone: ' +(sp.dshipPhone | mask: '000-000-0000') + (sp.dshipPhone2 != null ? (',  Phone2'+ (sp.dshipPhone2 | mask: '000-000-0000')) : '')}}</td>
                        <td colspan="1" class="tdeventlist">{{ sp.dshipAddress + (sp.dshipAddress2 != null ?', '+sp.dshipAddress2:'' )}} <br>
                             {{sp.dshipCity +' '+ sp.dshipState +' '+ sp.dshipZip }}</td>
                        <td colspan="1" class="tdeventlist tw-text-end">
                        <div class="d-flex tw-ml-4">
                          <svg *ngIf="sp.groupCode != listSelected" (click)="SeeItemsHistory(sp)" xmlns="http://www.w3.org/2000/svg" class="tw-w-8 tw-h-8 tw-cursor-pointer" width="20" height="20" viewBox="0 0 89.068 59.56" ><defs _ngcontent-lpe-c258=""><style _ngcontent-lpe-c258=""> .cls-23 { fill: none; stroke: #00ADEE; stroke-width: 4px; } .cls-25 { fill: #00ADEE; } </style></defs><g _ngcontent-lpe-c258="" id="eye-close-up" transform="translate(2.02 -159.525)"><path _ngcontent-lpe-c258="" id="Path_126" data-name="Path 126" d="M42.514,161.525A46.68,46.68,0,0,0,.171,188.535a1.822,1.822,0,0,0,0,1.542,46.7,46.7,0,0,0,84.686,0,1.822,1.822,0,0,0,0-1.542,48.616,48.616,0,0,0-9.515-13.514A43.971,43.971,0,0,0,65.024,167.2,46.039,46.039,0,0,0,42.514,161.525Zm0,46.95A19.165,19.165,0,1,1,61.679,189.31,19.163,19.163,0,0,1,42.514,208.475Z" transform="translate(0 0)" class="cls-23"></path><circle _ngcontent-lpe-c258="" id="Ellipse_1" data-name="Ellipse 1" cx="12.269" cy="12.269" r="12.269" transform="translate(30.245 177.032)" class="cls-25"></circle></g></svg>
                          <svg *ngIf="sp.groupCode == listSelected" xmlns="http://www.w3.org/2000/svg" class="tw-w-8 tw-h-8 tw-cursor-pointer" width="20" height="20" viewBox="0 0 89.068 59.56" ><defs _ngcontent-lpe-c258=""><style _ngcontent-lpe-c258=""> .cls-233 { fill: none; stroke: #ee5f00; stroke-width: 4px; } .cls-255 { fill: #ee5f00; } </style></defs><g _ngcontent-lpe-c258="" id="eye-close-up" transform="translate(2.02 -159.525)"><path _ngcontent-lpe-c258="" id="Path_126" data-name="Path 126" d="M42.514,161.525A46.68,46.68,0,0,0,.171,188.535a1.822,1.822,0,0,0,0,1.542,46.7,46.7,0,0,0,84.686,0,1.822,1.822,0,0,0,0-1.542,48.616,48.616,0,0,0-9.515-13.514A43.971,43.971,0,0,0,65.024,167.2,46.039,46.039,0,0,0,42.514,161.525Zm0,46.95A19.165,19.165,0,1,1,61.679,189.31,19.163,19.163,0,0,1,42.514,208.475Z" transform="translate(0 0)" class="cls-233"></path><circle _ngcontent-lpe-c258="" id="Ellipse_1" data-name="Ellipse 1" cx="12.269" cy="12.269" r="12.269" transform="translate(30.245 177.032)" class="cls-255"></circle></g></svg>
                        </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div class="pagination-container">
                    <pagination 
                    [boundaryLinks]="true"
                    [totalItems]="pagination.totalItems"     
                    [currentPage]="p2"       
                    [itemsPerPage]="pagination.itemsPerPage"
                    (pageChanged)="pageChanged($event)"  
                    [maxSize]="7"
                    previousText="&lsaquo;"
                    nextText="&rsaquo;"
                    firstText="&laquo;"
                    lastText="&raquo;">
                    </pagination>
                </div>
            </div>
        </div>

    </div>
    
    <!-- second column -->
    <div style="display: flex;" >
      <div *ngIf="recordListItems?.length>0" class="customer-content tw-px-4">

        <div class="tw-bg-white tw-py-4">
          <div class="tw-h-10 tw-flex tw-justify-between">
              <div class="tw-flex">
                <span class="tw-text-aedblue tw-text-2xl tw-mb-4 tw-font-bold">{{ 'DETAILS_LIST' | translate }}</span>
              </div>
          </div>
        </div>

        <div class="table-responsive">
          
          <table class="table-so table table-light table-custom overflow-auto">
            <thead class="tw-font-bold tw-text-aedblue">
              <tr style="font-size: small;background-color: #fafafa;">                
                <td colspan="1" class=" tw-text-base tw-text-aedblue tw-font-semibold ">CMS {{ 'CODE' | translate }}</td> 
                <td colspan="1" class=" tw-text-base tw-text-aedblue tw-font-semibold ">SKU</td>
                <td colspan="1" class=" tw-text-base tw-text-aedblue tw-font-semibold">{{ 'MANUFACTURER' | translate }}</td>
                <td colspan="1" class=" tw-text-base tw-text-aedblue tw-font-semibold ">{{ 'BRAND' | translate }}</td>
                <td colspan="1" class="tw-text-base tw-text-aedblue tw-font-semibold">{{ 'PRODUCT' | translate }}</td>
                <td colspan="1" class=" tw-text-base tw-text-aedblue tw-font-semibold ">{{ 'QUANTITY' | translate }}</td> 
              </tr>
            </thead>
    
            <tbody class="tw-py-2 tw-text-aedgraydark"
              *ngFor="let sp of recordListItems | paginate: { itemsPerPage: 10, currentPage: p, id:'table3'};  let odd = odd; let i = index"
              [ngClass]="odd ? 'tw-bg-aedgrayback' :'tw-bg-aedgrayback100'">
              <tr>    
                <td colspan="1" class="tdeventlist">{{ sp.listCode }}</td>
                <td colspan="1" class="tdeventlist">{{ sp.sku }}</td>
                <td colspan="1" class="tdeventlist">{{ sp.manufacture }}</td>
                <td colspan="1" class="tdeventlist">{{ sp.brand }}</td>  
                <td colspan="1" class="tdeventlist">{{ sp.productName }}</td>   
                <td colspan="1" class="tdeventlist">{{ sp.QTY }}</td>             
              </tr>
            </tbody>
          </table>  
  
          <div class="pagination-container" *ngIf="stockItemsPO.length > 0">   
            <pagination 
              [totalItems]="stockItemsPO.length"
              [(ngModel)]="p"
              [itemsPerPage]="10"
              id="table3"
              [boundaryLinks]="true"
              (pageChange)="p = $event"
              [maxSize]="maxsize"
              [autoHide]="true"
              previousText="&lsaquo;"
              nextText="&rsaquo;"
              firstText="&laquo;"
              lastText="&raquo;"
            ></pagination>
          </div>

        </div>

      </div>

      <div style="min-height: 10rem; height: auto; display: flex; background: #e5e5e5;" *ngIf="recordListItems?.length==0" class="customer-content tw-mx-4">
        <span style="margin: auto;font-size: 2rem; color: gray;">{{ 'CHOOSE_LIST' | translate }}</span>
      </div>

    </div>

  </div>