import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderItemsService {
baseUrl = environment.apiUrl;
laravel = environment.apiLaravel;

salesOrderItems = new BehaviorSubject<any[]>([]);
salesItemEvent: EventEmitter<string> = new EventEmitter();

constructor(private http: HttpClient) { }



raiseSoItemEvent(soItem) {
  this.salesOrderItems.next(soItem);
  this.salesItemEvent.next('');
}


}
