import { HttpClient } from '@angular/common/http';
import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, HostListener, OnInit, Output, OnDestroy, Input, DoCheck, ViewChild, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { NrnValidators } from 'src/app/nrn.validators';
import { Customer } from '../../../_modules/customers';
import { Pagination } from '../../../_modules/pagination';
import { AlertifyService } from '../../../_services/alertify.service';
import { AuthService } from '../../../_services/auth.service';
import { CustomerService } from '../../../_services/customer.service';
import 'rxjs/add/operator/takeUntil';
import { of as observableOf } from 'rxjs/observable/of';
import 'rxjs/add/observable/of';
import { EventsService } from 'src/app/_services/events.service';
import { startWith, map, tap, takeUntil } from 'rxjs/operators';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import {WaMatConfirmDialog, WaMatConfirmDialogModule} from '@webacad/material-confirm-dialog';
import { Subject } from 'rxjs';
import { SettingsService } from 'src/app/_services/settings.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { Address } from 'src/app/_modules/address';



@Component({
  selector: 'app-customer-form-mobile',
  templateUrl: './customer-form-mobile.component.html',
  styleUrls: ['./customer-form-mobile.component.scss']
})
export class CustomerFormMobileComponent implements OnInit, AfterViewInit, OnDestroy, DoCheck {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() deliveryRequest: any;
  @Input() showForm: boolean = true;
  @Output() showFormChange = new EventEmitter<boolean>();
  @Input() showSelectCustomer: boolean = true;
  @Output() showSelectCustomerChange = new EventEmitter<boolean>();
  @Output() closeEditCustomerChange = new EventEmitter<boolean>();
  @Output() change = new EventEmitter();
  @ViewChild('popTemplateiOs', {static: false}) popTemplateiOs: TemplateRef<any>;
  validatedAddress: Address[];
  modalRefAddress: BsModalRef;
  customer: Customer
  customerid: any;
  storeId: string;
  events: any[];
  storeSettings: any;
  customers: Customer[];
  customerEdit: Customer;
  customerForm = new FormGroup({});
  pagination: Pagination;
  editMode = true;
  fielRequired = true;
  addMode = false;
  editId: any;
  states: any[];
  mask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  default = '';  
  @Input() salesOrderToSave: any;

  name:any
  lastname:any
  constructor(private authService: AuthService, private alertify: AlertifyService,
    private customerService: CustomerService, private route: ActivatedRoute,
    private salesOrderService: SalesOrderService,
    private fb: FormBuilder, private router: Router,
    public bsModalRef: BsModalRef, private http: HttpClient,
    private modalService: BsModalService,
    private eventService: EventsService,
    private cd: ChangeDetectorRef,
    private settingsService: SettingsService,
    private confirmDialog: WaMatConfirmDialogModule,
    private globalService: GlobalsService,
  ) { }
  

  @Input() fromSalesOrder: boolean = false;
  searchEvent = '';
  @HostListener('window:beforeunload', ['$event'])

  
  ngOnInit() {

    if(this.deliveryRequest == 'Pickup'){
      this.fielRequired = false
    }



    // console.log(this.router.url.toString().includes('salesorder'))
    if (this.router.url.toString().includes('salesorder')){
      
      this.settingsService.SettingsEvent$
      .pipe(takeUntil(this.destroy$))   
      .subscribe( data => {
        this.storeSettings = data[0];
        if( this.storeSettings == undefined) return;
        if(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc')){
          // console.log('pasa')
  
          this.fields[0].fieldGroup.forEach((field:any, index)=>{
            if(index != 0 && index != 1 && index != 2){
              // console.log(field.templateOptions)
              field.templateOptions.required = false;
            }
          })
          this.fieldsShipping[0].fieldGroup.forEach((field:any)=>{
            field.templateOptions.required = false;
          })
        }
  
        if(this.deliveryRequest == 'Pickup'){
          // console.log(this.deliveryRequest)
          this.fields[0].fieldGroup.forEach((field:any, index)=>{
            if(index != 0 && index != 1 && index != 2){
              field.templateOptions.required = false;
              // console.log(field.templateOptions.required)
            }
          })
          this.fieldsShipping[0].fieldGroup.forEach((field:any)=>{
            field.templateOptions.required = false;
          })
        }
  
      }, error => {
        this.alertify.error(error);
      });

    }else{

      let specificFields = {
        values: [
            "cashierStatus"
        ]
       }
      this.settingsService.getSpecificSettings(specificFields)
    .pipe(takeUntil(this.destroy$))   
    .subscribe( data => {
      this.storeSettings = data[0];
      if(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc')){
        // console.log('pasa')

        this.fields[0].fieldGroup.forEach((field:any, index)=>{
          if(index != 0 && index != 1 && index != 2){
            field.templateOptions.required = false;
          }
        })
        this.fieldsShipping[0].fieldGroup.forEach((field:any)=>{
          field.templateOptions.required = false;
        })
      }

      if(this.deliveryRequest == 'Pickup'){
        // console.log(this.deliveryRequest)
        this.fields[0].fieldGroup.forEach((field:any, index)=>{
          if(index != 0 && index != 1 && index != 2){
            field.templateOptions.required = false;
            // console.log(field.templateOptions.required)
          }
        })
        this.fieldsShipping[0].fieldGroup.forEach((field:any)=>{
          field.templateOptions.required = false;
        })
      }

    }, error => {
      this.alertify.error(error);
    });

    this.getEvents();


    }

    

   
  

    this.storeId = JSON.parse(this.authService.getStoreId());
    this.customer = Object.assign({});
    this.customerService.editCustomer
    .pipe(takeUntil(this.destroy$))   
      .subscribe((
        res => {
          this.customerid = res;
        }
      ));
    this.customerid = this.customerService.editCustomer.value;
    if (this.customerid != 0) {
      this.customerService.getCustomer(this.customerid)
      .pipe(takeUntil(this.destroy$))   
        .subscribe(data => {
          this.customer = data[0];

          this.customer['phone1'] = this.customer['phone1'].toString().replace(')', '-').replace('(', '');
          if (this.customer['phone2'] != null) {
            this.customer['phone2'] = this.customer['phone2'].toString().replace(')', '-').replace('(', '');
          }
          if (this.customer['phone3'] != null) {
            this.customer['phone3'] = this.customer['phone3'].toString().replace(')', '-').replace('(', '');
          }

          this.editMode = true;
          this.addMode = false;
          this.default = this.customer.state;
        }, error => {
          this.alertify.error(error);
        });
  }else{    
    //inicializando selects
    this.customer['state'] = '';
    this.customer['phoneType'] = '';
    this.customer['phone2Type'] = '';
    this.customer['deliveryState'] = '';
    this.customer['phone3Type'] = '';
  }


    if (this.customerid == 0 ||  this.customerid == '' ) {
      this.addMode = true;
      this.editMode = false;
    }



    // console.log(this.fromSalesOrder)
   


  }


  form = new FormGroup({});
  options: FormlyFormOptions = {
    formState: {
      submitted : false,
    },
  };
  optionsShipping: FormlyFormOptions = {
    formState: {
      submitted : false,
    },
  };

  // customermodel: Partial<Customer> = { state: '',phoneType:'',phone2Type:'',deliveryState:'',phone3Type:''};
  
  fields: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'Id',
          type: 'input',
          className: 'col-md-12 tw-text-md',
          hide: true,
        },
        {
          key: 'firstName',
          type: 'input',
          id: 'input-firstName',
          className: 'col-md-12 input-formly-SOmobille',
          templateOptions: {
            type: 'text',
            placeholder: 'First Name',
            required: true,            
            maxLength: 100,
            minLength: 2,            
            pattern: NrnValidators.FORMATS.name
          },
          validation: {
            messages: {
              require: (error) => `First Name is required.`,
              minlength: (error) => `First Name must be 2 characters.`,
              maxlength: (error) => `First Name no must be more than 100 characters.`,
              pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not valid `,
            },
          },

          // asyncValidators: {
          //   firstName: {
          //     expression: (c:any) =>  new Promise((okay, reject) => {
          //       setTimeout(() => {
          //         okay(/^[A-Za-z ']+$/.test(c.value));

          //       }, 50);
          //     }),
          //     message: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid First Name`,
          //   },
          // },
          
        },
        {
          key: 'lastName',
          type: 'input',
          id: 'input-lastName',
          className: 'col-md-12 input-formly-SOmobille',
          templateOptions: {
            type: 'text',
            placeholder: 'Last Name',
            required: true,
            maxLength: 100,
            minLength: 2,            
            pattern: NrnValidators.FORMATS.name
          },
          validation: {
            messages: {
              require: (error) => `Last Name is required.`,
              minlength: (error) => `Last Name must be 2 characters.`,
              maxlength: (error) => `Last Name no must be more than 100 characters.`,
              pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not valid `,
            },
          },
        },
        {
          key: 'phone1',
          type: 'phone',
          id: 'input-phone1',
          className: 'col-md-6 input-formly-SOmobille',
          templateOptions: {
            placeholder: 'Phone',
            required: this.fielRequired,
            pattern: NrnValidators.FORMATS.maskphone
          },
          validation: {
            messages: {
              pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Phone Number`,
            },
          },
        },
        {
          key: 'phoneType',
          type: 'select',  
          id: 'select-phoneType',       
          className: 'col-md-6 select-formly-SOmobille  tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0',
          templateOptions: {
            type: 'select',
            required: this.fielRequired,
            defaultValue: '',
           
            options: [
              { label: 'Select...', value: ''},
              { label: 'Home', value: 'Home' },
              { label: 'Cell', value: 'Cell' },
              { label: 'Work', value: 'Work' }
            ],
          },

        },
        {
          key: 'phone2',
          type: 'phone',
          id: 'input-phone2',
          className: 'col-md-6  input-formly-SOmobille',
          templateOptions: {
            placeholder: 'Phone2',
            pattern: NrnValidators.FORMATS.maskphone
          },
          validation: {
            messages: {
              pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Phone Number`,
            },
          },
        },
        {
          key: 'phone2Type',
          type: 'select',
          id: 'select-phone2Type',
          className: 'col-md-6  select-formly-SOmobille tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0',
          templateOptions: {
            type: 'select',
            defaultValue: '',
            options: [
              { label: 'Select...', value: '' },
              { label: 'Home', value: 'Home' },
              { label: 'Cell', value: 'Cell' },
              { label: 'Work', value: 'Work' }
            ],
          }
        },
        {
          key: 'email',
          type: 'input',
          id: 'input-email',
          className: 'col-md-12 input-formly-SOmobille',
          templateOptions: {
            type: 'text',
            placeholder: 'Email',
            maxLength: 320,
            minLength: 2,    
            required: false,
            pattern: NrnValidators.FORMATS.email
          },
          validation: {
            messages: {
              minlength: (error) => `First Name requires at least 2 characters.`,
              pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Email`,
            },
          },


        },
        {
          key: 'billingAddress1',
          type: 'input',
          id: 'input-billingAddress1',
          className: 'col-md-12 input-formly-SOmobille',
          templateOptions: {
            type: 'text',
            placeholder: 'Address',
            maxLength:500,
            minLength: 2, 
            required: this.fielRequired
          },
          validation: {
            messages: {
              minlength: (error) => `Billing Address requires at least 2 characters.`,
            },
          },
        },
        {
          key: 'billingAddress2',
          type: 'input',
          id: 'input-billingAddress2',
          className: 'col-md-12 input-formly-SOmobille',
          templateOptions: {
            type: 'text',
            placeholder: 'Address 2',
            maxLength:500,
            minLength: 2, 
          },
          validation: {
            messages: {
              minlength: (error) => `Billing Address 2 requires at least 2 characters.`,
            },
          }
        },
        {
          key: 'city',
          type: 'input',
          id: 'input-city',
          className: 'col-md-12 input-formly-SOmobille',
          templateOptions: {
            type: 'text',
            placeholder: 'City',
            required: this.fielRequired,            
            maxLength:60,
            minLength: 2, 
          },
          validation: {
            messages: {
              minlength: (error) => `City requires at least 2 characters.`,
            },
          }
        },
        {
          key: 'state',
          type: 'select',
          id: 'select-state',
          className: 'col-md-6 select-formly-SOmobille',
          templateOptions: {
            required: this.fielRequired,
            defaultValue: '',           
            options: this.getStates(),
            valueProp: 'abbreviation',
            labelProp: 'name'
          }
        },
        {
          key: 'zip',
          type: 'input',

          id: 'input-zip',
          className: 'col-md-6 input-formly-SOmobille',
          templateOptions: {
            type: 'text',
            placeholder: 'Zip',
            maxLength: 5,
            minLength: 5,
            required: this.fielRequired
          },
          validation: {
            messages: {
              minlength: (error, field: FormlyFieldConfig) => `Zip code must be ${error.requiredLength} digits.`
            },
          },
        },
        //  {
        //    key: 'eventId',
        //    type: 'typeahead',
        //   className: 'col-md-12',
        //   templateOptions: {
        //     placeholder: 'Search for a Event:',
        //     search$: (term) => {
        //       this.searchEvent = term;
        //        if ((!term || term === '')) {
        //          return observableOf(this.events);
        //        }
        //     },
        //     options: this.events
        //   },
        //  },
        // {
        //   key: 'eventId',
        //   type: 'select',
        //  className: 'col-md-12',

        //   templateOptions: {
        //     required: true,
        //     options: this.getEventsObs(),
        //     valueProp: 'CID',
        //     labelProp: 'sponsor'
        //   }
        // },

        {
          key: 'comments',
          type: 'input',
          id: 'input-comments',
          className: 'col-md-12 input-formly-SOmobille',
          templateOptions: {
            type: 'text',
            placeholder: 'comments',
            maxLength:200,
            minLength: 2, 
          },
          validation: {
            messages: {
              minlength: (error) => `comments requires at least 2 characters.`,
            },
          }
        },

      ],
    },
  ];
  
  formShipping = new FormGroup({});
  fieldsShipping: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldGroup: [{
        key: 'fName',
        type: 'input',
        id: 'input-fName',
        className: 'col-md-12 input-formly-SOmobille',
        templateOptions: {
          type: 'text',
          placeholder: 'First Name',
          required: this.fielRequired, 
          maxLength: 100,
          minLength: 2,            
          pattern: NrnValidators.FORMATS.name
        },validation: {
          messages: {
            require: (error) => `First Name is required.`,
            minlength: (error) => `First Name must be 2 characters.`,
            maxlength: (error) => `First Name no must be more than 100 characters.`,
            pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not valid `,
          },
        },
      },
      {
        key: 'lName',
        type: 'input',
        id: 'input-lName',
        className: 'col-md-12 input-formly-SOmobille',
        templateOptions: {
          type: 'text',
          placeholder: 'Last Name',
          required: this.fielRequired, 
          maxLength: 100,
          minLength: 2,            
          pattern: NrnValidators.FORMATS.name
        },
        validation: {
          messages: {
            require: (error) => `Last Name is required.`,
            minlength: (error) => `Last Name must be 2 characters.`,
            maxlength: (error) => `Last Name no must be more than 100 characters.`,
            pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not valid `,
          },
        }
      },
      {
        key: 'phone3',
        type: 'phone',
        id: 'input-phone3',
        className: 'col-md-6 input-formly-SOmobille',
        templateOptions: {
          placeholder: 'Phone',
          pattern: NrnValidators.FORMATS.maskphone
        },
        validation: {
          messages: {
            pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Phone Number`,
          },
        },
      },
      {
        key: 'phone3Type',
        type: 'select',
        id: 'input-phone3Type',
        className: 'col-md-6 select-formly-SOmobille   tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0',
        templateOptions: {
          type: 'select',
          options: [
            { label: 'Select...', value: ''},
            { label: 'Home', value: 'Home' },
            { label: 'Cell', value: 'Cell' },
            { label: 'Work', value: 'Work' }
          ],
        }
      },

      {
        key: 'deliveryEmail',
        type: 'input',
        id: 'input-deliveryEmail',
        className: 'col-md-12 input-formly-SOmobille',
        templateOptions: {
          type: 'text',
          placeholder: 'Email',
          maxLength: 320,
          minLength: 2, 
          required: false,
          pattern: NrnValidators.FORMATS.email
        },
        validation: {
          messages: {
            minlength: (error) => `First Name requires at least 2 characters.`,
            pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Email`,
          },
        },
      },
      {
        key: 'deliveryAddress1',
        type: 'input',
        id: 'input-deliveryAddress1',
        className: 'col-md-12 input-formly-SOmobille',
        templateOptions: {
          type: 'text',
          placeholder: 'Address',
          required: this.fielRequired,
          maxLength:500,
          minLength: 2, 
        },
        validation: {
          messages: {
            minlength: (error) => `Shipping Address requires at least 2 characters.`,
          },
        },
      },
      {
        key: 'deliveryAddress2',
        type: 'input',
        id: 'input-deliveryAddress2',
        className: 'col-md-12 input-formly-SOmobille',
        templateOptions: {
          type: 'text',
          placeholder: 'Address 2',
          maxLength:500,
          minLength: 2, 
        },
          validation: {
            messages: {
              minlength: (error) => `Shipping Address requires at least 2 characters.`,
            },
          },
      },
      {
        key: 'deliveryCity',
        type: 'input',
        id: 'input-deliveryCity',
        className: 'col-md-12 input-formly-SOmobille',
        templateOptions: {
          type: 'text',
          placeholder: 'City',
          required: true,            
          maxLength:60,
          minLength: 2, 
        },
        validation: {
          messages: {
            minlength: (error) => `City requires at least 2 characters.`,
          },
        }
      },
      {
        key: 'deliveryState',
        type: 'select',
        id: 'input-deliveryState',
        className: 'col-md-6 select-formly-SOmobille',
        templateOptions: {
          required: this.fielRequired,         
          defaultValue: '',
          options: this.getStates(),
          valueProp: 'abbreviation',
          labelProp: 'name'
        }
      },
      {
        key: 'deliveryZip',
        type: 'input',
        id: 'input-deliveryZip',
        className: 'col-md-6 input-formly-SOmobille',
        templateOptions: {
          type: 'text',
          placeholder: 'Zip',
          maxLength: 5,
          minLength: 5,
          required: this.fielRequired
        },
        validation: {
          messages: {
            minlength: (error, field: FormlyFieldConfig) => `Zip code must be ${error.requiredLength} digits.`
          },
        },
      }],
    },
  ];



  ngDoCheck() { 
    if(this.form.touched){      
      if(this.name != this.form.value.firstName){
        this.name = this.form.value.firstName
        this.customerService.nameCustomer.next({'firstname':this.name,'lastname':this.lastname,'fromform':1})
      }
      if(this.lastname != this.form.value.lastName){
        this.lastname = this.form.value.lastName
        this.customerService.nameCustomer.next({'firstname':this.name,'lastname':this.lastname,'fromform':1})
      }
    }
  }


  unloadNotification($event: any) {
    if (this.customerForm.dirty) {
      $event.returnValue = true;
    }
  }

  


  
  ngAfterContentChecked() {
    
  }
  ngAfterViewInit() {
//    this.cd.detectChanges();

//    if (this.form.controls['phoneType'] && !this.form.controls['phoneType'].value) {

//    }



  }
  

  getStates(): Observable<any[]> {
    return this.http.get<any[]>('assets/states.json');
  }
  getEventsObs() {

    //console.log(this.form.controls['eventId']);
    //this.fields.controls['eventId'].valueChanges.subscribe((res) => {

    //});
    return observableOf(this.events);
  }

  getEvents() {
    this.eventService.getEvents(1, 200, this.searchEvent)
    .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
          this.events = res.result;
          this.pagination = res.pagination;
          const addevent: any = {};
          addevent['CID'] = this.storeId['storeId'];
          addevent['dbDatets'] = new Date();
          addevent['Status'] = 'Confirmed';
          addevent['sponsor'] = 'Non Event';
          addevent['program1'] = '';
          addevent['program2'] = '';
          addevent['defaultTaxes'] = 0;
          addevent['eventCity '] = '';
          addevent['eventState'] = '';
          addevent['eventCounty'] = '';


        }, error => {
          this.alertify.error(error);
        });

  }

  addCustomer() {

    if (this.form.valid && this.formShipping.valid ) {
      // this.closeEditCustomerChange.emit(false)
      if (this.customer !== undefined) {

        if (this.fromSalesOrder) {
          this.customer['eventId'] = this.eventService.salesOrderEvent.value;
        }
        this.closeEditCustomerChange.emit(false)


        if(this.salesOrderToSave != undefined && this.salesOrderToSave != "" && this.salesOrderToSave != null){
        if(this.salesOrderToSave['soItems'] && this.salesOrderToSave['soItems'].length > 0){
          this.salesOrderToSave['soItems'].forEach((item:any) =>{
            if(item.itemDelivery == 'Direct Shipment' && 
            ((item.deliveryAttn == null || item.deliveryAttn == "" || item.deliveryAttn == " " || item.deliveryAttn == 'null null') && 
            (item.deliveryAddress1 == null || item.deliveryAddress1 == "")  
            && (item.deliveryCity == null || item.deliveryCity == "") 
            && (item.deliveryPhone == "" || item.deliveryPhone == null) 
            && (item.deliveryState == null || item.deliveryState == "") )){
              if(this.salesOrderToSave['fName'] == null){this.salesOrderToSave['fName'] = ''}
              if(this.salesOrderToSave['lName'] == null){this.salesOrderToSave['lName'] = ''}
              item.deliveryAttn = this.customer['fName']+' '+this.customer['lName']  
              item.deliveryAddress1 = this.customer['deliveryAddress1']
              item.deliveryAddress2 = this.customer['deliveryAddress2']
              item.deliveryPhone = this.customer['phone3']
              item.deliveryEmail = this.customer['deliveryEmail']
              item.deliveryCity = this.customer['deliveryCity']
              item.deliveryState = this.customer['deliveryState']
              item.deliveryZip = this.customer['deliveryZip']
            }
          })
        }
      }

      
      if(this.customer['fName'] == '' || this.customer['fName'] == undefined || this.customer['fName'] == null)
      {
        this.copyAddressToShipping()
      }


        this.customer['created_at'] = this.globalService.dNow();
        this.customer['updated_at'] = this.globalService.dNow();
        // this.customer['Id'] = this.globalService.guid();
        this.customerService.addCustomer(this.customer)
        .pipe(takeUntil(this.destroy$))   
          .subscribe((res) => {
            this.alertify.success('Customer Added');
            this.addMode = false;
            setTimeout(() => {
              this.showSelectCustomerChange.emit(false)
              this.showFormChange.emit(false)
            }, 1000);
            
            this.cancelForm();
            // Triggers SalesOrder to add New Customer
            //this.salesOrderService.addCustomerSalesOrder.next(res['Id']);
            
            this.customerService.salesOrderCustomer.next(res[0]['Id']);
            // this.customerService.salesOrderCustomer.next(this.customer['Id']);


          }, error => {
            this.alertify.error(error);
          });

      }

    }else{  
      this.optionsShipping.formState.submitted = true;
      this.options.formState.submitted = true;
      // this.findInvalidControls();
    }

  }

//   public findInvalidControls() {
//     const invalid:any = [];

//     const controls = this.form.controls;
//     for (const name in controls) {
//         if (controls[name].invalid) {
//             invalid.push(name);
//         }
//     }
//     const controlsShi = this.formShipping.controls;
//     for (const name in controlsShi) {
//         if (controlsShi[name].invalid) {
//             invalid.push(name);
//         }
//     }

//     for(let i = 0; i < invalid.length; i++ ){
//       if(invalid[i] =='firstName'){
//         this.alertify.error('The billing field First Name is required'); 
//         }else if(invalid[i] =='lastName'){
//           this.alertify.error('The billing field Last Name is required'); 
//           }else if(invalid[i] =='phone1'){
//             this.alertify.error('The billing field Phone is required'); 
//             }else if(invalid[i] =='phoneType'){
//               this.alertify.error('The billing field Type Phone is required'); 
//               }else if(invalid[i] =='email'){
//                 this.alertify.error('The billing field Email is required'); 
//                 }else if(invalid[i] =='billingAddress1'){
//                   this.alertify.error('The billing field Address is required'); 
//                   }else if(invalid[i] =='city'){
//                     this.alertify.error('The billing field City is required'); 
//                     }else if(invalid[i] =='state'){
//                       this.alertify.error('The billing field State is required'); 
//                       }else if(invalid[i] =='zip'){
//                         this.alertify.error('The billing field Zip is required'); 
                      

//                       }else if(invalid[i] =='fName'){
//                         this.alertify.error('The shipping field First Name is required'); 
//                         }else if(invalid[i] =='lName'){
//                           this.alertify.error('The shipping field Last Name is required'); 
//                           }else if(invalid[i] =='deliveryEmail'){
//                             this.alertify.error('The shipping field Email is required'); 
//                             }else if(invalid[i] =='deliveryAddress1'){
//                               this.alertify.error('The shipping field Address is required'); 
//                               }else if(invalid[i] =='deliveryCity'){
//                                 this.alertify.error('The shipping field City is required'); 
//                                 }else if(invalid[i] =='deliveryState'){
//                                   this.alertify.error('The shipping field State is required'); 
//                                   }else if(invalid[i] =='deliveryZip'){
//                                     this.alertify.error('The shipping field Zip is required'); 
//                                     }
//      }
    
// }

  copyAddressToShipping() {
    // console.log(this.form.value)
    // console.log(this.form.value['firstName'])
    this.customer = Object.assign({}, this.form.value);
    // console.log(this.customer)
    this.customer['fName'] = this.customer['firstName'];
    this.customer['lName'] = this.customer['lastName'];
    this.customer['deliveryAddress1'] = this.customer['billingAddress1'];
    this.customer['deliveryAddress2'] = this.customer['billingAddress2'];
    this.customer['deliveryEmail'] = this.customer['email'];
    this.customer['phone3Type'] = this.customer['phoneType'];
    this.customer['phone3'] = this.customer['phone1'];
    this.customer['deliveryCity'] = this.customer['city'];
    this.customer['deliveryState'] = this.customer['state'];
    this.customer['deliveryZip'] = this.customer['zip'];
  }

  updateCustomer() {
    if (this.form.valid  && this.formShipping.valid ) {
      // this.customer = Object.assign({}, this.form.value);
      
      this.customer['Id'] = this.customerid;
      this.customer['updated_at'] = this.globalService.dNow();
      this.customerService.updateCustomer(this.customer.Id, this.customer)
      .pipe(takeUntil(this.destroy$))   
        .subscribe(() => {
          this.alertify.success('Customer Updated ');
          this.editMode = false;
          this.cancelForm(this.customer.Id.toString());
          this.customerForm.reset(this.customer);

          this.customerService.salesOrderCustomer.next(this.customer.Id);
        }, error => {
          this.alertify.error(error);
        });
    }else{
      this.optionsShipping.formState.submitted = true;
      this.options.formState.submitted = true;
      // this.findInvalidControls();
    }

  }

  editModeSwitch() {
    this.editMode = false;
    this.customerForm.reset();
  }

cancelFormHTML(id?: string){
   this.showFormChange.emit(false)
   this.showSelectCustomerChange.emit(false)
}

cancelForm(id?: string) {   
if (this.fromSalesOrder === false) {
  if (!this.router.url.includes('salesorder')) {
    this.router.navigate(['/customers']);
  }
}
this.change.emit(id);
this.bsModalRef.hide();
}

validateAddress(){
  if(!this.formShipping.valid){
    this.alertify.error('Complete all shipping fields')
    return;
  }
  this.salesOrderService.validateAddress({customer: this.customer, device: localStorage.getItem('device')})
  .pipe(
    takeUntil(this.destroy$))   
  .subscribe((a:any) =>{
    if(a?.messages){
      let show:string = '';
      a.messages.forEach(msg => {
        show = show+msg.summary +'<br> '
      });
      this.alertify.alert(show, ()=>{})
    }else if(a?.taxAuthorities){
      this.validatedAddress = a?.validatedAddresses as Address[];
      // console.log(this.validatedAddress)
      this.modalRefAddress = this.modalService.show(this.popTemplateiOs, { class: 'modal-lg'});
      // this.alertify.alert('the Address is Valid', ()=>{})
    }
  })
}

setAddressFromAvalara(address: Address | null):void{
  // console.log(address)
  if(address != null){
    // Persistance fields
    let persist = {
      fName: this.customer['fName'], 
      lName: this.customer['lName'], 
      phone3: this.customer['phone3'], 
      phone3Type: this.customer['phone3Type'], 
      deliveryEmail: this.customer['deliveryEmail'], 
      entityCode: this.customer['entityCode'], 
    }
    this.customer = Object.assign({}, this.form.value, persist);
    this.customer['deliveryAddress1'] = address.line1
    this.customer['deliveryAddress2'] = address.line2
    this.customer['deliveryState'] = address.region
    this.customer['deliveryCity'] = address.city
    this.customer['deliveryZip'] = address.postalCode.substring(0,5)
  }
  this.modalRefAddress.hide()
}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.customerService.nameCustomer.next({'firstname':'','lastname':'','fromform':0})

  }

}
