<div class="row" style="font-size: initial;color:#747070">
  <div class="col-md-6 tw-text-lg " colspan="3">
   
    <div class="tw-flex tw-w-full">
      <div class="tw-w-1/2" >Items</div>
      <div class="tw-w-1/2">{{ salesOrderItems | number: "1.0" }}</div> 
    </div>
    <div class="tw-bg-aedgraytotal tw-flex tw-w-full">
      <div class="tw-w-1/2" >Discounts</div>
      <div class="tw-w-1/2 ">${{ salesOrderDiscounts | number: "1.2-2" }}</div> 
    </div>
    <div class="tw-flex tw-w-full">
      <div class="tw-w-1/2" >Current Total</div>
      <div class="tw-w-1/2">${{ salesOrderSubTotal | number: "1.2-2" }}</div> 
    </div>
    <div class="tw-bg-aedgraytotal tw-flex tw-w-full">
      <div class="tw-w-1/2" >Delivery</div>
      <div class="tw-w-1/2">${{ salesOrderDelivery | number: "1.2-2" }}</div> 
    </div>
    <div class="tw-flex tw-w-full">
      <div class="tw-w-1/2" >Taxes</div>
      <button *ngIf="showCalculateTaxes && avaTaxActive" class="btn-orange-so-taxes" (click)="calculateTax()">Calculate</button>
      <div *ngIf="!showCalculateTaxes || !avaTaxActive"   class="tw-text-start">${{ ((salesOrder?.totalTaxSum != undefined && salesOrder?.totalTaxSum != null) ? salesOrder?.totalTaxSum : salesOrderTaxes )| number: "1.2-2" }}</div>
    </div>
    <div class="tw-bg-aedgraytotal tw-flex tw-w-full">
      <div class="tw-w-1/2" >Payments</div>
      <div class="tw-w-1/2">${{ salesOrderPayments | number: "1.2-2" }}</div> 
    </div>
    <div class="tw-flex tw-w-full">
      <div class="tw-w-1/2 tw-text-xl tw-font-bold" >Total</div>
      <div class="tw-w-1/2 tw-text-xl tw-font-bold">${{ salesOrderTotal | number: "1.2-2" }}</div> 
    </div>

    <div class="tw-bg-aedgraytotal tw-flex tw-w-full ">
      <div *ngIf="salesOrderTotal - salesOrderPayments >= 0" class="tw-w-1/2" >Balance</div>
      <div *ngIf="salesOrderTotal - salesOrderPayments < 0" class="tw-w-1/2" >Change</div>
      <div class="tw-w-1/2" *ngIf="salesOrderTotal - salesOrderPayments >= 0">
        ${{ salesOrderBalance | number: "1.2-2" }}
      </div>
      <div class="tw-w-1/2"  style="color: red;" *ngIf="salesOrderTotal - salesOrderPayments < 0">
        ${{ salesOrderBalance * -1 | number: "1.2-2" }}
      </div>
    </div>
   
  </div>
  
  <!-- <div class="col" colspan="3">
    <div>{{ salesOrderItems | number: "1.0" }}</div>
    <div class="tw-bg-aedgrayback">${{ salesOrderDiscounts | number: "1.2-2" }}</div>
    <div>${{ salesOrderSubTotal | number: "1.2-2" }}</div>
    <div>${{ salesOrderDelivery | number: "1.2-2" }}</div>
    
    <div>${{ salesOrderTaxes | number: "1.2-2" }}</div>
    <div>${{ salesOrderPayments | number: "1.2-2" }}</div>
    <div style="font-weight: bold;margin-top: 1rem;">${{ salesOrderTotal | number: "1.2-2" }}</div>
    <div style="color: green;" *ngIf="salesOrderTotal - salesOrderPayments > 0">
      ${{ salesOrderBalance | number: "1.2-2" }}
    </div>
    <div style="color: red;" *ngIf="salesOrderTotal - salesOrderPayments < 0">
      ${{ salesOrderBalance * -1 | number: "1.2-2" }}
    </div>
  </div> -->

  <div class="col" >
  </div> 
   <div class="col" >
  </div>
</div>

<!-- 
<div class="sale-order-summary-items">
    <div class="sale-order-summary-items-item">
      <div class="sale-order-summary-items-item-text-mobile">
        <p>Items</p>
      </div>
      <div class="sale-order-summary-items-item-amount-mobile">
        <p>{{ salesOrderItems | number: "1.0" }}</p>
      </div>
    </div>
    <div class="sale-order-summary-items-item">
      <div class="sale-order-summary-items-item-text-mobile">
        <p>Discount</p>
      </div>
      <div class="sale-order-summary-items-item-amount-mobile">
        <p>${{ salesOrderDiscounts | number: "1.2-2" }}</p>
      </div>
    </div>
    <div class="sale-order-summary-items-item">
      <div class="sale-order-summary-items-item-text-mobile">
        <p>Current Total</p>
      </div>
      <div class="sale-order-summary-items-item-amount-mobile">
        <p>${{ salesOrderSubTotal | number: "1.2-2" }}</p>
      </div>
    </div>
    <div class="sale-order-summary-items-item">
      <div class="sale-order-summary-items-item-text-mobile">
        <p>Delivery</p>
      </div>
      <div class="sale-order-summary-items-item-amount-mobile">
        <p>${{ salesOrderDelivery | number: "1.2-2" }}</p>
      </div>
    </div>
    <div class="sale-order-summary-items-item">
      <div class="sale-order-summary-items-item-text-mobile">
        <p>Taxes</p>
      </div>
      <div class="sale-order-summary-items-item-amount-mobile">
        <p>${{ salesOrderTaxes | number: "1.2-2" }}</p>
      </div>
    </div>
    <div class="sale-order-summary-items-item">
      <div class="sale-order-summary-items-item-text big-text">
        <p>Total</p>
      </div>
      <div class="sale-order-summary-items-item-amount big-text">
        <p>${{ salesOrderTotal | number: "1.2-2" }}</p>
      </div>
    </div>
    <div class="sale-order-summary-items-item">
      <div class="sale-order-summary-items-item-text-mobile">
        <p>Payments</p>
      </div>
      <div class="sale-order-summary-items-item-amount-mobile">
        <p>${{ salesOrderPayments | number: "1.2-2" }}</p>
      </div>
    </div>
    <div class="sale-order-summary-items-item">
      <div class="sale-order-summary-items-item-text-mobile">
        <p *ngIf="salesOrderTotal - salesOrderPayments > 0">Balance</p>
        <p *ngIf="salesOrderTotal - salesOrderPayments < 0">Change</p>
      </div>
      
      <div class="sale-order-summary-items-item-amount-mobile" 
      *ngIf="salesOrderTotal - salesOrderPayments > 0">
        <p>${{ salesOrderBalance | number: "1.2-2" }}</p>
      </div>
      <div class="sale-order-summary-items-item-amount-mobile" *ngIf="salesOrderTotal - salesOrderPayments < 0">
        <p>${{ salesOrderBalance * -1 | number: "1.2-2" }}</p>
      </div>
    </div>
  </div> -->