<!-- <div class="mt-2 ml-3 pull-right"

(click)="changeCollapse()">

    <i class="fa fa-arrow-circle-up" *ngIf="isCollapsed==false"></i>
    <i class="fa fa-arrow-circle-down" *ngIf="isCollapsed"></i>
</div>
 -->

<div (click)="changeCollapse()">
    <span class="material-icons" style="color: #00adee; font-size: 40px;" *ngIf="isCollapsed"> keyboard_arrow_up </span>
    <span class="material-icons" style="color: #00adee; font-size: 40px;"  *ngIf="isCollapsed==false"> keyboard_arrow_down </span>
</div>