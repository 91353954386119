import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InvoicereportComponent } from 'src/app/reports/invoicereport/invoicereport.component';
import { EventsService } from 'src/app/_services/events.service';
import { Pagination } from '../../../_modules/pagination';
import { AlertifyService } from '../../../_services/alertify.service';
import { SalesOrderService } from '../../../_services/salesorder.service';
import 'rxjs/add/operator/takeUntil';
import { GlobalsService } from 'src/app/_services/globals.service';
import { FormControl, Validators } from '@angular/forms';
import { Location } from '@angular/common'
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/_services/auth.service';
import moment from 'moment';
import { StoreproductsService } from 'src/app/_services/storeproducts.service';
import { SettingsService } from 'src/app/_services/settings.service';


@Component({
  selector: 'app-salesordersummary',
  templateUrl: './salesordersummary.component.html',
  styleUrls: ['./salesordersummary.component.scss']
})
export class SalesordersummaryComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() forSummary = false;
  @Input() soNumber = '';
  clients: any = {};
  salesorders: any = [];
  pagination: Pagination;
  dataTable: any;
  orderField = 'dbDatets';
  orderDir = 'desc';
  SOIDParam = null;
  dateFilter = '';
  statusFilter = '';
  excludedFilter : boolean;
  VOIDSalesOrders :boolean;
  paginationMode=false;
  event: any;
  editingDate: any;
  counto = 0;
  userRole = '';
  eventid = '';
  SOItemsToShow: any = []
  SOIDSelected :any
  
  showpagination:boolean = false;
  avalaraActive:boolean = false;
  lookingyet:boolean = true;

    //knowinf thr device  
    platform = '';
    showMobile = false;
    isIOS = false;
    isAndroid = false;
  
  
  //Open NavbarOption Filter
  openView = false;
  openStatus = false;
  openExcluded = false;

  editTracking = '';
  formTrackingNumber = new FormControl('');
  formTrackingDate = new FormControl('');
  public tableWidget: any;
  @Output() rowSelected: EventEmitter<number> = new EventEmitter();

  searchSalesOrder = new FormControl('',Validators.pattern('[a-zA-Z0-9 ]*'));
  specialcha:boolean = false;
  inputSearchCss:string = "main-navbar-content-page-search";
  inputSearchCssmobile:string = "main-navbar-content-page-search search-mobile";

  constructor( private salesService: SalesOrderService, private alertify: AlertifyService,
    private route: ActivatedRoute, private router: Router,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private eventService: EventsService,
    private settingsService: SettingsService,
    private global: GlobalsService,
    private location: Location,
    private storeProductService: StoreproductsService,
    private authService:AuthService) { 

      this.route.params      
 .pipe(takeUntil(this.destroy$))  
      .subscribe(params => {
        //this.eventService.salesOrderEvent.next(params['id']);
        this.eventid = params['id'];
        if(params['type'] != null){
          if(params['type'] == 'SO'){
            this.soNumber = params['sonumber']
            this.SOIDParam = this.soNumber;
            this.lookingyet = true
            this.pagination =  {
              totalItems: 10,
              totalPages: 10,
              currentPage: 1,
              itemsPerPage: 10
            };
          }
        }
      });
    }

  situateSO():void{
    if((this.soNumber == null || this.soNumber == undefined || this.soNumber == "" )){
      this.lookingyet = false
      return;
    }
    let items = this.salesorders.filter(e => e.SONumber == this.soNumber) 
    // If items is empty means that is not in this page so we have to retrieve next page
    if(items.length == 0){
     this.SOIDParam = this.soNumber;
     this.statusFilter = ''
     this.dateFilter = ''
     this.excludedFilter = false
     this.VOIDSalesOrders = false
     this.lookingyet = true
     this.loadSalesOrder(-1)
    }else{
      items.forEach(el => {
        // console.log('Found: ',el);
          this.showSalesOrders(el?.SOID, el?.items)
      });
      this.lookingyet = false
      this.SOIDParam = null
    }
  }

  ngOnInit() {
    // this.userRole = localStorage.getItem('role').replace('"', '');
    this.userRole = this.authService.decodeToken('role').role;
    let pag = 1;
    if(this.soNumber == null || this.soNumber == undefined || this.soNumber == "" ){
      pag = this.eventService.pagSOSummary.getValue();
    }

    this.pagination =  {
      totalItems: 10,
      totalPages: 10,
      currentPage: pag,
      itemsPerPage: 10
    };

    this.VOIDSalesOrders = this.eventService.voidSOSummary.getValue();
    this.statusFilter = this.eventService.statusSOSummary.getValue();
    this.pagination.currentPage = pag;
    this.excludedFilter = this.eventService.excludeSOSummary.getValue();
    this.dateFilter = this.eventService.viewSOSummary.getValue();

    this.eventService.salesOrderEvent
    .pipe(takeUntil(this.destroy$))      
    .subscribe((      
      res => {
          this.event = res;
          // console.log( this.eventid)
        this.eventService.getEvent(this.eventid)
        .pipe(takeUntil(this.destroy$))          
        .subscribe( data => {
          // console.log(data)
          this.event = data[0];          
          this.loadSalesOrder(-1);
   
        }, error => {
          this.alertify.error(error);
        });

      }
    ));

    this.platform = navigator.userAgent;
    if (this.platform.match(/Android/i)) {
      this.showMobile = true;
      this.isAndroid = true;
    }
    if (this.platform.match(/iPhone|iPad|iPod/i)) {
      this.showMobile = true;
      this.isIOS = true;
    }

    if (this.platform.includes('Mac')) {
      this.showMobile = false;

    }

    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
      this.isIOS = true;
    }

    if (this.platform.includes('Win')) {
      this.showMobile = false;

    }

    let specificFields = {
      values: [
          "avaTax"
      ]
    }
    this.settingsService.getSpecificSettings(specificFields)
    .pipe(takeUntil(this.destroy$))   
    .subscribe( data => {
      let storeSettings = data[0];
      if(storeSettings['avaTax'] == '1' || storeSettings['avaTax'] == true){
        this.avalaraActive = true
      }
    }, error => {
      this.alertify.error(error);
    });

  }

  updateTrackingnumber(SOID:any,SOItemID:any){

    this.salesService.updateTrackingnumber({"SOItemsID":SOItemID,"TrackingNumber":this.formTrackingNumber.value,"TrackingDate":this.formTrackingDate.value})
    .pipe(takeUntil(this.destroy$))        
      .subscribe(
      (res: any) => {
        this.salesorders.forEach(element => {
          if(element.SOID == SOID){
            element.items.forEach(e => {
              if(e.SOItemsID == SOItemID){
                e.trackingNumber = this.formTrackingNumber.value
                e.trackingDate = this.formTrackingDate.value
              }          
            });
          }
        });
    
        this.SOItemsToShow.forEach(element => {
          if(element.SOItemsID == SOItemID){
            element.trackingNumber = this.formTrackingNumber.value
            element.trackingDate = this.formTrackingDate.value
          }
        });       
    
        this.editTracking = ''; 
        this.alertify.success(res);
      },
      (err:any)=>{
        this.editTracking = '';
        this.alertify.error("Could not update tracking number") 
      }
      ); 
  }
  
  search() {
    this.loadSalesOrder(2);
   
  }

  openViewByNav() {
    this.openView =! this.openView;
    this.openStatus =! true;
    this.openExcluded =! true;
  }

  openStatusNav() {
    this.openStatus =! this.openStatus;
    this.openView =! true;
    this.openExcluded =! true;
  }
  editDateSO(so){
    // console.log(so)
    this.editingDate = JSON.parse(JSON.stringify(so));
  }

  saveDateSO(so){
    let data = {
      newDate: moment(so.dbDatets).format('YYYY-MM-DD')+" "+this.editingDate.dbDatets.slice(-8),
      SOID: so.SOID 
    }
    this.salesService.saveDateSo(data).pipe(tap((data)=> {
      // console.log(data)
    })).subscribe((response:any)=> {
      so.dbDatets = data.newDate
      this.editingDate = null
    });
  }

  openExcludedNav() {
    this.openExcluded =! this.openExcluded;
    this.openView =! true;
    this.openStatus =! true;
  }

  changeDirection(field: string) {

    this.orderField = field;
    if (this.orderDir === 'desc') {
      this.orderDir = '';
    } else {
      this.orderDir = 'desc';
    }


    this.loadSalesOrder(-1);
   
  }
  validateinput(){
       if(this.searchSalesOrder.valid){   
        this.inputSearchCss = "main-navbar-content-page-search" 
        this.inputSearchCssmobile = "main-navbar-content-page-search  search-mobile" 
          this.specialcha = false;
       }else{
        this.inputSearchCss = "main-navbar-content-page-search border-warning"      
        this.inputSearchCssmobile = "main-navbar-content-page-search border-warning search-mobile"   
        this.specialcha = true;
       } 
  }

  showSalesOrders(SOID:any, items:any){
    // console.log(SOID, items);
    if(SOID != ''){
      // console.log(this.SOsItemsStock)
      this.SOItemsToShow = items
      this.SOIDSelected = SOID;
 
    }else{
       this.SOItemsToShow = SOID;
        this.SOIDSelected = []
    }
  }

  loadSalesOrder(toggle:any) {
    
    if(this.searchSalesOrder.valid){   
      this.inputSearchCss = "main-navbar-content-page-search" 
      this.inputSearchCssmobile = "main-navbar-content-page-search  search-mobile"     
      this.specialcha = false;

      if(toggle == 2){
        if(this.statusFilter == "PIF" ||
        this.statusFilter == "Layaway" ||
        this.statusFilter == "Partial" ||
        this.statusFilter == "Order" ||
        this.statusFilter == "Draft" ||
        this.statusFilter == "" ){
          this.excludedFilter = false
          this.VOIDSalesOrders = false
          this.eventService.statusSOSummary.next(this.statusFilter);
          this.eventService.voidSOSummary.next(false);
          this.eventService.excludeSOSummary.next(false);
        }
      }
      
      if(toggle == 0){    
        if(this.VOIDSalesOrders){

          // let data = {
          //   userId: '123',
          //   userName: this.authService.decodeToken('username').username,
          //   section: 'Event Summary',
          //   action: 'View VOID',
          //   eventId: '*',
          //   device: localStorage.getItem('device'),
          // }
          // this.userService.logTracking(data).pipe(takeUntil(this.destroy$)).subscribe(a => {});

          this.statusFilter = "VOID"
          this.excludedFilter = false 
          this.eventService.statusSOSummary.next('VOID');
          this.eventService.voidSOSummary.next(true);
          this.eventService.excludeSOSummary.next(false);
        }else{
          this.statusFilter = ""
          this.eventService.statusSOSummary.next('');
          this.eventService.voidSOSummary.next(false);
        }    
      }
      
      if(toggle == 1){
        if(this.excludedFilter){
          this.statusFilter = ""
          this.VOIDSalesOrders = false          
          this.eventService.statusSOSummary.next('');
          this.eventService.voidSOSummary.next(false);
          this.eventService.excludeSOSummary.next(true);
        }else{
          this.statusFilter = ""
          this.eventService.excludeSOSummary.next(false);
        }
      }

      this.eventService.viewSOSummary.next(this.dateFilter);
      



    
      let cid = '';
    if (this.event != null) {
      cid = this.event['CID'];
    }
    this.salesService.getSalesOrdersSummary(this.pagination.currentPage, this.pagination.itemsPerPage, this.searchSalesOrder.value, this.eventid,
      this.orderField, this.orderDir, this.dateFilter, this.statusFilter, this.excludedFilter, this.SOIDParam)
      .pipe(takeUntil(this.destroy$))        
      .subscribe(
      (res: any) => {
      
        this.salesorders = [];
        if(!this.VOIDSalesOrders){
          this.salesorders = res.result.filter((so) => so.status != 'VOID');
          this.pagination = res.pagination;
        }else{
          this.salesorders = res.result.filter((so) => so.status == 'VOID');
          this.pagination = res.pagination;
          this.statusFilter = ''
        }

        if(this.lookingyet){
          this.situateSO()
        }

        this.showpagination = true;
    }, error => {
      this.alertify.error(error);
    });
   
  }else{
    this.inputSearchCss = "main-navbar-content-page-search border-warning"      
    this.inputSearchCssmobile = "main-navbar-content-page-search border-warning search-mobile"   
    this.specialcha = true;
  }
  }


  
  onKeydown(event) {
    if (event.key === "Enter") {
      this.search();
    }
  }


  selectSalesOrder(salesorder: any) {
    this.salesService.sofrom.next('summary')    
    this.eventService.touchchooseevent.next(false);
    this.salesService.editSalesOrder.next(salesorder);
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Event Summary',
    //   action: 'Edit SO',
    //   eventId: salesorder['SOID'],
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});


    this.router.navigate(['/salesorder/' + salesorder['SOID']]);
  }
  
  showSalesOrder(salesorder: any) {
    this.salesService.sofrom.next('summary') 
    this.salesService.editSalesOrder.next(salesorder);
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Event Summary',
    //   action: 'show SO',
    //   eventId: salesorder['SOID'],
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
    this.router.navigate(['/salesorder/' + salesorder['SOID']+'/edit']);
  }
  previewReport(salesorder: any) {
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Event Summary',
    //   action: 'Preview SO',
    //   eventId: salesorder['SOID'],
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});

    this.openPreview(salesorder);
  }

  async openPreview(salesorder) {
    let wait = await this.global.loadScript();
    if(wait){      
      const initialState = {
        salesOrderMode: true,
        so: salesorder,
        fromcomponent: "salesordersummary",
        sponsor:this.event['sponsor']
      };
      this.bsModalRef = this.modalService.show(InvoicereportComponent, {initialState, class: 'modal-lg'});
    }
  }


  pageChanged(event: any): void {  
    this.pagination.currentPage = event.page;    
    this.eventService.pagSOSummary.next(event.page);
    this.loadSalesOrder(-1);
  }

  clearEvent() {
    this.event = '';
    this.loadSalesOrder(-1);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

  }

  voidTransactionAvalara(soid: string){
    this.storeProductService.voidTransactionAvalara({SOID: soid, void: true, device: localStorage.getItem('device')}).toPromise()
    .then(console.log)
  }

  deleteSalesOrder(salesOrder: any) {
    this.alertify.confirm('Are you sure that you want to delete this Sales Order ' + salesOrder['SONumber']
    + ' ' + salesOrder['firstName'] + ' ' + salesOrder['lastName'], () => {

      this.salesService.deleteSalesOrder(salesOrder['SOID'])
      .pipe(takeUntil(this.destroy$))        
      .subscribe((
        (res) => {
          this.alertify.success('The Sales Order has been converted to VOID');
          this.loadSalesOrder(-1)
          if(salesOrder['avalaraActive'] == '1'){
            this.voidTransactionAvalara(salesOrder['SONumber'])
          }
         
        }
      ), error => {
        this.alertify.error(error);
      });
    }
    ,()=>{
    });

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Event Summary',
    //   action: 'delete SO',
    //   eventId: salesOrder['SOID'],
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
}

revertStatus(salesOrder){
  this.alertify.confirm('Are you sure that you want to restore this Sales Order ' + salesOrder['SONumber']
    + ' ' + salesOrder['firstName'] + ' ' + salesOrder['lastName'], () => {

      this.salesService.revertSalesOrder(salesOrder['SOID'])
      .pipe(takeUntil(this.destroy$))        
      .subscribe((
        (res) => {
          
          this.loadSalesOrder(-1)
          
          this.loadSalesOrder(0)
          this.alertify.success('The Sales Order has been restored');
        }
      ), error => {
        this.alertify.error(error);
      });
    }
    ,()=>{
    });

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Event Summary',
    //   action: 'revert SO from VOID',
    //   eventId: salesOrder['SOID'],
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
}

changeDeliveryStatus(salesOrder) {
    const so = Object.assign({});
    so['orderSigexp'] = atob(salesOrder['orderSigexp']);
    so['SOID'] = salesOrder['SOID'];
    so['deliveryStatus'] = salesOrder['deliveryStatus'];


    so['updated_at'] = this.global.dNow();
    so['modifiedBy'] = this.authService.decodeToken('username').username;

    let thereIsDelivered = false;
    salesOrder?.items.forEach(item => {
      if(item?.tobeDelivered == 'Delivered'){
        thereIsDelivered = true;
      }
    });

    if(thereIsDelivered && salesOrder?.deliveryStatus == 'Pending'){
      this.alertify.confirm("All items are set to Delivered status, Do you want to change all items to Pending?",
            ()=>{
              so['includeItems'] = true
              this.salesService.updateSOOnly(so['SOID'], so)
              .pipe(takeUntil(this.destroy$))        
              .subscribe((res:any) => {
                this.alertify.success('Delivery Status has been updated');
                // console.log(res)
                salesOrder?.items.forEach(item => 
                  res.forEach(itemApi => {
                    if(item?.SOItemsID == itemApi?.SOItemsID){
                      item.tobeDelivered = itemApi?.status;
                    }
                  })
                );
              }, error => {
                this.alertify.error(error);
              });
            },
            ()=>{
              so['includeItems'] = false
              this.salesService.updateSOOnly(so['SOID'], so)
              .pipe(takeUntil(this.destroy$))        
              .subscribe((res:any) => {
                this.alertify.success('Delivery Status has been updated');
                // console.log(res)
                salesOrder?.items.forEach(item => 
                  res.forEach(itemApi => {
                    if(item?.SOItemsID == itemApi?.SOItemsID){
                      item.tobeDelivered = itemApi?.status;
                    }
                  })
                );
              }, error => {
                this.alertify.error(error);
              });

            }
        ); 
    }else{
      so['includeItems'] = true
      this.salesService.updateSOOnly(so['SOID'], so)
      .pipe(takeUntil(this.destroy$))        
      .subscribe((res:any) => {
        this.alertify.success('Delivery Status has been updated');
        // console.log(res)
        salesOrder?.items.forEach(item => 
          res.forEach(itemApi => {
            if(item?.SOItemsID == itemApi?.SOItemsID){
              item.tobeDelivered = itemApi?.status;
            }
          })
        );
      }, error => {
        this.alertify.error(error);
      });
    }

   

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Event Summary',
    //   action: 'Change Delivery Status(SO)',
    //   eventId: so['SOID'],
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});

}

changeDeliveryStatusItem(soItem) {
  // console.log(soItem)
  this.salesService.updateDeliverySOItem(soItem['SOItemsID'], soItem['tobeDelivered'])
  .pipe(takeUntil(this.destroy$))        
  .subscribe((res:any) => {
    this.alertify.success('Delivery Status has been updated on this Item');
    this.salesorders.forEach(so => {
      if(so?.SOID == res?.SOID){
        so.deliveryStatus = res?.status;
      }
    });
  }, error => {
    this.alertify.error(error);
  });
}

changeExcludeFromPO(salesOrder) {
  const so = Object.assign({});
  so['orderSigexp'] = atob(salesOrder['orderSigexp']);
  so['SOID'] = salesOrder['SOID'];
  so['deliveryStatus'] = salesOrder['deliveryStatus'];

  so['excludeFromPoB'] = salesOrder['excludeFromPoB'];
  
  if (so['excludeFromPoB'] !== true && so['excludeFromPoB'] !== 1 && so['excludeFromPoB'] !== '1') {
    so['excludeFromPoB'] = null;
  }
  so['updated_at'] = this.global.dNow();
  so['modifiedBy'] = this.authService.decodeToken('username').username;

  this.salesService.updateSOOnly(so['SOID'], so)
  .pipe(takeUntil(this.destroy$))        
  .subscribe(res => {
    if (so['excludeFromPoB'] !== null) {
      this.alertify.success('Sales Order will be excluded from PO creation.');
    
    } else {
      this.alertify.success('Sales Order will not be excluded from PO creation.');
      
    }
    
  }, error => {
    this.alertify.error(error);
  });

  // let data = {
  //   userId: '123',
  //   userName: this.authService.decodeToken('username').username,
  //   section: 'Event Summary',
  //   action: 'Exclude from PO',
  //   eventId: salesOrder['SOID'],
  //   device: localStorage.getItem('device'),
  // }
  
  // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
  // .subscribe(a => {});



}







}




