import { Component, OnInit, OnDestroy, NgZone } from '@angular/core';
import { DevicesService } from 'src/app/_services/devices.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { AuthService } from 'src/app/_services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CardReaderService } from 'src/app/_services/cardreader.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
declare let SetDevice: any;
@Component({
  selector: 'app-adddevices',
  templateUrl: './adddevices.component.html',
  styleUrls: ['./adddevices.component.scss']
})
export class AdddevicesComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  step = 0;
  devices: any = [];
  letterToUse = '';
  device =  {};
  devicefound = [];
  deviceError = null;
  constructor(    private devicesService: DevicesService, private alertify: AlertifyService,
    private globals: GlobalsService,
    public bsModalRef: BsModalRef,
    private _ngZone: NgZone,
    private _route: ActivatedRoute,
    private _router: Router,
    private cardReaderService: CardReaderService,
    private modalService: BsModalService,
    private authService: AuthService) {

    }

  ngOnInit() {
    this.load();
  }

  load() {
    this.device = {};
    this.cardReaderService.deviceid.next('');
    this.cardReaderService.devicemac.next('');
    
    this.cardReaderService.setDeviceID();
   this.cardReaderService.deviceid
   .pipe(takeUntil(this.destroy$))   
   .subscribe(
     (deviceid: any) => {
      this.device['MacDevice_tx'] = deviceid;
      
     }, error => {
       this.alertify.error(error);
     });

     this.cardReaderService.devicemac
     .pipe(takeUntil(this.destroy$))   
     .subscribe(
       (devicemac: any) => {
        this.device['MacReader_tx'] = devicemac;
        this.checkIfRepeated(devicemac);
      }, error => {
        this.alertify.error(error);
      });

      this.cardReaderService.deviceError
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
        //  console.log(res);
         this.deviceError = res;
       }, error => {
         this.alertify.error(error);
       });
 
      this.retry();
  }

  retry() {
    this.step = 0;
    this.deviceError = null;
    
  }

  checkIfRepeated(mac) {

    for (let index = 0; index < this.devices.length; index++) {
      const element = this.devices[index];
    //  console.log('Checking' + element['MacReader_tx']);
      
    }

    this.devicefound = this.devices.filter(x => x.MacReader_tx === mac);

    if (this.devicefound.length > 0) {

    } else {
      if (mac.length > 0) {
        this.next();
        if (this.step === 0)  {
        this.step =1;
        }
      }
    }
  }


  getMAC(v) {
    this.device['MacReader_tx'] = v;
  }


  next() {
    this.letterToUse = String.fromCharCode( this.devices.length + 1 + 64);
    this.step = this.step + 1;
    if (this.step === 1) {
      if (this.devicefound.length == 0) {
      this.cardReaderService.setDevice();
      }
    }
  }

  
  finish() {
    this.devicesService.editDevice.next('');
    this.bsModalRef.hide();
    this.ngOnDestroy();

  }

  create() {
    
    this.device['yy_DeviceID_tx'] = this.globals.guid();
    this.device['Label_tx'] = this.letterToUse;
    
    this.devicesService.addDevice(this.device)
    .pipe(takeUntil(this.destroy$))   
    .subscribe(
      (res: any) => {
        this.devices = res.result;
        this.alertify.success('Card Reader added ' + this.letterToUse + ' successfully');

      this.devicesService.editDevice.next('');
      this.bsModalRef.hide();
      this.ngOnDestroy();

    }, error => {
      this.alertify.error(error);
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this._router.navigate([], {
      queryParams: {

      }
    });
  }



}
