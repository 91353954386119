import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterDevices'
})
export class FilterDevicesPipe implements PipeTransform {

  transform(items: any[], filter: Object): any[] {
    if (!items || !filter) {
      return items;
  }
  return items.filter(e => e.active == true)
  }

}
