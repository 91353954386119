import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';
import { PaginatedResult } from '../_modules/pagination';



@Injectable({
  providedIn: 'root'
})
export class EventsService {
  baseUrl = environment.apiUrl;
  laravel = environment.apiLaravel;
  salesOrderEvent = new BehaviorSubject<string>('0');
  loadsalesOrderEvent = new BehaviorSubject<boolean>(true);
  jsonevent = new BehaviorSubject<any>('');
  manyEvents = new BehaviorSubject<any>([]);
  touchchooseevent = new BehaviorSubject<any>(false);
  closeChooseEvent= new BehaviorSubject<any>(false);
  fundraiser = new BehaviorSubject<any>('0');

  pagSOSummary = new BehaviorSubject<number>(1);
  statusSOSummary = new BehaviorSubject<string>('');
  viewSOSummary= new BehaviorSubject<string>('');
  statusPOSummary = new BehaviorSubject<string>('');
  viewPOSummary= new BehaviorSubject<string>('');
  voidSOSummary= new BehaviorSubject<boolean>(false);
  excludeSOSummary= new BehaviorSubject<boolean>(false);
  

  pagPOSummary = new BehaviorSubject<number>(1);
  pagSOList = new BehaviorSubject<number>(1);
  inputSearchPOSummary = new BehaviorSubject<string>('');
  inputSearchSOList= new BehaviorSubject<string>('');

  statusSOsList= new BehaviorSubject<string>('');
  viewSOsList= new BehaviorSubject<string>('');
  voidSOsList= new BehaviorSubject<boolean>(false);
  excludeSOsList= new BehaviorSubject<boolean>(false);


  purchaseOrderEvent = new BehaviorSubject<string>('0');
  editEvent = new BehaviorSubject<string>('0');
  EventSOAndPO = new BehaviorSubject<any>([]);

  //event list search
  search_input= new BehaviorSubject<string>('');
  search_toggle = new BehaviorSubject<string>('');
  search_date = new BehaviorSubject<string>('');
  search_page = new BehaviorSubject<string>('');


constructor(private http: HttpClient) { }

getEvents(page?, itemsPerPage?, searchEvent?, dateFilter?) { 
  let params = new HttpParams();
  const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();

  if ( page != null && itemsPerPage != null) {
    params = params.append('page', page);
    params = params.append('per_page', itemsPerPage);

  }

  if (searchEvent != null) {
    params = params.append('searchEvent', searchEvent);
  }

  if (dateFilter != null) {
    params = params.append('dateFilter', dateFilter);
  }


  return this.http.get<any>(this.laravel + 'events', {observe: 'response', params})
  .pipe(
    map( response => {
      paginatedResult.result = (response.body.data);
      if (response.body.total != null) {
        paginatedResult.pagination = {
          totalItems: JSON.parse(response.body.total),
          totalPages: JSON.parse(response.body.last_page),
          currentPage: JSON.parse(response.body.current_page),
          itemsPerPage: JSON.parse(response.body.per_page)
        };
      }
      return paginatedResult;
    })
  );
  }
  getEventsToday(today){
    return this.http.post<any>(this.laravel + 'getEventsToday', today)
  }

  updateYTD(year){
    return this.http.get<any>(this.laravel + 'updateYTD/'+ year)
  }  

  getTotalEvents() {
    let result = 0;
    return this.http.get<any>(this.laravel + 'eventstotal')
    .pipe(
      map( response => {
        result = (response);
        return result;
      })
    );
  }

  getEvent(id: string): Observable<any> {
    return this.http.get<any>(this.laravel + 'events/' + id);
  }
  getEventSpecific(id: string, fields:any): Observable<any> {
    return this.http.post<any>(this.laravel + 'getEventSpecific/' + id, fields);
  }

  addEvent(event: any) {
    return this.http.post(this.laravel + 'events', event);
  }

  updateEvent(id, event: any) {
    return this.http.patch(this.laravel + 'events/' + id, event);
  }

  updateFutureEventsLayout(layout: any) {
    return this.http.post(this.laravel + 'updateFutureEventsLayout', layout);
  }

  deleteEvent(id) {
    return this.http.delete(this.laravel + 'events/' + id);
  }

  editedEvent(id) {
    this.editEvent.next(id);
  }

  checkEventSOAndPO(){
    return this.http.get(this.laravel+'checkEventSOAndPO');
  }

  getRawEvents(){
    return this.http.get(this.laravel+'getRawEvents');
  }

  markAsRead(values:any){
    return this.http.post(this.laravel+'markAsRead', values);
  }

  checkSOToPO(){
    return this.http.get(this.laravel+'checkSOToPO');
  }

   getDDSEvents(): Observable<any[]> {
     return this.http.get<any[]>(this.laravel + 'eventsDDS');
   }

   getNewDDSEvents(): Observable<any[]> {
    return this.http.get<any[]>(this.laravel + 'eventsNewDDS');
  }


}
