import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, Type } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs/Subscription';
import { Pagination } from '../../../_modules/pagination';
import { AlertifyService } from '../../../_services/alertify.service';
import { AuthService } from '../../../_services/auth.service';
import { ReportingService } from '../../../_services/reporting.service';
import { SalesOrderItemsService } from '../../../_services/salesorderitems.service';
import { StoreproductsService } from '../../../_services/storeproducts.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { AddsalesorderitemComponent } from '../../salesorder/addsalesorderitem/addsalesorderitem.component';
import 'rxjs/add/operator/takeUntil';
import { AddstockitemComponent } from './addstockitem/addstockitem.component';
import { StockItemsService } from 'src/app/_services/stockitems.service';
import * as _ from 'lodash';
import { Observable, Subject } from 'rxjs';
import { map, startWith, takeUntil, tap } from 'rxjs/operators';
import { StoreProductsReportComponent } from 'src/app/reports/store-products-report/store-products-report.component';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'src/app/_services/user.service';
import { ManuIndex } from 'src/app/_resolvers/ManuSyno';
import { TypeIndex } from 'src/app/_resolvers/TypeSyno';
import { SizesIndex } from 'src/app/_resolvers/SizeSyno';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { SettingsService } from 'src/app/_services/settings.service';
@Component({
  selector: 'app-storeproducts',
  templateUrl: './storeproducts.component.html',
  styleUrls: ['./storeproducts.component.scss'],
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class StoreproductsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() salesOrderMode = false;
  @Input() stockitems = false;
  @Input() fromEvent = false;
  @Input() show :boolean = true;
  showAva: boolean = false
  showAvalaraButton: boolean = false
  radiofilterBy = new FormControl('filter');
  radiofilterBystore = new FormControl('search');
  storeProducts: any = [];
  oneSearch = '';
  inputFocused: string = '';
  companies: any = [''];
  styles: any = [];
  sizes: any = [];
  products: any = [];
  @Input() pagination: Pagination;
  @Input() itemsPerPage = 10;
  totalItems = 0;
  subscriptions: Subscription[] = [];
  selectedStyle = '';
  selectedMan = '';
  selectedSize = '';
  selectedProduct = '';
  data: any = [];
  storeExcel: any = [];
  toSaveStoreProduct: any = [];
  showSave = false;
  isDealer = false;
  maxsize = 10;
  showTypeOptions = false;
  // Variables para Filtro con JS
  //State
  recordProducts: any = [];
  recordProductsToQRC = [];
  recordProductsDefault: any[];
  searched = false;
  //Abre el filtro ya seleccionado
  searchedInput = false;
  //Variables que estan por Default
  companiesDefault: any = [''];
  stylesDefault: any = [];
  sizesDefault: any = [];
  productsDefault: any = [];
  avalara: any = [];
  productsAvalara: any = [];
  avalaraRecords: any = [];
  //Total de Itesmen numeros
  totalitems = 0;
  p: number = 1;
  addToStock = false;
  filter = true;
  tabFilterNav = false;
  filterManu = '';
  filterStyle = '';
  filterSize = '';
  filterProduct = '';
  productbeingModified = '';
  filters = 0 ;
  Changefilter: boolean = true;
  Changefilterstore: boolean = false;
  //variables carga filter
  selectStyle:any;
  selectSize:any;
  optionsMat: string[] = ['One', 'Two', 'Three'];
  myControl = new FormControl('');
  avalaraGroup: FormGroup;
  filteredOptions: Observable<string[]>;
  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.optionsMat.filter((option:any) => option?.descriptionlong?.toLowerCase().includes(filterValue));
  }
  optionSelected(value:any, product: any, option: any){
    let filtered = this.avalara.find((p: any) => p.descriptionlong == value)
    product.description = filtered.description
    product.code = filtered.code
    // console.log(value, product, option, this.productsAvalara)
  }
  // focusInput(product: string): void{
  //   if(product != this.productbeingModified){
  //     this.myControl.reset()
  //     this.productbeingModified = product
  //   }
  //   // this.productbeingModified == '' ? this.productbeingModified = '' : this.productbeingModified = product
  // }
  options = {
    manufaturer : [],
    size : [],
    type : [],
    style : [],
  }

  model = {
    avalaraTypes: [],
  };
  loadsponsorscontributions:any = false
  form = new FormGroup({});
  option:  FormlyFormOptions = {
    formState: {
      submitted : false,
    },
  };
  //fields: FormlyFieldConfig[] = [{}];
  globalsizes = [];
  recordProductsNoFiltered: any[];

  submit() {
    // console.log(this.productsAvalara)
    this.model.avalaraTypes = this.productsAvalara.filter(a => a.code != undefined)
    this.storeProductsService.updateAvalaraTax(this.model)
      .pipe(takeUntil(this.destroy$))   
      .subscribe((result) =>{
        // console.log(result)
        this.alertify.success("Success")
    })
  }

  showAvalara(){
    this.showAva = !this.showAva;
    // console.log(this.products.map((p:any) => p.product))
  }

  changefilter(){
    if(this.radiofilterBy.value == 'filter'){
      this.Changefilter = true
    }else {
      this.Changefilter = false
    }
    this.clearFilter();
    this.recordProducts = []
  }

  changefilterstore(){
    this.recordProducts = this.recordProductsDefault
    if(this.radiofilterBystore.value == 'filter'){
      this.Changefilterstore = true
      this.megaSearch()
    }else {
      this.Changefilterstore = false
      this.loadSelectFilters('','MFGID');
    }
  }

  clearALL() {
    this.selectedMan = '';
    this.selectedStyle = '';
    this.selectedSize = '';
    this.selectedProduct = '';
    this.searched = false;
    this.recordProducts = this.recordProductsDefault;
    this.defaultFilters();
    this.totalitems = this.recordProducts.length;
  }

  openFilterMenu() {
    this.filter = !this.filter;
  }

  openTabFilter(){
    this.clearALL();
    this.clearsearchInputApply();
    this.tabFilterNav = !this.tabFilterNav;
  }
  clearsearchInputApply( ) {
    //Inputs Apply
    this.filterManu = '';
    this.filterStyle = '';
    this.filterSize = '';
    this.filterProduct = '';
    this.searchedInput = false;
    this.recordProducts = this.recordProductsDefault;
    this.totalitems = this.recordProducts.length;
  }

  numPorcent = new FormControl(100,[Validators.min(0.01),Validators.max(10000)]);
  numPriceHeigth = new FormControl(0,[Validators.min(0.01),Validators.max(100000)]);
  lastItem:any;  
  onlyCero :boolean = false;
  viewSalesPriceUpdate :boolean = false;
  SPTypeView = 'dollar';
  actionModal:string = '';
  filteredManu:boolean = false;
  filteredStyle:boolean = false;
  radioUpdateBy = new FormControl('dollar');
  radioDecimal = new FormControl('Yes');
  radioOverwrite = new FormControl('OnlyCero');
  productsQRSettings:any;
  toSaveQR:boolean = false;

  

  constructor(private storeProductsService: StoreproductsService,   
    
    private alertify: AlertifyService,
    private chRef: ChangeDetectorRef,
    private router: Router,    
    private itemsService: SalesOrderItemsService,
    private reportingService: ReportingService,
    private settingsService: SettingsService,
    private fb: FormBuilder,
    private globals: GlobalsService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService, private authService:AuthService
  ) { }

  ngOnInit() {
    //  this.chRef.detach();
    // console.log(this.recordProducts);
    
    this.pagination = {
      totalItems: 10,
      totalPages: 10,
      currentPage: 1,
      itemsPerPage: this.itemsPerPage
    };

    if (this.globals.isMobile()) {
      this.maxsize = 4;
    }

    if (this.router.url.toString().includes('storefront') && this.authService.decodeToken('role').role !== 'Manager') {
      this.isDealer = true;
    }

    if (this.router.url.toString().includes('storefront') ){
      this.Changefilter = false
    }

    this.loadStoreProductsLocal();
    // console.log('1')
    this.subscriptions.push(
      this.storeProductsService.storeEvent
      .pipe(takeUntil(this.destroy$))   
        .subscribe(() => {
          this.oneSearch = ''
          this.selectedManMod = this.storeProductsService.storeSID.value;
          this.selectedStyleMod = this.storeProductsService.storeStyleID.value;
          this.loadStoreProductsLocal();
          // console.log('2')
          this.searchSelectApply();
          //this.loadStoreProductsLocal();
        })
    );

    // this.filteredOptions = this.myControl.valueChanges.pipe(
    //   startWith(''),
    //   map((value:any) => this._filter(value || '')),
    // );

    
    this.avalaraGroup = this.fb.group({})
    this.filteredOptions = this.avalaraGroup.valueChanges.pipe(
      startWith(''),
      map((value:any) => this._filter(value[this.inputFocused] || '')),
    );
    let specificFields = {
      values: [
          "avaTax"
      ]
    }
    this.settingsService.getSpecificSettings(specificFields)
    .pipe(takeUntil(this.destroy$))   
    .subscribe( data => {
      let storeSettings = data[0];
      if(storeSettings['avaTax'] == '1' || storeSettings['avaTax'] == true){
        this.showAvalaraButton = true
      }
    }, error => {
      this.alertify.error(error);
    });
  }

  onFocus(product): void{
    // if(this.inputFocused != product){
    //   this.avalaraGroup.get(product).patchValue('')
    // }
    this.inputFocused = product;
  }

  searchAgain(){    
    this.clearALL();
    this.clearsearchInputApply();
    this.tabFilterNav = true
    this.searched = false
    this.filter = true
    this.oneSearch = ''
    this.showTypeOptions = false
    this.p = 1
  }

  megaSearch(){    

    if(this.oneSearch != ''){
      var wordpieces = [] = this.oneSearch.trim().split(" ");
      let filtered = this.recordProductsDefault
      wordpieces.forEach((word:any) => {       
        filtered = filtered.filter(x => (x.manufacturer+' '+x.product+' '+x.sizeName+' '+x.styleName+' '+x.SKU).toLowerCase().includes(word.toLowerCase()))
      })

      this.recordProducts = filtered;
      this.searched = true
      this.totalitems = this.recordProducts.length
      this.p = 1
      // console.log(filtered)
    }else{
      if(this.salesOrderMode){
        this.clearFilterFromSO();
      }else{
        this.recordProducts = this.recordProductsDefault
        this.totalitems = this.recordProducts.length
      }
    }



    // let filteredProducts = []
    // let repetitions = wordpieces.length;
    // this.recordProducts = this.recordProductsDefault;

    // let grouping = {
    //   manufaturer : [],
    //   size : [],
    //   type : [],
    //   style : [{"style":''}],
    // }

    // console.log(wordpieces) 
    // // defined the group every worj belongs using indexes
    
    // wordpieces.forEach((word:any) => {
    //   if( ManuIndex.All.toLowerCase().includes(word.toLowerCase()) ){
    //     grouping.manufaturer.push({"manufacturer":this.sortByIndex(word, ManuIndex)})
    //   }else if( SizesIndex.All.toLowerCase().includes(word.toLowerCase()) ){
    //     grouping.size.push({"size": this.sortByIndex(word, SizesIndex) })
    //   } else if( TypeIndex.ALL.toLowerCase().includes(word.toLowerCase()) ){
    //     grouping.type.push({"type": this.sortByIndex(word, TypeIndex)})
    //   } else{
    //     grouping.style[0].style = (grouping.style[0].style+' '+word).trim()
    //   }
    // })

    // this.options = grouping
    // // sort the group by length
    // //get all posibilities 
    // function cartesian(...arg) {
    //   var args = arg.filter((f)=> f.length > 0)
    //       var r = [], max = args.length-1;
    //       function helper(arr, i) {
    //           for (var j=0, l=args[i].length; j<l; j++) {
    //               var a = arr.slice(0); // clone arr
    //               a.push(args[i][j]);
    //               if (i==max)
    //                   r.push(a);
    //               else
    //                   helper(a, i+1);
    //           }
    //       }
    //       helper([], 0);
    //       return r;
    // }
    
    // let result  = cartesian(grouping.manufaturer, grouping.type , grouping.size, grouping.style)
    // let queries = []
    // result.forEach(element => { 
    //     let item = {}
    //     element.forEach(el => { 
    //     for(const i in el){
    //       item[i] = el[i]
    //       }
    //     })
    //     queries.push(item)
    // });
    

    // // for(const search in grouping){
    // //   console.log(grouping[search])
    // //   if(grouping[search].length == 1){
    // //     this.options[0].push(grouping[search][0])
    // //   }else if(grouping[search].length > 1){
    // //     grouping[search].forEach(element => {
    // //       this.options[1].push(element)
    // //     });      
    // //   }
    // // }




    // // console.log(grouping)
    // // console.log(this.options)
    // // console.log(queries)



    // // execute queries 
    // queries.forEach(query => {
    //   this.recordProducts.forEach((product:any)=>{

    //       if(((product.companyName.toLowerCase()).indexOf( query.manufacturer ? query?.manufacturer.toLowerCase() : '') > -1) 
    //       && ((product.product.toLowerCase()).indexOf(query.type ? query?.type.toLowerCase() : '') > -1) 
    //       && ((product.sizeName.toLowerCase()).indexOf( query.size ? query?.size.toLowerCase() : '') > -1) 
    //       && ((product.styleName.toLowerCase()).indexOf(query.style ? query?.style.toLowerCase() : '') > -1)){
    //           filteredProducts.push(product)
    //       }
        
    //   })
    // });

    // // console.log(filteredProducts)
    // this.recordProducts = filteredProducts;
    // this.recordProductsNoFiltered = filteredProducts
    // this.searched = true
    // this.totalitems = this.recordProducts.length
    // // console.log(this.salesOrderMode , this.searched , this.oneSearch)

  }



  sortByIndex(word:string, index: any):string{

    for(const manufac in index){
      if( index[manufac].toLowerCase().includes(word.toLowerCase()) ){
        return manufac
      }
    }
  }
  filterBy(field, text){
    // console.log(this.recordProductsNoFiltered)
    // console.log(field, text)
    let filetered = this.recordProductsNoFiltered.filter(p => {return p[field].toLowerCase().includes(text.toLowerCase()) == true} )
    // console.log(filetered)
    this.recordProducts = filetered
  }
  
  selectAType(type:string){
    let filtered =this.recordProducts.filter((p:any)=>{
      return p.Type == type
    })
    this.recordProducts = filtered;
    this.searched = true
  }






  getProductsQRSettings(){
    this.storeProductsService.getToExpQRC().
    toPromise()
    .then((data:string) =>{
      this.productsQRSettings = []
      this.productsQRSettings =  data;      
      this.loadProductsQRSettings();
    })
    .catch((err) =>{
    });
  }


  loadProductsQRSettings(){

    this.recordProductsToQRC = [];
    let convert = JSON.parse(this.productsQRSettings[0].exportToQRC);    
    convert.forEach(element => {     
    let json = JSON.parse(element)
    var record = this.recordProducts.filter((item) => item.productsId === json.productsId);
    if(record.length > 0){
      this.recordProductsToQRC.push(record[0]);
    }
    });

  }

  ViewSalesPriceUpdate(){
    this.viewSalesPriceUpdate = !this.viewSalesPriceUpdate
    this.numPorcent.setValue(100);
    this.numPriceHeigth.setValue(0);

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Store',
    //   action: 'View Sales Price Updater',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }

  toogleView(type: string) {
    if (this.SPTypeView !== type) {
        this.SPTypeView = type;
    }
    this.numPorcent.setValue(100);
    this.numPriceHeigth.setValue(0);
    this.radioUpdateBy.setValue(type)
  }
  toogleDecimal(type: string){    
    this.radioDecimal.setValue(type)
  }

  hideModal() {
    this.bsModalRef.hide();
  }

  refreshItems(){
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Store',
    //   action: 'Refresh Items',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});

    if (this.salesOrderMode === false) {
      this.oneSearch = '';
      this.initialVars();
    } 
    this.loadStoreProductsLocal();

  }
  clearFilterFromSO(){
    this.clearFilter();
    this.recordProducts = []
    this.totalitems = this.recordProductsDefault.length
    this.oneSearch = ''
  }
  clearFilter() {  
    if(this.toSaveStoreProduct.length > 0){
      this.alertify.confirm("You have data that you still don't save, are you sure to reload the data", () => { 
        this.defaultFilters();
        this.initialVars();
      }
      ,()=>{
      }); 
    }else{
       this.defaultFilters();
       this.initialVars();
    }
  
  }

  clearSearch(){
    this.recordProducts = this.recordProductsDefault
    this.oneSearch = ''
    this.totalitems = this.recordProducts.length
  }

  initialVars(){
    this.searched = false
     this.showSave = false;
    this.selectedMan = '';
    this.selectedStyle = '';
    this.selectedSize = '';
    this.selectedProduct = '';
    this.numPorcent.setValue(100)
    this.numPriceHeigth.setValue(0)
    this.viewSalesPriceUpdate = false;
    this.lastItem = [];
    this.filteredManu = false;
    this.filteredStyle = false;
    this.recordProducts = this.recordProductsDefault;
    this.totalitems = this.recordProducts.length;
  }

  loadStoreProducts() {
    this.storeProductsService.getStoreProducts(this.pagination.currentPage,
      this.pagination.itemsPerPage, this.selectedMan, this.selectedStyle
      , this.selectedSize, this.selectedProduct)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {

          this.storeProducts = res.result.data;
          this.storeProductsService.allStoreProducts = Object.assign([],this.storeProducts);
          this.storeProducts.forEach(element => {
            element['salesPriceNum'] = element['salesPriceNum'].toString().replace('.00', '');
          });
          this.pagination = res.pagination;
          this.totalItems = this.pagination.totalItems;

          this.companies = res.result.companies.filter(size => size.companyName != "");
          this.styles = res.result.styles.filter(size => size.styleName != "");
          this.sizes = res.result.sizes.filter(size => size.sizeName != "");
          this.products = res.result.products.filter(size => size.product != "");

        }, error => {
          this.alertify.error(error);
        });
  }

  openModalConfirm(action) {
    if(this.filteredStyle){  
    this.actionModal = action;
    if(this.actionModal == 'dollar'){
      if(this.numPriceHeigth.value > 0){

      this.alertify.confirm("Are you sure to update sales prices in these products",()=>{
      this.onSalesPriceItemMajor()
      },()=>{})

        // this.bsModalRef = this.modalService.show(template);
        // this.onlyCero = false
      }else{
        this.alertify.error("Sales Price must be greater than 0");
      }
    }
    if(this.actionModal == 'percent'){
      if(this.numPorcent.value > 0){
        
        this.alertify.confirm("Are you sure to update sales prices in these products",()=>{
          this.onSalesPriceItemPercent()
        },()=>{})
        // this.bsModalRef = this.modalService.show(template);
        // this.onlyCero = false
      }else{
        this.alertify.error("Percentage must be greater than 0");
      }
    }
  
    }else{          
        this.alertify.error('You need to select items on manufacturers only by style')
    }   
    
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Store',
    //   action: 'Apply Prices with Price Updater '+action,
    //   eventId: action == 'percent' ? 'percentage: '+this.numPorcent.value : this.numPriceHeigth.value,
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }


  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    //this.loadStoreProducts();
  }


//UPDATE PRICE
SalesPriceUpdate_0(){
  if(this.filteredStyle){
       this.alertify.confirm('Are you sure to update the Sales Price to 0 ?', () => {

  let recordProductsPorc = [];
  let numAffect = 0;
  this.toSaveStoreProduct = [];

  this.recordProducts.forEach(element => {     
    numAffect ++;
    element['salesPriceNum'] = 0 ;
    this.toSaveStoreProduct.push(element);  
    recordProductsPorc.push(element)          
  });

  this.recordProducts = recordProductsPorc;        
  this.showSave = true;    
  this.alertify.success(numAffect+' Sales Price update to 0')
}
,()=>{
}); 
  }else{          
    this.alertify.error('You need to select items on manufacturers only by style')
}

}
onSalesPriceItemMajor(){
 
    this.hideModal()
    let recordProductsPorc = [];
      let numAffect = 0;
      this.toSaveStoreProduct = [];

      this.recordProducts.forEach(element => {
     
        if(this.radioOverwrite.value == 'OnlyCero'){
            if(element['salesPriceNum'] == 0){
              numAffect ++;
              
              if(this.radioDecimal.value == 'Yes'){
              element['salesPriceNum'] =  this.globals.pNumberDecimal((element['wholeSalePriceNum'] / this.lastItem.wholeSalePriceNum) * this.numPriceHeigth.value) ;
            
              }else{
                element['salesPriceNum'] = this.globals.pNumberInt((element['wholeSalePriceNum'] / this.lastItem.wholeSalePriceNum) * this.numPriceHeigth.value) ;                  
              }
              this.toSaveStoreProduct.push(element); 
            } 
        }else{
              numAffect ++;
              if(this.radioDecimal.value == 'Yes'){
                element['salesPriceNum'] = this.globals.pNumberDecimal((element['wholeSalePriceNum'] / this.lastItem.wholeSalePriceNum) * this.numPriceHeigth.value) ;
              }else{
                element['salesPriceNum'] = this.globals.pNumberInt((element['wholeSalePriceNum'] / this.lastItem.wholeSalePriceNum) * this.numPriceHeigth.value) ;
              }
              this.toSaveStoreProduct.push(element);              
        }
          
                  
          recordProductsPorc.push(element)          
      });

      this.recordProducts = recordProductsPorc;        
      this.showSave = true;

      if(numAffect > 0){
        this.alertify.success(numAffect+' Sales Prices updated')
      }else{
        this.alertify.error('No items to update Sales Price')
      }

}

onSalesPriceItemPercent(){  

    this.hideModal()
    let recordProductsPorc = [];
      let numPorcent = 0;
      this.toSaveStoreProduct = [];
      this.recordProducts.forEach(element => {
        
      if(this.radioOverwrite.value == 'OnlyCero'){
        if(element['salesPriceNum'] == 0 ){
         
          numPorcent ++;
          if(this.radioDecimal.value == 'Yes'){
           element['salesPriceNum']= this.globals.pNumberDecimal(element['wholeSalePriceNum'] * (1+ (this.numPorcent.value/100))) ;
          }else{
            element['salesPriceNum'] = this.globals.pNumberInt(element['wholeSalePriceNum'] * (1+ (this.numPorcent.value/100))) ;
          }
          this.toSaveStoreProduct.push(element);
        }
      }else{
          
          numPorcent ++;
          if(this.radioDecimal.value == 'Yes'){
            element['salesPriceNum'] = this.globals.pNumberDecimal(element['wholeSalePriceNum'] * (1+ (this.numPorcent.value/100))) ;
          }else{
            element['salesPriceNum'] = this.globals.pNumberInt(element['wholeSalePriceNum'] * (1+ (this.numPorcent.value/100))) ;              
          }
          this.toSaveStoreProduct.push(element);
      }           


        recordProductsPorc.push(element)          
      });

      this.recordProducts = recordProductsPorc;        
      this.showSave = true;

      if(numPorcent > 0){
        this.alertify.success(numPorcent+' Sales Price updated')
      }else{
        this.alertify.error('No items to update Sales Price')
      }
    
}

////////////////////////////////////////





  onSalesPriceChange(id: string, storeProduct: any, save?: false) {
    this.showSave = save;
    if (this.showSave) {

      this.toSaveStoreProduct.forEach(element => {

        this.storeProductsService.updateStoreProducts(element['productsId'], element)
        .pipe(takeUntil(this.destroy$))   
          .subscribe(() => {
            this.showSave = false;
          }, error => {
            this.alertify.error(error);
          });
      });

      //Update also in event floor default and item layout
      this.storeProductsService.updatePricesOnLayout(this.toSaveStoreProduct)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(() => {
        this.showSave = false;
      }, error => {
        this.alertify.error(error);
      });


      this.alertify.success('Sales Price(s) were updated');
      this.toSaveStoreProduct = [];
      // let data = {
      //   userId: '123',
      //   userName: this.authService.decodeToken('username').username,
      //   section: 'Store',
      //   action: 'Save Prices',
      //   eventId: '*',
      //   device: localStorage.getItem('device'),
      // }
      
      // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
      // .subscribe(a => {});
    } else {
      this.showSave = true;
      this.toSaveStoreProduct.push(storeProduct);
    }
  }

  deleteStoreProduct(id?: string) {
    let msg = 'Are you sure that you want to delete this Store Product?';
    if (id === 'all') {
      msg = 'Are you sure you want to delete all the found Store Products?';
    }

    this.alertify.confirm(msg
      , () => {
        this.storeProductsService.deleteStoreProduct(id, this.selectedMan, this.selectedStyle
          , this.selectedSize, this.selectedProduct)
          .pipe(takeUntil(this.destroy$))   
          .subscribe(() => {
            this.alertify.success('Store Product(s) Deleted');
            if (id === 'all') {
              this.clearFilter();
            }
            this.loadStoreProductsLocal();
          }, error => {
            this.alertify.error(error);
          });
          // let data = {
          //   userId: '123',
          //   userName: this.authService.decodeToken('username').username,
          //   section: 'Store',
          //   action: 'Delete Items',
          //   eventId: '*',
          //   device: localStorage.getItem('device'),
          // }
          
          // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
          // .subscribe(a => {});
        }
        ,()=>{
        });
  }

  addStockItems(id: string, storeProduct: any, template?: TemplateRef<any>) {
    if (template != null) {
      this.bsModalRef = this.modalService.show(template);
    }
  }

  qtyStockAdded(storeProduct: any) {
    const msg = 'Do you want to add this item to Stock Items?';

    if (storeProduct != null) {
      this.storeProductsService.addStockItems(storeProduct)
      .pipe(takeUntil(this.destroy$))   
        .subscribe(() => {
          this.alertify.success('Store Product(s) added to Stock Items');
        }, error => {
          this.alertify.error(error);
        });
    }
    this.bsModalRef.hide();

    // this.alertify.confirm(msg
    // , () => {
    //});
  }

  //Funciones para filtro JS
  loadStoreProductsLocal() {
    if(this.salesOrderMode){
      let res: any = JSON.parse(localStorage.getItem('products'))
      console.log(res.result.data )

      if(res.result.data != undefined){        

        this.recordProducts = res.result.data;   
        this.storeProductsService.allStoreProducts = Object.assign([],this.recordProducts);
        this.recordProductsDefault = res.result.data;
        
        this.recordProducts.forEach(element => {
          element['salesPriceNum'] = element['salesPriceNum'].toString().replace('.00', '');
        });

        this.totalItems = this.pagination.totalItems;

        this.companies = res.result.companies.filter(size => size.companyName != "");
        this.styles = res.result.styles.filter(size => size.styleName != "");
        this.sizes = res.result.sizes.filter(size => size.sizeName != "");
        this.products = res.result.products.filter(size => size.product != "");

        this.totalitems = this.recordProducts.length;

        this.companiesDefault = res.result.companies.filter(size => size.companyName != "");
        this.stylesDefault = res.result.styles.filter(size => size.styleName != "");
        this.sizesDefault = res.result.sizes.filter(size => size.sizeName != "");
        this.productsDefault = res.result.products.filter(size => size.product != "");
      }else{
        this.storeProductGet();
      }
    }else{
      this.storeProductGet();
    }
  }

  storeProductGet(){
    this.storeProductsService.getStoreProducts(this.pagination.currentPage,
      100000, this.selectedMan, this.selectedStyle
      , this.selectedSize, this.selectedProduct)
      .pipe(takeUntil(this.destroy$))   
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
         
          this.recordProducts = res.result.data;   
          this.storeProductsService.allStoreProducts = Object.assign([],this.recordProducts);
          this.recordProductsDefault = res.result.data;
          
          this.recordProducts.forEach(element => {
            element['salesPriceNum'] = element['salesPriceNum'].toString().replace('.00', '');
          });

          this.totalItems = this.pagination.totalItems;

          this.companies = res.result.companies.filter(size => size.companyName != "");
          this.styles = res.result.styles.filter(size => size.styleName != "");
          this.sizes = res.result.sizes.filter(size => size.sizeName != "");
          this.products = res.result.products.filter(size => size.product != "");

          this.totalitems = this.recordProducts.length;

          this.companiesDefault = res.result.companies.filter(size => size.companyName != "");
          this.stylesDefault = res.result.styles.filter(size => size.styleName != "");
          this.sizesDefault = res.result.sizes.filter(size => size.sizeName != "");
          this.productsDefault = res.result.products.filter(size => size.product != "");
          this.avalaraRecords = res.result.avalaraProducts;
          this.avalara = res.result.avalara.filter(ava => ava.code != "").map((a:any) =>{ return {...a, descriptionlong: "("+a.code+")"+a.description } });

          this.productsAvalara = this.products.map((e:any) =>{
            let found = this.avalaraRecords.find(a => a?.product == e?.product);
            if(found){
              let newone = new FormControl("("+found.code+")"+found.description);
              this.avalaraGroup.addControl(e.product, newone);
              return {...e, code: found?.code, description: found?.description, descriptionlong: "("+found.code+")"+found.description}
            }else{
              let newone = new FormControl('');
              this.avalaraGroup.addControl(e.product, newone);
              return e
            }
          })
          
          this.optionsMat = this.avalara
         this.detectChanges();                 
         this.getProductsQRSettings();
        }, error => {
          this.alertify.error(error);
    });
  }

  detectChanges() {
    this.chRef.detectChanges();
  }

  //Carga Fors de los Select Filtros
  loadSelectFilters(query?:any,field?: any) {
    // if (field == 'MFGID') {
    //   this.filteredManu = true;
    //   this.filteredStyle = false;
    // }
    if (field == 'styleId') {
      this.filteredStyle = true;
    }
    // if (field == 'sizeName') {
    //   this.filteredStyle = false; 
    // }
    // if(field =='product'){
    //   this.filteredStyle = false;
    // }


    var manuArray = [];
    var styleArray = [];
    var sizeArray = [];
    var prodArray = [];
    this.p = 1;    

    this.searchSelectApply(true);

    this.recordProducts.forEach(element => {

      var size = sizeArray.findIndex(x => x.sizeName==element.sizeName);
      if (size === -1 ) {
        sizeArray.push({ sizeName: element.sizeName });
      }

      var style = styleArray.findIndex(x => x.styleName==element.styleName);
      if (style === -1 ) {
        styleArray.push({ styleId:element.styleId, styleName: element.styleName });
      }

      var prod = prodArray.findIndex(x => x.product==element.product);
      if (prod === -1 ) {
        prodArray.push({ product: element.product });
      }

      var manu = manuArray.findIndex(x => x.companyName==element.companyName);
      if (manu === -1 ) {
        manuArray.push({ companyName: element.companyName });
      }

    });


    if (field != 'MFGID') {
      this.companies = manuArray;
    }else {
      manuArray = [];
      manuArray.push(this.companies);
    }  

    if (field != 'styleId') {
      this.styles = styleArray;
    }else {
      styleArray = [];
      styleArray.push(this.styles);
    }

    if (field != 'sizeName') {
      this.sizes = sizeArray;
    }else {
      sizeArray = [];
      sizeArray.push(this.sizes);
    }

    if (field != 'product') {
      this.products = prodArray;
    }else {
      prodArray = [];
      prodArray.push(this.products);
    }     

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Store',
    //   action: 'Filter by '+field,
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
    this.sortResults();    
    this.detectChanges();
  }

  sortResults() {
    this.styles = _.sortBy(this.styles, 'styleName');
    this.sizes = _.sortBy(this.sizes, 'sizeName');
    this.products = _.sortBy(this.products, 'product');
  }

  searchSelectApply(include = false) {
    var find = include;

    this.recordProducts = this.recordProductsDefault;
   
    var filters = []  //all filter
    // var filterStyle = []  //only style filter
    // var filterSize = []  //only size of style filter

    if (this.selectedManMod) {
      filters.push({ text: this.selectedManMod, field: 'MFGID' });
      // filterStyle.push({ text: this.selectedManMod, field: 'MFGID' });
      // filterSize.push({ text: this.selectedManMod, field: 'MFGID' });
    }
    if (this.selectedStyle) {
      filters.push({ text: this.selectedStyle, field: 'styleId' });
      // filterSize.push({ text: this.selectedStyle, field: 'styleId' });
    }
    if (this.selectedSize) {
      filters.push({ text: this.selectedSize, field: 'sizeName' });
    }
    if (this.selectedProduct) {
      filters.push({ text: this.selectedProduct, field: 'product' });
    }

 
   this.totalitems = this.recordProducts.length;
    if ( !this.selectedManMod && !this.selectedStyle && !this.selectedSize && !this.selectedProduct ) {
      this.searched = false;
      return 0;
    }
    
   if (filters.length >= 1) {
    this.searched = true;
      var records: any;

      let search = this.recordProducts;
      
      //se hace el filtro por todos los campos
      filters.forEach(element => {
        search = this.recordProducts;
        this.recordProducts = this.findWordFilter(element.text, element.field, search);
      });

      this.sortArray(this.recordProducts) 
      if(this.filteredStyle){        
      this.lastItem = this.recordProducts[this.recordProducts.length -1];
      }else{
        this.lastItem = []
      }   
      this.numPriceHeigth.setValue(0);
      
    

      this.totalitems = this.recordProducts.length;
      return 0;
    }

    //Si no hay nada que buscar se ceirra el filtro 
    this.searchedInput = false;
    this.sortResults();
    this.detectChanges();
  }

  sortArray(array:any) {
    array.sort(this.compare);
  }

  compare( a, b ) {
  return a.wholeSalePriceNum - b.wholeSalePriceNum
}

  addToExpQRC(sp:any){
    const jsonpush = {
      'styleName': (sp.styleName),
      'Type':(sp.Type),
      'sizeName':(sp.sizeName),
      'salesPriceNum':(sp.salesPriceNum),
      'companyName':(sp.companyName),
      'productsId':(sp.productsId),
      "product":(sp.product),
      "wholeSalePriceNum":(sp.wholeSalePriceNum),
      "SKU":(sp.SKU)
    }
    this.recordProductsToQRC.push(jsonpush)
    this.toSaveQR = true;
    this.alertify.success('Store Product added to QRC table');
  }

  clearToExpQRC(sp:any){
    this.alertify.confirm('Are you sure to clear the QRC table ?', () => {      
    this.toSaveQR = true;
    this.recordProductsToQRC = [];
    }
    ,()=>{
    });
  }

  deleteToExpQRC(productsId:any){ 
    this.toSaveQR = true; 
    var newArray = this.recordProductsToQRC.filter((item) => item.productsId !== productsId);
    this.recordProductsToQRC = newArray;
  }

  saveToExpQRC(){
    const idtosave = [];
    this.recordProductsToQRC.forEach(element => {
      const productsId = {
        "productsId":element.productsId
      }
      
      idtosave.push(JSON.stringify(productsId));
    });

   const tosave = {"products" : JSON.stringify(idtosave)}

    this.storeProductsService.saveToExpQRC(tosave).
    toPromise()
    .then((data:string) =>{
      this.alertify.success("The QRC table was updated");
      this.toSaveQR = false;
    })
    .catch((err) =>{
    });

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Store',
    //   action: 'Save QR list',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});

  }


  async ExportToQRPDF(){
    let wait = await this.globals.loadScript();
    if(wait){
      const initialState = {
        showReport: 'QRC_products',
        reportdata: this.recordProductsToQRC
      };
      this.bsModalRef = this.modalService.show(StoreProductsReportComponent, {initialState, class: 'modal-xl modal-report'});
    }
  }

  // megaSearch(){
  //   var wordpieces = [] = this.filterManu.split(" ");
  //   console.log(wordpieces) 
  //   let filteredProducts = []
  //   let repetitions = wordpieces.length;
  //   this.recordProducts = this.recordProductsDefault;

  //   wordpieces.forEach((word:any, index)=>{
  //    // filteredProducts.push({ items : []})
  //     this.recordProducts.forEach((product:any)=>{
  //       if(((product.sizeName.toLowerCase()).indexOf(word.toLowerCase()) > -1) || ((product.companyName.toLowerCase()).indexOf(word.toLowerCase()) > -1)
  //           || ((product.styleName.toLowerCase()).indexOf(word.toLowerCase()) > -1) || ((product.product.toLowerCase()).indexOf(word.toLowerCase()) > -1)){
  //           //filteredProducts[index].items.push(product)
  //           filteredProducts.push(product)
  //       }
  //     })
  //   })
    
  //   console.log(filteredProducts)
  //   console.log(repetitions)
  //   let final = []
  //   filteredProducts.forEach((product:any)=>{
  //       let count = 0;
  //       filteredProducts.forEach((p:any)=>{
  //         if(p.productsId == product.productsId){
  //           count++
  //         }
  //       })
  //       if(count >= repetitions){
  //         final.push(product)
  //       }
  //   })

  //   const filteredArr = final.reduce((acc, current) => {
  //     const x = acc.find(item => item.productsId === current.productsId);
  //     if (!x) {
  //       return acc.concat([current]);
  //     } else {
  //       return acc;
  //     }
  //   }, []);

  //   final = filteredArr


  //   console.log(final)

  // }

  searchInputApply( ) {
    this.recordProducts = this.recordProductsDefault;
    var filters = []

    if (this.filterManu) {
      //console.log('SI push campo seleccionado', this.filterManu)
      filters.push({ text: this.filterManu, field: 'companyName' });
    }
    if (this.filterStyle) {
      //console.log('SI push campo seleccionado', this.filterStyle)
      filters.push({ text: this.filterStyle, field: 'styleName' });
    }
    if (this.filterSize) {
      //console.log('SI push campo seleccionado', this.filterSize)
      filters.push({ text: this.filterSize, field: 'sizeName' });
    }
    if (this.filterProduct) {
      //console.log('SI push campo seleccionado', this.filterProduct)
      filters.push({ text: this.filterProduct, field: 'product' });
    }

    this.filters = 1; 
    if (filters.length > 2) {
      this.filters = 3; 
    }
    
   if (filters.length >= 1) {
    this.searchedInput = true;
      var records: any;

      let search = this.recordProducts;

      filters.forEach(element => {
        search = this.recordProducts;
        this.recordProducts = this.findWord(element.text, element.field, search);
      });
      this.totalitems = this.recordProducts.length;
      return 0;
    }
    //Si no hay nada que buscar se ceirra el filtro 
    this.searchedInput = false;
  }

  findWord(query: any, field: any, search: any) {
    //console.log(query,field,' Todo esto se mando a la funcion de busqueda')
    if (field == 'companyName') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.companyName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'styleName') {
      //console.log('Se Busco en styleName')
      return search.filter(function (e) {
        return e.styleName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'sizeName') {
      //console.log('Se Busco en sizeName')
      return search.filter(function (e) {
        return e.sizeName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'product') {
      //console.log('Se Busco en product');
      return search.filter(function (e: any) {
        return e.product.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'MFGID') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.MFGID.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'styleId') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.styleId.toLowerCase().includes(query.toLowerCase());
      });
    }
  }

  findWordFilter(query: any, field: any, search: any) {
    //console.log(query,field,' Todo esto se mando a la funcion de busqueda')
    if (field == 'companyName') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.companyName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'styleName') {
      //console.log('Se Busco en styleName')
      return search.filter(function (e) {
        return e.styleName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'sizeName') {
      //console.log('Se Busco en sizeName')
      return search.filter(function (e) {
        return e.sizeName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'product') {
      //console.log('Se Busco en product');
      return search.filter(function (e: any) {
        return e.product.toLowerCase() == query.toLowerCase();
      });
    }
    if (field == 'MFGID') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.MFGID.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'styleId') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.styleId.toLowerCase().includes(query.toLowerCase());
      });
    }

    this.sortResults();
    this.detectChanges();
  }
  /**
   * Reset SelectFilter Default Content
   * To do Hacer lo mismo en Items in Stock
   **/
  defaultFilters() {
    this.companies = this.companiesDefault;
    this.styles = this.stylesDefault;
    this.sizes = this.sizesDefault;
    this.products = this.productsDefault;

  }
  //Termina Funciones para Filtro JS
  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.unsubscribe();
  }

  get selectedStyleMod() {
    return this.selectedStyle;
  }

  set selectedStyleMod(value) {
    this.selectedStyle = value;
  }

  get selectedManMod() {

    return this.selectedMan;
  }

  set selectedManMod(value) {
    this.selectedMan = value;  
  }

  get selectedSizeMod() {
    return this.selectedSize;
  }

  set selectedSizeMod(value) {
    this.selectedSize = value;
  }

  get selectedProductMod() {
    return this.selectedProduct;
  }

  set selectedProductMod(value) {
    this.selectedProduct = value; 
  }

  exportToCSV(data: any = []) {
    this.reportingService.exportToCSV(data);
  }

  loadStoreProductsForExcel() {

    // let data2 = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Store',
    //   action: 'Export To Excel',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data2).subscribe(a => {
    //   //console.log(a)
    // });

    this.alertify.confirm('Would you like to include Wholesale Price to the report?', 
    ()=>{
      let data: any = [];
      this.storeProductsService.getStoreProducts(1,
        1, this.selectedMan, this.selectedStyle
        , this.selectedSize, this.selectedProduct, 1)
        .pipe(takeUntil(this.destroy$))   
        .subscribe(
          (res: any) => {
            data = res.result;
            this.exportToCSV(data);
          }, error => {
            this.alertify.error(error);
          });
      return data;
    },
    ()=>{
      let data: any = [];
    this.storeProductsService.getStoreProducts(1,
      1, this.selectedMan, this.selectedStyle
      , this.selectedSize, this.selectedProduct, 2)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
          data = res.result;
          this.exportToCSV(data);
        }, error => {
          this.alertify.error(error);
        });
    return data;
    })

    
  }

  addSoItem(storeproduct: any) {
    //IF add to stock is false it will add Sales Order Item
  if (this.addToStock === false) {


    this.storeProductsService.validateStoreItem(storeproduct.supItemID)
    .toPromise()
    .then((data:any)=>{
    
    //verification if is active in Customers
    if(data[0].inactive == null || data[0].inactive == "" ){
      //verification if is active in Location
      if(data[0].active == 1 ){

        //updateJson of DB, Customers and Location
        // console.log(data[0].salesPriceNum)
        storeproduct.itemDelivery = ''
        storeproduct.salesPriceNum = data[0].salesPriceNum
        storeproduct.priceNum = data[0].salesPriceNum
        storeproduct.wholeSalePriceNum = data[0].Price
        storeproduct.quantityNum = 1
        // console.log('aqui2')

        this.itemsService.raiseSoItemEvent(storeproduct);
        this.alertify.success('Item Added');          
       

        // const initialState = {
        //   salesOrderMode: true,
        //   item: storeproduct
        // };

        
        if(this.fromEvent){
          this.bsModalRef.hide()
        }
        // this.bsModalRef = this.modalService.show(AddsalesorderitemComponent, { initialState, class: '', backdrop: true, ignoreBackdropClick: true  });

      }else{
        this.alertify.error("This product is inactive")
      }
    }else{
      this.alertify.error("This product is inactive in DDS")
    }




    })
    .catch((err)=>console.log(err));


    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: this.fromEvent ? 'Floor Items' : 'SO',
    //   action:  'Item Added',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }   
     
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  } 
  //IF add to stock is true it will add the Item to Stock Items
  else {
    const initialState = {
      sp: storeproduct,
      ignoreBackdropClick: true,
      backdrop: false
    };
      this.bsModalRef = this.modalService.show(AddstockitemComponent, {initialState });
      // let data = {
      //   userId: '123',
      //   userName: this.authService.decodeToken('username').username,
      //   section: 'Stock',
      //   action: 'Item Added to Stock Items',
      //   eventId: '*',
      //   device: localStorage.getItem('device'),
      // }
      
      // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
      // .subscribe(a => {});
    }

  }


  updateEntireStoreCatalog() { //Updates existing styles on the store catalog 
    let storedata: any;
    this.storeProductsService.updateEntireStoreCatalog(storedata)
    .pipe(takeUntil(this.destroy$))   
    .subscribe(() => {
      this.showSave = false;
      this.alertify.success('Store Catalog was updated');
      this.detectChanges();
    }, error => {
      this.alertify.error(error);
    });
  
  }

  trackBy(index, item) {
    
    return item.productsId;
    
  }

  


}


