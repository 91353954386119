
<div class="modal-body">
  <div class="modal-body-header">
    <h4>Send Email</h4>
  </div>
  <div class="modal-close-button">
    <app-closebutton></app-closebutton>
  </div>
  <form class="form-container w-100" [formGroup]="form" (ngSubmit)="submit(model)">
    <formly-form class="w-100" [form]="form" [fields]="fields" [model]="model">
      <div>
        <strong>Attachment: <a id="attachmentlink" #attachmentlink></a></strong>
      </div>
      <div class="form-buttons-container">
        <button class="btn btn-5 mr-2" [disabled]="!form.valid" type="sumbit">
          <span class="material-icons">
            email
          </span>
          Send
        </button>
        <button class="btn btn-6" (click)="cancelForm()" type="button">
          Cancel
        </button>
      </div>
    </formly-form>
  </form>
</div>
