import { Component, OnInit, Input, TemplateRef, EventEmitter, Output, OnDestroy, ChangeDetectorRef, AfterContentChecked, AfterViewChecked } from '@angular/core';
import { Customer } from '../../../_modules/customers';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomerService } from '../../../_services/customer.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { CustomerFormComponent } from '../customer-form/customer-form.component';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { SalesorderSearchComponent } from '../../salesorder/salesorder-search/salesorder-search.component';
import { DummymodalComponent } from '../../dummymodal/dummymodal.component';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CustomerCommentsComponent } from '../customer-comments/customer-comments.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-card',
  templateUrl: './customer-card.component.html',
  styleUrls: ['./customer-card.component.css']
})
export class CustomerCardComponent implements OnInit, OnDestroy, AfterContentChecked, AfterViewChecked {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() customer: Customer;
  @Input() search = false;
  @Input() editMode = false;
  @Output() editModeChange = new EventEmitter<boolean>();
  @Input() delivery = 'Delivery';
  @Input() salesOrder = false;
  @Input() viewType = 'Card';
  salesorders: any = [];
  showForm = false;
  settingCard = true;
    constructor(private modalService: BsModalService,
      private customerService: CustomerService,
      private userService: UserService,
      private alertify: AlertifyService,
      private bsModalRef: BsModalRef,
      private cdRef: ChangeDetectorRef,
      private salesService: SalesOrderService,
      private authService: AuthService,
      private translate: TranslateService, ) { }

  ngOnInit() {
    this.salesorders = this.customer['salesCount'];
  }
  ngAfterContentChecked(): void {
  }

  salesOrderByCustomer(id:any){
    this.salesService.salesOrderByCustomer(id)
    .pipe(takeUntil(this.destroy$))  
     .subscribe(
       (res: any) => {        
          this.viewSalesOrder(res);      
     }, error => {
       this.alertify.error(error);
     });
  }

  loadSalesOrder() {
    this.closeCardSettings();
    let salesordersCustomer = [];
    let specificFields:any = {
      values: [
          "SONumber","status", "SOID"
      ]
    }

      this.salesService.getSalesOrdersSpecific(1, 100, '', '', '', '', '', '','', this.customer['Id'],specificFields )
      .pipe(takeUntil(this.destroy$))  
     .subscribe(
       (res: any) => {
         salesordersCustomer = res.result;
          this.viewSalesOrder(salesordersCustomer);
     }, error => {
       this.alertify.error(error);
     });

    //  let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Customers',
    //   action: 'Show SO list from customers',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).subscribe(a => {
    //   //console.log(a)
    // });

  }

  viewSalesOrder(salesordersCustomer) {

    const initialState = {
      passSalesOrders: salesordersCustomer,
      class: 'modal-lg',
      style: 'width: 100%'
    };

    this.bsModalRef = this.modalService.show(SalesorderSearchComponent, {initialState,class: 'orders-modal', backdrop: true, ignoreBackdropClick: true});
  }



  editCustomer(id: any) {
    this.editModeChange.emit(true)    
    this.customerService.editCustomer.next(id);
    this.bsModalRef = this.modalService.show(CustomerFormComponent, {class: 'modal-lg', backdrop: true, ignoreBackdropClick: true });
    this.closeCardSettings();

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Customers',
    //   action: 'Edit Customer',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).subscribe(a => {});

  }

  seeComments(customer: any) {
    let initialState =  {
      customer : customer
    }
    // this.editModeChange.emit(true)    
    // this.customerService.editCustomer.next(id);
    this.bsModalRef = this.modalService.show(CustomerCommentsComponent, {initialState,  class: 'modal-lg', backdrop: true });
    this.closeCardSettings();


  }


  selectCustomer(id: any) {
    let seletectedCustomer = 0;
    this.customerService.salesOrderCustomer
    .pipe(takeUntil(this.destroy$))  
    .subscribe((
      res => {
        seletectedCustomer = res;
      }));
   // if(seletectedCustomer === 0) {
      this.customerService.salesOrderCustomer.next(id);
      this.bsModalRef.hide();
    //}
  }

  deleteCustomer(id: any) {
    let message = this.translate.instant('CONFIRM_DELETE_CUSTOMER');
    this.alertify.confirm(`${message}`
    + this.customer.firstName  + ' ' + this.customer.lastName, () => {
    this.customerService.deleteCustomer(this.customer.Id)
    .pipe(takeUntil(this.destroy$))  
    .subscribe(() => {
      let message = this.translate.instant('CUSTOMERDELETED');
      this.alertify.success(`${message}`);
      this.customerService.salesOrderCustomer.next(404)
      this.customerService.reloadCustomerList.next('yes')
    }, error => {
        this.alertify.error(error);
    });
  }
  ,()=>{
  });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  public openModalWithComponent() {

    if (this.customerService.salesOrderCustomer.value !== 0) {
      let message = this.translate.instant('CONFIRM_CHANGE_CUSTOMER');
      this.alertify.confirm(`${message}`, () => {
        this.customerService.selectedCustomer(0);
        this.customerService.salesOrderCustomer.next(0);
        this.bsModalRef = this.modalService.show(DummymodalComponent, {class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
      }
      ,()=>{
      });
    } else {
      this.customerService.selectedCustomer(0);
      this.customerService.salesOrderCustomer.next(0);
      this.bsModalRef = this.modalService.show(DummymodalComponent, {class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
    }
  }

  ngAfterViewChecked() {
    if (this.cdRef != null) {
      this.cdRef.detectChanges();
    }
  }

  onChange(): void {
    if (this.cdRef) {
    this.cdRef.detectChanges();
    }
  }

  //Todo Open Settings
  openCardSettings() {
    this.closeCardSettings();
    this.settingCard =! this.settingCard;
  }
  closeCardSettings () {
    var modals = document.querySelectorAll(".custom-card-content-item-footer-custom-options");
    for (var i = 0; i < modals.length; i++) {
      modals[i].classList.remove('active')
    }
  }
}

