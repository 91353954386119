<div class="container table-po-summary">
  <div class="text-center f-3 font-weight-bold">
    Total PO Summary
  </div>
  <div class="table-responsive">
    <table
      id="salestable"
      class="table"
      cellspacing="0"
    >
      <thead class="text-center">
        <tr>
          <th >Total SO</th>
          <th>Total PO</th>
          <th>Total SO items</th>
          <th>Total PO Items</th>
          <th>Total SO WholeSale</th>
          <th>Total PO WholeSale</th>
        </tr>
      </thead>
      <tbody class="text-center">
        <tr class="m-1 p-1" >
          <td >{{ SOsummary?.SalesOrder }}</td>
          <td >{{ POnum }}</td>
          <td >{{ SOsummary?.ItemSold }}</td>
          <td >{{ POsumItems }}</td>
          <td > $ {{ SOsummary?.WholeSalesPrice | number: "1.2-2" }}</td>
          <td > $ {{ POsumTotal | number: "1.2-2" }}</td>
        </tr>
      </tbody>
      <div *ngIf="included?.length === 0">
        No Data Found 
      </div>
    </table>
  </div>
</div>

<div class="settings-tabs mb-3 mt-3">
  <div class="tabs">
    <div class="tabs-links">
      <div
        class="tabs-links-link"
        (click)="show = 'Included'"
        [ngClass]="{ active: show === 'Included' }"
      >
        <p class="m-0"> Purchase Order Items</p>
      </div>
      <div
        class="tabs-links-link"
        (click)="show = 'Excluded' "
        [ngClass]="{ 'active': show === 'Excluded' }"
      >
        <p class="m-0">Items Excluded from Purchase Orders</p>
      </div>
  
    </div>
  </div>
</div>



<div class="row m-1 mt-2">
  <div class="col-md-12" *ngIf="show === 'Included' && included !== undefined">
    
    <!-- <h5>
    Purchase Order Items
    </h5> -->

    <div class="table-responsive table-event-summary mt-3">
      <table
        id="salestable"
        class="table table-light table-custom"
        cellspacing="0"
      >
        <thead class="table-custom-header">
          <tr>
            <th>PO</th>
            <th>Sales Order</th>
            <th>Manufacturer</th>
            <th>Size</th>
            <th>Product</th>
            <th>Stock status</th>
            <th class="text-right" uncheckable tabindex="0" role="button">
              Qty
            </th>    
            <th class="text-right" uncheckable tabindex="0" role="button">
              WholeSale
            </th>
          </tr>
        </thead>
        <tbody *ngIf="included !== undefined && included.length > 0">
          <tr class="m-1 p-1" *ngFor="let item of included">
            <td>{{ item.PONumber }}</td>
            <td>{{ item.SONumber }}</td>
            <td>{{ item.manufacturer }}</td>
            <td>{{ item.sizeName }}</td>
            <td>{{ item.Type }}</td>
            <td>{{ item.itemDelivery }}</td>
            <td class="text-right">{{ item.Qty | number: "1.0" }}</td>
            <td class="text-right">{{ item.Qty * item.WSP| currency }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right tw-font-bold">{{ totalQtyInclude | number: "1.0" }}</td>
            <td class="text-right tw-font-bold">{{ totalWSPInclude| currency }}</td>
          </tr>
        </tbody>
        <div *ngIf="included.length === 0">
          No Data Found 
        </div>
      </table>
    </div>
  </div>
  <div class="col-md-12"  *ngIf="show === 'Excluded' && excluded !== undefined">
    <!-- <h5>
      Items Excluded from Purchase Orders
    </h5> -->
    <div class="table-responsive table-event-summary mt-3">
      <table
        id="salestable"
        class="table table-light table-custom"
        cellspacing="0"
      > 
        <thead class="table-custom-header">
          <tr>
            <th>Sales Order</th>
            <th>Status</th>
            <th>Exclude From PO</th>
            <th>Delivery</th>
            
            <th>Manufacturer</th>
            <th>Size</th>
            <th>Product</th>
            <th class="text-right" uncheckable tabindex="0" role="button">
              Qty
            </th>
            <th class="text-right" uncheckable tabindex="0" role="button">
              WholeSale
            </th>
          </tr>
        </thead>
        <tbody *ngIf="excluded.length > 0">
          <tr class="m-1 p-1" *ngFor="let item of excluded">
            <td>{{ item.SONumber }}</td>
            <td>{{ item.status }}</td>
            <td> <i class="fa fa-check" *ngIf="item.ExcludeFromPO == 1"></i></td>
            <td>{{ item.itemDelivery }}</td>
            <td>{{ item.manufacturer }}</td>
            <td>{{ item.sizeName }}</td>
            <td>{{ item.Type }}</td>
            <td class="text-right">{{ item.Qty | number: "1.0" }}</td>
            <td class="text-right">{{ item.Qty * item.WSP | currency }}</td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td class="text-right tw-font-bold">{{ totalQtyExclude | number: "1.0" }}</td>
            <td class="text-right tw-font-bold">{{ totalWSPExclude| currency }}</td>
          </tr>
        </tbody>
        <div *ngIf="excluded.length === 0">
          No Data Found
        </div>
      </table>
    </div>
  </div>
</div>
