<nav
  class="navbar navbar-expand-lg navbar-light m-0 pt-3 pl-0 pb-0 mt-5 bg-white"
>
  <ul class="navbar-nav mr-auto">
    <li class="nav-item active">
      <a class="nav-link ml-4">
        <h6>
          PURCHASE ORDER <b>{{ purchaseOrder.POUUID }}</b>
        </h6>
      </a>
    </li>
  </ul>
  <div class="d-flex">
    <button
      class="btn btn-3 mr-1"
      (click)="goBackToList()"
    >
      <span class="material-icons">
      arrow_back
      </span>
      Back
    </button>
    <button
      class="btn btn-3"
      [ngClass]="{
        'btn-primary': !disabledbutton,
        'btn-outline-primary': disabledbutton
      }"
      (click)="submitPO()"
      [disabled]="!disabledbutton"
    >
      <span class="material-icons">
      save
      </span>
      Save
    </button>
  </div>
</nav>
<div class="p-3 bg-white rounded"  >
  <div class="p-2 rounded border">
    <div class="row">
      <div class="col-2">
        <span *ngIf="purchaseOrder.confirmationPOID"><b>DDS POID</b> <br> {{purchaseOrder.confirmationPOID}} </span>
      </div>
      <div class="col-2">
       <span *ngIf="purchaseOrder.confirmationStatus
       && purchaseOrder.confirmationStatus.includes('ERROR')
       " class="text-danger"
       > <b>Upload Confirmation</b> <br>  {{purchaseOrder.confirmationStatus}}</span>

       <span *ngIf="purchaseOrder.confirmationStatus
       && !purchaseOrder.confirmationStatus.includes('ERROR')
       " class=""
       > <b>Upload Confirmation</b> <br>  {{purchaseOrder.confirmationStatus}}</span>

      </div>
    </div>
    
    <div class="row">
      <div class="col-md-6">
        <b>Manufacturer</b> <br />
        {{ purchaseOrder.mfg }}
      </div>
      <div class="col-md-3">
        <b>Manufacturer ID</b> <br />
        {{ purchaseOrder.SID }}
      </div>
      <div class="col-md-3">
        <span
          *ngIf="
            purchaseOrder.DeliveryType !== 'Direct Shipment' &&
            purchaseOrder.DeliveryType !== 'MattressFirm DNTO' &&
            purchaseOrder.DeliveryType !== 'MattressFirm'
          "
        >
          <strong>Ship To</strong> <br />

          <select
            class="form-control d-inline form-control-sm pull-right"
            [(ngModel)]="purchaseOrder.shipA"
            [disabled]="purchaseOrder.UploadedStatus !== 'Pending'"
            placeholder="Ship To"
          >
            <option>{{ "Warehouse 1" }}</option>
            <option>{{ "P Address" }}</option>
            <option>{{ "Warehouse 2" }}</option>
            <option>{{ "Warehouse 3" }}</option>
          </select>
        </span>
      </div>
    </div>
    <div class="row">
      <div class="col-md-2">
        <b>Date</b> <br />
        {{ purchaseOrder.dbPOdatets | date: "shortDate" }}
      </div>
      <div class="col-md-2">
        <b>MyPOID</b> <br />
        {{ purchaseOrder.POUUID }}
      </div>
      <div class="col-md-2">
        <b>Upload Status</b> <br />
        {{ purchaseOrder.UploadStatus }}
      </div>
      <div class="col-md-6">
        <b>Delivery</b> <br />
        {{ purchaseOrder.DeliveryType }}
        <div
          *ngIf="
            purchaseOrder.DeliveryType === 'Direct Shipment' ||
            purchaseOrder.DeliveryType === 'MattressFirm DNTO' ||
            purchaseOrder.DeliveryType === 'MattressFirm'
          "
        >
          Ship To:
          <div class="row">
            <div class="col-md-12">{{ purchaseOrder.deliveryAttn }}</div>
            <div class="col-md-6">{{ purchaseOrder.deliveryPhone }}</div>
            <div class="col-md-6">{{ purchaseOrder.ds_phone2 }}</div>
            <div class="col-md-12" *ngIf="purchaseOrder.deliveryEmail">
              {{ purchaseOrder.deliveryEmail }}
            </div>
            <div class="col-md-12">{{ purchaseOrder.deliveryAddress1 }}</div>
            <div class="col-md-12">{{ purchaseOrder.deliveryAddress2 }}</div>
            <div class="col-md-12">
              {{ purchaseOrder.deliveryCity }}, {{ purchaseOrder.deliveryState }},
              {{ purchaseOrder.deliveryZip }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <form  [formGroup]="form" class="d-none" (ngSubmit)="saveDelivery()">
    Shipping Address
      <formly-form [form]="form" [fields]="fields" [model]="purchaseOrder">
          <div class="form-group text-center">
          </div>
      </formly-form>
    </form> -->

  <div class="pt-3 pb-3">
    <h5 class="pt-2 pb-2">Items</h5>
  </div>

   <app-purchaseorder-items [poitems]="poItems" [UploadStatus]="purchaseOrder.UploadStatus" [poitemsItems]="poitemsItems" [editstate]="edit"
  (todeleteItemsSQL)="funDelete($event)"  (todeletePOItemsSQL)="funPODelete($event)" ></app-purchaseorder-items>
</div>
<!-- <nav
  class="navbar navbar-expand-lg navbar-light m-0 pt-0 pl-0 pb-0 mt-1 border-bottom bg-white"
>
  <ul class="navbar-nav mr-auto">
    <li class="nav-item active">
      <a class="nav-link ml-4">
        <h6>
          PURCHASE ORDER <b>{{ purchaseOrder.POUUID }}</b>
        </h6>
      </a>
    </li>
  </ul>
  <div class="d-flex">
    <button
      class="btn btn-3"
      (click)="goBackToList()"
    >
      <span class="material-icons">
      arrow_back
      </span>
      Back
    </button>
    <button
      class="btn btn-3"
      [ngClass]="{
        'btn-primary': purchaseForm?.dirty,
        'btn-outline-primary': !purchaseForm?.dirty
      }"
      (click)="submitPO()"
      [disabled]="!purchaseForm?.dirty"
    >
      <span class="material-icons">
        save
      </span>
      Save
    </button>
  </div>
</nav> -->
