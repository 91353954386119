import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { HostListener,Component, EventEmitter, Output } from '@angular/core';
@Component({
  selector: 'app-bar-code-reader',
  templateUrl: './bar-code-reader.component.html',
  styleUrls: ['./bar-code-reader.component.scss']
})
export class BarCodeReaderComponent {
  @Output() ValueToFather = new EventEmitter<string>();
  private inputSubject: Subject<string> = new Subject();
  scannedValue: string = '';
  accumulatedInput: string = '';

  constructor() {
    this.inputSubject.pipe(
      debounceTime(300) 
    ).subscribe(input => {
      console.log(input);
      
      let x = this.cleanAndFormatValue(input);
      x = this.cleanAndFormatValue2(x);
      //console.log(x);
      
      if (x.startsWith('%%')) {
        document.getElementById("scanner").focus()
        this.scannedValue = x.substring(2);
        this.scannedValue = this.cleanAndFormatValueUpper(this.scannedValue);

        if (this.scannedValue!='') {
          this.ValueToFather.emit(this.scannedValue);
        }
      }
        
      this.scannedValue = '';
      this.accumulatedInput='';

    });
  }

  ngOnInit(): void {
    document.getElementById("scanner").focus()
  }
  private cleanAndFormatValueUpper(value: string): string {
    return value.replace(/Shift([a-z])/g, (match, letter) => letter.toUpperCase());
  }
  private cleanAndFormatValue(value: string): string { 
    value = value.replace(/Shift5/g, () => '%')
    value = value.replace(/Shift2/g, () => '@')
    value =  value.replace(/Shift3/g, () => '#')  
    return value;
  }  
  private cleanAndFormatValue2(value: string): string { 
    return value.replace(/Shift%/g, () => '%');
  }  



  @HostListener('window:keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.accumulatedInput += event.key;
    this.inputSubject.next(this.accumulatedInput);
  }


}
