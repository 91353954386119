
<div *ngIf="!showHistory" class="tw-grid tw-grid-cols-1 sm:tw-grid sm:tw-grid-cols-1 md:tw-grid md:tw-grid-cols-1 xl:tw-grid xl:tw-grid-cols-2">
  
  <!-- first column -->
  <div class="tw-mb-4" >
    <div class="tw-bg-white tw-p-4">
  
      <div class="tw-h-10 tw-flex tw-justify-between">
        <div class="tw-flex">
          <button class="btn-blue-so-square tw-mr-4" (click)="showHistory = true">{{ 'HISTORY' | translate }}</button>
          <button class="btn-blue-so-square" (click)="clearFilter()">{{ 'CLEAR_FILTERS' | translate }}</button>          
        </div>
        <div style="display: flex;"> <span class="tw-text-aedblue tw-my-auto tw-text-xl">{{ 'ITEMS' | translate }}: {{pagination.totalItems}}</span> </div>
      </div>
  
    </div>

    <div class="tw-px-4 tw-grid tw-grid-cols-1 sm:tw-grid sm:tw-grid-cols-1 md:tw-grid md:tw-grid-cols-2 lg:tw-grid lg:tw-grid-cols-2">
      
      <div class="tw-mb-2 tw-pr-2" >
        <div class="searchable">
          <input
            type="text"
            placeholder="{{ 'SEARCH_PRODUCT' | translate }}"
            [(ngModel)]="selectedProductMod" 
            (input)="filterProducts($event.target.value, 'productName')"
            (keydown.enter)="preventEnter($event)"
          />
          <ul>
            <li *ngFor="let product of filteredProducts" 
            (click)="loadFilter($event,'product', product.productName)"
            [ngClass]="{ 'selected': product.productName === selectedProductMod }"
            >{{ product.productName }}</li>
          </ul>
        </div>    
      </div>

      <div class="tw-mb-2 tw-pl-2">
        <div class="searchable">
          <input
            type="text"
            placeholder="{{ 'SEARCH_BRAND' | translate }}"
            [(ngModel)]="selectedBrandMod" 
            (input)="filterProducts($event.target.value, 'brand')"
            (keydown.enter)="preventEnter($event)"
          />
          <ul>
            <li *ngFor="let product of filteredBrands" 
            (click)="loadFilter($event,'brand', product.brand)"
            [ngClass]="{ 'selected': product.brand === selectedBrandMod }"
            >{{ product.brand }}</li>
          </ul>
        </div>
      </div>

      <div class="tw-mb-2 tw-pr-2">
        <div class="searchable">
          <input
            type="text"
            placeholder="{{ 'SEARCH_MANUFACTURER' | translate }}"
            [(ngModel)]="selectedManMod" 
            (input)="filterProducts($event.target.value, 'manufacturer')"
            (keydown.enter)="preventEnter($event)"
          />
          <ul>
            <li *ngFor="let product of filteredManufacturer" 
            (click)="loadFilter($event,'manufacturer', product.manufacture)"
            [ngClass]="{ 'selected': product.manufacture === selectedManMod }"
            >{{ product.manufacture }}</li>
          </ul>
        </div>
      </div>

      <div class="tw-mb-2 tw-pl-2">
        <div class="searchable">
          <input
            type="text"
            placeholder="{{ 'SEARCH_TYPE' | translate }}"
            [(ngModel)]="selectedTypeMod" 
            (input)="filterProducts($event.target.value, 'type')"
            (keydown.enter)="preventEnter($event)"
          />
          <ul>
            <li *ngFor="let product of filteredTypes" 
            (click)="loadFilter($event,'type', product.productType)"
            [ngClass]="{ 'selected': product.productType === selectedTypeMod }">{{ product.productType }}</li>
          </ul>
        </div>
      </div>
    </div>




  
    <div *ngIf="!salesOrderMode" class="customer-content tw-px-4">
      <div class="table-responsive">
        <table class="table-so table table-light table-custom overflow-auto">
          <thead class="tw-font-bold tw-text-aedblue">
              <tr style="font-size: small;background-color: #fafafa;">
              <td></td>
              <td> {{ 'PRODUCT' | translate }} </td>
              <td> {{ 'MANUFACTURER' | translate }} </td>
              <td> {{ 'BRAND' | translate }} </td>
              <td> {{ 'TYPE' | translate }} </td>
              <td> {{ 'QUANTITY' | translate }} </td>
              <td colspan="1" class="tw-text-base tw-text-aedblue tw-font-semibold"></td>
            </tr>
  
          <tbody class="tw-py-2 tw-text-aedgraydark"
            >
            <tr *ngFor="let sp of recordItems;  let odd = odd;"
            [ngClass]="odd ? 'tw-bg-aedgrayback' :'tw-bg-aedgrayback100'">
              
              <td colspan="1" style="padding-left: .5rem;" class="tdeventlist tw-cursor-pointer">
                <span *ngIf="sp.uuid == showOrderwithStock && sp.Sales > 0" (click)="showSalesOrders('')"
                  class="material-icons">expand_more</span>
                <span *ngIf="sp.uuid != showOrderwithStock  && sp.Sales > 0" (click)="showSalesOrders(sp.stockItemId)"
                  class="material-icons">chevron_right</span>
              </td>    
              <td colspan="1" class="tdeventlist">{{ sp.productName }}</td>
              <td colspan="1" class="tdeventlist">{{ sp.manufacture }}</td>
              <td colspan="1" class="tdeventlist">{{ sp.brand }}</td>
              <td colspan="1" class="tdeventlist">{{ sp.productType }}</td>
              <td colspan="1" class="tdeventlist tw-text-center">{{sp.QTY}}</td>
              <td colspan="1" class="tdeventlist tw-text-end">
                <div class="d-flex tw-ml-4">
                  <!-- view button -->
                  <button  class="custom-button" (click)="addToPO(sp)"><span class="material-icons">vertical_align_top</span></button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="pagination-container">
          <pagination 
            [boundaryLinks]="true"
            [totalItems]="pagination.totalItems"     
            [currentPage]="p2"       
            [itemsPerPage]="pagination.itemsPerPage"
            (pageChanged)="pageChanged($event)"  
            [maxSize]="7"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            firstText="&laquo;"
            lastText="&raquo;">
          </pagination>
        </div>
      </div>
    </div>
  </div>
  
  
  <!-- second column -->
  <div style="display: flex;">
    <div *ngIf="stockItemsPO?.length>0" class="customer-content tw-px-4">

      <div class="tw-bg-white tw-py-4">
        <div class="tw-h-10 tw-flex tw-justify-between">
            <div class="tw-flex">
              <button class="btn-blue-so-square" (click)="sendPO(sendPOModal)">{{ 'SEND_PO' | translate }}</button>
            </div>
        </div>
      </div>

      <div class="table-responsive">
        <table class="table-so table table-light table-custom overflow-auto">
          <thead class="tw-font-bold tw-text-aedblue">
              <tr style="font-size: small;background-color: #fafafa;">
              <td></td>
              <td> {{ 'PRODUCT' | translate }} </td>
              <td> {{ 'MANUFACTURER' | translate }} </td>
              <td> {{ 'BRAND' | translate }} </td>
              <td> {{ 'TYPE' | translate }} </td>
              <td> {{ 'QUANTITY' | translate }} </td>
              <td></td>
            </tr>
  
          <tbody class="tw-py-2 tw-text-aedgraydark"
            *ngFor="let sp of stockItemsPO | paginate: { itemsPerPage: 10, currentPage: p, id:'table2'};  let odd = odd; let i = index"
            [ngClass]="odd ? 'tw-bg-aedgrayback' :'tw-bg-aedgrayback100'">
            <tr>
              
              <td colspan="1" style="padding-left: .5rem;" class="tdeventlist tw-cursor-pointer">
                <span *ngIf="sp.uuid == showOrderwithStock && sp.Sales > 0" (click)="showSalesOrders('')"
                  class="material-icons">expand_more</span>
                <span *ngIf="sp.uuid != showOrderwithStock  && sp.Sales > 0" (click)="showSalesOrders(sp.stockItemId)"
                  class="material-icons">chevron_right</span>
              </td>    
              <td colspan="1" class="tdeventlist">{{ sp.productName }}</td>
              <td colspan="1" class="tdeventlist">{{ sp.manufacture }}</td>
              <td colspan="1" class="tdeventlist">{{ sp.brand }}</td>
              <td colspan="1" class="tdeventlist">{{ sp.productType }}</td>
              <td colspan="1" class="tdeventlist tw-text-center">
                

    
                <div>
                    <input 
                     
                    (blur)="onInputBlur(i)" 
                    style="width: 30px; text-align: center;" 
                    type="number" 
                    [(ngModel)]="sp.QTY" 
                    (ngModelChange)="onQuantityChange(i)">
                </div>

              </td>
              <td colspan="1" class="tdeventlist tw-text-end">
                <div class="d-flex tw-ml-4">
                  <svg  class="ml-4 mr-2 tw-cursor-pointer" (click)="deleteItem(i)" id="Group_235"
                    data-name="Group 235" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24.255" height="29.82" viewBox="0 0 24.255 29.82">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: #00adee;
                        }
  
                        .cls-2 {
                          clip-path: url(#clip-path);
                        }
                      </style>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_99" data-name="Rectangle 99" class="cls-1" width="24.255" height="29.82" />
                      </clipPath>
                    </defs>
                    <g id="Group_40" data-name="Group 40" class="">
                      <path id="Path_55" data-name="Path 55" class="cls-1"
                        d="M14.513,0a2.979,2.979,0,0,1,1.865,1.289,3.193,3.193,0,0,1,.43,1.838c-.015.657-.087.574.563.576,1.493,0,2.987,0,4.48,0a2.316,2.316,0,0,1,2.4,2.4q0,1.556,0,3.113a.939.939,0,0,1-1.043,1.043c-.388,0-.4.019-.414.412q-.15,3.426-.307,6.853c-.072,1.577-.151,3.154-.226,4.732-.078,1.616-.146,3.232-.236,4.848a2.812,2.812,0,0,1-2.887,2.711q-7.011,0-14.022,0a2.84,2.84,0,0,1-2.9-2.853c-.084-2.217-.2-4.433-.3-6.649-.073-1.587-.153-3.174-.228-4.761q-.119-2.5-.234-4.993c-.01-.218-.1-.3-.322-.3C.311,10.251,0,9.926,0,9.1,0,8.086,0,7.068,0,6.05A2.313,2.313,0,0,1,2.344,3.706c1.581,0,3.161-.009,4.742.005.3,0,.383-.09.371-.38A4.029,4.029,0,0,1,7.506,2.2,2.78,2.78,0,0,1,9.465.09L9.741,0ZM12.148,10.264q-4.233,0-8.467,0c-.221,0-.338.033-.322.3.082,1.364.151,2.728.219,4.092q.119,2.4.229,4.791c.086,1.878.164,3.756.25,5.634.031.668.07,1.335.113,2a.856.856,0,0,0,.638.78,1.594,1.594,0,0,0,.46.057h13.7a1.623,1.623,0,0,0,.432-.046.863.863,0,0,0,.682-.836q.115-2.177.225-4.354.12-2.424.232-4.849c.085-1.868.164-3.737.248-5.605.024-.523.048-1.045.089-1.567.03-.381.029-.4-.353-.4h-8.38m-.02-1.9q4.946,0,9.891,0c.249,0,.344-.061.336-.325-.018-.572,0-1.144-.006-1.716s-.141-.716-.725-.716H2.6c-.544,0-.692.148-.693.689q0,.785,0,1.571c0,.536-.051.492.478.492h9.746M12.119,3.7c.833,0,1.666-.005,2.5,0,.213,0,.3-.064.286-.283-.014-.193,0-.387-.005-.581a.934.934,0,0,0-.961-.957q-1.815-.018-3.631,0a.93.93,0,0,0-.948.939c-.01.193.009.388-.006.581-.018.229.07.3.3.3.823-.011,1.646,0,2.469,0"
                        transform="translate(0)" />
                      <path id="Path_56" data-name="Path 56" class="cls-1"
                        d="M85.757,162.45q0,3.025,0,6.051a.924.924,0,0,1-.669.918.966.966,0,0,1-1.059-.36.882.882,0,0,1-.173-.544q0-6.066,0-12.131a.912.912,0,0,1,.94-.937.924.924,0,0,1,.958.952c0,2.017,0,4.034,0,6.051"
                        transform="translate(-77.334 -143.36)" />
                      <path id="Path_57" data-name="Path 57" class="cls-1"
                        d="M145.636,162.462q0,3.011,0,6.022a.925.925,0,0,1-.653.929.965.965,0,0,1-1.082-.364.9.9,0,0,1-.166-.546q0-6.051,0-12.1a.915.915,0,0,1,.953-.954.926.926,0,0,1,.946.964q.006,3.025,0,6.051"
                        transform="translate(-132.557 -143.361)" />
                      <path id="Path_58" data-name="Path 58" class="cls-1"
                        d="M205.506,162.412q0,3.011,0,6.022a.925.925,0,0,1-.657.926.97.97,0,0,1-1.081-.368,1.066,1.066,0,0,1-.164-.664q0-4.335,0-8.669c0-1.105,0-2.211,0-3.316a.922.922,0,0,1,.783-.942.952.952,0,0,1,1.076.645,1.268,1.268,0,0,1,.042.431q0,2.967,0,5.935"
                        transform="translate(-187.771 -143.306)" />
                    </g>
                  </svg>
                </div>
              </td>
            </tr>
          </tbody>
        </table>


        <div class="pagination-container" *ngIf="stockItemsPO.length > 0">   
          <pagination 
            [totalItems]="stockItemsPO.length"
            [(ngModel)]="p"
            [itemsPerPage]="10"
            id="table2"
            [boundaryLinks]="true"
            (pageChange)="p = $event"
            [maxSize]="maxsize"
            [autoHide]="true"
            previousText="&lsaquo;"
            nextText="&rsaquo;"
            firstText="&laquo;"
            lastText="&raquo;"
          ></pagination>
        </div>
      </div>

    </div>


    <div style="min-height: 10rem; height: auto; display: flex; background: #e5e5e5;" *ngIf="stockItemsPO?.length==0" class="customer-content tw-mx-4">
      <span style="margin: auto;font-size: 2rem; color: gray;">{{ 'ADD_PRODUCTS_TO_SEND' | translate }}</span>
    </div>


  </div>
</div>


<app-po-history *ngIf="showHistory" (hideHistory)="hideHistory($event)"></app-po-history>

<ng-template #sendPOModal class=" tw-rounded-xl">
  
  <div class="modal-container">
    
    <div class="tw-flex tw-p-4 tw-flex-col tw-rounded-t-xl ">
      <div class="title-1-orange tw-mx-auto">{{ 'SEND_PO' | translate }}</div>
    </div>
  
    <div class="d-flex flex-column container w-100 tw-text-aedgrayl">
      <div class="tw-flex tw-flex-col">


        <div class="">
          <div>{{ 'TOTAL_ITEMS' | translate }}: {{ stockItemsPO?.length}}</div>
          <div>{{ 'TOTAL_QUANTITY' | translate }}: {{totalQTY()}}</div>
        </div>

        <div *ngIf="stockItemsPO?.length>0" class="customer-content">
          <div class="table-responsive">
            <table class="table-so table table-light table-custom overflow-auto">
              <thead class="tw-font-bold tw-text-aedblue">
                <tr style="font-size: small;background-color: #fafafa;">  
                  <td> {{ 'PRODUCT' | translate }} </td>
                  <td> {{ 'MANUFACTURER' | translate }} </td>
                  <td> {{ 'BRAND' | translate }} </td>
                  <td> {{ 'TYPE' | translate }} </td>
                  <td > {{ 'QUANTITY' | translate }} </td>
                </tr>
              </thead>
      
              <tbody class="tw-py-2 tw-text-aedgraydark" >
                <tr *ngFor="let sp of stockItemsPO | paginate: { itemsPerPage: 10, currentPage: p4, id:'table4'};  let odd = odd; let i = index" [ngClass]="odd ? 'tw-bg-aedgrayback' :'tw-bg-aedgrayback100'">  
                  <td colspan="1" class="tdeventlist">{{ sp.productName }}</td>
                  <td colspan="1" class="tdeventlist">{{ sp.manufacture }}</td>
                  <td colspan="1" class="tdeventlist">{{ sp.brand }}</td>
                  <td colspan="1" class="tdeventlist">{{ sp.productType }}</td>
                  <td colspan="1" class="tdeventlist tw-text-center"><div>{{ sp.QTY}}</div></td>
                </tr>
              </tbody>
            </table>

            <div class="pagination-container" *ngIf="stockItemsPO.length > 0">   
              <pagination 
                [totalItems]="stockItemsPO.length"
                [(ngModel)]="p4"
                [itemsPerPage]="10"
                id="table4"
                [boundaryLinks]="true"
                (pageChange)="p4 = $event"
                [maxSize]="maxsize"
                [autoHide]="true"
                previousText="&lsaquo;"
                nextText="&rsaquo;"
                firstText="&laquo;"
                lastText="&raquo;"
              ></pagination>
            </div>
          </div>
        </div>
        


        <form [formGroup]="InfoPO">
          <div class="tw-mb-2">
            <label for="address">{{ 'ADDRESS' | translate }}</label>
            <select (change)="ChangeAddress($event)" formControlName="address" class="input-header tw-py-1 tw--mt-2">
              <option disabled value="" selected>{{ 'SELECT' | translate }}</option>
              <option *ngFor="let address of addresses" [value]="address.uuid">{{address.addressName}}</option>
            </select>
            <div *ngIf="InfoPO.get('address').touched && InfoPO.get('address').hasError('required')">
              <div class="text-danger">{{ 'ADDRESS_REQUIRED' | translate }}.</div>
            </div>
          </div>

          <div *ngIf="InfoPO.get('address').value != null && InfoPO.get('address').value != ''">

            <div class="tw-grid tw-grid-cols-2" >
              <div class="tw-mr-2 tw-mb-2">
                <label for="name">{{ 'NAME' | translate }}</label>
                <input [ngClass]="InfoPO.get('name').valid ?'input-header':'input-header-error'"  formControlName="name" type="text">
              </div>

              <div class="tw-ml-2 tw-mb-2">
                <label for="email">{{ 'EMAIL' | translate }}</label>
                <input class="input-header" formControlName="email" type="text">
              </div>

              <div class="tw-mr-2 tw-mb-2">
                <label for="phone">{{ 'PHONE' | translate }}</label>
                <input [ngClass]="InfoPO.get('phone').valid?'input-header':'input-header-error'" class="input-header" formControlName="phone" type="text" mask="000-000-0000">
              </div>

              <div class="tw-ml-2 tw-mb-2">
                <label for="phone2">{{ 'PHONE2' | translate }}</label>
                <input class="input-header" formControlName="phone2" type="text" mask="000-000-0000">
              </div>
            </div>

          </div>

          <div class="tw-flex tw-py-4">
            <button (click)="savePO()" class="btn-blue-so tw-flex" style="cursor:pointer;" [ngClass]="{'disabled-button': InfoPO.invalid}">
              <span class="material-icons fs-1">check</span> {{ 'UPLOAD' | translate }}
            </button>
            <button (click)="closeModal(true)" class="btn-blue-so tw-flex ml-4" style="cursor:pointer;">
              <span class="material-icons fs-1">close</span> {{ 'CANCEL' | translate }}
            </button>
          </div>

        </form>

      </div>
    </div>
  </div>
</ng-template>