import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-salesorder-taxes',
  templateUrl: './salesorder-taxes.component.html',
  styleUrls: ['./salesorder-taxes.component.scss']
})
export class SalesorderTaxesComponent implements OnInit {
  @Input() salesOrder: any = [];
  constructor() { }

  ngOnInit() {

  }

}
