import { Component, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { SalesOrderItemsService } from 'src/app/_services/salesorderitems.service';
import { StoreproductsService } from 'src/app/_services/storeproducts.service';

@Component({
  selector: 'app-scannitemsios',
  templateUrl: './scannitemsios.component.html',
  styleUrls: ['./scannitemsios.component.scss']
})
export class ScannitemsiosComponent implements OnInit,OnDestroy{

  qrcValue:any = "B2AEE4C3-1279-11ED-AE44-0ABB5CE7E25C";
  destroy$: Subject<boolean> = new Subject<boolean>();
  options:any;
  itemSelected: any;

  constructor(
    private salesorderSer: SalesOrderService,
    public bsModalRef: BsModalRef,
    private alertify: AlertifyService,
    private itemsService: SalesOrderItemsService,
    private storeProductsService: StoreproductsService,
  ) { }


  ngOnInit(): void {
    // console.log(this.qrcValue);
    
    this.salesorderSer.getItemById(this.qrcValue)
        .pipe(takeUntil(this.destroy$))   
      .subscribe((data:any) => {
        if(data.item){
          this.options = data.options;
          this.itemSelected = data.item;
        }else{
          this.bsModalRef.hide();
          this.alertify.error("This item doesn't exist in store catalog");
        }
      }, err =>{
        this.bsModalRef.hide();
        this.alertify.error("This item doesn't exist in store catalog");
      });
  }

  submitItem(item:any) {
      

    this.storeProductsService.validateStoreItem(item.supItemID)
    .toPromise()
    .then((data:any)=>{
    if(data.length == 0){
      this.alertify.error("This product doesn't exists")
      return;
    }
    //verification if is active in Customers
    if(data[0].inactive == null || data[0].inactive == "" ){
      //verification if is active in Location
      if(data[0].active == 1 ){

        //updateJson of DB, Customers and Location
        // console.log(data[0].salesPriceNum)
        item.itemDelivery = ''
        item.salesPriceNum = data[0].salesPriceNum
        item.priceNum = data[0].salesPriceNum
        item.wholeSalePriceNum = data[0].Price
        item.quantityNum = 1

        let itemReady = this.convertItem(item)          
        this.itemsService.raiseSoItemEvent(itemReady);
        this.alertify.success('Item Added');          
        this.bsModalRef.hide();

        
      }else{
        this.alertify.error("This product is inactive")
      }
    }else{
      this.alertify.error("This product is inactive in DDS")
    }
    })
    .catch((err)=>console.log(err));
  }

  convertItem(soItem) {
    let soItemNew: any = [];
    
    soItemNew = Object.assign({}, soItem);
    soItemNew["quantityNum"] = 1;
    if (soItemNew["priceNum"] == undefined) {
      soItemNew["priceNum"] = soItem['salesPriceNum'];
    } else if (soItem['salesPriceNum'] == undefined ) {
      soItem['salesPriceNum'] = soItemNew["priceNum"];
    }
    soItemNew["priceNum"] = soItem['salesPriceNum'];
    soItemNew["manufacturer"] = soItem['Manufacturer'];
    soItemNew["status"] = 'insert';
    
    
    return soItemNew;
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  hideModal() {
    this.bsModalRef.hide();
  }

}
