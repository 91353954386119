


<div class="modal-body SSSS">
  <div class="d-flex pb-2 pt-2">
    
    <button
      [ngClass]="emailwasent ?  'btn btn-3emailsent':'btn btn-3'"
      [disabled]="showEmail === false"
      (click)="showSendEmail()"
    >
    <span class="material-icons">
      email
      </span> Send by Email
    </button>

    <button
      *ngIf="isAndroid || isIOS"
      class="btn btn-3"
      (click)="showPrint()"
    >
      <span class="material-icons">
        print
      </span> Print
    </button> 
  </div>
  <div class="modal-close-button">
    <app-closebutton></app-closebutton>
  </div>
  <div class="mx-auto d-none w-100 h-100" >
    <i class="fa fa-spinner fa-spin fa-5x"></i>
  </div>
  <div class="w-100" id="viewerContent"></div>
  <div class="d-print-table w-100 p-0 m-0" id="htmlContainer"></div>
</div>

