import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GlobalsService } from 'src/app/_services/globals.service';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { ViewChild } from '@angular/core';
import { AfterViewInit } from '@angular/core';
import { ViewChildren } from '@angular/core';
import { QueryList } from '@angular/core';
import { ElementRef } from '@angular/core';
import { AfterContentChecked } from '@angular/core';
import { SettingsService } from 'src/app/_services/settings.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { TransactionpaymentsService } from 'src/app/_services/transactionpayments.service';
import { CardReaderService } from 'src/app/_services/cardreader.service';
import { SignaturePad } from 'angular2-signaturepad';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-cardsignature',
  templateUrl: './cardsignature.component.html',
  styleUrls: ['./cardsignature.component.scss']
})
export class CardsignatureComponent implements OnInit, OnDestroy, AfterViewInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  savedSignature = '';
  @ViewChild(SignaturePad, {static: false}) signaturePad: SignaturePad;
  @ViewChildren('sigContainer1') public sigContainer1: QueryList<ElementRef>;
  @ViewChild('window', {static: false}) elementView: ElementRef;

  signaturePadOptions: Object = { // passed through to szimek/signature_pad constructor
    //    'minWidth': 5,
        'canvasWidth': 400,
        'canvasHeight': 400,
        'backgroundColor': 'white'
      };

      @Input() cardPayment: any;
      model: any;
      form = new FormGroup({});
      options: FormlyFormOptions = {};
      fields: FormlyFieldConfig[] = [
        {
          wrappers: ['formwrap'],
          templateOptions: { label: '' },
          fieldGroupClassName: 'row',
          fieldGroup: [
    
      ]},
     ];

  constructor(    
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private global: GlobalsService,
    private alertify: AlertifyService,
    private tpayments: TransactionpaymentsService,
    private cardService: CardReaderService,
    private settingsService: SettingsService) { }

  ngOnInit() {
    this.model = this.cardPayment;

  }

  submit () {
    if (this.signaturePad) {
      if (this.signaturePad.isEmpty() === false) {
        this.cardPayment['cardSigexp'] = btoa(this.signaturePad.toDataURL('image/jpeg'));

        this.tpayments.updateTransactionPayment(this.cardPayment['yy_TransactionPayment_tx'],  
        this.cardPayment)
        .pipe(takeUntil(this.destroy$))  
        .subscribe(
          (res: any) => {
              this.alertify.success('Payment Processed Successfully');
              this.cardService.cardSignatureAccept.next('Complete');
             this.cancelForm();
          }, error => {
              this.alertify.error(error);
              this.cardService.cardSignatureAccept.next('Failed');
             this.cancelForm();
          });
      }
    }

   
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  cancelForm() {
    //this.bsModalRef.hide();
    this.ngOnDestroy();
  }

  clear() {
    this.savedSignature = null;
    if (this.signaturePad !== undefined) {
      this.signaturePad.clear();
    }
  }

  ngAfterViewInit() {
    this.signaturePad.clear(); // invoke functions from szimek/signature_pad API
    if (this.cardPayment['cardSigexp'] !== null  && this.cardPayment['cardSigexp'] !== undefined) {
      //GET DATA FROM DATABASE THEN TRANSFORM TO URI TO BE ABLE TO VIEW WITH IMG TAG
      try {
      const data_uri = atob(this.cardPayment['cardSigexp']);
      const encoded_image = data_uri.split(',')[1];
      const decoded_image = atob(encoded_image);
      this.savedSignature = data_uri;
      } catch {}

    }
  }

}
