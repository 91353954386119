import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-gridtable',
  templateUrl: './gridtable.component.html',
  styleUrls: ['./gridtable.component.scss']
})
export class GridtableComponent implements OnInit {
  @Input() data: any = [];
  @Input() headers: any = [];
  @Input() heads: any = [];
  orderDir: any;
  orderField: any;
  constructor() { }

  ngOnInit() {
    //this.headers = ['1','2'];
    //this.data = ['1','2'];

  }

  search() {

  }

  changeDirection(head: any) {

  }

  selectSalesOrder(salesorder: any) {

  }

}
