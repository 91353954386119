<div class="modal-body">
  <app-closebutton></app-closebutton>
  <h6 class="label label-primary p-1">
    SALES ORDERS: {{ passSalesOrders.length }}
  </h6>
  <table
    id="salestable"
    class="table table-light table-custom mt-2"
    cellspacing="0"
    *ngIf="passSalesOrders"
  >
  <thead class="table-custom-header">
      <tr>
        <th>Order No</th>
        <th>Date</th>
        <!-- <th>Status</th> -->
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr class="table-light my-2" *ngFor="let salesorder of passSalesOrders">

        <td>{{ salesorder.SONumber }}</td>

        <td>{{ salesorder.dbDatets | date: "short" }}</td>

        <td>
          <button type="button" class="btn btn-add" (click)="selectSalesOrder(salesorder.SOID)">
            <span class="material-icons">  visibility </span>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
