import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { UserService } from 'src/app/_services/user.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { StoreUsersService } from 'src/app/_services/storeusers.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StoreusersformComponent } from './storeusersform/storeusersform.component';
import { GlobalsService } from 'src/app/_services/globals.service';
import 'rxjs/add/operator/takeUntil';
import { AuthService } from 'src/app/_services/auth.service';
import { Subject } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SettingsService } from 'src/app/_services/settings.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-storeusers',
	templateUrl: './storeusers.component.html',
	styleUrls: ['./storeusers.component.scss'],
})
export class StoreusersComponent implements OnInit, OnDestroy {
	destroy$: Subject<boolean> = new Subject<boolean>();
	cloverState: boolean = false;
	users: any[];

	atcfs = '';
	userRole = '';
	constructor(
		private storeUserService: StoreUsersService,
		private settingsService: SettingsService,
		private alertify: AlertifyService,
		public bsModalRef: BsModalRef,
		private modalService: BsModalService,
		private authService: AuthService,
		private translate: TranslateService, 
	) {
		storeUserService.editStoreUser$.subscribe((storeUser) => {
			this.loadStoreUsers();
		});
	}

	ngOnInit() {
		// this.loadStoreUsers();
		this.atcfs = this.authService.decodeToken('cfsat').cfsat;
		// this.userRole = localStorage.getItem('role').replace('"', '');
		this.userRole = this.authService.decodeToken('role').role;
		let specificFields = {
			values: ['cloverState'],
		};
		this.settingsService
			.getSpecificSettings(specificFields)
			.pipe(map((e) => e[0].cloverState))
			.toPromise()
			.then((cloverState) => (this.cloverState = cloverState == 0 ? false : true));
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	loadStoreUsers() {
		this.storeUserService
			.getStoreUsers(1, 100, '')
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: any) => {
					if (this.userRole === 'Manager') {
						res.result = res.result.filter((x) => x.role === 'Sales Person' || x.role === 'Cashier');
					}

					if (this.userRole === 'Admin') {
						res.result = res.result.filter((x) => x.role === 'Sales Person' || x.role === 'Cashier' || x.role === 'Manager');
					}

					this.users = res.result;
				},
				(error) => {
					this.alertify.error(error);
				}
			);
	}

	addStoreUsers() {
		const initialState = {
			user: {},
			users: this.users,
			cloverState: this.cloverState,
		};
		this.bsModalRef = this.modalService.show(StoreusersformComponent, {
			initialState,
			class: '',
			backdrop: true,
			ignoreBackdropClick: true,
		});
	}

	editUser(storeuser) {
		const initialState = {
			user: storeuser,
			users: this.users,
			cloverState: this.cloverState,
		};
		this.bsModalRef = this.modalService.show(StoreusersformComponent, {
			initialState,
			class: '',
			backdrop: true,
			ignoreBackdropClick: true,
		});
	}

	deleteUser(storeuser) {
		this.alertify.confirm(
			this.translate.instant('CONFIRM_DELETE_USER') +
				'<span class="tw-text-aedoranges">' +
				storeuser['name'] +
				'</span> ?',
			() => {
				this.storeUserService
					.deleteStoreUser(storeuser['uuid'])
					.pipe(takeUntil(this.destroy$))
					.subscribe(
						() => {
							this.alertify.success(this.translate.instant('USER_DELETED'));
							this.loadStoreUsers();
						},
						(error) => {
							this.alertify.error(error);
						}
					);
			},
			() => {}
		);
	}
}
