
<div class="modal-body" style="border: #f1f1f1 1px solid;border-radius:.5rem">
    <div class="modal-body-header">
      <h4>Send Email</h4>
    </div>
    <div class="modal-close-button">
    </div>
       
        <p class="tw-my-4 tw-text-aedoranges">You do not have an internet connection, if you wish, save this email to send it later</p>
        <form class="form-container w-100" [formGroup]="form" (ngSubmit)="submit(model)">
          <formly-form class="w-100" [form]="form" [fields]="fields" [model]="model">
            <div class="form-buttons-container tw-flex">
              <button class="btn btn-5 mr-2 tw-ml-auto" [disabled]="!form.valid" type="sumbit">
                <span class="material-icons">
                  save
                </span>
                Save
              </button>
              <button class="btn btn-6" (click)="cancelForm()" type="button">
                Cancel
              </button>
            </div>
          </formly-form>
        </form>
  </div>
  