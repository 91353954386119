import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyService } from './_services/currency.service';

@Pipe({
  name: 'currency',
  pure: false
})
export class CurrencyPipe implements PipeTransform {

  constructor(private currencyService: CurrencyService) { }

  transform(value: any, currencyCode?: string): any {
    const currentCurrency = currencyCode || this.currencyService.currentCurrencyValue;
    if (value === null || value === undefined || value === '') {
      return this.currencyService.getCurrencySymbol();
    } else if (typeof value === 'string') {
      return Number(value).toLocaleString('en-US', { style: 'currency', currency: currentCurrency});
    } else if (Array.isArray(value)) {
      return value.map(v => Number(v).toLocaleString('en-US', { style: 'currency', currency: currentCurrency}));
    } else if (typeof value === 'object') {
      return Object.values(value).map(v => Number(v).toLocaleString('en-US', { style: 'currency', currency: currentCurrency}));
    } else {
      return value.toLocaleString('en-US', { style: 'currency', currency: currentCurrency});
    }
  }
}