// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  
  // Production
  // urlLaravel:'https://aedpay-api.aedpay.com/',
  // apiUrl: 'https://aedpay-api.aedpay.com/api/',
  // apiLaravel: 'https://aedpay-api.aedpay.com/api/',
  // appKey: 'QNK1cLUcBpT/19DoqQnSjMxT3zAjgMsr0s/0wBoFfng=',
  // appVersion: '3.0.4.075',


  // Staging
  urlLaravel:'https://costoreapistaging.aedpay.com/',
  apiUrl: 'https://costoreapistaging.aedpay.com/api/',
  apiLaravel:'https://costoreapistaging.aedpay.com/api/',
  appKey: 'B3GB517tOuRrOPI/iG+72tPxu6hR3mUyGje8l2HZNDs=',
  appVersion: '1.0.0.006',
  linkLogin:'https://loginstaging.aedpay.com/'

  // urlLaravel:'https://cfsposappstaging-api.aedpay.com/',
  // apiUrl: 'https://cfsposappstaging-api.aedpay.com/api/',
  // apiLaravel:'https://cfsposappstaging-api.aedpay.com/api/',
  // appKey:'QNK1cLUcBpT/19DoqQnSjMxT3zAjgMsr0s/0wBoFfng=',
  // appVersion: '3.0.4.079',

  // //Local Marvin
  // urlLaravel:'http://cfsposapp.api.test/',
  // apiUrl: 'http://cfsposapp.api.test/api/',
  // apiLaravel: 'http://cfsposapp.api.test/api/',
  // appKey: 'dFW0xvCEGWwZPPy59HVbyJlnNipEBa4EHlcAtcZeFHU=',
  // appVersion: '3.0.4.022',


  //LOCALSERVER
  // urlLaravel:'http://aedpay.api/',
  // apiUrl: 'http://aedpay.api/api/',
  // apiLaravel: 'http://aedpay.api/api/',
  // appKey: 'QNK1cLUcBpT/19DoqQnSjMxT3zAjgMsr0s/0wBoFfng=',
  // appVersion: '3.0.4.030',

  //MELVIN
  // urlLaravel: 'http://127.0.0.1:8003/',
  // apiUrl: 'http://127.0.0.1:8003/api/',
  // apiLaravel: 'http://127.0.0.1:8003/api/',
  // appKey: 'B3GB517tOuRrOPI/iG+72tPxu6hR3mUyGje8l2HZNDs=',
  // appVersion: '1.0.0.006',
  // linkLogin:'http://127.0.0.1:4210/'

  // Eleana
  // urlLaravel: 'http://192.168.88.250:8003/',
  // apiUrl: 'http://192.168.88.250:8003/api/',
  // apiLaravel: 'http://192.168.88.250:8003/api/',
  // appKey: 'B3GB517tOuRrOPI/iG+72tPxu6hR3mUyGje8l2HZNDs=',
  // appVersion: '1.0.0.001',
  // linkLogin:'http://192.168.88.250:4210/'

  // Alvaro
  // urlLaravel: 'http://127.0.0.1:8003/',
  // apiUrl: 'http://127.0.0.1:8003/api/',
  // apiLaravel: 'http://127.0.0.1:8003/api/',
  // appKey: 'B3GB517tOuRrOPI/iG+72tPxu6hR3mUyGje8l2HZNDs=',
  // appVersion: '1.0.0.001',
  // linkLogin:'http://192.168.88.250:4210/'

  // Alvaro
  // urlLaravel: 'http://127.0.0.1:8003/',
  // apiUrl: 'http://127.0.0.1:8003/api/',
  // apiLaravel: 'http://127.0.0.1:8003/api/',
  // appKey: 'B3GB517tOuRrOPI/iG+72tPxu6hR3mUyGje8l2HZNDs=',
  // appVersion: '1.0.0.001',
  // linkLogin:'http://localhost:4210/'
};

