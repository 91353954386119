import { CdkDragDrop, CdkDragEnd, CdkDragEnter, CdkDragMove, CdkDragStart, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { EventsService } from 'src/app/_services/events.service';
import { SalesOrderItemsService } from 'src/app/_services/salesorderitems.service';
import * as Hammer from 'hammerjs'
import { trigger, keyframes, animate, transition } from '@angular/animations';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SizesIndex } from 'src/app/_resolvers/SizeSyno';
import { StoreproductsService } from 'src/app/_services/storeproducts.service';
import { UserService } from 'src/app/_services/user.service';
import { AuthService } from 'src/app/_services/auth.service';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { SettingsService } from 'src/app/_services/settings.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delivery-info',
  templateUrl: './delivery-info.component.html',
  styleUrls: ['./delivery-info.component.scss']
})
export class DeliveryInfoComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  showItems = false
  showFurni = false
  showItemsModaL = false
  @Input() eventid : any 
  @Input() event : any
  @Input() from : string
  animationState: string;
  selectAll = false
  itemModifying : any
  productsSelect:any = [];  
  products:any;
  companiesandsizes: any;
  companies: any;
  cursorY: any;
  cursorX: any;
  settings:any
  styles: any;
  sizes: any;
  showdeliveryinfo:boolean = false
  @ViewChild('popTemplateiOs', {static: false}) popTemplateiOs: ElementRef;
  modalRef: BsModalRef[]=[];
  showMoreSizes = ''
  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private itemsService: SalesOrderItemsService,
    private eventService: EventsService,
    private storeProductsService: StoreproductsService,
    private alertify: AlertifyService,
    private userService: UserService,
    private router: Router,
    private settingsService: SettingsService,
  ) { 
  }

  
  model = {
    deliveryinfo: []
  };
  form = new FormGroup({});

  options: FormlyFormOptions = {};
  //fields: FormlyFieldConfig[] = [{}];
  globalsizes = [];


  
  fields: FormlyFieldConfig[] = [
    {
      key: "deliveryinfo",
      type: "repeatsmall",
      wrappers: ["formwrap"],
      templateOptions: { label: "" },
      fieldGroupClassName: "row ",
      fieldArray: {
        fieldGroupClassName: "row ",
        templateOptions: {
          btnText: "Add Delivery or other charge",
          add: () => {},
          afterAdd: () => {},
          track: () => {
          },
          removePayment: () =>{}

        },

        fieldGroup: [
          {
            key: 'method',
            type: 'select-input',
            className: 'col-md-2',
            defaultValue: 'Delivery',
            templateOptions: {
              
              placeholder: '',
              required: true,
              options: [
                {label: 'Delivery', value: 'Delivery'},
                {label: 'Recycle', value: 'Recycle'},
                {label: 'Removal', value: 'Removal'},
                {label: 'Drop off Only', value: 'Drop off Only'},
                {label: 'Setup/Assembly', value: 'Setup/Assembly'},
                {label: 'Direct Shipment', value: 'Direct Shipment'}
              ],
            },

          },
          {
            key: "product",
            type: "select",
            className: "col-md-2",
            templateOptions: {
              options: this.productsSelect,
              valueProp: "product",
              labelProp: "product",
            }      
          },

          {
            key: "size",
            type: "select",
            className: "col-md-2",
            defaultValue: "ALL SIZES",
            templateOptions: {
              options: [],
              valueProp: "sizeName",
              labelProp: "sizeName",
            },
            lifecycle: {
              onInit: (form, field) => {
                let sizes = Object.assign([], this.companiesandsizes);
                
                sizes.push({
                  product: form.get("product").value,
                  sizeName: "",
                });

                sizes.push({
                  product: form.get("product").value,
                  sizeName: "ALL SIZES",
                });

                

                sizes = sizes.filter(
                  (x) => x.product === form.get("product").value
                );
                
                sizes.forEach( element => {

                  
                  element['sizeName'] = element['sizeName'].toString().trim().toUpperCase();
                  
                });
                
                field.templateOptions.options = sizes;

                form.get("product").valueChanges.subscribe((v) => {
                  let sizes = Object.assign([], this.companiesandsizes);
                  sizes.push({
                    product: form.get("product").value,
                    sizeName: "",
                  });
                  sizes.push({
                    product: form.get("product").value,
                    sizeName: "ALL SIZES",
                  });
               
                  sizes = sizes.filter(
                    (x) => x.product === form.get("product").value
                  );

                  sizes.forEach(element => {
                    if (element['sizeName']) {
                    element['sizeName'] = element['sizeName'].toString().trim().toUpperCase();
                    }
                  });
                  field.templateOptions.options = sizes;
                  
                });

              },
            },
          },
        
          {
            key: 'type',
            type: 'select',
            className: 'col-md-2',
            defaultValue: 'Delivery',
            templateOptions: {
              
              placeholder: '',
              required: true,
              options: [
                {label: 'Delivery', value: 'Delivery'},
                {label: 'Other Charges', value: 'Other Charges'},
              ],
            },

          },
          {
            key: "taxCode",
            type: "input",
            className: "col-md-1",
            templateOptions: {
              type: "text"
            }      
          },
          {
            key: "taxable",
            type: "checkbox",
            className: "col-md-1 tw-flex tw-justify-between",
            defaultValue: 0,
          },
          {
            key: "price",
            type: "input",
            className: "col-md-1",
            defaultValue: 0,
            templateOptions: {
              type: "number",
            },
          },
        ],
      },
    },
  ];



  ngOnInit() {
    if (this.router.url.toString().includes('salesorder') ){
      this.settingsService
    .SettingsEvent$
    .pipe(takeUntil(this.destroy$))   
    .subscribe(
      (data) => {
        // console.log(data)
        if(data[0] == undefined && data[0]["deliveryInfo"] != null) return;
        this.settings = data[0]
        this.model.deliveryinfo = JSON.parse(data[0]["deliveryInfo"]);
        this.showdeliveryinfo = true
        
      },
      (error) => {
        this.alertify.error(error);
      }
    );
    }else{
      let specificFields = {
        values: [
            "deliveryInfo", "DID", "password"
        ]
    }
    this.settingsService
    .getSpecificSettings(specificFields)
    .pipe(takeUntil(this.destroy$))   
    .subscribe(
      (data) => {
        // console.log(data)
        if(data[0] == undefined && data[0]["deliveryInfo"] == null) return;
        this.settings = data[0]
        this.model.deliveryinfo = JSON.parse(data[0]["deliveryInfo"]);
        this.showdeliveryinfo = true
        
      },
      (error) => {
        this.alertify.error(error);
      }
    );

    }
    

     

      this.loadStoreProducts()
  }

  loadStoreProducts() {
    this.storeProductsService
      .getStoreProducts(1, 1, "", "", "", "")
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {

          this.companies = res.result.companies;
          this.companiesandsizes = res.result.companiesandsizes;

          this.companiesandsizes.forEach(element => {
            element['sizeName'] = element['sizeName'].toString().trim().toUpperCase();
          });

          this.styles = res.result.styles;
          this.sizes = res.result.sizes;
          this.sizes.forEach(element => {
            element['sizeName'] = element['sizeName'].toString().toUpperCase();
          });
          this.products = res.result.products;
          this.productsSelect = res.result.products;
            this.fields[0].fieldArray.fieldGroup[2].templateOptions.options = this.sizes;
            this.fields[0].fieldArray.fieldGroup[1].templateOptions.options = this.products;

        },
        (error) => {
          this.alertify.error(error);
        }
      );
  } 

  submit() {

    
    delete this.settings['disc1'];
    delete this.settings['disc2'];
    delete this.settings['disc3'];
    delete this.settings['disc4'];
    delete this.settings['MFRM'];
    this.settings.deliveryInfo = JSON.stringify(this.model.deliveryinfo);
    this.settingsService.updateSettings(this.settings.DID, this.settings)
    .pipe(takeUntil(this.destroy$))   
      .subscribe(() => {
        this.alertify.success('Settings were updated');
      }, error => {
        this.alertify.error(error);
      });
  }



}
