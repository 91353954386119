import { Component, OnInit, OnDestroy, DoCheck, Input } from '@angular/core';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ActivatedRoute, Router } from '@angular/router';
import { PurchaseOrderService } from 'src/app/_services/purchaseorder.service';
import { AuthService } from 'src/app/_services/auth.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { NrnValidators } from 'src/app/nrn.validators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventsService } from 'src/app/_services/events.service';

@Component({
  selector: 'app-purchaseorder-form',
  templateUrl: './purchaseorder-form.component.html',
  styleUrls: ['./purchaseorder-form.component.scss']
})
export class PurchaseorderFormComponent implements OnInit, OnDestroy, DoCheck {
  destroy$: Subject<boolean> = new Subject<boolean>();
  purchaseOrder: any = [];
  poItems: any = [];
  poitemsItems: any = [];
  edit: boolean;
  purchaseForm: FormGroup;
  form = new FormGroup({});
  purchaseOrderNotChanged: any;
  userRole = '';
  todeletemysql:any;
  toPOdeletemysql:any;
  disabledbutton:boolean;
  eventid:any = ''

  fields: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldArray: {
        fieldGroupClassName: 'row border-bottom mb-1 m-1',
        templateOptions: {
          btnText: 'Add Address',
          },
      fieldGroup: [
    {
      key: 'deliveryAttn',
      type: 'input',
      className: 'col-md-12',
      templateOptions: {
      type: 'text',
      placeholder: 'Name',
      required: true
      }
    },
  {
    key: 'deliveryPhone',
      type: 'phone',
      className: 'col-md-6 mb-1',
      templateOptions: {
        placeholder: 'Phone',
        required: true,
        pattern: NrnValidators.FORMATS.maskphone
      },
      validation: {
        messages: {
          pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Phone Number`,
        },
      },
  },

  {
    key: 'deliveryEmail',
      type: 'input',
      className: 'col-md-6',
      templateOptions: {
        type: 'text',
        placeholder: 'Email',
        required: true,
        pattern: NrnValidators.FORMATS.email
      },
      validation: {
        messages: {
          pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Email`,
        },
      },


  },

  {
    key: 'deliveryAddress1',
      type: 'input',
      className: 'col-md-12',
      templateOptions: {
        type: 'text',
        placeholder: 'Address',
        required: true
      }
  },
  {
    key: 'deliveryAddress2',
      type: 'input',
      className: 'col-md-12',
      templateOptions: {
        type: 'text',
        placeholder: 'billingAddress2'
      }
  },
  {
    key: 'deliveryCity',
      type: 'input',
      className: 'col-md-4',
      templateOptions: {
        type: 'text',
        placeholder: 'City',
        required: true
      }
  },
  {
    key: 'deliveryState',
      type: 'select',
      className: 'col-md-4',
      templateOptions: {
        required: true,
        options: this.getStates(),
        valueProp: 'abbreviation',
        labelProp: 'name'
      }
  },
  {
    key: 'deliveryZip',
      type: 'input',
      className: 'col-md-4',
      templateOptions: {
        type: 'text',
        placeholder: 'Zip',
        required: true
      }
  }
],
  }}
  ];


  constructor(
    private alertify: AlertifyService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private route: ActivatedRoute,
    private purchaseSerivce: PurchaseOrderService,
    private authService: AuthService,
    private router: Router,
    private fb: FormBuilder,
    private http: HttpClient,
    private eventService:EventsService
  ) {
    // this.edit = this.router.getCurrentNavigation().extras.state.edit
    //console.log("ya en variable",this.edit);
  }




  getStates(): Observable<any[]> {
    // console.log(this.http.get<any[]>('assets/states.json'))
    return this.http.get<any[]>('assets/states.json');
  }

  ngOnInit() {
    this.userRole = this.authService.decodeToken('role').role;
    this.purchaseForm = this.fb.group({
    });



    this.purchaseSerivce.getPurchaseOrderEdit(this.route.snapshot.params['id'])
    .subscribe(
      (data:any) => {
          this.eventid = data.PO[0].CID
          this.purchaseOrder = data.PO[0];
          this.poItems = data.POItems;
          this.poitemsItems = data.poitemsItems;
          this.purchaseOrder['poItems'] = this.poItems;
          this.purchaseOrderNotChanged =this.purchaseOrder;


       
      }
    );


  }

funDelete(e:any){
this.todeletemysql = e;
this.disabledbutton = true
  } 
funPODelete(e:any){
this.toPOdeletemysql = e;
this.disabledbutton = true
}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  goBackToList() {
    this.router.navigate(['/eventsummary/'+this.eventid+'/PurchaseOrders'])
    // this._location.back();
  }

  // utilizando la variable booleana para esto
  ngDoCheck() {
    //This compares the SO for tracking changes and set the form as dirty for the guard
           // if (true) {
          // console.log('cambiando')

          //  const comparePO = JSON.parse(JSON.stringify( this.purchaseOrder));
          //  if (!_.isEqual(
          //   JSON.stringify(comparePO).replace(':null', ':""'),
          //   JSON.stringify(this.purchaseOrderNotChanged).replace(':null', ':""')
          //  )) {
          //     this.purchaseForm.markAsDirty();
          //     } else {
          //       this.purchaseForm.markAsPristine();
          // }
  }


  cancelForm() {

  }

  submitPO() {
  let postrequest = {"delete":this.toPOdeletemysql,
                     "update":this.todeletemysql};
  

  console.log(postrequest)
  this.purchaseSerivce.updatePurchaseOrderItems(postrequest)
    .pipe(takeUntil(this.destroy$))   
    .subscribe(res => {
      this.purchaseForm.markAsPristine();
      this.alertify.success('Purchase Order has been updated');      
      this.disabledbutton = false
    }, error => {
      this.alertify.error(error);
    });




    // let sendPO = JSON.parse(JSON.stringify( this.purchaseOrder));
    // for (let index = 0; index < sendPO['poItems'].length; index++) {
    //   const element = sendPO['poItems'][index];
    //   delete element['SONumber'];

    // }

    // this.purchaseSerivce.updatePurchaseOrder(this.purchaseOrder['MyPOID'], sendPO)
    // .pipe(takeUntil(this.destroy$))   
    // .subscribe(res => {
    //   this.alertify.success('Purchase Order has been updated');
    //   //this.salesOrderNotChanged = JSON.parse(JSON.stringify( this.purchaseOrder));
    //   //this.router.navigate(['/salesorder/' + res['SOID']]);
    // }, error => {
    //   this.alertify.error(error);
    // });
  }


}
