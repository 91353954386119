import { Component, ViewChild, ViewContainerRef } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
  selector: 'app-formwrap',
  templateUrl: './formwrap.component.html',
  styleUrls: ['./formwrap.component.scss']
})
export class FormwrapComponent extends FieldWrapper {
  @ViewChild('fieldComponent', {read: ViewContainerRef, static: false}) fieldComponent: ViewContainerRef;
}
