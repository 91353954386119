import { HostListener, EventEmitter, Output, ChangeDetectorRef, Component, ElementRef, Input, OnInit, OnDestroy, ViewChild, TemplateRef, AfterViewInit,} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs/Subscription';
import { Pagination } from 'src/app/_modules/pagination';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ReportingService } from 'src/app/_services/reporting.service';
import { SalesOrderItemsService } from 'src/app/_services/salesorderitems.service';
import { StockItemsService } from 'src/app/_services/stockitems.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { AddsalesorderitemComponent } from '../../salesorder/addsalesorderitem/addsalesorderitem.component';
import 'rxjs/add/operator/takeUntil';
import { StoreproductsComponent } from '../storeproducts/storeproducts.component';
import { StoreproductsService } from 'src/app/_services/storeproducts.service';
import { take } from 'rxjs/operators/take';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { UpcitemdbService } from 'src/app/_services/upcitemdb.service';
import { log } from 'console';
import { StoreProductsReportComponent } from 'src/app/reports/store-products-report/store-products-report.component';
import { NgIf } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
@Component({
	selector: 'app-stockitemslist',
	templateUrl: './stockitemslist.component.html',
	styleUrls: ['./stockitemslist.component.scss'],
})
export class StockitemslistComponent implements OnInit, OnDestroy, AfterViewInit {
	destroy$: Subject<boolean> = new Subject<boolean>();
	modalRef: BsModalRef;

	disabledPagination:boolean = false;

	@Input() salesOrderMode = false;
	stockItems: any = [];
	companies: any = [''];
	types: any = [];
	brands: any = [];
	products: any = [];
	pagination: Pagination;
	totalItems = 0;
	subscriptions: Subscription[] = [];
	selectedType = '';
	selectedMan = '';
	selectedBrand = '';
	selectedProduct = '';
	data: any = [];
	storeExcel: any = [];
	toSaveStockItems: any = [];
	showSave = false;
	isDealer = false;
	maxsize = 10;
	// Variables para Filtro con JS
	//State
	recordItems: any[];
	recordItemsDefault: any[];
	searched = false;
	//Abre el filtro ya seleccionado
	searchedInput = false;
	//Variables que estan por Default
	companiesDefault: any = [''];
	typesDefault: any = [];
	brandsDefault: any = [];
	typesForCustom: any = [];
	// companiesCustom are the companies created custom and companiesForCustom are all the companies in the storeproducts
	companiesCustom: any = [''];
	companiesForCustom: any = [''];
	productsDefault: any = [];
	productsForCustom: any = [];
	customStockItemEditing: any = null;
	//Total de Itesmen numeros
	totalitems = 0;
	p: number = 1;
	toEdit: string = '';
	showOrderwithStock: any = '';
	SOsItemsStock: any = [];
	customStockItems: FormGroup;
	productDeleted: FormGroup;
	showImageInput: boolean = false;
	imageUrl: string | null = null;
	foundProduct: any = []
	addBarcode: boolean  = false
	showModal: boolean  = false
	datesExpired:any = []
	maxQuantity: number;
	charCount: number = 0;
	user: any;
	userNameat: string;
	@ViewChild('imageInput') imageInput: ElementRef;
	@ViewChild('popTemplateiOs', { static: false }) popTemplateiOs: TemplateRef<any>;
	@ViewChild('dateTemplate', { static: false }) dateTemplate: TemplateRef<any>;
	@ViewChild('retailTemplate', { static: false }) retailTemplate: TemplateRef<any>;
	@ViewChild('deleteTemplate', { static: false }) deleteTemplate: TemplateRef<any>;
	@ViewChild('barcodeSelectionTemplate', { static: false }) barcodeSelectionTemplate: TemplateRef<any>;
	isAllowed: any;
	userRole: any;
	productsBarcode:any[] = []
	productPrices:any = [] 
	selectAll: boolean = false;
	productControl:number 
	filteredProducts: any = [];
	filteredBrands: any;
	filteredManufacturer: any;
	filteredTypes: any;


	constructor(
		private stockItemsService: StockItemsService,
		private alertify: AlertifyService,
		private chRef: ChangeDetectorRef,
		private router: Router,
		private authService: AuthService,
		private reportingService: ReportingService,
		private itemsService: SalesOrderItemsService,
		private userService: UserService,
		private bsModalRef: BsModalRef,
		private el: ElementRef,
		private globals: GlobalsService,
		private modalService: BsModalService,
		private storeProductsService: StoreproductsService,
		private fb: FormBuilder,
		private salesService: SalesOrderService,
		private upcItem: UpcitemdbService,
		private translate: TranslateService,
	) {}

	gotoSO(soid: any) {
		// [routerLink]="['/salesorder/'+item?.SOID]"
		this.salesService.sofrom.next('stockitems');
		this.router.navigate(['/salesorder/' + soid]);
	}

	gotoProductType(soid: any) {
		this.salesService.sofrom.next('stockitems');
		this.router.navigate(['/productType']);
	}

	ngOnInit() {
		this.pagination = {
			totalItems: 10,
			totalPages: 10,
			currentPage: 1,
			itemsPerPage: 10,
		};
		if (this.globals.isMobile()) {
			this.maxsize = 4;
		}
		if (this.router.url.toString().includes('stockitems') && this.authService.decodeToken('role').role !== 'Admin') {
			this.isDealer = true;
		}
		this.loadStockItemsLocal();

		// this.stockItemsService.addedStockItemSubject.next(null);
		// this.stockItemsService.addedStockItemSubject.pipe(takeUntil(this.destroy$)).subscribe((res) => {
		// 	if (res !== null) {
		// 		this.qtyStockAdded(res);
		// 	}
		// });

		this.productDeleted = this.fb.group({
			uuid: ['', []],
			barcode: ['', []],
			sku: ['', []],
			productName: ['', [Validators.required]],
			productCode: ['', []],
			price: [0, []],
			cost: [0, []],
			type: ['', []],
			warehouse: [1, [Validators.required, Validators.min(1)]],
			dateExpired: ['', []],
			reason: ['', [Validators.required]],
			description: ['', [Validators.required]],
			user: ['', []],
			weight: [0, []],
		});

		this.customStockItems = this.fb.group({
			uuid: ['', []],
			barcode: ['', []],
			sku: ['', []],
			brand: [' ', []],
			manufacture: [' ', []],
			productName: ['', [Validators.required]],
			price: [0, []],
			cost: [0, [Validators.required, Validators.min(1)]],
			type: ['', [Validators.required]],
			stockQty: [0, [
				
			]],
			dateExpired: ['', []],
			productDetails: ['', []],
			weight: [0, []],
			image: ['', []],
			anotherLote: [0, []],
			productCode: ['', []],
			warehouse: [0, [Validators.required, Validators.min(1)]],
			sendToRetail: [1, []],
			addQty: [0, []],
		});

		this.customStockItems.get('anotherLote').valueChanges.subscribe((value) => {
			if (value == 1) {
			  this.customStockItems.get('dateExpired').setValidators([Validators.required]);
			} else {
			  this.customStockItems.get('dateExpired').clearValidators();
			}	  		
			this.customStockItems.get('dateExpired').updateValueAndValidity();
		});

		this.addToInventory('');
		this.getRole()
		
	}

	//Método para agregar múltiples productos (1000 datos de muestra)
	addSampleProducts() {
		for (let i = 199; i < 500; i++) {
		  const sampleProduct = {
			uuid: this.globals.guid2(),
			barcode: Math.random() * 100000000000,
			sku: 'SKU' + i,
			brand: 'default',
			manufacture: 'default',
			productName: 'Product ' + i,
			//price: Math.random() * 100,
			cost: Math.random() * 100,
			type: '12345',
			stockQty: Math.random(),
			anotherLote: 0,
			productCode: 'PC' + i + Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1),
			dateExpired: null,
			productDetails: '',
			weight: 0,
			image: '',
			warehouse: 0,
		  };
	
		  this.stockItemsService.addStockItems(sampleProduct).subscribe((response) => {
			//console.log('Producto agregado:', response);
		  });
		}
	  }

	//to add products with the scan
	addToInventory(value: string) {
		if(value!=''){
		this.stockItemsService.getProductsFromUniqueProducts(value)
		.pipe(takeUntil(this.destroy$))
		.subscribe((data: any)=>{
			if(data.length>0){
                this.foundProduct = data.map((element: any) => {
                  	return {   
						warehouse: element.warehouse,   
						productCode: element.productCode,
						barcode: element.barcode,
						sku: element.sku,
						brand: element.brand,
						manufacture: element.manufacture,
						productName: element.productName,
						type: element.type,
						weight: element.weight,
						productDetails: element.productDetails,
						anotherLote: element.anotherLote,
						image: element.image,
               		}
				}); 

				this.foundProduct[0].warehouse = 1

				this.dateModal(this.foundProduct[0], this.dateTemplate)
				
				// 
				// console.log(this.foundProduct);

				//to open the edit modal for add product quantity
				//this.editStockItem(this.foundProduct[0], this.popTemplateiOs);

				//to update only the quantity without open edit modal
				// this.stockItemsService.updateStockItems(this.foundProduct[0].uuid, this.foundProduct[0])
				// .pipe(takeUntil(this.destroy$))
				// .subscribe(
				// 	(res: any) => {
				// 		console.log(res);
				// 		this.alertify.success('The quantity of the product has increased.')
				// 	},
				// 	(error) => {
				// 		this.alertify.error(error);
				// 	}
				// );

				// this.loadStockItemsLocal()
			}
			else {
				this.upcItem.getProduct(value)
				.subscribe((data: any) => {
					//console.log(data);
					if (data == 404){
						this.alertify.error(this.translate.instant('NO_PRODUCT_INFO'))
						this.openModal(this.popTemplateiOs, true)
					} else {
						let barcode = '';
						if (data.upc){
							barcode = data.upc
						} else {
							barcode = data.ean
						}

						const productName = data.title
						const sku = data.model
						const productDetails = data.description.replace(/<[^>]*>/g, '');
						const image = data.images[0]
						const brand = data.brand
						const type = ''
						const cost = 0
						const price = 0
						const warehouse = 1
						const weight = 0
						const anotherLote = 0
						const manufacture = ''
						
		
						const object = {
							productName,
							barcode,
							brand,
							sku,
							productDetails,
							weight,
							image,
							type,
							cost, 
							price,
							warehouse,
							anotherLote,
							manufacture
						}
						//console.log(object);
						this.editStockItem(object, this.popTemplateiOs, true);
					}
				});
			}
		})
	}
	}

	dateModal(data: any, template: TemplateRef<any>) {
		const barcode = data.barcode;
		
		this.stockItemsService.getDates(barcode).subscribe((response: any) => {
			//console.log(response);
			
			if (response['error']) {
        		this.alertify.error(this.translate.instant('NO_EXPIRATION_DATES'))
				this.editStockItem(data, this.popTemplateiOs, true);
			} else if (response['product']) {
				this.editStockItem(response['product'], this.popTemplateiOs, true);
			} else if (response['dates']) {
				this.datesExpired = response['dates'];
				this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
				this.showModal = true;
			}
		});
	}

	getProductByDate(date: string, barcode:string, id:string) {
		this.stockItemsService.getProductByDate(date, barcode, id).subscribe((response)=>{
			//console.log(response);
			this.showModal = false
			this.closeModal()
			this.editStockItem(response, this.popTemplateiOs)
		})
	}

	decrementQuantity(option:any) {
		if (option == 'sentToRetail') {
			if (this.customStockItems.get('sendToRetail').value > 0) {
				this.customStockItems.get('sendToRetail').setValue(this.customStockItems.get('sendToRetail').value - 1);
		  }
		} 
		
		if (option == 'delete') {
			if (this.productDeleted.get('warehouse').value > 0) {
				this.productDeleted.get('warehouse').setValue(this.productDeleted.get('warehouse').value - 1);
		  }
		}

		if (option == 'print') {
			if (this.customStockItems.get('sendToRetail').value > 0) {
				this.customStockItems.get('sendToRetail').setValue(this.customStockItems.get('sendToRetail').value - 1);
			}
		}
	}
	  
	incrementQuantity(option:any) {
		this.maxQuantity = this.customStockItems.get('warehouse').value;
	
		if (option == 'sentToRetail') {
			if (this.customStockItems.get('sendToRetail').value >= this.maxQuantity) {
				this.customStockItems.get('sendToRetail').setValue(this.maxQuantity);
				this.alertify.error(this.translate.instant('QUANTITY_EXCEEDS_AVAILABLE'))
			} else {
				this.customStockItems.get('sendToRetail').setValue(this.customStockItems.get('sendToRetail').value + 1);
			}
		}

		if (option == 'delete') {
			if (this.productDeleted.get('warehouse').value >= this.maxQuantity) {
				this.productDeleted.get('warehouse').setValue(this.maxQuantity);
				this.alertify.error(this.translate.instant('QUANTITY_EXCEEDS_AVAILABLE'))
			} else {
				this.productDeleted.get('warehouse').setValue(this.productDeleted.get('warehouse').value+ 1);
			}
		}

		if (option == 'print') {
			this.customStockItems.get('sendToRetail').setValue(this.customStockItems.get('sendToRetail').value + 1);
		}
		
	}

	onQuantityChange(event: any, diff:any) {
		this.maxQuantity = this.customStockItems.get('warehouse').value;

		if (diff == 'sentToRetail'){
			const qty = +event.target.value;
		
			if (qty > this.maxQuantity) {
			this.customStockItems.get('sendToRetail').setValue(this.maxQuantity);
			this.alertify.error(this.translate.instant('QUANTITY_EXCEEDS_AVAILABLE'))
			} else if (qty < 0) {
				this.customStockItems.get('sendToRetail').setValue(0);
			}
		}

		if (diff == 'delete'){
			const qty = +event.target.value;
		
			if (qty > this.maxQuantity) {
			this.productDeleted.get('warehouse').setValue(this.maxQuantity);
			this.alertify.error(this.translate.instant('QUANTITY_EXCEEDS_AVAILABLE'))
			} else if (qty < 0) {
				this.productDeleted.get('warehouse').setValue(0);
			}
		}
		
	}

	onPriceChange(event: any, productCode:any) {
		let price = +event.target.value;
		this.stockItemsService.retailPrices(productCode, price).subscribe((response:any)=>{
			//console.log(response.products);
			
			this.productPrices = response.products			
		})	
	}
	
	preventEnter(event: KeyboardEvent) {
		event.preventDefault(); 
	}

	sentToRetail() {
		//console.log(this.customStockItems);
		
		if (this.customStockItems.valid){
			if(this.customStockItems.value.sendToRetail >= 1) {
			
				this.stockItemsService
				.sentToretail(this.customStockItems.value)
				.pipe(takeUntil(this.destroy$))
				.subscribe(
					(res: any) => {
						if (res == 400) {
							this.alertify.error(this.translate.instant('QUANTITY_TO_RETAIL_EXCEEDS_WAREHOUSE'));
						} else {
							this.showSave = false;
							this.alertify.success(this.translate.instant('PRODUCT_SENT_TO_RETAIL'));
							this.modalRef.hide();
							this.resetForm();
							this.loadStockItemsLocal();
						}
					},
					(error) => {
						this.alertify.error(error);
					}
				);
			}
		}
	}

	openModal(template: TemplateRef<any>, add?:any) {
		this.resetForm();
		this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
		this.showModal = true
		if (add) {
			this.loadSelect();
		}
	}

	closeModal(noShow?:boolean) {
		this.modalRef.hide();
		if (noShow) {
			this.toEdit = '';
			this.customStockItemEditing = null;
			this.resetForm();
			this.showModal = false
		}
	}

	// to add a new image in inventory
	onImageSelected(event: Event) {
		const inputElement = event.target as HTMLInputElement;
		if (inputElement.files && inputElement.files.length > 0) {
			const file = inputElement.files[0];
			const imageFormat = ['image/jpeg', 'image/png', 'image/gif'];
				
			if (file && imageFormat.includes(file.type)) {
				const reader = new FileReader();
				reader.onload = () => {
					this.imageUrl = reader.result as string;
					this.customStockItems.get('image').setValue(this.imageUrl);
				};
				reader.readAsDataURL(file);
			} else {
				this.alertify.error(this.translate.instant('INVALID_FILE'))
			}
		}
	}

	loadSelect() {
		this.stockItemsService.getTypes().subscribe((res: any) => {
			this.types = res;
		});
	}

	saveCustomStock() {
		if (this.customStockItems.valid) {
			if (this.customStockItems.get('anotherLote').value != 1) {
				this.customStockItems.patchValue({ anotherLote: 0 })
			}
			this.customStockItems.controls['uuid'].setValue(this.globals.guid2());
			this.customStockItems.controls['warehouse'].setValue(this.customStockItems.get('stockQty').value);

			let data = JSON.parse(JSON.stringify(this.customStockItems.value))
			delete data.sendToRetail
			delete data.addQty
			delete data.price

			this.stockItemsService
				.addStockItems(data)
				.pipe(take(1))
				.subscribe(
					() => {
						this.resetForm();
						this.alertify.success(this.translate.instant('PRODUCT_ADDED_TO_INVENTORY'));
						this.loadStockItemsLocal();
						this.closeModal();
					},
					(error) => {
						this.alertify.error(error);
					}
				);
		}
	}

	resetForm() {
		this.customStockItems.reset()
		this.imageUrl = null;
		this.showImageInput = false;
		this.addBarcode = false;
		this.productDeleted.reset()
	}

	EditCustomStock() {
		if (this.customStockItems.valid) {
			let addQty = this.customStockItems.get('addQty').value
			let stockQty = this.customStockItems.get('stockQty').value
			let warehouse = this.customStockItems.get('warehouse').value

			stockQty = stockQty + addQty
			warehouse = warehouse + addQty

			this.customStockItems.patchValue({ stockQty: stockQty });
			this.customStockItems.patchValue({ warehouse: warehouse });
			if (this.customStockItems.get('anotherLote').value != 1) {
				this.customStockItems.patchValue({ anotherLote: 0 })
			}

			let data = JSON.parse(JSON.stringify(this.customStockItems.value))
			delete data.sendToRetail
			delete data.addQty
			delete data.price

			this.toSaveStockItems.push(this.customStockItems.value);

			this.stockItemsService
				.updateStockItems(data)
				.pipe(takeUntil(this.destroy$))
				.subscribe(
					(res: any) => {
						this.showSave = false;
						this.alertify.success(this.translate.instant('PRODUCT_UPDATED'));
						this.modalRef.hide();
						this.customStockItemEditing = null;
						this.resetForm();
						this.loadStockItemsLocal();
					},
					(error) => {
						this.alertify.error(error);
					}
				);
		}
	}

	showSalesOrders(stockItemId: any) {
		if (stockItemId != '') {
			this.storeProductsService
				.getSOsStockItems(stockItemId)
				.toPromise()
				.then((res) => {
					this.SOsItemsStock = res;
					this.showOrderwithStock = stockItemId;
				})
				.catch((err) => {
					console.log(err);
				});
		} else {
			this.showOrderwithStock = stockItemId;
			this.SOsItemsStock = [];
		}
	}

	// qtyStockAdded(storeProduct: any) {
	// 	const msg = 'Do you want to add this item to Stock Items?';
	// 	storeProduct.stockItemId = this.globals.guid();
	// 	if (storeProduct != null) {
	// 		this.storeProductsService
	// 			.addStockItems(storeProduct)
	// 			.pipe(take(1))

	// 			.subscribe(
	// 				() => {
	// 					this.alertify.success('Store Product(s) added to Stock Items');
	// 					this.loadStockItemsLocal();
	// 				},
	// 				(error) => {
	// 					this.alertify.error(error);
	// 				}
	// 			);
	// 	}
	// 	//this.bsModalRef.hide();
	// }

	loadstockItems() {
		this.stockItemsService
			.getStockItems(
				this.pagination.currentPage,
				this.pagination.itemsPerPage,
				this.selectedMan,
				this.selectedType,
				this.selectedBrand,
				this.selectedProduct
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: any) => {
					this.stockItems = res.result.data;
					this.pagination = res.pagination;
					this.totalItems = this.pagination.totalItems;
					if (this.salesOrderMode) {
						this.stockItems = this.stockItems.filter((stockItem) => stockItem['Stock'] > 0);
					}
					this.companies = res.result.companies;
					this.types = res.result.types;
					this.brands = res.result.brands;
					this.products = res.result.products;
				},
				(error) => {
					this.alertify.error(error);
				}
			);
	}

	clearFilter() {
		this.selectedMan = ''
		this.selectedType = ''
		this.selectedBrand = ''
		this.selectedProduct = ''
		this.filteredProducts = []
		this.filteredBrands = []
		this.filteredManufacturer = []
		this.filteredTypes = []
		this.loadStockItemsLocal()		
	}

	deleteStockItem(product: any) {
		this.closeModal(true)
		let msg = this.translate.instant('DELETE_PRODUCT_CONFIRMATION')

		this.alertify.confirm(
			msg,
			() => {
				this.stockItemsService
					.deleteFromWarehouse(product)
					.subscribe(
						() => {
							this.alertify.success(this.translate.instant('PRODUCT_DELETED_FROM_WAREHOUSE'));
							this.clearFilter();
							this.resetForm();
							this.loadStockItemsLocal();
						},
						(error) => {
							this.alertify.error(error);
						}
					);
			},
			() => {}
		);
	}

	editDate() {
		this.showModal = false
		this.closeModal()
		this.editStockItem(this.foundProduct[0], this.popTemplateiOs, true)
	}

	editStockItem(stock: any, tem?: TemplateRef<any>, add?:any) {
		//if (stock?.productsId == null) {
			this.loadSelect();
			this.customStockItemEditing = stock;
			this.customStockItems.patchValue(stock)
			this.modalRef = this.modalService.show(tem, { class: 'modal-lg', ignoreBackdropClick: true });
			this.showModal = true

			if (add == true) {
				this.customStockItemEditing = null
				this.addBarcode = true
			}
		// } else {
		// 	this.toEdit = stock?.uuid;
		// }
	}

	showDetails(data: any, tem?: TemplateRef<any>, add?:any) {
		this.customStockItems.patchValue(data)
		this.modalRef = this.modalService.show(tem, { class: 'modal-lg', ignoreBackdropClick: true });
		this.showModal = true
		if (add == 'retail'){
			this.stockItemsService.retailPrices(data.productCode, data.price).subscribe((response:any)=>{
				this.customStockItems.controls['price'].patchValue(response.price.price);			
			})
		}
	}

	deleteModal(product: any, tem: TemplateRef<any>) {
		this.customStockItems.controls['warehouse'].setValue(product?.warehouse);
		this.productDeleted.patchValue(product)
		this.productDeleted.controls['user'].setValue(this.userNameat);
		this.modalRef = this.modalService.show(tem, { class: 'modal-lg', ignoreBackdropClick: true });
		this.showModal = true
	}

	// removeStockItem(id: string, stockItem: any) {
	// 	const msg = 'Do you want to clear the inventory for this item?';
	// 	this.alertify.confirm(
	// 		msg,
	// 		() => {
	// 			this.stockItemsService
	// 				.removeStockItem(id, stockItem)
	// 				.pipe(takeUntil(this.destroy$))
	// 				.subscribe(
	// 					() => {
	// 						this.alertify.success('Stock Item(s) Invetory is Cleared');
	// 						this.clearFilter();
	// 						this.loadStockItemsLocal();
	// 					},
	// 					(error) => {
	// 						this.alertify.error(error);
	// 					}
	// 				);
	// 		},
	// 		() => {}
	// 	);
	// }

	//Funciones para filtro JS
	loadStockItemsLocal() {

		this.disabledPagination = true
		this.stockItemsService.getStockItems(this.pagination.currentPage,10,this.selectedMan,this.selectedType,this.selectedBrand,this.selectedProduct)
		.pipe(takeUntil(this.destroy$))
		.subscribe(
			(res: any) => {
				this.recordItems = res.result.data;
				this.recordItemsDefault = res.result.data;
				this.pagination = res.pagination;
				this.stockItems = res.result.data;
				this.companies = res.result.companies;
				this.companiesCustom = res.result.companiesCustom;
				this.types = res.result.type;
				this.brands = res.result.brand;
				this.products = res.result.products;
				this.totalItems = this.pagination.totalItems;
				this.companiesDefault = res.result.companies;
				this.typesDefault = res.result.type;
				this.brandsDefault = res.result.brand;
				this.productsDefault = res.result.products;
				this.disabledPagination = false;

			},
			(error) => {
				this.disabledPagination = false;
				this.alertify.error(error);
			}
		);
	}

	//For select filters
	loadFilter(event:any, field: any, value:any) {
		if (field == 'product') {
			this.selectedProduct = value
		} 
		if (field == 'brand') {
			this.selectedBrand = value
		} 
		if (field == 'manufacturer') {
			this.selectedMan = value
		} 
		if (field == 'type') {
			this.selectedType = value
		} 
		this.filteredProducts = []
		this.filteredBrands = []
		this.filteredManufacturer = []
		this.filteredTypes = []
		this.loadStockItemsLocal()
	}


	//Termina Funciones para Filtro JS
	get selectedTypeMod() {
		return this.selectedType;
	}

	set selectedTypeMod(value) {
		this.selectedType = value;
	}

	get selectedManMod() {
		return this.selectedMan;
	}

	set selectedManMod(value) {
		this.selectedMan = value;
	}

	get selectedBrandMod() {
		return this.selectedBrand;
	}

	set selectedBrandMod(value) {
		this.selectedBrand = value;
	}

	get selectedProductMod() {
		return this.selectedProduct;
	}

	set selectedProductMod(value) {
		this.selectedProduct = value;
	}

	editQuantity(id: string, stockItem: any, save?: boolean) {
		this.showSave = save;
		if (this.showSave) {
			this.toSaveStockItems.forEach((element) => {
				this.stockItemsService
					.updateStockItems(element)
					.pipe(takeUntil(this.destroy$))
					.subscribe(
						() => {
							this.showSave = false;
							this.toEdit = '';
						},
						(error) => {
							this.alertify.error(error);
						}
					);
			});
			this.alertify.success(this.translate.instant('SALES_PRICE_UPDATED'));
			this.toSaveStockItems = [];
		} else {
			this.showSave = true;
			this.toSaveStockItems.push(stockItem);
			stockItem.Stock = stockItem.quantity - stockItem.Sales;
		}
	}

	addSoItem(storeproduct: any) {
		if (storeproduct.manufacturer == null) {
			storeproduct.manufacturer = storeproduct.companyName;
		}
		let statusok = storeproduct['status'];
		if (storeproduct['taken']) {
			storeproduct['itemDelivery'] = 'Taken';
		} else {
			storeproduct['itemDelivery'] = 'In Stock';
		}

		storeproduct['quantityNum'] = 1;
		storeproduct['priceNum'] = storeproduct['salesPriceNum'];
		storeproduct['status'] = 'insert';
		this.itemsService.raiseSoItemEvent(storeproduct);

		storeproduct['status'] = statusok;
		storeproduct['Stock'] = storeproduct['Stock'] - 1;
		this.alertify.success('Item Added');

		// const initialState = {
		//   salesOrderMode: true,
		//   item: storeproduct
		// };
		// this.bsModalRef = this.modalService.show(AddsalesorderitemComponent, { initialState, class: '', backdrop: true, ignoreBackdropClick: true  });
	}

	exportToCSV(data: any = []) {
		this.reportingService.exportToCSV(data, 'StockItems.csv');
	}

	hideModal() {
		this.bsModalRef.hide();
	}

	loadStockItemsForExcel() {
		let data: any = [];
		this.stockItemsService
			.getStockItems(1, 1, this.selectedMan, this.selectedType, this.selectedBrand, this.selectedProduct, 1)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: any) => {
					data = res.result;
					this.exportToCSV(data);
				},
				(error) => {
					this.alertify.error(error);
				}
			);

		return data;
	}

	pageChanged(event: any): void {
		this.pagination.currentPage = event.page;
		this.loadStockItemsLocal();
	}

	addStockItem() {
		const initialState = {
			salesOrderMode: true,
			addToStock: true,
		};
		this.bsModalRef = this.modalService.show(StoreproductsComponent, { initialState, class: 'modal-xl w-100' });
	}

	async printBarcodes(product?: any, quantity?: number, paperSize?: string){ 
		
		let array: any[] = []
		if (product) {
			//const productToPrint = this.productsBarcode.find((p) => p.barcode == product);
			this.closeModal()
			const productToPrint = {barcode: product.uuid, name: product.productName}
			
			for (let i = 0; i < quantity; i++) {
			  array.push(productToPrint);
			}
		} else {
			array = this.productsBarcode
		}
		
		if(paperSize == '175') {
			let wait = await this.globals.loadScript();
			if(wait){
				const initialState = {
					showReport: 'Barcode175x067',
					reportdata: array
				};
				this.bsModalRef = this.modalService.show(StoreProductsReportComponent, {initialState, class: 'modal-xl modal-report'});
			}
		}
		else if(paperSize == '262') {
			let wait = await this.globals.loadScript();
			if(wait){
				const initialState = {
					showReport: 'Barcode2625x1',
					reportdata: array
				};
				this.bsModalRef = this.modalService.show(StoreProductsReportComponent, {initialState, class: 'modal-xl modal-report'});
			}
		} else {
			let wait = await this.globals.loadScript();
			if(wait){
				const initialState = {
					showReport: 'Barcode175x067',
					reportdata: array
				};
				this.bsModalRef = this.modalService.show(StoreProductsReportComponent, {initialState, class: 'modal-xl modal-report'});
			}
		}
		
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	ngAfterViewInit() {
		if (this.imageInput) {
		  this.imageInput.nativeElement.click();
		}
	  }

	updateCharCount(event: Event) {
		this.charCount = (event.target as HTMLTextAreaElement).value.length;
		if (this.charCount > 300) {
			const currentValue = (event.target as HTMLTextAreaElement).value;
			(event.target as HTMLTextAreaElement).value = currentValue.substr(0, 300);
			this.charCount = 300;
		}
	}

	getRole() {    
		this.user = JSON.parse(this.authService.decodeToken('user').user)
		this.userNameat = this.user['name']       
		this.userRole = this.authService.decodeToken('role').role;  
	}

	printProductsBarcode(openModal?) {
		this.stockItemsService.printProductsBarcode().subscribe((data:any [])=>{
			this.productsBarcode = data[0].map((product) => ({
				...product,
				selected: false,
				quantity: 1,
			  }));		
		})

		if(openModal) {
			this.openModal(this.barcodeSelectionTemplate)
		}
	}

	toggleSelectAll() {
		for (let product of this.productsBarcode) {
		product.selected = this.selectAll
		}
	}

	toggleSelection(product) {
		product.selected = product.selected
	}
	  
	async printSelectedProducts(paperSize?: string) { 
		let array: any[] = [];
		
		// Filtra los productos seleccionados y sus cantidades
		const selectedProducts = this.productsBarcode
			.filter((item) => item.selected && item.quantity > 0);
		
		if (selectedProducts.length > 0) {
			for (const selectedItem of selectedProducts) {
			const barcode = selectedItem.barcode;
			const name = selectedItem.name;
			const quantity = selectedItem.quantity;
			
			for (let i = 0; i < quantity; i++) {
				array.push({ barcode: barcode, name: name });
			}
			}
		}
		
		if (array.length > 0) {
			let wait = await this.globals.loadScript();
			if (wait) {
			let showReport: string;
			if (paperSize === '262') {
				showReport = 'Barcode2625x1';
			} else {
				showReport = 'Barcode175x067';
			}
		
			const initialState = {
				showReport,
				reportdata: array
			};
		
			this.bsModalRef = this.modalService.show(StoreProductsReportComponent, { initialState, class: 'modal-xl modal-report' });
			}
		}
	}
	
	filterProducts(query: string, option:any) {
		const searchTermLowerCase = query.toLowerCase();
		const searchRegex = new RegExp(searchTermLowerCase, 'i');

		if (searchTermLowerCase != ''){
			if (option == 'productName'){
				this.filteredProducts = this.products.filter((item: any) => {
					const itemValues = [
						item.productName
					];
	
					const validItemValues = itemValues
						.filter((value) => value !== null && value !== undefined)
						.map((value) => value.toLowerCase());
	
	
					const searchTerms = searchTermLowerCase.split(' ');

					return validItemValues.some((value: string) => {
						return searchTerms.every((term: string) => {
							const termRegex = new RegExp(term);
							return termRegex.test(value);
						});
					});
				});
			} 

			if (option == 'brand'){
				this.filteredBrands = this.brands.filter((item: any) => {
					const itemValues = [
						item.brand
					];
	
					const validItemValues = itemValues
						.filter((value) => value !== null && value !== undefined)
						.map((value) => value.toLowerCase());
	
					const searchTerms = searchTermLowerCase.split(' ');

					return validItemValues.some((value: string) => {
						return searchTerms.every((term: string) => {
							const termRegex = new RegExp(term);
							return termRegex.test(value);
						});
					});
				});
			} 

			if (option == 'manufacturer'){
				this.filteredManufacturer = this.companies.filter((item: any) => {
					const itemValues = [
						item.manufacture
					];
	
					const validItemValues = itemValues
						.filter((value) => value !== null && value !== undefined)
						.map((value) => value.toLowerCase());
	
					const searchTerms = searchTermLowerCase.split(' ');

					return validItemValues.some((value: string) => {
						return searchTerms.every((term: string) => {
							const termRegex = new RegExp(term);
							return termRegex.test(value);
						});
					});
				});
			} 

			if (option == 'type'){
				this.filteredTypes = this.types.filter((item: any) => {
					const itemValues = [
						item.productType
					];
	
					const validItemValues = itemValues
						.filter((value) => value !== null && value !== undefined)
						.map((value) => value.toLowerCase());
	
	
					const searchTerms = searchTermLowerCase.split(' ');

					return validItemValues.some((value: string) => {
						return searchTerms.every((term: string) => {
							const termRegex = new RegExp(term);
							return termRegex.test(value);
						});
					});
				});
			} 
		} else {
			this.filteredProducts = []
			this.filteredBrands = []
			this.filteredManufacturer = []
			this.filteredTypes = []
		}
	}
}
