import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';
import { PaginatedResult } from '../_modules/pagination';



@Injectable({
  providedIn: 'root'
})
export class EventsSMService {
  baseUrl = environment.apiUrl;
  laravel = environment.apiLaravel;
  salesOrderEvent = new BehaviorSubject<string>('0');
  builderrepetsmall = new BehaviorSubject<any>({});;
  editEvent = new BehaviorSubject<string>('0');
  refreshstats = new BehaviorSubject<string>('');
  changeAddressWHA = new BehaviorSubject<any>('');
  changeDefaultWHA = new BehaviorSubject<any>('');
  indexDefaultWHA = new BehaviorSubject<any>('');

constructor(private http: HttpClient) { }

getEventsSM(event?) {
  let params = new HttpParams();
  if (event != null) {
    params = params.append('event', event);
  }

   return this.http.get<any []>(this.laravel + 'eventssm', {observe: 'response', params})
   .pipe(
    map( response => {

      return response.body;
    })
  );
  }

  getEventOverHead(event?:any) {      
     return this.http.get(this.laravel + 'getEventOverHead/'+event);  
  }

  saveEventOverHead(post:any){    
     return this.http.post(this.laravel + 'saveEventOverHead',post); 
  }

    getFundRaiser(event: any) {
      return this.http.get(this.laravel + 'getfundraise/'+ event);
    }

    getPayoutTeams(event?:any){
      let params = new HttpParams();
      if ( event != null) {
        params = params.append('event', event);
      }
      return this.http.get<any>(this.laravel + 'getEventTypeSummaryContributionByTeamsReport', {params});
    }
    
   getEventsStats(event?) {
    let params = new HttpParams();
    if (event != null) {
      params = params.append('event', event);
    }
     return this.http.get<any []>(this.laravel + 'getEventStats/' + event, {observe: 'response', params})
      .pipe(
        map( response => {
          return response.body;
        })
      );
  }



  getEventsReport(event?) {
    let params = new HttpParams();
    if (event != null) {
      params = params.append('event', event);
    }
     return this.http.get<any []>(this.laravel + 'eventsReport', {observe: 'response', params})
      .pipe(
        map( response => {
          return response.body;
        })
      );
  }

  getEventsm(id): Observable<any> {
          return this.http.get<any>(this.laravel + 'eventssm/' + id);
  }

  addEvent(event: any) {
    return this.http.post(this.laravel + 'eventssm', event);
  }

  updateEvent(id, event: any) {
    return this.http.patch(this.laravel + 'eventssm/' + id, event);
  }

  deleteEvent(id) {
    return this.http.delete(this.laravel + 'eventssm/' + id);
  }

  uploadStats(statsSummary: any, userLogin: any) {

    let params = new HttpParams();
    params = params.append('myString', statsSummary);
    params = params.append('clave', userLogin);
    return this.http.post(this.laravel + 'uploadEventStats', params);
    return this.http.post('https://dealer.cfsbeds.com/aedpay_to_cfsbeds/createEventStatsAedPay.php', params);
  }


  uploadStatsNewDDS(data){
    return this.http.post(this.laravel + 'uploadEventStatsNewDDS', data);
  }

  getEventTypeSummary(page?, itemsPerPage?,  search?, searchEvent?, order?, 
    orderDir?, dateFilter?, statusFilter?) {
    let params = new HttpParams();
    const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();

    if ( page != null && itemsPerPage != null) {
      params = params.append('page', page);
      params = params.append('per_page', itemsPerPage);

    }

    if (search != null) {
      params = params.append('search', search);
    }
    if (order != null) {
      params = params.append('order', order);
    }
    if (orderDir != null) {
      params = params.append('orderDir', orderDir);
    }

    if (dateFilter != null) {
      params = params.append('dateFilter', dateFilter);
    }

    if (statusFilter != null) {
      params = params.append('statusFilter', statusFilter);
    }


    if (searchEvent != null) {
      params = params.append('searchEvent', searchEvent);
    }

    return this.http.get<any>(this.laravel + 'getEventTypeSummary',
    {observe: 'response', params})
    .pipe(
      map( response => {
          paginatedResult.result = (response.body.data);
          if (response.body.total != null) {
            paginatedResult.pagination = {
              totalItems: JSON.parse(response.body.total),
              totalPages: JSON.parse(response.body.last_page),
              currentPage: JSON.parse(response.body.current_page),
              itemsPerPage: JSON.parse(response.body.per_page)
            };
          }
          return paginatedResult;
        })
      );
    }



    getEventTypeSummaryContribution(page?, itemsPerPage?,  search?, searchEvent?, order?, 
      orderDir?, dateFilter?, statusFilter?) {
      let params = new HttpParams();
      const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();
  
      if ( page != null && itemsPerPage != null) {
        params = params.append('page', page);
        params = params.append('per_page', itemsPerPage);
  
      }
  
      if (search != null) {
        params = params.append('search', search);
      }
      if (order != null) {
        params = params.append('order', order);
      }
      if (orderDir != null) {
        params = params.append('orderDir', orderDir);
      }
  
      if (dateFilter != null) {
        params = params.append('dateFilter', dateFilter);
      }
  
      if (statusFilter != null) {
        params = params.append('statusFilter', statusFilter);
      }
  
  
      if (searchEvent != null) {
        params = params.append('searchEvent', searchEvent);
      }
  
      return this.http.get<any>(this.laravel + 'getEventTypeSummaryContribution',
      {observe: 'response', params})
      .pipe(
        map( response => {
            paginatedResult.result = (response.body.data);
            if (response.body.total != null) {
              paginatedResult.pagination = {
                totalItems: JSON.parse(response.body.total),
                totalPages: JSON.parse(response.body.last_page),
                currentPage: JSON.parse(response.body.current_page),
                itemsPerPage: JSON.parse(response.body.per_page)
              };
            }
            return paginatedResult;
          })
        );
      }

    getEventTypeSummaryDetail(page?, itemsPerPage?,  search?, searchEvent?, order?, 
      orderDir?, dateFilter?, statusFilter?,
      typeFilter?, sizeFilter?
      ) {
      let params = new HttpParams();
      const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();

      if ( page != null && itemsPerPage != null) {
        params = params.append('page', page);
        params = params.append('per_page', itemsPerPage);

      }

      if (search != null) {
        params = params.append('search', search);
      }
      if (order != null) {
        params = params.append('order', order);
      }
      if (orderDir != null) {
        params = params.append('orderDir', orderDir);
      }

      if (dateFilter != null) {
        params = params.append('dateFilter', dateFilter);
      }

      if (statusFilter != null) {
        params = params.append('statusFilter', statusFilter);
      }

      if (typeFilter != null) {
        params = params.append('searchType', typeFilter);
      }

      if (sizeFilter != null) {
        params = params.append('searchSize', sizeFilter);
      }

      if (searchEvent != null) {
        params = params.append('searchEvent', searchEvent);
      }

      return this.http.get<any>(this.laravel + 'getEventTypeSummaryDetail',
      {observe: 'response', params})
      .pipe(
        map( response => {
            paginatedResult.result = (response.body.data);
            if (response.body.total != null) {
              paginatedResult.pagination = {
                totalItems: JSON.parse(response.body.total),
                totalPages: JSON.parse(response.body.last_page),
                currentPage: JSON.parse(response.body.current_page),
                itemsPerPage: JSON.parse(response.body.per_page)
              };
            }
            return paginatedResult;
          })
        );
      }


}
