import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-select-input',
  template: `
    <div class="tw-mr-4 tw-mt-2">
              <div  *ngIf="!showSelectSP" class="tw-flex tw-border tw-border-aedgrayl tw-rounded-md tw-px-1">
                <input 
                [formControl]="formControl"
                [formlyAttributes]="field"
                  placeholder="Write the Custom Charge"
                  class="form-control tw-border-none tw-outlined-0 tw-bg-aedgrayback "
                />
                <button style="height:auto"
                class="tw-bg-aedgrayback tw-text-aedgraydark tw-text-sm tw-flex"
                (click)="selectFromStorage()">
                  <span class="tw-my-auto tw-bg-aedgrayback material-icons"> keyboard_arrow_down </span>
                </button>            
              </div>
    
            <select [formControl]="formControl" [formlyAttributes]="field" (change)="changeselectSP()" 
            *ngIf="showSelectSP" visibility="hidden" id="idselectsalesperson" class="form-control tw-bg-aedgrayback" >
              <option [ngValue]="salesp" *ngFor="let salesp of salesPersons">
                {{ salesp }}
              </option>
              <option value="Other">Other</option>
            </select>
      </div>
  `,
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent extends FieldType<FieldTypeConfig> implements OnInit  {
  showSelectSP = false;

  salesPersons = ['Delivery', 'Recycle', 'Removal', 'Drop off Only', 'Setup/Assembly','Direct Shipment'];

  // constructor() {
  //   super(builder);
  //  }

  ngOnInit(): void {
  }

  selectFromStorage(){
    if(this.showSelectSP){
      this.showSelectSP = false      
    }else{      
      this.showSelectSP = true
    }
  }

  changeselectSP(){
    if(this.formControl.value == 'Other'){
      this.formControl.setValue('');
      this.showSelectSP = false
    }
  }

}
