import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-choosedevice',
  templateUrl: './choosedevice.component.html',
  styleUrls: ['./choosedevice.component.scss']
})
export class ChoosedeviceComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
