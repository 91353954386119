import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { SalesOrderService } from './salesorder.service';
import { SettingsService } from './settings.service';

import { take } from 'rxjs/operators';
import { GlobalsService } from './globals.service';

declare var Stimulsoft: any;
@Injectable({
  providedIn: 'root'
})
export class ReportsService {
  baseUrl = environment.apiUrl;
  laravel = environment.apiLaravel; 
  editSalesOrder = new BehaviorSubject<string>('');
  
  dashboardYear = new BehaviorSubject<number>(new Date().getFullYear());
  dashboardYearCompare = new BehaviorSubject<number>(new Date().getFullYear());
 activateYearCompare = new BehaviorSubject<boolean>(false);
    onlyLoadOnce = new BehaviorSubject<string>(''); 
    keyYTDtable = new BehaviorSubject<string>(''); 
    
    //email sent
    SOIDemailsent = new BehaviorSubject<any>(''); 
    SOIDemailsentSOmobile = new BehaviorSubject<any>(''); 
    SOIDemailsentSo = new BehaviorSubject<any>(''); 
    loadNotitemsMobile = new BehaviorSubject<any>(''); 

  triggerSaveSO = new BehaviorSubject<boolean>(false);
  dataStream = new BehaviorSubject<string>(''); 

constructor(private http: HttpClient, 
  private salesService: SalesOrderService, 
  private settingsService: SettingsService,
  private globals: GlobalsService
  ) { 
  }



  getMatGroupType(params?:any){
    return this.http.post<any []>(this.laravel + 'getMatGroupType',params)   
}

 generateDayWiseTimeSeries(baseval, count, yrange) {
  var i = 0;
  var series = [];
  while (i < count) {
    var x = baseval;
    var y = Math.floor(Math.random() * (yrange.max - yrange.min + 1)) + yrange.min;

    series.push([x, y]);
    baseval += 86400000;
    i++;
  }
  return series;
}


getDashboardEventSummaryData(event?, dateFrom?, dateTo?): Observable<any> {
  let params = new HttpParams();
  if ( event != null) {
    params = params.append('event', event);
  }

  if ( dateFrom != null) {
    params = params.append('dateFrom', dateFrom);
  }

  if ( dateTo != null) {
    params = params.append('dateTo', dateTo);
  }
    let result: any;

    return this.http.get<any []>(this.laravel + 'getDashboardEventSummaryData', {params})
    .pipe(
      map( response => {
        result = (response);
        return result;
      })
    );

}

//OLD
// getSummaryByMonth(event?, dateFrom?, dateTo?): Observable<any> {
//   let params = new HttpParams();
//   if ( event != null) {
//     params = params.append('event', event);
//   }

//   if ( dateFrom != null) {
//     params = params.append('dateFrom', dateFrom);
//   }

//   if ( dateTo != null) {
//     params = params.append('dateTo', dateTo);
//   }
//     let result: any;

//     return this.http.get<any []>(this.laravel + 'getSummaryByMonthReport', {params})
//     .pipe(
//       map( response => {
//         result = (response);
//         return result;
//       })
//     );

// }

//new
getSummaryByMonth(data): Observable<any> {
    return this.http.post<any []>(this.laravel + 'getSummaryByMonthReport', data);
}

//old
// getTypeSummary(event?, dateFrom?, dateTo?): Observable<any> {
//   let params = new HttpParams();
//   if ( event != null) {
//     params = params.append('event', event);
//   }

//   if ( dateFrom != null) {
//     params = params.append('dateFrom', dateFrom);
//   }

//   if ( dateTo != null) {
//     params = params.append('dateTo', dateTo);
//   }
//     let result: any;

//     return this.http.get<any []>(this.laravel + 'getTypeSummary', {params})
//     .pipe(
//       map( response => {
//         result = (response);
//         return result;
//       })
//     );

// }

//new 
getTypeSummary(params:any): Observable<any> { 
      return this.http.post(this.laravel + 'getTypeSummary', params); 
  }


getTypeSummaryDetail(event?, dateFrom?, dateTo?, type?): Observable<any> {
  let params = new HttpParams();
  if ( event != null) {
    params = params.append('event', event);
  }

  if ( dateFrom != null) {
    params = params.append('dateFrom', dateFrom);
  }

  if ( dateTo != null) {
    params = params.append('dateTo', dateTo);
  }

  if ( type != null) {
    params = params.append('type', type);
  }

    let result: any;

    return this.http.get<any []>(this.laravel + 'getTypeSummaryDetail', {params})
    .pipe(
      map( response => {
        result = (response);
        return result;
      })
    );
}




getTypeSummaryDetailManufacturer(event?, dateFrom?, dateTo?, type?): Observable<any> {
  let params = new HttpParams();
  if ( event != null) {
    params = params.append('event', event);
  }

  if ( dateFrom != null) {
    params = params.append('dateFrom', dateFrom);
  }

  if ( dateTo != null) {
    params = params.append('dateTo', dateTo);
  }

  if ( type != null) {
    params = params.append('type', type);
  }

    let result: any;

    return this.http.get<any []>(this.laravel + 'getTypeSummaryDetailManufacturers', {params})
    .pipe(
      map( response => {
        result = (response);
        return result;
      })
    );
}


// getSummaryByMonthDetail(event?, dateFrom?, dateTo?): Observable<any> {
//   let params = new HttpParams();
//   if ( event != null) {
//     params = params.append('event', event);
//   }

//   if ( dateFrom != null) {
//     params = params.append('dateFrom', dateFrom);
//   }

//   if ( dateTo != null) {
//     params = params.append('dateTo', dateTo);
//   }
//     let result: any;

//     return this.http.get<any []>(this.laravel + 'getSummaryByMonthDetailReport', {params})
//     .pipe(
//       map( response => {
//         result = (response);
//         return result;
//       })
//     );

// }
getSummaryByMonthDetail(event?, dateFrom?, dateTo?): Observable<any> {
  let params = new HttpParams();
  if ( event != null) {
    params = params.append('event', event);
  }

  if ( dateFrom != null) {
    params = params.append('dateFrom', dateFrom);
  }

  if ( dateTo != null) {
    params = params.append('dateTo', dateTo);
  }
    let result: any;

    return this.http.get<any []>(this.laravel + 'eventsReport', {params})
    .pipe(
      map( response => {
        result = (response);
        return result;
      })
    );

}
getYearForDashboard(){
    return this.http.get<any []>(this.laravel + 'getYearForDashboard');  
}

getDashboardEventDetail(event?, dateFrom?, dateTo?, type?): Observable<any> {
  let params = new HttpParams();
  if ( event != null) {
    params = params.append('event', event);
  }

  if ( dateFrom != null) {
    params = params.append('dateFrom', dateFrom);
  }

  if ( dateTo != null) {
    params = params.append('dateTo', dateTo);
  }

  if ( type != null) {
    params = params.append('type', type);
  }

    let result: any;

    return this.http.get<any []>(this.laravel + 'getDashboardEventDetail', {params})
    .pipe(
      map( response => {
        result = (response);
        return result;
      })
    );
}




emailReport(data: any) {
  return this.http.patch(this.laravel + 'sendEmail', data);
}

emailReportSave(data: any) {
  return this.http.post(this.laravel + 'sendEmailSave', data);
}
 getInvoice(id: any) : Observable<any> {

   
    let viewer = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
    let report = new Stimulsoft.Report.StiReport();  
   
  const invoiceObservable = new Observable(observer => {           
    
    let contactinfo = '';
    let attachmentStream = '';
    this.settingsService.getSettings(0)
    .pipe(take(1))
    .subscribe( data => {
      const settings = data[0];
      //if (settings) {
        contactinfo = settings['contactInfo'];
        
        contactinfo = contactinfo != null ? (contactinfo.toString().replace(/\r/g, '<br>' )) : "";
        contactinfo = contactinfo != null ? (contactinfo.toString().replace(/\n/g, '<br>' )) : "";
      //}
      // Stimulsoft.Base.StiLicense.Key =this.globals.stimulSoft;
      this.salesService.getInvoice(id)
      .pipe(take(1))
      .subscribe((datasale: Response) => {   
      this.http.get('reports/Invoice.mrt')
      .pipe(take(1))
        .subscribe(async (res) => {     
          report.loadFile('reports/Invoice.mrt');
          
          datasale['delivery'].forEach(element => {
          if (id !== element['foreignSOID']) {
         // console.log(id);
        //  console.log(element['foreignSOID']);
          }
        });     
        datasale['delivery'] = datasale['delivery'].filter(x => x['foreignSOID'] === id); 
        datasale['Payments'] = datasale['Payments'].filter(x => x['foreignSOID'] === id);

          // datasale['SalesOrder']['disc1exp'] = atob(datasale['SalesOrder']['disc1exp']);
          // datasale['SalesOrder']['disc2exp'] = atob(datasale['SalesOrder']['disc2exp']);
          // datasale['SalesOrder']['disc3exp'] = atob(datasale['SalesOrder']['disc3exp']);
          // datasale['SalesOrder']['disc4exp'] = atob(datasale['SalesOrder']['disc4exp']); 
          // datasale['SalesOrder']['orderSigexp'] = atob(datasale['SalesOrder']['orderSigexp']); 
          if (datasale['SalesOrder']['disc1exp']) {
            datasale['SalesOrder']['disc1exp'] = atob(datasale['SalesOrder']['disc1exp']); 
            }
            if (datasale['SalesOrder']['disc2exp']) {
            datasale['SalesOrder']['disc2exp'] = atob(datasale['SalesOrder']['disc2exp']); 
            } 
            if (datasale['SalesOrder']['disc3exp']) {
            datasale['SalesOrder']['disc3exp'] = atob(datasale['SalesOrder']['disc3exp']);
            }
            if (datasale['SalesOrder']['disc4exp']) {
            datasale['SalesOrder']['disc4exp'] = atob(datasale['SalesOrder']['disc4exp']);
            }
            if (datasale['SalesOrder']['orderSigexp']) {
            datasale['SalesOrder']['orderSigexp'] = atob(datasale['SalesOrder']['orderSigexp']);
            }
       

              datasale['SalesOrder']['contactInfo'] = contactinfo;     
              const dssalesorder = new Stimulsoft.System.Data.DataSet();  
              report.dictionary.databases.clear();
              //console.log(JSON.stringify(datasale));
              report.dictionary.dataStore.clear();
              dssalesorder.readJson(datasale);
              report.regData('salesorder', null, dssalesorder);
                      viewer.report = report;
                      viewer.width = '100%';
                      viewer.options.toolbar.showAboutButton = false;
                      viewer.options.toolbar.showAboutButton = false;
                      viewer.options.toolbar.showEmailButton = true;
                      viewer.options.appearance.scrollbarsMode = true;
                      viewer.options.appearance.showTooltipsHelp = false;
                      viewer.options.appearance.showFormsHelp = false;
                      viewer.options.appearance.showFormsHints = false;
                      viewer.options.exports.showExportToHtml = false;
                      viewer.options.exports.showExportToHtml5 = false;
                      viewer.options.exports.showExportToDataFile = false;
                      viewer.options.exports.showExportToDocument = false;

                  


        
          report.renderAsync(async () => {
              await new Promise(resolve => {
                setTimeout(
                  () => {
                 
                const settingsrep = new Stimulsoft.Report.Export.StiPdfExportSettings();
                const service = new Stimulsoft.Report.Export.StiPdfExportService();
                const stream = new Stimulsoft.System.IO.MemoryStream(); 
                

                report.exportDocumentAsync((pdfData: any) => { 
                service.exportToAsync(function () {  
                  
                const datastrea = stream.toArray();
                attachmentStream = Stimulsoft.System.Convert.toBase64String(datastrea);
              //  if (this.roughSizeOfObject(attachmentStream) > 0) {
                // this.dataStream.next(attachmentStream);
                observer.next(attachmentStream);
                // }
              },report, stream, settingsrep);
                   
              },Stimulsoft.Report.StiExportFormat.Pdf);             
                resolve(''); 
               } , 2
              );     

              });
          });
     });
      });
    });
});

return invoiceObservable;  

}


roughSizeOfObject( object ) {

  var objectList = [];
  var stack = [ object ];
  var bytes = 0;

  while ( stack.length ) {
      var value = stack.pop();

      if ( typeof value === 'boolean' ) {
          bytes += 4;
      }
      else if ( typeof value === 'string' ) {
          bytes += value.length * 2;
      }
      else if ( typeof value === 'number' ) {
          bytes += 8;
      }
      else if
      (
          typeof value === 'object'
          && objectList.indexOf( value ) === -1
      )
      {
          objectList.push( value );

          for( var i in value ) {
              stack.push( value[ i ] );
          }
      }
  }
  return bytes;
}





}
