<div style="background-color: #fafafa;" class="tw-p-2">
    
    <div *ngIf="!showSearch && editmode">
        <form [formGroup]="cusForm">
            <div *ngIf="showForm" class="tw-grid tw-grid-cols-2">
    
                    <div class="tw-mb-2 tw-mr-1">                
                        <input formControlName="firstName" type="text" class="input-header" placeholder="{{ 'FIRST_NAME' | translate }}">
                    </div>
                    <div class="tw-mb-2 tw-ml-1">
                        <input formControlName="lastName" type="text" class="input-header" placeholder="{{ 'LAST_NAME' | translate }}">
                    </div>
    
                    <div class="tw-mb-2 tw-mr-1">
                        <input formControlName="billingAddress1" type="text" class="input-header" placeholder="{{ 'ADDRESS' | translate }}">
                    </div>
                    <div class="tw-mb-2 tw-ml-1">
                        <input formControlName="city" type="text" class="input-header" placeholder="{{ 'CITY' | translate }}">
                    </div>
    
                    <div class="tw-mb-2 tw-mr-1">
                        <select formControlName="state" class="input-header" >
                            <option *ngFor="let item of states" [value]="item.abbreviation">{{item.name}}</option>
                        </select>
                    </div>                
                    <div class="tw-mb-2 tw-ml-1">
                        <input formControlName="zip" type="text" class="input-header" placeholder="{{ 'ZIP' | translate }}" mask="00000">
                    </div>
    
                    <div class="tw-mb-2 tw-mr-1">
                        <input formControlName="email" type="email" class="input-header" placeholder="{{ 'EMAIL' | translate }}">
                    </div>
                    <div class="tw-mb-2 tw-ml-1">
                        <input formControlName="phone1" type="text" class="input-header" placeholder="{{ 'PHONE' | translate }} #" mask="000-000-0000">
                    </div>
    
            </div>
        </form>
        <div *ngIf="showForm" class="tw-flex">
            <button (click)="saveCust()" class="btn-blue-so-square" style="font-size: .8rem;">
                <span style="font-size: .8rem;" class="material-icons"> check </span>
                &nbsp; {{ 'DONE' | translate }}
            </button>
    
            <button (click)="cancelCust()" class="tw-ml-auto btn-blue-so-square" style="font-size: .8rem;">
                <span style="font-size: .8rem;" class="material-icons"> close </span>
                &nbsp; {{ 'CANCEL' | translate }}
            </button>
        </div>
    </div>

    <div *ngIf="showSearch && editmode">
        <div class="tw-flex tw-justify-between tw-mt-2">

            <div class="tw-flex tw-w-full">
              
              <input
              (keyup.enter)="search()"
              style="background-color:  #f5f5f5"
              type="search"
              [(ngModel)]="searchCus"
              placeholder="&#xf002; Search Customer"
              style="width: -webkit-fill-available;"
              class=" tw-mr-4 input-place-icon tw-border-2 tw-border-gray-400 tw-outline-none tw-rounded-md tw-px-2 "
              />  
      
              <button (click)="search()" class="btn-blue-so">
                <span class="material-icons">search</span>
              </button> 
      
            </div>  
       
        </div>

        <table class="table-responsive" *ngIf="customers.length > 0">
            <table  class="table table-light table-custom overflow-auto" cellspacing="0">
              <thead class="tw-font-bold tw-text-aedblue">
                <tr style="font-size: small;background-color: white;" >
                  <th class="border-b-th">{{ 'NAME' | translate }}</th>
                  <th class="border-b-th">{{ 'PHONE' | translate }}</th>
                  <th class="border-b-th">{{ 'EMAIL' | translate }}</th>
                  <th class="border-b-th"></th>
                </tr>
              </thead>
              <tbody class="tw-text-aedgraydark">
                <tr class="m-1 p-1" *ngFor="let customer of customers;let impar = odd" 
                [ngClass]="impar ? 'tw-bg-aedgrayback100' :'tw-bg-aedgrayback'">
                  <td>{{ customer?.firstName }} {{ customer?.lastName }}</td>
                  <td>{{ customer?.phoneType }} {{ customer?.phone1 }}</td>
                  <td><a href="mailto:#{{ customer?.email }}">{{ customer?.email }}</a></td>
                  <td *ngIf="searchMode == true ">
                    <button
                    class="btn btn-blue"
                    (click)="selectCustomer(customer.Id,customer)"
                    type="button"
                  >
                    <i class="fa fa-check"></i>
                  </button>
                  </td>   
                </tr>
              </tbody>
            </table>
        </table>
        <div id="pagcustomers"  *ngIf="customers.length > 0">
            <div  class="pagination-container disabled" *ngIf="pagination.totalItems > 5">
              <pagination 
                [boundaryLinks]="true"
                [totalItems]="pagination.totalItems"
                [(ngModel)]="pagination.currentPage"
                [itemsPerPage]="pagination.itemsPerPage"
                (pageChanged)="pageChanged($event)"
                [maxSize]="maxsize"
                previousText="&lsaquo;"
                nextText="&rsaquo;"
                firstText="&laquo;"
                lastText="&raquo;"
              >
              </pagination>
            </div>  
        </div>

    </div>
    



    <!-- SHOW INFORMATION -->
    <div *ngIf="!editmode">
        <div class="tw-grid tw-grid-cols-5" >
            
            <div class="tw-col-span-2" >
                <div>{{cusForm.value.firstName+' '+ cusForm.value.lastName}}</div>
                <div>{{cusForm.value.email != null?cusForm.value.email:''}}</div>
                <div>{{cusForm.value.phone1 != null?cusForm.value.phone1:'' }}</div>
            </div>

            <div class="tw-col-span-2" >
                <div>{{cusForm.value.billingAddress1 != null?cusForm.value.billingAddress1:''}}</div>
                <div>{{(cusForm.value.city != null?cusForm.value.city:'')+' '+ (cusForm.value.state!= null?cusForm.value.state:'') }}</div>
                <div>{{(cusForm.value.zip != null?cusForm.value.zip:'') }}</div>
            </div>

            <div class="tw-col-span-1 tw-flex" >
                <div class="tw-m-auto tw-flex" *ngIf="canEdit" >
                    <button class=" btn-blue-so-square" style="font-size: .8rem;height: fit-content;" (click)="editCustomer()">
                        <svg width="14" height="14" id="Component_59_102" data-name="Component 59 – 102" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 25.674 25.681"><defs _ngcontent-eqg-c254=""><style _ngcontent-eqg-c254=""> .cls-15 { fill: #fff; } </style><clipPath _ngcontent-eqg-c254="" id="clip-path"><rect _ngcontent-eqg-c254="" id="Rectangle_156" data-name="Rectangle 156" width="25.674" height="25.681" class="cls-15"></rect></clipPath></defs><g _ngcontent-eqg-c254="" id="Group_63" data-name="Group 63"><path _ngcontent-eqg-c254="" id="Path_76" data-name="Path 76" d="M25.674,4.884a5.154,5.154,0,0,1-1.642,2.877C21,10.767,17.983,13.8,14.961,16.819Q12.306,19.475,9.65,22.13c-.313.313-.633.618-.946.931a1.42,1.42,0,0,1-.578.335Q4.861,24.481,1.6,25.572c-.095.032-.186.073-.279.109h-.6a1.094,1.094,0,0,1-.594-1.576q1.09-3.266,2.177-6.534a1.567,1.567,0,0,1,.389-.631Q10.514,9.121,18.337,1.3A4.289,4.289,0,0,1,25.3,2.465a5.738,5.738,0,0,1,.377,1.216ZM7.95,20.871c.089-.02.135-.1.192-.153q6.486-6.483,12.975-12.964c.166-.165.138-.246-.011-.392Q19.716,5.988,18.343,4.6c-.159-.162-.245-.167-.41,0q-2.648,2.667-5.312,5.319-3.827,3.827-7.657,7.651c-.133.133-.16.211-.012.357.946.933,1.881,1.875,2.821,2.813.052.052.1.118.176.136M23.641,4.2c0-.013-.014-.1-.025-.178A2.1,2.1,0,0,0,22.107,2.18,2.2,2.2,0,0,0,19.73,2.8c-.1.1-.1.171,0,.27q1.454,1.447,2.9,2.9c.086.086.155.1.252.009A2.349,2.349,0,0,0,23.641,4.2M5.976,21.876a.9.9,0,0,0-.07-.088q-1-1-2-2c-.1-.1-.147-.078-.188.048-.11.341-.226.681-.339,1.021q-.321.961-.641,1.923c-.02.06-.073.127-.024.189s.124.016.183,0q1.473-.488,2.944-.98c.05-.017.121-.017.135-.108" transform="translate(0 0)" class="cls-15"></path></g></svg>
                    </button>

                    <button (click)="deleteCust()" class="tw-ml-2 btn-orange-so-square" style="font-size: .8rem;">
                        <span style="font-size: 1rem;" class="material-icons"> close </span>
                    </button>
                </div>
            </div>

        </div>
    </div>

</div>
