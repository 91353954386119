import { Injectable, EventEmitter } from '@angular/core';
declare let alertify: any;

@Injectable({
  providedIn: 'root'
})
export class ReportingService {
data: any = [];
constructor() { }

alertevent: EventEmitter<string> = new EventEmitter();

  exportToCSV(data: any = [], filename?: string) {
    if (filename == null) {
      filename = 'StoreProducts.csv';
    }
    this.data = data;
    const rows = this.data;
    let csvContent = 'data:text/csv;charset=utf-8,';
    let dataString = '';
    const x: Array<any> = [];
    const keys = Object.keys(this.data[0]);
    rows.forEach((row, index) => {
      x[index] = [];
      keys.forEach((i) => {
        if (row.hasOwnProperty(i)) {
          if (typeof row[i] === 'object') {
            row[i] = 'Object'; // so far just change object to "Object" string
          }
          x[index].push(row[i]);
        }
      });
    });

    csvContent += keys + '\n';
    x.forEach((row, index) => {
      dataString = row.join(',');
      csvContent += index < this.data.length ? dataString + '\n' : dataString;
    });
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', filename);
    link.click();
  }

}
