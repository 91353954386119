import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-choosestorestock-mobile',
  templateUrl: './choosestorestock-mobile.component.html',
  styleUrls: ['./choosestorestock-mobile.component.scss']
})
export class ChoosestorestockMobileComponent implements OnInit {
  @Input() show: string;
  @Input() showItems: boolean = false;
  @Input() fromFloorItems: boolean = false;
  @Output() showItemsChange = new EventEmitter<boolean>();
  constructor() { }

  ngOnInit() {
  }

  close(){
    this.showItemsChange.emit(false)
  }

  showStoreProducts() {
    this.show = 'Store';
  }

  showStockItems() {
    this.show = 'Stock';
  }

}
