import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { User } from '../_modules/user';
import { AuthService } from '../_services/auth.service';

@Directive({
  selector: '[appHasRole]'
})
export class HasRoleDirective implements OnInit {
  @Input() appHasRole: string[];
  role: string;
  constructor(private viewContainerRef: ViewContainerRef, private templateRef: TemplateRef<any>, 
    private authService: AuthService) { 
        this.role = this.authService.getRole();
    }
    ngOnInit(): void {
      if (this.role == null) {
        this.viewContainerRef.clear();
        return;
      }

      if (this.appHasRole.includes(this.role)) {
          this.viewContainerRef.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainerRef.clear();
      }

    }

    

}
