import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { QrScannerComponent } from 'angular2-qrscanner';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { SalesOrderItemsService } from 'src/app/_services/salesorderitems.service';
import { StoreproductsService } from 'src/app/_services/storeproducts.service';

@Component({
  selector: 'app-scanitem',
  templateUrl: './scanitem.component.html',
  styleUrls: ['./scanitem.component.scss']
})
export class ScanitemComponent implements OnDestroy{
  destroy$: Subject<boolean> = new Subject<boolean>();
  desiredDevice:any;
  options:any;
  constructor(
    private salesorderSer: SalesOrderService,
    public bsModalRef: BsModalRef,
    private alertify: AlertifyService,
    private itemsService: SalesOrderItemsService,
    private storeProductsService: StoreproductsService,
  ){}
  @ViewChild('scanner', { static: false })
    scanner: ElementRef<any>;
    stop:boolean = false;
    itemSelected: any;
    videoDevices: MediaDeviceInfo[] = [];
    /**
     * Some method.
     */
     scanCompleteHandler(response:any): void {
      if(response?.text != undefined){
        this.stop = true
        this.salesorderSer.getItemById(response?.text)
        .pipe(takeUntil(this.destroy$))   
      .subscribe((data:any) => {
        if(data.item){
                   
          this.itemSelected = data.item; 
          var  item_order = [data.item.productsId];
          this.options = data.options.sort((a, b) => item_order.indexOf(b.productsId) - item_order.indexOf(a.productsId));

        }else{
          this.bsModalRef.hide();
          this.alertify.error("This item doesn't exist in store catalog");
        }
      }, err =>{
        this.bsModalRef.hide();
        this.alertify.error("This item doesn't exist in store catalog");
      });
      }
    }

    camerasFoundHandler(response:any): void {
      //const videoDevices: MediaDeviceInfo[] = [];
      for (const device of response) {
        if (device.kind.toString() === 'videoinput') {
            this.videoDevices.push(device);
        }

        if (this.videoDevices.length > 0){
          let choosenDev;
          for (const dev of this.videoDevices){
              if (dev.label.includes('back')){
                  choosenDev = dev;
                  break;
              }
          }
          if (choosenDev) {
              this.desiredDevice = choosenDev;
          } else {
              this.desiredDevice = this.videoDevices[0];
          }
        }
      }
    }
    scanSuccessHandler(response:any): void {
    }

    changeCamera(){
      if(this.videoDevices.length > 1){
        if(this.desiredDevice.label === this.videoDevices[0].label){
          this.desiredDevice = this.videoDevices[1]
        }else{
          this.desiredDevice = this.videoDevices[0]
        }
      }
    }
   
    /**
     * Returns the back camera for ya.
     */
    submitItem(item:any) {
      

      this.storeProductsService.validateStoreItem(item.supItemID)
      .toPromise()
      .then((data:any)=>{
      if(data.length == 0){
        this.alertify.error("This product doesn't exists")
        return;
      }
      //verification if is active in Customers
      if(data[0].inactive == null || data[0].inactive == "" ){
        //verification if is active in Location
        if(data[0].active == 1 ){
  
          //updateJson of DB, Customers and Location
          // console.log(data[0].salesPriceNum)
          item.itemDelivery = ''
          item.salesPriceNum = data[0].salesPriceNum
          item.priceNum = data[0].salesPriceNum
          item.wholeSalePriceNum = data[0].Price
          item.quantityNum = 1

          let itemReady = this.convertItem(item)          
          this.itemsService.raiseSoItemEvent(itemReady);
          this.alertify.success('Item Added');          
          this.bsModalRef.hide();

          
        }else{
          this.alertify.error("This product is inactive")
        }
      }else{
        this.alertify.error("This product is inactive in DDS")
      }
      })
      .catch((err)=>console.log(err));
    }

    convertItem(soItem) {
      let soItemNew: any = [];
      
      soItemNew = Object.assign({}, soItem);
      soItemNew["quantityNum"] = 1;
      if (soItemNew["priceNum"] == undefined) {
        soItemNew["priceNum"] = soItem['salesPriceNum'];
      } else if (soItem['salesPriceNum'] == undefined ) {
        soItem['salesPriceNum'] = soItemNew["priceNum"];
      }
      soItemNew["priceNum"] = soItem['salesPriceNum'];
      soItemNew["manufacturer"] = soItem['Manufacturer'];
      soItemNew["status"] = 'insert';
      
      
      return soItemNew;
    }
  

    ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
    }

    hideModal() {
      this.bsModalRef.hide();
    }


}
