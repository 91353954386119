import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {
  @Input() salesOrder: any;
  @Input() deliveries: any[];
  @Input() canSelect = true;

  constructor() { }

  ngOnInit() {

  }

}
