import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TemplatemessagesService {

  laravel = environment.apiLaravel;

  constructor(private http: HttpClient) { }

  getTemplateMessages(){
    return this.http.get<any>(this.laravel + 'getTemplateMessages')
  }

  getTemplateMesSection(category:any){
    return this.http.get<any>(this.laravel + 'getTemplateMesSection/'+category)
  }

  updateMessagesSection(data:any){
        return this.http.post<any>(this.laravel + 'updateMessagesSection',data)
  }

  updateActivePDF(data:any){
    return this.http.post<any>(this.laravel + 'updateActivePDF',data)
  }
  
  deleteInvoicePDF(data:any){
    return this.http.post<any>(this.laravel + 'deleteInvoicePDF',data)
  }
}
