import { Component, OnInit, Input,OnChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomerService } from '../../../_services/customer.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { DummymodalComponent } from '../../dummymodal/dummymodal.component';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-address-card-mobile',
  templateUrl: './address-card-mobile.component.html',
  styleUrls: ['./address-card-mobile.component.scss']
})
export class AddressCardMobileComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() so: any;
  @Input() openSelectEvent: any;
  @Input() canSelect = true;
  @Input() customerComment = '';
  @Input() showForm = false;
  @Input() edit = false;
  @Input() showSelectCustomer = false;
  @Input() showEditCustomer = false;
  salesOrder: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('customerCard', {static : true}) customerCard: ElementRef;

  constructor(private modalService: BsModalService,
    private customerService: CustomerService,
    private alertify: AlertifyService,
    private bsModalRef: BsModalRef,
    ) { }

  ngOnInit() {

    this.customerService.showcustomermobile.subscribe(
      (res)=>{
        if(res == 'showcustomer'){
          this.closeAll()
        }
      }
    );

    this.newCustomer()

    if (this.edit) {
      this.closeAll()
    }

  }

  ngAfterViewInit(): void {
  }

  closeEditCustomerChange(event :any){
    this.scrollNew('customerContainer')
  }


  scrollNew(id:any) {
    let el = document.getElementById(id);
    window.scroll({top: el.offsetTop, behavior: 'smooth'});
  }
  ngOnChanges():void{
    if(this.openSelectEvent == true && this.customerService.salesOrderCustomer.value === 0){
      this.openModalWithComponent()
    }
  }

  public openModalWithComponent() {

    if (this.customerService.salesOrderCustomer.value !== 0) {
      this.alertify.confirm('Are you sure you want to change the customer?', () => {
        this.bsModalRef = this.modalService.show(DummymodalComponent, {class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
      }
      ,()=>{
      });
    } else {
      this.customerService.selectedCustomer(0);
      this.customerService.salesOrderCustomer.next(0);
      this.bsModalRef = this.modalService.show(DummymodalComponent, { class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
    }
  }
  selectCustomer(){
    this.showSelectCustomer = true
    this.showForm = false
    this.showEditCustomer = false
  }

  cancelsearch(a:any){
    if(a){      
      this.showSelectCustomer = false
      this.showForm = true
      this.showEditCustomer = false
    }
  }

  editSalesOrder() {
    this.salesOrder = JSON.parse(JSON.stringify(Object.assign({}, this.so)));
      this.showForm = false
      this.showEditCustomer = true
      this.showSelectCustomer = false
  }
  newCustomer(){
    this.showForm = true
    this.showEditCustomer = false
    this.showSelectCustomer = false
  }
  closeAll(){
    this.showForm = false
    this.showEditCustomer = false
    this.showSelectCustomer = false
  }

}

