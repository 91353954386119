import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocationService } from 'src/app/_services/location.service';
import { SettingsService } from 'src/app/_services/settings.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {
  inventoryMethod: any;
  showAlert:boolean = false
  lng: number;
  lat: number;

  constructor(
    private settingsService: SettingsService,
    private router: Router,
  ) { }

  show:any = 'warehouse'
  // show:any = 'uniqueProducts'

  ngOnInit(): void {
    this.getInventoryMethod()
  }

  getInventoryMethod() {
    this.settingsService.getInventoryMethod().subscribe((response)=>{
      this.inventoryMethod = response['inventoryMethod']
      //console.log(response);
      
      //console.log(this.inventoryMethod);

      if (this.inventoryMethod == null || this.inventoryMethod == ''){
        this.showAlert = true
      }
    })
  }

  redirectToInventorySetup() {
    this.router.navigate(['storeconfig']);
  }
}
