import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { publishReplay, refCount } from 'rxjs/operators';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';
import { PaginatedResult } from '../_modules/pagination';


@Injectable({
  providedIn: 'root'
})
export class StoreproductsService {
  laravel = environment.apiLaravel;
  storeSID = new BehaviorSubject<string>('');
  storeStyleID = new BehaviorSubject<string>('');
  allStoreProducts = [];

  constructor(private http: HttpClient) { }


  storeEvent: EventEmitter<string> = new EventEmitter();

  raiseStoreEvent(sid: string, styleid: string) {

    this.storeSID.next(sid);
    this.storeStyleID.next(styleid);
    this.storeEvent.next('');
  }


  getTotalStoreProducts() {
    let result = 0;
    return this.http.get<any>(this.laravel + 'storeproductstotal')
      .pipe(
        map(response => {
          result = (response);
          return result;
        })
      );
  }

  configs: Observable<PaginatedResult<any>>;
  getStoreProducts(page?, itemsPerPage?, searchMan?, searchStyle?, searchSize?, searchProduct?, forExcel?) {
    let params = new HttpParams();
    const paginatedResult: PaginatedResult<any> = new PaginatedResult<any[]>();

    if (page != null && itemsPerPage != null) {
      params = params.append('page', page);
      params = params.append('per_page', itemsPerPage);
    }

    if (searchMan != null) {
      params = params.append('searchMan', searchMan);
    }

    if (searchStyle != null) {
      params = params.append('searchStyle', searchStyle);
    }

    if (searchSize != null) {
      params = params.append('searchSize', searchSize);
    }

    if (searchProduct != null) {
      params = params.append('searchProduct', searchProduct);
    }

    if (forExcel != null) {
      params = params.append('forExcel', forExcel);
    }

    if (forExcel == null) {
      if (!this.configs) {


      //this.configs =
      return this.http.get<any>(this.laravel + 'storeproducts', { observe: 'response', params })
        .pipe(
          map(response => {
            paginatedResult.result = (response.body);
            if (response.body.total != null) {
              paginatedResult.pagination = {
                totalItems: JSON.parse(response.body.total),
                totalPages: JSON.parse(response.body.last_page),
                currentPage: JSON.parse(response.body.current_page),
                itemsPerPage: JSON.parse(response.body.per_page)
              };
            }
            return paginatedResult;
          }) 
      //    ,publishReplay(1),
      //    refCount()
        );
      }
    //  return this.configs;

    } else {
      return this.http.get<any>(this.laravel + 'storeproducts', { observe: 'response', params })
        .pipe(
          map(response => {
            paginatedResult.result = (response.body);
            return paginatedResult;
          })
        );
    }
  }

  getStylesSizesProducts(eventId){
    return this.http.get<any>(this.laravel + 'getStylesSizesProducts/'+eventId)
  }
  
  // Clear configs
  clearCache() {
    this.configs = null;
  }

  updateStoreProducts(id: string, storeProduct: any) {
    return this.http.patch(this.laravel + 'storeproducts/' + id, storeProduct);
  }
  updatePricesOnLayout(storeProduct: any) {
    let data = {
      products : storeProduct
    }
    return this.http.post(this.laravel + 'updatePricesOnLayout', data);
  }
  addStockItems(storeProduct: any) {
    return this.http.post(this.laravel + 'stockitems', storeProduct);
  }
  queryTaxLineItem(values: any) {
    return this.http.post(this.laravel + 'queryTaxLineItem',values);
  }
  queryManyLineItem(values: any) {
    return this.http.post(this.laravel + 'queryManyLineItem',values);
  }
  queryManyLineItemCommit(values: any) {
    return this.http.post(this.laravel + 'queryManyLineItemCommit',values);
  }
  voidTransactionAvalara(values: any) {
    return this.http.post(this.laravel + 'voidTransactionAvalara',values);
  }
  getAvalaraRecords() {
    return this.http.get(this.laravel + 'getAvalaraRecords');
  }
  getSOsStockItems(idStock: any) {
    return this.http.get(this.laravel + 'getSOsStockItems/'+idStock);
  }
  saveToExpQRC(productsId: any) {
    return this.http.post(this.laravel + 'saveToExpQRC', productsId);
  }

  getToExpQRC() {
    return this.http.get(this.laravel + 'getToExpQRC');
  }



  deleteStoreProduct(id: string, searchMan?, searchStyle?, searchSize?, searchProduct?) {
    let params = new HttpParams();
    if (searchMan != null) {
      params = params.append('searchMan', searchMan);
    }

    if (searchStyle != null) {
      params = params.append('searchStyle', searchStyle);
    }

    if (searchSize != null) {
      params = params.append('searchSize', searchSize);
    }

    if (searchProduct != null) {
      params = params.append('searchProduct', searchProduct);
    }

    return this.http.delete(this.laravel + 'storeproducts/' + id, { observe: 'response', params });
  }

  deleteFoundStoreProduct(id: string, search: string) {
    let params = new HttpParams();
    if (search != null) {
      params = params.append('search', search);
    }
    return this.http.delete(this.laravel + 'storeproducts', { observe: 'response', params });
  }

  getStoreProductsFromSearchBar(page?, itemsPerPage?, searchBar?, forExcel?) {
    let params = new HttpParams();
    const paginatedResult: PaginatedResult<any> = new PaginatedResult<any[]>();

    if (page != null && itemsPerPage != null) {
      params = params.append('page', page);
      params = params.append('per_page', itemsPerPage);
    }

    if (searchBar != null) {
      params = params.append('searchBar', searchBar);
    }

    if (forExcel != null) {
      params = params.append('forExcel', forExcel);
    }

    if (forExcel == null) {
      return this.http.get<any>(this.laravel + 'storeproductsfromsearch', { observe: 'response', params })
        .pipe(
          map(response => {
            paginatedResult.result = (response.body);
            if (response.body.total != null) {
              paginatedResult.pagination = {
                totalItems: JSON.parse(response.body.total),
                totalPages: JSON.parse(response.body.last_page),
                currentPage: JSON.parse(response.body.current_page),
                itemsPerPage: JSON.parse(response.body.per_page)
              };
            }
            return paginatedResult;
          })
        );
    } else {
      return this.http.get<any>(this.laravel + 'storeproducts', { observe: 'response', params })
        .pipe(
          map(response => {
            paginatedResult.result = (response.body);
            return paginatedResult;
          })
        );
    }
  }


  updateAvalaraTax(data) {
    return this.http.post(this.laravel + 'updateAvalaraTax', data);
  }

  updateEntireStoreCatalog(storeData) {
    return this.http.post(this.laravel + 'updateEntireStoreCatalog', storeData);
  }

  validateStoreItem(item:any){    
    return this.http.get(this.laravel + 'validateStoreItem/'+item);
  }
}
