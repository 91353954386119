<div class="page-wrapper tw-h-full"  *ngIf="loggedIn() ">

<nav id="sidebar" class="sidebar-1 tw-h-full" *ngIf="loggedIn()" 
  [ngClass]="{ activehalfhalf:isCollapsed == 'halfhalfwidth', 
               active: isCollapsed == 'halfwidth', 
               '': isCollapsed == '' }" >
    <div class="sidebar-1-content">


      <div [ngClass]="{'tw-hidden': isCollapsed != 'halfwidth'}" class="tw-py-4" (click)="goToDashBoard()">
        <img src="assets/aedpaysmallfornav.png" />
      </div>

      <div  [ngClass]="{'tw-hidden': isCollapsed == 'halfwidth','tw-pb-0': isCollapsed == 'halfhalfwidth'}" class="tw-py-4" >
        <img class="tw-w-full" src="assets/logo-closed.svg"/>
      </div>


      <ul class="sidebar-1-content-links">

       <!-- customers -->
        <div *appHasRole='["Admin","Dealer","Manager","Sales Person", "Cashier", "Inventory manager"]' routerLinkActive="active-new" [routerLink]="['/customers']" style="outline: none;" (click)="closeSettings('customers')"  
        class="tw-flex  tw-mt-2 tw-cursor-pointer" [ngClass]="isCollapsed != '' ? 'tw-w-full': 'tw-mx-auto'">     
          <div class="bg-icon tw-bg-aedblue tw-w-10  tw-h-10 tw-flex tw-rounded-lg tw-my-auto "
          [ngClass]="isCollapsed != '' ? 'tw-ml-0': 'tw-mx-auto'">
            
            <svg class="tw-font-bold tw-w-6 tw-h-6 tw-m-auto"  xmlns="http://www.w3.org/2000/svg" width="15.77" height="15.082" viewBox="0 0 15.77 15.082">
              <g id="Group_561" data-name="Group 561" transform="translate(3213 -8440)">
                <path id="Path_183" data-name="Path 183" d="M16.9,6.475h0a2.757,2.757,0,0,1-2.023-.884,3.047,3.047,0,0,1-.818-2.1A2.911,2.911,0,0,1,16.9.524h.012a2.921,2.921,0,0,1,2.831,2.984A2.912,2.912,0,0,1,16.9,6.475Zm0-4.951a1.91,1.91,0,0,0-1.842,1.964A2.051,2.051,0,0,0,15.6,4.906a1.767,1.767,0,0,0,1.295.569v0a1.912,1.912,0,0,0,1.844-1.968,1.921,1.921,0,0,0-1.836-1.983Z" transform="translate(-3222.024 8439.476)" fill="#fff"/>
                <path id="Path_183-2" data-name="Path 183" d="M16.142,4.89h0a2.023,2.023,0,0,1-1.484-.648,2.236,2.236,0,0,1-.6-1.544A2.136,2.136,0,0,1,16.142.524h.009a2.144,2.144,0,0,1,2.078,2.189A2.137,2.137,0,0,1,16.142,4.89Zm0-3.633A1.4,1.4,0,0,0,14.791,2.7a1.505,1.505,0,0,0,.4,1.04,1.3,1.3,0,0,0,.95.417v0a1.4,1.4,0,0,0,1.353-1.444,1.409,1.409,0,0,0-1.347-1.455Z" transform="translate(-3226.185 8442.812)" fill="#fff"/>
                <path id="Path_183-3" data-name="Path 183" d="M16.142,4.89h0a2.023,2.023,0,0,1-1.484-.648,2.236,2.236,0,0,1-.6-1.544A2.136,2.136,0,0,1,16.142.524h.009a2.144,2.144,0,0,1,2.078,2.189A2.137,2.137,0,0,1,16.142,4.89Zm0-3.633A1.4,1.4,0,0,0,14.791,2.7a1.505,1.505,0,0,0,.4,1.04,1.3,1.3,0,0,0,.95.417v0a1.4,1.4,0,0,0,1.353-1.444,1.409,1.409,0,0,0-1.347-1.455Z" transform="translate(-3216.185 8442.812)" fill="#fff"/>
                <path id="Path_186" data-name="Path 186" d="M12.173,17.881a4.056,4.056,0,0,1,3.882,2.745l1.4,4.217c.166.5.312,1,.318,1.025l.185.639H6.336l.221-.659s.171-.509.336-1.005l1.4-4.217A4.056,4.056,0,0,1,12.173,17.881Zm4.444,7.626c-.035-.113-.074-.232-.113-.349l-1.4-4.217a3.118,3.118,0,0,0-5.865,0l-1.4,4.217-.116.35Z" transform="translate(-3217.297 8428.574)" fill="#fff"/>
                <path id="Path_187" data-name="Path 187" d="M8.8,29.208H1.876a1.363,1.363,0,0,1-1.063-.5,1.271,1.271,0,0,1-.264-1.062l.656-3.2.009-.027A2.964,2.964,0,0,1,4.1,22.181a3.318,3.318,0,0,1,1.4.307L6,22.721l-.285.478a2.811,2.811,0,0,0-.25.534l-1.4,4.126-.11.325H8.466Zm-6.63-4.5-.645,3.14a.269.269,0,0,0,.059.229.367.367,0,0,0,.288.13H2.893c.063-.184.146-.428.228-.67l1.4-4.126q.03-.09.066-.18a2.321,2.321,0,0,0-.487-.051A2.019,2.019,0,0,0,2.174,24.709Z" transform="translate(-3213.524 8425.874)" fill="#fff"/>
                <path id="Path_188" data-name="Path 188" d="M27.911,29.208H20.984l.337-1.024H25.83l-.11-.325-1.4-4.126a2.812,2.812,0,0,0-.25-.534l-.285-.478.505-.233a3.318,3.318,0,0,1,1.4-.307,2.964,2.964,0,0,1,2.883,2.243l.009.027.656,3.2a1.271,1.271,0,0,1-.264,1.062A1.363,1.363,0,0,1,27.911,29.208Zm-1.017-1h1.017a.367.367,0,0,0,.288-.13.269.269,0,0,0,.059-.229l-.645-3.14a2.019,2.019,0,0,0-1.924-1.528,2.322,2.322,0,0,0-.487.051q.036.09.066.18l1.4,4.126C26.748,27.78,26.831,28.023,26.895,28.208Z" transform="translate(-3226.494 8425.874)" fill="#fff"/>
              </g>
            </svg>
          </div>
          <span *ngIf="isCollapsed != '' && !actionInSO" class="color-text tw-my-auto  tw-ml-3 tw-mr-auto tw-text-aedblue tw-font-bold" style="font-size: 1.1rem;">
            {{ 'CUSTOMERS' | translate }}</span>
        </div>         
        <div *ngIf="isCollapsed != '' && actionInSO" class="tw-flex color-text tw-my-auto tw-mr-auto tw-text-aedblue tw-font-bold" style="font-size: 0.8rem;"> 
        <span class="tw-mb-auto" >{{ 'CUSTOMERS' | translate }}</span> </div>
      
        <!-- Items in stock -->
        <div *appHasRole='["Admin","Dealer","Manager","Cashier", "Inventory manager"]' routerLinkActive="active-new" [routerLink]="['/stockitems']"  (click)="closeSettings('stock')" 
        class="tw-flex tw-mt-2 tw-cursor-pointer" style="outline: none;" [ngClass]="isCollapsed != '' ? 'tw-w-full': 'tw-mx-auto'">     
          <div class="bg-icon tw-bg-aedblue tw-w-10  tw-h-10 tw-flex tw-rounded-lg tw-my-auto "
          [ngClass]="isCollapsed != '' ? 'tw-ml-0': 'tw-mx-auto'">
          <svg class="tw-font-bold tw-w-6 tw-h-6 tw-m-auto" id="Group_507" data-name="Group 507" xmlns="http://www.w3.org/2000/svg" width="13.581" height="13.581" viewBox="0 0 13.581 13.581">
            <path id="Path_495" data-name="Path 495" d="M9.6,19.081H.991A1.492,1.492,0,0,1-.5,17.59V6.991A1.492,1.492,0,0,1,.991,5.5h10.6a1.492,1.492,0,0,1,1.491,1.491l-.015,10.6a.5.5,0,1,1-.994,0l.015-10.6a.5.5,0,0,0-.5-.5H.991a.5.5,0,0,0-.5.5v10.6a.5.5,0,0,0,.5.5H9.6a.5.5,0,1,1,0,.994Z" transform="translate(0.5 -5.5)" fill="#fff"/>
            <path id="Path_496" data-name="Path 496" d="M11.59,32.456H.991A1.492,1.492,0,0,1-.5,30.965V26a.5.5,0,0,1,.5-.5.5.5,0,0,1,.5.5v4.969a.5.5,0,0,0,.5.5h10.6a.5.5,0,0,0,.5-.5.5.5,0,1,1,.994,0A1.492,1.492,0,0,1,11.59,32.456Z" transform="translate(0.5 -18.875)" fill="#fff"/>
            <path id="Path_497" data-name="Path 497" d="M17.395,26.475a.5.5,0,0,1-.364-.159l-.9-.968a.5.5,0,0,1,.729-.676l.532.574,2.381-2.586a.5.5,0,1,1,.731.673L17.76,26.314A.5.5,0,0,1,17.395,26.475Z" transform="translate(-10.535 -16.869)" fill="#fff"/>
            <path id="Path_498" data-name="Path 498" d="M13.647,27.793a3.147,3.147,0,1,1,1.3-6.012.5.5,0,0,1-.411.9A2.154,2.154,0,1,0,15.8,24.647a2.163,2.163,0,0,0-.048-.456.5.5,0,1,1,.971-.21,3.149,3.149,0,0,1-3.076,3.813Z" transform="translate(-6.856 -16.2)" fill="#fff"/>
            <path id="Path_499" data-name="Path 499" d="M17.309,9.475a.5.5,0,0,1-.184-.036l-1.472-.589-1.472.589a.5.5,0,0,1-.681-.461V6a.5.5,0,1,1,.994,0V8.244l.975-.39a.5.5,0,0,1,.369,0l.975.39V6.328a.5.5,0,1,1,.994,0v2.65a.5.5,0,0,1-.5.5Z" transform="translate(-8.863 -5.5)" fill="#fff"/>
            <path id="Line_180" data-name="Line 180" d="M4.306-.506H0V-1.5H4.306Z" transform="translate(0.596 3.487)" fill="#fff"/>
            <path id="Line_181" data-name="Line 181" d="M4.306-.506H0V-1.5H4.306Z" transform="translate(8.546 3.487)" fill="#fff"/>
          </svg> 
            
          </div>
          <span *ngIf="isCollapsed != '' && !actionInSO" class="color-text tw-my-auto  tw-ml-3 tw-mr-auto tw-text-aedblue tw-font-bold" style="font-size: 1.1rem;">
            {{ 'INVENTORY' | translate }}</span>
        </div> 
        <div *ngIf="isCollapsed != '' && actionInSO" class="tw-flex color-text tw-my-auto tw-mr-auto tw-text-aedblue tw-font-bold" style="font-size: 0.8rem;"> 
          <span class="tw-mb-auto" >{{ 'INVENTORY' | translate }}</span> </div>

        <!-- Sales Orders -->
        <div *appHasRole='["Admin","Dealer","Manager","Cashier", "Sales Person", "Inventory manager"]'  routerLinkActive="active-new" [routerLink]="['/salesorder']"  (click)="closeSettings('salesorder')" 
        class="tw-flex tw-mt-2 tw-cursor-pointer" style="outline: none;" [ngClass]="isCollapsed != '' ? 'tw-w-full': 'tw-mx-auto'">     
          <div class="bg-icon tw-bg-aedblue tw-w-10  tw-h-10 tw-flex tw-rounded-lg tw-my-auto "
          [ngClass]="isCollapsed != '' ? 'tw-ml-0': 'tw-mx-auto'">            
            <svg class="tw-font-bold tw-w-6 tw-h-6 tw-m-auto" xmlns="http://www.w3.org/2000/svg" width="16.577" height="13.887" viewBox="0 0 16.577 13.887">
              <g id="Group_515" data-name="Group 515" transform="translate(1397.5 -8479.511)">
                <path id="Line_186" data-name="Line 186" d="M4.361-.37H-1a.537.537,0,0,1-.5-.565A.537.537,0,0,1-1-1.5H4.361a.537.537,0,0,1,.5.565A.537.537,0,0,1,4.361-.37Z" transform="translate(-1394.293 8485.346)" fill="#fff"/>
                <path id="Line_188" data-name="Line 188" d="M2.833-.37H-.935A.565.565,0,0,1-1.5-.935.565.565,0,0,1-.935-1.5H2.833A.565.565,0,0,1,3.4-.935.565.565,0,0,1,2.833-.37Z" transform="translate(-1394.293 8488.358)" fill="#fff"/>
                <path id="Path_509" data-name="Path 509" d="M30.044,7.412H28.065a.565.565,0,0,1-.565-.565V4.867a1.3,1.3,0,0,1,.383-.925L31.942-.117a1.308,1.308,0,0,1,1.85,0l1.236,1.236a1.308,1.308,0,0,1,0,1.85L30.969,7.028A1.3,1.3,0,0,1,30.044,7.412ZM28.63,6.281h1.414a.179.179,0,0,0,.126-.052L34.229,2.17a.177.177,0,0,0,0-.251L32.993.682a.177.177,0,0,0-.252,0L28.683,4.741a.179.179,0,0,0-.052.126Z" transform="translate(-1416.335 8480.012)" fill="#fff"/>
                <path id="Line_189" data-name="Line 189" d="M1.326,1.891a.563.563,0,0,1-.4-.166L-1.334-.535a.565.565,0,0,1,0-.8.565.565,0,0,1,.8,0L1.725.926a.565.565,0,0,1-.4.965Z" transform="translate(-1383.567 8481.765)" fill="#fff"/>
                <path id="Path_508" data-name="Path 508" d="M12.651,16.82H.665C.023,16.82-.5,16.1-.5,15.213V5.108C-.5,4.221.023,3.5.665,3.5H9.322c.276,0,.5.308.5.689s-.224.689-.5.689H.665c-.092,0-.166.1-.166.23v10.1c0,.127.075.23.166.23H12.651c.092,0,.166-.1.166-.23V10.619c0-.381.224-.689.5-.689s.5.308.5.689v4.593C13.817,16.1,13.294,16.82,12.651,16.82Z" transform="translate(-1397 8476.578)" fill="#fff"/>
              </g>
            </svg>
          </div>
          <span *ngIf="isCollapsed != '' && !actionInSO" class="color-text tw-my-auto  tw-ml-3 tw-mr-auto tw-text-aedblue tw-font-bold" style="font-size: 1.1rem;">
            {{ 'SALESORDER' | translate }}</span>
        </div>
        <div *ngIf="isCollapsed != '' && actionInSO" class="tw-flex color-text tw-my-auto tw-mr-auto tw-text-aedblue tw-font-bold" style="font-size: 0.8rem;"> 
        <span class="tw-mb-auto" >{{ 'SALESORDER' | translate }}</span> </div>

        <!-- Defaults -->
        <div *appHasRole='["Admin","Dealer","Manager"]' routerLinkActive="active-new" [routerLink]="['/defaults']" (click)="closeSettings('sponsors')"  
        class="tw-flex tw-mt-2 tw-cursor-pointer" style="outline: none;" [ngClass]="isCollapsed != '' ? 'tw-w-full': 'tw-mx-auto'">     
          <div class="bg-icon tw-bg-aedblue tw-w-10  tw-h-10 tw-flex tw-rounded-lg tw-my-auto "
          [ngClass]="isCollapsed != '' ? 'tw-ml-0': 'tw-mx-auto'">           
            <svg  class="tw-font-bold tw-w-6 tw-h-6 tw-m-auto" id="Group_504" data-name="Group 504" xmlns="http://www.w3.org/2000/svg" width="14.906" height="16.23" viewBox="0 0 14.906 16.23">
              <path id="Rectangle_764" data-name="Rectangle 764" d="M-.672-1.5H5.29a.829.829,0,0,1,.828.828V.653a.829.829,0,0,1-.828.828H-.672A.829.829,0,0,1-1.5.653V-.672A.829.829,0,0,1-.672-1.5Zm5.8.994H-.506V.487H5.125Z" transform="translate(3.819 1.5)" fill="#fff"/>
              <path id="Path_416" data-name="Path 416" d="M177.3,367.743h-4.637a1.161,1.161,0,0,1-1.159-1.159V354.659a1.161,1.161,0,0,1,1.159-1.159h1.656a.5.5,0,0,1,0,.994h-1.656a.166.166,0,0,0-.166.166v11.924a.166.166,0,0,0,.166.166H177.3a.5.5,0,1,1,0,.994Z" transform="translate(-171.5 -352.838)" fill="#fff"/>
              <path id="Path_417" data-name="Path 417" d="M201.315,359.793a.5.5,0,0,1-.5-.5v-4.637a.166.166,0,0,0-.166-.166H199a.5.5,0,1,1,0-.994h1.656a1.161,1.161,0,0,1,1.159,1.159V359.3A.5.5,0,0,1,201.315,359.793Z" transform="translate(-189.557 -352.838)" fill="#fff"/>
              <path id="Path_418" data-name="Path 418" d="M194.6,373.5h1.024a1.061,1.061,0,0,1,1.037.849l.11.552.144.06.469-.313a1.054,1.054,0,0,1,.586-.177h0a1.05,1.05,0,0,1,.748.31l.723.723a1.06,1.06,0,0,1,.132,1.334l-.311.467q.031.073.06.147l.55.11a1.061,1.061,0,0,1,.85,1.037v1.024a1.06,1.06,0,0,1-.851,1.037l-.551.11q-.029.072-.061.144l.313.469a1.058,1.058,0,0,1-.133,1.334l-.723.723a1.057,1.057,0,0,1-1.334.132l-.467-.311q-.072.031-.146.06l-.11.55a1.061,1.061,0,0,1-1.037.85H194.6a1.06,1.06,0,0,1-1.037-.849l-.11-.552-.144-.06-.469.313a1.058,1.058,0,0,1-1.333-.131l-.724-.725a1.058,1.058,0,0,1-.132-1.334l.311-.467q-.031-.072-.06-.146l-.551-.11a1.059,1.059,0,0,1-.85-1.037V378.6a1.06,1.06,0,0,1,.85-1.037l.553-.111q.029-.072.06-.143l-.313-.469a1.06,1.06,0,0,1,.132-1.334l.724-.724a1.057,1.057,0,0,1,1.334-.132l.468.312.145-.061.11-.551A1.061,1.061,0,0,1,194.6,373.5Zm.869,1.247h-.713l-.149.747a.623.623,0,0,1-.434.475,3.227,3.227,0,0,0-.615.257.624.624,0,0,1-.643-.03l-.636-.424-.5.5.425.637a.624.624,0,0,1,.03.642,3.289,3.289,0,0,0-.257.616.623.623,0,0,1-.475.433l-.749.15v.713l.748.15a.624.624,0,0,1,.475.432,3.275,3.275,0,0,0,.256.619.624.624,0,0,1-.029.643l-.423.634.5.5.637-.425a.624.624,0,0,1,.641-.03,3.308,3.308,0,0,0,.617.259.624.624,0,0,1,.433.475l.149.747h.713l.15-.748a.624.624,0,0,1,.432-.475,3.277,3.277,0,0,0,.619-.256.623.623,0,0,1,.643.029l.634.423.5-.5-.425-.638a.623.623,0,0,1-.03-.642,3.292,3.292,0,0,0,.259-.617.624.624,0,0,1,.475-.433l.747-.149v-.712l-.748-.149a.623.623,0,0,1-.475-.432,3.28,3.28,0,0,0-.256-.619.623.623,0,0,1,.029-.643l.423-.634-.5-.5-.637.425a.624.624,0,0,1-.643.03,3.24,3.24,0,0,0-.615-.258.623.623,0,0,1-.434-.475Z" transform="translate(-185.817 -368.492)" fill="#fff"/>
              <path id="Path_419" data-name="Path 419" d="M198.276,384.052a1.776,1.776,0,1,1,.355-3.516.41.41,0,1,1-.164.8.956.956,0,1,0,.765.937.977.977,0,0,0-.019-.192.41.41,0,0,1,.8-.162,1.8,1.8,0,0,1,.035.354A1.778,1.778,0,0,1,198.276,384.052Z" transform="translate(-189.076 -371.657)" fill="#fff"/>
            </svg>
          </div>
          <span *ngIf="isCollapsed != '' && !actionInSO" class="color-text tw-my-auto  tw-ml-3 tw-mr-auto tw-text-aedblue tw-font-bold" style="font-size: 1.1rem;">
            {{ 'DEFAULTS' | translate }}</span>
        </div>
        <div *appHasRole='["Admin","Dealer","Manager"]' class="tw-flex color-text tw-my-auto tw-mr-auto tw-text-aedblue tw-font-bold" style="font-size: 0.8rem;">
            <span *ngIf="isCollapsed != '' && actionInSO" class="tw-mb-auto" >{{ 'DEFAULTS' | translate }}</span>
        </div>
        
        
        <!-- Reports -->
        <div *appHasRole='["Admin","Dealer","Manager"]' routerLinkActive="active-new" [routerLink]="['/reports']" (click)="closeSettings('reports')" 
        class="tw-flex tw-mt-2 tw-cursor-pointer" style="outline: none;" [ngClass]="isCollapsed != '' ? 'tw-w-full': 'tw-mx-auto'">     
          <div class="bg-icon tw-bg-aedblue tw-w-10  tw-h-10 tw-flex tw-rounded-lg tw-my-auto "
          [ngClass]="isCollapsed != '' ? 'tw-ml-0': 'tw-mx-auto'">            
            <svg class="tw-font-bold tw-w-6 tw-h-6 tw-m-auto" id="Group_510" data-name="Group 510" xmlns="http://www.w3.org/2000/svg" width="14.906" height="16.23" viewBox="0 0 14.906 16.23">
              <path id="Line_170" data-name="Line 170" d="M3.965-.506H-1A.5.5,0,0,1-1.5-1,.5.5,0,0,1-1-1.5H3.965a.5.5,0,0,1,.5.5A.5.5,0,0,1,3.965-.506Z" transform="translate(4.15 5.475)" fill="#fff"/>
              <path id="Line_171" data-name="Line 171" d="M4.959-.506H-1A.5.5,0,0,1-1.5-1,.5.5,0,0,1-1-1.5H4.959a.5.5,0,0,1,.5.5A.5.5,0,0,1,4.959-.506Z" transform="translate(4.15 8.125)" fill="#fff"/>
              <path id="Line_172" data-name="Line 172" d="M3.3-.506H-1A.5.5,0,0,1-1.5-1,.5.5,0,0,1-1-1.5H3.3a.5.5,0,0,1,.5.5A.5.5,0,0,1,3.3-.506Z" transform="translate(4.15 10.775)" fill="#fff"/>
              <path id="Path_450" data-name="Path 450" d="M608.259,367.73h-9.275a2.487,2.487,0,0,1-2.484-2.484V352.659a1.161,1.161,0,0,1,1.159-1.159h.679a.5.5,0,0,1,.49.414.494.494,0,0,0,.974,0,.5.5,0,0,1,.49-.414h1.689a.5.5,0,0,1,.49.414.494.494,0,0,0,.974,0,.5.5,0,0,1,.49-.414h1.689a.5.5,0,0,1,.49.414.494.494,0,0,0,.975,0,.5.5,0,0,1,.49-.414h.679a1.161,1.161,0,0,1,1.159,1.159l.014,11.714a.5.5,0,1,1-.994,0l-.014-11.714a.166.166,0,0,0-.166-.166h-.323a1.488,1.488,0,0,1-2.667,0h-.977a1.488,1.488,0,0,1-2.667,0h-.977a1.488,1.488,0,0,1-2.667,0h-.323a.166.166,0,0,0-.166.166v12.587a1.492,1.492,0,0,0,1.491,1.491h9.275a2.157,2.157,0,0,0,2.1-1.656h-9.286a1.825,1.825,0,0,1-1.753,1.325.5.5,0,1,1,0-.994.829.829,0,0,0,.828-.828.5.5,0,0,1,.5-.5h10.268a.5.5,0,0,1,.5.5A3.15,3.15,0,0,1,608.259,367.73Z" transform="translate(-596.5 -351.5)" fill="#fff"/>
            </svg>
          </div>
          <span *ngIf="isCollapsed != '' && !actionInSO" class="color-text tw-my-auto  tw-ml-3 tw-mr-auto tw-text-aedblue tw-font-bold" style="font-size: 1.1rem;">
            {{ 'REPORTS' | translate }}</span>
        </div>
        <div *appHasRole='["Admin","Dealer","Manager"]' class="tw-flex color-text tw-my-auto tw-mr-auto tw-text-aedblue tw-font-bold" style="font-size: 0.8rem;"> 
          <span *ngIf="isCollapsed != '' && actionInSO" class="tw-mb-auto" >{{ 'REPORTS' | translate }}</span> </div>

        <!-- Help Center -->
        <div *appHasRole='["Admin","Dealer","Manager"]' routerLinkActive="active-new" [routerLink]="['/help']" (click)="closeSettings('help')" 
        class="tw-flex tw-mt-2 tw-cursor-pointer" style="outline: none;" [ngClass]="isCollapsed != '' ? 'tw-w-full': 'tw-mx-auto'">     
          <div class="bg-icon tw-bg-aedblue tw-w-10  tw-h-10 tw-flex tw-rounded-lg tw-my-auto "
          [ngClass]="isCollapsed != '' ? 'tw-ml-0': 'tw-mx-auto'">
          <svg class="tw-font-bold tw-w-6 tw-h-6 tw-m-auto" xmlns="http://www.w3.org/2000/svg" width="16.23" height="16.23" viewBox="0 0 16.23 16.23">
            <g id="Group_505" data-name="Group 505" transform="translate(0 0)">
              <path id="Path_420" data-name="Path 420" d="M517.615,15.731a8.1,8.1,0,0,1-5.73-13.846A8.086,8.086,0,0,1,517.1-.484c.172-.011.346-.016.517-.016a8.115,8.115,0,0,1,6.128,13.435v1.882a.909.909,0,0,1-.268.649.894.894,0,0,1-.635.264h0a.9.9,0,0,1-.3-.053l-1.708-.611A8.042,8.042,0,0,1,517.615,15.731Zm0-15.237c-.151,0-.3,0-.455.014a7.124,7.124,0,0,0-2.42,13.625,7.132,7.132,0,0,0,5.86-.052.5.5,0,0,1,.376-.017l1.773.634V12.747a.5.5,0,0,1,.13-.335A7.119,7.119,0,0,0,517.615.494Z" transform="translate(-509.5 0.5)" fill="#fff"/>
              <path id="Path_421" data-name="Path 421" d="M528.984,18.126a.5.5,0,0,1-.5-.5v-.2a2.468,2.468,0,0,1,.339-1.245,2.547,2.547,0,0,1,.91-.908,1.494,1.494,0,0,0,.7-1.614,1.492,1.492,0,0,0-2.947.326.5.5,0,0,1-.994,0,2.487,2.487,0,0,1,3.013-2.429,2.473,2.473,0,0,1,1.9,1.9,2.487,2.487,0,0,1-1.175,2.674,1.515,1.515,0,0,0-.757,1.3v.2A.5.5,0,0,1,528.984,18.126Z" transform="translate(-520.869 -7.527)" fill="#fff"/>
              <path id="Path_422" data-name="Path 422" d="M533.494,33.5a.5.5,0,1,0-.5.5.5.5,0,0,0,.5-.5" transform="translate(-524.882 -21.573)" fill="#fff"/>
            </g>
          </svg>
          </div>
          <span *ngIf="isCollapsed != '' && !actionInSO" class="color-text tw-my-auto  tw-ml-3 tw-mr-auto tw-text-aedblue tw-font-bold" style="font-size: 1.1rem;">
            {{ 'HELPCENTER' | translate }}</span>
        </div>
        <div *appHasRole='["Admin","Dealer","Manager"]'  class="tw-flex color-text tw-my-auto tw-mr-auto tw-text-aedblue tw-font-bold" style="font-size: 0.8rem;"> 
          <span *ngIf="isCollapsed != '' && actionInSO" class="tw-mb-auto" > {{ 'HELPCENTER' | translate }}</span> </div>   

      </ul>




      <ng-template #popTemplate class="p-5 m-5">
        <app-settingspopup></app-settingspopup>
      </ng-template>   

      <!-- si no es ios -->
      <div style="margin-top:auto;margin-bottom:1rem;" *ngIf="!isIOS" 
      [ngClass]="{'tw-flex tw-justify-between': isCollapsed == 'halfwidth',
      '':isCollapsed == 'halfhalfwidth',
      '': isCollapsed == '' }">
   
        <div class="tw-flex  tw-cursor-pointer">   
          <div [ngClass]="isCollapsed == 'halfwidth'?'tw-mr-auto':'tw-mx-auto tw-mb-2'">

            <div style="background-color: #4db6ac;" [outsideClick]="true" [popover]="popTemplate" 
            class="tw-w-12 tw-h-12 tw-text-white tw-flex tw-rounded-full">
             <span class="tw-m-auto tw-font-bold tw-text-lg">{{avatar | uppercase }}</span> 
            </div>
            
                       
          </div>  


        </div>

        <div class="tw-flex" style="width: fit-content;" [ngClass]="isCollapsed == 'halfwidth'?'tw-ml-auto':'tw-mx-auto'">
          <div  (click)="logout()" class="btn-blue-so tw-flex tw-my-auto" [ngClass]="{'tw-mx-2':isCollapsed != 'halfwidth'}">
            <span class="material-icons">logout</span>
            <span [ngClass]="isCollapsed == 'halfwidth'?'tw-block':'tw-hidden'">{{ 'LOGOUT' | translate }}</span> 
          </div>
        </div>
      </div>

      <!-- si es ios -->
      <div *ngIf="isIOS" style="margin-top: auto;margin-bottom:1rem;"
      [ngClass]="{'tw-flex tw-justify-between': isCollapsed == 'halfwidth',
      '':isCollapsed == 'halfhalfwidth',
      '': isCollapsed == '' }">
    
        <div class="">

          <div [ngClass]="isCollapsed == 'halfwidth' ? '' : 'tw-mx-2'" 
          [routerLink]="['/storeconfig']" class="btn-blue-so tw-mb-2 tw-py-2" *appHasRole='["Admin","Dealer","Manager"]'>
            <span class="material-icons">settings</span>
          </div> 
          <div class="tw-flex tw-cursor-pointer">

            <div style="background-color: #4db6ac;"
            class="tw-w-12 tw-h-12 tw-text-white tw-flex tw-rounded-full"
            [ngClass]="isCollapsed == 'halfwidth'?'tw-mx-auto':'tw-mx-auto tw-mb-2'">
              <span class="tw-m-auto tw-font-bold tw-text-lg">{{avatar | uppercase }}</span> 
             </div>
   
          </div>       
 
        </div>

        <div class="tw-flex" style="width: fit-content;" [ngClass]="isCollapsed == 'halfwidth'?'tw-ml-auto':'tw-mx-auto'">
          <div  (click)="logout()" class="btn-blue-so tw-mt-auto" [ngClass]="{'tw-mx-2':isCollapsed != 'halfwidth'}">
            <span class="material-icons" >logout</span>
            <span [ngClass]="isCollapsed == 'halfwidth'?'tw-block':'tw-hidden'">{{ 'LOGOUT' | translate }}</span> 
          </div>
        </div>
      </div>


    </div> <!-- fin del sidebar -->


    <div class="sidebar-1-content-closesidebar" [ngClass]="{ 'closed-sidebar': isTouched && isCollapsed }" id="close-sidebar" (click)="closeSideBar()">
      <span class="material-icons">keyboard_arrow_right</span>
    </div>




    
</nav>




  
  <!-- page-content" -->  
  <main id="mainWithSideBar"  class="body-page-content" 
  [ngClass]="{
              '': isCollapsed == '', 
              halfwidth: isCollapsed == 'halfwidth',
              halfhalfwidth:isCollapsed == 'halfhalfwidth'
            }">

      <div *ngIf="showcurrrentLoc && !showMobile && !actionInSO" class="tw-h-12 tw-w-full tw-flex">
        
        <div  class="sm:-tw-ml-2 md:-tw-ml-2 mdd:-tw-ml-8 lg:-tw-ml-8 xl:-tw-ml-8  
        tw-bg-aedgraydark tw-flex tw-rounded-r-full tw-my-auto tw-text-white" >
          <div class="tw-my-auto tw-py-2 tw-px-4 tw-pl-20"> 
            <span>{{typeCorporate == 'multistore'? 'Current Store:':'Current Territory:'}}</span> <span class="tw-font-bold">{{currrentLoc}}</span> 
          </div>          
        </div>

      </div>

    <div class="container-fluid p-0" >
      <router-outlet *ngIf="loggedIn()"></router-outlet>
    </div>

    <div *ngIf="showcurrrentLoc && !showMobile && !actionInSO" style="position: fixed;bottom: 0;right: 0;    opacity: .3;
    z-index: unset;pointer-events: none;background-color: gray;" 
    class=" tw-h-auto tw-w-80 tw-bg-aedgrayback100 tw-rounded-bl-3xl tw-rounded-tl-3xl tw-text-white tw-mb-4 tw-pl-12 tw-pr-6 tw-py-4
    ">
      <div class="tw-text-lg">{{typeCorporate == 'multistore'? 'Current Store:':'Current Territory:'}}</div>
      <div class="tw-text-lg tw-font-bold">{{currrentLoc}}</div>
    </div>

  </main>

</div>