<div class="w-100">
    <div class="tw-flex tw-my-4">
        <div class="tw-w-full tw-flex tw-justify-between">
            <button [disabled]="operationInProgress" class="btn-blue-so-square tw-ml-4" (click)="getExpiringItems(expiringOption === 'nextMonth' ? 'nextWeek' : 'nextMonth')" >
                {{ 'VIEW_EXPIRED_ITEMS' | translate}} {{expiringOption === 'nextMonth' ? ('WEEK' | translate) : ('MONTH' | translate)}}
            </button>
        </div>
    </div>

    <div class="customer-content tw-px-4">
        <div class="table-responsive tw-w-full">
            <table class="table-so table table-light table-custom overflow-auto tw-w-full">
                <thead class="tw-font-bold tw-text-aedblue tw-w-full">
                    <tr class="tw-w-full tw-uppercase tw-text-center tw-bg-aedgrayback100">
                        <td class="tw-full" colspan="9">
                            {{ 'PRODUCT_ABOUT_TO_EXPIRE' | translate}} {{expiringOption === 'nextMonth' ? ('MONTH' | translate) : ('WEEK' | translate)}}
                        </td>
                    </tr>
                    <tr style="font-size: small;background-color: #fafafa;">
                        <td>{{ 'PRODUCT_CODE' | translate }}</td>
                        <td>{{ 'SKU' | translate }}</td>
                        <td>{{ 'BARCODE' | translate }}</td>
                        <td>{{ 'PRODUCT_NAME' | translate }}</td>
                        <td class="tw-text-center">{{ 'WAREHOUSE' | translate }}</td>
                        <td class="tw-text-center">{{ 'RETAIL' | translate }}</td>
                        <td class="tw-text-center">{{ 'AVAILABLE' | translate }}</td>
                        <td class="tw-text-center">{{ 'EXPIRED_DATE' | translate }}</td>
                        <td class="tw-text-center">{{ 'REMAINING_DAYS' | translate }}</td>
                    </tr>

                <tbody class="tw-py-2 tw-text-aedgraydark tw-w-full"
                    *ngFor="let product of products | paginate: { itemsPerPage: 10, currentPage: p, id:'products'};  let odd = odd"
                    [ngClass]="odd ? 'tw-bg-aedgrayback' :'tw-bg-aedgrayback100'">
                    <tr>
                        <td>{{product.productCode}}</td>
                        <td>{{product.sku}}</td>
                        <td>{{product.barcode}}</td>
                        <td>{{product.productName}}</td>
                        <td class="tw-text-center">{{product.warehouse}}</td>
                        <td class="tw-text-center">{{product.retail - product.sold}}</td>
                        <td class="tw-text-center">{{product.retail - product.sold + product.warehouse}}</td>
                        <td class="tw-text-center">{{product.dateExpired}}</td>
                        <td class="tw-text-center">{{product.remainingDays}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination-container">
                <pagination-controls 
                id="products" (pageChange)="p = $event" maxSize="10" previousLabel="" nextLabel="" autoHide="true">
                </pagination-controls>
            </div>
        </div>
    </div>
</div>
