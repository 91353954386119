import { AfterContentChecked, Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SizesIndex } from 'src/app/_resolvers/SizeSyno';
import { AuthService } from 'src/app/_services/auth.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { SettingsService } from 'src/app/_services/settings.service';
import { UserService } from 'src/app/_services/user.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { ItemdeliveryComponent } from './itemdelivery/itemdelivery.component';

@Component({
  selector: 'app-salesorderitems',
  templateUrl: './salesorderitems.component.html',
  styleUrls: ['./salesorderitems.component.scss']
})
export class SalesorderitemsComponent implements OnInit, AfterContentChecked {
  @Input() salesOrderItems: any = [];
  @Input() SOItemsToDelete: any = [];
  @Input() salesOrder: any = [];
  @Input() canSelect = true;
  totalItem:any;
  deliveryInfo:any;
  storeSettings:any;
  referralTypes = [
    'Referral Flyers',
    'Signs',
    'Feeder Memos',
    'Faculty Discount',
    'Hero Flyer',
    'District Generic Email',
    'Social Media',
    'Direct Mail',
    'NewsPaper Ad',
    'Other'
  ];
  
  platform = '';
  showMobile = false;
  isIOS = false;
  isAndroid = false;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private alertify: AlertifyService,
    private global: GlobalsService,
    public bsModalRef: BsModalRef,
    public userService: UserService,
    public settingService: SettingsService,
    private modalService: BsModalService,
    private authService: AuthService) { }

  ngOnInit() {
    this.platform = navigator.userAgent;
    if (this.platform.match(/Android/i)) {
      this.showMobile = true;
      this.isAndroid = true;
    }
    if (this.platform.match(/iPhone|iPad|iPod/i)) {
      this.showMobile = true;
      this.isIOS = true;

    }

    if (this.platform.includes('Mac')) {
      this.showMobile = false;

    }

    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
      this.isIOS = true;
    }

    if (this.platform.includes('Win')) {
      this.showMobile = false;

    }

    this.settingService.SettingsEvent$
    .pipe(takeUntil(this.destroy$))   
    .subscribe( data => {
      this.storeSettings = data[0];
      this.deliveryInfo = JSON.parse(this.storeSettings.deliveryInfo);
      // console.log(this.deliveryInfo)
    }, error => {
      this.alertify.error(error);
    });
  }

  ngAfterContentChecked(): void {
    if(this.salesOrderItems != undefined){
      this.salesOrderItems.forEach(soItem => {
        this.changeToNegative(soItem);
      });
    }

  }
  //this method is no longer used
  addItem(soItem: any[]) {
    soItem['SOItemsID'] = this.global.guid();
    soItem['foreignSOID'] = this.salesOrder['SOID'];
    this.salesOrderItems.push(soItem);
  }

  addDiscountItem(soItem: any[]) {
    const newSoItem = Object.assign({}, soItem);
    newSoItem['SOItemsID'] = this.global.guid();
    newSoItem['created_at'] = new Date();
    newSoItem['updated_at'] = new Date();
    newSoItem['foreignSOID'] = this.salesOrder['SOID'];
    newSoItem['manufacter'] = 'DISCOUNT ABOVE ITEM';
    newSoItem['styleName'] = this.salesOrder.soSource || '';
    newSoItem['itemType'] = 'discount';
    newSoItem['status'] = 'insert';
    newSoItem['priceNumUnit'] = newSoItem['priceNumUnit'] * newSoItem['quantityNum'] * (-1);
    newSoItem['priceNum'] = newSoItem['priceNumUnit'];
    newSoItem['noteExp'] = soItem['SOItemsID'];
    newSoItem['PDis'] = 100;
    const toFind = this.salesOrderItems.findIndex(obj => obj['SOItemsID'] === soItem['SOItemsID']);
    this.salesOrderItems.splice(toFind + 1, 0, newSoItem);
    
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Add item Discount',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});

  }

  deleteItem(soItem) {
    const toDelete = this.salesOrderItems.findIndex(obj => obj['SOItemsID'] === soItem);
    this.SOItemsToDelete.push(this.salesOrderItems[toDelete]);
    this.salesOrderItems.splice(toDelete, 1 );
  }

  onQtyChange(quantity: number, soItem: any, salesPrice: number  ) { 
    
    //validation number
    if(quantity > 100){
      quantity = 100;
      this.alertify.error('The max qty is 100')
    }else if(salesPrice > 30000){
      salesPrice = 30000;
      this.alertify.error('The max sales price is 30000')
    }else if(salesPrice < 0){
      salesPrice = 0;
      this.alertify.error("Sales price can't be negative")      
    }
      
      soItem['quantityNum'] = quantity;
      if (soItem['itemType'] === 'discount') {
        soItem['quantityNum'] = 1;
        soItem['PDis'] = 100;
        if (soItem['priceNumUnit'] > 0) {
          salesPrice = soItem['priceNumUnit'] * -1;
          
    this.onPDisChange(quantity,soItem,salesPrice); 
        }
      }
      soItem['priceNumUnit'] = salesPrice;    
      this.totalItem = soItem['priceNumUnit'] * soItem['quantityNum'];
     // this.checkTotalsForDiscounts(soItem);

    }

    onPDisChange(quantity: number, soItem: any, salesPrice: number  ) {

        if (soItem['PDis'] < 0 ) {
          soItem['PDis'] = 0.01;
          this.alertify.error("Discount can't be negative or 0");
        }else if(soItem['PDis'] > 100){  
          soItem['PDis'] = 100;      
          this.alertify.error("Discount can't be greater than 100");
        }

        if (soItem['itemType'] === 'discount') {  
          if(soItem['PDis'] <= 100 && soItem['PDis'] > 0){
            salesPrice = this.global.pNumber(soItem['priceNum'] * soItem['PDis'] / 100) * -1;
          }
        }

        soItem['priceNumUnit'] = salesPrice;
    }

    onValueDiscountChange(quantity: number, soItem: any, salesPrice: number  ) {
      
      if (soItem['itemType'] === 'discount') {   

        var priceNum
        if(soItem['manufacter'] ==  'DISCOUNT ABOVE ITEM' ){
          priceNum = (soItem['priceNum'] * -1)
        }else{
          priceNum = soItem['priceNum'];
        }
        if( priceNum >= (soItem['priceNumUnit'] * -1)){
          salesPrice = this.global.pNumber(priceNum * soItem['PDis'] / 100) * -1;
          salesPrice = this.global.pNumber((soItem['priceNumUnit'] / priceNum)  * 100) * -1;
          soItem['PDis'] = this.global.pNumber((((soItem['priceNumUnit']/(priceNum))*100)*-1));
        }else{
          this.alertify.error('discount not valid');
          soItem['priceNumUnit'] = priceNum;
          soItem['PDis'] = 100;
        }
      }        
    }

    checkTotalsForDiscounts(soItem) {
      this.salesOrderItems.forEach(element => {
        if (element['noteExp'] === soItem['SOItemsID']) {
          element['priceNum'] = soItem['priceNumUnit'];
          this.onPDisChange(0, element, 0);
        }
      });
    }

    changeToNegative(soItem: any) {
      let salesPrice = soItem['priceNumUnit'];
      if (soItem['itemType'] === 'discount') {
        soItem['quantityNum'] = 1;
        if (soItem['priceNumUnit'] > 0) {
          salesPrice = soItem['priceNumUnit'] * -1;
        }
        soItem['priceNumUnit'] = salesPrice;
      }
      // if(soItem['status'] != 'insert'){
      //   soItem['status'] = 'fromDB'
      // }
    }

  addQty(soItem: any) {
      soItem['quantityNum'] = Number(soItem['quantityNum']) + 1;
  }

  showDs(soItem: any) {
    const initialState = {
      salesOrderMode: true,
      item: soItem,
      salesOrder: this.salesOrder
    };
    this.bsModalRef = this.modalService.show(ItemdeliveryComponent, {initialState, class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
  }

  SynoTypes(style:string) : string{
    let response = ''

    if( (SizesIndex['ALL SIZES'].toLowerCase()).includes(style.toLowerCase())) {
      response = SizesIndex['ALL SIZES']
    }
    else if( (SizesIndex.TWIN.toLowerCase()).includes(style.toLowerCase())) {
      response = SizesIndex.TWIN
    }
    else if( (SizesIndex["TWIN XL"].toLowerCase()).includes(style.toLowerCase())) {
      response = SizesIndex["TWIN XL"]
    }
    else if( (SizesIndex.FULL.toLowerCase()).includes(style.toLowerCase())) {
      response = SizesIndex.FULL
    }
    else if( (SizesIndex.QUEEN.toLowerCase()).includes(style.toLowerCase())){ 
      response = SizesIndex.QUEEN
    }
    else if( (SizesIndex.KING.toLowerCase()).includes(style.toLowerCase())) {
      response = SizesIndex.KING
    }
    else if( (SizesIndex["CAL KING"].toLowerCase()).includes(style.toLowerCase())){ 
      response = SizesIndex["CAL KING"]
    }

    return response;
  }

  updateAddress(data:any){
    // console.log(data)
    let item = data.item

    if(data.change){
      this.addDeliveryAuto(item, 'Direct Shipment', true);
    }

  }

  addDeliveryAuto(item, type, avoid?: boolean){
    // console.log(this.salesOrder)

    // Count how many direct shipment or nto/stock exists

    let direct = 0
    let nto = 0
    if(this.salesOrder.deliveryItems != undefined){
      // console.log(this.salesOrder.deliveryItems)
      this.salesOrder.deliveryItems.forEach((element:any) => {
        // console.log(element.deliveryNotes)
        if((element.deliveryNotes == null ? '' : element.deliveryNotes ).includes('Direct Shipment')){
          // console.log('conta')
          direct++
        }
        if((element.deliveryNotes == null ? '' : element.deliveryNotes ).includes('Delivery Event')){
          nto++
        }
      });
    }
    //
    if(avoid == true){
      direct = 0
    }

    // check all the default delivery costs
    this.deliveryInfo.forEach(deli => {
      // set the synonim
      let sino = this.SynoTypes(deli.size)

      let allSizes = false
      if(sino.includes('ALL SIZES')){
        // console.log('ALL SIZES')
        // If size in ALL SIZES skips sizes and just compare product
        if(item.Type.trim().toLowerCase() == deli.product.trim().toLowerCase()){
  
  
          if(type == 'Direct Shipment' && direct == 0){
            let delivery ={
              Type : "Direct Shipment",
              deliveryAmountNum : deli.method == 'Direct Shipment' ? deli.price : 0,
              deliveryCode : "",
              deliveryId : this.global.guid(),
              deliveryNotes : "1",
              foreignSOID : this.salesOrder['SOID'],
              pretax : "1",
              status : "insert",
            }
            if(this.salesOrder.deliveryItems == undefined){
              this.salesOrder.deliveryItems = []
              this.salesOrder.deliveryItems.push(delivery)
            }else{
              this.salesOrder.deliveryItems.push(delivery)
            }
  
  
          } else if((type == 'NTO' || type == 'In Stock' )&& nto == 0){
            let delivery ={
              Type : "Delivery",
              deliveryAmountNum : deli.method == 'Delivery' ? deli.price : 0,
              deliveryCode : "",
              deliveryId : this.global.guid(),
              deliveryNotes : " 2 ",
              foreignSOID : this.salesOrder['SOID'],
              pretax : "1",
              status : "insert",
            }
            if(this.salesOrder.deliveryItems == undefined){
              this.salesOrder.deliveryItems = []
              this.salesOrder.deliveryItems.push(delivery)
            }else{
              this.salesOrder.deliveryItems.push(delivery)
            }
  
          }
      
          // this.refreshDelivery.next('hola')
        }

      }
      // Valida size and prosuct equal
      if(sino.toLowerCase().includes(item.sizeName.trim().toLowerCase()) && item.Type.trim().toLowerCase() == deli.product.trim().toLowerCase()){
        // console.log('aplica')


        if(type == 'Direct Shipment' && direct == 0){
          let delivery ={
            Type : "Delivery",
            deliveryAmountNum : deli.method == 'Direct Shipment' ? deli.price : 0,
            deliveryCode : "",
            deliveryId : this.global.guid(),
            deliveryNotes : " 3",
            foreignSOID : this.salesOrder['SOID'],
            pretax : "1",
            status : "insert",
          }
          if(this.salesOrder.deliveryItems == undefined){
            this.salesOrder.deliveryItems = []
            this.salesOrder.deliveryItems.push(delivery)
          }else{
            this.salesOrder.deliveryItems.push(delivery)
          }


        } else if((type == 'NTO' || type == 'In Stock' )&& nto == 0){
          let delivery ={
            Type : "Delivery",
            deliveryAmountNum : deli.method == 'Delivery' ? deli.price : 0,
            deliveryCode : "",
            deliveryId : this.global.guid(),
            deliveryNotes : " 4 ",
            foreignSOID : this.salesOrder['SOID'],
            pretax : "1",
            status : "insert",
          }
          if(this.salesOrder.deliveryItems == undefined){
            this.salesOrder.deliveryItems = []
            this.salesOrder.deliveryItems.push(delivery)
          }else{
            this.salesOrder.deliveryItems.push(delivery)
          }

        }
    
        // this.refreshDelivery.next('hola')
      }
    });

    if(this.salesOrder.deliveryItems != undefined){
      // console.log(this.salesOrder.deliveryItems)
      this.salesOrder.deliveryItems.forEach((element:any) => {
        // console.log(element.deliveryNotes)
        if((element.deliveryNotes == null ? '' : element.deliveryNotes ).includes('Direct Shipment')){
          // console.log('conta')
          direct++
        }
        if((element.deliveryNotes == null ? '' : element.deliveryNotes ).includes('Delivery Event')){
          nto++
        }
      });
    }

    if(type == 'Direct Shipment' && direct == 0){
      let delivery ={
        Type : "Delivery",
        deliveryAmountNum : 0.00,
        deliveryCode : "",
        deliveryId : this.global.guid(),
        deliveryNotes : " 5",
        foreignSOID : this.salesOrder['SOID'],
        pretax : "1",
        status : "insert",
      }
      if(this.salesOrder.deliveryItems == undefined){
        this.salesOrder.deliveryItems = []
        this.salesOrder.deliveryItems.push(delivery)
      }else{
        this.salesOrder.deliveryItems.push(delivery)
      }


    } else if((type == 'NTO' || type == 'In Stock' )&& nto == 0){
      let delivery ={
        Type : "Delivery",
        deliveryAmountNum : 0.00 ,
        deliveryCode : "",
        deliveryId : this.global.guid(),
        deliveryNotes : " 6 ",
        foreignSOID : this.salesOrder['SOID'],
        pretax : "1",
        status : "insert",
      }
      if(this.salesOrder.deliveryItems == undefined){
        this.salesOrder.deliveryItems = []
        this.salesOrder.deliveryItems.push(delivery)
      }else{
        this.salesOrder.deliveryItems.push(delivery)
      }

    }

 //   this.refreshDelivery.next('hola')
  }


  changeToDs(soItem) {
    if (soItem['itemDelivery'] === 'Direct Shipment' || soItem['itemDelivery'] === 'Delivery NTO'
    || soItem['itemDelivery'] === 'Delivery In Stock') {
        this.showDs(soItem);
        if(soItem['itemDelivery'] === 'Direct Shipment'){
          this.addDeliveryAuto(soItem, 'Direct Shipment', false)
        }
        
    }
    if(soItem['itemDelivery'] === 'NTO' || soItem['itemDelivery'] == 'Taken' || soItem['itemDelivery'] == 'In Stock'){
      if((soItem['itemDelivery'] === 'NTO' || soItem['itemDelivery'] === 'In Stock') && this.salesOrder.deliveryRequest == 'Delivery'){
        this.addDeliveryAuto(soItem, 'NTO', false)
      }
      this.salesOrderItems.forEach((item:any) => {
        if(item['SOItemsID'] == soItem['SOItemsID']){
          item['deliveryAttn'] = null;
          item['deliveryAddress1'] = null;
          item['deliveryAddress2'] = null;
          item['deliveryCity'] = null;
          item['deliveryEmail'] = null;
          item['deliveryPhone'] = null;
          item['deliveryState'] = null;
          item['deliveryZip'] = null;
        }
      });
    }

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Select Inventory Status',
    //   eventId: soItem['itemDelivery'],
    //   device: localStorage.getItem('device'),
    // }
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
    
  }

}


