import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GlobalsService } from 'src/app/_services/globals.service';
import { SendemailComponent } from '../sendemail/sendemail.component';

declare var Stimulsoft: any;
@Component({
  selector: 'app-store-products-report',
  templateUrl: './store-products-report.component.html',
  styleUrls: ['./store-products-report.component.scss']
})
export class StoreProductsReportComponent implements OnInit {

@Input() reportdata: any;
@Input() showReport: any;

viewer :any;
report :any;
showMobile = false;  
platform = '';
show:boolean = false;
reportFormat = 'PDF';
attachmentStream: any;

constructor(public globals: GlobalsService,
  private modalService: BsModalService,
  public bsModalRef: BsModalRef) { }  

ngOnInit(): void {
  this.show == false;
  this.platform = navigator.userAgent;

  if (this.platform.includes('Mac')) {
    this.showMobile = false;
  }

  if (this.platform.includes('Win')) {
    this.showMobile = false;
  }

  if (this.platform.match(/Android/i)) {
    this.showMobile = true;
  }
  if (this.platform.match(/iPhone|iPad|iPod/i)) {
    this.showMobile = true;
  }

  this.getReport();   

}

async getReport(){
  let wait = await this.globals.loadScript();
  if(wait){
    this.viewer = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
    this.report = new Stimulsoft.Report.StiReport();

    this.report.loadFile('reports/' + this.showReport + '.mrt');
    this.report.dictionary.databases.clear();
    const dssalesprice = new Stimulsoft.System.Data.DataSet();
    dssalesprice.readJson(this.reportdata);
    this.report.regData('salesprice', null, dssalesprice);
    this.viewer.report = this.report;
    this.viewer.options.toolbar.showAboutButton = false;
    this.viewer.options.toolbar.showEmailButton = true;
    this.viewer.options.appearance.scrollbarsMode = true;
    this.viewer.options.appearance.showTooltipsHelp = false;
    this.viewer.options.appearance.showFormsHelp = false;
    this.viewer.options.appearance.showFormsHints = false;
    this.viewer.options.exports.showExportToHtml = false;
    this.viewer.options.exports.showExportToHtml5 = false;
    this.viewer.options.exports.showExportToDataFile = false;
    this.viewer.options.exports.showExportToDocument = false;
    this.viewer.options.exports.showExportDialog = false;
    this.viewer.options.toolbar.printDestination = Stimulsoft.Viewer.StiPrintDestination.Direct;

    if (this.showMobile === true) {
      this.viewer.options.toolbar.showPrintButton = false;
      this.viewer.options.toolbar.showSaveButton = true;
      this.viewer.options.toolbar.showExportToPDF = true;
      this.viewer.options.toolbar.showExportToExcel  = false;
      this.viewer.options.toolbar.showExportToRtf = false;
      this.viewer.options.toolbar.showExportToExcel2007 = false;
      this.viewer.options.toolbar.showExportToExcelXML = false;
      this.viewer.options.appearance.interfaceType = Stimulsoft.Viewer.StiInterfaceType.Mobile;
    }

    this.viewer.onBeginExportReport = function (event) {
      switch (event.format) {
        case Stimulsoft.Report.StiExportFormat.Excel2007:
        event.settings.excelType = Stimulsoft.Report.Export.StiExcelType.ExcelXml;
        break;
      }
    };

    this.show = true;
    this.viewer.options.width = '100%';
    this.viewer.options.height = '100%';
    this.viewer.renderHtml('viewerContent');
  }
}

async  showSendEmail(format) {
  let wait = await this.globals.loadScript();
  if(wait){
    this.viewer = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
    this.report = new Stimulsoft.Report.StiReport();

    this.reportFormat = format;
    const model = {};

    if (this.reportFormat === 'pdf') {
      var settings = new Stimulsoft.Report.Export.StiPdfExportSettings();
      var service = new Stimulsoft.Report.Export.StiPdfExportService();
      var stream = new Stimulsoft.System.IO.MemoryStream();
      service.exportTo(this.report, stream, settings);
      var datastream = stream.toArray();
      this.attachmentStream = Stimulsoft.System.Convert.toBase64String(datastream);
      model['attachmentfiletype'] =  'application/pdf';
      model['attachmentfilename'] = this.showReport + '.pdf';
    }

    if (this.reportFormat === 'xlsx') {
      var settings = new Stimulsoft.Report.Export.StiExcel2007ExportSettings();
      var service = new Stimulsoft.Report.Export.StiExcel2007ExportService();
      var stream = new Stimulsoft.System.IO.MemoryStream();
      service.exportTo(this.report, stream, settings);
      var datastream = stream.toArray();
      this.attachmentStream = Stimulsoft.System.Convert.toBase64String(datastream);
      model['attachmentfiletype'] =  'application/ms-excel';
      model['attachmentfilename'] = this.showReport + '.xlsx';
    }

    if (this.reportFormat === 'csv') {
      var settings = new Stimulsoft.Report.Export.StiCsvExportSettings();
      var service = new Stimulsoft.Report.Export.StiCsvExportService();
      var stream = new Stimulsoft.System.IO.MemoryStream();
      service.exportTo(this.report, stream, settings);
      var datastream = stream.toArray();
      this.attachmentStream = Stimulsoft.System.Convert.toBase64String(datastream);
      model['attachmentfiletype']  = 'text/comma-separated-values';
      model['attachmentfilename'] = this.showReport + '.csv';
    }

    model['subject'] = 'Report ' + this.showReport;
    model['message'] = 'Please find attached ' + this.showReport ;
    model['attachmentbase64'] =  this.attachmentStream;


    const initialState = {
      model: model
    };
    this.bsModalRef = this.modalService.show(SendemailComponent, {initialState, class: 'modal-lg'});
  }
}

}