import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-collapse-control',
  templateUrl: './collapse-control.component.html',
  styleUrls: ['./collapse-control.component.scss']
})
export class CollapseControlComponent implements OnInit {
  @Input()  isCollapsed = false;
  @Output() open: EventEmitter<boolean> = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  changeCollapse() {
    this.isCollapsed = !this.isCollapsed;
    this.open.emit(this.isCollapsed);
  }


}
