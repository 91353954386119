import { Component, OnInit, Input, AfterContentChecked } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { Router } from '@angular/router';
import { UserService } from 'src/app/_services/user.service';
import { AuthService } from 'src/app/_services/auth.service';


@Component({
  selector: 'app-salesorder-search',
  templateUrl: './salesorder-search.component.html',
  styleUrls: ['./salesorder-search.component.scss']
})
export class SalesorderSearchComponent implements OnInit, AfterContentChecked {
  @Input() salesorders: any = [];
  userRole = '';
  passSalesOrders: any = [];
  constructor(  private bsModalRef: BsModalRef,
    private salesService: SalesOrderService,
    private userService: UserService,
    private router: Router,
    private authService:AuthService) { }

  ngOnInit() {
    this.salesorders = this.passSalesOrders;
    this.getRole();
  }

  ngAfterContentChecked(): void {

  }


  getRole() {
    // this.userRole = localStorage.getItem('role').replace('"', '');
    this.userRole = this.authService.decodeToken('role').role;
  }

  selectSalesOrder(id: string) {

    this.salesService.sofrom.next('customers')
    this.salesService.editSalesOrder.next(id);
    
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Customers',
    //   action: 'Go to SO from Customers',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    // this.userService.logTracking(data).subscribe(a => {
    //   //console.log(a)
    // });

    this.router.navigate(['/salesorder/' + id]);
    this.close();
  }

  close() {
    this.bsModalRef.hide();
  }

}
