import { Component, OnInit, Input, TemplateRef, EventEmitter,
  Output, OnDestroy, ChangeDetectorRef, AfterContentChecked, AfterViewChecked, OnChanges, DoCheck, SimpleChanges } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CustomerService } from '../../../_services/customer.service';
import { AlertifyService } from '../../../_services/alertify.service';

import { DummymodalComponent } from '../../dummymodal/dummymodal.component';
import 'rxjs/add/operator/takeUntil';
import { AddressComponent } from '../address/address.component';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CustomerFormComponent } from '../../customer/customer-form/customer-form.component';


@Component({
  selector: 'app-address-card',
  templateUrl: './address-card.component.html',
  styleUrls: ['./address-card.component.scss']
})
export class AddressCardComponent implements OnInit, OnChanges,DoCheck{
  @Input() so: any;
  @Input() openSelectEvent: any;
  @Input() canSelect = true;
  @Input() customerComment = '';
  halftwoiconsLabel = false
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(private modalService: BsModalService,
    private customerService: CustomerService,
    private userService: UserService,
    private alertify: AlertifyService,
    private bsModalRef: BsModalRef,
    private authService: AuthService) { }

  ngOnInit() {
  }
  
  ngOnChanges():void{
    if(this.openSelectEvent == true && this.customerService.salesOrderCustomer.value === 0){
      this.openModalWithComponent()
    }
  }


  ngDoCheck() { 
    if(document.getElementById("halftwo").clientWidth > 300){
      this.halftwoiconsLabel = true
    }else{
      this.halftwoiconsLabel = false
    }
  }

  public openModalWithComponent() {
    const initialState = {
      fromSalesOrder: true,
      viewType:'List',
      deliveryRequest: this.so['deliveryRequest'],
      salesOrderToSave: this.so
    };
    // console.log(this.so)

    if (this.customerService.salesOrderCustomer.value !== 0) {  

      this.alertify.confirm('Are you sure you want to change the customer?', () => {
        //this.customerService.selectedCustomer(0);
        //this.customerService.salesOrderCustomer.next(0);
        // this.bsModalRef = this.modalService.show(CustomerFormComponent,  {initialState, class: 'modal-lg', ignoreBackdropClick: true});
        this.bsModalRef = this.modalService.show(DummymodalComponent, {initialState,class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
      
      }
      ,()=>{
      });
    } else {
      // this.customerService.selectedCustomer(0);
      // this.customerService.salesOrderCustomer.next(0);
      this.customerService.editCustomer.next(0);
      this.bsModalRef = this.modalService.show(CustomerFormComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true});
    }
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Select Customer',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }

  openlistCustomer(){
    this.bsModalRef.hide();
    const initialState = {
      fromSalesOrder: true,
      viewType:'List',
      salesOrderToSave:this.so
    };
    this.bsModalRef = this.modalService.show(DummymodalComponent, {initialState,class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
  }

  // public openModalWithComponent() {

  //   if (this.customerService.salesOrderCustomer.value !== 0) {
  //     this.alertify.confirm('Are you sure you want to change the customer?', () => {
  //       //this.customerService.selectedCustomer(0);
  //       //this.customerService.salesOrderCustomer.next(0);
  //       this.bsModalRef = this.modalService.show(DummymodalComponent, {class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
  //     });
  //   } else {
  //     this.customerService.selectedCustomer(0);
  //     this.customerService.salesOrderCustomer.next(0);
  //     this.bsModalRef = this.modalService.show(DummymodalComponent, { class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
  //   }
  //   let data = {
  //     userId: '123',
  //     userName: this.authService.decodeToken('username').username,
  //     section: 'SO',
  //     action: 'Select Customer',
  //     eventId: '*',
  //     device: localStorage.getItem('device'),
  //   }
    
  //   this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
  //   .subscribe(a => {});
  // }

  editSalesOrder(so: any) {
      const salesOrder = JSON.parse(JSON.stringify(Object.assign({}, so)));
      const initialState = {
        so: salesOrder,
        customerComment: this.customerComment,
        salesOrderToSave: this.so,
        deliveryRequest: this.so['deliveryRequest']
      };
      this.bsModalRef = this.modalService.show(AddressComponent, {initialState, class: 'modal-lg w-100', backdrop: true, ignoreBackdropClick: true});
      // let data = {
      //   userId: '123',
      //   userName: this.authService.decodeToken('username').username,
      //   section: 'SO',
      //   action: 'Edit Customer',
      //   eventId: '*',
      //   device: localStorage.getItem('device'),
      // }
      
      // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
      // .subscribe(a => {});
  }


}
