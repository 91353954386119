import { HttpClient } from '@angular/common/http';
import { AfterContentChecked, Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { Observable } from 'rxjs/Observable';
import { NrnValidators } from 'src/app/nrn.validators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { CustomerService } from 'src/app/_services/customer.service';
import { GlobalsService } from 'src/app/_services/globals.service';

@Component({
  selector: 'app-customer-address',
  templateUrl: './customer-address.component.html',
  styleUrls: ['./customer-address.component.scss']
})
export class CustomerAddressComponent implements OnInit, AfterContentChecked {
  @Input() customer: any = [];
  states: any[];
  mask = [ /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  form = new FormGroup({});
  model: any = {};
  fields: FormlyFieldConfig[] = [
  {
    wrappers: ['formwrap'],
    templateOptions: { label: '' },
    fieldGroupClassName: 'row',
    fieldGroup: [{
    key: 'fName',
    type: 'input',
    className: 'col-md-12',
    templateOptions: {
    type: 'text',
    placeholder: 'First Name',
    required: true
  },
  },
{
  key: 'lName',
    type: 'input',
    className: 'col-md-12',
    templateOptions: {
      type: 'text',
      placeholder: 'Last Name',
      required: true
    }
},
{
  key: 'phone3',
    type: 'phone',
    className: 'col-md-6',
    templateOptions: {
      placeholder: 'Phone',
      pattern: NrnValidators.FORMATS.maskphone
    },
    validation: {
      messages: {
        pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Phone Number`,
      },
    },
},
{
  key: 'phone3Type',
    type: 'select',
    className: 'col-md-6',
    templateOptions: {
      type: 'select',
      options: [
        {label: 'Home', value: 'Home'},
        {label: 'Cell', value: 'Cell'},
        {label: 'Work', value: 'Work'}
      ],
    }
},

{
  key: 'deliveryEmail',
    type: 'input',
    className: 'col-md-12',
    templateOptions: {
      type: 'text',
      placeholder: 'Email',
      required: true
    }
},
{
  key: 'deliveryAddress1',
    type: 'input',
    className: 'col-md-12',
    templateOptions: {
      type: 'text',
      placeholder: 'Address',
      required: true
    }
},
{
  key: 'deliveryAddress2',
    type: 'input',
    className: 'col-md-12',
    templateOptions: {
      type: 'text',
      placeholder: 'billingAddress2'
    }
},
{
  key: 'deliveryCity',
    type: 'input',
    className: 'col-md-12',
    templateOptions: {
      type: 'text',
      placeholder: 'City',
      required: true
    }
},
{
  key: 'deliveryState',
    type: 'select',
    className: 'col-md-6',
    templateOptions: {
      required: true,
      options: this.getStates(),
      valueProp: 'abbreviation',
      labelProp: 'name'
    }
},
{
  key: 'deliveryZip',
    type: 'input',
    className: 'col-md-6',
    templateOptions: {
      type: 'text',
      placeholder: 'Zip',
      required: true
    }
}],
},
];


  constructor(private http: HttpClient, private customerService: CustomerService, private alertify: AlertifyService,private global:GlobalsService) { }

  ngOnInit() {
    this.model = this.customer;
  }

  submit(model: any) {
    this.addCustomer();
  }

  getStates(): Observable<any[]> {
    return this.http.get<any[]>('assets/states.json');
  }

  ngAfterContentChecked(): void {
    this.model = this.customer;
    if (this.customer) {
      this.form.patchValue(this.customer);
    }
  }

  addCustomer() {
    if (this.form.valid) {
      this.model['Id'] = '';      
      this.model['created_at'] = this.global.dNow();
      this.model['updated_at'] = this.global.dNow();
      this.customerService.addCustomer(this.model).subscribe(() => {
          this.alertify.success('Customer Added');
      }, error => {
          this.alertify.error(error);
      });
    }
  }

}
