import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PaymentsFormComponent } from './payments-form/payments-form.component';
import { CardReaderService } from 'src/app/_services/cardreader.service';

@Component({
  selector: 'app-payments',
  templateUrl: './payments.component.html',
  styleUrls: ['./payments.component.scss']
})
export class PaymentsComponent implements OnInit {
  @Input() payments: any = [];
  @Input() settings: any;
  constructor(
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private cardService:  CardReaderService
  ) { }

  ngOnInit() {
    // console.log(this.payments)
    this.cardService.setDeviceID();
  }

  filterPayments(payments):any[]{   
    let newPayments:any = []
    if(payments != null && payments != undefined){
      newPayments =  payments.filter((p:any)=> p.voidtype_tx == null || p.voidtype_tx == undefined);
      return newPayments;
    }else{
      return [];
    }
  }

  editPayment(payment: any) {
    const initialState = {
      passpay: payment,
      passpayments: this.payments,
      settings: this.settings
    };
    this.bsModalRef = this.modalService.show(PaymentsFormComponent, {initialState,
      class: 'modal-lg'
    });
  }
}