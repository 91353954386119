import { Component, OnInit } from '@angular/core';
import { StockItemsService } from 'src/app/_services/stockitems.service';
import { take } from 'rxjs/operators/take';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-near-expiry',
  templateUrl: './near-expiry.component.html',
  styleUrls: ['./near-expiry.component.scss']
})
export class NearExpiryComponent implements OnInit {

  p: number = 1
  products:any = []
  expiringOption = 'nextWeek'
	operationInProgress = false;

  constructor(
    private stockItemsService: StockItemsService,
  ) { }

  ngOnInit(): void {
    this.getExpiringItems(this.expiringOption)
  }

  getExpiringItems(option:string) {
    if (this.operationInProgress) {
      return; 
    }

    this.operationInProgress = true;

    this.stockItemsService.getExpiringItems(option).subscribe((data)=>{
      //console.log(data);
      
      this.products = Object.values(data)
      //console.log(this.products);

      this.expiringOption = option
      
    })

    setTimeout(() => {
      this.operationInProgress = false;
    }, 1000); 
  }
}
