import { Component, OnInit } from '@angular/core';
import { StyleitemsService } from 'src/app/_services/styleitems.service';

@Component({
  selector: 'app-storefront',
  templateUrl: './storefront.component.html',
  styleUrls: ['./storefront.component.scss'],
  host: {
    '(window:resize)': 'onResize($event)',
    '(document:click)': 'onClick($event)'

  }
})
export class StorefrontComponent implements OnInit {
  show = true;
  mobile = false;
  constructor(private styleItemsService: StyleitemsService) { }

  ngOnInit() {
    var w = window.innerWidth;
    this.windowSize();
  }

  clearFilter() {
    this.styleItemsService.clearStyleItems.next(true);
  }

  onResize(event){
    event.target.innerWidth; // window width
    this.windowSize();
  }

  onClick(event) {
    
  }

  windowSize() {
    var w = window.innerWidth;
    if (this.mobile != true) {
      if (w <= 790) {
        this.mobile = true;
      }
    }
  }
}
