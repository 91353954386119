import { AfterContentChecked, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { TabsetComponent } from 'ngx-bootstrap/tabs';
import {  fromEvent, Subject } from 'rxjs';
import { skip, takeUntil } from 'rxjs/operators';
import { SizesIndex } from 'src/app/_resolvers/SizeSyno';
import { AuthService } from 'src/app/_services/auth.service';
import { CustomerService } from 'src/app/_services/customer.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { PurchaseOrderService } from 'src/app/_services/purchaseorder.service';
import { ReportingService } from 'src/app/_services/reporting.service';
import { ReportsService } from 'src/app/_services/reports.service';
import { SettingsService } from 'src/app/_services/settings.service';
import { UserService } from 'src/app/_services/user.service';
import { AlertifyService } from '../../../_services/alertify.service';
interface Notification{
  SOItemsID : string;
  reason: string;
  confirmed: boolean
} 

@Component({
  selector: 'app-salesorderitems-mobile',
  templateUrl: './salesorderitems-mobile.component.html',
  styleUrls: ['./salesorderitems-mobile.component.scss']
})


export class SalesorderitemsMobileComponent implements OnInit, AfterContentChecked {
  @Input() salesOrderItems: any = [];
  @Input() SOItemsToDelete: any = [];
  @Input() SOItemsToUpdate: any = [];
  @Output() SOItemsToUpdateChange = new EventEmitter<any>();
  salesOrderItemsOriginal: any = [];
  @Input() salesOrder: any = [];
  @Output() refreshDelivery = new EventEmitter<string>();
  @Output() showLIstItems = new EventEmitter<boolean>();

  discountItems: any = {}
  @Input() showCalculateTaxes: boolean = true;
  @Output() showCalculateTaxesChange = new EventEmitter<boolean>();
  
  @ViewChild('staticTabs', { static: false }) staticTabs?: TabsetComponent;

  @Input() showListItems:boolean  = false;
  deliveryInfo:any = [];
  checkDelivery:boolean = false;
  validation:boolean = true;
  storeSettings:any;
  @Input() canSelect: boolean = true;
  itemsDiffShipAddrs :any = []
  warnings: Notification[] = []
  notifications:any = { warnings: [], emailsent: [] }
  salesOrderItemsCopy= []
  firstCustomer: any
  totalItem:any;
  POselected :any= [];
  PObody :any;
  showPODetails: boolean = false;
  setVisible: boolean = false;
  Xtop: number = 0;
  Ytop:number = 0;
  realYtop:number = 0;
  realXtop:number = 0;
  MyPOIDSelected : any
  POItemsToShow: any = []
  todelete: any = []
  itemsPO: any = []
 

  SOItemsIDToChanDiscount = ''
  referralTypes = [
    'Referral Flyers',
    'Signs',
    'Feeder Memos',
    'Faculty Discount',
    'Hero Flyer',
    'District Generic Email',
    'Social Media',
    'Direct Mail',
    'NewsPaper Ad',
    'Other'
  ];
  itemModifying = {
    SOItemsID : ''
  };
  platform = '';
  showMobile = false;
  isIOS = false;
  showAddress = false;
  isAndroid = false;
  tabz:any = 'list'
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private alertify: AlertifyService,
    private global: GlobalsService,
    public bsModalRef: BsModalRef,
    public PurchaseOrderService: PurchaseOrderService,
    public userService: UserService,
    public settingService: SettingsService,
    private customerService:CustomerService,
    private authService:AuthService,
    private route: ActivatedRoute,
    private reportService:ReportsService) {
      setTimeout(() => {   
        let param = this.route.snapshot.params.id
        if(param != '' && param != null && param != undefined){    
        this.showListItems = true
        }   
      }, 200);
     }


   selectTab(tabId: number) {
    if (this.staticTabs?.tabs[tabId]) {
      this.staticTabs.tabs[tabId].active = true;
    }
  }

  changeshowListItems(a:any){
    this.showListItems = a 
    this.showAddress = false
    this.itemModifying = { SOItemsID: ''}
    this.showLIstItems.emit(a);
    
  }


  ngOnInit() {

  // asignar los warnings
  this.reportService.loadNotitemsMobile.subscribe((res:any)=>{
    if(this.salesOrder['notifications'] != null && this.salesOrder['notifications'] != "" && this.salesOrder['notifications'] != "[]"){
      this.notifications = JSON.parse(this.salesOrder['notifications']);
      if(JSON.parse(this.salesOrder['notifications']).warnings != undefined){
        this.warnings = JSON.parse(this.salesOrder['notifications']).warnings;   
        this.compareAddress(false)   
      }
    }
  })
    

  // console.log(this.salesOrderItems, this.salesOrder)
  if(this.salesOrderItems.length > 0){
      this.showListItems = false
      // this.salesOrderItemsCopy = JSON.parse(JSON.stringify(this.salesOrderItems)) 
      this.salesOrderItemsOriginal = JSON.parse(JSON.stringify(this.salesOrderItems)) 
  }
   
    this.platform = navigator.userAgent;
    if (this.platform.match(/Android/i)) {
      this.showMobile = true;
      this.isAndroid = true;
    }
    if (this.platform.match(/iPhone|iPad|iPod/i)) {
      this.showMobile = true;
      this.isIOS = true;

    }

    if (this.platform.includes('Mac')) {
      this.showMobile = false;

    }

    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
      this.isIOS = true;
    }

    if (this.platform.includes('Win')) {
      this.showMobile = false;

    }

    this.settingService.SettingsEvent$
    .pipe(takeUntil(this.destroy$))   
    .subscribe( data => {
      if(data[0] != undefined){
        this.storeSettings = data[0];
        let result = JSON.parse(this.storeSettings.deliveryInfo);
        if(result != undefined) this.deliveryInfo = result;
      }
      if( this.storeSettings == undefined) return;
      if(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc')){
        this.validation = false;
      }
      // console.log(this.validation)
      // console.log(this.deliveryInfo)
    }, error => {
      this.alertify.error(error);
    });

    this.firstCustomer = this.salesOrder['customerId'];
    // pipe(skip(1)).
    this.customerService.changeCustomer.pipe(takeUntil(this.destroy$), skip(1))   
    .subscribe(data => { 
      if(( data.customerId != NaN && data.customerId != '0' && data.customerId != this.firstCustomer) || (data.event == 'update')){
        this.compareAddress(true)
      }
    }, error => {
      this.alertify.error(error);
    });


    if(this.salesOrder['notifications'] != null && this.salesOrder['notifications'] != "" && this.salesOrder['notifications'] != "[]"){
      this.notifications = JSON.parse(this.salesOrder['notifications']);
      if(JSON.parse(this.salesOrder['notifications']).warnings != undefined){
        this.warnings = JSON.parse(this.salesOrder['notifications']).warnings;   
        this.compareAddress(false)   
      }
    }

    fromEvent(document.body, 'click').pipe(takeUntil(this.destroy$))  
    .subscribe((e:any) => {
      if(document.getElementById('clickbox') != null){
        if (document.getElementById('clickbox').contains(e.target)){
        
        } else{
          this.showPODetails = false
          this.setVisible = false
          this.itemsPO = []
        }
      }
      if(!this.showPODetails || document.getElementById('clickbox') == null){
        this.Xtop = e.pageX
        this.Ytop = e.pageY
        this.realYtop = e.pageY - 50
      }
    })

    fromEvent(document.body, 'touchstart').pipe(takeUntil(this.destroy$))  
    .subscribe((e:any) => {
      if(document.getElementById('clickbox') != null){
        if (document.getElementById('clickbox').contains(e.target)){
        
        } else{
          this.showPODetails = false
          this.setVisible = false
          this.itemsPO = []
        }
      }
      if(!this.showPODetails || document.getElementById('clickbox') == null){
        this.Xtop = e.pageX
        this.Ytop = e.pageY
        this.realYtop = e.pageY - 50
      }
    })
    this.refreshDiscounts()
  }

  refreshDiscounts(){
    this.salesOrderItems.filter(soit => soit.itemType != 'discount').forEach(element => {
      let cant = this.salesOrderItems.filter(item => item.noteExp == element.SOItemsID).reduce((acc, {priceNumUnit}) => acc + (priceNumUnit > 0 ? priceNumUnit : priceNumUnit*-1), 0)//.reduce((item, acc) => acc + item.priceNumUnit, 0)
      this.discountItems[element.SOItemsID] = cant
      // console.log(this.discountItems)  
    });
  }

  getDiscountsByItem(SOItemsID):number{
    let discountQuantity = this.salesOrderItems.filter(e => e.noteExp == SOItemsID)
    .reduce((acc, item) => acc + item.priceNumUnit,0);
    // console.log(discountQuantity)
    return discountQuantity;
  }

  confirmWarning(warn: Notification){
    if(warn.confirmed == false){
      warn.confirmed = true
    }
    
    // JUSTO ANTES DE MANDARLOS AL JSON DE SO, DEBO DE INCLUIR LOS EMAILS SEND 
    this.notifications.warnings = this.warnings;
    // console.log(this.warnings);    
    // console.log(this.notifications);   
    this.salesOrder['notifications'] = JSON.stringify(this.notifications);
  }

  checkItemsWarning(): boolean{
    this.notifications.warnings = this.warnings;
    this.salesOrder['notifications'] = JSON.stringify(this.notifications);
    if(!!this.warnings.find((w: Notification) => w.confirmed == false)){
      return true;
    }else{
      return false;
    }
  }

  compareAddress(changeCustomer?: boolean){
    this.salesOrderItems.filter(so => so['itemDelivery'] == 'Direct Shipment' && so['itemType'] != 'discount')
    .forEach(soItem => {

      if((soItem['deliveryAddress1'] == '' || soItem['deliveryAddress1'] == null )||
        (soItem['deliveryCity'] == '' || soItem['deliveryCity'] == null) ||
        (soItem['deliveryState'] == '' || soItem['deliveryState'] == null ) ||
        (soItem['deliveryZip'] == '' || soItem['deliveryZip'] == null))
      {
        this.copyFromShipping(soItem)
      }

      if((
      soItem['deliveryAddress1'] != this.salesOrder['deliveryAddress1'] ||
      soItem['deliveryAddress2'] != this.salesOrder['deliveryAddress2'] ||
      soItem['deliveryCity'] != this.salesOrder['deliveryCity'] ||
      soItem['deliveryState'] != this.salesOrder['deliveryState'] ||
      soItem['deliveryZip'] != this.salesOrder['deliveryZip']
      ) && (
        soItem['deliveryAddress1'] != this.salesOrder['billingAddress1'] ||
        soItem['deliveryAddress2'] != this.salesOrder['billingAddress2'] ||
        soItem['deliveryCity'] != this.salesOrder['city'] ||
        soItem['deliveryState'] != this.salesOrder['state'] ||
        soItem['deliveryZip'] != this.salesOrder['zip']
      ))
      {
        if(this.warnings.length > 0){
          if(!!this.warnings.find((w: Notification) => w.SOItemsID == soItem['SOItemsID']) == false){
            this.itemsDiffShipAddrs.push(soItem['SOItemsID']) 
            this.warnings.push({SOItemsID: soItem['SOItemsID'], confirmed: false, reason: 'differentAddress'})
          }else{
            if(changeCustomer){
              this.warnings.find((w: Notification) => w.SOItemsID == soItem['SOItemsID']).confirmed = false
            }
          }
        }else{
          this.warnings.push({SOItemsID: soItem['SOItemsID'], confirmed: false, reason: 'differentAddress'})
        }
      }else{
        if(this.warnings.length > 0){
          if(!!this.warnings.find((w: Notification) => w.SOItemsID == soItem['SOItemsID'])){
            this.warnings.find((w: Notification) => w.SOItemsID == soItem['SOItemsID']).confirmed = true
            
            // JUSTO ANTES DE MANDARLOS AL JSON DE SO, DEBO DE INCLUIR LOS EMAILS SEND 
            this.notifications.warnings = this.warnings;
            // console.log(this.warnings);    
            // console.log(this.notifications);   
            this.salesOrder['notifications'] = JSON.stringify(this.notifications)
          }
        }
        // let items = this.warnings
        // this.warnings = items.filter((e: Notification) => e.SOItemsID != soItem['SOItemsID']);
      }
      this.notifications.warnings = this.warnings;      
    });
    
    // JUSTO ANTES DE MANDARLOS AL JSON DE SO, DEBO DE INCLUIR LOS EMAILS SEND 
    this.notifications.warnings = this.warnings;
    // console.log(this.warnings);    
    // console.log(this.notifications);   
    this.salesOrder['notifications'] = JSON.stringify(this.notifications);
  }

  compareOneAddress(SOItemsID?: string){
    this.salesOrderItems.filter(so => so['itemDelivery'] == 'Direct Shipment' && so['SOItemsID'] == SOItemsID && so['itemType'] != 'discount')
    .forEach(soItem => {
      if((
      soItem['deliveryAddress1'] != this.salesOrder['deliveryAddress1'] ||
      soItem['deliveryAddress2'] != this.salesOrder['deliveryAddress2'] ||
      soItem['deliveryCity'] != this.salesOrder['deliveryCity'] ||
      soItem['deliveryState'] != this.salesOrder['deliveryState'] ||
      soItem['deliveryZip'] != this.salesOrder['deliveryZip']
      ) && (
        soItem['deliveryAddress1'] != this.salesOrder['billingAddress1'] ||
        soItem['deliveryAddress2'] != this.salesOrder['billingAddress2'] ||
        soItem['deliveryCity'] != this.salesOrder['city'] ||
        soItem['deliveryState'] != this.salesOrder['state'] ||
        soItem['deliveryZip'] != this.salesOrder['zip']
      ))
      {
        if(this.warnings.length > 0){
          if(!!this.warnings.find((w: Notification) => w.SOItemsID == soItem['SOItemsID']) == false){
            this.itemsDiffShipAddrs.push(soItem['SOItemsID']) 
            this.warnings.push({SOItemsID: soItem['SOItemsID'], confirmed: false, reason: 'differentAddress'})
          }else{
              this.warnings.find((w: Notification) => w.SOItemsID == soItem['SOItemsID']).confirmed = false
          }
        }else{
          this.warnings.push({SOItemsID: soItem['SOItemsID'], confirmed: false, reason: 'differentAddress'})
        }
      }else{
        if(this.warnings.length > 0){
          if(!!this.warnings.find((w: Notification) => w.SOItemsID == soItem['SOItemsID'])){
            this.warnings.find((w: Notification) => w.SOItemsID == soItem['SOItemsID']).confirmed = true

            // JUSTO ANTES DE MANDARLOS AL JSON DE SO, DEBO DE INCLUIR LOS EMAILS SEND 
            this.notifications.warnings = this.warnings;
            // console.log(this.warnings);    
            // console.log(this.notifications);   
            this.salesOrder['notifications'] = JSON.stringify(this.notifications)
          }
        }
        // let items = this.warnings
        // this.warnings = items.filter((e: Notification) => e.SOItemsID != soItem['SOItemsID']);
      }
    });
            

    // JUSTO ANTES DE MANDARLOS AL JSON DE SO, DEBO DE INCLUIR LOS EMAILS SEND  
    this.notifications.warnings = this.warnings;
    // console.log(this.warnings);    
    // console.log(this.notifications);    
    this.salesOrder['notifications'] = JSON.stringify(this.notifications);
  }

  ngAfterContentChecked(): void {
    this.salesOrderItems.forEach(soItem => {
      this.changeToNegative(soItem);
    });
  }
  //this method is no longer used
  addItem(soItem: any[]) {
    soItem['SOItemsID'] = this.global.guid();
    soItem['foreignSOID'] = this.salesOrder['SOID'];
    this.salesOrderItems.push(soItem);
  }

  selectDefaultDiscount(soitem:any){
    soitem.RecNo = null
  }  
  selectReferalDiscount(referral:any,soitem:any){
    soitem.styleName = referral
    if(soitem.styleName == 'Other'){
      soitem.RecNo = 0;
    }
  }

  showPOdetails(po, poid:any){
    this.itemsPO = []
    this.POselected = po
    
    this.PurchaseOrderService.searchAdvancePOdetails(poid)
    .toPromise()
    .then((res:any)=>{
      if(res != null && res != 0 && res.length > 0){
        this.itemsPO = res[1];
        this.PObody = res[0];
        this.showPODetails = true
        setTimeout(() => {
         
          if(this.showMobile){
            let boxleft =  document.getElementById(po.POID).offsetLeft as number;
            let boxtop =  document.getElementById(po.POID).offsetTop as number;
            this.Xtop = boxleft
            this.Ytop = boxtop 
          }else{
            let box =  document.getElementById('clickbox').clientHeight as number;
            let boxwidth =  document.getElementById(po.POID).clientWidth as number;
            let boxleft =  document.getElementById(po.POID).getBoundingClientRect().left as number;
            // console.log(this.Ytop,  window.scrollY)
            // console.log(boxwidth, boxleft, boxtop, this.Ytop)
            this.Xtop = boxwidth + boxleft + 35
            this.realXtop = boxwidth + boxleft+2
            this.Ytop = this.Ytop - (box/2)
          }

          this.setVisible=true
        }, 100);

      }
    })
    .catch((err)=>{console.log(err)});



}

  addDiscountItem(soItem: any[]) {

    const exitsitemdiscount  = this.salesOrderItems.find(obj => obj['noteExp'] === soItem['SOItemsID']);
    if(exitsitemdiscount != undefined){
      this.alertify.error("This item already has a discount");
      return;
    }

    const newSoItem = Object.assign({}, soItem);
    newSoItem['SOItemsID'] = this.global.guid();
    newSoItem['created_at'] = new Date();
    newSoItem['updated_at'] = new Date();
    newSoItem['foreignSOID'] = this.salesOrder['SOID'];
    newSoItem['manufacter'] = 'DISCOUNT ABOVE ITEM';
    newSoItem['styleName'] = this.salesOrder.soSource || '';
    newSoItem['itemType'] = 'discount';
    newSoItem['status'] = 'insert';
    newSoItem['priceNum'] = newSoItem['priceNumUnit'] * newSoItem['quantityNum'] * (-1);
    newSoItem['priceNumUnit'] = 0;
    newSoItem['noteExp'] = soItem['SOItemsID'];
    newSoItem['PDis'] = 0;
    newSoItem['PO'] = null;
    const toFind = this.salesOrderItems.findIndex(obj => obj['SOItemsID'] === soItem['SOItemsID']);
    this.salesOrderItems.splice(toFind + 1, 0, newSoItem);
    
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Add item Discount',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});

  }

  deleteItem(soItem) {

    let item = this.salesOrderItems.filter(item => 
      item?.SOItemsID == soItem
    );

    if(item[0].PO != null){
      this.alertify.alert("Cannot delete this item because it is already converted on PO#"+item[0].PO?.POUUID+" as "+item[0].PO?.mfg+" "+item[0].PO?.DeliveryType+". If you want to delete it please go to the Purchase Order #"+item[0].PO?.POUUID+"  and delete the item there first", 
      ()=>{})
    }else{
      this.delete(soItem)
    }
    this.refreshDiscounts() 

  }

  delete(soItem){
    const toDelete = this.salesOrderItems.findIndex(obj => obj['SOItemsID'] === soItem);
    this.SOItemsToDelete.push(this.salesOrderItems[toDelete]);
    
    const filetereddiscounts = this.salesOrderItems.filter(obj => obj['noteExp'] === soItem);
    
    filetereddiscounts.forEach(element => {
      const td = this.salesOrderItems.findIndex(obj => obj['SOItemsID'] === element['SOItemsID']);
      this.SOItemsToDelete.push(this.salesOrderItems[td]);
      this.salesOrderItems.splice(td, 1 );
    });

    this.salesOrderItems.splice(toDelete, 1 );
  }
  
 

  onQtyChange(quantity: number, soItem: any, salesPrice: number  ) { 
    
    //validation number
    if(quantity > 100){
      quantity = 100;
      this.alertify.error('The max qty is 100')
    }else if(salesPrice > 30000){
      salesPrice = 30000;
      this.alertify.error('The max sales price is 30000')
    }else if(salesPrice < 0){
      salesPrice = 0;
      this.alertify.error("Sales price can't be negative")      
    }
      
      soItem['quantityNum'] = quantity;
      if (soItem['itemType'] === 'discount') {
        soItem['quantityNum'] = 1;
        soItem['PDis'] = 100;
        if (soItem['priceNumUnit'] > 0) {
          salesPrice = soItem['priceNumUnit'] * -1;
          
    this.onPDisChange(quantity,soItem,salesPrice); 
        }
      }
      soItem['priceNumUnit'] = salesPrice;    
      this.totalItem = soItem['priceNumUnit'] * soItem['quantityNum'];


      this.salesOrderItems.forEach(item => {
        if(item.manufacter == 'DISCOUNT ABOVE ITEM' && item.storeProductId == soItem['storeProductId']){
          item['priceNum'] = soItem['priceNumUnit'] * soItem['quantityNum'] * (-1);

          item['priceNumUnit'] = this.global.pNumber(item['priceNum'] * item['PDis'] / 100) * -1;
          // console.log(item['priceNum'] ,item['PDis'])
        }
      });
     // this.checkTotalsForDiscounts(soItem);
     this.refreshDiscounts() 

    }

    copyItemsToOriginal(soItems){
      if(this.salesOrderItems.length > 0){
        this.salesOrderItemsOriginal = JSON.parse(JSON.stringify(soItems)) 
      }
    }

    copyToOriginal(itemsToWarn:any){
      this.salesOrderItemsOriginal = JSON.parse(JSON.stringify(this.salesOrderItems))
      if(itemsToWarn?.length > 0){
        let msg = '';
        itemsToWarn.forEach(itemwarn => {
          const indexitem = this.salesOrderItems.findIndex(obj => obj['SOItemsID'] === itemwarn?.SOItemsID);
          if(itemwarn?.newStatus == 'NTO' || itemwarn?.newStatus == 'Direct Shipment'){
            msg += "- "+this.salesOrderItems[indexitem]['name']+" was removed from PO "+itemwarn?.oldStatus+" and now it is included on PO# "+itemwarn?.PO?.POUUID+" "+ itemwarn?.PO?.DeliveryType+" <br>"
          }else if(itemwarn?.newStatus == 'In Stock' || itemwarn?.newStatus == 'Taken'){
            msg += "- "+this.salesOrderItems[indexitem]['name']+" was removed from PO "+itemwarn?.oldStatus+" and now its Inventory Status is "+itemwarn?.newStatus+" <br>"
          }
        });
        this.alertify.alert(msg, ()=>{})
      }
    }

    onPDisChange(quantity: number, soItem: any, salesPrice: number  ) {

        if (soItem['PDis'] < 0 ) {
          soItem['PDis'] = 0.01;
          this.alertify.error("Discount can't be negative or 0");
        }else if(soItem['PDis'] > 100){  
          soItem['PDis'] = 100;      
          this.alertify.error("Discount can't be greater than 100");
        }

        if (soItem['itemType'] === 'discount') {  
          if(soItem['PDis'] <= 100 && soItem['PDis'] > 0){
            salesPrice = this.global.pNumber(soItem['priceNum'] * soItem['PDis'] / 100) * -1;
          }
        }

        soItem['priceNumUnit'] = salesPrice;
        this.refreshDiscounts() 
    }

    onValueDiscountChange(quantity: number, soItem: any, salesPrice: number  ) {
      if (soItem['itemType'] === 'discount') {   

        var priceNum
        if(soItem['manufacter'] ==  'DISCOUNT ABOVE ITEM' ){
          priceNum = (soItem['priceNum'] * -1)
        }else{
          priceNum = soItem['priceNum'];
        }
        if( priceNum >= (soItem['priceNumUnit'] * -1)){
          salesPrice = this.global.pNumber(priceNum * soItem['PDis'] / 100) * -1;
          salesPrice = this.global.pNumber((soItem['priceNumUnit'] / priceNum)  * 100) * -1;
          soItem['PDis'] = this.global.pNumber((((soItem['priceNumUnit']/(priceNum))*100)*-1));
        }else{
          this.alertify.error('discount not valid');
          soItem['priceNumUnit'] = priceNum;
          soItem['PDis'] = 100;
        }
      }       
      this.refreshDiscounts() 
    }

    checkTotalsForDiscounts(soItem) {
      this.salesOrderItems.forEach(element => {
        if (element['noteExp'] === soItem['SOItemsID']) {
          element['priceNum'] = soItem['priceNumUnit'];
          this.onPDisChange(0, element, 0);
        }
      });
    }

    changeToNegative(soItem: any) {
      let salesPrice = soItem['priceNumUnit'];
      if (soItem['itemType'] === 'discount') {
        soItem['quantityNum'] = 1;
        if (soItem['priceNumUnit'] > 0) {
          salesPrice = soItem['priceNumUnit'] * -1;
        }
        soItem['priceNumUnit'] = salesPrice;
      }
      // if(soItem['status'] != 'insert'){
      //   soItem['status'] = 'fromDB'
      // }
    }

  addQty(soItem: any) {
      soItem['quantityNum'] = Number(soItem['quantityNum']) + 1;
  }

  showDs(soItem: any) {
    this.showAddress = true
    this.itemModifying = soItem
  }

  checkStatus(){
    this.checkDelivery = true  
  }

   copyFromShipping(soItem) {
    soItem['deliveryAttn'] = this.salesOrder['fName'] + ' ' + this.salesOrder['lName'];
    soItem['deliveryPhone'] = this.salesOrder['phone3'];
    soItem['deliveryEmail'] = this.salesOrder['deliveryEmail'];
    soItem['deliveryAddress1'] = this.salesOrder['deliveryAddress1'];
    soItem['deliveryAddress2'] = this.salesOrder['deliveryAddress2'];
    soItem['deliveryCity'] = this.salesOrder['deliveryCity'];
    soItem['deliveryState'] = this.salesOrder['deliveryState'];
    soItem['deliveryZip'] = this.salesOrder['deliveryZip'];
    //this.form.patchValue(this.item);
    
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Delivery from Shipping',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$)).subscribe(a => {});
  }

  SynoTypes(style:string) : string{
    let response = ''

    if( (SizesIndex['ALL SIZES'].toLowerCase()).includes(style.toLowerCase())) {
      response = SizesIndex['ALL SIZES']
    }
    else if( (SizesIndex.TWIN.toLowerCase()).includes(style.toLowerCase())) {
      response = SizesIndex.TWIN
    }
    else if( (SizesIndex["TWIN XL"].toLowerCase()).includes(style.toLowerCase())) {
      response = SizesIndex["TWIN XL"]
    }
    else if( (SizesIndex.FULL.toLowerCase()).includes(style.toLowerCase())) {
      response = SizesIndex.FULL
    }
    else if( (SizesIndex.QUEEN.toLowerCase()).includes(style.toLowerCase())){ 
      response = SizesIndex.QUEEN
    }
    else if( (SizesIndex.KING.toLowerCase()).includes(style.toLowerCase())) {
      response = SizesIndex.KING
    }
    else if( (SizesIndex["CAL KING"].toLowerCase()).includes(style.toLowerCase())){ 
      response = SizesIndex["CAL KING"]
    }

    return response;
  }

  closeAddDefault(e:any){    
    
    this.showAddress = false
    this.itemModifying = {
      SOItemsID : ''
    };
  }

  updateAddress(data:any){
    let item = data.item
    
    let counter = 0
    if(data.change){
      this.addDeliveryAuto(item, 'Direct Shipment', true);
    }
    //  this.compareOneAddress(item?.SOItemsID)
     this.itemModifying = { SOItemsID: ''}
  }

  addDeliveryAuto(item, type, avoid?: boolean){

    // console.log(item);

    // Count how many direct shipment or nto/stock exists

    let direct = 0
    let nto = 0
    if(this.salesOrder.deliveryItems != undefined){
      // console.log(this.salesOrder.deliveryItems)
      this.salesOrder.deliveryItems.forEach((element:any) => {
        // console.log(element.deliveryNotes)
        if((element.deliveryNotes == null ? '' : element.deliveryNotes ).includes('Direct Shipment')){
          // console.log('conta')
          direct++
        }
        if((element.deliveryNotes == null ? '' : element.deliveryNotes ).includes('Delivery Event')){
          nto++
        }
      });
    }
    //
    if(avoid == true){
      direct = 0
    }



    // check all the default delivery costs
    this.deliveryInfo.forEach(deli => {
      // set the synonim

      let sino = this.SynoTypes(deli.size)

      let allSizes = false
      if(sino.includes('ALL SIZES')){
        // console.log('ALL SIZES')
        // If size in ALL SIZES skips sizes and just compare product
        if(item?.Type?.trim()?.toLowerCase() == deli?.product?.trim()?.toLowerCase()){
  
  
          if(type == 'Direct Shipment' && direct == 0 && deli.method.trim() == 'Direct Shipment'){
            let delivery ={
              Type : "Direct Shipment",
              deliveryAmountNum :  deli.price,
              deliveryCode : "",
              deliveryId : this.global.guid(),
              deliveryNotes : "Direct Shipment",
              foreignSOID : this.salesOrder['SOID'],
              pretax : deli.taxable == true ? 1 : 0,
              status : "insert",
            }
            if(this.salesOrder.deliveryItems == undefined){
              this.salesOrder.deliveryItems = []
              this.salesOrder.deliveryItems.push(delivery)
            }else{
              this.salesOrder.deliveryItems.push(delivery)
            }
  
  
          } else if((type == 'NTO' || type == 'In Stock' )&& nto == 0  && deli.method == 'Delivery'){
            let delivery ={
              Type : "Delivery",
              deliveryAmountNum : deli.price,
              deliveryCode : "",
              deliveryId : this.global.guid(),
              deliveryNotes : "Delivery Event",
              foreignSOID : this.salesOrder['SOID'],
              pretax : deli.taxable == true ? 1 : 0,
              status : "insert",
            }
            if(this.salesOrder.deliveryItems == undefined){
              this.salesOrder.deliveryItems = []
              this.salesOrder.deliveryItems.push(delivery)
            }else{
              this.salesOrder.deliveryItems.push(delivery)
            }
  
          }
      
          this.refreshDelivery.next('hola')
        }

      }

      if(sino.toLowerCase().includes(item?.sizeName?.trim().toLowerCase()) && item?.Type?.trim().toLowerCase() == deli?.product?.trim().toLowerCase() ){
        // console.log('aplica')


        if(type == 'Direct Shipment' && direct == 0 && deli.method.trim() == 'Direct Shipment'){
          let delivery ={
            Type : "Direct Shipment",
            deliveryAmountNum : deli.price,
            deliveryCode : "",
            deliveryId : this.global.guid(),
            deliveryNotes : " Direct Shipment",
            foreignSOID : this.salesOrder['SOID'],
            pretax : deli.taxable == true ? 1 : 0,
            status : "insert",
          }
          if(this.salesOrder.deliveryItems == undefined){
            this.salesOrder.deliveryItems = []
            this.salesOrder.deliveryItems.push(delivery)
          }else{
            this.salesOrder.deliveryItems.push(delivery)
          }


        } else if((type == 'NTO' || type == 'In Stock' )&& nto == 0  && deli.method == 'Delivery'){
          let delivery ={
            Type : "Delivery",
            deliveryAmountNum :  deli.price,
            deliveryCode : "",
            deliveryId : this.global.guid(),
            deliveryNotes : " Delivery Event ",
            foreignSOID : this.salesOrder['SOID'],
            pretax : deli.taxable == true ? 1 : 0,
            status : "insert",
          }
          if(this.salesOrder.deliveryItems == undefined){
            this.salesOrder.deliveryItems = []
            this.salesOrder.deliveryItems.push(delivery)
          }else{
            this.salesOrder.deliveryItems.push(delivery)
          }

        }
    
        this.refreshDelivery.next('hola')
      }
    });



    this.refreshDelivery.next('hola')


  }

  changeToDs(soItem) {
    this.showCalculateTaxesChange.emit(true)

   
    let originalItem = this.salesOrderItemsOriginal.filter(item => 
      item?.SOItemsID == soItem?.SOItemsID
    );
    // if this sales order item has been converted
    if(soItem?.PO != null){
      
      // We will use 3 arrays with items: the original, the copy and the actual changing
      // First Check if the item turn back to the original status
      if((soItem.itemDelivery == originalItem[0].itemDelivery) && soItem?.PO?.UploadStatus == 'Pending'){

        // let itemstochange = JSON.parse(JSON.stringify(this.SOItemsToUpdate))
        this.SOItemsToUpdate = this.SOItemsToUpdate.filter(e => e.SOItemsID != soItem?.SOItemsID);

        // this.salesOrderItemsCopy = JSON.parse(JSON.stringify(this.salesOrderItems))
        this.updateInventoryStatus(soItem)

        // console.log(this.SOItemsToUpdate)
      }
      // then If change inventory status and is Pending
      else if((soItem.itemDelivery != originalItem[0].itemDelivery) && soItem?.PO?.UploadStatus == 'Pending'){
        let msg = soItem?.name+" is already converted in PO#"+soItem?.PO?.POUUID+" as "+soItem?.PO?.mfg+" "+soItem?.PO?.DeliveryType+".<br> Do you actually want to change the inventory status to "+soItem.itemDelivery+"? <br> Note: This change would take effect until you save this sales order";
        this.alertify.confirm(msg, 
        ()=>{
          let checkifExists = !!this.SOItemsToUpdate.find(e => e.SOItemsID == soItem?.SOItemsID);
          // console.log(!!this.SOItemsToUpdate.find(e => e.SOItemsID == soItem?.SOItemsID))
          if(checkifExists){
            let last = JSON.parse(JSON.stringify(this.salesOrderItems))
            this.SOItemsToUpdate.forEach(e => {
                if(e.SOItemsID == soItem?.SOItemsID){
                  e.oldStatus = originalItem[0].itemDelivery
                  e.newStatus =  soItem.itemDelivery  
                }
            });
            // console.log(this.SOItemsToUpdate)
          }else{
            this.SOItemsToUpdate.push({POITID: soItem?.PO?.POITID, SOItemsID: soItem?.SOItemsID, quantityNum :originalItem[0].quantityNum, oldStatus: originalItem[0].itemDelivery, newStatus:  soItem.itemDelivery});
          }

          // this.salesOrderItemsCopy = JSON.parse(JSON.stringify(this.salesOrderItems))
          this.updateInventoryStatus(soItem)

          // console.log(this.SOItemsToUpdate)
        }, 
        ()=>{
          soItem.itemDelivery = originalItem[0].itemDelivery
        })
      }else if((soItem.itemDelivery != originalItem[0].itemDelivery) && soItem?.PO?.UploadStatus == 'Uploaded'){
        let msg = soItem?.name+" is already converted in PO#"+soItem?.PO?.POUUID+" and Uploaded as "+soItem?.PO?.DeliveryType+".<br> Do you actually want to change the inventory status to "+soItem.itemDelivery+"? <br> Note: This change would take effect until you save this sales order";
        this.alertify.confirm(msg, 
        ()=>{

        }, 
        ()=>{
          soItem.itemDelivery = originalItem[0].itemDelivery
        })
      }

      this.SOItemsToUpdateChange.emit(this.SOItemsToUpdate)

    }else{
      this.updateInventoryStatus(soItem)
    }

  }

  updateInventoryStatus(soItem){
    if (soItem['itemDelivery'] === 'Direct Shipment') {
        this.copyFromShipping(soItem)
        if(soItem['itemDelivery'] === 'Direct Shipment'){
          this.addDeliveryAuto(soItem, 'Direct Shipment', false)
        }
    }

    if(soItem['itemDelivery'] === 'NTO' || soItem['itemDelivery'] == 'Taken' || soItem['itemDelivery'] == 'In Stock'){
      this.showAddress = false
      this.itemModifying = { SOItemsID: ''}

      if(this.warnings.length > 0){
        if(!!this.warnings.find((w: Notification) => w.SOItemsID == soItem['SOItemsID'])){
          this.warnings.find((w: Notification) => w.SOItemsID == soItem['SOItemsID']).confirmed = true
          // this.salesOrder['notifications'] = JSON.stringify(this.warnings);
        }
      }

      this.salesOrderItems.forEach((item:any) => {
        if((soItem['itemDelivery'] === 'NTO' || soItem['itemDelivery'] === 'In Stock') && this.salesOrder.deliveryRequest == 'Delivery'){
          this.addDeliveryAuto(soItem, 'NTO', false)
        }
        if(item['SOItemsID'] == soItem['SOItemsID']){
          item['deliveryAttn'] = null;
          item['deliveryAddress1'] = null;
          item['deliveryAddress2'] = null;
          item['deliveryCity'] = null;
          item['deliveryEmail'] = null;
          item['deliveryPhone'] = null;
          item['deliveryState'] = null;
          item['deliveryZip'] = null;
        }
      });

     

    }
  }

}


