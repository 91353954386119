import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';

@Injectable({
  providedIn: 'root'
})


export class CustomerlistGuard implements CanActivate {
  
  constructor(private authService: AuthService, private alertify: AlertifyService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      
      // if (this.authService.getRole() != "Sales Person") {
      //   return true;
      // } else {
      //   this.alertify.error("You can't enter this area");
      //   return false;
      // }

      return true;
    
  }
  
}
