import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import moment from 'moment';
import { Router, ActivatedRoute } from '@angular/router';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import * as _ from 'lodash';

declare let SetDevice: any;
declare let SetDeviceID: any;
declare let ReadDevice: any;
declare let DeactivateDevice: any;
declare var Stimulsoft: any;

@Injectable({
  providedIn: 'root'
})
export class GlobalsService {
  private title = new BehaviorSubject<String>('');
   optionTrashFL = new BehaviorSubject<String>('default');
   file1 = new BehaviorSubject<boolean>(false);
   file2 = new BehaviorSubject<boolean>(false);
   reportloadding = new BehaviorSubject<boolean>(false);
  private title$ = this.title.asObservable();
  savePaymentForce = new BehaviorSubject<String>('');
  savePaymentFromMobile = new BehaviorSubject<String>('');
  public savePaymentForce$ = this.savePaymentForce.asObservable();


constructor(
  private bsModalRef: BsModalRef,
  private modalService: BsModalService,
  private _ngZone:NgZone
) { }

public loadScript() :any {

  if(Stimulsoft == ''){

      return new Promise((resolve, reject) => {
        try {        
          this.reportloadding.next(true)
          const container = document.head || document.body

          const el = document.createElement('script')    
          el.type = 'text/javascript'
          el.async = true
          el.src = 'assets/stimulsoft.reports.pack.js'
    
          el.addEventListener('load', () => {
           
            const el2 = document.createElement('script')
    
            el2.type = 'text/javascript';
            el2.async = true;
            el2.src = 'assets/stimulsoft.viewer.pack.js';
            Stimulsoft.Base.StiLicense.key =this.stimulSoft;
      
            el2.addEventListener('load', () => {
             this.reportloadding.next(false)
              resolve(true)
            })        
            container.appendChild(el2)

          })       
          container.appendChild(el)

        } catch (err) {
          reject(err)
        }
      }) 

  }else{
    return true; 
  }
 
}



setTitle(title: String) {
  this.title.next(title);
}

getTitle(): Observable<String> {
  return this.title$;
}

public guid() {
  return this.s4() + this.s4() + '-' + this.s4() + '-' + this.s4() + '-' +
  this.s4() + '-' + this.s4() + this.s4() + this.s4();
}
public guid2() {
  return '@#' + this.s4() + this.s4() + this.s4().substring(2);
}

public s10() {

}

public dNow() {
const localTime  = moment.utc(moment.utc().format('YYYY/MM/DD HH:mm:ss')).toDate();
const localTimedt = moment(localTime.toString()).format('YYYY/MM/DD HH:mm:ss');
return localTimedt;
}

public s4() {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

public pNumber(number) {

  let result = Math.round(number * 100);
  let decimal = result.toString().slice(-2);
  let nondecimal = result.toString().slice(0,-2);
  //return Number(nondecimal + '.' + decimal);
  return _.round(number, 2);
}

public pNumberDecimal(number) {
  let rounded = String(_.round(number, 2))
  let result = rounded.split(".",3);
  if(result.length ==  2){
    if(result[1].length == 1){
      rounded = rounded+'0';
    }
  }else if(result.length == 1){
    rounded = rounded +'.00';
  }
  return rounded;
}

public pNumberInt(number) {

  let result = Math.round(number * 100);
  let decimal = result.toString().slice(-2);
  let nondecimal = result.toString().slice(0,-2);
  //return Number(nondecimal + '.' + decimal);
  return _.round(number, 0);
}



  public  getOS() {
    return window.navigator.platform.toString();
  }

  public getDate(dateT) {
    const localTimedt = moment(dateT).format('MM/DD/YYYY');
    return localTimedt;
    }

    public changeMySQLDate(date) {
      var dateTime = new Date(date);
      var datestring = moment(dateTime).format('MM/DD/YYYY');
      return datestring;
    }
    getLast7Days() {
    return moment().subtract(7, 'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
    }

    public isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }

    public sortByKey(array, key) {
      return array.sort(function(a, b) {
          const x = a[key]; const y = b[key];
          return ((x < y) ? -1 : ((x > y) ? 1 : 0));
      });
  }

  getTimezoneOffsetString(date: Date): string {
    const timezoneOffset = date.getTimezoneOffset();
    const hoursOffset = String(
      Math.floor(Math.abs(timezoneOffset / 60))
    ).padStart(2, '0');
    const minutesOffset = String(Math.abs(timezoneOffset % 60)).padEnd(2, '0');
    const direction = timezoneOffset > 0 ? '-' : '+';
    return `T00:00:00${direction}${hoursOffset}:${minutesOffset}`;
  }

  getField(key: string, fields: FormlyFieldConfig[]): FormlyFieldConfig {
    for (let i = 0, len = fields.length; i < len; i++) {
      const f = fields[i];
      if (f.key === key) {
        return f;
      }
      
      if (f.fieldGroup && !f.key) {
        const cf = this.getField(key, f.fieldGroup);
        if (cf) {
          return cf;
        }
      }
    }
  }

  closeLastModal() {
    this.modalService.hide(this.modalService.getModalsCount());
  }
  
  isMobile()
  {
    var w = window.innerWidth;

      if (w <= 790) {
          return true;
      } 
        return false;
  }


  b64EncodeUnicode(str) {
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function(match, p1) {
        return String.fromCharCode(parseInt(p1, 16))
    }));
  }

  b64DecodeUnicode(str) {
    return decodeURIComponent(Array.prototype.map.call(atob(str), function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''));
  }


  stimulSoft = "6vJhGtLLLz2GNviWmUTrhSqnOItdDwjBylQzQcAOiHlBxqRvofQ95y8ny9haIKXGs2Kz687P+mJVyTklBd65WO9fNE" + 
  "3ZQDvUeFffdM7jeNWWKX+naZwBYE5W5PlBNV1G8vbeGyo7q0jwonyHYihecjd7cE8MNXgVUSoO1518xab/vO2WFXOS" + 
  "vB5V6DIYD3TBvwD9RKKbuGUdeUSu3rGK0FrwbJtmZb0KdslZqfoCbHn2b5U4v/7X+5z2Z6ciQT1qu/nxooMotcmj+K" + 
  "cGcZnZbigsqgOu1VW/qmBDq6Vwr9a1Xve8unNvgShAvg7EJGbbUCe1zlSNDYUkAfSswBPrFmrc2M81PtmsJO11VO21" + 
  "+WNMkWbRR/MHqWdyGsIa4guyvpWDAd3tRky0YxT2vaUqsnpz6bRU4UOzv5VntOTscoTp8hHlPaX2Z305yJqTxG/rCu" + 
  "J9yycvHI/VVhVdcAW2PsgQ3fkxepefTcW7jc6aZB5+hZBW7LWJQ2/xII7BwZU4fT+FW8KqLy/KzkvMsTyMwxOt6DpQ" + 
  "VeNkcXFQ7MFknIXCZH8WgBhm6p9PANh3GT+5";


}
