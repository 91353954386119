  <div *ngIf="urlf">


  <div *ngIf="
    !urlf.includes('salesorder') 
  && !urlf.includes('customer')
  && !urlf.includes('welcome') 
  && !urlf.includes('events') 
  && !urlf.includes('sponsors') 
  && !urlf.includes('productType') 
  && !urlf.includes('stockitems')
  && !urlf.includes('storefront') && !urlf.includes('reports') 
  && !urlf.includes('purchaseorder') && !urlf.includes('dash') && !urlf.includes('storeconfig') && !urlf.includes('help') && !urlf.includes('mobilestore') && !urlf.includes('stockmobile') && !urlf.includes('eventcalen')">
<div class="navbar m-0 pl-4 bg-white" *ngIf="urlf">
    <ul class="navbar-nav">
        <li class="nav-item">

  <a class="nav-link" [ngSwitch]="urlf.length>0 ">
    <!-- <i class="fa fa-list  d-none mr-1"></i> -->
    <i class="mr-1"></i>
      <h6 class="d-inline">
        <span *ngSwitchCase="urlf==='salesorder'">SALES ORDER</span>
        <span class="d-inline" *ngSwitchCase="urlf==='salesorder/add'">NEW SALES ORDER</span>
        <span class="d-inline" *ngSwitchCase="urlf.includes('salesorder/') && urlf!=='salesorder/add'">EDIT SALES ORDER</span>
        <span *ngSwitchCase="urlf==='events'">EVENTS</span>
        <span *ngSwitchCase="urlf==='storeusers'">STORE USERS</span>
        <!-- <span *ngSwitchCase="urlf==='customers'">CUSTOMERS</span> -->
        <span *ngSwitchCase="urlf==='storefront'">STORE CATALOG</span>
        <span *ngSwitchCase="urlf==='stockitems'">ITEMS IN STOCK</span>
        <span *ngSwitchCase="urlf==='storeconfig'">SETTINGS</span>
        <span *ngSwitchCase="urlf==='sponsors'">SPONSORS</span>
        <!-- <span class="text-center text-bold m-1" *ngSwitchCase="urlf==='help'">How can we help?</span> -->
        <!-- <span *ngSwitchCase="urlf==='reports'">REPORTS</span> -->
      </h6>
  </a>
  </li>
</ul>
</div>
</div>
</div>
