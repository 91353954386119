import { Component, DoCheck, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs/Subscription';
import { Customer } from '../../../_modules/customers';
import { Pagination } from '../../../_modules/pagination';
import { AlertifyService } from '../../../_services/alertify.service';
import { AuthService } from '../../../_services/auth.service';
import { CustomerService } from '../../../_services/customer.service';
import { SalesorderSearchComponent } from '../../salesorder/salesorder-search/salesorder-search.component';
import { CustomerCardComponent } from '../customer-card/customer-card.component';
import { CustomerFormComponent } from '../customer-form/customer-form.component';
import { GlobalsService } from 'src/app/_services/globals.service';
import 'rxjs/add/operator/takeUntil';
import { EventsService } from 'src/app/_services/events.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { of, Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { CustomerCommentsComponent } from '../customer-comments/customer-comments.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss']
})
export class CustomersListComponent implements OnInit, OnDestroy,DoCheck {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() customer: Customer;
  @Input() deliveryRequest: any;
  @Input() searchMode = false;
  @Input() itemsPerPage = 9;
  @Input() showPagination = true;
  @Input() showHeader = true;
  @Input() viewType = 'Card';
  @Input() fromSalesOrder = false;
  @Input() salesOrderToSave:any;
  obs$:any
  customers: any[];
  customerEdit: Customer;
  customerForm: FormGroup;
  pagination: Pagination;
  editMode: boolean = false;
  addMode = false;
  editId: number;
  paginationMode = false;
  fromList = false;
  event: any;
  role = this.authService.decodeToken('role').role
  hcustomersrecords:any = 'auto'
  

  counto = 0;
  maxsize = 10;
  salesorders: any = [];
  @ViewChild('editForm', {static: false}) editForm: NgForm;
  subscriptions: Subscription[] = [];
  //validation search
  // searchCustomer = new FormControl('',Validators.pattern('[a-zA-Z0-9- ]*'));
  searchCustomer = new FormControl('',Validators.pattern('[a-zA-Z0-9-@. ]*'));
  specialcha:boolean = false;
  inputSearchCss:string = "main-navbar-content-page-search" 
  inputSearchCssmobile:string = "main-navbar-content-page-search search-mobile";  
  yo:any
  platform = '';
  showMobile = false;
  isIOS = false;
  isAndroid = false;
  constructor(
    private alertify: AlertifyService,
    private customerService: CustomerService,
    private route: ActivatedRoute, 
    private router: Router,
    private modalService: BsModalService,
    private eventService: EventsService,
    private salesService: SalesOrderService,
    private globals: GlobalsService,
    public bsModalRef: BsModalRef,
    private authService: AuthService) { }
    

  ngOnInit() {

    
   this.platform = navigator.userAgent;
   if (this.platform.match(/Android/i)) {
     this.showMobile = true;
     this.isAndroid = true;
   }
   if (this.platform.match(/iPhone|iPad|iPod/i)) {
     this.showMobile = true;
     this.isIOS = true;

   }

   if (this.platform.includes('Mac')) {
     this.showMobile = false;

   }

   if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
    this.showMobile = true;
    this.isIOS = true;
  }

   if (this.platform.includes('Win')) {
     this.showMobile = false;

   }


    // if(this.role == 'Sales Person' || this.role == 'Cashier'){  
    //   document.getElementById('sidebar').style.display = "none"; 
    //   document.getElementById('mainWithSideBar').className = "body-page-content-SalesPerson";
    // }
   
    if(!this.searchMode){
    }

    if(!this.fromSalesOrder){
      this.viewType = 'Card'
    }

    this.pagination =  {
        totalItems: 0,
        totalPages: 10,
        currentPage: 1,
        itemsPerPage: this.itemsPerPage
      };

      if (this.router.url.includes('customers') === true ) {
        this.fromList=true;
      }
      if(!this.fromList){
        this.viewType = 'List'
      }
      
      if (this.globals.isMobile()) {
        this.maxsize = 4;
      }

   
      this.loadCustomersLar();
      
      this.customerService.reloadCustomerList.subscribe(
        (res:any)=>{
          if(res != ''){
            this.loadCustomersLar();            
            this.customerService.reloadCustomerList.next('')
          }
        }
      );

  }

  salesOrderByCustomer(id:any){
    this.salesService.salesOrderByCustomer(id)
    .pipe(takeUntil(this.destroy$))  
     .subscribe(
       (res: any) => {        
          this.viewSalesOrder(res);      
     }, error => {
       this.alertify.error(error);
     });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // this.unsubscribe();
  }
  
  ngDoCheck() { 
    if(!this.fromSalesOrder){
      let pag = document.getElementById("pagcustomers");
      let hscrren = document.getElementById("bodymainhtml");

      if(this.showMobile){
        let hnavmob = document.getElementById("navmobilecustomer");
        this.hcustomersrecords = (hscrren.clientHeight - 40 - pag.clientHeight - hnavmob.clientHeight  )
      }else{
        let hnav1 = document.getElementById("navdesktop");
        this.hcustomersrecords = (hscrren.clientHeight - 40 - hnav1.clientHeight - pag.clientHeight )
      } 
    }
 

  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  selectCustomer(id: any,customer:any) {

    if(this.salesOrderToSave != undefined){
         if(this.salesOrderToSave['soItems'] && this.salesOrderToSave['soItems'].length > 0){
      this.salesOrderToSave['soItems'].forEach((item:any) =>{           
        if(item.itemDelivery == 'Direct Shipment' && 
        ((item.deliveryAttn == null || item.deliveryAttn == "" || item.deliveryAttn == " " || item.deliveryAttn == 'null null') && 
        (item.deliveryAddress1 == null || item.deliveryAddress1 == "")  
        && (item.deliveryCity == null || item.deliveryCity == "") 
        && (item.deliveryPhone == "" || item.deliveryPhone == null) 
        && (item.deliveryState == null || item.deliveryState == "") )){
          if(this.salesOrderToSave['fName'] == null){this.salesOrderToSave['fName'] = ''}
          if(this.salesOrderToSave['lName'] == null){this.salesOrderToSave['lName'] = ''}
          item.deliveryAttn = customer['fName']+' '+customer['lName']  
          item.deliveryAddress1 = customer['deliveryAddress1']
          item.deliveryAddress2 = customer['deliveryAddress2']
          item.deliveryPhone = customer['phone3']
          item.deliveryEmail = customer['deliveryEmail']
          item.deliveryCity = customer['deliveryCity']
          item.deliveryState = customer['deliveryState']
          item.deliveryZip = customer['deliveryZip']
        }
      })
    } 
    }
    
      this.customerService.salesOrderCustomer.next(id);
      this.bsModalRef.hide();
  }
 

  addCustomer() {
    this.customerService.editCustomer.next(0);
    const initialState = {
      fromSalesOrder: this.searchMode,
      deliveryRequest: this.deliveryRequest,
      salesOrderToSave :this.salesOrderToSave

    };
    this.bsModalRef.hide();
    this.bsModalRef = this.modalService.show(CustomerFormComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true});
    var modals = document.querySelectorAll(".custom-card-content-item-footer-custom-options");
    for (var i = 0; i < modals.length; i++) {
      modals[i].classList.remove('active')
    }

  }
  editCustomer(id: any) {
    this.editMode = true;
    this.customerService.editCustomer.next(id);
    this.bsModalRef = this.modalService.show(CustomerFormComponent, {class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
    this.closeCardSettings();

  }

  seeComments(customer: any) {
    let initialState =  {
      customer : customer
    }
    this.bsModalRef = this.modalService.show(CustomerCommentsComponent, {initialState,  class: 'modal-lg', backdrop: true });
    this.closeCardSettings();
  }

  
  searchInput(e:any){
    if(this.searchCustomer.value == '' || e?.key == 'Enter'){
      this.search()
    }
  }
  search() { 
     this.pagination.currentPage = 1;
     this.loadCustomersLar(); 
  }

  loadCustomersLar() {
     this.paginationMode = true
     if(this.searchCustomer.valid){
        this.inputSearchCss = "main-navbar-content-page-search"
        this.inputSearchCssmobile = "main-navbar-content-page-search  search-mobile"    
        this.specialcha = false;
        let cid = '';
    
        this.customerService.getCustomersByEvents(this.pagination.currentPage, this.pagination.itemsPerPage, this.searchCustomer.value, cid)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (res: any) => { 
                this.customers = res.result;
                this.pagination = res.pagination;
                this.paginationMode = false
          }, error => {
              this.alertify.error(error);
          },
          () => null
        );

    }else{
      this.inputSearchCss = "main-navbar-content-page-search border-warning" 
      this.inputSearchCssmobile = "main-navbar-content-page-search border-warning search-mobile"     
      this.specialcha = true;
    }
  
  }

  openModalWithComponent() {
    this.bsModalRef = this.modalService.show(CustomerCardComponent);
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadCustomersLar();
  }

  toogleView(type: string) {
    if (this.viewType !== type) {
        this.viewType = type;
    }
  }

  loadSalesOrder(id) {
    this.closeCardSettings();
    let salesordersCustomer = [];
    let specificFields:any = {
      values: [
          "SONumber","status", "SOID"
      ]
    }
      this.salesService.getSalesOrdersSpecific(1, 100, '', '', '', '', '','', '', id, specificFields)
      .pipe(takeUntil(this.destroy$))  
     .subscribe(
       (res: any) => {
         salesordersCustomer = res.result;
          this.viewSalesOrder(salesordersCustomer);
     }, error => {
       this.alertify.error(error);
     });
  }

  viewSalesOrder(salesordersCustomer) {   

    const initialState = {
      passSalesOrders: salesordersCustomer,
      class: 'modal-lg',
      style: 'width: 100%'
    };

    this.bsModalRef = this.modalService.show(SalesorderSearchComponent, {initialState,class: 'orders-modal', backdrop: true, ignoreBackdropClick: true});
  }

  closeCardSettings () {
    var modals = document.querySelectorAll(".custom-card-content-item-footer-custom-options");
    for (var i = 0; i < modals.length; i++) {
      modals[i].classList.remove('active')
    }
  }

  async logout() {
    
    this.yo = true;
  
      if(  this.router.url.slice(0, 12) == '/salesorder'  ||  this.router.url.slice(0, 12)  =='/storeconfig'){      
        await new Promise((resolve,reject)=>{                          
                this.alertify.confirm("Are you sure you want to logout?",
                   ()=>{
                      resolve(this.yo = true) 
                   }
                   ,()=>{
                   }
                ); 
          setTimeout(() => {  
             reject(this.yo = false) 
          }, 10000)    
        });  
      }else{
        this.yo = true
      }
  
    if(this.yo){
      this.authService.showunsavedSO.next('No')
      this.authService.showunsavedSett.next('No')
      localStorage.removeItem('auth_token');
      localStorage.removeItem('isCashier');
      localStorage.removeItem('products');
      this.authService.decodedToken = null;
      this.authService.currentUser = null;
      this.alertify.message('logged out');
      // this.router.navigate(['/login']);
      window.location.href =environment.linkLogin;  
      this.modalService.hide();
    }    
  
  }
}
