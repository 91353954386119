import { Injectable } from '@angular/core';
import { User } from '../_modules/user';
import { UserService} from '../_services/user.service';
import { AlertifyService } from '../_services/alertify.service';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators/catchError';
import { CustomerService } from '../_services/customer.service';
import { Customer } from '../_modules/customers';

@Injectable()
export class CustomerFormResolver implements Resolve<Customer> {

    constructor(private customerService: CustomerService, private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Customer> {
        return this.customerService.getCustomer(route.params['id']).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving data');
                this.router.navigate(['/customers']);
                return of(null);
            })
        );
    }
}
