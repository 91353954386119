import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { EventsService } from 'src/app/_services/events.service';
import { UserService } from 'src/app/_services/user.service';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  showSilencedNoti:boolean = false;
  @Input() showNotifications : boolean;
  @Output() showNotificationsChange = new EventEmitter<boolean>();

  events:any = [];
  constructor(
    private eventService:EventsService,
    private alertify: AlertifyService,
    private userService : UserService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.events = []
   this.initialInfo()
  }

  initialInfo(){
    this.eventService.EventSOAndPO.subscribe((data:any)=>{
      this.events = data?.notifications
    })
  }



  markAsRead(uuid :any, types:any, fromNotification: string = ''):void{
    let values = {
      id:uuid,
      type:types
    }
    this.eventService.markAsRead(values).subscribe((data:any)=>{
      this.eventService.checkSOToPO().subscribe((data:any)=>{
        //console.log(data)
        if(data.length > 0){
         this.eventService.EventSOAndPO.next(data)
         if(fromNotification == 'fromNotification'){
           this.alertify.success('Notification marked as read');
         }
        }
      })
    }, error =>{
      //console.log(error)
    })

  }
  markAsSilenced(uuid :any, types:any, fromNotification: string = ''):void{
    let values = {
      id:uuid,
      type:types, 
      silenced : true
    }
    this.eventService.markAsRead(values).subscribe((data:any)=>{
      this.eventService.checkSOToPO().subscribe((data:any)=>{
        //console.log(data)
        if(data?.notifications?.length > 0){
         this.eventService.EventSOAndPO.next(data)
        //  this.initialInfo()
           this.alertify.success('Notification silenced');
        }
      })
    }, error =>{
      //console.log(error)
    })

  }

  showSilenced(){
    this.showSilencedNoti = !this.showSilencedNoti;
  }


}
