import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { GlobalsService } from 'src/app/_services/globals.service';
import { take, takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { Subject } from 'rxjs';
import { DevicesService } from 'src/app/_services/devices.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-devicespos',
	templateUrl: './devicespos.component.html',
	styleUrls: ['./devicespos.component.scss'],
})
export class DevicesposComponent implements OnInit {
	destroy$: Subject<boolean> = new Subject<boolean>();
	modalRef: BsModalRef;
	devices: any[];
	types: any[];
	totalItems = 0;
	p: number = 1;
	editDevice: any = null;
	device: FormGroup;
	showModal: boolean = false;
	@ViewChild('popTemplate', { static: false }) popTemplate: TemplateRef<any>;

	constructor(
		private router: Router,
		private fb: FormBuilder,
		private modalService: BsModalService,
		private bsModalRef: BsModalRef,
		private globals: GlobalsService,
		private alertify: AlertifyService,
		private devicesService: DevicesService,
		private translate: TranslateService,
	) {}

	ngOnInit(): void {
		this.getDevices();

		this.device = this.fb.group({
			type: ['', [Validators.required]],
			deviceName: [0, [Validators.required]],
			brand: [0, []],
			uuid: ['', []],
		});
	}

	getDevices() {
		this.devicesService
			.getDevices()
			.pipe(takeUntil(this.destroy$))
			.subscribe((data: any) => {
				this.devices = data;
				//console.log(this.devices);
			});
	}

	openModal(template: TemplateRef<any>) {
		this.resetForm();
		this.getTypes();
		this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
		this.showModal = true;
	}

	closeModal(noShow?: boolean) {
		this.modalRef.hide();
		this.editDevice = null;
		this.resetForm();
		this.showModal = false;
	}

	saveDevice() {
		if (this.device.valid) {
			this.device.controls['type'].setValue(this.device.get('type').value);
			this.device.controls['deviceName'].setValue(this.device.get('deviceName').value);
			this.device.controls['brand'].setValue(this.device.get('brand').value);
			this.device.controls['uuid'].setValue(this.globals.guid());

			this.devicesService
				.addDevice(this.device.value)
				.pipe(take(1))
				.subscribe(
					() => {
						this.resetForm();
						this.alertify.success(this.translate.instant('DEVICE_ADDED'));
						this.getDevices();
						this.closeModal();
					},
					(error) => {
						this.alertify.error(error);
					}
				);
		}
		this.devices;
	}

	editDevices(device: any, tem?: TemplateRef<any>, add?: any) {
		this.editDevice = device;
		this.getTypes();
		this.device.controls['type'].setValue(device.type);
		this.device.controls['deviceName'].setValue(device.deviceName);
		this.device.controls['brand'].setValue(device.brand);
		this.device.controls['uuid'].setValue(device.uuid);
		this.modalRef = this.modalService.show(tem, { class: 'modal-lg', ignoreBackdropClick: true });
		this.showModal = true;
	}

	updateDevice() {
		if (this.device.valid) {
			this.device.controls['type'].patchValue(this.device.get('type').value);
			this.device.controls['deviceName'].patchValue(this.device.get('deviceName').value);
			this.device.controls['brand'].patchValue(this.device.get('brand').value);

			this.devicesService
				.updateDevice(this.device.get('uuid').value, this.device.value)
				.pipe(takeUntil(this.destroy$))
				.subscribe(
					(res: any) => {
						this.alertify.success(this.translate.instant('DEVICE_UPDATED'));
						this.modalRef.hide();
						this.editDevice = null;
						this.resetForm();
						this.getDevices();
					},
					(error) => {
						this.alertify.error(error);
					}
				);
		}
	}

	deleteDevice(id?: string) {
		let msg = this.translate.instant('DELETE_DEVICE_CONFIRMATION')

		this.alertify.confirm(
			msg,
			() => {
				this.devicesService
					.deleteDevice(id)
					.pipe(takeUntil(this.destroy$))
					.subscribe(
						(res) => {
							this.alertify.success(this.translate.instant('DEVICE_DELETED'));
							this.getDevices();
						},
						(error) => {
							this.alertify.error(error);
						}
					);
			},
			() => {}
		);
	}

	resetForm() {
		this.device.controls['type'].setValue('');
		this.device.controls['deviceName'].setValue('');
		this.device.controls['brand'].setValue('');
	}

	getTypes() {
		this.devicesService.getTypes().subscribe((res: any) => {
			this.types = res;
		});
	}
}
