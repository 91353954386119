<div class="store-catalog-container desktop">
  <div class="mr-0 store-catalog-container-records" [ngClass]="show ? 'half-width' : 'col-md-12'">

    <app-storeproducts [show]="show" ></app-storeproducts> 
  </div>
  
  <div
    class="store-catalog-container-dds-container"
    [ngClass]="show ? 'active' : ''"
  >
    <div class="close-dds" (click)="show = !show" [ngClass]="show ? 'active' : ''">
      <span class="material-icons"> navigate_next </span>
    </div>
    <div class="store-catalog-container-dds-container-content">
      <!-- Configurar Botones -->
     
      

      <h5 *ngIf="show" class="mb-4">
        DDS ORDER FORMS
      </h5>
      
      

      <app-styleitems *ngIf="show"></app-styleitems>
    </div>
  </div>
</div>
<div class="store-catalog-container mobile">
  <app-storeproductsmobile *ngIf="show"></app-storeproductsmobile>
</div>