import { Component, OnInit, Input, IterableDiffers, OnDestroy, Output, EventEmitter, DoCheck } from '@angular/core';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { GlobalsService } from 'src/app/_services/globals.service';
import { CardReaderService } from 'src/app/_services/cardreader.service';
import { map, switchMap, take, takeUntil } from 'rxjs/operators';
import { Observable, of, Subject, timer } from 'rxjs';
import { AuthService } from 'src/app/_services/auth.service';
const clover = require ("remote-pay-cloud");

import {WebSocketCloudCloverDeviceConfiguration, WebSocketCloudCloverDeviceConfigurationBuilder, WebSocketPairedCloverDeviceConfigurationBuilder, device } from 'remote-pay-cloud'
import { CloverSVG } from 'src/app/_directives/clover';
import { SettingsService } from 'src/app/_services/settings.service';
import { TranslateService } from '@ngx-translate/core';
interface Editing {
  index?: number|null;
  paymentID?: string;
  position?: any|null;
}
@Component({
  selector: 'app-payments-form',
  templateUrl: './payments-form.component.html',
  styleUrls: ['./payments-form.component.scss']
})
export class PaymentsFormComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  cardinal = ['First', 'Second', 'Third', 'Fourth', 'Fifth', 'Sixth', 'Seventh', 'Eighth', 'ninth', 'tenth', 'eleventh', 'twelfth', 'thirteenth', 'fourteenth', 'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'];
  paymentTypes: any;

  @Input() from: any = '';
  @Input() settings: any;

  valcanEdit: any = false;
  @Input() set canEdit(value: any) {
    this.valcanEdit = value
  }
  get canEdit(): any {
    return this.valcanEdit;
  }

  valpasspayments: any = [];
  @Input() set passpayments(value: any) {    
    this.valpasspayments = value
  }
  get passpayments(): any {
    return this.valpasspayments;
  }

  valsalesOrder: any;
  @Input() set salesOrder(value: any) {
    this.valsalesOrder = value 
       
    this.updateTotals()
    if(this.singlePayment.paymentID != undefined){
      console.log('input sales order no undef');      
      setTimeout(() => {
        this.singlePayment.paymentAmount = this.getPayed();      
      }, 500);
    }
  }
  get salesOrder(): any {
    return this.valsalesOrder;
  }




  @Output() closePaymentForm: EventEmitter<any> = new EventEmitter();
  @Output() updatePaymentOnSO: EventEmitter<any> = new EventEmitter();
  @Input() payment: any = { };
  @Input() PaymentsToDelete: any = [];

  finishLoadPayment:any = false
  paymentInProcess: any;
  paymentTotal = 0;
  cardPayment: any;
  cardPaymentProcessed = false;
  showCloverSide : boolean =false;
  showOtherPayments : boolean =true;
  multiple : boolean =false;
  modifyingPayment : Editing ={
    index : null,
    paymentID: '',
    position:''
  };
  addingPayment: boolean = false
  flagSelector: boolean = false
  toBePayed = 0;
  deviceId: string = ""
  cloverIMG:any;
  devices:any[]=[];
  statusAvalara: string = ''
  iterableDiffer: any;
  myjson: any = JSON;
  model = {
    payments: []
  };
  singlePayment:any = {}
  creditForm = false;
  countdown$: Observable<number> = timer(0, 1000).pipe(
    take(100),
    map((value: number) => 100 - value)
  );

  historyAction = {
    function:'',
    payment:'',
    email:'',
    method:''
  }

  form = new FormGroup({});
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'payments',
      type: 'repeatpayments',
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row ',
      fieldArray: {
        fieldGroupClassName: 'm-rem02 row tw-rounded-lg tw-border-2 tw-border-aedgrayl mb-2 tw-py-4',
        templateOptions: {
          btnText: 'Add Payment',
          afterAdd:  () => {
            if (this.settings['cardLocation']) {
              if (this.fields[0]) {
                this.fields[0].fieldGroup[this.fields[0].fieldGroup.length - 1].fieldGroup[2].templateOptions.options = this.paymentTypes;
                this.fields[0].fieldArray.fieldGroup[2].templateOptions.options = this.paymentTypes;
              }
            }
          },        
          removePayment: (x) =>{ 
            if(this.model.payments[x]['paymentID'] != null){
              this.PaymentsToDelete.push(this.model.payments[x])
            } 
          }
        },


      fieldGroup: [
        {
          key: 'paymentID',
          type: 'input',
          defaultValue: '',
          className: 'col-md-6',
          hide: true,
          lifecycle: {
            onInit: (form, field) => {
              if ( field.formControl.value === '') {
                field.model['paymentID'] = this.global.guid();
                field.formControl.setValue(this.global.guid());
              }
            },
          },
        },
        {
          key: 'foreignSOID',
          type: 'input',
          className: 'col-md-6',
          hide: true,
          lifecycle: {
            onInit: (form, field) => {
                field.model['foreignSOID'] = this.salesOrder['SOID'];
                field.formControl.setValue(this.salesOrder['SOID']);
            },
          },
        },

        {
          key: 'paymentType',
          type: 'select',
          className: 'col-md-5',
          hideExpression: (model) => model['transactionPayment'],
          defaultValue: 'Cash',
          templateOptions: {
            label: 'Method',
            placeholder: '',
            required: true,
            options: [
              {label: 'Cash', value: 'Cash'},
              {label: 'Check', value: 'Check'},
              {label: 'Master Card', value: 'Master Card'},
              {label: 'Visa', value: 'Visa'},
              {label: 'Amex', value: 'Amex'},
              {label: 'Discover', value: 'Discover'},
              {label: 'Financing', value: 'Financing'},
              {label: 'Credit Card', value: 'Credit Card'}
            ],
          },
          lifecycle : {
            onInit: (field) => {
            }
          }
        }
    ,
    
    {
      key: 'paymentType',
      type: 'input',
      className: 'col-md-5',
      hideExpression: (model) => !model['transactionPayment'],
      templateOptions: {
        label: 'Method',
        required: true
      
      },
    },
    
    
    {
        key: 'paymentAmount',
        type: 'input',
        className: 'col-md-4 mr-0 pr-0',
        defaultValue: 0,
        hideExpression: (model) => model['transactionPayment'],
        templateOptions: {
          type: 'number',
          label: 'Amount',
          required: true,
          // addonRight: {
          //   text: 'Process Card',
          //    class: ' text-small text-primary',
          //   onClick: (to, fieldType, $event) => {
          //     console.log(to, fieldType, $event);
              
          //     this.showCard(fieldType.model);
          //   },
  
          },
          // addonRight: {
          //   text: '$',
          // },

        
        lifecycle: {
          onInit: (form, field) => {
            if ( parseFloat(field.formControl.value) === 0) {
              if (this.getPayed() > 0 ) {
                field.model['paymentAmount'] = this.getPayed();
                field.formControl.setValue(this.getPayed());
              }
            }
          },
        },
      },

      {
        key: 'paymentAmount',
        type: 'input',
        className: 'col-md-4 mr-0 pr-0',
        hideExpression: (model) => !model['transactionPayment'],

        defaultValue: 0,
        templateOptions: {
          type: 'number',
          label: 'Amount',
          required: true,
          readonly: true,
          },
      },


      {
        type: 'button',
        key: 'btnProcess',
        className: 'col-md-2  my-auto mt-1',
        templateOptions: {
         label: ' ',
          text: 'Process',
          btnType: 'primary btn-3',
          btnSize: 'btn-3',
          onClick: ($event, model) => {
            this.showCard(23);
          },
          onClickProcessed: ($event, model) => {
            this.showPaymentProcessed(model);
          }
        }, 
      },
      {
        key: 'paymentDetail',
        type: 'textarea',
        className: 'col-md-5',
        templateOptions: {
          placeholder: 'Internal Notes',
          rows: 1,
        },
      },
      {
        key: 'Note',
        type: 'textarea',
        className: 'col-md-6',
        templateOptions: {
          placeholder: 'Receipt Notes',
          rows: 1,
        },
      },

      {
        key: 'cardLastFour',
        type: 'input',
        hide: true,
        className: 'col-md-6',
        templateOptions: {
          label: 'Last Four',
        },
      },
      {
        key: 'authCode',
        type: 'input',
        hide: true,
        className: 'col-md-6',
        templateOptions: {
          label: 'Auth Code',
        },
      }
    ],
    },}
];

devicesActiveCount:boolean = false

  constructor(
    private alertify: AlertifyService,
    private bsModalRef: BsModalRef,
    private global: GlobalsService,
    private settingService: SettingsService,
    private cardReaderService: CardReaderService,
    private _iterableDiffers: IterableDiffers,
    private authService: AuthService,
    private translate: TranslateService,
    ) {

      this.iterableDiffer = this._iterableDiffers.find([]).create(null);
    }

     ngOnInit() {    
      

      this.getCloverDevices();

      this.cardReaderService.cardSignatureAccept
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any ) => {
          if (res === 'Complete') {            
            this.model.payments[this.model.payments.length - 1]['authCode'] = this.cardPayment['authCode'].toUpperCase();
            this.model.payments[this.model.payments.length - 1]['cardLastFour'] = this.cardPayment['cardLastFour'].toUpperCase();
            this.model.payments[this.model.payments.length - 1]['cardType'] = this.cardPayment['cardType'].toUpperCase();
            this.model.payments[this.model.payments.length - 1]['nameOnCard'] = this.cardReaderService.cardName.value.toUpperCase();

            this.options.resetModel({ ...this.model, ...this.model.payments });
            this.submitNotClosing(this.model);
          }
        }, error => {
          this.alertify.error(error);
      });

      this.global.savePaymentFromMobile
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any ) => {
          if (res == 'savePayMobile') {
            if(this.finishLoadPayment){
              this.submit(null);
            }
            this.global.savePaymentFromMobile.next('');
          }
        }, error => {
          this.alertify.error(error);
      });

      this.cloverIMG = CloverSVG

    } 

    getCloverDevices(){      

      this.loadData()

      if(this.settings.cloverState != '1'){
        return;
      }

      let values = {
        username : this.authService.decodeToken('role').role == 'Dealer'? 'isDealer' : this.authService.currentUser.name,
        clientId : 'aedpay',
        bearer : this.settings['authClover'],
        verifyActive:true
      }
      
      this.settingService.getDevicesByUser(values)
      .pipe(takeUntil(this.destroy$),
      switchMap((devices: any[])=>{
        this.devices = devices
        if(this.devices.length > 0){
          return of(devices)
        }else{
          return of(null)
        }
      }))   
      .subscribe((devices:any[] | null) =>{
        if(devices == null){
          this.showOtherPayments = true
        }
      })      
    }

    pingAvalara(device): Promise<boolean>{
      let values = {
        deviceId : device.deviceId,
        clientId : 'aedpay',
        bearer : this.settings['authClover'],
      }
      let active: boolean = false
      this.cardReaderService.pingAvalara(values)
      .pipe(takeUntil(this.destroy$))   
      .toPromise().then(
        (res: any) => {
          if(res.connected == true){
            active = true
            device.active = true
          }
        }).catch(( error:any) => {
          active = false
          device.active = false   
      });
      
      return Promise.resolve(active)
    }

    async loadData(){               

      
      if(this.passpayments != undefined ){

        //verifico si viene algun payment, ya puede ser void o no, para asignarlo al modelo      
        if(this.passpayments.length > 0){
          this.model.payments =  Object.assign([], JSON.parse(JSON.stringify( this.passpayments)));        
        }
        // hago el filtro para excluir los void, y si hay payments que no son void, entonces son pagos multiples
        if (this.filterPayments(this.passpayments).length > 0) {
          this.model.payments =  Object.assign([], JSON.parse(JSON.stringify( this.passpayments)));
          this.multiple = true
          this.finishLoadPayment = true
        }else{         
          
          this.singlePayment = {
            paymentID: this.global.guid(),
            paymentType: "",
            paymentAmount: this.getPayed(),
            foreignSOID: this.salesOrder['SOID']
          }; 

          this.singlePayment.paymentType = (this.settings.cloverState == '1' && await this.filterActive(this.devices).length > 0) ?"":"Cash"
          this.finishLoadPayment = true
        } 

      }else{
        this.singlePayment = {
          paymentID: this.global.guid(),
          paymentType: "",
          paymentAmount: this.getPayed(),
          foreignSOID: this.salesOrder['SOID']
        }; 
        this.singlePayment.paymentType = (this.settings.cloverState == '1' && await this.filterActive(this.devices).length > 0) ?"":"Cash"
        this.finishLoadPayment = true
      }
  
      this.paymentTypes = [
        {label: 'Cash', value: 'Cash'},
        {label: 'Check', value: 'Check'},
        {label: 'Credit Card', value: 'Credit Card'},
        {label: 'Financing', value: 'Financing'}
      ];

      this.updateTotals()        

    }


    filterActive(items:any):any{   
      if(items != null && items.length > 0){   
        return items.filter((e:any) => e.active == true);
      }
      else{
        return []
      }
    }


    savePayment(){ 
      if(!this.multiple ){  
        
        if(this.singlePayment.paymentType == ""){
          this.alertify.warning(this.translate.instant('SELECT_PAYMENT_METHOD'))
          return;
        }       
        
        if(this.singlePayment.paymentAmount <= 0){
          this.alertify.warning(this.translate.instant('PAYMENT_MUST_BE_GREATER'))
          return;
        } 
        
        this.multiple = true      
        this.model.payments.push(this.singlePayment) 
        this.singlePayment = {}
        this.updateTotals()
        this.updatePaymentOnSO.emit(this.filterPayments(this.model.payments))
      }           
    }

    connectUsing():void{
          const networkConfigurationBuilder = new WebSocketCloudCloverDeviceConfigurationBuilder(
            localStorage.getItem('appID'),// applicationId
            localStorage.getItem('deviceId'), // deviceId
            "3YTY3HKP8S251", // merchantId 
            localStorage.getItem('code'), // accessToken
          );
          const cloudConfiguration = networkConfigurationBuilder.setCloverServer("https://sandbox.dev.clover.com")
        .setFriendlyId("23423432")
        .build();


        var builderConfiguration = {};
            builderConfiguration[clover.CloverConnectorFactoryBuilder.FACTORY_VERSION] = clover.CloverConnectorFactoryBuilder.VERSION_12;
            var cloverConnectorFactory = clover.CloverConnectorFactoryBuilder.createICloverConnectorFactory(builderConfiguration);

            var cloverConnector = cloverConnectorFactory.createICloverConnector(cloudConfiguration);

            var defaultCloverConnectorListener = Object.assign({}, clover.remotepay.ICloverConnectorListener.prototype, {

              onDeviceReady: function (merchantInfo) {
                  // updateStatus("Pairing successfully completed, your Clover device is ready to process requests.");
                  // console.log({message: "Device Ready to process requests!", merchantInfo: merchantInfo});
              },
           
              onDeviceDisconnected: function () {
                  // console.log({message: "Disconnected"});
              },
           
              onDeviceConnected: function () {
                  // console.log({message: "Connected, but not available to process requests"});
              }
           
           });

          //  cloverConnector.addCloverConnectorListener(defaultCloverConnectorListener);
           cloverConnector.initializeConnection();
           cloverConnector.showMessage("Welcome to Clover Connector!");

    }

    multiplePayments(from?:any):void{

      this.multiple = true ;
      let devicesActiveCount:boolean = this.filterActive(this.devices)?.length > 0 ? true: false
      if(!devicesActiveCount && this.settings.cloverState == '0'){
        this.showOtherPayments = true
      }else{
        this.showOtherPayments = false
      }


      if(this.model.payments.length > 0){
        if(this.singlePayment.paymentType == ""){
          this.alertify.warning(this.translate.instant('SELECT_PAYMENT_METHOD'))
          return;
        } 

        if(this.singlePayment.paymentAmount <= 0){
          this.alertify.warning(this.translate.instant('PAYMENT_MUST_BE_GREATER'))
          return;
        } 
        if(this.toBePayed < 0){           
          this.modifyingPayment = {index :null, paymentID:'',position:''}
          this.addingPayment = false  
          this.singlePayment = {}               
          this.updateTotals();   
          this.updatePaymentOnSO.emit(this.filterPayments(this.model.payments))      

          console.log('menor que 0');          
          return;
        }
        if(this.toBePayed == 0){
            this.modifyingPayment = {index :null, paymentID:'',position:''}
            this.addingPayment = false  
            this.singlePayment = {}               
            this.updateTotals();  

        }

        if (this.toBePayed > 0) {
          
          this.modifyingPayment = {index :null, paymentID:'',position:''}
          this.addingPayment = true

          
          this.updateTotals();
          if(this.getPayed()> 0){
            
            this.singlePayment = {
              paymentID: this.global.guid(),
              paymentType: (devicesActiveCount && this.settings.cloverState == '1') ? "": "Cash",
              paymentAmount: this.getPayed(),
              foreignSOID: this.salesOrder['SOID']
            };

            this.model.payments.push(this.singlePayment)  
          }

        
        }  
                         
        this.updatePaymentOnSO.emit(this.filterPayments(this.model.payments))      
      }else{
          console.log(this.getPayed());
          if(this.getPayed() > 0){
            this.addingPayment = true
            if(this.singlePayment.paymentID == undefined){
              this.singlePayment = {
                paymentID: this.global.guid(),
                paymentType: devicesActiveCount && this.settings.cloverState == '1' ? "": "Cash",
                paymentAmount: this.getPayed(),
                foreignSOID: this.salesOrder['SOID']
              };
            }
            this.model.payments.push(this.singlePayment) 
          }else{
            this.singlePayment = {}
          }
   
        this.updatePaymentOnSO.emit(this.filterPayments(this.model.payments))

      }
    }

    printClover(payment: any){
      if(this.deviceId == ''){
        this.selectCloverDevice(null)
        this.flagSelector = true;
        this.historyAction.function = 'printClover'
        this.historyAction.payment = payment
        return
      }

      this.historyAction.function = ''

      let values = {
        bearer : this.settings['authClover'],
        paymentId : payment.authCode,
        clientId : 'aedpay',
        deviceId : this.deviceId
      }
      this.cardReaderService.getSimplePayment(values)
      .pipe(switchMap((a): Observable<any> =>{
        let msg = "Payment id: "+a.id+"\nAmount charged: $"+(a.amount/100)+"\nDevice: "+a.device.id+"\nStatus: "+a.result;
        alert(msg);
        return this.cardReaderService.printSimplePayment(values)
      }))
      .subscribe(a => {
       
      })
      return;
    }

    confirmEmailClover(payment:any, method: string){
      
      if(this.deviceId == ''){
        this.selectCloverDevice(null)
        this.flagSelector = true;
        this.historyAction.function = 'confirmEmailClover';
        this.historyAction.payment = payment ;
        this.historyAction.method = method;
        return
      }

      this.historyAction.function = '';

      let additionalData = '';

      if(method == 'EMAIL'){
        additionalData =  (this.salesOrder['email'] != null && this.salesOrder['email'] != undefined && this.salesOrder['email'] != '') 
         ? this.salesOrder['email'] : (this.salesOrder['deliveryEmail'] != null && this.salesOrder['deliveryEmail'] != undefined && this.salesOrder['deliveryEmail'] != '' ? this.salesOrder['deliveryEmail'] : "");
        }else if(method =='SMS'){
        additionalData =  (this.salesOrder['phone1'] != null && this.salesOrder['phone1'] != undefined && this.salesOrder['phone1'] != '') 
         ? this.salesOrder['phone1'] : (this.salesOrder['phone3'] != null && this.salesOrder['phone3'] != undefined && this.salesOrder['phone3'] != '' ? this.salesOrder['phone3'] : "");

      }
     
    this.alertify.prompt(additionalData, this.translate.instant('PRODUCT_ADDED') +` ${method == 'EMAIL' ? "email": "phone" }`, additionalData,
      
    (evt, value) => {    
      this.emailClover(payment, value, method)
    },

    () => { 
    });
  
    }

    emailClover(payment: any, email, method: string){
      if(this.deviceId == ''){
        this.selectCloverDevice(null)
        this.flagSelector = true;
        return
      }
      let values = {
        bearer : this.settings['authClover'],
        paymentId : payment.authCode,
        clientId : 'aedpay',
        deviceId : this.deviceId,
        email: email,
        method: method,
      }
      this.cardReaderService.emailSimplePayment(values)
      .subscribe((a: any) =>{
        if(a.printJobStatus == "DONE"){
          this.alertify.alert(this.translate.instant('RECEIPT_SENT_VIA')+method, ()=>{});
        }
        // console.log(a)
      })
    }

    removeEditingPayment():void{
      this.addingPayment = false
      this.removePayment(this.singlePayment, null)
      this.updateTotals()
    }

    removePayment(payment: any, index: number): void{
      if(this.deviceId == '' && payment.paymentType == "Credit Card"){
        this.selectCloverDevice(null)
        this.flagSelector = true;
        this.historyAction.function = 'removePayment'
        this.historyAction.payment = payment
        return
      }

      this.historyAction.function = ''

      if(payment.paymentType != "Credit Card"){
        let filtered = this.model.payments.filter(e => e.paymentID != payment.paymentID)

        this.model.payments = filtered
          this.PaymentsToDelete.push(payment)
          this.modifyingPayment = {index :null, paymentID:'',position:''}
          if(this.singlePayment?.paymentID != undefined){

            if(payment.paymentID == this.singlePayment.paymentID){
              this.singlePayment = {} 
            }
          }
          this.updateTotals()
          this.updatePaymentOnSO.emit(this.filterPayments(this.model.payments))
      }else{
        // If this is a card void
        this.alertify.confirm(this.translate.instant('PAYMENT_COMMITED_CONFIRM'), 
        ()=>{
          let values = {
            bearer : this.settings['authClover'],
            paymentId : payment.authCode,
            clientId : 'aedpay',
            deviceId : this.deviceId,
            foreignSOID : this.salesOrder['foreignSOID']

          }
          this.cardReaderService.voidSimplePayment(values).toPromise().
          then((data) =>{
            if(data.voidReason){
              payment.transactionvoid_tx = data.paymentId
              payment.voidtype_tx = data.voidReason
              payment.VoidAuthCode_tx = data.voidStatus
              let valores = {
                deviceId : this.deviceId,
                clientId : 'aedpay',
                bearer : this.settings['authClover'],
                message : "welcome",
              }
              this.cardReaderService.displayMessage(valores).toPromise()
              .then((a) => {

              })

              this.updateTotals()
              this.updatePaymentOnSO.emit(this.filterPayments(this.model.payments))
            }

          })
        }, 
        ()=>{

        })
      }
    }

    editPayment(payment: any, index :number){
      if(this.addingPayment){
        this.alertify.warning(this.translate.instant('FINISH_CREATING_PAYMENT'))
        return 
      }
      if(this.modifyingPayment.paymentID != ''){
        this.alertify.warning(this.translate.instant('SAVE_BEFORE_EDIT'))
        return
      }
      this.modifyingPayment.position = index;
      this.modifyingPayment.index = this.model.payments.findIndex(e => e.paymentID == payment.paymentID);
      this.modifyingPayment.paymentID = (this.model.payments.find(e => e.paymentID == payment.paymentID) || {paymentID : ''} ).paymentID;
      // if(payment.paymentType == 'Credit Card'){
      //   this.showOtherPayments = true
      // }
      this.singlePayment = payment
    }

    sanitizeInput(event: any){
      this.singlePayment.paymentAmount = (event.target.value as string).replace(',', '.');
    }

    ngOnDestroy(): void {
      this.global.optionTrashFL.next('default')
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
    }

    

    // se puede hacer submit desde la transaccion, o desde los botones
    // si es desde la tarjeta no se cierra el modal
    submit(payments: any, noclosemodal?: boolean) {
        
      //si solo tiene un pago
      if(!this.multiple){

        if(this.singlePayment.paymentType == ""){
          this.alertify.warning(this.translate.instant('SELECT_PAYMENT_METHOD'))
          return;
        }   

        if(this.singlePayment.paymentID != undefined){
          this.alertify.warning(this.translate.instant('PLEASE_SAVE_PAYMENT'))
          return
        }
        
      }

      // si tiene mas de un pago
      if(this.model.payments.length > 0){
        if(this.singlePayment.paymentType == ""){
          this.alertify.warning(this.translate.instant('SELECT_PAYMENT_METHOD'))
          return;
        }

        if(this.singlePayment.paymentID != undefined){
          this.alertify.warning(this.translate.instant('PLEASE_SAVE_PAYMENT'))
          return
        }
      }

      this.passpayments = Object.assign([], this.model.payments);
      const soID = this.salesOrder['SOID'];

      for (const value of this.passpayments) {
        value['foreignSOID'] = soID;
        delete value['btnProcess'];
        if (!value.hasOwnProperty('paymentID')) {
          value['paymentID'] = this.global.guid();
        }
      }

      this.salesOrder['payments'] = this.passpayments;

      //si es mandado desde la tarjeta no se cierra el modal
      if(noclosemodal){
        this.global.savePaymentForce.next('hola')
        return;
      }

      if(this.from == 'SOmobile'){
        this.closePaymentForm.emit(true)
      }
      this.bsModalRef.hide();
    }

    submitNotClosing(payments: any) {
      this.passpayments = Object.assign([], this.model.payments);
      const soID = this.salesOrder['SOID'];

      for (const value of this.passpayments) {
        value['foreignSOID'] = soID;
        delete value['btnProcess'];
        if (!value.hasOwnProperty('paymentID')) {
          value['paymentID'] = this.global.guid();
        }
      }

      this.salesOrder['payments'] = this.passpayments;
      this.cardReaderService.paymentSaved.next(true);
    }   

    getPayed() {
      return this.toBePayed;
    }

    updateTotals() {
      this.paymentTotal = 0 ;
      if (this.model.payments) {
        for (const i of this.model.payments.filter(e => e.voidtype_tx == undefined || e.voidtype_tx == null)) {
          if (i['paymentAmount'] !== 0) {
              this.paymentTotal += this.global.pNumber((i['paymentAmount']));
            }
        }
        if (this.salesOrder['Total'] !== undefined) {
          this.toBePayed = +this.global.pNumber((this.global.pNumber(this.salesOrder['Total']) - this.global.pNumber(this.paymentTotal)));
        
          // if(this.singlePayment.paymentID != undefined){
          //   this.singlePayment.paymentAmount = this.toBePayed;
          // }
      }


      }

    }

    showPaymentProcessed(model) {
      if(this.deviceId == ''){
        this.selectCloverDevice(null)
        this.flagSelector = true;
        return
      }
      let values = {
        bearer : this.settings['authClover'],
        paymentId : model.authCode,
        clientId : 'aedpay',
        deviceId : this.deviceId
      }
      this.cardReaderService.printSimplePayment(values)
      .pipe(switchMap((e): Observable<any> =>{
        return this.cardReaderService.getSimplePayment(values)
      }))
      .subscribe(a => {
        let msg = this.translate.instant('PAYMENT_ID') + a.id + "\n" +
                  this.translate.instant('AMOUNT_CHARGED') + "$" + (a.amount/100) + "\n" +
                  this.translate.instant('DEVICE') + a.device.id + "\n" +
                  this.translate.instant('STATUS') + a.result;
        alert(msg);
      })
      return;
    }

    filterPayments(payments):any[]{   
      let newPayments = []
      if(this.modifyingPayment.index == null && !this.addingPayment){
        newPayments = payments.filter((p:any)=> p.voidtype_tx == null || p.voidtype_tx == undefined);
      } else{
        if(this.addingPayment){
          newPayments= payments.filter((p , index)=> index != (payments.length-1) && (p.voidtype_tx == null || p.voidtype_tx == undefined))
        }else{
          newPayments= payments.filter((p , index)=> p.paymentID != this.modifyingPayment.paymentID && (p.voidtype_tx == null || p.voidtype_tx == undefined) )
        }
      }
      return newPayments
    }

    getIndex(id): number{
      return this.filterPayments(this.model.payments).findIndex(e=>e.paymentID == id)
    }
  
    selectCloverDevice(payment){
      if(this.filterActive(this.devices).length > 0){
        this.showCloverSide = true
        this.statusAvalara = 'Select Device'
      }
      if(payment != null){
        this.paymentInProcess = payment;
      }
    }

  showCard(deviceId) {
    this.deviceId = deviceId
    if(this.flagSelector){
      this.flagSelector = false
      this.showCloverSide = false
      if(this.historyAction.function != ''){
        if(this.historyAction.function == 'confirmEmailClover'){
          this.confirmEmailClover(this.historyAction.payment,this.historyAction.method);
        }else if(this.historyAction.function == 'printClover'){
          this.printClover(this.historyAction.payment);
        }else if(this.historyAction.function == 'removePayment'){
          this.removePayment(this.historyAction.payment,null);
        }
      }
      return
    }
    this.statusAvalara = 'Processing Transaction'
    let values = {
      deviceId : this.deviceId,
      foreignSOID : this.salesOrder['SOID'],
      clientId : 'aedpay',
      bearer : this.settings['authClover'],
      payment : this.paymentInProcess,
    }
    this.cardReaderService.simplePayment(values)
    .pipe(takeUntil(this.destroy$))   
    .pipe(
      switchMap(
        (res:any): Observable<any> => {
          let values = {
            deviceId : this.deviceId,
            clientId : 'aedpay',
            bearer : this.settings['authClover'],
            message : "welcome",
          }
          if(res?.payment?.result == "SUCCESS"){

            if(!this.multiple){
              this.singlePayment.authCode = res?.payment?.id
              this.singlePayment.JSONresponse = JSON.stringify(res)
              this.singlePayment.paymentType = 'Credit Card'
              
              this.singlePayment.cardLastFour = res?.payment?.cardTransaction?.last4
              this.singlePayment.cardDateSubmitted = res?.payment?.cardTransaction?.createdTime
              this.singlePayment.cardType = res?.payment?.cardTransaction?.cardType
              this.singlePayment.nameOnCard = res?.payment?.cardTransaction?.vaultedCard?.cardholderName

              this.model.payments[0] = this.singlePayment
              this.singlePayment = {}
              this.updateTotals()
              this.updatePaymentOnSO.emit(this.filterPayments(this.model.payments))
              this.submit(false,true)
            }else{
              if(this.modifyingPayment.index != null){
                this.model.payments[this.modifyingPayment.index]['authCode'] = res?.payment?.id
                this.model.payments[this.modifyingPayment.index]['cardLastFour'] = res?.payment?.cardTransaction?.last4
                this.model.payments[this.modifyingPayment.index]['cardDateSubmitted'] = res?.payment?.cardTransaction?.createdTime
                this.model.payments[this.modifyingPayment.index]['cardType'] = res?.payment?.cardTransaction?.cardType
                this.model.payments[this.modifyingPayment.index]['nameOnCard'] = res?.payment?.cardTransaction?.vaultedCard?.cardholderName
                this.model.payments[this.modifyingPayment.index]['JSONresponse'] = JSON.stringify(res)
                this.model.payments[this.modifyingPayment.index]['paymentType'] = 'Credit Card'            
                this.singlePayment = {}          
                this.modifyingPayment = {index :null, paymentID:''}
              }else{
                this.singlePayment.authCode = res?.payment?.id
                this.singlePayment.JSONresponse = JSON.stringify(res)
                this.singlePayment.paymentType = 'Credit Card'

                this.singlePayment.cardLastFour = res?.payment?.cardTransaction?.last4
                this.singlePayment.cardDateSubmitted = res?.payment?.cardTransaction?.createdTime
                this.singlePayment.cardType = res?.payment?.cardTransaction?.cardType
                this.singlePayment.nameOnCard = res?.payment?.cardTransaction?.vaultedCard?.cardholderName
                this.singlePayment = {}          
              }    
              this.updateTotals()
              this.multiplePayments();
              this.submit(false,true)

            }


            this.statusAvalara = 'Transaction Complete'
            this.addingPayment = false
            
            setTimeout(() => {
              this.showCloverSide = false
            }, 4000);  

            return this.cardReaderService.displayMessage(values)
          }else{
            return of(res)
          }
        }
      )).subscribe(
        (res: any) => {
          this.showCloverSide = false
          // console.log(res)
          if(res?.code){
            if(res?.code == "processing_error"){
              this.alertify.error(res.message)
              let values = {
                deviceId : this.deviceId,
                clientId : 'aedpay',
                bearer : this.settings['authClover'],
                message : "welcome",
              }
              this.cardReaderService.displayMessage(values).toPromise()
              .then(()=>{
                // console.log
              })
            }
          }
          
        }, 
        (error:any) => {
          console.log(error)
          this.showCloverSide = false
          if(error.includes("amount_too_small")){
            this.alertify.error(this.translate.instant('AMOUNT_TOO_SMALL'));
          } else if(error.includes("could not be established. Please manually s")){
            this.alertify.error(this.translate.instant('CONNECTION_ERROR'));
          } else if(error.includes("Conflicting request (PAY) currently in progres")){
            this.alertify.error(this.translate.instant('CONFLICTING_REQUEST'));
          } else if(error.includes('Promise has been cancelled') || error.includes('cURL error 52: Empty reply from server')
            || error.includes('cURL error 28: Operation timed out after')){
            this.goDeviceCloverHome();
          }
        });
  }

  goDeviceCloverHome(){
    let valores = {
      deviceId : this.deviceId,
      clientId : 'aedpay',
      bearer : this.settings['authClover'],
      message : "welcome",
    }
    this.cardReaderService.displayMessage(valores).toPromise()
    .then((a) => {
    })
    .catch((err) => {
      console.log(err);      
      this.goDeviceCloverHome()
    }) 
  }

  camelCase(str) { 
      return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
      {
          return index == 0 ? word.toLowerCase() : word.toUpperCase();
      }).replace(/\s+/g, '');
  }
}