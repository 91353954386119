
<div *ngIf="status === 1 ">    
    <ngx-loading-bar height="4px" color="#f57317"></ngx-loading-bar> 
    <!-- <app-wait-st *ngIf="showWatting == true"></app-wait-st> -->
    <div *ngIf="reportloadding" style="height: 100%;width:100%;position:fixed;z-index: 95;background-color: rgb(12,12,12,.2);">
        <div style="display: flex;height: 100%;width:100%">
          <div style="display: flex;margin-left:auto;margin-right:auto;margin-top:auto;margin-bottom:auto">
            <div class="spinner-border" style="color: #ffffff;" role="status">
              <span class="visually-hidden"></span>
            </div>
            <div style="margin-left: 1rem;    margin-top: auto; margin-bottom: auto; color: white;">Loading Report</div>            
          </div>
        </div>
    </div>     
    <app-login *ngIf="showNav == false" ></app-login>
    <app-nav class="d-print-none" *ngIf="showNav == true" ></app-nav> 
</div>





<div class="container w-100 my-10 d-flex p-2" *ngIf="status === 0">
    <div class="row w-100 my-10">          
        <div class="col-md-2"></div>
              <div class=" center border-bottom col-md-8 z-depth-1 p-3 " style="text-align: center;">
                <img src="assets/aedpayhorsmall.png"  class="img-circle mb-2 m-auto">
                <h6 class="text-warning m-auto mt-2"><i class="fa fa-warning"></i>{{messageError}}</h6>
            </div>
        <div class="col-md-2"></div>          
    </div>
</div>

<ng-template #popTemplate   >
    <div  class="tw-flex tw-flex-col tw-p-10" >
      <p class="tw-text-lg tw-rounded-lg  tw-text-aedgrayl tw-font-semibold">New updates on aedpay</p>
      <p class="tw-text-lg tw-rounded-lg  tw-text-aedgrayl tw-font-semibold">Please Restart the app</p>
      <p class="tw-text-sm tw-rounded-lg  tw-text-aedgrayl tw-font-semibold">Thanks</p>     
   </div>   
</ng-template>