import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { HostListener,EventEmitter, Output } from '@angular/core';
import { GlobalsService } from 'src/app/_services/globals.service';
import { StockItemsService } from 'src/app/_services/stockitems.service';
import { Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { SearchToSOService } from 'src/app/_services/search-to-so.service';
import { FormControl, Validators } from '@angular/forms';
import { async } from '@angular/core/testing';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-prods',
  templateUrl: './prods.component.html',
  styleUrls: ['./prods.component.scss']
})
export class ProdsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  p:any = 1;
  editMode: boolean[] = [];
  maxQtyItem:number = 0;
  maxQtyAllLotes:number = 0;
  recarga:any
  lastCodeChecked:any = ''
  hasparam=false;
  codeToAdd = new FormControl('',[Validators.required]);
  @Input() productsAdded:any = [];
  subscription: Subscription;

  valtaxNumber: any = 0;
  @Input() set taxNumber(value: any) {
    this.valtaxNumber = value    
    this.calculateTotals()
  }
  get taxNumber(): any {
    return this.valtaxNumber;
  }

  valcanEdit: any = true;
  @Input() set canEdit(value: any) {
    this.valcanEdit = value
  }
  get canEdit(): any {
    return this.valcanEdit;
  }

  @Output() productsToSO = new EventEmitter<Array<any>>();
  @Output() totalsfromItemsToSO = new EventEmitter<Array<any>>();
  
  touchStart: number;
  longPress: boolean;
  timeout: any;
  idparam: any=0;
  constructor( private stockItemsService: StockItemsService, 
  private global: GlobalsService,
  private alertify: AlertifyService,
  private addToSo: SearchToSOService,
  private route:ActivatedRoute,
  private translate: TranslateService,
  ){ 
    this.subscription= new Subscription;
  }

  ngOnInit(): void {    
    this.idparam = this.route.snapshot.params.id;
    this.productsToSO.emit(this.productsAdded);
    this.hasparam = (this.idparam != '' && this.idparam != null && this.idparam != undefined);
    this.subscription=this.addToSo.currentMessage.subscribe((message) => {
      if(message != ''){
        this.addToSOFromSearch(message)
      }
    });

  }

  checkItems(){
    //console.log(this.productsAdded);    
  }

  // agregar productos desde el search
  addToSOFromSearch(product:any) {
    if(product==null)return;
    let foundProduct = this.productsAdded.findIndex((productAdded: any) => productAdded.productCode == product.productCode);
    if(foundProduct != -1){
      this.maxQtyAvailable(foundProduct,'search');
    }else{
      product.lotesIds = [{id:product.storeProductId,qty:1}]
      this.lastCodeChecked = product.productCode;
      this.productsAdded.push(product);
      this.alertify.success(this.translate.instant('PRODUCT_ADDED'));
      this.calculateTotals();
    } 
  }







  // Methods to barcode

  triggerScan(){
    this.addToSO('@#21f759fbca');
  }

  addToSO(value: string) {
    let indexF:any = '';
    if (value.substring(0, 2) !='@#') {      
      indexF = this.productsAdded.findIndex((product: any) => product.barcode == value);
    }else{
      indexF = this.productsAdded.findIndex((product: any) => product.uuid == value);
    }
    if(indexF != -1){
      this.maxQtyAvailable(indexF,'search');
    }else{
      this.getSearchProdScanner(value);
    } 
  }
  getSearchProdScanner(barcode:any): Promise<any> {
    return new Promise((resolve, reject) => {

      this.stockItemsService.getSearchProdScanner({barcode:barcode, columnSearch:( barcode.substring(0, 2) !='@#'?'barcode':'uuid')})
      .pipe(takeUntil(this.destroy$))
      .subscribe(
          (res: any) => {
            //console.log(res);
            
            if(res.length > 0){

              
              // si es another lote, y escanea un barcode normal
              if(barcode.substring(0, 2) !='@#'){
                // obtego que tengas disponible en retail
                if(res[0].anotherLote == 1){
                  
                  
                  let filtered = res.filter((x:any)=>(x.retail - x.sold) > 0);
                  //si son mas que 2 retails, obtengo las distintas fechas
                  if(filtered.length > 1){
                    const distinctDates = Array.from(new Set(filtered.map(item => (item.dateExpired != null ? item.dateExpired : 'NoDate'))));
                    // Construir el contenido HTML para los radio buttons
                    const contenidoHtml = `
                    <form id="opcionesForm" style="display: flex;flex-wrap: wrap;">
                      ${distinctDates.map((opcion, index) => `
                        <input type="radio" name="opcion" value="${opcion}" id="opcion${index}">
                        <label for="opcion${index}" class="radio-cuadro"><span style="margin:auto">${(opcion != 'NoDate')?opcion:'No expiry date'}</span></label><br>
                      `).join('')}
                    </form>
                    `;

                    this.alertify.confirmDate(contenidoHtml,(e)=>{
                        console.log(e);
                      if(distinctDates.includes(e)){
                        //console.log(e);
                        
                        let indexDate = filtered.findIndex((x:any)=>x.dateExpired == (e == 'NoDate'?null:e));
                        if(indexDate != -1){
                          let item  = filtered[indexDate]
                          item.allLotes  = JSON.parse(JSON.stringify(res)) 
                          item.SOItemsID = this.global.guid()
                          item.lotesIds = [{id:item.storeProductId,qty:1}]
                          this.lastCodeChecked = item.productCode;
                          this.productsAdded.push(item);
                          this.codeToAdd.setValue('')
                          this.productsToSO.emit(this.productsAdded);
                          this.calculateTotals();
                        }else{
                          this.alertify.warning(this.translate.instant('DATE_NOT_FOUND'))
                        }

                      }else{
                        this.alertify.warning(this.translate.instant('NO_DATE_SELECTED'))
                      }

                    },()=>{})

                  }else{
                    // si solo tiene un record, se agrega el primero, y valido que tenga en retail, si no le alerto que solo tiene el warehouse
                    let filtered = res.filter((x:any)=>(x.retail - x.sold) > 0);
                    if(filtered.length > 0){
                      let item  = res[0]
                      item.allLotes  = JSON.parse(JSON.stringify(res)) 
                      item.SOItemsID = this.global.guid()
                      item.lotesIds = [{id:item.storeProductId,qty:1}]
                      this.lastCodeChecked = item.productCode;
                      this.productsAdded.push(item);
                      this.codeToAdd.setValue('')
                      this.productsToSO.emit(this.productsAdded);
                      this.calculateTotals();
                    }else{
                      this.alertify.warning(this.translate.instant('NO_PRODUCTS_ON_RETAIL'))
                    }
                  }


                }else{
                  // si no es anotherLote
                  //coloco el que tiene en retail y sea el mas antiguo, si no le advierto que no hay en retail
                  let filtered = res.filter((x:any)=>(x.retail - x.sold) > 0);
                  if (filtered.length > 0) {
                    let item  = filtered[0]
                    item.allLotes  = JSON.parse(JSON.stringify(res)) 
                    item.SOItemsID = this.global.guid()
                    item.lotesIds = [{id:item.storeProductId,qty:1}]
                    this.lastCodeChecked = item.productCode;
                    this.productsAdded.push(item);
                    this.codeToAdd.setValue('')
                    this.productsToSO.emit(this.productsAdded);
                    this.calculateTotals();
                  }else{
                    this.alertify.warning(this.translate.instant('NO_PRODUCTS_ON_RETAIL'))
                  }

                }


              // aqui esta la logica de un uuid
              }else{
                // haremos la misma logina de eleginar las fechas no, mas que sin la alerte y elgigiendolo de un solo, ya que es el uuid
                let filtered = res.filter((x:any)=>(x.retail - x.sold) > 0);
                                                   
                let indexDate = filtered.findIndex((x:any)=>x.uuid == barcode);
                if(indexDate != -1){
                  let item  = filtered[indexDate]
                  item.allLotes  = JSON.parse(JSON.stringify(res)) 
                  item.SOItemsID = this.global.guid()
                  item.lotesIds = [{id:item.storeProductId,qty:1}]
                  this.lastCodeChecked = item.productCode;
                  this.productsAdded.push(item);
                  this.codeToAdd.setValue('')
                  this.productsToSO.emit(this.productsAdded);
                  this.calculateTotals();
                }else{
                  this.alertify.warning(this.translate.instant('NOT_FOUND'))
                }
              }             
              

            }else{
              this.alertify.warning(this.translate.instant('PRODUCT_NOT_AVAILABLE'))
            }
          },
          (err:any)=>{
            console.log(err);            
          }
      );

    });
  }
  // Methods to barcode



  // Add Product Per Code or SKU
  addProductPerCode(){

    if(this.codeToAdd.valid){      
      // verify if product already exists
      let existSKUonAdded:any = this.productsAdded.filter((x:any)=>x.SKU == this.codeToAdd.value || x.productCode == this.codeToAdd.value)
      if(existSKUonAdded.length > 0){
        this.alertify.warning(this.translate.instant('PRODUCT_ADDED'));
        return;
      }

      this.stockItemsService.codeToAdd(this.codeToAdd.value)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
          (res: any) => {
            if(res.length > 0){

              // si es another lote
              // obtego que tengas disponible en retail
              if(res[0].anotherLote == 1){
                
                
                let filtered = res.filter((x:any)=>(x.retail - x.sold) > 0);
                //si son mas que 2 retails, obtengo las distintas fechas
                if(filtered.length > 1){
                  const distinctDates = Array.from(new Set(filtered.map(item => (item.dateExpired != null ? item.dateExpired : 'NoDate'))));

                  //console.log(distinctDates);
                  
                  // Construir el contenido HTML para los radio buttons
                  const contenidoHtml = `
                  <form id="opcionesForm" style="display: flex;flex-wrap: wrap;">
                    ${distinctDates.map((opcion, index) => `
                      <input type="radio" name="opcion" value="${opcion}" id="opcion${index}">
                      <label for="opcion${index}" class="radio-cuadro"><span style="margin:auto">${opcion != 'NoDate' ? opcion : 'No expiry date'}</span></label><br>
                    `).join('')}
                  </form>
                  `;

                  this.alertify.confirmDate(contenidoHtml,(e)=>{                   
                    

                    if(distinctDates.includes(e)){
                      console.log(e);
                      
                      let indexDate = filtered.findIndex((x:any)=>x.dateExpired == (e == 'NoDate'?null:e));
                      //console.log(indexDate);
                      
                      if(indexDate != -1){
                        let item  = filtered[indexDate]
                        item.allLotes  = JSON.parse(JSON.stringify(res)) 
                        item.SOItemsID = this.global.guid()
                        item.lotesIds = [{id:item.storeProductId,qty:1}]
                        this.lastCodeChecked = item.productCode;
                        this.productsAdded.push(item);
                        this.codeToAdd.setValue('')
                        this.productsToSO.emit(this.productsAdded);
                        this.calculateTotals();
                      }else{
                        this.alertify.warning(this.translate.instant('DATE_NOT_FOUND'))
                      }

                    }else{
                      this.alertify.warning(this.translate.instant('NO_DATE_SELECTED'))
                    }

                  },()=>{})

                }else{
                  // si solo tiene un record, se agrega el primero, y valido que tenga en retail, si no le alerto que solo tiene el warehouse
                  let filtered = res.filter((x:any)=>(x.retail - x.sold) > 0);
                  if(filtered.length > 0){
                    let item  = res[0]
                    item.allLotes  = JSON.parse(JSON.stringify(res)) 
                    item.SOItemsID = this.global.guid()
                    item.lotesIds = [{id:item.storeProductId,qty:1}]
                    this.lastCodeChecked = item.productCode;
                    this.productsAdded.push(item);
                    this.codeToAdd.setValue('')
                    this.productsToSO.emit(this.productsAdded);
                    this.calculateTotals();
                  }else{
                    this.alertify.warning(this.translate.instant('NO_PRODUCTS_ON_RETAIL'))
                  }
                }


              }else{
                // si no es anotherLote
                //coloco el que tiene en retail y sea el mas antiguo, si no le advierto que no hay en retail
                let filtered = res.filter((x:any)=>(x.retail - x.sold) > 0);
                if (filtered.length > 0) {
                  let item  = filtered[0]
                  item.allLotes  = JSON.parse(JSON.stringify(res)) 
                  item.SOItemsID = this.global.guid()
                  item.lotesIds = [{id:item.storeProductId,qty:1}]
                  this.lastCodeChecked = item.productCode;
                  this.productsAdded.push(item);
                  this.codeToAdd.setValue('')
                  this.productsToSO.emit(this.productsAdded);
                  this.calculateTotals();
                }else{
                  this.alertify.warning(this.translate.instant('NO_PRODUCTS_ON_RETAIL'))
                }

              }            
            }else{
              this.alertify.warning(this.translate.instant('PRODUCT_NOT_AVAILABLE'))
            }
          },
          (err:any)=>{
            console.log(err);            
          }
      );  
    }

  }






  // get allLotes
  maxQtyAvailable(index:any,from:any = ''){

    // si en menos de 5 segundos, quiere comprobar el mismo producto, usaremos el allLotes, del json del producto
    // pero se si pasa de ese tiempo, se consulta a la base de datos

    // si es diferente es porque ya pasaron los 5 s, o es un producto diferente, SE CONSULTA EN LA DB
    if(this.lastCodeChecked != this.productsAdded[index]['productCode']){
      this.lastCodeChecked = this.productsAdded[index]['productCode']
      this.stockItemsService.codeToAdd(this.productsAdded[index]['productCode'])
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        async ( res: any) =>  {
            this.checkMaxQtyAvailable(res,index,from)
        },
        (err:any)=>{
          console.log(err);            
        }
      ); 
    // cuando es el mismo, se comprueba que tiene allLotes, y si es asi, comprobamos con ese array, sino SE CONSULTA EN LA DB
    }else{

      if('allLotes' in this.productsAdded[index]){
        this.checkMaxQtyAvailable(this.productsAdded[index]['allLotes'],index,from)
      }else{
        this.lastCodeChecked = this.productsAdded[index]['productCode']
        this.stockItemsService.codeToAdd(this.productsAdded[index]['productCode'])
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          async ( res: any) =>  {
              this.checkMaxQtyAvailable(res,index,from)
          },
          (err:any)=>{
            console.log(err);            
          }
        ); 
      }

    }

    // siempre limpiamos el timer
    clearTimeout(this.recarga)
    this.recarga = setTimeout(()=>{    
      this.lastCodeChecked = '';
    }, 5000); 

  }


  checkMaxQtyAvailable(res:any,index:any,from:any){
    //suma la cantidad maxima que se puede agregar manualmente al item, si coinciden el precio de venta
    this.productsAdded[index]['allLotes'] = JSON.parse(JSON.stringify(res))
    let itemlotes:any = JSON.parse(JSON.stringify(res));
    this.maxQtyAllLotes = 0
    this.maxQtyItem = 0

    this.maxQtyAllLotes = itemlotes.reduce(function(acumulador, objeto) {
      return acumulador + (objeto['stockQty'] - objeto['sold'])
    }, 0);

    // restar la suma que ya esta agregada en lso products Added
    // let prodAddedWithProdCode = this.productsAdded.filter((x:any)=>x.productCode == this.productsAdded[index]['productCode']);
    // if(prodAddedWithProdCode.length > 1){
    //   let prodCodExists = prodAddedWithProdCode.reduce(function(acumulador, objeto) {
    //     return acumulador + objeto['quantityNum']
    //   }, 0);      
    //   this.maxQtyAllLotes = this.maxQtyAllLotes - prodCodExists + this.productsAdded[index]['quantityNum'];
    // }

    let itemLotesFil:any = itemlotes.filter((x:any)=>this.productsAdded[index]['priceNumUnit'] == x['priceNumUnit'] && x['retail']> 0)
    this.maxQtyItem = itemLotesFil.reduce(function(acumulador, objeto) {
      return acumulador + objeto['retail'] - objeto['sold']
    }, 0);


    //console.log("max all lotes "+this.maxQtyAllLotes);       
    //console.log("max qty "+this.maxQtyItem);  

    if(from == 'search'){
      this.productsAdded[index].quantityNum =  parseInt(this.productsAdded[index].quantityNum) + 1  
      this.onQuantityChange(index)
      this.calculateTotals();
    }
  }


  // change qty on list,
  async onQuantityChange(index) {

    let qtyInput =  this.productsAdded[index]['quantityNum'];

    if (qtyInput > this.maxQtyAllLotes) {
      qtyInput = this.maxQtyItem
      this.alertify.warning(this.translate.instant('MAX_PRODUCTS_RETAIL')+this.maxQtyAllLotes+', '+this.maxQtyItem+'.')
    }

    if (qtyInput > this.maxQtyItem && qtyInput <= this.maxQtyAllLotes) {
      qtyInput = this.maxQtyItem
      this.alertify.warning(this.translate.instant('MAX_PRODUCTS_WAREHOUSE')+this.maxQtyItem+'. '+this.translate.instant('FIND_MORE_PRODUCTS'))
    }
    
    if (qtyInput > this.maxQtyItem) {
      qtyInput = this.maxQtyItem
    }

    this.productsAdded[index]['quantityNum'] = qtyInput

    let qtyres = qtyInput   
      
    let itemlotes:any = this.productsAdded[index]['allLotes'];
    let itemLotesFil:any = itemlotes.filter((x:any)=>this.productsAdded[index]['priceNumUnit'] == x['priceNumUnit'])
    let storeProductIdArray = []
    
    // aqui hago la logica que si solo tiene un lotesId, ordeno este arreglo con esa fecha, y del segundo en delante, en orden de fecha
  
      let date = this.productsAdded[index]['dateExpired'];

      console.log(date);
      

      itemLotesFil.sort((a, b) => {
        if (a.dateExpired === date) {
          return -1; // Poner el objeto al principio
        } else if (b.dateExpired === date) {
          return 1; // Poner el objeto al principio
        } else {          
          return a.dateExpired.localeCompare(b.dateExpired);// Si las fechas no son iguales, ordenar según la fecha
        }
      });    

    for(const objeto of itemLotesFil){
      if (qtyres > objeto['retail'] - objeto['sold']) {
        storeProductIdArray.push({id:objeto.storeProductId,qty:(objeto['retail'] - objeto['sold'])})         
        qtyres -= (objeto['retail'] - objeto['sold'])
      }else{
        storeProductIdArray.push({id:objeto.storeProductId,qty:qtyres})
        break;
      }
    }
    
    this.productsAdded[index]['lotesIds'] = storeProductIdArray      

    this.calculateTotals();
    this.productsToSO.emit(this.productsAdded);
  }


  // activamos el input Qty, y obtenemos la cantidad maxima disponible
  toggleEditMode(index: number) {
    this.editMode[index] = true;
    this.maxQtyAvailable(index);
  }

  // activamos el input Qty, y obtenemos la cantidad maxima disponible, pero en mobile
  onTouchStart(event: TouchEvent, index:any) {
    event.preventDefault();
    event.stopPropagation();
    this.timeout = setTimeout(() => {
    this.editMode[index] = true;
      this.maxQtyAvailable(index);
    }, 500); 
  }

  onTouchEnd() {
    clearTimeout(this.timeout);
  }

  onInputBlur(index: number) {
    this.editMode[index] = false;
  }

  calculateTotals(){

    let subTotalOrderSum:any=0
    let totalTaxNum:any=0   
    
    if(this.productsAdded != undefined){

      this.productsAdded.forEach(element => {
        subTotalOrderSum += element.quantityNum*this.global.pNumber(element.priceNumUnit);
        
        totalTaxNum += element.taxable == 1 
        ? (
            (element.taxNumber != null && element.taxNumber != '' && element.taxNumber != 0) 
            ? (element.quantityNum*this.global.pNumber(element.priceNumUnit)) * (element.taxNumber/100)
            : (element.quantityNum*this.global.pNumber(element.priceNumUnit)) * (this.taxNumber/100)
          )        
        : 0;
      });

    }
    
    let totales:any = {      
      subTotalOrderSum: this.global.pNumber(subTotalOrderSum),
      totalTaxNum:this.global.pNumber(totalTaxNum),
      Total:this.global.pNumber(subTotalOrderSum+totalTaxNum),
    }

    this.totalsfromItemsToSO.emit(totales);

  }

  removeitem(i: any) {
    this.lastCodeChecked = ''
    this.productsAdded.splice(i, 1);
    this.calculateTotals();
    this.productsToSO.emit(this.productsAdded);
  }

  ngOnDestroy(): void {    
    this.subscription.unsubscribe();
  }

}