
  <div class="sale-order-items-container" *ngFor="let soitem of salesOrderItems; let i = index">
    <div class="sale-order-items-container-items" *ngIf="soitem.itemType != 'discount'">
      
      <div class="sale-order-items-container-items-description">
        <div class="pt-1 col-1 col-sm-1 col-md-1 col-lg-1 col-xl-1">
          <div class="sale-order-items-container-items-close">
            <div *ngIf="!canSelect" class="sale-order-items-container-items-close-content" (click)="deleteItem(soitem.SOItemsID)">
              <span class="material-icons">
                delete
                </span>
            </div>
          </div>
        </div>
        <div class="pt-1 col-5 col-sm-5 col-md-5 col-lg-2 col-xl-2"><p class="m-0">{{ soitem.manufacter | uppercase }}</p></div>
        <div class="pt-1 col-12 col-sm-12 col-md-12 col-lg-6 col-xl-6"><p class="m-0">{{ soitem.styleName }}</p></div>
        <div class="pt-1 col-12 col-sm-12 col-md-12 col-lg-3 col-xl-3"><p class="m-0">{{ soitem.sizeName }}</p></div>
      </div>
      <div class="sale-order-items-container-items-content">
        <div class="pt-1 col-xs-3 col-sm-4 col-md-4 col-lg-3 col-xl-2">
          <div class="d-flex align-items-center" style="height: 100%;">
            <p class="m-0">{{ soitem.Type }}</p>
          </div>
        </div>
        <div class="pt-1 col-xs-9 col-sm-8 col-md-8 col-lg-9 col-xl-6">
          <div class="select-sale-order-container-from">
            <p class="m-0">{{ soitem.SKU }}</p>
            <br>
            <p class="m-0" style="padding-left: 5px;" *ngIf="canSelect">{{ soitem.itemDelivery }}</p>
              <select
                *ngIf="!canSelect"
                type="text"
                class="form-control"
                [ngClass]="{ 'is-invalid': soitem.itemDelivery === undefined }"
                [(ngModel)]="soitem.itemDelivery"
                (change)="changeToDs(soitem)"
                placeholder="Delivery"
              >
              <option disabled value="" hidden selected>Inventory status</option>

                <option>{{ "NTO" }}</option>
                <option>{{ "Taken" }}</option>
                <option>{{ "Direct Shipment" }}</option>
                <option>{{ "In Stock" }}</option>
                <!-- <option>{{ "Delivery NTO" }}</option>
                <option>{{ "Delivery In Stock" }}</option> -->
              </select>
            <div class="invalid-feedback">Inventory Status</div>
          </div>
        </div>
        <div class="pt-1 col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-4">
          <div class="select-qty-price-container">
            <div class="quantity-input">
              <button
                class="btn btn-outline btn-circle z-depth-1 qties d-none"
                (click)="soitem.quantityNum = soitem.quantityNum - 1"
              >
                -
              </button>
              <input
                [readonly]="canSelect"
                class="form-control"
                type="number"
                min="0"
                id="quantity"
                (blur)="
                  onQtyChange($event.target.value, soitem, soitem.priceNumUnit)
                "
                required
                [(ngModel)]="soitem.quantityNum"
              />
              <button
                class="btn btn-outline btn-circle z-depth-1 qties d-none"
                (click)="addQty(soitem)"
              >
                +
              </button>
            </div>
    
            <div class="price-input">
              <div class="price-input-container">
                <span class="material-icons">
                  local_offer
                </span>
                <input
                  [readonly]="canSelect"
                  class="form-control"
                  min="0"
                  type="number"
                  id="salesprice"
                  (blur)="onQtyChange(soitem.quantityNum, soitem, $event.target.value)"
                  required
                  [(ngModel)]="soitem.priceNumUnit"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sale-order-items-container-items-footer">
        <div class="col-md-12 col-lg-8 col-xl-8">
          <div *ngIf="!canSelect" class="d-flex flex-wrap">
            <p class="btn btn-8 mr-2" (click)="addDiscountItem(soitem)" >
              <span style="font-size: 15px;font-weight: 700;">
                %
                </span> Discount
            </p>

            <!-- <p class="sale-order-items-container-items-footer-remove" (click)="deleteItem(soitem.SOItemsID)">
              <span class="material-icons">
              remove_circle_outline
              </span> Remove
            </p> -->

            <p
              class="btn btn-8"
              *ngIf="
                soitem.itemDelivery == 'Direct Shipment' ||
                soitem.itemDelivery?.includes('Delivery')
              "
              (click)="showDs(soitem)"
            >
              <span class="material-icons">
                account_box
              </span>
              Address
            </p>
          </div>
        </div>
        <div class="col-md-12 col-lg-4 col-xl-4">
          <p class="sale-order-items-container-items-footer-number">
            <span class="material-icons">
              attach_money
            </span>
            {{ soitem.priceNumUnit * soitem.quantityNum | number: "1.2-2" }}
          </p>
        </div>
      </div>
     
    </div>
    <!-- Hacer Cuando se pone en Discount -->

<div class="sale-order-items-container-discount" *ngIf="soitem.itemType == 'discount'" [ngClass]="{ active: soitem.manufacter == 'ORDER DISCOUNT' , '': alert }">

    <div class="pt-2 col-xl-1">
      <div class="sale-order-items-container-items-close">
        <div *ngIf="!canSelect" class="sale-order-items-container-items-close-content" (click)="deleteItem(soitem.SOItemsID)">
          <span class="material-icons">
            delete
            </span>
        </div>
      </div>
    </div> 
    
    <div class="pt-2 col-xl-11">{{ soitem.manufacter | uppercase }}</div>
    <div class="col-12 col-lg-6 col-xl-5 pt-2">
        <div *ngIf="!isIOS" class="sale-order-detail-content-form-input-container">
          <ng-template
            #customItemTemplate
            let-model="item"
            let-index="index"
            class=""
          >
            {{ model }}
          </ng-template>
          
          <input
            [readonly]="canSelect"
            [(ngModel)]="soitem.styleName"
            [typeahead]="referralTypes"
            [typeaheadOptionsLimit]="20"
            [typeaheadMinLength]="0"
            placeholder="Discount"
            [typeaheadItemTemplate]="customItemTemplate"
            class="form-control sale-input"
            style="text-transform: none;"
          />
          <div class="invalid-feedback">Please choose a Sales Person</div>
        </div>


        <div *ngIf="isIOS" class="sale-order-detail-content-form-input-container">    
          <input
            [readonly]="canSelect"
            [(ngModel)]="soitem.styleName"
            placeholder="Discount"
            class="form-control sale-input"
            style="text-transform: none;"
          />
        </div>



        <!-- <input [readonly]="canSelect" class="form-control " style="text-align: left;" placeholder="Discount Description" [(ngModel)]="soitem.styleName"> -->
    </div>
    <!-- <div class="pt-2 col-12 col-lg-6 col-xl-3"> -->
      <!-- <div class="pt-2 col-xl-3">{{ soitem.manufacter | uppercase }}</div> -->
      <div class="pt-2 col-12 col-lg-6 col-xl-4">
      <div class="price-input-container">
        <input
          [readonly]="canSelect"
          class="form-control"
          type="number"
          min="0"
          max="100"
          id="PDis"
          (keyup)="onPDisChange($event.target.value, soitem, soitem.PDis)"
          (change)="onPDisChange($event.target.value, soitem, soitem.PDis)"
          
          required
          [(ngModel)]="soitem.PDis"
        />
        <span class="ml-1">
          %
        </span>
      </div>
    </div>
  
    <div class="pt-2 col-12 col-lg-6 col-xl-3">
      <div class="price-input-container">
        <span class="material-icons">
          local_offer
        </span>
        <input
          [readonly]="canSelect"
          class="form-control"
          min="0"
          type="number"
          id="salesprice"
          required
          (keyup)="onValueDiscountChange($event.target.value, soitem, soitem.PDis)"
          [(ngModel)]="soitem.priceNumUnit"
        />
      </div>
      
    </div>
  
    <div class="pt-2 col-xl-12">
      <div class="discount-price">
        <p>
          <span class="material-icons">
            attach_money
          </span>
          {{ soitem.priceNumUnit * soitem.quantityNum | number: "1.2-2" }}
        </p>
      </div>
    </div>
  
    <!-- <div class="pt-2 col-12">
      <p
        class="remove-discount"
        (click)="deleteItem(soitem.SOItemsID)"
      >
        <span class="material-icons">
        remove_circle_outline
        </span> Remove Discount
      </p>
    </div> -->
    
  </div>

    
  </div>


<!-- <div *ngFor="let soitem of salesOrderItems; let i = index"> -->
  
<!-- </div> -->

<!-- <app-gridtable (header)="['SONumber']" (data)="salesorders"></app-gridtable> -->
