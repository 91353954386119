import { Component, OnInit, OnDestroy, Input, DoCheck, TemplateRef, ViewChild, ElementRef } from '@angular/core';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { SettingsService } from 'src/app/_services/settings.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import 'rxjs/add/operator/takeUntil';
import { GlobalsService } from 'src/app/_services/globals.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ObjectUnsubscribedError, Subject, of } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { log } from 'console';
import { data, get } from 'jquery';
import { StoreUsersService } from 'src/app/_services/storeusers.service';
import { TranslateService } from '@ngx-translate/core';
import { CurrencyService } from 'src/app/_services/currency.service';
import { LanguageService } from 'src/app/_services/language.service';


interface CloverCred {enviromentClover: string; appID: string; appSecret: string;}
@Component({
  selector: 'app-storeconfiguration',
  templateUrl: './storeconfiguration.component.html',
  styleUrls: ['./storeconfiguration.component.scss']
})
export class StoreconfigurationComponent implements OnInit, OnDestroy {
  constructor(public authService: AuthService,private alertify: AlertifyService,
    private settingsService: SettingsService,
    private storeUserService : StoreUsersService,
    public bsModalRef: BsModalRef,
    private global: GlobalsService,
    private modalService: BsModalService,
    private translate: TranslateService,
    private currencyService: CurrencyService,
    private languageService: LanguageService,
    ) {}
  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('modalAuthClover', {static: false}) modalAuthClover: TemplateRef<any>;
  @Input() show = 'Dealer';
  targetEnv = 'https://sandbox.dev.clover.com'; // Pointing to Sandbox Environment
  appID = 'C97F8310DHSBT'; // Input your app ID here
  appSecret = 'ca1cd584-2b65-0162-b24f-b01c82d7c4c3'; // Input your app secret here
  avalaraStatus : any = {}
  userRole = this.authService.decodeToken('role').role;
  drop = false;
  cloverUrl: string = "" 
  settings: any;
  cloverWindow: any;
  version: string = environment.appVersion; 
  tokenClover = '';
  locations:any = [];
  DevicesToDelete:any = [];
  territory = new FormControl('');
  form = new FormGroup({});
  model = {
    devices: []
  };
  cloverCredentials! : CloverCred
  formAvalaraConfig = new FormGroup({});
  formCloverConfig = new FormGroup({});
  formCloverDevices = new FormGroup({});
  typeAedPass:any = true;
  typeDDSPass:any = true;
  avalaraConnnected: boolean = false
  typeCorporate:any = this.authService.decodeToken('corporateType').corporateType
  isEditing: boolean = false;

	showModal: boolean  = false
  termsAccepted: boolean = false;
  isLocked: boolean = false
	@ViewChild('methodTemplate', { static: false }) popTemplateiOs: TemplateRef<any>;
  modalRef: BsModalRef;

  valueOldSelect = '';
  @ViewChild('inputLoc')  inputLoc: ElementRef | any;

  fields: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldGroup: [

        {
          key: 'PrincipalStore',
          type: 'input',
          className: 'col-md-6 tw-font-bold',
          templateOptions: {
            type: 'text',
            label: 'Location/Territory'
          }
        },
    
      {
          key: 'ROID',
          type: 'input',
          className: 'col-md-6 tw-font-bold',
          templateOptions: {
            type: 'text',
            label: 'ROID',
            disabled: true
          }
        },   
        {
          key: 'companyName',
          type: 'input',
          className: 'col-md-6 tw-font-bold',
          templateOptions: {
            type: 'text',
            label: 'Legal Name'
          }
        }, 
        {
          key: 'name',
          type: 'input',
          className: 'col-md-6 tw-font-bold',
          templateOptions: {
            type: 'text',
            label: 'Dealer Name'
          }
        },
        {
          key: 'username',
          type: 'input',
          className: 'col-md-6 tw-font-bold',
          hide: this.isdealer(),
          templateOptions: {
            type: 'text',
            label: 'User Name',
            disabled: true
          }
        },
        {
          key: 'password',
          type: 'input',
          className: 'col-md-6 tw-font-bold',
          hide: this.isdealer(),
          templateOptions: {
            type: 'password',
            label: 'Password'
          }
        },    
        // {
        //   key: 'ddsDealerToken',
        //   type: 'textarea',
        //   className: 'col-md-6 tw-font-bold',
        //   hide: this.isdealer(),
        //   templateOptions: {
        //     type: 'text',
        //     label: 'DDS Token',
        //     disabled: true
        //   }
        // },
        {
          key: 'email',
          type: 'input',
          className: 'col-md-12 tw-font-bold',
          hide: this.isdealer(),
          templateOptions: {
            type: 'text',
            label: 'Email',
          }
        },
        {
          key: 'contactInfo',
          type: 'textarea',
          className: 'col-md-12 tw-font-bold',
          templateOptions: {
            type: 'text',
            label: 'Contact Info',
            rows: 4
          }
        },
        {
          key: 'frecuencyNotiTime',
          type: 'input',
          className: 'col-md-6 tw-font-bold',
          templateOptions: {
            label: 'Amount time',
            type: 'select',
            required: true,
            options: [{ value: '1', label : '1' }, { value: '2',  label : '2' }, { value: '3',  label : '3' }, { value: '4',  label : '4' }, { value: '5',  label : '5' }]
          }
        },
        {
          key: 'frecuencyNoti',
          type: 'input',
          className: 'col-md-3 tw-font-bold',
          templateOptions: {
            type: 'select',
            label: 'Format time',
            required: true,
            options: [{ value: 'hours', label : 'Hours' },{ value: 'days', label : 'Days' }, { value: 'weeks',  label : 'Weeks' }]
          }
        },
      ]
    }
  ];

// newform Dealers
dealerForm = new FormGroup({
  PrincipalStore: new FormControl('',[]),
  ROID:new FormControl('',[]),
  companyName: new FormControl('',[]),
  name: new FormControl('',[]),
  //username: new FormControl('',[]),
  ddsDealerToken: new FormControl('',[]),
  password: new FormControl('',[]),
  contactInfo: new FormControl('',[]),
  email: new FormControl('',[]),
  //to set the verification code duration
  frecuencyNotiTime: new FormControl('',[]),
  frecuencyNoti: new FormControl('',[]),
  inventoryMethod: new FormControl('', []),
  invoiceHeader: new FormControl('', []),
  invoiceFooter: new FormControl('', []),
  language: new FormControl('', []),
  currency: new FormControl('', []),
});

//Form to send email code and switchdatabase
changeStore = new FormGroup({
  ROID:new FormControl('',[]),
  username: new FormControl('',[]),
  email: new FormControl('',[]),
  role: new FormControl('',[]),
  dbServerUser: new FormControl('',[]),
  dbServer: new FormControl('',[]),
  setDB: new FormControl('',[]),
});
  charCount: number
  charCountFooter: number

  isdealer():boolean{
    if(this.userRole != 'Dealer'){
      return true;
    }else{
      return false;
    }
  }
  

  fieldscarddata: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'accountId',
          type: 'input',
          className: 'col-md-12 tw-font-bold',
          templateOptions: {
            type: 'text',
            label: 'Account Number',
            placeholder: 'Ex: 2000453020'
          }
        },
        {
          key: 'licenseKey',
          type: 'input',
          className: 'col-md-12 tw-font-bold',
          templateOptions: {
            type: 'text',
            label: 'License Key',
            placeholder: 'Ex: JJ2111FAC633693K'
          }
        },
        {
          key: 'enviroment',
          type: 'select',
          className: 'col-md-12 tw-font-bold',
          templateOptions: {
            required: true,
            options: [
              {name: "Production", value: "production"},
              {name: "Sandbox", value: "sandbox"},
            ],
            label: 'Enviroment',
            valueProp: 'value',
            labelProp: 'name'
          }
        },
        {
          key: 'companyCode',
          type: 'input',
          className: 'col-md-12 tw-font-bold',
          templateOptions: {
            type: 'text',
            label: 'Company Code',
            placeholder: 'Ex: MiamiTrees'
          }
        }
      ]
    },
  ];

  fieldsAvalaraConfig: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'avaTax',
          type: 'checkbox',
          className: 'col-md-12 tw-font-bold',
          templateOptions: {
            type: 'number',
            label: 'Enable Avatax',
          }
        },
        {
          key: 'DocumentRecording',
          type: 'checkbox',
          className: 'col-md-12 tw-font-bold',
          templateOptions: {
            type: 'text',
            label: 'Control Document Record on Avatax',
          }
        },
        {
          key: 'avataxLogging',
          type: 'checkbox',
          className: 'col-md-12 tw-font-bold',
          templateOptions: {
            type: 'number',
            label: 'Enable logging',
          }
        },
        {
          key: 'defaultFreightCode',
          type: 'input',
          className: 'col-md-12 tw-font-bold',
          templateOptions: {
            type: 'text',
            label: 'Default Freight Code',
            placeholder: 'Ex: FR020100'
          }
        },
      ]
    },
  ];

  fieldsCloverConfig: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'cloverState',
          type: 'checkbox',
          className: 'col-md-12 tw-font-bold',
          templateOptions: {
            type: 'number',
            label: this.translate.instant('ENABLE_CLOVER'),
          }
        },
        {
          key: 'authClover',
          type: 'input',
          hide: true,
          templateOptions: {
            type: 'text',
            required: false
          }
        },
      ]
    },
  ];

  fieldsCloverDevices: FormlyFieldConfig[] = [
    {
      key: 'devices',
      type: 'repeatsmall',
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row ',
      fieldArray: {
        
        fieldGroupClassName: 'row ',
        templateOptions: {
          btnText: this.translate.instant('ADD_DEVICE'),
          add:  () => {},
          track: () => {},
          removePayment: (x) =>{ 
            if(this.model.devices[x]['UUID'] != null){
              this.DevicesToDelete.push(this.model.devices[x])
            } 
          }
        },
      fieldGroup: [
        {
          key: 'UUID',
          type: 'input',
          defaultValue: '',
          hide: true,
          lifecycle: {
            onInit: (form, field) => {
              if ( field.formControl.value === '') {
                field.model['UUID'] = this.global.guid();
                field.formControl.setValue(this.global.guid());
              }
            },
          },
        },
      {
        key: 'name',
        type: 'input',
        className: 'col-md-3',
        templateOptions: {
          required: true,
          label: this.translate.instant('NAME'),
        }
      }
    , {
      key: 'Type',
        type: 'select',
        className: 'col-md-3',
        templateOptions: {
          label: this.translate.instant('TYPE'),
          placeholder: '',
          required: true,
          options: [
            {label: 'Station solo', value: 'stationWhite'},
            {label: 'Station duo', value: 'stationDuoWhite'},
            {label: 'Flex', value: 'flexWhite'},
            {label: 'Mini', value: 'miniWhite'},
            {label: 'Mobile', value: 'mobileWhite'}
          ],
        },
      },    
      {
        key: 'deviceId',
          type: 'input',
          className: 'col-md-3',
          templateOptions: {
            type: 'text',
            required: true,
            label: this.translate.instant('DEVICE_ID'),
          },
        },
        {
          key: 'PlatForm',
            type: 'input',
            className: 'col-md-2',
            templateOptions: {
              type: 'text',
              label: this.translate.instant('PLATFORM'),
            },
        }  
  ],
  
  },
  }
  ];
  

    selectLocation(event:any){      
      
      const local = this.locations.findIndex((x:any)=>x.ROID==this.territory.value);
      let DB = '';
      if ( local != -1 ){
        DB = this.locations[local].dbServer;
      }
      this.changeStore.controls['setDB'].setValue(DB);
      this.changeStore.controls['role'].setValue(this.authService.decodeToken('role').role);
      this.changeStore.controls['username'].setValue(this.authService.decodeToken('username').username);
      this.changeStore.controls['dbServerUser'].setValue(this.authService.decodeToken('dbServerUser').dbServerUser);
      this.changeStore.controls['dbServer'].setValue(this.authService.decodeToken('dbServer').dbServer);
      this.changeStore.controls['email'].setValue(this.authService.decodeToken('email').email);
      this.changeStore.controls['ROID'].setValue(this.locations[local].ROID);

      let token = '';
      let showAlertify = false;
      let switchDatabase = false;

      //Send email with code verification
      this.settingsService.emailVerification(this.changeStore.value)
      .subscribe(response => {
        //Get data from response
        token = response['token'];
        showAlertify = response['showAlertify'];
        switchDatabase = response['switchDatabase'];
        const createdAt = response['created_at'];
        localStorage.setItem('emailCode', response['code']);

        const territorySelected = this.territory.value;
        const dbname = this.locations.find((element)=>{return element.ROID == territorySelected}).dbServer;
        const ROID = this.changeStore.controls['ROID'].value;
        const username = this.changeStore.controls['username'].value;
        const dbServerUser = this.changeStore.controls['dbServerUser'].value;
        const dbServer = this.changeStore.controls['dbServer'].value;
        const role = this.changeStore.controls['role'].value;
        const setDB = this.changeStore.controls['setDB'].value;
        const email = this.changeStore.controls['email'].value;
        const amount = this.dealerForm.controls['frecuencyNotiTime'].value;
        const format = this.dealerForm.controls['frecuencyNoti'].value;

        const code = this.authService.decodeEmailCode('codeIncomplete').codeIncomplete;
      
        //showAlertify and switchDatabase
        if (showAlertify){
          this.alertify.promptTimer('', this.translate.instant('INSERT_CODE'), '', 120,
          (evt, value) => {
            if(value == code){   
                const object={
                  value, 
                  tokenEncode: token,
                  dbname,
                  createdAt,
                  ROID,
                  username,
                  dbServerUser,
                  role,
                  email,
                  setDB,
                  dbServer,
                  amount,
                  format
                }
                
                this.territory.disable()
                this.authService.switchdatabase(object)
                .toPromise()
                .then((data:any)=>{
                  localStorage.removeItem('auth_token')
                  localStorage.removeItem('infoCorp')
                  localStorage.setItem('auth_token', data.tokenString)
                  this.authService.loadVariable(data.tokenString)
                  
                  this.territory.enable()
                  this.authService.showunsavedSett.next('Yes')
                  this.authService.changeavatar.next('Yes')
                  this.loadSettings();    
                  this.valueOldSelect = event.target.value 
                })
                .catch((err)=>{
                  // console.log(err)
                  this.territory.enable()
                })
            }else{
              //console.log(value);
              
              this.inputLoc.nativeElement.value = this.valueOldSelect;
              this.alertify.error(this.translate.instant('VERIFICATION_CODE_ERROR'));
            }
          },
          () => {        
            this.inputLoc.nativeElement.value = this.valueOldSelect;
            this.alertify.error(this.translate.instant('CODE_EXPIRED'));
          });

          setTimeout(() => {
            this.alertify.close();
            this.inputLoc.nativeElement.value = this.valueOldSelect;
            //this.alertify.error('Code expired');
          }, 120000);
        }else {
          localStorage.removeItem('auth_token')
          localStorage.removeItem('infoCorp')
          localStorage.setItem('auth_token', response['tokenString'])
          this.authService.loadVariable(response['tokenString'])             

          this.territory.enable()
          this.authService.showunsavedSett.next('Yes')
          this.authService.changeavatar.next('Yes')
          this.valueOldSelect = event.target.value 
          this.loadSettings();  
        }
      },
      (err)=>{
        this.inputLoc.nativeElement.value = this.valueOldSelect;
      }) 
    }




  alreadySet(event:any){
    // console.log(event)
    if(localStorage.getItem('code') != undefined){
      this.tokenClover = localStorage.getItem('code') || ''
      // console.log(this.tokenClover)
      this.alertify.success('token set')
    }
  }

  ngOnInit() {     
    this.authService.getLocations({'dbServerC':this.authService.decodeToken('dbServerC').dbServerC}).
    toPromise()
    .then((data:any[])=>{
      //console.log(data);
      
    if(data.length > 0){
      let roidrow = data.filter((x:any)=> x.dbServer == this.authService.decodeToken('dbServer').dbServer); 
      this.territory.setValue(roidrow[0].ROID)
      this.valueOldSelect = roidrow[0].ROID


      if(this.userRole != 'Dealer'){
        let allLoc = JSON.parse(this.authService.decodeToken('user').user).allLocations;
        if(allLoc == 1){
          this.locations = data        
        }else{
          let onlyLocation = [];
          let locUser:any = JSON.parse(this.authService.decodeToken('user').user).locations;
          if(locUser != null){
            data.forEach(element => {
              if(locUser.includes(element.dbServer)){
                onlyLocation.push(element)
              }         
            }); 
            this.locations = onlyLocation;     
          }
        }
      }else{
        this.locations = data
      }
    }
    })
    .catch((err)=>{console.log(err)})


    this.authService.showunsavedSett.next('Yes')
    this.loadSettings();

    //console.log(this.userRole);

    this.translate.get(['ENABLE_CLOVER', 'ADD_DEVICE', 'NAME', 'TYPE', 'DEVICE_ID', 'PLATFORM'])
    
  }

  getcloverCredentials():void{
    this.settingsService.getCloverCredentials()
    .pipe(takeUntil(this.destroy$))   
    .subscribe((data: CloverCred)=>{
      // console.log(data)
      this.cloverCredentials = data
      this.getCodeCloverFromUrl()
    })
  }

  pingConnectionAvalara():void{
    this.authService.pingAvalara()
    .pipe(takeUntil(this.destroy$))   
    .subscribe((data: any) => {
      this.avalaraStatus = data;
      localStorage.setItem('avatax', data)
      if(this.avalaraStatus?.authenticated) {
        this.alertify.alert('Connection Successful', ()=>{});
        this.avalaraConnnected = true;
      }else{
        this.alertify.alert('Error, Check your credentials', ()=>{});
        this.avalaraConnnected = false;
      } 
    })
  }

  loadSettings() {
    this.settingsService.getSettings(0)
    .pipe(takeUntil(this.destroy$))   
      .subscribe(data => {
        this.settings = data[0];
        if (this.settings) {
          this.settings['DocumentRecording'] = this.settings['DocumentRecording'] == '0' ? false: true;
          this.settings['avaTax'] = this.settings['avaTax'] == '0' ? false: true;
          this.settings['cloverState'] = this.settings['cloverState'] == '0' ? false: true;
          if(!this.isdealer()){
            this.loaddealerform(data[0]);
          }else{
            this.storeUserService.getStoreUserDBOrigin(JSON.parse(this.authService.decodeToken('user').user).uuid)
            .subscribe(
              (res:any)=>{
                data[0].frecuencyNoti = res.frecuencyNoti
                data[0].frecuencyNotiTime = res.frecuencyNotiTime
                this.loaddealerform(data[0]);
              },(err:any)=>{
                console.log(err);    
              }
            )
          }
        }
        this.form.markAsPristine();
      }, error => {
        this.alertify.error(error);
      });

 
      this.settingsService.getDevices().toPromise()
      .then((devices: any)=>{
        this.model.devices = devices
      }).catch(console.log)

      this.getcloverCredentials()

  }

  getCodeCloverFromUrl(): void{
    // window.addEventListener('storage', this.alreadySet)
    // this.tokenClover = localStorage.getItem('code') || ''
    const queryString = window.location.hash;
    const urlParams = new URLSearchParams(queryString);
    const code = urlParams.get('code')

    if(code){
      const url = `${this.cloverCredentials.enviromentClover}/oauth/token?client_id=${this.cloverCredentials.appID}&client_secret=${this.cloverCredentials.appSecret}&code=${code}`;
      let values = {
        'URL' : url
      }
      this.settingsService.authToClover(values).toPromise()
      // this.http.get(url).toPromise()
      .then((data:any) => {
        // console.log(data)
        if(data.access_token){
          this.tokenClover = data?.access_token;
          this.settings['authClover'] = data?.access_token;
          this.formCloverConfig.patchValue({authClover: data.access_token})
          this.alertify.success('Avalara connection success')
          this.saveClover(true)
          this.show = 'Clover'
          // localStorage.setItem('code', data?.access_token)
          // localStorage.setItem('appID', this.appID)
          // localStorage.setItem('appSecret', this.appSecret)
          // localStorage.setItem('deviceId', 'C045UT24540489')
        }
        }).catch((e: any) => {
          // console.log(e)
        })
    } 
  }

  loaddealerform(setting:any){
    this.dealerForm.patchValue(setting);
    if (this.dealerForm.get('inventoryMethod').value == 'FIFO' || this.dealerForm.get('inventoryMethod').value == "Average Cost") {
      this.isEditing = true;
      this.isLocked = true;
    }
    this.charCount = this.dealerForm.value.invoiceHeader.length
    this.charCountFooter = this.dealerForm.value.invoiceFooter.length
  }

  updatedealerform(){
    //this.dealerForm.controls['ddsDealerToken'].setValue(this.dealerForm.value.ddsDealerToken.trim())
    let json = {}
    if(this.userRole != 'Dealer'){
      json = this.dealerForm.value;
      json['role'] = this.userRole
      json['userId'] = JSON.parse(this.authService.decodeToken('user').user).uuid
    }else{      
      json = this.dealerForm.value;
      json['role'] = this.userRole
    }

    this.settingsService.updateDealerInfo(json)
    .toPromise()
    .then((data:any)=>{ 
      this.loadSettings();
      this.changeLanguage(this.dealerForm.get('language').value);
      this.changeCurrency(this.dealerForm.get('currency').value);
      this.alertify.success(this.translate.instant('DEALER_INFO_UPDATED')); 
    })
    .catch((err:any)=>{ 
      console.log(err);
     })
  }

  async submit(settings: any, testAvalara: boolean = false) {
    this.settings['DocumentRecording'] = this.settings['DocumentRecording'] == false ? 0:1;
    this.settings['avaTax'] = this.settings['avaTax'] == false ? 0:1;
    this.settings['cloverState'] = this.settings['cloverState'] == false ? 0:1;

    delete this.settings['MFRM'];
    this.settings['markup'] = 'SETTINGS';
   await this.settingsService.updateSettings(settings['ROID'], settings)
    .pipe(takeUntil(this.destroy$))   
      .subscribe(() => {
        this.alertify.success(this.translate.instant('SETTINGS_UPDATED'));
        this.form.markAsPristine();
        testAvalara ? this.pingConnectionAvalara():null;
      }, error => {
        this.alertify.error(error);
      });

  }
  saveClover(saveToken: boolean = false) {
    let values: any = {
      formClover : this.formCloverConfig.value
    }
    if(saveToken){
      values = {
        formClover : {authClover : this.tokenClover}
      }
    }
    this.settingsService.updateClover(values)
    .pipe(takeUntil(this.destroy$),
    switchMap((response) =>{
      this.alertify.success(this.translate.instant('CLOVER_SETTINGS_UPDATED'));
      return of(1)
    }))   
      .subscribe(() => {
        if(!saveToken && (this.settings.authClover == null || this.settings.authClover == "")){
          this.getConnectionClover()
        }
      }, error => {
        this.alertify.error(error);
      });
  }

  saveCloverDevices() {
    let devices = this.model.devices
    let values: any = {
      devices : devices,
      DevicesToDelete: this.DevicesToDelete
    }

    this.settingsService.updateCloverDevices(values)
    .pipe(takeUntil(this.destroy$),
    switchMap((response) =>{
      this.alertify.success(this.translate.instant('CLOVER_DEVICES_UPDATED'));
      return of(1)
    }))   
      .subscribe(() => {
      }, error => {
        this.alertify.error(error);
      });
  }

  cancelForm() { }

  toggleDrop() {
    this.drop =! this.drop;
  }

  showStores() {
    this.show = 'Store';
    this.drop = false;
  }

  showMerchant() {
    this.show = 'Merchant';
    this.drop = false;
  }
  showClover() {
    this.show = 'Clover';
    this.drop = false;
  }


  getConnectionClover(): void{
    // if(this.settings['appID'] == null || this.settings['appID'] == undefined || this.settings['appID'] == ''){
    //   this.alertify.warning('please complete Client ID and Client Secret in order to login to Clover');
    //   return;
    // }

    this.alertify.confirm(this.translate.instant('REDIRECT_CONFIRM'), ()=>{
      this.cloverUrl = `${this.cloverCredentials.enviromentClover}/oauth/authorize?client_id=${this.cloverCredentials.appID}`;
      window.location.href = this.cloverUrl
      // console.log(this.cloverWindow)
    }, ()=>{})
    
    // this.modalService.show(this.modalAuthClover, { class: 'modal-lg'})
    // var strWindowFeatures = "location=yes,height=570,width=520,scrollbars=yes,status=yes";
    // window.location.href = url;
    // this.http.get(url).subscribe(data =>{
    //   console.log(data)
    // })
  }

  showDealer() {
    this.show = 'Dealer';
    this.drop = false;
  }

  showDisclousers() {
    this.show = 'Disclouser';
    this.drop = false;
  }

  showContribution() {
    this.show = 'Contribution';
    this.drop = false;
  }
  showNotifications() {
    this.show = 'Notifications';
    this.drop = false;
  }

  ngOnDestroy(): void {
    window.removeEventListener('storage', this.alreadySet);
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  openModal(template: TemplateRef<any>) {
		this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
		this.showModal = true
	}

  closeModal(noShow?:boolean) {
		this.modalRef.hide();
		this.showModal = false
    this.termsAccepted = false;
	}

  saveMethod(method:string){
    if(this.termsAccepted) {
      this.closeModal()
      const data = {
        'inventoryMethod': method
      }
      this.settingsService.updateInventoryMethod(data)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(() => {
        this.alertify.success(this.translate.instant('INVENTORY_METHOD_UPDATED')); 
        this.loadSettings()
      }, error => {
        this.alertify.error(error);
      });
    }
  }

  toggleLock() {
    this.isLocked = !this.isLocked;
  }

  updateCharCount(event: Event, option) {
    if (option === 'invoiceHeader') {
      this.charCount = (event.target as HTMLTextAreaElement).value.length;
      if (this.charCount > 30) {
        const currentValue = (event.target as HTMLTextAreaElement).value;
        (event.target as HTMLTextAreaElement).value = currentValue.substr(0, 30);
        this.charCount = 30;
      }
    } if (option === 'invoiceFooter') {
      this.charCountFooter = (event.target as HTMLTextAreaElement).value.length;
      if (this.charCountFooter > 30) {
        const currentValue = (event.target as HTMLTextAreaElement).value;
        (event.target as HTMLTextAreaElement).value = currentValue.substr(0, 30);
        this.charCountFooter = 30;
      }
    } 
	}

  changeLanguage(language: string) {  
    if (this.userRole == 'Dealer') {
      this.languageService.setLanguage(language);
    } 
  }

  changeCurrency(currency: string) {
    this.currencyService.changeCurrency(currency);
  }
}
