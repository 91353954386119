import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, EventEmitter, HostListener, OnInit, Output, OnDestroy, Input, ViewChild, TemplateRef } from '@angular/core';
import {  FormGroup } from '@angular/forms';
import {  Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/map';
import { NrnValidators } from 'src/app/nrn.validators';
import { Customer } from '../../../_modules/customers';
import { Address } from '../../../_modules/address';
import { Pagination } from '../../../_modules/pagination';
import { AlertifyService } from '../../../_services/alertify.service';
import { AuthService } from '../../../_services/auth.service';
import { CustomerService } from '../../../_services/customer.service';
import 'rxjs/add/operator/takeUntil';
import { of as observableOf } from 'rxjs/observable/of';
import 'rxjs/add/observable/of';
import { EventsService } from 'src/app/_services/events.service';
import { takeUntil } from 'rxjs/operators';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { Subject } from 'rxjs';
import { SettingsService } from 'src/app/_services/settings.service';
import { DummymodalComponent } from '../../dummymodal/dummymodal.component';
import { GlobalsService } from 'src/app/_services/globals.service';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.css']
})
export class CustomerFormComponent implements OnInit, AfterViewInit, OnDestroy {
  constructor(
    private authService: AuthService, 
    private translate: TranslateService, 
    private alertify: AlertifyService,
    private customerService: CustomerService,
    private salesOrderService: SalesOrderService, private router: Router,
    public bsModalRef: BsModalRef, private http: HttpClient,
    private modalService: BsModalService,
    private eventService: EventsService,
    private settingsService: SettingsService,
    private globalService: GlobalsService,
    
  ) { }

  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('popTemplateiOs', {static: false}) popTemplateiOs: TemplateRef<any>;
  @Output() change = new EventEmitter();
  @Input() salesOrderToSave:any
  deliveryRequest: any;
  customer: Customer;
  customerid: any;
  storeId: string;
  storeSettings: any = {avaTax:false};
  events: any[];
  customers: Customer[];
  customerEdit: Customer;
  customerForm = new FormGroup({});
  pagination: Pagination;
  editMode = true;
  addMode = false;
  editId: any;
  states: any[];
  mask = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  default = '';
  validatedAddress: Address[];
  modalRefAddress: BsModalRef;

  fromSalesOrder = false;
  searchEvent = '';
  @HostListener('window:beforeunload', ['$event'])



  form = new FormGroup({});
  options: FormlyFormOptions = {
    formState: {
      submitted : false,
    },
  };
  optionsShipping: FormlyFormOptions = {
    formState: {
      submitted : false,
    },
  };
  fields: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'Id',
          type: 'input',
          className: 'col-md-6',
          hide: true,
        },
        {
          key: 'firstName',
          type: 'input',
          id: 'input-firstName',
          className: 'col-md-6 input-header-payment input',
          templateOptions: {
            type: 'text',
            placeholder: this.translate.instant('FIRST_NAME'),
            required: true,            
            maxLength: 100,
            minLength: 2,            
            pattern: NrnValidators.FORMATS.name
          },
          validation: {
            messages: {
              required: (error) => this.translate.instant('FIRST_NAME_REQUIRED'),
              minlength: (error) => this.translate.instant('FIRST_NAME_MINLENGTH'),
              maxlength: (error) => this.translate.instant('FIRST_NAME_MAXLENGTH'),
              pattern: (error, field: FormlyFieldConfig) => this.translate.instant('FIRST_NAME_INVALID'),
            },
          },
          
        },
        {
          key: 'lastName',
          type: 'input',
          id: 'input-lastName',
          className: 'col-md-6 input-header-payment input',
          templateOptions: {
            type: 'text',
            placeholder: this.translate.instant('LAST_NAME'),
            required: true,
            maxLength: 100,
            minLength: 2,            
            pattern: NrnValidators.FORMATS.name
          },
          validation: {
            messages: {
              required: (error) => this.translate.instant('LAST_NAME_REQUIRED'),
              minlength: (error) => this.translate.instant('LAST_NAME_MINLENGTH'),
              maxlength: (error) => this.translate.instant('LAST_NAME_MAXLENGTH'),
              pattern: (error, field: FormlyFieldConfig) => this.translate.instant('LAST_NAME_INVALID'),
            },
          },
        },
        {
          key: 'phone1',
          type: 'phone',
          id: 'input-phone1',
          className: 'col-md-6 input-header-payment input',
          templateOptions: {
            placeholder: this.translate.instant('PHONE'),
            required: true,
            pattern: NrnValidators.FORMATS.maskphone
          },
          validation: {
            messages: {
              required: (error) => this.translate.instant('PHONE_REQUIRED'),
              pattern: (error, field: FormlyFieldConfig) => this.translate.instant('PHONE_INVALID'),
            },
          },
        },
        // {
        //   key: 'phoneType',
        //   type: 'select',  
        //   id: 'select-phoneType',       
        //   className: 'col-md-6 input-header-payment select',
        //   templateOptions: {
        //     type: 'select',
        //     hidden: true,
        //     defaultValue: '',
           
        //     options: [
        //       { label: 'Select...', value: ''},
        //       { label: 'Home', value: 'Home' },
        //       { label: 'Cell', value: 'Cell' },
        //       { label: 'Work', value: 'Work' }
        //     ],
        //   },

        // },
        // {
        //   key: 'phone2',
        //   type: 'phone',
        //   id: 'input-phone2',
        //   className: 'col-md-6 input-header-payment input',
        //   templateOptions: {
        //     hidden:true,
        //     placeholder: 'Phone2',
        //     pattern: NrnValidators.FORMATS.maskphone
        //   },
        //   validation: {
        //     messages: {
        //       pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Phone Number`,
        //     },
        //   },
        // },
        // {
        //   key: 'phone2Type',
        //   type: 'select',
        //   id: 'select-phone2Type',
        //   className: 'col-md-6 input-header-payment select',
        //   templateOptions: {
        //     hidden:true,
        //     type: 'select',
        //     defaultValue: '',
        //     options: [
        //       { label: 'Select...', value: '' },
        //       { label: 'Home', value: 'Home' },
        //       { label: 'Cell', value: 'Cell' },
        //       { label: 'Work', value: 'Work' }
        //     ],
        //   }
        // },
        {
          key: 'email',
          type: 'input',
          id: 'input-email',
          className: 'col-md-6 input-header-payment input',
          templateOptions: {
            type: 'text',
            placeholder: this.translate.instant('EMAIL'),
            maxLength: 320,
            minLength: 2,    
            pattern: NrnValidators.FORMATS.email
          },
          validation: {
            messages: {
              required: (error) => this.translate.instant('EMAIL_REQUIRED'),
              minlength: (error) => this.translate.instant('EMAIL_MINLENGTH'),
              pattern: (error, field: FormlyFieldConfig) => this.translate.instant('EMAIL_INVALID'),
            },
          },
        },
        {
          key: 'billingAddress1',
          type: 'input',
          id: 'input-billingAddress1',
          className: 'col-md-6 input-header-payment input' ,
          templateOptions: {
            type: 'text',
            placeholder: this.translate.instant('ADDRESS'),
            maxLength:500,
            minLength: 2, 
            required: true
          },
          validation: {
            messages: {
              required: (error) => this.translate.instant('ADDRESS_REQUIRED'),
              minlength: (error) => this.translate.instant('ADDRESS_MINLENGTH'),
            },
          },
        },
        // {
        //   key: 'billingAddress2',
        //   type: 'input',
        //   id: 'input-billingAddress2',
        //   className: 'col-md-12 input-header-payment input',
        //   templateOptions: {
        //     hidden:true,
        //     type: 'text',
        //     placeholder: 'Address 2',
        //     maxLength:500,
        //     minLength: 2, 
        //   },
        //   validation: {
        //     messages: {
        //       minlength: (error) => `Billing Address 2 requires at least 2 characters.`,
        //     },
        //   }
        // },
        {
          key: 'city',
          type: 'input',
          id: 'input-city',
          className: 'col-md-6 input-header-payment input',
          templateOptions: {
            type: 'text',
            placeholder: this.translate.instant('CITY'),
            required: true,            
            maxLength:60,
            minLength: 2, 
          },
          validation: {
            messages: {
              required: (error) => this.translate.instant('CITY_REQUIRED'),
              minlength: (error) => this.translate.instant('CITY_MINLENGTH'),
            },
          }
        },
        {
          key: 'state',
          type: 'select',
          id: 'select-state',
          className: 'col-md-6 input-header-payment select',
          templateOptions: {
            required: true,
            defaultValue: '',
            options: this.getStates(),
            valueProp: 'abbreviation',
            labelProp: 'name'
          }
        },
        {
          key: 'zip',
          type: 'input',

          id: 'input-zip',
          className: 'col-md-6 input-header-payment input',
          templateOptions: {
            type: 'text',
            placeholder: this.translate.instant('ZIP'),
            maxLength: 5,
            minLength: 5,
            required: true
          },
          validation: {
            messages: {
              required: (error) => this.translate.instant('ZIP_REQUIRED'),
              minlength: (error, field: FormlyFieldConfig) => this.translate.instant('ZIP_LENGTH'),
            },
          },
        },
        // {
        //   key: 'comments',
        //   type: 'textarea',
        //   id: 'input-comments',
        //   className: 'col-md-12 input-header-payment input',
        //   templateOptions: {
        //     hidden:true,
        //     type: 'text',
        //     placeholder: 'comments',
        //     maxLength:200,
        //     minLength: 2, 
        //   },
        //   validation: {
        //     messages: {
        //       minlength: (error) => `comments requires at least 2 characters.`,
        //     },
        //   }
        // },

      ],
    },
  ];

  formShipping = new FormGroup({});
  fieldsShipping: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldGroup: [{
        key: 'fName',
        type: 'input',
        id: 'input-fName',
        className: 'col-md-12 input-header-payment input',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('FIRST_NAME'),
          required: true, 
          maxLength: 100,
          minLength: 2,            
          pattern: NrnValidators.FORMATS.name
          },validation: {
            messages: {
              required: (error) => this.translate.instant('FIRST_NAME_REQUIRED'),
              minlength: (error) => this.translate.instant('FIRST_NAME_MINLENGTH'),
              maxlength: (error) => this.translate.instant('FIRST_NAME_MAXLENGTH'),
              pattern: (error, field: FormlyFieldConfig) => this.translate.instant('FIRST_NAME_INVALID'),
            },
          },
        },
      {
        key: 'lName',
        type: 'input',
        id: 'input-lName',
        className: 'col-md-12 input-header-payment input',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('LAST_NAME'),
          required: true, 
          maxLength: 100,
          minLength: 2,            
          pattern: NrnValidators.FORMATS.name
        },
        validation: {
          messages: {
            required: (error) => this.translate.instant('LAST_NAME_REQUIRED'),
            minlength: (error) => this.translate.instant('LAST_NAME_MINLENGTH'),
            maxlength: (error) => this.translate.instant('LAST_NAME_MAXLENGTH'),
            pattern: (error, field: FormlyFieldConfig) => this.translate.instant('LAST_NAME_INVALID'),
          },
        }
      },
      {
        key: 'phone3',
        type: 'phone',
        id: 'input-phone3',
        className: 'col-md-6 input-header-payment input',
        templateOptions: {
          required: true, 
          placeholder: this.translate.instant('PHONE'),
          pattern: NrnValidators.FORMATS.maskphone
        },
        validation: {
          messages: {
            required: (error) => this.translate.instant('PHONE_REQUIRED'),
            pattern: (error, field: FormlyFieldConfig) => this.translate.instant('PHONE_INVALID'),
          },
        },
      },
      {
        key: 'phone3Type',
        type: 'select',
        id: 'input-phone3Type',
        className: 'col-md-6 input-header-payment select',
        templateOptions: {
          required:true,
          type: 'select',
          options: [
            { label: 'Select...', value: ''},
            { label: 'Home', value: 'Home' },
            { label: 'Cell', value: 'Cell' },
            { label: 'Work', value: 'Work' }
          ],
        }
      },

      {
        key: 'deliveryEmail',
        type: 'input',
        id: 'input-deliveryEmail',
        className: 'col-md-12 input-header-payment input',
        templateOptions: {
          type: 'text',
          required:true,
          placeholder: this.translate.instant('EMAIL'),
          maxLength: 320,
          minLength: 2, 
          pattern: NrnValidators.FORMATS.email
        },
        validation: {
          messages: {
            required: (error) => this.translate.instant('EMAIL_REQUIRED'),
            minlength: (error) => this.translate.instant('EMAIL_MINLENGTH'),
            pattern: (error, field: FormlyFieldConfig) => this.translate.instant('EMAIL_INVALID')
          },
        },
      },
      {
        key: 'deliveryAddress1',
        type: 'input',
        id: 'input-deliveryAddress1',
        className: 'col-md-12 input-header-payment input',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('ADDRESS'),
          required: true,
          maxLength:500,
          minLength: 2, 
        },
        validation: {
          messages: {
            required: (error) => this.translate.instant('ADDRESS_REQUIRED'),
            minlength: (error) => this.translate.instant('ADDRESS_SHIPPING_MINLENGTH'),
          },
        },
      },
      {
        key: 'deliveryAddress2',
        type: 'input',
        id: 'input-deliveryAddress2',
        className: 'col-md-12 input-header-payment input',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('ADDRESS2'),
          maxLength:500,
          minLength: 2, 
        },
          validation: {
            messages: {
              minlength: (error) => this.translate.instant('ADDRESS_SHIPPING_MINLENGTH'),
            },
          },
      },
      {
        key: 'deliveryCity',
        type: 'input',
        id: 'input-deliveryCity',
        className: 'col-md-12 input-header-payment input',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('CITY'),
          required: true,            
          maxLength:60,
          minLength: 2, 
        },
        validation: {
          messages: {
            required: (error) => this.translate.instant('CITY_REQUIRED'),
            minlength: (error) => this.translate.instant('CITY_MINLENGTH'),
          },
        }
      },
      {
        key: 'deliveryState',
        type: 'select',
        id: 'input-deliveryState',
        className: 'col-md-6 input-header-payment select',
        templateOptions: {
          required: true,
          defaultValue: '',
          options: this.getStates(),
          valueProp: 'abbreviation',
          labelProp: 'name'
        },
        validation: {
          messages: {
            required: (error) => this.translate.instant('STATE_REQUIRED'),
          },
        }
      },
      {
        key: 'deliveryZip',
        type: 'input',
        id: 'input-deliveryZip',
        className: 'col-md-6 input-header-payment input',
        templateOptions: {
          type: 'text',
          placeholder: this.translate.instant('ZIP'),
          maxLength: 5,
          minLength: 5,
          required: true
        },
        validation: {
          messages: {
            required: (error) => this.translate.instant('ZIP_REQUIRED'),
            minlength: (error) => this.translate.instant('ZIP_LENGTH'),
          },
        },
      },
      {
        key: 'entityCode',
        type: 'select',
        id: 'input-deliveryState',
        className: 'col-md-6 input-header-payment select',
        templateOptions: {
          required: false,
          defaultValue: '',
          options: this.getEntityCodes(),
          valueProp: 'code',
          labelProp: 'name'
        },
        hide: (this.storeSettings.avaTax == 1 || this.storeSettings.avaTax == true) ? false : true,
        
      }
    ],
    },
  ];





  unloadNotification($event: any) {
    if (this.customerForm.dirty) {
      $event.returnValue = true;
    }
  }

  ngAfterViewInit() {
  }

  ngOnInit() {   

    if (this.router.url.toString().includes('customers') ){
      this.settingsService.getSettings(0)
      .pipe(takeUntil(this.destroy$))   
      .subscribe( data => {
        this.storeSettings = data[0];
        if(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc')){
          this.fields[0].fieldGroup.forEach((field:any, index)=>{
            if(index != 0 && index != 1 && index != 2){
              field.templateOptions.required = false;
            }
          })
          this.fieldsShipping[0].fieldGroup.forEach((field:any)=>{
            field.templateOptions.required = false;
          })
        }
        if(this.storeSettings['avaTax'] == '0' || this.storeSettings['avaTax'] == false){
          this.fieldsShipping[0].fieldGroup[10].hide = true
        }
        if(this.deliveryRequest == 'Pickup'){
  
          this.fields[0].fieldGroup.forEach((field:any, index)=>{
            if(index != 0 && index != 1 && index != 2){
              field.templateOptions.required = false;
            }
          })
          this.fieldsShipping[0].fieldGroup.forEach((field:any)=>{
            field.templateOptions.required = false;
          })
        }
      }, error => {
        this.alertify.error(error);
      });

    }else{
      this.settingsService.SettingsEvent$
      .pipe(takeUntil(this.destroy$))   
      .subscribe( data => {
        this.storeSettings = data[0];
        if( this.storeSettings == undefined) return;

        if(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc')){

          this.fields[0].fieldGroup.forEach((field:any, index)=>{
            if(index != 0 && index != 1 && index != 2){
              field.templateOptions.required = false;
            }
          })
          this.fieldsShipping[0].fieldGroup.forEach((field:any)=>{
            field.templateOptions.required = false;
          })
        }
        if(this.storeSettings['avaTax'] == '0' || this.storeSettings['avaTax'] == false){
          this.fieldsShipping[0].fieldGroup[10].hide = true
        }
        if(this.deliveryRequest == 'Pickup'){
  
          this.fields[0].fieldGroup.forEach((field:any, index)=>{
            if(index != 0 && index != 1 && index != 2){
              field.templateOptions.required = false;
            }
          })
          this.fieldsShipping[0].fieldGroup.forEach((field:any)=>{
            field.templateOptions.required = false;
          })
        }
      }, error => {
        this.alertify.error(error);
      });
    }


    

    this.storeId = JSON.parse(this.authService.getStoreId());
    this.customer = Object.assign({});
    this.customerService.editCustomer
    .pipe(takeUntil(this.destroy$))   
      .subscribe((
        res => {
          this.customerid = res;
        }
      ));

    this.customerid = this.customerService.editCustomer.value;
    // console.log(this.customerid != 0 )
    if (this.customerid != 0 ) {
      this.customerService.getCustomer(this.customerid)
      .pipe(takeUntil(this.destroy$))   
        .subscribe(data => {
          this.customer = data[0];

          this.customer['phone1'] = this.customer['phone1'].toString().replace(')', '-').replace('(', '');
          if (this.customer['phone2'] != null) {
            this.customer['phone2'] = this.customer['phone2'].toString().replace(')', '-').replace('(', '');
          }
          if (this.customer['phone3'] != null) {
            this.customer['phone3'] = this.customer['phone3'].toString().replace(')', '-').replace('(', '');
          }

          this.editMode = true;
          this.addMode = false;
          this.default = this.customer.state;
        }, error => {
          this.alertify.error(error);
        });
    }else{
      //inicializando selects
    this.customer['state'] = '';
    this.customer['phoneType'] = '';
    this.customer['phone2Type'] = '';
    this.customer['deliveryState'] = '';
    this.customer['phone3Type'] = '';
    }


    if (this.customerid == 0 || this.customerid == '') {
      this.addMode = true;
      this.editMode = false;
    }

  }

  getStates(): Observable<any[]> {
    return this.http.get<any[]>('assets/states.json');
  }
  getEntityCodes(): Observable<any[]> {
    return this.http.get<any[]>('assets/entityCodes.json');
  }
  getEventsObs() {

    //console.log(this.form.controls['eventId']);
    //this.fields.controls['eventId'].valueChanges.subscribe((res) => {

    //});
    return observableOf(this.events);
  }

  addCustomer() {

    if (this.form.valid && this.formShipping.valid ) {
      if (this.customer !== undefined) {

        if (this.fromSalesOrder) {
          this.customer['eventId'] = this.eventService.salesOrderEvent.value;
        }

        if(this.salesOrderToSave != undefined){
        if(this.salesOrderToSave['soItems'] && this.salesOrderToSave['soItems'].length > 0){
          this.salesOrderToSave['soItems'].forEach((item:any) =>{           
            if(item.itemDelivery == 'Direct Shipment' && 
            ((item.deliveryAttn == null || item.deliveryAttn == "" || item.deliveryAttn == " " || item.deliveryAttn == 'null null') && 
            (item.deliveryAddress1 == null || item.deliveryAddress1 == "")  
            && (item.deliveryCity == null || item.deliveryCity == "") 
            && (item.deliveryPhone == "" || item.deliveryPhone == null) 
            && (item.deliveryState == null || item.deliveryState == "") )){
              if(this.salesOrderToSave['fName'] == null){this.salesOrderToSave['fName'] = ''}
              if(this.salesOrderToSave['lName'] == null){this.salesOrderToSave['lName'] = ''}
              item.deliveryAttn = this.customer['fName']+' '+this.customer['lName'] 
              item.deliveryAddress1 = this.customer['deliveryAddress1']
              item.deliveryAddress2 = this.customer['deliveryAddress2']
              item.deliveryPhone = this.customer['phone3']
              item.deliveryEmail = this.customer['deliveryEmail']
              item.deliveryCity = this.customer['deliveryCity']
              item.deliveryState = this.customer['deliveryState']
              item.deliveryZip = this.customer['deliveryZip']
            }
          })
        }          
        }


        if(this.customer['fName'] == '' || this.customer['fName'] == undefined || this.customer['fName'] == null)
        {
          this.copyAddressToShipping()
        }

        
        this.customer['created_at'] = this.globalService.dNow();
        this.customer['updated_at'] = this.globalService.dNow();
        // this.customer['Id'] = this.globalService.guid();
        this.customerService.addCustomer(this.customer)
        .pipe(takeUntil(this.destroy$))   
          .subscribe((res) => {
            let message = this.translate.instant('CUSTOMERADDED');
            this.alertify.success(`${message}`);
            this.addMode = false;
            this.cancelForm();
            this.customerService.reloadCustomerList.next('yes')
            this.customerService.salesOrderCustomer.next(res[0]['Id']);
          }, error => {
            this.alertify.error(error);
          });

      }

    }else{  
      this.optionsShipping.formState.submitted = true;
      this.options.formState.submitted = true;
    }

  }


  copyAddressToShipping() {
    let eventId = this.customer['eventId'];
    this.customer = Object.assign({}, this.form.value);
    this.customer['eventId'] = eventId;
    this.customer['fName'] = this.customer['firstName'];
    this.customer['lName'] = this.customer['lastName'];
    this.customer['deliveryAddress1'] = this.customer['billingAddress1'];
    this.customer['deliveryAddress2'] = this.customer['billingAddress2'];
    this.customer['deliveryEmail'] = this.customer['email'];
    this.customer['phone3Type'] = this.customer['phoneType'];
    this.customer['phone3'] = this.customer['phone1'];
    this.customer['deliveryCity'] = this.customer['city'];
    this.customer['deliveryState'] = this.customer['state'];
    this.customer['deliveryZip'] = this.customer['zip'];
  }

  updateCustomer() {
    if (this.form.valid  && this.formShipping.valid ) {
      
      this.customer['Id'] = this.customerid;
      this.customer['updated_at'] = this.globalService.dNow();
      this.customerService.updateCustomer(this.customer.Id, this.customer)
      .pipe(takeUntil(this.destroy$))   
        .subscribe(() => {
          let message = this.translate.instant('CUSTOMERUPDATED');
          this.alertify.success(`${message}`);
          this.editMode = false;
          this.cancelForm(this.customer.Id.toString());
          this.customerForm.reset(this.customer);
          this.customerService.reloadCustomerList.next('yes')
          this.customerService.salesOrderCustomer.next(this.customer.Id);
        }, error => {
          this.alertify.error(error);
        });
    }else{
      this.optionsShipping.formState.submitted = true;
      this.options.formState.submitted = true;
    }

  }

  validateAddress(){
    if(!this.formShipping.valid){
      let message = this.translate.instant('INVALIDFORM');
      this.alertify.success(`${message}`);
      return;
    }
    this.salesOrderService.validateAddress({customer: this.customer, device: localStorage.getItem('device')})
    .pipe(
      takeUntil(this.destroy$))   
    .subscribe((a:any) =>{
      if(a?.messages){
        let show:string = '';
        a.messages.forEach(msg => {
          show = show+msg.summary +'<br> '
        });
        this.alertify.alert(show, ()=>{})
      }else if(a?.taxAuthorities){
        this.validatedAddress = a?.validatedAddresses as Address[];
        console.log(this.validatedAddress)
        this.modalRefAddress = this.modalService.show(this.popTemplateiOs, { class: 'modal-lg'});
      }
    })
  }

  setAddressFromAvalara(address: Address | null):void{
    console.log(address)
    if(address != null){
      // Persistance fields
      let persist = {
        fName: this.customer['fName'], 
        lName: this.customer['lName'], 
        phone3: this.customer['phone3'], 
        phone3Type: this.customer['phone3Type'], 
        deliveryEmail: this.customer['deliveryEmail'], 
        entityCode: this.customer['entityCode'], 
      }
      this.customer = Object.assign({}, this.form.value, persist);
      this.customer['deliveryAddress1'] = address.line1
      this.customer['deliveryAddress2'] = address.line2
      this.customer['deliveryState'] = address.region
      this.customer['deliveryCity'] = address.city
      this.customer['deliveryZip'] = address.postalCode.substring(0,5)
    }
    this.modalRefAddress.hide()
  }

  editModeSwitch() {
    this.editMode = false;
    this.customerForm.reset();
  }

cancelFormHTML(id?: string){
  let message = this.translate.instant('EXITWITHOUTSAVE');
     this.alertify.confirm(`${message}`, () => {
    if (this.fromSalesOrder === false) {
      if (!this.router.url.includes('salesorder')) {
        this.router.navigate(['/customers']);
      }
    }
    this.change.emit(id);
    this.bsModalRef.hide();

   }
   ,()=>{
   });
}

cancelForm(id?: string) {   
if (this.fromSalesOrder === false) {
  if (!this.router.url.includes('salesorder')) {
    this.router.navigate(['/customers']);
  }
}
this.change.emit(id);
this.bsModalRef.hide();
}

openlistCustomer(){
  this.bsModalRef.hide();
  const initialState = {
    fromSalesOrder: true,
    viewType:'List',
    salesOrderToSave:this.salesOrderToSave
  };
  this.bsModalRef = this.modalService.show(DummymodalComponent, {initialState,class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
