import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-dummymodal',
  templateUrl: './dummymodal.component.html',
  styleUrls: ['./dummymodal.component.scss']
})
export class DummymodalComponent implements OnInit {
  title: string;
  closeBtnName: string;
  list: any[] = [];
  @Input() deliveryRequest: any;
  @Input() salesOrderToSave: any;
  viewType:any = 'Card'
  fromSalesOrder:any = false

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.list.push('PROFIT!!!');
  }

}
