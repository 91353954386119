import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { DefaultService } from 'src/app/_services/default.service';
import { GlobalsService } from 'src/app/_services/globals.service';

@Component({
  selector: 'app-openclosecashreg',
  templateUrl: './openclosecashreg.component.html',
  styleUrls: ['./openclosecashreg.component.scss']
})
export class OpenclosecashregComponent implements OnInit,OnDestroy  {

  destroy$: Subject<boolean> = new Subject<boolean>();
  devices:any = []
  users:any = []

  opensCR:any = []
  closesCR:any = []
  history:boolean=false;
  showForm:boolean = false
  titleForm:string = ''
  p:any = 1

  occrForm = new FormGroup({
    uuid: new FormControl('',[]),
    openCash: new FormControl('',[Validators.required]),    
    userId: new FormControl('',[Validators.required]),
    deviceId: new FormControl('',[Validators.required]),
    deviceName: new FormControl('',[]),
    openingBy: new FormControl('',[]),
    open_at: new FormControl('',[]),     
    sold: new FormControl('',[]),
    close_at: new FormControl('',[])
  });
  totalsCRSelected: any;

  constructor(
    private defaultSvc:DefaultService,
    private authSvc:AuthService,
    private alertify:AlertifyService,
    private global:GlobalsService,
    private translate: TranslateService,
  ) { }

  ngOnInit(): void {
    this.getdevices()
  }

  getdevices(){
    this.defaultSvc.getDevicesPOS({type:'cashRegister',from:'defaults'})
    .pipe(takeUntil(this.destroy$))   
    .subscribe((res:any) => {
      this.devices = res.devices
      this.users = res.users
      this.opensCR = res.opensCR
    },(err)=>{
      console.log(err);      
    });  
  }

  goToHistory(){
    this.history = true;
    this.showForm = false;
    this.occrForm.reset();
  }
  
  openBox(){
    this.totalsCRSelected={};
    this.titleForm = this.translate.instant('OPEN_CASH_REGISTER');
    this.occrForm.reset();
    this.occrForm.controls['openingBy'].setValue(JSON.parse(this.authSvc.decodeToken('user').user).name)
    this.showForm = true;
  }

  saveOCCR(){
    
    
    if(this.occrForm.valid){

      let i =  this.opensCR.findIndex((x:any) => x.deviceId == this.occrForm.value.deviceId)
      console.log(i);
      
      if(i != -1){
        this.alertify.error(this.translate.instant('CASH_REGISTER_NOT_CLOSED'));
        return;

      }else{
        this.occrForm.controls['uuid'].setValue(this.global.guid())
        this.occrForm.controls['deviceName'].setValue(this.getName('device',this.occrForm.value.deviceId))
        this.occrForm.controls['open_at'].setValue(this.global.dNow())
  
        this.defaultSvc.saveOpenCashRegister(this.occrForm.value)
        .pipe(takeUntil(this.destroy$))   
        .subscribe((res:any) => {
          this.opensCR.push(res)
          this.occrForm.reset()
          this.showForm = false
        },(err)=>{
          console.log(err);      
        });  

      }

    }else{
      this.alertify.warning(this.translate.instant('COMPLETE_REQUIRED_DATA'))
    }

  }

  cancelOCCR(){
    this.occrForm.reset()
    this.showForm = false
  }

  getName(type:any, uuid:any){
    if(type == 'user'){
      let user = this.users.find((x:any)=>x.uuid == uuid);
      return user.name
    }else{
      let device = this.devices.find((x:any)=>x.uuid == uuid);
      return device.deviceName
    }
  }

  selectOpenCR(CR:any){

    this.defaultSvc.geCloseInfoCashRegister(CR)
    .pipe(takeUntil(this.destroy$))   
    .subscribe((res:any) => {
      
      if(res.total > 0){
        this.totalsCRSelected=res;   
        this.totalsCRSelected.uuid=CR.uuid;
        this.occrForm.reset(CR);
        this.showForm = true
      }else{
        //console.log('todo vacio');
        
        // todo en vacio
        let json = {
          "total": 0,
          "totalChange": 0,
          "payments": [
            {
              "paymentType": "Cash",
              "totalAmount": "0"
            }
          ]
        }
        this.totalsCRSelected=json;   
        this.totalsCRSelected.uuid=CR.uuid;
        this.occrForm.reset(CR);
        this.showForm = true
      }  

    },(err)=>{
      console.log(err);      
    });  

  }

  getInfo():Object{
    try {
      const changes:number = this.totalsCRSelected.totalChange;
      let totalCash:number = 0;
      this.totalsCRSelected.payments.forEach(element => {
        if ( element.paymentType == 'Cash' ) {
          totalCash+=parseFloat(element.totalAmount);
        }
      });
      const openAm:number=parseFloat(this.occrForm.value.openCash);

      const object = {
        'closeCash':(openAm+totalCash+changes).toString(),
        'soldCash':(totalCash+changes).toString(),
        'sold':this.totalsCRSelected.total.toString()
      }
      return object;
    } catch (error) {
      return {};
    }
    
  }
  closeCR(){

    let totalCash: number = 0;
    this.totalsCRSelected.payments.forEach(element => {
      if (element.paymentType == 'Cash') {
        totalCash += parseFloat(element.totalAmount);
      }
    });

    const changes: number = this.totalsCRSelected.totalChange;
    const openAm: number = parseFloat(this.occrForm.value.openCash);
    const closeCash: number = parseFloat((openAm + totalCash + changes).toFixed(2));
    const sold: number = parseFloat(this.totalsCRSelected.total.toFixed(2));

    const object = {
      'uuid':this.totalsCRSelected.uuid,
      'payments': JSON.stringify(this.totalsCRSelected.payments),
      'changes':changes,
      'closeCash': closeCash.toString(),
      'sold': sold.toString(),
      'close_at':this.global.dNow()
    };

    this.alertify.confirm(
      `
        ${this.translate.instant('CASH_REGISTER_WILL_BE_CLOSED')}\n
        ${this.translate.instant('SOLD')}: $${object.sold}, 
        ${this.translate.instant('CLOSE_AMOUNT')}: $${object.closeCash}\n
        ${this.translate.instant('ARE_YOU_SURE')}
      `, 
      () => {
        this.defaultSvc.closeCashRegister(object)
          .pipe(takeUntil(this.destroy$))   
          .subscribe((res: any) => {  
            this.alertify.success(this.translate.instant('CASH_REGISTER_CLOSED'));
            this.showForm = false
            this.getdevices()
          }, (err) => {
            console.log(err);      
          });  
      },
      () => {
        // ...
      }
    );
    
    const dialogElement = document.querySelector(".alertify .ajs-content") as HTMLElement;
    if (dialogElement) {
      dialogElement.style.whiteSpace = "pre-line";
    }
    
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}