import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { SponsorContributionService } from 'src/app/_services/sponsorcontribution.service';
import { StoreproductsService } from 'src/app/_services/storeproducts.service';

@Component({
  selector: 'app-commissions',
  templateUrl: './commissions.component.html',
  styleUrls: ['./commissions.component.scss']
})
export class CommissionsComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() fromcomponet:any; 
  @Input() eventId:any = ''; 

  productsOriginal:any = [];  

  products:any = [];
  sizes:any = [];  
  styles:any = [];
  delimiters:any = [
    {delimiter:">"},
    {delimiter:">="},
  ]
  types:any = [
    {type:"CURRENCY"},
    {type:"QTY"},
  ]

  companiesandsizes: any;
  companies: any;

  model:any = [];
  modelOriginal:any = [];
  
  form = new FormGroup({});
  
  options:  FormlyFormOptions = {
    formState: {
      submitted : false,
    },
  };

  fields: FormlyFieldConfig[] = [
    {
      type: "repeatsmall",
      wrappers: ["formwrap"],
      templateOptions: { label: "" },
      fieldGroupClassName: "row ",
      fieldArray: {
        fieldGroupClassName: "row ",
        templateOptions: {
          btnText: "Add Commission",
          add: () => {},
          afterAdd: () => {},
          track: () => {},
          removePayment: () => {}, 

        },

        fieldGroup: [
          {
            key: "product",
            type: "select",
            className: "col-md-2",
            templateOptions: {
              required: true,
              options: this.products,
              valueProp: "product",
              labelProp: "product",
            },
             lifecycle: {
              onInit: (form, field) => {
               
              }
            }
          },

          {
            key: "styleName",
            type: "select",
            className: "col-md-2",
            defaultValue: "ALL STYLES",
            templateOptions: {
              options: this.styles,
              valueProp: "styleName",
              labelProp: "styleName",
            },
            lifecycle: {
              onInit: (form, field) => {

                // *********************** the first time
                  let valProd = form.get("product").value == null ? '': form.get("product").value
                  let valSize = form.get("sizeName").value == 'ALL SIZES' ? '' : form.get("sizeName").value
                  let valStyle = form.get("styleName").value == 'ALL STYLES' ? '' : form.get("styleName").value

                  let prodfiltered = this.productsOriginal.filter((x:any)=>
                  x.Type.toLowerCase().includes(valProd.toLowerCase()) &&
                  x.sizeName.toLowerCase().includes(valSize.toLowerCase())  && 
                  x.styleName.toLowerCase().includes(valStyle.toLowerCase()) )
                  
                  const ustyles: string[] = [];
          
                  for (const dato of prodfiltered) {   
                      if (!ustyles.includes(dato.styleName)) {
                        if(dato.styleName != ''){
                          ustyles.push(dato.styleName);
                        }
                      }      
                  }        
          
                  let newarraystyles = [{"styleName":"ALL STYLES"}]  
                  const UppersStyles = ustyles.map(word => (word.toUpperCase()).trim());
                  const dstyles = UppersStyles.filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);      
                  dstyles.forEach(element => {
                    newarraystyles.push({"styleName":element})
                  });  

                  field.templateOptions.options = newarraystyles;
                // *********************** the first time



                form.get("product").valueChanges.subscribe((v) => {

                  form.get("sizeName").setValue('ALL SIZES')
                  form.get("styleName").setValue('ALL STYLES')
                  let valProd = form.get("product").value
                  let valSize = form.get("sizeName").value == 'ALL SIZES' ? '' : form.get("sizeName").value
                  let valStyle = form.get("styleName").value == 'ALL STYLES' ? '' : form.get("styleName").value

                  let prodfiltered = this.productsOriginal.filter((x:any)=>
                  x.Type.toLowerCase().includes(valProd.toLowerCase()) &&
                  x.sizeName.toLowerCase().includes(valSize.toLowerCase())  && 
                  x.styleName.toLowerCase().includes(valStyle.toLowerCase()) )
                  
                  const ustyles: string[] = [];
          
                  for (const dato of prodfiltered) {   
                      if (!ustyles.includes(dato.styleName)) {
                        if(dato.styleName != ''){
                          ustyles.push(dato.styleName);
                        }
                      }      
                  }        
          
                  let newarraystyles = [{"styleName":"ALL STYLES"}]    
                  const UppersStyles = ustyles.map(word => (word.toUpperCase()).trim());
                  const dstyles = UppersStyles.filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);          
                  dstyles.forEach(element => {
                    newarraystyles.push({"styleName":element})
                  });  

                  field.templateOptions.options = newarraystyles;
                  
                });

                form.get("sizeName").valueChanges.subscribe((v) => {

                  let valProd = form.get("product").value
                  let valSize = form.get("sizeName").value == 'ALL SIZES' ? '' : form.get("sizeName").value
                  let valStyle = form.get("styleName").value == 'ALL STYLES' ? '' : form.get("styleName").value

                  let prodfiltered = this.productsOriginal.filter((x:any)=>
                  x.Type.toLowerCase().includes(valProd.toLowerCase()) &&
                  x.sizeName.toLowerCase().includes(valSize.toLowerCase())  && 
                  x.styleName.toLowerCase().includes(valStyle.toLowerCase()) )

                  const ustyles: string[] = [];
          
                  for (const dato of prodfiltered) {   
                      if (!ustyles.includes(dato.styleName)) {
                        if(dato.styleName != ''){
                          ustyles.push(dato.styleName);
                        }
                      }      
                  }        
          
                  let newarraystyles = [{"styleName":"ALL STYLES"}]   
                  const UppersStyles = ustyles.map(word => (word.toUpperCase()).trim());
                  const dstyles = UppersStyles.filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);               
                  dstyles.forEach(element => {
                    newarraystyles.push({"styleName":element})
                  });  

                  field.templateOptions.options = newarraystyles;
                  
                });

                form.get("styleName").valueChanges.subscribe((v) => {                  

                  let valProd = form.get("product").value
                  let valSize = form.get("sizeName").value == 'ALL SIZES' ? '' : form.get("sizeName").value
                  let valStyle = form.get("styleName").value == 'ALL STYLES' ? '' : form.get("styleName").value

                  let prodfiltered = this.productsOriginal.filter((x:any)=>
                  x.Type.toLowerCase().includes(valProd.toLowerCase()) &&
                  x.sizeName.toLowerCase().includes(valSize.toLowerCase())  && 
                  x.styleName.toLowerCase().includes(valStyle.toLowerCase()) )

                  const ustyles: string[] = [];
          
                  for (const dato of prodfiltered) {   
                      if (!ustyles.includes(dato.styleName)) {
                        if(dato.styleName != ''){
                          ustyles.push(dato.styleName);
                        }
                      }      
                  }        
          
                  let newarraystyles = [{"styleName":"ALL STYLES"}]   
                  const UppersStyles = ustyles.map(word => (word.toUpperCase()).trim());
                  const dstyles = UppersStyles.filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);               
                  dstyles.forEach(element => {
                    newarraystyles.push({"styleName":element})
                  });  

                  field.templateOptions.options = newarraystyles;
                  
                });

              }
            }
          },
          {
            key: "sizeName",
            type: "select",
            className: "col-md-2",
            defaultValue: "ALL SIZES",
            templateOptions: {
              options: this.sizes,
              valueProp: "sizeName",
              labelProp: "sizeName",
            },
            lifecycle: {
              onInit: (form, field) => {
                // *********************** the first time
                  let valProd = form.get("product").value == null ? '': form.get("product").value
                  let valSize = form.get("sizeName").value == 'ALL SIZES' ? '' : form.get("sizeName").value
                  let valStyle = form.get("styleName").value == 'ALL STYLES' ? '' : form.get("styleName").value

                  let prodfiltered = this.productsOriginal.filter((x:any)=>
                  x.Type.toLowerCase().includes(valProd.toLowerCase()) &&
                  x.sizeName.toLowerCase().includes(valSize.toLowerCase())  && 
                  x.styleName.toLowerCase().includes(valStyle.toLowerCase()) )
                  
                  const usizes: string[] = [];
          
                  for (const dato of prodfiltered) {   
                      if (!usizes.includes(dato.sizeName)) {
                        if(dato.sizeName != ''){
                          usizes.push(dato.sizeName);
                        }
                      }      
                  }        
          
                  let newarraysizes = [{"sizeName":"ALL SIZES"}] 
                  
                  const UppersSizes = usizes.map(word => (word.toUpperCase()).trim());
                  const dsizes = UppersSizes.filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);      
                  dsizes.forEach(element => {
                    newarraysizes.push({"sizeName":element})
                  });  

          

                  field.templateOptions.options = newarraysizes;
                // *********************** the first time
             
                form.get("product").valueChanges.subscribe((v) => {
                  form.get("sizeName").setValue('ALL SIZES')
                  form.get("styleName").setValue('ALL STYLES')
                  let valProd = form.get("product").value
                  let valSize = form.get("sizeName").value == 'ALL SIZES' ? '' : form.get("sizeName").value
                  let valStyle = form.get("styleName").value == 'ALL STYLES' ? '' : form.get("styleName").value

                  let prodfiltered = this.productsOriginal.filter((x:any)=>
                  x.Type.toLowerCase().includes(valProd.toLowerCase()) &&
                  x.sizeName.toLowerCase().includes(valSize.toLowerCase())  && 
                  x.styleName.toLowerCase().includes(valStyle.toLowerCase()) )
                  
                  const usizes: string[] = [];
          
                  for (const dato of prodfiltered) {   
                      if (!usizes.includes(dato.sizeName)) {
                        if(dato.sizeName != ''){
                          usizes.push(dato.sizeName);
                        }
                      }      
                  }        
          
                  let newarraysizes = [{"sizeName":"ALL SIZES"}] 
                  const UppersSizes = usizes.map(word => (word.toUpperCase()).trim());
                  const dsizes = UppersSizes.filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);      
                  dsizes.forEach(element => {
                    newarraysizes.push({"sizeName":element})
                  });  

                  field.templateOptions.options = newarraysizes;
                  
                });

                form.get("styleName").valueChanges.subscribe((v) => {

                  let valProd = form.get("product").value
                  let valSize = form.get("sizeName").value == 'ALL SIZES' ? '' : form.get("sizeName").value
                  let valStyle = form.get("styleName").value == 'ALL STYLES' ? '' : form.get("styleName").value
                  
                  let prodfiltered = this.productsOriginal.filter((x:any)=>
                  x.Type.toLowerCase().includes(valProd.toLowerCase()) &&
                  x.sizeName.toLowerCase().includes(valSize.toLowerCase())  && 
                  x.styleName.toLowerCase().includes(valStyle.toLowerCase()) )

                  const usizes: string[] = [];
          
                  for (const dato of prodfiltered) {   
                      if (!usizes.includes(dato.sizeName)) {
                          usizes.push(dato.sizeName);                          
                      }      
                  }        
                  
                  let newarraysizes = [{"sizeName":"ALL SIZES"}]  
                  const UppersSizes = usizes.map(word => (word.toUpperCase()).trim());
                  const dsizes = UppersSizes.filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);      
                  dsizes.forEach(element => {
                    if (element != '') {
                      newarraysizes.push({"sizeName":element})
                    }                  
                  });                    

                  field.templateOptions.options = newarraysizes;
                  
                });

                form.get("sizeName").valueChanges.subscribe((v) => {

                  let valProd = form.get("product").value
                  let valSize = form.get("sizeName").value == 'ALL SIZES' ? '' : form.get("sizeName").value
                  let valStyle = form.get("styleName").value == 'ALL STYLES' ? '' : form.get("styleName").value
                  
                  let prodfiltered = this.productsOriginal.filter((x:any)=>
                  x.Type.toLowerCase().includes(valProd.toLowerCase()) &&
                  x.sizeName.toLowerCase().includes(valSize.toLowerCase())  && 
                  x.styleName.toLowerCase().includes(valStyle.toLowerCase()) )

                  const usizes: string[] = [];
          
                  for (const dato of prodfiltered) {   
                      if (!usizes.includes(dato.sizeName)) {
                          usizes.push(dato.sizeName);                          
                      }      
                  }        
                  
                  let newarraysizes = [{"sizeName":"ALL SIZES"}]  
                  const UppersSizes = usizes.map(word => (word.toUpperCase()).trim());
                  const dsizes = UppersSizes.filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);      
                  dsizes.forEach(element => {
                    if (element != '') {
                      newarraysizes.push({"sizeName":element})
                    }                  
                  });                    

                  field.templateOptions.options = newarraysizes;
                  
                });

              }
            }
          },
          {
            key: "type",
            type: "select",
            className: "col-md-1",
            defaultValue: "CURRENCY",
            templateOptions: {
              options: this.types,
              valueProp: "type",
              labelProp: "type",
            }
          },
          {
            key: "rangeFrom",
            type: "input",
            className: "col-md-1",
            defaultValue: 0,
            templateOptions: {
              type: "number",
              required: true,
            },
          },
          {
            key: "delimiter",
            type: "select",
            className: "col-md-1",
            defaultValue: ">=",
            templateOptions: {
              options: this.delimiters,
              valueProp: "delimiter",
              labelProp: "delimiter",
            }
          },
          {
            key: "rangeTo",
            type: "input",
            className: "col-md-1",
            defaultValue: "",
            templateOptions: {
              type: "number",
            },
          },
          {
            key: "commission",
            type: "input",
            className: "col-md-1",
            defaultValue: 0,
            templateOptions: {
              required: true,
              type: "number",        
            },
          },
          {
            key: "uuid",
            hide:true,
            type: "input",
            className: "col-md-1",
            defaultValue: 0,
            templateOptions: {
              type: "",        
              hidden:true
            },
          },
          {
            key: "eventId",
            hide:true,
            type: "input",
            className: "col-md-1",
            defaultValue: this.eventId,
            templateOptions: {
              type: "",        
              hidden:true
            },
          }
        ],
      },
    },
  ];


  constructor(private alertify: AlertifyService,
    private storeProductsService: StoreproductsService,
    private sponsorContributionService: SponsorContributionService,
    private globals:GlobalsService) { }

  ngOnInit(): void {
    this.loadStoreProducts();
  }


  loadStoreProducts() {
    this.storeProductsService.getStylesSizesProducts(this.eventId)
    .pipe(takeUntil(this.destroy$))   
    .subscribe(
      (res: any) => {

        this.productsOriginal = res.products
        this.products = []
        this.styles = []
        this.sizes = []
        const uproducts: string[] = [];
        const usizes: string[] = [];
        const ustyles: string[] = [];

        for (const dato of this.productsOriginal) {
            if (!uproducts.includes((dato.Type))) {
              uproducts.push(dato.Type);
            }

            if (!usizes.includes(dato.sizeName)) {
              usizes.push(dato.sizeName);
            }

            if (!ustyles.includes(dato.styleName)) {
              ustyles.push(dato.styleName);
            }
        }

        const UppersProds = uproducts.map(word => (word.toUpperCase()).trim());
        const dproducts = UppersProds.filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);      
        dproducts.forEach(element => {
          this.products.push({"product":element})
        });
        
        const UppersSizes = usizes.map(word => (word.toUpperCase()).trim());
        const dsizes = UppersSizes.filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);      
        this.sizes = [{"sizeName":"ALL SIZES"}]        
        dsizes.forEach(element => {
          this.sizes.push({"sizeName":element})
        }); 

        const UppersStyles = ustyles.map(word => (word.toUpperCase()).trim());
        const dstyles = UppersStyles.filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);      
        this.styles = [{"styleName":"ALL STYLES"}]        
        dstyles.forEach(element => {
          this.styles.push({"styleName":element})
        }); 

        this.fields[0].fieldArray.fieldGroup[0].templateOptions.options = this.products;
        this.fields[0].fieldArray.fieldGroup[1].templateOptions.options = this.styles;
        this.fields[0].fieldArray.fieldGroup[2].templateOptions.options = this.sizes;
        
        // this.getCommissions();
        this.model = res.commissions
        this.modelOriginal = Object.assign([], res.commissions);
      },
      (error) => {
        this.alertify.error(error);
      }
    );
  } 

  validateAllSizesOnProducts(): boolean{
    let errors = '';
    let valid = true;
    this.model.forEach(element => {
        
      let found = this.model.filter(x => x.product === element['product']);
      let arrayDuplicateSS = []

      found.forEach(element2 => { 

        // validate the range record
        if(element2['rangeTo'] != "" && valid == true){
          if( parseFloat(element2['rangeFrom']) > parseFloat(element2['rangeTo'])){
            valid = false;           
            errors = element['product'] + ' has commissions for style "' + element['styleName'] + '" and size "'+element['sizeName']+'" have a "Range From" greater than "Range To".';
            this.alertify.error(errors);
            return false;
          }
        }    
        
        if (element['styleName'] === element2['styleName'] && element['sizeName'] === element2['sizeName'])
        {    
          arrayDuplicateSS.push([element2['rangeFrom'],element2['delimiter'],element2['rangeTo'],element2['type'],element2['styleName'],element2['sizeName']])  
        }
      });

      if(arrayDuplicateSS.length > 1 && valid == true){



        // to get rows with range to empty
        let countRangeToEmpty = 0;
        let arrayRangeToEmpty = [];
        let type = arrayDuplicateSS[0][3];
        
        arrayDuplicateSS.forEach(el => {
        // verify if in a duplicates, there are a currency and qty, and return that only one should be exist
          if(type != el[3]){
            valid = false;           
            errors = element['product'] + ' has commissions for style "' + el[4] + '" and size "'+el[5]+'" that have diferent types, they can only be "CURRENCY" or "QTY".';
            this.alertify.error(errors);
            return false;
          }
        if(el[2] == "" || el[2] == null){
          countRangeToEmpty++;
          arrayRangeToEmpty = el
        } 
        });        

        // only can get a json with a range to empty
        if(countRangeToEmpty > 1 && valid === true){
          valid = false;
          errors = element['product'] + ' has commissions for style "' + element['styleName'] + '" and size "'+element['sizeName']+'" that have more than one range without limit.';
          this.alertify.error(errors);
          return false;
        }else if(countRangeToEmpty == 1 && valid === true){
          // the row with rangeFrom empty shoul be major tha anothers rangeTo
          arrayDuplicateSS.forEach(e => {

            if(e[1] == "" || e[1] == ">=" || e[1] == "<=" && valid === true){
              if(e[2] >= parseFloat(arrayRangeToEmpty[0])){
                if(e[2] == parseFloat(arrayRangeToEmpty[0])){
                  if(arrayRangeToEmpty[1] == "" || arrayRangeToEmpty[1] == ">=" || arrayRangeToEmpty[1] == "<=" ){
                    valid = false;
                    errors = element['product'] + ' has commissions for style "' + element['styleName'] + '" and size "'+element['sizeName']+'" that have more than one range with repeat values.';
                    this.alertify.error(errors);
                    return false;
                  }
                }else{
                  valid = false;
                  errors = element['product'] + ' has commissions for style "' + element['styleName'] + '" and size "'+element['sizeName']+'" that have more than one range with repeat values.';
                  this.alertify.error(errors);
                  return false;
                }
              }
            }

            if(e[1] == ">" || e[1] == "<"  && valid === true){
                if(e[2] > parseFloat(arrayRangeToEmpty[0])){
                  valid = false;
                  errors = element['product'] + ' has commissions for style "' + element['styleName'] + '" and size "'+element['sizeName']+'" that have more than one range with repeat values.';
                  this.alertify.error(errors);
                  return false;              
              }
            }
          });
        }

        //if there arent rangeto empty, comprate the range not contain reapet ranges
        if(countRangeToEmpty == 0 && valid === true){
          let arrayMergeValues = [];
          arrayDuplicateSS.forEach(range => {
            let valuesrange:any = this.obtenerValoresDeRango(range);
            arrayMergeValues = arrayMergeValues.concat(valuesrange);
          });

          const set = new Set(arrayMergeValues);
          if (arrayMergeValues.length != set.size) {
            valid = false;
            errors = element['product'] + ' has commissions for style "' + element['styleName'] + '" and size "'+element['sizeName']+'" that have more than one range with repeat values.';
            this.alertify.error(errors);
            return false;
          } 
        }
      }
    });
    return valid;
  }

  obtenerValoresDeRango(rango:any): any {
    const valores = [];
  
    if (rango[1] == '>' || rango[1] == '<') {
      for (let i:any = (parseFloat(rango[0]) + 0.01); i <  parseFloat(rango[2]); i= parseFloat((i+0.01).toFixed(2))) {
        valores.push(i.toFixed(2));
      }
    } else if (rango[1] == '>=' || rango[1] == '<=' || rango[1] == '') {
      for (let i:any = (parseFloat(rango[0])); i <= parseFloat(rango[2]); i = parseFloat((i+0.01).toFixed(2)) ) {
        valores.push(i.toFixed(2));
      }
    }  
    return valores;
  }

  submit(model){
    
    if (this.validateAllSizesOnProducts() == true) {

      if(this.form.valid){

        // make the 3 JsonPipe, toSave, toDelete, toUpdate
        let toSave = [];     
        this.model.forEach(element => {
          if(element.uuid == 0){
            element['uuid'] = this.globals.guid();
            element['eventId'] = this.eventId;
            element['created_at'] = this.globals.dNow();
            toSave.push(element)
          }


        });

        let toUpdate = [];
        this.model.forEach(m => {
          let exit = 0;  
          this.modelOriginal.forEach(mo => {
            if (mo.uuid == m.uuid) {
              exit++;
            }
          });
          if(exit > 0){
            m.updated_at = this.globals.dNow()
            toUpdate.push(m)
          }        
        });

        let toDelete = [];
        this.modelOriginal.forEach(mo => {
          let exit = 0;  
          this.model.forEach(m => {
            if (mo.uuid == m.uuid) {
              exit++;
            }
          });
          if(exit == 0){
            toDelete.push(mo)
          }        
        });   

        this.sponsorContributionService.saveCommissions({toSave:toSave,toUpdate:toUpdate,toDelete:toDelete,eventId:this.eventId})
        .pipe(takeUntil(this.destroy$))   
        .subscribe(
            (res: any) => {
              this.modelOriginal = Object.assign([], res);
              this.alertify.success('Commissions updated');       
            },
            (err: any) => {
              // console.log(err);          
            }
        )
      }else{
        this.alertify.error('invalid form')
      }
    }

  }

}
