<div  style="background-color: #f5f5f5 ;">
    <!-- <div class="modal-body-header">
      <h4>CUSTOMER</h4>
    </div> -->
    <!-- <div class="modal-close-button">
      <app-closebutton></app-closebutton>
    </div> -->
    <!-- <div *ngIf="form?.dirty" class="alert alert-warning">
      <strong>Information: </strong> You made changes. Any unsaved changes will be
      lost!
    </div> -->
  
    <small *ngIf="form?.dirty" class="text-warning bg-white">
      <strong><i class="fa fa-warning"></i>Information: </strong> You made changes. Any unsaved changes will be
      lost!
    </small>
  
  
    <form style="background-color: #f5f5f5 !important;"  [formGroup]="form" (ngSubmit)="addCustomer()">
      <div class="form-half"  style="margin-bottom: 1rem;">
        <h1 class="title-1">Billing Address</h1>
  
        <form style="background-color: #f5f5f5 !important;" [formGroup]="form" (ngSubmit)="addCustomer()">
          <formly-form style="background-color: #f5f5f5 !important;"
           [form]="form" [fields]="fields" [model]="customer" [options]="options">
          </formly-form>
        </form>
      </div>
  
      <div class="form-half">
        <div *ngIf="fromSalesOrder === false" class="form-full">
          <h5 class="form-header">Event</h5>
          <ng-select
            [items]="events"
            *ngIf="fromSalesOrder === false"
            #select
            bindLabel="Sponsor"
            dropdownPosition="auto"
            bindValue="CID"
            placeholder="Select an Event"
            [(ngModel)]="customer['eventId']"
            [ngModelOptions]="{ standalone: true }"
            [virtualScroll]="true"
          >
            <ng-template
              ng-label-tmp
              ng-option-tmp
              let-item="item"
              let-search="searchTerm"
            >
              <div>
                {{ item.sponsor }} {{ item.program1 }}
                {{ item.ShowDate | date }}
              </div>
            </ng-template>
          </ng-select>
        </div>


        <div class="form-header-container tw-mb-2 tw-flex">
          <div class="tw-flex tw-text-base sm:tw-text-base  md:tw-text-lg  lg:tw-text-lg tw-font-sans tw-text-gray-500 tw-font-bold">
            <span class="tw-my-auto">Shipping Address</span> 
          </div>
          <button class="btn-blue-so tw-ml-auto" type="button" (click)="copyAddressToShipping()">Same as Billing</button>
        </div>

       
        <form [formGroup]="formShipping" (ngSubmit)="addCustomer()">
          <formly-form
            [form]="formShipping"
            [fields]="fieldsShipping"
            [model]="customer"
            [options]="optionsShipping"
          >
            <div class="form-group text-center"></div>
          </formly-form>
        </form>
      </div>
     
       
      <div class="md:tw-flex tw-grid tw-grid-cols-2  tw-gap-2">
           <!-- [disabled]="!form.valid || !formShipping.valid" -->
        <button
        class="tw-bg-aedblue tw-text-white  tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-flex tw-items-center tw-justify-center tw-w-24"
        *ngIf="addMode"
        type="submit"
        id="btnSaveButton">
        <i class="material-icons">check</i>
        Save
        </button>
          <!-- [disabled]="!form.valid || !formShipping.valid" -->

        <button
        class="tw-bg-aedblue tw-text-white  tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-flex tw-items-center tw-justify-center tw-w-24"
        *ngIf="editMode"        
        (click)="updateCustomer()">
        Done
        </button>

        <button 
        class="tw-bg-aedblue tw-text-white  tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-flex tw-items-center tw-justify-center tw-w-24"
        (click)="cancelFormHTML()">
        <i class="material-icons">close</i>
        Cancel
        </button>

        <button *ngIf="storeSettings?.avaTax == 1 || storeSettings?.avaTax == true" class="tw-bg-aedblue tw-text-white tw-w-56  tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-flex tw-items-center tw-justify-center" (click)="validateAddress()" type="button">
          <span class="material-icons"> check </span> 
          Validate Address
        </button>

      </div>
    </form>
  </div>
  
  <ng-template #popTemplateiOs >
    <div class="tw-flex tw-flex-col tw-w-full tw-justify-center tw-pt-3">
      <p class="tw-text-xl tw-text-aedorangel tw-font-semibold tw-text-center">Avalara Suggested Address(es)</p>
      <p class="tw-text-base tw-text-aedgrays tw-font-normal tw-text-center">Select an address below</p>
    </div>
    <div class="tw-p-4 md:tw-grid  tw-flex-col md:tw-grid-cols-3 tw-gap-3" >
      <!-- Customer shipping -->
      <div class="tw-border-2 tw-text-base tw-flex tw-flex-col hover:tw-shadow-lg tw-cursor-pointer tw-border-gray-400 tw-rounded-lg tw-p-2" (click)="setAddressFromAvalara(null)">
        <span class="tw-font-bold tw-break-words" >Current Shipping Address</span>
        <span >{{customer?.deliveryAddress1}} </span>
        <span *ngIf="customer?.deliveryAddress2 != ''" >{{customer?.deliveryAddress2}} </span>
        <span >{{customer?.deliveryState}} {{customer?.deliveryCity}} {{customer?.deliveryZip}}</span>
      </div>
      <!-- From Avalara -->
      <div class="tw-border-2 tw-text-base tw-flex tw-flex-col hover:tw-shadow-lg tw-cursor-pointer tw-border-gray-400 tw-rounded-lg tw-p-2" *ngFor="let address of validatedAddress" (click)="setAddressFromAvalara(address)">
        <span class="tw-font-bold tw-break-words" >{{address?.addressType}} </span>
        <span [ngClass]="{'tw-text-green-500': address.line1 != customer?.deliveryAddress1}">{{address?.line1}} </span>
        <span [ngClass]="{'tw-text-green-500': address.line2 != customer?.deliveryAddress2}" *ngIf="address?.line2 != ''" >{{address?.line2}} </span>
        <div class="tw-flex tw-space-x-1">
          <span [ngClass]="{'tw-text-green-500': address.region != customer?.deliveryState}" >{{address?.region}}</span> 
          <span [ngClass]="{'tw-text-green-500': address.city != customer?.deliveryCity}">{{address?.city}} </span> 
          <span [ngClass]="{'tw-text-green-500': address?.postalCode?.substring(0,5) != customer?.deliveryZip}">{{address?.postalCode?.substring(0,5)}}</span>
        </div>
      </div>
    </div>
  </ng-template>