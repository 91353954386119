<div *ngIf="customer">
  <app-customer-card
    [customer]="customer"
    [search]="true"
    [delivery]="salesOrder.deliveryRequest"
    [salesOrder]="salesOrder"
  ></app-customer-card>
</div>
<div *ngIf="!customer">
  <div class="btn btn-outline-primary" (click)="openModalWithComponent()">
    {{ 'SELECTCUSTOMER' | translate }}
  </div>
</div>
