import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DummymodalComponent } from '../../dummymodal/dummymodal.component';
import { CustomerService } from 'src/app/_services/customer.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-customer-info',
  templateUrl: './customer-info.component.html',
  styleUrls: ['./customer-info.component.scss']
})
export class CustomerInfoComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() salesOrder: any;
  @Input() showBoth = false;
  customer: any[];
  delivery = 'Pickup';
  seletectedCustomer;
  constructor(
    private customerService: CustomerService,
    public bsModalRef: BsModalRef,
    public userService: UserService,
    private modalService: BsModalService,
    private alertify: AlertifyService,
    private authService: AuthService
    ) { }

  ngOnInit() {
    this.customerService.salesOrderCustomer
    .pipe(takeUntil(this.destroy$)) 
    .subscribe((
      res => {
        this.seletectedCustomer = res;
        this.customerService.getCustomer(this.seletectedCustomer)
        .pipe(takeUntil(this.destroy$)) 
        .subscribe( data => {
          this.customer = data[0];
        }, error => {
          this.alertify.error(error);
        });
      }
    ));

  }

  public openModalWithComponent() {
      this.bsModalRef = this.modalService.show(DummymodalComponent, {class: 'modal-lg'});
      // let data = {
      //   userId: '123',
      //   userName: this.authService.decodeToken('username').username,
      //   section: 'SO',
      //   action: 'Select Customer',
      //   eventId: '*',
      //   device: localStorage.getItem('device'),
      // }
      
      // this.userService.logTracking(data).pipe(takeUntil(this.destroy$)).subscribe(a => {});
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}


