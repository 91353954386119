import { Directive, ElementRef, HostListener, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appClickPanel]'
})
export class ClickPanelDirective {

  //constructor(private el: ElementRef,    private renderer: Renderer2) { }
  constructor() { }

  @Input() defaultColor: string;

  @Input('appClickPanel') highlightColor: string;

  @HostListener('mouseenter') onMouseEnter() {
    this.highlight(this.highlightColor || this.defaultColor || 'red');
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.highlight(null);
  }

  private highlight(color: string) {
    //this.el.nativeElement.style.backgroundColor = color;


  }

}
