<div [ngClass]="from != 'SOmobile' ? 'tw-p-4':''">
  
  
      <div *ngIf="from != 'SOmobile'" >

        <div class="tw-flex">
          <button class="tw-ml-auto" (click)="closeModal()">      
            <span class="material-icons tw-text-aedblue" style="font-size: 1.8rem"> check_circle </span>
          </button>
        </div>  

        <!-- icon and title -->
        <div class="tw-flex tw-justify-between">  
          <div class="tw-flex tw-flex-row" >
            <h1 class="tw-text-2xl sm:tw-text-lg md:tw-text-3xl lg:tw-text-3xl tittle-0">
              <span style="min-width: max-content;">void payments</span>  
             </h1>  
            <svg  class="tw-h-8 tw-w-8 tw-stroke-1 tw-ml-3 tw-text-aedoranges" xmlns="http://www.w3.org/2000/svg" width="32.758" height="32.486" viewBox="0 0 32.758 32.486">
              <g id="Group_256" data-name="Group 256" transform="translate(3534.159 624.977)">
                <g id="Group_232" data-name="Group 232" transform="translate(-3533.809 -624.627)">
                  <path id="Path_172" data-name="Path 172" d="M388.213,3.832A15.932,15.932,0,0,1,399.46,8.449,15.746,15.746,0,0,1,394.4,34.142a15.988,15.988,0,0,1-17.439-3.379A15.746,15.746,0,0,1,382.022,5.07,15.93,15.93,0,0,1,388.213,3.832Zm0,29.9A14.281,14.281,0,0,0,398.3,29.59a14.071,14.071,0,0,0,3.055-4.489,14.048,14.048,0,0,0,0-10.989A14.071,14.071,0,0,0,398.3,9.622a14.336,14.336,0,0,0-20.171,0,14.07,14.07,0,0,0-3.055,4.489,14.048,14.048,0,0,0,0,10.989,14.07,14.07,0,0,0,3.055,4.489,14.282,14.282,0,0,0,10.086,4.139Z" transform="translate(-372.183 -3.713)" fill="#ee5f00"/>
                  <path id="Path_172_-_Outline" data-name="Path 172 - Outline" d="M388.213,3.482A16.282,16.282,0,0,1,399.707,8.2a16.1,16.1,0,0,1-5.168,26.264,16.338,16.338,0,0,1-17.821-3.453,16.1,16.1,0,0,1,5.168-26.264A16.278,16.278,0,0,1,388.213,3.482Zm0,31.549a15.582,15.582,0,0,0,11-4.516,15.366,15.366,0,0,0,3.336-4.9,15.349,15.349,0,0,0,0-12.011,15.547,15.547,0,0,0-8.281-8.207,15.638,15.638,0,0,0-17.057,3.3,15.367,15.367,0,0,0-3.336,4.9,15.349,15.349,0,0,0,0,12.011,15.547,15.547,0,0,0,8.282,8.207A15.582,15.582,0,0,0,388.213,35.031Zm0-29.9a14.631,14.631,0,0,1,10.331,4.241,14.42,14.42,0,0,1,3.13,4.6,14.4,14.4,0,0,1,0,11.264,14.42,14.42,0,0,1-3.13,4.6,14.686,14.686,0,0,1-20.664,0,14.418,14.418,0,0,1-3.13-4.6,14.4,14.4,0,0,1,0-11.264,14.419,14.419,0,0,1,3.13-4.6,14.632,14.632,0,0,1,10.332-4.241Zm0,28.246a13.931,13.931,0,0,0,9.839-4.038,13.723,13.723,0,0,0,2.979-4.378,13.7,13.7,0,0,0,0-10.714,13.723,13.723,0,0,0-2.979-4.378,13.986,13.986,0,0,0-19.678,0,13.722,13.722,0,0,0-2.979,4.378,13.7,13.7,0,0,0,0,10.714,13.721,13.721,0,0,0,2.979,4.378,13.931,13.931,0,0,0,9.839,4.038Z" transform="translate(-372.183 -3.713)" fill="#ee5f00"/>
                  <path id="Path_172_-_Outline-2" data-name="Path 172 - Outline" d="M388.181,3.681a16.05,16.05,0,0,1,11.331,4.652,15.865,15.865,0,0,1-5.094,25.886,16.106,16.106,0,0,1-17.568-3.4A15.864,15.864,0,0,1,381.944,4.93,16.048,16.048,0,0,1,388.181,3.681Zm0,31.549a15.814,15.814,0,0,0,11.164-4.583,15.628,15.628,0,0,0-5.019-25.5A15.869,15.869,0,0,0,377.016,8.5a15.628,15.628,0,0,0,5.02,25.5A15.813,15.813,0,0,0,388.181,35.231Zm0-29.9a14.4,14.4,0,0,1,10.168,4.173,14.189,14.189,0,0,1,3.08,4.527,14.166,14.166,0,0,1,0,11.082,14.189,14.189,0,0,1-3.08,4.527,14.454,14.454,0,0,1-20.337,0,14.19,14.19,0,0,1-3.08-4.527,14.166,14.166,0,0,1,0-11.082,14.189,14.189,0,0,1,3.08-4.527,14.4,14.4,0,0,1,10.169-4.173Zm0,28.246a14.162,14.162,0,0,0,10-4.1,13.954,13.954,0,0,0,3.029-4.452,13.93,13.93,0,0,0,0-10.9,13.954,13.954,0,0,0-3.029-4.452,14.218,14.218,0,0,0-20,0,13.952,13.952,0,0,0-3.029,4.452,13.929,13.929,0,0,0,0,10.9,13.952,13.952,0,0,0,3.029,4.452,14.164,14.164,0,0,0,10,4.1Z" transform="translate(-372.151 -3.681)" fill="#ee5f00"/>
                  <path id="Path_172_-_Outline_-_Outline" data-name="Path 172 - Outline - Outline" d="M388.181,3.331a16.4,16.4,0,0,1,11.577,4.753,16.215,16.215,0,0,1-5.205,26.457,16.456,16.456,0,0,1-17.95-3.478,16.214,16.214,0,0,1,5.206-26.457A16.4,16.4,0,0,1,388.181,3.331Zm0,31.549A15.464,15.464,0,0,0,399.1,30.4a15.25,15.25,0,0,0,3.31-4.865,15.231,15.231,0,0,0,0-11.918,15.428,15.428,0,0,0-8.218-8.145A15.519,15.519,0,0,0,377.262,8.75a15.249,15.249,0,0,0-3.31,4.865,15.231,15.231,0,0,0,0,11.918,15.429,15.429,0,0,0,8.219,8.145A15.465,15.465,0,0,0,388.181,34.881Zm0-29.9A14.749,14.749,0,0,1,398.6,9.258a14.537,14.537,0,0,1,3.156,4.638,14.516,14.516,0,0,1,0,11.357,14.537,14.537,0,0,1-3.156,4.638,14.8,14.8,0,0,1-20.83,0,14.538,14.538,0,0,1-3.156-4.638,14.515,14.515,0,0,1,0-11.357,14.538,14.538,0,0,1,3.156-4.638,14.75,14.75,0,0,1,10.415-4.275Zm0,28.246a13.812,13.812,0,0,0,9.755-4,13.605,13.605,0,0,0,2.954-4.34,13.58,13.58,0,0,0,0-10.622,13.605,13.605,0,0,0-2.954-4.34,13.868,13.868,0,0,0-19.512,0,13.6,13.6,0,0,0-2.954,4.34,13.579,13.579,0,0,0,0,10.622,13.6,13.6,0,0,0,2.954,4.34,13.813,13.813,0,0,0,9.756,4Z" transform="translate(-372.151 -3.681)" fill="#ee5f00"/>
                  <path id="Path_173" data-name="Path 173" d="M392.1,31.166V28.6a7.382,7.382,0,0,1-3.884-1.118l.609-1.7a6.765,6.765,0,0,0,3.656,1.093c1.8,0,3.021-1.042,3.021-2.491,0-1.4-.99-2.262-2.869-3.025-2.589-1.017-4.188-2.186-4.188-4.4a4.166,4.166,0,0,1,3.833-4.092V10.3h1.574v2.466a6.757,6.757,0,0,1,3.3.889l-.634,1.677a6.123,6.123,0,0,0-3.2-.865c-1.955,0-2.691,1.17-2.691,2.186,0,1.323.939,1.983,3.148,2.9,2.615,1.068,3.935,2.389,3.935,4.651a4.42,4.42,0,0,1-4.012,4.322v2.643Z" transform="translate(-376.935 -4.839)" fill="#ee5f00"/>
                  <path id="Path_173_-_Outline" data-name="Path 173 - Outline" d="M394.049,31.516h-2.3V28.935a7.583,7.583,0,0,1-3.738-1.17l-.215-.154.849-2.374.373.249a6.386,6.386,0,0,0,3.462,1.034,3.06,3.06,0,0,0,1.962-.621,1.92,1.92,0,0,0,.709-1.52c0-1.154-.768-1.936-2.65-2.7-2.287-.9-4.407-2.093-4.407-4.721a4.458,4.458,0,0,1,3.833-4.383V9.948H394.2v2.483a6.984,6.984,0,0,1,3.128.92l.255.149-.884,2.338-.356-.2a5.761,5.761,0,0,0-3.025-.818c-1.08,0-2.341.481-2.341,1.836,0,1.055.658,1.633,2.932,2.574,2.909,1.187,4.151,2.675,4.151,4.975a4.534,4.534,0,0,1-1.111,2.982,5.209,5.209,0,0,1-2.9,1.63Zm-1.6-.7h.9v-2.59l.293-.048a4.607,4.607,0,0,0,2.78-1.455,3.834,3.834,0,0,0,.939-2.522c0-2-1.077-3.249-3.717-4.327a9.075,9.075,0,0,1-2.5-1.356,2.328,2.328,0,0,1-.866-1.866c0-1.262.94-2.536,3.041-2.536a6.381,6.381,0,0,1,3.014.715l.385-1.019a6.585,6.585,0,0,0-2.877-.7L393.5,13.1V10.648h-.874v2.515l-.294.048a4.477,4.477,0,0,0-2.593,1.291,3.523,3.523,0,0,0-.946,2.455c0,1.809,1.112,2.95,3.966,4.071,1.53.621,3.091,1.5,3.091,3.35a2.635,2.635,0,0,1-.96,2.057,3.7,3.7,0,0,1-2.411.783,7.156,7.156,0,0,1-3.472-.918l-.371,1.038a7.4,7.4,0,0,0,3.468.909l.344.006Z" transform="translate(-376.935 -4.839)" fill="#ee5f00"/>
                </g>
              </g>
            </svg>
          </div> 
        </div>  

      </div>  


      <div *ngIf="paymentsVOID.length > 0 && !showCloverSide">
        
        <nav *ngFor="let payment of paymentsVOID;let lastItem = last;let i = index" class="tw-flex tw-flex-col tw-p-4 tw-my-1 tw-bg-aedgraye tw-rounded-lg">
          <div class="tw-flex tw-space-x-1 tw-justify-between tw-w-full tw-py-1">
            
            <div class="tw-flex tw-w-3/4 tw-flex-col">
              <span [ngClass]="{'tw-text-aedgrays': (payment.voidtype_tx != undefined && payment.voidtype_tx != null), 'tw-text-aedoranges' : payment.voidtype_tx == undefined || payment.voidtype_tx == null }"  class="tw-text-2xl tw-font-bold tw-my-auto">
                <!-- <strong *ngIf="payment.voidtype_tx != undefined && payment.voidtype_tx != null">VOIDED</strong>  -->
                {{ paymentsVOID.length > 1 ? ((getIndex(payment.paymentID)+1)+'. '):'' }} {{payment.paymentType}} | {{payment.paymentAmount | currency}}
              </span>
              
              <div *ngIf="payment.authCode" class="tw-text-base tw-text-aedgrays"  style="display: flex; flex-wrap: wrap;">
                
                <div style="margin-right: 1rem;">
                  <div style="min-width: max-content;">{{ "Transaction approved: "+payment.authCode }}</div>
                  <div>{{ "Card Type: "+payment.cardType }}</div> 
                </div>

                <div>
                  <div>{{ "Last 4 digits: "+payment.cardLastFour }}</div>
                  <div>{{ "Card Holder: "+payment.nameOnCard }}</div> 
                </div>

              </div>
            </div>

            <!-- Buttons options -->
            <div class="tw-grid tw-grid-cols-2 tw-gap-1"> 

              <button style="width: max-content;outline: none;" (click)="printClover(payment)"
                *ngIf="payment.paymentType == 'Credit Card' && (payment.authCode != undefined && payment.authCode != null)"
                class="tw-text-white tw-w-14 tw-h-10 tw-py-1 tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-ml-2" 
                [ngClass]="false ? 'tw-bg-aedbluedisabled' : 'tw-bg-aedblue'" 
                >
                <svg class="tw-w-7 sm:tw-w-7 tw-h-7 sm:tw-h-7" xmlns="http://www.w3.org/2000/svg" width="42.687" height="40" viewBox="0 0 42.687 40">
                    <g id="Group_251" data-name="Group 251" transform="translate(3525.284 756.338)">
                      <path id="Path_192" data-name="Path 192" d="M78.963,39.32H61.153a4.158,4.158,0,0,1-4.212-4.092V21.833H83.174V35.228A4.158,4.158,0,0,1,78.963,39.32ZM59.069,23.9V35.228a2.058,2.058,0,0,0,2.084,2.025h17.81a2.058,2.058,0,0,0,2.085-2.025V23.9Z" transform="translate(-3574 -755.658)" fill="#fff"/>
                      <path id="Path_193" data-name="Path 193" d="M54.51,9.306h31.1a5.73,5.73,0,0,1,5.8,5.65V25.574a5.73,5.73,0,0,1-5.8,5.65H81.048V23.545H59.069v7.679H54.51a5.73,5.73,0,0,1-5.794-5.65V14.956A5.73,5.73,0,0,1,54.51,9.306Zm31.1,19.85a3.631,3.631,0,0,0,3.669-3.583V14.956a3.631,3.631,0,0,0-3.669-3.583H54.51a3.63,3.63,0,0,0-3.667,3.583V25.574a3.63,3.63,0,0,0,3.667,3.583h2.433V21.478H83.175v7.679Z" transform="translate(-3574 -755.302)" fill="#fff"/>
                      <line id="Line_88" data-name="Line 88" x2="8.762" transform="translate(-3508.323 -727.609)" fill="#fff"/>
                      <path id="Line_89" data-name="Line 89" d="M8.762.669H0V-.709H8.762Z" transform="translate(-3508.323 -727.589)" fill="#fff"/>
                      <line id="Line_90" data-name="Line 90" x2="8.762" transform="translate(-3508.323 -722.68)" fill="#fff"/>
                      <path id="Line_91" data-name="Line 91" d="M8.762.669H0V-.709H8.762Z" transform="translate(-3508.323 -722.66)" fill="#fff"/>
                      <line id="Line_92" data-name="Line 92" x2="6.301" transform="translate(-3519.125 -739.976)" fill="#fff"/>
                      <path id="Line_93" data-name="Line 93" d="M6.3.669H0V-.709H6.3Z" transform="translate(-3519.125 -739.956)" fill="#fff"/>
                      <path id="Line_94" data-name="Line 94" d="M27.937,1H0V-1.064H27.937Z" transform="translate(-3517.91 -732.761)" fill="#fff"/>
                      <path id="Path_194" data-name="Path 194" d="M62.027,0H78.09a5.31,5.31,0,0,1,5.074,5.507v6.872H56.952V5.507A5.31,5.31,0,0,1,62.027,0ZM81.094,10.133V5.507a3.144,3.144,0,0,0-3-3.261H62.027a3.145,3.145,0,0,0-3.006,3.261v4.626Z" transform="translate(-3574 -756.337)" fill="#fff"/>
                    </g>
                </svg>
              </button>
    
              <button style="width: max-content;outline: none;" (click)="confirmEmailClover(payment, 'EMAIL')"
                *ngIf="payment.paymentType == 'Credit Card' && (payment.authCode != undefined && payment.authCode != null)"
                class="tw-text-white tw-w-14 tw-h-10 tw-py-1 tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-ml-2" 
                [ngClass]="false ? 'tw-bg-aedbluedisabled' : 'tw-bg-aedblue'" 
                >
                <svg class="tw-w-7 sm:tw-w-7 tw-h-7 sm:tw-h-7" xmlns="http://www.w3.org/2000/svg" width="42.552" height="35.421" viewBox="0 0 42.552 35.421">
                  <g id="Group_250" data-name="Group 250" transform="translate(3474.511 752.796)">
                    <path id="Rectangle_396" data-name="Rectangle 396" d="M1.936-1.124H38.369a3.139,3.139,0,0,1,3.06,3.205V31.091a3.14,3.14,0,0,1-3.06,3.206H1.936a3.139,3.139,0,0,1-3.06-3.205V2.082A3.139,3.139,0,0,1,1.936-1.124ZM38.368,32.05a.939.939,0,0,0,.916-.96V2.082a.938.938,0,0,0-.915-.959H1.936a.939.939,0,0,0-.915.959v29.01a.938.938,0,0,0,.915.958Z" transform="translate(-3473.388 -751.672)" fill="#fff"/>
                    <path id="Path_195" data-name="Path 195" d="M121.019,25.463h-.013a2.955,2.955,0,0,1-2.2-1.007L100.772,4.42l1.555-1.544,18.037,20.035a.856.856,0,0,0,1.3.006L139.661,3.267,141.2,4.825,123.21,24.475A2.952,2.952,0,0,1,121.019,25.463Z" transform="translate(-3574 -755)" fill="#fff"/>
                    <path id="Line_95" data-name="Line 95" d="M14.452,16.649-.81.779.81-.779l15.262,15.87Z" transform="translate(-3448.868 -735.085)" fill="#fff"/>
                    <path id="Line_96" data-name="Line 96" d="M.81,16.649-.81,15.091,14.452-.779,16.072.779Z" transform="translate(-3472.47 -735.085)" fill="#fff"/>
                  </g>
                </svg>    
                <i class="fa fa-spinner fa-spin mr-1" *ngIf="emailLoading"></i>
              </button>
              
              <button style="width: max-content;outline: none;" (click)="confirmEmailClover(payment, 'SMS')"
                *ngIf="payment.paymentType == 'Credit Card' && (payment.authCode != undefined && payment.authCode != null)"                  
                class="tw-text-white tw-w-14 tw-h-10 tw-py-1 tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-ml-2" 
                [ngClass]="false ? 'tw-bg-aedbluedisabled' : 'tw-bg-aedblue'" 
                ><span class="material-icons tw-w-7 sm:tw-w-7 tw-h-7 sm:tw-h-7"> chat</span>
              </button>

            </div>

          </div>
        </nav>

      </div>


      <div *ngIf="showCloverSide" class="tw-flex tw-flex-col tw-items-center tw-justify-center">
        <img *ngIf="statusAvalara != 'Select Device'" class="tw-transition tw-animate-spin tw-transform" src="assets/clover.png"  style="height: 150px;">
        <span class="tw-text-2xl tw-text-aedgraycard tw-font-bold">{{statusAvalara}}</span>
        <span class="tw-text-2xl tw-text-aedoranges tw-font-bold" *ngIf="statusAvalara == 'Processing Transaction'"> Charging {{paymentInProcess.paymentAmount | currency}}</span>
        <!-- {{cloverIMG}} -->
        <div *ngIf="statusAvalara == 'Select Device'" class="tw-flex tw-space-x-2 tw-pt-5" >
          <div *ngFor="let device of filterActive(devices)" (click)="showCard(device?.deviceId)" class="tw-rounded-2xl hover:tw-bg-aedoranges tw-cursor-pointer tw-bg-aedgrayk tw-flex tw-flex-col tw-justify-center tw-items-center tw-mx-auto tw-p-4" >
            <div>
              <span [innerHTML]="cloverIMG[device.Type].svg | safeHtml"></span>
            </div>
            <span class="tw-text-white tw-font-bold tw-text-lg">{{device?.name}}</span>
            <span class="tw-text-white tw-font-bold tw-text-sm">{{cloverIMG[device?.Type].name}}</span>
          </div>
        </div>
        <button class="btn-orange-so tw-mt-2" *ngIf="statusAvalara == 'Select Device'"  (click)="showCloverSide = false" >Cancel</button>
        <!-- {{devices | jzson}} -->
        <span *ngIf="statusAvalara == 'Processing Transaction'" >{{countdown$ | async}} seconds remaining</span>
      </div>

</div>