<div *ngIf="!isLogged" class="" hidden>
  <div id="bg" [ngStyle]="styleForImage"></div>





  <div class="">
    <div class="loginformaround"> 
      
  
      <div class="loginform">
        <img src="assets/aedpayhorsmalllogin.png" class="" />
        <form
          #loginForm="ngForm"
          class="form bg-transparent text-center"
          (ngSubmit)="login()"
        >

       
          <div class="">
            <div class="input-group mb-3">
              <div class="input-group-prepend"> 
                <span class="input-group-text smalltext bg-white">
                  <span class="material-icons text-primary" style="font-size: 16px;">
                    person
                  </span>
                </span>
              </div>
              <input
                class="form-control"
                type="text"
                id ="username"
                name="username"
                placeholder="UserName"
                required
                [(ngModel)]="model.username"
                (ngModelChange)="ConvertToLower($event)"
              />
            </div>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text smalltext bg-white">
                  <span class="material-icons text-primary" style="font-size: 16px;">
                    vpn_key
                    </span>
                </span>
              </div>
              <input
                class="form-control"
                type="password"
                name="password"
                id="password"
                placeholder="Password"
                required
                [(ngModel)]="model.password"
                (ngModelChange)="ConvertToLowerPass($event)"
              />
            </div>
          </div>
          <div class="d-flex justify-content-center">
            <button
              [disabled]="!loginForm.valid"
              class="btn btn-2 tw-cursor-pointer"
              type="submit"
              id="btnLogin"
            >
              <i class="fa fa-sign-in mr-1" ></i> Login
            </button>  
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
