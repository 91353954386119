import { Output, Component, ElementRef, Input, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs/Subscription';
import { Pagination } from 'src/app/_modules/pagination';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ReportingService } from 'src/app/_services/reporting.service';
import { StockItemsService } from 'src/app/_services/stockitems.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import 'rxjs/add/operator/takeUntil';
import { take } from 'rxjs/operators/take';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UpcitemdbService } from 'src/app/_services/upcitemdb.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-items-to-po',
  templateUrl: './items-to-po.component.html',
  styleUrls: ['./items-to-po.component.scss']
})
export class ItemsToPOComponent implements OnInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
	modalRef: BsModalRef;
	@Output() product: any = [];
	@Input() salesOrderMode = false;
	p:any = 1
	p2:any = 1
	p4:any = 1
	editMode: boolean[] = [];
	addresses: any = [];
  	stockItemsPO: any = [];
	stockItems: any = [];
	companies: any = [''];
	types: any = [];
	brands: any = [];
	products: any = [];
	pagination: any;
	subscriptions: Subscription[] = [];
	selectedType = '';
	selectedMan = '';
	selectedBrand = '';
	selectedProduct = '';
	data: any = [];
	storeExcel: any = [];
	toSaveStockItems: any = [];
	showSave = false;
	isDealer = false;
	maxsize = 10;
	recordItems: any = [];
	recordItemsDefault: any = [];
	customStockItemEditing: any = null;
	toEdit: string = '';
	showOrderwithStock: any = '';
	SOsItemsStock: any = [];
	showModal: boolean = false;
	// InfoPO: FormGroup;
	InfoPO = new FormGroup({
		CCID: new FormControl('',[]),
		address: new FormControl('',[Validators.required]),
		products: new FormControl([],[]),
		name: new FormControl('',[Validators.required]),
		phone: new FormControl('',[Validators.required]),
		phone2: new FormControl('',[]),
		email: new FormControl('',[]),
	});
	showHistory:any = false;
	filteredProducts: any;
	filteredBrands: any;
	filteredManufacturer: any;
	filteredTypes: any;

	constructor(
		private stockItemsService: StockItemsService,
		private alertify: AlertifyService,
		private router: Router,
		private authService: AuthService,
		private globals: GlobalsService,
		private modalService: BsModalService,
		private fb: FormBuilder,
		private translate: TranslateService,
	) {}
	dbServerC = this.authService.decodeToken('dbServerC')?.dbServerC;
	dbServer = this.authService.decodeToken('dbServer')?.dbServer;

  ngOnInit(): void {
	this.p2 = 1
    this.pagination = {
		totalItems: 10,
		totalPages: 10,
		currentPage: 1,
		itemsPerPage: 10,
	};
		if (this.globals.isMobile()) {
			this.maxsize = 4;
		}
		if (this.router.url.toString().includes('stockitems') && this.authService.decodeToken('role').role !== 'Admin') {
			this.isDealer = true;
		}

		this.loadStockItemsLocal(0);
		this.getAddressTerritory()
  }

  ChangeAddress(e:any){
	let uuid = e.target.value
	let address = this.addresses.find((x:any)=> x.uuid == uuid)
	if(address){
		this.InfoPO.controls['name'].setValue(address.name)
		this.InfoPO.controls['phone'].setValue(address.phone)
		this.InfoPO.controls['phone2'].setValue(address.phone2)
		this.InfoPO.controls['email'].setValue(address.email)
	}
  }

  	loadStockItemsLocal(indexes:any = 0) {

		this.recordItems = []
		this.stockItems = []
		this.stockItemsService
		.getCMSItems(this.p2,this.pagination.itemsPerPage,this.selectedMan,this.selectedType,this.selectedBrand,this.selectedProduct,indexes)
		.pipe(takeUntil(this.destroy$))
		.subscribe(
			(res: any) => {
				
				this.recordItems = JSON.parse(JSON.stringify(res.result.data)) 
				this.stockItems = JSON.parse(JSON.stringify(res.result.data));

				if(this.recordItems.length > 0){					
					this.companies = res.companies.filter((x:any)=>x.manufacture != '-' && x.manufacture != ''  && x.manufacture != ' - ');
					this.types = res.type.filter((x:any)=>x.productType != '-' && x.productType != '' && x.productType != ' - ');
					this.brands = res.brand.filter((x:any)=>x.brand != '-' && x.brand != '' && x.brand != ' - ');
					this.products = res.products.filter((x:any)=>x.productName != '-' && x.productName != '' && x.productName != ' - ');
					
				}
				
				this.pagination.totalPages = res.result.last_page;
				this.pagination.totalItems = res.result.total;
				
			},
			(error) => {
				this.alertify.error(error);
			}
		);
	}



	
	getAddressTerritory() {
		this.stockItemsService
			.getAddresses(
				{
					dbServer:this.dbServer,
					dbServerC:this.dbServerC
				}	
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: any) => {
					//console.log(res);
					this.addresses = res.data;
				},
				(error) => {
					this.alertify.error(error);
				}
			);
	}

	totalQTY():number{
		let total=0;
		this.stockItemsPO.forEach(element => {
			total += element.QTY;
		});
		
		return total;
	}
	closeModal(noShow?: boolean) {
		this.modalRef.hide();
		if (noShow) {
			this.toEdit = '';
			this.customStockItemEditing = null;
			this.resetForm();
			this.showModal = false;
		}
	}
	resetForm() {
		this.InfoPO.reset();
	}


	//For select filters
	loadFilter(event: any, field: any, value: any) {
		if (field == 'product') {
			this.selectedProduct = value;
		}
		if (field == 'brand') {
			this.selectedBrand = value;
		}
		if (field == 'manufacture') {
			this.selectedMan = value;
		}
		if (field == 'type') {
			this.selectedType = value;
		}
		this.p2 = 1
		this.filteredProducts = []
		this.filteredBrands = []
		this.filteredManufacturer = []
		this.filteredTypes = []
		this.loadStockItemsLocal(1)
	}



	pageChanged(event:any){
		//console.log(event);		
		this.p2 = event.page
		this.loadStockItemsLocal()
	}

	//Termina Funciones para Filtro JS
	get selectedTypeMod() {
		return this.selectedType;
	}

	set selectedTypeMod(value) {
		this.selectedType = value;
	}

	get selectedManMod() {
		return this.selectedMan;
	}

	set selectedManMod(value) {
		this.selectedMan = value;
	}

	get selectedBrandMod() {
		return this.selectedBrand;
	}

	set selectedBrandMod(value) {
		this.selectedBrand = value;
	}

	get selectedProductMod() {
		return this.selectedProduct;
	}

	set selectedProductMod(value) {
		this.selectedProduct = value;
	}
	clearFilter() {
		this.selectedMan = ''
		this.selectedType = ''
		this.selectedBrand = ''
		this.selectedProduct = ''
		this.filteredProducts = []
		this.filteredBrands = []
		this.filteredManufacturer = []
		this.filteredTypes = []
		this.loadStockItemsLocal()
	}

  addToPO(item:any){
    const findItem = this.stockItemsPO.find((element)=>{
        return element.productCode==item.productCode;
    })

    if(!findItem){
      let product = JSON.parse(JSON.stringify(item))
      product.QTY=1;
      this.stockItemsPO.push(product);
    }
    else
    {
      findItem.QTY += 1;
    };
    
  }

  sendPO(tem: TemplateRef<any>){
	this.p4 = 1
	this.modalRef = this.modalService.show(tem, { class: 'modal-lg', ignoreBackdropClick: true });
	this.showModal = true;
  }

  deleteItem(index: number) {
    if (index >= 0 && index < this.stockItemsPO.length) {
      this.stockItemsPO.splice(index, 1);
    }
  }

  onInputBlur(index:any){
    this.editMode[index] = false;
  }

  onQuantityChange(i:any){
	if(this.stockItemsPO[i].QTY<=0){
		this.stockItemsPO[i].QTY=1
	}
  }
  toggleEditMode(index: number) {
    this.editMode[index] = true;
  }

  savePO(){
	
	const idAddress = this.InfoPO.value.address;
	const Address = this.addresses.find((element)=>{return element.uuid==idAddress})
	const PO={
		"listCode": "",

		"addressId": idAddress,
		"jsonProds": this.stockItemsPO,
		"territory": Address.ROID,
		"dshipName": this.InfoPO.value.name,
		"dshipPhone": this.InfoPO.value.phone,
		"dshipPhone2": this.InfoPO.value.phone2,
		"dshipEMail": this.InfoPO.value.email,
		"ROID": Address.ROID,
		"deliveryType": "Direct Shipment",
		"address": Address.address,
		"address2": Address.address2,
		"city": Address.city,
		"state": Address.state,
		"zipcode": Address.zipcode,
		'dbServerC': this.dbServerC
	}

		this.stockItemsService.saveList(PO)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: any) => {
					//console.log(res);
					this.InfoPO.reset();
					this.alertify.success(this.translate.instant('PURCHASE_SUCCESS'));
					this.closeModal()
					this.stockItemsPO = []
				},
				(error) => {
					this.alertify.error(error);
				}
			);
	
  }

  hideHistory(e:any){
	this.showHistory = e
  }

  filterProducts(query: string, option:any) {
	const searchTermLowerCase = query.toLowerCase();
	const searchRegex = new RegExp(searchTermLowerCase, 'i');

	//console.log(searchTermLowerCase);
	
	if (searchTermLowerCase != ''){
		if (option == 'productName'){
			this.filteredProducts = this.products.filter((item: any) => {
				const itemValues = [
					item.productName
				];

				const validItemValues = itemValues
					.filter((value) => value !== null && value !== undefined)
					.map((value) => value.toLowerCase());


				const searchTerms = searchTermLowerCase.split(' ');

				return validItemValues.some((value: string) => {
					return searchTerms.every((term: string) => {
						const termRegex = new RegExp(term);
						return termRegex.test(value);
					});
				});
			});
		} 

		if (option == 'brand'){
			this.filteredBrands = this.brands.filter((item: any) => {
				const itemValues = [
					item.brand
				];

				const validItemValues = itemValues
					.filter((value) => value !== null && value !== undefined)
					.map((value) => value.toLowerCase());


				const searchTerms = searchTermLowerCase.split(' ');

				return validItemValues.some((value: string) => {
					return searchTerms.every((term: string) => {
						const termRegex = new RegExp(term);
						return termRegex.test(value);
					});
				});
			});
		} 

		if (option == 'manufacturer'){
			this.filteredManufacturer = this.companies.filter((item: any) => {
				const itemValues = [
					item.manufacture
				];

				const validItemValues = itemValues
					.filter((value) => value !== null && value !== undefined)
					.map((value) => value.toLowerCase());
				
				const searchTerms = searchTermLowerCase.split(' ');

				return validItemValues.some((value: string) => {
					return searchTerms.every((term: string) => {
						const termRegex = new RegExp(term);
						return termRegex.test(value);
					});
				});
		});
		} 

		if (option == 'type'){
			this.filteredTypes = this.types.filter((item: any) => {
				const itemValues = [
					item.productType
				];

				const validItemValues = itemValues
					.filter((value) => value !== null && value !== undefined)
					.map((value) => value.toLowerCase());


				const searchTerms = searchTermLowerCase.split(' ');

				return validItemValues.some((value: string) => {
					return searchTerms.every((term: string) => {
						const termRegex = new RegExp(term);
						return termRegex.test(value);
					});
				});
			});
		} 
	} else {
		this.filteredProducts = []
		this.filteredBrands = []
		this.filteredManufacturer = []
		this.filteredTypes = []
	}
}

preventEnter(event: KeyboardEvent) {
	event.preventDefault(); 
}

}
