<div class="modal-body modal-sale-report">

  <div class="modal-close-button">
    <app-closebutton *ngIf="showClose"></app-closebutton>
  </div>

  <div class="d-flex flex-column">
    <div class="d-flex align-items-center" *ngIf="showReport.length > 0">
      <span class="mr-1">Send by Email as </span>
      
      <button
        class="btn btn-3 mr-2"
        (click)="showSendEmail('xlsx')"
      >
        Excel
      </button>
      <button
        class="btn btn-3 mr-2"
        (click)="showSendEmail('pdf')"
      >
        PDF
      </button>
      <button
      *ngIf="isAndroid || isIOS"
      class="btn btn-3"
      (click)="showPrint()"
    >
      <span class="material-icons">
        print
      </span> Print
    </button>
    </div>

    <div class="col-md-12 mt-10 text-center" *ngIf="!show">
      <i class="fa fa-refresh fa-spin fa-3x"></i>
      <h6>Loading Data....</h6>

      <!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" style="margin: auto; display: block; shape-rendering: auto;" width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
        <circle cx="50" cy="50" fill="none" stroke="#192f56" stroke-width="10" r="35" stroke-dasharray="164.93361431346415 56.97787143782138" transform="rotate(311.217 50 50)">
          <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
        </circle> -->
      <!-- </svg>         -->
    </div>

    <div class="col-md-12 mt-10 text-center" *ngIf="show">
      <h6 *ngIf="errorLoading">{{ errorLoading }}</h6>
    </div>

    <div class="col-md-12" id="viewerContent"></div>
  </div>
</div>
