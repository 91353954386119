import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { PaginatedResult } from '../_modules/pagination';
import { Customer } from '../_modules/customers';
import { map } from 'rxjs/operators/map';
import { AlertifyService } from './alertify.service';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {
baseUrl = environment.apiUrl;
laravel = environment.apiLaravel;
editPurchaseOrder = new BehaviorSubject<string>('');
triggerSavePurchaseOrder = new BehaviorSubject<boolean>(false);
private changedHeader = new BehaviorSubject<any>('');
currentHeder = this.changedHeader.asObservable();

constructor(private http: HttpClient) { }


addPurchaseOrder(purchaseOrder: any) {
  return this.http.post(this.laravel + 'purchaseorder', purchaseOrder);
}

updatePurchaseOrder(id: number, purchaseOrder) {

  return this.http.patch(this.laravel + 'purchaseorder/' + id, purchaseOrder);
}

updateEventWhenPoCreated(values:any) {
  return this.http.post(this.laravel + 'purchaseorder/updateEvent',values );
}

uploadPurchaseOrder(purchaseOrder: any) {
  return this.http.post('https://dealer.cfsbeds.com/aedpay_to_cfsbeds/createPOAedPay.php', purchaseOrder);
  //return this.http.post(this.laravel + 'uploadpurchaseorder', purchaseOrder);
}

uploadPurchaseOrderConfirm(purchaseOrder: any, poid) {
  let sendPO = {
    pO: purchaseOrder,
    id: poid,
    stringPO: btoa(unescape(encodeURIComponent(JSON.stringify(purchaseOrder))))
    // stringPO: btoa(JSON.stringify(purchaseOrder))
  }
  
  return this.http.post(this.laravel + 'uploadPurchaseOrder', sendPO);
}

searchAdvancePOdetails(poid:any){
  return this.http.get(this.laravel + 'searchAdvancePOdetails/' + poid);
}

createPurchaseOrder(ROID: any, EID: any, DB: any) {

  let params = new HttpParams();
  params = params.append('ROID', ROID);
  params = params.append('EID', EID);
  params = params.append('DB', DB);

  //Modified for excluding SalesOrder from creating PPOs
  //return this.http.post('https://aedsoft.com/mycfspos/soconvpoexcludesan.php', params);//changed this to new url --soconvpoexclude.php
  // return this.http.post('https://posapitest.aedpay.com/public/soconvpoexcludeUpdated.php', params);
   
//Production
// return this.http.post('https://cfs2015.aedpay.com/soconvpoexcludeUpdated.php', params);

// Production on controller 
return this.http.post(this.baseUrl + 'soconvpoexcludeUpdatedNew', params);

//Staging 
// return this.http.post('https://cfs2015staging.aedpay.com/soconvpoexcludeUpdated.php', params);
 //return this.http.post(this.laravel +'ConvertSOToPO', params);

//local development
//return this.http.post('http://localhost/soconvpoexcludeUpdated.php', params);
  
}

getPOSummary(eventId:any) {
  let sendPO = {
    eventId: eventId
  }
  return this.http.post(this.laravel + 'getposummary', sendPO);
  
}

createPurchaseOrderMattressFirm(ROID: any, EID: any, DB: any) {

  let params = new HttpParams();
  params = params.append('ROID', ROID);
  params = params.append('EID', EID);
  params = params.append('DB', DB);

  return this.http.post('https://aedsoft.com/mycfspos/soconvpomattressfirm.php', params);

}




deletePurchaseOrder(id: number) {
  return this.http.delete(this.laravel + 'purchaseorder/' + id);
}


getPurchaseOrders(page?, itemsPerPage?,  search?, searchEvent?, order?, orderDir?, dateFilter?, statusFilter?) {
  let params = new HttpParams();
  const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();
  if ( page != null && itemsPerPage != null) {
    params = params.append('page', page);
    params = params.append('per_page', itemsPerPage);
  }

  if (search != null) {
    params = params.append('search', search);
  }
  if (order != null) {
    params = params.append('order', order);
  }
  if (orderDir != null) {
    params = params.append('orderDir', orderDir);
  }

  if (dateFilter != null) {
    params = params.append('dateFilter', dateFilter);
  }

  if (statusFilter != null) {
    params = params.append('statusFilter', statusFilter);
  }

  if (searchEvent != null) {
    params = params.append('searchEvent', searchEvent);
  }

  return this.http.get<any>(this.laravel + 'purchaseorder', {observe: 'response', params})
  .pipe(
    map( response => {
      paginatedResult.result = (response.body.data);
      if (response.body.total != null) {
        paginatedResult.pagination = {
          totalItems: JSON.parse(response.body.total),
          totalPages: JSON.parse(response.body.last_page),
          currentPage: JSON.parse(response.body.current_page),
          itemsPerPage: JSON.parse(response.body.per_page)
        };
      }
      return paginatedResult;
    })
  );
  }



  getPurchaseOrdersIncluded(id) {
    return this.http.get<any>(this.laravel + 'purchaseIncludedExcluded/' + id);
  }




  getPurchaseOrder(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'purchaseorder/' + id);
  }  
  
  getPurchaseOrderShow2(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'purchaseordershow2/' + id);
  }

  getPurchaseOrderEdit(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'purchaseorderEdit/' + id);
  }
  updatePurchaseOrderItems(purchaseOrder:any) {
    return this.http.post(this.laravel + 'purchaseorderItems', purchaseOrder);
  }


}
