<div class="storeproduct-desk" [ngClass]="salesOrderMode === true ? 'modal-body' : ''">
  

    <div *ngIf="salesOrderMode === true" class="modal-close-button mt-3">
      <!-- <app-closebutton></app-closebutton> -->
    </div>
  
    <nav class="main-navbar" *ngIf="!salesOrderMode">
      <!-- <div class="main-navbar-info-page">
        <span class="mr-1" >Store Catalog:</span>
        <strong class="text-dark">{{ totalitems }}</strong>
      </div> -->
      <div class="main-navbar-content-page">
  
        <button class="btn btn-3 d-none" (click)="updateEntireStoreCatalog()">
          Update Entire Store Catalog
        </button>
        <button class="btn btn-3" (click)="loadStoreProductsForExcel()">
          Export to Excel
        </button>
        <button class="btn btn-3" (click)="refreshItems()">Refresh Items</button>
        <button class="btn btn-3" (click)="deleteStoreProduct('all')">
          <span class="material-icons">
            delete
            </span> Delete Found Items
        </button>
  
      </div>
    </nav>
  
  
    <div class="nav-display-options">
      <div *ngIf="!Changefilter"  class="nav-display-options-filter justify-content-between">
              <!-- primer linea -->
              <div class="nav-display-options-filter-buttons-view" >
                <div class="nav-display-options-filter-buttons-view-text">
                  <p>Filter by:</p>
                </div>
                <div class="nav-display-options-filter-buttons-view-buttons">
                 
                  <div class="form-container customer mr-2">
                    <select [(ngModel)]="selectedManMod" class="form-control" (ngModelChange)="loadSelectFilters($event,'MFGID')">
                      <option disabled value="" selected>Manufacturer</option>
                      <option *ngFor="let com of companies" [ngValue]="com?.MFGID">{{com?.companyName}}</option>             
                    </select>
                  </div>
        
                  <div class="form-container customer mr-2">
                    <select [(ngModel)]="selectedStyleMod" name="style" class="form-control" (ngModelChange)="loadSelectFilters($event,'styleId')" >
                      <option disabled value="" selected>Style</option>
                      <option [ngValue]="sty?.styleId" *ngFor="let sty of styles">{{sty?.styleName}}</option>
                    </select>
                  </div>
        
                  <div class="form-container customer mr-2">
                    <select [(ngModel)]="selectedSizeMod" class="form-control" (ngModelChange)="loadSelectFilters($event,'sizeName')">
                      <option disabled value="" selected>Size</option>
                      <option *ngFor="let size of sizes" [ngValue]="size?.sizeName">{{size?.sizeName}}</option>
                    </select>
                  </div>
        
                  <div class="form-container customer mr-2">
                    <select [(ngModel)]="selectedProductMod" name="product" class="form-control"  id="sizeselect" (ngModelChange)="loadSelectFilters($event,'product')" >
                      <option disabled value="" selected>Product</option>
                      <option [ngValue]="product?.product" *ngFor="let product of products" >{{ product?.product }}</option>
                    </select>
                  </div>
                  <!-- <To do , Preguntar para que son los botones> -->
                  <div class="mr-2 d-flex">
  
                    <button
                    class="btn btn-3"
                    (click)="clearFilter()"
                    *ngIf="!salesOrderMode && (selectedMan != '' || selectedStyle != '' || selectedSize != '' || selectedProduct != '')"
                  >
                    Show All
                  </button>
  
                    <button
                      class="btn btn-3"
                      (click)="clearFilter()"
                      *ngIf="salesOrderMode"
                    >
                      Show All
                    </button>
                    <!-- Uncomment this to active Price Updater -->
                    <!-- <button *ngIf="!salesOrderMode"   class="btn btn-3" (click)="ViewSalesPriceUpdate()">
                      <span *ngIf="!viewSalesPriceUpdate" class="material-icons"> visibility</span>
                      <span *ngIf="viewSalesPriceUpdate" class="material-icons"> visibility_off</span>              
                      Sales Price Update
                    </button> -->
  
                    <button class="btn btn-3" *ngIf="salesOrderMode" (click)="Changefilter = !Changefilter; clearFilter()">
                      Change Filter
                      </button>
                 
                    <button *ngIf="showSave" class="btn btn-3" style="margin-left: auto;" (click)="onSalesPriceChange('', '', true,false)">Save Prices</button>
        
                  </div>
                  <!-- <To do , Preguntar para que son los botones> -->
                  <div>
                    <span *ngIf="salesOrderMode">Items: {{ totalitems }}</span>
                  </div>
                </div>
                
              
        
              </div>
              
              <!-- segunda linea -->
              <div *ngIf="viewSalesPriceUpdate" class="nav-display-options-filter-buttons-view-buttons" style="margin-top: 1rem;">
        
                <button *ngIf="viewSalesPriceUpdate" class="btn btn-3 mr-2 " (click)="SalesPriceUpdate_0()">           
                  Zero out sales prices
                </button>
                <div btnRadioGroup  [formControl]="radioUpdateBy"  class="sale-order-detail-content-set-for mr-2"  role="tablist"  aria-orientation="vertical">
                    <p class="m-0 sale-order-detail-content-set-for-text" style="width: max-content;">Update by:</p>
                      <div  class="button-container">
                        <div
                          class="button-pickup"
                          data-toggle="pill"
                          role="tab"
                          aria-controls="v-pills-profile"
                          aria-selected="false"
                          btnRadio="dollar"
                          (click)="toogleView('dollar')"
                        >
                        $
                        </div>
                        <div
                        style="padding: 0.35em 0.7em;"
                          class="button-delivery"
                          data-toggle="pill"
                          role="tab"
                          aria-controls="v-pills-home"
                          aria-selected="true"
                          btnRadio="percent"
                          (click)="toogleView('percent')"
                        >
                        %
                        </div>
                      </div>    
                </div>
        
                <div btnRadioGroup  [formControl]="radioDecimal"  class="sale-order-detail-content-set-for mr-2"  role="tablist"  aria-orientation="vertical">
                  <p class="m-0 sale-order-detail-content-set-for-text" style="width: max-content;">Decimal:</p>
                    <div  class="button-container">
                      <div
                        class="button-pickup"
                        data-toggle="pill"
                        role="tab"
                        aria-controls="v-pills-profile"
                        aria-selected="false"
                        btnRadio="Yes"
                        (click)="toogleDecimal('Yes')"
                      >
                      Yes
                      </div>
                      <div
                      style="padding: 0.35em 0.7em;"
                        class="button-delivery"
                        data-toggle="pill"
                        role="tab"
                        aria-controls="v-pills-home"
                        aria-selected="true"
                        btnRadio="No"
                        (click)="toogleDecimal('No')"
                      >
                      No
                      </div>
                    </div>    
              </div>
        
              <div btnRadioGroup  [formControl]="radioOverwrite"  class="sale-order-detail-content-set-for mr-2"  role="tablist"  aria-orientation="vertical">
                <p class="m-0 sale-order-detail-content-set-for-text" style="width: max-content;">Overwrite:</p>
                  <div  class="button-container">
                    <div
                      class="button-pickup"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="v-pills-profile"
                      aria-selected="false"
                      btnRadio="OnlyCero"
                    >
                    Only 0            
                    </div>
                    <div
                    style="padding: 0.35em 0.7em;"
                      class="button-delivery"
                      data-toggle="pill"
                      role="tab"
                      aria-controls="v-pills-home"
                      aria-selected="true"
                      btnRadio="AllItems"
                    >
                    All
                    </div>
                  </div>    
            </div>
        
        
        
              <div class="input-group" style="margin-left: auto;" >
                <div *ngIf="SPTypeView =='percent'" style="display: flex;">
                  <span style="margin-right: -3px;" class="input-group-text">%</span>
                  <input style="height: auto;width: 5rem;"  [formControl]="numPorcent" class="form-control "  type="number"/>
                  <button class="btn btn-3 mr-2" (click)="openModalConfirm(modalConfirm,'percent')">Apply</button>
                </div>
                
                <div *ngIf="SPTypeView =='dollar'" style="display: flex;">   
                  <span style="margin-right: -3px;" class="input-group-text">$</span>                         
                  <input style="height: auto;width: 5rem;"  [formControl]="numPriceHeigth" class="form-control mr-2"  type="number"/>
                  <button class="btn btn-3 mr-2" (click)="openModalConfirm(modalConfirm,'dollar')">Apply</button> 
                </div>
              <!-- <button *ngIf="showSave && lastItem?.wholeSalePriceNum == 0 && SPTypeView == 'percent'" style="margin-left: auto;" class="btn btn-3" (click)="onSalesPriceChange('', '', true,false)">Save Prices</button> -->
              </div>
        
              </div>
        
              <!-- tercer linea -->
              <div *ngIf="lastItem?.wholeSalePriceNum > 0 && SPTypeView == 'dollar' && viewSalesPriceUpdate" class="nav-display-options-filter-buttons-view-buttons" style="margin-top: 1rem;">
                <div class="nav-display-options-type-view-text" >Price to: {{lastItem?.product}} | {{lastItem?.sizeName}}, WS: ${{lastItem?.wholeSalePriceNum}}</div>
                <!-- <button *ngIf="showSave && lastItem?.wholeSalePriceNum > 0" style="margin-left: auto;" class="btn btn-3" (click)="onSalesPriceChange('', '', true,false)">Save Prices</button> -->
              </div>
  
      </div>
  
  
  
  
  
  
  
      <div *ngIf="Changefilter" [ngClass]="{ active: filter }"> 
  
        <div class="selecter-filter-container-title">
          <label class="pointer"  [ngClass]="{ 'active': tabFilterNav }">Search</label>
        </div>
  
      
        <div class="row" >
          <div class="col-6 d-flex flex-row mb-2 justify-content-between">
              <div class="col-4 col-md-2 pl-0">
                <label for="Manufacture" class="mb-0 mr-2">Manufacturer</label>
              </div>
              <input class="form-control col-8 " type="text" [(ngModel)]="filterManu">
          </div>
          <div class="col-6 d-flex flex-row justify-content-between">
              <div class="col-4 col-md-2 pl-0">
                <label for="Manufacture" class="mb-0 mr-2">Style</label>
              </div>
              <input class="form-control col-8" type="text" [(ngModel)]="filterStyle">
          </div>
          <div class="col-6 d-flex flex-row justify-content-between ">
              <div class="col-4 col-md-2 pl-0"><label for="Manufacture" class="mb-0 mr-2">Size</label></div>
              <input class="form-control col-8" type="text" [(ngModel)]="filterSize">
             
          </div>
          <div class="col-6 d-flex flex-row justify-content-between">
              <div class="col-4 col-md-2 pl-0">
                <label for="Manufacture" class="mb-0 mr-2">Product</label>
              </div>
              <input class="form-control col-8" type="text" [(ngModel)]="filterProduct">
  
          </div>
          <div class="col-12 d-flex align-items-center">
            <div class="form-group mt-3 mb-3 d-flex">
              <button (click)="searchInputApply(); this.filter = false; megaSearch();" class="btn btn-3 mr-1">Search</button>
              <button (click)="clearsearchInputApply();this.searchedInput = false" class="btn btn-3 mr-1">Clear</button>
              <button (click)="clearsearchInputApply();this.filter = false;this.searchedInput = false" class="btn btn-3 mr-1">Show All</button>
              <button class="btn btn-3" (click)="Changefilter = !Changefilter; clearFilter()">
                Change Filter
                </button>
            </div>
          </div>
        </div>
      </div>
  
  
  
  
  
  
  
  
  
  
  
  
  
    </div>
  
  
  
  
  
  
  
  
  
    <div *ngIf="(selectedMan != '' || filterManu != '' || filterStyle != '' || filterSize != '' || filterProduct != '' || selectedStyle != '' || selectedSize != '' || selectedProduct != '') || !salesOrderMode" class="customer-content">
      <table class="table-responsive" >
        <table id="salestable" class="table table-light table-custom overflow-auto" cellspacing="0">
          <thead class="table-custom-header">
            <tr>
              <!-- Uncomment bellow to active row and show QR -->
              <!-- <th *ngIf="!salesOrderMode"></th> -->
              <th>manufacturer</th>
              <th>Style</th>
              <th>Size</th>
              <th>Product</th>
              <th>Sales Price</th>
              <!-- <th class="mx-auto"> <span *ngIf="!salesOrderMode">Add to Stock</span></th>             -->
              <th></th>
  
            </tr>
          </thead>
          <tbody *ngIf="!salesOrderMode">
            <tr class="m-1 p-1" *ngFor="let sp of recordProducts
            | paginate: { itemsPerPage: 10, currentPage: p } ; trackBy:trackBy
            ">
              <!-- Uncomment bellow to active row and show QR -->
            <!-- <td>
              <button *ngIf="!salesOrderMode" class="btn btn-delete" (click)="addToExpQRC(sp)">
              <span class="material-icons">qr_code</span>
            </button>
          </td> -->
              <td>{{ sp.companyName }}</td>
              <td>{{ sp.styleName }} <br /> SKU: {{ sp.SKU }}</td>
              <td>{{ sp.sizeName }} <br /> Type: {{ sp.Type }}</td>
              <td>
                {{ sp.product }} <br />
                <span *ngIf="isDealer"> WholeSale: {{ sp.wholeSalePriceNum }}</span>
                <br *ngIf="isDealer" />
                <span *ngIf="sp.minQtyNum > 1">Qty per set: {{ sp.minQtyNum }}</span>
              </td>
              <td>
                <input
                  *ngIf="!salesOrderMode"
                  class="form-control form-control-sm text-right"
                  type="number"
                  name=""
                  (blur)="onSalesPriceChange(sp.productsId, sp, false)"
                  required
                  [(ngModel)]="sp.salesPriceNum"
                />
                <div *ngIf="salesOrderMode">{{ sp.salesPriceNum }}</div>
              </td>
  
              <td>
                <div class="pl-2">
                  <button
                    *ngIf="!salesOrderMode"
                    class="btn btn-delete"
                    (click)="deleteStoreProduct(sp.productsId)">
                    <span class="material-icons"> delete </span>
                  </button>
                  <button
                    *ngIf="salesOrderMode"
                    class="btn btn-add"
                    (click)="addSoItem(sp)"
                  >
                    <span class="material-icons">
                      add
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="salesOrderMode">
            <tr class="m-1 p-1" *ngFor="let sp of recordProducts | paginate: { itemsPerPage: 10, currentPage: p } ; trackBy:trackBy">
              <td>{{ sp.companyName }}</td>
              <td>{{ sp.styleName }} <br /> SKU: {{ sp.SKU }}</td>
              <td>{{ sp.sizeName }} <br /> Type: {{ sp.Type }}</td>
              <td>
                {{ sp.product }} <br />
                <span *ngIf="isDealer"> WholeSale: {{ sp.wholeSalePriceNum }}</span>
                <br *ngIf="isDealer" />
                <span *ngIf="sp.minQtyNum > 1">Qty per set: {{ sp.minQtyNum }}</span>
              </td>
              <td>
                <input
                  *ngIf="!salesOrderMode"
                  class="form-control form-control-sm text-right"
                  type="number"
                  name=""
                  (blur)="onSalesPriceChange(sp.productsId, sp, false)"
                  required
                  [(ngModel)]="sp.salesPriceNum"
                />
                <div *ngIf="salesOrderMode">{{ sp.salesPriceNum }}</div>
              </td>
              <td>
                <div>
                  <ng-template #template>
                    <app-addstockitem
                      [sp]="sp"
                      (qtyStockAdded)="qtyStockAdded($event)"
                    ></app-addstockitem>
                  </ng-template>
     
                </div>
                <div class="pl-2">
                  <button
                    *ngIf="!salesOrderMode"
                    class="btn btn-delete"
                    (click)="deleteStoreProduct(sp.productsId)">
                    <span class="material-icons"> delete </span>
                  </button>
                  <button
                    *ngIf="salesOrderMode"
                    class="btn btn-add"
                    (click)="addSoItem(sp)"
                  >
                    <span class="material-icons">
                      add
                    </span>
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </table>
    </div>
   
    








    <div class="pagination-container" >
      <pagination-controls  (pageChange)="p = $event" maxSize="10" previousLabel="" nextLabel="" autoHide="true" ></pagination-controls>
    </div>
    
    <div class="font-weight-bold pt-20 mx-auto text-center" *ngIf="(selectedMan == '' && selectedStyle == '' && selectedSize == '' && selectedProduct == '') && salesOrderMode">
      <h4 style="color: gray;">Filter to show the products</h4>   
    </div>
  </div>
  
  
  
  
  











  
  
  <div class="storeproduct-mobile"  [ngClass]="salesOrderMode === true ? 'modal-body modal-body-white' : ''">
    
    <!--  nav -->
    <div style="background-color: white;" class="" [ngClass]="{ 'no-fixed': salesOrderMode }" >
      <div class="w-100 d-flex justify-content-between align-items-center">
        <div style="display: flex;">
          <div class="btn btn-3 mr-2 mb-2" *ngIf="showSave" (click)="onSalesPriceChange('', '', true)">Save</div>
        </div>
      </div>
  
      <!-- <div class="selecter-filter-container" [ngClass]="{ 'active': searched }"> 
        <div class="selecter-filter-container-title store-filter-container row pr-0 pl-0" [ngClass]="{'fullheight': filters > 2 }">
          <div class="col-12 col-md-12" *ngIf="oneSearch && searched">

            <div class="selecter-filter-container-title p-0">
               <div class="d-flex align-items-center">
                <h6 class="selecter-filter-container-title-select-header">Searched:</h6>
                <span class="tw-ml-1" *ngFor="let item of options.manufaturer" (click)="filterBy('manufacturer', item.manufacturer)" [ngClass]="{ 'btn btn-2' : options.manufaturer.length > 1 }"> {{ item.manufacturer }}  </span>
                <span class="tw-ml-1" *ngFor="let item of options.size" (click)="filterBy('sizeName', item.size)" [ngClass]="{ 'btn btn-2' : options.size.length > 1 }"> {{ item.size }} </span>
                <span class="tw-ml-2" *ngFor="let item of options.style" (click)="filterBy('styleName', item.style)" [ngClass]="{ 'btn btn-2' : options.style.length > 1 }"> {{ item.style }} </span>
                <span class="tw-ml-2" *ngFor="let item of options.type" (click)="filterBy('Type', item.type)" [ngClass]="{ 'btn btn-2' : options.type.length > 1 }"> {{ item.type }} </span>
              </div>            
            </div>

          </div>

          <div class="col-12 col-md-6" *ngIf="oneSearch && searched">
            <div class="selecter-filter-container-title p-0">

            </div>
          </div> 
        </div>
      </div>  -->

   
  
    
  
      <div style="background-color: white !important;" class="" [ngClass]="{ active: filter }"> 
     
        <!-- toggle -->
        <div class="tw-w-full tw-px-4">
          <div class="tw-text-sm sm:tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-sans tw-text-gray-400 tw-pb-2 tw-font-medium">
            Store Catalog: {{numproducts}}</div>
    
          <div class="tw-flex" style="display: flex;">            
           
            <div  btnRadioGroup  [formControl]="radioUpdateBy"
            class="tw-toggle" >  
              <div 
              btnRadio="percent"
              (click)="openTabFilter()"
              class="tw-rounded-l-lg"
              [ngClass]="radioUpdateBy.value == 'dollar' ? 'tw-toggle-inactive' : 'tw-toggle-active'">
                Filter
              </div> 
              <div 
              btnRadio="dollar"
              (click)="openTabFilter()"
              class="tw-rounded-r-lg"
              [ngClass]="radioUpdateBy.value == 'percent' ? 'tw-toggle-inactive' : 'tw-toggle-active'">
              Search
              </div>            
            </div> 
            <button *ngIf="!tabFilterNav" class="btn-blue-so tw-ml-2" (click)="initVarsSelect()">Clear</button>      
            <!-- <span class="material-icons ml-3 tw-text-aedblue" style="font-size:1.8rem">
              search
            </span>   -->
            <span class="material-icons ml-3 tw-text-aedblue" (click)="refreshItems()" style="font-size:1.8rem">
              autorenew
            </span>  
          </div>
        </div>
        
        <!-- filter  -->
        <div  *ngIf="!tabFilterNav">

          <table class="tw-mx-4 tw-my-2">
            <tbody>
              <tr>
                <td class="tw-pr-2 tw-hidden sm:tw-hidden md:tw-block lg:tw-block">Manufacturer</td>
                <td>
                  <select [(ngModel)]="selectedManMod" class="input-header-white" (ngModelChange)="loadSelectFilters($event,'MFGID')">
                    <option disabled value="" selected>Choose manufacturer</option>
                    <option *ngFor="let com of companies" [ngValue]="com?.MFGID">{{ com?.companyName }}</option>
                  </select> 
                </td>
              </tr>

              <tr>
                <td class="tw-pr-2 tw-hidden sm:tw-hidden md:tw-block lg:tw-block">Style</td>
                <td>
                  <select [(ngModel)]="selectedStyleMod" class="input-header-white" (ngModelChange)="loadSelectFilters($event,'styleId')">
                    <option disabled value="" selected>Choose Style</option>
                    <option *ngFor="let sty of styles" [ngValue]="sty?.styleId">{{ sty?.styleName }}</option>
                  </select>
                </td>
              </tr>

              <tr>
                <td class="tw-pr-2 tw-hidden sm:tw-hidden md:tw-block lg:tw-block">Size</td>
                <td>
                  <select [(ngModel)]="selectedSizeMod" class="input-header-white" (ngModelChange)="loadSelectFilters($event,'sizeName')">
                    <option disabled value="" selected>Choose Size</option>
                    <option *ngFor="let size of sizes" [ngValue]="size?.sizeName">{{ size?.sizeName }}</option>
                  </select>
                </td>
              </tr>
              
              <tr>
                <td class="tw-pr-2 tw-hidden sm:tw-hidden md:tw-block lg:tw-block">Product</td>
                <td>
                  <select [(ngModel)]="selectedProductMod" (ngModelChange)="loadSelectFilters($event,'product')" name="product" class="input-header-white" id="sizeselect">
                    <option disabled value="" selected>Choose Product</option>
                    <option [ngValue]="product?.product" *ngFor="let product of products">{{ product?.product }}</option>
                 </select>  
                </td>
              </tr>
            </tbody>
          </table>
        
        </div>

        <!-- search  -->
        <div class="" *ngIf="tabFilterNav">
          <div class="tw-grid tw-grid-cols-1 sm:tw-grid sm:tw-grid-cols-1 md:tw-flex lg:tw-flex tw-py-2 tw-px-4">
            <div class="tw-flex">
              <div class="pl-0 mr-2 tw-flex tw-font-medium tw-text-sm sm:tw-text-base md:tw-text-lg lg:tw-text-xl">
               <div class="tw-my-auto">Search</div> 
              </div>
              <input class="input-header-white tw-mr-0 sm:tw-mr-0 md:tw-mr-2 lg:tw-mr-2" type="text" [(ngModel)]="oneSearch" [ngbTypeahead]="search">
            </div>
           
            <div class="tw-flex tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0">
              <button (click)="this.filter = false; megaSearch();" class="btn-blue-so">Search</button>
              <button class="tw-ml-auto sm:tw-ml-auto md:tw-ml-4 lg:tw-ml-4" *ngIf="searched" (click)="searchAgain()">
                <span class="material-icons">search_off</span>
              </button>              
            </div>

          </div>
        </div>

      </div>

    </div>
    <!-- fin nav -->
  
    
    
  
    <div class="acordion-dropdown-container store-scroll-top" [ngClass]="{ 'scroll-top-store-catalog': searched || searchedInput }" *ngIf="!salesOrderMode">
      <!-- <div class="acordion-dropdown-container-header">
        <div class="acordion-dropdown-container-header-title">
          <p>Store Catalog</p>
        </div>
        <div class="acordion-dropdown-container-header-icons">
          <app-collapse-control [isCollapsed]="isCollapseDec1" (open)="onOpenDic1($event)"></app-collapse-control>
        </div>
      </div> -->
      <div class="acordion-dropdown-container-items" [ngClass]="{ active: isCollapseDec1 === true, '': isCollapseDec1 }">
        
        <div class="customer-content">
          <div class="card-customer-mobile-record border"  *ngFor="let sp of recordProducts | paginate: { itemsPerPage: 10, currentPage: p }; trackBy:trackBy">
            <div class="card-customer-mobile-record-base-info">
              <div class="row">
                <!-- Manufacture -->
                <div class="col-12">
                  <p class="m-0"><strong>{{ sp.companyName }}</strong></p>
                </div>
                <!-- Style -->
                <div class="col-12">
                  <p class="m-0 large-text-card">{{ sp.styleName }}</p>
                </div>
                <!-- Size -->
                <div class="col-6">
                  <p class="m-0">{{ sp.sizeName }}</p>
                </div>
                <!-- Type -->
                <div class="col-6">
                  <p class="m-0">Type: {{ sp.Type }}</p>
                </div>
                <!-- Sale Price -->
                <div class="col-6">
                  <span style="width: 80px;">Sale Price</span>
                  <input
                      *ngIf="!salesOrderMode"
                      class="form-control form-control-sm text-right"
                      type="number"
                      name=""
                      (blur)="onSalesPriceChange(sp.productsId, sp, false)"
                      required
                      style="max-width: 100% !important;"
                      [(ngModel)]="sp.salesPriceNum"
                    />
                  <div *ngIf="salesOrderMode">{{ sp.salesPriceNum | currency }}</div>
      
                </div>
                <!-- WholeSale -->
                <div class="col-6" *ngIf="isDealer">
                  <p class="m-0">WholeSale: {{sp.wholeSalePriceNum}}</p>
                </div>
                <!-- Buttons -->
                <div class="col-12">
                  <div class="  w-100">
                    <div>
                      <ng-template #template>
                        <app-addstockitem
                          [sp]="sp"
                          (qtyStockAdded)="qtyStockAdded($event)"
                        ></app-addstockitem>
                      </ng-template>
                      <!-- <button
                        *ngIf="!salesOrderMode"
                        class="btn btn-add mr-2"
                        (click)="addStockItems(sp.id, sp, template)"
                      >
                        <span class="material-icons"> add </span>
                      </button> -->
                    </div>
                    <div class="" style="display:flex">
                      <!-- Uncomment this to active qr mobile -->
                      <!-- <button *ngIf="!salesOrderMode" class="btn btn-delete" (click)="addToExpQRC(sp)">
                        <span class="material-icons">qr_code</span>
                      </button> -->
                      <button style="margin-left: auto;"
                        *ngIf="!salesOrderMode"
                        class="btn btn-delete mr-3"
                        (click)="deleteStoreProduct(sp.productsId)">
                        <span class="material-icons"> delete </span>
                      </button>
                      <button
                        *ngIf="salesOrderMode"
                        class="btn btn-add"
                        (click)="addSoItem(sp)"
                      > 
                        <span class="material-icons">
                          add
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pagination-container mb-2" *ngIf="!salesOrderMode">
          <pagination-controls  (pageChange)="p = $event" maxSize="5" previousLabel="" nextLabel="" autoHide="true" ></pagination-controls>
        </div>
      </div>
    </div>
  
  
  
  
  
                      <!-- Uncomment this to active qr mobile table -->
  
  <!-- 
    <div *ngIf="recordProductsToQRC.length > 0 && !salesOrderMode" class="acordion-dropdown-container store-scroll-top mt-2" [ngClass]="{ 'scroll-top-store-catalog': searched || searchedInput }" >
      <div class="acordion-dropdown-container-header">
        <div class="acordion-dropdown-container-header-title">    
          <p>Products to export QRC </p> 
        </div>
       
        <div class="acordion-dropdown-container-header-icons">
          <app-collapse-control [isCollapsed]="isCollapseDec3" (open)="onOpenDic3($event)"></app-collapse-control>
        </div>
      </div>
      <div class="acordion-dropdown-container-items" [ngClass]="{ active: isCollapseDec3 === true, '': isCollapseDec3 }">
       
    <div class="customer-content" >
  
      <div style="display:flex;"  class="py-2">  
        <button class="btn btn-3 ml-auto"  (click)="clearToExpQRC()">Clear</button>
        <button class="btn btn-3 "  (click)="ExportToQRPDF()">Export</button>
        <button class="btn btn-3 " *ngIf="toSaveQR" (click)="saveToExpQRC()">Save</button>
      </div>
  
      <div class="card-customer-mobile-record border"  *ngFor="let sp of recordProductsToQRC">
        <div class="card-customer-mobile-record-base-info">
          <div class="row">
            <div class="col-12">
              <p class="m-0"><strong>{{ sp.companyName }}</strong></p>
            </div>
            <div class="col-12">
              <p class="m-0 large-text-card">{{ sp.styleName }}</p>
            </div>
            <div class="col-6">
              <p class="m-0">{{ sp.sizeName }}</p>
            </div>
            <div class="col-6">
              <p class="m-0">Type: {{ sp.Type }}</p>
            </div>
            <div class="col-6">
              <span style="width: 80px;">Sale Price</span>    
              <div >{{ sp.salesPriceNum | currency }}</div>
  
            </div>
            <div class="col-6" *ngIf="isDealer">
              <p class="m-0">WholeSale: {{sp.wholeSalePriceNum}}</p>
            </div>
            <div class="col-12">
              <div class="  w-100">               
                <div class="" style="display:flex">                   
                  <button style="margin-left: auto;"
                    class="btn btn-delete mr-3"
                    (click)="deleteToExpQRC(sp.productsId)">
                    <span class="material-icons"> delete </span>
                  </button>                
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </div> -->
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
    <!-- <div class="acordion-dropdown-container my-2" *ngIf="!salesOrderMode">
      <div class="acordion-dropdown-container-header">
        <div class="acordion-dropdown-container-header-title">
          <p>DDS ORDER FORMS</p>
        </div>
        <div class="acordion-dropdown-container-header-icons">
          <app-collapse-control [isCollapsed]="isCollapseDec2" (open)="onOpenDic2($event)"></app-collapse-control>
        </div>
      </div>
  
      <div class="acordion-dropdown-container-items" [ngClass]="{ active: isCollapseDec2 === true, '': isCollapseDec2 }">
        <app-styleitemsmobile></app-styleitemsmobile>
      </div>
    </div> -->
  
    
    <!-- <div class="font-weight-bold pt-20 mx-auto text-center" *ngIf="(oneSearch == '') && salesOrderMode">
      <h4 style="color: gray;">Filter to show the products</h4>   
    </div> -->
    
    <cdk-virtual-scroll-viewport itemSize="50"  class="customer-content p-3 " style="height: 400px;" *ngIf="salesOrderMode && searched && (oneSearch != '' || selectedMan != '' || filterManu != '' || filterStyle != '' || filterSize != '' || filterProduct != '' || selectedStyle != '' || selectedSize != '' || selectedProduct != '')" >
      <div class="mx-1 tw-mb-2 tw-p-2 tw-rounded-sm border"
       *ngFor="let sp of this.recordProducts | paginate: { itemsPerPage: 10, currentPage: p } ; trackBy:trackBy; 
       let impar = odd" [ngClass]="impar ? 'tw-bg-white' :'tw-bg-aedgrayback'">
       
          <div class="tw-grid tw-grid-cols-12">
            
            <div class="tw-col-span-4">
              <div><strong>{{ sp.companyName }}</strong></div>
              <div>{{ sp.sizeName }}</div>
            </div>  

            <div class="tw-col-span-4">
              <div><strong>{{ sp.styleName }}</strong></div>
              <div>{{ sp.Type }}</div>
            </div> 
            
            <div class="tw-col-span-2 tw-flex">
              <div class="tw-mx-auto">{{ sp.salesPriceNum | currency }}</div>
            </div>

            <div class="tw-col-span-2 tw-flex">   
                <button *ngIf="salesOrderMode"  (click)="addSoItem(sp)"
                class="tw-flex tw-w-10 tw-h-10 tw-rounded-full tw-bg-aedblue tw-ml-auto tw-my-auto"> 
                  <span style="font-size: 1.8rem;color: white;" class="material-icons tw-m-auto">add</span>
                </button>
            </div>


            <!-- <div class="col-8 d-flex flex-column">
              <div class="d-flex containers justify-content-between w-100">
                <div class="m-0 col-md-6 col-6"><strong>{{ sp.companyName }}</strong></div>
                <div class="m-0 col-md-6 col-6">{{ sp.styleName }}</div>
              </div>

              <div class="d-flex containers justify-content-between w-100">
                <p class="m-0 col-md-6 col-6">{{ sp.sizeName }}</p>
                <p class="m-0 col-md-6 col-6">Type: {{ sp.Type }}</p>
              </div>
            </div> -->

            <!-- <div class="col-4">
              <div class="col-12">
                <div class="d-flex containers justify-content-between w-100 align-items-center">
                  <div class="fs-2" *ngIf="salesOrderMode">{{ sp.salesPriceNum | currency }}</div>
                  <div class="pl-3 d-flex justify-content-center align-items-center">
                    <button *ngIf="salesOrderMode" class="btn btn-add-mobile" (click)="addSoItem(sp)" > 
                      <span style="font-size: 1.8rem;" class="material-icons fs-1">add</span>
                    </button>
                  </div> 
                </div>
              </div>            
            </div> -->
           
          </div>
      </div>
    </cdk-virtual-scroll-viewport>
    <div class="tw-mx-1 pagination-container mb-2" *ngIf="salesOrderMode && searched && (oneSearch != '' || selectedMan != '' || filterManu != '' || filterStyle != '' || filterSize != '' || filterProduct != '' || selectedStyle != '' || selectedSize != '' || selectedProduct != '')">
      <pagination-controls  (pageChange)="p = $event" maxSize="5" previousLabel="" nextLabel="" autoHide="true" ></pagination-controls>
    </div>


    <div *ngIf="showTypeOptions && !searched">
        <div class="tw-flex tw-mx-4 ">
          <p class="tw-text-aedgrayl tw-font-sans tw-text-lg tw-font-bold">What type of product you are looking for?</p>
        </div>
        <div class="tw-flex tw-grid-cols-4 tw-mx-4 tw-mb-2">
          <div style="margin-right: 1rem;" (click)="selectAType(type)" class="tw-w-full tw-h-16 tw-flex tw-flex-col tw-justify-center tw-items-center tw-bg-aedblue tw-text-white tw-rounded-lg tw-cursor-pointer" *ngFor="let type of typesToShow">
            <h1  class="tw-text-lg tw-font-bold tw-text-white">
              {{type}}
            </h1>
          </div>
        </div>
    </div>



    <!-- [(ngModel)]="searchBarText"  blurr-->
    <!-- <div class="mt-5" *ngIf="!salesOrderMode">
      <label for="">Manufacture</label>
      <input type="text" [(ngModel)]="filterManu" (input)="searchinput($event.target.value,'companyName')" >
    </div>
    <div class="mt-5" *ngIf="!salesOrderMode">
      <Label>Style</Label>
      <input type="text" [(ngModel)]="filterStyle" (input)="searchinput($event.target.value,'styleName')" >
    </div>
    <div class="mt-5" *ngIf="!salesOrderMode">
      <label for="">Size</label>
      <input type="text" [(ngModel)]="filterSize" (input)="searchinput($event.target.value,'sizeName')" >
    </div>
    <div class="mt-5" *ngIf="!salesOrderMode">
      <label for="">product</label>
      <input type="text" [(ngModel)]="filterProduct" (input)="searchinput($event.target.value,'product')" >
    </div> -->
  
  </div>
  
  
  
  
  
  
  
  
  
              <!-- Uncomment bellow to show qr table -->
  <!-- <div *ngIf=" !salesOrderMode " style="margin: top 2rem ;">
  <div style="display:flex;"  class="py-2">  
  <button class="btn btn-3 " *ngIf="recordProductsToQRC.length > 0" (click)="clearToExpQRC()">Clear</button>
  <button class="btn btn-3 " *ngIf="recordProductsToQRC.length > 0" (click)="ExportToQRPDF()">Generate QRC</button>
  <button class="btn btn-3 " *ngIf="toSaveQR" (click)="saveToExpQRC()">Save</button>
  </div>
  
  <table  class="table table-light table-custom" cellspacing="0" >
    <thead class="table-custom-header">
      <tr>
        <th>manufacturer</th>
        <th>Style</th>
        <th>Size</th>
        <th>Product</th>
        <th>Sales Price</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr class="m-1 p-1" *ngFor="let spQR of recordProductsToQRC">
  
        <td>{{ spQR?.companyName }}</td>
        <td>{{ spQR?.styleName }} <br /> SKU: {{ spQR?.SKU }}</td>
        <td>{{ spQR?.sizeName }} <br /> Type: {{ spQR?.Type }}</td>
        <td>
          {{ spQR.product }} <br />
          <span *ngIf="isDealer"> WholeSale: {{ spQR.wholeSalePriceNum }}</span>
          <br *ngIf="isDealer" />
          <span *ngIf="spQR.minQtyNum > 1">Qty per set: {{ spQR.minQtyNum }}</span>
        </td>
        <td>    
          <div >{{ spQR.salesPriceNum }}</div>
        </td>
  
        <td>
          <div class="pl-2">
            <button
              class="btn btn-delete"
              (click)="deleteToExpQRC(spQR.productsId)">
              <span class="material-icons"> delete </span>
            </button>       
          </div>
        </td>
      </tr>
      <tr class="m-1 p-1" *ngIf="recordProductsToQRC.length == 0">
        No products to Generate QRC 
      </tr>
       
    </tbody>
    </table>
  
  </div> -->
  
  
  
  
  <ng-template #modalConfirm>
    <div class="modal-header">
      <h4 class="modal-title pull-left">Confirm</h4>
      <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="hideModal()">
        <span aria-hidden="true" class="visually-hidden">&times;</span>
      </button>
    </div>
    <div class="modal-body">
    Are you sure to update sales prices in these products?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-default" (click)="hideModal()">Cancel</button>  
      <button *ngIf="actionModal == 'percent'" type="button" class="btn btn-default" (click)="onSalesPriceItemPercent()">ok</button>
      <button *ngIf="actionModal == 'dollar'" type="button" class="btn btn-default" (click)="onSalesPriceItemMajor()">ok</button>
    </div>
  </ng-template>