<div class="modal-body tw-rounded-2xl" style="background-color:#93d8f2;margin: 0; overflow-y: unset !important;">
    <div class="row d-flex justify-content-between tw-pr-4">
      <div class="tw-flex tw-items-center tw-text-white tw-mx-5 tw-py-1">
        <span class="material-icons"> add </span> 
        <p class="tw-text-white tw-text-lg tw-font-bold tw-font-sans">Items</p> 
      </div>
      <span class="material-icons" style="font-size: 30px; color: #fff;" (click)="close()" >close</span>
    </div>
    <div class="tabs">
      
      <div class="tw-grid tw-grid-cols-2">
        <div class="tw-text-center tw-py-2" [ngClass]="{ 'active tw-bg-white': show === 'Store', '': show !== 'Store' }" (click)="showStoreProducts()">
          <div class="tw-text-sm sm:tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-sans  tw-text-gray-500 tw-font-bold">Store Products</div>
        </div>
        <div class="tw-text-center tw-py-2" [ngClass]="{ 'active tw-bg-white': show === 'Stock', '': show !== 'Stock'}" (click)="showStockItems()">
          <div class="tw-text-sm sm:tw-text-base md:tw-text-lg lg:tw-text-xl tw-font-sans  tw-text-gray-500 tw-font-bold">Stock Items</div>
        </div>
      </div>

      <div class="tabs-content-mobile">
        <div class="tab-content-mobile" *ngIf="show == 'Store'">
          <app-storeproducts-mobile [fromFloorItems]="fromFloorItems" [salesOrderMode]="true" [itemsPerPage]="30" > </app-storeproducts-mobile>
          <!-- <app-storeproductsmobile class="storeproduct-mobile" [salesOrderMode]="true" [itemsPerPage]="30" > </app-storeproductsmobile> -->
        </div>
        <div class="tw-p-4" *ngIf="show == 'Stock'">
          <app-stockitemslist class="" [salesOrderMode]="true"> </app-stockitemslist>
          <!-- <app-stockitemslistmobile class="stockitem-mobile w-100" [salesOrderMode]="true"> </app-stockitemslistmobile> -->
        </div>
      </div>
    </div>
  </div>
  