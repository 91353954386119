<div class="w-100">
    <div class="tw-flex tw-my-4">
        <div class="tw-w-full tw-flex tw-justify-between">
            <button class="btn-blue-so-square tw-ml-4" (click)="openModal(popTemplate)">
                {{ 'ADD_PRODUCT_TYPE' | translate }}
            </button>
        </div>
    </div>

    <div class="customer-content tw-px-4">
        <div class="table-responsive">
            <table class="table-so table table-light table-custom overflow-auto">
                <thead class="tw-font-bold tw-text-aedblue">
                    <tr style="font-size: small;background-color: #fafafa;">
                        <td colspan="1" class="tw-text-base tw-text-aedblue tw-font-semibold tw-pl-1">{{ 'PRODUCT_TYPE' | translate }}</td>
                        <td colspan="1" class="tw-text-base tw-text-aedblue tw-font-semibold ">{{ 'TAXABLE' | translate }}</td>
                        <td colspan="3" class=" tw-text-base tw-text-aedblue tw-font-semibold ">{{ 'TAX_QUANTITY' | translate }}</td>
                        <td colspan="3" class=" tw-text-base tw-text-aedblue tw-font-semibold "></td>
                    </tr>

                <tbody class="tw-py-2 tw-text-aedgraydark"
                    *ngFor="let type of productTypes | paginate: { itemsPerPage: 10, currentPage: p, id:'productType'};  let odd = odd"
                    [ngClass]="odd ? 'tw-bg-aedgrayback' :'tw-bg-aedgrayback100'">
                    <tr>
                        <td colspan="1" class="tdeventlist tw-pl-1">{{ type.productType }}</td>
                        <td colspan="3" class="tdeventlist"><span *ngIf="type.taxable ==1"
                                class="material-icons fs-1">check</span></td>
                        <td colspan="1" class="tdeventlist">{{ type.taxNumber}}</td>
                        <td colspan="1" class="tdeventlist tw-text-end">
                            <div class="d-flex tw-ml-4">
                                <!-- edit button -->
                                <svg class="ml-4 tw-cursor-pointer" (click)="editProductType(type, popTemplate)"
                                    id="Component_59_102" data-name="Component 59 – 102"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="25.674" height="25.681" viewBox="0 0 25.674 25.681">
                                    <defs>
                                        <style>
                                            .cls-15 {
                                                fill: #00adee;
                                            }
                                        </style>
                                        <clipPath id="clip-path">
                                            <rect id="Rectangle_156" data-name="Rectangle 156" class="cls-15"
                                                width="25.674" height="25.681" />
                                        </clipPath>
                                    </defs>
                                    <g id="Group_63" data-name="Group 63" class="">
                                        <path id="Path_76" data-name="Path 76" class="cls-15"
                                            d="M25.674,4.884a5.154,5.154,0,0,1-1.642,2.877C21,10.767,17.983,13.8,14.961,16.819Q12.306,19.475,9.65,22.13c-.313.313-.633.618-.946.931a1.42,1.42,0,0,1-.578.335Q4.861,24.481,1.6,25.572c-.095.032-.186.073-.279.109h-.6a1.094,1.094,0,0,1-.594-1.576q1.09-3.266,2.177-6.534a1.567,1.567,0,0,1,.389-.631Q10.514,9.121,18.337,1.3A4.289,4.289,0,0,1,25.3,2.465a5.738,5.738,0,0,1,.377,1.216ZM7.95,20.871c.089-.02.135-.1.192-.153q6.486-6.483,12.975-12.964c.166-.165.138-.246-.011-.392Q19.716,5.988,18.343,4.6c-.159-.162-.245-.167-.41,0q-2.648,2.667-5.312,5.319-3.827,3.827-7.657,7.651c-.133.133-.16.211-.012.357.946.933,1.881,1.875,2.821,2.813.052.052.1.118.176.136M23.641,4.2c0-.013-.014-.1-.025-.178A2.1,2.1,0,0,0,22.107,2.18,2.2,2.2,0,0,0,19.73,2.8c-.1.1-.1.171,0,.27q1.454,1.447,2.9,2.9c.086.086.155.1.252.009A2.349,2.349,0,0,0,23.641,4.2M5.976,21.876a.9.9,0,0,0-.07-.088q-1-1-2-2c-.1-.1-.147-.078-.188.048-.11.341-.226.681-.339,1.021q-.321.961-.641,1.923c-.02.06-.073.127-.024.189s.124.016.183,0q1.473-.488,2.944-.98c.05-.017.121-.017.135-.108"
                                            transform="translate(0 0)" />
                                    </g>
                                </svg>
                                <!-- delete button -->
                                <svg *ngIf="type.count == 0" class="ml-4 mr-2 tw-cursor-pointer"
                                    (click)="deleteType(type.uuid)" id="Group_235" data-name="Group 235"
                                    xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="24.255" height="29.82" viewBox="0 0 24.255 29.82">
                                    <defs>
                                        <style>
                                            .cls-1 {
                                                fill: #00adee;
                                            }

                                            .cls-2 {
                                                clip-path: url(#clip-path);
                                            }
                                        </style>
                                        <clipPath id="clip-path">
                                            <rect id="Rectangle_99" data-name="Rectangle 99" class="cls-1"
                                                width="24.255" height="29.82" />
                                        </clipPath>
                                    </defs>
                                    <g id="Group_40" data-name="Group 40" class="">
                                        <path id="Path_55" data-name="Path 55" class="cls-1"
                                            d="M14.513,0a2.979,2.979,0,0,1,1.865,1.289,3.193,3.193,0,0,1,.43,1.838c-.015.657-.087.574.563.576,1.493,0,2.987,0,4.48,0a2.316,2.316,0,0,1,2.4,2.4q0,1.556,0,3.113a.939.939,0,0,1-1.043,1.043c-.388,0-.4.019-.414.412q-.15,3.426-.307,6.853c-.072,1.577-.151,3.154-.226,4.732-.078,1.616-.146,3.232-.236,4.848a2.812,2.812,0,0,1-2.887,2.711q-7.011,0-14.022,0a2.84,2.84,0,0,1-2.9-2.853c-.084-2.217-.2-4.433-.3-6.649-.073-1.587-.153-3.174-.228-4.761q-.119-2.5-.234-4.993c-.01-.218-.1-.3-.322-.3C.311,10.251,0,9.926,0,9.1,0,8.086,0,7.068,0,6.05A2.313,2.313,0,0,1,2.344,3.706c1.581,0,3.161-.009,4.742.005.3,0,.383-.09.371-.38A4.029,4.029,0,0,1,7.506,2.2,2.78,2.78,0,0,1,9.465.09L9.741,0ZM12.148,10.264q-4.233,0-8.467,0c-.221,0-.338.033-.322.3.082,1.364.151,2.728.219,4.092q.119,2.4.229,4.791c.086,1.878.164,3.756.25,5.634.031.668.07,1.335.113,2a.856.856,0,0,0,.638.78,1.594,1.594,0,0,0,.46.057h13.7a1.623,1.623,0,0,0,.432-.046.863.863,0,0,0,.682-.836q.115-2.177.225-4.354.12-2.424.232-4.849c.085-1.868.164-3.737.248-5.605.024-.523.048-1.045.089-1.567.03-.381.029-.4-.353-.4h-8.38m-.02-1.9q4.946,0,9.891,0c.249,0,.344-.061.336-.325-.018-.572,0-1.144-.006-1.716s-.141-.716-.725-.716H2.6c-.544,0-.692.148-.693.689q0,.785,0,1.571c0,.536-.051.492.478.492h9.746M12.119,3.7c.833,0,1.666-.005,2.5,0,.213,0,.3-.064.286-.283-.014-.193,0-.387-.005-.581a.934.934,0,0,0-.961-.957q-1.815-.018-3.631,0a.93.93,0,0,0-.948.939c-.01.193.009.388-.006.581-.018.229.07.3.3.3.823-.011,1.646,0,2.469,0"
                                            transform="translate(0)" />
                                        <path id="Path_56" data-name="Path 56" class="cls-1"
                                            d="M85.757,162.45q0,3.025,0,6.051a.924.924,0,0,1-.669.918.966.966,0,0,1-1.059-.36.882.882,0,0,1-.173-.544q0-6.066,0-12.131a.912.912,0,0,1,.94-.937.924.924,0,0,1,.958.952c0,2.017,0,4.034,0,6.051"
                                            transform="translate(-77.334 -143.36)" />
                                        <path id="Path_57" data-name="Path 57" class="cls-1"
                                            d="M145.636,162.462q0,3.011,0,6.022a.925.925,0,0,1-.653.929.965.965,0,0,1-1.082-.364.9.9,0,0,1-.166-.546q0-6.051,0-12.1a.915.915,0,0,1,.953-.954.926.926,0,0,1,.946.964q.006,3.025,0,6.051"
                                            transform="translate(-132.557 -143.361)" />
                                        <path id="Path_58" data-name="Path 58" class="cls-1"
                                            d="M205.506,162.412q0,3.011,0,6.022a.925.925,0,0,1-.657.926.97.97,0,0,1-1.081-.368,1.066,1.066,0,0,1-.164-.664q0-4.335,0-8.669c0-1.105,0-2.211,0-3.316a.922.922,0,0,1,.783-.942.952.952,0,0,1,1.076.645,1.268,1.268,0,0,1,.042.431q0,2.967,0,5.935"
                                            transform="translate(-187.771 -143.306)" />
                                    </g>
                                </svg>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="pagination-container">
                <pagination-controls 
                id="productType" (pageChange)="p = $event" maxSize="10" previousLabel="" nextLabel="" autoHide="true">
                </pagination-controls>
            </div>
        </div>
    </div>
</div>

<ng-template #popTemplate>
    <div *ngIf="showModal" class="modal-background"></div>
    <form [formGroup]="type" class="modal-container-sm">
        <div class=" tw-p-4 tw-text-center">
            <div class="title-1-orange tw-mx-auto">{{ editType == null ? ('ADD_PRODUCT_TYPE' | translate) : ('EDIT_PRODUCT_TYPE' | translate) }}</div>
        </div>

        <div class="tw-text-aedgray tw-px-5 tw-w-full">
            <div class="tw-flex tw-flex-col">

                <div class="tw-grid tw-gap-4">
                    <div class="tw-mb-4">
                        <label for="productType">{{ 'PRODUCT_TYPE' | translate }}</label>
                        <input formControlName="productType" id="productType" placeholder="{{ 'PRODUCT_TYPE' | translate }}" type="text"
                            class="input-header tw-py-1 tw--mt-2">
                    </div>

                    <div class="tw-mb-4">
                        <label for="taxable">{{ 'TAXABLE' | translate }}</label>
                        <div class="tw-flex tw-items-center">
                            <input value="1" type="checkbox" formControlName="taxable" id="taxable" 
                            class="tw-mr-2" placeholder="{{ 'TAXABLE' | translate }}">
                            <label for="taxable" class="tw-mt-2 tw-mr-2">{{ 'YES' | translate }}</label>
                        </div>
                    </div>

                    <div class="tw-mb-4">
                        <label for="taxNumber">{{ 'TAX_QUANTITY' | translate }}</label>
                        <input formControlName="taxNumber" id="taxNumber" placeholder="{{ 'TAX_QUANTITY' | translate }}" type="number"
                            class="input-header tw-py-1 tw--mt-2">
                    </div>

                    <div class="tw-mb-4 tw-flex">
                        <button *ngIf="editType == null" (click)="saveType()" class="btn-blue-so tw-flex"
                            style="cursor:pointer;">
                            <span class="material-icons fs-1">check</span> {{ 'ADD' | translate }}
                        </button>
                        <button *ngIf="editType != null" (click)="updateType()" class="btn-blue-so tw-flex"
                            style="cursor:pointer;">
                            <span class="material-icons fs-1">check</span> {{ 'UPDATE' | translate }}
                        </button>
                        <button (click)="closeModal()" class="btn-blue-so tw-flex ml-4" style="cursor:pointer;">
                            <span class="material-icons fs-1">close</span> {{ 'CANCEL' | translate }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </form>
</ng-template>