<div class="modal-body">
  <div class="modal-body-header">
    <h4>Add Item</h4>
  </div>
  <div class="modal-close-button">
    <app-closebutton></app-closebutton>
  </div>
  <div>
    <p>
      {{ item.companyName | uppercase }}
      <br />
      {{ item.name | uppercase }}
      <br />
      SKU: {{ item.SKU | uppercase }}
    </p>
  </div>
  <div class="form-container">
    <div class="form-half">
      Quantity
      <input
        class="form-control text-right"        
        type="number" 
        [formControl]="quantityNumTest"
      />
        <!-- [(ngModel)]="item.quantityNum" -->
      <br />
    </div>
    <div class="form-half">
      Price
      <input
        class="form-control text-right"
        type="number" 
        [formControl]="priceNumTest"
      />
        <!-- [(ngModel)]="item.priceNum" -->
      <br />
    </div>
    <div class="form-buttons-container">
      <button (click)="submit()" class="btn btn-5">
        <span class="material-icons"> add </span> Add
      </button>
    </div>
  </div>
</div>
