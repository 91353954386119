import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-closebutton',
  templateUrl: './closebutton.component.html',
  styleUrls: ['./closebutton.component.scss']
})
export class ClosebuttonComponent implements OnInit {

  constructor(private bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  hideModal() {
    this.bsModalRef.hide();
  }

}
