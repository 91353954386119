import { Component, OnInit, Input, AfterContentChecked, DoCheck, OnDestroy, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { GlobalsService } from 'src/app/_services/globals.service';
import 'rxjs/add/operator/takeUntil';
import { startWith } from 'rxjs/internal/operators/startWith';
import { tap } from 'rxjs/internal/operators/tap';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { SettingsService } from 'src/app/_services/settings.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delivery-mobile',
  templateUrl: './delivery-mobile.component.html',
  styleUrls: ['./delivery-mobile.component.scss']
})


export class DeliveryMobileComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  // @Input() salesOrder: any;
// @Input() deliveries: any[];
// @Input() canSelect = true;

  @Input() showEditDelivery: boolean = false;
  @Output() showEditDeliveryChange = new EventEmitter<boolean>();


  

  @Input() delivery: any = { };
  @Input() salesOrder: any;
  @Input() DeliveryToDelete: any = [];
  @Input() passdiscounts: any;
  oldDiscounts: any;
  @Input() passSO: any;
  discountTotal = 0;
  toBePayed = 0;
  iterableDiffer: any;
  discounts: any;
  @Input() event: any;
  showEdit: false;
  newFormData: boolean = true;
  model = {
    deliveryItems: []
  };
  deliveryinfo = []

  ngOnInit() {
    this.initData()
  }

  addDelivery(p){
    let delivery ={
      Type : p.method,
      delOrOther : p.type,
      deliveryAmountNum :  p.price,
      deliveryCode : "",
      deliveryId : this.global.guid(),
      deliveryNotes : "",
      foreignSOID : this.salesOrder['SOID'],
      pretax : p.taxable == true ? 1 : 0,
      status : "insert",
    }

    this.alertify.success('Delivery Added');
    this.salesOrderService.delivery.next(delivery);
  }

  initData(){
    // this.discounts = this.passdiscounts;
    if (this.passdiscounts !== undefined) {
      this.passdiscounts.map(e => {
        if(e.pretax == null){
          e.pretax = 0
        }
      });
    this.model.deliveryItems =   Object.assign([], JSON.parse(JSON.stringify( this.passdiscounts)));
    }
    if(this.salesOrder['deliveryItems'] != undefined || this.salesOrder['deliveryItems'] != null){
      this.model.deliveryItems =   Object.assign([], JSON.parse(JSON.stringify( this.salesOrder['deliveryItems'])));
    }

    this.salesOrder = this.passSO;
    if (this.router.url.toString().includes('salesorder') ){
      this.settingsService
      .SettingsEvent$
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (data) => {
          // console.log(data[0]["deliveryInfo"])
          if(data[0] == undefined) return;
          if(data[0]["deliveryInfo"] != null){
            this.deliveryinfo = JSON.parse(data[0]["deliveryInfo"]);
          }
          
        },
        (error) => {
          this.alertify.error(error);
        }
      );
    }else{
      let specificFields = {
        values: [
            "deliveryInfo"
        ]
       }
      this.settingsService
      .getSpecificSettings(specificFields)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (data) => {
          // console.log(data[0]["deliveryInfo"])
          if(data[0]["deliveryInfo"] != null){
            this.deliveryinfo = JSON.parse(data[0]["deliveryInfo"]);
          }
          
        },
        (error) => {
          this.alertify.error(error);
        }
      );
    }
    

  }

  form = new FormGroup({});
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'deliveryItems',
    type: 'repeat-mobile',
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'tw-grid tw-grid-cols-12 border-bottom',
      fieldArray: {
        fieldGroupClassName: 'tw-grid tw-grid-cols-12 border-bottom',
        templateOptions: {
          btnText: 'Add Delivery',
          addItem:  (x) => {  
            this.model.deliveryItems.push();
            // console.log('trigger this one', x)
          },
          removeItem: (x) =>{ 
            if(this.model.deliveryItems[x]['deliveryId'] != null){
              this.DeliveryToDelete.push(this.model.deliveryItems[x])
            } 
          }
         },


      fieldGroup: [
{
key: 'Type',
type: 'select-input-bold',
className: 'tw-col-span-12 sm:tw-col-span-12 md:tw-col-span-8 lg::tw-col-span-8'+
 'select-formly-SOmobille tw-mb-2',
defaultValue: 'Delivery',
templateOptions: {
  placeholder: '',
  required: true,
  options: [
    {label: 'Delivery', value: 'Delivery'},
    {label: 'Recycle', value: 'Recycle'},
    {label: 'Removal', value: 'Removal'},
    {label: 'Drop off Only', value: 'Drop off Only'},
    {label: 'Setup/Assembly', value: 'Setup/Assembly'},
    {label: 'Direct Shipment', value: 'Direct Shipment'}
  ],
},
},

//type
{
  key: 'delOrOther',
  type: 'select',
  className: 'tw-col-span-12 sm:tw-col-span-12 md:tw-col-span-8 lg::tw-col-span-8'+
   'select-formly-SOmobille tw-mb-2',
  defaultValue: 'Delivery',
  templateOptions: {
    // label: 'ETA',
    options: [
      {label: 'Delivery', value: 'Delivery'},
      {label: 'Other Charges', value: 'Other Charges'}
    ],
  },
},

{
  key: 'pretax',
  type: 'checkbox',  
className: 'tw-col-span-12 sm:tw-col-span-12 md:tw-col-span-4 lg::tw-col-span-4 '+
'tw-text-lg tw-mb-2 tw-ml-0 sm:tw-ml-0 md:tw-ml-2 lg:tw-ml-2',
  templateOptions: {
    label: 'Taxable',
    placeholder: ''
  },
  lifecycle: {
    onInit: (form, field) => {
      form.get('Type').valueChanges.pipe(                
        takeUntil(this.destroy$)  ,
        startWith(form.get('Type').value),
        tap(typeId => {
          if (this.event !== undefined) {
          if (typeId === 'Delivery' && field.formControl.value == null) {
            field.formControl.setValue(this.event['deliveryPreTax'] == null ? 0 : this.event['deliveryPreTax']);
          }
          if (typeId === 'Recycle' && field.formControl.value == null) {
            field.formControl.setValue(this.event['recyclePreTax'] == null ? 0 : this.event['recyclePreTax']);
          }
          if (typeId === 'Removal' && field.formControl.value == null) {
            field.formControl.setValue(this.event['removalPretax'] == null ? 0 : this.event['removalPretax']);
          }
        }
        }),
      ).subscribe();
    },
    }
},

{
  key: 'deliveryNotes',
  type: 'input',  
  className: 'tw-col-span-12 sm:tw-col-span-12 md:tw-col-span-4 lg::tw-col-span-4 '+
  'tw-mr-0 sm:tw-mr-0 md:tw-mr-2 lg:tw-mr-2  tw-mb-2  input-formly-SOmobille',
  templateOptions: {
    placeholder: 'Notes',
  }
},

{
  key: 'deliveryCode',
  type: 'input',
  className: 'tw-col-span-5 sm:tw-col-span-5 md:tw-col-span-4 lg::tw-col-span-4 '+
  'tw-mr-1 sm:tw-mr-1 md:tw-mr-0 lg:tw-mr-0 tw-mb-2  input-formly-SOmobille',
  templateOptions: {
    placeholder: 'ETA'
  }
},

{
key: 'deliveryAmountNum',
  type: 'input',
  className: 'tw-col-span-5 sm:tw-col-span-5 md:tw-col-span-4 lg::tw-col-span-4 '+
  'tw-ml-1 sm:tw-ml-1 md:tw-ml-2 lg:tw-ml-2 tw-mb-2  input-formly-SOmobille',
  defaultValue: 0,
  templateOptions: {
    type: 'number',
    required: true,
    placeholder: 'Enter Amount*'
  }
}

]
      }
      }

 ];


  constructor(
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private router: Router,
    private salesOrderService: SalesOrderService,
    private changeDetector: ChangeDetectorRef,
    private global: GlobalsService,
    private authService: AuthService,
    private alertify: AlertifyService,
    private settingsService: SettingsService,
  ) { }

 

  cancelForm() {
    if(this.passdiscounts != undefined){
      this.passdiscounts.map(e => {
        if(e.pretax == 0){
          e.pretax = null
        }
      });
    }

    //  console.log(this.discounts)
    
    this.salesOrder['deliveryItems'] = this.passdiscounts;
    this.bsModalRef.hide();
    this.showEditDeliveryChange.emit(false)
    this.changeDetector.detectChanges();
  }

  submit() {
    this.passdiscounts = Object.assign([], this.model.deliveryItems);
    const soID = this.salesOrder['SOID'];
    for (const value of this.passdiscounts) {
      value['foreignSOID'] = soID;
      if (!value.hasOwnProperty('deliveryId')) {
        value['deliveryId'] = this.global.guid();
        value['status'] = 'insert'
      }

      if (value['pretax'] === false) {
        value['pretax'] = '';
      }

    }

    this.passdiscounts.map(e => {
      if(e.pretax == 0){
        e.pretax = null
      }
    });


    
    this.salesOrder['deliveryItems'] = this.passdiscounts;
    this.bsModalRef.hide();
    this.showEditDeliveryChange.emit(false)
    this.changeDetector.detectChanges();
  }

  ngAfterContentChecked(): void {
    this.updateTotals();
  }

  updateTotals() {
    this.discountTotal = 0 ;
    if (this.model.deliveryItems) {
      for (const i of this.model.deliveryItems) {
        if (i['deliveryAmountNum'] > 0) {
//          this.discountTotal += parseFloat(i['deliveryAmountNum']);
            this.discountTotal += this.global.pNumber(i['deliveryAmountNum']);
          }

      }

    }

  }

  ngOnDestroy(): void {    
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // console.log('destroyed')
  }


}
