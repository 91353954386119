import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';

@Component({
  selector: 'app-salesorder-list-front',
  templateUrl: './salesorder-list-front.component.html',
  styleUrls: ['./salesorder-list-front.component.scss']
})
export class SalesorderListFrontComponent implements OnInit {
role:any
  constructor(private authSvc:AuthService,private salesSerivce:SalesOrderService) { }

  ngOnInit(): void {
    document.getElementById('mainWithSideBar').style.backgroundColor = "#fff"    
    if(this.role == 'Sales Person' || this.role == 'Cashier'){    
      document.getElementById('sidebar').style.display = "none";
      document.getElementById('mainWithSideBar').className = "body-page-content-SalesPerson";
    }

  }

}
