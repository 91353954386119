import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Pagination } from 'src/app/_modules/pagination';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { CustomerService } from 'src/app/_services/customer.service';

@Component({
  selector: 'app-cust',
  templateUrl: './cust.component.html',
  styleUrls: ['./cust.component.scss']
})
export class CustComponent implements OnInit , OnDestroy{

  destroy$: Subject<boolean> = new Subject<boolean>();
  showForm = false;
  states:any = [];
  customers:Array<any>=[];
  paginationMode: boolean=false;
  searchMode: boolean=false;
  editmode:boolean=true;
  idparam: any='';
  @Input() role:any = ''

  
  
  valcustomer: any = {};
  @Input() set customer(value: any) {
    this.valcustomer = value
  }
  get customer(): any {
    return this.valcustomer;
  }

  valshowSearch:any = true
  @Input() set showSearch(value: any) {
    this.valshowSearch = value
  }
  get showSearch(): any {
    return this.valshowSearch;
  }


  valcanEdit: any = true;
  @Input() set canEdit(value: any) {
    this.valcanEdit = (this.role=='Admin' || this.role=='Dealer')?true:value;
  }
  get canEdit(): any {
    return this.valcanEdit;
  }



  customerOld:any = {};

  @Output() updatecustomerOnSO = new EventEmitter<any>();
  @Output() cancelAddCustomerOnSO = new EventEmitter<any>();
  @Output() changeShowSearch = new EventEmitter<boolean>();


  searchCus:string='';
  itemsPerPage: number=5;
  maxsize = 10;
  pagination: Pagination;
  cusForm = new FormGroup({
    Id: new FormControl('',[]),
    firstName: new FormControl('',[Validators.required]),
    lastName: new FormControl('',[]),
    billingAddress1: new FormControl('',[]),
    city: new FormControl('',[]),
    state: new FormControl('',[]),
    zip: new FormControl('',[]),
    email: new FormControl('',[]),
    phone1: new FormControl('',[]),
  });

  constructor( private http: HttpClient,
    private customerService:CustomerService,
    private alertify:AlertifyService,
    private route:ActivatedRoute,
    private translate: TranslateService,
    ) { }

  getStates(){
    return this.http.get<any[]>('assets/states.json');
  }

  saveCust(){
    
    //sino hay customer vamso a guardar
    if(this.customer == ""){
      
      if (this.cusForm.valid) {
        this.customerService.addCustomer(this.cusForm.value)
        .pipe(takeUntil(this.destroy$))   
        .subscribe((res:any) => {
          this.cusForm.reset(res[0])
          // this.cusForm.controls['Id'].setValue(res[0].Id);
          this.updatecustomerOnSO.emit(res[0])
          this.customer = this.cusForm.value
          this.showForm = true;
          this.showSearch = false;
          this.editmode = false;
          this.alertify.success(this.translate.instant('CUSTOMERADDED'));
        }, error => {
          this.alertify.error(error);
        });  
      }   
    
    // sino, actualizamos el so.customer
    }else{

      if (this.cusForm.valid) {
        this.customerService.updateCustomer(this.cusForm.value.Id,this.cusForm.value)
        .pipe(takeUntil(this.destroy$))   
        .subscribe((res:any) => {
          this.updatecustomerOnSO.emit(this.cusForm.value)
          this.customer = this.cusForm.value
          this.showForm = true;
          this.showSearch = false;
          this.changeShowSearch.emit(this.showSearch);
          this.editmode = false;
          this.alertify.success(this.translate.instant('CUSTOMERUPDATED'));
        }, error => {
          this.alertify.error(error);
        });  
      } 

    }
      
  }

  cancelCust(){
    if(this.customer != ""){
      this.cusForm.reset(this.customerOld)
      this.showForm = true;
      this.showSearch = false;
      this.changeShowSearch.emit(this.showSearch);
      this.editmode = false;
    }else{
      this.cancelAddCustomerOnSO.emit(false)
    }
  }

  deleteCust(){
      this.showForm = true;
      this.showSearch = false;
      this.changeShowSearch.emit(this.showSearch);
      this.editmode = true;
      this.cusForm.reset()
      this.customer = ""
      this.updatecustomerOnSO.emit("")    
  }

  editCustomer(){
    this.customerOld = JSON.parse(JSON.stringify(this.customer));
    this.showSearch = false;
    this.changeShowSearch.emit(this.showSearch);
    this.showForm = true
    this.editmode = true;
  }

  ngOnInit(): void {
    this.idparam = this.route.snapshot.params.id;
    this.getStates().subscribe((data) => {
      this.states = data;
    });  
    this.pagination =  {
      totalItems: 0,
      totalPages: 10,
      currentPage: 1,
      itemsPerPage: this.itemsPerPage
    };
   
    //verifico si la orden tiene customer
    if(this.customer != "" && this.customer != null && this.customer != undefined){
      this.cusForm.reset(this.customer)
      this.customerOld=JSON.parse(JSON.stringify(this.customer));
      this.cusForm.patchValue({ Id: this.customer.customerId});
      this.showForm = false
      this.editmode = false
    }else{
      this.showForm = true
    }

  }

  getcustomers(){
    this.paginationMode = true
    this.customerService.getCustomersLar(this.pagination.currentPage,this.pagination.itemsPerPage,this.searchCus,null,'SO') 
    .pipe(takeUntil(this.destroy$))   
    .subscribe((res:any) => {
     this.customers=res.result;
     //console.log(res);

     this.pagination = res.pagination
     
    }, error => {
      this.alertify.error(error);
    });  
  }

  search() { 
    if (this.searchCus != '') {      
      this.pagination.currentPage = 1;
      this.searchMode=true;
      this.getcustomers();
    }
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.getcustomers();
  }

  selectCustomer(id,customer){
    this.customer = customer;
    this.cusForm.patchValue(customer)
    this.showForm = false;
    this.showSearch = false;
    this.editmode = false;    
    this.updatecustomerOnSO.emit(customer)
    this.changeShowSearch.emit(this.showSearch);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
