export const SizesIndex = 
    {
        "ALL SIZES": " ALL SIZES ",
        "TWIN": "Twin  , Twin LP , tuin ",
        "TWIN XL": "Twin XL , Twin XL LP, TXL",
        "FULL": "Full , Full LP , Full SPT Low Profile , Full SPT , FULL , FULL  , Full SPT Low Profile, ful",
        "QUEEN": "SHORT QUEEN , Queen, Quen , Queen SPT , Queen LP , SPLIT QUEEN (ORDER 2 FOR SPLIT QUEEN), Queen SPT Low Profile , QUEEN  Set , Queen (Set of 2) ,  Queen, Queen SPT Low Profile, SPLIT QUEEN  (ORDER 2 TO MAKE A SET)",
        "KING": "KING , King LP , Split King kin,  , KING  Set , KING  (2 per FLAT PACK) , KING , King (set of 2)",
        "CAL KING": "Split Cal King , Split Head Cal King , calking , calkin , Split head King , Split head Cal King , Split Cal King (set of 2) , SPLIT CAL KING (ORDER 2 FOR SET) , SPLIT CAL KING (ORDER 2 FOR SPLIT CAL KING) , CAL KING , King LP ",
        "All":" Twin  , Twin LP , tuin Twin XL , Twin XL LP, TXL Full , Full LP , Full SPT Low Profile , Full SPT , FULL , FULL  , Full SPT Low Profile, ful SHORT QUEEN , Queen , Queen SPT , Queen LP , SPLIT QUEEN (ORDER 2 FOR SPLIT QUEEN), Queen SPT Low Profile , QUEEN  Set , Queen (Set of 2) , Cal Queen, Queen SPT Low Profile, SPLIT QUEEN  (ORDER 2 TO MAKE A SET) \
        KING , King LP , Split King , KING  Set , KING  (2 per FLAT PACK) , KING , King (set of 2) , Split Cal King , Split Head Cal King  , Split head King , Split head Cal King , Split Cal King (set of 2) , SPLIT CAL KING (ORDER 2 FOR SET) , SPLIT CAL KING (ORDER 2 FOR SPLIT CAL KING) , CAL KING , King LP ",
    }
  
