import { CardReaderService } from 'src/app/_services/cardreader.service';
import { PaymentsFormComponent } from '../payments/payments-form/payments-form.component';
import { Component, OnInit, Input, AfterContentChecked, DoCheck, IterableDiffers, OnDestroy, Output, EventEmitter } from '@angular/core';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { GlobalsService } from 'src/app/_services/globals.service';
import { DecimalPipe } from '@angular/common';
import { CardpaymentComponent } from 'src/app/components/cardpayment/cardpayment.component';
import { last, take, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-payments-mobile',
  templateUrl: './payments-mobile.component.html',
  styleUrls: ['./payments-mobile.component.scss']
})
export class PaymentsMobileComponent implements OnInit {

  @Input() payments: any = [];
  @Input() settings: any;

  @Input() showEditPayment: boolean = true;
  @Output() showEditPaymentChange = new EventEmitter<boolean>();
  
  constructor(
    private cardService:  CardReaderService,
    private alertify: AlertifyService,
    private bsModalRef: BsModalRef,
    private global: GlobalsService,
    private userService: UserService,
    private modalService: BsModalService,
    private cardReaderService: CardReaderService,
    private _iterableDiffers: IterableDiffers,
    private authService: AuthService
  ) {
    this.iterableDiffer = this._iterableDiffers.find([]).create(null);
   }

  ngOnInit() {
    this.cardService.setDeviceID();

    ////////////////////////////////
    if (this.passpayments !== undefined) {
      this.model.payments =  Object.assign([], JSON.parse(JSON.stringify( this.passpayments)));
      }
      // console.log(this.passSO)
      this.salesOrder = this.passSO;

  
  
      this.paymentTypes = [
        {label: 'Cash', value: 'Cash'},
        {label: 'Check', value: 'Check'},
        {label: 'Credit Card', value: 'Credit Card'},
        {label: 'Financing', value: 'Financing'}
      ];


      this.cardReaderService.cardSignatureAccept
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any ) => {
          if (res === 'Complete') {
            var newline = String.fromCharCode(13, 10);
            let cardpayment = this.cardReaderService.soPayment.value;
            
            this.model.payments[this.model.payments.length - 1]['authCode'] = this.cardPayment['authCode'].toUpperCase();
            this.model.payments[this.model.payments.length - 1]['cardLastFour'] = this.cardPayment['cardLastFour'].toUpperCase();
            this.model.payments[this.model.payments.length - 1]['cardType'] = this.cardPayment['cardType'].toUpperCase();
            this.model.payments[this.model.payments.length - 1]['nameOnCard'] = this.cardReaderService.cardName.value.toUpperCase();

            // this.model.payments[this.model.payments.length - 1]['Note'] =
            // 'Approval Code: ' + this.cardPayment['authCode'] + newline +
            // 'Last Four Digits: ' + this.cardPayment['cardLastFour'] + newline +
            // 'Card Type: ' + this.cardPayment['cardType'].toUpperCase() + newline +
            // 'Name on Card: ' + this.cardReaderService.cardName.value;

            this.options.resetModel({ ...this.model, ...this.model.payments });
            this.submitNotClosing(this.model);

          }
        }, error => {
          this.alertify.error(error);
        });


  }


  
  filterPayments(payments):any[]{   
    let newPayments:any = []
    if(payments != null && payments != undefined){
      newPayments =  payments.filter((p:any)=> p.voidtype_tx == null || p.voidtype_tx == undefined);
      return newPayments;
    }else{
      return [];
    }
  }


  editPayment(payment: any) {
    const initialState = {
      passpay: payment,
      passpayments: this.payments,
      settings: this.settings
    };
    this.bsModalRef = this.modalService.show(PaymentsFormComponent, {initialState,
      class: 'modal-lg'
    });
  }

  ////////////////////////////////////////////////////////////////////////////////////////////

  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() payment: any = { };
  @Input() PaymentsToDelete: any = { };
  salesOrder: any;
  passpay: any;
  paymentTypes: any;
  @Input() passpayments: any;
  @Input() passSO: any;
  paymentTotal = 0;
  cardPayment: any;
  cardPaymentProcessed = false;
  toBePayed = 0;
  iterableDiffer: any;
  myjson: any = JSON;
  model = {
    payments: []
  };
  creditForm = false;

  form = new FormGroup({});
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'payments',
      type: 'repeatpayments',
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'tw-grid tw-grid-cols-12 border-bottom',
      fieldArray: {
        fieldGroupClassName: 'tw-grid tw-grid-cols-12 border-bottom',
        templateOptions: {
          btnText: 'Add Payment',
          afterAdd:  () => {
            if (this.settings['cardLocation']) {
              if (this.fields[0]) {
                this.fields[0].fieldGroup[this.fields[0].fieldGroup.length - 1].fieldGroup[2].templateOptions.options = this.paymentTypes;
                this.fields[0].fieldArray.fieldGroup[2].templateOptions.options = this.paymentTypes;
              }
            }
          },
          process: (res, model) => {
            this.showCard(model);
          },
          removePayment: (x) =>{ 
            if(this.model.payments[x]['paymentID'] != null){
              this.PaymentsToDelete.push(this.model.payments[x])
              // console.log(this.PaymentsToDelete)
            } 
          }
        },


      fieldGroup: [
        {
          key: 'paymentID',
          type: 'input',
          defaultValue: '',
          className: 'col-md-6',
          hide: true,
          lifecycle: {
            onInit: (form, field) => {
              if ( field.formControl.value === '') {
                field.model['paymentID'] = this.global.guid();
                field.formControl.setValue(this.global.guid());
                field.model['status'] = 'insert';
              }
            },
          },
        },
        {
          key: 'foreignSOID',
          type: 'input',
          className: 'col-md-6',
          hide: true,
          lifecycle: {
            onInit: (form, field) => {
                field.model['foreignSOID'] = this.salesOrder['SOID'];
                field.formControl.setValue(this.salesOrder['SOID']);
            },
          },
        },

        {
          key: 'paymentType',
          type: 'select',          
          className: 'tw-col-span-12 sm:tw-col-span-12 md:tw-col-span-6 lg::tw-col-span-6 '+
          'tw-mt-2 tw-mr-0 sm:tw-mr-0 md:tw-mr-4 lg:tw-mr-4 input-header-payment tw-mb-2',
          // className: 'col-md-5 pl-0 input-header-payment',
          hideExpression: (model) => model['transactionPayment'],
          defaultValue: 'Cash',
          templateOptions: {
            label: 'Method',
            placeholder: '',
            required: true,
            options: [
              {label: 'Cash', value: 'Cash'},
              {label: 'Check', value: 'Check'},
              {label: 'Master Card', value: 'Master Card'},
              {label: 'Visa', value: 'Visa'},
              {label: 'Amex', value: 'Amex'},
              {label: 'Discover', value: 'Discover'},
              {label: 'Financing', value: 'Financing'},
              // {label: 'Credit Card', value: 'Credit Card'}
            ],
          },
          lifecycle : {
            onInit: (field) => {
            }
          }
        }
    ,
    
    {
      key: 'paymentType',
      type: 'input',
      className: 'tw-col-span-12 sm:tw-col-span-12 md:tw-col-span-6 lg::tw-col-span-6 '+
      'tw-mt-2 tw-mr-0 sm:tw-mr-0 md:tw-mr-4 lg:tw-mr-4 input-header-payment tw-mb-2',
      hideExpression: (model) => !model['transactionPayment'],
      templateOptions: {
        label: 'Method',
        required: true
      
      },
    },
    
    
    {
        key: 'paymentAmount',
        type: 'input',
        className: 'tw-col-span-12 sm:tw-col-span-12 md:tw-col-span-6 lg::tw-col-span-6 '+
        'input-header-payment tw-mb-2 tw-mt-0 sm:tw-mt-0 md:tw-mt-2 lg:tw-mt-2 ',
        defaultValue: 0,
        hideExpression: (model) => model['transactionPayment'],
        templateOptions: {
          type: 'number',
          label: 'Amount',
          required: true,
          // addonRight: {
          //   text: 'Process Card',
          //    class: ' text-small text-primary',
          //   onClick: (to, fieldType, $event) => {
          //     console.log(to, fieldType, $event);
              
          //     this.showCard(fieldType.model);
          //   },
  
          },
          // addonRight: {
          //   text: '$',
          // },

        
        lifecycle: {
          onInit: (form, field) => {
            if ( parseFloat(field.formControl.value) === 0) {
              if (this.getPayed() > 0 ) {
                field.model['paymentAmount'] = this.getPayed();
                field.formControl.setValue(this.getPayed());
              }
            }
          },
        },
      },

      {
        key: 'paymentAmount',
        type: 'input',        
        className: 'tw-col-span-12 sm:tw-col-span-12 md:tw-col-span-6 lg::tw-col-span-6 '+
        'input-header-payment tw-mb-2  tw-mt-0 sm:tw-mt-0 md:tw-mt-2 lg:tw-mt-2 ',
        // className: 'col-md-4 mr-0 pr-0 input-formly-SOmobille',
        hideExpression: (model) => !model['transactionPayment'],

        defaultValue: 0,
        templateOptions: {
          type: 'number',
          label: 'Amount',
          required: true,
          readonly: true,
          },
      },



      {
        key: 'paymentDetail',
        type: 'textarea',
        className: 'tw-col-span-12 sm:tw-col-span-12 md:tw-col-span-6 lg::tw-col-span-6 '+
        'tw-mr-0 sm:tw-mr-0 md:tw-mr-4 lg:tw-mr-4 input-header-payment tw-mb-2',
        templateOptions: {
          label: 'Internal Notes',
          placeholder: 'Internal Notes',
          rows: 2,
        },
      },
      {
        key: 'Note',
        type: 'textarea',
        className: 'tw-col-span-12 sm:tw-col-span-12 md:tw-col-span-6 lg::tw-col-span-6 '+
        'input-header-payment tw-mb-2',
        templateOptions: {
          label: 'Receipt Notes',
          placeholder: 'Receipt Notes',
          rows: 2,
        },
      },

      {
        type: 'button',
        key: 'btnProcess',
        className: 'my-auto mt-1',
        hide: true,
        templateOptions: {
         label: ' ',
          text: 'Process',
          // btnType: 'primary btn-3',
          btnSize: 'btn-3',
          onClick: ($event, model) => {
            this.showCard(model);
          },
          onClickProcessed: ($event, model) => {
            this.showCardProcessed(model);
          },
          hideExpression: (model, formState) => {
            
          },
        },
      },

      {
        key: 'cardLastFour',
        type: 'input',
        hide: true,
        className: 'col-md-6',
        templateOptions: {
          label: 'Last Four',
        },
      },
      {
        key: 'authCode',
        type: 'input',
        hide: true,
        className: 'col-md-6',
        templateOptions: {
          label: 'Auth Code',
        },
      },
      // {
      //   key: 'cardType',
      //   type: 'input',
      //   className: 'col-md-6',
      //   templateOptions: {
      //     label: 'Card Type',
      //   },
      // },
      // {
      //   key: 'cardDateSubmitted',
      //   type: 'input',
      //   className: 'col-md-6',
      //   templateOptions: {
      //     label: 'Card Date',
      //   },
      // },
      // {
      //   key: 'CardTransactionType_tx',
      //   type: 'input',
      //   className: 'col-md-6',
      //   templateOptions: {
      //     label: 'Transaction Type',
      //   },
      // }
    ],
    },}
];


ngOnDestroy(): void {
  this.destroy$.next(true);
  this.destroy$.unsubscribe();
}

cancelForm() {
  this.bsModalRef.hide();
  this.showEditPaymentChange.emit(false)
}

submit() {
  this.passpayments = Object.assign([], this.model.payments);
  const soID = this.salesOrder['SOID'];

  for (const value of this.passpayments) {
    value['foreignSOID'] = soID;
    delete value['btnProcess'];
    if (!value.hasOwnProperty('paymentID')) {
      value['paymentID'] = this.global.guid();
      value['status'] = 'insert'
    }
  }

  this.salesOrder['payments'] = this.passpayments;
  this.bsModalRef.hide();
  this.showEditPaymentChange.emit(false)
  // let data = {
  //   userId: '123',
  //   userName: this.authService.decodeToken('username').username,
  //   section: 'SO',
  //   action: 'Add Payment',
  //   eventId: '*',
  //   device: localStorage.getItem('device'),
  // }
  
  // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
  // .subscribe(a => {});
}


submitNotClosing(payments: any) {
  this.passpayments = Object.assign([], this.model.payments);
  const soID = this.salesOrder['SOID'];

  for (const value of this.passpayments) {
    value['foreignSOID'] = soID;
    delete value['btnProcess'];
    if (!value.hasOwnProperty('paymentID')) {
      value['paymentID'] = this.global.guid();
    }
  }

  this.salesOrder['payments'] = this.passpayments;
  this.cardReaderService.paymentSaved.next(true);
}


ngAfterContentChecked(): void {
  this.updateTotals();
}

ngDoCheck(): void {
  const changes = this.iterableDiffer.diff(this.model.payments);
  if (changes) {
      //this.model.payments[this.model.payments.length - 1]['paymentAmount'] = this.toBePayed;
      //this.model.payments = this.model.payments;
  }
}


getPayed() {
  return this.toBePayed;
}

updateTotals() {
  this.paymentTotal = 0 ;
  if (this.model.payments) {
    for (const i of this.model.payments) {
      if (i['paymentAmount'] !== 0) {
          this.paymentTotal += this.global.pNumber((i['paymentAmount']));
        }
    }
    if(this.salesOrder['Total']){
      if (this.salesOrder['Total'] !== undefined) {
        this.toBePayed = +this.global.pNumber((this.global.pNumber(this.salesOrder['Total']) - this.global.pNumber(this.paymentTotal)));
      }
    }

  }

}


showCardProcessed(model) {
  this.cardPayment = model;
  this.creditForm = true;
  this.cardPaymentProcessed = true;
  return;
}


showCard(model) {

// this.model.payments.push({});
// this.model = { ...this.model };
// this.options.resetModel({ ...this.model, ...this.model.payments });
this.cardPayment = model;
this.creditForm = true;
this.cardPaymentProcessed = false;
this.cardReaderService.cardSignatureAccept.next('');
this.cardReaderService.cardSignatureAccept
.pipe(takeUntil(this.destroy$))   
.subscribe(
  (res: any) => {
    if (res === 'Complete') {
    this.creditForm = false;
    }
  }, error => {
  this.alertify.error(error);
});
return;

const initialState = {
  sopayment: this.model.payments[0],
  amount: this.model.payments[0]['paymentAmount']
};
this.bsModalRef = this.modalService.show(CardpaymentComponent, {initialState, class: 'modal-lg'});


this.cardReaderService.soPayment
.pipe(take(1))
.subscribe(
  (res: any) => {
    if (res !== "") {
        //this.model.payments.push({});
        this.model = { ...this.model };
        //this.options.resetModel({ ...this.model, ...this.model.payments });
        if (this.cardReaderService.soPayment) {
          if (this.model.payments[this.model.payments.length - 1]['paymentID']
          === this.cardReaderService.soPayment['paymentID']) {
              const transresp = this.cardReaderService.transactionResponse.value;
              this.model.payments[this.model.payments.length - 1]['Note'] = 
              'Approval Code: ' + this.cardReaderService.cardAuthCode.value;
              this.model.payments[this.model.payments.length - 1]['paymentDetail'] = res;
              this.model.payments[this.model.payments.length - 1]['paymentDetail'] =
              'Credit Card Type: ';
              // + this.camelCase(transresp['account_type'].toString());
              this.model.payments[this.model.payments.length - 1]['paymentAmount'] = transresp['auth_amount'];
              this.model.payments[this.model.payments.length - 1]['paymentType'] = 'Credit Card';
              this.model.payments[this.model.payments.length - 1] = this.cardReaderService.soPayment;
              this.model = { ...this.model };
              //this.options.resetModel({ ...this.model, ...this.model.payments });
          }
      }
    }
  }, error => {
    this.alertify.error(error);
  });


}

camelCase(str) { 
  return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index)
  {
      return index == 0 ? word.toLowerCase() : word.toUpperCase();
  }).replace(/\s+/g, '');
}


}
