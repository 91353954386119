<div class="tw-flex tw-flex-col tw-space-y-5 tw-p-10">
    
    <header class="tw-flex tw-justify-between">
        <span class="title-1-orange">{{ 'COMMENTS' | translate }} {{customer?.firstName+" "+customer?.lastName}}</span>
    </header>


    <main>
        <div *ngIf="comments.length > 0">
            <div *ngFor="let co of comments" class="tw-flex tw-space-y-1 tw-space-x-2 tw-p-2 tw-border-2 tw-border-gray-300 tw-rounded-lg tw-mt-1">
                <div class="tw-flex tw-items-center">
                    <div style="background-color: #4db6ac;" 
                    class="tw-w-12 tw-h-12 tw-text-white tw-flex tw-rounded-full">
                     <span class="tw-m-auto tw-font-bold tw-text-lg">{{co.userId | uppercase }}</span> 
                    </div>
                </div>
                <div>
                    <div class="tw-flex tw-space-x-1 tw-items-baseline">
                        <span class="tw-text-gray-600 tw-text-md">{{co.user}}</span>
                        <span class="tw-text-gray-400 tw-text-xs">{{co.timestamp}}</span>
                    </div>
                    <span class="tw-pt-2">{{co.content}}</span>
                </div>
            </div>
        </div>
    </main>



    <footer class="tw-flex tw-w-full">
        <div class="tw-flex tw-space-x-2 tw-w-full">
            <input type="text" class="input-header tw-w-4/5" [(ngModel)]="comment">
            <button class="btnsmall-somobille tw-w-1/5" [ngClass]="{'btnsmall-somobille-blocked': comment == ''}" [disabled]="comment == ''" (click)="addComment()">Add comment</button>
        </div>
    </footer>
</div>