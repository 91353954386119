import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';
import { PaginatedResult } from '../_modules/pagination';
import { int } from '@zxing/library/esm/customTypings';



@Injectable({
  providedIn: 'root'
})
export class StockItemsService {
  laravel = environment.apiLaravel;
  storeSID = new BehaviorSubject<string>('');
  storeStyleID = new BehaviorSubject<string>('');
  addedStockItemSubject = new BehaviorSubject<any>({});

  constructor(private http: HttpClient) { }


  storeEvent: EventEmitter<string> = new EventEmitter();

  raiseStoreEvent(sid: string, styleid: string) {

    this.storeSID.next(sid);
    this.storeStyleID.next(styleid);
    this.storeEvent.next('');
  }


  getTotalStockItems() {
    let result = 0;
    return this.http.get<any>(this.laravel + 'stockitemstotal')
    .pipe(
      map( response => {
        result = (response);
        return result;
      })
    );
  } 
  
  codeToAdd(code) {
    return this.http.get<any>(this.laravel + 'codeToAdd/'+code)
  }

  getStockItems(page?, itemsPerPage?, searchMan?, searchType?, searchBrand?, searchProduct?, forExcel? ) {
    let params = new HttpParams();
    const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();

    if ( page != null && itemsPerPage != null) {
      params = params.append('page', page);
      params = params.append('per_page', itemsPerPage);
    }

    if (searchMan != null) {
      params = params.append('searchMan', searchMan);
    }

    if (searchType != null) {
      params = params.append('searchType', searchType);
    }

    if (searchBrand != null) {
      params = params.append('searchBrand', searchBrand);
    }

    if (searchProduct != null) {
      params = params.append('searchProduct', searchProduct);
    }

    if (forExcel != null) {
      params = params.append('forExcel', forExcel);
    }

    if (forExcel == null) {
        return this.http.get<any>(this.laravel + 'stockitems', {observe: 'response', params})
        .pipe(
          map( response => {
            paginatedResult.result = (response.body);
            if (response.body.total != null) {
              paginatedResult.pagination = {
                totalItems: JSON.parse(response.body.total),
                totalPages: JSON.parse(response.body.last_page),
                currentPage: JSON.parse(response.body.current_page),
                itemsPerPage: JSON.parse(response.body.per_page)
              };
            }
            return paginatedResult;
          })
        );
      } else {
        return this.http.get<any>(this.laravel + 'stockitems', {observe: 'response', params})
        .pipe(
          map( response => {
            paginatedResult.result = (response.body);
            return paginatedResult;
          })
        );
      }
    }


    updateStockItems(stockItem: any) {
      return this.http.patch(this.laravel + 'stockitemsUpdate', stockItem);
    }


    addStockItems( stockItem: any) {
      return this.http.post(this.laravel + 'stockitems' ,stockItem);
    }
    getSearchProd( stockItem: any) {
      return this.http.post(this.laravel + 'getSearchProd' ,stockItem);
    }
    getSearchProdScanner( data: any) {
      return this.http.post(this.laravel + 'getProductScanner',data);
    }
    getFiltersProd() {
      return this.http.get(this.laravel + 'getFiltersProd');
    }


    deleteStockItem(id: string, searchMan?, searchType?, searchBrand?, searchProduct?) {
      let params = new HttpParams();
      if (searchMan != null) {
        params = params.append('searchMan', searchMan);
      }

      if (searchType!= null) {
        params = params.append('searchType', searchType);
      }

      if (searchBrand != null) {
        params = params.append('searchBrand', searchBrand);
      }

      if (searchProduct != null) {
        params = params.append('searchProduct', searchProduct);
      }

      return this.http.delete(this.laravel + 'stockitems/' + id, {observe: 'response', params});
    }

    removeStockItem(id: string, stockItem: any) {
      return this.http.post(this.laravel + 'stockitemsremove', stockItem);
    }



    deleteFoundStockItems(id: string, search: string) {
      let params = new HttpParams();
      if (search != null) {
        params = params.append('search', search);
      }
      return this.http.delete(this.laravel + 'stockitems', {observe: 'response', params});
    }

    getDataSelect(){
      return this.http.get(this.laravel + 'selectType');
    }

    getTypes(){
      return this.http.get(this.laravel + 'getTypes');
    }

    getDates(barcode:any, retail?) {
      let params = new HttpParams()
        .set('barcode', barcode);

      if (retail != null) {
        params = params.append('retail', retail);
      }

      return this.http.get(this.laravel + 'getDates' , { params })
    }

    getProductByDate(date: string, barcode: string, id:string) {
      const params = new HttpParams()
        .set('date', date)
        .set('barcode', barcode)
        .set('id', id);
  
      return this.http.get(this.laravel + 'getProductByDate', { params });
    }

    getExpiringItems(option: string) {
      const params = new HttpParams().set('option', option)
  
      return this.http.get(this.laravel + 'getExpiringItems' , {params})
    }

    getRetailItems(page?, itemsPerPage?, searchMan?, searchType?, searchBrand?, searchProduct?, forExcel? ) {
      let params = new HttpParams();
      const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();
  
      if ( page != null && itemsPerPage != null) {
        params = params.append('page', page);
        params = params.append('per_page', itemsPerPage);
      }
  
      if (searchMan != null) {
        params = params.append('searchMan', searchMan);
      }
  
      if (searchType != null) {
        params = params.append('searchType', searchType);
      }
  
      if (searchBrand != null) {
        params = params.append('searchBrand', searchBrand);
      }
  
      if (searchProduct != null) {
        params = params.append('searchProduct', searchProduct);
      }
  
      if (forExcel != null) {
        params = params.append('forExcel', forExcel);
      }
  
      if (forExcel == null) {
        return this.http.get<any>(this.laravel + 'retailIndex', {observe: 'response', params})
        .pipe(
          map( response => {
            paginatedResult.result = (response.body);
            if (response.body.total != null) {
              paginatedResult.pagination = {
                totalItems: JSON.parse(response.body.total),
                totalPages: JSON.parse(response.body.last_page),
                currentPage: JSON.parse(response.body.current_page),
                itemsPerPage: JSON.parse(response.body.per_page)
              };
            }
            return paginatedResult;
          })
        );
      } else {
        return this.http.get<any>(this.laravel + 'retailIndex', {observe: 'response', params})
        .pipe(
          map( response => {
            paginatedResult.result = (response.body);
            return paginatedResult;
          })
        );
      }
    }
  deleteFromWarehouse(product:any) {
    return this.http.patch(this.laravel + 'deleteFromWarehouse', product);
  }

  getCMSItems(page?, itemsPerPage?, searchMan?, searchType?, searchBrand?, searchProduct?, indexes? ) {
    let params = new HttpParams();
    const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();

    if ( page != null && itemsPerPage != null) {
      params = params.append('page', page);
      params = params.append('per_page', itemsPerPage);
    }

    if (searchMan != null) {
      params = params.append('searchMan', searchMan);
    }

    if (searchType != null) {
      params = params.append('searchType', searchType);
    }

    if (searchBrand != null) {
      params = params.append('searchBrand', searchBrand);
    }

    if (searchProduct != null) {
      params = params.append('searchProduct', searchProduct);
    }

    if (indexes != null) {
      params = params.append('indexes', indexes);
    }

    
    // if (forExcel == null) {
      return this.http.get<any>(this.laravel + 'cmsProducts', {params})
  //     .pipe(
  //       map( response => {
  //         paginatedResult.result = (response.body);
  //         if (response.body.total != null) {
  //           paginatedResult.pagination = {
  //             totalItems: JSON.parse(response.body.total),
  //             totalPages: JSON.parse(response.body.last_page),
  //             currentPage: JSON.parse(response.body.current_page),
  //             itemsPerPage: JSON.parse(response.body.per_page)
  //           };
  //         }
  //         return paginatedResult;
  //       })
  //     );
  //   } else {
  //     return this.http.get<any>(this.laravel + 'cmsProducts', {observe: 'response', params})
  //     .pipe(
  //       map( response => {
  //         paginatedResult.result = (response.body);
  //         return paginatedResult;
  //       })
  //     );
  //   }
  }

  getHistoryLists(data){
    return this.http.post(this.laravel + 'getHistoryLists', data);
  }  
  getHistoryListsItems(data){
    return this.http.post(this.laravel + 'getHistoryListsItems', data);
  }

  deleteFromRetail(product:any) {
    return this.http.patch(this.laravel + 'deleteFromRetail', product)
  }

  sentToretail(data:any) {
    return this.http.patch(this.laravel + 'sentToretail', data);
  }

  verifyItemsQty(barcode: string) {
    return this.http.get(this.laravel + 'verifyItemsQty/' + barcode);
  }

  printProductsBarcode() {
    return this.http.get(this.laravel + 'printProductsBarcode');
  }

  getAddresses(data:any){
    return this.http.post(this.laravel + 'getAddresses',data);
  }

  saveList(data:any){
    return this.http.post(this.laravel + 'saveList',data);
  }

  savePO(data:any){
    return this.http.post(this.laravel + 'savePO',data);
  }
  
  getProductsFromUniqueProducts( stockItem: any) {
    return this.http.get(this.laravel + 'getProductsFromUniqueProducts/' + stockItem);
  }

  retailPrices( productCode: any, price:number) {
    const params = new HttpParams()
        .set('productCode', productCode)
        .set('price', price);
    return this.http.get(this.laravel + 'retailPrices' ,{ params });
  }

}