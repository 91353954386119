import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDate'
})
export class FormatDatePipe implements PipeTransform {

  transform(value: Date): string {
    if (!value) return '';
    const options: Intl.DateTimeFormatOptions = { 
      weekday: 'short', 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit' 
    };
    const str = value.toLocaleDateString('en-US', options);
    const withoutTimeZone = str.substring(0, str.lastIndexOf(" ") + 1).trim();
    return withoutTimeZone;
  }

}
