import { Component, OnInit, ViewChild, OnDestroy, AfterViewInit, ComponentRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { StoreUsersService } from 'src/app/_services/storeusers.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { HttpClient } from '@angular/common/http';
import { GlobalsService } from 'src/app/_services/globals.service';
import 'rxjs/add/operator/takeUntil';
import { Observable, of, Subject } from 'rxjs';
import { AuthService } from 'src/app/_services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { NrnValidators } from 'src/app/nrn.validators';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-storeusersform',
	templateUrl: './storeusersform.component.html',
	styleUrls: ['./storeusersform.component.scss'],
})
export class StoreusersformComponent implements OnInit, AfterViewInit, OnDestroy {
	constructor(
		public bsModalRef: BsModalRef,
		private modalService: BsModalService,
		private userService: StoreUsersService,
		private alertify: AlertifyService,
		private authService: AuthService,
		private http: HttpClient,
		private globals: GlobalsService,
		private translate: TranslateService, 
	) {}

	destroy$: Subject<boolean> = new Subject<boolean>();
	form = new FormGroup({});
	user: any = [];
	users: any = [];
	model: any = {};
	viewpassword = false;
	cloverState!: boolean;
	userRole = '';
	atcfs = '';
	dbServer = this.authService.decodeToken('dbServer').dbServer;
	cmpRef: ComponentRef<Component>;

	locations: any = [];
	allLocations = new FormControl('0');

	changeLocations() {}

	locationChange(e, loc) {
		loc.checked = e.target.checked;
	}

	optionsRoles = [
		{ label: this.translate.instant('SELECT'), value: "" },
		{ label: this.translate.instant('ADMIN'), value: 'Admin' },
		{ label: this.translate.instant('INVENTORY_MANAGER'), value: 'Inventory manager' },
		{ label: this.translate.instant('CASHIER'), value: 'Cashier' },
	];

	options: FormlyFormOptions = {};
	alertMessage = '';
	fields: FormlyFieldConfig[] = [
		{
			wrappers: ['formwrap'],
			templateOptions: { label: '' },
			fieldGroupClassName: 'row',
			fieldGroup: [
				{
					key: 'name',
					type: 'input',
					className: 'col-md-12 tw-font-bold',
					modelOptions: {
						updateOn: 'change',
					},
					templateOptions: {
						type: 'text',
						label: this.translate.instant('FIRST_NAME'),
						placeholder: this.translate.instant('FIRST_NAME'),
						required: true,            
						maxLength: 100,
						minLength: 2,            
						pattern: NrnValidators.FORMATS.name
					},
					validation: {
						show: true,
						messages: {
						required: (error) => this.translate.instant('FIRST_NAME_REQUIRED'),
						minlength: (error) => this.translate.instant('FIRST_NAME_MINLENGTH'),
						maxlength: (error) => this.translate.instant('FIRST_NAME_MAXLENGTH'),
						pattern: (error, field: FormlyFieldConfig) => this.translate.instant('FIRST_NAME_INVALID'),
						}
					},

					lifecycle: {
						onInit: (form, field, model, options) => {
							if (this.model['uuid']) {
								field.formControl.valueChanges.subscribe(() => {
									var name = field.formControl.value;
									if (name != null) {
										const splittedname = name.split(' ');
										let suggested = splittedname[0].charAt(0) + splittedname[splittedname.length - 1];
										if (splittedname.length === 1) {
											suggested = splittedname[0];
										}
										this.form.controls['username'].setValue(suggested.toLowerCase());
									}
								});
							}
							if (!this.model['uuid']) {
								field.formControl.valueChanges.subscribe(() => {
									var name = field.formControl.value;
									if (name != null) {
										const splittedname = name.split(' ');
										let suggested = splittedname[0].charAt(0) + splittedname[splittedname.length - 1];
										if (splittedname.length === 1) {
											suggested = splittedname[0];
										}
										this.form.controls['username'].setValue(suggested.toLowerCase());
									}
								});
							}
						},
					},
				},
				{
					key: 'username',
					type: 'input',
					className: 'col-md-12  tw-font-bold',
					templateOptions: {
						type: 'text',
						label: this.translate.instant('USERNAME'),
						placeholder: this.translate.instant('USERNAME'),
						required: true,
						addonRight: {
							text: 'aaa',
							id: 'rappendid',
						},
					},
					validation: {
						show: true,
						messages: {
							required: (error) => this.translate.instant('USERNAME_REQUIRED'),
							uniqueUsername: (error, field: FormlyFieldConfig) => {
								return field.templateOptions.errorMsg || this.translate.instant('USERNAME_TAKEN')
							},
						},
					},

					lifecycle: {
						onInit: (form, field) => {
							field.formControl.valueChanges.subscribe(() => {
								this.users = this.users.filter((x) => x['uuid'] !== this.model['uuid']);
								let ufound = this.users.filter((x) => x['username'] === field.formControl.value);
								if (ufound.length > 0) {
									field.formControl.setErrors({ uniqueUsername: true });
									this.alertMessage = this.translate.instant('USERNAME_TAKEN')
								} else {
									field.formControl.setErrors(null);
									this.alertMessage = '';
								}
							});
						},
					},
				},

				{
					key: 'password',
					type: 'input',
					className: 'col-md-12  tw-font-bold',
					hideExpression: (model) => this.viewpassword,
					templateOptions: {
						type: 'password',
						label: this.translate.instant('PASSWORD'),
						placeholder: this.translate.instant('PASSWORD'),
						required: true,
						min: 4,
						addonRight: {
							text: 'aaa',
							id: 'pappendid',
						},
					},
					validation: {
						show: true,
						messages: {
							required: (error) => this.translate.instant('PASSWORD_REQUIRED'),
							min: (error, field: FormlyFieldConfig) => this.translate.instant('PASSWORD_MINLENGTH'),
						},
					},		
				},
				{
				  key: 'password',
				  type: 'input',
				  className: 'col-md-12  tw-font-bold',
				  hideExpression: (model) => !this.viewpassword,
				  templateOptions: {
				    label: this.translate.instant('PASSWORD'),
				    required: true,
				    addonRight: {
				      text:  'aaa',
				      id: 'pappendid2'
				    }
				  },
				},
				{
					key: 'phone',
					type: 'phone',
					id: 'phone',
					className: 'col-md-12  tw-font-bold',
					templateOptions: {
						type: 'phone',
						required: true, 
						label: this.translate.instant('PHONE'),
						placeholder: this.translate.instant('PHONE'),
						pattern: NrnValidators.FORMATS.maskphone,
					},		
					validation: {
						show: true,
						messages: {
						required: (error) => this.translate.instant('PHONE_REQUIRED'),
						pattern: (error) => this.translate.instant('PHONE_INVALID'),
						},
					},
				},
				{
					key: 'email',
					type: 'input',
					id: 'email',
					className: 'col-md-12   tw-font-bold',
					templateOptions: {
						type: 'text',
						label: this.translate.instant('EMAIL'),
						placeholder: this.translate.instant('EMAIL'),
						maxLength: 320,
						minLength: 10,
						required: true
					},
					validation: {
						show: true,
						messages: {
							required: (error) => this.translate.instant('EMAIL_REQUIRED'),
							minlength: (error) => this.translate.instant('EMAIL_MINLENGTH'),
							pattern: (error, field: FormlyFieldConfig) => this.translate.instant('EMAIL_INVALID'),
						},
					},
				},
				{
					key: 'role',
					type: 'select',
					className: 'col-md-12   tw-font-bold',
					templateOptions: {
						type: 'select',
						label: this.translate.instant('ROLE'),
						required: true,
						options: [],
						//this.getRoles(),
						valueProp: 'label',
						labelProp: 'value',
					},
					lifecycle: {
						onInit: (form, field) => {
							// this.userRole = localStorage.getItem('role').replace('"', '');
							this.userRole = this.authService.decodeToken('role').role;

							if (this.userRole === 'Admin') {
								this.optionsRoles = [
									{ label: this.translate.instant('SELECT'), value: "Select..." },
									{ label: this.translate.instant('INVENTORY_MANAGER'), value: 'Inventory manager' },
									{ label: this.translate.instant('CASHIER'), value: 'Cashier' },
								];
							}
							field.templateOptions.options = this.optionsRoles;
						},
					},
					validation: {
						messages: {
							required: (error) => this.translate.instant('ROLE_REQUIRED'),
						}
					},
				},

				{
					key: 'active',
					type: 'checkbox',
					className: 'col-md-6  tw-font-bold',
					templateOptions: {
						label: this.translate.instant('ACTIVE'),
						placeholder: '',
						indeterminate: false,
					},
					validation: {
						//show: true,
					},
				},

				{
				  key: 'activeClover',
				  type: 'checkbox',
				  className: 'col-md-6  tw-font-bold',
				  hideExpression: (model)=>{
				    let accept = this.cloverState == false || (model.role == 'Sales Person'|| model.role == 'Report Viewer')
				    if(accept) model.activeClover = 0;
				    return accept
				  },
				  templateOptions: {
				    label: this.translate.instant('USE_CLOVER'),
				    placeholder: '',
				  }
				}
			],
		},
	]

	

	ngOnInit() {

		this.locations.push({
			"dbServer":this.authService.decodeToken('dbServer').dbServer,
      		"locationName":JSON.parse(this.authService.decodeToken('user').user).locationName,
      		"checked":true
		});

		this.authService
			.getLocations({'dbServerC':this.authService.decodeToken('dbServerC').dbServerC})
			.toPromise()
			.then((data: any[]) => {
				//console.log(data);
				if (data.length > 0) {
					if (this.user != '') {
						let jsonloc = this.user.locations;
						if (jsonloc != null) {
							this.allLocations.setValue(this.user.allLocations || '0');
							data.forEach((element) => {
								if (jsonloc.includes(element.dbServer) || element.dbServer == this.dbServer) {
									element['checked'] = true;
								} else {
									element['checked'] = false;
								}
							});
							this.locations = data;
						} else {
							data.forEach((element) => {
								if (element.dbServer == this.dbServer) {
									element['checked'] = true;
								} else {
									element['checked'] = false;
								}
							});
							this.locations = data;
						}
					} else {
						data.forEach((element) => {
							if (element.dbServer == this.dbServer) {
								element['checked'] = true;
							} else {
								element['checked'] = false;
							}
						});
						this.locations = data;
					}
				}
			})
			.catch((err) => {
				// console.log(err)
			});

		// console.log(this.locations)
		if(Object.keys(this.user).length == 0){
			this.user.role = ''
		}
		console.log(this.user);
		
		this.model = Object.assign({}, this.user);
		// this.userRole = localStorage.getItem('role').replace('"', '');
		this.userRole = this.authService.decodeToken('role').role;
		this.atcfs = '@' + this.authService.decodeToken('cfsat').cfsat;
		this.model['atCfs_tx'] = this.atcfs;

		if (!this.model['uuid']) {
			this.model['active'] = true;
		}
		this.model.activeClover == '0' ? this.model.activeClover = 0 : this.model.activeClover = 1
	}

	ngAfterViewInit(): void {
		document.getElementsByClassName('input-group-append')[0].innerHTML =
			'<span class="input-group-text smallerf" style="font-size: small;">' + this.atcfs + '</span>';
		document.getElementsByClassName('input-group-append')[1].innerHTML = '';
		document.getElementsByClassName('input-group-append')[2].innerHTML = '';
		if (this.model['uuid']) {
			let button =
				'<button style="position: absolute; margin-left: -2.5rem;display: flex;height: 100%;" type="button" id="viewpassbutton">' +
				'<svg  class="tw-my-auto" xmlns="http://www.w3.org/2000/svg" width="33.177" height="22.258" viewBox="0 0 33.177 22.258">' +
				'<defs> <style>.cls-1 {fill: none;stroke-width: 1.7px;} .cls-1, .cls-2 {stroke: #00adee;} .cls-2 {fill: #00adee;}</style></defs>' +
				'<g id="eye-close-up" transform="translate(0.858 -160.675)">' +
				'<path id="Path_126" data-name="Path 126" class="cls-1" d="M15.731,161.525A17.272,17.272,0,0,0,.063,171.519a.674.674,0,0,0,0,.57,17.278,17.278,0,0,0,31.334,0,.674.674,0,0,0,0-.57A17.272,17.272,0,0,0,15.731,161.525Zm0,17.372a7.091,7.091,0,1,1,7.091-7.091A7.09,7.09,0,0,1,15.731,178.9Z" transform="translate(0 0)"/>' +
				'<circle id="Ellipse_1" data-name="Ellipse 1" class="cls-2" cx="4.54" cy="4.54" r="4.54" transform="translate(11.191 167.263)"/>' +
				'</g>' +
				'</svg>' +
				'</button>';
			let button2 =
				'<button style="position: absolute; margin-left: -2.5rem;display: flex;height: 100%;" type="button" id="viewpassbutton2">' +
				'<svg class="tw-my-auto"  xmlns="http://www.w3.org/2000/svg" width="33.177" height="22.258" viewBox="0 0 33.177 22.258">' +
				'<defs> <style>.cls-1 {fill: none;stroke-width: 1.7px;} .cls-1, .cls-2 {stroke: #00adee;} .cls-2 {fill: #00adee;}</style></defs>' +
				'<g id="eye-close-up" transform="translate(0.858 -160.675)">' +
				'<path id="Path_126" data-name="Path 126" class="cls-1" d="M15.731,161.525A17.272,17.272,0,0,0,.063,171.519a.674.674,0,0,0,0,.57,17.278,17.278,0,0,0,31.334,0,.674.674,0,0,0,0-.57A17.272,17.272,0,0,0,15.731,161.525Zm0,17.372a7.091,7.091,0,1,1,7.091-7.091A7.09,7.09,0,0,1,15.731,178.9Z" transform="translate(0 0)"/>' +
				'<circle id="Ellipse_1" data-name="Ellipse 1" class="cls-2" cx="4.54" cy="4.54" r="4.54" transform="translate(11.191 167.263)"/>' +
				'</g>' +
				'</svg>' +
				'</button>';

			document.getElementsByClassName('input-group-append')[1].innerHTML = button;
			document.getElementsByClassName('input-group-append')[2].innerHTML = button2;
			document.getElementById('viewpassbutton').addEventListener('click', () => {
				this.togglev();
			});

			document.getElementById('viewpassbutton2').addEventListener('click', () => {
				this.togglev();
			});
		}
	}

	togglev() {
		this.viewpassword = !this.viewpassword;
	}

	getRoles(): Observable<any[]> {
		return of(this.optionsRoles);
	}

	cancelForm() {
		this.bsModalRef.hide();
	}

	submit(model) {
		if (model['active'] === false) {
			model['active'] = '';
		}
		delete model['atCfs_tx'];

		if (model['uuid']) {
			let arrayloc = [];
			this.locations.forEach((element) => {
				if (element.checked) {
					arrayloc.push(element.dbServer);
				}
			});

			model['allLocations'] = this.allLocations.value;
			model['locations'] = arrayloc.toString();
			this.userService
				.updateStoreUser(model['uuid'], model)
				.pipe(takeUntil(this.destroy$))
				.subscribe(
					() => {
						this.alertify.success(this.translate.instant('USER_UPDATED'));
						this.userService.confirmEditedUser(model);
						this.bsModalRef.hide();
						this.ngOnDestroy();
					},
					(error) => {
						this.alertify.error(error);
					}
				);
		} else {
			model['uuid'] = this.globals.guid();

			let arrayloc = [];
			this.locations.forEach((element) => {
				if (element.checked) {
					arrayloc.push(element.dbServer);
				}
			});

			model['allLocations'] = this.allLocations.value;
			model['locations'] = arrayloc.toString();

			this.userService
				.addStoreUser(model)
				.pipe(takeUntil(this.destroy$))
				.subscribe(
					() => {
						this.alertify.success(this.translate.instant('USER_CREATED'));
						this.userService.confirmEditedUser(model);
						this.bsModalRef.hide();
						this.ngOnDestroy();
					},
					(error) => {
						this.alertify.error(error);
					}
				);
		}

		this.user = Object.assign({}, model);
	}
	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}
}
