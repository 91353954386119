<nav class="main-navbar">
  <div class="main-navbar-info-page">
    <div class="tw-text-2xl sm:tw-text-lg md:tw-text-3xl lg:tw-text-3xl tittle-0">{{ 'REPORTS' | translate }}</div>
  </div>
</nav>

<div class="nav-display-options align-items-center d-block">




  <div class="nav-display-options-type-view mt-1">

    <div class="nav-display-options-type-view-text d-flex align-items-center">
      <p class="m-0 mr-1">{{ 'FROM' | translate }}</p>
      <input
        type="text"
        class="form-control d-inline"
        #dp="bsDatepicker"
        bsDatepicker
        [bsValue]="bsValueFrom"
        [(ngModel)]="bsValueFrom"
      />
    </div>
    <div class="nav-display-options-type-view-text d-flex align-items-center">
      <p class="m-0 mr-1">{{ 'TO' | translate }}</p>
      <input
        type="text"
        class="form-control d-inline"
        #dp="bsDatepicker"
        bsDatepicker
        [bsValue]="bsValueTo"
        [(ngModel)]="bsValueTo"
      />
    </div>

    <div
      class="nav-display-options-filter-buttons-view-buttons bg-grey"
      (click)="changeDates()"
      btnRadioGroup
      [(ngModel)]="dateFilter"
    >
      <button class="btn btn-4 mr-1" btnRadio="year" tabindex="0" role="button">
        YTOD
      </button>
      <button class="btn btn-4 mr-1" btnRadio="q1" tabindex="0" role="button">
        Q1
      </button>
      <button class="btn btn-4 mr-1" btnRadio="q2" tabindex="0" role="button">
        Q2
      </button>
      <button class="btn btn-4 mr-1" btnRadio="q3" tabindex="0" role="button">
        Q3
      </button>
      <button class="btn btn-4 mr-1" btnRadio="q4" tabindex="0" role="button">
        Q4
      </button>
    </div>
  </div>
</div>

  <div class="col-md-10">
    <app-salesreport
      *ngIf="showReport.length > 0"
      [dateFrom]="bsValueFrom"
      [dateTo]="bsValueTo"
      [event]="selectedEvent"
      [showReport]="showReport"
      [showClose]="false"
    ></app-salesreport>
  </div>




<div>
  <div class="report-item-title  mt-4" style="font-size: 1.8rem;">
    {{ 'GENERAL_REPORTS' | translate }}
  </div>
  <div class="tw-flex flex-wrap">

    <div  class="tw-m-2 tw-bg-white shadow tw-w-80" style="border-radius: 2.75rem;" >
      <div style="min-height: 28rem;">
        <div class="tw-w-full">
          <img class="w-full " src="assets/Group139.png" style=" display: block;margin-left: auto;margin-right: auto; height: 200px; width: 150px; padding: 50px 40px 50px 40px;">
          <div class="tw-px-4 tw-pb-8 tw-cursor-pointer tw-text-base tw-text-aedgraydark">
            <div (click)="loadReport('SalesReport')" class="tw-py-1 tw-px-2 tw-my-1 tw-rounded-xl hover:tw-bg-aedblue hover:tw-text-white">Orders Report</div>
            <hr class="tw-mx-2">
          </div>
        </div>
      </div>
    </div>

  </div>
</div>