import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-salesorder-alert',
  templateUrl: './salesorder-alert.component.html',
  styleUrls: ['./salesorder-alert.component.scss']
})
export class SalesorderAlertComponent implements OnInit {
  @Input() alert = true;
  constructor() { }

  ngOnInit() {
  }

  closeAlert(avalue) {
     if (this.alert !== avalue) {
      this.alert = !this.alert;
     }
  }

}
