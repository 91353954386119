import { CdkDragDrop, CdkDragEnd, CdkDragPlaceholder,  CdkDragEnter, CdkDragMove, CdkDragStart, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { AfterContentInit, AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { EventsService } from 'src/app/_services/events.service';
import { SalesOrderItemsService } from 'src/app/_services/salesorderitems.service';
import * as Hammer from 'hammerjs'
import { trigger, keyframes, animate, transition } from '@angular/animations';
import * as kf from './keyframes';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { SizesIndex } from 'src/app/_resolvers/SizeSyno';
import { StoreproductsService } from 'src/app/_services/storeproducts.service';
import { UserService } from 'src/app/_services/user.service';
import { AuthService } from 'src/app/_services/auth.service';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { SettingsService } from 'src/app/_services/settings.service';
import { StoreProductsReportComponent } from 'src/app/reports/store-products-report/store-products-report.component';
import { Router } from '@angular/router';
import { pdf, exportPDF } from "@progress/kendo-drawing";
import { saveAs } from "@progress/kendo-file-saver";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-floor-items',
  templateUrl: './floor-items.component.html',
  styleUrls: ['./floor-items.component.scss'],
  animations: [
    trigger('cardAnimator', [
      transition('* => wobble', animate(1000, keyframes(kf.wobble))),
      transition('* => swing', animate(1000, keyframes(kf.swing))),
      transition('* => jello', animate(1000, keyframes(kf.jello))),
      transition('* => zoomOutRight', animate(1000, keyframes(kf.zoomOutRight))),
      transition('* => slideOutLeft', animate(1000, keyframes(kf.slideOutLeft))),
      transition('* => rotateOutUpRight', animate(1000, keyframes(kf.rotateOutUpRight))),
      transition('* => flipOutY', animate(1000, keyframes(kf.flipOutY))),
    ])
  ]
})
export class FloorItemsComponent implements OnInit, AfterViewInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  showItems = false
  showFurni = false
  showItemsModaL = false
  @Input() eventid : any 
  @Input() event : any
  @Input() from : string
  @Input() fromDefaults : boolean = false
  @Input() fromcomponet = '';
  animationState: string;
  selectAll = false
  beingmoved = false
  tookY: boolean = false
  tookX: boolean = false
  parallelX : number = 0
  parallelY : number = 0
  freeItemX : number = 0
  freeItemY : number = 0
  freeItemHeight : number = 0
  freeItemWidth : number = 0
  currentDragging : number = 0
  currentPositions: any = []
  itemModifying : any
  lessPercentage = 1
  productsSelect:any = [];  
  recordProductsToQRC:any = [];  
  products:any;
  canSelectAnEvent = false
  printing = false
  heightCanvas = 600;
  widthCanvas = 700;
  companiesandsizes: any;
  companies: any;
  cursorY: any;
  cursorX: any;
  styles: any;
  sizes: any;
  userRole: any;
  @ViewChild('popTemplateiOs', {static: false}) popTemplateiOs: ElementRef;
  // @ViewChild('dragContainer') dragContainer: ElementRef;
  @ViewChild("dragContainer") dragContainer: ElementRef;
  modalRef: BsModalRef[]=[];
  showMoreSizes = ''
  constructor(
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private itemsService: SalesOrderItemsService,
    private eventService: EventsService,
    private storeProductsService: StoreproductsService,
    private alertify: AlertifyService,
    private router: Router,
    private authService: AuthService,
    private settingsService: SettingsService,
		private translate: TranslateService
  ) { 
  }
  itemsFloor = []
  furnitures = [
    {name: 'Shelf', urlImage : 'assets/shelf.png', height: 60, width : 50},
    {name: 'Table', urlImage : 'assets/table.png',  height: 30, width : 50},
    {name: 'Bean Bag', urlImage : 'assets/bean.png',  height: 50, width : 50},
    {name: 'Massage Chair', urlImage : 'assets/massage.png', height: 50, width : 50},
    {name: 'Desk', urlImage : 'assets/admin.png', height: 50, width : 50},
    {name: 'Twin', urlImage : 'assets/twin.png', height: 70, width : 50},
    {name: 'Full', urlImage : 'assets/full.png', height: 70, width : 65},
    {name: 'Queen', urlImage : 'assets/full.png', height: 70, width : 70},
    {name: 'King', urlImage : 'assets/king.png', height: 70, width : 75},
  ]

  background = 'assets/basketball.jpg'
  backgrounds = [
    {name: 'Basketball', urlImage : 'assets/basketball.jpg'},
    {name: 'Tenis', urlImage : 'assets/tenis.jpg'},
    {name: 'Soccer', urlImage : 'assets/soccer.jpg'},
    {name: 'Football', urlImage : 'assets/football.jpg'},
  ]

  ngAfterViewInit(): void {
    // console.log('viewInit', this.dragContainer.nativeElement.getBoundingClientRect())
    this.onResize(this.dragContainer.nativeElement.getBoundingClientRect())

    // var elm = document.getElementById("maindiv");
    // elm.addEventListener('mousemove',this.getcoord)    
  }

  getcoord(ev){
    var bnds = ev.target.getBoundingClientRect();
   this.cursorX = ev.clientX - bnds.left;
   this.cursorY = ev.clientY - bnds.top;

    return [Math.round(ev.clientX - bnds.left), Math.round(ev.clientY - bnds.top)];
     
  }

  drop(event: CdkDragDrop<string[]>, items) {
    moveItemInArray(items, event.previousIndex, event.currentIndex);
  }

  savePDF(pdf: any) {
    let oldwidth = this.widthCanvas;
    let oldheight = this.heightCanvas;
    this.printing = true;
    // this.widthCanvas = 563;
    // this.heightCanvas = 750;
    setTimeout(() => {
      // this.onResize(this.dragContainer.nativeElement.getBoundingClientRect())
      this.onResizeHeight(this.dragContainer.nativeElement.getBoundingClientRect())
    }, 50);
    setTimeout(() => {
       // enable/show the loader
      pdf.export().then(group => {
        const options = <pdf.PDFOptions>{ paperSize: "Letter" };
        exportPDF(group, options).then(data => {
          let today = new Date();
          let yeah = today.toLocaleString().split(',');
          saveAs(data, "floor-item-"+yeah[0].replace('/', '-')+".pdf");
          this.printing = false;
          this.widthCanvas = oldwidth;
          this.heightCanvas = oldheight;
          setTimeout(() => {
            this.onResize(this.dragContainer.nativeElement.getBoundingClientRect())
          }, 50);
        });
      });
    }, 1000);
   
  }

  model = {
    deliveryinfo: []
  };
  form = new FormGroup({});
  options: FormlyFormOptions = {};
  //fields: FormlyFieldConfig[] = [{}];
  globalsizes = [];





  selectAllProducts(items:any){
    this.selectAll = true

    // console.log(items)
      items.forEach(item => {
        this.select(item)
      });
      this.selectAll = false
  }

  openModal(template: TemplateRef<any>) {
    let size = 'modal-lg'
    if(localStorage.getItem('device') == 'iOS' || localStorage.getItem('device') == 'Android' || localStorage.getItem('device') == 'unknown'){
      size = 'modal-xl'
    }
    this.modalRef.push(this.modalService.show(template, { class: size}));
  }
doubleTap(template: TemplateRef<any>, item:any){
    this.itemModifying = item
   // this.model.deliveryinfo = this.itemModifying.delivery
    this.openModal(template)
  }

  addFurnite(furn:any){
    this.itemsFloor.push({id: Math.floor(Math.random()*90000) + 10000 , name: furn.name ,title : '', image : furn.urlImage, position: {x: 0, y: 0}, delivery : [] , items:[], style:[{rotate : 0, height : furn.height, width : furn.width}]})
  }
  

  startAnimation(state) {
    if (!this.animationState) {
      this.animationState = state;
    }
  }
  saveGrouping(){

  }

  removeItem(item, index){
    this.alertify.confirm(this.translate.instant('DELETE_ITEM_CONFIRMATION'), ()=>{ 
    this.itemsFloor.splice(index, 1)
    this.showItemsModaL = false
    this.modalRef.forEach(modal => modal.hide());
    this.bsModalRef.hide();
    }
    ,()=>{
    })
  }
  removeOneItem(items, index){    
      items.splice(index, 1)
  }
  resetAnimationState() {
    this.animationState = '';
  }

  delete(){
    this.startAnimation('wobble')
  }

  rotate(item){
    //  console.log(item.style)
    if(item?.style[0]?.rotate == null || item?.style[0]?.rotate == undefined){
      item.style.push({rotation : 45})
    }else{
      var rota = item?.style[0]?.rotate
      if(item?.style[0]?.rotate < 360){
        item.style[0].rotate = rota + 45;
      }else{
        item.style[0].rotate = 0
      }

    }
    
  }

  ExportToQRPDF(){
    // console.log(this.itemsFloor)
    this.itemsFloor.forEach((container)=>{
      container?.items.forEach(product => {
        if(product != null && product != undefined){
          this.recordProductsToQRC.push(product)
        }
      });
    })
    const initialState = {
      showReport: 'QRC_products',
      reportdata: this.recordProductsToQRC
    };
    this.bsModalRef = this.modalService.show(StoreProductsReportComponent, {initialState, class: 'modal-xl modal-report'});
  }


  select(item) {
    // console.log(item)
    if(this.from == 'so'){

      this.storeProductsService.validateStoreItem(item.supItemID)
      .toPromise()
      .then((data:any)=>{
      if(data.length == 0){
        this.alertify.error("This product has been removed from DDS.")
        return;
      }
      //verification if is active in Customers
      if(data[0].inactive == null || data[0].inactive == "" ){
        //verification if is active in Location
        if(data[0].active == 1 ){
  
          //updateJson of DB, Customers and Location
          // console.log(data[0].salesPriceNum)
          item.itemDelivery = '';
          // item.salesPriceNum = data[0].salesPriceNum
          // item.priceNum = data[0].salesPriceNum
          item.wholeSalePriceNum = data[0].Price
          item.quantityNum = 1
  
          let newitem = this.convertItem(item);
          this.itemsService.raiseSoItemEvent(newitem);
          this.alertify.success('Item Added');          
        
          
        }else{
          this.alertify.error("This product is inactive.")
        }
      }else{
        this.alertify.error("This product is inactive in DDS.")
      }
      })
      .catch((err)=>console.log(err));
  

      // item = this.convertItem(item);
      // this.itemsService.raiseSoItemEvent(item);
      
    }

  }

  addHeight(){
    this.heightCanvas += 100
  }

  lessHeight(){
    this.heightCanvas -= 100
  }

  convertItem(soItem) {
    let soItemNew: any = [];
    
    soItemNew = Object.assign({}, soItem);
    soItemNew["quantityNum"] = 1;
    if (soItemNew["priceNum"] == undefined) {
      soItemNew["priceNum"] = soItem['salesPriceNum'];
    } else if (soItem['salesPriceNum'] == undefined ) {
      soItem['salesPriceNum'] = soItemNew["priceNum"];
    }
    soItemNew["priceNum"] = soItem['salesPriceNum'];
    soItemNew["status"] = 'insert';
    
    return soItemNew;
  }

    ngOnInit() {
      this.userRole = this.authService.decodeToken('role').role;

    this.eventService.salesOrderEvent
      .pipe(takeUntil(this.destroy$))   
        .subscribe((res) => {
          this.eventid = res;

          if (this.eventid !== "0" && this.canSelectAnEvent) {
            this.eventService
              .getEvent(this.eventid)
              .pipe(takeUntil(this.destroy$))   
              .subscribe(
                (data) => {
                  // console.log(data)
                   let event = data[0];
                  if (event.floorItems != null) {
                    if(JSON.parse(event['floorItems']).items.length > 0){
                      this.itemsFloor = JSON.parse(event['floorItems']).items || []
                      this.heightCanvas = JSON.parse(event['floorItems']).config.height || 600
                      this.canSelectAnEvent = false                      
                    }else{
                    this.DefaultFloor();                      
                    }
                  }else{
                    this.DefaultFloor();
                  }
                },
                (error) => {
                  this.alertify.error(error);
                }
              );
          }

        });
        if (this.router.url.toString().includes('salesorder') ){
          this.settingsService
          .SettingsEvent$
          .pipe(takeUntil(this.destroy$))   
          .subscribe(
            (data) => {
              // console.log(data[0]["deliveryInfo"])
              if(data[0]["deliveryInfo"] != null){
                this.model.deliveryinfo = JSON.parse(data[0]["deliveryInfo"]);
              }
  
              //charge default config
              if(data[0]["floorItems"] != null  && this.fromDefaults){
                  this.itemsFloor = JSON.parse(data[0]["floorItems"]).items || []
                  this.heightCanvas = JSON.parse(data[0]["floorItems"]).config.height || 600              
              } 
            },
            (error) => {
              this.alertify.error(error);
            }
          );
        }else{
          let specificFields = {
            values: [
                "deliveryInfo", "floorItems", "DID", "password"
            ]
           }
          this.settingsService
          .getSpecificSettings(specificFields)
          .pipe(takeUntil(this.destroy$))   
          .subscribe(
            (data) => {
              // console.log(data[0]["deliveryInfo"])
              if(data[0]["deliveryInfo"] != null){
                this.model.deliveryinfo = JSON.parse(data[0]["deliveryInfo"]);
              }
  
              //charge default config
              if(data[0]["floorItems"] != null  && this.fromDefaults){
                  this.itemsFloor = JSON.parse(data[0]["floorItems"]).items || []
                  this.heightCanvas = JSON.parse(data[0]["floorItems"]).config.height || 600   
                  if(this.itemsFloor.length > 0){
                    this.itemsFloor.forEach((element:any)=>{ 
                      if(element.id == 100){
                        element.id = Math.floor(Math.random()*90000) + 10000
                      }
                    })                  
                  }
                  // console.log(this.itemsFloor)
                     
              } 
            },
            (error) => {
              this.alertify.error(error);
            }
          );
        }

       

        if(!this.fromDefaults){
          if(this.event['floorItems'] != null){
            if(JSON.parse(this.event['floorItems']).items.length > 0){
            this.itemsFloor = JSON.parse(this.event['floorItems']).items || []
            // console.log(this.itemsFloor)
            this.heightCanvas = JSON.parse(this.event['floorItems']).config.height || 600
            }else{
              this.DefaultFloor()              
            }
          }else{
            if(this.from == 'event'){
              this.itemsFloor = []
              this.heightCanvas = 600
            }else if(this.from == 'so'){
              this.DefaultFloor()
            }
          }
        }
        
      this.itemsService.salesItemEvent
      .pipe(takeUntil(this.destroy$))   
  
        .subscribe((res:any) => {

          
          const soItem:any = this.itemsService.salesOrderItems.value;
          // console.log(soItem)
          let imageSelected = ''
          switch (soItem.product) {
            case 'MAT':
                imageSelected = 'assets/full.png'
              break;
            case 'PILLOW':
              imageSelected = 'assets/pillow.png'
              break;
            case 'FOUNDATION':
              imageSelected = 'assets/foundation.png'
              break;
            case 'ADJ BASE':
              imageSelected = 'assets/adj.png'
              break;
            case 'FRAME ACCESSORY':
              imageSelected = 'assets/frame.png'
              break;
             case 'FRAME':
              imageSelected = 'assets/frame.png'
              break;
            case 'SHEET':
              imageSelected = 'assets/sheet.png'
              break;
            default:
              imageSelected = 'assets/twin.png'

              break;
          }
         
        //  if (this.fromSalesOrder == 0) {
           // const soItem = this.itemsService.salesOrderItems.value;
            let soItemNew: any = [];
            soItemNew = Object.assign({}, soItem);
            
            //this.selectedItem["item"] = soItemNew;
            
            var results = this.storeProductsService.allStoreProducts.filter(
              (x) =>
                x.MFGID == soItemNew["MFGID"] &&
                x.styleId == soItemNew["styleId"] &&
                x.product == soItemNew["product"]
            );
  
            var ordered = new Array();
            var twin = [];
            var twinxl = [];
            var full = [];
            var queen = [];
            var king = [];
            var cking = [];
            var other = [];
            
            results.forEach((style:any)=>{
              if( (SizesIndex.TWIN.toLowerCase()).includes(style["sizeName"].toLowerCase())) {
                twin.push(style)
                return;
              }
              if( (SizesIndex["TWIN XL"].toLowerCase()).includes(style["sizeName"].toLowerCase())) {
                twinxl.push(style)
                return;
              }
              if( (SizesIndex.FULL.toLowerCase()).includes(style["sizeName"].toLowerCase())) {
                full.push(style)
                return;
              }
              if( (SizesIndex.QUEEN.toLowerCase()).includes(style["sizeName"].toLowerCase())){ 
                queen.push(style)
                return;
              }
              if( (SizesIndex.KING.toLowerCase()).includes(style["sizeName"].toLowerCase())) {
                king.push(style)
                return;
              }
              if( (SizesIndex["CAL KING"].toLowerCase()).includes(style["sizeName"].toLowerCase())){ 
                cking.push(style)
                return;
              }
  
              other.push(style)
  
            })
  
            twin.forEach(element => ordered.push(element));
            twinxl.forEach(element => ordered.push(element));
            full.forEach(element => ordered.push(element));
            queen.forEach(element => ordered.push(element));
            king.forEach(element => ordered.push(element));
            cking.forEach(element => ordered.push(element));
            other.forEach(element => ordered.push(element));

          
            soItemNew.image = imageSelected
            if(this.from == 'event'){
              if(this.showItemsModaL){
                if(this.itemModifying.title == '' && this.itemModifying.items.length == 0){
                  this.itemModifying.title = soItemNew.manufacturer
                }
                soItemNew.options = ordered
                this.itemModifying.items.push(soItemNew)
                // this.showItemsModaL = false
              }else{
                soItemNew.options = ordered
                this.itemsFloor.push({id: soItemNew.productsId ,title : '' , image : imageSelected, position: {x: 0, y: 0}, delivery : [] , items:[soItemNew], style:[{rotate : 0, height : '70px', width : '70px'}]})
              }
            }
            // console.log(this.itemsFloor)
           // console.log(this.itemsFloor[5].style[0].rotate)
  
            //this.selectedItem["item"]["options"] = ordered;
  
           // this.modalService.hide(this.modalService.getModalsCount() - 1);
         // }
        });

        if(this.itemsFloor.length > 0){
          this.itemsFloor.forEach((element:any)=>{ 
            if(element.id == 100){
              element.id = Math.floor(Math.random()*90000) + 10000
            }
          })                  
        }
        // console.log(this.itemsFloor)

       

        // this.loadStoreProducts()
    }

    loadDefaultFloor(){
      if(this.itemsFloor.length > 0){
        this.alertify.confirm("You have a Event Floor set, Are you sure to load Default Event Floor",()=>{
          this.DefaultFloor()
        }
        ,()=>{
        })
      }else{
          this.DefaultFloor()
      }
    }
    DefaultFloor(){
      this.settingsService
      .getSettings(0)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (data) => {    
          if(data[0]["floorItems"] != null){
            this.itemsFloor = JSON.parse(data[0]["floorItems"]).items 
            this.heightCanvas = JSON.parse(data[0]["floorItems"]).config.height 
            if(this.itemsFloor.length > 0){
              this.itemsFloor.forEach((element:any)=>{ 
                if(element.id == 100){
                  element.id = Math.floor(Math.random()*90000) + 10000
                }
              })                  
            }
            // console.log(this.itemsFloor)
    
          }
        },
        (error) => {
          this.alertify.error(error);
        }
      );
    }
    submit(model: any) {
      // let counter = 0;
      // for (const value of this.model.sponsorscontributions) {
      //   counter++;
      //   if (!value.hasOwnProperty("yy_SponsorContribution_tx")) {
      //     value["yy_SponsorContribution_tx"] = this.global.guid();
      //   }
      //   value["OrderNum"] = counter.toString();
      // }
      // counter = 0;
      // if (this.validateAllSizesOnProducts() === true) {
      // this.sponsorContributionService
      //   .updateSponsorContribution(0, this.model)
      //   .pipe(takeUntil(this.destroy$))   
      //   .subscribe(
      //     (res) => {
      //       this.alertify.success(
      //         "Contributions to Sponsor were saved successfully"
      //       );
      //       this.saveSettings();
      //     },
      //     (error) => {
      //       this.alertify.error(error);
      //     }
      //   );
      }


    loadStoreProducts() {
      this.storeProductsService
        .getStoreProducts(1, 1, "", "", "", "")
        .pipe(takeUntil(this.destroy$))   
        .subscribe(
          (res: any) => {
  
            this.companies = res.result.companies;
            this.companiesandsizes = res.result.companiesandsizes;
  
            this.companiesandsizes.forEach(element => {
              element['sizeName'] = element['sizeName'].toString().trim().toUpperCase();
            });
  
            this.styles = res.result.styles;
            this.sizes = res.result.sizes;
            this.sizes.forEach(element => {
              element['sizeName'] = element['sizeName'].toString().toUpperCase();
            });
            this.products = res.result.products;
            this.productsSelect = res.result.products;
              // this.fields[0].fieldArray.fieldGroup[2].templateOptions.options = this.sizes;
              // this.fields[0].fieldArray.fieldGroup[0].templateOptions.options = this.products;
  
          },
          (error) => {
            this.alertify.error(error);
          }
        );
    } 

   

    showProducts(){
      this.showItems = true
      this.showFurni = false
      this.showItemsModaL = false
    }

    showFurniture(){
      this.showFurni = !this.showFurni
      this.showItems = false

    }

    showProductInModal(){
      this.showItemsModaL = true
    }

    

    dragEnd($event: CdkDragEnd, item, index) {
      this.beingmoved = false;
      if($event.source.getFreeDragPosition().x > 591 && $event.source.getFreeDragPosition().y < 9){
          this.removeItem(null, index)
      }else{
        if(this.tookX && this.tookY){
          item.position.y = this.freeItemY
          item.position.x = this.freeItemX
      }else{
        if(this.tookY){
          item.position.y = this.freeItemY
          item.position.x = $event.source.getFreeDragPosition().x
        }else if(this.tookX){
          item.position.x = this.freeItemX
          item.position.y = $event.source.getFreeDragPosition().y
        }
      }
      
        if(this.freeItemY == 0 && this.freeItemX == 0){
          item.position = $event.source.getFreeDragPosition();
        }
        // console.log("END", item)
      }

    }
    dragEnter($event: CdkDragStart, item, index) {
        // console.log("ENTER", item, $event)
        this.currentDragging = item.id
        this.freeItemHeight = document.getElementById(item.id).clientHeight
        this.freeItemWidth = document.getElementById(item.id).clientWidth
        this.currentPositions = this.itemsFloor.filter(it => it.id != item.id).map((e) =>  { return {x: e.position.x, y: e.position.y}})


    }
    dragMove($event: CdkDragMove, item, index, cdkdrag) {
      // console.log(this.itemsFloor)
      // console.log(item)
      this.parallelX = 0
      this.parallelY = 0
      this.tookY = false
      this.tookX = false
      this.freeItemX = 0
      this.freeItemY = 0
      let X = $event.source.getFreeDragPosition().x;
      let Y = $event.source.getFreeDragPosition().y;
      let acerto = false
      this.currentPositions.forEach(ele => {
        if(X > (ele.x - 10) && X < (ele.x + 10)){
          // console.log(this.itemsFloor)
          // console.log(item)
          this.beingmoved = true
          this.freeItemY = Y
          this.parallelX = ele.x
          this.freeItemX = ele.x
          this.tookY = false
          this.tookX = true
          acerto = true
          // console.log("cerca X",  $event.source.getFreeDragPosition(),ele, "freeItemX: "+this.freeItemX, "parallelX: "+this.parallelX, "freeItemY: "+this.freeItemY, "tookY: "+this.tookY, "tookX: "+this.tookX)
          return
        }else if(Y > (ele.y - 10) && Y < (ele.y + 10)){
          // console.log("cerca Y", $event.source.getFreeDragPosition())
          this.beingmoved = true;
          this.freeItemX = X
          this.parallelY = ele.y
          this.freeItemY = ele.y
          this.tookY = true
          this.tookX = false
          acerto = true
          return;
        }else if(!acerto) {
          // console.log("MOVE", ele)
          this.beingmoved = false;
          this.tookY = false
          this.tookX = false
          this.freeItemX = 0
          this.freeItemY = 0
          this.parallelX = 0
          this.parallelY = 0
          return;
        }

        
      });
      if(this.parallelX != 0 && this.parallelY != 0){
        // console.log(this.itemsFloor)
        // console.log("both")
        this.beingmoved = true
        this.freeItemY = this.parallelY
        this.freeItemX = this.parallelX
        this.tookY = true
        this.tookX = true
        acerto = true
        // console.log("cerca X",  $event.source.getFreeDragPosition(),ele, "freeItemX: "+this.freeItemX, "parallelX: "+this.parallelX, "freeItemY: "+this.freeItemY, "tookY: "+this.tookY, "tookX: "+this.tookX)
        return
      }
    }

    started($event) {
      // console.log($event)

    }

    onResize(event) {
      let percentage 
      if(event.width > 0 && event.width < 700){
        percentage = parseFloat((event.width /700).toFixed(2));
        this.lessPercentage =  percentage 
      }else{
        this.lessPercentage = 1
      }
    }

    onResizeHeight(event) {
      let percentagew 
      let percentageh
      let lastHeight = this.heightCanvas;

        percentagew = parseFloat((563 /event.width).toFixed(2));
        this.widthCanvas = 563
        this.lessPercentage =  percentagew

      if(event.height > 780){
        percentageh = parseFloat((780 /lastHeight).toFixed(2));
        this.lessPercentage = percentagew > percentageh ? percentageh : percentagew 
      } 
    }

    dragEndForNew($event: CdkDragEnd, item) {
      // console.log(item);
     

      var elm = document.getElementById("maindiv");
      elm.addEventListener('mousemove',getcoord, false) 
      let x;
      let y;
      function getcoord(ev){
        var bnds = ev.target.getBoundingClientRect();
       x = ev.clientX - bnds.left;
       y = ev.clientY - bnds.top;
    
     // console.log( ev.clientX - bnds.left, ev.clientY - bnds.top)
         
      }

      setTimeout(()=>{
        // console.log(x, y)
        this.itemsFloor.push({id: Math.floor(Math.random()*90000) + 10000 , name: item.name , title : '' , image : item.urlImage, position: {x: x == undefined ? 0 : x - (40), y: y == undefined ? 0 : y -(40)} , delivery : [] , items:[], style:[{rotate : 0, height : item.height, width : item.width}]})
  
      }, 1000)

    }

    ngOnDestroy(): void {
      this.destroy$.next(true);
      this.destroy$.unsubscribe();
    }

    saveFloor(){
      let data = {
        config : {height : this.heightCanvas, width : this.widthCanvas},
        items : this.itemsFloor
      }
    
     
      // console.log(this.itemsFloor)

      if(this.fromDefaults){
        let layoutToSend = {
          floorItems : JSON.stringify(data)
        }
        this.settingsService.updateDefaultFloor(layoutToSend)
        .pipe(takeUntil(this.destroy$))   
          .subscribe(
            () => {
              this.alertify.success("Default Floor Item has been updated");
            },
            (error) => {
              this.alertify.error(error);
            }
          );
      }else{
        this.event['floorItems'] = JSON.stringify(data)
         this.settingsService
        .updateFloorEvent(this.event)
        .pipe(takeUntil(this.destroy$))   
        .subscribe(
          (data) => {
            // console.log(data)
            this.alertify.success("Event Floor has been updated");
          },
          (error) => {
            this.alertify.error(error);
          }
        );
      }

     
    }

}
