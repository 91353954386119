<!-- [ngClass]="1 ? 'modal-body' : ''" -->
<div >
  <nav
    class="main-navbar scrollable"
    *ngIf="!forSummary"
  >
    <div class="main-navbar-info-page">
      <span class="mr-1">Purchase Orders:</span>
      <strong class="text-dark" 
      counto
      [step]="30"
      [countTo]="pagination.totalItems"
      [countFrom]="0"
      [duration]="1"
      (countoChange)="counto = $event" >{{ counto | number: "1.0" }}</strong>
    </div>
    <div class="main-navbar-content-page">
      <div [ngClass]="inputSearchCss">
        <span class="material-icons" *ngIf="!specialcha">search</span>
        <span class="material-icons" style="color: #ffc107;" *ngIf="specialcha">filter_list_off</span>
        <input
          type="text"
          [formControl]="searchPurchaseOrder"
          (input)="search()"
          placeholder="Search by Purchase Order No or Manufacturer"
        />
      </div>
    </div>
    
  </nav>

  <div class="nav-display-options-mobile"  *ngIf="!forSummary">
    
    <div class="w-100 d-flex justify-content-between align-items-center">
      <h6 class="ml-2">Purchases Orders</h6>
      <div [ngClass]="inputSearchCssmobile">
        <span class="material-icons"  *ngIf="!specialcha"> search</span>
        <span class="material-icons" style="color: #ffc107;" *ngIf="specialcha">filter_list_off</span>
        <input
          type="text"
          [formControl]="searchPurchaseOrder"
          (input)="search()"
          placeholder="Search by Purchase Order No or Manufacturer"
        />
      </div>
    </div>
    
    <div class="nav-links-contrainer-filter">
      <div class="btn btn-3 mr-2" (click)="openviewBy()">View</div>
      <div class="btn btn-3 mr-2" (click)="openstatus()">Status</div>
      <div class="btn btn-3" (click)="opensortBy()">Sort</div>
    </div>

    <div class="selecter-filter-container" [ngClass]="{ active: !viewby }"> 
        <div class="selecter-filter-container-title">
            <label for="">View</label>
        </div>
        <div class="selecter-filter-container-items" btnRadioGroup [(ngModel)]="dateFilter">
          <div class="selecter-filter-container-items-item" (click)="search();openviewBy()" btnRadio="">All</div>
          <div class="selecter-filter-container-items-item" (click)="search();openviewBy()" btnRadio="today">Today</div>
          <div class="selecter-filter-container-items-item" (click)="search();openviewBy()" btnRadio="week">This Week</div>
          <div class="selecter-filter-container-items-item" (click)="search();openviewBy()" btnRadio="month">This Month</div>
          <div class="selecter-filter-container-items-item" (click)="search();openviewBy()" btnRadio="year">This Year</div>
        </div>
    </div>
    <div class="selecter-filter-container" [ngClass]="{ active: !status }"> 
      <div class="selecter-filter-container-title">
        <label for="">Status</label>
    </div>
      <div class="selecter-filter-container-items" btnRadioGroup [(ngModel)]="statusFilter">
        <div class="selecter-filter-container-items-item" (click)="search();openstatus()" btnRadio="">All</div>
        <div class="selecter-filter-container-items-item" (click)="search();openstatus()" btnRadio="Uploaded">Uploaded</div>
        <div class="selecter-filter-container-items-item" (click)="search();openstatus()" btnRadio="Pending">Pending</div>
      </div>
    </div>

    <div class="selecter-filter-container" [ngClass]="{ active: !sortBy }"> 
      <div class="selecter-filter-container-title">
        <label for="">Sort</label>
      </div>
      <div class="selecter-filter-container-items" btnRadioGroup >
        <div></div>
        <div class="selecter-filter-container-items-item" (click)="changeDirection('POUUID');opensortBy()" btnRadio="">PO No.</div>
        <div class="selecter-filter-container-items-item" (click)="changeDirection('shipA');opensortBy()" btnRadio="Bil">Ship To</div>
        <div class="selecter-filter-container-items-item" (click)="changeDirection('dbPOdatets');opensortBy()" btnRadio="Date">Date</div>
        <div class="selecter-filter-container-items-item" (click)="changeDirection('mfg');opensortBy()" *ngIf="forSummary" btnRadio="manufacturer">Manufacturer</div>
        <div class="selecter-filter-container-items-item" (click)="changeDirection('DeliveryType');opensortBy()" btnRadio="Bil">Delivery</div>
        <div class="selecter-filter-container-items-item" (click)="changeDirection('UploadStatus');opensortBy()" btnRadio="Bil">Status</div>
      </div>
    </div>

    <div class="selecter-filter-container active"> 
      <div class="row" *ngIf="dateFilter || statusFilter">
        <div class="col-6" [ngClass]="statusFilter == ''  ? 'col-12' : 'col-6'">
          <div class="selecter-filter-container-title no-padding" *ngIf="dateFilter">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">View:</h6>
              <span class="selecter-filter-container-title-select-event">{{dateFilter}}</span>
            </div>
            <div class="selecter-filter-container-title-closed">
              <span class="material-icons" (click)="dateFilter = ''; search(1);closeEventSelected()" >
                close
              </span>
            </div>
          </div>
        </div>
        <div class="col-6" [ngClass]="dateFilter == ''  ? 'col-12' : 'col-6'">
          <div class="selecter-filter-container-title no-padding" *ngIf="statusFilter">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Status:</h6>
              <span class="selecter-filter-container-title-select-event">{{statusFilter}}</span>
            </div>
            <div class="selecter-filter-container-title-closed">
              <span class="material-icons" (click)="statusFilter = '';search(2);closeEventSelected()" >
                close
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="selecter-filter-container-title border-b" *ngIf="sortByText">
        <div class="d-flex align-items-center">
          <h6 class="selecter-filter-container-title-select-header">Sort:</h6>
          <span class="selecter-filter-container-title-select-event">{{sortByText}}</span>
        </div>
        <div class="selecter-filter-container-title-closed">
          <span class="material-icons" (click)="sortByText = ''; search(3);closeEventSelected()" >
            close
          </span>
        </div>
      </div>
    </div>

    <app-event-info-mobile [searchBy]="'All'" [fromEvent]="true"  class="event-nav-display-options-mobile" ></app-event-info-mobile>
  </div>

  <div class="nav-display-options scrollable" *ngIf="!forSummary" >   
    <app-event-info [searchBy]="'All'" [fromEvent]="true"></app-event-info>
    <div class="nav-display-options-type-view no-mobile">
      <div class="nav-display-options-type-view-text"><p class="m-0">Items: <strong class="text-black">{{totalItemsS}}</strong></p></div>
      <div class="nav-display-options-type-view-text"><p class="m-0">Amount: <strong class="text-black">{{totalAmountS}}</strong></p></div>
    </div>
  </div>
  
  
  <div class="row">
    <div class="col-12">
      <div class="nav-display-options half scrollable">
        <div class="nav-display-options-filter  mr-3">
          <div class="nav-display-options-filter-buttons-view btn btn-6 pl-3 pr-0 py-0" (click)="openViewByNav()" [ngClass]="{ '': !openView, 'hidden-active': openView }">
            <div class="nav-display-options-filter-buttons-view-text">
              <p>View:</p>
            </div>
            <div class="nav-display-options-filter-buttons-view-buttons hidden view-465" btnRadioGroup [(ngModel)]="dateFilter">
              <button class="btn btn-4 mr-1" (click)="search()" btnRadio="" tabindex="0" role="button">All</button>
              <button class="btn btn-4 mr-1" (click)="search()" btnRadio="today" tabindex="0" role="button">Today</button>
              <button class="btn btn-4 mr-1" (click)="search()" btnRadio="week" tabindex="0" role="button">This Week</button>
              <button class="btn btn-4 mr-1" (click)="search()" btnRadio="month"tabindex="0" role="button">This Month</button>
              <button class="btn btn-4 mr-1" (click)="search()" btnRadio="year" tabindex="0" role="button">This Year</button>
            </div>
          </div>
        </div>
        <div class="nav-display-options-filter">
          <div class="nav-display-options-filter-buttons-view btn btn-6 pl-3 pr-0 py-0" (click)="openStatusNav()" [ngClass]="{ '': !openStatus, 'hidden-active': openStatus }">
            <div class="nav-display-options-filter-buttons-view-text"><p>Status:</p></div>
            <div class="nav-display-options-filter-buttons-view-buttons hidden status-260" btnRadioGroup [(ngModel)]="statusFilter">
              <button class="btn btn-4 mr-1" (click)="search()" btnRadio="" tabindex="0" role="button">All</button>
              <button class="btn btn-4 mr-1" (click)="search()" btnRadio="Uploaded" tabindex="0" role="button">Uploaded</button>
              <button class="btn btn-4 mr-1" (click)="search()" btnRadio="Pending" tabindex="0" role="button">Pending</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="customer-content" 
  [ngClass]="{ 
    'scroll-top-purchaseorder': !forSummary ,
    'scroll-sale-order-top-view': dateFilter || statusFilter || sortByText,
    'scroll-sale-order-top-sortby': dateFilter && statusFilter && sortByText || statusFilter && sortByText || dateFilter && sortByText }" >

    <div class="table-responsive no-mobile">
      <table id="salestable" class="table table-light table-custom store-catalog-table" cellspacing="0">
        <thead class="table-custom-header">
          <tr>
            <th></th>

            <th (click)="changeDirection('POUUID')">
              PO No.
              <label class="ml-1 mb-0">
                <span *ngIf="orderDir=='' && orderField=='POUUID'" class="material-icons"> arrow_upward </span>
                <span *ngIf="orderDir=='desc' && orderField=='POUUID'" class="material-icons"> arrow_downward </span>
              </label>
            </th>
            <th (click)="changeDirection('shipA')">
              Ship To
              <label class="ml-1">
                <span *ngIf="orderDir=='' && orderField=='shipA'" class="material-icons"> arrow_upward </span>
                <span *ngIf="orderDir=='desc' && orderField=='shipA'" class="material-icons"> arrow_downward </span>
              </label>
            </th>
            <th (click)="changeDirection('dbPOdatets')">
              Date
              <label class="ml-1">
                <span *ngIf="orderDir=='' && orderField=='dbPOdatets'" class="material-icons"> arrow_upward </span>
                <span *ngIf="orderDir=='desc' && orderField=='dbPOdatets'" class="material-icons"> arrow_downward </span>
              </label>
            </th>
            <th (click)="changeDirection('mfg')">
              Manufacturer
              <label class="ml-1">
                <span *ngIf="orderDir=='' && orderField=='mfg'" class="material-icons"> arrow_upward </span>
                <span *ngIf="orderDir=='desc' && orderField=='mfg'" class="material-icons"> arrow_downward </span>
              </label>
            </th>
            <th (click)="changeDirection('DeliveryType')">
              Delivery
              <label class="ml-1">
                <span *ngIf="orderDir=='' && orderField=='DeliveryType'" class="material-icons"> arrow_upward </span>
                <span *ngIf="orderDir=='desc' && orderField=='DeliveryType'" class="material-icons"> arrow_downward </span>
              </label>
            </th>
            <th (click)="changeDirection('UploadStatus')">
              Status
              <label class="ml-1">
                <span *ngIf="orderDir=='' && orderField=='UploadStatus'" class="material-icons"> arrow_upward </span>
                <span *ngIf="orderDir=='desc' && orderField=='UploadStatus'" class="material-icons"> arrow_downward </span>
              </label>
            </th>
            <th>
              Total Items
            </th>
            <th *ngIf="userRole != 'Manager' ">
              Total Amount
            </th>
            <th>

            </th>
          </tr>
        </thead>
        <tbody *ngFor="let purchaseorder of purchaseorders" >
          <tr class="m-1 p-1" [ngClass]="{'tw-border-2 tw-border-aedoranges tw-rounded-md': poNumber == purchaseorder.POUUID && purchaseorder.confirmationStatus != 'Success'}">
            <td colspan="1" style="padding-left: .5rem;" class="tdeventlist tw-cursor-pointer">
              <span *ngIf="purchaseorder?.MyPOID == MyPOIDSelected && purchaseorder?.poItems?.length > 0" (click)="showPO('', [])" class="material-icons md-24 tw-w-5 tw-h-5">expand_more</span>
              <span *ngIf="purchaseorder?.MyPOID != MyPOIDSelected && purchaseorder?.poItems?.length > 0" (click)="showPO(purchaseorder?.MyPOID , purchaseorder )" class="material-icons md-24">chevron_right</span>
            </td>
            <td>{{ purchaseorder.POUUID }}  

                  <br>
                  
                  <small class="text-success"
                  *ngIf="purchaseorder.confirmationStatus === 'Success'">
                    Uploaded to DDS
                </small>

                <small class="text-success"
                *ngIf="purchaseorder.confirmationPOID != null">
                  {{ purchaseorder.confirmationPOID.slice(5) }}  
              </small>

                <small class="text-danger"
                *ngIf="purchaseorder.confirmationStatus === 'ERROR'">
                  Failed to Upload
              </small>


            </td>
            <td>
              <select
                class="form-control"
                [(ngModel)]="purchaseorder.shipA"
                placeholder="Ship To"
                [disabled]="purchaseorder.UploadStatus !== 'Pending'"
                *ngIf="
                  purchaseorder.DeliveryType !== 'Direct Shipment' &&
                  purchaseorder.DeliveryType !== 'MattressFirm' &&
                  purchaseorder.DeliveryType !== 'MattressFirm DNTO'
                "
                (ngModelChange)="changePurchaseOrder(purchaseorder)"
              >
                <option>{{ "Warehouse 1" }}</option>
                <option>{{ "P Address" }}</option>
                <option>{{ "Warehouse 2" }}</option>
                <option>{{ "Warehouse 3" }}</option>
              </select>
              <div class="" *ngIf="purchaseorder.deliveryAttn">
                <p class="m-0">{{ purchaseorder.deliveryAttn }}</p>
                <p class="m-0">
                  {{ purchaseorder.deliveryPhone }} {{ purchaseorder.ds_phone2 }}
                </p>
                <p class="m-0" *ngIf="purchaseorder.deliveryEmail">
                  {{ purchaseorder.deliveryEmail }}
                </p>
                <p class="m-0">{{ purchaseorder.deliveryAddress1 }}</p>
                <p class="m-0">{{ purchaseorder.deliveryAddress2 }}</p>
                <p class="m-0">
                  {{ purchaseorder.deliveryCity }}, {{ purchaseorder.deliveryState }},
                  {{ purchaseorder.deliveryZip }}
                </p>
              </div>
            </td>
            <td>{{ purchaseorder.showPOdate | date: "shortDate" }}</td>
            <td><p class="m-0" style="max-width: 120px;font-size: 11px;">{{ purchaseorder.mfg }}</p></td>
            <td>
              {{ purchaseorder.DeliveryType }}
            </td>
            <td>
              <select
                class="form-control d-inline form-control-sm pull-right"
                [(ngModel)]="purchaseorder.UploadStatus"
                (ngModelChange)="changePurchaseOrder(purchaseorder)"
              >
                <option>{{ "Pending" }}</option>
                <option>{{ "Uploaded" }}</option>
              </select>
            </td>
            <td>
              {{ purchaseorder.TotalItems | number: "1.0" }}
            </td>
            <td *ngIf="userRole != 'Manager'">
              <span *ngIf="purchaseorder.TotalAmount">$</span> {{ purchaseorder.TotalAmount | number: "1.2-2" }}
            </td>
            <td>
              <!-- If not iOS -->
              <div *ngIf="!isIOS" class="d-flex">
                <button
                  (click)="selectPurchaseOrderedit(purchaseorder)"
                  class="btn btn-blue mr-3"
                  tooltip="Edit PO"
                >
                  <span class="material-icons"> edit </span>
                </button>
                <button
                  (click)="selectPurchaseOrder(purchaseorder)"
                  class="btn btn-blue mr-3"
                  tooltip="View PO"
                >
                  <span class="material-icons"> visibility </span>
                </button>
                <button
                  (click)="uploadPurchaseOrder(purchaseorder)"
                  *ngIf="
                    purchaseorder.UploadStatus !== 'Uploaded' &&
                    purchaseorder.deliveryAttn !== 'Error No Direct Shipment Address' && forSummary
                  "
                  class="btn btn-blue mr-3"
                  tooltip="Upload to DDS"
                  [disabled]="uploadingPO"
                >
                  <span class="material-icons invert" *ngIf="!uploadingPO"> system_update_alt </span>
                  <span class="material-icons" *ngIf="uploadingPO"> history </span>
                </button>
                <button
                  *ngIf="purchaseorder.UploadStatus !== 'Uploaded' && forSummary"
                  (click)="deletePurchaseOrder(purchaseorder)"
                  tooltip="Delete PO"
                  class="btn btn-delete"
                >
                  <span class="material-icons"> delete </span>
                </button>
              </div>
              <!-- If iOS -->
              <div *ngIf="isIOS" class="d-flex">
                <button
                  (click)="selectPurchaseOrderedit(purchaseorder)"
                  class="btn btn-blue mr-3"
                >
                  <span class="material-icons"> edit </span>
                </button>
                <button
                  (click)="selectPurchaseOrder(purchaseorder)"
                  class="btn btn-blue mr-3"
                >
                  <span class="material-icons"> visibility </span>
                </button>
                <button
                  (click)="uploadPurchaseOrder(purchaseorder)"
                  *ngIf="
                    purchaseorder.UploadStatus !== 'Uploaded' &&
                    purchaseorder.deliveryAttn !== 'Error No Direct Shipment Address' && forSummary
                  "
                  class="btn btn-blue mr-3"
                  [disabled]="uploadingPO"
                >
                  <span class="material-icons invert" *ngIf="!uploadingPO"> system_update_alt </span>
                  <span class="material-icons" *ngIf="uploadingPO"> history </span>
                </button>
                <button
                  *ngIf="purchaseorder.UploadStatus !== 'Uploaded' && forSummary"
                  (click)="deletePurchaseOrder(purchaseorder)"
                  class="btn btn-delete"
                >
                  <span class="material-icons"> delete </span>
                </button>
              </div>
            </td>
          </tr>

          <!-- templateSODetails -->
          <tr [ngClass]="{'tw-border-b-2 tw-border-l-2 tw-border-r-2 tw-border-aedorangel tw-rounded-md': poNumber == purchaseorder.POUUID && purchaseorder.confirmationStatus != 'Success'}" *ngIf="purchaseorder?.MyPOID == MyPOIDSelected" >
            <td colspan="1"></td>
            <td class="" colspan="13">
              <div class="tw-bg-white tw-rounded-lg tw-mr-2 " *ngFor="let item of POItemsToShow?.poItems">
                <div class="tw-flex tw-flex-col"> 
                  <div class="tw-text-aedblue tw-font-bold tw-text-base  tw-w-full">
                    {{item?.styleName}} {{item?.Type}} {{item?.size}} {{item?.Price | currency}} ({{item?.soQtyNum}})
                  </div>
                  <div class="tw-flex tw-flex-col">
                    <div class="tw-pl-6" *ngFor="let poitems of POItemsToShow.poitemsItems">
                        <div class="tw-flex tw-space-x-3" *ngIf="poitems?.POITID == item?.POITID">
                          <button *ngIf="purchaseorder.confirmationStatus !== 'Success'" (click)="deleteItem(poitems.SOItemsID,poitems.POITID,poitems.quantityNum)" class="btn btn-blue">
                            <span class="material-icons">
                              delete
                              </span>
                          </button> 
                          <div [id]="poitems?.SOID" class="hover:tw-text-aedoranges w- tw-cursor-pointer tw-font-semibold tw-text-base tw-inline-block tw-text-aedgraycard" (click)="showSOdetails(poitems, templateSODetails)">
                            {{poitems?.SONumber}} ({{poitems?.status}}) | {{poitems?.firstName}} {{poitems?.lastName}} QTY: {{poitems?.quantityNum}}
                          </div>
                        </div>
                    </div>
                  </div>
                  <!-- <div class="tw-w-8/12 tw-text-base tw-flex tw-flex-wrap tw-text-aedgraycard">
                  <div *ngIf="item.styleName" >{{item?.styleName.length > 24 ? item?.styleName.slice(0,24)+'...' : item?.styleName}}</div> |
                   {{item?.wholeSalePrice}} | 
                   {{item?.Type }} | 
                   <div *ngIf="item.size" >{{item?.size.length > 10 ? item?.size.slice(0,9)+'...' : item?.size}}</div> | 
                   {{item?.quantityNum}}  
                  </div> -->
                </div>                  
              </div>
            </td>  
          </tr>


        </tbody>
      </table>
    </div>

    <div class="card-customer-mobile-record " [ngClass]="{'border m-3': forSummary }"  *ngFor="let purchaseorder of purchaseorders">
      <div class="card-customer-mobile-record-base-info">
        <div class="row">
          <!-- po -->
          <div class="col-5">
            <p class="m-0"><strong>PO</strong> {{ purchaseorder.POUUID }}</p>
          </div>
          <!-- Manufacture -->
          <div class="col-7 justify-content-end w-100">
            <p class="m-0">{{ purchaseorder.mfg }}</p>
          </div>
          <!-- Date -->
          <div class="col-6">
            <p class="m-0">{{ purchaseorder.showPOdate | date: "shortDate" }}</p>
          </div>
          <!-- Status -->
          <div class="col-6">
            <select
              class="form-control d-inline form-control-sm pull-right"
              [(ngModel)]="purchaseorder.UploadStatus"
              (ngModelChange)="changePurchaseOrder(purchaseorder)"
            >
              <option>{{ "Pending" }}</option>
              <option>{{ "Uploaded" }}</option>
            </select>
          </div>
          <!-- delivery -->
          <div
          [ngClass]="purchaseorder.DeliveryType !== 'Direct Shipment' && purchaseorder.DeliveryType !== 'MattressFirm' && purchaseorder.DeliveryType !== 'MattressFirm DNTO' ? 'col-6' : 'col-12' ">
            <p class="m-0" >{{ purchaseorder.DeliveryType }}</p>
          </div>
           <!-- Were House -->
          <div class="col-6" *ngIf="purchaseorder.DeliveryType !== 'Direct Shipment' && purchaseorder.DeliveryType !== 'MattressFirm' && purchaseorder.DeliveryType !== 'MattressFirm DNTO' ">
            <select
              class="form-control"
              [(ngModel)]="purchaseorder.shipA"
              placeholder="Ship To"
              [disabled]="purchaseorder.UploadStatus !== 'Pending'"
              *ngIf="
                purchaseorder.DeliveryType !== 'Direct Shipment' &&
                purchaseorder.DeliveryType !== 'MattressFirm' &&
                purchaseorder.DeliveryType !== 'MattressFirm DNTO'
              "
              (ngModelChange)="changePurchaseOrder(purchaseorder)"
            >
              <option>{{ "Warehouse 1" }}</option>
              <option>{{ "P Address" }}</option>
              <option>{{ "Warehouse 2" }}</option>
              <option>{{ "Warehouse 3" }}</option>
            </select>
          </div>
          <!-- Shipto -->
          <!-- <div class="col-12" *ngIf="purchaseorder.deliveryAttn">
            <div class="d-flex w-100" >
              <p class="m-0 large-text-card" >
                {{ purchaseorder.deliveryAttn }}
                {{ purchaseorder.deliveryEmail }}
                {{ purchaseorder.deliveryAddress1 }} {{ purchaseorder.deliveryAddress2 }} 
                {{ purchaseorder.deliveryCity }}, {{ purchaseorder.deliveryState }},
                {{ purchaseorder.deliveryZip }}
                {{ purchaseorder.deliveryPhone }} {{ purchaseorder.ds_phone2 }}
              </p>
            </div>
          </div> -->
          <!-- Items -->
          <div class="col-6 col-sm-3">
            <p class="m-0"><strong>Items:</strong> {{ purchaseorder.TotalItems | number: "1.0" }}</p>
          </div>
          <!-- Amount -->
          <div class="col-6 col-sm-5" *ngIf="userRole != 'Manager'">
            <p class="m-0"><strong>Amount:</strong> $ {{ purchaseorder.TotalAmount | number: "1.2-2" }}</p>
          </div>
          <!-- Buttons -->
          <div class="col-12 col-sm-4">
            <!-- is IOS -->
            <div *ngIf="isIOS" class="d-flex justify-content-end w-100">

              <button
                (click)="selectPurchaseOrder(purchaseorder)"
                class="btn btn-blue mr-4"
              >
                <span class="material-icons"> visibility </span>
              </button>
              <button
                (click)="uploadPurchaseOrder(purchaseorder)"
                *ngIf="
                  purchaseorder.UploadStatus !== 'Uploaded' &&
                  purchaseorder.deliveryAttn !== 'Error No Direct Shipment Address'
                "
                class="btn btn-blue mr-4"
                [disabled]="uploadingPO"
              >
                <span class="material-icons invert" *ngIf="!uploadingPO"> system_update_alt </span>
                <span class="material-icons" *ngIf="uploadingPO"> history </span>
              </button>
              <button
                *ngIf="purchaseorder.UploadStatus !== 'Uploaded'"
                (click)="deletePurchaseOrder(purchaseorder)"
                class="btn btn-delete"
              >
                <span class="material-icons"> delete </span>
              </button>
            </div>
            <!-- if not iOS -->
            <div *ngIf="!isIOS" class="d-flex justify-content-end w-100">
              <button
                (click)="selectPurchaseOrder(purchaseorder)"
                class="btn btn-blue mr-4"
                tooltip="View PO"
              >
                <span class="material-icons"> visibility </span>
              </button>
              <button
                (click)="uploadPurchaseOrder(purchaseorder)"
                *ngIf="
                  purchaseorder.UploadStatus !== 'Uploaded' &&
                  purchaseorder.deliveryAttn !== 'Error No Direct Shipment Address'
                "
                class="btn btn-blue mr-4"
                tooltip="Upload to DDS"
                [disabled]="uploadingPO"
              >
                <span class="material-icons invert" *ngIf="!uploadingPO"> system_update_alt </span>
                <span class="material-icons" *ngIf="uploadingPO"> history </span>
              </button>
              <button
                *ngIf="purchaseorder.UploadStatus !== 'Uploaded'"
                (click)="deletePurchaseOrder(purchaseorder)"
                tooltip="Delete PO"
                class="btn btn-delete"
              >
                <span class="material-icons"> delete </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div class="pagination-container" *ngIf="purchaseorders.length > 0">
    <pagination
    *ngIf="showPagination"
      [disabled]="paginationMode"
      [boundaryLinks]="true"
      [totalItems]="pagination.totalItems"
      [(ngModel)]="pagination.currentPage"
      [itemsPerPage]="pagination.itemsPerPage"
      (pageChanged)="pageChanged($event)"
      [maxSize]="maxsize"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
      firstText="&laquo;"
      lastText="&raquo;"
    >
    </pagination>
  </div>
</div>

<div id="triangle-left" *ngIf="setVisible" class="tw-absolute tw-rounded-sm tw-z-10"  [ngStyle]="{'left': realXtop+'px' , 'top': realYtop+'px' }">

</div>

<div  id="clickbox" [ngClass]="{'tw-invisible': !setVisible}" class="tw-z-20 tw-absolute tw-border-4 tw-bg-white tw-border-aedorangel tw-rounded-lg" [ngStyle]="{'left': Xtop+'px' , 'top': Ytop+'px' }"  *ngIf="showSODetails">
  <div *ngIf="itemsSO.length > 0" class=" tw-p-3 tw-mt-2">
  <div class="tw-mb-1 tw-text-lg tw-text-aedoranges tw-flex tw-justify-between tw-items-center"><span class="tw-font-bold"> {{SOselected?.SONumber}} | {{SOselected?.firstName}} {{SOselected?.lastName}} </span> <i (click)="showSODetails = false; setVisible = false; itemsSO = []" class="material-icons tw-cursor-pointer tw-text-aedorangel">cancel</i></div>
  <div class="tw-mb-1 tw-text-base tw-text-aedblue"><span class="tw-font-bold">Items</span></div>
    
    <div  *ngFor="let soitem of itemsSO;let impar = odd " class="tw-p-1" [ngClass]="impar ? 'tw-bg-gray-50' :'tw-bg-gray-100'">

      <div *ngIf="soitem?.itemType != 'discount'" >
        <div [ngClass]="{'tw-text-aedoranges tw-animate-pulse': SOselected?.SOItemsID == soitem?.SOItemsID && itemsSO.length > 1}" class="tw-grid tw-grid-cols-2">
            <div>
              <div>{{ soitem.manufacter}}</div>
              <div>{{ soitem.Type}} ({{ soitem.sizeName}})</div>
              <div class="tw-font-bold">{{ soitem.itemDelivery}}</div>
            </div>
            <div>
              <div>{{ soitem.styleName}}</div>
              <div>{{ soitem.SKU}}</div>
              <div class="tw-font-bold tw-text-end ">{{ soitem.quantityNum }} x ${{ soitem.priceNumUnit }} | ${{ soitem.priceNumUnit * soitem.quantityNum | number: "1.2-2" }}</div>
            </div>                        
        </div>           
      </div>
      
      <div *ngIf="soitem?.itemType == 'discount'">

        <div class="tw-grid tw-grid-cols-2">
          <div>
            <!-- <div>No se puede</div> -->
            <div>{{ soitem.Type}} <span *ngIf="soitem?.sizeName != null">({{ soitem.sizeName}})</span> </div>
            <div class="tw-font-bold">{{ soitem.itemDelivery}}</div>
            <div class="tw-text-aedblue tw-font-bold">{{ soitem.manufacter}}</div>
          </div>

          <div>
            <!-- <div>No se puede </div> -->
            <div>{{ soitem.SKU}}</div>
            <div class="tw-font-bold">{{ soitem.styleName}}</div>
            <div *ngIf="soitem.manufacter == 'DISCOUNT ABOVE ITEM'" class="tw-font-bold tw-text-end">  {{(soitem.priceNumUnit/soitem.priceNum) * 100 | number: "1.2-2"}}% ({{soitem.priceNum * -1}}) | ${{ soitem.priceNumUnit * -1 | number: "1.2-2"}}</div>
            <div *ngIf="soitem.manufacter == 'ORDER DISCOUNT'" class="tw-font-bold tw-text-end">  {{((soitem.priceNumUnit * -1)/soitem.priceNum) * 100 | number: "1.2-2"}}% ({{soitem.priceNum}}) | ${{ soitem.priceNumUnit * -1| number: "1.2-2"}}</div>
          </div>

        </div>

      </div>        

    </div>

  </div >

  <div *ngIf="itemsSO.length == 0" class="tw-text-center tw-font-semibold tw-text-gray-500">
    Loading...
  </div>

  <!-- <div *ngIf="deliveriesdropdown.length > 0" class="tw-bg-white tw-p-1 tw-mt-2">
    <div class="tw-mb-1 tw-text-base tw-text-aedblue"><span class="tw-font-bold">Deliveries</span></div>
    <div  *ngFor="let delivery of deliveriesdropdown;let impar = odd " class="tw-p-1" [ngClass]="impar ? 'tw-bg-gray-50' :'tw-bg-gray-100'">

        <div class="tw-grid tw-grid-cols-2">
            <div>
              <div>{{ delivery?.Type}}</div>
              <div>{{ delivery?.deliveryNotes}} </div>
            </div>
            <div>
              <div class="tw-font-bold tw-text-end">{{ delivery?.deliveryAmountNum | currency}} </div>
              <div>{{ delivery?.deliveryCode}}</div>
            </div>                        
        </div>

    </div>
  </div> -->

  <!-- <div *ngIf="paymentsdropdown.length > 0" class="tw-bg-white tw-p-1 tw-mt-2">
    <div class="tw-mb-1 tw-text-base tw-text-aedblue"><span class="tw-font-bold">Payments</span></div>
    <div  *ngFor="let payment of paymentsdropdown;let impar = odd " class="tw-p-1" [ngClass]="impar ? 'tw-bg-gray-50' :'tw-bg-gray-100'">

      <div class="tw-grid tw-grid-cols-2">
          <div>
            <div>{{ payment?.paymentType}}</div>
            <div>{{ payment?.paymentDetail}}</div>
          </div>
          <div>
            <div class="tw-font-bold tw-text-end">{{ payment?.paymentAmount | currency}}</div>
            <div>{{ payment?.Note}}</div>
          </div>                        
      </div>

    </div>
  </div> -->
</div>

