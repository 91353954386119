import { Component, OnInit, OnDestroy, ViewChild, ElementRef, TemplateRef, ChangeDetectorRef, DoCheck } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/_services/events.service';
import 'rxjs/add/operator/takeUntil';
import { PopoverConfig } from 'ngx-bootstrap/popover';
import { Subject } from 'rxjs';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';


export function getPopoverConfig(): PopoverConfig {
  return Object.assign(new PopoverConfig(), {
    
    container: 'body',
    triggers: 'focus click',
    delay: 500
  });
}

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  providers: [{ provide: PopoverConfig, useFactory: getPopoverConfig }]
  
})


export class NavComponent implements OnInit, OnDestroy,DoCheck {
  destroy$: Subject<boolean> = new Subject<boolean>();


  avatar:any = this.avatara();
  isCollapsed:string = '';
  model: any = {};
  isTouched = false;
  photoUrl: string;
  notiheight: number = window.innerHeight - 130;
  showAlert :number = 0;
  settingMenu = true;

  userRole = '';
  popupHtml = '';
  user: any;
  cfsat: any;
  events = [];
  eventsToday = [];
  lastEvents = [];
  isEventRoute = false;
  yo:any
  platform = '';
  showMobile = false;
  isIOS = false;
  isAndroid = false;
  sifunciono = "nofunciono";
  actionInSO:boolean = false

  showcurrrentLoc:boolean = false;
  currrentLoc:string = '';
  typeCorporate:any = this.authService.decodeToken('corporateType').corporateType
  
  constructor(
      public authService: AuthService,
      private alertify: AlertifyService,
      public router: Router,
      public bsModalRef: BsModalRef,
      private eventService: EventsService,
		  private translate: TranslateService
    ) { }

  onClick(event) {
      if (((event.target as HTMLElement).id).toString().includes("settingspop") === false ) {
        this.closeSettings('');
      } // or some similar check 
  }
  
  ngDoCheck() { 
    if(this.router.url.includes('salesorder')){      
      if(this.actionInSO == false){
        this.isCollapsed = ''
        this.actionInSO = true
      }
    }else{   
      if(this.actionInSO == true){
        if(this.showMobile){
        this.isCollapsed = ''
        }else{
        this.isCollapsed = 'halfwidth'          
        }
      }
      this.actionInSO = false      
    }
  }

  closeSideBar() {
    // dependiendo esta variable cambiaremos de half or halfhalf
    if(this.actionInSO){
      if(this.isCollapsed == ''){
        this.isCollapsed = 'halfhalfwidth'
      }else{
        this.isCollapsed = ''
      }
    }else{
      if(this.isCollapsed == ''){
        this.isCollapsed = 'halfwidth'
      }else{        
        this.isCollapsed = ''
      }
    }
  }
  
  getCurrentLoc(){
    if(this.typeCorporate == 'multistore'){

      this.authService.getLocations({'dbServerC':this.authService.decodeToken('dbServerC').dbServerC}).
      toPromise()
      .then((data:any[])=>{
        
        if(data.length > 1){
          let roidrow = data.filter((x:any)=> x.dbServer == this.authService.decodeToken('dbServer').dbServer); 
          this.currrentLoc = roidrow[0].territory
  
          if(this.userRole != 'Dealer'){
            let allLoc = JSON.parse(this.authService.decodeToken('user').user).allLocations;
            if(allLoc == 1){
              this.showcurrrentLoc = true       
            }else{
              let onlyLocation = [];
              let locUser:any = JSON.parse(this.authService.decodeToken('user').user).locations;
              if(locUser != null){
                data.forEach(element => {
                  if(locUser.includes(element.dbServer)){
                    onlyLocation.push(element)
                  }         
                }); 
                if (onlyLocation.length > 1) {            
                  this.showcurrrentLoc = true
                }  
              }
            }
          }else{
            this.showcurrrentLoc = true
          }
        }else{
          this.showcurrrentLoc = false
        }
  
  
      })
      .catch((err)=>{console.log(err)})

    }

  }

  ngOnInit() {

    this.authService.changeavatar.subscribe((data)=>{ 
      this.avatar = this.avatara(); 
      this.getCurrentLoc();
     })  
     this.authService.changeLocName.subscribe((data)=>{ 
      this.currrentLoc = data; 
     }) 

    this.authService.currentPhotoUrl.subscribe(photoUrl => this.photoUrl = photoUrl);
    if (this.loggedIn()) {
      this.getRole(); 
    }
    


    
    this.cfsat = this.authService.decodeToken('cfsat').cfsat;
    this.user = this.authService.currentUser;
    var hasTouchscreen = 'ontouchstart' in window;
    var w = window.innerWidth;    


   this.popupHtml=`<div *ngIf="loggedIn()"   >                
   <h6>{{ user.name | titlecase }}</h6>
   <p class="m-0">{{ user.name | titlecase }}@{{ cfsat }}
   </p>
 </div>
 
   <div *ngIf="userRole == 'Admin' || userRole == 'Dealer' || userRole == 'Manager' " routerLinkActive="active" class="idebar-1-content-account-info-links-link">
     <a [routerLink]="['/storeconfig']"> Settings </a>
   </div>`; 

   this.platform = navigator.userAgent;
   if (this.platform.match(/Android/i)) {
     this.showMobile = true;
     this.isAndroid = true;
   }
   if (this.platform.match(/iPhone|iPad|iPod/i)) {
     this.showMobile = true;
     this.isIOS = true;

   }

   if (this.platform.includes('Mac')) {
     this.showMobile = false;

   }

   if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
    this.showMobile = true;
    this.isIOS = true;
    this.sifunciono = "si funciono"
  }

   if (this.platform.includes('Win')) {
     this.showMobile = false;

   }

   if(this.showMobile){
    this.isCollapsed = ''
   }else{
    this.isCollapsed = 'halfwidth'
   }
    
  }

  avatara(){
    let username = this.authService.decodeToken('username').username;
    let numwords:any = username.split(" ");
    let word = '';
    if(numwords.length > 0){
      if(numwords.length > 1){
        word = numwords[0].slice(0,1) + numwords[1].slice(0,1);
      }else{
        word = numwords[0].slice(0,2)
      }
    }       
    return word;
  }


  loggedIn() {
    const token = localStorage.getItem('auth_token');
    return !!token;
  }

  async logout() {
    this.yo = true;

    if(this.router.url.slice(0, 12) == '/salesorder/'  ||  this.router.url.slice(0, 12)  =='/storeconfig'){
    
      await new Promise((resolve,reject)=>{    
      this.alertify.confirm(this.translate.instant('LOGOUT_CONFIRMATION'),
      ()=>{
        resolve(this.yo = true) 
      }
      ,()=>{
      }
      );     

      setTimeout(() => {  
      reject(this.yo = false) 
      }, 10000)    
      });

    }else{
      this.yo = true
    }

  if(this.yo){
      this.authService.showunsavedSO.next('No')
      this.authService.showunsavedSett.next('No')
      localStorage.removeItem('auth_token');
      localStorage.removeItem('products');

      this.authService.decodedToken = null;
      this.authService.currentUser = null;
      this.alertify.message('logged out');
      window.location.href =environment.linkLogin;  
    }

  }

  getRole() {
    this.userRole = this.authService.decodeToken('role').role; 
    this.user = JSON.parse(this.authService.decodeToken('user').user); 
  }

  openSettings() {
    this.settingMenu =! this.settingMenu;
  }

  closeSettings(event: string) {
    this.settingMenu =! false;

    this.eventService.pagPOSummary.next(1);
    this.eventService.statusPOSummary.next('');
    this.eventService.viewPOSummary.next('');
    this.eventService.inputSearchPOSummary.next('');
    this.eventService.inputSearchSOList.next('');

    
    this.eventService.voidSOsList.next(false);
    this.eventService.excludeSOsList.next(false);
    this.eventService.statusSOsList.next(''); 
    this.eventService.viewSOsList.next(''); 

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Nav',
    //   action: event,
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).subscribe(a => {
    //   // console.log(a)
    // });
    
  }

  ngOnDestroy(): void { 
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}