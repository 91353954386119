
 <!-- <div class="sale-order-summary-items">
  <div class="sale-order-summary-items-item">
    <div class="sale-order-summary-items-item-text">
      <p>Items</p>
    </div>
    <div class="sale-order-summary-items-item-amount">
      <p>{{ salesOrderItems | number: "1.0" }}</p>
    </div>
  </div>
  <div class="sale-order-summary-items-item">
    <div class="sale-order-summary-items-item-text">
      <p>Discount</p>
    </div>
    <div class="sale-order-summary-items-item-amount">
      <p>${{ salesOrderDiscounts | number: "1.2-2" }}</p>
    </div>
  </div>
  <div class="sale-order-summary-items-item">
    <div class="sale-order-summary-items-item-text">
      <p>Current Total</p>
    </div>
    <div class="sale-order-summary-items-item-amount">
      <p>${{ salesOrderSubTotal | number: "1.2-2" }}</p>
    </div>
  </div>
  <div class="sale-order-summary-items-item">
    <div class="sale-order-summary-items-item-text">
      <p>Delivery</p>
    </div>
    <div class="sale-order-summary-items-item-amount">
      <p>${{ salesOrderDelivery | number: "1.2-2" }}</p>
    </div>
  </div>
  <div class="sale-order-summary-items-item">
    <div class="sale-order-summary-items-item-text">
      <p>Taxes</p>
    </div>
    <div class="sale-order-summary-items-item-amount">
      <p>${{ salesOrderTaxes | number: "1.2-2" }}</p>
    </div>
  </div>
  <div class="sale-order-summary-items-item">
    <div class="sale-order-summary-items-item-text big-text">
      <p>Total</p>
    </div>
    <div class="sale-order-summary-items-item-amount big-text">
      <p>${{ salesOrderTotal | number: "1.2-2" }}</p>
    </div>
  </div>
  <div class="sale-order-summary-items-item">
    <div class="sale-order-summary-items-item-text">
      <p>Payments</p>
    </div>
    <div class="sale-order-summary-items-item-amount">
      <p>${{ salesOrderPayments | number: "1.2-2" }}</p>
    </div>
  </div>
  <div class="sale-order-summary-items-item">
    <div class="sale-order-summary-items-item-text">
      <p *ngIf="salesOrderTotal - salesOrderPayments > 0">Balance</p>
      <p *ngIf="salesOrderTotal - salesOrderPayments < 0">Change</p>
    </div>
    
    <div class="sale-order-summary-items-item-amount" *ngIf="salesOrderTotal - salesOrderPayments > 0">
      <p>${{ salesOrderBalance | number: "1.2-2" }}</p>
    </div>
    <div class="sale-order-summary-items-item-amount" *ngIf="salesOrderTotal - salesOrderPayments < 0">
      <p>${{ salesOrderBalance * -1 | number: "1.2-2" }}</p>
    </div>
  </div>
</div>  -->


<div class="tw-grid tw-text-aedgrayl tw-text-lg">

  <div class="tw-flex tw-px-2">
    <div>Items</div>
    <div class="tw-ml-auto">{{ salesOrderItems | number: "1.0" }}</div>
  </div>

  <div class=" tw-px-2 tw-flex tw-rounded-sm" style="background: #dadada;">
    <div>Discount</div>
    <div class="tw-ml-auto">${{ salesOrderDiscounts | number: "1.2-2" }}</div>
  </div>

  <div class="tw-flex tw-px-2">
    <div>Current Total</div>
    <div class="tw-ml-auto">${{ salesOrderSubTotal | number: "1.2-2" }}</div>
  </div>
  
  <div class="tw-flex tw-px-2" style="background: #dadada;">
    <div>Delivery</div>
    <div class="tw-ml-auto">${{ salesOrderDelivery | number: "1.2-2" }}</div>
  </div>

  <div class="tw-flex tw-px-2 tw-justify-between">
    <div>Taxes</div>
    <button *ngIf="showCalculateTaxes && avaTaxActive" class="btn-orange-so-taxes" (click)="calculateTax()">Calculate</button>
    <div *ngIf="!showCalculateTaxes || !avaTaxActive"   class="tw-ml-auto">${{ ((salesOrder?.totalTaxSum != undefined && salesOrder?.totalTaxSum != null) ? salesOrder?.totalTaxSum : salesOrderTaxes )| number: "1.2-2" }}</div>
  </div>
  
  <div class="tw-flex  tw-px-2 tw-text-2xl tw-font-bold" style="background: #dadada;">
    <div>Total</div>
    <div class="tw-ml-auto">${{ salesOrderTotal | number: "1.2-2" }}</div>
  </div>
  
  <div class="tw-flex tw-px-2 ">
    <div>Payments</div>
    <div class="tw-ml-auto">${{ salesOrderPayments | number: "1.2-2" }}</div>
  </div>
  
  <div class="tw-flex tw-px-2 " *ngIf="salesOrderTotal - salesOrderPayments >= 0 " style="background: #dadada;">
    <div>Balance</div>
    <div class="tw-ml-auto">${{ salesOrderBalance | number: "1.2-2" }}</div>
  </div>
  
  <div class="tw-flex tw-px-2 " style="color: red;" *ngIf="salesOrderTotal - salesOrderPayments < 0" style="background: #dadada;">
    <div>Change</div>
    <div class="tw-ml-auto">${{ salesOrderBalance * -1 | number: "1.2-2" }}</div>
  </div>

</div>

