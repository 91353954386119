
  <div  class="alert alert-dismissable alert-primary animated fadeInUp" [ngClass]="{ active: alert === true, '': alert }">
    <span class="message">The Sales Order has been modified, if you leave without saving all your changes will be lost.</span>
    {{alert}}
    <!-- <button type="button" class="btn btn-close" data-dismiss="alert" aria-label="Close" (click)="closeAlert(false)">
      <span class="material-icons">
        close
        </span>
    </button> -->
  </div>

  <div  class="alert alert-dismissable alert-primary animated fadeInUp" >
    <!-- <span class="message">If you want to continue please <a href="">save</a> your Sales Order.</span> -->
    <span class="message">If you want to continue please save your Sales Order.</span>
    <!-- <button type="button" class="btn btn-close" data-dismiss="alert" aria-label="Close" (click)="closeAlert(false)">
      <span class="material-icons">
        close
        </span>
    </button> -->
  </div>