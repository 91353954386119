<div class="tw-rounded-sm" *ngFor="let pay of filterPayments(payments); let impar = odd" 
[ngClass]="impar ? 'tw-bg-aedgraypaydel' :''">
  
  <div class="tw-flex tw-px-2">
    <div class="tw-font-bold"><strong *ngIf="pay.voidtype_tx != undefined && pay.voidtype_tx != null">({{ 'VOIDED' | translate }}) &nbsp; </strong>{{pay?.paymentType}}</div>
    <div class="item-amount tw-ml-auto tw-font-bold ">$ {{pay.paymentAmount | number:'1.2-2'}}</div>
  </div>

  <div class="tw-flex tw-px-2" *ngIf="pay?.paymentDetail">
    <div class="tw-font-bold">{{ 'INTERNAL_NOTES' | translate }}</div>
    <div class="item-type tw-ml-auto">{{pay.paymentDetail}}</div>  
  </div>
  
  <div class="tw-flex tw-px-2" *ngIf="pay?.Note">
    <div class="tw-font-bold">{{ 'RECEIPT_NOTES' | translate }}</div>
    <div class="item-type tw-ml-auto">{{pay?.Note}}</div>  
  </div>

</div>