import { Injectable, EventEmitter } from '@angular/core';
declare let alertify: any;
import alertifyjs from 'alertifyjs';
window['alertify'] = alertifyjs;

@Injectable({
  providedIn: 'root'
})
export class AlertifyService {

constructor() { }

alertevent: EventEmitter<string> = new EventEmitter();

raiseAlertEvent() {
  this.alertevent.next('');
}

confirm(message: string, okCallback: () => any,cancelCallback:() => any) {
  alertify.confirm(message, 
  function(e) {
    if (e) {
      okCallback();
    } else {}
  },
  function(e) {
    if (e) {
      cancelCallback();
    } else {}
  }
  
  ).setHeader('<img style="width:8rem" src="assets/aedpayconfirm.png" />').set('labels', 
{ok:'Yes', 
cancel:'No'}); 
}


confirmDate(contenidoHTML: string, okCallback: (e) => any,cancelCallback:() => any) {
  alertify.confirm(contenidoHTML, 
    function(evt: any, value: string) {
      const opcionSeleccionada = (document.querySelector('input[name="opcion"]:checked') as HTMLInputElement)?.value;  
      if (opcionSeleccionada) {
        okCallback(opcionSeleccionada)
      } else {
        alertify.warning('No date selected');
      }
    },

  function(e) {
    if (e) {
      cancelCallback();
    } else {}
  }
  
  ).setHeader('<img style="width:8rem" src="assets/aedpayconfirm.png" />').set('labels', 
{ok:'Select Date', 
cancel:'Cancel'}); 


const css = `
  .radio-cuadro {
    display: flex;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 10px;
    width:7rem;
    height:7rem;
  }
  
  input[type="radio"] {
    display: none;
  }
  
  input[type="radio"] + label {
    cursor: pointer;
  }
  
  input[type="radio"]:checked + label {
    background-color: #007bff;
    color: #fff;
  }
`;

const style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);

}
// '<svg class="tw-h-5 tw-w-5 tw-mr-2" xmlns="http://www.w3.org/2000/svg" width="16.442" height="15.727" viewBox="0 0 16.442 15.727">'+
// '<defs><style>.cls-1 {fill: none;stroke: #fff;stroke-linecap: round;stroke-linejoin: round;stroke-width: 3px;}</style></defs>'+
// '<path id="Path_174" data-name="Path 174" class="cls-1" d="M32.1,19.076l5.217,6.058L44.38,12.957" transform="translate(-29.988 -10.907)"/></svg>'+
// ' Yes '
// '<span style="display: flex;margin-left: auto;margin-right: auto;"> <svg class="tw-h-5 tw-w-5 tw-mr-2" xmlns="http://www.w3.org/2000/svg" width="14.708" height="14.708" viewBox="0 0 14.708 14.708">'+
// '<defs><style>.cls-1 {fill: none;stroke: #fff;stroke-linecap: round;stroke-width: 3px;}</style></defs>'+
// ' <g id="Group_235" data-name="Group 235" transform="translate(2.121 2.121)">'+
// '  <line id="Line_5" data-name="Line 5" class="cls-1" x2="10.466" y2="10.466" transform="translate(0)"/>'+
// '  <line id="Line_6" data-name="Line 6" class="cls-1" x1="10.466" y2="10.466" transform="translate(0)"/></g></svg>'+
// '  No </span>'
success(message: string) {
  alertify.success(message);
  this.raiseAlertEvent();
}

alert(message: string, okCallback: () => any) {
  alertify.alert(message, 
    (e)=>{
    if(e) okCallback()
  })
  .setHeader('<img style="width:8rem" src="assets/aedpayconfirm.png" />');
  // this.raiseAlertEvent();
}

error(message: string) {
  alertify.error(message);
  this.raiseAlertEvent();
}

warning(message: string) {
  alertify.warning(message);
  this.raiseAlertEvent();
}

message(message: string) {
  alertify.message(message);
  this.raiseAlertEvent();
}

prompt(title, message, val, okCallback: (evt,value) => any,cancelCallback:() => any) {
  alertify.prompt( title, message, val
               , function(evt:any, value:any) {  okCallback(evt,value); }
               , function() {cancelCallback();})
               .setHeader('<img style="width:8rem" src="assets/aedpayconfirm.png" />');
  }

  close() {
    alertify.closeAll();
  }

  promptTimer(
    title: string,
    message: string,
    val,
    timeoutInSeconds: number, // La duración en segundos
    okCallback: (evt: any, value: any) => any,
    cancelCallback: () => any
  ) {
    let timeLeft = timeoutInSeconds;
    let text = document.createElement('div');

    const timerInterval = setInterval(() => {
      if (timeLeft <= 0) {
        clearInterval(timerInterval);
        // alertify.closeAll(); // Cierra automáticamente el prompt cuando el tiempo se agota 
      }
      timeLeft--;
      text.innerText = `Remaining time: ${timeLeft} seconds`;
    }, 1000);

    alertify.prompt( title, message, val
      , function(evt:any, value:any) {  okCallback(evt,value); }
      , function() {cancelCallback();})
      .setHeader(text)
  }
}


