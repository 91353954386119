<div  *ngIf="so?.customerId"  class="tw-flex tw-justify-between" >  
  
<div class="tw-flex tw-flex-row tw-items-center" >
  <h1 class="tw-text-2xl sm:tw-text-lg md:tw-text-3xl lg:tw-text-3xl tittle-0">Customer</h1>     
  <svg *ngIf="halftwoiconsLabel" class="tw-w-10 tw-h-10 ml-2" xmlns="http://www.w3.org/2000/svg" width="47.633" height="38.7" viewBox="0 0 47.633 38.7">
    <defs>
      <style>
        .cls-10 {
          fill: #f97316;
        }
      </style>
    </defs>
    <g id="Group_124" data-name="Group 124" transform="translate(2.814 0.85)">
      <g id="Group_4" data-name="Group 4" transform="translate(0 0)">
        <path id="Path_3" data-name="Path 3" class="cls-10" d="M110.091,155.993h-.009c-5.077-.011-10.079-.012-14.728-.012H85.886a1.693,1.693,0,0,1-1.311-.341,1.639,1.639,0,0,1-.363-1.307c0-1.66,0-3.284,0-4.9a13.717,13.717,0,0,1,8.7-12.793q.264-.105.528-.2a10.068,10.068,0,0,1-4.244-4.053,10.12,10.12,0,0,1,8.927-15.095c.213,0,.43.006.644.019a9.747,9.747,0,0,1,6.1,2.719,10.105,10.105,0,0,1-1.28,15.793,10.593,10.593,0,0,1-1.046.618,13.661,13.661,0,0,1,5.481,3.609,13.324,13.324,0,0,1,3.7,9.155c.009.746.006,1.5,0,2.232,0,.954-.008,1.939.016,2.9a1.633,1.633,0,0,1-.395,1.227A1.671,1.671,0,0,1,110.091,155.993ZM95.354,154.28c4.636,0,9.622,0,14.686.012-.022-.958-.018-1.925-.014-2.862,0-.724.006-1.472,0-2.2a11.785,11.785,0,0,0-.847-4.318,12.417,12.417,0,0,0-9.646-7.483l-1.547-.248-1.467.259a15.114,15.114,0,0,0-2.972.779,12.017,12.017,0,0,0-7.633,11.218c0,1.6,0,3.207,0,4.847h9.443Zm2.77-35.288a8.511,8.511,0,0,0-8.39,6.662,8.623,8.623,0,0,0,.944,5.9,8.755,8.755,0,0,0,5.884,4.219l1.407.329,1.506-.343a8.809,8.809,0,0,0,5.4-3.546,8.4,8.4,0,0,0-1.181-10.947,8.047,8.047,0,0,0-5.024-2.257C98.489,119,98.305,118.993,98.125,118.993Z" transform="translate(-77.39 -118.143)"/>
        <path id="Path_4" data-name="Path 4" class="cls-10" d="M28.722,190.545h-.013c-1.389-.007-2.821-.01-4.256-.01-1.4,0-2.846,0-4.3.01h-.012a1.288,1.288,0,0,1-1.4-1.37,10.073,10.073,0,0,1,1.079-5.5,8.793,8.793,0,0,1,4.114-3.805,7.294,7.294,0,0,1,2.717-13.524,7.17,7.17,0,0,1,.984-.068,7.31,7.31,0,0,1,5.168,2.189,7.166,7.166,0,0,1,2.091,5.17,2.06,2.06,0,0,1-.668,1.537,11.826,11.826,0,0,0-2.765,3.841,2.74,2.74,0,0,1-.741,1.025,1.5,1.5,0,0,1-.041,1.14c-.028.091-.059.194-.092.31A13.68,13.68,0,0,0,30.109,185c-.02,1.2-.02,2.468,0,4.123a1.429,1.429,0,0,1-.362,1.045A1.406,1.406,0,0,1,28.722,190.545Zm-4.27-1.71c1.331,0,2.66,0,3.954.009-.018-1.526-.017-2.729,0-3.869a15.351,15.351,0,0,1,.543-3.942l.032-.112-.095-.024a1.013,1.013,0,0,1-.7-.387l-1.538.294a7.393,7.393,0,0,0-5.352,3.718,8.219,8.219,0,0,0-.864,4.322C21.795,188.837,23.144,188.834,24.453,188.834Zm3.185-20.854a5.463,5.463,0,0,0-.75.052,5.768,5.768,0,0,0-3.325,1.709,5.222,5.222,0,0,0-1.505,3.249,5.922,5.922,0,0,0,.972,3.773,5.508,5.508,0,0,0,3.073,2.2c.116.033.23.069.374.113l.263.081,1.029.315-.041-.117.869-.245.165-.048c.133-.039.27-.079.417-.114a.913.913,0,0,0,.726-.608,13.513,13.513,0,0,1,3.154-4.4.373.373,0,0,0,.137-.329,5.479,5.479,0,0,0-1.6-3.951A5.6,5.6,0,0,0,27.638,167.98Z" transform="translate(-21.545 -152.697)"/>
        <path id="Path_170" data-name="Path 170" class="cls-10" d="M24.905,190.545a1.406,1.406,0,0,1-1.026-.373,1.429,1.429,0,0,1-.362-1.045c.021-1.655.021-2.926,0-4.123a13.681,13.681,0,0,0-.479-3.511c-.033-.116-.064-.219-.092-.31a1.5,1.5,0,0,1-.041-1.14,2.739,2.739,0,0,1-.741-1.025,11.826,11.826,0,0,0-2.765-3.841,2.061,2.061,0,0,1-.668-1.538,7.166,7.166,0,0,1,2.091-5.17,7.31,7.31,0,0,1,5.168-2.189,7.169,7.169,0,0,1,.984.068,7.294,7.294,0,0,1,2.717,13.524,8.793,8.793,0,0,1,4.113,3.805,10.073,10.073,0,0,1,1.079,5.5,1.288,1.288,0,0,1-1.4,1.37h-.011c-1.452-.007-2.9-.01-4.3-.01-1.435,0-2.867,0-4.256.01Zm-.262-9.625.032.113a15.351,15.351,0,0,1,.543,3.942c.019,1.139.02,2.343,0,3.868,1.294-.006,2.623-.009,3.954-.009,1.31,0,2.66,0,4.018.009a8.219,8.219,0,0,0-.864-4.322,7.393,7.393,0,0,0-5.352-3.718l-1.538-.294a1.013,1.013,0,0,1-.7.387Zm1.347-12.94a5.6,5.6,0,0,0-3.957,1.682,5.479,5.479,0,0,0-1.6,3.951.373.373,0,0,0,.137.329,13.513,13.513,0,0,1,3.154,4.4.914.914,0,0,0,.727.608c.146.035.283.075.416.114l.165.048.869.245-.041.117,1.029-.315.263-.081c.144-.045.258-.08.375-.113a5.508,5.508,0,0,0,3.073-2.2,5.923,5.923,0,0,0,.972-3.773,5.222,5.222,0,0,0-1.505-3.249,5.768,5.768,0,0,0-3.325-1.709A5.461,5.461,0,0,0,25.99,167.98Z" transform="translate(9.922 -152.697)"/>
      </g>
    </g>
  </svg>
</div>

  <div class="tw-flex" *ngIf="!canSelect">

    <button class="btn btn-blue-static mr-1" (click)="openModalWithComponent()">
      <svg class="tw-h-10 tw-w-10" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="51.491" height="51.473" viewBox="0 0 51.491 51.473">
        <defs>
          <style>
            .cls-101 {
              fill: none;
            }
      
            .cls-201 {
              clip-path: url(#clip-path);
            }
      
            .cls-301 {
              fill: #00adee;
            }
          </style>
          <clipPath id="clip-path">
            <rect id="Rectangle_79" data-name="Rectangle 79" class="cls-101" width="51.491" height="51.473"/>
          </clipPath>
        </defs>
        <g id="Group_34" data-name="Group 34" class="cls-101">
          <path id="Path_48" data-name="Path 48" class="cls-301" d="M0,47.118a6.871,6.871,0,0,1,2.078-3.357C4.86,41.009,15.35,30.521,18.117,27.755c.543-.543.576-.711.192-1.378A17.386,17.386,0,0,1,16.1,19.919a16.9,16.9,0,0,1,1.636-9.951A17.778,17.778,0,0,1,36.511.232,17.673,17.673,0,0,1,50.4,11.643a17.488,17.488,0,0,1,.989,7.971,17.439,17.439,0,0,1-5.654,11.254,17.386,17.386,0,0,1-20.389,2.44c-.112-.061-.222-.128-.335-.187a.8.8,0,0,0-1.177.167c-.3.3-.6.6-.906.9-2.495,2.5-12.733,12.691-15.212,15.2a6.864,6.864,0,0,1-3.357,2.078H2.9a4.307,4.307,0,0,1-2.9-2.9ZM46.747,17.79A13.035,13.035,0,1,0,33.682,30.816,13.019,13.019,0,0,0,46.747,17.79" transform="translate(0 0)"/>
        </g>
      </svg>
    </button>
    <!-- edit -->
    <button class="btn btn-blue-static" (click)="editSalesOrder(so)" type="button">
      <svg id="Component_59_102" data-name="Component 59 – 102" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25.674" height="25.681" viewBox="0 0 25.674 25.681">
        <defs>
          <style>
            .cls-15 {
              fill: #00adee;
            }
      
          
          </style>
          <clipPath id="clip-path">
            <rect id="Rectangle_156" data-name="Rectangle 156" class="cls-15" width="25.674" height="25.681"/>
          </clipPath>
        </defs>
        <g id="Group_63" data-name="Group 63" class="">
          <path id="Path_76" data-name="Path 76" class="cls-15" d="M25.674,4.884a5.154,5.154,0,0,1-1.642,2.877C21,10.767,17.983,13.8,14.961,16.819Q12.306,19.475,9.65,22.13c-.313.313-.633.618-.946.931a1.42,1.42,0,0,1-.578.335Q4.861,24.481,1.6,25.572c-.095.032-.186.073-.279.109h-.6a1.094,1.094,0,0,1-.594-1.576q1.09-3.266,2.177-6.534a1.567,1.567,0,0,1,.389-.631Q10.514,9.121,18.337,1.3A4.289,4.289,0,0,1,25.3,2.465a5.738,5.738,0,0,1,.377,1.216ZM7.95,20.871c.089-.02.135-.1.192-.153q6.486-6.483,12.975-12.964c.166-.165.138-.246-.011-.392Q19.716,5.988,18.343,4.6c-.159-.162-.245-.167-.41,0q-2.648,2.667-5.312,5.319-3.827,3.827-7.657,7.651c-.133.133-.16.211-.012.357.946.933,1.881,1.875,2.821,2.813.052.052.1.118.176.136M23.641,4.2c0-.013-.014-.1-.025-.178A2.1,2.1,0,0,0,22.107,2.18,2.2,2.2,0,0,0,19.73,2.8c-.1.1-.1.171,0,.27q1.454,1.447,2.9,2.9c.086.086.155.1.252.009A2.349,2.349,0,0,0,23.641,4.2M5.976,21.876a.9.9,0,0,0-.07-.088q-1-1-2-2c-.1-.1-.147-.078-.188.048-.11.341-.226.681-.339,1.021q-.321.961-.641,1.923c-.02.06-.073.127-.024.189s.124.016.183,0q1.473-.488,2.944-.98c.05-.017.121-.017.135-.108" transform="translate(0 0)"/>
        </g>
      </svg>
    </button>
  </div>
  
</div>

<div  *ngIf="!so?.customerId" >
  <div    class="tw-flex tw-justify-between"  >
    
    <div class="tw-flex tw-flex-row tw-items-center" >
      <h1 class="tw-text-2xl sm:tw-text-lg md:tw-text-3xl lg:tw-text-3xl tittle-0">Customer</h1>     
      <svg *ngIf="halftwoiconsLabel" class="tw-w-10 tw-h-10 ml-2" xmlns="http://www.w3.org/2000/svg" width="47.633" height="38.7" viewBox="0 0 47.633 38.7">
        <defs>
          <style>
            .cls-10 {
              fill: #f97316;
            }
          </style>
        </defs>
        <g id="Group_124" data-name="Group 124" transform="translate(2.814 0.85)">
          <g id="Group_4" data-name="Group 4" transform="translate(0 0)">
            <path id="Path_3" data-name="Path 3" class="cls-10" d="M110.091,155.993h-.009c-5.077-.011-10.079-.012-14.728-.012H85.886a1.693,1.693,0,0,1-1.311-.341,1.639,1.639,0,0,1-.363-1.307c0-1.66,0-3.284,0-4.9a13.717,13.717,0,0,1,8.7-12.793q.264-.105.528-.2a10.068,10.068,0,0,1-4.244-4.053,10.12,10.12,0,0,1,8.927-15.095c.213,0,.43.006.644.019a9.747,9.747,0,0,1,6.1,2.719,10.105,10.105,0,0,1-1.28,15.793,10.593,10.593,0,0,1-1.046.618,13.661,13.661,0,0,1,5.481,3.609,13.324,13.324,0,0,1,3.7,9.155c.009.746.006,1.5,0,2.232,0,.954-.008,1.939.016,2.9a1.633,1.633,0,0,1-.395,1.227A1.671,1.671,0,0,1,110.091,155.993ZM95.354,154.28c4.636,0,9.622,0,14.686.012-.022-.958-.018-1.925-.014-2.862,0-.724.006-1.472,0-2.2a11.785,11.785,0,0,0-.847-4.318,12.417,12.417,0,0,0-9.646-7.483l-1.547-.248-1.467.259a15.114,15.114,0,0,0-2.972.779,12.017,12.017,0,0,0-7.633,11.218c0,1.6,0,3.207,0,4.847h9.443Zm2.77-35.288a8.511,8.511,0,0,0-8.39,6.662,8.623,8.623,0,0,0,.944,5.9,8.755,8.755,0,0,0,5.884,4.219l1.407.329,1.506-.343a8.809,8.809,0,0,0,5.4-3.546,8.4,8.4,0,0,0-1.181-10.947,8.047,8.047,0,0,0-5.024-2.257C98.489,119,98.305,118.993,98.125,118.993Z" transform="translate(-77.39 -118.143)"/>
            <path id="Path_4" data-name="Path 4" class="cls-10" d="M28.722,190.545h-.013c-1.389-.007-2.821-.01-4.256-.01-1.4,0-2.846,0-4.3.01h-.012a1.288,1.288,0,0,1-1.4-1.37,10.073,10.073,0,0,1,1.079-5.5,8.793,8.793,0,0,1,4.114-3.805,7.294,7.294,0,0,1,2.717-13.524,7.17,7.17,0,0,1,.984-.068,7.31,7.31,0,0,1,5.168,2.189,7.166,7.166,0,0,1,2.091,5.17,2.06,2.06,0,0,1-.668,1.537,11.826,11.826,0,0,0-2.765,3.841,2.74,2.74,0,0,1-.741,1.025,1.5,1.5,0,0,1-.041,1.14c-.028.091-.059.194-.092.31A13.68,13.68,0,0,0,30.109,185c-.02,1.2-.02,2.468,0,4.123a1.429,1.429,0,0,1-.362,1.045A1.406,1.406,0,0,1,28.722,190.545Zm-4.27-1.71c1.331,0,2.66,0,3.954.009-.018-1.526-.017-2.729,0-3.869a15.351,15.351,0,0,1,.543-3.942l.032-.112-.095-.024a1.013,1.013,0,0,1-.7-.387l-1.538.294a7.393,7.393,0,0,0-5.352,3.718,8.219,8.219,0,0,0-.864,4.322C21.795,188.837,23.144,188.834,24.453,188.834Zm3.185-20.854a5.463,5.463,0,0,0-.75.052,5.768,5.768,0,0,0-3.325,1.709,5.222,5.222,0,0,0-1.505,3.249,5.922,5.922,0,0,0,.972,3.773,5.508,5.508,0,0,0,3.073,2.2c.116.033.23.069.374.113l.263.081,1.029.315-.041-.117.869-.245.165-.048c.133-.039.27-.079.417-.114a.913.913,0,0,0,.726-.608,13.513,13.513,0,0,1,3.154-4.4.373.373,0,0,0,.137-.329,5.479,5.479,0,0,0-1.6-3.951A5.6,5.6,0,0,0,27.638,167.98Z" transform="translate(-21.545 -152.697)"/>
            <path id="Path_170" data-name="Path 170" class="cls-10" d="M24.905,190.545a1.406,1.406,0,0,1-1.026-.373,1.429,1.429,0,0,1-.362-1.045c.021-1.655.021-2.926,0-4.123a13.681,13.681,0,0,0-.479-3.511c-.033-.116-.064-.219-.092-.31a1.5,1.5,0,0,1-.041-1.14,2.739,2.739,0,0,1-.741-1.025,11.826,11.826,0,0,0-2.765-3.841,2.061,2.061,0,0,1-.668-1.538,7.166,7.166,0,0,1,2.091-5.17,7.31,7.31,0,0,1,5.168-2.189,7.169,7.169,0,0,1,.984.068,7.294,7.294,0,0,1,2.717,13.524,8.793,8.793,0,0,1,4.113,3.805,10.073,10.073,0,0,1,1.079,5.5,1.288,1.288,0,0,1-1.4,1.37h-.011c-1.452-.007-2.9-.01-4.3-.01-1.435,0-2.867,0-4.256.01Zm-.262-9.625.032.113a15.351,15.351,0,0,1,.543,3.942c.019,1.139.02,2.343,0,3.868,1.294-.006,2.623-.009,3.954-.009,1.31,0,2.66,0,4.018.009a8.219,8.219,0,0,0-.864-4.322,7.393,7.393,0,0,0-5.352-3.718l-1.538-.294a1.013,1.013,0,0,1-.7.387Zm1.347-12.94a5.6,5.6,0,0,0-3.957,1.682,5.479,5.479,0,0,0-1.6,3.951.373.373,0,0,0,.137.329,13.513,13.513,0,0,1,3.154,4.4.914.914,0,0,0,.727.608c.146.035.283.075.416.114l.165.048.869.245-.041.117,1.029-.315.263-.081c.144-.045.258-.08.375-.113a5.508,5.508,0,0,0,3.073-2.2,5.923,5.923,0,0,0,.972-3.773,5.222,5.222,0,0,0-1.505-3.249,5.768,5.768,0,0,0-3.325-1.709A5.461,5.461,0,0,0,25.99,167.98Z" transform="translate(9.922 -152.697)"/>
          </g>
        </g>
      </svg>
    </div>

    <div class="tw-flex">
      <div class="tw-mr-4 btn-blue-so" style="min-width: fit-content;" (click)="openModalWithComponent()">
        Add Customer
      </div>

      <button class="btn btn-blue-static-mobile mr-1" (click)="openlistCustomer()" >
        <span class="material-icons" style="font-size:1.8rem">
          search
        </span>
      </button>      
    </div>

    
  </div>
</div>

<div class="tw-flex tw-w-full tw-text-aedgrayl" *ngIf="so?.customerId !== 0">
  <div class="tw-flex tw-flex-col tw-w-1/2">
      <h5 class="tw-font-bold tw-text-aedgrayl">BILLING</h5>
      <p class="m-0">{{ so?.firstName }} {{ so?.lastName }}</p>
      <p class="m-0">{{ so?.billingAddress1 }}</p>
      <p class="m-0" *ngIf="so?.billingAddress2">{{ so?.billingAddress2 }}</p>
      <p class="m-0">{{ so?.city }} {{ so?.state }} {{ so?.zip }}</p>
      <p class="m-0">{{ so?.phoneType }} {{ so?.phone1 }}</p>
      <a href="mailto:{{ so?.email }}">{{ so?.email }}</a>
  </div>
  <div class="tw-flex tw-flex-col tw-w-1/2" *ngIf="so.deliveryRequest === 'Delivery'">
    <h5 class="tw-font-bold tw-text-aedgrayl">SHIPPING</h5>
    <p class="m-0">{{ so?.fName }} {{ so?.lName }}</p>
    <p class="m-0">{{ so?.deliveryAddress1 }}</p>
    <p class="m-0" *ngIf="so?.deliveryAddress2">{{ so?.deliveryAddress2 }}</p>
    <p class="m-0">{{ so?.deliveryCity }} {{ so?.deliveryState }} {{ so?.deliveryZip }}</p>
    <p class="m-0">{{ so?.phone3Type }} {{ so?.phone3 }}</p>
    <a class="tw-text-aedblue" href="mailto:{{ so?.deliveryEmail }}">{{ so?.deliveryEmail }}</a>
  </div>
</div>