import { Component, OnInit, Input, Output,EventEmitter } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-purchaseorder-items',
  templateUrl: './purchaseorder-items.component.html',
  styleUrls: ['./purchaseorder-items.component.scss']
})
export class PurchaseorderItemsComponent implements OnInit {
  @Input() poitems: any = [];
  @Input() poitemsItems: any = [];
  @Input() UploadStatus: string = '';
  @Input() edit: any;
  @Output() todeleteItemsSQL = new EventEmitter();
  @Output() todeletePOItemsSQL = new EventEmitter();
  poitemsItemsSelected:any = [];
  toPOdelete:any = [];
  todelete:any = [];
  userRole = '';
  filterIdPoItem:any;
  @Input() editstate: boolean
  poListDist : any = []
  poitemslist : any = []
  constructor(private authService: AuthService ) { }

  ngOnInit() {
    // console.log(this.editstate)
    


    this.userRole = this.authService.decodeToken('role').role;
    //console.log('editstate', this.editstate)
    //console.log('delete', this.poitems)
    // for(let i = 0; i < this.poitems.length; i++){
    //   for(let j = 0 ;j < this.poitems[i].Qty; j++){
    //     //------------------------

    //     //this.poListDist.push(this.poitems[i])
    //     this.poitemslist.push(this.poitems[i])

    //     //-----------------------
    //     console.log((j+1),' indice',this.poitems[i].SONumber) 
    //     console.log('arrays', this.poitemslist[i])
    //   }
     
    // }
  }

filterItems(idPoItem:any){
  this.filterIdPoItem = idPoItem;
  if(idPoItem == null){
    this.poitemsItemsSelected = [];
  }else{
    this.poitemsItemsSelected = this.poitemsItems.filter(x => x.POITID == idPoItem);
  }
}


  // deleteItem(poitem) {
  //   const toDelete = this.poitems.findIndex(obj => obj['POITID'] === poitem);
  //   this.poitems.splice(toDelete, 1 );
  // } 
  deleteItem(itemId:any,poitid:any,qty:any) {
    this.poitemsItems = this.poitemsItems.filter(obj => obj['SOItemsID'] !== itemId);
    this.poitemsItemsSelected = this.poitemsItems.filter(x => x['POITID'] === this.filterIdPoItem);
    this.todelete.push({"item":itemId,"poitid":poitid,"qty":qty})

    // verifico si ha borrado todos los id de esa PO para borrar toda la PO
    let poitemWilVerified = this.poitemsItems.filter(obj => obj['POITID'] == poitid);
    if(poitemWilVerified.length == 0){
      this.deletePOItem(poitid);
      this.todelete = this.todelete.filter(obj => obj['poitid'] !== poitid);
    }else{      
      const toUpdateQty= this.poitems.findIndex(obj => obj['POITID'] === poitid);
      this.poitems[toUpdateQty].soQtyNum = this.poitems[toUpdateQty].soQtyNum - qty;
      this.poitems[toUpdateQty].Qty = this.poitems[toUpdateQty].Qty - qty;
    }



    

    this.todeleteItemsSQL.emit(this.todelete);
  }
  
  deletePOItem(poitid:any){
    this.poitems = this.poitems.filter(obj => obj['POITID'] !== poitid);  
    this.toPOdelete.push({"poPOitid":poitid});
    this.todeletePOItemsSQL.emit(this.toPOdelete);
  }

}
