import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-choosestorestock',
  templateUrl: './choosestorestock.component.html',
  styleUrls: ['./choosestorestock.component.scss']
})
export class ChoosestorestockComponent implements OnInit {
  @Input() show: string;
  constructor() { }

  ngOnInit() {
  }

  showStoreProducts() {
    this.show = 'Store';
  }

  showStockItems() {
    this.show = 'Stock';
  }

}
