import { Component, OnInit, Input, AfterContentChecked, DoCheck, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormGroup } from '@angular/forms';
import { FormlyFormOptions, FormlyFieldConfig } from '@ngx-formly/core';
import { GlobalsService } from 'src/app/_services/globals.service';
import 'rxjs/add/operator/takeUntil';
import { startWith } from 'rxjs/internal/operators/startWith';
import { tap } from 'rxjs/internal/operators/tap';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs';
import { UserService } from 'src/app/_services/user.service';
import { AuthService } from 'src/app/_services/auth.service';
import { SettingsService } from 'src/app/_services/settings.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-delivery-form',
  templateUrl: './delivery-form.component.html',
  styleUrls: ['./delivery-form.component.scss']
})
export class DeliveryFormComponent implements OnInit,  AfterContentChecked, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();


  @Input() delivery: any = { };
  @Input() salesOrder: any;
  @Input() DeliveryToDelete: any = [];
  passdiscounts: any;
  passSO: any;
  discountTotal = 0;
  toBePayed = 0;
  iterableDiffer: any;
  event: any;
  model = {
    deliveryItems: []
  };
  deliveryinfo = []
  form = new FormGroup({});
  options: FormlyFormOptions = {};
  fields: FormlyFieldConfig[] = [
    {
      key: 'deliveryItems',
      type: 'repeat',
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldArray: {

        fieldGroupClassName: 'row tw-rounded-lg tw-border-2 tw-border-aedgrayl mb-2 tw-py-4', 

        templateOptions: {
          btnText: 'Add Delivery',
          add:  () => {  this.model.deliveryItems.push();
         
            //this.model.deliveries[this.model.deliveries.length - 1]['paymentAmount'] = this.getPayed();
            //this.model.deliveryItems[this.model.deliveryItems.length - 1]['Type'] = 'Delivery';
          },
          removeItem: (x) =>{ 
            if(this.model.deliveryItems[x]['deliveryId'] != null){
              this.DeliveryToDelete.push(this.model.deliveryItems[x])
            } 
          }
        },

        fieldGroup: [

          // method
          {
            key: 'Type',
            type: 'select-input',
            className: 'col-md-3 tw-mb-2',
            defaultValue: 'Delivery',
            templateOptions: {
              
              label: 'Method',
              placeholder: '',
              required: true,
              options: [
                {label: 'Delivery', value: 'Delivery'},
                {label: 'Recycle', value: 'Recycle'},
                {label: 'Removal', value: 'Removal'},
                {label: 'Drop off Only', value: 'Drop off Only'},
                {label: 'Setup/Assembly', value: 'Setup/Assembly'},
                {label: 'Direct Shipment', value: 'Direct Shipment'}
              ],
            },

          },
          //type
          {
            key: 'delOrOther',
            type: 'select',
            className: 'col-md-3 tw-mt-auto',
            defaultValue: 'Delivery',
            templateOptions: {
              // label: 'ETA',
              options: [
                {label: 'Delivery', value: 'Delivery'},
                {label: 'Other Charges', value: 'Other Charges'}
              ],
            },
          },
          //eta
          {
            key: 'deliveryCode',
            type: 'input',
            className: 'col-md-3 tw-mt-auto',
            templateOptions: {
              // label: 'ETA',
              placeholder: 'ETA'
            },
          },
          // amount
          {
            key: 'deliveryAmountNum',
              type: 'input',
              className: 'col-md-3 tw-mt-auto',
              defaultValue: 0,
              templateOptions: {
                type: 'number',
                // label: 'Amount',
                required: true,
              },
          },
          //pretax
          {
            key: 'pretax',
            type: 'checkbox',
            className: 'col-md-2 tw-mt-auto',
            templateOptions: {
              label: 'Taxable',
              placeholder: ''
            },
            lifecycle: {
              onInit: (form, field) => {
                form.get('Type').valueChanges.pipe(                
                  takeUntil(this.destroy$)  ,
                  startWith(form.get('Type').value),
                  tap(typeId => {
                    if (this.event !== undefined) {
                    if (typeId === 'Delivery' && field.formControl.value == null) {
                      field.formControl.setValue(this.event['deliveryPreTax'] == null ? 0 : this.event['deliveryPreTax']);
                    }
                    if (typeId === 'Recycle' && field.formControl.value == null) {
                      field.formControl.setValue(this.event['recyclePreTax'] == null ? 0 : this.event['recyclePreTax']);
                    }
                    if (typeId === 'Removal' && field.formControl.value == null) {
                      field.formControl.setValue(this.event['removalPretax'] == null ? 0 : this.event['removalPretax']);
                    }
                  }
                  }),
                ).subscribe();
              },
              }

          },
          // notes
          {
            key: 'deliveryNotes',
            type: 'input',
            className: 'col-md-11',
            templateOptions: {
              // label: 'Notes (75 Characters Limit)',
              placeholder: 'Notes',

            },
          }

        ]

      }
    }
  ];


  constructor(
    private bsModalRef: BsModalRef,
    private modalService: BsModalService,
    private router: Router,
    private global: GlobalsService,
    private settingsService: SettingsService,
    private alertify: AlertifyService,
    private salesOrderService: SalesOrderService,
    private authService: AuthService
  ) { }

  addDelivery(p){
    // console.log(p.taxable ? (p.taxable == true ? 1 : 0) : 1)
   // this.newFormData = false
    let delivery ={
      Type : p.method,
      delOrOther : p.type,
      deliveryAmountNum :  p.price,
      deliveryCode : "",
      deliveryId : this.global.guid(),
      deliveryNotes : "",
      foreignSOID : this.salesOrder['SOID'],
      pretax : p.taxable == true ? 1 : 0,
      status : "insert",
      ...((p.taxCode!= undefined && p.taxCode != null) && {taxCode: p.taxCode})
    }
    this.salesOrderService.delivery.next(delivery);
  }
 

  ngOnInit() {
    if (this.passdiscounts !== undefined) {
      this.passdiscounts.map(e => {
        if(e.pretax == null){
          e.pretax = 0
        }
      });
    this.model.deliveryItems =   Object.assign([], JSON.parse(JSON.stringify( this.passdiscounts)));
    }
    this.salesOrder = this.passSO;

    if (this.router.url.toString().includes('salesorder') ){
      this.settingsService
      .SettingsEvent$
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (data) => {
          // console.log(data[0]["deliveryInfo"])
          if(data[0]["deliveryInfo"] != null){
            this.deliveryinfo = JSON.parse(data[0]["deliveryInfo"]);
          }
          
        },
        (error) => {
          this.alertify.error(error);
        }
      );
    }else{
      this.settingsService
      .getSettings(0)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (data) => {
          // console.log(data[0]["deliveryInfo"])
          if(data[0]["deliveryInfo"] != null){
            this.deliveryinfo = JSON.parse(data[0]["deliveryInfo"]);
          }
          
        },
        (error) => {
          this.alertify.error(error);
        }
      );
    }


  }

  hideModal() {
    this.bsModalRef.hide();
  }

  cancelForm() {
    if(this.passdiscounts != undefined){
      this.passdiscounts.map(e => {
        if(e.pretax == 0){
          e.pretax = null
        }
      });
    }
    
    this.salesOrder['deliveryItems'] = this.passdiscounts;
    this.bsModalRef.hide();
  }

  submit(deliveries: any) {
    this.passdiscounts = Object.assign([], this.model.deliveryItems);
    const soID = this.salesOrder['SOID'];
    for (const value of this.passdiscounts) {
      value['foreignSOID'] = soID;
      if (!value.hasOwnProperty('deliveryId')) {
        value['deliveryId'] = this.global.guid();
        value['status'] = 'insert'
      }

      if (value['pretax'] === false) {
        value['pretax'] = '';
      }

    }

    this.passdiscounts.map(e => {
      if(e.pretax == 0){
        e.pretax = null
      }
    });
    
    this.salesOrder['deliveryItems'] = this.passdiscounts;
    //console.log(this.salesOrder['deliveryItems'])
    this.bsModalRef.hide();

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Add Delivery',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }

  ngAfterContentChecked(): void {
    this.updateTotals();
  }

  updateTotals() {
    this.discountTotal = 0 ;
    if (this.model.deliveryItems) {
      for (const i of this.model.deliveryItems) {
        if (i['deliveryAmountNum'] > 0) {
//          this.discountTotal += parseFloat(i['deliveryAmountNum']);
            this.discountTotal += this.global.pNumber(i['deliveryAmountNum']);
          }

      }

    }

  }

  ngOnDestroy(): void {    
    this.global.optionTrashFL.next('default');
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


}
