

<div class="bg-white">

    <div class="row w-100" *ngIf="deviceid.length == 0">  
        <div class=" w-100 text-center">
            <i class="mx-auto fa fa-refresh fa-spin fa-5x"> </i>        
        </div>
        
        <div class="waitingtext text-center mx-auto">
            Checking if the card reader is connected
        </div>
    </div>


    <div class="row w-100 bg-white" *ngIf="cardname.length == 0 && deviceid.length > 0">
        <div class=" w-100 text-center">
            <i class="mx-auto fa fa-refresh fa-spin fa-5x"> </i>        
        </div>
        <div class="w-100 text-center">
            Please swipe or dip credit card.
        </div>
        
    </div>
    

    <div class="p-2">
    

        <div class="row " *ngIf="deviceid.length > 0" >
            <div class="col-sm-1 text-right">
            </div>
            <div class="col-sm-10" *ngIf="removeCard">
                <div class="row p-10">
                    <div class="col-sm-4 border border-right d-none">
                    </div>
                    <div class="col-sm-12">
                        <h6 >Please Remove Card</h6>
                        <app-cardsignature [cardPayment] = "payment"></app-cardsignature>
                    </div>
                </div>
            </div>
            <div class="col-sm-10 p-3" *ngIf="removeCard === false">
                <div class="row pull-right">
                    <p [ngClass]="{'text-danger':  readerStatus.includes('Error')}">
                        <b> Status: </b> {{readerStatus}} </p>
                </div>
                <br>
                <div class="text-danger" *ngIf="validateMessage.length > 0">
                    {{validateMessage }}
                </div>
                <br>
                <!-- Choose Card Type: 
                <button class="btn-sm btn btn-outline-primary" *ngIf="cardType === 0" (click) = "cardType = 1">Credit Card</button>
                <button class="btn-sm btn btn-outline-primary" *ngIf="cardType === 0" (click) = "cardType = 2; pinpad()">Debit Card</button>
                <span *ngIf="cardType === 1"> Credit Card</span>
                <span *ngIf="cardType === 2"> Debit Card</span>
                <br>
                -->
                <!-- <div *ngIf="cardType !== 0"> -->
               <div *ngIf="cardname.length > 0">
                    <small>Card Number</small> 
                    <br>
                    <h5 *ngIf="lastfour.length > 0">***************{{lastfour}}</h5> 
                        <input class="form-control d-none"
                        type="text" id="cardno" name="cardno" placeholder="Card Holder" required [(ngModel)]="cardno" >
                        <small>Name on Card</small> <br>
                        <h5>{{cardname}} </h5> 
                        <input class="form-control d-none" 
                        type="text" id="cardname" name="cardname" placeholder="Name" required [(ngModel)]="cardname" >
                        <div class="row">
                            <div class="col-sm-12">
                                <small>Expiration Date</small> <br> 
                                
                            <h5> {{expmonth}} / {{expyear}}</h5>
                            </div>
                            <div class="col-sm-12">
                                <small>Amount</small> <br> 
                            <h4> {{amount | currency }}</h4>
                                <br>
                                <p>
                            </div>
                        </div>
                    </div> 
                        <div class="row" >
                            <button class="mx-auto btn btn-3" (click)="processPayment()" [disabled]="validateMessage.length > 0" >Continue</button>
                            <!-- <button class="btn btn-6 ml-1" (click)="cancelForm()"  type="button">Cancel</button> -->
                            <!-- <button class="btn btn-6 ml-1" (click)="retry()"  type="button">Retry</button> -->
                            
                            <!-- <button class="btn btn-6" (click)="loadDemoData()"  type="button">Load Data</button>
                            <button class="btn btn-6" (click)="loadElectron()"  type="button">Electron Test</button> -->
                        </div>
                        <div class="text-success" *ngIf="paymentstatus === 'Approved'">
                            <br>
                            {{paymentstatus}}  <br>
                            {{authcode}} <br>
                        </div>
                        <div class="text-danger" *ngIf="paymentstatus === 'Denied'">
                        Denied  <br>
                        </div>
                        <br>
                        <div class="d-none">
                            {{respose | json}} <br>
                            Status: {{respose?.status_id}} <br>
                            Account Type: {{respose?.account_type}} <br>
                            Last Four: {{respose?.last_four}} <br>
                            Error: {{responseerror | json}} <br>        
                        </div>
            
            </div>
            <div class="col-sm-1"></div>
        </div>
    </div>

    <div class="row mx-auto" *ngIf="testuser">
        Device Id {{deviceid}} 
        <!-- <button class="btn btn-3" (click)="processPayment()">Continue</button> -->
        <button class="btn btn-6" (click)="cardReaderService.deviceid.next('aaaa')">Activate Device</button>
        <button class="btn btn-6 " (click)="cancelForm()"  type="button">Cancel</button>
        <button class="btn btn-6" (click)="loadDemoData()"  type="button">Load Data</button>
        <!-- <button class="btn btn-6" (click)="loadElectron()"  type="button">Electron Test</button> -->
    </div>                    
</div>