import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SalesOrderService } from 'src/app/_services/salesorder.service';

@Component({
  selector: 'app-choosestatus',
  templateUrl: './choosestatus.component.html',
  styleUrls: ['./choosestatus.component.scss']
})
export class ChoosestatusComponent implements OnInit {
  so: any[];
  choose = false;
  status = '';
  balance = 0;


  constructor(public bsModalRef: BsModalRef,
    private salesService: SalesOrderService) { }

  ngOnInit() {
    this.status = this.so['status'] || 0;
    this.balance = this.so['Balance'] || 0;
  }

  statusChange(status) {
    this.so['status'] = status;
    this.choose = true;
    this.salesService.triggerSaveSO.next(true);
    this.bsModalRef.hide();
  }

  cancel() {
    this.bsModalRef.hide();
  }

}
