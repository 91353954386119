<div class="tw-grid tw-grid-cols-3">
    
    <div class="tw-col-span-2 tw-pr-4">
        <table id="salestable" class="table-so table table-light table-custom overflow-auto" >
    
            <thead class="tw-font-bold tw-text-aedblue" >
                <tr style="font-size: small;background-color: #fafafa;" >                    
                    <th >{{ 'OPENING_BY' | translate }} <br>{{ 'ASSIGNED_TO' | translate }} </th>
                    <th >{{ 'CASH_REGISTER' | translate }}</th>
                    <th >{{ 'OPEN_AMOUNT' | translate }} <br> {{ 'CLOSE_AMOUNT' | translate }} </th>
                    <th >{{ 'SOLD' | translate }}</th>
                    <th >{{ 'OPEN_AT' | translate }} <br> {{ 'CLOSE_AT' | translate }}</th>
                    <th ></th>
                </tr>
            </thead>
        
            <tbody class="tw-text-aedgraydark">
            <tr class="m-1 p-1" *ngFor="let sp of closesCR | paginate: { itemsPerPage: 10, currentPage: p };let impar = odd; let i = index" 
            [ngClass]="impar ? 'tw-bg-aedgrayback' :'tw-bg-aedgrayback100'">
                
                <td>{{ sp.openingBy }} <br> {{getName('user',sp.userId)}} </td>
                <td>{{getName('device',sp.deviceId)}}</td>    
                <td>{{ sp.openCash | currency }} <br> {{ sp.closeCash | currency }}</td>
                <td>{{sp.sold | currency}}</td>
                <td>{{ sp.open_at | date: "short" }} <br> {{ sp.close_at | date: "short" }}</td>
    
                <td> 
                    <button class="tw-my-auto" (click)="selectOpenCR(sp)">
                       <svg xmlns="http://www.w3.org/2000/svg" class="tw-w-8 tw-h-8" width="20" height="20" viewBox="0 0 89.068 59.56" ><defs _ngcontent-lpe-c258=""><style _ngcontent-lpe-c258=""> .cls-23 { fill: none; stroke: #00ADEE; stroke-width: 4px; } .cls-25 { fill: #00ADEE; } </style></defs><g _ngcontent-lpe-c258="" id="eye-close-up" transform="translate(2.02 -159.525)"><path _ngcontent-lpe-c258="" id="Path_126" data-name="Path 126" d="M42.514,161.525A46.68,46.68,0,0,0,.171,188.535a1.822,1.822,0,0,0,0,1.542,46.7,46.7,0,0,0,84.686,0,1.822,1.822,0,0,0,0-1.542,48.616,48.616,0,0,0-9.515-13.514A43.971,43.971,0,0,0,65.024,167.2,46.039,46.039,0,0,0,42.514,161.525Zm0,46.95A19.165,19.165,0,1,1,61.679,189.31,19.163,19.163,0,0,1,42.514,208.475Z" transform="translate(0 0)" class="cls-23"></path><circle _ngcontent-lpe-c258="" id="Ellipse_1" data-name="Ellipse 1" cx="12.269" cy="12.269" r="12.269" transform="translate(30.245 177.032)" class="cls-25"></circle></g></svg>
                    </button>
                </td>
            </tr>
            
            </tbody>
        </table>
    </div>

    <!-- form -->
    <div class="tw-col-span-1">
        <div *ngIf="showForm" style="background-color: #fafafa;" class="tw-p-2">  

            <div style="margin-bottom: 10px;">
                {{ 'RESUME' | translate }}
                <div>{{ 'OPENING_BY' | translate }}: {{totalsCRSelected.openingBy}}</div>
                <div>{{ 'ASSIGNED_TO' | translate }}: {{getName('user',totalsCRSelected.userId)}}</div>
                <div>{{ 'CASH_REGISTER' | translate }}: {{getName('device',totalsCRSelected.deviceId)}}</div>
                <div>{{ 'OPEN_AMOUNT' | translate }}: {{totalsCRSelected.openCash | currency}}</div>
                <div *ngFor="let p of totalsCRSelected.payments">
                    <div>{{(p.paymentType | translate)+': '+(p.totalAmount | currency)}}</div>
                </div>
                <div>{{ 'CHANGES' | translate }}: {{(totalsCRSelected.changes) | currency}}</div>
                <div>{{ 'CLOSE_AMOUNT' | translate }}: {{totalsCRSelected.closeCash | currency}}</div>
                <div>{{ 'SOLD' | translate }}: {{ totalsCRSelected.sold | currency}} </div>
            </div>
            
            <div class="tw-flex">
                <button  (click)="showForm = false" class="btn-blue-so-square" style="font-size: .8rem;">
                    <span style="font-size: .8rem;" class="material-icons"> check </span>
                    &nbsp; {{ 'DONE' | translate }}
                </button>
            </div>        
        </div>
    </div>

</div>