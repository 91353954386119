import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs/Subscription';
import { Customer } from '../../../_modules/customers';
import { Pagination } from '../../../_modules/pagination';
import { AlertifyService } from '../../../_services/alertify.service';
import { AuthService } from '../../../_services/auth.service';
import { CustomerService } from '../../../_services/customer.service';
import { SalesorderSearchComponent } from '../../salesorder/salesorder-search/salesorder-search.component';
import { CustomerCardComponent } from '../customer-card/customer-card.component';
import { CustomerFormComponent } from '../customer-form/customer-form.component';
import { GlobalsService } from 'src/app/_services/globals.service';
import 'rxjs/add/operator/takeUntil';
import { EventsService } from 'src/app/_services/events.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'app-customer-list-mobile',
  templateUrl: './customer-list-mobile.component.html',
  styleUrls: ['./customer-list-mobile.component.scss']
})
export class CustomerListMobileComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() customer: Customer;
  @Input() searchMode = false;
  @Input() itemsPerPage = 9;
  @Input() showPagination = true;
  @Input() showHeader = true;
  @Input() viewType = 'List';
  @Input() salesOrderToSave:any;
  @Output() CancelSearch = new EventEmitter<any>();
  customers: any[];
  customerEdit: Customer;
  customerForm: FormGroup;
  pagination: Pagination;
  editMode = false;
  addMode = false;
  editId: number;
  paginationMode = false;
  fromList = false;
  event: any;

  counto = 0;
  maxsize = 10;
  salesorders: any = [];
  @ViewChild('editForm', {static: false}) editForm: NgForm;
  subscriptions: Subscription[] = [];
  //validation search
  searchCustomer = new FormControl('',Validators.pattern('[a-zA-Z0-9- ]*'));
  specialcha:boolean = false;
  inputSearchCss:string = "main-navbar-content-page-searchm" 
  inputSearchCssmobile:string = "main-navbar-content-page-searchm search-mobile";
  @Input() showForm: boolean = true;
  @Output() showFormChange = new EventEmitter<boolean>();
  @Input() showSelectCustomer: boolean = true;
  @Output() showSelectCustomerChange = new EventEmitter<boolean>();


  constructor(

    private alertify: AlertifyService,
    private customerService: CustomerService,
    private route: ActivatedRoute, 
    private router: Router,
    private modalService: BsModalService,
    private eventService: EventsService,
    private userService: UserService,
    private salesService: SalesOrderService,
    private globals: GlobalsService,
    public bsModalRef: BsModalRef,
    private authService: AuthService) { }
    
    cancelSearch() {
      this.CancelSearch.emit(true);
    }


  ngOnInit() {
    this.pagination =  {
        totalItems: 10,
        totalPages: 10,
        currentPage: 1,
        itemsPerPage: this.itemsPerPage
      };

      if (this.router.url.includes('customers') === true ) {
        this.fromList=true;
      }
      
      if (this.globals.isMobile()) {
        this.maxsize = 4;
      }
      
     this.route.data
     .pipe(takeUntil(this.destroy$))  
     .subscribe(data => {
        if (data['customers'] != null) {
          this.customers = data['customers'].result;
          this.pagination = data['customers'].pagination;
        } else {
          this.loadCustomersLar();
        }
     });
     if (this.router.url.includes('customers') === true ) {  
       this.eventService.salesOrderEvent
       .pipe(takeUntil(this.destroy$))  
       .subscribe((
         res => {
           this.event = res;
           this.loadCustomersLar();
          //  this.eventService.getEvent(this.event).subscribe( data => {
          //    this.event = data[0];
          //    //this.loadSalesOrder();
          //    this.loadCustomersLar();
          //  }, error => {
          //    this.alertify.error(error);
          //  });
   
         }
       ));
    }


      // this.subscriptions.push(
      //   this.alertify.alertevent        
      //   .pipe(takeUntil(this.destroy$))  
      //   .subscribe(() => {
      //     this.loadCustomersLar();
      //   })
      // );

      // this.salesorders = this.customer['salesCount'];
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // this.unsubscribe();
  }

  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  selectCustomer(id: number,customer:any) {
   
    let seletectedCustomer = 0;
    this.customerService.salesOrderCustomer
    .pipe(takeUntil(this.destroy$))  
    .subscribe((
      res => {
        seletectedCustomer = res;
      }));
    //if(seletectedCustomer === 0) {
      this.customerService.salesOrderCustomer.next(id);

      if(this.salesOrderToSave != undefined){
      if(this.salesOrderToSave['soItems'] && this.salesOrderToSave['soItems'].length > 0){
        this.salesOrderToSave['soItems'].forEach((item:any) =>{
          if(item.itemDelivery == 'Direct Shipment' && 
          ((item.deliveryAttn == null || item.deliveryAttn == "" || item.deliveryAttn == " " || item.deliveryAttn == 'null null') && 
          (item.deliveryAddress1 == null || item.deliveryAddress1 == "")  
          && (item.deliveryCity == null || item.deliveryCity == "") 
          && (item.deliveryPhone == "" || item.deliveryPhone == null) 
          && (item.deliveryState == null || item.deliveryState == "") )){
            if(this.salesOrderToSave['fName'] == null){this.salesOrderToSave['fName'] = ''}
            if(this.salesOrderToSave['lName'] == null){this.salesOrderToSave['lName'] = ''}
            item.deliveryAttn = customer['fName']+' '+customer['lName'] 
            item.deliveryAddress1 = customer['deliveryAddress1']
            item.deliveryAddress2 = customer['deliveryAddress2']
            item.deliveryPhone = customer['phone3']
            item.deliveryEmail = customer['deliveryEmail']
            item.deliveryCity = customer['deliveryCity']
            item.deliveryState = customer['deliveryState']
            item.deliveryZip = customer['deliveryZip']
          }
        })
      }
    }

      // this.bsModalRef.hide();
      this.showFormChange.emit(false)
      this.showSelectCustomerChange.emit(false)
    //}
  }
 

  addCustomer() {
    this.customerService.editCustomer.next(0);
    const initialState = {
      fromSalesOrder: this.searchMode
    };
    this.bsModalRef.hide();
    this.bsModalRef = this.modalService.show(CustomerFormComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true});
    var modals = document.querySelectorAll(".custom-card-content-item-footer-custom-options");
    for (var i = 0; i < modals.length; i++) {
      modals[i].classList.remove('active')
    }

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: this.searchMode ? 'SO ' : 'Customers',
    //   action: this.searchMode ? 'Add Customer from SO' : 'Add Customer',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }

    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});

  }
  editCustomer(id: any) {
    this.customerService.editCustomer.next(id);
    this.bsModalRef = this.modalService.show(CustomerFormComponent, {class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
    this.closeCardSettings();

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'Customers',
    //   action: 'Edit Customer',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).subscribe(a => {});

  }

  

  search() {    
    this.loadCustomersLar();    
  }




// get updateData() { 
//   return this.loadCustomersLar.bind(this);
// }

  loadCustomersLar() {
     this.paginationMode = true
     if(this.searchCustomer.valid){
      this.inputSearchCss = "main-navbar-content-page-searchm"
      this.inputSearchCssmobile = "main-navbar-content-page-searchm  search-mobile"    
      this.specialcha = false;
       let cid = '';
    if (this.event != null) {
      if (this.router.url.includes('customers') === true ) {
        cid = this.event;
      }
      
    }

    
    
    this.customerService.getCustomersByEvents(this.pagination.currentPage, this.pagination.itemsPerPage, this.searchCustomer.value, cid)
    .pipe(takeUntil(this.destroy$))  
    .subscribe(
      (res: any) => {

        this.customers = res.result;
        this.pagination = res.pagination;
        this.paginationMode = false
    }, error => {
      this.alertify.error(error);
    });

    }else{
      this.inputSearchCss = "main-navbar-content-page-searchm border-warning" 
      this.inputSearchCssmobile = "main-navbar-content-page-searchm border-warning search-mobile"     
      this.specialcha = true;
    }
  
  }

  openModalWithComponent() {
    this.bsModalRef = this.modalService.show(CustomerCardComponent);
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadCustomersLar();
  }

  toogleView(type: string) {
    if (this.viewType !== type) {
        this.viewType = type;
    }
  }
  loadSalesOrder(id) {
    this.closeCardSettings();
    let salesordersCustomer = [];
//     this.salesService.getSalesOrders(1, 10, this.customer['firstName'] + ' ' + this.customer['lastName'])
      let specificFields:any = {
        values: [
            "SONumber","status", "SOID"
        ]
      }
      this.salesService.getSalesOrdersSpecific(1, 100, '', '', '', '', '','', '', id, specificFields)
      .pipe(takeUntil(this.destroy$))  
     .subscribe(
       (res: any) => {
         salesordersCustomer = res.result;
          this.viewSalesOrder(salesordersCustomer);
     }, error => {
       this.alertify.error(error);
     });
  }
  viewSalesOrder(salesordersCustomer) {

    for (let index = 0; index < salesordersCustomer.length; index++) {
      salesordersCustomer[index]['dbdatets'] = new Date(salesordersCustomer[index]['dbdatets']);
    }


    const initialState = {
      passSalesOrders: salesordersCustomer,
      class: 'modal-lg',
      style: 'width: 100%'
    };

    this.bsModalRef = this.modalService.show(SalesorderSearchComponent, {initialState,class: 'orders-modal', backdrop: true, ignoreBackdropClick: true});
  }

  closeCardSettings () {
    var modals = document.querySelectorAll(".custom-card-content-item-footer-custom-options");
    for (var i = 0; i < modals.length; i++) {
      modals[i].classList.remove('active')
    }
  }
}
