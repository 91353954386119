import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { StockItemsService } from 'src/app/_services/stockitems.service';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-addstockitem',
  templateUrl: './addstockitem.component.html',
  styleUrls: ['./addstockitem.component.scss']
})
export class AddstockitemComponent implements OnInit {
  @Input() sp: any;
  @Output() qtyStockAdded = new EventEmitter<any>();
  salesOrderMode = false;
  constructor(private stockItemsService: StockItemsService,
    public bsModalRef: BsModalRef) { }

  ngOnInit() {
    this.sp['quantity'] = 0;
    
  }

  addStockItems(storeProduct: any, accept: boolean) {
    if (accept) {
    this.qtyStockAdded.emit(storeProduct);
      this.stockItemsService.addedStockItemSubject.next(storeProduct);
    } else {
      this.qtyStockAdded.emit(null);
      this.stockItemsService.addedStockItemSubject.next(null);
    }
    this.bsModalRef.hide();

  }


}
