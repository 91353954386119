import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators/catchError';
import { AlertifyService } from '../_services/alertify.service';
import { SalesOrderService } from '../_services/salesorder.service';

@Injectable()
export class SalesorderListResolver implements Resolve<any []> {

    pageNumber = 0;
    pageSize = 1;

    constructor(private salesService: SalesOrderService, private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<any []> {
        
        return this.salesService.getSalesOrders(this.pageNumber, this.pageSize).pipe(
            catchError(error => {
                this.alertify.error('Problem retrieving data');
                this.router.navigate(['/']);
                return of(null);
            })
        );
    }
}
