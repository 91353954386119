import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { map } from 'rxjs/operators/map';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from '../../environments/environment';
import { User } from '../_modules/user';


import jwt_decode from 'jwt-decode';
import * as CryptoJS from "crypto-js";
import { Router } from '@angular/router';
//import { LanguageService } from './language.service';


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.apiUrl + 'auth/';
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  currentUser: any;
  photoUrl = new BehaviorSubject<string>('../../assets/user.png');
  showunsavedSO = new BehaviorSubject<string>('Yes');
  showunsavedSett = new BehaviorSubject<string>('Yes');
  changeavatar = new BehaviorSubject<string>('No');
  changeLocName = new BehaviorSubject<string>('');
  matchVersion = new BehaviorSubject<boolean>(false);
  currentPhotoUrl = this.photoUrl.asObservable();
  cachedRequests: Array<HttpRequest<any>> = [];
  userName: any;
  role: any;
  

  constructor(
    private http: HttpClient, 
    private router: Router, 
    //private languageService: LanguageService
    ) {}

  changeMemberPhoto(photoUrl: string) {
    this.photoUrl.next(photoUrl);
  }

  // login(model: any) {
  //   return this.http.post(this.baseUrl + 'login', model).pipe(
  //     map((response: any) => {
  //       const user = response;
  //       if (user) {
  //         localStorage.setItem('auth_token', user.tokenString);           
  //         this.currentUser = JSON.parse(this.decodeToken('user').user);
  //         this.decodedToken = this.jwtHelper.decodeToken(user.tokenString);  
  //       }
  //     })
  //   );
  // }

  loadToken(token:any){  

    if(token != undefined){
      localStorage.setItem('auth_token', token); 
      localStorage.removeItem('infoCorp')
      this.currentUser = JSON.parse(this.decodeToken('user').user);
      this.role = this.decodeToken('role').role;
      this.userName = this.currentUser.name
      if (this.role === 'Dealer'){
        //this.languageService.setLanguage();
      } else {
        //this.languageService.setLanguage(undefined, this.userName);
      }
      this.decodedToken = this.jwtHelper.decodeToken(token);
      return token;
    }else{
      return token;
    }
  }
  
  getLocations(user: any) {
    return this.http.post(environment.apiUrl + 'getLocations', user);
  }
  
  switchdatabase(user: any) {
    return this.http.post(environment.apiUrl + 'switchDatabase', user);
  }

  loadVariable(token: any){
    this.currentUser = JSON.parse(this.decodeToken('user').user);
    this.decodedToken = this.jwtHelper.decodeToken(token);
  }

  switchRole(data: any) {
    return this.http.post(environment.apiUrl + 'switchRole',data).pipe(
      map((response: any) => {
        const user = response;
        if (user != 0) {
          localStorage.setItem('auth_token', user.tokenString);  
          localStorage.removeItem('infoCorp')
          this.currentUser = JSON.parse(this.decodeToken('user').user);
          this.decodedToken = this.jwtHelper.decodeToken(user.tokenString);
          this.changeMemberPhoto(undefined);
        }
      })
    );
  }

  decodeToken(param:any){
    let token:any = localStorage.getItem('auth_token')
    if (token == null) {
      return null
    }
    let tokendecoded:any = jwt_decode(token)
    
    const key = CryptoJS.enc.Base64.parse(environment.appKey);

    let user
    let role
    let username
    let email
    let cfsat
    let DID
    let dbServer
    let dbServerC
    let dbServerUser
    let version
    let dealername
    let corporateType

   try {
      //userId
    if(param == 'user'){
      const ivuser= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.user)).iv);
      const valueuser = JSON.parse(atob(tokendecoded.user)).value;
      user = CryptoJS.AES.decrypt(valueuser, key, {iv: ivuser}).toString(CryptoJS.enc.Utf8);   
      }

      if(param == 'version'){
        const ivversion= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.version)).iv);
        const valueversion = JSON.parse(atob(tokendecoded.version)).value;
        version = CryptoJS.AES.decrypt(valueversion, key, {iv: ivversion}).toString(CryptoJS.enc.Utf8);   
        }
      
      //role
      if(param == 'role'){
      const ivrole= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.role)).iv);
      const valuerole = JSON.parse(atob(tokendecoded.role)).value;
      role = CryptoJS.AES.decrypt(valuerole, key, {iv: ivrole}).toString(CryptoJS.enc.Utf8);   
      }
  
      //username
      if(param == 'username'){
      const ivusername= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.username)).iv);
      const valueusername = JSON.parse(atob(tokendecoded.username)).value;
      username = CryptoJS.AES.decrypt(valueusername, key, {iv: ivusername}).toString(CryptoJS.enc.Utf8);   
      }
      
      //email
      if(param == 'email'){
        const ivemail = CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.email)).iv);
        const valueemail = JSON.parse(atob(tokendecoded.email)).value;
        email = CryptoJS.AES.decrypt(valueemail, key, {iv: ivemail}).toString(CryptoJS.enc.Utf8);   
      }
      
      //cfsat
      if(param == 'cfsat'){
      const ivcfsat= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.cfsat)).iv);
      const valuecfsat = JSON.parse(atob(tokendecoded.cfsat)).value;
      cfsat = CryptoJS.AES.decrypt(valuecfsat, key, {iv: ivcfsat}).toString(CryptoJS.enc.Utf8);   
      }
      
      //DID
      if(param == 'DID'){
      const ivDID= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.DID)).iv);
      const valueDID = JSON.parse(atob(tokendecoded.DID)).value;
      DID = CryptoJS.AES.decrypt(valueDID, key, {iv: ivDID}).toString(CryptoJS.enc.Utf8);   
      }
      
      //dbServer
      if(param == 'dbServer'){
      const ivdbServer= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.dbServer)).iv);
      const valuedbServer = JSON.parse(atob(tokendecoded.dbServer)).value;
      dbServer = CryptoJS.AES.decrypt(valuedbServer, key, {iv: ivdbServer}).toString(CryptoJS.enc.Utf8);   
      }

      if(param == 'dbServerC'){
        const ivdbServerC= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.dbServerC)).iv);
        const valuedbServerC = JSON.parse(atob(tokendecoded.dbServerC)).value;
        dbServerC = CryptoJS.AES.decrypt(valuedbServerC, key, {iv: ivdbServerC}).toString(CryptoJS.enc.Utf8);   
      }

      if(param == 'corporateType'){
        const ivcorporateType= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.corporateType)).iv);
        const valuecorporateType = JSON.parse(atob(tokendecoded.corporateType)).value;
        corporateType = CryptoJS.AES.decrypt(valuecorporateType, key, {iv: ivcorporateType}).toString(CryptoJS.enc.Utf8);   
      }

      //dbServerUser
      if(param == 'dbServerUser'){
        const ivdbServerUser= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.dbServerUser)).iv);
        const valuedbServerUser = JSON.parse(atob(tokendecoded.dbServerUser)).value;
        dbServerUser = CryptoJS.AES.decrypt(valuedbServerUser, key, {iv: ivdbServerUser}).toString(CryptoJS.enc.Utf8);   
        }

      //dealername
      if(param == 'dealername'){
        const ivdealername= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.dealername)).iv);
        const valuedealername = JSON.parse(atob(tokendecoded.dealername)).value;
        dealername = CryptoJS.AES.decrypt(valuedealername, key, {iv: ivdealername}).toString(CryptoJS.enc.Utf8);   
        }
      return {
        'user': user,
        'role':role,
        'username':username,
        'email':email,
        'cfsat':cfsat,
        'dbServer':dbServer,
        'dbServerC':dbServerC,
        'corporateType':corporateType,
        'dbServerUser':dbServerUser,
        'dealername':dealername,
        'version':version,
        'DID':DID
      }
    }catch(err) {
      // console.log('token invalid')
      localStorage.removeItem('auth_token')
      localStorage.removeItem('infoCorp')
      localStorage.removeItem('token')
      // this.router.navigate(['/login'])
      window.location.href =environment.linkLogin;  

    }

    
  }

  decodeEmailCode(param:any){
    let token:any = localStorage.getItem('emailCode')
    let tokendecoded:any = jwt_decode(token)
    const key = CryptoJS.enc.Base64.parse(environment.appKey);
    let codeComplete
    let codeIncomplete

   try {
    if(param == 'codeComplete'){
      const ivuser= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.codeComplete)).iv);
      const valueuser = JSON.parse(atob(tokendecoded.codeComplete)).value;
      codeComplete = CryptoJS.AES.decrypt(valueuser, key, {iv: ivuser}).toString(CryptoJS.enc.Utf8);
    }
    if(param == 'codeIncomplete'){
      const ivuser= CryptoJS.enc.Base64.parse(JSON.parse(atob(tokendecoded.codeIncomplete)).iv);
      const valueuser = JSON.parse(atob(tokendecoded.codeIncomplete)).value;
      codeIncomplete = CryptoJS.AES.decrypt(valueuser, key, {iv: ivuser}).toString(CryptoJS.enc.Utf8);
    }
      return {
        'codeComplete': codeComplete,
        'codeIncomplete': codeIncomplete
      }
    }catch(err) {
      localStorage.removeItem('emailCode') 
    }
  }
  

  register(user: User) {
    return this.http.post(this.baseUrl + 'register', user);
  }

  pingAvalara() {
    return this.http.get(this.baseUrl + 'pingAvalara');
  }

  loggedIn() {
    const token = localStorage.getItem('auth_token');
    return !this.jwtHelper.isTokenExpired(token);
  }

  public getToken(): string {
    return localStorage.getItem('auth_token');
  }

  public getStoreId(): string {
    return this.decodeToken('user').user;
  }

public getRole(): string {
    return this.decodeToken('role').role;
}


 logout() {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('infoCorp')
    localStorage.removeItem('isCashier');
    localStorage.removeItem('products');
    this.decodedToken = null;
    this.currentUser = null;
  }
  
  logoutPromise() {
    return new Promise((resolve, reject) =>{
      localStorage.removeItem('auth_token');
      localStorage.removeItem('infoCorp')
      localStorage.removeItem('isCashier');
      localStorage.removeItem('products');
      this.decodedToken = null;
      this.currentUser = null;
      resolve(true)
    })
    
  }
}
