import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../environments/environment';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  laravel = environment.apiLaravel;
  salesOrderEvent = new BehaviorSubject<number>(0);
  editStoreUser = new BehaviorSubject<string>('');

  SettingsEvent$ = new BehaviorSubject<any>([]);

  constructor(private http: HttpClient) { }

  getSettings(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'settings');
  }

  getSettingsOnSO(): Observable<any> {
    return this.http.get<any>(this.laravel + 'settingsOnSO');
  }

  getSpecificSettings(values:any): Observable<any> {
    return this.http.post<any>(this.laravel + 'settings', values);
  }
  updateSettings(id: string, settings: any) {
    return this.http.patch(this.laravel + 'settings/' + id, settings);
  }
  updateDealerInfo(fordealer: any) {
    return this.http.post(this.laravel + 'updateDealerInfo', fordealer);
  }
  // codeDuration(data: any) {
  //   return this.http.post(this.laravel + 'codeDuration', data);
  // }
  updateWHAddress( address: any) {
    return this.http.post(this.laravel + 'updateWHAddress', address);
  }

  updateDefaultLayout(layout: any) {
    return this.http.post(this.laravel + 'updateDefaultLayout', layout);
  }
  updateClover(layout: any) {
    return this.http.post(this.laravel + 'updateClover', layout);
  }
  getDevices(){
    return this.http.get(this.laravel+'getDevices');
  }
  getDevicesByUser(value){
    return this.http.post(this.laravel+'getDevicesByUser',value);
  }
  updateCloverDevices(layout: any) {
    return this.http.post(this.laravel + 'updateCloverDevices', layout);
  }
  authToClover(url: any){
    return this.http.post(this.laravel + 'authToClover', url);
  }
  
  getCloverCredentials(){
    return this.http.get(this.laravel + 'getCloverCredentials');
  }
  updateDefaultFloor(layout: any) {
    return this.http.post(this.laravel + 'updateDefaultFloor', layout);
  }
  updateFloorEvent(layout: any) {
    return this.http.post(this.laravel + 'updateFloorEvent', layout);
  }

  updateDefaultTaxes(layout: any) {
    return this.http.post(this.laravel + 'updateDefaultTaxes', layout);
  }
  
  getDefaultTaxes() {
    return this.http.get(this.laravel + 'getDefaultTaxes');
  }

  emailVerification(data: any) {
    return this.http.post<any []>(this.laravel + 'emailVerification' , data);
  }

  updateInventoryMethod(settings: any) {
    return this.http.patch(this.laravel + 'updateInventoryMethod', settings);
  }

  getInventoryMethod() {
    return this.http.get(this.laravel + 'getInventoryMethod');
  }

  getCurrency() {
    return this.http.get(this.laravel + 'getCurrency')
  }

  getCountry(lng: number, lat: number) {
    if (lng && lat && !isNaN(lng) && !isNaN(lat)) {
      return this.http.get(this.laravel + 'getCountry', {params: {lng: lng.toString(), lat: lat.toString()}, responseType: 'text'});
    } else {
      return of(null);
    }
  }

  getSettingsLanguage() { 
    return this.http.get(this.laravel + 'getLanguage');
  }

  getUserLanguage(userId: string) {
    return this.http.get(this.laravel + 'getUserLanguage', { params: { userId } });
  }

  updateUserLanguage(language: string, userId: string) {
    return this.http.post(this.laravel + 'updateUserLanguage', { language, userId });
  }
}
