import { Component, OnInit, OnDestroy, ViewChild, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { SalesreportComponent } from '../salesreport/salesreport.component';
import 'rxjs/add/operator/takeUntil';
import { GlobalsService } from 'src/app/_services/globals.service';
import { Subject } from 'rxjs';
import { AuthService } from 'src/app/_services/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';

declare var Stimulsoft: any;
@Component({
  selector: 'app-reports-lists',
  templateUrl: './reports-lists.component.html',
  styleUrls: ['./reports-lists.component.scss']
})
export class ReportsListsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  viewer: any;
  report: any;
  
  bsValueFrom = new Date();
  bsValueTo = new Date();
  dateFilter: any = 'year';
  showReport = '';
  userRole = '';
  platform = '';
  showMobile = false;
  isAndroid = false
  isIOS = false
  yo:any

  fromReportList:boolean = false
  constructor(private http: HttpClient, private salesService: SalesOrderService,
  private alertify: AlertifyService,
  private modalService: BsModalService,
  public bsModalRef: BsModalRef,
  private globals: GlobalsService,
  private authService:AuthService,
  private router: Router,
  private translate: TranslateService,
  ) { }

  ngOnInit() {    
    this.fromReportList = true
    this.userRole = this.authService.decodeToken('role').role;
    this.changeDates();

    this.platform = navigator.userAgent;
    if (this.platform.match(/Android/i)) {
      this.isAndroid = true;
      this.showMobile = true;
    }
    if (this.platform.match(/iPhone|iPad|iPod/i)) {
      this.isIOS = true;
      this.showMobile = true;
    }
    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
       this.isIOS = true;
    }
  }

  async loadReport(report)  {  
      let wait = await this.globals.loadScript();
      if(wait){
        this.viewer = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
        this.report = new Stimulsoft.Report.StiReport();
        this.openSalesReport(report);   
      }
  }

  public openSalesReport(showReport, parameter = '' , dataparameter = '' ) {

    const initialState = {
      dateFrom: this.bsValueFrom,
      dateTo: this.bsValueTo,
      showReport: showReport,
      parameter: parameter,
      dataparameter: dataparameter
    };
    this.bsModalRef = this.modalService.show(SalesreportComponent, {initialState, class: 'modal-xl modal-report'});
  }

  changeDates() {
    const curr = new Date;
    if (this.dateFilter === 'week') {
      this.bsValueFrom = new Date(curr.setDate(curr.getDate() - curr.getDay() +1));
      this.bsValueTo = new Date();
    }
    if (this.dateFilter === 'month') {
      this.bsValueFrom = new Date(curr.getFullYear(), curr.getMonth(), 1);
      this.bsValueTo = new Date();
    }
    if (this.dateFilter === 'year') {
      this.bsValueFrom = new Date(curr.getFullYear(), 0, 1);
      this.bsValueTo = new Date();
    }

    if (this.dateFilter === 'q1') {
      this.bsValueFrom = new Date(curr.getFullYear(), 0, 1);
      this.bsValueTo = new Date(curr.getFullYear(), 2, 31);
    }


    if (this.dateFilter === 'q2') {
      this.bsValueFrom = new Date(curr.getFullYear(), 3, 1);
      this.bsValueTo = new Date(curr.getFullYear(), 5, 30);
    }


    if (this.dateFilter === 'q3') {
      this.bsValueFrom = new Date(curr.getFullYear(), 6, 1);
      this.bsValueTo = new Date(curr.getFullYear(), 8, 30);
    }

    if (this.dateFilter === 'q4') {
      this.bsValueFrom = new Date(curr.getFullYear(), 9, 1);
      this.bsValueTo = new Date(curr.getFullYear(), 11, 31);
    }

  }

  openParameters(template: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(template);
  }

  closeParameters() {
    this.bsModalRef.hide();
  }

  async logout() {
      
    this.yo = true;

    if(  this.router.url.slice(0, 12) == '/salesorder'  ||  this.router.url.slice(0, 12)  =='/storeconfig'){
      
        await new Promise((resolve,reject)=>{
                          
                this.alertify.confirm(this.translate.instant('LOGOUT_CONFIRMATION'),
                  ()=>{
                      resolve(this.yo = true) 
                  }
                  ,()=>{
                  }
                );     

          setTimeout(() => {  
            reject(this.yo = false) 
          }, 10000)    
    });

    }else{
      this.yo = true
    }

    if(this.yo){
      this.authService.showunsavedSO.next('No')
      this.authService.showunsavedSett.next('No')
      localStorage.removeItem('auth_token');
      localStorage.removeItem('isCashier');
      localStorage.removeItem('products');
      this.authService.decodedToken = null;
      this.authService.currentUser = null;
      this.alertify.message('logged out');
      this.modalService.hide()
      this.bsModalRef.hide()
      window.location.href =environment.linkLogin;  
    }    

  }

  ngOnDestroy(): void {
    this.fromReportList = false
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}