<app-bar-code-reader (ValueToFather)="addToInventory($event)"></app-bar-code-reader>
<div class="w-100">
  <div class="tw-flex tw-my-4" *ngIf="!salesOrderMode">
    <div class="tw-flex tw-ml-auto">
      <button class="btn-blue-so-square tw-ml-4" (click)="openModal(popTemplateiOs)">
        {{ 'ADDINVENTORY' | translate }}
      </button>
      <button class="btn-blue-so-square tw-ml-4" (click)="loadStockItemsForExcel()">
        {{ 'EXPORT_TO_EXCEL' | translate }}
      </button>
    </div>
  </div>

  <!-- filter option item stoke in retail -->
  <div class="tw-bg-white tw-p-4">

    <div class="tw-flex tw-justify-between tw-mb-2">
      <div class="tw-flex">
        <button class="btn-blue-so-square" (click)="clearFilter()">{{ 'CLEAR_FILTERS' | translate }}</button>
        <button *ngIf="showSave && !salesOrderMode" class="btn-blue-so-py0" (click)="editQuantity('', '', true)">{{ 'SAVE' | translate }}
        </button>
      </div>
      <div>{{ 'ITEMS' | translate }}: {{pagination.totalItems}}</div>
    </div>

    <div class="tw-grid tw-grid-cols-4 sm:tw-grid sm:tw-grid-cols-4 md:tw-grid-cols md:tw-grid-cols-4 lg:tw-flex">
      <div class="searchable">
        <input
          type="text"
          placeholder="{{ 'SEARCH_PRODUCT' | translate }}"
          [(ngModel)]="selectedProductMod" 
          (input)="filterProducts($event.target.value, 'productName')"
          (keydown.enter)="preventEnter($event)"
        />
        <ul>
          <li *ngFor="let product of filteredProducts" 
          (click)="loadFilter($event,'product', product.productName)"
          [ngClass]="{ 'selected': product.productName === selectedProductMod }"
          >{{ product.productName }}</li>
        </ul>
      </div>
      <div class="searchable">
        <input
          type="text"
          placeholder="{{ 'SEARCH_BRAND' | translate }}"
          [(ngModel)]="selectedBrandMod" 
          (input)="filterProducts($event.target.value, 'brand')"
          (keydown.enter)="preventEnter($event)"
        />
        <ul>
          <li *ngFor="let product of filteredBrands" 
          (click)="loadFilter($event,'brand', product.brand)"
          [ngClass]="{ 'selected': product.brand === selectedBrandMod }"
          >{{ product.brand }}</li>
        </ul>
      </div>
      <div class="searchable">
        <input
          type="text"
          placeholder="{{ 'SEARCH_MANUFACTURER' | translate }}"
          [(ngModel)]="selectedManMod" 
          (input)="filterProducts($event.target.value, 'manufacturer')"
          (keydown.enter)="preventEnter($event)"
        />
        <ul>
          <li *ngFor="let product of filteredManufacturer" 
          (click)="loadFilter($event,'manufacturer', product.manufacture)"
          [ngClass]="{ 'selected': product.manufacture === selectedManMod }"
          >{{ product.manufacture }}</li>
        </ul>
      </div>
      <div class="searchable">
        <input
          type="text"
          placeholder="{{ 'SEARCH_TYPE' | translate }}"
          [(ngModel)]="selectedTypeMod" 
          (input)="filterProducts($event.target.value, 'type')"
          (keydown.enter)="preventEnter($event)"
        />
        <ul>
          <li *ngFor="let product of filteredTypes" 
          (click)="loadFilter($event,'type', product.productType)"
          [ngClass]="{ 'selected': product.productType === selectedTypeMod }">{{ product.productType }}</li>
        </ul>
      </div>
      <!-- <select [(ngModel)]="selectedProductMod" name="product" class="input-header-white tw-mr-3 tw-mb-2" id="sizeselect"
        (ngModelChange)="loadFilter($event,'product', selectedProductMod)">
        <option disabled value="" selected>Choose Product</option>
        <option [ngValue]="product?.productName" *ngFor="let product of products">{{ product?.productName }} </option>
      </select>
      <select [(ngModel)]="selectedBrandMod" class="input-header-white tw-mr-3 tw-mb-2"
        (ngModelChange)="loadFilter($event,'brand', selectedBrandMod)">
        <option disabled value="" selected>Choose Brand</option>
        <option *ngFor="let brand of brands" [ngValue]="brand?.brand">{{brand?.brand}}</option>
      </select>

      <select [(ngModel)]="selectedManMod" class="input-header-white tw-mr-3  tw-mb-2"
        (ngModelChange)="loadFilter($event,'manufacture', selectedManMod)">
        <option disabled value="" selected>Choose Manufacture</option>
        <option *ngFor="let com of companies" [ngValue]="com?.manufacture">{{com?.manufacture}}</option>
      </select>

      <select [(ngModel)]="selectedTypeMod" class="input-header-white tw-mr-3  tw-mb-2"
        (ngModelChange)="loadFilter($event,'type', selectedTypeMod)">
        <option disabled value="" selected>Choose Type</option>
        <option *ngFor="let type of types" [ngValue]="type?.productType">{{type?.productType}}</option>
      </select> -->
    </div>

  </div>

  <div *ngIf="!salesOrderMode" class="customer-content tw-px-4">
    <div class="table-responsive">
      <table class="table-so table table-light table-custom overflow-auto">
        <thead class="tw-font-bold tw-text-aedblue">
            <tr style="font-size: small;background-color: #fafafa;">
            <td> {{ 'SKU_BARCODE' | translate }} </td>
            <td> {{ 'MANUFACTURER_BRAND' | translate }} </td>
            <td> {{ 'TYPE_PRODUCT' | translate }} </td>
            <td>{{ 'PRICE' | translate }}</td>
            <td>{{ 'RETAIL' | translate }}</td>
            <td>{{ 'SOLD' | translate }}</td>
            <td>{{ 'AVAILABLE' | translate }}</td>
            <td > {{ 'EXPIRED_DATE' | translate }} </td>
            <td colspan="1" class="tw-text-base tw-text-aedblue tw-font-semibold"></td>
          </tr>

        <tbody class="tw-py-2 tw-text-aedgraydark"
          *ngFor="let sp of recordItems;  let odd = odd"
          [ngClass]="odd ? 'tw-bg-aedgrayback' :'tw-bg-aedgrayback100'">
          <tr>
            
            <!-- <td colspan="1" style="padding-left: .5rem;" class="tdeventlist tw-cursor-pointer">
              <span *ngIf="sp.uuid == showOrderwithStock && sp.Sales > 0" (click)="showSalesOrders('')"
                class="material-icons">expand_more</span>
              <span *ngIf="sp.uuid != showOrderwithStock  && sp.Sales > 0" (click)="showSalesOrders(sp.stockItemId)"
                class="material-icons">chevron_right</span>
            </td> -->
            <td colspan="1" class="tdeventlist">{{ sp.sku }}<br>{{ sp.barcode }}  </td>
            <td colspan="1" class="tdeventlist">{{ sp.manufacture }} <br>{{ sp.brand }}</td>
            <td colspan="1" class="tdeventlist">{{ sp.productType }} <br>{{ sp.productName }}</td>         
            <td colspan="1" class="tdeventlist tw-text-center">{{sp.price | currency}}</td>
            <td colspan="1" class="tdeventlist tw-text-center">{{sp.retail}}</td>
            <td colspan="1" class="tdeventlist tw-text-center">{{ sp.sold }}</td>

            <td colspan="1" class="tdeventlist tw-text-center">{{ sp.retail - sp.sold }}</td>
            <td colspan="1" class="tdeventlist tw-text-center">{{ sp.dateExpired }}</td>
            <td colspan="1" class="tdeventlist tw-text-end">
              <div class="d-flex tw-ml-4">
                <!-- view button -->
                <svg (click)="showDetails(sp, popTemplateShow)" class="tw-cursor-pointer tw-w-8 tw-h-8 tw-ml-5" xmlns="http://www.w3.org/2000/svg" width="36.829" height="35.1" viewBox="0 0 89.068 59.56">
                  <defs>
                    <style>
                      .cls-0 {
                        fill: none;
                        stroke: #00adee;
                        stroke-width: 4px;
                      }
                
                      .cls-2 {
                        fill: #00adee; 
                      }
                    </style>
                  </defs>
                  <g id="eye-close-up" transform="translate(2.02 -159.525)">
                    <path id="Path_126" data-name="Path 126" class="cls-0" d="M42.514,161.525A46.68,46.68,0,0,0,.171,188.535a1.822,1.822,0,0,0,0,1.542,46.7,46.7,0,0,0,84.686,0,1.822,1.822,0,0,0,0-1.542,48.616,48.616,0,0,0-9.515-13.514A43.971,43.971,0,0,0,65.024,167.2,46.039,46.039,0,0,0,42.514,161.525Zm0,46.95A19.165,19.165,0,1,1,61.679,189.31,19.163,19.163,0,0,1,42.514,208.475Z" transform="translate(0 0)"/>
                    <circle id="Ellipse_1" data-name="Ellipse 1" class="cls-2" cx="12.269" cy="12.269" r="12.269" transform="translate(30.245 177.032)"/>
                  </g>
                </svg>

                <!-- edit button -->
                <svg *ngIf="toEdit != sp.uuid" class="ml-4 tw-cursor-pointer"
                  (click)="editStockItem(sp, popTemplateiOs)" id="Component_59_102" data-name="Component 59 – 102"
                  xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25.674"
                  height="25.681" viewBox="0 0 25.674 25.681">
                  <defs>
                    <style>
                      .cls-15 {
                        fill: #00adee;
                      }
                    </style>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_156" data-name="Rectangle 156" class="cls-15" width="25.674"
                        height="25.681" />
                    </clipPath>
                  </defs>
                  <g id="Group_63" data-name="Group 63" class="">
                    <path id="Path_76" data-name="Path 76" class="cls-15"
                      d="M25.674,4.884a5.154,5.154,0,0,1-1.642,2.877C21,10.767,17.983,13.8,14.961,16.819Q12.306,19.475,9.65,22.13c-.313.313-.633.618-.946.931a1.42,1.42,0,0,1-.578.335Q4.861,24.481,1.6,25.572c-.095.032-.186.073-.279.109h-.6a1.094,1.094,0,0,1-.594-1.576q1.09-3.266,2.177-6.534a1.567,1.567,0,0,1,.389-.631Q10.514,9.121,18.337,1.3A4.289,4.289,0,0,1,25.3,2.465a5.738,5.738,0,0,1,.377,1.216ZM7.95,20.871c.089-.02.135-.1.192-.153q6.486-6.483,12.975-12.964c.166-.165.138-.246-.011-.392Q19.716,5.988,18.343,4.6c-.159-.162-.245-.167-.41,0q-2.648,2.667-5.312,5.319-3.827,3.827-7.657,7.651c-.133.133-.16.211-.012.357.946.933,1.881,1.875,2.821,2.813.052.052.1.118.176.136M23.641,4.2c0-.013-.014-.1-.025-.178A2.1,2.1,0,0,0,22.107,2.18,2.2,2.2,0,0,0,19.73,2.8c-.1.1-.1.171,0,.27q1.454,1.447,2.9,2.9c.086.086.155.1.252.009A2.349,2.349,0,0,0,23.641,4.2M5.976,21.876a.9.9,0,0,0-.07-.088q-1-1-2-2c-.1-.1-.147-.078-.188.048-.11.341-.226.681-.339,1.021q-.321.961-.641,1.923c-.02.06-.073.127-.024.189s.124.016.183,0q1.473-.488,2.944-.98c.05-.017.121-.017.135-.108"
                      transform="translate(0 0)" />
                  </g>
                </svg>
                <svg *ngIf="toEdit == sp.uuid" class="ml-4 tw-my-auto tw-cursor-pointer" (click)="toEdit = ''"
                  xmlns="http://www.w3.org/2000/svg" width="25.674" height="25.681" viewBox="0 0 25.674 25.681">
                  <defs>
                    <style>
                      .cls-170 {
                        fill: none;
                        stroke: #00adee;
                        stroke-linecap: round;
                        stroke-width: 3px;
                      }
                    </style>
                  </defs>
                  <g id="Component_68_10" data-name="Component 68 – 10" transform="translate(2.121 2.121)">
                    <line id="Line_22" data-name="Line 22" class="cls-170" x2="16.468" y2="15.734" />
                    <line id="Line_23" data-name="Line 23" class="cls-170" x1="16.468" y2="15.734" />
                  </g>
                </svg>
                <!-- delete button -->
                <div *appHasRole='["Admin","Dealer"]'>
                  <svg *ngIf="(sp.retail - sp.sold) > 0" class="ml-4 mr-2 tw-cursor-pointer" (click)="deleteModal(sp, deleteTemplate)" id="Group_235"
                    data-name="Group 235" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                    width="24.255" height="29.82" viewBox="0 0 24.255 29.82">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: #00adee;
                        }
  
                        .cls-2 {
                          clip-path: url(#clip-path);
                        }
                      </style>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_99" data-name="Rectangle 99" class="cls-1" width="24.255" height="29.82" />
                      </clipPath>
                    </defs>
                    <g id="Group_40" data-name="Group 40" class="">
                      <path id="Path_55" data-name="Path 55" class="cls-1"
                        d="M14.513,0a2.979,2.979,0,0,1,1.865,1.289,3.193,3.193,0,0,1,.43,1.838c-.015.657-.087.574.563.576,1.493,0,2.987,0,4.48,0a2.316,2.316,0,0,1,2.4,2.4q0,1.556,0,3.113a.939.939,0,0,1-1.043,1.043c-.388,0-.4.019-.414.412q-.15,3.426-.307,6.853c-.072,1.577-.151,3.154-.226,4.732-.078,1.616-.146,3.232-.236,4.848a2.812,2.812,0,0,1-2.887,2.711q-7.011,0-14.022,0a2.84,2.84,0,0,1-2.9-2.853c-.084-2.217-.2-4.433-.3-6.649-.073-1.587-.153-3.174-.228-4.761q-.119-2.5-.234-4.993c-.01-.218-.1-.3-.322-.3C.311,10.251,0,9.926,0,9.1,0,8.086,0,7.068,0,6.05A2.313,2.313,0,0,1,2.344,3.706c1.581,0,3.161-.009,4.742.005.3,0,.383-.09.371-.38A4.029,4.029,0,0,1,7.506,2.2,2.78,2.78,0,0,1,9.465.09L9.741,0ZM12.148,10.264q-4.233,0-8.467,0c-.221,0-.338.033-.322.3.082,1.364.151,2.728.219,4.092q.119,2.4.229,4.791c.086,1.878.164,3.756.25,5.634.031.668.07,1.335.113,2a.856.856,0,0,0,.638.78,1.594,1.594,0,0,0,.46.057h13.7a1.623,1.623,0,0,0,.432-.046.863.863,0,0,0,.682-.836q.115-2.177.225-4.354.12-2.424.232-4.849c.085-1.868.164-3.737.248-5.605.024-.523.048-1.045.089-1.567.03-.381.029-.4-.353-.4h-8.38m-.02-1.9q4.946,0,9.891,0c.249,0,.344-.061.336-.325-.018-.572,0-1.144-.006-1.716s-.141-.716-.725-.716H2.6c-.544,0-.692.148-.693.689q0,.785,0,1.571c0,.536-.051.492.478.492h9.746M12.119,3.7c.833,0,1.666-.005,2.5,0,.213,0,.3-.064.286-.283-.014-.193,0-.387-.005-.581a.934.934,0,0,0-.961-.957q-1.815-.018-3.631,0a.93.93,0,0,0-.948.939c-.01.193.009.388-.006.581-.018.229.07.3.3.3.823-.011,1.646,0,2.469,0"
                        transform="translate(0)" />
                      <path id="Path_56" data-name="Path 56" class="cls-1"
                        d="M85.757,162.45q0,3.025,0,6.051a.924.924,0,0,1-.669.918.966.966,0,0,1-1.059-.36.882.882,0,0,1-.173-.544q0-6.066,0-12.131a.912.912,0,0,1,.94-.937.924.924,0,0,1,.958.952c0,2.017,0,4.034,0,6.051"
                        transform="translate(-77.334 -143.36)" />
                      <path id="Path_57" data-name="Path 57" class="cls-1"
                        d="M145.636,162.462q0,3.011,0,6.022a.925.925,0,0,1-.653.929.965.965,0,0,1-1.082-.364.9.9,0,0,1-.166-.546q0-6.051,0-12.1a.915.915,0,0,1,.953-.954.926.926,0,0,1,.946.964q.006,3.025,0,6.051"
                        transform="translate(-132.557 -143.361)" />
                      <path id="Path_58" data-name="Path 58" class="cls-1"
                        d="M205.506,162.412q0,3.011,0,6.022a.925.925,0,0,1-.657.926.97.97,0,0,1-1.081-.368,1.066,1.066,0,0,1-.164-.664q0-4.335,0-8.669c0-1.105,0-2.211,0-3.316a.922.922,0,0,1,.783-.942.952.952,0,0,1,1.076.645,1.268,1.268,0,0,1,.042.431q0,2.967,0,5.935"
                        transform="translate(-187.771 -143.306)" />
                    </g>
                  </svg>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="pagination-container" *ngIf="stockItems.length > 0">
        <pagination 
          [boundaryLinks]="true"
          [totalItems]="pagination.totalItems"
          [(ngModel)]="pagination.currentPage"
          [itemsPerPage]="pagination.itemsPerPage"
          (pageChanged)="pageChanged($event)"
          [maxSize]="maxsize"
          [autoHide]="true"
          previousText="&lsaquo;"
          nextText="&rsaquo;"
          firstText="&laquo;"
          lastText="&raquo;"
        >
        </pagination>
      </div>
    </div>
  </div>
</div>

<ng-template #popTemplateiOs>
  <div *ngIf="showModal" class="modal-background"></div>
  <form [formGroup]="customStockItems" class="modal-container">
    <div class="tw-flex tw-p-4 tw-flex-col tw-rounded-t-xl ">
      <div class="title-1-orange tw-mx-auto">{{customStockItemEditing == null ? ('ADD_ITEM' | translate) : ('EDIT_ITEM' | translate) }}
      </div>
    </div>
  
    <div class="d-flex flex-column container w-100 tw-text-aedgrayl">
      <div class="tw-flex tw-flex-col">

        <div class="tw-grid tw-grid-cols-2  tw-gap-1">
          <div *ngIf="customStockItemEditing != null">
            <label for="productCode">{{ 'PRODUCT_CODE' | translate }}</label>
            <input formControlName="productCode" id="productCode" placeholder="{{ 'PRODUCT_CODE' | translate }}" type="text"
              class="input-header tw-py-1 tw--mt-2" [readonly]="customStockItemEditing != null">
          </div>

          <div>
            <label for="barcode">{{ 'BARCODE' | translate }}</label>
            <input formControlName="barcode" id="barcode" placeholder="{{ 'BARCODE' | translate }}" type="text"
              class="input-header tw-py-1 tw--mt-2" [readonly]="customStockItemEditing != null">
          </div>

          <div>
            <label for="sku">SKU</label>
            <input formControlName="sku" id="sku" placeholder="xxxxxxx" type="text"
              class="input-header tw-py-1 tw--mt-2" [readonly]="customStockItemEditing != null">
          </div>

          <div>
            <label for="productName">{{ 'PRODUCT_NAME' | translate }}</label>
            <input formControlName="productName" id="productName" placeholder="{{ 'PRODUCT_NAME' | translate }}" type="text"
              class="input-header tw-py-1 tw--mt-2">
            <div *ngIf="customStockItems.get('productName').touched && customStockItems.get('productName').hasError('required')">
              <div class="text-danger">{{ 'PRODUCT_NAME_REQUIRED' | translate }}</div>
            </div>
          </div>

          <div>
            <label for="type">{{ 'TYPE' | translate }}</label>
            <select formControlName="type" id="type" class="input-header tw-py-1 tw--mt-2">
              <option class="tw-bg-gray-200 tw-text-gray-400" value="" selected>{{ 'SELECT' | translate }}</option>
              <option *ngFor="let type of types" [value]="type.uuid">{{type.productType}}</option>
            </select>
            <div *ngIf="customStockItems.get('type').value == '' || customStockItems.get('type').hasError('required')">
              <div class="text-danger">{{ 'TYPE_REQUIRED' | translate }}</div>
            </div>
          </div>

          <div>
            <label for="brand">{{ 'BRAND' | translate }}</label>
            <input formControlName="brand" id="brand" placeholder="{{ 'BRAND' | translate }}" type="text"
              class="input-header tw-py-1 tw--mt-2 ">
          </div>


          <div class="">
            <label for="manufacture">{{ 'MANUFACTURER' | translate }}</label>
            <input formControlName="manufacture" id="manufacture" placeholder="{{ 'MANUFACTURER' | translate }}" type="text"
              class="input-header tw-py-1 tw--mt-2 ">
          </div>

          <div>
            <label for="cost">{{ 'COST' | translate }}</label>
            <input formControlName="cost" id="cost" placeholder="{{ 'COST' | translate }}" type="number"
              class="input-header tw-py-1 tw--mt-2 ">
            <div *ngIf="customStockItems.get('cost').value == null && customStockItems.get('cost').hasError('required')">
              <div class="text-danger">{{ 'COST_REQUIRED' | translate }}</div>
            </div>
            <div *ngIf="customStockItems.get('cost').value == 0 && customStockItems.get('cost').hasError('min')">
              <div class="text-danger">{{ 'COST_MUST_BE_GREATER_THAN_ZERO' | translate }}</div>
            </div>
          </div>

          <div>
            <label for="price">{{ 'SALES_PRICE' | translate }}</label>
            <input formControlName="price" id="price" placeholder="{{ 'SALES_PRICE' | translate }}" type="number"
              class="input-header tw-py-1 tw--mt-2 ">
            <div *ngIf="customStockItems.get('price').value == null && customStockItems.get('price').hasError('required')">
              <div class="text-danger">{{ 'PRICE_IS_REQUIRED' | translate }}</div>
            </div>
            <div *ngIf="customStockItems.get('price').value == 0 && customStockItems.get('price').hasError('min')">
              <div class="text-danger">{{ 'PRICE_MUST_BE_GREATER_THAN_ZERO' | translate }}</div>
            </div>
          </div>

          <div *ngIf="customStockItemEditing == null || userRole=='Admin' || userRole=='Dealer'">
            <label for="retail">{{ 'QUANTITY' | translate }}</label>
            <input formControlName="retail" id="retail" placeholder="{{ 'QUANTITY' | translate }}" type="number"
              class="input-header tw-py-1 tw--mt-2" [ngClass]="{'highlighted-input': customStockItemEditing}">
            <div *ngIf="customStockItems.get('retail').value == null || customStockItems.get('retail').hasError('required')">
              <div class="text-danger">{{ 'QUANTITY_REQUIRED' | translate }}</div>
            </div>
            <div *ngIf="customStockItems.get('retail').value == 0 || customStockItems.get('retail').hasError('min')">
              <div class="text-danger">{{ 'QUANTITY_MUST_BE_GREATER_THAN_ZERO' | translate }}</div>
            </div>
          </div>


          <div *ngIf="customStockItemEditing != null">
            <label for="addQty">{{ 'ADD_TO_QUANTITY' | translate }}</label>
            <input formControlName="addQty" id="addQty" placeholder="{{ 'ADD_TO_QUANTITY' | translate }}" type="number"
              class="input-header tw-py-1 tw--mt-2" [ngClass]="{'highlighted-input': customStockItemEditing}">
              <div *ngIf="customStockItems.get('addQty').touched && customStockItems.get('addQty').hasError('required')">
                <div class="text-danger">{{ 'QUANTITY_REQUIRED' | translate }}</div>
              </div>
          </div>

          <div>
            <label for="weight">{{ 'WEIGHT' | translate }}</label>
            <input formControlName="weight" id="weight" placeholder="Weight" type="number"
              class="input-header tw-py-1 tw--mt-2">
          </div>

          <div>
            <label for="dateExpired">{{ 'EXPIRED_DATE' | translate }}</label>
            <div>
              <input formControlName="dateExpired" id="dateExpired" placeholder="xx/xx/xx" type="date"
                class="input-header tw-py-1 tw--mt-2 ">
              <div *ngIf="customStockItems.get('dateExpired').hasError('required')">
                <div class="text-danger">{{ 'DATE_EXPIRED_REQUIRED' | translate }}</div>
              </div>
            </div>
          </div>

          <div>
            <label for="productDetails">{{ 'DETAILS' | translate }}</label>
            <input formControlName="productDetails" id="productDetails" placeholder="Details" type="text"
              class="input-header tw-py-1 tw--mt-2 ">
          </div>

          <div>
            <label for="image">{{ 'PRODUCT_IMAGE' | translate }}</label>

            <div id="image">
              <label  class="custom-file-upload" for="file-upload" >{{!imageUrl ? ('ADD_IMAGE' | translate) : ('CHANGE_IMAGE' | translate)}}</label>                       
              <input id="file-upload"  class="tw-w-full tw-rounded tw-border tw-border-gray-300 tw-p-2 tw-mb-2" 
              type="file" #imageInput (change)="onImageSelected($event)" accept="image/*">              
            </div>

            <img *ngIf="imageUrl" class="tw-mt-2" id="image" [src]="imageUrl" alt="Product image" (load)="showImageInput = false">
            <img *ngIf="addBarcode && !imageUrl" class="tw-mt-2" id="image" [src]="customStockItems.get('image').value" alt="Product image" (load)="showImageInput = false">
          </div>

          <div *ngIf="customStockItemEditing == null">
            <label for="anotherLote">{{ 'DISPLAY_PRODUCT_EXPIRATION_DATES_FOR_CASHIER' | translate }}</label>
            <div class="tw-m-0">
                <input value="1" type="checkbox" formControlName="anotherLote" id="anotherLote" class="tw-mr-2">
                <label for="anotherLote" class="tw-mt-2 tw-mr-2">{{ 'YES' | translate }}</label>
            </div>
          </div>
        </div>

        <div class="tw-flex tw-py-4">
          <button *ngIf="customStockItemEditing == null" (click)="saveCustomStock()" class="btn-blue-so tw-flex"
            style="cursor:pointer;" [ngClass]="{'disabled-button': customStockItems.invalid}">
            <span class="material-icons fs-1">check</span> {{ 'ADD' | translate }}
          </button>
          <button *ngIf="customStockItemEditing != null" (click)="EditCustomStock()" class="btn-blue-so tw-flex"
            style="cursor:pointer;" [ngClass]="{'disabled-button': customStockItems.invalid}">
            <span class="material-icons fs-1">check</span> {{ 'UPDATE' | translate }}
          </button>
          <button (click)="closeModal(true)" class="btn-blue-so tw-flex ml-4" style="cursor:pointer;">
            <span class="material-icons fs-1">close</span> {{ 'CANCEL' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #popTemplateShow class=" tw-rounded-xl">
  <div *ngIf="showModal" class="modal-background"></div>
  <form [formGroup]="customStockItems" class="modal-container">
    <div class="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center">
      <div class="tw-w-full tw-md:w-2/3 tw-lg:w-1/2 tw-xl:w-1/3 tw-p-3">
        <div class="tw-text-center tw-flex tw-items-center tw-justify-center">
          <img *ngIf="customStockItems.get('image').value != '' && customStockItems.get('image').value != null" id="image" [src]="customStockItems.get('image').value" class="tw-mt-2 tw-w-auto tw-h-auto tw-max-w-screen-sm tw-max-h-60"
            alt="{{ 'PRODUCT_IMAGE' | translate }}" />
          <img *ngIf="customStockItems.get('image').value == '' || customStockItems.get('image').value == null" id="image" [src]="'assets/default.jpg'"
            alt="{{ 'PRODUCT_IMAGE' | translate }}" />
        </div>
        <div class="tw-text-center tw-mt-5">
          <h2 class="tw-text-xl tw-font-bold tw-text-gray-800">
            {{ customStockItems.get('productName').value }}
          </h2>
          <div class="tw-text-gray-600 tw-text-lg  tw-mt-2 tw-mb-2">
            <span class="tw-text-gray-700 tw-font-bold">{{ customStockItems.get('productCode').value }}</span>
          </div>
          <p class="tw-text-gray-500 tw-mt-1">{{ 'BARCODE' | translate }}: {{ customStockItems.get('barcode').value }}</p>
          <p class="tw-text-gray-500 tw-mt-1">SKU: {{ customStockItems.get('sku').value }}</p>
          <p class="tw-text-gray-500 tw-mt-1">{{ 'EXPIRED_DATE' | translate }}: {{ customStockItems.get('dateExpired').value }}</p>
          <p class="tw-text-gray-500 tw-mt-1" *ngIf="customStockItems.get('brand').value != '-'">{{ 'BRAND' | translate }}: {{
            customStockItems.get('brand').value }}</p>
          <p class="tw-text-gray-500 tw-mt-1">{{ 'WEIGHT' | translate }}: {{ customStockItems.get('barcode').value }}</p>
          <p class="tw-text-gray-500 tw-mt-1" *ngIf="customStockItems.get('manufacture').value != '-'">{{ 'MANUFACTURER' | translate }}: {{
            customStockItems.get('manufacture').value }}</p>
          <p class="tw-text-gray-500 tw-mt-4">{{ customStockItems.get('productDetails').value }}</p>
        </div>
        <div class="tw-flex tw-py-4">
          <button (click)="closeModal()" class="btn-blue-so tw-flex ml-4" style="cursor:pointer;">
            <span class="material-icons fs-1">close</span>{{ 'CLOSE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #dateTemplate class=" tw-rounded-xl">
  <div *ngIf="showModal" class="modal-background"></div>
  <form [formGroup]="customStockItems" class="modal-container-sm">
    <div class="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center">
      <div class="tw-w-full ">
        <div  class="title-1-orange tw-mx-auto tw-items-center tw-justify-center tw-text-center" >{{ 'PRODUCT_EXPIRATION_DATES' | translate }}</div>   
    
          <div class="tw-mb-4 tw-mt-4 tw-grid tw-grid-cols-2">
              <button (click)="getProductByDate(date.dateExpired, date.barcode, date.uuid)" class="tw-bg-gray-400 tw-text-white tw-py-2 tw-mb-3 tw-ml-2 tw-w-11/12" *ngFor="let date of datesExpired">
                {{ date.dateExpired }}
              </button>
          </div>
  
          <div class="tw-flex">
            <button (click)="closeModal()" class="btn-blue-so tw-flex tw-ml-1" style="cursor:pointer;">
              <span class="material-icons fs-1">close</span>{{ 'CLOSE' | translate }}
            </button>
  
            <button (click)="editDate()" class="btn-blue-so tw-flex tw-ml-4" style="cursor:pointer;">
              <span class="material-icons fs-1">add</span>{{ 'ADD' | translate }}
            </button>
          </div>
  
      </div>
    </div>
  </form>
</ng-template>

<ng-template #deleteTemplate class=" tw-rounded-xl">
  <div *ngIf="showModal" class="modal-background"></div>
  <form [formGroup]="productDeleted" class="modal-container-sm">
    <div class="tw-container tw-mx-auto tw-flex tw-items-center tw-justify-center">
      <div class="tw-w-full ">
        <div  class="title-1-orange tw-mx-auto tw-items-center tw-justify-center tw-text-center tw-mb-2" >{{ 'DELETE_FROM_RETAIL' | translate }}</div>   
    
        <div class="tw-text-aedgray tw-px-5 tw-w-full">
          <div class="tw-flex tw-flex-col">

          <div class="tw-grid tw-gap-4">
              <div class="tw-items-center tw-justify-center tw-text-center">
                  <label class="tw-font-bold">{{productDeleted.get('productName').value}}</label><br>
                  <label class="tw-font-bold">{{productDeleted.get('productCode').value}}</label>
              </div>

              <div>
                <label for="reason">{{ 'DELETION_REASON' | translate }}:</label><br>
                <select formControlName="reason" id="reason" class="input-header tw-py-1 tw--mt-2">
                  <option disabled value="" selected>{{ 'SELECT' | translate }}</option>
                  <option value="Considerable damage">{{ 'CONSIDERABLE_DAMAGE' | translate }}</option>
                  <option value="Expired items">{{ 'EXPIRED_ITEMS' | translate }}</option>
                  <option value="Product stolen">{{ 'PRODUCT_STOLEN' | translate }}</option>
                  <option value="Return to the warehouse">{{ 'RETURN_TO_THE_WAREHOUSE' | translate }}</option>
                </select>
                <div *ngIf="productDeleted.get('reason').value == '' || productDeleted.get('reason').hasError('required')">
                  <div class="text-danger">{{ 'DELETION_REASON_IS_REQUIRED' | translate }}.</div>
                </div>
              </div>

              <div>
                <label for="description">{{ 'DETAILED_DESCRIPTION_REASON' | translate }}:</label><br>
                <textarea formControlName="description" class="input-header tw-h-40 tw-max-h-40" id="description" name="description" rows="5" cols="40" maxlength="300" (input)="updateCharCount($event)"></textarea>
                <div class="tw-text-xs">{{charCount}}/300 {{ 'CHARACTERS' | translate }}</div>
                <div *ngIf="productDeleted.get('description').value == '' || productDeleted.get('description').hasError('required')">
                  <div class="text-danger">{{ 'DETAILED_DESCRIPTION_REQUIRED' | translate }}.</div>
                </div>
              </div>

              <div class="tw-mb-4">
                  <label for="retail">{{ 'QUANTITY_TO_DELETE' | translate }}:</label>
                  <div class="tw-flex tw-items-center tw-mt-2">
                    <button (click)="decrementQuantity('delete')" class="tw-rounded-full tw-border tw-border-gray-400 tw-mr-2 hover:tw-bg-gray-200 tw-max-h-8 tw-max-w-10 tw-flex tw-items-center">
                      <span class="material-icons">remove</span>
                    </button>
                    <input formControlName="retail" id="retail" placeholder="{{ 'QUANTITY' | translate }}" type="number" 
                    class="input-header tw-ml-2 tw-py-2 tw-px-4 tw-rounded-md tw-w-16 tw-text-center tw-border tw-border-gray-400 tw-shadow-sm"
                    (input)="onQuantityChange($event, 'delete')" (keydown.enter)="preventEnter($event)">
                    <button (click)="incrementQuantity('delete')" class="tw-rounded-full tw-border tw-border-gray-400 tw-ml-2 hover:tw-bg-gray-200 tw-max-h-8 tw-max-w-10 tw-flex tw-items-center">
                      <span class="material-icons">add</span>
                    </button>
                  </div>
                  <div *ngIf="productDeleted.get('retail').value == null && productDeleted.get('retail').hasError('required')">
                    <div class="text-danger">{{ 'QUANTITY_TO_DELETE_REQUIRED' | translate }}.</div>
                  </div>  
                  <div *ngIf="productDeleted.get('retail').value == 0 && productDeleted.get('retail').hasError('min')">
                    <div class="text-danger">{{ 'QUANTITY_TO_DELETE_GREATER_THAN_ZERO' | translate }}.</div>
                  </div>                       
              </div>
            </div>
          </div>
        </div>
  
        <div class="tw-flex tw-items-center tw-justify-center tw-mt-4">
          <button [ngClass]="{'disabled-button': productDeleted.invalid}"
            (click)="deleteFromRetail(productDeleted.value, customStockItems.value)" class="btn-blue-so tw-flex" style="cursor:pointer;">
            {{ 'CONFIRM' | translate }}
          </button>
          <button (click)="closeModal(true)" class="btn-blue-so tw-flex tw-ml-4" style="cursor:pointer;">
            {{ 'CLOSE' | translate }}
          </button>
        </div>
      </div>
    </div>
  </form>
</ng-template>