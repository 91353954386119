
import { Component, OnInit, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { StyleitemsService } from '../../../../_services/styleitems.service';
import { AlertifyService } from '../../../../_services/alertify.service';
import { AuthService } from '../../../../_services/auth.service';
import { ActivatedRoute } from '@angular/router';
import { Pagination } from '../../../../_modules/pagination';
import { StoreproductsService } from '../../../../_services/storeproducts.service';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-styleitemsmobile',
  templateUrl: './styleitemsmobile.component.html',
  styleUrls: ['./styleitemsmobile.component.scss']
})
export class StyleitemsmobileComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  styleItems: any = [];
  manufacturers: any = [];
  styles: any = [];
  pagination: Pagination;
  totalStyles = 0;
  selectedStyle = '';
  selectedMan = '';
  ROID = '';
  userRole = '';
  constructor(
    private styleService: StyleitemsService,
    private alertify: AlertifyService,
    private chRef: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authService: AuthService,
    private storeProductsService: StoreproductsService) {
  }

  ngOnInit() {
    // this.userRole = localStorage.getItem('role').replace('"', '');
    this.userRole = this.authService.decodeToken('role').role;

    this.pagination = {
      totalItems: 10,
      totalPages: 10,
      currentPage: 1,
      itemsPerPage: 10
    };

    this.styleService.getManufacturers()
    .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
          this.manufacturers = res;
        }, error => {
          this.alertify.error(error);
        });

    // this.ROID = this.authService.decodedToken.DID;
    this.ROID = this.authService.decodeToken('DID').DID

    this.styleService.clearStyleItems
    .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
          if (res === true) {
            this.clearFilter();
          }
        }, error => {
          this.alertify.error(error);
        });

  }


  get selectedManMod() {
    return this.selectedMan;
  }

  set selectedManMod(value) {
    this.selectedMan = value;
    this.selectedStyle = '';
    this.loadStyles();
    //this.loadStyleItems();
  }

  loadStyles() {
    this.styleService.getStyles(this.selectedMan)
    .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
          this.styles = res;
        }, error => {
          this.alertify.error(error);
        });
  }

  get selectedStyleMod() {
    return this.selectedStyle;
  }

  set selectedStyleMod(value) {
    this.selectedStyle = value;
    //this.loadStyleItems();
  }

  loadStyleItems() {
    this.styleService.getStyleItems(this.pagination.currentPage, this.pagination.itemsPerPage, this.selectedStyle, this.selectedMan)
    .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
          this.styleItems = res.result;
          this.styleItems.forEach(element => {
            //   element['Price'] = element['Price'].toString().replace('.00','');
          });

          this.pagination = res.pagination;
          this.totalStyles = this.pagination.totalItems;

          this.storeProductsService.raiseStoreEvent(this.selectedMan, this.selectedStyle);

        }, error => {
          this.alertify.error(error);
        });
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    this.loadStyleItems();
  }

  addStoreItems() {
    const man = this.manufacturers.find(x => x.SID === this.selectedMan);

    let msg = 'Add the Store Items from  ' + man.companyName;

    const sty = this.styles.find(x => x.styleId === this.selectedStyle);
    let styID = '';
    if (sty) {
      msg = msg + ' / ' + sty.styleName;
      styID = sty.styleId;
    }
    msg = msg + '?';

    this.alertify.confirm(msg, () => {

      this.styleService.addStoreProducts(man.SID, styID)
      .pipe(takeUntil(this.destroy$))   
        .subscribe(() => {
          this.alertify.success('Items were added to the Store successfully');
          this.storeProductsService.raiseStoreEvent(man.SID, styID);

        }, error => {
          this.alertify.error(error);
        });
    }
    ,()=>{
    });
  }


  clearFilter() {
    this.selectedMan = '';
    this.selectedStyle = '';
    this.styles = [];
    this.styleItems = 0;
    this.totalStyles = 0;
    this.pagination = {
      totalItems: 0,
      totalPages: 0,
      currentPage: 0,
      itemsPerPage: 0
    };
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
