import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { SalesfrontComponent } from '../components/salesorder/salesfront/salesfront.component';
import { SalesorderFormComponent } from '../components/salesorder/salesorder-form/salesorder-form.component';
import { AlertifyService } from '../_services/alertify.service';
import { SalesOrderService } from '../_services/salesorder.service';
import { SoComponent } from '../components/so/so/so.component';

@Injectable()
export class UnsavedSalesOrderChanges implements CanDeactivate<SoComponent> {
   yo:boolean = false;
  constructor(private alertify: AlertifyService,
   private salesSerivce:SalesOrderService){
  }

  async canDeactivate(component: SoComponent) {
   
   this.yo = true;   
   
   if(component['so'].payments != undefined){
      let tocheck = 0;
      let toInsert = 0;
      let toUpdate = 0;
      let toVoid = 0;

      component['so'].payments.forEach(element => {
         if (element.status == 'insert' && element.paymentType == "Credit Card") {            
            tocheck ++;
            toInsert ++;
         }else if (element.status == 'fromDB' && element.paymentType == "Credit Card"){
            let originalPay = component['salesOrderO'].payments.filter((x:any) => x.paymentID == element.paymentID)
            
            if(originalPay[0].paymentType != "Credit Card"){              
               tocheck ++;
               toUpdate ++;
            }
         }else if(element.voidtype_tx != undefined && element.voidtype_tx != null){
            let originalPay = component['salesOrderO'].payments.filter((x:any) => x.paymentID == element.paymentID)
            if(originalPay[0].voidtype_tx == undefined || originalPay[0].voidtype_tx == null){
               tocheck ++;
               toVoid ++;
            }
         }
      });
      

      if(tocheck > 0){

         let msg = '';
         
         if (toVoid > 0) {
            msg = 'The Sales Order has VOID payments unsaved, if you leave, your payment will be update';
         }
         if (toUpdate > 0 || toInsert > 0) {
            msg = 'The Sales Order has Credit Card payments unsaved, if you leave, your payment will be update';
         }

         await new Promise((resolve,reject)=>{
            this.alertify.confirm(msg,
               ()=>{
                  this.salesSerivce.forceUpdatePayments(component['salesOrder'].SOID,
                  {payments:component['salesOrder'].payments,PaymentsToDelete:component['salesOrder'].PaymentsToDelete})
                  .toPromise()
                  .then((result) => {
                     console.log();                  
                  }).catch((err) => {
                     this.alertify.success('Payments saved')
                  });

                  setTimeout(() => {
                     resolve(this.yo = true)
                  }, 500);
               },
               ()=>{
                  return false;
               }
            );
                
            setTimeout(() => {         
               reject(this.yo = false) 
            }, 10000) 
         });
      
      }else{
         this.yo = true;
      }

   }else{
      this.yo = true
   } 

      return this.yo; 
    }
}
