import { Component, OnInit, Input, IterableDiffers, OnChanges, SimpleChanges, DoCheck, IterableDiffer, KeyValueDiffer, KeyValueDiffers, AfterContentChecked, Output, EventEmitter } from '@angular/core';
import { SalesOrderItemsService } from '../../../_services/salesorderitems.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { SettingsService } from 'src/app/_services/settings.service';
import { AuthService } from 'src/app/_services/auth.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
@Component({
  selector: 'app-salesordertotal-mobile',
  templateUrl: './salesordertotal-mobile.component.html',
  styleUrls: ['./salesordertotal-mobile.component.scss']
})
export class SalesordertotalMobileComponent implements OnInit, AfterContentChecked
{
  @Input() salesOrder: any;
  lastSalesOrder: any;

  @Output('calculateTaxes') calculateTaxes: EventEmitter<any> = new EventEmitter();
  @Input() showCalculateTaxes: boolean = true;
  @Output() showCalculateTaxesChange = new EventEmitter<boolean>();
  @Input() lastSalesOrderTotal: number;
  @Input() edit: boolean;
  salesOrderDeliveryTaxes: number;
  avaTaxActive: boolean = true
  salesOrderItemTaxes: number;
  salesOrderDiscountTaxes: number;

  salesOrderTotal: number;
  salesOrderSubTotal: number;
  salesOrderDiscounts: number;
  salesOrderDelivery: number;
  salesOrderItems: number;
  salesOrderTaxes: number;
  storeSettings: any;
  salesOrderPayments: number;
  deliveryTaxable: number;
  salesOrderBalance: number = 0;
  constructor(private _iterableDiffers: IterableDiffers,
    private _differs: KeyValueDiffers,
    private globals: GlobalsService,
    private settingsService: SettingsService,
    private authService: AuthService,
    private itemsService: SalesOrderItemsService ) {
  }
  destroy$: Subject<boolean> = new Subject<boolean>();

  ngOnInit() {
    this.settingsService.SettingsEvent$
    .pipe(takeUntil(this.destroy$))   
    .subscribe( data => {
      if(data.length > 0){
        if(data[0]) this.storeSettings = data[0];
        
        if(this.storeSettings['avaTax'] == 0 || this.storeSettings['avaTax'] == false){
          this.avaTaxActive = false
        }else{
          if((this.salesOrder['avalaraActive'] != null && this.salesOrder['avalaraActive'] != undefined) || !this.edit){
            this.avaTaxActive = true
          }else{
            this.avaTaxActive = false
          }
        }
      }
    }, error => {
    });




    if(this.salesOrder){
      this.lastSalesOrderTotal = this.salesOrder['totalOrder']
    }

  }

  ngAfterContentChecked(): void {
    if (this.salesOrder) {
      if(JSON.stringify(this.salesOrder) === JSON.stringify(this.lastSalesOrder) ){
        // console.log('nothing has changed')
      }else{
        // console.log('something has changed')
        this.updateTotals()
      }
    }
  }


  updateTotals() {
    const soItems = this.salesOrder['soItems'];
    const deliveryItems = this.salesOrder['deliveryItems'];
    const payments = this.salesOrder['payments'];
    this.salesOrderTotal = 0;
    this.salesOrderSubTotal = 0;
    this.salesOrderDiscounts = 0;
    this.salesOrderDelivery = 0;
    this.salesOrderItems = 0;
    this.salesOrderTaxes = 0;
    this.salesOrderPayments = 0;
    this.deliveryTaxable = 0;
    this.salesOrderItemTaxes = 0;
    this.salesOrderDeliveryTaxes = 0;
    this.salesOrderDiscountTaxes = 0;

    if (deliveryItems) {
      for (const i of deliveryItems) {
        if (i.deliveryAmountNum > 0) {
          this.salesOrderDelivery += this.globals.pNumber(i.deliveryAmountNum);
          //Math.round(parseFloat(i.deliveryAmountNum) * 100) / 100;
          if (i.pretax == 1) {
            //this.deliveryTaxable += Math.round(parseFloat(i.deliveryAmountNum) * 100) / 100;
            this.deliveryTaxable += this.globals.pNumber(i.deliveryAmountNum);
    
            if (i.taxRateAvalara != undefined && i.taxRateAvalara != null) {
              this.salesOrderDeliveryTaxes += (i.isItemTaxable == true || i.isItemTaxable == "1") ? parseFloat(i.taxCalculated) : 0;
            }else{
              this.salesOrderDeliveryTaxes += (this.salesOrder['taxNumber'] / 100) * i.deliveryAmountNum;
            }
            
          }

          }
      }
    }
    if (payments) {
      for (const i of payments.filter(e => e.voidtype_tx == undefined || e.voidtype_tx == null)) {
        if (i.paymentAmount !== 0) {
          //this.salesOrderPayments +=   Math.round(parseFloat(i.paymentAmount) * 100) / 100;
          this.salesOrderPayments +=   this.globals.pNumber(i.paymentAmount);
          }
      }
    }
    if (soItems) {
      for (const i of soItems) {
        if (i.priceNumUnit > 0 && i.itemType !== 'discount') {
          this.salesOrderSubTotal += (i.priceNumUnit * i.quantityNum);
          }
          if (i.itemType) {
          if (i.itemType.toString().toLowerCase() === 'discount') {
            if (i.priceNumUnit < 0) {
            this.salesOrderDiscounts += (i.priceNumUnit * i.quantityNum);
            } else if (i.priceNumUnit > 0) {
            this.salesOrderDiscounts += (i.priceNumUnit * -1 * i.quantityNum);
            }
          }
        }

        if (i.itemType !== 'discount') {
          this.salesOrderItems += Number(i.quantityNum);
        }
     
        let discount = soItems.filter(item => item.noteExp == i.SOItemsID).reduce((acc, {priceNumUnit}) => acc + (priceNumUnit > 0 ? priceNumUnit : priceNumUnit*-1), 0)//.reduce((item, acc) => acc + item.priceNumUnit, 0)
        if (i.itemType !== 'discount' && i.taxRateAvalara != undefined && i.taxRateAvalara != null) {
          this.salesOrderItemTaxes += (i.isItemTaxable == true || i.isItemTaxable == "1") ? parseFloat(i.taxCalculated) : 0;
        }else if(i.itemType !== 'discount'){
          this.salesOrderItemTaxes += (this.salesOrder['taxNumber'] / 100) * ((i.priceNumUnit * i.quantityNum) - discount);
        } 
        
        if(i.itemType == 'discount' && i.manufacter == 'ORDER DISCOUNT' && (i.taxRateAvalara == undefined || i.taxRateAvalara == null)){
          // Taxes Order disocunt to substract in sales order when Avalara isnot active
          this.salesOrderDiscountTaxes += (this.salesOrder['taxNumber'] / 100) * (i.priceNumUnit * i.quantityNum);
        }else if(i.itemType == 'discount' && i.manufacter == 'ORDER DISCOUNT' && (i.taxRateAvalara != undefined && i.taxRateAvalara != null)){
          this.salesOrderItemTaxes += (i.isItemTaxable == true || i.isItemTaxable == "1") ? parseFloat(i.taxCalculated) : 0;
        }
      }
      // }
      // this.salesOrderSubTotal = (this.salesOrderSubTotal + this.salesOrderDiscounts);
      //  this.salesOrderTaxes = Number((this.salesOrderSubTotal * (this.salesOrder['taxNumber']) / 100)) +
      //  Number((this.deliveryTaxable * (this.salesOrder['taxNumber']) / 100));
      // this.salesOrderTotal = Number((this.salesOrderSubTotal  + this.salesOrderDelivery + this.salesOrderTaxes));
      //console.log(this.salesOrderDiscounts);
      //console.log(this.salesOrderDiscounts);
      this.salesOrderPayments = this.globals.pNumber(this.salesOrderPayments);
      this.salesOrderSubTotal = this.globals.pNumber(this.salesOrderSubTotal) + this.globals.pNumber(this.salesOrderDiscounts);
       this.salesOrderTaxes =
             this.globals.pNumber(this.salesOrderItemTaxes) +
           this.globals.pNumber(this.salesOrderDeliveryTaxes)+
           this.globals.pNumber(this.salesOrderDiscountTaxes);
      this.salesOrderTotal = this.globals.pNumber((   this.globals.pNumber(this.salesOrderSubTotal)  +
        this.globals.pNumber(this.salesOrderDelivery) +
      this.globals.pNumber(this.salesOrderTaxes)));
      // console.log(this.lastSalesOrderTotal, this.salesOrderTotal)
      //Check if the customer has change to show button calculate taxes
      let equalCustomer = JSON.stringify({
        deliveryRequest: this.salesOrder?.deliveryRequest,
        deliveryAddress1:  this.salesOrder?.deliveryAddress1,
        deliveryAddress2:  this.salesOrder?.deliveryAddress2,
        deliveryCity:  this.salesOrder?.deliveryCity,
        deliveryState:  this.salesOrder?.deliveryState,
        deliveryZip:  this.salesOrder?.deliveryZip,
      }) == JSON.stringify({
        deliveryRequest: this.lastSalesOrder?.deliveryRequest,
        deliveryAddress1:  this.lastSalesOrder?.deliveryAddress1,
        deliveryAddress2:  this.lastSalesOrder?.deliveryAddress2,
        deliveryCity:  this.lastSalesOrder?.deliveryCity,
        deliveryState:  this.lastSalesOrder?.deliveryState,
        deliveryZip:  this.lastSalesOrder?.deliveryZip,
      });

      // Check if any items has change to show button calculate taxes
      let equalInItems = JSON.stringify(this.lastSalesOrder?.soItems != undefined ? this.lastSalesOrder.soItems.
        map(e => ({
          SOItemsID: e.SOItemsID,  itemDelivery : e.itemDelivery, isItemTaxable : (e.isItemTaxable == true || e.isItemTaxable == "1" || e.isItemTaxable == 1) ? true: false, deliveryAttn: e.deliveryAttn, deliveryPhone: e.deliveryPhone, deliverEmail: e.deliverEmail, deliveryAddress1: e.deliveryAddress1, deliveryAddress2: e.deliveryAddress2, deliveryState: e.deliveryState, deliveryCity: e.deliveryCity, deliveryZip: e.deliveryZip})): []) == JSON.stringify(this.salesOrder.soItems.
        map(e => ({
          SOItemsID: e.SOItemsID,  itemDelivery : e.itemDelivery, isItemTaxable : (e.isItemTaxable == true || e.isItemTaxable == "1" || e.isItemTaxable == 1) ? true: false, deliveryAttn: e.deliveryAttn, deliveryPhone: e.deliveryPhone, deliverEmail: e.deliverEmail, deliveryAddress1: e.deliveryAddress1, deliveryAddress2: e.deliveryAddress2, deliveryState: e.deliveryState, deliveryCity: e.deliveryCity, deliveryZip: e.deliveryZip})));
     
          // console.log(this.lastSalesOrderTotal, this.salesOrder['totalOrder'], (this.lastSalesOrderTotal != this.salesOrderTotal), equalCustomer, equalInItems)
      if((this.lastSalesOrderTotal != this.salesOrderTotal) || !equalInItems || !equalCustomer){
        this.showCalculateTaxesChange.emit(true)
      }else{
        this.showCalculateTaxesChange.emit(false)
      }
      // console.log('SalesOrderTaxes Without ' + this.salesOrderTaxes);
      // console.log('SalesOrderTaxes With ' + this.globals.pNumber(this.salesOrderTaxes));

      // console.log('Taxes ' + this.globals.pNumber(this.salesOrderSubTotal) *
      // this.globals.pNumber((this.salesOrder['taxNumber']) / 100) +
      // this.globals.pNumber(this.deliveryTaxable) * 
      // this.globals.pNumber(this.salesOrder['taxNumber']) / 100);


      this.salesOrder['SubTotal'] = this.globals.pNumber(this.salesOrderSubTotal);
      this.salesOrder['Payments'] =  this.globals.pNumber(this.salesOrderPayments);
      this.salesOrder['delivery'] =  this.globals.pNumber(this.salesOrderDiscounts);
      this.salesOrder['Delivery'] =  this.globals.pNumber(this.salesOrderDelivery);
      this.salesOrder['Total'] =   this.globals.pNumber(this.salesOrderTotal);
      this.salesOrder['totalTaxNum'] =   this.globals.pNumber(this.salesOrderTaxes);
      // this.salesOrder['Balance'] = Math.round((this.salesOrderTotal) * 100) / 100 - this.salesOrderPayments;
      // this.salesOrderBalance = Math.round((this.salesOrderTotal) * 100) / 100 - this.salesOrderPayments;
      this.salesOrder['Balance'] = this.globals.pNumber(this.salesOrderTotal)  - this.globals.pNumber(this.salesOrderPayments);
      this.salesOrderBalance = this.globals.pNumber(this.salesOrderTotal)   - this.globals.pNumber(this.salesOrderPayments);

      
      if (this.salesOrder['payments'] === undefined) {
        if (this.salesOrder['status'].includes('Draft'))  {
          this.salesOrder['status'] = 'Draft';
        }
        this.lastSalesOrder = JSON.parse(JSON.stringify(this.salesOrder))
        return;

        //this.salesOrder['status'] = 'PIF'
      }

      if (this.salesOrder['payments'].length === 0) {

        if (this.salesOrder['status'].includes('Draft'))  {
          this.salesOrder['status'] = 'Draft';
          }
        this.lastSalesOrder = JSON.parse(JSON.stringify(this.salesOrder))
        return;
      }



      if (this.salesOrder.Balance <= 0
        && this.salesOrder['soItems'] !== undefined
        && this.salesOrder['soItems'].length > 0 
        ) {
          if(this.salesOrder['status'] !== 'VOID' && !(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings?.cashierStatus == 'vc' || this.storeSettings?.cashierStatus == 'vcsp')) ){
            this.salesOrder['status'] = 'PIF';
          }
      } else {
           if (this.salesOrder['status'] === 'Draft')  {
           this.salesOrder['status']= 'Draft';
           }
      }



    }
    this.lastSalesOrder = JSON.parse(JSON.stringify(this.salesOrder))


  }

   calculateTax():void{
    this.calculateTaxes.emit();
    this.lastSalesOrderTotal = this.salesOrderTotal
  }
}