import { Component, OnInit, OnDestroy, ChangeDetectorRef, Input, ViewEncapsulation } from '@angular/core';
import { EventsService } from 'src/app/_services/events.service';
import 'rxjs/add/operator/takeUntil';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { Pagination } from 'src/app/_modules/pagination';
import { PurchaseOrderService } from 'src/app/_services/purchaseorder.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';
import { SettingsService } from 'src/app/_services/settings.service';
import { take } from 'rxjs/operators/take';
import { FormControl, Validators } from '@angular/forms';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-purcharorders-list',
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({ opacity: 0}),
          animate('500ms', style({ opacity: 1}))
        ]),
        transition(':leave', [
          style({ opacity: 1}),
          animate('100ms', style({ opacity: 0}))
        ])
      ]
    )
  ],
  templateUrl: './purcharorders-list.component.html',
  styleUrls: ['./purcharorders-list.component.scss']
})
export class PurcharordersListComponent implements OnInit, OnDestroy {
  
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() forSummary = false;
  @Input() poNumber = '';
  purchaseorders: any = [];
  purchaseOrderToGo: any;
  event: any;
  filter: any;
  orderField = 'POUUID';
  orderDir = 'desc';
  pagination: Pagination;
  dateFilter = '';
  statusFilter = '';
  settings: any = [];
  username = '';
  SOselected :any= [];
  password = '';
  totalItems = 0;
  Xtop: number = 0;
  Ytop:number = 0;
  realYtop:number = 0;
  realXtop:number = 0;
  totalItemsS = '';
  totalAmount = 0;
  totalAmountS = '';
  uploadingPO = false;
  showSODetails: boolean = false;
  setVisible: boolean = false;
  lookingyet: boolean = true;
  userRole = '';
  maxsize = 10;
  viewby = true;
  status =  true;
  sortBy =  true;
  paginationMode = false;
  showPagination = false;
  MyPOIDSelected : any
  POItemsToShow: any = []
  todelete: any = []
  itemsSO: any = []
  platform = '';
  showMobile = false;
  isIOS = false;
  isAndroid = false;
  sifunciono = "nofunciono";

  //Open NavbarOption Filter
  openView = false;
  openStatus = false;
  eventSelected = false;
  sortBySelect = false;
  //sortby Text
  sortByText = '';

  counto = 0;

    //validation search
    searchPurchaseOrder = new FormControl('',Validators.pattern('[a-zA-Z0-9- ]*'));
    specialcha:boolean = false;
    inputSearchCss:string = "main-navbar-content-page-search";
    inputSearchCssmobile:string = "main-navbar-content-page-search search-mobile";

  constructor(private purchaseOrderService: PurchaseOrderService, private eventService: EventsService,
    private alertify: AlertifyService,
    private chRef: ChangeDetectorRef, private route: ActivatedRoute, private router: Router,
    public bsModalRef: BsModalRef,
    public purchaseService: PurchaseOrderService,
    private purchaseSerivce: PurchaseOrderService,
    private globals: GlobalsService,
    private salesService: SalesOrderService,
    private authService: AuthService,
    private settingsService: SettingsService,
		private translate: TranslateService,
    ) { 
      
      this.route.params      
 .pipe(takeUntil(this.destroy$))  
      .subscribe(params => {
        //this.eventService.salesOrderEvent.next(params['id']);
        this.event = params['id'];
        if(params['type'] != null){
          if(params['type'] == 'PO'){
            this.poNumber= params['sonumber']
            this.pagination =  {
              totalItems: 10,
              totalPages: 10,
              currentPage: 1,
              itemsPerPage: 10
            };
          }
        }
      });
    }

    situatePO():void{
      if((this.poNumber == null || this.poNumber == undefined || this.poNumber == "" )){
        this.lookingyet = false
        return;
      }
      let items = this.purchaseorders.filter(e => e.POUUID == this.poNumber) 
      // If items is empty means that is not in this page so we have to retrieve next page
      if(items.length == 0){
        let event = {
          page: this.pagination.currentPage + 1,
          itemsPerPage: 10
        }
        if(event.page > this.pagination.totalPages){
          this.lookingyet = false
          return;
        }
        this.lookingyet = true
        this.pageChanged(event)
      }else{
        items.forEach(el => {
          // console.log('Found: ',el);
            this.showPO(el?.MyPOID, el)
        });
        this.lookingyet = false
      }
    }
  
    

  ngOnInit() {

    
    // this.userRole = localStorage.getItem('role').replace('"', '');
    this.userRole = this.authService.decodeToken('role').role;

    this.pagination =  {
      totalItems: 10,
      totalPages: 10,
      currentPage: 1,
      itemsPerPage: 10
    };
    this.pagination.currentPage = this.eventService.pagPOSummary.getValue();      
  this.searchPurchaseOrder.setValue(this.eventService.inputSearchPOSummary.getValue());

    if (this.globals.isMobile()) {
      this.maxsize = 4;
    }
    this.settingsService.getSettings(0)
    .pipe(takeUntil(this.destroy$))   
    .subscribe( data => {
      this.username =  data[0]['username'];
      this.password = data[0]['password'];
    }, error => {
      this.alertify.error(error);
    });

    this.eventService.salesOrderEvent
    .pipe(takeUntil(this.destroy$))   
    .subscribe((
      res => {
        
        this.event = res;
        this.eventService.getEvent(this.event).subscribe( data => {
          // console.log('from PO')
          this.event = data[0];  
          this.statusFilter = this.eventService.statusPOSummary.getValue();
          this.dateFilter = this.eventService.viewPOSummary.getValue();
          this.loadPurchaseOrder();
          this.showPagination = true;
        }, error => {
          this.alertify.error(error);
        });
      }
    ));

    this.platform = navigator.userAgent;
    if (this.platform.match(/Android/i)) {
      this.showMobile = true;
      this.isAndroid = true;
    }
    if (this.platform.match(/iPhone|iPad|iPod/i)) {
      this.showMobile = true;
      this.isIOS = true;
 
    }
 
    if (this.platform.includes('Mac')) {
      this.showMobile = false;
 
    }
 
    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
     this.showMobile = true;
     this.isIOS = true;
     this.sifunciono = "si funciono"
   }
 
    if (this.platform.includes('Win')) {
      this.showMobile = false;
 
    }

    fromEvent(document.body, 'click').pipe(takeUntil(this.destroy$))  
    .subscribe((e:any) => {
      if(document.getElementById('clickbox') != null){
        if (document.getElementById('clickbox').contains(e.target)){
        
        } else{
          this.showSODetails = false
          this.setVisible = false
          this.itemsSO = []
        }
      }
      if(!this.showSODetails || document.getElementById('clickbox') == null){
        this.Xtop = e.pageX
        this.Ytop = e.pageY
        this.realYtop = e.pageY - 50
      }
    })

    fromEvent(document.body, 'touchstart').pipe(takeUntil(this.destroy$))  
    .subscribe((e:any) => {
      if(document.getElementById('clickbox') != null){
        if (document.getElementById('clickbox').contains(e.target)){
        
        } else{
          this.showSODetails = false
          this.setVisible = false
          this.itemsSO = []
        }
      }
      if(!this.showSODetails || document.getElementById('clickbox') == null){
        this.Xtop = e.pageX
        this.Ytop = e.pageY
        this.realYtop = e.pageY - 50
      }
    })
  }

  recalculateTotalAmount(){
    this.purchaseorders.forEach(po => {
        if(po['MyPOID'] == this.MyPOIDSelected){
          po['TotalAmount'] = 0
          po['TotalItems'] = 0
          this.POItemsToShow?.poitemsItems.forEach(poitem => {
            po['TotalAmount'] +=  (poitem?.wholeSalePrice * poitem?.quantityNum)
            po['TotalItems'] +=  parseInt(poitem?.quantityNum)
          });
        }
    });
  }

  deleteItem(itemId:any,poitid:any,qty:any) {
    this.alertify.confirm(this.translate.instant('DELETE_PO_ITEM_CONFIRMATION'),
    () => {
      
    var toPOdelete = []
    var todelete = []

    this.POItemsToShow.poitemsItems = this.POItemsToShow.poitemsItems.filter(obj => obj['SOItemsID'] !== itemId);
    // this.poitemsItemsSelected = this.poitemsItems.filter(x => x['POITID'] === this.filterIdPoItem);
    todelete.push({"item":itemId,"poitid":poitid,"qty":qty})

    // verifico si ha borrado todos los id de esa PO para borrar toda la PO
    let poitemWilVerified = this.POItemsToShow.poitemsItems.filter(obj => obj['POITID'] == poitid);
    if(poitemWilVerified.length == 0){

      this.POItemsToShow.poItems = this.POItemsToShow.poItems.filter(obj => obj['POITID'] !== poitid);  
      toPOdelete.push({"poPOitid":poitid})
      // let postrequest = {"delete":[{"poPOitid":poitid}]};
      // console.log(postrequest);

      todelete = todelete.filter(obj => obj['poitid'] !== poitid);
    }else{      
      const toUpdateQty= this.POItemsToShow.poItems.findIndex(obj => obj['POITID'] === poitid);
      this.POItemsToShow.poItems[toUpdateQty].soQtyNum = this.POItemsToShow.poItems[toUpdateQty].soQtyNum - qty;
      this.POItemsToShow.poItems[toUpdateQty].Qty = this.POItemsToShow.poItems[toUpdateQty].Qty - qty;
    }
    
    let postrequest = {"delete":toPOdelete, "update":todelete};
  

    // console.log(postrequest)
    this.purchaseSerivce.updatePurchaseOrderItems(postrequest)
    .pipe(takeUntil(this.destroy$))   
    .subscribe(res => {
      // this.purchaseForm.markAsPristine();
      this.alertify.success('Purchase Order has been updated'); 
      this.recalculateTotalAmount()     
      // this.disabledbutton = false
    }, error => {
      this.alertify.error(error);
    });


    }, 
    ()=>{

    })


  }

  deletePOItem(poitid:any,  poitems:any){
    poitems = poitems.filter(obj => obj['POITID'] !== poitid);  
    let postrequest = {"delete":[{"poPOitid":poitid}]};
    // console.log(postrequest);
  }

  showSOdetails(so, template:any){
      this.itemsSO = []
      this.SOselected = so
      this.salesService.searchAdvanceSOdetails(so?.SOID)
      .toPromise()
      .then((res:any)=>{
        if(res != null && res != 0 && res.length > 0){
          this.itemsSO = res[0];
          this.showSODetails = true
          setTimeout(() => {
            let box =  document.getElementById('clickbox').clientHeight as number;
            let boxwidth =  document.getElementById(so.SOID).clientWidth as number;
            let boxleft =  document.getElementById(so.SOID).getBoundingClientRect().left as number;
            // console.log(box)
            // console.log(boxwidth, boxleft)
            this.Xtop = boxwidth + boxleft + 35
            this.realXtop = boxwidth + boxleft+2
            this.Ytop = this.Ytop - (box/2)
            this.setVisible=true
          }, 100);

          // this.bsModalRef = this.modalService.show(template, { class: 'modal-lg', backdrop: true });
          // this.deliveriesdropdown = res[1];
          // this.paymentsdropdown = res[2];
        }
      })
      .catch((err)=>{console.log(err)});



  }

  
  search() {
    this.pageChanged(1);
    // this.loadPurchaseOrder();
  }
  closeEventSelected() {
    if (this.dateFilter == '' && this.statusFilter == '' && this.sortByText == '' ) {
      this.eventSelected = false;
    }
  }
  //Filtro Mobile funciones
  openviewBy() {
    this.viewby =! this.viewby;
    this.status = true;
    this.sortBy = true;

    if (this.viewby) {
      //console.log(this.viewby, 'true')
      this.eventSelected = false;
    } else {
      //console.log(this.viewby,'false')
      this.eventSelected = true;
    }
    if (this.dateFilter == '') {
     // console.log(this.dateFilter, 'true')
      this.eventSelected = false;
    } else {
      //console.log(this.dateFilter,'false')
      this.eventSelected = true;
    }
  }
  openstatus() {
    this.status =! this.status;
    this.viewby = true;
    this.sortBy = true;
    this.eventSelected = true;
  }
  
  showPO(MyPOID:any, items:any){
    // console.log(MyPOID, items);
    if(MyPOID != ''){
      // console.log(this.SOsItemsStock)
      this.POItemsToShow = items
      this.MyPOIDSelected = MyPOID;
 
    }else{
       this.POItemsToShow = MyPOID;
        this.MyPOIDSelected = []
    }
  }

  opensortBy() {
    this.sortBy =! this.sortBy;
    this.status = true;
    this.viewby = true;
    if (!this.dateFilter  && !this.statusFilter ) {
      this.eventSelected = true;
    }else {
      this.sortBySelect = true;
    }
  }
  //Filtro Mobile funciones End
  openViewByNav() {
    this.openView =! this.openView;
    this.openStatus = false;
  } 

  openStatusNav() {
    this.openStatus =! this.openStatus;
    this.openView = false;
  }


  loadPurchaseOrder() {

    this.paginationMode = true;
    let cid = '';
    if (this.event != null) {
      cid = this.event['CID'];
    }  
    
    if(this.searchPurchaseOrder.valid){

      
    this.eventService.statusPOSummary.next(this.statusFilter);
    this.eventService.viewPOSummary.next(this.dateFilter);


      this.inputSearchCss = "main-navbar-content-page-search" 
      this.inputSearchCssmobile = "main-navbar-content-page-search  search-mobile"     
      this.specialcha = false;
    // console.log(this.pagination.currentPage, this.pagination.itemsPerPage, this.searchPurchaseOrder,
    //   cid, this.orderField, this.orderDir, this.dateFilter, this.statusFilter)
    this.purchaseOrderService.getPurchaseOrders(this.pagination.currentPage, this.pagination.itemsPerPage, this.searchPurchaseOrder.value,
      cid, this.orderField, this.orderDir, this.dateFilter, this.statusFilter)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
      (res: any) => {
        this.purchaseorders = res.result;
        this.pagination = res.pagination;
          let purchaseOrdersWithItems =  this.purchaseorders.slice(0);

          this.totalItems = 0;
          this.totalAmount = 0;

          purchaseOrdersWithItems = purchaseOrdersWithItems.filter(x => x['TotalItems'] > 0);
          for (let index = 0; index < purchaseOrdersWithItems.length; index++) {
            this.totalItems += parseFloat(purchaseOrdersWithItems[index]['TotalItems']);
            this.totalAmount += parseFloat(purchaseOrdersWithItems[index]['TotalAmount']);

            //Convertir Total Items String con Decilmal
            var i = Math.round(this.totalItems);
            var partI = i.toFixed(2).split(".");
            var items = partI[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            this.totalItemsS = items;
            //Convertir Total Amount String con Decilmal
            var n = Math.round(this.totalAmount);
            var parts = n.toFixed(2).split(".");
            var amount = parts[0].replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
            this.totalAmountS = amount; 
          }

          if(this.lookingyet){
            this.situatePO()
          }

          this.paginationMode = false;

        
      //  this.createChart();
    }, (error) => {
      this.alertify.error(error);
    });
  
      }else{
        this.inputSearchCss = "main-navbar-content-page-search border-warning"      
        this.inputSearchCssmobile = "main-navbar-content-page-search border-warning search-mobile"   
        this.specialcha = true;
      }
  }


  changeDirection(field: string) {

    this.orderField = field;
    this.sortBy =! true;
    
    if (this.orderField == 'POUUID') {
      this.sortByText = 'Order NO.'
    }
    if (this.orderField == 'dbPOdatets') {
      this.sortByText = 'Date'
    }
    if (this.orderField == 'mfg') {
      this.sortByText = 'manufacturer'
    }
    if (this.orderField == 'shipA') {
      this.sortByText = 'Ship To'
    }
    if (this.orderField == 'DeliveryType') {
      this.sortByText = 'Delivery'
    }
    if (this.orderField == 'UploadStatus') {
      this.sortByText = 'Status'
    }

    if (this.orderDir === 'desc') {
      this.orderDir = '';
    } else {
      this.orderDir = 'desc';
    }


    this.loadPurchaseOrder();
  }



  selectPurchaseOrder(purchaseorder: any) {
    this.router.navigate(['/purchaseorder/' + purchaseorder['MyPOID']]);
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: this.forSummary ? 'Event Summary' : 'PO',
    //   action: 'Show PO',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
    
    this.router.navigate(['/purchaseorder/' + purchaseorder['MyPOID']], { state: { edit: false }} );
  }

  selectPurchaseOrderedit(purchaseorder: any) {
    this.router.navigate(['/purchaseorder/' + purchaseorder['MyPOID']], { state: { edit: true }} );
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  

  deletePurchaseOrder(purchaseOrder: any) {
    this.alertify.confirm(this.translate.instant('DELETE_PO_ITEM_CONFIRMATION') + purchaseOrder['POUUID']
    + ' ' + this.globals.getDate(purchaseOrder['showPOdate'])
    + ' '  + purchaseOrder['mfg'] + '?', () => {

      this.purchaseOrderService.deletePurchaseOrder(purchaseOrder['MyPOID'])
      .pipe(takeUntil(this.destroy$))   
      .subscribe((
        (res) => {
          this.alertify.success('The Purchase Order was deleted');
          const notDeleted = this.purchaseorders.filter(item => item['MyPOID'] !== purchaseOrder['MyPOID']);
          this.purchaseorders = notDeleted;

          let values = {
            eventId:this.event['CID']
          }
          this.purchaseService.updateEventWhenPoCreated(values).subscribe((data:any)=>{
            this.eventService.checkSOToPO().subscribe((data:any)=>{
              this.eventService.EventSOAndPO.next(data)
              this.poNumber = 'null';
           })
          })

          
        }
      ), error => {
        this.alertify.error(error);
      });
    }
    ,()=>{
    });

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: this.forSummary ? 'Event Summary' : 'PO',
    //   action: 'Delete PO',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
}

changePurchaseOrder(purchaseOrderFromList, message = true) {
    const purchaseOrder = JSON.parse(JSON.stringify(purchaseOrderFromList));
    delete purchaseOrder['TotalItems'];
    delete purchaseOrder['TotalAmount'];
    delete purchaseOrder['showPOdate'];
    delete purchaseOrder['poItems'];
    delete purchaseOrder['poitemsItems'];
    this.purchaseOrderService.updatePurchaseOrder(purchaseOrder['MyPOID'], purchaseOrder)
    .pipe(takeUntil(this.destroy$))   
    .subscribe(res => {
      if (message) {
      this.alertify.success('PO ' + purchaseOrder['POUUID'] + 'was updated');
      }
    }, error => {
      this.alertify.error(error);
    });

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: this.forSummary ? 'Event Summary':'PO',
    //   action: 'Change PO status ShipTo',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }
  pageChanged(event: any): void {
    if(event ==1){
    this.eventService.pagPOSummary.next(event);
    this.pagination.currentPage = event;
    }else{
    this.eventService.pagPOSummary.next(event.page);
    this.pagination.currentPage = event.page;      
    }
    this.eventService.inputSearchPOSummary.next(this.searchPurchaseOrder.value);
  
    this.loadPurchaseOrder();

  }

  sendAllPending() {

  }



  uploadPurchaseOrder(purchaseOrder) {
    this.uploadingPO = true;
    if (!purchaseOrder['shipA'] && purchaseOrder['DeliveryType'] !== 'Direct Shipment'
    && purchaseOrder['DeliveryType'] !== 'MattressFirm' && purchaseOrder['DeliveryType'] !== 'MattressFirm DNTO'
    ) {
      this.alertify.error('Please select a Ship To Address');
      this.uploadingPO = false;
      return;
    }
   
    this.purchaseOrderToGo = JSON.parse(JSON.stringify(purchaseOrder));
    delete this.purchaseOrderToGo['poItems'];
    delete this.purchaseOrderToGo['poitemsItems'];   
    this.purchaseOrderService.getPurchaseOrder(this.purchaseOrderToGo['MyPOID'])
    .pipe(takeUntil(this.destroy$))   
    .subscribe(
      data => {
        if (data['POItemsForUpload'] !== undefined) {
          const poItems = data['POItemsForUpload'];
          this.purchaseOrderToGo['poItems'] = poItems;
          this.cleanAndSend(this.purchaseOrderToGo, purchaseOrder);
        } else {
        }
      }, error => {
        this.uploadingPO = false;
      }
    );
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: this.forSummary ? 'Event Summary' : 'PO',
    //   action: 'Upload PO',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }
  cleanAndSend(purchaseOrderFromList, purchaseOrderOriginal) {

    const purchaseOrder = JSON.parse(JSON.stringify(purchaseOrderFromList));
    purchaseOrder['username'] =  this.username;
    purchaseOrder['userpass'] = this.password;
    purchaseOrder['dealerPO'] = purchaseOrder['POUUID'];
    purchaseOrder['optAddress'] = '';
    if (purchaseOrder['shipA'] === 'P Address') {
      purchaseOrder['optAddress'] = '0';
    } else if (purchaseOrder['shipA'] === 'Warehouse 1') {
      purchaseOrder['optAddress'] = '1';
    } else if (purchaseOrder['shipA'] === 'Warehouse 2') {
      purchaseOrder['optAddress'] = '2';
    } else if (purchaseOrder['shipA'] === 'Warehouse 3') {
      purchaseOrder['optAddress'] = '3';
    }  
    
    if (purchaseOrder['DeliveryType'] === 'MattressFirm' || purchaseOrder['DeliveryType'] === 'MattressFirm DNTO') {
      purchaseOrder['TypeProcess'] = purchaseOrder['DeliveryType'];
    }


    delete purchaseOrder['TotalItems'];
    delete purchaseOrder['TotalAmount'];
    delete purchaseOrder['showPOdate'];
    delete purchaseOrder['noteExp'];
    delete purchaseOrder['orderXmlHeader'];
    delete purchaseOrder['order_xml_headerexp'];
    delete purchaseOrder['eventId'];
    delete purchaseOrder['ShippingFullAddressexp'];
    delete purchaseOrder['FullAddress'];
    delete purchaseOrder['dbPOdatets'];
    delete purchaseOrder['dbActualyDateSent'];
    delete purchaseOrder['created_at'];
    delete purchaseOrder['updated_at'];
    delete purchaseOrder['dateCreated'];
    delete purchaseOrder['dateModified'];
    delete purchaseOrder['FullAddressexp'];

    if (purchaseOrder['DeliveryType'] === 'Direct Shipment'
    || purchaseOrder['DeliveryType'] === 'MattressFirm'
    || purchaseOrder['DeliveryType'] === 'MattressFirm DNTO') {
      purchaseOrder['ShippingFullAddress'] = 'DirectShipment';
      purchaseOrder['dshipName'] = purchaseOrder['deliveryAttn'];
      purchaseOrder['dshipPhone'] = purchaseOrder['deliveryPhone'];
      purchaseOrder['dshipPhone2'] = purchaseOrder['ds_phone2'];
      purchaseOrder['dshipEMail'] = purchaseOrder['deliveryEmail'];
      purchaseOrder['dshipAddress'] = purchaseOrder['deliveryAddress1'];
      purchaseOrder['dshipAddress2'] = purchaseOrder['deliveryAddress2'];
      purchaseOrder['dshipCity'] = purchaseOrder['deliveryCity'];
      if (this.globals.isNumeric(purchaseOrder['deliveryState'])) {
        purchaseOrder['dshipState'] = '';
        purchaseOrder['dshipZip'] = purchaseOrder['deliveryState'];
      } else {
        purchaseOrder['dshipState'] = purchaseOrder['deliveryState'];
        purchaseOrder['dshipZip'] = purchaseOrder['deliveryZip'];
      }
    }

    let poid = purchaseOrder['MyPOID'];

    delete purchaseOrder['deliveryAttn'];
    delete purchaseOrder['deliveryPhone'];
    delete purchaseOrder['ds_phone2'];
    delete purchaseOrder['deliveryEmail'];
    delete purchaseOrder['deliveryAddress1'];
    delete purchaseOrder['deliveryAddress2'];
    delete purchaseOrder['deliveryCity'];
    delete purchaseOrder['deliveryState'];
    delete purchaseOrder['deliveryZip'];
    delete purchaseOrder['MyPOID'];
    delete purchaseOrder['CID'];
    delete purchaseOrder['mfg'];
    delete purchaseOrder['shipA'];
    delete purchaseOrder['MCID'];
    delete purchaseOrder['Note'];
    delete purchaseOrder['method'];
    delete purchaseOrder['POUUID'];
    delete purchaseOrder['TypeOfShipment'];
    delete purchaseOrder['UploadStatus'];
    delete purchaseOrder['confirmationPOID'];
    delete purchaseOrder['confirmationStatus'];
    delete purchaseOrder['DeliveryType'];

    this.purchaseOrderService.uploadPurchaseOrderConfirm(purchaseOrder, poid)
    //this.purchaseOrderService.uploadPurchaseOrder(purchaseOrder)
 .pipe(takeUntil(this.destroy$))   
    .subscribe((res:any) => {

      // this.purchaseorders.forEach(element => {
      //   if (element.MyPOID == this.purchaseOrderToGo['MyPOID']) {
      //       element.confirmationPOID = res.DDSPOID
      //   }
      // });      
      // console.log(res);
      purchaseOrderOriginal['confirmationStatus'] = res['status'];
      if (purchaseOrderOriginal['confirmationStatus'] !== 'ERROR') {
        purchaseOrderOriginal['UploadStatus'] = 'Uploaded';
        purchaseOrderOriginal['confirmationPOID'] =  res.DDSPOID;
      this.changePurchaseOrder(purchaseOrderOriginal, false);
      
      this.alertify.success('PO Data ' + purchaseOrder['dealerPO'] +  '   uploaded to DDS');
        // This is to charge notifications
      
      let values = {
        eventId:this.event['CID']
      }
      this.purchaseService.updateEventWhenPoCreated(values).subscribe((data:any)=>{
        this.eventService.checkSOToPO().subscribe((data:any)=>{
          this.eventService.EventSOAndPO.next(data)
          this.poNumber = 'null';
       })
      })

      } else {
        this.alertify.error('PO Data ' + purchaseOrder['dealerPO'] +  '   failed to upload to DDS');
      }


      this.uploadingPO = false;

    }, error => {    
      this.alertify.error(error);
      this.uploadingPO = false;
    });
  }

  uploadAllPending() {
    this.statusFilter = 'Pending';
    this.pagination.itemsPerPage = 150;
    this.loadPurchaseOrder();

    let cid = '';
    if (this.event != null) {
      cid = this.event['CID'];
    }
    this.purchaseOrderService.getPurchaseOrders(this.pagination.currentPage, this.pagination.itemsPerPage, this.searchPurchaseOrder.value,
      cid, this.orderField, this.orderDir, this.dateFilter, this.statusFilter).pipe(
      take(1))
      .subscribe(
      (res: any) => {
        this.purchaseorders = res.result;
        this.pagination = res.pagination;
        let purchaseOrdersWithItems =  this.purchaseorders.slice(0);

        this.totalItems = 0;
        this.totalAmount = 0;

        purchaseOrdersWithItems = purchaseOrdersWithItems.filter(x => x['TotalItems'] > 0);
        for (let index = 0; index < purchaseOrdersWithItems.length; index++) {
          this.totalItems += parseFloat(purchaseOrdersWithItems[index]['TotalItems']);
          this.totalAmount += parseFloat(purchaseOrdersWithItems[index]['TotalAmount']);
        }
        for (let index = 0; index < this.purchaseorders.length; index++) {
          const purchaseOrder = this.purchaseorders[index];
          if (this.purchaseorders[index]['confirmationStatus'].toLowerCase() !== 'ERROR')  {
            this.uploadPurchaseOrder(purchaseOrder);
            this.purchaseorders[index]['UploadStatus'] = 'Uploaded';
          }
          // this.loadPurchaseOrder();
        }
        this.loadPurchaseOrder();
    }, error => {
      this.alertify.error(error);
    });

    // setTimeout(() => {
    //   this.loadPurchaseOrder();
    // }, 2000);
    setTimeout(() => {
    this.statusFilter = '';
    this.pagination.itemsPerPage = 150;
    this.loadPurchaseOrder();
    }, 5000);

  }


}
