
<div style="padding: 1rem; height: 81vh;">
  <div style="height: 16vh;">
    <div style="display: flex; justify-content: space-between;">
      <label style="color: #6D6D6D;
      font-size: 22pt;
      font-weight: bolder;">
        {{ 'SEARCH_PRODUCT' | translate }}
      </label>
      <span class="material-icons" style="font-size: 60px; cursor: pointer;" (click)="closeModal()">close</span>
    </div>
 
    <div style="display: flex;margin-bottom: 1rem;">
      <div class="input-icon-container">
        <input spellcheck="false" class="inputSearch" (keyup.enter)="receiveData(src)" [(ngModel)]="src" type="text" required>
        <div (click)="receiveData(src)" class="icon-wrapper">
          <svg id="gray_search_icon" style="width:28px;" data-name="gray search icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" class="material-icons" viewBox="0 0 25 24.993">
            <defs>
              <clipPath id="clip-path">
                <rect id="Rectangle_79" data-name="Rectangle 79" width="25" height="24.993" fill="#adadad"/>
              </clipPath>
            </defs>
            <g id="Group_34" data-name="Group 34" clip-path="url(#clip-path)">
              <path id="Path_48" data-name="Path 48" d="M25,22.878a3.336,3.336,0,0,0-1.009-1.63c-1.351-1.336-6.444-6.428-7.787-7.772-.263-.264-.28-.345-.093-.669a8.442,8.442,0,0,0,1.072-3.136,8.208,8.208,0,0,0-.794-4.832A8.479,8.479,0,0,0,12.057.711a8.476,8.476,0,0,0-4.784-.6A8.467,8.467,0,0,0,2.547,2.5,8.391,8.391,0,0,0,.532,5.653a8.492,8.492,0,0,0-.48,3.87A8.468,8.468,0,0,0,2.8,14.988a8.441,8.441,0,0,0,9.9,1.185c.054-.03.108-.062.163-.091a.389.389,0,0,1,.572.081c.148.145.293.293.44.439,1.211,1.212,6.182,6.162,7.386,7.381a3.332,3.332,0,0,0,1.63,1.009h.7A2.091,2.091,0,0,0,25,23.583ZM2.3,8.638a6.329,6.329,0,1,1,6.343,6.325A6.321,6.321,0,0,1,2.3,8.638" transform="translate(0 0)" fill="#adadad"/>
            </g>
          </svg>
          
        </div>
      </div>
    </div>
  </div>



  <div class="container-gridS" style="padding-top: 0;overflow-y: auto; ">

    <div style="width: fit-content; margin-left: auto; margin-right: auto;">
      
      <div class="grid-container" [style.gap]="gap">
        <div class="grid-item" *ngFor="let sp of recordItems">

          <div class="itemsgrid">
            <div style="height: 60%;display: flex;justify-content: center; width: 100%; background-color: white;">
              <img  class="img_search" [src]="(sp.image) ? sp.image : 'assets/default.jpg'">
            </div>

            <div style="height: 20%; padding-left: 10px;display: flex; align-items: center;">
              <div style="text-align: start; font-weight: bold; font-size: 12pt;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">
                {{ sp.name }}
              </div>
            </div>

            <div style="height: 20%; padding-left: 10px; display: flex; align-items: start;">
              <div style="text-align: start; font-weight: 500; font-size: 12pt; ">
                {{ sp.priceNumUnit ? (sp.priceNumUnit | currency) : '' }}
              </div>
            </div> 
          </div>

          <div class="d-flex " style="justify-content: center; align-items: center; height: 70px;">
            <button *ngIf="sp.inRetail == 1" class="buttonadd" (click)="addSoItem(sp)">{{ 'ADD_TO_ORDER' | translate }}</button>
            <button *ngIf="sp.inRetail == 0" class="buttonNoRetail">{{ 'ON_WAREHOUSE' | translate }}</button>
          </div>

        </div>
      </div>

    </div>
    <br>
  </div>
  
  
  
</div>
