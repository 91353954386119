import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-salesfront',
  templateUrl: './salesfront.component.html',
  styleUrls: ['./salesfront.component.scss']
})
export class SalesfrontComponent implements OnInit {
  show = true;
  mobile = false;
  
    //knowinf thr device  
    platform = '';
    showMobile = false;
    isIOS = false;
    isAndroid = false;
    salesOrder:any = {};
    salesOrderO:any = {};
    // fromLocal = localStorage.getItem('device')
  constructor() { }

  valueSOFormO(e:any){
    this.salesOrderO = JSON.parse(JSON.stringify(e)) 
  }
  valueSOForm(e:any){    
    this.salesOrder = e    
  }

  ngOnInit(){
    var w = window.innerWidth;
    // this.windowSize();

    this.platform = navigator.userAgent;
    if (this.platform.match(/Android/i)) {
      this.showMobile = true;
      this.isAndroid = true;
    }
    if (this.platform.match(/iPhone|iPad|iPod/i)) {
      this.showMobile = true;
      this.isIOS = true;
    }

    if (this.platform.includes('Mac')) {
      this.showMobile = false;

    }

    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
      this.isIOS = true;
    }

    // if (this.platform.includes('Win')) {
    //   this.showMobile = false;

    // }
    // console.log('dentro de sales froont')
  }

  onResize(event){
    event.target.innerWidth; // window width
    this.windowSize();
  }

  windowSize() {
    var w = window.innerWidth;
    if (this.mobile != true) {
      if (w <= 790) {
        this.mobile = true;
      }
    }
  }
}
