<div [ngClass]="from != 'SOmobile' ? 'p-4':''" >  
  
    <form  [formGroup]="form" (ngSubmit)="submit(model)">
      <div  *ngIf="creditForm === false">

        <div class="tw-flex" *ngIf="from != 'SOmobile'">
          <button [disabled]="showCloverSide" class="tw-ml-auto" type="sumbit">      
            <span class="material-icons tw-text-aedblue" style="font-size: 1.8rem"> check_circle </span>
          </button>
        </div>  

        <!-- icon and title -->
        <div class="tw-flex">  
          <div class="tw-flex tw-flex-row" *ngIf="from != 'SOmobile'" >
            <h1 class="tw-text-2xl sm:tw-text-lg md:tw-text-3xl lg:tw-text-3xl tittle-0" style="width: min-content;">{{ 'PAYMENTS' | translate }}</h1>  
            <svg  class="tw-h-8 tw-w-8 tw-stroke-1 tw-ml-3 tw-text-aedoranges" xmlns="http://www.w3.org/2000/svg" width="32.758" height="32.486" viewBox="0 0 32.758 32.486">
              <g id="Group_256" data-name="Group 256" transform="translate(3534.159 624.977)">
                <g id="Group_232" data-name="Group 232" transform="translate(-3533.809 -624.627)">
                  <path id="Path_172" data-name="Path 172" d="M388.213,3.832A15.932,15.932,0,0,1,399.46,8.449,15.746,15.746,0,0,1,394.4,34.142a15.988,15.988,0,0,1-17.439-3.379A15.746,15.746,0,0,1,382.022,5.07,15.93,15.93,0,0,1,388.213,3.832Zm0,29.9A14.281,14.281,0,0,0,398.3,29.59a14.071,14.071,0,0,0,3.055-4.489,14.048,14.048,0,0,0,0-10.989A14.071,14.071,0,0,0,398.3,9.622a14.336,14.336,0,0,0-20.171,0,14.07,14.07,0,0,0-3.055,4.489,14.048,14.048,0,0,0,0,10.989,14.07,14.07,0,0,0,3.055,4.489,14.282,14.282,0,0,0,10.086,4.139Z" transform="translate(-372.183 -3.713)" fill="#ee5f00"/>
                  <path id="Path_172_-_Outline" data-name="Path 172 - Outline" d="M388.213,3.482A16.282,16.282,0,0,1,399.707,8.2a16.1,16.1,0,0,1-5.168,26.264,16.338,16.338,0,0,1-17.821-3.453,16.1,16.1,0,0,1,5.168-26.264A16.278,16.278,0,0,1,388.213,3.482Zm0,31.549a15.582,15.582,0,0,0,11-4.516,15.366,15.366,0,0,0,3.336-4.9,15.349,15.349,0,0,0,0-12.011,15.547,15.547,0,0,0-8.281-8.207,15.638,15.638,0,0,0-17.057,3.3,15.367,15.367,0,0,0-3.336,4.9,15.349,15.349,0,0,0,0,12.011,15.547,15.547,0,0,0,8.282,8.207A15.582,15.582,0,0,0,388.213,35.031Zm0-29.9a14.631,14.631,0,0,1,10.331,4.241,14.42,14.42,0,0,1,3.13,4.6,14.4,14.4,0,0,1,0,11.264,14.42,14.42,0,0,1-3.13,4.6,14.686,14.686,0,0,1-20.664,0,14.418,14.418,0,0,1-3.13-4.6,14.4,14.4,0,0,1,0-11.264,14.419,14.419,0,0,1,3.13-4.6,14.632,14.632,0,0,1,10.332-4.241Zm0,28.246a13.931,13.931,0,0,0,9.839-4.038,13.723,13.723,0,0,0,2.979-4.378,13.7,13.7,0,0,0,0-10.714,13.723,13.723,0,0,0-2.979-4.378,13.986,13.986,0,0,0-19.678,0,13.722,13.722,0,0,0-2.979,4.378,13.7,13.7,0,0,0,0,10.714,13.721,13.721,0,0,0,2.979,4.378,13.931,13.931,0,0,0,9.839,4.038Z" transform="translate(-372.183 -3.713)" fill="#ee5f00"/>
                  <path id="Path_172_-_Outline-2" data-name="Path 172 - Outline" d="M388.181,3.681a16.05,16.05,0,0,1,11.331,4.652,15.865,15.865,0,0,1-5.094,25.886,16.106,16.106,0,0,1-17.568-3.4A15.864,15.864,0,0,1,381.944,4.93,16.048,16.048,0,0,1,388.181,3.681Zm0,31.549a15.814,15.814,0,0,0,11.164-4.583,15.628,15.628,0,0,0-5.019-25.5A15.869,15.869,0,0,0,377.016,8.5a15.628,15.628,0,0,0,5.02,25.5A15.813,15.813,0,0,0,388.181,35.231Zm0-29.9a14.4,14.4,0,0,1,10.168,4.173,14.189,14.189,0,0,1,3.08,4.527,14.166,14.166,0,0,1,0,11.082,14.189,14.189,0,0,1-3.08,4.527,14.454,14.454,0,0,1-20.337,0,14.19,14.19,0,0,1-3.08-4.527,14.166,14.166,0,0,1,0-11.082,14.189,14.189,0,0,1,3.08-4.527,14.4,14.4,0,0,1,10.169-4.173Zm0,28.246a14.162,14.162,0,0,0,10-4.1,13.954,13.954,0,0,0,3.029-4.452,13.93,13.93,0,0,0,0-10.9,13.954,13.954,0,0,0-3.029-4.452,14.218,14.218,0,0,0-20,0,13.952,13.952,0,0,0-3.029,4.452,13.929,13.929,0,0,0,0,10.9,13.952,13.952,0,0,0,3.029,4.452,14.164,14.164,0,0,0,10,4.1Z" transform="translate(-372.151 -3.681)" fill="#ee5f00"/>
                  <path id="Path_172_-_Outline_-_Outline" data-name="Path 172 - Outline - Outline" d="M388.181,3.331a16.4,16.4,0,0,1,11.577,4.753,16.215,16.215,0,0,1-5.205,26.457,16.456,16.456,0,0,1-17.95-3.478,16.214,16.214,0,0,1,5.206-26.457A16.4,16.4,0,0,1,388.181,3.331Zm0,31.549A15.464,15.464,0,0,0,399.1,30.4a15.25,15.25,0,0,0,3.31-4.865,15.231,15.231,0,0,0,0-11.918,15.428,15.428,0,0,0-8.218-8.145A15.519,15.519,0,0,0,377.262,8.75a15.249,15.249,0,0,0-3.31,4.865,15.231,15.231,0,0,0,0,11.918,15.429,15.429,0,0,0,8.219,8.145A15.465,15.465,0,0,0,388.181,34.881Zm0-29.9A14.749,14.749,0,0,1,398.6,9.258a14.537,14.537,0,0,1,3.156,4.638,14.516,14.516,0,0,1,0,11.357,14.537,14.537,0,0,1-3.156,4.638,14.8,14.8,0,0,1-20.83,0,14.538,14.538,0,0,1-3.156-4.638,14.515,14.515,0,0,1,0-11.357,14.538,14.538,0,0,1,3.156-4.638,14.75,14.75,0,0,1,10.415-4.275Zm0,28.246a13.812,13.812,0,0,0,9.755-4,13.605,13.605,0,0,0,2.954-4.34,13.58,13.58,0,0,0,0-10.622,13.605,13.605,0,0,0-2.954-4.34,13.868,13.868,0,0,0-19.512,0,13.6,13.6,0,0,0-2.954,4.34,13.579,13.579,0,0,0,0,10.622,13.6,13.6,0,0,0,2.954,4.34,13.813,13.813,0,0,0,9.756,4Z" transform="translate(-372.151 -3.681)" fill="#ee5f00"/>
                  <path id="Path_173" data-name="Path 173" d="M392.1,31.166V28.6a7.382,7.382,0,0,1-3.884-1.118l.609-1.7a6.765,6.765,0,0,0,3.656,1.093c1.8,0,3.021-1.042,3.021-2.491,0-1.4-.99-2.262-2.869-3.025-2.589-1.017-4.188-2.186-4.188-4.4a4.166,4.166,0,0,1,3.833-4.092V10.3h1.574v2.466a6.757,6.757,0,0,1,3.3.889l-.634,1.677a6.123,6.123,0,0,0-3.2-.865c-1.955,0-2.691,1.17-2.691,2.186,0,1.323.939,1.983,3.148,2.9,2.615,1.068,3.935,2.389,3.935,4.651a4.42,4.42,0,0,1-4.012,4.322v2.643Z" transform="translate(-376.935 -4.839)" fill="#ee5f00"/>
                  <path id="Path_173_-_Outline" data-name="Path 173 - Outline" d="M394.049,31.516h-2.3V28.935a7.583,7.583,0,0,1-3.738-1.17l-.215-.154.849-2.374.373.249a6.386,6.386,0,0,0,3.462,1.034,3.06,3.06,0,0,0,1.962-.621,1.92,1.92,0,0,0,.709-1.52c0-1.154-.768-1.936-2.65-2.7-2.287-.9-4.407-2.093-4.407-4.721a4.458,4.458,0,0,1,3.833-4.383V9.948H394.2v2.483a6.984,6.984,0,0,1,3.128.92l.255.149-.884,2.338-.356-.2a5.761,5.761,0,0,0-3.025-.818c-1.08,0-2.341.481-2.341,1.836,0,1.055.658,1.633,2.932,2.574,2.909,1.187,4.151,2.675,4.151,4.975a4.534,4.534,0,0,1-1.111,2.982,5.209,5.209,0,0,1-2.9,1.63Zm-1.6-.7h.9v-2.59l.293-.048a4.607,4.607,0,0,0,2.78-1.455,3.834,3.834,0,0,0,.939-2.522c0-2-1.077-3.249-3.717-4.327a9.075,9.075,0,0,1-2.5-1.356,2.328,2.328,0,0,1-.866-1.866c0-1.262.94-2.536,3.041-2.536a6.381,6.381,0,0,1,3.014.715l.385-1.019a6.585,6.585,0,0,0-2.877-.7L393.5,13.1V10.648h-.874v2.515l-.294.048a4.477,4.477,0,0,0-2.593,1.291,3.523,3.523,0,0,0-.946,2.455c0,1.809,1.112,2.95,3.966,4.071,1.53.621,3.091,1.5,3.091,3.35a2.635,2.635,0,0,1-.96,2.057,3.7,3.7,0,0,1-2.411.783,7.156,7.156,0,0,1-3.472-.918l-.371,1.038a7.4,7.4,0,0,0,3.468.909l.344.006Z" transform="translate(-376.935 -4.839)" fill="#ee5f00"/>
                </g>
              </g>
            </svg>
          </div> 

          <!-- <div class="tw-text-lg tw-font-bold tw-text-aedblue" [ngClass]="from != 'SOmobile'?'tw-ml-auto tw-flex':''">
            <div style="margin-right: 2rem;">Sales Order Total:  {{salesOrder.Total | currency}}</div>
            <div>Balance: {{toBePayed | currency}}</div>          
          </div> -->

        </div>

        <div *ngIf="showCloverSide" class="tw-flex tw-flex-col tw-items-center tw-justify-center tw-py-1" style="background-color: #fafafa;">
          <img *ngIf="statusAvalara != 'Select Device'" class="tw-transition tw-animate-spin tw-transform" src="assets/clover.png"  style="height: 150px;">
          <span class="tw-text-2xl tw-text-aedgraycard tw-font-bold">{{statusAvalara}}</span>
          <span class="tw-text-2xl tw-text-aedoranges tw-font-bold" *ngIf="statusAvalara == 'Processing Transaction'"> {{ 'CHARGING' | translate }} {{paymentInProcess.paymentAmount | currency}}</span>
          <!-- {{cloverIMG}} -->
          <div *ngIf="statusAvalara == 'Select Device'" class="tw-flex tw-space-x-2 tw-pt-5" >
            <div *ngFor="let device of filterActive(devices)" (click)="showCard(device?.deviceId)" class="tw-rounded-2xl hover:tw-bg-aedoranges tw-cursor-pointer tw-bg-aedgrayk tw-flex tw-flex-col tw-justify-center tw-items-center tw-mx-auto tw-p-4" >
              <div>
                <span [innerHTML]="cloverIMG[device.Type].svg | safeHtml"></span>
              </div>
              <span class="tw-text-white tw-font-bold tw-text-lg">{{device?.name}}</span>
              <span class="tw-text-white tw-font-bold tw-text-sm">{{cloverIMG[device?.Type].name}}</span>
            </div>
          </div>
          <button class="btn-orange-so tw-mt-2" *ngIf="statusAvalara == 'Select Device'"  (click)="showCloverSide = false" >{{ 'CANCEL' | translate }}</button>
          <!-- {{devices | jzson}} -->
          <span *ngIf="statusAvalara == 'Processing Transaction'" >{{countdown$ | async}} {{ 'SECONDS_REMAINING' | translate }}</span>
        </div>

      </div>
    </form>  

    <div *ngIf="!showCloverSide">

      <div *ngIf="model.payments.length > 0">
        
        <nav *ngFor="let payment of filterPayments(model.payments); let lastItem = last;let i = index" class="tw-flex tw-flex-col tw-p-4 tw-my-1" 
        style="background-color: #fafafa;">
          <div class="tw-block sm:tw-block md:tw-flex lg:tw-flex xl:tw-flex   tw-space-x-1 tw-justify-between tw-w-full tw-py-1">
            
            <div class="tw-flex tw-flex-col">
              <span [ngClass]="{'tw-text-aedgrays': (payment.voidtype_tx != undefined && payment.voidtype_tx != null), 'tw-text-aedoranges' : payment.voidtype_tx == undefined || payment.voidtype_tx == null }"  
              class="tw-text-base sm:tw-text-base md:tw-text-xl lg:tw-text-2xl xl:tw-text-2xl tw-font-bold tw-my-auto">
                <strong *ngIf="payment.voidtype_tx != undefined && payment.voidtype_tx != null">{{ 'VOIDED' | translate }}</strong> 
                {{ filterPayments(model.payments).length > 1 ? ((getIndex(payment.paymentID)+1)+'. ') :'' }} {{payment.paymentType | translate}} | {{payment.paymentAmount | currency}}
              </span>
              
              <div *ngIf="payment.authCode" class="tw-text-base tw-text-aedgrays" style="display: flex; flex-wrap: wrap;">
                
                <div style="margin-right: 1rem;">
                  <div style="min-width: max-content;">{{ 'TRANSACTION_APPROVED' | translate }}: {{payment.authCode}}</div>
                  <div>{{ 'CARD_TYPE' | translate }}: {{payment.cardType}}</div> 
                </div>

                <div>
                  <div>{{ 'LAST_4_DIGITS' | translate }}: {{payment.cardLastFour}}</div>
                  <div>{{ 'CARD_HOLDER' | translate }}: {{payment.nameOnCard}}</div> 
                </div>

              </div>
            </div>

            <!-- Buttons options -->
            <div class="tw-flex tw-gap-1 tw-ml-0  sm:tw-grid sm:tw-grid-cols-2 sm:tw-ml-auto
            md:tw-grid md:tw-grid-cols-2 md:tw-ml-auto     lg:tw-grid lg:tw-grid-cols-2 lg:tw-ml-auto    xl:tw-grid xl:tw-grid-cols-2 xl:tw-ml-auto">

              <button *ngIf="canEdit && (payment.voidtype_tx == undefined || payment.voidtype_tx == null)" (click)="removePayment(payment, in)" style="width: max-content;outline: none;" 
                class="tw-text-white tw-w-14 tw-h-10 tw-py-1 tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-ml-2" 
                [ngClass]="false ? 'tw-bg-aedbluedisabled' : 'tw-bg-aedblue'"
                ><svg class="tw-w-7 sm:tw-w-7 tw-h-7 sm:tw-h-7" id="Group_235" data-name="Group 235" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24.255" height="29.82" viewBox="0 0 24.255 29.82">
                  <defs>
                    <style>
                      .cls-41 {
                        fill: #fff;
                      }
                
                      .cls-2 {
                        clip-path: url(#clip-path);
                      }
                    </style>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_99" data-name="Rectangle 99" class="cls-1" width="24.255" height="29.82"/>
                    </clipPath>
                  </defs>
                  <g id="Group_40" data-name="Group 40" class="">
                    <path id="Path_55" data-name="Path 55" class="cls-41" d="M14.513,0a2.979,2.979,0,0,1,1.865,1.289,3.193,3.193,0,0,1,.43,1.838c-.015.657-.087.574.563.576,1.493,0,2.987,0,4.48,0a2.316,2.316,0,0,1,2.4,2.4q0,1.556,0,3.113a.939.939,0,0,1-1.043,1.043c-.388,0-.4.019-.414.412q-.15,3.426-.307,6.853c-.072,1.577-.151,3.154-.226,4.732-.078,1.616-.146,3.232-.236,4.848a2.812,2.812,0,0,1-2.887,2.711q-7.011,0-14.022,0a2.84,2.84,0,0,1-2.9-2.853c-.084-2.217-.2-4.433-.3-6.649-.073-1.587-.153-3.174-.228-4.761q-.119-2.5-.234-4.993c-.01-.218-.1-.3-.322-.3C.311,10.251,0,9.926,0,9.1,0,8.086,0,7.068,0,6.05A2.313,2.313,0,0,1,2.344,3.706c1.581,0,3.161-.009,4.742.005.3,0,.383-.09.371-.38A4.029,4.029,0,0,1,7.506,2.2,2.78,2.78,0,0,1,9.465.09L9.741,0ZM12.148,10.264q-4.233,0-8.467,0c-.221,0-.338.033-.322.3.082,1.364.151,2.728.219,4.092q.119,2.4.229,4.791c.086,1.878.164,3.756.25,5.634.031.668.07,1.335.113,2a.856.856,0,0,0,.638.78,1.594,1.594,0,0,0,.46.057h13.7a1.623,1.623,0,0,0,.432-.046.863.863,0,0,0,.682-.836q.115-2.177.225-4.354.12-2.424.232-4.849c.085-1.868.164-3.737.248-5.605.024-.523.048-1.045.089-1.567.03-.381.029-.4-.353-.4h-8.38m-.02-1.9q4.946,0,9.891,0c.249,0,.344-.061.336-.325-.018-.572,0-1.144-.006-1.716s-.141-.716-.725-.716H2.6c-.544,0-.692.148-.693.689q0,.785,0,1.571c0,.536-.051.492.478.492h9.746M12.119,3.7c.833,0,1.666-.005,2.5,0,.213,0,.3-.064.286-.283-.014-.193,0-.387-.005-.581a.934.934,0,0,0-.961-.957q-1.815-.018-3.631,0a.93.93,0,0,0-.948.939c-.01.193.009.388-.006.581-.018.229.07.3.3.3.823-.011,1.646,0,2.469,0" transform="translate(0)"/>
                    <path id="Path_56" data-name="Path 56" class="cls-41" d="M85.757,162.45q0,3.025,0,6.051a.924.924,0,0,1-.669.918.966.966,0,0,1-1.059-.36.882.882,0,0,1-.173-.544q0-6.066,0-12.131a.912.912,0,0,1,.94-.937.924.924,0,0,1,.958.952c0,2.017,0,4.034,0,6.051" transform="translate(-77.334 -143.36)"/>
                    <path id="Path_57" data-name="Path 57" class="cls-41" d="M145.636,162.462q0,3.011,0,6.022a.925.925,0,0,1-.653.929.965.965,0,0,1-1.082-.364.9.9,0,0,1-.166-.546q0-6.051,0-12.1a.915.915,0,0,1,.953-.954.926.926,0,0,1,.946.964q.006,3.025,0,6.051" transform="translate(-132.557 -143.361)"/>
                    <path id="Path_58" data-name="Path 58" class="cls-41" d="M205.506,162.412q0,3.011,0,6.022a.925.925,0,0,1-.657.926.97.97,0,0,1-1.081-.368,1.066,1.066,0,0,1-.164-.664q0-4.335,0-8.669c0-1.105,0-2.211,0-3.316a.922.922,0,0,1,.783-.942.952.952,0,0,1,1.076.645,1.268,1.268,0,0,1,.042.431q0,2.967,0,5.935" transform="translate(-187.771 -143.306)"/>
                  </g>
                </svg>
              </button>    

              <button *ngIf="canEdit && (payment.voidtype_tx == undefined || payment.voidtype_tx == null) && !payment.authCode" (click)="editPayment(payment, i)" style="width: max-content;outline: none;" 
                class="tw-text-white tw-w-14 tw-h-10 tw-py-1 tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-ml-2" 
                [ngClass]="false ? 'tw-bg-aedbluedisabled' : 'tw-bg-aedblue'">
                <svg class="tw-w-7 sm:tw-w-7 tw-h-7 sm:tw-h-7" id="Component_59_102" data-name="Component 59 – 102" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25.674" height="25.681" viewBox="0 0 25.674 25.681">
                  <defs>
                    <style>
                      .cls-7 {
                        fill: #fff;
                      }
          
                    </style>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_156" data-name="Rectangle 156" class="cls-7" width="25.674" height="25.681"/>
                    </clipPath>
                  </defs>
                  <g id="Group_63" data-name="Group 63" class="cls-8">
                    <path id="Path_76" data-name="Path 76" class="cls-7" d="M25.674,4.884a5.154,5.154,0,0,1-1.642,2.877C21,10.767,17.983,13.8,14.961,16.819Q12.306,19.475,9.65,22.13c-.313.313-.633.618-.946.931a1.42,1.42,0,0,1-.578.335Q4.861,24.481,1.6,25.572c-.095.032-.186.073-.279.109h-.6a1.094,1.094,0,0,1-.594-1.576q1.09-3.266,2.177-6.534a1.567,1.567,0,0,1,.389-.631Q10.514,9.121,18.337,1.3A4.289,4.289,0,0,1,25.3,2.465a5.738,5.738,0,0,1,.377,1.216ZM7.95,20.871c.089-.02.135-.1.192-.153q6.486-6.483,12.975-12.964c.166-.165.138-.246-.011-.392Q19.716,5.988,18.343,4.6c-.159-.162-.245-.167-.41,0q-2.648,2.667-5.312,5.319-3.827,3.827-7.657,7.651c-.133.133-.16.211-.012.357.946.933,1.881,1.875,2.821,2.813.052.052.1.118.176.136M23.641,4.2c0-.013-.014-.1-.025-.178A2.1,2.1,0,0,0,22.107,2.18,2.2,2.2,0,0,0,19.73,2.8c-.1.1-.1.171,0,.27q1.454,1.447,2.9,2.9c.086.086.155.1.252.009A2.349,2.349,0,0,0,23.641,4.2M5.976,21.876a.9.9,0,0,0-.07-.088q-1-1-2-2c-.1-.1-.147-.078-.188.048-.11.341-.226.681-.339,1.021q-.321.961-.641,1.923c-.02.06-.073.127-.024.189s.124.016.183,0q1.473-.488,2.944-.98c.05-.017.121-.017.135-.108" transform="translate(0 0)"/>
                  </g>
                </svg>
              </button>

              <button style="width: max-content;outline: none;" 
                *ngIf="payment.paymentType == 'Credit Card' && (payment.authCode != undefined && payment.authCode != null)"
                class="tw-text-white tw-w-14 tw-h-10 tw-py-1 tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-ml-2" 
                [ngClass]="false ? 'tw-bg-aedbluedisabled' : 'tw-bg-aedblue'" 
                (click)="printClover(payment)">
                <svg class="tw-w-7 sm:tw-w-7 tw-h-7 sm:tw-h-7" xmlns="http://www.w3.org/2000/svg" width="42.687" height="40" viewBox="0 0 42.687 40">
                    <g id="Group_251" data-name="Group 251" transform="translate(3525.284 756.338)">
                      <path id="Path_192" data-name="Path 192" d="M78.963,39.32H61.153a4.158,4.158,0,0,1-4.212-4.092V21.833H83.174V35.228A4.158,4.158,0,0,1,78.963,39.32ZM59.069,23.9V35.228a2.058,2.058,0,0,0,2.084,2.025h17.81a2.058,2.058,0,0,0,2.085-2.025V23.9Z" transform="translate(-3574 -755.658)" fill="#fff"/>
                      <path id="Path_193" data-name="Path 193" d="M54.51,9.306h31.1a5.73,5.73,0,0,1,5.8,5.65V25.574a5.73,5.73,0,0,1-5.8,5.65H81.048V23.545H59.069v7.679H54.51a5.73,5.73,0,0,1-5.794-5.65V14.956A5.73,5.73,0,0,1,54.51,9.306Zm31.1,19.85a3.631,3.631,0,0,0,3.669-3.583V14.956a3.631,3.631,0,0,0-3.669-3.583H54.51a3.63,3.63,0,0,0-3.667,3.583V25.574a3.63,3.63,0,0,0,3.667,3.583h2.433V21.478H83.175v7.679Z" transform="translate(-3574 -755.302)" fill="#fff"/>
                      <line id="Line_88" data-name="Line 88" x2="8.762" transform="translate(-3508.323 -727.609)" fill="#fff"/>
                      <path id="Line_89" data-name="Line 89" d="M8.762.669H0V-.709H8.762Z" transform="translate(-3508.323 -727.589)" fill="#fff"/>
                      <line id="Line_90" data-name="Line 90" x2="8.762" transform="translate(-3508.323 -722.68)" fill="#fff"/>
                      <path id="Line_91" data-name="Line 91" d="M8.762.669H0V-.709H8.762Z" transform="translate(-3508.323 -722.66)" fill="#fff"/>
                      <line id="Line_92" data-name="Line 92" x2="6.301" transform="translate(-3519.125 -739.976)" fill="#fff"/>
                      <path id="Line_93" data-name="Line 93" d="M6.3.669H0V-.709H6.3Z" transform="translate(-3519.125 -739.956)" fill="#fff"/>
                      <path id="Line_94" data-name="Line 94" d="M27.937,1H0V-1.064H27.937Z" transform="translate(-3517.91 -732.761)" fill="#fff"/>
                      <path id="Path_194" data-name="Path 194" d="M62.027,0H78.09a5.31,5.31,0,0,1,5.074,5.507v6.872H56.952V5.507A5.31,5.31,0,0,1,62.027,0ZM81.094,10.133V5.507a3.144,3.144,0,0,0-3-3.261H62.027a3.145,3.145,0,0,0-3.006,3.261v4.626Z" transform="translate(-3574 -756.337)" fill="#fff"/>
                    </g>
                </svg>
              </button>
    
              <button style="width: max-content;outline: none;" 
                *ngIf="payment.paymentType == 'Credit Card' && (payment.authCode != undefined && payment.authCode != null)"
                class="tw-text-white tw-w-14 tw-h-10 tw-py-1 tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-ml-2" 
                [ngClass]="false ? 'tw-bg-aedbluedisabled' : 'tw-bg-aedblue'" 
                (click)="confirmEmailClover(payment, 'EMAIL')">
                <svg class="tw-w-7 sm:tw-w-7 tw-h-7 sm:tw-h-7" xmlns="http://www.w3.org/2000/svg" width="42.552" height="35.421" viewBox="0 0 42.552 35.421">
                  <g id="Group_250" data-name="Group 250" transform="translate(3474.511 752.796)">
                    <path id="Rectangle_396" data-name="Rectangle 396" d="M1.936-1.124H38.369a3.139,3.139,0,0,1,3.06,3.205V31.091a3.14,3.14,0,0,1-3.06,3.206H1.936a3.139,3.139,0,0,1-3.06-3.205V2.082A3.139,3.139,0,0,1,1.936-1.124ZM38.368,32.05a.939.939,0,0,0,.916-.96V2.082a.938.938,0,0,0-.915-.959H1.936a.939.939,0,0,0-.915.959v29.01a.938.938,0,0,0,.915.958Z" transform="translate(-3473.388 -751.672)" fill="#fff"/>
                    <path id="Path_195" data-name="Path 195" d="M121.019,25.463h-.013a2.955,2.955,0,0,1-2.2-1.007L100.772,4.42l1.555-1.544,18.037,20.035a.856.856,0,0,0,1.3.006L139.661,3.267,141.2,4.825,123.21,24.475A2.952,2.952,0,0,1,121.019,25.463Z" transform="translate(-3574 -755)" fill="#fff"/>
                    <path id="Line_95" data-name="Line 95" d="M14.452,16.649-.81.779.81-.779l15.262,15.87Z" transform="translate(-3448.868 -735.085)" fill="#fff"/>
                    <path id="Line_96" data-name="Line 96" d="M.81,16.649-.81,15.091,14.452-.779,16.072.779Z" transform="translate(-3472.47 -735.085)" fill="#fff"/>
                  </g>
                </svg>    
                <i class="fa fa-spinner fa-spin mr-1" *ngIf="emailLoading"></i>
              </button>
              
              <button style="width: max-content;outline: none;"
                *ngIf="payment.paymentType == 'Credit Card' && (payment.authCode != undefined && payment.authCode != null)" 
                (click)="confirmEmailClover(payment, 'SMS')" 
                class="tw-text-white tw-w-14 tw-h-10 tw-py-1 tw-px-4 tw-rounded-lg tw-text-lg tw-font-bold tw-cursor-pointer tw-flex tw-items-center tw-justify-center tw-ml-2" 
                [ngClass]="false ? 'tw-bg-aedbluedisabled' : 'tw-bg-aedblue'" 
                ><span class="material-icons tw-w-7 sm:tw-w-7 tw-h-7 sm:tw-h-7"> chat</span>
              </button>

            </div>

          </div>
        </nav>

      </div>











      <div class="tw-flex tw-w-full tw-flex-col">
        <div class="tw-flex tw-flex-col">   





          <div *ngIf="singlePayment.paymentID != undefined" class="tw-flex tw-flex-col tw-justify-between tw-py-5 tw-px-4 tw-my-2"
          style="background-color: #fafafa;">
            
            <nav [ngClass]="from != 'SOmobile'?'tw-flex tw-justify-between':''">
              
              <div class="tw-text-lg sm:tw-text-lg md:tw-text-lg lg:tw-text-lg tw-text-aedoranges tw-font-bold tw-flex">
                
                <span class="tw-my-auto" *ngIf="modifyingPayment.index != null">Editing {{"Payment "+(modifyingPayment.position + 1)+". "}}{{ '' | currency }}</span>
                <span class="tw-my-auto" *ngIf="modifyingPayment.index == null">{{multiple ? ( filterPayments(model.payments).length > 1 ? ( (filterPayments(model.payments).length+1)+'. '):'') : ('TRANSACTION_AMOUNT' | translate) }}{{ '' | currency }}</span>
                <!-- <span class="tw-my-auto tw-ml-2" *ngIf="!multiple">{{singlePayment.paymentAmount | number:'1.2-2'}}</span> -->
                <input [max]="toBePayed" appTwoDigitDecimaNumber  [disabled]="singlePayment.authCode != undefined"  [(ngModel)]="singlePayment.paymentAmount" 
                [ngClass]="{'tw-text-aedgrays tw-border-aedgrays': singlePayment.authCode != undefined,  
                'tw-text-aedorangel tw-border-aedoranges' : singlePayment.authCode == undefined}" 
                class="tw-my-auto tw-ml-2 tw-text-base tw-px-2 tw-font-bold tw-bg-transparent  focus:tw-outline-none tw-text-end tw-rounded-md"
                style="border: 1px solid #ee5f00;min-width: 5rem;width: 5rem;height: fit-content;">
              </div>  

              <!-- FOUR BUTTONS -->
              <div style="display: flex; flex-wrap: wrap;">

                <div style="display: flex;" [ngClass]="from != 'SOmobile'?'':'tw-mt-2'">
                  <button class="tw-mr-4" *ngIf="filterActive(devices)?.length > 0 && settings.cloverState == '1'" [disabled]="singlePayment.authCode != undefined" (click)="selectCloverDevice(singlePayment)" [ngClass]="{'btn-blue-so-blocked': singlePayment.authCode != undefined,  'btn-blue-so' : singlePayment.authCode == undefined, 'tw-animate-pulse' : singlePayment.paymentType == ''}">
                    <img src="assets/whiteclover.png" style="height: 20px;" alt="">
                    {{ 'CREDIT' | translate }}
                  </button>

                  <select [disabled]="singlePayment.authCode != undefined"
                    *ngIf="(settings.cloverState == '1'  && filterActive(devices)?.length > 0)"
                    class="tw-mr-4 tw-text-lg tw-text-aedblue tw-border-2 tw-rounded-lg tw-bg-transparent tw-font-bold tw-border-aedblue" style="outline: none;"
                    [(ngModel)]="singlePayment.paymentType"
                  >
                    <option value="" disabled>{{ 'SELECT_METHOD' | translate }}</option>
                    <option value="Cash">{{ 'CASH' | translate }}</option>
                    <option value="Check">{{ 'CHECK' | translate }}</option>
                  </select>

                  <select
                    [disabled]="singlePayment.authCode != undefined"
                    *ngIf="filterActive(devices)?.length == 0"
                    class="tw-mr-4 tw-text-lg tw-text-aedblue tw-border-2 tw-rounded-lg tw-bg-transparent tw-font-bold tw-border-aedblue" style="outline: none;"            
                    [(ngModel)]="singlePayment.paymentType"
                  >
                    <option value="" disabled>{{ 'SELECT_METHOD' | translate }}</option>                    
                    <option value="Cash">{{ 'CASH' | translate }}</option>
                    <option value="Card">{{ 'CREDIT_CARD' | translate }}</option>
                    <option value="Check">{{ 'CHECK' | translate }}</option>              
                  </select>
                </div>


                <div style="display: flex;" [ngClass]="from != 'SOmobile'?'':'tw-mt-2'">
                  <button (click)="multiple ? multiplePayments('fromSaveRow'): savePayment()" class="btn-blue-so tw-mr-4">{{ 'SAVE' | translate }}</button>

                  <svg (click)="removeEditingPayment()" class="tw-my-auto tw-w-7 sm:tw-w-7 tw-h-7 sm:tw-h-7 tw-cursor-pointer" id="Group_235" data-name="Group 235" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24.255" height="29.82" viewBox="0 0 24.255 29.82">
                    <defs>
                      <style>
                        .cls-55 {
                          fill: #00adee;
                        }
                  
                        .cls-2 {
                          clip-path: url(#clip-path);
                        }
                      </style>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_99" data-name="Rectangle 99" class="cls-1" width="24.255" height="29.82"/>
                      </clipPath>
                    </defs>
                    <g id="Group_40" data-name="Group 40" class="">
                      <path id="Path_55" data-name="Path 55" class="cls-55" d="M14.513,0a2.979,2.979,0,0,1,1.865,1.289,3.193,3.193,0,0,1,.43,1.838c-.015.657-.087.574.563.576,1.493,0,2.987,0,4.48,0a2.316,2.316,0,0,1,2.4,2.4q0,1.556,0,3.113a.939.939,0,0,1-1.043,1.043c-.388,0-.4.019-.414.412q-.15,3.426-.307,6.853c-.072,1.577-.151,3.154-.226,4.732-.078,1.616-.146,3.232-.236,4.848a2.812,2.812,0,0,1-2.887,2.711q-7.011,0-14.022,0a2.84,2.84,0,0,1-2.9-2.853c-.084-2.217-.2-4.433-.3-6.649-.073-1.587-.153-3.174-.228-4.761q-.119-2.5-.234-4.993c-.01-.218-.1-.3-.322-.3C.311,10.251,0,9.926,0,9.1,0,8.086,0,7.068,0,6.05A2.313,2.313,0,0,1,2.344,3.706c1.581,0,3.161-.009,4.742.005.3,0,.383-.09.371-.38A4.029,4.029,0,0,1,7.506,2.2,2.78,2.78,0,0,1,9.465.09L9.741,0ZM12.148,10.264q-4.233,0-8.467,0c-.221,0-.338.033-.322.3.082,1.364.151,2.728.219,4.092q.119,2.4.229,4.791c.086,1.878.164,3.756.25,5.634.031.668.07,1.335.113,2a.856.856,0,0,0,.638.78,1.594,1.594,0,0,0,.46.057h13.7a1.623,1.623,0,0,0,.432-.046.863.863,0,0,0,.682-.836q.115-2.177.225-4.354.12-2.424.232-4.849c.085-1.868.164-3.737.248-5.605.024-.523.048-1.045.089-1.567.03-.381.029-.4-.353-.4h-8.38m-.02-1.9q4.946,0,9.891,0c.249,0,.344-.061.336-.325-.018-.572,0-1.144-.006-1.716s-.141-.716-.725-.716H2.6c-.544,0-.692.148-.693.689q0,.785,0,1.571c0,.536-.051.492.478.492h9.746M12.119,3.7c.833,0,1.666-.005,2.5,0,.213,0,.3-.064.286-.283-.014-.193,0-.387-.005-.581a.934.934,0,0,0-.961-.957q-1.815-.018-3.631,0a.93.93,0,0,0-.948.939c-.01.193.009.388-.006.581-.018.229.07.3.3.3.823-.011,1.646,0,2.469,0" transform="translate(0)"/>
                      <path id="Path_56" data-name="Path 56" class="cls-55" d="M85.757,162.45q0,3.025,0,6.051a.924.924,0,0,1-.669.918.966.966,0,0,1-1.059-.36.882.882,0,0,1-.173-.544q0-6.066,0-12.131a.912.912,0,0,1,.94-.937.924.924,0,0,1,.958.952c0,2.017,0,4.034,0,6.051" transform="translate(-77.334 -143.36)"/>
                      <path id="Path_57" data-name="Path 57" class="cls-55" d="M145.636,162.462q0,3.011,0,6.022a.925.925,0,0,1-.653.929.965.965,0,0,1-1.082-.364.9.9,0,0,1-.166-.546q0-6.051,0-12.1a.915.915,0,0,1,.953-.954.926.926,0,0,1,.946.964q.006,3.025,0,6.051" transform="translate(-132.557 -143.361)"/>
                      <path id="Path_58" data-name="Path 58" class="cls-55" d="M205.506,162.412q0,3.011,0,6.022a.925.925,0,0,1-.657.926.97.97,0,0,1-1.081-.368,1.066,1.066,0,0,1-.164-.664q0-4.335,0-8.669c0-1.105,0-2.211,0-3.316a.922.922,0,0,1,.783-.942.952.952,0,0,1,1.076.645,1.268,1.268,0,0,1,.042.431q0,2.967,0,5.935" transform="translate(-187.771 -143.306)"/>
                    </g>
                  </svg>
                </div>

              </div>


            </nav>

            <nav class="tw-flex tw-pt-2" >
              <input type="text" placeholder="{{ 'INTERNAL_NOTES' | translate }}" [(ngModel)]="singlePayment.paymentDetail" 
              class="tw-w-1/2 tw-px-2 tw-mr-1 tw-bg-transparent tw-text-aedgraycard tw-text-base  tw-outline-none tw-rounded-md" style="border:1px solid #777">
              <input type="text" placeholder="{{ 'RECEIPT_NOTES' | translate }}" [(ngModel)]="singlePayment.Note"           
              class="tw-w-1/2 tw-px-2 tw-ml-1 tw-bg-transparent tw-text-aedgraycard tw-text-base  tw-outline-none tw-rounded-md" style="border:1px solid #777">
            </nav>

          </div>









          <div class="tw-flex">
            <button *ngIf="canEdit && toBePayed > 0" (click)="multiplePayments()" 
              [ngClass]="{'btn-blue-so': toBePayed == 0, 'btn-white-so': toBePayed > 0 || toBePayed < 0}" >
              {{ multiple ? 
                (toBePayed > 0 ? ('ADD_PAYMENT' | translate) : ( toBePayed == 0 ? ('DONE' | translate) : ('CHANGE' | translate) )) : 
                (toBePayed > 0 ? ('MULTIPLE_PAYMENTS' | translate) : ( toBePayed == 0 ? ('DONE' | translate) : ('CHANGE' | translate) ))
              }}
            </button>
          </div>





        </div>
      </div>

    </div>

</div>