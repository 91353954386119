import { Component, OnInit , Input} from '@angular/core';

@Component({
  selector: 'app-tot',
  templateUrl: './tot.component.html',
  styleUrls: ['./tot.component.scss']
})
export class TotComponent implements OnInit {



  // subTotal
  valsubTotal: any = 0;
  @Input() set subTotal(value: any) {
    this.valsubTotal = value       
  }
  get subTotal(): any {
    return this.valsubTotal;
  }

  
  // Taxes
  valtax: any = 0;
  @Input() set tax(value: any) {
    this.valtax = value       
  }
  get tax(): any {
    return this.valtax;
  }

  
  // Total
  valtotal: any = 0;
  @Input() set total(value: any) {
    this.valtotal = value       
  }
  get total(): any {
    return this.valtotal;
  }

  
  // Payment
  valpayments: any = 0
  @Input() set payments(value: any) {
    this.valpayments = value       
  }
  get payments(): any {
    return this.valpayments;
  }

  
  // Balance
  valbalance: any = 0;
  @Input() set balance(value: any) {
    this.valbalance = value       
  }
  get balance(): any {
    return this.valbalance;
  }

  
  // subTotal:any=0;
  // tax:any=0;
  // total:any=0;
  // payments:any=0;
  // balance:any=0;

  // valso: any ={};
  // @Input() set so(value: any) {
  //   this.valso = value 
  //   this.updateVariable()      
  // }
  // get so(): any {
  //   return this.valso;
  // }


  constructor() { }

  ngOnInit(): void {
  }

  // updateVariable(){
  //   console.log(this.so);
    
  //   this.subTotal = this.so.subTotalOrderSum
  //   this.tax = this.so.totalTaxNum
  //   this.total = this.so.Total
  //   this.payments = this.so.totalPaymentsNum
  //   this.balance = this.so.Change_num
  // }


}
