import { Component, DoCheck, NgZone, OnInit, Renderer2 } from '@angular/core';
import { Pagination } from 'src/app/_modules/pagination';
import { StockItemsService } from 'src/app/_services/stockitems.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { SearchToSOService } from 'src/app/_services/search-to-so.service';
import { GlobalsService } from 'src/app/_services/globals.service';

@Component({
  selector: 'app-search-prods',
  templateUrl: './search-prods.component.html',
  styleUrls: ['./search-prods.component.scss']
})
export class SearchProdsComponent implements OnInit,DoCheck {
	recordItems=[];
	recordItemsDefault: any[];
  //get from DB
  manufactureDB = [];
	brandDB = [];
	productNameDB = [];
	typeDB = [];

  //toSend
  manufacture = [];
	brand = [];
	productName = [];
	type = [];

  totalitems = 0;
  pagination: Pagination;
	p: number = 1;

  src='';
  gap:string = '0px';

  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private stockItemsService: StockItemsService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private addToSo: SearchToSOService,
    private global: GlobalsService,
    private zone: NgZone
    ) { }

  ngOnInit(): void {
    this.getFiltersProd();
    this.pagination = {
			totalItems: 5,
			totalPages: 5,
			currentPage: 1,
			itemsPerPage: 5,
		};
    
  }

  getFiltersProd() {
		this.stockItemsService
    .getFiltersProd()
    .pipe(takeUntil(this.destroy$))
    .subscribe(
      (res: any) => {  
        this.productNameDB = res[0];
        this.typeDB = res[1];
        this.brandDB = res[2];
        this.manufactureDB = res[3];
      },
      (error) => {
        this.alertify.error(error);
      }
    );
	}

  ngDoCheck(): void {
    if(this.recordItems.length > 0){
      const container:HTMLDivElement | null = document.querySelector('.grid-container') as HTMLDivElement | null;
      const containerWidth = container.offsetWidth;
      const itemWidth:any | null = (document.querySelector('.grid-item')as HTMLDivElement | null)?.offsetWidth || 0;
      const nInRows = Math.floor(containerWidth / itemWidth);
      const gap = (  (containerWidth - 5 - (itemWidth*nInRows)   ) / (nInRows -1))   
      this.gap = (gap != null && gap != undefined ? ( Math.round(gap)): 0)+'px';
    }
  }

  closeModal() {
    this.addToSo.changeMessage('');
    this.modalService.hide(); 
  }
  
  
  addSoItem(sp:any){
    sp.SOItemsID = this.global.guid()
    
    this.addToSo.changeMessage(sp);
  }

  async receiveData(search: string): Promise<any> {
    if(search!=''){
      const searchTerms = search.toLowerCase().split(' ');

      const count:number=searchTerms.length;
      const productName: string[] = [];
      const brand: string[] = [];
      const type: string[] = [];
      const manufacture: string[] = [];

      searchTerms.forEach(term => {
        if (this.manufactureDB.some((x: { manufacture: string; }) => x.manufacture && x.manufacture.toLowerCase().includes(term.toLowerCase()))) manufacture.push(term);
        if (this.brandDB.some((x: { brand: string; }) => x.brand && x.brand.toLowerCase().includes(term.toLowerCase()))) brand.push(term);
        if (this.productNameDB.some((x: { productName: string; }) => x.productName && x.productName.toLowerCase().includes(term.toLowerCase()))) productName.push(term);
        if (this.typeDB.some((x: { type: string; }) => x.type && x.type.toLowerCase().includes(term.toLowerCase()))) type.push(term);
      });
    
      
      const result = {searchTerms,productName, brand, type, manufacture, count };
      this.recordItems= [];
	    this.recordItemsDefault= [];
      this.stockItemsService.getSearchProd(result)
      .toPromise().then((result:any) => {
        this.recordItems= result;
        this.recordItemsDefault= result;
        this.p=1;
        // var observer = new IntersectionObserver(function(entries) {
        //   entries.forEach(entry => {
        //     if (entry.isIntersecting) {
        //       entry.target.classList.add('active');
        //     }
        //   });
        // });
        // var elementos = document.querySelectorAll('grid-item');
        // elementos.forEach(el => observer.observe(el));
      }).catch((err) => {
        console.log(err);      
      });
    }
  }

}
