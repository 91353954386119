import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';
import { PaginatedResult } from '../_modules/pagination';



@Injectable({
  providedIn: 'root'
})
export class DevicesService {
  baseUrl = environment.apiUrl;
  laravel = environment.apiLaravel;
  devicesEvent = new BehaviorSubject<number>(0);
  editDevice = new BehaviorSubject<string>('');
  newDevice = new BehaviorSubject<string>('');
  editDevice$ = this.editDevice.asObservable();

  confirmEditedUser(device) {
    this.editDevice.next(device);
  }

constructor(private http: HttpClient) { }

  getDevices(page?, itemsPerPage?, searchDevice?) {
    return this.http.get<any>(this.laravel + 'devicesPOS')
  }

  getDevice(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'devicesPOS/' + id);
  }

  addDevice(device: any) {
    return this.http.post(this.laravel + 'devicesPOS', device);
  }

  updateDevice(id: string, device: any) {
    return this.http.patch(this.laravel + 'devicesPOS/' + id, device);
  }

  deleteDevice(id: string) {
    return this.http.delete(this.laravel + 'devicesPOS/' + id);
  }

  editedDevice(id: string) {
    this.editDevice.next(id);
  }

  getTypes(){
      return this.http.get(this.laravel + 'getDevicesTypes');
  }



}
