import { Component, OnInit } from '@angular/core';
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { EventsSMService } from 'src/app/_services/eventssm.service';
import { SettingsService } from 'src/app/_services/settings.service';
import { UserService } from 'src/app/_services/user.service';

@Component({
  selector: 'formly-repeat-section-small',
  template: `
    <div id="mainsection" *ngFor="let fiel of field.fieldGroup; let i = index; let impar = odd;"
    [ngClass]="impar ? 'tw-bg-aedgrayback100 par' :'tw-bg-aedgrayback impar'" class="tw-px-4" >
      <formly-group
        [field]="fiel" 
        [options]="options"
        [form]="formControl">

        <div class="col-md-2 d-flex align-items-center" *ngIf="field.key == 'warehouseaddress'">
          <button *ngIf="storeSettings?.avaTax == 1 || storeSettings?.avaTax == true" class="btn-blue-so" type="button" (click)="validateAddress(i)">
              Validate Address
          </button>
        </div>

        <div class="col-md-1 d-flex align-items-center">
          <button class="btn btn-delete"  type="button" (click)="removePayment(i); remove(i)">
          <svg id="Group_235" data-name="Group 235" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24.255" height="29.82" viewBox="0 0 24.255 29.82">
          <defs>
            <style>
              .cls-1 {
                fill: #00adee;
              }
        
              .cls-2 {
                clip-path: url(#clip-path);
              }
            </style>
            <clipPath id="clip-path">
              <rect id="Rectangle_99" data-name="Rectangle 99" class="cls-1" width="24.255" height="29.82"/>
            </clipPath>
          </defs>
          <g id="Group_40" data-name="Group 40" class="">
            <path id="Path_55" data-name="Path 55" class="cls-1" d="M14.513,0a2.979,2.979,0,0,1,1.865,1.289,3.193,3.193,0,0,1,.43,1.838c-.015.657-.087.574.563.576,1.493,0,2.987,0,4.48,0a2.316,2.316,0,0,1,2.4,2.4q0,1.556,0,3.113a.939.939,0,0,1-1.043,1.043c-.388,0-.4.019-.414.412q-.15,3.426-.307,6.853c-.072,1.577-.151,3.154-.226,4.732-.078,1.616-.146,3.232-.236,4.848a2.812,2.812,0,0,1-2.887,2.711q-7.011,0-14.022,0a2.84,2.84,0,0,1-2.9-2.853c-.084-2.217-.2-4.433-.3-6.649-.073-1.587-.153-3.174-.228-4.761q-.119-2.5-.234-4.993c-.01-.218-.1-.3-.322-.3C.311,10.251,0,9.926,0,9.1,0,8.086,0,7.068,0,6.05A2.313,2.313,0,0,1,2.344,3.706c1.581,0,3.161-.009,4.742.005.3,0,.383-.09.371-.38A4.029,4.029,0,0,1,7.506,2.2,2.78,2.78,0,0,1,9.465.09L9.741,0ZM12.148,10.264q-4.233,0-8.467,0c-.221,0-.338.033-.322.3.082,1.364.151,2.728.219,4.092q.119,2.4.229,4.791c.086,1.878.164,3.756.25,5.634.031.668.07,1.335.113,2a.856.856,0,0,0,.638.78,1.594,1.594,0,0,0,.46.057h13.7a1.623,1.623,0,0,0,.432-.046.863.863,0,0,0,.682-.836q.115-2.177.225-4.354.12-2.424.232-4.849c.085-1.868.164-3.737.248-5.605.024-.523.048-1.045.089-1.567.03-.381.029-.4-.353-.4h-8.38m-.02-1.9q4.946,0,9.891,0c.249,0,.344-.061.336-.325-.018-.572,0-1.144-.006-1.716s-.141-.716-.725-.716H2.6c-.544,0-.692.148-.693.689q0,.785,0,1.571c0,.536-.051.492.478.492h9.746M12.119,3.7c.833,0,1.666-.005,2.5,0,.213,0,.3-.064.286-.283-.014-.193,0-.387-.005-.581a.934.934,0,0,0-.961-.957q-1.815-.018-3.631,0a.93.93,0,0,0-.948.939c-.01.193.009.388-.006.581-.018.229.07.3.3.3.823-.011,1.646,0,2.469,0" transform="translate(0)"/>
            <path id="Path_56" data-name="Path 56" class="cls-1" d="M85.757,162.45q0,3.025,0,6.051a.924.924,0,0,1-.669.918.966.966,0,0,1-1.059-.36.882.882,0,0,1-.173-.544q0-6.066,0-12.131a.912.912,0,0,1,.94-.937.924.924,0,0,1,.958.952c0,2.017,0,4.034,0,6.051" transform="translate(-77.334 -143.36)"/>
            <path id="Path_57" data-name="Path 57" class="cls-1" d="M145.636,162.462q0,3.011,0,6.022a.925.925,0,0,1-.653.929.965.965,0,0,1-1.082-.364.9.9,0,0,1-.166-.546q0-6.051,0-12.1a.915.915,0,0,1,.953-.954.926.926,0,0,1,.946.964q.006,3.025,0,6.051" transform="translate(-132.557 -143.361)"/>
            <path id="Path_58" data-name="Path 58" class="cls-1" d="M205.506,162.412q0,3.011,0,6.022a.925.925,0,0,1-.657.926.97.97,0,0,1-1.081-.368,1.066,1.066,0,0,1-.164-.664q0-4.335,0-8.669c0-1.105,0-2.211,0-3.316a.922.922,0,0,1,.783-.942.952.952,0,0,1,1.076.645,1.268,1.268,0,0,1,.042.431q0,2.967,0,5.935" transform="translate(-187.771 -143.306)"/>
          </g>
        </svg>
          </button>
        </div>
      </formly-group>
    </div>
    <div class="mt-3" >
      <button *ngIf="field.fieldArray.templateOptions.btnText != 'Add Question'" class="btn-blue-so"
      type="button" (click)="add(); track()"> <span class="material-icons">
      add
      </span> {{field.fieldArray.templateOptions.btnText}}</button>
    </div>
  `,
})
export class RepeatSmallComponent extends FieldArrayType  implements OnInit  {
  
  destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    private eventSMService: EventsSMService,
    builder: FormlyFormBuilder,
    private settingsService: SettingsService) {
    super(builder);
  }

  storeSettings: any;

  ngOnInit() {
    this.eventSMService.changeDefaultWHA
    .pipe(takeUntil(this.destroy$))  
    .subscribe(
      (idcheck:any)=>{
        if(idcheck != ''){

          // find the index where it is
          let array = this.field.fieldGroup
          for (let i = 0; i < array.length; i++) {

            const fileg = array[i].fieldGroup;
            fileg.forEach(campo => {
              if(campo.key == 'default'){
                if(campo.id == idcheck){    
                  this.eventSMService.indexDefaultWHA.next(JSON.stringify(i));
                  setTimeout(() => {
                    this.eventSMService.changeDefaultWHA.next('');          
                  }, 1000); 
                }
              }
            }); 

          }              
   
        }
    })

    this.settingsService.getSettings(0)
    .pipe(takeUntil(this.destroy$))   
    .subscribe( data => {
      this.storeSettings = data[0];
    }, error => {
    });
  }
 
  track() {
    this.field.fieldArray.templateOptions.track();
  }

  validateAddress(i:any){              
    this.eventSMService.changeAddressWHA.next(JSON.stringify(i))
  }

  removePayment(x) {
    this.field.fieldArray.templateOptions.removePayment(x);
  }

}
