<div class="col-xs-12 col-md-12">

    <div class="tw-text-2xl sm:tw-text-lg md:tw-text-3xl lg:tw-text-3xl tittle-0 mb-2">
      {{ 'INVENTORY' | translate }}
    </div>

    <div *ngIf="inventoryMethod == 'FIFO' || inventoryMethod == 'Average Cost'">
      <div class="settings-tabs">
        <div class="tabs">
          <div class="tabs-links">
  
            <div class="tabs-links-link" [ngClass]="{active: show === 'warehouse'}" (click)="show = 'warehouse'">
              <p class="m-0">{{ 'WAREHOUSE' | translate }}</p>
            </div>
  
            <div class="tabs-links-link" [ngClass]="{active: show === 'retail'}" (click)="show = 'retail'">
              <p class="m-0">{{ 'RETAIL' | translate }}</p>
            </div>
  
            <div class="tabs-links-link" [ngClass]="{active: show === 'nearExpiry'}" (click)="show = 'nearExpiry'">
              <p class="m-0">{{ 'PRODUCTS_ABOUT_TO_EXPIRE' | translate }}</p>
            </div>    
  
            <div class="tabs-links-link" [ngClass]="{active: show === 'productType'}" (click)="show = 'productType'">
              <p class="m-0">{{ 'PRODUCT_TYPES' | translate }}</p>
            </div>  
  
            <div class="tabs-links-link" [ngClass]="{active: show === 'uniqueProducts'}" (click)="show = 'uniqueProducts'">
              <p class="m-0">{{ 'PURCHASE_ORDERS' | translate }}</p>
            </div>   
  
          </div>        
        </div>
      </div>
  
  
      <div *ngIf="show == 'warehouse'" class="bg-white tw-p-3">
          <app-stockitemslist></app-stockitemslist>
      </div>
      
      <div *ngIf="show == 'retail'" class="bg-white tw-p-3">
          <app-retail></app-retail>
      </div>
  
      <div *ngIf="show == 'nearExpiry'" class="bg-white tw-p-3">
          <app-near-expiry></app-near-expiry>
      </div>
  
      <div *ngIf="show == 'productType'" class="bg-white tw-p-3">
          <app-product-type></app-product-type>
      </div>
  
      <div *ngIf="show == 'uniqueProducts'" class="bg-white tw-p-3">
        <app-items-to-po></app-items-to-po>
      </div>
    </div>
  
    <div *ngIf="showAlert">
      <div class="inventory-warning">
        <p>{{ 'ACCESS_INVENTORY' | translate }}</p>
        <button (click)="redirectToInventorySetup()">{{ 'SET_INVENTORY_METHOD' | translate }}</button>
      </div>
      
    </div>
    
  </div>
