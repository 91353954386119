import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pays',
  templateUrl: './pays.component.html',
  styleUrls: ['./pays.component.scss']
})
export class PaysComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
