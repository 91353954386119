<!-- <div [ngClass]="{'tw-px-2 sm:tw-px-2 md:tw-px-4 lg:tw-px-4 tw-mb-2': role == 'Cashier' || role =='Sales Person'}">   -->
<div>
  <div *ngIf="searchMode" class="modal-body-header" style="margin-bottom: -20px;">
    <div *ngIf="showHeader">
      <div class="title-1-orange">{{ 'SELECTCUSTOMER' | translate }}</div>
    </div>
    <div class="modal-close-button">
      <app-closebutton></app-closebutton>
    </div>
  </div>

  <!-- navbar desktop -->
  <div id="navdesktop" >
  <nav class=" tw-h-full" *ngIf="!showMobile" [ngClass]="searchMode === true  ? 'scrollable' : 'scrollable'">
    <div *ngIf="fromList == true" class="tw-flex">
      <p class="title-0-orange">{{ 'CUSTOMERS' | translate }}: </p>
      <p class="title-0-orange ml-1">{{ pagination.totalItems }}</p>

      <div class="nav-display-options-type-view tw-ml-auto" btnRadioGroup [(ngModel)]="viewType">
        <div class="nav-display-options-type-view-text"><p>{{ 'VIEWAS' | translate }}:</p></div>
    
        <div [ngClass]="viewType == 'Card'?'tw-bg-aedgrayback100':''" class="mr-1 tw-p-1 flex tw-cursor-pointer"
          btnRadio="Card" tabindex="0" role="button" (click)="toogleView('Card')">
          <i class="fa fa-address-card"></i>
        </div>
    
        <div [ngClass]="viewType == 'List'?'tw-bg-aedgrayback100':''" class="tw-p-1 flex tw-cursor-pointer"
        btnRadio="List" tabindex="1s" role="button" (click)="toogleView('List')">
          <i class="fa fa-list"></i>
        </div>
      </div>

      <!-- <button *ngIf="role == 'Cashier' || role == 'Sales Person'" [routerLink]="['/salesorder']" routerLinkActive="router-link-active"  class="tw-ml-auto btn btn-3"  >Go to Sales Orders</button> -->
    </div>

    <div class="tw-flex tw-justify-between tw-mt-2">

      <div class="tw-flex tw-w-full tw-mr-4">
        
        <input
        style="background-color:  #f5f5f5"
        type="search"
        [formControl]="searchCustomer"
        placeholder="&#xf002; {{ 'SEARCHCUSTOMER' | translate }}"
        (keyup)="searchInput($event)"
        style="width: -webkit-fill-available;"
        class=" tw-mr-4 input-place-icon tw-border-2 tw-border-gray-400 tw-outline-none tw-rounded-md tw-px-2 "
        />  

        <button (click)="search()" class="btn-blue-so">
          <span class="material-icons">search</span>
        </button> 

      </div>  

      <button class="btn-blue-so" style="min-width: max-content;" (click)="addCustomer()">
        <span class="material-icons">add</span>
        <span>{{ 'ADDCUSTOMER' | translate }}</span> 
      </button>

    </div>
  </nav>    
  </div>


  

  <!-- /navbar desktop -->

  <!-- navbar mobile -->
  <div id="navmobilecustomer">
    <div *ngIf="!searchMode && showMobile" class="" [ngClass]="{'' : !searchMode === true, 'mobile-nav-summary': searchMode === true,'tw-w-full': role =='Cashier' || role =='Sales Person' }">
      <div class="tw-py-2 tw-w-full justify-content-between align-items-center
      tw-block sm:tw-block md:tw-flex lg:tw-flex">
        <div class="title-0-orange">{{ 'CUSTOMERS' | translate }}: <strong >{{ pagination.totalItems }}</strong></div>
        <div class="tw-flex">
          <span class="material-icons" style="color: #ffc107;width: 1.5rem;" *ngIf="specialcha">filter_list_of</span>
          <input style="background-color: #f5f5f5;" type="search" [formControl]="searchCustomer" 
          (keyup)="searchInput($event)"
          placeholder="{{ 'SEARCHCUSTOMER' | translate }}" 
          class="input-header-white tw-w-full tw-text-lg tw-text-aedgrayl"/>
          <button (click)="search()" class="tw-ml-2 btn-blue-so">
            <span class="material-icons">search</span>
          </button> 
        </div>
      </div>

      <div class="tw-w-full tw-flex tw-mb-2">
        <div class=" tw-ml-auto tw-flex">
          <!-- <button *ngIf="role == 'Cashier' || role == 'Sales Person'" [routerLink]="['/salesorder']" routerLinkActive="router-link-active"  
          class="tw-mr-2 btn btn-3" >Go to Sales Orders</button> -->
          <div class="btn-blue-so" id="btnAddCustomerMov" (click)="addCustomer()">
            <span class="material-icons">add</span>{{ 'ADDCUSTOMER' | translate }}
          </div>        
        </div>
      </div>

      <app-event-info-mobile [searchBy]="'All'" [fromEvent]="true"  class="event-nav-display-options-mobile" ></app-event-info-mobile>
    </div>    
  </div>

  <!-- /navbar mobile -->









  <div [style.height.px]="hcustomersrecords" *ngIf="customers?.length > 0" style="overflow: auto;" 
    [ngClass]="searchMode === true  ? 'customer-summary-scroll' : 'scroll-top-purchaseorder-down'">
    
    <div *ngIf="viewType === 'List'" class="table-responsive tw-mt-4">
      <table class="table-responsive">
        <table  class="table table-light table-custom overflow-auto" cellspacing="0">
          <thead class="tw-font-bold tw-text-aedblue">
            <tr style="font-size: small;background-color: white;" >
              <th class="border-b-th">{{ 'NAME' | translate }}</th>
              <th class="border-b-th">{{ 'PHONE' | translate }}</th>
              <th class="border-b-th">{{ 'EMAIL' | translate }}</th>
              <th class="border-b-th"></th>
            </tr>
          </thead>
          <tbody class="tw-text-aedgraydark">
            <tr class="m-1 p-1" *ngFor="let customer of customers;let impar = odd" 
            [ngClass]="impar ? 'tw-bg-aedgrayback100' :'tw-bg-aedgrayback'">
              <td>{{ customer?.firstName }} {{ customer?.lastName }}</td>
              <td>{{ customer?.phoneType }} {{ customer?.phone1 }}</td>
              <td><a href="mailto:#{{ customer?.email }}">{{ customer?.email }}</a></td>
              <td class="tw-flex" *ngIf="searchMode == false ">
                <div class="d-flex tw-ml-auto">
                  <p
                    class="customer-list-container-records-item-info-orders tw-mr-4"
                    *ngIf="customer.salesCount > 0"
                    (click)="salesOrderByCustomer(customer.Id)"
                  >
                  
                    <span class="material-icons mr-1">local_grocery_store</span> {{ 'ORDER' | translate }}: <span class="customer-list-container-records-item-info-orders-counter">{{ customer.salesCount }}</span>
                  </p>

    
                  <button
                    class="btn btn-blue"
                    (click)="editCustomer(customer.Id)"
                    type="button"
                  >
                    <svg  id="Component_59_102" data-name="Component 59 – 102" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25.674" height="25.681" viewBox="0 0 25.674 25.681">
                      <defs>
                        <style>
                          .cls-15 {
                            fill: #00adee;
                          }     
                        </style>
                        <clipPath id="clip-path">
                          <rect id="Rectangle_156" data-name="Rectangle 156" class="cls-15" width="25.674" height="25.681"/>
                        </clipPath>
                      </defs>
                      <g id="Group_63" data-name="Group 63" class="">
                        <path id="Path_76" data-name="Path 76" class="cls-15" d="M25.674,4.884a5.154,5.154,0,0,1-1.642,2.877C21,10.767,17.983,13.8,14.961,16.819Q12.306,19.475,9.65,22.13c-.313.313-.633.618-.946.931a1.42,1.42,0,0,1-.578.335Q4.861,24.481,1.6,25.572c-.095.032-.186.073-.279.109h-.6a1.094,1.094,0,0,1-.594-1.576q1.09-3.266,2.177-6.534a1.567,1.567,0,0,1,.389-.631Q10.514,9.121,18.337,1.3A4.289,4.289,0,0,1,25.3,2.465a5.738,5.738,0,0,1,.377,1.216ZM7.95,20.871c.089-.02.135-.1.192-.153q6.486-6.483,12.975-12.964c.166-.165.138-.246-.011-.392Q19.716,5.988,18.343,4.6c-.159-.162-.245-.167-.41,0q-2.648,2.667-5.312,5.319-3.827,3.827-7.657,7.651c-.133.133-.16.211-.012.357.946.933,1.881,1.875,2.821,2.813.052.052.1.118.176.136M23.641,4.2c0-.013-.014-.1-.025-.178A2.1,2.1,0,0,0,22.107,2.18,2.2,2.2,0,0,0,19.73,2.8c-.1.1-.1.171,0,.27q1.454,1.447,2.9,2.9c.086.086.155.1.252.009A2.349,2.349,0,0,0,23.641,4.2M5.976,21.876a.9.9,0,0,0-.07-.088q-1-1-2-2c-.1-.1-.147-.078-.188.048-.11.341-.226.681-.339,1.021q-.321.961-.641,1.923c-.02.06-.073.127-.024.189s.124.016.183,0q1.473-.488,2.944-.98c.05-.017.121-.017.135-.108" transform="translate(0 0)"/>
                      </g>
                    </svg>
                  </button>
                </div>
              </td> 
              <td *ngIf="searchMode == true ">
                <button
                class="btn btn-blue"
                (click)="selectCustomer(customer.Id,customer)"
                type="button"
              >
                <i class="fa fa-check"></i>
              </button>
              </td>   
            </tr>
          </tbody>
        </table>
      </table>





    </div>  
    
    <div  *ngIf="viewType === 'Card'"  class="tw-grid tw-grid-cols-1 sm:tw-grid-cols-2 md:tw-grid-cols-2 lg:tw-grid-cols-3">
      <div
        [ngClass]="!searchMode ? 'custom-card' : 'col-lg-6'"
        *ngFor="let customer of customers"
        class="customer-records-container-item tw-w-full"
      >
        <app-customer-card
          [(editMode)]="editMode"
          [customer]="customer"
          [search]="searchMode"
          [delivery]="'Pickup'"
          [viewType]="viewType"
          class="w-100 h-100"
        ></app-customer-card>
      </div>
    </div> 

  </div>

<div id="pagcustomers" >
  <div  class="pagination-container disabled" *ngIf="showPagination && customers?.length > 0">
    <pagination 
    [ngClass]="{'pagination-rigth': role == 'Cashier'}"
      [disabled]="paginationMode"
      [boundaryLinks]="true"
      [totalItems]="pagination.totalItems"
      [(ngModel)]="pagination.currentPage"
      [itemsPerPage]="pagination.itemsPerPage"
      (pageChanged)="pageChanged($event)"
      [maxSize]="maxsize"
      previousText="&lsaquo;"
      nextText="&rsaquo;"
      firstText="&laquo;"
      lastText="&raquo;"
    >
    </pagination>
  </div>  
</div>


  <!-- <div  *ngIf="role == 'Cashier' && !fromSalesOrder" class="tw-fixed tw-bottom-0 tw-z-50 tw-mb-2 lg:tw-mb-2"> 
    <button type="button" class=" tw-h-12 tw-bg-aedblue tw-px-2 tw-py-2 tw-font-bold tw-text-lg tw-rounded-md tw-text-white" 
    (click)="logout()">{{ 'LOGOUT' | translate }}</button>
  </div> -->

</div>