
<div #customerCard id="customerCard"></div>
<div  style="display: flex;"  *ngIf="so?.customerId"  >


  <div class="tw-flex tw-flex-row tw-items-center" >
    <h1 class="tw-text-2xl sm:tw-text-lg md:tw-text-3xl lg:tw-text-3xl tittle-0">Customer</h1>     
    <svg class="tw-w-10 tw-h-10 tw-ml-3" xmlns="http://www.w3.org/2000/svg" width="36.013" height="34.449" viewBox="0 0 36.013 34.449">
      <g id="Group_262" data-name="Group 262" transform="translate(3869.729 624.799)">
        <path id="Path_183" data-name="Path 183" d="M19.721,12.988h0a6,6,0,0,1-4.4-1.924,6.658,6.658,0,0,1-1.785-4.6,6.584,6.584,0,0,1,1.807-4.551A6.013,6.013,0,0,1,19.724,0h.026a6.371,6.371,0,0,1,6.167,6.513,6.593,6.593,0,0,1-1.8,4.564A6.022,6.022,0,0,1,19.721,12.988Zm0-11.257A4.3,4.3,0,0,0,16.6,3.109a4.86,4.86,0,0,0-1.33,3.36,4.934,4.934,0,0,0,1.315,3.409,4.286,4.286,0,0,0,3.141,1.379v0a4.618,4.618,0,0,0,4.465-4.745,4.638,4.638,0,0,0-4.444-4.781Z" transform="translate(-3871.468 -624.45)" fill="#ee5f00"/>
        <path id="Path_183_-_Outline" data-name="Path 183 - Outline" d="M19.721,13.338h0A6.348,6.348,0,0,1,15.064,11.3a7.007,7.007,0,0,1-1.88-4.838,6.933,6.933,0,0,1,1.9-4.792A6.36,6.36,0,0,1,19.724-.35h.027a6.721,6.721,0,0,1,6.517,6.863,6.942,6.942,0,0,1-1.9,4.805A6.369,6.369,0,0,1,19.721,13.338Zm0-12.988a5.666,5.666,0,0,0-4.129,1.807,6.236,6.236,0,0,0-1.711,4.31,6.31,6.31,0,0,0,1.69,4.357,5.654,5.654,0,0,0,4.146,1.814l0,.35v-.35a5.676,5.676,0,0,0,4.143-1.8,6.245,6.245,0,0,0,1.7-4.324A6.02,6.02,0,0,0,19.748.35Zm-.352,11.773v-.529a4.643,4.643,0,0,1-3.046-1.476,5.282,5.282,0,0,1-1.41-3.65,5.208,5.208,0,0,1,1.427-3.6,4.645,4.645,0,0,1,3.383-1.486h.019a4.989,4.989,0,0,1,4.793,5.131,5,5,0,0,1-4.465,5.082v.529Zm.349-1.216h0a4.269,4.269,0,0,0,4.115-4.4,4.288,4.288,0,0,0-4.1-4.43h-.017A3.951,3.951,0,0,0,16.849,3.35a4.511,4.511,0,0,0-1.234,3.12,4.585,4.585,0,0,0,1.22,3.169A3.939,3.939,0,0,0,19.721,10.907Z" transform="translate(-3871.468 -624.45)" fill="#ee5f00"/>
        <path id="Path_184" data-name="Path 184" d="M32.671,18.551H32.66a4.83,4.83,0,0,1-4.694-4.925,5.042,5.042,0,0,1,1.361-3.473,4.547,4.547,0,0,1,3.334-1.47h.032a4.594,4.594,0,0,1,3.333,1.473,5.1,5.1,0,0,1-.006,6.932A4.575,4.575,0,0,1,32.671,18.551Zm-.01-8.137a2.833,2.833,0,0,0-2.073.925,3.318,3.318,0,0,0-.891,2.286,3.1,3.1,0,0,0,2.968,3.195h.006a2.86,2.86,0,0,0,2.091-.921,3.3,3.3,0,0,0,.889-2.284,3.112,3.112,0,0,0-2.971-3.2Z" transform="translate(-3873.695 -625.79)" fill="#ee5f00"/>
        <path id="Path_184_-_Outline" data-name="Path 184 - Outline" d="M32.671,18.9H32.66a5.181,5.181,0,0,1-5.044-5.275,5.391,5.391,0,0,1,1.456-3.712A4.894,4.894,0,0,1,32.66,8.332h.034A4.942,4.942,0,0,1,36.28,9.915a5.448,5.448,0,0,1-.006,7.412A4.921,4.921,0,0,1,32.671,18.9Zm-.012-9.868a4.2,4.2,0,0,0-3.078,1.36,4.694,4.694,0,0,0-1.266,3.233A4.48,4.48,0,0,0,32.661,18.2h.01a4.228,4.228,0,0,0,3.094-1.354,4.748,4.748,0,0,0,.005-6.451A4.247,4.247,0,0,0,32.69,9.033Zm.012,8.137h-.007a3.448,3.448,0,0,1-3.317-3.545,3.667,3.667,0,0,1,.986-2.526,3.18,3.18,0,0,1,2.328-1.035h.021A3.462,3.462,0,0,1,36,13.614a3.652,3.652,0,0,1-.985,2.525A3.207,3.207,0,0,1,32.671,17.169Zm-.011-6.406a2.487,2.487,0,0,0-1.817.814,2.969,2.969,0,0,0-.8,2.047,2.748,2.748,0,0,0,2.619,2.845h.006a2.513,2.513,0,0,0,1.836-.811,2.955,2.955,0,0,0,.794-2.044,2.761,2.761,0,0,0-2.623-2.851Z" transform="translate(-3873.695 -625.79)" fill="#ee5f00"/>
        <path id="Path_185" data-name="Path 185" d="M7.056,18.551a4.575,4.575,0,0,1-3.349-1.463A5.1,5.1,0,0,1,3.7,10.155,4.594,4.594,0,0,1,7.034,8.683h.032a4.547,4.547,0,0,1,3.334,1.47,5.042,5.042,0,0,1,1.361,3.473,4.83,4.83,0,0,1-4.694,4.925Zm.01-8.137h-.02a3.112,3.112,0,0,0-2.971,3.2A3.3,3.3,0,0,0,4.965,15.9a2.86,2.86,0,0,0,2.091.921h.007a3.1,3.1,0,0,0,2.967-3.195,3.318,3.318,0,0,0-.891-2.286A2.833,2.833,0,0,0,7.066,10.413Z" transform="translate(-3869.741 -625.79)" fill="#ee5f00"/>
        <path id="Path_185_-_Outline" data-name="Path 185 - Outline" d="M7.056,18.9h0a4.921,4.921,0,0,1-3.6-1.573,5.448,5.448,0,0,1-.006-7.412A4.941,4.941,0,0,1,7.031,8.333h.033a4.894,4.894,0,0,1,3.591,1.581,5.391,5.391,0,0,1,1.456,3.712A5.18,5.18,0,0,1,7.068,18.9Zm.01-9.868H7.035A4.246,4.246,0,0,0,3.956,10.4a4.748,4.748,0,0,0,.005,6.451A4.228,4.228,0,0,0,7.055,18.2h.011a4.479,4.479,0,0,0,4.344-4.575,4.694,4.694,0,0,0-1.266-3.233A4.2,4.2,0,0,0,7.066,9.032Zm-.01,8.137a3.207,3.207,0,0,1-2.346-1.031,3.652,3.652,0,0,1-.985-2.525,3.462,3.462,0,0,1,3.318-3.551h.022A3.18,3.18,0,0,1,9.394,11.1a3.667,3.667,0,0,1,.986,2.526,3.448,3.448,0,0,1-3.316,3.545Zm.011-6.406h-.02a2.761,2.761,0,0,0-2.622,2.851,2.954,2.954,0,0,0,.794,2.044,2.514,2.514,0,0,0,1.836.811h.006A2.748,2.748,0,0,0,9.68,13.625a2.969,2.969,0,0,0-.8-2.047A2.487,2.487,0,0,0,7.067,10.763Z" transform="translate(-3869.741 -625.79)" fill="#ee5f00"/>
        <path id="Path_186" data-name="Path 186" d="M18.5,17.357A9.136,9.136,0,0,1,23.8,19.026a8.688,8.688,0,0,1,3.275,4.386L30.251,33c.376,1.134.706,2.273.72,2.321l.32,1.106H5.607l.383-1.141c0-.012.393-1.169.763-2.286l3.18-9.588a8.688,8.688,0,0,1,3.275-4.386A9.136,9.136,0,0,1,18.5,17.357ZM28.976,34.7c-.109-.353-.236-.755-.368-1.151l-3.18-9.588A6.969,6.969,0,0,0,22.8,20.441a7.5,7.5,0,0,0-8.593,0,6.968,6.968,0,0,0-2.629,3.516L8.4,33.546c-.132.4-.265.8-.383,1.151Z" transform="translate(-3870.244 -627.129)" fill="#ee5f00"/>
        <path id="Path_186_-_Outline" data-name="Path 186 - Outline" d="M18.5,17.007A9.484,9.484,0,0,1,24,18.74,9.035,9.035,0,0,1,27.4,23.3l3.18,9.588c.379,1.144.71,2.286.724,2.334l.449,1.553H5.121l.54-1.607c.067-.2.42-1.253.761-2.28L9.6,23.3a9.035,9.035,0,0,1,3.405-4.562A9.484,9.484,0,0,1,18.5,17.007ZM30.826,36.078l-.191-.659c-.014-.048-.341-1.178-.716-2.308l-3.18-9.588a8.34,8.34,0,0,0-3.144-4.21,8.881,8.881,0,0,0-10.184,0,8.34,8.34,0,0,0-3.144,4.21l-3.18,9.588c-.341,1.029-.695,2.082-.762,2.283l0,0-.228.68ZM18.5,18.739A7.762,7.762,0,0,1,23,20.155a7.316,7.316,0,0,1,2.76,3.692l3.18,9.588c.116.35.241.739.37,1.158l.14.453H7.528l.154-.461c.118-.354.252-.755.383-1.151l3.18-9.588A7.316,7.316,0,0,1,14,20.155,7.762,7.762,0,0,1,18.5,18.739Zm10,15.609c-.077-.244-.152-.475-.224-.691L25.1,24.068a6.621,6.621,0,0,0-2.5-3.34,7.15,7.15,0,0,0-8.189,0,6.621,6.621,0,0,0-2.5,3.34L8.73,33.656l-.23.691Z" transform="translate(-3870.244 -627.129)" fill="#ee5f00"/>
        <path id="Path_187" data-name="Path 187" d="M18.18,37.092H2.8A2.829,2.829,0,0,1,.6,36.06a2.619,2.619,0,0,1-.545-2.188l1.489-7.254.015-.047a7.563,7.563,0,0,1,2.29-3.521,6.127,6.127,0,0,1,4.009-1.392,7.276,7.276,0,0,1,3.064.674l.874.4-.493.827a6.664,6.664,0,0,0-.592,1.267L7.531,34.21c-.128.377-.258.758-.373,1.1H17.592ZM3.218,27.063,1.749,34.219a.889.889,0,0,0,.191.746,1.1,1.1,0,0,0,.864.4H5.309c.149-.434.369-1.077.582-1.707L9.07,24.272q.116-.342.268-.683a5.537,5.537,0,0,0-1.482-.2C6.58,23.389,4.285,23.868,3.218,27.063Z" transform="translate(-3869.379 -627.792)" fill="#ee5f00"/>
        <path id="Path_187_-_Outline" data-name="Path 187 - Outline" d="M18.664,37.442H2.8A3.177,3.177,0,0,1,.327,36.281,2.968,2.968,0,0,1-.289,33.8l1.5-7.293.015-.047a7.911,7.911,0,0,1,2.4-3.682,6.469,6.469,0,0,1,4.231-1.472,7.623,7.623,0,0,1,3.21.706l1.228.565L11.6,23.74a6.315,6.315,0,0,0-.561,1.2L7.863,34.322l-.216.635h10.2ZM1.882,26.707.4,33.942a2.271,2.271,0,0,0,.473,1.9,2.48,2.48,0,0,0,1.934.9H17.7l-.357-1.085H6.668l.158-.463c.116-.339.246-.72.373-1.1l3.179-9.382A7.016,7.016,0,0,1,11,23.382l.294-.492-.521-.24a6.928,6.928,0,0,0-2.918-.642,5.786,5.786,0,0,0-3.786,1.312,7.215,7.215,0,0,0-2.18,3.36Zm3.678,9H2.8a1.453,1.453,0,0,1-1.136-.524,1.233,1.233,0,0,1-.262-1.038L2.88,26.972l.007-.02c1.137-3.405,3.6-3.914,4.97-3.914a5.9,5.9,0,0,1,1.576.213l.392.109-.166.371c-.1.216-.183.436-.256.653L6.223,33.767c-.214.63-.434,1.274-.582,1.708Zm-2-8.556L2.092,34.29a.535.535,0,0,0,.119.455.756.756,0,0,0,.593.266H5.059c.142-.414.323-.946.5-1.469L8.739,24.16q.055-.162.118-.325a5.221,5.221,0,0,0-1-.1,4.334,4.334,0,0,0-2.218.589A5,5,0,0,0,3.557,27.154Z" transform="translate(-3869.379 -627.792)" fill="#ee5f00"/>
        <path id="Path_188" data-name="Path 188" d="M35.636,37.092H20.26l.588-1.785H31.283c-.116-.339-.246-.72-.373-1.1L27.73,24.828a6.664,6.664,0,0,0-.592-1.267l-.493-.827.874-.4a7.276,7.276,0,0,1,3.064-.674,6.127,6.127,0,0,1,4.009,1.392,7.563,7.563,0,0,1,2.29,3.521l.015.047,1.489,7.254a2.62,2.62,0,0,1-.545,2.189A2.829,2.829,0,0,1,35.636,37.092Zm-2.505-1.731h2.505a1.1,1.1,0,0,0,.864-.4.889.889,0,0,0,.19-.747l-1.469-7.156a4.851,4.851,0,0,0-4.638-3.675,5.537,5.537,0,0,0-1.482.2q.152.34.268.683l3.179,9.382C32.762,34.284,32.982,34.927,33.131,35.361Z" transform="translate(-3872.506 -627.792)" fill="#ee5f00"/>
        <path id="Path_188_-_Outline" data-name="Path 188 - Outline" d="M35.636,37.442H19.776l.819-2.485h10.2l-.216-.635L27.4,24.94a6.314,6.314,0,0,0-.561-1.2l-.692-1.161,1.228-.565a7.623,7.623,0,0,1,3.21-.706,6.469,6.469,0,0,1,4.231,1.472,7.911,7.911,0,0,1,2.4,3.682l.026.085L38.729,33.8a2.968,2.968,0,0,1-.617,2.48A3.177,3.177,0,0,1,35.636,37.442Zm-14.892-.7H35.636a2.48,2.48,0,0,0,1.934-.9,2.271,2.271,0,0,0,.473-1.9l-1.485-7.234-.009-.028a7.215,7.215,0,0,0-2.18-3.36,5.786,5.786,0,0,0-3.786-1.312,6.928,6.928,0,0,0-2.918.642l-.521.24.294.492a7.015,7.015,0,0,1,.623,1.334L31.241,34.1c.128.377.257.758.373,1.1l.158.463H21.1Zm14.892-1.031H32.881l-.081-.236c-.149-.434-.369-1.077-.582-1.708l-3.179-9.382c-.073-.216-.16-.436-.256-.652l-.166-.371.392-.109a5.9,5.9,0,0,1,1.576-.213,5.186,5.186,0,0,1,4.97,3.914l.007.02,1.473,7.176a1.234,1.234,0,0,1-.262,1.038A1.453,1.453,0,0,1,35.636,35.711Zm-2.255-.7h2.255a.756.756,0,0,0,.593-.266.535.535,0,0,0,.119-.455l-1.464-7.135a5.858,5.858,0,0,0-1.3-2.206,4.052,4.052,0,0,0-3-1.21,5.221,5.221,0,0,0-1,.1q.063.163.118.325l3.179,9.382C33.057,34.065,33.239,34.6,33.381,35.011Z" transform="translate(-3872.506 -627.792)" fill="#ee5f00"/>
      </g>
    </svg>
  </div>

  <div style="margin-left: auto;">

    <div class="d-flex gap-3" *ngIf="!canSelect && !showForm && !showSelectCustomer && !showEditCustomer">  
      <button class="btn btn-blue-static-mobile mr-1" (click)="selectCustomer()" >
        <span class="material-icons mr-3" style="font-size:1.8rem">
          search
        </span>
      </button>
    
       <button  class="btn btn-blue-static-mobile mr-3"
        (click)="editSalesOrder()"
        type="button">
        <svg id="Component_59_102" data-name="Component 59 – 102" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25.674" height="25.681" viewBox="0 0 25.674 25.681">
          <defs>
            <style>
              .cls-15 {
                fill: #00adee;
              }
            </style>
            <clipPath id="clip-path">
              <rect id="Rectangle_156" data-name="Rectangle 156" class="cls-15" width="25.674" height="25.681"/>
            </clipPath>
          </defs>
          <g id="Group_63" data-name="Group 63" class="cls-2">
            <path id="Path_76" data-name="Path 76" class="cls-15" d="M25.674,4.884a5.154,5.154,0,0,1-1.642,2.877C21,10.767,17.983,13.8,14.961,16.819Q12.306,19.475,9.65,22.13c-.313.313-.633.618-.946.931a1.42,1.42,0,0,1-.578.335Q4.861,24.481,1.6,25.572c-.095.032-.186.073-.279.109h-.6a1.094,1.094,0,0,1-.594-1.576q1.09-3.266,2.177-6.534a1.567,1.567,0,0,1,.389-.631Q10.514,9.121,18.337,1.3A4.289,4.289,0,0,1,25.3,2.465a5.738,5.738,0,0,1,.377,1.216ZM7.95,20.871c.089-.02.135-.1.192-.153q6.486-6.483,12.975-12.964c.166-.165.138-.246-.011-.392Q19.716,5.988,18.343,4.6c-.159-.162-.245-.167-.41,0q-2.648,2.667-5.312,5.319-3.827,3.827-7.657,7.651c-.133.133-.16.211-.012.357.946.933,1.881,1.875,2.821,2.813.052.052.1.118.176.136M23.641,4.2c0-.013-.014-.1-.025-.178A2.1,2.1,0,0,0,22.107,2.18,2.2,2.2,0,0,0,19.73,2.8c-.1.1-.1.171,0,.27q1.454,1.447,2.9,2.9c.086.086.155.1.252.009A2.349,2.349,0,0,0,23.641,4.2M5.976,21.876a.9.9,0,0,0-.07-.088q-1-1-2-2c-.1-.1-.147-.078-.188.048-.11.341-.226.681-.339,1.021q-.321.961-.641,1.923c-.02.06-.073.127-.024.189s.124.016.183,0q1.473-.488,2.944-.98c.05-.017.121-.017.135-.108" transform="translate(0 0)"/>
          </g>
        </svg>
      </button>
    </div>
    
    <div class="d-flex gap-3" *ngIf=" !(!showForm && !showSelectCustomer && !showEditCustomer)">
      <button class="btn btn-blue-static-mobile mr-1" (click)="closeAll()">
        <span class="material-icons mr-3" style="font-size: 1.8rem">
          cancel
        </span>
      </button>
    </div>
 
  </div> 
</div>









<div style="display: flex;" *ngIf="!so?.customerId" >
  <div class="tw-flex tw-flex-row tw-items-center" >
    <h1 class="tw-text-2xl sm:tw-text-lg md:tw-text-3xl lg:tw-text-3xl tittle-0">Customer</h1>     
    <svg class="tw-w-10 tw-h-10 tw-ml-3" xmlns="http://www.w3.org/2000/svg" width="36.013" height="34.449" viewBox="0 0 36.013 34.449">
      <g id="Group_262" data-name="Group 262" transform="translate(3869.729 624.799)">
        <path id="Path_183" data-name="Path 183" d="M19.721,12.988h0a6,6,0,0,1-4.4-1.924,6.658,6.658,0,0,1-1.785-4.6,6.584,6.584,0,0,1,1.807-4.551A6.013,6.013,0,0,1,19.724,0h.026a6.371,6.371,0,0,1,6.167,6.513,6.593,6.593,0,0,1-1.8,4.564A6.022,6.022,0,0,1,19.721,12.988Zm0-11.257A4.3,4.3,0,0,0,16.6,3.109a4.86,4.86,0,0,0-1.33,3.36,4.934,4.934,0,0,0,1.315,3.409,4.286,4.286,0,0,0,3.141,1.379v0a4.618,4.618,0,0,0,4.465-4.745,4.638,4.638,0,0,0-4.444-4.781Z" transform="translate(-3871.468 -624.45)" fill="#ee5f00"/>
        <path id="Path_183_-_Outline" data-name="Path 183 - Outline" d="M19.721,13.338h0A6.348,6.348,0,0,1,15.064,11.3a7.007,7.007,0,0,1-1.88-4.838,6.933,6.933,0,0,1,1.9-4.792A6.36,6.36,0,0,1,19.724-.35h.027a6.721,6.721,0,0,1,6.517,6.863,6.942,6.942,0,0,1-1.9,4.805A6.369,6.369,0,0,1,19.721,13.338Zm0-12.988a5.666,5.666,0,0,0-4.129,1.807,6.236,6.236,0,0,0-1.711,4.31,6.31,6.31,0,0,0,1.69,4.357,5.654,5.654,0,0,0,4.146,1.814l0,.35v-.35a5.676,5.676,0,0,0,4.143-1.8,6.245,6.245,0,0,0,1.7-4.324A6.02,6.02,0,0,0,19.748.35Zm-.352,11.773v-.529a4.643,4.643,0,0,1-3.046-1.476,5.282,5.282,0,0,1-1.41-3.65,5.208,5.208,0,0,1,1.427-3.6,4.645,4.645,0,0,1,3.383-1.486h.019a4.989,4.989,0,0,1,4.793,5.131,5,5,0,0,1-4.465,5.082v.529Zm.349-1.216h0a4.269,4.269,0,0,0,4.115-4.4,4.288,4.288,0,0,0-4.1-4.43h-.017A3.951,3.951,0,0,0,16.849,3.35a4.511,4.511,0,0,0-1.234,3.12,4.585,4.585,0,0,0,1.22,3.169A3.939,3.939,0,0,0,19.721,10.907Z" transform="translate(-3871.468 -624.45)" fill="#ee5f00"/>
        <path id="Path_184" data-name="Path 184" d="M32.671,18.551H32.66a4.83,4.83,0,0,1-4.694-4.925,5.042,5.042,0,0,1,1.361-3.473,4.547,4.547,0,0,1,3.334-1.47h.032a4.594,4.594,0,0,1,3.333,1.473,5.1,5.1,0,0,1-.006,6.932A4.575,4.575,0,0,1,32.671,18.551Zm-.01-8.137a2.833,2.833,0,0,0-2.073.925,3.318,3.318,0,0,0-.891,2.286,3.1,3.1,0,0,0,2.968,3.195h.006a2.86,2.86,0,0,0,2.091-.921,3.3,3.3,0,0,0,.889-2.284,3.112,3.112,0,0,0-2.971-3.2Z" transform="translate(-3873.695 -625.79)" fill="#ee5f00"/>
        <path id="Path_184_-_Outline" data-name="Path 184 - Outline" d="M32.671,18.9H32.66a5.181,5.181,0,0,1-5.044-5.275,5.391,5.391,0,0,1,1.456-3.712A4.894,4.894,0,0,1,32.66,8.332h.034A4.942,4.942,0,0,1,36.28,9.915a5.448,5.448,0,0,1-.006,7.412A4.921,4.921,0,0,1,32.671,18.9Zm-.012-9.868a4.2,4.2,0,0,0-3.078,1.36,4.694,4.694,0,0,0-1.266,3.233A4.48,4.48,0,0,0,32.661,18.2h.01a4.228,4.228,0,0,0,3.094-1.354,4.748,4.748,0,0,0,.005-6.451A4.247,4.247,0,0,0,32.69,9.033Zm.012,8.137h-.007a3.448,3.448,0,0,1-3.317-3.545,3.667,3.667,0,0,1,.986-2.526,3.18,3.18,0,0,1,2.328-1.035h.021A3.462,3.462,0,0,1,36,13.614a3.652,3.652,0,0,1-.985,2.525A3.207,3.207,0,0,1,32.671,17.169Zm-.011-6.406a2.487,2.487,0,0,0-1.817.814,2.969,2.969,0,0,0-.8,2.047,2.748,2.748,0,0,0,2.619,2.845h.006a2.513,2.513,0,0,0,1.836-.811,2.955,2.955,0,0,0,.794-2.044,2.761,2.761,0,0,0-2.623-2.851Z" transform="translate(-3873.695 -625.79)" fill="#ee5f00"/>
        <path id="Path_185" data-name="Path 185" d="M7.056,18.551a4.575,4.575,0,0,1-3.349-1.463A5.1,5.1,0,0,1,3.7,10.155,4.594,4.594,0,0,1,7.034,8.683h.032a4.547,4.547,0,0,1,3.334,1.47,5.042,5.042,0,0,1,1.361,3.473,4.83,4.83,0,0,1-4.694,4.925Zm.01-8.137h-.02a3.112,3.112,0,0,0-2.971,3.2A3.3,3.3,0,0,0,4.965,15.9a2.86,2.86,0,0,0,2.091.921h.007a3.1,3.1,0,0,0,2.967-3.195,3.318,3.318,0,0,0-.891-2.286A2.833,2.833,0,0,0,7.066,10.413Z" transform="translate(-3869.741 -625.79)" fill="#ee5f00"/>
        <path id="Path_185_-_Outline" data-name="Path 185 - Outline" d="M7.056,18.9h0a4.921,4.921,0,0,1-3.6-1.573,5.448,5.448,0,0,1-.006-7.412A4.941,4.941,0,0,1,7.031,8.333h.033a4.894,4.894,0,0,1,3.591,1.581,5.391,5.391,0,0,1,1.456,3.712A5.18,5.18,0,0,1,7.068,18.9Zm.01-9.868H7.035A4.246,4.246,0,0,0,3.956,10.4a4.748,4.748,0,0,0,.005,6.451A4.228,4.228,0,0,0,7.055,18.2h.011a4.479,4.479,0,0,0,4.344-4.575,4.694,4.694,0,0,0-1.266-3.233A4.2,4.2,0,0,0,7.066,9.032Zm-.01,8.137a3.207,3.207,0,0,1-2.346-1.031,3.652,3.652,0,0,1-.985-2.525,3.462,3.462,0,0,1,3.318-3.551h.022A3.18,3.18,0,0,1,9.394,11.1a3.667,3.667,0,0,1,.986,2.526,3.448,3.448,0,0,1-3.316,3.545Zm.011-6.406h-.02a2.761,2.761,0,0,0-2.622,2.851,2.954,2.954,0,0,0,.794,2.044,2.514,2.514,0,0,0,1.836.811h.006A2.748,2.748,0,0,0,9.68,13.625a2.969,2.969,0,0,0-.8-2.047A2.487,2.487,0,0,0,7.067,10.763Z" transform="translate(-3869.741 -625.79)" fill="#ee5f00"/>
        <path id="Path_186" data-name="Path 186" d="M18.5,17.357A9.136,9.136,0,0,1,23.8,19.026a8.688,8.688,0,0,1,3.275,4.386L30.251,33c.376,1.134.706,2.273.72,2.321l.32,1.106H5.607l.383-1.141c0-.012.393-1.169.763-2.286l3.18-9.588a8.688,8.688,0,0,1,3.275-4.386A9.136,9.136,0,0,1,18.5,17.357ZM28.976,34.7c-.109-.353-.236-.755-.368-1.151l-3.18-9.588A6.969,6.969,0,0,0,22.8,20.441a7.5,7.5,0,0,0-8.593,0,6.968,6.968,0,0,0-2.629,3.516L8.4,33.546c-.132.4-.265.8-.383,1.151Z" transform="translate(-3870.244 -627.129)" fill="#ee5f00"/>
        <path id="Path_186_-_Outline" data-name="Path 186 - Outline" d="M18.5,17.007A9.484,9.484,0,0,1,24,18.74,9.035,9.035,0,0,1,27.4,23.3l3.18,9.588c.379,1.144.71,2.286.724,2.334l.449,1.553H5.121l.54-1.607c.067-.2.42-1.253.761-2.28L9.6,23.3a9.035,9.035,0,0,1,3.405-4.562A9.484,9.484,0,0,1,18.5,17.007ZM30.826,36.078l-.191-.659c-.014-.048-.341-1.178-.716-2.308l-3.18-9.588a8.34,8.34,0,0,0-3.144-4.21,8.881,8.881,0,0,0-10.184,0,8.34,8.34,0,0,0-3.144,4.21l-3.18,9.588c-.341,1.029-.695,2.082-.762,2.283l0,0-.228.68ZM18.5,18.739A7.762,7.762,0,0,1,23,20.155a7.316,7.316,0,0,1,2.76,3.692l3.18,9.588c.116.35.241.739.37,1.158l.14.453H7.528l.154-.461c.118-.354.252-.755.383-1.151l3.18-9.588A7.316,7.316,0,0,1,14,20.155,7.762,7.762,0,0,1,18.5,18.739Zm10,15.609c-.077-.244-.152-.475-.224-.691L25.1,24.068a6.621,6.621,0,0,0-2.5-3.34,7.15,7.15,0,0,0-8.189,0,6.621,6.621,0,0,0-2.5,3.34L8.73,33.656l-.23.691Z" transform="translate(-3870.244 -627.129)" fill="#ee5f00"/>
        <path id="Path_187" data-name="Path 187" d="M18.18,37.092H2.8A2.829,2.829,0,0,1,.6,36.06a2.619,2.619,0,0,1-.545-2.188l1.489-7.254.015-.047a7.563,7.563,0,0,1,2.29-3.521,6.127,6.127,0,0,1,4.009-1.392,7.276,7.276,0,0,1,3.064.674l.874.4-.493.827a6.664,6.664,0,0,0-.592,1.267L7.531,34.21c-.128.377-.258.758-.373,1.1H17.592ZM3.218,27.063,1.749,34.219a.889.889,0,0,0,.191.746,1.1,1.1,0,0,0,.864.4H5.309c.149-.434.369-1.077.582-1.707L9.07,24.272q.116-.342.268-.683a5.537,5.537,0,0,0-1.482-.2C6.58,23.389,4.285,23.868,3.218,27.063Z" transform="translate(-3869.379 -627.792)" fill="#ee5f00"/>
        <path id="Path_187_-_Outline" data-name="Path 187 - Outline" d="M18.664,37.442H2.8A3.177,3.177,0,0,1,.327,36.281,2.968,2.968,0,0,1-.289,33.8l1.5-7.293.015-.047a7.911,7.911,0,0,1,2.4-3.682,6.469,6.469,0,0,1,4.231-1.472,7.623,7.623,0,0,1,3.21.706l1.228.565L11.6,23.74a6.315,6.315,0,0,0-.561,1.2L7.863,34.322l-.216.635h10.2ZM1.882,26.707.4,33.942a2.271,2.271,0,0,0,.473,1.9,2.48,2.48,0,0,0,1.934.9H17.7l-.357-1.085H6.668l.158-.463c.116-.339.246-.72.373-1.1l3.179-9.382A7.016,7.016,0,0,1,11,23.382l.294-.492-.521-.24a6.928,6.928,0,0,0-2.918-.642,5.786,5.786,0,0,0-3.786,1.312,7.215,7.215,0,0,0-2.18,3.36Zm3.678,9H2.8a1.453,1.453,0,0,1-1.136-.524,1.233,1.233,0,0,1-.262-1.038L2.88,26.972l.007-.02c1.137-3.405,3.6-3.914,4.97-3.914a5.9,5.9,0,0,1,1.576.213l.392.109-.166.371c-.1.216-.183.436-.256.653L6.223,33.767c-.214.63-.434,1.274-.582,1.708Zm-2-8.556L2.092,34.29a.535.535,0,0,0,.119.455.756.756,0,0,0,.593.266H5.059c.142-.414.323-.946.5-1.469L8.739,24.16q.055-.162.118-.325a5.221,5.221,0,0,0-1-.1,4.334,4.334,0,0,0-2.218.589A5,5,0,0,0,3.557,27.154Z" transform="translate(-3869.379 -627.792)" fill="#ee5f00"/>
        <path id="Path_188" data-name="Path 188" d="M35.636,37.092H20.26l.588-1.785H31.283c-.116-.339-.246-.72-.373-1.1L27.73,24.828a6.664,6.664,0,0,0-.592-1.267l-.493-.827.874-.4a7.276,7.276,0,0,1,3.064-.674,6.127,6.127,0,0,1,4.009,1.392,7.563,7.563,0,0,1,2.29,3.521l.015.047,1.489,7.254a2.62,2.62,0,0,1-.545,2.189A2.829,2.829,0,0,1,35.636,37.092Zm-2.505-1.731h2.505a1.1,1.1,0,0,0,.864-.4.889.889,0,0,0,.19-.747l-1.469-7.156a4.851,4.851,0,0,0-4.638-3.675,5.537,5.537,0,0,0-1.482.2q.152.34.268.683l3.179,9.382C32.762,34.284,32.982,34.927,33.131,35.361Z" transform="translate(-3872.506 -627.792)" fill="#ee5f00"/>
        <path id="Path_188_-_Outline" data-name="Path 188 - Outline" d="M35.636,37.442H19.776l.819-2.485h10.2l-.216-.635L27.4,24.94a6.314,6.314,0,0,0-.561-1.2l-.692-1.161,1.228-.565a7.623,7.623,0,0,1,3.21-.706,6.469,6.469,0,0,1,4.231,1.472,7.911,7.911,0,0,1,2.4,3.682l.026.085L38.729,33.8a2.968,2.968,0,0,1-.617,2.48A3.177,3.177,0,0,1,35.636,37.442Zm-14.892-.7H35.636a2.48,2.48,0,0,0,1.934-.9,2.271,2.271,0,0,0,.473-1.9l-1.485-7.234-.009-.028a7.215,7.215,0,0,0-2.18-3.36,5.786,5.786,0,0,0-3.786-1.312,6.928,6.928,0,0,0-2.918.642l-.521.24.294.492a7.015,7.015,0,0,1,.623,1.334L31.241,34.1c.128.377.257.758.373,1.1l.158.463H21.1Zm14.892-1.031H32.881l-.081-.236c-.149-.434-.369-1.077-.582-1.708l-3.179-9.382c-.073-.216-.16-.436-.256-.652l-.166-.371.392-.109a5.9,5.9,0,0,1,1.576-.213,5.186,5.186,0,0,1,4.97,3.914l.007.02,1.473,7.176a1.234,1.234,0,0,1-.262,1.038A1.453,1.453,0,0,1,35.636,35.711Zm-2.255-.7h2.255a.756.756,0,0,0,.593-.266.535.535,0,0,0,.119-.455l-1.464-7.135a5.858,5.858,0,0,0-1.3-2.206,4.052,4.052,0,0,0-3-1.21,5.221,5.221,0,0,0-1,.1q.063.163.118.325l3.179,9.382C33.057,34.065,33.239,34.6,33.381,35.011Z" transform="translate(-3872.506 -627.792)" fill="#ee5f00"/>
      </g>
    </svg>
  </div>
<div style="margin-left: auto;">
  <button class="btn btn-blue-static-mobile" (click)="selectCustomer()" *ngIf="!showSelectCustomer">
  <span class="material-icons" style="font-size: 1.8rem">
  search
  </span>
  </button>

  <button class="btn btn-blue-static-mobile" *ngIf="showSelectCustomer && !showEditCustomer" (click)="cancelsearch(true)">
    <span class="material-icons" style="font-size: 1.8rem">
      cancel
    </span>
  </button>
</div> 
</div>





<div style="background-color:  #f5f5f5 !important; 
    width: 100%;
    border-radius: 3px;
    margin-top: 1rem;" 
    class="tw-grid tw-grid-cols-2"
*ngIf="so?.customerId !== 0 && !showForm && !showSelectCustomer && !showEditCustomer">

  <div class="tw-col-span-1">
      <strong class="title-2 ">Billing Address:</strong>
      <h1 class="info-1 ">{{ so?.firstName }} {{ so?.lastName }}</h1>
      <h1 class="info-1 ">{{ so?.billingAddress1 }}</h1>
      <h1 class="info-1 " *ngIf="so?.billingAddress2">{{ so?.billingAddress2 }}</h1>
      <h1 class="info-1 ">{{ so?.city }} {{ so?.state }} {{ so?.zip }}</h1>
      <h1 class="info-1 ">{{ so?.phoneType }} {{ so?.phone1 }}</h1>
      <div class="info-1 " href="mailto:{{ so?.email }}">{{ so?.email }}</div>
  </div>

  <div class="tw-col-span-1" *ngIf="so.deliveryRequest === 'Delivery'">
    <span class="title-2">Shipping Address:</span> 
    <h1 class="info-1">{{ so?.fName }} {{ so?.lName }}</h1>
    <h1 class="info-1">{{ so?.deliveryAddress1 }}</h1>
    <h1 class="info-1" *ngIf="so?.deliveryAddress2">{{ so?.deliveryAddress2 }}</h1>
    <h1 class="info-1">{{ so?.deliveryCity }} {{ so?.deliveryState }} {{ so?.deliveryZip }}</h1>
    <h1 class="info-1">{{ so?.phone3Type }} {{ so?.phone3 }}</h1>
    <div class="info-1" href="mailto:{{ so?.deliveryEmail }}">{{ so?.deliveryEmail }}</div>
  </div>

</div>

<div style="background-color: #f5f5f5 !important;" *ngIf="showForm">
<app-customer-form-mobile [deliveryRequest]="so['deliveryRequest']" [salesOrderToSave]="so" [fromSalesOrder]="true" [(showSelectCustomer)]="showSelectCustomer" (closeEditCustomerChange)="closeEditCustomerChange($event)" [(showEditCustomer)]="showEditCustomer"  [(showForm)]="showForm" ></app-customer-form-mobile>
</div>

<div style="background-color: #f5f5f5 !important;" *ngIf="showSelectCustomer">
  <app-customer-list-mobile  (CancelSearch)="cancelsearch($event)" [salesOrderToSave]="so" style="background-color:  #f5f5f5 !important;" [fromSalesOrder]="true" [searchMode]="true" [(showEditCustomer)]="showEditCustomer"  [(showSelectCustomer)]="showSelectCustomer" [(showForm)]="showForm"></app-customer-list-mobile>
</div>

<div style="background-color: #f5f5f5 !important;" *ngIf="showEditCustomer">
  <app-address-mobile [customerComment]="customerComment" [deliveryRequest]="so['deliveryRequest']" [salesOrderToSave]="so" [so]="salesOrder" [(showEditCustomer)]="showEditCustomer" [(showSelectCustomer)]="showSelectCustomer" (closeEditCustomerChange)="closeEditCustomerChange($event)" [(showForm)]="showForm"></app-address-mobile>
</div>




