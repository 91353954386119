import { Component, OnDestroy, OnInit, ElementRef, ViewChild, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { SalesOrder } from 'src/app/_modules/salesorders';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { SettingsService } from 'src/app/_services/settings.service';
import { SearchProdsComponent } from '../search-prods/search-prods.component';
import { SearchToSOService } from 'src/app/_services/search-to-so.service';
import { DefaultService } from 'src/app/_services/default.service';
import { ShowReceiptComponent } from '../show-receipt/show-receipt.component';
import printJS from "print-js";
import { TranslateService } from '@ngx-translate/core';
declare var Stimulsoft: any;


declare var iswebKit: any;

@Component({
  selector: 'app-so',
  templateUrl: './so.component.html',
  styleUrls: ['./so.component.scss']
})
export class SoComponent implements OnInit,OnDestroy {
  @ViewChild('printSection') printSectionElement: ElementRef;
  destroy$: Subject<boolean> = new Subject<boolean>();
  idparam:any = ''
  canEdit:any = true

  // booleans
  showCustomerForm:boolean = false
  showAfterSettings:boolean = false
  showPaymentForm:boolean = false

  storeSettings: any = [];
  defaultTax:any = 0;
  payments:any = []
  now=new Date();

  corporate:any={};
  address:any={};

  role = this.authService.decodeToken('role').role;
  opensCR:any = [];
  CRID:any = '';
  CRNAME:any = '';

    
  //knowinf thr device  
  platform = '';
  showMobile = false;
  isIOS = false;
  isAndroid = false;

  //stimulsoft
  viewer :any;
  report :any;


  so:any = {
  }
  paymentsJSON=[];
  showSearch:boolean=false;
  editmode:boolean=false;
  words: any = {};
  constructor(
    private settingsService:SettingsService,
    private alertify:AlertifyService,
    private authService:AuthService,
    private global:GlobalsService,
    private salesSerivce:SalesOrderService,
    private route:ActivatedRoute,
    private router:Router,
    public bsModalRef: BsModalRef,
		private modalService: BsModalService,
    private addToSo: SearchToSOService,
    private defaultSvc:DefaultService,
		private translate: TranslateService
  ) { }

  dbServerC = this.authService.decodeToken('dbServerC')?.dbServerC;
  dbServer = this.authService.decodeToken('dbServer')?.dbServer;
  user = this.authService.decodeToken('user')?.user;


  changeShowPaymentForm(){
    if(this.so.Total > 0){
      this.showPaymentForm = true
    }else{
      this.alertify.warning(this.translate.instant('INVALID_TOTAL_WARNING'))
    }
  }

  ngOnInit(): void {

    this.platform = navigator.userAgent;
    if (this.platform.match(/Android/i)) {
      this.showMobile = true;
      this.isAndroid = true;
    }
    if (this.platform.match(/iPhone|iPad|iPod/i)) {
      this.showMobile = true;
      this.isIOS = true;
    }
    if (this.platform.includes('Mac')) {
      this.showMobile = false;
    }

    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
      this.isIOS = true;
    }



    // this.getCR()
    this.idparam = this.route.snapshot.params.id;
    

    // guardar esta respuesta en localStorage, ya que solo se necesita una vez
    const infoCorp = localStorage.getItem('infoCorp');  
    if (infoCorp != null || infoCorp != undefined) { 
      const res = JSON.parse(localStorage.getItem('infoCorp'))
      this.corporate=res.corp;
      this.address=res.info; 
    }else{
      this.salesSerivce.getInfoStore({dbserverC:this.dbServerC,dbserver:this.dbServer})
      .pipe(takeUntil(this.destroy$))   
      .subscribe(res => {
        localStorage.setItem('infoCorp',JSON.stringify(res))
        this.corporate=res.corp;
        this.address=res.info; 
      }, error => {
        console.log(error);        
      });
    }


    if(this.idparam != '' && this.idparam != null && this.idparam != undefined){
      
      this.salesSerivce.getSalesOrder(this.idparam)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(res => {

        //bloqueo todo si esta en edit, solo se podra imprimir el recibo
        this.canEdit = false;
                
        this.so = res.SalesOrder
        this.so.payments = res.Payments 
        this.payments = res.Payments 
        this.so.soItems = res.soItems

        //Devices
        if(res['Devices'].length > 0){
          this.opensCR = res['Devices']
          this.CRID = res['Devices'][0]['deviceId'];
          this.CRNAME = res['Devices'][0]['deviceName'];
        }        

        this.payments.forEach((element) => {
          if (element.paymentType == 'Credit Card') {
            const x = JSON.parse(element.JSONresponse);
            this.paymentsJSON.push(x);      
          }
          else{
            this.paymentsJSON.push({});
          }
        });

        // load customer
        if(res.SalesOrder.customerId != '' && res.SalesOrder.customerId != null && res.SalesOrder.customerId != undefined){
          let jsonCus =  {
            customerId:res.SalesOrder.customerId,
            firstName:res.SalesOrder.firstName,
            lastName:res.SalesOrder.lastName,
            billingAddress1:res.SalesOrder.billingAddress1,
            city:res.SalesOrder.city,

            state:res.SalesOrder.state,
            zip:res.SalesOrder.zip,
            phone1:res.SalesOrder.phone1,
            email:res.SalesOrder.email
          }

          this.so.customer = jsonCus;
          this.showCustomerForm = true
        }       

        //Settings
        this.storeSettings = res.Settings;   
        this.so.taxNumber = res.Settings['taxes'] != null && res.Settings['taxes'] != '' ? (JSON.parse(res.Settings['taxes']).defaultTaxes) : 0
        this.showAfterSettings = true
        this.showPaymentForm = true

      }, error => {
        console.log(error);        
      });

    // fin de editar
    }else{

      this.initSalesOrder(); 

    }

  }
  
  searchProducts() {
    this.addToSo.changeMessage('');
		this.bsModalRef = this.modalService.show(SearchProdsComponent, {
			class: 'ModalClass',
			backdrop: true,
			ignoreBackdropClick: true
		});

	}

  showSearchs(value:any){
    this.showSearch=value;
  }

 

  initSalesOrder() {

    this.so['SOID'] = this.global.guid();
    this.so['customer'] = "";
    this.so['identifierBackUp'] = this.global.guid(); 
    this.so['created_at'] = this.global.dNow();
    this.so['updated_at'] = this.global.dNow();
    this.so['dbDatets'] = this.global.dNow();

    this.so['status'] = 'Draft';
    this.so['SONumber'] = '';
    this.so['soItems'] = [];
    //totals
    this.so['subTotalOrderSum'] = 0;
    this.so['Total'] = 0;
    this.so['totalPaymentsNum'] = 0;
    this.so['discountTotalPostNum'] = 0;
    this.so['Change_num'] = 0;


    let username = this.authService.decodeToken('user').user
    username = JSON.parse(username)['name'];
    if (username === undefined) {
      username = this.authService.decodedToken.unique_name;
    }
    this.so['salesPerson'] = username;

    //con este obtengo los Settings, Devices y Draft Order
    this.salesSerivce.getDraftNumber()
    .pipe(takeUntil(this.destroy$))   
      .subscribe((data:any) => {
        

        //Settings
        this.storeSettings = data.Settings;   
        this.so.taxNumber = (data['Settings']['taxes'] != null && data['Settings']['taxes'] != '') ? (JSON.parse(data['Settings']['taxes']).defaultTaxes):0
        this.showAfterSettings = true

        //Devices
        if(data['Devices'].length > 0){
          this.opensCR = data['Devices']
          this.CRID = data['Devices'][0]['deviceId'];
          this.CRNAME = data['Devices'][0]['deviceName'];
        }  

        //DraftNumber
        if (data['draftnum'][0] !== undefined) {
          const draftnumber = data['draftnum'][0]['DraftNum'];
          this.so['DraftNum'] = parseInt(draftnumber.toString(), 0) + 1;
          //console.log(this.so['DraftNum']);          
        }else{
          this.so['DraftNum'] = 1;
        }
      }, error => {
        this.alertify.error(error);
      });
  }

    

 

  updateCustSO(event:any){

    this.so.customer = event
    if(event != ''){
      this.so.customerId = event.Id 
      this.so.firstName = event.firstName    
      this.so.lastName = event.lastName    
      this.so.billingAddress1 = event.billingAddress1    
      this.so.city = event.city    
      this.so.state = event.state    
      this.so.zip = event.zip    
      this.so.phone1 = event.phone1 
      this.so.email = event.email
    }else{
      this.so.customerId = null
      this.so.firstName = null    
      this.so.lastName = null
      this.so.billingAddress1 = null
      this.so.city = null
      this.so.state = null
      this.so.zip = null
      this.so.phone1 = null
      this.so.email = null
    } 
    if(this.idparam != '' && this.idparam != null && this.idparam != undefined && this.so.customerId != '' && this.so.customerId != null && this.so.customerId != undefined ){
      // solo actualizar los campos de SO
      let json = {
        SOID:this.so.SOID,
        customerId:this.so.customerId,
        firstName:this.so.firstName,
        lastName:this.so.lastName,
        billingAddress1:this.so.billingAddress1,
        city:this.so.city,
        state:this.so.state,
        zip:this.so.zip,
        phone1:this.so.phone1,
        email:this.so.email
      }
      this.salesSerivce.updateCustomerOnSO(json)
      .pipe(takeUntil(this.destroy$))    
      .subscribe(res => {     
        //console.log('customer updated on SO');        
      }, error => {
        console.log(error);      
      });
    }

  }

  cancelAddCustSO(event:any){
    this.showCustomerForm = false
    if(this.idparam != '' && this.idparam != null && this.idparam != undefined){
      // solo actualizar los campos de SO
      let json = {
        SOID:this.so.SOID,
        customerId:null,
        firstName:null,
        lastName:null,
        billingAddress1:null,
        city:null,
        state:null,
        zip:null,
        phone1:null,
        email:null
      }
      this.salesSerivce.updateCustomerOnSO(json)
      .pipe(takeUntil(this.destroy$))    
      .subscribe(res => {     
        //console.log('customer updated on SO');        
      }, error => {
        console.log(error);      
      });
    }
  }
  getProductsForm(event:any){
    this.so.soItems = event;    
  }
  
  totalsfromItemsToSO(event:any){    
    this.so.subTotalOrderSum = event.subTotalOrderSum
    this.so.totalTaxNum = event.totalTaxNum
    this.so.Total = event.Total
    this.so.Change_num = event.Total - this.so.totalPaymentsNum
    this.so = JSON.parse(JSON.stringify(this.so))
  }

  updatePaymentOnSO(event:any){
    
    let totalPaid:any = 0;

    event.forEach(element => {
      totalPaid = totalPaid + this.global.pNumber(element.paymentAmount);
    });
    
    this.so.totalPaymentsNum = totalPaid;
    this.so.Change_num = this.so.Total - totalPaid
    this.payments = event

    if(event.length > 0){

      if(this.so.Change_num <= 0){
        if(this.idparam != '' && this.idparam != null && this.idparam != undefined){          
          this.updateSO();
        }else{        
          this.assingSONumber()
        }
      }

    }
  }

  assingSONumber() {
    this.salesSerivce.getSONumber(this.so['SOID'])
    .pipe(takeUntil(this.destroy$))   
    .subscribe(res => {
      this.so['SONumber'] = res[0]['SOnumber'];         
      this.saveSO();          
    });   
  }

  saveSO(){ 
    console.log(JSON.parse(this.authService.decodeToken('user').user).uuid);
    this.so.cashRegisterId = this.CRID; 
    // return 
    const so = Object.assign({}, this.so);
    so['created_at'] = this.global.dNow();
    so['createdBy'] = this.so['salesPerson'];
    so['createUserId'] = this.authService.decodeToken('role').role == 'Dealer' ? 'Dealer':JSON.parse(this.authService.decodeToken('user').user).uuid;
  
    if (so['soItems']) {
      for (const value of so['soItems']) {
        value['identifierBackUp'] = this.so['identifierBackUp'];
        value['created_at'] = this.global.dNow();  
        value['createdBy'] = this.so['salesPerson']; 
        value['foreignSOID'] = so['SOID'];
        value['lotesIds'] = value['lotesIds'];
      }
    }
  
    if (this.payments) {
      for (const value of this.payments) {
        value['identifierBackUp'] = this.so['identifierBackUp'];
        value['created_at'] = this.global.dNow();
        value['createdBy'] = this.so['salesPerson'];
        value['foreignSOID'] = so['SOID']
      }
    }

    if(so['Change_num'] <= 0){
      so['status'] = 'PIF';
    }else{
      so['status'] = 'Order';
    }

    so['payments'] = JSON.parse(JSON.stringify(this.payments))

    this.salesSerivce.addSalesOrder(so)
    .pipe(takeUntil(this.destroy$))   
    .subscribe(res => {   

      this.so.payments = JSON.parse(JSON.stringify(this.payments))  
      this.alertify.success(this.translate.instant('SALES_ORDER_ADDED'));
      this.router.navigate(['/salesorder/' + res['SOID']]);           

    }, error => {
      this.alertify.error(error);
    });  
  }



  updateSO(){
    const so =  JSON.parse(JSON.stringify(this.so));

    let identifierBackUp = this.global.guid()
    let modifyBy = this.authService.decodeToken('username').username;
    let date = this.global.dNow();

    so['identifierBackUp'] = identifierBackUp; 
    so['updated_at'] = date
    so['modifiedBy'] = modifyBy

    if(so['soItems'] != undefined){  
      for (const value of so['soItems']) {
        value['identifierBackUp'] = identifierBackUp; 
        value['updated_at'] = date
        value['modifiedBy'] = modifyBy
        value['foreignSOID'] = so['SOID']
      }
    }

    if(this.payments){ 
      for (const value of this.payments) {
        value['identifierBackUp'] = identifierBackUp; 
        value['updated_at'] = date
        value['modifiedBy'] = modifyBy
        value['foreignSOID'] = so['SOID']
      }
    }

    if(so['Change_num'] <= 0){
      so['status'] = 'PIF';
    }else{
      so['status'] = 'Order';
    }

    so['payments'] = JSON.parse(JSON.stringify(this.payments))

    this.salesSerivce.updateSalesOrder(so['SOID'], so)
    .pipe(takeUntil(this.destroy$))    
    .subscribe(res => {     
      this.alertify.success(this.translate.instant('SALES_ORDER_UPDATED')); 
    }, error => {
    });
  }
  



async onPrint() {
  let language = this.storeSettings.language;
  // para mostrar los labels traducidos
  this.translate.getTranslation(language).subscribe((translations: any) => {
    this.words = {
      subtotal: translations['SUBTOTAL'].toUpperCase(),
      tax: translations['TAX'].toUpperCase(),
      totales: translations['TOTAL'].toUpperCase(),
      payment: translations['PAYMENT'].toUpperCase(),
      change: translations['CHANGE'].toUpperCase(),
      price: translations['PRICE'],
      product: translations['PRODUCT'],
      items_Sold: translations['ITEMS_SOLD'].toUpperCase(),
      totalPurchase: translations['TOTAL_PURCHASE'].toUpperCase(),
      transaction: translations['TRANSACTION'].toUpperCase(),
      no_signature_required: translations['NO_SIGNATURE_REQUIRED'].toUpperCase(),
    };
  });

    this.now=new Date();
    const options: Intl.DateTimeFormatOptions = { 
      weekday: 'short', 
      year: 'numeric', 
      month: 'short', 
      day: 'numeric', 
      hour: '2-digit', 
      minute: '2-digit', 
      second: '2-digit' 
    };
    const str = this.now.toLocaleDateString('en-US', options);
    const jsondate = str.substring(0, str.lastIndexOf(" ") + 1).trim();  
    
    let pays = [];
    this.so.payments.forEach( ps => {
      if (ps['paymentType'] != 'Credit Card') {
        ps['paymentType'] = ps['paymentType'].toUpperCase();
        this.translate.get(ps['paymentType']).subscribe((translatedPaymentType: string) => {
          ps['paymentType'] = translatedPaymentType;
        });
        pays.push(ps)
      }else{
        let json = JSON.parse(ps['JSONresponse']);
        ps['paymentType'] = ps['paymentType'].toUpperCase();
        ps['referenceId'] = json['payment']['cardTransaction']['referenceId'];
        ps['transactionNo'] = json['payment']['cardTransaction']['transactionNo'];
        ps['applicationIdentifier'] = json['payment']['cardTransaction']['extra']['applicationIdentifier'];
        ps['cvmResult'] =  json['payment']['cardTransaction']['extra']['cvmResult'];

        this.translate.get(ps['paymentType']).subscribe((translatedPaymentType: string) => {
          ps['paymentType'] = translatedPaymentType;
        });

        ps['msgSig'] = ps['cvmResult'] == 'NO_CVM_REQUIRED'? 'NO_SIGNATURE_REQUIRED':'';

        this.translate.get(ps['msgSig']).subscribe((translatedMsgSig: string) => {
          ps['msgSig'] = translatedMsgSig;
        });

        ps['SIGformat'] = json?.signature?.signature?.format ?? '';
        ps['SIGdata'] = json?.signature?.signature?.data ?? '';
        pays.push(ps);
      }
    });

    let json = {
      SONumber:this.so.SONumber,
      header: this.storeSettings.invoiceHeader.toUpperCase(),
      footer: this.storeSettings.invoiceFooter.toUpperCase(),
      logo:this.corporate.logo,
      companyName:this.corporate.companyName.toUpperCase(),
      phone:this.address.phone.slice(0,4)+'-'+this.address.phone.slice(4,7)+'-'+this.address.phone.slice(7,10),
      name:this.address.name.toUpperCase(),
      address:this.address.address.toUpperCase(),
      address2:this.address.address2.toUpperCase(),
      city:this.address.city.toUpperCase(),
      state:this.address.state.toUpperCase(),
      zip:(this.address.zip != null ?this.address.zip:'').toUpperCase(),

      firstName: (this.so.firstName) ? this.so.firstName.toUpperCase() : '',
      lastName: (this.so.lastName) ? this.so.lastName.toUpperCase() : '',
      phone1: (this.so.phone1)?this.so.phone1.slice(0,4) + '-' + this.so.phone1.slice(4,7) + '-' + this.so.phone1.slice(7,10):'',
      email: (this.so.email) ? this.so.email.toUpperCase() : '',
      billingAddress1: (this.so.billingAddress1) ? this.so.billingAddress1.toUpperCase() : '',      
      cityC: (this.so.city) ? this.so.city.toUpperCase() : '',
      stateC: (this.so.state) ? this.so.state.toUpperCase() : '',
      zipC: (this.so.zip) ? this.so.zip.toUpperCase() : '',

      items:this.so.soItems,
      itemsSold:this.so.soItems.length,

      subTotalOrderSum:(this.so.subTotalOrderSum),
      totalTaxNum:this.so.totalTaxNum,
      Total:this.so.Total,
      totalPaymentsNum:this.so.totalPaymentsNum,
      Change_num:this.so.Change_num,

      // para mostrar los labels traducidos 
      
      subtotal:this.words.subtotal,
      tax:this.words.tax,
      totales:this.words.totales,
      payment:this.words.payment,
      change:this.words.change,
      price:this.words.price,
      product:this.words.product,
      items_Sold:this.words.items_Sold,
      totalPurchase:this.words.totalPurchase,
      transaction:this.words.transaction,

      //PARA MOSTRAR EL TIPO DE MONEDA
      currency:this.storeSettings.currency,

      payments:pays,
      now:jsondate
    };

    // STIMULSOFT
    let wait = await this.global.loadScript();
    if(wait){
      this.viewer = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
      this.report = new Stimulsoft.Report.StiReport();
      this.report.loadFile('reports/receipt.mrt');
      this.report.dictionary.databases.clear();
      const dssalesprice = new Stimulsoft.System.Data.DataSet();            
      dssalesprice.readJson(json);
      this.report.regData('salesprice', null, dssalesprice);  

      if(this.isAndroid){ 
        this.getbase64PDF().pipe(take(1)).
        subscribe(async res => {
          const byteCharacters = atob(res);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          } 
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {type: 'application/pdf'});
          var fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl);
        });     

      }else if(this.isIOS){

        this.getbase64PDF().pipe(take(1)).
        subscribe(async res => {
          if(iswebKit == "isWebKit"){
            // if new aplication
            (document.getElementById('base64pdf') as HTMLInputElement).value = res;
            (document.getElementById('sendenbase64') as HTMLInputElement).click(); 
            setTimeout(() => {           
            (document.getElementById('base64pdf') as HTMLInputElement).value = "";
            }, 1000);
          }else{
          // if old aplication
          window.location.href = "APPReport://data:application/pdf;base64," + res;
          }
        }); 
      }else {
       
        this.getbase64PDF().pipe(take(1)).
        subscribe(async res => {
          if (window.require) {
            let found=0;
            pays.forEach(element => {
              if (element.paymentType != 'CASH' ) {
                found=1;
              }
            });
            const method=(found==0)?'Open':'NoOpen'
            const { ipcRenderer } = window.require('electron'); 
            const obj = {
              res,
              method
            }
            ipcRenderer.send('printer-settings', obj);    
            console.log('aqui');
                  
          } else {
            
            this.report.render();
            this.report.print(false); 
          }       
        }); 
      }

      // PRINT BLADE
      // this.salesSerivce.getBase64Receipt(json)
      // .pipe(takeUntil(this.destroy$))    
      // .subscribe((res:any)  => { 
        
      //   if(this.isAndroid){       
      //     const byteCharacters = atob(res);
      //     const byteNumbers = new Array(byteCharacters.length);
      //     for (let i = 0; i < byteCharacters.length; i++) {
      //       byteNumbers[i] = byteCharacters.charCodeAt(i);
      //     } 
      //     const byteArray = new Uint8Array(byteNumbers);
      //     const blob = new Blob([byteArray], {type: 'application/pdf'});
      //     var fileUrl = URL.createObjectURL(blob);
      //     window.open(fileUrl);
      //   }else if(this.isIOS){
      //     if(iswebKit == "isWebKit"){
      //       // if new aplication
      //       (document.getElementById('base64pdf') as HTMLInputElement).value = res;
      //       (document.getElementById('sendenbase64') as HTMLInputElement).click(); 
      //       setTimeout(() => {           
      //       (document.getElementById('base64pdf') as HTMLInputElement).value = "";
      //       }, 1000);
      //     }else{
      //     // if old aplication
      //     window.location.href = "APPReport://data:application/pdf;base64," + res;
      //     }
      //   }else {
      //     // probaremos en electron
      //     if (window.require) {
      //       const { ipcRenderer } = window.require('electron'); 
      //       ipcRenderer.send('mostrar-vista-previa', res);          
      //     } else {
      //       console.log('browser')
      //       printJS({ printable: res, type: "pdf", base64: true }); 
      //     }       
      //   }
      // });


      // PRINT HTML
      // const printContents = this.printSectionElement.nativeElement.innerHTML;
      // const popupWindow = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
      // popupWindow.document.open();
      // popupWindow.document.write(`
      //   <html>
      //     <head>
      //       <style>
      //         @media print {
      //           @page {
      //             margin: 0;
      //             size: auto;
      //           }
      //           body {
      //             font-family: sans-serif;
      //             margin: 0;
      //           }
      //           .padY{

      //           }
                
      //         }
      //       </style>
      //     </head>
      //     <body onload="window.print();window.close()">
      //       ${printContents}
      //     </body>
      //   </html>`
      // );
      // popupWindow.document.close();
    }


}

getbase64PDF() : Observable<any> {
  
  const base64PDF = new Observable(observer => {     
    let att:any
    this.report.renderAsync(async () => {
      await new Promise(resolve => {
        setTimeout(
          () => {           
        const settingsrep = new Stimulsoft.Report.Export.StiPdfExportSettings();
        const service = new Stimulsoft.Report.Export.StiPdfExportService();
        const stream = new Stimulsoft.System.IO.MemoryStream(); 

        this.report.exportDocumentAsync((pdfData: any) => { 
        service.exportToAsync(function () {  
          
        const datastrea = stream.toArray();
        
        att = Stimulsoft.System.Convert.toBase64String(datastrea);       
        observer.next(att);

      },this.report, stream, settingsrep);
           
      },Stimulsoft.Report.StiExportFormat.Pdf);             
        resolve(''); 
       } , 2
      );     

      });
  });
  });
  
  return base64PDF;  
}

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}

