import { Injectable, OnDestroy } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CustomerFormComponent } from '../components/customer/customer-form/customer-form.component';
import { AlertifyService } from '../_services/alertify.service';
import { SalesOrderService } from '../_services/salesorder.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PreventUnsavedChangesCustomer implements CanDeactivate<CustomerFormComponent> {
  yo:boolean = true;
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    private alertify: AlertifyService,
    private salesService:SalesOrderService,
		private translate: TranslateService, 
    ) {}

  async canDeactivate(component: CustomerFormComponent) {
    // console.log(component.customerForm)
   if(component.customerForm.dirty){
     await new Promise((resolve,reject)=>{
    this.alertify.confirm(this.translate.instant('UNSAVED_CHANGES_MESSAGE'),
            ()=>{
              resolve( this.yo = true )
            }
            ,()=>{
              // this.salesService.deleteSOsAutoSaved('id')
              // .pipe(takeUntil(this.destroy$)) 
              // .subscribe(
              //   (res)=>{                      
              // },
              // (err)=>{console.log(err)}
              // )
            }
          );
          setTimeout(() => {  
            reject(this.yo = false) 
          }, 10000)
      });
   }else{
     this.yo = true
   }

    return this.yo; 

  }

  
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


}
