<div class="col-md-12 p-0">
  <form #orderForm="ngForm">
    <select
      [(ngModel)]="selectedManMod"
      name="manufacturer"
      required
      class="custom-select mb-2"
      id="companyselect"
      onchange=""
    >
      <option disabled value="" selected>Choose Manufacturer</option>
      <option [ngValue]="man?.SID" *ngFor="let man of manufacturers">{{
        man?.companyName
      }}</option> </select
    >
    <select
      [(ngModel)]="selectedStyleMod"
      name="style"
      class="custom-select mb-3"
      id="styleselect"
      onchange=""
    >
      <option disabled value="" selected>Choose Style</option>
      <option [ngValue]="sty?.styleId" *ngFor="let sty of styles">{{
        sty?.styleName
      }}</option> 
    </select>
    <div class="d-flex">
      <button
      type="button"
      [disabled]="!orderForm.valid"
      class="btn btn-3 mr-2"
      (click)="addStoreItems()"
      >
        <span class="material-icons"> add </span> Add to Store Items
      </button>
      <button
        class="btn btn-3"
        [disabled]="!orderForm.valid"
        (click)="clearFilter()"
      >
        Clear All
      </button>
    </div>
  </form>

  <p class="mt-2 mb-2">Items:<strong>{{ totalStyles }}</strong></p> 

  <!-- <div class="customer-content">
    <div class="customer-list-container">
      <div class="customer-list-container-header">
        <div class="customer-list-container-header-type"><h5>Type</h5></div>
        <div class="customer-list-container-header-size"><h5>Size</h5></div>
        <div class="customer-list-container-header-product"><h5>product</h5></div>
        <div class="customer-list-container-header-whole"><h5>Whole Sale</h5></div>
      </div>
      <div *ngFor="let si of styleItems" 
        class="customer-list-container-records catalog">
        <div class="customer-list-container-records-item">

          <div class="customer-list-container-records-item-type">
            <p class="m-0">{{ si.Type }}</p>
          </div>

          <div class="customer-list-container-records-item-size">
            <p class="m-0"> {{ si.Size }} <br /> SKU: {{ si.VITEMID }}</p>
          </div>

          <div class="customer-list-container-records-item-product">
            <p class="m-0">
              {{ si.product }}
            </p>
          </div>
          <div class="customer-list-container-records-item-whole">
            <p class="m-0">
              Qty per set: {{ si.minQty }}
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>  -->
  <div class="customer-content">
    <div class="table-responsive">
      <table id="salestable" class="table table-light table-custom store-catalog-table" cellspacing="0">
        <thead class="table-custom-header">
          <tr>
            <th>Type</th>
            <th>Size</th>
            <th>Product</th>
            <th *ngIf="userRole!=='Manager'" >Whole Sale</th>
          </tr>
        </thead>
        <tbody>
          <tr class="m-1 p-1" *ngFor="let si of styleItems">
            <td>{{ si.Type }}</td>
            <td>{{ si.Size }} <br /> SKU: {{ si.VITEMID }}</td>
            <td>{{ si.product }}</td>
            <td>
              <span *ngIf="userRole!=='Manager'" >
                {{si.Price | number}}
              </span>
              <span *ngIf="si.minQty > 1">Qty per set: {{ si.minQty }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="pagination-container" *ngIf="styleItems.length > 0">
  <pagination
    [boundaryLinks]="true"
    [totalItems]="pagination.totalItems"
    [(ngModel)]="pagination.currentPage"
    [itemsPerPage]="pagination.itemsPerPage"
    (pageChanged)="pageChanged($event)"
    [maxSize]="4"
    previousText="&lsaquo;"
    nextText="&rsaquo;"
    firstText="&laquo;"
    lastText="&raquo;"
  >
  </pagination>
</div>