<div  style="background-color: #fafafa;" class="tw-p-4">

<div class="tw-flex">
    <span class="tw-my-auto tw-text-2xl tw-font-bold tw-text-aedgraydark">
        {{ 'TOTALS' | translate }}
    </span>
</div>

<div class="tw-grid tw-grid-cols-5">
    <div class="tw-col-span-1"></div>
    <div class="tw-col-span-4">

        <div class="tw-grid tw-grid-cols-4 tw-py-1">
            <div class="tw-col-span-1 tw-text-end tw-text-aedblue tw-flex">
                <span class="tw-my-auto tw-ml-auto" style="font-size: 1rem;">{{ 'SUBTOTAL' | translate }}</span> 
            </div>
            <div class="tw-col-span-3 tw-text-end tw-text-aedgraydark tw-text-2xl">{{subTotal | currency}}</div>
        </div>

        <hr>
        
        <div class="tw-grid tw-grid-cols-4 tw-py-1">
            <div class="tw-col-span-1 tw-text-end tw-text-aedblue tw-flex">
                <span class="tw-my-auto tw-ml-auto" style="font-size: 1rem;"> {{ 'TAX' | translate }}</span> 
            </div>
            <div class="tw-col-span-3 tw-text-end tw-text-aedgraydark tw-text-2xl">{{tax | currency}}</div>
        </div>

        <hr>

        <div class="tw-grid tw-grid-cols-4 tw-py-1">
            <div class="tw-col-span-1 tw-text-end tw-text-aedblue tw-flex tw-font-bold" >
                <span class="tw-my-auto tw-ml-auto" style="font-size: 1rem;">{{ 'TOTAL' | translate }}</span> 
            </div>
            <div class="tw-col-span-3 tw-text-end tw-text-aedgraydark tw-text-3xl tw-font-bold">{{total | currency}}</div>
        </div>

        <hr>

        <div class="tw-grid tw-grid-cols-4 tw-py-1">
            <div class="tw-col-span-1 tw-text-end tw-text-aedblue tw-flex">
                <span class="tw-my-auto tw-ml-auto" style="font-size: 1rem;" >{{ 'PAYMENT' | translate }}</span> 
            </div>
            <div class="tw-col-span-3 tw-text-end tw-text-aedgraydark tw-text-2xl">{{payments | currency}}</div>
        </div>

        <hr *ngIf="balance != 0">

        <div *ngIf="balance != 0" class="tw-grid tw-grid-cols-4 tw-py-1">
            <div class="tw-col-span-1 tw-text-end tw-text-aedblue  tw-flex">
                <span class="tw-my-auto tw-ml-auto" style="font-size: 1rem;">{{balance > 0?'Balance':('CHANGE' | translate)}}</span> 
            </div>
            <div class="tw-col-span-3 tw-text-end tw-text-aedgraydark tw-text-2xl">{{balance | currency}}</div>
        </div>

                   
       
    </div>
</div>

</div>