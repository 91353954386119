<div class="container d-flex flex-column p-3">
    <div class="d-flex flex-colum justify-content-between">
        <button type="button" class="close pull-right ml-2 mr-2" aria-label="Close" (click)="hideModal()">
            X
        </button>
    </div>

    <div class="mx-8 d-flex flex-column px-10">
        <h4 class="text-center mx-3 h4">Choose a Size</h4>
        <h6 class="text-center mx-3 h6">{{itemSelected?.Manufacturer}}</h6>
        <h6 class="text-center mx-3 h6">{{itemSelected?.name}}</h6>
        <h6 class="text-center mx-3 mb-3 h6">{{itemSelected?.product}}</h6>
        <div *ngFor="let item of options">
            <div class="row my-2 mx-5" (click)="submitItem(item)">
                <div *ngIf="itemSelected.productsId == item.productsId" class="col-8 fs-3 fw-bold" style="cursor:pointer;" >
                    {{item?.sizeName}} (Actual size)
                </div>
                <div *ngIf="itemSelected.productsId != item.productsId" class="col-8 fs-3 fw-bold" style="cursor:pointer;" >
                    {{item?.sizeName}}
                </div>
                <div class="col-4">
                  <p *ngIf="item.salesPriceNum > 0" class="fs-3 fw-bold" style="cursor:pointer; font-weight: 300;">
                    $ {{item?.salesPriceNum  | number: "1.2-2"}}
                  </p>
                  <p *ngIf="item.salesPriceNum == 0" style="cursor:pointer; font-weight: 100;">
                    $ 0.00
                  </p>
                   
                </div>
              </div>
        </div>
    </div>
</div>