<div class="modal-content tw-bg-aedblueback">

  <div class="tw-justify-between tw-flex tw-p-8">  

    <div class="tw-font-bold tw-text-xl tw-flex"> <span class="material-icons"> add </span> Add Items</div>
    <div><app-closebutton></app-closebutton></div>    
  </div>


    <div class="tabs-links half tw-mx-8" style="cursor: pointer;">
      <div class="tw-w-1/2 tw-text-center tw-text-xl tw-py-2 tw-font-bold" [ngClass]="{ 'tw-bg-white tw-text-aedblue': show === 'Store', 'tw-bg-aedblueback': show !== 'Store' }" (click)="showStoreProducts()">
        Store Products
      </div>
      <div class="tw-w-1/2 tw-text-center tw-text-xl tw-py-2  tw-font-bold" [ngClass]="{ 'tw-bg-white tw-text-aedblue': show === 'Stock', 'tw-bg-aedblueback': show !== 'Stock'}" (click)="showStockItems()">
        Stock Items
      </div>
    </div>



    <div class="tw-flex tw-bg-white tw-justify-between tw-mx-8 tw-mb-8" style="min-height: calc(100vh - 150px)">
      <div style="width:-webkit-fill-available;" *ngIf="show == 'Store'">
        <app-storeproducts [salesOrderMode]="true" [itemsPerPage]="30" > </app-storeproducts>
        <!-- <app-storeproductsmobile class="storeproduct-mobile" [salesOrderMode]="true" [itemsPerPage]="30" > </app-storeproductsmobile> -->
      </div>
      <div style="width:-webkit-fill-available;" *ngIf="show == 'Stock'">
        <app-stockitemslist class="" [salesOrderMode]="true"> </app-stockitemslist>
        <!-- <app-stockitemslistmobile class="stockitem-mobile w-100" [salesOrderMode]="true"> </app-stockitemslistmobile> -->
      </div>
    </div>

</div>
