
        <button class="btn btn-outline-primary z-depth-1" (click)="addDevice()"> <i class="fa fa-plus-circle fa-lg"></i>
            Add Card Reader
            </button>
          
          <table  class="table table-light  table-sm mt-2 z-depth-1" cellspacing="0">
              <thead><tr>
              <th>Label</th>
              <th>Device</th>
              <th></th>
              </tr></thead>
              <tbody>
                <tr *ngFor="let dev of devices" class=" m-1 p-1 ">
                    <td>{{dev.Label_tx}}</td>
                    <td>{{dev.MacReader_tx}}
                    </td>
                    <td>
                        <button class="btn btn-outline-primary btn-sm m-0"><i class="fa fa-refresh"></i></button>        

                        <button class="btn btn-outline-danger btn-sm m-0"
                        (click)="deleteDevice(dev);" type="button"><i class="fa fa-trash"></i>
                        </button>

                        <button class="btn btn-outline-success btn-sm m-0"
                        (click)="selectDevice(dev);" type="button"><i class="fa fa-check"></i>
                        </button>
                    </td>
              </tr>
            </tbody></table>

