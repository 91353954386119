import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { DefaultService } from 'src/app/_services/default.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
@Component({
  selector: 'app-solist',
  templateUrl: './solist.component.html',
  styleUrls: ['./solist.component.scss']
})
export class SolistComponent implements OnInit,AfterViewInit {

  destroy$: Subject<boolean> = new Subject<boolean>();
  @ViewChild('inputDate')  inputDate: ElementRef | any;
  role = this.authService.decodeToken('role').role
  p:any = 1
  salesorders:any = []
  
  opensCR:any = []
  CRID:any = '';
  CRNAME:any = '';

  showWarning:any = false
  messageWarning:any = ''

  
  
  constructor(
    private alertify:AlertifyService,
    private authService:AuthService,
    private salesService:SalesOrderService,
    private defaultService:DefaultService,
    private router:Router,
		private translate: TranslateService,
    ) { }

  ngOnInit(): void {
    this.getDevices()
  }

  getDevices(){
    this.defaultService.getDevicesPOS({from:'SOList',userId: this.role == 'Dealer' || this.role == 'Admin' ?'Dealer':JSON.parse(this.authService.decodeToken('user').user).uuid})
    .pipe(takeUntil(this.destroy$))   
    .subscribe( (data:any) => {
      this.opensCR = data.opensCR;
      this.messageWarning = this.role == 'Dealer' || this.role == 'Admin' ? 
      this.translate.instant('NO_CASH_REGISTER_OPEN') :
      this.translate.instant('NO_CASH_REGISTER_ASSIGNED');
      this.showWarning = this.opensCR.length == 0 ? true : false
      
    }, error => {
      this.alertify.error(error);
    });
  }


  addSalesOrder(){
    this.router.navigate(['/salesorder/add']);
  }

  ngAfterViewInit() {
    // get orders today
    const currentDate: Date = new Date();
    const year: number = currentDate.getFullYear();
    const month: any = currentDate.getMonth() + 1; // Los meses comienzan desde 0 (enero), por lo que sumamos 1
    const day: any = currentDate.getDate();  
    const date = year+'-'+(month < 10?'0'+month:month)+'-'+(day <10?'0'+day:day) 
    this.inputDate.nativeElement.value = date;

    this.getOrderDay(date)
  }

  transfotDate(date){
    const currentDate: Date = new Date(date);
    const year: number = currentDate.getFullYear();
    const month: any = currentDate.getMonth() + 1; // Los meses comienzan desde 0 (enero), por lo que sumamos 1
    const day: any = currentDate.getDate() + 1;  
    const date2 = (month < 10?'0'+month:month)+'/'+(day <10?'0'+day:day)+'/'+year 
    return date2;
  }

  getOrderDay(date){
    this.salesService.getSalesOrderDay(date)
    .pipe(takeUntil(this.destroy$))   
    .subscribe( data => {
      this.p = 1
      this.salesorders = data;
    }, error => {
      this.alertify.error(error);
    });
  }

  changeInputDate(event:any){
    this.getOrderDay(event.target.value)
  }

  selectSalesOrder(salesorder: any) { 
    this.router.navigate(['/salesorder/' + salesorder['SOID']]);
  }

  voidSO(so){
    this.alertify.confirm(this.translate.instant('DELETE_ORDER_CONFIRM')+so.SONumber+this.translate.instant('WARNING_RETURN_ORDER'),
    ()=>{
      this.salesService.voidSalesOrder(so['SOID'])
      .pipe(takeUntil(this.destroy$))   
      .subscribe( data => {
        let index = this.salesorders.findIndex((x:any)=>x.SOID == so['SOID'])
        if(index != -1){
          this.salesorders[index].status = 'VOID';
        }
      }, error => {
        console.log(error);      
      });
    },
    ()=>{})

  }

  restartSO(so){
    this.alertify.confirm(this.translate.instant('RESTORE_ORDER_CONFIRM')+so.SONumber+this.translate.instant('WARNING_REMOVED_ORDER'),
    ()=>{
      this.salesService.restartSO(so['SOID'])
      .pipe(takeUntil(this.destroy$))   
      .subscribe( data => {
        let index = this.salesorders.findIndex((x:any)=>x.SOID == so['SOID'])
        if(index != -1){
          this.salesorders[index].status = 'PIF';
        }
      }, error => {
        console.log(error);      
      });
    },
    ()=>{})
  }

}