<div  class="sidebar-1-content-account2" style="width: 160px;">
  <!-- [routerLink]="['/dash']"  -->
  <div class="sidebar-1-content-account-container2">
    <div class="sidebar-1-content-account-info2 active" id="settingspop" 
    
    >
      <div class="sidebar-1-content-account-info-relative2">
        <div *ngIf="loggedIn() && user != ''" style="padding: 10px 0px;" >                
          <h6>{{ userName| titlecase }}</h6>
          <p class="m-0">{{ userNameat }}</p>
        </div>
        

        <div>     
          <div  routerLinkActive="active" class="idebar-1-content-account-info-links-link">
            <div *ngIf="userRole == 'Admin' || userRole == 'Dealer' || userRole == 'Manager' " 
              style="border-top: 1px solid rgb(182, 182, 182); margin-top: 10px; padding-top: 8px;">
              <a [routerLink]="['/storeconfig']"> {{ 'SETTINGS' | translate }} </a>
            </div>
            <div *ngIf="userRole !== 'Dealer'" style="border-top: 1px solid rgb(182, 182, 182); margin-top: 10px; padding-top: 8px;">
              <a>{{ 'LANGUAGE' | translate }}</a>
              <select class="input-header tw-w-1/2" [(ngModel)]="language" (change)="changeLanguage()">
                <option value="" [selected]="''" disabled>{{ 'SELECT' | translate }}</option>
                <option value="en" [selected]="'en'">{{ 'ENGLISH' | translate }}</option>
                <option value="es" [selected]="'es'">{{ 'SPANISH' | translate }}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div> 
</div>