<div class="p-1 bg-white">
  <p class="label label-primary p-1 mb-0">
    
    <!-- -  For {{passType}} {{passSize}} -->
    <span class="text-small"> SALES ORDERS: {{passSalesOrders.length}}   </span>
  </p>
      <table id="salestable" class="table table-hover table-sm rounded " cellspacing="0" *ngIf="passSalesOrders">
          <thead class="">
              <tr>
                  <th>Order No</th>
                  <th>Date</th>
                  <th>Status</th>
                  <th class="text-right">Qty</th>
                  <th class="text-right">WholeSale</th>
                  <th class="text-right">SalesPrice</th>
                  
              </tr>
            </thead>
          <tbody>
            <tr class="table-light my-2"  *ngFor="let salesorder of passSalesOrders">
              <td>{{salesorder.SONumber}}</td>
              <td>{{ salesorder.ShowDate | date: 'short' }}</td>
              <td>{{salesorder.status}}</td>
              <td class="text-right">{{salesorder.Qty | number: '1.0'}}</td>
              <td class="text-right">$ {{salesorder.WholeSale | number: '1.2-2'}}</td>
              <td class="text-right">$ {{salesorder.SalesPrice | number: '1.2-2'}}</td>
              
            </tr>
          </tbody>
          <!-- <thead class="">
            <tr class="text-bigger">
                <td></td>
                <td></td>
                <td></td>
                <td class="text-right">{{QtyTotal | number: '1.0'}}</td>
                <td class="text-right">$ {{wholeSalePrice | number: '1.2-2'}}</td>
                <td class="text-right">$ {{SalesPrice | number: '1.2-2'}}</td>
            </tr>
          </thead> -->
      </table>
      

  </div>
  

