<div class="modal-body  tw-rounded-lg">

  <form  [formGroup]="form" (ngSubmit)="submit(model)" >

    <div class="tw-flex">
      <button class="tw-ml-auto" [disabled]="!form.valid"  type="sumbit">      
        <span class="material-icons tw-text-aedblue" style="font-size: 1.8rem"> check_circle </span>
      </button>
    </div>

    <div class="tw-flex"> 

      <div class="tw-flex tw-flex-row tw-w-full" >
        <h1 class="tw-text-2xl sm:tw-text-lg md:tw-text-3xl lg:tw-text-3xl tittle-0" >Delivery and other charges</h1>
        <svg  class="tw-w-12 tw-h-10 tw-ml-3" xmlns="http://www.w3.org/2000/svg" width="48.641" height="33.204" viewBox="0 0 48.641 33.204">
          <g id="Group_258" data-name="Group 258" transform="translate(3668.601 930.07)">
            <path id="Path_184" data-name="Path 184" d="M301.147,8.59h4.745a4.55,4.55,0,0,1,3.908,2.2l5.413,8.494a1.1,1.1,0,0,1,.1.717,1.186,1.186,0,0,1-.187.7.991.991,0,0,1-.8.375H301.147Zm12.1,10.929-5.114-8.025a2.663,2.663,0,0,0-2.245-1.353h-2.877v9.378Z" transform="translate(-3935.634 -935.507)" fill="#ee5f00"/>
            <path id="Path_184_-_Outline" data-name="Path 184 - Outline" d="M300.8,8.24h5.1a4.9,4.9,0,0,1,4.2,2.362l5.414,8.5a1.424,1.424,0,0,1,.15.924,1.532,1.532,0,0,1-.255.881,1.342,1.342,0,0,1-1.085.517H300.8Zm14.118,11.227-5.414-8.5a4.2,4.2,0,0,0-3.609-2.03h-4.4V20.72h12.824a.642.642,0,0,0,.522-.233c.056-.076.095-.152.119-.513C314.986,19.646,314.971,19.558,314.916,19.466Zm-12.25-9.676h3.227a3.009,3.009,0,0,1,2.541,1.518l5.455,8.561H302.666Zm9.947,9.378-4.775-7.493a2.317,2.317,0,0,0-1.946-1.185h-2.527v8.678Z" transform="translate(-3935.634 -935.507)" fill="#ee5f00"/>
            <path id="Ellipse_3" data-name="Ellipse 3" d="M4.524-1.149A5.679,5.679,0,1,1-1.149,4.529,5.682,5.682,0,0,1,4.524-1.149Zm0,9.49A3.811,3.811,0,1,0,.719,4.529,3.812,3.812,0,0,0,4.524,8.34Z" transform="translate(-3652.739 -907.424)" fill="#ee5f00"/>
            <path id="Ellipse_3_-_Outline" data-name="Ellipse 3 - Outline" d="M4.524-1.5A6.029,6.029,0,1,1-1.5,4.529,6.033,6.033,0,0,1,4.524-1.5Zm0,11.358A5.329,5.329,0,1,0-.8,4.529,5.332,5.332,0,0,0,4.524,9.858Zm0-9.49A4.161,4.161,0,1,1,.369,4.529,4.163,4.163,0,0,1,4.524.369Zm0,7.621A3.461,3.461,0,1,0,1.069,4.529,3.462,3.462,0,0,0,4.524,7.99Z" transform="translate(-3652.739 -907.424)" fill="#ee5f00"/>
            <path id="Ellipse_4" data-name="Ellipse 4" d="M4.524-1.149A5.679,5.679,0,1,1-1.149,4.529,5.682,5.682,0,0,1,4.524-1.149Zm0,9.49A3.811,3.811,0,1,0,.719,4.529,3.812,3.812,0,0,0,4.524,8.34Z" transform="translate(-3634.289 -907.424)" fill="#ee5f00"/>
            <path id="Ellipse_4_-_Outline" data-name="Ellipse 4 - Outline" d="M4.524-1.5A6.029,6.029,0,1,1-1.5,4.529,6.033,6.033,0,0,1,4.524-1.5Zm0,11.358A5.329,5.329,0,1,0-.8,4.529,5.332,5.332,0,0,0,4.524,9.858Zm0-9.49A4.161,4.161,0,1,1,.369,4.529,4.163,4.163,0,0,1,4.524.369Zm0,7.621A3.461,3.461,0,1,0,1.069,4.529,3.462,3.462,0,0,0,4.524,7.99Z" transform="translate(-3634.289 -907.424)" fill="#ee5f00"/>
            <path id="Path_185" data-name="Path 185" d="M274.288,6.29h18.591a4.023,4.023,0,0,1,4.108,3.923V29.35a5.563,5.563,0,0,1-5.68,5.424h-5.321l.23-1.071a3.551,3.551,0,0,0,.08-.745c0-.972-1.209-1.886-1.928-2.574a3.869,3.869,0,0,0-2.691-1.065,4.243,4.243,0,0,0-.769.071A3.737,3.737,0,0,0,278,32.013a3.553,3.553,0,0,0-.046,1.688l.233,1.073h-3.9a4.023,4.023,0,0,1-4.108-3.923V10.212A4.023,4.023,0,0,1,274.288,6.29Zm17.018,26.7a3.734,3.734,0,0,0,3.812-3.64V10.212a2.194,2.194,0,0,0-2.24-2.139H274.288a2.194,2.194,0,0,0-2.24,2.139V30.851a2.194,2.194,0,0,0,2.24,2.139H276a5.279,5.279,0,0,1,1.776-3.982,5.684,5.684,0,0,1,2.773-1.369,6.2,6.2,0,0,1,1.125-.1h0a5.77,5.77,0,0,1,4.012,1.589,5.27,5.27,0,0,1,1.661,3.834c0,.011,0,.021,0,.032Z" transform="translate(-3929.955 -936.009)" fill="#ee5f00"/>
            <path id="Path_185_-_Outline" data-name="Path 185 - Outline" d="M274.288,5.94h18.591a4.373,4.373,0,0,1,4.458,4.273V29.35a5.913,5.913,0,0,1-6.03,5.774h-5.754l.321-1.495a3.2,3.2,0,0,0,.072-.671c0-.663-.8-1.388-1.44-1.971-.135-.122-.262-.238-.379-.35a3.521,3.521,0,0,0-2.449-.968,3.892,3.892,0,0,0-.706.066,3.381,3.381,0,0,0-2.635,2.369,3.206,3.206,0,0,0-.042,1.523l.325,1.5h-4.331a4.373,4.373,0,0,1-4.458-4.273V10.212A4.373,4.373,0,0,1,274.288,5.94Zm17.018,28.485a5.213,5.213,0,0,0,5.33-5.074V10.212a3.673,3.673,0,0,0-3.758-3.573H274.288a3.673,3.673,0,0,0-3.758,3.573V30.851a3.673,3.673,0,0,0,3.758,3.573h3.463l-.141-.649a3.9,3.9,0,0,1,.05-1.853,4.094,4.094,0,0,1,3.183-2.877,4.594,4.594,0,0,1,.833-.077,4.218,4.218,0,0,1,2.932,1.162c.111.106.235.218.366.337.744.676,1.67,1.516,1.67,2.489a3.9,3.9,0,0,1-.088.818l-.139.648Zm-17.018-26.7h18.591a2.544,2.544,0,0,1,2.59,2.489V29.35a4.084,4.084,0,0,1-4.162,3.99H287l0-.382a4.923,4.923,0,0,0-1.553-3.581,5.422,5.422,0,0,0-3.77-1.492,5.854,5.854,0,0,0-1.062.1,5.334,5.334,0,0,0-2.6,1.284,4.929,4.929,0,0,0-1.66,3.72l0,.352h-2.068a2.544,2.544,0,0,1-2.59-2.489V10.212A2.544,2.544,0,0,1,274.288,7.723ZM291.306,32.64a3.384,3.384,0,0,0,3.462-3.29V10.212a1.844,1.844,0,0,0-1.89-1.789H274.288a1.844,1.844,0,0,0-1.89,1.789V30.851a1.844,1.844,0,0,0,1.89,1.789h1.375a5.754,5.754,0,0,1,.188-1.159,5.656,5.656,0,0,1,1.695-2.733,6.034,6.034,0,0,1,2.943-1.453,6.554,6.554,0,0,1,1.188-.11,6.119,6.119,0,0,1,4.254,1.686,5.624,5.624,0,0,1,1.761,3.77Z" transform="translate(-3929.955 -936.009)" fill="#ee5f00"/>
            <path id="Path_186" data-name="Path 186" d="M301.151,24.788h14.167V35.979a3.4,3.4,0,0,1-3.4,3.4h-1.859l.234-1.124a3.831,3.831,0,0,0,.08-.778c0-2.1-2.52-3.811-4.618-3.811a3.792,3.792,0,0,0-2.948,1.4L301.151,37.1Zm12.3,1.868H303.02V32.5a5.674,5.674,0,0,1,8.41,4.975c0,.011,0,.022,0,.034h.49a1.531,1.531,0,0,0,1.53-1.53Z" transform="translate(-3935.638 -940.656)" fill="#ee5f00"/>
            <path id="Path_186_-_Outline" data-name="Path 186 - Outline" d="M300.8,24.438h14.867V35.979a3.752,3.752,0,0,1-3.748,3.748h-2.289l.321-1.545a3.481,3.481,0,0,0,.072-.706,3.144,3.144,0,0,0-1.386-2.416,4.911,4.911,0,0,0-2.881-1.044,3.444,3.444,0,0,0-2.677,1.274L300.8,38.081Zm14.167.7H301.5V36.116l1.037-1.27a4.141,4.141,0,0,1,3.219-1.532,5.633,5.633,0,0,1,3.31,1.191,3.821,3.821,0,0,1,1.658,2.97,4.181,4.181,0,0,1-.087.849l-.146.7h1.429a3.051,3.051,0,0,0,3.048-3.048Zm-12.3,1.168H313.8v9.673a1.882,1.882,0,0,1-1.88,1.88h-.842l0-.384a5.324,5.324,0,0,0-7.891-4.669l-.519.287Zm10.431.7H303.37v4.932a6.024,6.024,0,0,1,8.4,5.22h.149a1.181,1.181,0,0,0,1.18-1.18Z" transform="translate(-3935.638 -940.656)" fill="#ee5f00"/>
            <path id="Line_88" data-name="Line 88" d="M14.363.479H0V-.767H14.363Z" transform="translate(-3668.251 -922.607)" fill="#ee5f00"/>
            <path id="Line_88_-_Outline" data-name="Line 88 - Outline" d="M14.713.829H-.35V-1.117H14.713ZM.35.129H14.013V-.417H.35Z" transform="translate(-3668.251 -922.607)" fill="#ee5f00"/>
            <path id="Line_89" data-name="Line 89" d="M14.363.479H0V-.767H14.363Z" transform="translate(-3664.754 -919.653)" fill="#ee5f00"/>
            <path id="Line_89_-_Outline" data-name="Line 89 - Outline" d="M14.713.829H-.35V-1.117H14.713ZM.35.129H14.013V-.417H.35Z" transform="translate(-3664.754 -919.653)" fill="#ee5f00"/>
            <path id="Line_90" data-name="Line 90" d="M14.363.479H0V-.767H14.363Z" transform="translate(-3668.251 -916.571)" fill="#ee5f00"/>
            <path id="Line_90_-_Outline" data-name="Line 90 - Outline" d="M14.713.829H-.35V-1.117H14.713ZM.35.129H14.013V-.417H.35Z" transform="translate(-3668.251 -916.571)" fill="#ee5f00"/>
          </g>
        </svg>
      </div> 

      <div class="modal-header-delivery-container tw-w-1/3">
        <h6 class="h6 tw-text-aedgrayl">Delivery Charges</h6>
        <h6 class="h6 tw-text-aedgrayl">$ {{discountTotal | number:'1.2-2'}}</h6>
      </div>

    </div>

    <!-- Delivery Info -->
   <section class="col-12 tw-pb-4 tw-border-2 tw-bg-white tw-border-aedgrays tw-rounded-xl tw-mx-auto">
    <div class="d-flex mt-3 tw-text-start">
      <div class="col-2 tw-text-base tw-text-aedgrayl tw-font-semibold ">Method</div>
      <div class="col-2 tw-text-base tw-text-aedgrayl tw-font-semibold ">Product</div>
      <div class="col-2 tw-text-base tw-text-aedgrayl tw-font-semibold">Size</div>
      <div class="col-3 tw-text-sm tw-text-aedgrayl tw-font-semibold" >Type</div>
      <div class="col-2 tw-text-base tw-text-aedgrayl tw-font-semibold">Price</div>
    </div>
    <div class="d-flex  tw-text-start tw-items-center tw-mb-1" *ngFor="let p of deliveryinfo; let even = even; let odd = odd"
      [ngClass]="{ 'tw-bg-aedgrayback': odd}">
      <div class="col-2">{{p.method}}</div>
      <div class="col-2">{{p.product}}</div>
      <div class="col-2">{{p.size}}</div>
      <div class="col-3">{{p.type}}</div>
      <div class="col-2">{{p.price | currency}}</div>
      <div class="col-1 tw-flex tw-justify-center">
        <button
        type="button"
        class="btn btn-add-mobile"
        (click)="addDelivery(p)"
      >
        <span style="font-size: 30px; width: 30px;" class="material-icons fs-1">
          add
        </span>
      </button>
      </div>

    </div>
  </section>
<!-- end Delivery Info -->

    <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options" > 
    </formly-form>

  </form>

</div>