<div *ngIf="events?.length > 0" class="card tw-bg-aedgrayk tw-rounded-b-lg tw-flex tw-flex-col tw-scroll-auto tw-h-full">
  <div class="tw-flex tw-grow-0 tw-items-center tw-py-1 tw-space-x-1 hover:tw-text-aedgraycard tw-mx-4 tw-text-white tw-cursor-pointer " (click)="showSilenced()">
    <i *ngIf="!showSilencedNoti" class="material-icons">visibility</i>
    <i *ngIf="showSilencedNoti" class="material-icons">visibility_off</i>
    <span>{{ showSilencedNoti ? 'Hide' : 'View'}} Deleted </span> 
  </div>
  <div class="tw-mb-4 tw-flex tw-flex-col tw-overflow-y-scroll tw-mx-4" >
    <div class=" tw-flex tw-flex-col" *ngFor="let event of events">
   
      <!-- SO -->
      <div *ngFor="let so of event?.salesOrders; let even = even" >
        
        <div *ngIf="so?.silenced == showSilencedNoti" [ngClass]="{'tw-bg-aedgraybg': even}" class="tw-bg-white hover:tw-bg-aedgraye tw-group tw-cursor-pointer tw-flex tw-p-2 justify-content-between" >
          <div class="tw-flex tw-flex-col tw-p-3">
            <p class="tw-text-aedblue tw-text-base"> <strong>Sales Order # {{so.SONumber}}</strong> has items that haven't been converted to PO:<br></p>
            <div *ngIf="so?.items.length > 0"><span *ngFor="let mi of so?.items" >{{mi?.name}}<br></span></div>
            <p class="card-text"> <strong></strong>{{so?.frecuency}} day(s) ago</p>
            <p *ngIf="so?.silenced" class="tw-text-sm tw-italic tw-text-aedorangel"> <strong></strong>{{so?.silenced && 'Deleted'}}</p>
          </div>

          <div class="tw-space-x-2 tw-flex tw-items-center tw-justify-center tw-text-aedblue">
            <button [routerLink]="['/eventsummary/', event.CID, so.SONumber, 'SO']"  (click)="showNotificationsChange.emit(false)"  ><span class="material-icons md-48 ">edit</span></button>
            <button *ngIf="!so?.silenced" (click)="markAsSilenced(so.SONumber , 'SO', '')" ><span class="material-icons tw-text-[30px]">delete</span></button>
          </div>
        </div>
      </div>
  
  
  
      <!-- PO -->
      <div *ngFor="let po of event?.PO; let even = even" >
        
        <div *ngIf="po?.silenced == showSilencedNoti" [ngClass]="{'tw-bg-aedgraybg': even}" class="tw-bg-white hover:tw-bg-aedgraye tw-group tw-cursor-pointer tw-flex tw-p-2 justify-content-between" >
          <div class="tw-flex tw-flex-col tw-p-3">
            <p class="tw-text-aedblue tw-text-base"> <strong>Purchase Order #{{po.POUUID}}</strong> hasn't been uploaded to DDS<br></p>
            <p class="card-text"> <strong></strong>{{po?.frecuency}} day(s) ago</p>
            <p *ngIf="po?.silenced" class="tw-text-sm tw-italic tw-text-aedorangel"> <strong></strong>{{po?.silenced && 'Deleted'}}</p>
          </div>

          <div class="tw-space-x-2 tw-flex tw-items-center tw-justify-center tw-text-aedblue">
            <button [routerLink]="['/eventsummary/', event.CID, po.POUUID, 'PO']"  (click)="showNotificationsChange.emit(false)"  ><span class="material-icons md-48 ">edit</span></button>
            <button *ngIf="!po?.silenced" (click)="markAsSilenced(po.POUUID , 'PO', 'fromNotification')" ><span class="material-icons tw-text-[30px]">delete</span></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
        