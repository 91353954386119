import { Component, OnInit, Input, AfterContentChecked, OnDestroy, OnChanges, Output } from '@angular/core';
import { StoreUsersService } from 'src/app/_services/storeusers.service';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ActivatedRoute } from '@angular/router';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import 'rxjs/add/operator/takeUntil';
import { EventEmitter } from '@angular/core';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import * as _ from "lodash";
import { EventsService } from 'src/app/_services/events.service';
import { float } from '@zxing/library/esm/customTypings';
import { SettingsService } from 'src/app/_services/settings.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterContentChecked, OnDestroy {
  @Input() salesOrder: any = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  salesOrderForm: FormGroup;
  @Output() change: EventEmitter<any> = new EventEmitter();
  @Input() canSelect = true;
  @Input() defaultTax = 0;
  taxesattempt: number = 0
  platform = '';
  showMobile = false;
  isIOS = false;
  isAndroid = false;

  otherSalesPerson = false;
  salesPerson: any[];
  salesPersons = [];
  referralTypes = [

    'Referral Flyers',
    'Signs',
    'Feeder Memos',
    'Faculty Discount',
    'Hero Flyer',
    'District Generic Email',
    'Social Media',
    'Direct Mail',
    'NewsPaper Ad',
    'Other'
  ];

  // tofundraiser vars
  fundraiser: any = [];
  selctedFundraiser: any = [];
  event: any;
  idparam: any;
  touchchooseevent: boolean = false



  constructor(
    private storeUserService: StoreUsersService,
    private authService: AuthService,
    private alertify: AlertifyService,
    private route: ActivatedRoute, private fb: FormBuilder,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    private salesService: SalesOrderService,
    private global: GlobalsService,
    private eventService: EventsService,
    private settingsService: SettingsService

  ) { }

  ngOnInit() {
    this.idparam = this.route.snapshot.params['id'];
    this.fundraiser = [];
    this.selctedFundraiser = [];

    //  console.log(this.salesOrder)

    this.salesOrderForm = this.fb.group({
      fundraiser: [this.salesOrder['fundraiser']],
      salesPerson: [this.salesOrder['salesPerson'], [Validators.required]],
      soSource: [this.salesOrder['soSource'], [Validators.required]],
      referralName: [this.salesOrder['referralName']],
      deliveryRequest: [this.salesOrder['deliveryRequest']],
      taxNumber: [this.salesOrder['taxNumber']],
      salesPersonOther: [this.salesOrder['salesPersonOther']]
    });

    this.loadTeamsFirst();
    this.loadStoreUsers();
    //this.defaultVal();
    this.salesOrderForm.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (this.salesOrderForm.value['salesPerson'] == 'other') {
          this.otherSalesPerson = true
          this.salesOrderForm.patchValue({ 'salesPerson': '' });
        }
        this.salesService.changeSOHeder(this.salesOrderForm);
      });

    this.salesService.salesTax
      .pipe(takeUntil(this.destroy$))
      .subscribe((res) => {
        if (res != null) {


          if (res['status'] === 'Init') {

            this.salesOrderForm.value['taxNumber'] = 0;
            this.salesOrderForm.controls['taxNumber'].setValue(0);
          } else if (res['status'] === 'ChangeEvent') {
            this.salesOrderForm.value['taxNumber'] = parseFloat(res['value']);
            this.salesOrderForm.controls['taxNumber'].setValue(parseFloat(res['value']));
          }

          //set the default tax if this one is 0
          // console.log(this.salesOrderForm.value['taxNumber'], this.defaultTax, this.taxesattempt)

          if (isNaN(this.salesOrderForm.value['taxNumber']) ||
            this.salesOrderForm.value['taxNumber'] == 0 ||
            this.salesOrderForm.value['taxNumber'] == null ||
            this.salesOrderForm.value['taxNumber'] == undefined
          ) {
            // console.log('salesTax obs change', this.defaultTax )
            this.salesOrderForm.value['taxNumber'] = this.defaultTax || this.taxesattempt;
            this.salesOrderForm.controls['taxNumber'].setValue(this.defaultTax || this.taxesattempt);
          }

          if (this.salesOrderForm.value['taxNumber'] == 0) {
            this.settingsService.SettingsEvent$
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
              // console.log(data)
              if(data[0] == undefined || data[0]['taxes'] == null) return;
              if(this.salesOrderForm.value['taxNumber'] == 0){
                this.salesOrderForm.controls['taxNumber'].setValue(JSON.parse(data[0]['taxes']).defaultTaxes);
                this.taxesattempt = JSON.parse(data[0]['taxes']).defaultTaxes
              }
              }, error => {
                this.alertify.error(error);
              });
          }

        }

      });

    this.salesService.changeheaderFromUnsaved.subscribe(
      (res: any) => {
        if (res != '' && res != null && res != undefined) {
          this.salesOrderForm.controls['taxNumber'].setValue(parseFloat(res['taxNumber']));
          this.salesOrderForm.controls['fundraiser'].setValue(res['fundraiser']);
          this.salesOrderForm.controls['salesPerson'].setValue(res['salesPerson']);
          this.salesOrderForm.controls['soSource'].setValue(res['soSource']);
          this.salesOrderForm.controls['referralName'].setValue(res['referralName']);
          this.salesOrderForm.controls['deliveryRequest'].setValue(res['deliveryRequest']);
          this.salesOrderForm.controls['salesPersonOther'].setValue(res['salesPersonOther']);
        }
      }
    )

    this.platform = navigator.userAgent;
    if (this.platform.match(/Android/i)) {
      this.showMobile = true;
      this.isAndroid = true;
    }
    if (this.platform.match(/iPhone|iPad|iPod/i)) {
      this.showMobile = true;
      this.isIOS = true;

    }

    if (this.platform.includes('Mac')) {
      this.showMobile = false;

    }

    if (this.platform.includes(navigator.platform) || (navigator.userAgent.includes('Mac') && "ontouchend" in document)) {
      this.showMobile = true;
      this.isIOS = true;
    }

    if (this.platform.includes('Win')) {
      this.showMobile = false;

    }



  }

  teamsChange(e, fund) {
    this.selctedFundraiser = this.selctedFundraiser.map((item : {team:string, checked:boolean}) => {
      if(item.team == fund){
        return {...item, checked : e.target.checked}
      }else{
        return item
      }
    });
    this.salesOrderForm.controls['fundraiser'].setValue(JSON.stringify(this.selctedFundraiser));        
  }



  loadTeamsFirst() {
    // console.log('LOAD teams first')

    this.eventService.touchchooseevent
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          this.touchchooseevent = res;
        });

    this.eventService.fundraiser
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res1: any) => {
          let res = res1.teams
          // console.log(this.idparam, res1)
          if (res == null || res == undefined || res == 0) {
            this.fundraiser = []
          } else {
            this.fundraiser = []
            let convert = JSON.parse(res);
            if (this.idparam == undefined || this.touchchooseevent == true) { 
              if (convert.length > 1) {
                convert.forEach(element => {
                  this.fundraiser.push({ "team": element.program, "checked": false });
                });
              } else if (convert.length == 1) {
                convert.forEach(element => {
                  this.fundraiser.push({ "team": element.program, "checked": true });
                });
              }              
            } 

            if (this.idparam != undefined) {
              if (this.salesOrder['fundraiser'] != null || this.salesOrder['fundraiser'] != undefined) {
                this.fundraiser = JSON.parse(this.salesOrder['fundraiser']);
                // console.log(this.fundraiser, convert)
                convert.forEach((newitem: any) => {
                  let exists = false
                  this.fundraiser.forEach((existing: any) => {
                    if(newitem?.program == existing?.team){
                      exists = true
                    }
                  });
                  if(!exists){
                    this.fundraiser.push({ "team": newitem?.program, "checked": false });
                  }
                });
                this.selctedFundraiser = this.fundraiser;
              }else if(convert?.length > 0){
                convert.forEach(element => {  
                  this.fundraiser.push({"team":element.program ,"checked":false});                    
                 });
              }
            }

            this.selctedFundraiser = this.fundraiser;
            this.salesOrderForm.controls['fundraiser'].setValue(JSON.stringify(this.selctedFundraiser));


          }
        }, error => {
          this.alertify.error(error);
        });


  }



  ngAfterContentChecked(): void {
    // this.defaultVal();
  }

  defaultVal() {
    // if (this.salesOrderForm['salesPerson']) {
    if (this.salesOrder) {
      // this.salesOrderForm.patchValue(this.salesOrder);
      //this.salesOrderForm.controls['soSource'].setValue(this.salesOrder['soSource']);
    }
    // }
  }

  addSalesOrder() {
    // if(this.salesOrderForm.valid){
    this.salesOrder['fundraiser'] = this.salesOrderForm.value['fundraiser'];
    this.salesOrder['salesPerson'] = this.salesOrderForm.value['salesPerson'];
    this.salesOrder['referralName'] = this.salesOrderForm.value['referralName'];
    this.salesOrder['soSource'] = this.salesOrderForm.value['soSource'];
    this.salesOrder['deliveryRequest'] = this.salesOrderForm.value['deliveryRequest'];
    this.salesOrder['taxNumber'] = this.salesOrderForm.value['taxNumber'];
    // }else{      
    //   this.alertify.error('some fields are required');
    // }

  }


  loadStoreUsers() {
    let other = true;
    let specificFields:any = {
      values: [
          "name"
      ]
    }
    this.storeUserService.getStoreUsersSpecific(1, 100, '', specificFields)
      //  .takeUntil(componentDestroyed(this))  
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (res: any) => {
          // console.log(res)
          this.salesPerson = res.result;
          for (let index = 0; index < res.result.length; index++) {
            const element = res.result[index];
            this.salesPersons.push(element['name'].toString());
            if (element['name'].toString() == this.salesOrder['salesPerson']) {
              other = false
            }
          }

          this.otherSalesPerson = other



        }, error => {
          this.alertify.error(error);
        });


  }

  selectFromStorage() {
    this.otherSalesPerson = false
  }



  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();

  }



}
