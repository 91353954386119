import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { User } from '../_modules/user';
import { PaginatedResult } from '../_modules/pagination';
import { map } from 'rxjs/operators/map';
import { Message } from '../_modules/message';

@Injectable({
  providedIn: 'root'
})
export class TransactionpaymentsService {
  baseUrl = environment.apiUrl;
  laravel = environment.apiLaravel;

  constructor(private http: HttpClient) { }

  getTransactionPayment(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'tpayments/' + id);
  }


  addTransactionPayment(tpayment) {
    return this.http.post(this.laravel + 'tpayments', tpayment);
  }

  updateTransactionPayment(id, tpayment: any) {
    return this.http.patch(this.laravel + 'tpayments/' + id, tpayment);
  }

}
