<div class="p-1" >
  <!-- <app-closebutton></app-closebutton> -->
  <span class="border-bottom p-1 mt-0"> Signature
  </span>
  <form  [formGroup]="form" (ngSubmit)="submit()">
    <formly-form [form]="form" [fields]="fields" [model]="cardPayment">
        <div class="form-group text-center">

        <div class="sig-container z-depth-1" >
          <!-- Signature -->
            <div class="pull-right">
               <button type="button" class="btn btn-3" (click)="clear()"> <i class="fa fa-remove"></i></button>
            </div>
            <div class="tw-text-center" fxLayout="row" #sigContainer1>
              <div *ngIf="savedSignature">
                  <img src={{savedSignature}} alt="">
              </div>
                <signature-pad *ngIf="!savedSignature"  
                [options]="signaturePadOptions" 
                name="orderSig"></signature-pad>
            </div>
          </div>

            <button class="btn btn-3 mx-auto mt-2"
            [disabled]="!form.valid" type="sumbit">Continue</button>
            <!-- <button class="btn btn-outline-danger ml-2" (click)="cancelForm()"  type="button"> Cancel</button> -->
        </div>
    </formly-form>
  <!-- </div> -->
  </form>
</div>


