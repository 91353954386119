<div style="height: 100vh;">
    <div class="tw-px-4 tw-py-8" style="height: 100%;">

        <div class="tw-grid tw-grid-cols-7" style="height: 100%;">

            <!-- FIRST COLUMN -->
            <div class="tw-col-span-4 tw-p-4" style="background-color: #fafafa;">

                <div class="tw-flex tw-mb-4">

                    <div class="tw-flex">
                        <span class="tw-my-auto tw-text-2xl tw-font-bold tw-text-aedgraydark">
                            {{ 'PRODUCTS' | translate }}
                        </span>
                    </div>

                    <div class="tw-ml-auto tw-flex">
                        <button *ngIf="canEdit" class="tw-ml-auto btn-blue-so-square" style="font-size: 1rem;" 
                        (click)="searchProducts()">
                            <span class="material-icons">search</span>
                            &nbsp;{{ 'SEARCH_PRODUCT' | translate }}
                        </button>

                        <div *ngIf="!canEdit" class="tw-text-2xl tw-font-bold tw-text-aedgraydark">
                            {{('ORDER.' | translate)}}{{': '+so.SONumber}}
                        </div>
                    </div>

                </div>

                <div>
                    <app-prods
                    [canEdit]="canEdit"
                    [productsAdded]="this.so.soItems"
                    [taxNumber]="so.taxNumber"
                    (productsToSO)="getProductsForm($event)"
                    (totalsfromItemsToSO)="totalsfromItemsToSO($event)"
                ></app-prods>  
                </div>              

            </div>

            <!-- ANOTHER COLUMN -->
            <div class="tw-col-span-3 tw-ml-4">
                
                <!-- TOTALS -->
                <app-tot 
                [subTotal]="so.subTotalOrderSum"
                [tax]="so.totalTaxNum"
                [total]="so.Total"
                [payments]="so.totalPaymentsNum"
                [balance]="so.Change_num"
                ></app-tot>

                <!-- CUSTOMERS -->
                <div class="tw-mt-6">
                    
                    <button *ngIf="!showCustomerForm" (click)="showCustomerForm = true" 
                    [ngClass]="!canEdit? 'tw-bg-aedbluedisabled':'tw-bg-aedblue'"
                    class="tw-cursor-pointer tw-text-white tw-w-44 tw-h-10 tw-flex" style="outline: none;">
                        <div class="tw-m-auto tw-flex">
                            <span *ngIf="canEdit" style="font-size: 1rem;" class="material-icons my-auto">add</span>
                            <span *ngIf="canEdit" style="font-size: 1.2rem;">{{ 'CUSTOMER' | translate }}</span>
                            <span *ngIf="!canEdit" style="font-size: 1.2rem;">{{ 'NO_CUSTOMER' | translate }}</span>
                        </div>
                    </button>

                    <div *ngIf="showCustomerForm">
                        <div class="tw-px-6 tw-flex tw-bg-aedblueback2 tw-text-white" style="justify-content: space-between;" >
                            <span style="font-size: 1.7rem;">{{ 'CUSTOMER' | translate }}</span>
                            <span *ngIf="!showSearch && (this.role=='Admin' || this.role=='Dealer')?true:canEdit" (click)="showSearch = true" style="font-size: 1.5rem;cursor: pointer;" class="material-icons my-auto">search</span>
                            <span *ngIf="showSearch" (click)="showSearch = false" style="font-size: 1.5rem;cursor: pointer;" class="material-icons my-auto">close</span>
                        </div>
                        <div class="tw-px-2 tw-pb-2 tw-bg-aedblueback2" >
                            <app-cust 
                            [role]="role"
                            [canEdit]="canEdit"
                            [customer]="so.customer" 
                            [(showSearch)]="showSearch"
                            (changeShowSearch)="showSearchs($event)"
                            (updatecustomerOnSO)="updateCustSO($event)"
                            (cancelAddCustomerOnSO)="cancelAddCustSO($event)"></app-cust>
                        </div>
                    </div>
                </div>

                
                <!-- PAYMENTS -->
                <div class="tw-mt-6">
                  
                    <div *ngIf="!showPaymentForm" (click)="changeShowPaymentForm()" class="tw-cursor-pointer tw-bg-aedblue tw-text-white tw-w-44 tw-h-10 tw-flex">
                        <div class="tw-m-auto tw-flex">
                            <span style="font-size: 1rem;" class="material-icons my-auto">add</span>
                            <span style="font-size: 1.2rem;">{{ 'PAYMENT' | translate }}</span>
                        </div>
                    </div>

                    <div *ngIf="showAfterSettings && showPaymentForm">
                       
                        <div class="tw-px-4 tw-flex tw-bg-aedblueback2 tw-text-white">
                            <span style="font-size: 1.2rem;">{{ 'PAYMENTS' | translate }}</span>
                        </div>
                        <div class="tw-px-4 tw-pb-2 tw-bg-aedblueback2" >
                            <div *ngIf="(role != 'Dealer' && role != 'Admin') && (this.idparam == '' || this.idparam == null || this.idparam == undefined)" style="display: flex;" >
                                <div  class="tw-text-lg sm:tw-text-lg md:tw-text-lg lg:tw-text-lg tw-text-white tw-flex" *ngIf="CRID != ''">
                                    {{ 'CASH_REGISTER_ASSIGNED' | translate }}: {{CRNAME}} 
                                </div>
                                <div  class="tw-text-lg sm:tw-text-lg md:tw-text-lg lg:tw-text-lg tw-text-white tw-flex" *ngIf="CRID == ''">
                                    {{ 'NO_CASH_REGISTER_ASSIGNED' | translate }}
                                </div>
                            </div>
                            <div *ngIf="(role == 'Dealer' || role == 'Admin') && (this.idparam == '' || this.idparam == null || this.idparam == undefined)" style="display: flex;">
                                <label class="tw-text-lg sm:tw-text-lg md:tw-text-lg lg:tw-text-lg tw-text-white tw-flex">{{ 'CASH_REGISTER' | translate }}: </label>
                                <select [(ngModel)]="CRID" class="tw-mr-4 tw-text-lg tw-text-aedblue tw-border-2 tw-rounded-lg tw-bg-white tw-border-aedblue" style="margin-left: 10px;">
                                    <option *ngFor="let item of opensCR" [value]="item.deviceId">{{item.deviceName}}</option>
                                </select>
                            </div>
                            <app-payments-form
                            [canEdit]="canEdit"
                            [from]="'SOmobile'"
                            [settings]="storeSettings"
                            [passpayments]="this.so.payments"
                            [salesOrder]="this.so"
                            (updatePaymentOnSO)="updatePaymentOnSO($event)"
                            ></app-payments-form>
                        </div>
                    </div>
 


                </div>

                <!-- PRINT -->
                <div *ngIf="this.idparam != '' && this.idparam != null && this.idparam != undefined" class="tw-mt-6">

                    <div (click)="onPrint()" class="tw-cursor-pointer tw-bg-aedblue tw-text-white tw-w-44 tw-h-10 tw-flex">
                        <div class="tw-m-auto tw-flex">
                            <span style="font-size: 1.2rem;">{{ 'PRINT' | translate }}</span>
                        </div>
                    </div>    

                </div>

            </div>
        </div>

    </div>    
</div>


    
<div #printSection hidden>

    <div style="display: flex; flex-direction: column; margin: 20px; align-items: center; font-size: 10px;">
        <div style="width: 100%; ">{{'Give us feedback @ aepay.com' | uppercase }}</div>
        <div style="width: 100%; ">{{'Thank you! ID #' + so.SONumber | uppercase}}</div>
        <img [src]="corporate.logo" style="width: 50%; margin-top: 15px;">
        
        <div style="display: flex; flex-direction: column; align-items: center; width: 100%;margin-top: 15px;">
            <label style="text-align: center;">{{corporate?.companyName| uppercase}}</label>
            <label>{{ (address.phone != null ? (address.phone | mask:'0000-000-000'):'') + ' ' + 
                    (address.name != null ? (address.name | uppercase):'')}}</label>
            <label>{{ (address.address)?address.address:'' +' '+ (address.address2)?address.address2:'' | uppercase}}</label>
            <label>{{(address.city)?address.city:'' +' '+ (address.state)?address.state:'' +' '+ (address.zip)?address.zip:'' | uppercase}}</label>
            
            <br>

            <!-- customer -->
            <div *ngIf="so.firstName != null && so.firstName != ''" style="display: flex; flex-direction: column; align-items: center; width: 100%;">
                <div>{{'Customer: '+ so.firstName+' '+(so.lastName != null ? so.lastName : '')}}</div>
                <div *ngIf="(so.phone1 != null && so.phone1 != '') || (so.email != null && so.email != '')">                            
                    {{(so.email != null ? so.email+' ' : '')+(so.phone1 != null ? 'Tel: '+(so.phone1 | mask:"0000-000-000") : '')}}
                </div>
                <div>{{(so.billingAddress1 != null ? so.billingAddress1 : '')+' '+(so.billingAddress2 != null ? so.billingAddress2 : '')+
                    ' '+(so.city != null ? so.city : '')+' '+(so.state != null ? so.state : '')+' '+(so.zip != null ? so.zip : '')}}
                </div>
            </div>

            <br>

            <label>{{ 'ITEMS' | translate }} {{ 'SOLD.' | translate }} {{so?.soItems?.length}}</label>
        </div>



        <table style="margin-top:15px; width: 100%;">
            <thead >
                <tr>
                    <th></th>
                    <th style="text-align: left;font-size: 10px;">{{ 'PRODUCT' | translate }}</th>
                    <th style="text-align: left;font-size: 10px;">{{ 'SKU' | translate }}</th>
                    <th style="text-align: left;font-size: 10px;">{{ 'QUANTITY' | translate }}</th>
                    <th style="text-align: left;font-size: 10px;">{{ 'PRICE' | translate }}</th>
                    <th></th>
                </tr>
            </thead>
        
            <tbody >
                <tr *ngFor="let sp of this.so.soItems ;let impar = odd; let i = index">
                    <td style="font-size: 10px;">{{i+1}}</td>
                    <td style="text-align: left;font-size: 10px; overflow: hidden;">{{ sp.name  | uppercase | slice:0:9}}</td>
                    <td style="text-align: left;font-size: 10px;">{{ sp.SKU | uppercase}}</td>
                    <td style="text-align: center;font-size: 10px;">
                        {{ sp.quantityNum}} 
                    </td>    
                    <td style="text-align: left;font-size: 10px;">{{ sp.priceNumUnit | currency}}</td>
                    <td style="text-align: left;font-size: 10px;">{{(sp.taxable=='1')?'T':'X'}}</td>
                </tr>
            </tbody>
        </table>
        
        <div style="display:flex;margin-top:15px;width: 100%;">
            <div style="display: grid;
                        grid-template-columns: repeat(5, 1fr); 
                        gap: 15px;"
            >
                <div style="grid-column: span 2;"></div>
                <div style="grid-column: span 3;">
            
                    <div    style="display: grid;
                                grid-template-columns: repeat(4, 1fr);
                                gap: 15px;"
                            class="padY" 
                    >
                        <div style="grid-column: span 1; text-align: end; font-weight: bold; display: flex;">
                            <span style="font-size: 10px; margin: auto; margin-right: 0;text-align:start">{{ 'SUBTOTAL' | translate }}</span> 
                        </div>
                        <div style="grid-column: span 3; font-size: 10px; text-align: end;" >{{so.subTotalOrderSum | currency}}</div>
                    </div>
            

                    <div    style="display: grid;
                                grid-template-columns: repeat(4, 1fr);
                                gap: 15px;"
                            class="padY" 
                    >
                        <div style="grid-column: span 1; text-align: end; font-weight: bold; display: flex;">
                            <span style="font-size: 10px; margin: auto; margin-right: 0;text-align:start">{{ 'TAX' | translate }}</span> 
                        </div>
                        <div style="grid-column: span 3; font-size: 10px; text-align: end;">{{so.totalTaxNum | currency}}</div>
                    </div>
            
         
            
                    <div style="display: grid;
                                grid-template-columns: repeat(4, 1fr);
                                gap: 15px;"
                        class="padY"       
                    >
                        <div style="grid-column: span 1; text-align: end; font-weight: bold; display: flex;">
                            <span style="font-size: 10px; margin: auto; margin-right: 0;text-align:start">{{ 'TOTAL' | translate }}</span> 
                        </div>
                        <div style="grid-column: span 3; font-size: 10px; text-align: end;">{{so.Total | currency}}</div>
                    </div>
            
                
            
                    <div style="display: grid;
                                grid-template-columns: repeat(4, 1fr);
                                gap: 15px;"
                        class="padY"       
                    >
                        <div style="grid-column: span 1; text-align: end; font-weight: bold; display: flex;">
                            <span style="font-size: 10px; margin: auto; margin-right: 0;text-align:start">{{ 'PAYMENT' | translate }}</span> 
                        </div>
                        <div style="grid-column: span 3; font-size: 10px; text-align: end;">{{so.totalPaymentsNum | currency}}</div>
                    </div>
            
    
                    <div *ngIf="so.Change_num != 0" style="display: grid;
                                grid-template-columns: repeat(4, 1fr);
                                gap: 15px;"
                        class="padY"       
                    >
                        <div style="grid-column: span 1; text-align: end; font-weight: bold; display: flex;">
                            <span style="font-size: 10px; margin: auto; margin-right: 0;text-align:start">{{so.Change_num > 0?'Balance':'Change'}}</span> 
                        </div>
                        <div style="grid-column: span 3; font-size: 10px; text-align: end;">{{so.Change_num | currency}}</div>
                    </div>
                                        
                </div>
                
            </div>
        </div>

        <div style="margin-top:15px;width: 100%;font-size: 10px;">
            <div *ngFor="let payment of so.payments ;let impar = odd; let i = index">
                <div *ngIf="payment?.paymentType != 'Credit Card'">
                    {{ payment?.paymentType + ' ' + (payment?.paymentAmount | currency) }}
                </div>
                <div *ngIf="payment?.paymentType == 'Credit Card'" style="display: flex; flex-direction: column; align-items: start;">
                   <label>{{payment?.cardType + ' -' + payment?.cardLastFour + ' APPR#' + paymentsJSON[i].payment?.cardTransaction.authCode}}</label>
                   <label style="margin-left: 10px;">{{(payment?.paymentAmount | currency)+ ' TOTAL PURCHASE'}}</label>
                   <label>{{('REF #'+ paymentsJSON[i].payment?.cardTransaction.referenceId)}}</label>
                   <label>{{('TRANSACTION - '+ paymentsJSON[i].payment?.cardTransaction.transactionNo)}}</label>
                   <label>{{('AID - '+ paymentsJSON[i].payment?.cardTransaction.extra.applicationIdentifier)}}</label>
                   <label>{{(payment?.created_at)}}</label>
                   <label style="width: 100%;">
                    <div *ngIf="paymentsJSON[i].payment?.cardTransaction.extra.cvmResult == 'NO_CVM_REQUIRED'">{{ 'NO_SIGNATURE_REQUIRED' | translate }}</div>
                    <div style="width: 100%; display: flex; flex-direction: column; align-items: center; padding: 10px;" *ngIf="paymentsJSON[i].payment?.cardTransaction.extra.cvmResult == 'SIGNATURE'">
                        {{ 'SIGNATURE' | translate }}
                        <img style="width: 100px; height: 100px; padding: 5px;" [src]='"data:image/"+paymentsJSON[i].issues?.signature.signature.format+";base64,"+paymentsJSON[i].issues?.signature.signature.data'>
                    </div>
                       
                    </label>
                    
                </div>
            </div>      
        </div>

        <div style="width: 100%;margin-top: 15px;">{{'Low prices. You can trust. Every day.'| uppercase}}</div>   
        <div style="width: 100%; margin-bottom: 20px;">{{ now | formatDate }}</div>
        <br>
        <div style="height: 1rem; min-height: 1rem; color:white">|</div>
        


    </div>
</div>