import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { NrnValidators } from 'src/app/nrn.validators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/_services/user.service';
import { AuthService } from 'src/app/_services/auth.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { Address } from 'src/app/_modules/address';
declare let alertify: any;
interface Notification{
  SOItemsID : string;
  reason: string;
  confirmed: boolean
} 
@Component({
  selector: 'app-itemdelivery',
  templateUrl: './itemdelivery.component.html',
  styleUrls: ['./itemdelivery.component.scss']
})
export class ItemdeliveryComponent implements OnInit {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() showAddress: boolean = false;
  @Output() showAddressChange = new EventEmitter<boolean>();
  @Output() refreshAddress = new EventEmitter<any>();
  @Output() closeAddress = new EventEmitter<any>();
  @ViewChild('popTemplateiOs', {static: false}) popTemplateiOs: TemplateRef<any>;
  @Input() salesOrder: any;
  @Input() warnings: Notification[];
  @Input() notifications: any;
  editId: number;
  states: any[];
  @Input() item: any;
  @Input() storeSettings: any;
  // item: any;
  mask = [ /[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  default = '';
  model: any;
  modalRefAddress: BsModalRef;
  validatedAddress: Address[];
  form = new FormGroup({});
  options: FormlyFormOptions = {
    formState: {
      submitted : false,
    },
  };

  fields: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldGroup: [
        // {
        //   key: 'Id',
        //   type: 'input',
        //   className: 'col-md-6',
        //   hide: true,
        // },
    {
      key: 'deliveryAttn',
      type: 'input',
      className: 'col-md-12 input-header-payment input',
      templateOptions: {
      type: 'text',
      placeholder: 'Name',
      required: true
      }
    },
  {
    key: 'deliveryPhone',
      type: 'phone',
      className: 'col-md-6 mb-1 input-header-payment input',
      templateOptions: {
        placeholder: 'Phone',
        required: true,
        pattern: NrnValidators.FORMATS.maskphone
      },
      validation: {
        messages: {
          pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Phone Number`,
        },
      },
  },

  {
    key: 'deliveryEmail',
      type: 'input',
      className: 'col-md-6 input-header-payment input',
      templateOptions: {
        type: 'text',
        placeholder: 'Email',
        pattern: NrnValidators.FORMATS.email
      },
      validation: {
        messages: {
          pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Email`,
        },
      },


  },

  {
    key: 'deliveryAddress1',
      type: 'input',
      className: 'col-md-12 input-header-payment input',
      templateOptions: {
        type: 'text',
        placeholder: 'Address',
        required: true
      }
  },
  {
    key: 'deliveryAddress2',
      type: 'input',
      className: 'col-md-12 input-header-payment input',
      templateOptions: {
        type: 'text',
        placeholder: 'Address 2'
      }
  },
  {
    key: 'deliveryCity',
      type: 'input',
      className: 'col-md-4 input-header-payment input',
      templateOptions: {
        type: 'text',
        placeholder: 'City',
        required: true
      }
  },
  {
    key: 'deliveryState',
      type: 'select',
      className: 'col-md-4 input-header-payment input',
      templateOptions: {
        required: true,
        options: this.getStates(),
        valueProp: 'abbreviation',
        labelProp: 'name'
      }
  },
  {
    key: 'deliveryZip',
      type: 'input',
      className: 'col-md-4 input-header-payment input',
      templateOptions: {
        type: 'text',
        placeholder: 'Zip',
        required: true,
        maxLength: 5,
        minLength: 5,
      }
  }
],
  },
  ];


  itemOriginal:any = {}


  constructor( private alertifySvc: AlertifyService,
    private fb: FormBuilder, private http: HttpClient,
    public bsModalRef: BsModalRef,
    public userService: UserService,
    private modalService: BsModalService,
    private alertify: AlertifyService,
    private salesService: SalesOrderService,
    private authService: AuthService) { }

  ngOnInit() {
  }

  closeAddressFunct(){
    this.closeAddress.emit(false)
  }


  useDifAddress()
  {
    this.alertifySvc.prompt('', `This item will be shipped to the provided address. If you're certain that you want to change the address, please confirm by typing "I am sure".`, '',
      
    (evt, value) => {        
      if(value == "I am sure"){    
        this.item.itemAddress = 1; 
        this.item.deliveryAttn = "";
        this.item.deliveryPhone = "";
        this.item.deliveryEmail = "";
        this.item.deliveryAddress1 = "";
        this.item.deliveryAddress2 = ""; 
        this.item.deliveryCity = "";
        this.item.deliveryState = ""; 
        this.item.deliveryZip = "";
      }
    },

    () => { 
    });
  }

  getStates(): Observable<any[]> {
    return this.http.get<any[]>('assets/states.json');
  }

  validateAddress(){
    if(!this.form.valid){
      this.alertify.error('Complete all shipping fields')
      return;
    }

    this.salesService.validateAddress({customer: this.form.value, device: localStorage.getItem('device')})
    .pipe(
      takeUntil(this.destroy$))   
    .subscribe((a:any) =>{
      if(a?.messages){
        let show:string = '';
        a.messages.forEach(msg => {
          show = show+msg.summary +'<br> '
        });
        this.alertify.alert(show, ()=>{})
      }else if(a?.taxAuthorities){
        this.validatedAddress = a?.validatedAddresses as Address[];
        // console.log(this.validatedAddress)
        this.modalRefAddress = this.modalService.show(this.popTemplateiOs, { class: 'modal-lg'});
        // this.alertify.alert('the Address is Valid', ()=>{})
      }
    })
  }

  setAddressFromAvalara(address: Address | null):void{
    // console.log(address)
    if(address != null){
      this.item['deliveryAddress1'] = address.line1
      this.item['deliveryAddress2'] = address.line2
      this.item['deliveryCity'] = address.city
      this.item['deliveryState'] = address.region
      this.item['deliveryZip'] = address.postalCode.substring(0,5)
      this.form.patchValue(this.item);
    }
    this.modalRefAddress.hide()
  }
  
  saveDelivery() {
    if(this.form.valid){ 

          if((
            this.item['deliveryAddress1'] != this.salesOrder['deliveryAddress1'] ||
            this.item['deliveryAddress2'] != this.salesOrder['deliveryAddress2'] ||
            this.item['deliveryCity'] != this.salesOrder['deliveryCity'] ||
            this.item['deliveryState'] != this.salesOrder['deliveryState'] ||
            this.item['deliveryZip'] != this.salesOrder['deliveryZip']
            ) && (
              this.item['deliveryAddress1'] != this.salesOrder['billingAddress1'] ||
              this.item['deliveryAddress2'] != this.salesOrder['billingAddress2'] ||
              this.item['deliveryCity'] != this.salesOrder['city'] ||
              this.item['deliveryState'] != this.salesOrder['state'] ||
              this.item['deliveryZip'] != this.salesOrder['zip']
            ))
            {
              // Add ifnot exists
              
              if(this.warnings.length > 0){
                if(!!this.warnings.find((w: Notification) => w.SOItemsID == this.item['SOItemsID']) == false){
                  this.warnings.push({SOItemsID: this.item['SOItemsID'], confirmed: false, reason: 'differentAddress'})
                }
              }else{
                this.warnings.push({SOItemsID: this.item['SOItemsID'], confirmed: false, reason: 'differentAddress'})
              }
              if(this.warnings.find((w: Notification) => w.SOItemsID == this.item['SOItemsID']).confirmed == true && !this.form.dirty){
                this.showAddressChange.emit(false)
                let data = {
                  item: this.item,
                  change: this.form?.dirty,
                }
                this.refreshAddress.next(data)
                
                return;
              }
              //  Ask if they wanna constinous or what, 
              // this.alertifySvc.confirm('This item direct ship address differs from sales order shipping address, Would you like to continuous?', 
              // ()=>{
              this.warnings.find((w: Notification) => w.SOItemsID == this.item['SOItemsID']).confirmed = true
              this.notifications.warnings = this.warnings
              this.salesOrder['notifications'] =  JSON.stringify(this.notifications)          
      
              
              this.showAddressChange.emit(false)
              let data = {
                item: this.item,
                change: this.form?.dirty,
              }
              this.refreshAddress.next(data)
      
            //   }, 
            // ()=>{
      
            // })
            }else{
              
              if(this.warnings.length > 0){
                if(!!this.warnings.find((w: Notification) => w.SOItemsID == this.item['SOItemsID'])){
                  // let items = this.warnings
                  // this.warnings = items.filter((e: Notification) => e.SOItemsID != this.item['SOItemsID']);
                  this.warnings.find((w: Notification) => w.SOItemsID == this.item['SOItemsID']).confirmed = true  
                  this.notifications.warnings = this.warnings
                  this.salesOrder['notifications'] =  JSON.stringify(this.notifications)       
                  
                }
              }
      
              this.showAddressChange.emit(false)              
              let data = {
                item: this.item,
                change: this.form?.dirty,
              }
              this.refreshAddress.next(data)

            }    

    }else{
       this.options.formState.submitted = true;      
    }

  }

  copyFromBilling() {
    this.item['deliveryAttn'] = this.salesOrder['firstName'] + ' ' + this.salesOrder['lastName'];
    this.item['deliveryPhone'] = this.salesOrder['phone1'];
    this.item['deliveryEmail'] = this.salesOrder['email'];
    this.item['deliveryAddress1'] = this.salesOrder['billingAddress1'];
    this.item['deliveryAddress2'] = this.salesOrder['billingAddress2'];
    this.item['deliveryCity'] = this.salesOrder['city'];
    this.item['deliveryState'] = this.salesOrder['state'];
    this.item['deliveryZip'] = this.salesOrder['zip'];
    this.form.patchValue(this.item);

  }

  copyFromShipping() {
    this.item['deliveryAttn'] = this.salesOrder['fName'] + ' ' + this.salesOrder['lName'];
    this.item['deliveryPhone'] = this.salesOrder['phone3'];
    this.item['deliveryEmail'] = this.salesOrder['deliveryEmail'];
    this.item['deliveryAddress1'] = this.salesOrder['deliveryAddress1'];
    this.item['deliveryAddress2'] = this.salesOrder['deliveryAddress2'];
    this.item['deliveryCity'] = this.salesOrder['deliveryCity'];
    this.item['deliveryState'] = this.salesOrder['deliveryState'];
    this.item['deliveryZip'] = this.salesOrder['deliveryZip'];
    this.form.patchValue(this.item);

  }

  copyFromPrevious() {
    const items = this.salesOrder['soItems'].filter(item => item['deliveryAttn'] != null && item['deliveryAttn'] !== undefined && item['itemType'] != 'discount');
    let soItem;
    // items.forEach((element, index) => {
    //   soItem = element;
    // });
    let index = items.indexOf(this.item)
    soItem = items[index-1] 
  
    if (soItem && index > 0) {
    
      this.item['deliveryAttn'] = soItem['deliveryAttn'];
      this.item['deliveryPhone'] = soItem['deliveryPhone'];
      this.item['deliveryEmail'] = soItem['deliveryEmail'];
      this.item['deliveryAddress1'] = soItem['deliveryAddress1'];
      this.item['deliveryAddress2'] = soItem['deliveryAddress2'];
      this.item['deliveryCity'] = soItem['deliveryCity'];
      this.item['deliveryState'] = soItem['deliveryState'];
      this.item['deliveryZip'] = soItem['deliveryZip'];
      this.form.patchValue(this.item);
    
    }

    if(this.warnings.length > 0){
      if(!!this.warnings.find((w: Notification) => w.SOItemsID == this.item['SOItemsID'])){
        // let items = this.warnings
        // this.warnings = items.filter((e: Notification) => e.SOItemsID != this.item['SOItemsID']);
        this.warnings.find((w: Notification) => w.SOItemsID == this.item['SOItemsID']).confirmed = true

      }else{
        this.warnings.push({SOItemsID: this.item['SOItemsID'], confirmed: true, reason: 'differentAddress'})
      }
    }

    this.notifications.warnings = this.warnings
    this.salesOrder['notifications'] =  this.notifications   


    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Delivery from Previous',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});

  }

  clearForm() {
    this.form.reset();
  }

  submit() {
  }

  cancelForm() {
    // this.form.reset();
    // if (this.item['deliveryAttn'] === null || this.item['deliveryAttn'] === undefined) {
    //   this.item['itemDelivery'] = null;
    // }
    this.bsModalRef.hide();
  }

}
