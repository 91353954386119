import { Routes } from '@angular/router';
import { CustomerFormComponent } from './components/customer/customer-form/customer-form.component';
import { CustomersListComponent } from './components/customer/customers-list/customers-list.component';

import { HomeComponent } from './components/home/home.component';
import { PurcharordersListComponent } from './components/purchaseorders/purcharorders-list/purcharorders-list.component';
import { StockitemslistComponent } from './components/store/stockitemslist/stockitemslist.component';
import { StorefrontComponent } from './components/store/storefront/storefront.component';
import { StoreproductsComponent } from './components/store/storeproducts/storeproducts.component';
import { StyleitemsComponent } from './components/store/styleitems/styleitems.component';
import { StoreconfigurationComponent } from './components/storeconfiguration/storeconfiguration.component';
import { StoreusersComponent } from './components/storeusers/storeusers.component';
import { ReportsListsComponent } from './reports/reports-lists/reports-lists.component';
import { SalesreportComponent } from './reports/salesreport/salesreport.component';
import { AuthGuard } from './_guards/auth.guard';
import { PreventUnsavedChangesCustomer } from './_guards/PreventUnsavedChangesCustomer.guard';
import { CustomerFormResolver } from './_resolvers/customer-form.resolver';
import { SalesOrderFormResolver } from './_resolvers/salesorder-form.resolver';
import { PreventUnsavedChanges } from './_guards/prevent-unsaved-changes.guard';

import { LoginComponent } from './components/login/login.component';
import { UnsavedSalesOrderChanges } from './_guards/unsavedsalesorder.guard';
import { PurchaseorderFormComponent } from './components/purchaseorders/purchaseorder-form/purchaseorder-form.component';
import { PurchaseOrderFormResolver } from './_resolvers/purchaseorder-form.resolver';

import { CardpaymentComponent } from './components/cardpayment/cardpayment.component';

import { AdddevicesComponent } from './components/devices/adddevices/adddevices.component';
import { StoreproductsmobileComponent } from './components/store/mobile/storeproductsmobile/storeproductsmobile.component';
import { StockitemslistmobileComponent } from './components/store/mobile/stockitemslistmobile/stockitemslistmobile.component';

import { SponsorscontributionsComponent } from './components/storeconfiguration/sponsorscontributions/sponsorscontributions.component';
import { AdminguardGuard } from './_guards/adminguard.guard';
import { ManagerGuard } from './_guards/managerguard.guard';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { SalesfrontComponent } from './components/salesorder/salesfront/salesfront.component';
import { SalesorderListFrontComponent } from './components/salesorder/salesorder-list-front/salesorder-list-front.component';
import { CustomerlistGuard } from './_guards/customerlist.guard';
import { SoComponent } from './components/so/so/so.component';
import { SolistComponent } from './components/so/solist/solist.component';
import { ProductTypeComponent } from './components/store/product-type/product-type.component';
import { DefaultComponent } from './components/defaults/default/default.component';
import { InventoryComponent } from './components/store/inventory/inventory.component';





export const appRoutes: Routes = [
    {path: '', component: LoginComponent},
    {
        path: '',
        runGuardsAndResolvers: 'always',
        canActivate: [AuthGuard],
        children: [
             {path: 'home', component: HomeComponent, canActivate: [AdminguardGuard]},
             {path: 'welcome', component: SplashScreenComponent},
             {path: 'card', component: CardpaymentComponent, canActivate: [AdminguardGuard]},
             {path: 'adddevice', component: AdddevicesComponent, canActivate: [AdminguardGuard]},
            {path: 'customers', component: CustomersListComponent,canActivate: [CustomerlistGuard]},//, resolve: {customers: CustomerListResolver}
            {path: 'customers/:id', component: CustomerFormComponent, resolve: {customer: CustomerFormResolver},
            canDeactivate: [PreventUnsavedChangesCustomer]},
            {path: 'customers/add', component: CustomerFormComponent,
            canDeactivate: [PreventUnsavedChangesCustomer]},

            {path: 'salesorder', component: SolistComponent},
            {path: 'salesorder/add', component: SoComponent,canDeactivate: [UnsavedSalesOrderChanges]},
            {path: 'salesorder/:id', component: SoComponent,canDeactivate: [UnsavedSalesOrderChanges]},
            {path: 'salesorder/:id/:edit', component: SoComponent,canDeactivate: [UnsavedSalesOrderChanges]},

            {path: 'styleitems', component: StyleitemsComponent},
            {path: 'storeproducts', component: StoreproductsComponent},
            {path: 'storefront', component: StorefrontComponent},
            {path: 'stockitems', component: InventoryComponent},
            {path: 'purchaseorder', component: PurcharordersListComponent},
            {path: 'purchaseorder/:id', component: PurchaseorderFormComponent,
            resolve: { purchaseorder: PurchaseOrderFormResolver}
          },
            {path: 'reports', component: ReportsListsComponent},
            
            {path: 'help', loadChildren: () => import('./_modules/help/help.module').then(m=>m.HelpModule)},
            
            {path: 'salesreports', component: SalesreportComponent},
            {path: 'storeusers', component: StoreusersComponent},
            {path: 'defaults', component: DefaultComponent},
            {path: 'sponsors', component: SponsorscontributionsComponent},
            {path: 'storeconfig', component: StoreconfigurationComponent, canDeactivate: [PreventUnsavedChanges] },
            {path: 'mobilestore', component: StoreproductsmobileComponent},
            {path: 'stockmobile', component: StockitemslistmobileComponent},
            {path: 'productType', component: ProductTypeComponent},
        ]
    },
    {path: '**', component: LoginComponent, pathMatch: 'full'}
];


