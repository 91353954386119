import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CardReaderService } from 'src/app/_services/cardreader.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { switchMap, takeUntil } from 'rxjs/operators';
import { Observable, Subject  ,of} from 'rxjs';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { CloverSVG } from 'src/app/_directives/clover';
import { SettingsService } from 'src/app/_services/settings.service';

@Component({
  selector: 'app-payments-void',
  templateUrl: './payments-void.component.html',
  styleUrls: ['./payments-void.component.scss']
})
export class PaymentsVOIDComponent implements OnInit ,OnDestroy{

  @Input() settings: any;
  @Input() salesOrder: any = {};
  @Input() paymentsVOID:any = []
  @Input() from:any = ''
  deviceId: string = ""
  devices:any[]=[];
  showCloverSide : boolean =false;
  statusAvalara: string = ''
  flagSelector: boolean = false
  paymentInProcess: any;
  destroy$: Subject<boolean> = new Subject<boolean>();
  cloverIMG:any;

  historyAction = {
    function:'',
    payment:'',
    email:'',
    method:''
  }



  constructor(private modalService: BsModalService,
    private alertify: AlertifyService,
    public cardReaderService: CardReaderService,
    private settingService: SettingsService,
    private authService: AuthService
    ) { }

  ngOnInit(): void {
    this.getCloverDevices();
    this.cloverIMG = CloverSVG
  }

  getCloverDevices(){ 
    this.settingService.getDevicesByUser(this.authService.decodeToken('role').role == 'Dealer'? 'isDealer' : this.authService.currentUser.name)
    .pipe(takeUntil(this.destroy$),
    switchMap((devices: any[])=>{
      this.devices = devices
      if(this.devices.length > 0){
        return of(devices)
      }else{
        return of(null)
      }
    }))   
    .subscribe((devices:any[] | null) =>{
      if(devices == null){
      }else if(devices.length > 0){
        devices.forEach(async (device: any)=>{
          console.log('verify device');            
          device.active = await this.pingAvalara(device)
        })
      }
    })    
  }

  pingAvalara(device): Promise<boolean>{
    let values = {
      deviceId : device.deviceId,
      clientId : 'aedpay',
      bearer : this.settings['authClover'],
    }
    let active: boolean = false
    this.cardReaderService.pingAvalara(values)
    .pipe(takeUntil(this.destroy$))   
    .toPromise().then(
      (res: any) => {
        if(res.connected == true){
          active = true
          device.active = true
        }
      }).catch(( error:any) => {
        active = false
        device.active = false   
    });
    
    return Promise.resolve(active)
  }

  selectCloverDevice(payment){
    if(this.filterActive(this.devices).length > 0){
      this.showCloverSide = true
      this.statusAvalara = 'Select Device'
    }
    if(payment != null){
      this.paymentInProcess = payment;
    }
  }

  filterActive(items:any):any{   
    if(items != null && items.length > 0){   
      return items.filter((e:any) => e.active == true);
    }
    else{
      return []
    }
  }

  printClover(payment: any){

    if(this.filterActive(this.devices).length == 0){
      this.alertify.warning('No active devices')
      return
    }
    if(this.deviceId == ''){
      this.selectCloverDevice(null)
      this.flagSelector = true;
      this.historyAction.function = 'printClover'
      this.historyAction.payment = payment
      return
    }
    this.historyAction.function = ''

    let values = {
      bearer : this.settings['authClover'],
      paymentId : payment.authCode,
      clientId : 'aedpay',
      deviceId : this.deviceId
    }
    this.cardReaderService.getSimplePayment(values)
    .pipe(switchMap((a): Observable<any> =>{
      let msg = "Payment id: "+a.id+"\nAmount charged: $"+(a.amount/100)+"\nDevice: "+a.device.id+"\nStatus: "+a.result;
      alert(msg);
      return this.cardReaderService.printSimplePayment(values)
    }))
    .subscribe(a => {     
    })
    return;
  }

  confirmEmailClover(payment:any, method: string){

    if(this.filterActive(this.devices).length == 0){
      this.alertify.warning('No active devices')
      return
    }

    if(this.deviceId == ''){
      this.selectCloverDevice(null)
      this.flagSelector = true;
      this.historyAction.function = 'confirmEmailClover';
      this.historyAction.payment = payment ;
      this.historyAction.method = method;
      return
    }

    this.historyAction.function = '';

    let additionalData = '';

    if(method == 'EMAIL'){
      additionalData =  (this.salesOrder['email'] != null && this.salesOrder['email'] != undefined && this.salesOrder['email'] != '') 
       ? this.salesOrder['email'] : (this.salesOrder['deliveryEmail'] != null && this.salesOrder['deliveryEmail'] != undefined && this.salesOrder['deliveryEmail'] != '' ? this.salesOrder['deliveryEmail'] : "");
      }else if(method =='SMS'){
      additionalData =  (this.salesOrder['phone1'] != null && this.salesOrder['phone1'] != undefined && this.salesOrder['phone1'] != '') 
       ? this.salesOrder['phone1'] : (this.salesOrder['phone3'] != null && this.salesOrder['phone3'] != undefined && this.salesOrder['phone3'] != '' ? this.salesOrder['phone3'] : "");

    }
   
  this.alertify.prompt(additionalData, `Confirm ${method == 'EMAIL' ? "email": "phone" }`, additionalData,
    
  (evt, value) => {    
    this.emailClover(payment, value, method)
  },

  () => { 
  });

  }

  emailClover(payment: any, email, method: string){
    if(this.deviceId == ''){
      this.selectCloverDevice(null)
      this.flagSelector = true;
      return
    }
    let values = {
      bearer : this.settings['authClover'],
      paymentId : payment.authCode,
      clientId : 'aedpay',
      deviceId : this.deviceId,
      email: email,
      method: method,
    }
    this.cardReaderService.emailSimplePayment(values)
    .subscribe((a: any) =>{
      if(a.printJobStatus == "DONE"){
        this.historyAction.function = '';
        this.alertify.alert("Receipt sent via "+method, ()=>{});
      }
      // console.log(a)
    })
  }

  showCard(deviceId) {
    this.deviceId = deviceId
    this.flagSelector = false
    this.showCloverSide = false  
    if(this.historyAction.function != ''){
      if(this.historyAction.function == 'confirmEmailClover'){
        this.confirmEmailClover(this.historyAction.payment,this.historyAction.method);
      }else if(this.historyAction.function == 'printClover'){
        this.printClover(this.historyAction.payment);
      }
    }
  }

  getIndex(id): number{
    return this.paymentsVOID.findIndex(e=>e.paymentID == id)
  }

  closeModal(){
    this.modalService.hide();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
