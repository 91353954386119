import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { PaginatedResult } from '../_modules/pagination';
import { Customer } from '../_modules/customers';
import { map } from 'rxjs/operators/map';
import { AlertifyService } from './alertify.service';
import { SettingsService } from './settings.service';
import * as _ from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class SalesOrderService {
baseUrl = environment.apiUrl;
laravel = environment.apiLaravel;
editSalesOrder = new BehaviorSubject<string>('');
isCollapse = new BehaviorSubject<boolean>(false);
fromautosavedlist = new BehaviorSubject<boolean>(false);

showHeader = new BehaviorSubject<boolean>(true);

addCustomerSalesOrder = new BehaviorSubject<string>('');
idautosaved = new BehaviorSubject<string>('');

triggerSaveSO = new BehaviorSubject<boolean>(false);
public changedHeader = new BehaviorSubject<any>('');
currentHeder = this.changedHeader.asObservable();
salesTax = new BehaviorSubject<any>({});
delivery = new BehaviorSubject<any>({});
sofrom = new BehaviorSubject<any>('');

changeheaderFromUnsaved = new BehaviorSubject<any>('');


// search Sales orders
saveDataSearch = new BehaviorSubject<any>(1);

normalOrAdvsearch = new BehaviorSubject<any>('');
eventsearch = new BehaviorSubject<any>('');

showParkedOrProcessed = new BehaviorSubject<any>('');
normalsearch = new BehaviorSubject<any>('');
pagNormalSearchProcessed = new BehaviorSubject<any>(1);
pagNormalSearchParked = new BehaviorSubject<any>(1);

advancesearchform = new BehaviorSubject<any>({});
pagAdvSearch = new BehaviorSubject<any>(1);





constructor(private http: HttpClient, private settingsService: SettingsService
  ) { }

  // sendMessage(values: socketRes) {
  //   this.socket.emit('editingso', values);
  // }
  // connection() {
  //   return this.socket.fromEvent('user_connected').pipe(tap((res:any)=> {
  //     console.log(res)
  //     localStorage.setItem('socket_user_id', res?.socket_user_id)
  // }));
  // }

  // check_so() {
  //   return this.socket.fromEvent('check_so');
  // }

addSalesOrder(salesOrder: any) {
  return this.http.post(this.laravel + 'salesorder', salesOrder);
}
getItemById(id: any) {
  return this.http.get(this.laravel + 'getItemById/'+id);
}
getBase64Receipt(data:any){
  return this.http.post(this.laravel + 'getBase64Receipt', data);
}

updateRoleInSO(id: string, values) {
  return this.http.post(this.laravel + 'updateRoleInSO/' + id, values);
}

validateAddress(values) {
  return this.http.post(this.laravel + 'validateAddress', values);
}

getSOsAutoSaved(user:any) {
  return this.http.post(this.laravel + 'getSOsAutoSaved',user);
}
deleteSOsAutoSaved(SOID:any) {
  return this.http.get(this.laravel + 'deleteSOsAutoSaved/'+SOID);
}
updateSalesOrder(id: string, salesOrder) {
  return this.http.patch(this.laravel + 'salesorder/' + id, salesOrder);
}
updateCustomerOnSO( data) {
  return this.http.post(this.laravel + 'updateCustomerOnSO', data);
}

forceUpdatePayments(id: string, salesOrder) {
  return this.http.post(this.laravel + 'forceUpdatePayments/' + id, salesOrder);
}

updateDeliverySOItem(SOItemsID:string, tobeDelivered:string){
  return this.http.get(this.laravel + `updateDeliverySOItem/${SOItemsID}/${tobeDelivered}`);
}

saveOrderAutosave(params:any) {
  return this.http.post(this.laravel + 'saveOrderAutosave',params);
}
saveDateSo(params:any) {
  return this.http.post(this.laravel + 'saveDateSo',params);
}
getOrderAutosave(params:any) {
  return this.http.post(this.laravel + 'getOrderAutosave',params);
}
updateSOOnly(id: number, salesOrder) {
return this.http.patch(this.laravel + 'salesorderupdateSOOnly/' + id, salesOrder);
}
deleteSalesOrder(id: number) {
  return this.http.delete(this.laravel + 'salesorder/' + id);
}
revertSalesOrder(id: number) {
  return this.http.get(this.laravel + 'revertSOtoPreviousStatus/' + id);
}

getSalesOrders(page?, itemsPerPage?,  search?, searchEvent?, order?, orderDir?, dateFilter?,
  statusFilter?, excludeFilter?,customerIdFilter?) {
  let params = new HttpParams();
  const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();


  if ( page != null && itemsPerPage != null) {
    params = params.append('page', page);
    params = params.append('per_page', itemsPerPage);

  }

  if (search != null) {
    params = params.append('search', search);
  }
  if (order != null) {
    params = params.append('order', order);
  }
  if (orderDir != null) {
    params = params.append('orderDir', orderDir);
  }

  if (dateFilter != null) {
    params = params.append('dateFilter', dateFilter);
  }

  if (statusFilter != null) {
    params = params.append('statusFilter', statusFilter);
  }
  
  if (excludeFilter != null) {
    params = params.append('excludeFilter', excludeFilter);
  }


  if (searchEvent != null) {
    params = params.append('searchEvent', searchEvent);
  }

  if (customerIdFilter != null) {
  params = params.append('customerId', customerIdFilter);
  }

  return this.http.get<any>(this.laravel + 'salesorder', {observe: 'response', params})
  .pipe(
    map( response => {
      paginatedResult.result = (response.body.data);
      if (response.body.total != null) {
        paginatedResult.pagination = {
          totalItems: JSON.parse(response.body.total),
          totalPages: JSON.parse(response.body.last_page),
          currentPage: JSON.parse(response.body.current_page),
          itemsPerPage: JSON.parse(response.body.per_page)
        };
      }
      return paginatedResult;
    })
  );
}

salesOrderByCustomer(id:any){
  return this.http.get<any>(this.laravel + 'salesOrderByCustomer/'+id);
}

getSalesOrdersSpecific(page?, itemsPerPage?,  search?, searchEvent?, order?, orderDir?, dateFilter?,
  statusFilter?, excludeFilter?,customerIdFilter?, fields?:any[]) {
  let params = new HttpParams();
  const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();


  if ( page != null && itemsPerPage != null) {
    params = params.append('page', page);
    params = params.append('per_page', itemsPerPage);

  }

  if (search != null) {
    params = params.append('search', search);
  }
  if (order != null) {
    params = params.append('order', order);
  }
  if (orderDir != null) {
    params = params.append('orderDir', orderDir);
  }

  if (dateFilter != null) {
    params = params.append('dateFilter', dateFilter);
  }

  if (statusFilter != null) {
    params = params.append('statusFilter', statusFilter);
  }
  
  if (excludeFilter != null) {
    params = params.append('excludeFilter', excludeFilter);
  }


  if (searchEvent != null) {
    params = params.append('searchEvent', searchEvent);
  }

  if (customerIdFilter != null) {
  params = params.append('customerId', customerIdFilter);
  }

  return this.http.post<any>(this.laravel + 'salesorderspecific', fields, {observe: 'response', params})
  .pipe(
    map( response => {
      paginatedResult.result = (response.body.data);
      if (response.body.total != null) {
        paginatedResult.pagination = {
          totalItems: JSON.parse(response.body.total),
          totalPages: JSON.parse(response.body.last_page),
          currentPage: JSON.parse(response.body.current_page),
          itemsPerPage: JSON.parse(response.body.per_page)
        };
      }
      return paginatedResult;
    })
  );
}

searchAdvanceSO(params:any){
  return this.http.post(this.laravel + 'searchAdvanceSO' , params);
}
indexsearchadvance(){  
  return this.http.get(this.laravel + 'indexsearchadvance');
}

searchAdvanceSOdetails(soid:any){
  return this.http.get(this.laravel + 'searchAdvanceSOdetails/' + soid);
}

  getSalesOrdersSummary(page?, itemsPerPage?,  search?, searchEvent?, order?, orderDir?, dateFilter?, statusFilter?,
    excludedFilter?, SOID?) {
    let params = new HttpParams();
    const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();

    if ( page != null && itemsPerPage != null) {
      params = params.append('page', page);
      params = params.append('per_page', itemsPerPage);

    }

    if (search != null) {
      params = params.append('search', search);
    }
    if (order != null) {
      params = params.append('order', order);
    }
    if (orderDir != null) {
      params = params.append('orderDir', orderDir);
    }

    if (dateFilter != null) {
      params = params.append('dateFilter', dateFilter);
    }

    if (statusFilter != null) {
      params = params.append('statusFilter', statusFilter);
    }

    if (excludedFilter != null) {
      params = params.append('excludedFilter', excludedFilter);
    }

    if (SOID != null) {
      params = params.append('SOID', SOID);
    }


    if (searchEvent != null) {
      params = params.append('searchEvent', searchEvent);
    }

    return this.http.get<any>(this.laravel + 'salesordersummary', {observe: 'response', params})
    .pipe(
      map( response => {
        paginatedResult.result = (response.body.data);
        if (response.body.total != null) {
          paginatedResult.pagination = {
            totalItems: JSON.parse(response.body.total),
            totalPages: JSON.parse(response.body.last_page),
            currentPage: JSON.parse(response.body.current_page),
            itemsPerPage: JSON.parse(response.body.per_page)
          };
        }
        return paginatedResult;
      })
    );
    }

  updateTrackingnumber(data:any){
    return this.http.post(this.laravel + 'updateTrackingnumber' , data);
  }



  getCustomer(id): Observable<Customer> {
    return this.http.get<Customer>(this.laravel + 'customers/' + id);
  }

  getSalesOrder(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'salesorder/' + id);
  }

  getInfoStore(db): Observable<any> {
    return this.http.post<any>(this.laravel + 'infoStore' , db);
  }
  openCashDrawer(): Observable<any> {
    return this.http.post(this.laravel + 'openCashDrawer', {});
  }
  

  getSalesOrderDay(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'getSalesOrderDay/' + id);
  }

  voidSalesOrder(soid): Observable<any> {
    return this.http.get<any>(this.laravel + 'voidSalesOrder/' + soid);
  }

  restartSO(soid): Observable<any> {
    return this.http.get<any>(this.laravel + 'restartSO/' + soid);
  }

  getInvoice(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'getInvoice/' + id);
  }

  getPO(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'po/' + id);
  }

  getSalesOrderItems(id): Observable<any> {
    return this.http.get<any>(this.laravel + 'salesorderitems/' + id);
  }

  getSalesReport(report?, event?, dateFrom?, dateTo?, status?, parameter?, dataparameter?,setFor?): Observable<any> {
    let params = new HttpParams();
    if ( event != null) {
      params = params.append('event', event);
    }


    if ( dateFrom != null) {
      params = params.append('dateFrom', dateFrom);
    }

    if ( dateTo != null) {
      params = params.append('dateTo', dateTo);
    }

    if ( parameter != null) {
      params = params.append('parameter', parameter);
    }

    params = params.append('report', report);

    if ( dataparameter != null) {
      if (parameter === 'DeliveryRequest') {
        params = params.append('SODelivery', dataparameter);
      }

      if (parameter === 'itemDelivery') {
        params = params.append('itemDelivery', dataparameter);
      }

    }

    if (report === 'ProductPerformance') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'getProductPerforming', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    if (report === 'POReport') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'getPOReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      )
    }

    if (report === 'POItemsReport') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'getPOItemsReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    if (report === 'ProductPerformanceByCincinnati') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'ProductPerformanceByCincinnati', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }


    if (report === 'StatsReportByManufacturer') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'StatsReportByManufacturer', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    if (report === 'StatsReportBySaltLakeCity') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'StatsReportByManufacturer', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    if (report === 'EmailCustomersBySponsor') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'EmailCustomersBySponsor', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    if (report === 'EmailCustomersBySponsorExcel') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'EmailCustomersBySponsor', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    if (report === 'KansasCityReportByBilling') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'getKansasCityReportByBilling', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    if (report === 'KansasCityReportByShipping') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'getKansasCityReportByShipping', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }
    

    if (report === 'PackingSlip') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'getPackingSlip', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    if (report === 'CincinnatiPackingSlip') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'getCincinnatiPackingSlip', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    
    if (report === 'DenverPackingSlip') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'getCincinnatiPackingSlip', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }


    if (report === 'ProductPerformanceBySize') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'getProductPerformingBySize', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    

    if (report === 'AllSalesOrders') {
      let SalesOrders: any[];
      let result: any;
      return this.http.get<any []>(this.laravel + 'AllSalesOrders', {params})
      .pipe(
        map( response => {
          SalesOrders = response;

      // let contactinfo = '';
      // this.settingsService.getSettings(0)
      // .pipe(take(1))
      // .subscribe( data => {
      //   const settings = data[0];
      //     contactinfo = settings['contactInfo'];
      //     contactinfo = (contactinfo.toString().replace(/\r/g, '<br>' ));
      //     contactinfo = (contactinfo.toString().replace(/\n/g, '<br>' ));
  
      //     SalesOrders.map((so:any)=>{
      //         if (so['disc1exp']) {
      //           so['disc1exp'] = atob(so['disc1exp']);
      //         }
      //         if (so['disc2exp']) {
      //           so['disc2exp'] = atob(so['disc2exp']);
      //         }
      //         if (so['disc3exp']) {
      //           so['disc3exp'] = atob(so['disc3exp']);
      //         }
      //         if (so['disc4exp']) {
      //           so['disc4exp'] = atob(so['disc4exp']);
      //         }
      //         if (so['orderSigexp']) {
      //           so['orderSigexp'] = atob(so['orderSigexp']);
      //         }

      //       so['contactInfo'] = contactinfo;
      //     })
         
      // });

      return SalesOrders;
     
        })
      );
    }


    

    if (report === 'SalesReport' || report === 'WholeSalesReport') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'salesReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }
    // WORKS FOR LAYAWAY, TOTALS AND PARTIAL
    if (report === 'PartialReport' || report === 'LayawayReport') {
      let result: any;
    
      if (report === 'PartialReport') {
        params = params.append('status', 'Partial');
      }
      if (report === 'LayawayReport') {
        params = params.append('status', 'Layaway');
      }
      params = params.append('report', report);
      
      return this.http.get<any []>(this.laravel + 'totalsReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    if (report === 'TotalsReport') {
      let result: any;
      if (report === 'TotalsReport') {
        params = params.append('status', '');
      }
      params = params.append('report', report);      
      return this.http.get<any []>(this.laravel + 'totalsMonthReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    if (report === 'CustomersReport' || 
    report === 'CustomersDetailReport' || 
    report === 'ReferralReport' 
    || report === 'DeliveryReport'  
    
    || report === 'DeliveryCompletedReport' || report === 'DeliveryPendingReport'
    || report === 'ProductReport'
    || report === 'SchoolReferralReport' || report === 'DispatchTrackReport' || report === 'DispatchOmahaReport' || report === 'DispatchKentuckyReport' 
    || report === 'SchoolReferralWithPricesReport' || report === 'DispatchTrackWithDetailsReport' 
    || report === 'DispatchTrackWithDetailsReport' 
    || report === 'DispatchTrackReportUniversal' 
    || report === 'DispatchTrackWithDetailsReportUniversal' 
    || report === 'DispatchTrackWithDetailsReportUniversalDeliveries' 
    || report === 'PaymentTypesReport' 
    || report === 'PaymentTypesReportDetails' 
    ) {

      let result: any;
  
      return this.http.get<any []>(this.laravel + 'customersReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );

    }

    if (report === 'SalesPersonPerformanceReport') {

      let result: any;
      return this.http.get<any []>(this.laravel + 'customersWithDiscountReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );

    }


    if (report === 'DeliveryPerProductReport' 
    || report === 'Avery5161Report'
    || report === 'Avery5163Report'
    || report === 'Avery5164Report'
    || report === 'Avery5161FixReport'
    || report === 'Avery5163FixReport'
    || report === 'Avery5164FixReport'
    ) {
      let result: any;
      params = params.append('setFor', setFor);
      return this.http.get<any []>(this.laravel + 'deliveryPerProductReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }


    if (report === 'SecondDeliveryReport') {

      let result: any;
      return this.http.get<any []>(this.laravel + 'secondDeliveryReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );

    }


        if (report === 'ThirdDeliveryReport') {

      let result: any;
      return this.http.get<any []>(this.laravel + 'thirdDeliveryReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );

    }

    if (report === 'RockfordDeliveryReport' ) {
      let result: any;
      return this.http.get<any []>(this.laravel + 'rockfordDeliveryReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }
    
    if (report === 'AvalaraBreakDownSummaryReport') {
      let result: any;
      return this.http.get<any []>(this.laravel + 'AvalaraBreakDownSummaryReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
    }

    if (report === 'EventSummary' || report === 'EventSummaryComplete' ) {
      let result: any;
      return this.http.get<any []>(this.laravel + 'eventsReport', {params})
      .pipe(
        map( response => {
          result = (response);
          return result;
        })
      );
      }


      if (report === 'EventSummaryWithTaxes') {
        let result: any;
        return this.http.get<any []>(this.laravel + 'eventsReportWithTaxes', {params})
        .pipe(
          map( response => {
            result = (response);
            return result;
          })
        );
        }

        if (report === 'EventSummaryWithTaxesByDate') {
          let result: any;
          return this.http.get<any []>(this.laravel + 'eventsReportWithTaxes', {params})
          .pipe(
            map( response => {
              result = (response);
              return result;
            })
          );
          }

        if (report === 'EventSummaryWithTaxesByMonth') {
          let result: any;
          return this.http.get<any []>(this.laravel + 'eventsReportWithTaxes', {params})
          .pipe(
            map( response => {
              result = (response);
              return result;
            })
          );
          }
        

    if (report === 'EventContributionReport') {
      let result: any;
    
      return this.http.get<any>(this.laravel + 'getEventTypeSummaryContributionReport',
      {params})
      .pipe(
        map( response => {
              result  = (response);
              return result;
            
          })
        );
    }

    if (report === 'EventContributionByTeamsReport' || report === 'EventContributionByTeamsReportSponsor' ||
    report === 'EventContributionByTeamsReportReferral') {
      let result: any;
    
      return this.http.get<any>(this.laravel + 'getEventTypeSummaryContributionByTeamsReport',
      {params})
      .pipe(
        map( response => {
              result  = (response);
              return result;
            
          })
        );
    }

    if (report === 'CommissionsPayoutsWholeSale' || report === 'CommissionsPayouts') {
      let result: any;
    
      return this.http.get<any>(this.laravel + 'getCommissionsPayouts',
      {params})
      .pipe(
        map( response => {
              result  = (response);
              return result;
            
          })
        );
    }


    if (report === 'EventContributionByStylesReport') {
      let result: any;
    
      return this.http.get<any>(this.laravel + 'getEventTypeSummaryContributionByStylesReport',
      {params})
      .pipe(
        map( response => {
              result  = (response);
              return result;
            
          })
        );
    }


    return this.http.get<any>(this.laravel + 'salesReport/', {params});
  }

  getSONumber(id) {
    return this.http.get(this.laravel + 'getSONumber/' + id);
  }

  getDraftNumber() {
    return this.http.get(this.laravel + 'getDraftNumber');
  }



  triggerSave(): Observable<any>  {
    return new Observable<true>();
  }


  changeSOHeder(m) {
    this.changedHeader.next(m);
  }

  getObjectDifference(obj1: any, obj2: any): any {
    const diff = {};

    this.compareObjects(obj1, obj2, '', diff);

    return diff;
  }

  private compareObjects(obj1: any, obj2: any, currentPath: string, diff) {
    const keys = _.union(_.keys(obj1), _.keys(obj2));

    for (const key of keys) {
      const newPath = currentPath ? `${key} ${parseInt(currentPath) + 1}` : key;
      if(key == 'lineAvalara' || key == 'isItemTaxable' || key == 'taxCalculated' || key == "avalaraActive"){

      }else{
        if (_.isObject(obj1[key]) && _.isObject(obj2[key])) {
          if (_.isArray(obj1[key]) && _.isArray(obj2[key])) {
            if (!_.isEqual(obj1[key], obj2[key])) {
              diff[newPath] = { old: obj1[key], new: obj2[key] };
            }
          } else {
            if (!_.isEqual(_.keys(obj1[key]), _.keys(obj2[key]))) {
              diff[newPath] = { old: obj1[key], new: obj2[key] };
            } else {
              this.compareObjects(obj1[key], obj2[key], newPath, diff);
            }
          }
        } else if (obj1[key] !== obj2[key]) {
          diff[newPath] = { old: obj1[key], new: obj2[key] };
        }
      }
      }
  }

  

}

// const valueNames ={
//   deliveryAmountNum  : "Delivery Amount"
//   deliveryAmountNum  : "Delivery Amount"
//   deliveryAmountNum  : "Delivery Amount"
//   deliveryAmountNum  : "Delivery Amount"
//   deliveryAmountNum  : "Delivery Amount"
// }
