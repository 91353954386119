<div *ngIf="!showEditPayment" style="padding-bottom: 10px;">
    <div class="sale-order-dropdown-container-items-item tw-border-2 tw-border-aedgraydark tw-rounded-md" *ngFor="let pay of filterPayments(payments)">
        <div class="sale-order-dropdown-container-items-item-content">
          <p class="item-type tw-font-semibold tw-text-lg"><strong *ngIf="pay.voidtype_tx != undefined && pay.voidtype_tx != null">(VOIDED)</strong>{{pay.paymentType}}</p>
          <p class="item-amount tw-font-semibold tw-text-lg">$ {{pay.paymentAmount | number:'1.2-2'}}</p>
        </div>
        <div class="sale-order-dropdown-container-items-item-note" *ngIf="pay.Note">
          <div class="note-title">
            <p class="tw-font-semibold tw-text-lg">Note: {{pay.Note}}</p>
          </div>
          <!-- <p>{{pay.Note}}</p> -->
          <!-- <div class="row">
            <div class="col-md-6">Approval Code: {{pay.authCode}}</div>
            <div class="col-md-6">Last Four Digits: {{pay.cardLastFour}}</div>
            <div class="col-md-6">Card Type: {{pay.cardType}}</div>
            <div class="col-md-12">Name on Card: {{pay.nameOnCard}}</div>
          </div> -->
        </div>
      
        <div class="sale-order-dropdown-container-items-item-note" *ngIf="pay.authCode">
          <div class="note-title">
            <h5></h5>
          </div>
          <small>
          <div class="row">
            <div class="col-md-6">Approval Code: {{pay.authCode}}</div>
            <div class="col-md-6">Last Four Digits: {{pay.cardLastFour}}</div>
            <div class="col-md-6">Card Type: {{pay.cardType}}</div>
            <div class="col-md-12">Name on Card: {{pay.nameOnCard}}</div>
          </div>
        </small>
        </div>
      
      </div>
</div>






  <div *ngIf="showEditPayment" class="">
    <!-- <button class="btn btn-3" 
    *ngIf="creditForm" (click)="creditForm = !creditForm"> <i class="fa fa-chevron-left"></i> Back</button>
    <app-closebutton></app-closebutton>
    <div class="modal-body-header" *ngIf="creditForm === false">
      <h4>Payments</h4>
    </div>
    <div class="modal-body-header" *ngIf="creditForm">
      <h4>Debit / Credit Card</h4>
    </div> -->
  
    <div  *ngIf="creditForm === false">

    <div class="d-flex flex-column align-items-end tw-text-base tw-font-semibold tw-text-aedgrayl">
      <div *ngIf="salesOrder != undefined">Total  $ {{salesOrder?.Total | number:'1.2-2'}}</div>
      <div class="">Payment  $ {{paymentTotal | number:'1.2-2'}}</div>

      <div *ngIf="toBePayed != 0">
        <hr class="mt-1 mb-1">
        <div *ngIf="toBePayed > 0">Balance $ {{toBePayed | number:'1.2-2'}}</div>
        <hr class="mt-1 mb-1">
        <div *ngIf="toBePayed < 0" class="text-danger">Change $ {{toBePayed*-1 | number:'1.2-2'}}</div>
      </div>

    </div>

    <form  [formGroup]="form" (ngSubmit)="submit(model)" class="form-container">
      <formly-form [form]="form" [fields]="fields" [model]="model" [options]="options" class="w-100">
          <!-- <div class="form-buttons-container">
              <button class="btn btn-5 mr-2"
              [disabled]="!form.valid" type="sumbit"> 
                <span class="material-icons">
                  save
                </span> Save
              </button>
              <button class="btn btn-6" (click)="cancelForm()"  type="button"> Cancel</button>
          </div> -->
      </formly-form>
    </form>
  </div>

<!-- no se usa -->
  <div *ngIf="creditForm === true">
    
    <app-cardprocessed *ngIf="cardPaymentProcessed" [sopayment] = "cardPayment"></app-cardprocessed>
    <app-cardpayment *ngIf="cardPaymentProcessed === false" [sopayment] = "cardPayment"></app-cardpayment>
  </div>
  
  </div>

  