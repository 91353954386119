import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule, NO_ERRORS_SCHEMA,  APP_INITIALIZER, DEFAULT_CURRENCY_CODE, LOCALE_ID} from '@angular/core';
import { NgSelectModule } from '@ng-select/ng-select';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserModule } from '@angular/platform-browser';
import { Router, RouterModule } from '@angular/router';
import { JwtInterceptor, JwtModule } from '@auth0/angular-jwt';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { LoadingPageModule, MaterialBarModule } from 'angular-loading-page';
import { TextMaskModule } from 'angular2-text-mask';

import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FormlyFieldInput } from '../app/controls/input/input.component';


import { AppComponent } from './app.component';
import { CustomerAddressComponent } from './components/customer/customer-address/customer-address.component';
import { CustomerCardComponent } from './components/customer/customer-card/customer-card.component';
import { CustomerFormComponent } from './components/customer/customer-form/customer-form.component';
import { CustomerInfoComponent } from './components/customer/customer-info/customer-info.component';
import { CustomersListComponent } from './components/customer/customers-list/customers-list.component';
import { CustomertInfoComponent } from './components/customer/customert-info/customert-info.component';

import { DummymodalComponent } from './components/dummymodal/dummymodal.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { NavComponent } from './components/nav/nav.component';
import { PurcharordersListComponent } from './components/purchaseorders/purcharorders-list/purcharorders-list.component';
import { AddsalesorderitemComponent } from './components/salesorder/addsalesorderitem/addsalesorderitem.component';
import { ChoosestatusComponent } from './components/salesorder/choosestatus/choosestatus.component';
import { ChoosestorestockComponent } from './components/salesorder/choosestorestock/choosestorestock.component';
import { CommentsComponent } from './components/salesorder/comments/comments.component';
import { DeliveryFormComponent } from './components/salesorder/delivery-form/delivery-form.component';
import { DeliveryComponent } from './components/salesorder/delivery/delivery.component';
import { HeaderComponent } from './components/salesorder/header/header.component';
import { PaymentsFormComponent } from './components/salesorder/payments/payments-form/payments-form.component';
import { PaymentsComponent } from './components/salesorder/payments/payments.component';
import { SalesorderFormComponent } from './components/salesorder/salesorder-form/salesorder-form.component';
import { SalesorderSearchComponent } from './components/salesorder/salesorder-search/salesorder-search.component';
import { SalesorderTaxesComponent } from './components/salesorder/salesorder-taxes/salesorder-taxes.component';
import { ItemdeliveryComponent } from './components/salesorder/salesorderitems/itemdelivery/itemdelivery.component';
import { SalesorderitemsComponent } from './components/salesorder/salesorderitems/salesorderitems.component';
import { SalesordertotalComponent } from './components/salesorder/salesordertotal/salesordertotal.component';
import { StockitemslistComponent } from './components/store/stockitemslist/stockitemslist.component';
import { StorefrontComponent } from './components/store/storefront/storefront.component';
import { AddstockitemComponent } from './components/store/storeproducts/addstockitem/addstockitem.component';
import { StoreproductsComponent } from './components/store/storeproducts/storeproducts.component';
import { StyleitemsComponent } from './components/store/styleitems/styleitems.component';
import { StoreconfigurationComponent } from './components/storeconfiguration/storeconfiguration.component';
import { StoreusersComponent } from './components/storeusers/storeusers.component';
import { StoreusersformComponent } from './components/storeusers/storeusersform/storeusersform.component';
import { TitlebarComponent } from './components/titlebar/titlebar.component';
import { ClosebuttonComponent } from './controls/closebutton/closebutton.component';
import { CollapseControlComponent } from './controls/collapse-control/collapse-control.component';
import { FormwrapComponent } from './controls/formwrap/formwrap.component';
import { GridtableComponent } from './controls/gridtable/gridtable.component';
import { FileValueAccessor } from './controls/input-file/file-value-accessor.ts';
import { FormlyFieldFile } from './controls/input-file/input-file.component';
import { RepeatComponent } from './controls/repeat/repeat.component';
import { TitleComponent } from './controls/title/title.component';
import { InvoicereportComponent } from './reports/invoicereport/invoicereport.component';
import { ReportsListsComponent } from './reports/reports-lists/reports-lists.component';
import { SalesreportComponent } from './reports/salesreport/salesreport.component';
import { appRoutes } from './routes';
import { ClickPanelDirective } from './_directives/click-panel.directive';
import { AuthGuard } from './_guards/auth.guard';
import { PreventUnsavedChanges } from './_guards/prevent-unsaved-changes.guard';
import { PreventUnsavedChangesCustomer } from './_guards/PreventUnsavedChangesCustomer.guard';
import { CustomerFormResolver } from './_resolvers/customer-form.resolver';
import { CustomerListResolver } from './_resolvers/customer-list.resolver.ts';
import { ListsResolver } from './_resolvers/lists.resolver';
import { MemberDetailResolver } from './_resolvers/member-detail.resolver';
import { MemberEditResolver } from './_resolvers/member-editresolver';
import { MemberListResolver } from './_resolvers/member-list.resolver';
import { MessagesResolver } from './_resolvers/messages.resolver';
import { SalesOrderFormResolver } from './_resolvers/salesorder-form.resolver';
import { SalesorderListResolver } from './_resolvers/salesorder-list.resolver';
import { AlertifyService } from './_services/alertify.service';
import { TokenInterceptor } from './_services/auth.interceptor';
import { AuthService } from './_services/auth.service';
import { CustomerService } from './_services/customer.service';
import { ErrorInterceptorProvider } from './_services/error.interceptor';
import { GlobalsService } from './_services/globals.service';
import { SalesOrderService } from './_services/salesorder.service';
import { StoreproductsService } from './_services/storeproducts.service';
import { StyleitemsService } from './_services/styleitems.service';
import { UserService } from './_services/user.service';
import { UpcitemdbService } from './_services/upcitemdb.service';
import { RepeatSmallComponent } from './controls/repeatsmalll/repeatsmall.component';
import { LanguageService } from'./_services/language.service';
import { CountoModule } from 'angular2-counto';
import { SalesordersummaryComponent } from './components/salesorder/salesordersummary/salesordersummary.component';
import { SendemailComponent } from './reports/sendemail/sendemail.component';
import { UnsavedSalesOrderChanges } from './_guards/unsavedsalesorder.guard';
import { AddressComponent } from './components/salesorder/address/address.component';
import { AddressCardComponent } from './components/salesorder/address-card/address-card.component';
import { PurchaseorderFormComponent } from './components/purchaseorders/purchaseorder-form/purchaseorder-form.component';
import { PurchaseorderItemsComponent } from './components/purchaseorders/purchaseorder-items/purchaseorder-items.component';
import { PurchaseOrderFormResolver } from './_resolvers/purchaseorder-form.resolver';
import { TrendModule } from 'ngx-trend';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FullCalendarModule } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin


import { CardpaymentComponent } from './components/cardpayment/cardpayment.component';
import { FormlyFieldTypeahead } from './controls/formly-field-typeahead/formly-field-typeahead.component';


import { DevicesComponent } from './components/devices/devices.component';
import { AdddevicesComponent } from './components/devices/adddevices/adddevices.component';
import { SalesorderSearchtypeComponent } from './components/salesorder/salesorder-searchtype/salesorder-searchtype.component';
import { ProductTypeComponent } from './components/store/product-type/product-type.component';

import { DeliveryperproductComponent } from './components/reports/deliveryperproduct/deliveryperproduct.component';
import { SalesorderAlertComponent } from './components/salesorder/salesorder-alert/salesorder-alert.component';
import { ChoosedeviceComponent } from './components/devices/choosedevice/choosedevice.component';
import { CardsignatureComponent } from './components/cardpayment/cardsignature/cardsignature.component';
import { CardpinpadComponent } from './components/cardpayment/cardpinpad/cardpinpad.component';
import { FormlyFieldButton } from './controls/button-type/button-type.component';
import { RepeatPaymentsComponent } from './controls/repeatpayments/repeatpayments.component';
import { CardprocessedComponent } from './components/cardpayment/cardprocessed/cardprocessed.component';
//import { NgxElectronModule } from 'ngx-electron';
import { SvgIconComponent } from './reports/svg-icon/svg-icon.component';
import { StoreproductsmobileComponent } from './components/store/mobile/storeproductsmobile/storeproductsmobile.component';
import { StyleitemsmobileComponent } from './components/store/mobile/styleitemsmobile/styleitemsmobile.component';
import { SponsorscontributionsComponent } from './components/storeconfiguration/sponsorscontributions/sponsorscontributions.component';
import { StockitemslistmobileComponent } from './components/store/mobile/stockitemslistmobile/stockitemslistmobile.component';
//Pagination
import {NgxPaginationModule} from 'ngx-pagination';
import { PurchasedetailsComponent } from './components/purchaseorders/purchasedetails/purchasedetails.component';
import { SettingspopupComponent } from './components/nav/settingspopup/settingspopup.component';
import { ItemmenuComponent } from './components/itemmenu/itemmenu.component';
import { DragDirective } from './_directives/dragDrop.directive';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { NgxColorsModule } from 'ngx-colors';
import { NotificationsComponent } from './components/notifications/notifications.component';
import {WaMatConfirmDialogModule} from '@webacad/material-confirm-dialog';
import { CommonModule } from '@angular/common';

import { HasRoleDirective } from './_directives/has-role.directive';
import { SharedModule } from './_modules/shared/shared.module';
import { SignaturePadModule } from 'angular2-signaturepad';
import { LoadingBarRouterModule } from '@ngx-loading-bar/router';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { StoreProductsReportComponent } from './reports/store-products-report/store-products-report.component';
import { GoogleTagManagerModule } from 'angular-google-tag-manager';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { SalesfrontComponent } from './components/salesorder/salesfront/salesfront.component';
import { SalesorderFormobileComponent } from './components/salesorder/salesorder-formobile/salesorder-formobile.component';
import { MatCardModule } from '@angular/material/card';
import { MatButtonModule } from '@angular/material/button';
import { HammerModule} from '@angular/platform-browser';
import { WaitSTComponent } from './components/wait-st/wait-st.component';

import * as Hammer from 'hammerjs';
import { HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { SwipeListModule } from '@nhochdrei/angular-swipe-list';
import { HeaderMobileComponent } from './components/salesorder/header-mobile/header-mobile.component';
import { AddressCardMobileComponent } from './components/salesorder/address-card-mobile/address-card-mobile.component';
import { CustomerFormMobileComponent } from './components/customer/customer-form-mobile/customer-form-mobile.component';
import { CustomerListMobileComponent } from './components/customer/customer-list-mobile/customer-list-mobile.component';
import { AddressMobileComponent } from './components/salesorder/address-mobile/address-mobile.component';
import { DeliveryMobileComponent } from './components/salesorder/delivery-mobile/delivery-mobile.component';
import { RepeatMobileComponent } from './controls/repeat-mobile/repeat-mobile.component';
import { PaymentsMobileComponent } from './components/salesorder/payments-mobile/payments-mobile.component';
import { SalesordertotalMobileComponent } from './components/salesorder/salesordertotal-mobile/salesordertotal-mobile.component';
import { SalesorderitemsMobileComponent } from './components/salesorder/salesorderitems-mobile/salesorderitems-mobile.component';
import { StoreproductsMobileComponent } from './components/store/storeproducts-mobile/storeproducts-mobile.component';
import { ChoosestorestockMobileComponent } from './components/salesorder/choosestorestock-mobile/choosestorestock-mobile.component';
import { FloorItemsComponent } from './components/floor-items/floor-items.component';
import { SalesorderListFrontComponent } from './components/salesorder/salesorder-list-front/salesorder-list-front.component';
import { SalesPersonListSOComponent } from './components/salesorder/sales-person-list-so/sales-person-list-so.component';
import { DeliveryInfoComponent } from './components/delivery-info/delivery-info.component';
import { SpellCheckerModule } from 'ngx-spellchecker';
import { SelectInputComponent } from './controls/select-input/select-input.component';
import { SelectInputBoldComponent } from './controls/select-input-bold/select-input-bold.component';
import { NgxMaskModule, IConfig } from 'ngx-mask'
import { RepeatSmallProgramComponent } from './controls/repeatsmallprogram/repeatsmallprogram';
import { SendemailLocalComponent } from './reports/sendemail-local/sendemail-local.component';
import { ScannitemsiosComponent } from './components/scannitemsios/scannitemsios.component';
import { CustomerCommentsComponent } from './components/customer/customer-comments/customer-comments.component';
import { TemplateMessagesComponent } from './components/template-messages/template-messages.component';
import { PDFExportModule } from '@progress/kendo-angular-pdf-export';
import { CommissionsComponent } from './components/commissions/commissions.component';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import { TwoDigitDecimaNumberDirective } from './_directives/twodecimalnumber.directive';

import { PdfViewerModule} from 'ng2-pdf-viewer';
import { PdfViewerComponent } from './components/pdf-viewer/pdf-viewer.component';
import { FilterDevicesPipe } from './_directives/filter-devices.pipe';
import { PaymentsVOIDComponent } from './components/salesorder/payments/payments-void/payments-void.component';
import { PreviousRouteService } from './_services/previous-route.service';
import { SoComponent } from './components/so/so/so.component';
import { ProdsComponent } from './components/so/prods/prods.component';
import { TotComponent } from './components/so/tot/tot.component';
import { CustComponent } from './components/so/cust/cust.component';
import { PaysComponent } from './components/so/pays/pays.component';
import { SearchProdsComponent } from './components/so/search-prods/search-prods.component';
import { BarCodeReaderComponent } from './components/so/bar-code-reader/bar-code-reader.component';
import { SolistComponent } from './components/so/solist/solist.component';
import { DefaultComponent } from './components/defaults/default/default.component';
import { TaxesComponent } from './components/defaults/taxes/taxes.component';
import { DevicesposComponent } from './components/defaults/devicespos/devicespos.component';
import { OpenclosecashregComponent } from './components/defaults/openclosecashreg/openclosecashreg.component';
import { FormatDatePipe } from './format-date.pipe';
import { HistoryCRComponent } from './components/defaults/openclosecashreg/history-cr/history-cr.component';
import { InventoryComponent } from './components/store/inventory/inventory.component';
import { RetailComponent } from './components/store/retail/retail.component';
import { NearExpiryComponent } from './components/store/near-expiry/near-expiry.component';
import { ItemsToPOComponent } from './components/store/items-to-po/items-to-po.component';
import { PoHistoryComponent } from './components/store/po-history/po-history.component';

//translate service
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

//currency
import { CurrencyService } from './_services/currency.service';
import { CurrencyPipe } from './currency.pipe';
import { LocationService } from './_services/location.service';
import { settings } from 'cluster';
import { SettingsService } from './_services/settings.service';

// import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

// const config: SocketIoConfig = { url: 'http://localhost:3000', options: {} };
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any>{
    // override hammerjs default configuration
    'swipe': { direction: Hammer.DIRECTION_HORIZONTAL, touchAction: "auto" }
  };
}
export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
export function tokenGetter() {
  return localStorage.getItem('auth_token');
}
export function showErrorOption(field) {
  return (field.formState.submitted || field.formControl.touched || (field.field.validation && field.field.validation.show)) && !field.formControl.valid;
}
// Requires an exported function for tranlate loader factory
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
FullCalendarModule.registerPlugins([ // register FullCalendar plugins
  dayGridPlugin]);
@NgModule({
  declarations: [
    AppComponent,
    NavComponent,
    HomeComponent,
    FormlyFieldTypeahead,
    
    
    
    
    CustomersListComponent,
    CustomerFormComponent,
    CustomerCardComponent,
    SalesorderFormComponent,
    DummymodalComponent,
    FormwrapComponent,
    
    SalesorderitemsComponent,
    StoreproductsComponent,
    StyleitemsComponent,
    LoginComponent,
    StorefrontComponent,
    SalesordertotalComponent,
    PaymentsComponent,
    DeliveryComponent,
    HeaderComponent,
    CommentsComponent,
    SalesorderTaxesComponent,
    CustomerAddressComponent,
    FormlyFieldInput,
    PaymentsFormComponent,
    RepeatComponent,
    RepeatSmallComponent,
    RepeatSmallProgramComponent,
    CustomertInfoComponent,
    CustomerInfoComponent,
    SalesorderSearchComponent,
    StockitemslistComponent,
    ClickPanelDirective,
    AddstockitemComponent,
    GridtableComponent,
    ClosebuttonComponent,
    ChoosestorestockComponent,
    TitleComponent,
    PurcharordersListComponent,
    ReportsListsComponent,
    CollapseControlComponent,
    AddsalesorderitemComponent,
    ItemdeliveryComponent,
    DeliveryFormComponent,
    SalesreportComponent,
    TitlebarComponent,
    StoreusersComponent,
    StoreusersformComponent,
    
    ChoosestatusComponent,
    StoreconfigurationComponent,
    FormlyFieldFile,
    FileValueAccessor,
    InvoicereportComponent,
    
    ProductTypeComponent,
    SalesordersummaryComponent,
    SendemailComponent,
    SendemailLocalComponent,
    AddressComponent,
    AddressCardComponent,
    PurchaseorderFormComponent,
    PurchaseorderItemsComponent,
    
    CardpaymentComponent,
    FormlyFieldTypeahead,
    
    DevicesComponent,
    AdddevicesComponent,
    SalesorderSearchtypeComponent,
    
    DeliveryperproductComponent,
    SalesorderAlertComponent,
    ChoosedeviceComponent,
    CardsignatureComponent,
    CardpinpadComponent,
    FormlyFieldButton,
    RepeatPaymentsComponent,
    CardprocessedComponent,
    SvgIconComponent,
    StoreproductsmobileComponent,
    StyleitemsmobileComponent,
    SponsorscontributionsComponent,
    StockitemslistmobileComponent,
    PurchasedetailsComponent,
    SettingspopupComponent,
    ItemmenuComponent,
     DragDirective,
     NotificationsComponent,
     HasRoleDirective,
     StoreProductsReportComponent,
     SplashScreenComponent,
     SalesfrontComponent,
     SalesorderFormobileComponent,
     HeaderMobileComponent,
     AddressCardMobileComponent,
     CustomerFormMobileComponent,
     CustomerListMobileComponent,
     AddressMobileComponent,
     DeliveryMobileComponent,
     RepeatMobileComponent,
     PaymentsMobileComponent,
     SalesordertotalMobileComponent,
     SalesorderitemsMobileComponent,
     StoreproductsMobileComponent,
     ChoosestorestockMobileComponent,
     WaitSTComponent,
     FloorItemsComponent,
     SalesorderListFrontComponent,
     SalesPersonListSOComponent,
     DeliveryInfoComponent,
     SelectInputComponent,
     SelectInputBoldComponent,
    //,PurchasedetailsComponent
     ScannitemsiosComponent,
    CustomerCommentsComponent,
    TemplateMessagesComponent,
    CommissionsComponent,
    TwoDigitDecimaNumberDirective,
    PdfViewerComponent,
    FilterDevicesPipe,
    PaymentsVOIDComponent,
    SoComponent,
    ProdsComponent,
    TotComponent,
    CustComponent,
    PaysComponent,
    SearchProdsComponent,
    BarCodeReaderComponent,
    SolistComponent,
    DefaultComponent,
    TaxesComponent,
    DevicesposComponent,
    OpenclosecashregComponent,
    FormatDatePipe,
    HistoryCRComponent,
    InventoryComponent,
    RetailComponent,
    NearExpiryComponent,
    ItemsToPOComponent,
    PoHistoryComponent,
    CurrencyPipe,
  ],
  entryComponents: [
    
    DummymodalComponent,
    PaymentsFormComponent,
    SalesorderSearchComponent,
    ChoosestorestockComponent,
    AddsalesorderitemComponent,
    ItemdeliveryComponent,
    DeliveryFormComponent,
    StoreusersformComponent,
    CardsignatureComponent,
    CardpinpadComponent,
    ChoosestatusComponent,
    InvoicereportComponent,
    SendemailComponent,
    SendemailLocalComponent,
    AddressComponent,
    CardpaymentComponent,
    AdddevicesComponent,
    SalesorderSearchtypeComponent,
    AddstockitemComponent,
    ItemmenuComponent
  ],
  imports: [
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    SignaturePadModule,
    CommonModule,
    BrowserAnimationsModule,
    NgSelectModule,
    BrowserModule,
    FullCalendarModule,
    HttpClientModule,
    FormsModule,
    NgxColorsModule,
    WaMatConfirmDialogModule,
    ReactiveFormsModule,
    
    LoadingPageModule,
    MaterialBarModule,
    TrendModule,
    
    NgxPaginationModule,
    LoadingBarRouterModule,
    LoadingBarHttpClientModule,
    MatAutocompleteModule,
    GoogleTagManagerModule.forRoot({
      id: 'G-05XW2401F4',
    }),
    NgxMaskModule.forRoot(),


    FormlyModule.forRoot({
      wrappers: [
        { name: 'formwrap', component: FormwrapComponent },
      ],
      validationMessages: [
        { name: 'required', message: 'required' },
      ],
      types: [
        { name: 'phone', component: FormlyFieldInput },
        { name: 'repeat', component: RepeatComponent },
        { name: 'repeat-mobile', component: RepeatMobileComponent },
        { name: 'repeatpayments', component: RepeatPaymentsComponent },
        { name: 'repeatsmall', component: RepeatSmallComponent },
        { name: 'repeatsmallprogram', component: RepeatSmallProgramComponent},
        { name: 'file', component: FormlyFieldFile, wrappers: ['form-field'] },
        { name: 'typeahead', component: FormlyFieldTypeahead }, 
        { name: 'button', component: FormlyFieldButton},
        { name: 'select-input', component: SelectInputComponent },
        { name: 'select-input-bold', component: SelectInputBoldComponent },
      ],
      extras: {
        showError: showErrorOption,
      },
    }),

    ScrollingModule,
    DragDropModule,
    FormlyBootstrapModule,
    HttpClientModule,
    TextMaskModule,
    SwipeListModule,
    HammerModule,
    SpellCheckerModule,
    RouterModule.forRoot(appRoutes, {
      scrollPositionRestoration: 'enabled',
      useHash: true
  }),
  
    CountoModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:5000'],
        blacklistedRoutes: ['localhost:5000/api/auth']
      }
    }),
    SharedModule,
    PDFExportModule, 
    PdfViewerModule  ,

  ],
  schemas: [NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA],

  providers: [
    PreviousRouteService,
    AuthService,
    GlobalsService,
    ErrorInterceptorProvider,
    AlertifyService,
    AuthGuard,
    UserService,
    MemberDetailResolver,
    MemberListResolver,
    MemberEditResolver,
    PreventUnsavedChanges,
    ListsResolver,
    MessagesResolver,
    CustomerService,
    CustomerListResolver,
    CustomerFormResolver,
    PreventUnsavedChangesCustomer,
    UnsavedSalesOrderChanges,
    SalesOrderService,
    SalesorderListResolver,
    SalesOrderFormResolver,
    PurchaseOrderFormResolver,
    BsModalRef,
    BsModalService,
    StyleitemsService,
    LanguageService,
    UpcitemdbService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    JwtInterceptor,
    StoreproductsService,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: MyHammerConfig
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useFactory: (authService: AuthService, currencyService: CurrencyService) => {
        return authService.loggedIn() ? currencyService.currentCurrencyValue : null;
      },
      deps: [AuthService, CurrencyService]
    },
  ],
  bootstrap: [AppComponent],
  exports: []
})
export class AppModule { 
constructor() {
  
}

}
