import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { SettingsService } from 'src/app/_services/settings.service';

@Component({
  selector: 'app-taxes',
  templateUrl: './taxes.component.html',
  styleUrls: ['./taxes.component.scss']
})
export class TaxesComponent implements OnInit ,OnDestroy{
  
	destroy$: Subject<boolean> = new Subject<boolean>();

  taxesForm = new FormGroup({
    defaultTaxes: new FormControl('',[Validators.required]),    
    defaultCounty: new FormControl('',[]),
  });

  constructor(private settingsSvc:SettingsService,
    private alertify:AlertifyService,
    private translate: TranslateService,
    ) { }

  ngOnInit(): void {
    this.settingsSvc.getDefaultTaxes()
    .pipe(takeUntil(this.destroy$))
    .subscribe((data: any) => {
      console.log(data['taxes']);     
      console.log(JSON.parse(data['taxes']));     
      this.taxesForm.reset(JSON.parse(data['taxes']));
    },(err)=>{
      //console.log(err);      
    })
  }

  saveTax(){
    if(this.taxesForm.value){
      this.settingsSvc.updateDefaultTaxes({taxes:{defaultTaxes:this.taxesForm.value.defaultTaxes , defaultCounty:this.taxesForm.value.defaultCounty }})
      .pipe(takeUntil(this.destroy$))
			.subscribe((data: any) => {
        this.alertify.success(this.translate.instant('TAX_UPDATED'));
      },(err)=>{
        console.log(err);      
      })
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
