import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/_services/auth.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss']
})
export class SplashScreenComponent implements OnInit {

  constructor(
    public router: Router,
    public authService: AuthService ) { }

  ngOnInit(): void {
     let userRole = this.authService.decodeToken('role').role;
     
      if ( userRole === 'Admin' || userRole === 'Dealer') {        
        this.router.navigate(['/storeconfig']);
      } else if(userRole === 'Sales Person' || userRole === 'Cashier'){
        document.getElementById('mainWithSideBar').className = "body-page-content-SalesPerson";
        document.getElementById('sidebar').style.display = "none";
        this.router.navigate(['/salesorder']); 
      }
  }

}
