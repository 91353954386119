import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DefaultService {

  constructor(private http: HttpClient, private router: Router) {}
  
  getDevicesPOS(data: any) {
    return this.http.post(environment.apiUrl + 'getDevicesPOS', data);
  }

  getClosesCR(data: any) {
    return this.http.post(environment.apiUrl + 'getClosesCR', data);
  }

  saveOpenCashRegister(data: any) {
    return this.http.post(environment.apiUrl + 'saveOpenCashRegister', data);
  }

  geCloseInfoCashRegister(data: any) {
    return this.http.post(environment.apiUrl + 'geCloseInfoCashRegister', data);
  }

  closeCashRegister(data: any) {
    return this.http.post(environment.apiUrl + 'closeCashRegister', data);
  }
}