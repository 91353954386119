import { Component, OnInit } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cardpinpad',
  templateUrl: './cardpinpad.component.html',
  styleUrls: ['./cardpinpad.component.scss']
})
export class CardpinpadComponent implements OnInit {
  pin = '';
  cardPayment: any;
  constructor(
    private modalService: BsModalService,
    private bsModalRef: BsModalRef
  ) { }

  ngOnInit() {
  }

  addCharacter(data) {
    this.pin = this.pin + data;
  }

  deleteCharacter() {
    this.pin = this.pin.substr(0, this.pin.length - 1);
  }

  clear() {
    this.pin = '';
  }

  confirm() {
    this.cardPayment['pinpad'] = this.pin;
    this.cancel();
  }

  cancel() {
    this.bsModalRef.hide();
  }


}
