import { Component, OnInit } from '@angular/core';
import { FieldArrayType, FormlyFormBuilder } from '@ngx-formly/core';
import { Subject } from 'rxjs';
import { GlobalsService } from 'src/app/_services/globals.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { SalesOrderService } from 'src/app/_services/salesorder.service';

@Component({
  selector: 'formly-repeat-section',
  templateUrl: './repeat.component.html',
})
export class RepeatComponent extends FieldArrayType implements OnInit {
  from:string = 'default'
  destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(builder: FormlyFormBuilder, 
    private global:GlobalsService, 
    private salesOrderService : SalesOrderService) {
    super(builder);
  }
  
  ngOnInit(): void {
    this.global.optionTrashFL.subscribe(
      (res:any)=>{this.from = res}
    )    
  
    this.salesOrderService.delivery.pipe(takeUntil(this.destroy$)).subscribe(data =>{
      // console.log(data)
      if(Object.entries(data).length > 0){
        this.add(null, data)
        this.salesOrderService.delivery.next({})
      } 
    })
  }


  removeItem(x) {
    this.field.fieldArray.templateOptions.removeItem(x);
  }

  ngOnDestroy(): void {    
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    // console.log('destroyed')
  }

}
