import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import 'rxjs/add/operator/takeUntil';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { SendemailComponent } from '../sendemail/sendemail.component';
import { SettingsService } from 'src/app/_services/settings.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { take, takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { TemplatemessagesService } from 'src/app/_services/templatemessages.service';
import { EventsService } from 'src/app/_services/events.service';
import { AuthService } from 'src/app/_services/auth.service';


declare var Stimulsoft: any;
declare let Print: any;
declare var iswebKit: any;

@Component({
  selector: 'app-salesreport',
  templateUrl: './salesreport.component.html',
  styleUrls: ['./salesreport.component.scss']
})
export class SalesreportComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  show = false;
  reportdata: any[];
  // viewer: any = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
  // report: any = new Stimulsoft.Report.StiReport();
  viewer: any
  report: any 
  
  so: any;
  salesorder: any;
  @Input() showReport = '';
  @Input() dateFrom: Date;
  @Input() dateTo: Date;
  @Input() event: any;
  @Input() showClose = true;
  @Input() status = '';
  @Input() parameter = '';
  @Input() dataparameter = '';
  eventsponsor:any = ''
  eventdbdate:any = ''
  attachmentStream:any = '';
  settings: any;
  reportFormat = 'PDF';
  platform = '';
  errorLoading = '';
  // deliveriesDefault = [];

  showMobile = false;
  isIOS = false;
  isAndroid = false;
  setFor = 'Delivery';
  constructor(private salesService: SalesOrderService,
    private modalService: BsModalService,
    public bsModalRef: BsModalRef,
    public globals: GlobalsService,
    public settingsService: SettingsService,
    private http : HttpClient,
    private templateMeSvc : TemplatemessagesService,    
    private eventService: EventsService,
    private authService:AuthService
    ) { }


  ngOnInit() {


    this.platform = navigator.userAgent;
    if (this.platform.match(/Android/i)) {
      this.showMobile = true;
     this.isAndroid = true;
    }
    if (this.platform.match(/iPhone|iPad|iPod/i)) {
      this.showMobile = true;
       this.isIOS = true;

    }

    if (this.platform.includes('Mac')) {
      this.showMobile = false;

    }

    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
       this.isIOS = true;
    }

    if (this.platform.includes('Win')) {
      this.showMobile = false;

    }

    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
      this.isIOS = true;
    }
    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.showMobile = true;
       this.isIOS = true;
    }


  // Stimulsoft.Base.StiLicense.key =this.globals.stimulSoft;
    // if(this.showReport ==='DispatchTrackWithDetailsReportUniversalDeliveries' || 
    // this.showReport ==='DispatchTrackWithDetailsReportUniversal' || 
    // this.showReport ==='DispatchTrackWithDetailsReport' 
    // ){
    //   this.settingsService.getSettings(0)
    //   .pipe(take(1))
    //   .subscribe( async data => {
    //     let deliveries = JSON.parse(data[0].deliveryInfo).filter((x:any)=>x.type == 'Other Charges') 
    //     deliveries.forEach(element => {
    //       this.deliveriesDefault.push(element.method)
    //     });     
    //   });
    // }
    this.eventService.jsonevent.subscribe(
      (res:any)=>{
         this.eventsponsor = JSON.parse(res).sponsor
         this.eventdbdate = JSON.parse(res).dbDatets
      }
    )
    this.getReport();
  }

 async getReport() {
    let wait = await this.globals.loadScript();
    if(wait){
      this.viewer = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
      this.report = new Stimulsoft.Report.StiReport();
    this.show = false;
    this.reportdata = [ ];
    this.salesService.getSalesReport(this.showReport, this.event, this.dateFrom.toDateString(), this.dateTo.toDateString(), status,
    this.parameter, this.dataparameter,this.setFor)
    .pipe(takeUntil(this.destroy$)) 
    .subscribe(async (res: any[]) => {
      if (res.length === 0) {
        this.errorLoading = 'No data found...';
        this.show = true;
        return;
      } else {
        this.show = false;
      }
      this.reportdata = [];
      this.show = false;
      this.errorLoading = '';


        if (this.showReport ==='SecondDeliveryReport' || this.showReport ==='ThirdDeliveryReport' ||
        this.showReport ==='RockfordDeliveryReport' ) {

          const toDelete = [];

          for (let index = 0; index < res.length; index++) {
            const element = res[index];
            if (index >= 0) {
              if (res[index].hasOwnProperty('SOID') && res[index].hasOwnProperty('SOItemsID')) {
                res[index]['SOItemsRecords'] = new Array();
                let respay = res.filter(item => (item['SOID'] === res[index]['SOID']
                && item['ShipTo'] === res[index]['ShipTo'])) ;
                const result = [];
                const map = new Map();
                for (const item of respay) {
                    if (!map.has(item['SOItemsID'])) {
                        map.set(item['SOItemsID'], true);    // set any value to Map

                        if (this.showReport ==='RockfordDeliveryReport') {
                        result.push({
                           'SOID' : ( item['SOID']),
                           'SOItemsID' : ( item['SOItemsID']),
                          // 'MFG' : ( item['MFG']),
                          // 'styleName' : ( item['styleName']),
                          // 'Type' : ( item['Type']),
                          // 'sizeName' : ( item['sizeName']),
                           'quantityNum' : ( item['quantityNum']),
                          // 'SKU' : ( item['SKU']),
                          // 'LineTotal' : ( item['LineTotal']),
                          // 'itemDelivery' : ( item['itemDelivery']),
                          // 'DSAddress' : ( item['DSAddress']),
                          // 'toBeDelivered' : ( item['toBeDelivered']),
                          'LabelAddress': ( item['LabelAddress']),
                          // 'itemOrder': ( item['itemOrder']),
                          // 'manufacter': ( item['manufacter'])

                        });
                      } else {
                        result.push({
                          'SOID' : ( item['SOID']),
                          'SOItemsID' : ( item['SOItemsID']),
                         'LabelAddress': ( item['LabelAddress']),
                       });
                      }
                    }
                }
                respay = result;
                for (let indexpay = 0; indexpay < respay.length; indexpay++) {
                  const elementpay = respay[indexpay];
                  if (indexpay > 0) {
                      toDelete.push(respay[indexpay]['SOItemsID']);
                  }
                  res[index]['Item' + (indexpay + 1)] = respay[indexpay]['LabelAddress'];
                  if (this.showReport ==='RockfordDeliveryReport') {
                    res[index]['SOtoBeDelivered'] =
                    parseInt(res[index]['SOtoBeDelivered']) +
                    parseInt(respay[indexpay]['quantityNum']);
                    if (indexpay > 0) {
                      res[index]['LabelAddress'] = res[index]['LabelAddress'] + ', ' +
                      respay[indexpay]['LabelAddress'];
                    }
                  }


                }
              }
              // if (index > 0) {
              //  if (res[index]['SOID'] === res[index - 1]['SOID']) {
              //   res[index]['SOItemsRecords'] = [];
              //   }
              // }
            }
          }
          //console.log(toDelete);
          for (let indexd = 0; indexd < toDelete.length; indexd++) {
            const element = toDelete[indexd];

            for( var i = 0; i < res.length; i++){
              if ( res[i]['SOItemsID'] === element) {
                res.splice(i, 1);
                i--;
              }
           }
          }
        }



        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          if (index >= 0) {
            if (res[index].hasOwnProperty('SOID') && res[index].hasOwnProperty('SOItemsID')) {
              res[index]['SOItemsRecords'] = new Array();
              let respay = res.filter(item => item['SOID'] === res[index]['SOID']);
              const result = [];
              const map = new Map();
              for (const item of respay) {
                  if (!map.has(item['SOItemsID'])) {
                      map.set(item['SOItemsID'], true);    // set any value to Map
                      result.push({
                        'SOID' : ( item['SOID']),
                        'SOItemsID' : ( item['SOItemsID']),
                        'MFG' : ( item['MFG']),
                        'styleName' : ( item['styleName']),
                        'Type' : ( item['Type']),
                        'sizeName' : ( item['sizeName']),
                        'quantityNum' : ( item['quantityNum']),
                        'SKU' : ( item['SKU']),
                        'LineTotal' : ( item['LineTotal']),
                        'itemDelivery' : ( item['itemDelivery']),
                        'DSAddress' : ( item['DSAddress']),
                        'toBeDelivered' : ( item['toBeDelivered']),
                        'SOtoBeDelivered' : ( item['SOtoBeDelivered']),
                        'LabelAddress': ( item['LabelAddress']),
                        'itemOrder': ( item['itemOrder']),
                        'manufacter': ( item['manufacter']),
                        'trackingDate': ( item['trackingDate'])

                      });


                  }
              }

              respay = result;
              for (let indexpay = 0; indexpay < respay.length; indexpay++) {
              const elementpay = respay[indexpay];

              if (!this.showReport.toString().includes('Avery')) {
                res[index]['SOItemsRecords'].push({
                  'SOID' : ( respay[indexpay]['SOID']),
                  'SOItemsID' : ( respay[indexpay]['SOItemsID']),
                  'MFG' : ( respay[indexpay]['MFG']),
                  'styleName' : ( respay[indexpay]['styleName']),
                  'Type' : ( respay[indexpay]['Type']),
                  'sizeName' : ( respay[indexpay]['sizeName']),
                  'quantityNum' : ( respay[indexpay]['quantityNum']),
                  'SKU' : ( respay[indexpay]['SKU']),
                  'LineTotal' : ( respay[indexpay]['LineTotal']),
                  'itemDelivery' : ( respay[indexpay]['itemDelivery']),
                  'DSAddress' : ( respay[indexpay]['DSAddress']),
                  'toBeDelivered' : ( respay[indexpay]['toBeDelivered']),
                  'LabelAddress' : ( respay[indexpay]['LabelAddress']),
                  'itemOrder' : ( respay[indexpay]['itemOrder']),
                  'manufacter' : ( respay[indexpay]['manufacter']),
                  'trackingDate' : ( respay[indexpay]['trackingDate'])
                });
              }
              if (this.showReport.toString().includes('Avery')) {
                for (let indexrepeat = 0; indexrepeat < parseInt(respay[indexpay]['quantityNum']); indexrepeat++) {
                    if (parseInt(respay[indexpay]['quantityNum']) > 1) {
                    res[index]['SOItemsRecords'].push({
                      'SOID' : ( respay[indexpay]['SOID']),
                      'SOItemsID' : ( respay[indexpay]['SOItemsID']),
                      'MFG' : ( respay[indexpay]['MFG']),
                      'styleName' : ( respay[indexpay]['styleName']),
                      'Type' : ( respay[indexpay]['Type']),
                      'sizeName' : ( respay[indexpay]['sizeName']),
                      'quantityNum' : ( respay[indexpay]['quantityNum']),
                      'SKU' : ( respay[indexpay]['SKU']),
                      'itemDelivery' : ( respay[indexpay]['itemDelivery']),
                      'DSAddress' : ( respay[indexpay]['DSAddress']),
                      'toBeDelivered' : ( respay[indexpay]['toBeDelivered']),
                      'LabelAddress' : (  respay[indexpay]['LabelAddress']),
                      'LineTotal' : (  (indexrepeat + 1 ).toString() +
                      ' of ' + respay[indexpay]['quantityNum']) + ' of '
                      + respay[indexpay]['SOtoBeDelivered'] ,
                      'itemOrder' : ( respay[indexpay]['itemOrder']),
                      'manufacter' : ( respay[indexpay]['manufacter']),
                      'trackingDate' : ( respay[indexpay]['trackingDate']),
                    });
                  } else {
                    res[index]['SOItemsRecords'].push({
                      'SOID' : ( respay[indexpay]['SOID']),
                      'SOItemsID' : ( respay[indexpay]['SOItemsID']),
                      'MFG' : ( respay[indexpay]['MFG']),
                      'styleName' : ( respay[indexpay]['styleName']),
                      'Type' : ( respay[indexpay]['Type']),
                      'sizeName' : ( respay[indexpay]['sizeName']),
                      'quantityNum' : ( respay[indexpay]['quantityNum']),
                      'SKU' : ( respay[indexpay]['SKU']),
                      'LineTotal' : '1 of ' + respay[indexpay]['SOtoBeDelivered'],
                      'itemDelivery' : ( respay[indexpay]['itemDelivery']),
                      'DSAddress' : ( respay[indexpay]['DSAddress']),
                      'toBeDelivered' : ( respay[indexpay]['toBeDelivered']),
                      'LabelAddress' : (  respay[indexpay]['LabelAddress']),
                      'itemOrder' : ( respay[indexpay]['itemOrder']),
                      'manufacter' : ( respay[indexpay]['manufacter']),
                      'trackingDate' : ( respay[indexpay]['trackingDate']),
                    });
                  }
                }



              }
            }

              if (index > 0) {
              if (res[index]['SOID'] === res[index - 1]['SOID']) {
                res[index]['SOItemsRecords'] = [];
                }
              }
            }
          }
        }
        if (this.showReport.toString().includes('Avery')) {
          for (let index = 0; index < res.length; index++) {
            for (let indexitem = 0; indexitem < res[index]['SOItemsRecords'].length; indexitem++) {
              const element = res[index]['SOItemsRecords'][indexitem];
              //element['SKU'] = indexitem + 1;

              element['LineTotal'] =   (indexitem + 1 ).toString() +
              ' of '+ res[index]['SOtoBeDelivered'];

            }
          }
      }



        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          if (index >= 0) {
            if (res[index].hasOwnProperty('SOID') && res[index].hasOwnProperty('paymentID')  && this.showReport !='PaymentTypesReportDetails') {
              res[index]['PaymentsRecords'] = new Array();
              let respay = res.filter(item => item['SOID'] === res[index]['SOID']);
              const result = [];
              const map = new Map();
              for (const item of respay) {
                  if (!map.has(item['paymentID'])) {
                      map.set(item['paymentID'], true);    // set any value to Map
                      result.push({
                        'SOID' : ( item['SOID']),
                        'paymentID' : ( item['paymentID']),
                        'paymentType' : ( item['paymentType']),
                        'paymentDetail' : ( item['paymentDetail']),
                        'paymentAmount' : ( item['paymentAmount']),
                        'Note' : ( item['Note'])
                      });

                      if (res[index]['PaymentAllNotes']) {
                        res[index]['PaymentAllNotes'] =
                        res[index]['PaymentAllNotes'] + '\n' + item['paymentType'] + '       ' +
                        item['paymentAmount'] + ' ';
                       } else {
                         res[index]['PaymentAllNotes'] =
                         item['paymentType'] + '       ' +  item['paymentAmount'];
                       }
                  }
              }

              respay = result;
              for (let indexpay = 0; indexpay < respay.length; indexpay++) {
              const elementpay = respay[indexpay];
              res[index]['PaymentsRecords'].push({
                'SOID' : ( respay[indexpay]['SOID']),
                'paymentID' : ( respay[indexpay]['paymentID']),
                'paymentType' : ( respay[indexpay]['paymentType']),
                'paymentDetail' : ( respay[indexpay]['paymentDetail']),
                'paymentAmount' : ( respay[indexpay]['paymentAmount']),
                'Note' : ( respay[indexpay]['Note'])
              });
            }

            if (index > 0) {
             if (res[index]['SOID'] === res[index - 1]['SOID']) {
              res[index]['PaymentsRecords'] = [];
              }
            }
            }
          }
        }

        for (let index = 0; index < res.length; index++) {
          const element = res[index];

  
          //hacer arreglo de los tipos de other charges de defaults en tabla setting
          


          if(this.showReport ==='DispatchTrackWithDetailsReportUniversalDeliveries'){
            res[index]['distincDeliveries'] = [];

            let itemsresSO = res.filter(item => item['SOID'] === res[index]['SOID']);
            
            var lookup = {};
            var distincDeliveries = [];    
            for (var item, i = 0; item = itemsresSO[i++];) {
              var deliveryId = item.deliveryId;
    
              if (!(deliveryId in lookup)) {
                lookup[deliveryId] = 1;
                distincDeliveries.push(deliveryId);
              }
            }
            
            let deliveriesSO = []
            distincDeliveries.forEach(element => {
              let SO = []
              SO = itemsresSO.filter((x:any)=>x.deliveryId == element);
              // solo se incluyen si no son other charges
              if(SO[0]['delOrOther'] != 'Other Charges'){
                deliveriesSO.push({
                "SONumber":SO[0].SONumber,
                "SOID":SO[0].SOID,
                "deliveryRequest":SO[0].deliveryRequest,
                "ShipName":SO[0].ShipName,
                "ShipAddress1":SO[0].ShipAddress1,
                "ShipAddress2":SO[0].ShipAddress2,
                "ShipCity":SO[0].ShipCity,
                "ShipState":SO[0].ShipState,
                "ShipZip":SO[0].ShipZip,
                "ShipEmail":SO[0].ShipEmail,

                "Phone1":SO[0].Phone1,
                "Phone2":SO[0].Phone2,
                "Phone3":SO[0].Phone3,
                "quantityNum":SO[0].quantityNum,

                "MFG":SO[0].MFG,
                "styleName":SO[0].styleName,
                "Type":SO[0].Type,
                "sizeName":SO[0].sizeName,
                "SKU":SO[0].SKU,

                "DeliveryType":SO[0].DeliveryType+' '+SO[0]['DeliveryETA']+' '+SO[0]['DeliveryNote'],
                "itemDelivery":SO[0].itemDelivery
                });
              }
       
            });
            
            res[index]['distincDeliveries'] = deliveriesSO;
          }

          if (index >= 0) {
            if (res[index].hasOwnProperty('SOID') && res[index].hasOwnProperty('deliveryId') &&  this.showReport !='PaymentTypesReportDetails' ) {
              res[index]['DiscountsRecords'] = new Array();
              res[index]['DeliveryAllNotesNoPrice'] = '';
              let resdis = res.filter(item => item['SOID'] === res[index]['SOID']);
              const resultdis = [];
              const mapdis = new Map();
              for (const item of resdis) {
                  if (!mapdis.has(item['deliveryId'])) {
                      mapdis.set(item['deliveryId'], true);    // set any value to Map
                      resultdis.push({
                        'SOID' : ( item['SOID']),
                        'deliveryId' : ( item['deliveryId']),
                        'DeliveryETA' : ( item['DeliveryETA']),
                        'DeliveryAmount' : ( item['DeliveryAmount']),
                        'DeliveryNote' : ( item['DeliveryNote']),
                        'DeliveryType' : ( item['DeliveryType']),
                      });
                        if (res[index]['DeliveryAllNotes']) {
                       res[index]['DeliveryAllNotes'] =
                       res[index]['DeliveryAllNotes'] + '\n' + item['DeliveryType'] + ' ' +item['DeliveryETA'] + ' ' +  item['DeliveryAmount'] + ' ' + item['DeliveryNote'] + ' ' ;
                       
                       if(item['delOrOther'] != 'Other Charges'){                        
                        res[index]['DeliveryAllNotesNoPrice'] = res[index]['DeliveryAllNotesNoPrice'] + item['DeliveryType'] + ' ' +item['DeliveryETA'] + ' ' + item['DeliveryNote']+ '\n' ;
                       }
                    
                      } else {
                        res[index]['DeliveryAllNotes'] =
                        item['DeliveryType'] + ' ' +
                        item['DeliveryETA'] + ' ' +  item['DeliveryAmount'] + ' ' + 
                        item['DeliveryNote'] + ' ' ;

                        if(item['delOrOther'] != 'Other Charges'){
                          res[index]['DeliveryAllNotesNoPrice'] = item['DeliveryType'] + ' ' + item['DeliveryETA'] + ' ' +  item['DeliveryNote'] + '\n';
                        }
                      }
                  }
              }

              resdis = resultdis;
              for (let indexdis = 0; indexdis < resdis.length; indexdis++) {
              const elementdis = resdis[indexdis];
              res[index]['DiscountsRecords'].push({
                'SOID' : ( elementdis['SOID']),
                'deliveryId' : ( elementdis['deliveryId']),
                'DeliveryETA' : ( elementdis['DeliveryETA']),
                'DeliveryAmount' : ( elementdis['DeliveryAmount']),
                'DeliveryNote' : ( elementdis['DeliveryNote']),
                'DeliveryType' : ( elementdis['DeliveryType'])
              });
            }

            if (index > 0) {
             if (res[index]['SOID'] === res[index - 1]['SOID']) {
              res[index]['DiscountsRecords'] = [];
              }
            }
            }
          }

            /// was here
          if(this.showReport != 'TotalsReport' && this.showReport != 'EventSummaryWithTaxes'  && this.showReport != 'EventSummaryWithTaxesByDate'){
            const newres = res.filter(item => item['ItemSoldSalesOrder'] !== 0);
            res = newres;
          }



          // if (index >= 0) {
          //   if (res[index].hasOwnProperty('SOID') && res[index].hasOwnProperty('deliveryId')) {
          //    if (res[index]['SOID'] === res[index - 1]['SOID']) {
          //     res[index]['DiscountsRecords'] = [];
          //      res[index]['DiscountsRecords']['deliveryId'] = ( res[index]['deliveryId']);
          //      res[index]['DiscountsRecords']['DeliveryETA'] = ( res[index]['DeliveryETA']);
          //      res[index]['DiscountsRecords']['DeliveryAmount'] = ( res[index]['DeliveryAmount']);
          //      res[index]['DiscountsRecords']['DeliveryNote'] = ( res[index]['DeliveryNote']);
          //      res[index]['DiscountsRecords']['DeliveryType'] = ( res[index]['DeliveryType']);
          //     }
          //   }
          // }
        }


        for (let index = 0; index < res.length; index++) {
          const element = res[index];


            if (res[index]['Total']  === 0  || res[index]['Total']  === '0') {
            res[index]['Total'] = parseFloat(res[index]['SalesPrice']) +
             parseFloat(res[index]['Taxes']) +
             parseFloat(res[index]['AdditionalCharges']);
            }
            if(this.showReport != 'ProductReport'){
              if (res[index]['Balance']  === 0 || res[index]['Balance']  === '0') {
              res[index]['Balance'] = (parseFloat(res[index]['SalesPrice']) +
              parseFloat(res[index]['Taxes']) +
              parseFloat(res[index]['AdditionalCharges']) -
             parseFloat(res[index]['Payments']));
             
            }
            }


          if (index > 0) {



            if (res[index].hasOwnProperty('SONumber') && this.showReport !='PaymentTypesReportDetails' && (res[index].hasOwnProperty('SubTotal')
            || res[index].hasOwnProperty('SalesPrice') )) {

             if (res[index]['SONumber'] === res[index - 1]['SONumber']) {


                res[index]['Date'] = '';
                res[index]['DeliveryAllNotes'] = '';
                res[index]['DeliveryAllNotesNoPrice'] = '';
                res[index]['status'] = '';
                //res[index]['SOItemsID'] = '';
                res[index]['Customer'] = '';
                res[index]['BillingAddress'] = '';
                res[index]['ShippingAddress'] = '';
                res[index]['SOtoBeDelivered'] = 0;
                res[index]['deliveryRequest'] = '';

                res[index]['SubTotal'] = 0;
                res[index]['SalesPrice'] = 0;
                res[index]['Taxes'] = 0;
                res[index]['AdditionalCharges'] = 0;
                res[index]['Total'] = 0;
                res[index]['Payments'] = 0;
                res[index]['Balance'] = 0;
                res[index]['ItemSoldSalesOrder'] = 0;
                res[index]['SOtoBeDelivered'] = 0;
              }


            }
          }
        }

        if (this.showReport ==='DeliveryReport' || this.showReport === 'DispatchTrackReport' || this.showReport === 'DispatchOmahaReport' || this.showReport === 'DispatchKentuckyReport' || this.showReport === 'DispatchTrackWithDetailsReport' 
        || this.showReport === 'DispatchTrackReportUniversal' || this.showReport === 'DispatchTrackWithDetailsReportUniversal' || this.showReport === 'DispatchTrackWithDetailsReportUniversalDeliveries'
        || this.showReport === 'CustomersReport' || this.showReport === 'DeliveryCompletedReport' || this.showReport === 'DeliveryPendingReport' 
        || this.showReport === 'Avery5163FixReport' || this.showReport === 'Avery5161FixReport' || this.showReport === 'Avery5164FixReport') {

          const filteredArr = res.reduce((acc, current) => {
            const x = acc.find(item => item.SOID === current.SOID);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);

          res = filteredArr
        }

        let se;
        if(this.showReport ==='AllSalesOrders' || this.showReport ==='PackingSlip'){
        let contactinfo = '';
        this.settingsService.getSettings(0)
        .pipe(take(1))
        .subscribe( async data => {
          const settings = data[0];
            contactinfo = settings['contactInfo'];
            contactinfo = (contactinfo.toString().replace(/\r/g, '<br>' ));
            contactinfo = (contactinfo.toString().replace(/\n/g, '<br>' ));

            res.map((so:any)=>{

                if (so['disc1exp']) {
                  if(so['disc1exp'] != null && so['disc1exp'] != 'null'){                    
                    so['disc1exp'] = atob(so['disc1exp']); 
                  }else{
                    so['disc1exp'] = null
                  }
                }

                if (so['disc2exp']) {
                  if(so['disc2exp'] != null && so['disc2exp'] != 'null'){                    
                    so['disc2exp'] = atob(so['disc2exp']); 
                  }else{
                    so['disc2exp'] = null
                  }
                }

                if (so['disc3exp']) {
                  if(so['disc3exp'] != null && so['disc3exp'] != 'null'){                    
                    so['disc3exp'] = atob(so['disc3exp']); 
                  }else{
                    so['disc3exp'] = null
                  }
                }

                if (so['disc4exp']) {
                  if(so['disc4exp'] != null && so['disc4exp'] != 'null'){                    
                    so['disc4exp'] = atob(so['disc4exp']); 
                  }else{
                    so['disc4exp'] = null
                  }
                }

                if (so['orderSigexp']) {
                  if(so['orderSigexp'] != null && so['orderSigexp'] != 'null'){                    
                    so['orderSigexp'] = atob(so['orderSigexp']); 
                  }else{
                    so['orderSigexp'] = null
                  }
                }

                so['xx_customer_id_tx'] = contactinfo;
            })

            this.reportdata = res;
            // console.log(this.showReport)
            // console.log(this.reportdata)
            if(this.event == 'ROID'){
              if( this.showReport == 'AllSalesOrders'||
              this.showReport == 'PackingSlip'  
              ){
                let filterdate = this.reportdata.filter(x => x.dbDatets.slice(0, 10) >= this.convert(this.dateFrom) && x.dbDatets.slice(0, 10) <= this.convert(this.dateTo))
                this.reportdata = filterdate;           
              } 
            }

            this.report.loadFile('reports/' + this.showReport + '.mrt');
            this.report.dictionary.databases.clear();
            const dssalesprice = new Stimulsoft.System.Data.DataSet();
            dssalesprice.readJson(this.reportdata);
            this.report.regData('salesprice', null, dssalesprice);
            this.viewer.report = this.report;
            this.viewer.options.toolbar.showAboutButton = false;
            this.viewer.options.toolbar.showEmailButton = true;
            this.viewer.options.appearance.scrollbarsMode = true;
            this.viewer.options.appearance.showTooltipsHelp = false;
            this.viewer.options.appearance.showFormsHelp = false;
            this.viewer.options.appearance.showFormsHints = false;
            this.viewer.options.exports.showExportToHtml = false;
            this.viewer.options.exports.showExportToHtml5 = false;
            this.viewer.options.exports.showExportToDataFile = false;
            this.viewer.options.exports.showExportToDocument = false;
            this.viewer.options.exports.showExportDialog = false;
            this.viewer.options.toolbar.printDestination = Stimulsoft.Viewer.StiPrintDestination.Direct;

          //   if (this.showMobile === true) {
          //     this.viewer.options.toolbar.showPrintButton = false;
          //     this.viewer.options.toolbar.showSaveButton = true;
          //     this.viewer.options.toolbar.showExportToPDF = true;
          //     this.viewer.options.toolbar.showExportToExcel  = false;
          //     this.viewer.options.toolbar.showExportToRtf = false;
          //     this.viewer.options.toolbar.showExportToExcel2007 = false;
          //     this.viewer.options.toolbar.showExportToExcelXML = false;
          //      this.viewer.options.appearance.interfaceType = Stimulsoft.Viewer.StiInterfaceType.Mobile;
          // }

          if (this.showMobile === true || this.isIOS) {
            this.viewer.options.toolbar.show = true;
            this.viewer.options.toolbar.showOpenButton = false;
            this.viewer.options.toolbar.showPrintButton = false;
            this.viewer.options.toolbar.showSaveButton = true;
            this.viewer.options.toolbar.showFindButton = false;
            this.viewer.options.toolbar.showBookmarksButton = false;
            this.viewer.options.toolbar.showParametersButton = false;
            this.viewer.options.toolbar.showResourcesButton = false;
            this.viewer.options.toolbar.showEditorButton = false;
            this.viewer.options.toolbar.showFullScreenButton = true;
            this.viewer.options.toolbar.showRefreshButton = false;
            this.viewer.options.toolbar.showZoomButton = true;
            this.viewer.options.toolbar.showViewModeButton = false;
            this.viewer.options.toolbar.showDesignButton = false;
            this.viewer.options.toolbar.showAboutButton = false;
            this.viewer.options.toolbar.showExportToPDF = false;
            this.viewer.options.toolbar.showPinToolbarButton = false;
            this.viewer.options.toolbar.showExportToExcel  = false;
            this.viewer.options.toolbar.showExportToRtf = false;
            this.viewer.options.toolbar.showExportToExcel2007 = false;
            this.viewer.options.toolbar.showExportToExcelXML = false;
            this.viewer.options.toolbar.zoom = Stimulsoft.Viewer.StiZoomMode.PageWidth;
            this.viewer.options.appearance.scrollbarsMode = false;
            this.viewer.options.appearance.fullScreenMode = false;
            this.viewer.options.appearance.interfaceType = Stimulsoft.Viewer.StiInterfaceType.Touch;
        }

          this.viewer.onBeginExportReport = function (event) {
            switch (event.format) {
            case Stimulsoft.Report.StiExportFormat.Excel2007:
            // event.settings.
            event.settings.excelType = Stimulsoft.Report.Export.StiExcelType.ExcelXml;

            break;
            }
            };


            this.show = true;
            this.viewer.options.width = '100%';
            this.viewer.options.height = '100%';
            this.viewer.renderHtml('viewerContent');
            // this.report.renderAsync();
        });
      }else{
        this.reportdata = res;
        // console.log(this.event)
        // console.log(this.showReport)
        // console.log(this.reportdata)
        if(this.event == 'ROID'){
          
          
          
          
          if( this.showReport == 'SalesReport' ||
              this.showReport == 'WholeSalesReport' ||
              this.showReport == 'TotalsReport' ||
              this.showReport == 'PartialReport' ||
              this.showReport == 'LayawayReport' ||
              this.showReport == 'ProductReport' ||
              this.showReport == 'CustomersReport' ||
              this.showReport == 'CustomersDetailReport' ||
              this.showReport == 'ReferralReport' ||
              this.showReport == 'DeliveryReport' ||
              this.showReport == 'DeliveryCompletedReport' ||
              this.showReport == 'DeliveryPendingReport' ||
              this.showReport == 'SecondDeliveryReport' ||
              this.showReport == 'ThirdDeliveryReport' ||
              this.showReport == 'RockfordDeliveryReport' ||
              this.showReport == 'DispatchTrackReport' ||
              this.showReport == 'DispatchTrackWithDetailsReport' ||
              this.showReport == 'DeliveryPerProductReport' ||
              this.showReport == 'PickUpPerProductReport' ||
              this.showReport == 'Avery5161FixReport' ||
              this.showReport == 'Avery5163FixReport' ||
              this.showReport == 'Avery5164FixReport' ||
              this.showReport == 'DenverPackingSlip' ||
              this.showReport == 'CincinnatiPackingSlip' 
          ){
            if( this.showReport == 'ReferralReport'){
              let newreportdata = []
              this.reportdata.forEach(element => {
                element.SO = element.SO.filter(x => x.dbDatets >= this.convert(this.dateFrom) && x.dbDatets <= this.convert(this.dateTo))
                if(element.SO.length > 0){
                  newreportdata.push(element)
                }
              });
              this.reportdata = newreportdata
            }else if(this.showReport == 'CincinnatiPackingSlip'){
              let filterdate = this.reportdata.filter(x => x.dbDatets.slice(0, 10) >= this.convert(this.dateFrom) && x.dbDatets.slice(0, 10) <= this.convert(this.dateTo))
              this.reportdata = filterdate;
            }else if(this.showReport == 'DenverPackingSlip'){
              let filterdate = this.reportdata.filter(x => x.dbDatets.slice(0, 10) >= this.convert(this.dateFrom) && x.dbDatets.slice(0, 10) <= this.convert(this.dateTo))
              this.reportdata = filterdate;
            } else {
              let filterdate = this.reportdata.filter(x => x.Date.slice(0, 10) >= this.convert(this.dateFrom) && x.Date.slice(0, 10) <= this.convert(this.dateTo))
              this.reportdata = filterdate;                       
            }
           
          }   
                 

        }

        if(this.showReport == 'DenverPackingSlip'){
          // console.log(this.reportdata)
          let filterdate = this.reportdata.filter(so => so.SOItems.some(({itemDelivery}) => (itemDelivery == "NTO" || itemDelivery == "In Stock") ))
          this.reportdata = filterdate;
        }




        //console.log(JSON.stringify(this.reportdata))
        this.report.loadFile('reports/' + this.showReport + '.mrt');
        this.report.dictionary.databases.clear();
        const dssalesprice = new Stimulsoft.System.Data.DataSet();
        dssalesprice.readJson(this.reportdata);
        this.report.regData('salesprice', null, dssalesprice);
        this.viewer.report = this.report;
        this.viewer.options.toolbar.showAboutButton = false;
        this.viewer.options.toolbar.showEmailButton = true;
        this.viewer.options.appearance.scrollbarsMode = true;
        this.viewer.options.appearance.showTooltipsHelp = false;
        this.viewer.options.appearance.showFormsHelp = false;
        this.viewer.options.appearance.showFormsHints = false;
        this.viewer.options.exports.showExportToHtml = false;
        this.viewer.options.exports.showExportToHtml5 = false;
        this.viewer.options.exports.showExportToDataFile = false;
        this.viewer.options.exports.showExportToDocument = false;
        this.viewer.options.exports.showExportDialog = false;
        this.viewer.options.toolbar.printDestination = Stimulsoft.Viewer.StiPrintDestination.Direct;

      //   if (this.showMobile === true) {
      //     this.viewer.options.toolbar.showPrintButton = false;
      //     this.viewer.options.toolbar.showSaveButton = true;
      //     this.viewer.options.toolbar.showExportToPDF = true;
      //     this.viewer.options.toolbar.showExportToExcel  = false;
      //     this.viewer.options.toolbar.showExportToRtf = false;
      //     this.viewer.options.toolbar.showExportToExcel2007 = false;
      //     this.viewer.options.toolbar.showExportToExcelXML = false;
      //      this.viewer.options.appearance.interfaceType = Stimulsoft.Viewer.StiInterfaceType.Mobile;
      // }

      if (this.showMobile === true || this.isIOS) {
        this.viewer.options.toolbar.show = true;
        this.viewer.options.toolbar.showOpenButton = false;
        this.viewer.options.toolbar.showPrintButton = false;
        this.viewer.options.toolbar.showSaveButton = true;
        this.viewer.options.toolbar.showFindButton = false;
        this.viewer.options.toolbar.showBookmarksButton = false;
        this.viewer.options.toolbar.showParametersButton = false;
        this.viewer.options.toolbar.showResourcesButton = false;
        this.viewer.options.toolbar.showEditorButton = false;
        this.viewer.options.toolbar.showFullScreenButton = true;
        this.viewer.options.toolbar.showRefreshButton = false;
        this.viewer.options.toolbar.showZoomButton = true;
        this.viewer.options.toolbar.showViewModeButton = false;
        this.viewer.options.toolbar.showDesignButton = false;
        this.viewer.options.toolbar.showAboutButton = false;
        this.viewer.options.toolbar.showExportToPDF = false;
        this.viewer.options.toolbar.showPinToolbarButton = false;
        this.viewer.options.toolbar.showExportToExcel  = false;
        this.viewer.options.toolbar.showExportToRtf = false;
        this.viewer.options.toolbar.showExportToExcel2007 = false;
        this.viewer.options.toolbar.showExportToExcelXML = false;
        this.viewer.options.toolbar.zoom = Stimulsoft.Viewer.StiZoomMode.PageWidth;
        this.viewer.options.appearance.scrollbarsMode = false;
        this.viewer.options.appearance.fullScreenMode = false;
        this.viewer.options.appearance.interfaceType = Stimulsoft.Viewer.StiInterfaceType.Touch;
    }

      this.viewer.onBeginExportReport = function (event) {
        switch (event.format) {
        case Stimulsoft.Report.StiExportFormat.Excel2007:
        // event.settings.
        event.settings.excelType = Stimulsoft.Report.Export.StiExcelType.ExcelXml;

        break;
        }
        };


        this.show = true;
        this.viewer.options.width = '100%';
        this.viewer.options.height = '100%';
        this.viewer.renderHtml('viewerContent');
        // this.report.renderAsync();
      }



      }
      , error => {
        this.show = true;
        this.errorLoading = 'Data could not be loaded. Please try again later.';
         // console.log(error);
      });
  }
  }

  convert(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

 async showPrint() {  
    let wait = await this.globals.loadScript();
    if(wait){

      //old
      // this.viewer = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
      // this.report = new Stimulsoft.Report.StiReport();
      // let attachmentStream = '';
      // let res:any;
 
      // const settingsrep = new Stimulsoft.Report.Export.StiPdfExportSettings();
      // const service = new Stimulsoft.Report.Export.StiPdfExportService();
      // const stream = new Stimulsoft.System.IO.MemoryStream();                 

      // service.exportTo(this.report, stream, settingsrep);
      // var datastream = stream.toArray();
      // attachmentStream = Stimulsoft.System.Convert.toBase64String(datastream); 

      this.viewer = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
      this.report = new Stimulsoft.Report.StiReport();
  
      this.reportFormat = 'pdf';
  
      this.report.loadFile('reports/' + this.showReport + '.mrt');
      const dssalesorder = new Stimulsoft.System.Data.DataSet();  
      this.report.dictionary.databases.clear();
      this.report.dictionary.dataStore.clear();  
  
      dssalesorder.readJson(this.reportdata);
      this.report.regData('reportdata', null, dssalesorder);
      
      this.getbase64PDF().pipe(take(1)).
      subscribe(
          async attachmentStream => {
            if(this.isIOS){        
              if(iswebKit == "isWebKit"){
                // if new aplication
                (document.getElementById('base64pdf') as HTMLInputElement).value = attachmentStream;
                (document.getElementById('sendenbase64') as HTMLInputElement).click(); 
                setTimeout(() => {           
                (document.getElementById('base64pdf') as HTMLInputElement).value = "";
                }, 1000);
              }else{
                // if old aplication
                window.location.href = "APPReport://data:application/pdf;base64," + attachmentStream;
              }
          }
      
            if(this.isAndroid){
              const byteCharacters = atob(attachmentStream);
              const byteNumbers = new Array(byteCharacters.length);
              for (let i = 0; i < byteCharacters.length; i++) {
                byteNumbers[i] = byteCharacters.charCodeAt(i);
              }
              
              const byteArray = new Uint8Array(byteNumbers);
              const blob = new Blob([byteArray], {type: 'application/pdf'});
              var fileUrl = URL.createObjectURL(blob);
              window.open(fileUrl);
            }
          }
      );  
    }

  }
 
async showSendEmail(format) {
  let wait = await this.globals.loadScript();
  if(wait){
    this.viewer = new Stimulsoft.Viewer.StiViewer(null, 'StiViewer', false);
    this.report = new Stimulsoft.Report.StiReport();

    this.reportFormat = format;
    const model = {};     
    
    let section = ''
    if(this.event == '0'){
      section = 'Email For Date Range Report'
    }else{
      section = 'Email For Single Report'
    }

    this.templateMeSvc.getTemplateMesSection(section)
    .toPromise()
    .then((result) => {          
     let dfrom = new Date(this.dateFrom)
     let dto = new Date(this.dateTo)
     let devent = new Date(this.eventdbdate)
      var Obj = {
        "<<ReportName>>": this.showReport,
        "<<Sponsor>>": this.eventsponsor,
        "<<EventDate>>": devent.toDateString(),
        "<<DateFrom>>": dfrom.toDateString(),
        "<<DateTo>>": dto.toDateString(),
        "<<DateRange>>": "From "+dfrom.toDateString()+" to "+dto.toDateString(),
        "<<Dealer>>": this.authService.decodeToken('dealername').dealername,
      };
      let subject:any = '';
      let msg:any = '';

      if (result[0].messages != '' && result[0].messages != null && result[0].messages != undefined) {
        if(JSON.parse(result[0].messages).length > 0){
          
            JSON.parse(result[0].messages).forEach(element => {
              if(element.title.toLowerCase() == 'subject'){
                subject = element.content.replace(/<<ReportName>>|<<DateFrom>>|<<DateTo>>|<<DateRange>>|<<Sponsor>>|<<EventDate>>|<<Dealer>>/gi, function(matched){
                  return Obj[matched];
                });
              }else if(element.title.toLowerCase() == 'message'){
                msg = element.content.replace(/<<ReportName>>|<<DateFrom>>|<<DateTo>>|<<DateRange>>|<<Sponsor>>|<<EventDate>>|<<Dealer>>/gi, function(matched){
                  return Obj[matched];
                });              
              }
            });

        }
      }

      if(subject == ''){
        subject = 'Report ' + this.showReport;
      }
      if(msg == ''){
        msg = 'Please find attached ' + this.showReport ;
      } 

      this.report.loadFile('reports/' + this.showReport + '.mrt');
      const dssalesorder = new Stimulsoft.System.Data.DataSet();  
      this.report.dictionary.databases.clear();
      this.report.dictionary.dataStore.clear(); 
      

      model['subject'] = subject
      model['message'] = msg

      dssalesorder.readJson(this.reportdata);
      this.report.regData('reportdata', null, dssalesorder);
      


      if (this.reportFormat === 'pdf') {     
        this.getbase64PDF().pipe(take(1)).
          subscribe(
              async res => {
                this.attachmentStream =  res;
                model['attachmentfiletype'] =  'application/pdf';
                model['attachmentfilename'] = this.showReport + '.pdf';
                model['attachmentbase64'] =  res;
                model['fromcomponent'] =  "reportlist";
                const initialState = {
                  model: model
                };
                this.bsModalRef = this.modalService.show(SendemailComponent, {initialState, class: 'modal-lg'});
              }
          );
      }

      if (this.reportFormat === 'xlsx') {    
        this.getbase64XLSX().pipe(take(1)).
        subscribe(
            async res => {
              this.attachmentStream =  res;
              model['attachmentbase64'] =  res;
              model['attachmentfiletype'] =  'application/ms-excel';
              model['attachmentfilename'] = this.showReport + '.xlsx';
              model['fromcomponent'] =  "reportlist";
              const initialState = {
                model: model
              };
              this.bsModalRef = this.modalService.show(SendemailComponent, {initialState, class: 'modal-lg'});
            }
        );
      }
  
    }).catch((err) => {
      // console.log(err);
    });
  }

}



getbase64PDF() : Observable<any> {
  
  const base64PDF = new Observable(observer => {     
    let att:any
    this.report.renderAsync(async () => {
      await new Promise(resolve => {
        setTimeout(
          () => {           
        const settingsrep = new Stimulsoft.Report.Export.StiPdfExportSettings();
        const service = new Stimulsoft.Report.Export.StiPdfExportService();
        const stream = new Stimulsoft.System.IO.MemoryStream(); 

        this.report.exportDocumentAsync((pdfData: any) => { 
        service.exportToAsync(function () {  
          
        const datastrea = stream.toArray();
        
        att = Stimulsoft.System.Convert.toBase64String(datastrea);       
        observer.next(att);

      },this.report, stream, settingsrep);
           
      },Stimulsoft.Report.StiExportFormat.Pdf);             
        resolve(''); 
       } , 2
      );     

      });
  });
  });
  
  return base64PDF;  
}


getbase64XLSX() : Observable<any> {
  
  const base64XLSX = new Observable(observer => {     
    let att:any
    this.report.renderAsync(async () => {
      await new Promise(resolve => {
        setTimeout(
          () => {           
        const settingsrep = new Stimulsoft.Report.Export.StiExcel2007ExportSettings();
        const service = new Stimulsoft.Report.Export.StiExcel2007ExportService();
        const stream = new Stimulsoft.System.IO.MemoryStream(); 

        this.report.exportDocumentAsync((pdfData: any) => { 
        service.exportToAsync(function () {  
          
        const datastrea = stream.toArray();
        
        att = Stimulsoft.System.Convert.toBase64String(datastrea);       
        observer.next(att);

      },this.report, stream, settingsrep);
           
      },Stimulsoft.Report.StiExportFormat.Pdf);             
        resolve(''); 
       } , 2
      );     

      });
  });
  });
  
  return base64XLSX;  
}


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    document.body.removeAttribute("style");
  }
}
