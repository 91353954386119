<!-- <div class="sale-order-comments-title"> -->
 <!-- <p *ngIf="!mobile" > Comments:</p> -->
<!-- </div> -->
<!-- <div class="sale-order-comments-textarea"> -->
  <textarea 
    *ngIf="!canSelect"
    [readonly]="canSelect"
    rows="3"
    class="form-control tw-rounded-md tw-border-1 sm:tw-border-1 md:tw-border-2 lg:tw-border-2 tw-border-aedgrayl"
    placeholder="Order Comments"
    [(ngModel)]="salesOrder.comments"
  >
  </textarea>
  <div *ngIf="canSelect">{{ salesOrder.comments != '' && salesOrder.comments != null ? salesOrder.comments : 'No Comments'  }}</div>
<!-- </div> -->