import { Output, Component, ElementRef, Input, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs/Subscription';
import { Pagination } from 'src/app/_modules/pagination';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ReportingService } from 'src/app/_services/reporting.service';
import { StockItemsService } from 'src/app/_services/stockitems.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import 'rxjs/add/operator/takeUntil';
import { take } from 'rxjs/operators/take';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { UpcitemdbService } from 'src/app/_services/upcitemdb.service';
import { add } from 'ngx-bootstrap/chronos';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-retail',
	templateUrl: './retail.component.html',
	styleUrls: ['./retail.component.scss'],
})
export class RetailComponent implements OnInit {
	destroy$: Subject<boolean> = new Subject<boolean>();
	modalRef: BsModalRef;

	@Input() salesOrderMode = false;
	stockItems: any = [];
	companies: any = [''];
	types: any = [];
	brands: any = [];
	products: any = [];
	pagination: Pagination;
	subscriptions: Subscription[] = [];
	selectedType = '';
	selectedMan = '';
	selectedBrand = '';
	selectedProduct = '';
	data: any = [];
	storeExcel: any = [];
	toSaveStockItems: any = [];
	showSave = false;
	isDealer = false;
	maxsize = 10;
	recordItems: any[];
	recordItemsDefault: any[];
	customStockItemEditing: any = null;
	p: number = 1;
	toEdit: string = '';
	showOrderwithStock: any = '';
	SOsItemsStock: any = [];
	customStockItems: FormGroup;
	showImageInput: boolean = false;
	imageUrl: string | null = null;
	foundProduct: any = [];
	addBarcode: boolean = false;
	showModal: boolean = false;
	datesExpired: any = [];
	productDeleted: FormGroup;
	charCount: number = 0;
	user: any;
	userNameat: string;
	avalailable:number = 0;
	@ViewChild('imageInput') imageInput: ElementRef;
	@ViewChild('popTemplateiOs', { static: false }) popTemplateiOs: TemplateRef<any>;
	@ViewChild('dateTemplate', { static: false }) dateTemplate: TemplateRef<any>;
	@ViewChild('retailTemplate', { static: false }) retailTemplate: TemplateRef<any>;
	@ViewChild('deleteTemplate', { static: false }) deleteTemplate: TemplateRef<any>;
	maxQuantity: any;
	userRole: any;
	filteredProducts: any = [];
	filteredBrands: any;
	filteredManufacturer: any;
	filteredTypes: any;

	constructor(
		private stockItemsService: StockItemsService,
		private alertify: AlertifyService,
		private router: Router,
		private authService: AuthService,
		private reportingService: ReportingService,
		private bsModalRef: BsModalRef,
		private globals: GlobalsService,
		private modalService: BsModalService,
		private fb: FormBuilder,
		private upcItem: UpcitemdbService,
		private translate: TranslateService,
	) {}

	ngOnInit() {
		this.pagination = {
			totalItems: 10,
			totalPages: 10,
			currentPage: 1,
			itemsPerPage: 10,
		};
		if (this.globals.isMobile()) {
			this.maxsize = 4;
		}
		if (this.router.url.toString().includes('stockitems') && this.authService.decodeToken('role').role !== 'Admin') {
			this.isDealer = true;
		}

		this.loadStockItemsLocal();

		this.productDeleted = this.fb.group({
			uuid: ['', []],
			barcode: ['', []],
			sku: ['', []],
			productName: ['', [Validators.required]],
			productCode: ['', []],
			price: [0, []],
			cost: [0, []],
			type: ['', []],
			retail: [1, [Validators.required, Validators.min(1)]],
			dateExpired: ['', []],
			reason: ['', [Validators.required]],
			description: ['', [Validators.required]],
			user: ['', []],
			weight: [0, []],
		});

		this.customStockItems = this.fb.group({
			uuid: ['', []],
			barcode: ['', []],
			sku: ['', []],
			brand: [' ', []],
			manufacture: [' ', []],
			productName: ['', [Validators.required]],
			price: [0, [Validators.required, Validators.min(1)]],
			cost: [0, [Validators.required, Validators.min(1)]],
			type: [null, [Validators.required]],
			stockQty: [0, []],
			dateExpired: ['', []],
			productDetails: ['', []],
			weight: [0, []],
			image: ['', []],
			anotherLote: [0, []],
			productCode: ['', []],
			retail: [0, [Validators.required, Validators.min(1)]],
			addQty: [0, []],
		});

		this.customStockItems.get('anotherLote').valueChanges.subscribe((value) => {
			if (value == 1) {
			  this.customStockItems.get('dateExpired').setValidators([Validators.required]);
			} else {
			  this.customStockItems.get('dateExpired').clearValidators();
			}	  		
			this.customStockItems.get('dateExpired').updateValueAndValidity();
		});

		this.addToInventory('');

		this.getRole()
	}

	//to add products with the scan
	addToInventory(value: string) {
		if (value != '') {
			this.stockItemsService
				.getProductsFromUniqueProducts(value)
				.pipe(takeUntil(this.destroy$))
				.subscribe((data: any) => {
					if (data.length > 0) {
						this.foundProduct = data.map((element: any) => {
							return {
								retail: element.stockQty,
								productCode: element.productCode,
								barcode: element.barcode,
								sku: element.sku,
								brand: element.brand,
								manufacture: element.manufacture,
								productName: element.productName,
								type: element.type,
								weight: element.weight,
								productDetails: element.productDetails,
								anotherLote: element.anotherLote,
								image: element.image,
							};
						});

						this.foundProduct[0].retail = 1;
						this.dateModal(this.foundProduct[0], this.dateTemplate)
						
					} else {
						this.upcItem.getProduct(value).subscribe((data: any) => {
							//console.log(data);
							if (data == 404) {
								this.alertify.error(this.translate.instant('NO_PRODUCT_INFO'));
								this.openModal(this.popTemplateiOs);
							} else {
								let barcode = '';
								if (data.upc) {
									barcode = data.upc;
								} else {
									barcode = data.ean;
								}

								const productName = data.title;
								const sku = data.model;
								const productDetails = data.description;
								const image = data.images[0];
								const brand = data.brand;
								const type = '';
								const cost = 0;
								const price = 0;
								const retail = 1;
								const weight = 0;
								const anotherLote = 0;
								const manufacture = ''

								const object = {
									productName,
									barcode,
									brand,
									sku,
									productDetails,
									weight,
									image,
									type,
									cost,
									price,
									retail,
									anotherLote,
									manufacture
								};
								//console.log(object);
								this.editStockItem(object, this.popTemplateiOs, true);
							}
						});
					}
				});
		}
	}

	dateModal(data: any, template: TemplateRef<any>) {
		const barcode = data.barcode;
    //'true' specify that is from retail
		this.stockItemsService.getDates(barcode, true).subscribe((response: any) => {
			//console.log(response);
			
			if (response['error']) {
        		this.alertify.error(this.translate.instant('NO_EXPIRATION_DATES'))
				this.editStockItem(data, this.popTemplateiOs, true);
			} else if (response['product']) {
				this.editStockItem(response['product'], this.popTemplateiOs, true);
			} else if (response['dates']) {
				this.datesExpired = response['dates'];
				this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
				this.showModal = true;
			}
		});
	}

	getProductByDate(date: string, barcode: string, id: string) {
		this.stockItemsService.getProductByDate(date, barcode, id).subscribe((response) => {
			this.showModal = false;
			this.closeModal();
			this.editStockItem(response, this.popTemplateiOs);
		});
	}

	openModal(template: TemplateRef<any>) {
		this.resetForm();
		this.loadSelect();
		this.modalRef = this.modalService.show(template, { class: 'modal-lg' });
		this.showModal = true;
	}

	closeModal(noShow?: boolean) {
		this.modalRef.hide();
		if (noShow) {
			this.toEdit = '';
			this.customStockItemEditing = null;
			this.resetForm();
			this.showModal = false;
		}
	}

	// to add a new image in inventory
	onImageSelected(event: Event) {
		const inputElement = event.target as HTMLInputElement;
		if (inputElement.files && inputElement.files.length > 0) {
			const file = inputElement.files[0];
			const imageFormat = ['image/jpeg', 'image/png', 'image/gif'];

			if (file && imageFormat.includes(file.type)) {
				const reader = new FileReader();
				reader.onload = () => {
					this.imageUrl = reader.result as string;
					this.customStockItems.get('image').setValue(this.imageUrl);
				};
				reader.readAsDataURL(file);
			} else {
				this.alertify.error(this.translate.instant('INVALID_FILE'));
			}
		}
	}

	loadSelect() {
		this.stockItemsService.getTypes().subscribe((res: any) => {
			this.types = res;
		});
	}

	saveCustomStock() {
		// console.log(this.customStockItems)
		if (this.customStockItems.valid) {
			if (this.customStockItems.get('retail').value > 0) {
				let stockQty = this.customStockItems.get('stockQty').value;
				let retail = this.customStockItems.get('retail').value

				stockQty = stockQty + retail

				this.customStockItems.patchValue({ stockQty: stockQty });
			}
			if (this.customStockItems.get('anotherLote').value != 1) {
				this.customStockItems.patchValue({ anotherLote: 0 })
			}
			this.customStockItems.controls['uuid'].setValue(this.globals.guid2());
			//this.customStockItems.controls['retail'].setValue(this.customStockItems.get('stockQty').value);

			let data = JSON.parse(JSON.stringify(this.customStockItems.value))
			delete data.addQty
			data.fromRetail = true

			this.stockItemsService
				.addStockItems(data)
				.pipe(take(1))
				.subscribe(
					() => {
						this.resetForm();
						this.alertify.success(this.translate.instant('PRODUCT_ADDED_TO_THE_RETAIL'));
						this.loadStockItemsLocal();
						this.closeModal();
					},
					(error) => {
						this.alertify.error(error);
					}
				);
		}
	}

	resetForm() {
		this.customStockItems.reset()
		this.imageUrl = null;
		this.showImageInput = false;
		this.addBarcode = false;
		this.productDeleted.reset()
		this.avalailable = 0
	}

	EditCustomStock() {
		if (this.customStockItems.valid) {
			let addQty = this.customStockItems.get('addQty').value;
			
			if (addQty > 0) {
				let stockQty = this.customStockItems.get('stockQty').value;
				let retail = this.customStockItems.get('retail').value;

				stockQty = stockQty + addQty;
				retail = retail + addQty;

				this.customStockItems.patchValue({ stockQty: stockQty });
				this.customStockItems.patchValue({ retail: retail });
			}

			if (this.customStockItems.get('anotherLote').value != 1) {
				this.customStockItems.patchValue({ anotherLote: 0 })
			}

			let data = JSON.parse(JSON.stringify(this.customStockItems.value))
			delete data.addQty
			data.fromRetail = true

			this.toSaveStockItems.push(this.customStockItems.value);

			this.stockItemsService
				.updateStockItems(data)
				.pipe(takeUntil(this.destroy$))
				.subscribe(
					(res: any) => {
						this.showSave = false;
						this.alertify.success(this.translate.instant('PRODUCT_UPDATED'));
						this.modalRef.hide();
						this.customStockItemEditing = null;
						this.resetForm();
						this.loadStockItemsLocal();
					},
					(error) => {
						this.alertify.error(error);
					}
				);
		}
	}

	clearFilter() {
		this.selectedMan = ''
		this.selectedType = ''
		this.selectedBrand = ''
		this.selectedProduct = ''
		this.filteredProducts = []
		this.filteredBrands = []
		this.filteredManufacturer = []
		this.filteredTypes = []
		this.loadStockItemsLocal()
	}

	decrementQuantity(option:any) {
		if (option == 'delete') {
			if (this.productDeleted.get('retail').value > 0) {
				this.productDeleted.get('retail').setValue(this.productDeleted.get('retail').value - 1);
		  }
		}
	}
	  
	incrementQuantity(option:any) {
		this.maxQuantity = this.avalailable
		if (option == 'delete') {
			if (this.productDeleted.get('retail').value >= this.maxQuantity) {
				this.productDeleted.get('retail').setValue(this.maxQuantity);
				this.alertify.error(this.translate.instant('QUANTITY_EXCEEDS_AVAILABLE'))
			} else {
				this.productDeleted.get('retail').setValue(this.productDeleted.get('retail').value+ 1);
			}
		}
		
	}

	onQuantityChange(event: any, diff:any) {
		this.maxQuantity = this.avalailable
		if (diff == 'delete'){
			const qty = +event.target.value;
		
			if (qty > this.maxQuantity) {
			this.productDeleted.get('retail').setValue(this.maxQuantity);
			this.alertify.error(this.translate.instant('QUANTITY_EXCEEDS_AVAILABLE'))
			} else if (qty < 0) {
				this.productDeleted.get('retail').setValue(0);
			}
		}
		
	}

	deleteModal(product: any, tem: TemplateRef<any>) {
		const retail = product.retail
		const sold =  product.sold
		this.avalailable = retail - sold
		this.customStockItems.patchValue(product)
		this.productDeleted.patchValue(product)
		this.productDeleted.controls['user'].setValue(this.userNameat);
		this.modalRef = this.modalService.show(tem, { class: 'modal-lg', ignoreBackdropClick: true });
		this.showModal = true
	}

	deleteFromRetail(productToDelete: any, product:any) {
		this.closeModal(true)
		const data = {
			'productToDelete':productToDelete,
			'product': product
		}

		let msg = this.translate.instant('DELETE_PRODUCT_CONFIRMATION')

		this.alertify.confirm(
			msg,
			() => {
				this.stockItemsService
					.deleteFromRetail(data)
					.pipe(takeUntil(this.destroy$))
					.subscribe(
						(response) => {
							if (response['error']){
								this.alertify.error(this.translate.instant('PRODUCT_CANNOT_BE_DELETED'));
							} else {
								this.alertify.success(this.translate.instant('PRODUCT_DELETED_FROM_RETAIL'));
								this.clearFilter();
								this.resetForm();
								this.loadStockItemsLocal();
							}
						},
						(error) => {
							this.alertify.error(error);
						}
					);
			},
			() => {}
		);
	}

	editDate() {
		this.showModal = false;
		this.closeModal();
		this.editStockItem(this.foundProduct[0], this.popTemplateiOs, true);
	}

	editStockItem(stock: any, tem?: TemplateRef<any>, add?: any) {
		this.loadSelect();
		this.customStockItemEditing = stock;
		this.customStockItems.patchValue(stock)
		//console.log(this.customStockItems.value);
		
		this.modalRef = this.modalService.show(tem, { class: 'modal-lg', ignoreBackdropClick: true });
		this.showModal = true;

		if (add) {
			this.customStockItemEditing = null;
			this.addBarcode = true;
		}
	}

	showDetails(data: any, tem?: TemplateRef<any>) {
		this.customStockItems.patchValue(data)
		this.modalRef = this.modalService.show(tem, { class: 'modal-lg', ignoreBackdropClick: true });
		this.showModal = true;
	}

	//Funciones para filtro JS
	loadStockItemsLocal() {
		this.stockItemsService
			.getRetailItems(
				this.pagination.currentPage,
				10,
				this.selectedMan,
				this.selectedType,
				this.selectedBrand,
				this.selectedProduct
			)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: any) => {
					this.recordItems = res.result.data;
					this.pagination = res.pagination;
					this.stockItems = res.result.data;
					this.companies = res.result.companies;
					this.types = res.result.type;
					this.brands = res.result.brand;
					this.products = res.result.products;
				},
				(error) => {
					this.alertify.error(error);
				}
			);
	}

	//For select filters
	loadFilter(event: any, field: any, value: any) {
		if (field == 'product') {
			this.selectedProduct = value;
		}
		if (field == 'brand') {
			this.selectedBrand = value;
		}
		if (field == 'manufacturer') {
			this.selectedMan = value;
		}
		if (field == 'type') {
			this.selectedType = value;
		}
		this.filteredProducts = []
		this.filteredBrands = []
		this.filteredManufacturer = []
		this.filteredTypes = []
		this.loadStockItemsLocal()
	}

	//Termina Funciones para Filtro JS
	get selectedTypeMod() {
		return this.selectedType;
	}

	set selectedTypeMod(value) {
		this.selectedType = value;
	}

	get selectedManMod() {
		return this.selectedMan;
	}

	set selectedManMod(value) {
		this.selectedMan = value;
	}

	get selectedBrandMod() {
		return this.selectedBrand;
	}

	set selectedBrandMod(value) {
		this.selectedBrand = value;
	}

	get selectedProductMod() {
		return this.selectedProduct;
	}

	set selectedProductMod(value) {
		this.selectedProduct = value;
	}

	exportToCSV(data: any = []) {
		this.reportingService.exportToCSV(data, 'StockItems.csv');
	}

	loadStockItemsForExcel() {
		let data: any = [];
		this.stockItemsService
			.getRetailItems(1, 1, this.selectedMan, this.selectedType, this.selectedBrand, this.selectedProduct, 1)
			.pipe(takeUntil(this.destroy$))
			.subscribe(
				(res: any) => {
					data = res.result;
					this.exportToCSV(data);
				},
				(error) => {
					this.alertify.error(error);
				}
			);

		return data;
	}

	pageChanged(event: any): void {
		this.pagination.currentPage = event.page;
		this.loadStockItemsLocal();
	}

	ngOnDestroy(): void {
		this.destroy$.next(true);
		this.destroy$.unsubscribe();
	}

	ngAfterViewInit() {
		if (this.imageInput) {
			this.imageInput.nativeElement.click();
		}
	}

	updateCharCount(event: Event) {
		this.charCount = (event.target as HTMLTextAreaElement).value.length;
		if (this.charCount > 300) {
			const currentValue = (event.target as HTMLTextAreaElement).value;
			(event.target as HTMLTextAreaElement).value = currentValue.substr(0, 300);
			this.charCount = 300;
		}
	}
	getRole() {    
		this.user = JSON.parse(this.authService.decodeToken('user').user)
		this.userNameat = this.user['name']  
		this.userRole = this.authService.decodeToken('role').role;     
	}

	filterProducts(query: string, option:any) {
		const searchTermLowerCase = query.toLowerCase();
		const searchRegex = new RegExp(searchTermLowerCase, 'i');

		//console.log(searchTermLowerCase);
		
		if (searchTermLowerCase != ''){
			if (option == 'productName'){
				this.filteredProducts = this.products.filter((item: any) => {
					const itemValues = [
						item.productName
					];
	
					const validItemValues = itemValues
						.filter((value) => value !== null && value !== undefined)
						.map((value) => value.toLowerCase());
	
	
					const searchTerms = searchTermLowerCase.split(' ');

					return validItemValues.some((value: string) => {
						return searchTerms.every((term: string) => {
							const termRegex = new RegExp(term);
							return termRegex.test(value);
						});
					});
				});
			} 

			if (option == 'brand'){
				this.filteredBrands = this.brands.filter((item: any) => {
					const itemValues = [
						item.brand
					];
	
					const validItemValues = itemValues
						.filter((value) => value !== null && value !== undefined)
						.map((value) => value.toLowerCase());
	
	
					const searchTerms = searchTermLowerCase.split(' ');

					return validItemValues.some((value: string) => {
						return searchTerms.every((term: string) => {
							const termRegex = new RegExp(term);
							return termRegex.test(value);
						});
					});
				});
			} 

			if (option == 'manufacturer'){
				this.filteredManufacturer = this.companies.filter((item: any) => {
					const itemValues = [
						item.manufacture
					];
	
					const validItemValues = itemValues
						.filter((value) => value !== null && value !== undefined)
						.map((value) => value.toLowerCase());
					
					const searchTerms = searchTermLowerCase.split(' ');

					return validItemValues.some((value: string) => {
						return searchTerms.every((term: string) => {
							const termRegex = new RegExp(term);
							return termRegex.test(value);
						});
					});
			});
			} 

			if (option == 'type'){
				this.filteredTypes = this.types.filter((item: any) => {
					const itemValues = [
						item.productType
					];
	
					const validItemValues = itemValues
						.filter((value) => value !== null && value !== undefined)
						.map((value) => value.toLowerCase());
	
	
					const searchTerms = searchTermLowerCase.split(' ');

					return validItemValues.some((value: string) => {
						return searchTerms.every((term: string) => {
							const termRegex = new RegExp(term);
							return termRegex.test(value);
						});
					});
				});
			} 
		} else {
			this.filteredProducts = []
			this.filteredBrands = []
			this.filteredManufacturer = []
			this.filteredTypes = []
		}
	}

	preventEnter(event: KeyboardEvent) {
		event.preventDefault(); 
	}
}
