import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';

declare let SetDevice: any;
declare let SetDeviceID: any;
declare let SetDeviceMAC: any;
declare let ReadDevice: any;
declare let DeactivateDevice: any;
declare let android: any;
declare let ipcMain: any;

@Injectable({
  providedIn: 'root'
})
export class CardReaderService {
  private title = new BehaviorSubject<String>('');
  deviceid = new BehaviorSubject<String>(''); //THIS DEVICE ID
  devicemac = new BehaviorSubject<String>(''); // READER MAC ADDRESS
  cardNo = new BehaviorSubject<String>('');
  cardName = new BehaviorSubject<String>('');
  cardExp = new BehaviorSubject<String>('');
  deviceError = new BehaviorSubject<String>('');
  deviceStatus = new BehaviorSubject<String>('');
  cardAuthCode = new BehaviorSubject<String>('');
  cardAuthStatus = new BehaviorSubject<String>('');
  cardError = new BehaviorSubject<String>('');
  accountType = new BehaviorSubject<String>('');
  transactionResponse = new BehaviorSubject<any>({});
  soPayment = new BehaviorSubject<any>({});
  private title$ = this.title.asObservable();
  status = new BehaviorSubject<String>('');
  cardSignatureAccept = new BehaviorSubject<String>('');
  paymentSaved = new BehaviorSubject<boolean>(false);
  isIOS = false;
  isAndroid = false;
  platform = '';
  baseUrl = environment.apiUrl;
  laravel = environment.apiLaravel;
constructor(
  private _ngZone: NgZone,
  private _route: ActivatedRoute,
  private _router: Router,
  private http: HttpClient
) { 

  window['angularComponentRef'] = {
    zone: this._ngZone,
    getDeviceID: (deviceid, mac) => this.getDeviceID(deviceid, mac),
    getDeviceIDOnly: (deviceid) => this.getDeviceIDOnly(deviceid),
    getMAC: (mac) => this.getDeviceMAC(mac),
    getDeviceMAC: (mac) => this.getDeviceMAC(mac),
    setCreditCard: (cardNo, cardName, cardExp) => this.setCreditCard(cardNo, cardName, cardExp),
    getMessage: (message) => this.showMessage(message),
    gotNothing: () => this.gotNothing(),
    getDeviceClear: () => this.getDeviceClear(),
    tryReadingCard: () => this.getDeviceClear(),
    startReadingCard: () => this.getDeviceClear(),
    failedRead: () => this.failedRead(),
    setReaderStatus: (status)=> this.setReaderStatus(status),
    setDeviceStatus: (status)=> this.setDeviceStatus(status),
    component: this
  };

  this.platform = navigator.userAgent;
  if (this.platform.match(/Android/i)) {
    this.isAndroid = true;
  }
  if (this.platform.match(/iPhone|iPad|iPod/i)) {
    this.isIOS = true;
  }

  // if (this.platform.includes('Mac')) {
  //   this.showMobile = false;
  // }

  // if (this.platform.includes('Win')) {
  //   this.showMobile = false;
  // }



}
showMessage(message) {
  alert(message);
}

displayMessage(values): Observable<any>{
  return this.http.post(this.baseUrl+'showHello', values)
}
pingAvalara(values): Observable<any>{
  return this.http.post(this.baseUrl+'ping', values)
}
simplePayment(values): Observable<any>{
  return this.http.post(this.baseUrl+'simplePayment', values)
}
getSimplePayment(values): Observable<any>{
  return this.http.post(this.baseUrl+'getSimplePayment', values)
}
printSimplePayment(values): Observable<any>{
  return this.http.post(this.baseUrl+'printSimplePayment', values)
}
emailSimplePayment(values): Observable<any>{
  return this.http.post(this.baseUrl+'emailSimplePayment', values)
}
voidSimplePayment(values): Observable<any>{
  return this.http.post(this.baseUrl+'voidSimplePayment', values)
}
setReaderStatus(status) {
  this.status = status;
}

setDeviceStatus(status) {
  this.deviceStatus.next(status);
  this.deviceid.next('gotstatus');
}

getDeviceID(deviceid, macreader) {
  if (deviceid.length > 0) {
    this.deviceid.next(deviceid);
  }
  if (macreader.length > 0) {
    this.devicemac.next(macreader);
  }
 }

 getDeviceIDOnly(deviceid) {
  if (deviceid.length > 0) {
    this.deviceid.next(deviceid);
  }
 }

 getDeviceMAC(devicemac) {
  if (devicemac.length > 0) {
    this.deviceid.next(devicemac);
  }
 }

 setCreditCard(cardNo, cardName, cardExp) {
  if (cardNo.length > 0) {
  this.cardNo.next(cardNo);
  this.cardName.next(cardName);
  this.cardExp.next(cardExp);
  }

}

gotNothing() {
  const message = 'Could not connect to Card Reader. Please make sure the card reader is turned on and retry.';
  this.deviceError.next(message);
}

getDeviceClear() {
  this.deviceid.next("");

}

failedRead() {
  const message = 'Failed to connect to Card Reader. Please make sure the card reader is turned on and retry.';
  this.deviceError.next(message);
}



  setDevice() {
    try {
      
    SetDevice.postMessage(JSON.stringify({ action: 'device' }));
    }  catch(e) {
     // console.log('Error CardReader Service Set Device');
    }
  }

  setDeviceID() {
    try {
      if (this.isAndroid) {
        android.setdevice('set');
      } else
      if (this.isIOS) {
        window.location.href = 'SetDeviceID://SetDeviceID';
      } else {
       // console.log('Start Reading');
      }
      ipcMain.send('setdevice','set');
    SetDeviceID.postMessage(JSON.stringify({ action: 'device' }));
    } catch(e) {
     // console.log('Error CardReader Service Set DeviceID');
    }
  }

  setDeviceMAC() {
    try {
    SetDeviceMAC.postMessage(JSON.stringify({ action: 'device' }));
    }  catch(e) {
     // console.log('Error CardReader Service Set Device MAC');
    }
  }


  readCard(mac) {
    if (mac !== null) {
    try {

      ReadDevice.postMessage(JSON.stringify({ mac: mac }));
      }  catch(e) {
       // console.log('Error CardReader Service Set Read Card');
      }
    }
  }

  stopCard(mac) {
    if (mac !== null) {
    try {
      if (this.isIOS) {
        window.location.href = 'DisconnectDevice://DisconnectDevice';
      }
      if (this.isAndroid) {
        android.disconnectdevice('mac');
      }
      DeactivateDevice.postMessage(JSON.stringify({ mac: mac }));
      }  catch(e) {
       // console.log('Error CardReader Service Stop Card');
      }
    }
  }


  deviceDisconnect() {
    try {
      if (this.isIOS) {
        window.location.href = 'DisconnectDevice://DisconnectDevice';
      }

      if (this.isAndroid) {
        android.disconnectdevice('mac');
      }
      DeactivateDevice.postMessage(JSON.stringify({ mac: ''}));
      }  catch(e) {
       // console.log('Error CardReader Service Set Device Disconnect');
      }
    
  }



}
