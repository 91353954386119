<div class="container d-flex flex-column p-3">

    <div class="d-flex flex-column" *ngIf="!stop">
        <div class="tw-flex justify-content-between tw-mb-1">            
          <div class="tw-flex tw-text-xl sm:tw-text-lg md:tw-text-3xl lg:tw-text-3xl tittle-0">
            <span class="material-icons tw-my-auto">qr_code</span>
            Scan the QR code
          </div>
          <button class="tw-flex" type="button" (click)="hideModal()">
            <span class="material-icons tw-my-auto">close</span>
          </button>
        </div>
        <zxing-scanner
          (click)="changeCamera()"    
          class="mx-5 mx-auto"
          #scanner
          [(device)]="desiredDevice"
          (scanComplete)="scanCompleteHandler($event)"
          (scanSuccess)="scanSuccessHandler($event)"
          (camerasFound)="camerasFoundHandler($event)">
        </zxing-scanner>
    </div>

    <div class="" *ngIf="stop">
      
      <div class="tw-text-xl sm:tw-text-lg md:tw-text-3xl lg:tw-text-3xl tittle-0 tw-w-full tw-text-center">Choose a Size</div>
      <br>

      <div class="tw-text-xs tw-font-semibold tw-text-center">{{itemSelected?.Manufacturer}}</div>
      <div class="tw-text-xs tw-font-semibold tw-text-center">{{itemSelected?.product}}</div>
      <div class="tw-text-xs tw-font-semibold tw-text-center">{{itemSelected?.name}}</div>

          <div *ngFor="let item of options" class="tw-mb-2">
            <div class=" tw-flex tw-justify-between">

              <div class="tw-pr-2 tw-flex tw-justify-between tw-w-full">
                <div class="tw-w-full tw-h-full tw-flex">
                  <span class="tw-my-auto" *ngIf="itemSelected?.productsId == item.productsId" >
                      {{item?.sizeName}} (Actual size)
                  </span>
                  <span class="tw-my-auto" *ngIf="itemSelected?.productsId != item.productsId"  >
                      {{item?.sizeName}}
                  </span>
                </div>                
  
                <div class="tw-my-auto tw-text-bold">
                    {{item?.salesPriceNum   | currency }}
                </div> 
              </div>
             

              <button  (click)="submitItem(item)" 
              class="tw-flex tw-p-1 tw-rounded-full tw-bg-aedblue tw-my-auto">
                <span class="material-icons tw-m-auto" style="font-size: 1.8rem; color: white;">add</span>
              </button>
                   
            </div>
          </div>
    </div>
</div>