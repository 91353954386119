<div class="modal-background">    
  <div class="modal-container">
  <div class="modal-body-header" >
    <div class="tw-mr-auto"><button class="btn-blue-so" *ngIf="fromSalesOrder" (click)="openlistCustomer()">{{ 'SELECTCUSTOMER' | translate }}</button></div>
    <div class="tw-w-full tw-text-center"><h4 class="title-1-orange">{{editMode ? ('EDIT' | translate) : ('ADD' | translate)}} {{ 'CUSTOMER' | translate }}</h4></div> 
    <div class="tw-ml-auto"><app-closebutton></app-closebutton></div>
  </div>

  <small *ngIf="form?.dirty" class="tw-bg-aedgrays bg-white ">
    <strong><i class="fa fa-warning"></i>{{ 'INFORMATION' | translate }}: </strong> {{ 'INFORMATIONCHANGES' | translate }} 
  </small>


  <form class="form-container" [formGroup]="form" (ngSubmit)="addCustomer()">
   
    <!-- <div class="form-half"> -->
      <!-- <div class="title-2 tw-mb-2">Billing Address</div>       -->
      <form [formGroup]="form" (ngSubmit)="addCustomer()">
        <formly-form [form]="form" [fields]="fields" [model]="customer" [options]="options">
        </formly-form>
      </form>
    <!-- </div> -->

    <!-- <div class="form-half">
      <div class="form-header-container  tw-mb-2">
        <h5 class="title-2">Shipping Address</h5>
        <button class="btn-blue-so" type="button" (click)="copyAddressToShipping()">
          <span class="material-icons mr-2" id="btnCopyFromBilling"> file_copy </span>Copy from Billing
        </button>
      </div>
     
      <form [formGroup]="formShipping" (ngSubmit)="addCustomer()">
        <formly-form [form]="formShipping" [fields]="fieldsShipping" [model]="customer" [options]="optionsShipping" >
          <div class="form-group text-center"></div>
        </formly-form>
      </form>
    </div>    -->
     
    <div class="tw-flex tw-mt-3">
      <button class="btn-blue-so tw-w-24" *ngIf="addMode" type="submit" id="btnSaveButton" >
        <span class="material-icons"> add </span> {{ 'ADD' | translate }}
      </button>

      <button class="btn-blue-so tw-w-24 tw-ml-3" *ngIf="editMode" type="button" (click)="updateCustomer()">
        <span class="material-icons">save</span> {{ 'GUARDAR' | translate }}
      </button>

      <button class="btn-blue-so tw-w-24 tw-ml-3" (click)="cancelFormHTML()" type="button">
        <span class="material-icons"> close </span> {{ 'CANCEL' | translate }}
      </button>
        
      <button hidden *ngIf="storeSettings?.avaTax == 1 || storeSettings?.avaTax == true" class="btn-blue-so tw-ml-3" (click)="validateAddress()" type="button">
        <span class="material-icons"> check </span> {{ 'VALIDATEADDRESS' | translate }}
      </button>
    </div>

  </form>
</div>
</div>

<ng-template #popTemplateiOs >
  <div class="tw-flex tw-flex-col tw-w-full tw-justify-center tw-pt-3">
    <p class="tw-text-xl tw-text-aedorangel tw-font-semibold tw-text-center">Avalara Suggested Address(es)</p>
    <p class="tw-text-base tw-text-aedgrays tw-font-normal tw-text-center">Select an address below</p>
  </div>
  <div class="tw-p-4 tw-grid tw-grid-cols-3 tw-space-x-3" >
    <!-- Customer shipping -->
    <div class="tw-border-2 tw-text-base tw-flex tw-flex-col hover:tw-shadow-lg tw-cursor-pointer tw-border-gray-400 tw-rounded-lg tw-p-2" (click)="setAddressFromAvalara(null)">
      <span class="tw-font-bold tw-break-words" >Current Shipping Address</span>
      <span >{{customer?.deliveryAddress1}} </span>
      <span *ngIf="customer?.deliveryAddress2 != ''" >{{customer?.deliveryAddress2}} </span>
      <span >{{customer?.deliveryState}} {{customer?.deliveryCity}} {{customer?.deliveryZip}}</span>
    </div>
    <!-- From Avalara -->
    <div class="tw-border-2 tw-text-base tw-flex tw-flex-col hover:tw-shadow-lg tw-cursor-pointer tw-border-gray-400 tw-rounded-lg tw-p-2" *ngFor="let address of validatedAddress" (click)="setAddressFromAvalara(address)">
      <span class="tw-font-bold tw-break-words" >{{address?.addressType}} </span>
      <span [ngClass]="{'tw-text-green-500': address.line1 != customer?.deliveryAddress1}">{{address?.line1}} </span>
      <span [ngClass]="{'tw-text-green-500': address.line2 != customer?.deliveryAddress2}" *ngIf="address?.line2 != ''" >{{address?.line2}} </span>
      <div class="tw-flex tw-space-x-1">
        <span [ngClass]="{'tw-text-green-500': address.region != customer?.deliveryState}" >{{address?.region}}</span> 
        <span [ngClass]="{'tw-text-green-500': address.city != customer?.deliveryCity}">{{address?.city}} </span> 
        <span [ngClass]="{'tw-text-green-500': address?.postalCode?.substring(0,5) != customer?.deliveryZip}">{{address?.postalCode?.substring(0,5)}}</span>
      </div>
    </div>
  </div>
</ng-template>