import { Injectable } from '@angular/core';
import { User } from '../_modules/user';
import { UserService} from '../_services/user.service';
import { AlertifyService } from '../_services/alertify.service';
import { Resolve, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators/catchError';
import { Customer } from '../_modules/customers';
import { CustomerService } from '../_services/customer.service';

@Injectable()
export class CustomerListResolver implements Resolve<Customer []> {

    pageNumber = 1;
    pageSize = 9;

    constructor(private customerService: CustomerService, private router: Router, private alertify: AlertifyService) {}

    resolve(route: ActivatedRouteSnapshot): Observable<Customer []> {
        return this.customerService.getCustomersLar(this.pageNumber, this.pageSize).pipe(
            catchError(error => {
                this.alertify.error(error);
                this.router.navigate(['/']);
                return of(null);
            })
        );
    }
}
