<div class="tw-rounded-sm " *ngFor="let deliver of deliveries; let impar = odd" [ngClass]="impar ? 'tw-bg-aedgraypaydel' :''">
  
  <div class="tw-flex tw-px-2">
    <div class="tw-font-bold">{{deliver.Type}}</div>
    <!-- <p class="item-eta">{{deliver.deliveryCode}}</p> -->
    <div class="item-amount tw-ml-auto tw-font-bold">$ {{deliver.deliveryAmountNum | number:'1.2-2'}}</div>
  </div>

  <div class="tw-flex tw-px-2" *ngIf="deliver.deliveryNotes">
    <div class="tw-font-bold">Note</div>
    <div class="item-type tw-ml-auto">{{deliver.deliveryNotes}}</div>  
  </div>

</div>