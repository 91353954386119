


<div class="modal-body">
  <div *ngIf="so['Total'] == balance">
    <div class="d-flex justify-content-center">
     <p> What do you want to do next?</p>
    </div>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-3 mr-1"
        (click)="statusChange('Order')"
        type="button"
      >
        Estimate
      </button>
  
      <button
        class="btn btn-3"
        (click)="statusChange('Draft')"
        type="button"
      >
        Parked
      </button>
    </div>
  </div>
  <div class="" *ngIf="so['Total'] > balance">
    <div class="d-flex justify-content-center">
      <p class="text-center">The Order still has a balance of $ {{ balance | number: "1.2-2" }}. <br />
        Choose Order Status:</p>
    </div>
    <div class="d-flex flex-wrap justify-content-center">
      <button
        class="btn btn-3 mr-1"
        (click)="statusChange('Partial Payment/COD')"
        type="button"
      >
        Partial Payment/COD
      </button>
  
      <button
        class="btn btn-3 mr-1"
        (click)="statusChange('Layaway')"
        type="button"
      >
        Layaway
      </button>
  
      <button
        class="btn btn-3"
        (click)="cancel()"
        type="button"
      >
        Back to Order
      </button>
    </div>
  </div>

</div>