<div [ngClass]="salesOrderMode === true ? 'modal-body modal-body-white' : ''">
  <div class="nav-display-options-mobile" [ngClass]="{ 'no-fixed': salesOrderMode }">
    <div class="w-100 d-flex justify-content-between align-items-center">
      <h6 class="ml-2">Items in Stock: {{ totalitems }} </h6>
      <!-- <div class="btn btn-3 mr-2 mb-2" (click)="openFilterMenu()">
        Filter
      </div> -->
    </div>

    <div class="selecter-filter-container" [ngClass]="{ 'active': searched }">
      <div class="selecter-filter-container-title store-filter-container row pr-0 pl-0" [ngClass]="{'fullheight': filters > 2 }"
      >
        <div class="col-12 col-md-6" *ngIf="selectedManMod && searched">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">
                Manufacturer:
              </h6>
              <span class="selecter-filter-container-title-select-event">{{
                manuFilter
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="selectedStyleMod && searched">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">
                Style:
              </h6>
              <span class="selecter-filter-container-title-select-event">{{
                styleFilter
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="selectedSizeMod && searched">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">
                Size:
              </h6>
              <span class="selecter-filter-container-title-select-event">{{
                selectedSizeMod
              }}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="selectedProductMod && searched">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">
                Product:
              </h6>
              <span class="selecter-filter-container-title-select-event">{{
                selectedProductMod
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="selecter-filter-container" [ngClass]="{ 'active': searchedInput }"> 
      <div class="selecter-filter-container-title store-filter-container row pr-0 pl-0" [ngClass]="{'fullheight': filters > 2 }">
        <div class="col-12 col-md-6" *ngIf="filterManu">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Manufacturer:</h6>
              <span class="selecter-filter-container-title-select-event">{{filterManu}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="filterStyle">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Style:</h6>
              <span class="selecter-filter-container-title-select-event">{{filterStyle}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="filterSize">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Size:</h6>
              <span class="selecter-filter-container-title-select-event">{{filterSize}}</span>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="filterProduct">
          <div class="selecter-filter-container-title p-0">
            <div class="d-flex align-items-center">
              <h6 class="selecter-filter-container-title-select-header">Product:</h6>
              <span class="selecter-filter-container-title-select-event">{{filterProduct}}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="selecter-filter-container selecter-filter-container-tabs" [ngClass]="{ active: filter }">
      <div class="selecter-filter-container-title">
        <div btnRadioGroup  [formControl]="radioUpdateBy"  class="sale-order-detail-content-set-for mr-2"  role="tablist"  aria-orientation="vertical">
          <div  class="button-container">
            <div
              class="button-pickup"
              data-toggle="pill"
              role="tab"
              aria-controls="v-pills-profile"
              aria-selected="false"
              btnRadio="dollar"
              (click)="openTabFilter()"
            >
            Filter
            </div>
            <div
            style="padding: 0.35em 0.7em;"
              class="button-delivery"
              data-toggle="pill"
              role="tab"
              aria-controls="v-pills-home"
              aria-selected="true"
              btnRadio="percent"
              (click)="openTabFilter()"
            >
            Search
            </div>
          </div>    
    </div>
      </div>

      <div class="row" *ngIf="!tabFilterNav">
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2">
              <label for="Manufacture" class="mb-0 mr-2">Manufacturer</label>
            </div>
            <select [(ngModel)]="selectedManMod" class="form-control" (ngModelChange)="loadSelectFilters($event,'MFGID')">
              <option disabled value="" selected
                >Please choose manufacturer</option
              >
              <option *ngFor="let com of companies" [ngValue]="com?.MFGID">{{
                com?.companyName
              }}</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2">
              <label for="Manufacture" class="mb-0 mr-2">Style</label>
            </div>
            <select
              [(ngModel)]="selectedStyleMod"
              (ngModelChange)="loadSelectFilters($event,'styleId')"
              name="style"
              class="form-control"
              id="styleselect"
              onchange=""
            >
              <option disabled value="" selected>Please choose Style</option>
              <option [ngValue]="sty?.styleId" *ngFor="let sty of styles">{{
                sty?.styleName
              }}</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2">
              <label for="Manufacture" class="mb-0 mr-2">Size</label>
            </div>
            <select [(ngModel)]="selectedSizeMod" class="form-control" (ngModelChange)="loadSelectFilters($event,'sizeName')">
              <option disabled value="" selected>Please choose Size</option>
              <option *ngFor="let size of sizes" [ngValue]="size?.sizeName">{{
                size?.sizeName
              }}</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2">
              <label for="Manufacture" class="mb-0 mr-2">Product</label>
            </div>
            <select
              [(ngModel)]="selectedProductMod"
              (ngModelChange)="loadSelectFilters($event,'product')"
              name="product"
              class="form-control"
              id="sizeselect"
              onchange=""
            >
              <option disabled value="" selected>Please choose Product</option>
              <option
                [ngValue]="product?.product"
                *ngFor="let product of products"
                >{{ product?.product }}</option
              >
            </select>
          </div>
        </div>
        <div class="col-12 d-flex align-items-center">
          <div class="form-group mt-3 mb-3 d-flex">
            <button
              (click)="this.filter = false;"
              class="btn btn-3 mr-1"
            >
              Filter
            </button>
            <button (click)="clearALL()" class="btn btn-3 mr-1">Clear</button>
            <button
              (click)="clearALL(); this.filter = false"
              class="btn btn-3 mr-1">
              Show All
            </button>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="tabFilterNav">
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2 pl-0"><label for="Manufacture" class="mb-0 mr-2">Manufacturer</label></div>
            <input class="form-control" type="text" [(ngModel)]="filterManu">
            <!-- (input)="searchinput($event.target.value,'companyName')"  -->
          </div>
        </div>
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2 pl-0">
              <label for="Manufacture" class="mb-0 mr-2">Style</label>
            </div>
            <input class="form-control" type="text" [(ngModel)]="filterStyle">
            <!-- (input)="searchinput($event.target.value,'styleName')" -->
          </div>
        </div>
        <div class="col-12">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2 pl-0"><label for="Manufacture" class="mb-0 mr-2">Size</label></div>
            <input class="form-control" type="text" [(ngModel)]="filterSize">
            <!-- (input)="searchinput($event.target.value,'sizeName')" -->
          </div>
        </div>
        <div class="col-12 ">
          <div class="form-group d-flex align-items-center">
            <div class="col-3 col-md-2 pl-0">
              <label for="Manufacture" class="mb-0 mr-2">Product</label>
            </div>
            <input class="form-control" type="text" [(ngModel)]="filterProduct">
            <!-- (input)="searchinput($event.target.value,'product')" -->
          </div>
        </div>
        <div class="col-12 d-flex align-items-center">
          <div class="form-group mt-3 mb-3 d-flex">
            <button (click)="searchInputApply();this.filter = false" class="btn btn-3 mr-1">Search</button>
            <button (click)="clearsearchInputApply();this.searchedInput = false" class="btn btn-3 mr-1">Clear</button>
            <button (click)="clearsearchInputApply();this.filter = false;this.searchedInput = false" class="btn btn-3 mr-1">Show ALL</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div
    class="customer-content p-3"
    *ngIf="!salesOrderMode"
    [ngClass]="{ 'scroll-top-store-catalog': searched || searchedInput , 'customer-store-scroll': !salesOrderMode }"
  >
    <div class="card-customer-mobile-record border" *ngFor="let sp of recordItems | paginate: { itemsPerPage: 10, currentPage: p }">
      <div class="card-customer-mobile-record-base-info">
        <div class="row">
          <!-- Manufacture -->
          <div class="col-12">
            <p class="m-0">
              <strong>{{ sp.companyName }}</strong>
            </p>
          </div>
          <!-- Style -->
          <div class="col-12">
            <p class="m-0 large-text-card">{{ sp.styleName }}</p>
          </div>
          <!-- Size -->
          <div class="col-6">
            <p class="m-0">{{ sp.sizeName }}</p>
          </div>
          <!-- Type -->
          <div class="col-6">
            <p class="m-0">Type: {{ sp.Type }}</p>
          </div>
          <!-- Sale Price -->
          <div class="col-6">
            <input
              *ngIf="!salesOrderMode"
              class="form-control form-control-sm text-right"
              type="number"
              name=""
              (blur)="editQuantity(sp.supItemID, sp, false)"
              required
              [(ngModel)]="sp.salesPriceNum"
            />
            <div *ngIf="salesOrderMode">{{ sp.salesPriceNum }}</div>
          </div>
          <!-- WholeSale -->
          <div class="col-6" *ngIf="isDealer">
            <p class="m-0">WholeSale: {{ sp.wholeSalePriceNum }}</p>
          </div>
          <div class="col-12 d-flex justify-content-between w-100">
            <!-- Added -->
            <p class="m-0" *ngIf="!salesOrderMode">
              <strong> Added</strong>
              {{ sp.PO }}
            </p>
            <!-- Sold -->
            <p class="m-0" *ngIf="!salesOrderMode">
              <strong> Sold</strong>
              {{ sp.Sales }}
            </p>
            <!-- Quantity -->
            <p class="m-0">
              <strong>Qty</strong>
              {{ sp.Stock }}
            </p>
          </div>
          <!-- Buttons -->
          <div class="col-12">
            <div class="d-flex justify-content-end w-100">
              <button
                *ngIf="!salesOrderMode && sp.Sales == 0"
                class="btn btn-delete"
                (click)="deleteStockItem(sp.id)"
              >
                <span class="material-icons"> delete </span>
              </button>
              <button
                *ngIf="
                  !salesOrderMode && sp.Sales > 0 && sp.PO > 0 && sp.Stock > 0
                "
                class="btn btn-delete"
                (click)="removeStockItem(sp.id, sp)"
              >
                <span class="material-icons">remove </span>
              </button>
              <button
                *ngIf="salesOrderMode"
                class="btn btn-add"
                (click)="addSoItem(sp)"
              >
                <span class="material-icons"> add </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    
  </div>

  <div class="customer-content p-3" *ngIf="salesOrderMode" >
    <div class="card-customer-mobile-record border" *ngFor="let sp of recordItems | paginate: { itemsPerPage: 10, currentPage: p }">
      <div class="card-customer-mobile-record-base-info">
       
        <!-- new row -->
        <div class="row">

          <div class="col-8 d-flex flex-column">
            <!-- Manufacture -->
          <div class="d-flex containers justify-content-between w-100">
            <p class="m-0 col-md-6 col-6"><strong>{{ sp.companyName }}</strong></p>
            <p class="m-0 col-md-6 col-6">{{ sp.styleName }}</p>
          </div>
          <!-- Style -->
          <div class="d-flex containers justify-content-between w-100">
            <p class="m-0 col-md-6 col-6">{{ sp.sizeName }}</p>
            <p class="m-0 col-md-6 col-6">Type: {{ sp.Type }}</p>
          </div>
          </div>

          <div class="col-4">
          <!-- Buttons -->
          <div class="col-12">
            <div class="d-flex containers justify-content-between w-100 align-items-center">
              <div class="fs-2" *ngIf="salesOrderMode">{{ sp.salesPriceNum | currency }}</div>
              <div class="pl-3 d-flex justify-content-center align-items-center">
                <button
                  *ngIf="salesOrderMode"
                  class="btn btn-add-mobile"
                  (click)="addSoItem(sp)"
                >
                  <span style="font-size: 1.8rem;" class="material-icons fs-1">
                    add
                  </span>
                </button>
              </div>  
            </div>
          </div>
          </div>   

        </div>
       
       
       
       
       
       
       
       
       
       
       <!-- old row -->
        <!-- <div class="row">
          <div class="col-12">
            <p class="m-0">
              <strong>{{ sp.companyName }}</strong> hola
            </p>
          </div>
          <div class="col-12">
            <p class="m-0 large-text-card">{{ sp.styleName }}</p>
          </div>
          <div class="col-6">
            <p class="m-0">{{ sp.sizeName }}</p>
          </div>
          <div class="col-6">
            <p class="m-0">Type: {{ sp.Type }}</p>
          </div>
          <div class="col-6">
            <input
              *ngIf="!salesOrderMode"
              class="form-control form-control-sm text-right"
              type="number"
              name=""
              (blur)="editQuantity(sp.supItemID, sp, false)"
              required
              [(ngModel)]="sp.salesPriceNum"
            />
            <div *ngIf="salesOrderMode">{{ sp.salesPriceNum }}</div>
          </div>
          <div class="col-6" *ngIf="isDealer">
            <p class="m-0">WholeSale: {{ sp.wholeSalePriceNum }}</p>
          </div>
          <div class="col-12 d-flex justify-content-between w-100">
          
            <p class="m-0" *ngIf="!salesOrderMode">
              <strong> Added</strong>
              {{ sp.PO }}
            </p>
            <p class="m-0" *ngIf="!salesOrderMode">
              <strong> Sold</strong>
              {{ sp.Sales }}
            </p>
            <p class="m-0">
              <strong>Qty</strong>
              {{ sp.Stock }}
            </p>
          </div>
          <div class="col-12">
            <div class="d-flex justify-content-end w-100">
              <button
                *ngIf="!salesOrderMode && sp.Sales == 0"
                class="btn btn-delete"
                (click)="deleteStockItem(sp.id)"
              >
                <span class="material-icons"> delete </span>
              </button>
              <button
                *ngIf="
                  !salesOrderMode && sp.Sales > 0 && sp.PO > 0 && sp.Stock > 0
                "
                class="btn btn-delete"
                (click)="removeStockItem(sp.id, sp)"
              >
                <span class="material-icons">remove </span>
              </button>
              <button
                *ngIf="salesOrderMode"
                class="btn btn-add"
                (click)="addSoItem(sp)"
              >
                <span class="material-icons"> add </span>
              </button>
            </div>
          </div>
        </div> -->








      </div>
    </div>
  </div>
<!-- *ngIf="!salesOrderMode" -->
  <div class="pagination-container tw-mx-3" >
    <pagination-controls  (pageChange)="p = $event" maxSize="5" previousLabel="" nextLabel="" autoHide="true"></pagination-controls>
  </div>
</div>
