<div class="modal-body modal-sale-report">

    <div class="modal-close-button">
      <app-closebutton ></app-closebutton>
    </div>
  
    <div class="d-flex flex-column">
      <div class="d-flex align-items-center" *ngIf="showReport.length > 0">
        <span class="mr-1">Send by Email as </span>
        
        <button
          class="btn btn-3 mr-2"
          (click)="showSendEmail('xlsx')"
        >
          Excel
        </button>
        <button
          class="btn btn-3 mr-2"
          (click)="showSendEmail('pdf')"
        >
          PDF
        </button>    
      </div>
  
      <div class="col-md-12 mt-10 text-center" *ngIf="!show">
        <i class="fa fa-refresh fa-spin fa-3x"></i>
        <h6>Loading Data....</h6>
  
      </div>
  
  
      <div class="col-md-12" id="viewerContent"></div>
    </div>
  </div>
  