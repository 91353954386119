import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { PaginatedResult } from '../_modules/pagination';
import { map } from 'rxjs/operators/map';

@Injectable({
  providedIn: 'root'
})
export class ProductTypeService {
  laravel = environment.apiLaravel;

  constructor(private http: HttpClient) { }

  getTypes() {
    return this.http.get<any>(this.laravel + 'productType')
  }

  addType(type: any) {
    return this.http.post(this.laravel + 'productType' , type);
  }

  updateType(id: string, type: any) {
    return this.http.patch(this.laravel + 'productType/' + id, type);
  }

  deleteType(id:any) {
    return this.http.delete(this.laravel + 'productType/' + id);
  }
}
