import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Directive, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs/Subscription';
import { Pagination } from '../../../../_modules/pagination';
import { AlertifyService } from '../../../../_services/alertify.service';
import { AuthService } from '../../../../_services/auth.service';
import { ReportingService } from '../../../../_services/reporting.service';
import { SalesOrderItemsService } from '../../../../_services/salesorderitems.service';
import { StoreproductsService } from '../../../../_services/storeproducts.service';
import { AddsalesorderitemComponent } from '../../../salesorder/addsalesorderitem/addsalesorderitem.component';
import 'rxjs/add/operator/takeUntil';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StoreProductsReportComponent } from 'src/app/reports/store-products-report/store-products-report.component';
import { UserService } from 'src/app/_services/user.service';


@Component({
  selector: 'app-storeproductsmobile',
  templateUrl: './storeproductsmobile.component.html',
  styleUrls: ['./storeproductsmobile.component.scss']
  
})

export class StoreproductsmobileComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();

  @Input() salesOrderMode = false;
  @Input() stockitems = false;
  @Input() fromFloorItems = false;
  storeProducts: any = [];
  companies: any = [''];
  styles: any = [];
  sizes: any = [];
  products: any = [];
  //Never Change
  companiesDefault: any = [''];
  stylesDefault: any = [];
  sizesDefault: any = [];
  productsDefault: any = [];
  @Input() pagination: Pagination;
  @Input() itemsPerPage = 10;
  totalItems = 0;
  subscriptions: Subscription[] = [];
  selectedStyle = '';
  selectedMan = '';
  selectedSize = '';
  selectedProduct = '';
  data: any = [];
  storeExcel: any = [];
  toSaveStoreProduct: any = [];
  showSave = false;
  isDealer = false;
  searched = false;
  filter = false;
  isCollapseDec1 = true;
  isCollapseDec2 = false;
  isCollapseDec3 = true;
  tabFilterNav = false;
  //Search Bar
  searchBarText = '';

  //Variables filtros
  manuFilter = '';
  styleFilter = '';
  //State
  recordProducts: any[];
  recordProductsToQRC = [];
  recordProductsDefault: any[];
  //FilterInputs
  filterManu = '';
  filterStyle = '';
  filterSize = '';
  filterProduct = '';
  //InputsFilters
  inputManu = '';
  //PaginateJS
  p: number = 1;
  //Abre el filtro ya seleccionado
  searchedInput = false;
  //SI el arreglo es mayor A 2 se hace mas grande el friltro Menu
  filters = 0 ;
  //Total de Itesmen numeros
  totalitems = 0;

    //variables carga filter
  selectStyle:any;
  selectSize:any;
  toSaveQR:boolean = false;  
  productsQRSettings:any;

  constructor(private storeProductsService: StoreproductsService,
    private alertify: AlertifyService,
    private chRef: ChangeDetectorRef,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private reportingService: ReportingService,
    private itemsService: SalesOrderItemsService,
    private bsModalRef: BsModalRef,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
    this.pagination = {
      totalItems: 10,
      totalPages: 10,
      currentPage: 1,
      itemsPerPage: this.itemsPerPage
    };


    if (this.router.url.toString().includes('storefront') && this.authService.decodeToken('role').role.replace('"', '') !== 'Manager') {
      this.isDealer = true;
    }

      // this.loadStoreProducts();
      this.loadStoreProductsLocal();
      this.subscriptions.push(
      this.storeProductsService.storeEvent
      .pipe(takeUntil(this.destroy$))   
        .subscribe(() => {
          this.selectedManMod = this.storeProductsService.storeSID.value;
          this.selectedStyleMod = this.storeProductsService.storeStyleID.value;
          //this.loadStoreProducts();
        })
    );

  }

  getProductsQRSettings(){
    this.storeProductsService.getToExpQRC().
    toPromise()
    .then((data:string) =>{
      this.productsQRSettings =  data;      
      this.loadProductsQRSettings();
    })
    .catch((err) =>{
      // console.log(err)
    });
  }

  loadProductsQRSettings(){
    let convert = JSON.parse(this.productsQRSettings[0].exportToQRC);  
    if(convert != null && convert != undefined){
      convert.forEach(element => {     
      let json = JSON.parse(element)
      var record = this.recordProducts.filter((item) => item.productsId === json.productsId);
      if(record.length > 0){
        this.recordProductsToQRC.push(record[0]);
      }
      });
    }
  }

  hideModal() {
    this.bsModalRef.hide();
  }

  loadStoreProductsFromSearch() {
    this.storeProductsService.getStoreProductsFromSearchBar(this.pagination.currentPage,
      this.pagination.itemsPerPage, this.searchBarText)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {

          this.storeProducts = res.result.data;
          this.storeProducts.forEach(element => {
            element['salesPriceNum'] = element['salesPriceNum'].toString().replace('.00', '');
          });
          this.pagination = res.pagination;
          this.totalItems = this.pagination.totalItems;

          // this.companies = res.result.companies;
          // this.styles = res.result.styles;
          // this.sizes = res.result.sizes;
          // this.products = res.result.products;

        }, error => {
          this.alertify.error(error);
        });
  }

  clearFilter() {
    this.selectedMan = '';
    this.selectedStyle = '';
    this.selectedSize = '';
    this.selectedProduct = '';
    this.searched = false;
  }

  onOpenDic1(data: boolean) {
    this.isCollapseDec1 = data;
  }
  onOpenDic2(data: boolean) {
    this.isCollapseDec2 = data;
  }
  onOpenDic3(data: boolean) {
    this.isCollapseDec3 = data;
  }

  openFilterMenu() {
    this.filter = !this.filter;
  }

  applyFilterSelect () {
    this.loadStoreProductsLocal();
    var filters = []
    if (this.selectedMan) {
      filters.push({ text: this.filterManu, field: 'companyName' });
    }
    if (this.selectedStyle) {
      filters.push({ text: this.filterStyle, field: 'styleName' });
    }
    if (this.selectedSize) {
      filters.push({ text: this.filterSize, field: 'sizeName' });
    }
    if (this.selectedProduct) {
      filters.push({ text: this.filterProduct, field: 'product' });
    }
    this.filters = 1; 
    if (filters.length > 2) {
      this.filters = 3; 
    }

    if ( !this.selectedMan && !this.selectedStyle && !this.selectedSize && !this.selectedProduct ) {
      this.searched = false;
      return 0;
    }

    this.searched = true;
  }
  //Eliminar Esta Funcion
  loadStoreProducts() {
    this.storeProductsService.getStoreProducts(this.pagination.currentPage,
      this.pagination.itemsPerPage, this.selectedMan, this.selectedStyle
      , this.selectedSize, this.selectedProduct)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
      (res: any) => {

        this.storeProducts = res.result.data;
        this.storeProducts.forEach(element => {
          element['salesPriceNum'] = element['salesPriceNum'].toString().replace('.00','');
        });
        this.pagination = res.pagination;
        this.totalItems = this.pagination.totalItems;

        this.companies = res.result.companies;
        // console.log(this.companies)
        this.styles = res.result.styles;
        this.sizes = res.result.sizes;
        this.products = res.result.products;
        

    }, error => {
      this.alertify.error(error);
    });
  }

  loadStoreProductsLocal() {
    this.storeProductsService.getStoreProducts(this.pagination.currentPage,
      100000, this.selectedMan, this.selectedStyle
      , this.selectedSize, this.selectedProduct)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
          this.recordProducts = res.result.data;
          this.recordProductsDefault = res.result.data;
          
          this.recordProducts.forEach(element => {
            element['salesPriceNum'] = element['salesPriceNum'].toString().replace('.00', '');
          });

          this.pagination = res.pagination;
          this.totalItems = this.pagination.totalItems;

          this.companies = res.result.companies;
          this.styles = res.result.styles;
          this.sizes = res.result.sizes;
          this.products = res.result.products;

          this.totalitems = this.recordProducts.length;

          this.companiesDefault = res.result.companies;
          this.stylesDefault = res.result.styles;
          this.sizesDefault = res.result.sizes;
          this.productsDefault = res.result.products;
                 
          this.getProductsQRSettings();
        }, error => {
          this.alertify.error(error);
        });
  }
  //Carga Fors de los Select Filtros
  loadSelectFilters(query:any,field: any) {

    var manuArray = [];
    var styleArray = [];
    var sizeArray = [];
    var prodArray = [];
    this.p = 1;

    this.searchSelectApply(true); 

    this.recordProducts.forEach(element => {

      var manu = manuArray.findIndex(x => x.companyName==element.companyName);
      if (manu === -1 ) {
        manuArray.push({ companyName: element.companyName });
      }

      var style = styleArray.findIndex(x => x.styleName==element.styleName);
      if (style === -1 ) {
        styleArray.push({ styleId:element.styleId, styleName: element.styleName });
      }

      var size = sizeArray.findIndex(x => x.sizeName==element.sizeName);
      if (size === -1 ) {
        sizeArray.push({ sizeName: element.sizeName });
      }

      var prod = prodArray.findIndex(x => x.product==element.product);
      if (prod === -1 ) {
        prodArray.push({ product: element.product });
      }
    });



    if (field != 'MFGID') {
      this.companies = manuArray;
    }else {
      manuArray = [];
      manuArray.push(this.companies);
    }

    if (field != 'styleId') {
      this.styles = styleArray;
    }else {
      styleArray = [];
      styleArray.push(this.styles);
    }

    if (field != 'sizeName') {
      this.sizes = sizeArray;
    }else {
      sizeArray = [];
      sizeArray.push(this.sizes);
    }

    if (field != 'product') {
      this.products = prodArray;
    }else {
      prodArray = [];
      prodArray.push(this.products);
    }    

    // let data = {
    //   userId: '123',
    //   userName: localStorage.getItem('username'),
    //   section: 'SO',
    //   action:  'Filter By '+field,
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
     
    
  }

  searchinput(query: any, field: any) {
    var filters = []

    if (query.trim().length === 0) {
      this.recordProducts = this.recordProductsDefault;
      if (this.filterManu) {
        //console.log('SI push campo seleccionado', this.filterManu)
        filters.push({ text: this.filterManu, field: 'companyName' });
      }
      if (this.filterStyle) {
        //console.log('SI push campo seleccionado', this.filterStyle)
        filters.push({ text: this.filterStyle, field: 'styleName' });
      }
      if (this.filterSize) {
        //console.log('SI push campo seleccionado', this.filterSize)
        filters.push({ text: this.filterSize, field: 'sizeName' });
      }
      if (this.filterProduct) {
        //console.log('SI push campo seleccionado', this.filterProduct)
        filters.push({ text: this.filterProduct, field: 'product' });
      }
    }

    if (filters.length >= 1) {
      this.recordProducts = this.storeProducts;
      var records: any;
      let search = this.recordProducts;

      filters.forEach(element => {
        search = this.recordProducts;
        this.recordProducts = this.findWord(element.text, element.field, search);
        
      });

      // for (let index = 0; index < filters.length; index++) {
      //   search = this.recordProducts;
      //   console.log('------' + filters[index].text, '-', filters[index].field + '--------');
      //   this.recordProducts = this.findWord(filters[index].text, filters[index].field, search);
      //   console.log(this.recordProducts);
      // }
      return records;
    }

    const search = this.recordProducts;

    this.recordProducts = this.findWord(query, field, search);

    

    return records;

  }

  searchInputApply( ) {

    // let data = {
    //   userId: '123',
    //   userName: localStorage.getItem('username'),
    //   section: 'SO',
    //   action:  'Filter By Search',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});

    this.recordProducts = this.recordProductsDefault;
    var filters = []

    if (this.filterManu) {
      //console.log('SI push campo seleccionado', this.filterManu)
      filters.push({ text: this.filterManu, field: 'companyName' });
    }
    if (this.filterStyle) {
      //console.log('SI push campo seleccionado', this.filterStyle)
      filters.push({ text: this.filterStyle, field: 'styleName' });
    }
    if (this.filterSize) {
      //console.log('SI push campo seleccionado', this.filterSize)
      filters.push({ text: this.filterSize, field: 'sizeName' });
    }
    if (this.filterProduct) {
      //console.log('SI push campo seleccionado', this.filterProduct)
      filters.push({ text: this.filterProduct, field: 'product' });
    }

    this.filters = 1; 
    if (filters.length > 2) {
      this.filters = 3; 
    }
    
   if (filters.length >= 1) {
    this.searchedInput = true;
      var records: any;

      let search = this.recordProducts;

      filters.forEach(element => {
        search = this.recordProducts;
        this.recordProducts = this.findWord(element.text, element.field, search);
      });
      this.totalitems = this.recordProducts.length;
      return 0;
    }
    //Si no hay nada que buscar se ceirra el filtro 
    this.searchedInput = false;
  }
  searchSelectApply(include = false) {
    var find = include;

    this.recordProducts = this.recordProductsDefault;

    var filters = []  //all filter
    // var filterStyle = []  //only style filter
    // var filterSize = []  //only size of style filter

    if (this.selectedManMod) {
      filters.push({ text: this.selectedManMod, field: 'MFGID' });
      // filterStyle.push({ text: this.selectedManMod, field: 'MFGID' });
      // filterSize.push({ text: this.selectedManMod, field: 'MFGID' });
    }
    if (this.selectedStyle) {
      filters.push({ text: this.selectedStyle, field: 'styleId' });
      // filterSize.push({ text: this.selectedStyle, field: 'styleId' });
    }
    if (this.selectedSize) {
      filters.push({ text: this.selectedSize, field: 'sizeName' });
    }
    if (this.selectedProduct) {
      filters.push({ text: this.selectedProduct, field: 'product' });
    }

    this.filters = 1; 
    if (filters.length > 2) {
      this.filters = 3; 
    }    

    if ( !this.selectedManMod && !this.selectedStyle && !this.selectedSize && !this.selectedProduct ) {
      this.searched = false;
      return 0;
    }
    
   if (filters.length >= 1) {
    this.searched = true;
      var records: any;

      let search = this.recordProducts;
      
      //se hace el filtro por todos los campos
      filters.forEach(element => {
        search = this.recordProducts;
        this.recordProducts = this.findWord(element.text, element.field, search);
      });

      //filterStyle.forEach(element => {
      //   search = this.recordProductsDefault;
      //   this.selectStyle = this.findWordFilter(element.text, element.field, search);
      // });

      // filterSize.forEach(element => {
      //   search = this.recordProductsDefault;
      //   this.selectSize = this.findWordFilter(element.text, element.field, search);
      // });


      if (find) {
        filters.forEach(element => {
          search = this.recordProducts;
          this.recordProducts = this.findWordFilter(element.text, element.field, search);
        });
      }else{
        filters.forEach(element => {
          search = this.recordProducts;
          this.recordProducts = this.findWord(element.text, element.field, search);
        });
      }

      this.totalitems = this.recordProducts.length;
      return 0;
    }

    //Si no hay nada que buscar se ceirra el filtro 
    this.searchedInput = false;
  }






  addToExpQRC(sp:any){
    this.recordProductsToQRC.push(sp)
    this.alertify.success('Store Product added to QRC table');
    // let data = {
    //   userId: '123',
    //   userName: localStorage.getItem('username'),
    //   section: 'SO',
    //   action: 'Add product to QRC list',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }

  clearToExpQRC(sp:any){ 
  this.alertify.confirm('Are you sure to clear the QRC table ?', () => {      
    this.toSaveQR = true;
    this.recordProductsToQRC = [];
  }
  ,()=>{
  });
  }
  deleteToExpQRC(productsId:any){ 
    this.toSaveQR = true; 
    var newArray = this.recordProductsToQRC.filter((item) => item.productsId !== productsId);
    this.recordProductsToQRC = newArray;
  }

  saveToExpQRC(){
    const idtosave = [];
    this.recordProductsToQRC.forEach(element => {
      const productsId = {
        "productsId":element.productsId
      }
      
      idtosave.push(JSON.stringify(productsId));
    });

   const tosave = {"products" : JSON.stringify(idtosave)}

    this.storeProductsService.saveToExpQRC(tosave).
    toPromise()
    .then((data:string) =>{
      this.alertify.success("The QRC table was updated");
      this.toSaveQR = false;
    })
    .catch((err) =>{
      // console.log(err)
    });

  }

  ExportToQRPDF(){
    const initialState = {
      showReport: 'QRC_products',
      reportdata: this.recordProductsToQRC
    };
    this.bsModalRef = this.modalService.show(StoreProductsReportComponent, {initialState, class: 'modal-xl modal-report'});
  }



















  findWord(query: any, field: any, search: any) {
    //console.log(query,field,' Todo esto se mando a la funcion de busqueda')
    if (field == 'companyName') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.companyName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'styleName') {
      //console.log('Se Busco en styleName')
      return search.filter(function (e) {
        return e.styleName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'sizeName') {
      //console.log('Se Busco en sizeName')
      return search.filter(function (e) {
        return e.sizeName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'product') {
      //console.log('Se Busco en product');
      return search.filter(function (e: any) {
        return e.product.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'MFGID') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.MFGID.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'styleId') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.styleId.toLowerCase().includes(query.toLowerCase());
      });
    }
  }
  findWordFilter(query: any, field: any, search: any) {
    //console.log(query,field,' Todo esto se mando a la funcion de busqueda')
    if (field == 'companyName') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.companyName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'styleName') {
      //console.log('Se Busco en styleName')
      return search.filter(function (e) {
        return e.styleName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'sizeName') {
      //console.log('Se Busco en sizeName')
      return search.filter(function (e) {
        return e.sizeName.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'product') {
      //console.log('Se Busco en product');
      return search.filter(function (e: any) {
        return e.product.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'MFGID') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.MFGID.toLowerCase().includes(query.toLowerCase());
      });
    }
    if (field == 'styleId') {
      //console.log('Se Busco en companyName','y sebusco'+query)
      return search.filter(function (e) {
        return e.styleId.toLowerCase().includes(query.toLowerCase());
      });
    }
  }
  /**
   * Clean Input Filters and renew array Products
   * To do Hacer lo mismo en Items in Stock
   **/
  
  clearsearchInputApply( ) {
    //Inputs Apply
    this.filterManu = '';
    this.filterStyle = '';
    this.filterSize = '';
    this.filterProduct = '';
    this.searchedInput = false;
    this.recordProducts = this.recordProductsDefault;
    this.totalitems = this.recordProducts.length;
  }

  /**
   * Clean Input Filters and renew array Products
   * To do Hacer lo mismo en Items in Stock
   **/
  clearALL() {
    this.selectedMan = '';
    this.selectedStyle = '';
    this.selectedSize = '';
    this.selectedProduct = '';
    this.searched = false;
    this.recordProducts = this.recordProductsDefault;
    this.defaultFilters();
    this.totalitems = this.recordProducts.length;
  }
  /**
   * Reset SelectFilter Default Content
   * To do Hacer lo mismo en Items in Stock
   **/
  defaultFilters() {
    this.companies = this.companiesDefault;
    this.styles = this.stylesDefault;
    this.sizes = this.sizesDefault;
    this.products = this.productsDefault;
  }

  openTabFilter(){
    this.clearALL();
    this.clearsearchInputApply();
    this.tabFilterNav = !this.tabFilterNav;
  }

  pageChanged(event: any): void {
    this.pagination.currentPage = event.page;
    
    this.loadStoreProducts();
    //this.loadStoreProductsLocal();
  }

  onSalesPriceChange(id: string, storeProduct: any, save?: false) {
    this.showSave = save;
    if (this.showSave) {

      this.toSaveStoreProduct.forEach(element => {

        this.storeProductsService.updateStoreProducts(element['productsId'], element)
        .pipe(takeUntil(this.destroy$))   
          .subscribe(() => {
            this.showSave = false;
          }, error => {
            this.alertify.error(error);
          });
      });
      this.alertify.success('Sales Price(s) were updated');
      this.toSaveStoreProduct = [];
    } else {
      this.showSave = true;
      this.toSaveStoreProduct.push(storeProduct);
    }

    // let data = {
    //   userId: '123',
    //   userName: localStorage.getItem('username'),
    //   section: 'SO',
    //   action:  'Save Prices',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }

  deleteStoreProduct(id?: string) {
    let msg = 'Are you sure that you want to delete this Store Product?';
    if (id === 'all') {
      msg = 'Are you sure you want to delete all the found Store Products?';
    }

    this.alertify.confirm(msg
      , () => {
        this.storeProductsService.deleteStoreProduct(id, this.selectedMan, this.selectedStyle
          , this.selectedSize, this.selectedProduct)
          .pipe(takeUntil(this.destroy$))   
          .subscribe(() => {
            this.alertify.success('Store Product(s) Deleted');
            if (id === 'all') {
              this.clearFilter();
            }
            this.loadStoreProducts();
          }, error => {
            this.alertify.error(error);
          });
      }
      ,()=>{
      });
  }

  addStockItems(id: string, storeProduct: any, template?: TemplateRef<any>) {
    if (template != null) {
      this.bsModalRef = this.modalService.show(template);
    }
  }

  qtyStockAdded(storeProduct: any) {
    const msg = 'Do you want to add this item to Stock Items?';

    if (storeProduct != null) {
      this.storeProductsService.addStockItems(storeProduct)
      .pipe(takeUntil(this.destroy$))   
        .subscribe(() => {
          this.alertify.success('Store Product(s) added to Stock Items');
        }, error => {
          this.alertify.error(error);
        });
    }
    this.bsModalRef.hide();

    // this.alertify.confirm(msg
    // , () => {
    //});
  }


  unsubscribe() {
    this.subscriptions.forEach((subscription: Subscription) => {
      subscription.unsubscribe();
    });
    this.subscriptions = [];
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.unsubscribe();
  }

  get selectedStyleMod() {
    return this.selectedStyle;
  }

  set selectedStyleMod(value) {
    const style = this.styles.find(sty => sty.styleId === value);
    this.styleFilter = style?.styleName;
    this.selectedStyle = value;
    //   this.loadStoreProducts();
  }

  get selectedManMod() {
    return this.selectedMan;
  }

  set selectedManMod(value) {

    const manufacturer = this.companies.find(manu => manu.MFGID === value);

    this.manuFilter = manufacturer.companyName;
    this.selectedMan = value;
    
    //  this.loadStoreProducts();
  }

  get selectedSizeMod() {
    return this.selectedSize;
  }

  set selectedSizeMod(value) {
    this.selectedSize = value;
    // this.loadStoreProducts();
  }

  get selectedProductMod() {

    return this.selectedProduct;
  }

  set selectedProductMod(value) {
    this.selectedProduct = value;
    //this.loadStoreProducts();
  }

  exportToCSV(data: any = []) {
    this.reportingService.exportToCSV(data);
  }

  loadStoreProductsForExcel() {

    let data: any = [];
    this.storeProductsService.getStoreProducts(1,
      1, this.selectedMan, this.selectedStyle
      , this.selectedSize, this.selectedProduct, 1)
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
          data = res.result;
          this.exportToCSV(data);
        }, error => {
          this.alertify.error(error);
        });

    return data;
  }

  addSoItem(storeproduct: any) {
    const initialState = {
      salesOrderMode: true,
      item: storeproduct

    };
    this.bsModalRef = this.modalService.show(AddsalesorderitemComponent, { initialState, class: '' , backdrop: true, ignoreBackdropClick: true });

    // let data = {
    //   userId: '123',
    //   userName: localStorage.getItem('username'),
    //   section: 'SO',
    //   action:  'Item Added',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }

  trackBy(index, item) {
    
    return item.productsId;
    
  }

}
