import { Component, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig } from '@ngx-formly/core';

@Component({
  selector: 'app-select-input',
  template: `
    <div class="event-form-payouts">
              <div  *ngIf="!showSelectSP" class="tw-flex tw-border tw-border-aedgrayl tw-rounded-md">
                <input 
                [formControl]="formControl"
                [formlyAttributes]="field"
                  placeholder="Write the Custom Charge"
                  class="input-header tw-border-none tw-bg-aedgrayback"
                />
                <button 
                class=" tw-bg-aedgrayback tw-h-full tw-flex tw-items-center tw-bg-transparent tw-text-aedgraydark tw-text-base tw-rounded-r-md "
                (click)="selectFromStorage()">
                  <span class="material-icons"> keyboard_arrow_down </span>
                </button>            
              </div>
    
            <select [formControl]="formControl" [formlyAttributes]="field" (change)="changeselectSP()" 
            *ngIf="showSelectSP" visibility="hidden" id="idselectsalesperson" class="input-header tw-bg-aedgrayback " >
              <option [ngValue]="salesp" *ngFor="let salesp of salesPersons">
                {{ salesp }}
              </option>
              <option value="Other">Other</option>
            </select>
      </div>
  `,
  styleUrls: ['./select-input-bold.component.scss']
})
export class SelectInputBoldComponent extends FieldType<FieldTypeConfig> implements OnInit  {
  showSelectSP = false;

  salesPersons = ['Delivery', 'Recycle', 'Removal', 'Drop off Only', 'Setup/Assembly','Direct Shipment'];

  // constructor() {
  //   super(builder);
  //  }

  ngOnInit(): void {
  }

  selectFromStorage(){
    if(this.showSelectSP){
      this.showSelectSP = false      
    }else{      
      this.showSelectSP = true
    }
  }

  changeselectSP(){
    if(this.formControl.value == 'Other'){
      this.formControl.setValue('');
      this.showSelectSP = false
    }
  }

}
