import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class UpcitemdbService {
  laravel = environment.apiLaravel;

  constructor(private http: HttpClient) {}

  getProduct(barcode: string) {
    return this.http.get(this.laravel + 'searchUPC/' + barcode);
  }
}
