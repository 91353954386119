import { Component, ElementRef, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { log } from 'console';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { LanguageService } from 'src/app/_services/language.service';

@Component({
  selector: 'app-settingspopup',
  templateUrl: './settingspopup.component.html',
  styleUrls: ['./settingspopup.component.scss']
})
export class SettingspopupComponent implements OnInit {
  user: any = '';
  userRole = '';
  userName:any
  userNameat:any
  cfsat:any
  language: string = localStorage.getItem('language');
  userId: any;

  constructor(
    public authService: AuthService,
    private alertify: AlertifyService,
    public router: Router,
    private _eref: ElementRef,
    private languageService: LanguageService,
  ) { }

  ngOnInit() {      
    this.getRole();
  }

  loggedIn() {
    const token = localStorage.getItem('auth_token');
    return !!token;
  }

  getRole() {    
    // this.userRole = localStorage.getItem('role').replace('"', '');
    // this.user = JSON.parse(localStorage.getItem('user'));
    
    this.userRole = this.authService.decodeToken('role').role;
    this.user = JSON.parse(this.authService.decodeToken('user').user);   
    this.userNameat = this.authService.decodeToken('username').username;       
    this.userName = this.user.name  
    this.cfsat = this.authService.decodeToken('cfsat').cfsat
    this.userId = this.user.id
    
  }

  changeLanguage() {   
    if (this.userRole !== 'Dealer'){
      this.languageService.setLanguage(this.language, this.userName);
    }
  }

}
