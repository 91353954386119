import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { StoreconfigurationComponent } from '../components/storeconfiguration/storeconfiguration.component';
import { AlertifyService } from '../_services/alertify.service';

@Injectable()
export class PreventUnsavedChanges implements CanDeactivate<StoreconfigurationComponent> {

  yo:boolean = true;
 
  constructor(private alertify: AlertifyService) {}

   async canDeactivate(component: StoreconfigurationComponent) {
    
    const token = localStorage.getItem('auth_token');
  
    if (token != null || token != undefined) {  
      if (component.form.dirty) {
        await new Promise((resolve,reject)=>{    
        this.alertify.confirm("The Settings has been modified, if you leave without saving all your changes will be lost?",
            ()=>{
              resolve(this.yo = true) 
            },
            ()=>{

            }
        );  
        setTimeout(() => {  
        reject(this.yo = false) 
        }, 10000)    
        });
      }else{   
        this.yo = true
      }
      return this.yo;
    }else{
      return this.yo;
    }  
          
    }
}




