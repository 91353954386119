import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';
import { PaginatedResult } from '../_modules/pagination';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StyleitemsService {
  laravel = environment.apiLaravel;
  clearStyleItems = new BehaviorSubject<boolean>(false);
  constructor(private http: HttpClient) { }

  getStyleItems(page?, itemsPerPage?, search?, searchMan?) {
    let params = new HttpParams();
    const paginatedResult: PaginatedResult<any> = new PaginatedResult<any []>();

    if ( page != null && itemsPerPage != null) {
      params = params.append('page', page);
      params = params.append('per_page', itemsPerPage);
    }

    if (search != null) {
      params = params.append('search', search);
    }

    if (searchMan != null) {
      params = params.append('searchManufacturer', searchMan);
    }

    return this.http.get<any>(this.laravel + 'styleitems', {observe: 'response', params})
    .pipe(
      map( response => {
        paginatedResult.result = (response.body.data);
        if (response.body.total != null) {
          paginatedResult.pagination = {
            totalItems: JSON.parse(response.body.total),
            totalPages: JSON.parse(response.body.last_page),
            currentPage: JSON.parse(response.body.current_page),
            itemsPerPage: JSON.parse(response.body.per_page)
          };
        }
        return paginatedResult;
      })
    );
    }


    getManufacturers() {
      let params = new HttpParams();
      let dataResult: any[];
      //if (search != null) {
        //params = params.append('search', search);
      //}

      return this.http.get<any>(this.laravel + 'manufacturers', {observe: 'response', params})
      .pipe(
        map( response => {

          dataResult = (response.body);
          return dataResult;
        })
      );
      }

      getStyles(search) {
        let params = new HttpParams();
        let dataResult: any[];

        if (search != null) {
          params = params.append('search', search);
        }

        return this.http.get<any>(this.laravel + 'styles', {observe: 'response', params})
        .pipe(
          map( response => {

            dataResult = (response.body);
            return dataResult;
          })
        );
        }



        addStoreProducts(sid: string, style: string) {
          let params = new HttpParams();
            if (sid != null) {
              params = params.append('sid', sid);
            }
            if (style != null) {
              params = params.append('style', style);
            }
          return this.http.post(this.laravel + 'styleitems', params);
        }

}
