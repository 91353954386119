<div *ngIf="!showMobile" class="tw-p-4 tw-bg-aedblue tw-rounded-md">
  
    <div class="tw-flex tw-bg-white tw-px-4 tw-py-2" >
      <div>
        <span class="tw-text-aedblue tw--mb-10" style="font-size: x-small;" *ngIf="!showListItems">EDIT MODE</span>
        <div style="font-size: large;" class="tw-font-bold tw-mt-2">Items List</div>
      </div>
      <div *ngIf="!showListItems" class="tw-ml-auto tw-flex" >
        <button (click)="changeshowListItems(true)" class="btn-blue-so tw-my-auto"> <span class="material-icons">close</span> &nbsp; EXIT EDIT MODE </button>
      </div>
    </div>      

    <div *ngIf="showListItems" class="tw-bg-white"  style="height: 50vh;overflow: auto;">          
      <div  *ngFor="let soitem of salesOrderItems;let impar = odd " class="tw-p-4" [ngClass]="impar ? 'tw-bg-white' :'tw-bg-aedgrayback'">
      
        <div *ngIf="soitem?.itemType != 'discount'" >
          <div class="tw-grid tw-grid-cols-3">
              <div>
                <div>{{ soitem.manufacter}}</div>
                <div>{{ soitem.Type}}</div>
                <div class="tw-font-bold">{{ soitem.itemDelivery}}</div>
              </div>
              <div>
                <div>{{ soitem.styleName}}</div>
                <div>{{ soitem.sizeName}}</div>
                <div class="tw-font-bold">{{ soitem.SKU}}</div>
              </div>
              <div>
                <div  class="tw-text-end">       
                  <svg *ngIf="!canSelect" class="tw-ml-auto" id="Component_59_102" data-name="Component 59 – 102" (click)="changeshowListItems(false)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25.674" height="25.681" viewBox="0 0 25.674 25.681">
                    <defs>
                      <style>
                        .cls-15 {
                          fill: #00adee;
                        }  
                      </style>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_156" data-name="Rectangle 156" class="cls-15" width="25.674" height="25.681"/>
                      </clipPath>
                    </defs>
                    <g id="Group_63" data-name="Group 63" class="">
                      <path id="Path_76" data-name="Path 76" class="cls-15" d="M25.674,4.884a5.154,5.154,0,0,1-1.642,2.877C21,10.767,17.983,13.8,14.961,16.819Q12.306,19.475,9.65,22.13c-.313.313-.633.618-.946.931a1.42,1.42,0,0,1-.578.335Q4.861,24.481,1.6,25.572c-.095.032-.186.073-.279.109h-.6a1.094,1.094,0,0,1-.594-1.576q1.09-3.266,2.177-6.534a1.567,1.567,0,0,1,.389-.631Q10.514,9.121,18.337,1.3A4.289,4.289,0,0,1,25.3,2.465a5.738,5.738,0,0,1,.377,1.216ZM7.95,20.871c.089-.02.135-.1.192-.153q6.486-6.483,12.975-12.964c.166-.165.138-.246-.011-.392Q19.716,5.988,18.343,4.6c-.159-.162-.245-.167-.41,0q-2.648,2.667-5.312,5.319-3.827,3.827-7.657,7.651c-.133.133-.16.211-.012.357.946.933,1.881,1.875,2.821,2.813.052.052.1.118.176.136M23.641,4.2c0-.013-.014-.1-.025-.178A2.1,2.1,0,0,0,22.107,2.18,2.2,2.2,0,0,0,19.73,2.8c-.1.1-.1.171,0,.27q1.454,1.447,2.9,2.9c.086.086.155.1.252.009A2.349,2.349,0,0,0,23.641,4.2M5.976,21.876a.9.9,0,0,0-.07-.088q-1-1-2-2c-.1-.1-.147-.078-.188.048-.11.341-.226.681-.339,1.021q-.321.961-.641,1.923c-.02.06-.073.127-.024.189s.124.016.183,0q1.473-.488,2.944-.98c.05-.017.121-.017.135-.108" transform="translate(0 0)"/>
                    </g>
                  </svg>
                </div>
                <div class="tw-text-end">{{ soitem.quantityNum }} x ${{ soitem.priceNumUnit }}</div>
                <div class="tw-text-end tw-font-bold"> ${{ soitem.priceNumUnit * soitem.quantityNum | number: "1.2-2" }}</div>
                <div [id]="soitem?.PO?.POID" (click)="showPOdetails(soitem?.PO, soitem?.PO?.POID)" *ngIf="soitem?.PO != null" [ngClass]="{'tw-text-aedblue': soitem?.PO?.UploadStatus == 'Pending', 'tw-text-[#40bcbc]': soitem?.PO?.UploadStatus == 'Uploaded'}"  class="tw-text-end tw-font-bold tw-text-lg tw-cursor-pointer">Item converted on {{soitem?.PO?.UploadStatus}} <strong>PO #{{soitem?.PO?.POUUID}} {{ soitem?.PO?.UploadStatus == 'Uploaded' ? ' (DDS# '+soitem?.PO?.confirmationPOID?.replace("CFSPO","")+")" : null}}</strong></div>
              </div>
          </div>           
        </div>
        
        <div *ngIf="soitem?.itemType == 'discount'">

          <div class="tw-grid tw-grid-cols-3">
            <div>
              <!-- <div>No se puede</div> -->
              <div>{{ soitem.Type}}</div>
              <div class="tw-font-bold">{{ soitem.itemDelivery}}</div>
              <div class="tw-text-aedoranges tw-font-bold">{{ soitem.manufacter}}</div>
            </div>

            <div>
              <!-- <div>No se puede </div> -->
              <div>{{ soitem.sizeName}}</div>
              <div class="tw-font-bold">{{ soitem.SKU}}</div>
              <div class="tw-font-bold">{{ soitem.styleName}}</div>
            </div>

            <div>
              <div class="tw-text-end"> 
                <svg *ngIf="!canSelect" class="tw-ml-auto" id="Component_59_102" data-name="Component 59 – 102" (click)="changeshowListItems(false)" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="25.674" height="25.681" viewBox="0 0 25.674 25.681">
                  <defs>
                    <style>
                      .cls-15 {
                        fill: #00adee;
                      }
                
                     
                    </style>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_156" data-name="Rectangle 156" class="cls-15" width="25.674" height="25.681"/>
                    </clipPath>
                  </defs>
                  <g id="Group_63" data-name="Group 63" class="">
                    <path id="Path_76" data-name="Path 76" class="cls-15" d="M25.674,4.884a5.154,5.154,0,0,1-1.642,2.877C21,10.767,17.983,13.8,14.961,16.819Q12.306,19.475,9.65,22.13c-.313.313-.633.618-.946.931a1.42,1.42,0,0,1-.578.335Q4.861,24.481,1.6,25.572c-.095.032-.186.073-.279.109h-.6a1.094,1.094,0,0,1-.594-1.576q1.09-3.266,2.177-6.534a1.567,1.567,0,0,1,.389-.631Q10.514,9.121,18.337,1.3A4.289,4.289,0,0,1,25.3,2.465a5.738,5.738,0,0,1,.377,1.216ZM7.95,20.871c.089-.02.135-.1.192-.153q6.486-6.483,12.975-12.964c.166-.165.138-.246-.011-.392Q19.716,5.988,18.343,4.6c-.159-.162-.245-.167-.41,0q-2.648,2.667-5.312,5.319-3.827,3.827-7.657,7.651c-.133.133-.16.211-.012.357.946.933,1.881,1.875,2.821,2.813.052.052.1.118.176.136M23.641,4.2c0-.013-.014-.1-.025-.178A2.1,2.1,0,0,0,22.107,2.18,2.2,2.2,0,0,0,19.73,2.8c-.1.1-.1.171,0,.27q1.454,1.447,2.9,2.9c.086.086.155.1.252.009A2.349,2.349,0,0,0,23.641,4.2M5.976,21.876a.9.9,0,0,0-.07-.088q-1-1-2-2c-.1-.1-.147-.078-.188.048-.11.341-.226.681-.339,1.021q-.321.961-.641,1.923c-.02.06-.073.127-.024.189s.124.016.183,0q1.473-.488,2.944-.98c.05-.017.121-.017.135-.108" transform="translate(0 0)"/>
                  </g>
                </svg>
               </div>
              <!-- <div> {{ soitem.quantityNum }} x ${{ soitem.priceNumUnit }} </div> -->
              <div *ngIf="soitem.manufacter == 'DISCOUNT ABOVE ITEM'" class="tw-font-bold tw-text-end">  {{(soitem.priceNumUnit/soitem.priceNum) * 100 | number: "1.2-2"}}% ({{soitem.priceNum * -1}}) </div>
              <div *ngIf="soitem.manufacter == 'ORDER DISCOUNT'" class="tw-font-bold tw-text-end">  {{((soitem.priceNumUnit * -1)/soitem.priceNum) * 100 | number: "1.2-2"}}% ({{soitem.priceNum}}) </div>
              <div class="tw-text-aedoranges tw-font-bold tw-text-end"> ${{ soitem.priceNumUnit | number: "1.2-2"}} </div>
              <div *ngFor="let noti of warnings">
                <span class="tw-text-aedoranges"  *ngIf="noti?.SOItemsID == soitem?.SOItemsID && noti?.confirmed == false"><span  class="material-icons">error</span></span>
              </div>

              <!-- <div class="tw-flex tw-mx-1" *ngIf="soitem?.taxRateAvalara">
                <div *ngIf="soitem?.isItemTaxable" class="tw-flex tw-flex-wrap tw-items-center"> <span class="tw-text-aedoranges">Item Tax: {{soitem?.taxRateAvalara * 100 | number: "1.2-2"}} % </span> <span class="tw-text-aedblue tw-font-semibold tw-pl-1"> Taxes calculated {{soitem?.taxRateAvalara * ((soitem.priceNumUnit * soitem.quantityNum) - discountItems[soitem.SOItemsID]) | number: "1.2-2"}}</span></div>
                <div *ngIf="!soitem?.isItemTaxable" class="tw-flex tw-flex-wrap tw-items-center"> <span class="tw-text-aedoranges">Item no taxable</span> </div>
              </div> -->
            </div>

          </div>

        </div>        

      </div>
    </div>





    <div *ngIf="!showListItems" class=" tw-bg-white tw-px-2  tw-pb-2" style="height: 50vh;overflow: auto;">
      
      <div class="sale-order-items-container" *ngFor="let soitem of salesOrderItems; let i = index">    

     
        <div [ngClass]="salesOrderItems[i+1]?.manufacter == 'DISCOUNT ABOVE ITEM' 
        ? 'tw-p-2 tw-border-x-2 tw-border-t-2 tw-border-aedgraydark tw-rounded-t-md' 
        : 'tw-p-2 tw-border-2 tw-border-aedgraydark tw-rounded-md tw-mb-2'"  
        style="background-color: #ffffff" 
        *ngIf="soitem.itemType != 'discount'"
        >
          <!-- Header -->
          <div class="tw-grid tw-grid-cols-5 tw-text-lg">        
            <div class="tw-col-span-2 tw-pr-2">{{ soitem.manufacter | uppercase }}</div>
            <div class="tw-col-span-3">{{ soitem.styleName }}</div>    
          </div>

          <!-- Content -->
          <div class="tw-grid tw-grid-cols-5 tw-text-lg">
              <div class="tw-col-span-2 tw-pr-2" style="height: 100%;">{{ soitem.Type }}</div>           
              <div class="tw-col-span-1 tw-pr-2" style="height: 100%;">{{ soitem.sizeName }}</div>
              <div class="tw-col-span-2 tw-font-bold" style="height: 100%;">{{ soitem.SKU }} </div>    
          </div>

          <!-- Footer -->
          <div class="tw-grid tw-grid-cols-5">
              
            <div  *ngIf="soitem.note != null && soitem.note != undefined" class="tw-col-span-2 tw-pr-2">
                <div class="" style="padding-left: 5px;" *ngIf="canSelect">{{ soitem.itemDelivery }}</div>
                  <select
                    *ngIf="!canSelect"
                    type="text"
                    class=" tw-py-1"
                    [ngClass]="{ 'input-header-error': ((soitem.itemDelivery == '' || soitem.itemDelivery == null || soitem.itemDelivery == undefined) && validation), 
                                  'input-header-white' : ((soitem.itemDelivery != '' && soitem.itemDelivery != null && soitem.itemDelivery != undefined) || !validation) }"
                    [(ngModel)]="soitem.itemDelivery"
                    (change)="changeToDs(soitem)" 
                  >
                  <option value="" hidden disabled selected>Inventory status</option>

                    <option>{{ "Taken" }}</option>
                    <option>{{ "In Stock" }}</option>
                  
                  </select>
            </div>

            <div *ngIf="soitem.note == null || soitem.note == undefined"  class="tw-col-span-2 tw-pr-2">
              <div class="" style="padding-left: 5px;" *ngIf="canSelect">{{ soitem.itemDelivery }}</div>
                <select
                  [disabled]="soitem?.PO?.UploadStatus == 'Uploaded'"
                  *ngIf="!canSelect"
                  type="text"
                  class=" tw-py-1"
                  [ngClass]="{ 'input-header-error': ((soitem.itemDelivery == '' || soitem.itemDelivery == null || soitem.itemDelivery == undefined) && validation), 
                                'input-header-white' : ((soitem.itemDelivery != '' && soitem.itemDelivery != null && soitem.itemDelivery != undefined) || !validation) }"
                  [(ngModel)]="soitem.itemDelivery"
                  (change)="changeToDs(soitem)" 
                >
                  <option value="" hidden disabled selected >Inventory status</option>
                  <option>{{ "NTO" }}</option>
                  <option>{{ "Taken" }}</option>
                  <option>{{ "Direct Shipment" }}</option>
                  <option>{{ "In Stock" }}</option>
                
                </select>
            </div>
            
            <div class="tw-col-span-1 tw-pr-2">
              <input
                [disabled]="soitem?.PO?.UploadStatus == 'Uploaded'"
                [readonly]="canSelect"
                class="input-header-white tw-py-0.5"
                type="number"
                min="0"
                (click)="$event.target.select()"
                id="quantity"
                (input)="onQtyChange($event.target.value, soitem, soitem.priceNumUnit)"
                required
                [(ngModel)]="soitem.quantityNum"
              />
            </div>          
                  
            <div class="tw-col-span-1 tw-pr-2">
              <input
                  [disabled]="soitem?.PO?.UploadStatus == 'Uploaded'"
                  [readonly]="canSelect"
                  class="input-header-white tw-py-0.5"
                  min="0"
                  (click)="$event.target.select()"
                  
                  id="salesprice"
                  appTwoDigitDecimaNumber
                  (input)="onQtyChange(soitem.quantityNum, soitem, $event.target.value)"
                  required
                  [(ngModel)]="soitem.priceNumUnit"
                />
            </div>

            <div class="tw-col-span-1 tw-flex tw-px-4 tw-justify-between">
              <div *ngIf="!canSelect" (click)="deleteItem(soitem.SOItemsID)">
                <svg id="Group_235" data-name="Group 235" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24.255" height="29.82" viewBox="0 0 24.255 29.82">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: #00adee;
                      }
                
                      .cls-2 {
                        clip-path: url(#clip-path);
                      }
                    </style>
                    <clipPath id="clip-path">
                      <rect id="Rectangle_99" data-name="Rectangle 99" class="cls-1" width="24.255" height="29.82"/>
                    </clipPath>
                  </defs>
                  <g id="Group_40" data-name="Group 40" class="">
                    <path id="Path_55" data-name="Path 55" class="cls-1" d="M14.513,0a2.979,2.979,0,0,1,1.865,1.289,3.193,3.193,0,0,1,.43,1.838c-.015.657-.087.574.563.576,1.493,0,2.987,0,4.48,0a2.316,2.316,0,0,1,2.4,2.4q0,1.556,0,3.113a.939.939,0,0,1-1.043,1.043c-.388,0-.4.019-.414.412q-.15,3.426-.307,6.853c-.072,1.577-.151,3.154-.226,4.732-.078,1.616-.146,3.232-.236,4.848a2.812,2.812,0,0,1-2.887,2.711q-7.011,0-14.022,0a2.84,2.84,0,0,1-2.9-2.853c-.084-2.217-.2-4.433-.3-6.649-.073-1.587-.153-3.174-.228-4.761q-.119-2.5-.234-4.993c-.01-.218-.1-.3-.322-.3C.311,10.251,0,9.926,0,9.1,0,8.086,0,7.068,0,6.05A2.313,2.313,0,0,1,2.344,3.706c1.581,0,3.161-.009,4.742.005.3,0,.383-.09.371-.38A4.029,4.029,0,0,1,7.506,2.2,2.78,2.78,0,0,1,9.465.09L9.741,0ZM12.148,10.264q-4.233,0-8.467,0c-.221,0-.338.033-.322.3.082,1.364.151,2.728.219,4.092q.119,2.4.229,4.791c.086,1.878.164,3.756.25,5.634.031.668.07,1.335.113,2a.856.856,0,0,0,.638.78,1.594,1.594,0,0,0,.46.057h13.7a1.623,1.623,0,0,0,.432-.046.863.863,0,0,0,.682-.836q.115-2.177.225-4.354.12-2.424.232-4.849c.085-1.868.164-3.737.248-5.605.024-.523.048-1.045.089-1.567.03-.381.029-.4-.353-.4h-8.38m-.02-1.9q4.946,0,9.891,0c.249,0,.344-.061.336-.325-.018-.572,0-1.144-.006-1.716s-.141-.716-.725-.716H2.6c-.544,0-.692.148-.693.689q0,.785,0,1.571c0,.536-.051.492.478.492h9.746M12.119,3.7c.833,0,1.666-.005,2.5,0,.213,0,.3-.064.286-.283-.014-.193,0-.387-.005-.581a.934.934,0,0,0-.961-.957q-1.815-.018-3.631,0a.93.93,0,0,0-.948.939c-.01.193.009.388-.006.581-.018.229.07.3.3.3.823-.011,1.646,0,2.469,0" transform="translate(0)"/>
                    <path id="Path_56" data-name="Path 56" class="cls-1" d="M85.757,162.45q0,3.025,0,6.051a.924.924,0,0,1-.669.918.966.966,0,0,1-1.059-.36.882.882,0,0,1-.173-.544q0-6.066,0-12.131a.912.912,0,0,1,.94-.937.924.924,0,0,1,.958.952c0,2.017,0,4.034,0,6.051" transform="translate(-77.334 -143.36)"/>
                    <path id="Path_57" data-name="Path 57" class="cls-1" d="M145.636,162.462q0,3.011,0,6.022a.925.925,0,0,1-.653.929.965.965,0,0,1-1.082-.364.9.9,0,0,1-.166-.546q0-6.051,0-12.1a.915.915,0,0,1,.953-.954.926.926,0,0,1,.946.964q.006,3.025,0,6.051" transform="translate(-132.557 -143.361)"/>
                    <path id="Path_58" data-name="Path 58" class="cls-1" d="M205.506,162.412q0,3.011,0,6.022a.925.925,0,0,1-.657.926.97.97,0,0,1-1.081-.368,1.066,1.066,0,0,1-.164-.664q0-4.335,0-8.669c0-1.105,0-2.211,0-3.316a.922.922,0,0,1,.783-.942.952.952,0,0,1,1.076.645,1.268,1.268,0,0,1,.042.431q0,2.967,0,5.935" transform="translate(-187.771 -143.306)"/>
                  </g>
                </svg>
              </div>
              <div class="tw-flex tw-flex-col">                
                <span [ngClass]="{'tw-line-through tw-text-aedgrays': discountItems?.[soitem.SOItemsID] != undefined && discountItems?.[soitem.SOItemsID] > 0}" class="tw-ml-auto tw-text-aedblue tw-font-bold tw-text-end tw-mt-auto">$ {{ soitem.priceNumUnit * soitem.quantityNum | number: "1.2-2" }}</span> 
                <span *ngIf="discountItems?.[soitem.SOItemsID] != undefined && discountItems?.[soitem.SOItemsID] > 0" class="tw-ml-auto tw-text-aedgrays tw-font-bold tw-text-end tw-mt-auto tw-underline tw-w-100">- {{discountItems?.[soitem.SOItemsID] | number: "1.2-2"}}</span> 
                <span *ngIf="discountItems?.[soitem.SOItemsID] != undefined && discountItems?.[soitem.SOItemsID] > 0" class="tw-ml-auto tw-text-aedblue tw-font-bold tw-text-end tw-mt-auto">$ {{(soitem.priceNumUnit * soitem.quantityNum) - discountItems?.[soitem.SOItemsID] | number: "1.2-2"}}</span> 
              </div>              
            </div>

          </div>
            
            

          <div class="tw-pt-2">

              <div *ngIf="!canSelect" class="d-flex flex-wrap">

                <button class="btn-blue-so"  (click)="addDiscountItem(soitem)" >  Item Discount </button>

                <button class="btn-blue-so tw-ml-2 tw-flex tw-items-center tw-justify-center"
                  *ngIf="(soitem.itemDelivery == 'Direct Shipment' ||  soitem.itemDelivery?.includes('Delivery')) && itemModifying?.SOItemsID != soitem?.SOItemsID"
                  (click)="showDs(soitem)" >
                  <span class="material-icons"> account_box  </span>Address 
                </button>

                <!-- <div *ngFor="let noti of warnings">
                  <div class="tw-text-aedoranges tw-flex tw-flex-wrap tw-items-center"  *ngIf="noti?.SOItemsID == soitem?.SOItemsID && noti?.confirmed == false" > <span  class="material-icons">error</span> <span>This direct ship address differs from sales order shipping address</span><button class="tw-ml-2 btnsmall-somobille" (click)="confirmWarning(noti)">Confirm</button></div>
                </div> -->
                
                <div class="tw-flex tw-mx-1"  *ngIf="soitem?.taxRateAvalara">
                  <div *ngIf="soitem?.isItemTaxable" class="tw-flex tw-flex-wrap tw-items-center"> <span class="tw-text-aedoranges">Item Tax: {{soitem?.taxRateAvalara * 100 | number: "1.2-2"}} % </span> <span class="tw-text-aedblue tw-font-semibold tw-pl-1"> Taxes calculated {{soitem?.taxRateAvalara * ((soitem.priceNumUnit * soitem.quantityNum) - discountItems[soitem.SOItemsID]) | number: "1.2-2"}}</span></div>
                  <div *ngIf="!soitem?.isItemTaxable" class="tw-flex tw-flex-wrap tw-items-center"> <span class="tw-text-aedoranges">Item no taxable</span> </div>
                </div>
                
                <div [id]="soitem?.PO?.POID"  *ngIf="soitem?.PO != null" (click)="showPOdetails(soitem?.PO, soitem?.PO?.POID)" 
                [ngClass]="{'tw-text-aedblue': soitem?.PO?.UploadStatus == 'Pending', 'tw-text-[#40bcbc]': soitem?.PO?.UploadStatus == 'Uploaded'}" 
                class="tw-text-end tw-flex-grow tw-font-bold tw-items-center tw-flex tw-justify-end tw-text-[3rem] tw-cursor-pointer"> 
                
                  <span  class="material-icons">error</span> Item converted on {{soitem?.PO?.UploadStatus}} PO #{{soitem?.PO?.POUUID}} {{ soitem?.PO?.UploadStatus == 'Uploaded' ? ' (DDS# '+soitem?.PO?.confirmationPOID?.replace("CFSPO","")+")" : null}}
              
                </div>
              </div>
              <app-itemdelivery [storeSettings]="storeSettings" (closeAddress)="closeAddDefault($event)" (refreshAddress)="updateAddress($event)" *ngIf="showAddress && soitem.SOItemsID == itemModifying?.SOItemsID " 
              [warnings]="warnings" [notifications]="notifications" [(showAddress)]="showAddress" [salesOrderMode]="true" 
              [item]="soitem" [salesOrder]="salesOrder" ></app-itemdelivery>

          </div>
        
        </div>



        <!-- Hacer Cuando se pone en Discount -->
        <div class="sale-order-items-container-discount tw-bg-white tw-border-l-2 tw-border-r-2 tw-border-aedgraydark tw-p-2" 
             *ngIf="soitem.itemType == 'discount'"
             [ngClass]="{ active: soitem.manufacter == 'ORDER DISCOUNT', 
                          '': alert,                     
                          'tw-border-b-0 tw-rounded-b-md tw-mb-2': salesOrderItems[i+1]?.manufacter == 'DISCOUNT ABOVE ITEM',
                          'tw-rounded-md tw-mt-0 tw-mb-2': soitem.manufacter == 'ORDER DISCOUNT',
                          'tw-border-b-2 tw-rounded-b-md tw-mb-2': salesOrderItems[i+1]?.manufacter != 'DISCOUNT ABOVE ITEM'
                        }" 
                       
                          >

          <hr  *ngIf="soitem.manufacter == 'DISCOUNT ABOVE ITEM'" class="tw-mx-8 tw-w-full tw-bg-aedgraydark">
          <div *ngIf="soitem.manufacter == 'ORDER DISCOUNT'" style="margin-top:-50px;color: white;" class="pt-2 col-12 col-sm-12 col-md-12 col-lg-12 title-1">% {{ soitem.manufacter | uppercase }}</div>
          <div *ngIf="soitem.manufacter != 'ORDER DISCOUNT'" class="tw-py-2 tw-text-aedoranges tw-font-bold">{{ soitem.manufacter  | titlecase }}</div>

          <div class="tw-grid tw-grid-cols-5">
            <div class="tw-col-span-2 mr-2">
                <div  class="sale-order-detail-content-form-input-container">
                  <ng-template
                    #customItemTemplate
                    let-model="item"
                    let-index="index"
                    class=""
                  >
                    {{ model }}
                  </ng-template>
                  
                  <input
                    [readonly]="canSelect"
                    [(ngModel)]="soitem.styleName"
                    [typeahead]="referralTypes"
                    [typeaheadOptionsLimit]="20"
                    [typeaheadMinLength]="0"
                    placeholder="Discount"
                    [typeaheadItemTemplate]="customItemTemplate"
                    class="input-header-white tw-py-0.5"
                    style="text-transform: none;"
                  />
                  <div class="invalid-feedback">Please choose a Sales Person</div>
                </div>


                <div *ngIf="isIOS" class="sale-order-detail-content-form-input-container">    
                  <input
                    [readonly]="canSelect"
                    [(ngModel)]="soitem.styleName"
                    placeholder="Discount"
                    class="input-header-white"
                    style="text-transform: none;"
                  />
                </div>
            </div>

            <div class="tw-col-span-1 tw-mr-2">
                <div class="input-header-white tw-flex">
                  <input
                    [readonly]="canSelect"
                    class="tw-w-4/5 tw-py-0.5 tw-bg-white tw-ring-0 tw-text-aedgraydark  tw-text-end  tw-text-base tw-outline-none"
                    type="number"
                    min="0"
                    max="100"
                    id="PDis"
                    (keyup)="onPDisChange($event.target.value, soitem, soitem.PDis)"
                    (change)="onPDisChange($event.target.value, soitem, soitem.PDis)"
                    (click)="$event.target.select()"
                    required
                    [(ngModel)]="soitem.PDis"
                  />
                  <div class="tw-w-1/5 tw-bg-white tw-flex">
                    <span class="tw-ml-auto">%</span>
                  </div>
                </div>
            </div>
          
            <div class="tw-col-span-1">
                <input
                  [readonly]="canSelect"
                  class="input-header-white tw-py-0.5"
                  min="0"
                  type="number"
                  id="salesprice"
                  (click)="$event.target.select()"
                  required
                  (keyup)="onValueDiscountChange($event.target.value, soitem, soitem.PDis)"
                  [(ngModel)]="soitem.priceNumUnit"
                />
            </div>

            <div class="tw-col-span-1 tw-px-2 tw-flex tw-justify-evenly">
                <div *ngIf="soitem.manufacter == 'ORDER DISCOUNT' && (storeSettings?.avaTax == 1 || storeSettings?.avaTax == true)" class="tw-flex tw-items-center tw-flex-col">
                  <span class="tw-text-aedgrays tw-text-sm">taxable</span>
                  <input type="checkbox"  [(ngModel)]="soitem.isItemTaxable" (ngModelChange)="null" >
                </div>
                <div *ngIf="!canSelect" (click)="deleteItem(soitem.SOItemsID)">
                  <svg id="Group_235" data-name="Group 235" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24.255" height="29.82" viewBox="0 0 24.255 29.82">
                    <defs>
                      <style>
                        .cls-1 {
                          fill: #00adee;
                        }
                  
                        .cls-2 {
                          clip-path: url(#clip-path);
                        }
                      </style>
                      <clipPath id="clip-path">
                        <rect id="Rectangle_99" data-name="Rectangle 99" class="cls-1" width="24.255" height="29.82"/>
                      </clipPath>
                    </defs>
                    <g id="Group_40" data-name="Group 40" class="">
                      <path id="Path_55" data-name="Path 55" class="cls-1" d="M14.513,0a2.979,2.979,0,0,1,1.865,1.289,3.193,3.193,0,0,1,.43,1.838c-.015.657-.087.574.563.576,1.493,0,2.987,0,4.48,0a2.316,2.316,0,0,1,2.4,2.4q0,1.556,0,3.113a.939.939,0,0,1-1.043,1.043c-.388,0-.4.019-.414.412q-.15,3.426-.307,6.853c-.072,1.577-.151,3.154-.226,4.732-.078,1.616-.146,3.232-.236,4.848a2.812,2.812,0,0,1-2.887,2.711q-7.011,0-14.022,0a2.84,2.84,0,0,1-2.9-2.853c-.084-2.217-.2-4.433-.3-6.649-.073-1.587-.153-3.174-.228-4.761q-.119-2.5-.234-4.993c-.01-.218-.1-.3-.322-.3C.311,10.251,0,9.926,0,9.1,0,8.086,0,7.068,0,6.05A2.313,2.313,0,0,1,2.344,3.706c1.581,0,3.161-.009,4.742.005.3,0,.383-.09.371-.38A4.029,4.029,0,0,1,7.506,2.2,2.78,2.78,0,0,1,9.465.09L9.741,0ZM12.148,10.264q-4.233,0-8.467,0c-.221,0-.338.033-.322.3.082,1.364.151,2.728.219,4.092q.119,2.4.229,4.791c.086,1.878.164,3.756.25,5.634.031.668.07,1.335.113,2a.856.856,0,0,0,.638.78,1.594,1.594,0,0,0,.46.057h13.7a1.623,1.623,0,0,0,.432-.046.863.863,0,0,0,.682-.836q.115-2.177.225-4.354.12-2.424.232-4.849c.085-1.868.164-3.737.248-5.605.024-.523.048-1.045.089-1.567.03-.381.029-.4-.353-.4h-8.38m-.02-1.9q4.946,0,9.891,0c.249,0,.344-.061.336-.325-.018-.572,0-1.144-.006-1.716s-.141-.716-.725-.716H2.6c-.544,0-.692.148-.693.689q0,.785,0,1.571c0,.536-.051.492.478.492h9.746M12.119,3.7c.833,0,1.666-.005,2.5,0,.213,0,.3-.064.286-.283-.014-.193,0-.387-.005-.581a.934.934,0,0,0-.961-.957q-1.815-.018-3.631,0a.93.93,0,0,0-.948.939c-.01.193.009.388-.006.581-.018.229.07.3.3.3.823-.011,1.646,0,2.469,0" transform="translate(0)"/>
                      <path id="Path_56" data-name="Path 56" class="cls-1" d="M85.757,162.45q0,3.025,0,6.051a.924.924,0,0,1-.669.918.966.966,0,0,1-1.059-.36.882.882,0,0,1-.173-.544q0-6.066,0-12.131a.912.912,0,0,1,.94-.937.924.924,0,0,1,.958.952c0,2.017,0,4.034,0,6.051" transform="translate(-77.334 -143.36)"/>
                      <path id="Path_57" data-name="Path 57" class="cls-1" d="M145.636,162.462q0,3.011,0,6.022a.925.925,0,0,1-.653.929.965.965,0,0,1-1.082-.364.9.9,0,0,1-.166-.546q0-6.051,0-12.1a.915.915,0,0,1,.953-.954.926.926,0,0,1,.946.964q.006,3.025,0,6.051" transform="translate(-132.557 -143.361)"/>
                      <path id="Path_58" data-name="Path 58" class="cls-1" d="M205.506,162.412q0,3.011,0,6.022a.925.925,0,0,1-.657.926.97.97,0,0,1-1.081-.368,1.066,1.066,0,0,1-.164-.664q0-4.335,0-8.669c0-1.105,0-2.211,0-3.316a.922.922,0,0,1,.783-.942.952.952,0,0,1,1.076.645,1.268,1.268,0,0,1,.042.431q0,2.967,0,5.935" transform="translate(-187.771 -143.306)"/>
                    </g>
                  </svg>
                </div>
            </div> 
          </div>

          
        </div>

        
      </div>        

    </div>

</div>




















































<div *ngIf="showMobile">
  <div class="sale-order-items-container" *ngFor="let soitem of salesOrderItems; let i = index">
    
    


  <!-- Hacer Cuando se pone items -->
  <div class="tw-border-2 tw-border-aedgraydark tw-rounded-md tw-mb-1" style="background-color: #f5f5f5" *ngIf="soitem.itemType != 'discount'">
    <!-- Header -->
    <div class="tw-flex tw-pt-4 tw-px-2 tw-text-lg tw-font-semibold">
  
      <div class="tw-w-4/12 "><p class="tw-font-semibold" >{{ soitem.manufacter | uppercase }}</p></div>
      <div class="tw-w-6/12"><p  class="tw-font-semibold">{{ soitem.styleName }}</p></div>

      <div class="tw-w-2/12">
        <div class="tw-flex tw-justify-end">
          <svg  *ngIf="!canSelect" (click)="deleteItem(soitem.SOItemsID)" id="Group_235" data-name="Group 235" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24.255" height="29.82" viewBox="0 0 24.255 29.82">
            <defs>
              <style>
                .cls-1 {
                  fill: #00adee;
                }
          
                .cls-2 {
                  clip-path: url(#clip-path);
                }
              </style>
              <clipPath id="clip-path">
                <rect id="Rectangle_99" data-name="Rectangle 99" class="cls-1" width="24.255" height="29.82"/>
              </clipPath>
            </defs>
            <g id="Group_40" data-name="Group 40" class="">
              <path id="Path_55" data-name="Path 55" class="cls-1" d="M14.513,0a2.979,2.979,0,0,1,1.865,1.289,3.193,3.193,0,0,1,.43,1.838c-.015.657-.087.574.563.576,1.493,0,2.987,0,4.48,0a2.316,2.316,0,0,1,2.4,2.4q0,1.556,0,3.113a.939.939,0,0,1-1.043,1.043c-.388,0-.4.019-.414.412q-.15,3.426-.307,6.853c-.072,1.577-.151,3.154-.226,4.732-.078,1.616-.146,3.232-.236,4.848a2.812,2.812,0,0,1-2.887,2.711q-7.011,0-14.022,0a2.84,2.84,0,0,1-2.9-2.853c-.084-2.217-.2-4.433-.3-6.649-.073-1.587-.153-3.174-.228-4.761q-.119-2.5-.234-4.993c-.01-.218-.1-.3-.322-.3C.311,10.251,0,9.926,0,9.1,0,8.086,0,7.068,0,6.05A2.313,2.313,0,0,1,2.344,3.706c1.581,0,3.161-.009,4.742.005.3,0,.383-.09.371-.38A4.029,4.029,0,0,1,7.506,2.2,2.78,2.78,0,0,1,9.465.09L9.741,0ZM12.148,10.264q-4.233,0-8.467,0c-.221,0-.338.033-.322.3.082,1.364.151,2.728.219,4.092q.119,2.4.229,4.791c.086,1.878.164,3.756.25,5.634.031.668.07,1.335.113,2a.856.856,0,0,0,.638.78,1.594,1.594,0,0,0,.46.057h13.7a1.623,1.623,0,0,0,.432-.046.863.863,0,0,0,.682-.836q.115-2.177.225-4.354.12-2.424.232-4.849c.085-1.868.164-3.737.248-5.605.024-.523.048-1.045.089-1.567.03-.381.029-.4-.353-.4h-8.38m-.02-1.9q4.946,0,9.891,0c.249,0,.344-.061.336-.325-.018-.572,0-1.144-.006-1.716s-.141-.716-.725-.716H2.6c-.544,0-.692.148-.693.689q0,.785,0,1.571c0,.536-.051.492.478.492h9.746M12.119,3.7c.833,0,1.666-.005,2.5,0,.213,0,.3-.064.286-.283-.014-.193,0-.387-.005-.581a.934.934,0,0,0-.961-.957q-1.815-.018-3.631,0a.93.93,0,0,0-.948.939c-.01.193.009.388-.006.581-.018.229.07.3.3.3.823-.011,1.646,0,2.469,0" transform="translate(0)"/>
              <path id="Path_56" data-name="Path 56" class="cls-1" d="M85.757,162.45q0,3.025,0,6.051a.924.924,0,0,1-.669.918.966.966,0,0,1-1.059-.36.882.882,0,0,1-.173-.544q0-6.066,0-12.131a.912.912,0,0,1,.94-.937.924.924,0,0,1,.958.952c0,2.017,0,4.034,0,6.051" transform="translate(-77.334 -143.36)"/>
              <path id="Path_57" data-name="Path 57" class="cls-1" d="M145.636,162.462q0,3.011,0,6.022a.925.925,0,0,1-.653.929.965.965,0,0,1-1.082-.364.9.9,0,0,1-.166-.546q0-6.051,0-12.1a.915.915,0,0,1,.953-.954.926.926,0,0,1,.946.964q.006,3.025,0,6.051" transform="translate(-132.557 -143.361)"/>
              <path id="Path_58" data-name="Path 58" class="cls-1" d="M205.506,162.412q0,3.011,0,6.022a.925.925,0,0,1-.657.926.97.97,0,0,1-1.081-.368,1.066,1.066,0,0,1-.164-.664q0-4.335,0-8.669c0-1.105,0-2.211,0-3.316a.922.922,0,0,1,.783-.942.952.952,0,0,1,1.076.645,1.268,1.268,0,0,1,.042.431q0,2.967,0,5.935" transform="translate(-187.771 -143.306)"/>
            </g>
          </svg>
        </div>
      </div>
    
    </div>
    <!-- Content -->
    <div class="tw-flex  tw-px-2 tw-text-lg">
      <div class="tw-w-4/12">
        <div class="align-items-center" style="height: 100%;">
          <p class="m-0 tw-font-semibold">{{ soitem.Type }}</p>
        </div>
      </div>
      <div class="tw-w-5/12">
        <div class="align-items-center" style="height: 100%;">
          <p class="tw-font-semibold">{{ soitem.sizeName }}</p>
        </div>
      </div>
      <div class="tw-w-3/12">
        <div class="align-items-end" style="height: 100%;">
          <p class="tw-font-semibold">{{ soitem.SKU }}</p>
        </div>
      </div>
    </div>



    <div class="tw-grid tw-cols-1 sm:tw-grid sm:tw-cols-1 md:tw-flex lg:tw-flex tw-px-2 tw-pb-2 ">
        
        <div class="tw-flex tw-w-full">
          <div *ngIf="soitem.note != null && soitem.note != undefined"  
          class="tw-flex tw-flex-col tw-w-2/3 sm:tw-w-2/3 md:tw-w-full lg:tw-w-full 
          tw-mr-2 sm:tw-mr-2 md:tw-mr-2 lg:tw-mr-2">
            <div class="" style="padding-left: 5px;" *ngIf="canSelect">{{ soitem.itemDelivery }}</div>
              <select
                *ngIf="!canSelect"
                type="text"
                class=" tw-py-1"
                [ngClass]="{ 'input-header-error': ((soitem.itemDelivery == '' || soitem.itemDelivery == null) && validation), 
                              'input-header' : ((soitem.itemDelivery != '' && soitem.itemDelivery != null) || !validation) 
                            }"
                [(ngModel)]="soitem.itemDelivery"
                (change)="changeToDs(soitem)" 
              >
              <option value="" hidden disabled selected>Inventory status</option>

                <option>{{ "Taken" }}</option>
                <option>{{ "In Stock" }}</option>
              
              </select>
            <!-- <div class="invalid-feedback">Select Warehouse Status</div> -->
          </div>

          <div *ngIf="soitem.note == null || soitem.note == undefined"  
          class="tw-flex tw-flex-col tw-w-2/3 sm:tw-w-2/3 md:tw-w-full lg:tw-w-full
          tw-mr-2 sm:tw-mr-2 md:tw-mr-2 lg:tw-mr-2">
            <div *ngIf="canSelect">{{ soitem.itemDelivery }}</div>
              <select
                *ngIf="!canSelect"
                type="text"
                class=" tw-py-1"
                [ngClass]="{ 'input-header-error': ((soitem.itemDelivery == '' || soitem.itemDelivery == null) && validation), 
                              'input-header' : ((soitem.itemDelivery != '' && soitem.itemDelivery != null) || !validation) 
                            }"
                [(ngModel)]="soitem.itemDelivery"
                (change)="changeToDs(soitem)" 
              >

                <option value="" hidden disabled selected >Inventory status</option>
                <option>{{ "NTO" }}</option>
                <option>{{ "Taken" }}</option>
                <option>{{ "Direct Shipment" }}</option>
                <option>{{ "In Stock" }}</option>
              
              </select>
            <!-- <div class="invalid-feedback">Select Warehouse Status</div> -->
          </div>

          <div class="quantity-input tw-flex tw-flex-col tw-w-1/3 sm:tw-w-1/3 md:tw-w-full lg:tw-w-full
          tw-mr-0 sm:tw-mr-0 md:tw-mr-2 lg:tw-mr-2">
            <input
              [readonly]="canSelect"
              class="input-header tw-py-0.5"
              type="number"
              min="0"
              id="quantity"
              (click)="$event.target.select()"
              (input)="
                onQtyChange($event.target.value, soitem, soitem.priceNumUnit)
              "
              *ngIf="!canSelect" 
              required
              [(ngModel)]="soitem.quantityNum"
            />
            <div *ngIf="canSelect" class="tw-text-lg tw-text-end" >Qty: {{soitem.quantityNum}}</div>
          </div>    
        </div>  
        
        <div class="tw-flex tw-w-full tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0">
          <div class="quantity-input tw-w-1/3 sm:tw-w-1/3 md:tw-w-full lg:tw-w-full
          tw-mr-0 sm:tw-mr-0 md:tw-mr-0 lg:tw-mr-0">
            <input
                [readonly]="canSelect"
                class="input-header tw-py-0.5"
                min="0"
                *ngIf="!canSelect"
                appTwoDigitDecimaNumber
                id="salesprice"
                (click)="$event.target.select()"
                (input)="onQtyChange(soitem.quantityNum, soitem, $event.target.value)"
                required
                [(ngModel)]="soitem.priceNumUnit"
              />
              <div *ngIf="canSelect" class="tw-text-lg">Price: {{soitem.priceNumUnit | currency}}</div>

          </div>

          <div class="quantity-input tw-w-2/3 sm:tw-w-2/3 md:tw-w-full lg:tw-w-full  tw-flex tw-items-end tw-justify-end
          tw-ml-0 sm:tw-ml-0 md:tw-ml-0 lg:tw-ml-0">
            <div class="tw-flex tw-flex-col">                
                <span [ngClass]="{'tw-line-through tw-text-aedgrays': discountItems?.[soitem.SOItemsID] != undefined && discountItems?.[soitem.SOItemsID] > 0}" class="tw-ml-auto tw-text-aedblue tw-font-bold tw-text-end tw-mt-auto">$ {{ soitem.priceNumUnit * soitem.quantityNum | number: "1.2-2" }}</span> 
                <span *ngIf="discountItems?.[soitem.SOItemsID] != undefined && discountItems?.[soitem.SOItemsID] > 0" class="tw-ml-auto tw-text-aedgrays tw-font-bold tw-text-end tw-mt-auto tw-underline tw-w-100">- {{discountItems?.[soitem.SOItemsID] | number: "1.2-2"}}</span> 
                <span *ngIf="discountItems?.[soitem.SOItemsID] != undefined && discountItems?.[soitem.SOItemsID] > 0" class="tw-ml-auto tw-text-aedblue tw-font-bold tw-text-end tw-mt-auto">$ {{(soitem.priceNumUnit * soitem.quantityNum) - discountItems?.[soitem.SOItemsID] | number: "1.2-2"}}</span> 
              </div>   
          </div> 
        </div>

    </div>
      
      

    <div class="tw-px-2 tw-pb-2">
      <div class="">
        <div *ngIf="!canSelect" class="d-flex flex-wrap">
          <button class="btn-blue-so"  (click)="addDiscountItem(soitem)" >
          Item Discount
          </button>

          <p
            class="btn-blue-so tw-px-1 tw-ml-2 tw-flex tw-items-center tw-justify-center"
            *ngIf="
             ( soitem.itemDelivery == 'Direct Shipment' ||
              soitem.itemDelivery?.includes('Delivery'))
              && itemModifying?.SOItemsID != soitem?.SOItemsID
            "
            (click)="showDs(soitem)"
          >
            <span class="material-icons">
              account_box
            </span>
            Address
          </p>

          <div [id]="soitem?.PO?.POID"  *ngIf="soitem?.PO != null" (click)="showPOdetails(soitem?.PO, soitem?.PO?.POID)" 
          [ngClass]="{'tw-text-aedblue': soitem?.PO?.UploadStatus == 'Pending', 'tw-text-[#40bcbc]': soitem?.PO?.UploadStatus == 'Uploaded'}" 
          class="tw-text-end tw-flex-grow tw-font-bold tw-items-center tw-flex tw-justify-end tw-text-[3rem] tw-cursor-pointer"> 
          
            <span  class="material-icons">error</span> Item converted on {{soitem?.PO?.UploadStatus}} PO #{{soitem?.PO?.POUUID}} {{ soitem?.PO?.UploadStatus == 'Uploaded' ? ' (DDS# '+soitem?.PO?.confirmationPOID?.replace("CFSPO","")+")" : null}}
        
          </div>

          <!-- <div *ngFor="let noti of warnings" class="tw-items-center">
            <div class="tw-text-aedoranges tw-flex tw-flex-wrap tw-items-center" *ngIf="noti?.SOItemsID == soitem?.SOItemsID && noti?.confirmed == false" > <span  class="material-icons">error</span> <span>This direct ship address differs from sales order shipping address</span><button class="tw-ml-2 btnsmall-somobille" (click)="confirmWarning(noti)">Confirm</button></div>
          </div> -->

          <div class="tw-flex tw-mx-1"  *ngIf="soitem?.taxRateAvalara">
            <div *ngIf="soitem?.isItemTaxable" class="tw-flex tw-flex-wrap tw-items-center"> <span class="tw-text-aedoranges">Item Tax: {{soitem?.taxRateAvalara * 100 | number: "1.2-2"}} % </span> <span class="tw-text-aedblue tw-font-semibold tw-pl-1"> Taxes calculated {{soitem?.taxRateAvalara * ((soitem.priceNumUnit * soitem.quantityNum) - discountItems[soitem.SOItemsID]) | number: "1.2-2"}}</span></div>
            <div *ngIf="!soitem?.isItemTaxable" class="tw-flex tw-flex-wrap tw-items-center"> <span class="tw-text-aedoranges">Item no taxable</span> </div>
          </div>


        </div>
        
      </div>
    
    

      <app-itemdelivery [storeSettings]="storeSettings"  (closeAddress)="closeAddDefault($event)" (refreshAddress)="updateAddress($event)" *ngIf="showAddress && soitem.SOItemsID == itemModifying?.SOItemsID " [warnings]="warnings" [notifications]="notifications" [(showAddress)]="showAddress" [salesOrderMode]="true" [item]="soitem" [salesOrder]="salesOrder" ></app-itemdelivery>


    </div>
  
  </div>



  <!-- Hacer Cuando se pone en Discount -->
  <div class="sale-order-items-container-discount tw-border-aedgrayl  tw-rounded-sm" *ngIf="soitem.itemType == 'discount'"
       [ngClass]="{ active: soitem.manufacter == 'ORDER DISCOUNT' , '': alert }">

    <div *ngIf="soitem.manufacter == 'ORDER DISCOUNT'" style="margin-top:-50px;color: white;" class="pt-2 title-1">% {{ soitem.manufacter | uppercase }}</div>
    <div *ngIf="soitem.manufacter != 'ORDER DISCOUNT'" class="pt-2  title-1 tw-px-2">{{ soitem.manufacter | uppercase }}</div>



    <div class="tw-pt-2 tw-grid tw-grid-cols-2 sm:tw-grid-cols-2 md:tw-grid-cols-5 lg:tw-grid-cols-5 tw-px-2 tw-w-full">
      <div class="tw-text-lg" *ngIf="canSelect" >{{soitem.styleName}}</div>
      <div class="tw-text-lg tw-ml-0 sm:tw-ml-0 md:tw-ml-2 lg:tw-ml-2" *ngIf="canSelect" >{{soitem.PDis}}%</div>
      <div class="tw-text-lg tw-ml-0 sm:tw-ml-0 md:tw-ml-2 lg:tw-ml-2" *ngIf="canSelect" >-{{soitem.priceNumUnit * -1 | currency}}</div>
      
      <div class="tw-col-span-1 sm:tw-col-span-1 md:tw-col-span-2 lg:tw-col-span-2 " *ngIf="!canSelect">
        <div *ngIf="!isIOS" class="">
          <ng-template
            #customItemTemplate
            let-model="item"
            let-index="index"
            class=""
          >
            {{ model }}
          </ng-template>
          
          <input
            [readonly]="canSelect"
            [(ngModel)]="soitem.styleName"
            [typeahead]="referralTypes"
            [typeaheadOptionsLimit]="20"
            [typeaheadMinLength]="0"
            placeholder="Discount"
            [typeaheadItemTemplate]="customItemTemplate"
            class="input-header"
            style="text-transform: none;"
          />
          <div class="invalid-feedback">Please choose a Sales Person</div>
        </div>

        <div *ngIf="isIOS" class="">   

          <div>
            <div *ngIf="soitem.RecNo == 0" 
            class="tw-flex tw-border tw-border-aedgrayl tw-rounded-md">
              <input 
                [readonly]="canSelect"
                [(ngModel)]="soitem.styleName"
                placeholder="Discount"
                (click)="$event.target.select()"
                class="tw-bg-aedgrayback tw-text-aedgraydark tw-text-base tw-rounded-l-md tw-w-full tw-pl-3"
              />
              <button 
                class="tw-h-6 tw-bg-aedgrayback tw-text-aedgraydark tw-text-base tw-rounded-r-md "
              (click)="selectDefaultDiscount(soitem)">
                <span class="material-icons"> keyboard_arrow_down </span>
              </button>            
            </div>
            
            <select *ngIf="soitem.RecNo == null" class="input-header"
              [(ngModel)]="soitem.styleName" (change)="selectReferalDiscount(soitem.styleName,soitem)">
              <option  [selected]="soitem.styleName == referral" [ngValue]="referral" *ngFor="let referral of referralTypes">
              {{ referral }}
              </option>
            </select>

            <!-- <div *ngIf="soitem.SOItemsID == SOItemsIDToChanDiscount" >
              <div (click)="selectReferalDiscount(referral,soitem)" *ngFor="let referral of referralTypes" [ngClass]="{'tw-bg-gray-200': soitem.styleName == referral}">
                <span class="tw-px-2 tw-text-base">{{ referral }}</span> 
              </div>
            </div> -->
          </div>

        </div>
      </div>

      <div class="tw-ml-2"  *ngIf="!canSelect">
          <div class="input-header tw-flex">
            <input
              [readonly]="canSelect"
              class="tw-w-4/5 tw-bg-aedgrayback tw-ring-0 tw-text-aedgraydark  tw-text-end  tw-text-base tw-outline-none"
              type="number"
              min="0"
              max="100"
              (click)="$event.target.select()"
              id="PDis"
              (keyup)="onPDisChange($event.target.value, soitem, soitem.PDis)"
              (change)="onPDisChange($event.target.value, soitem, soitem.PDis)"            
              required
              [(ngModel)]="soitem.PDis"
            />
            <div class="tw-w-1/5 tw-bg-aedgrayback tw-flex">
            <span class="tw-ml-auto">%</span>
            </div>
          </div>
      </div>
    
      <div class="tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0 tw-ml-0 sm:tw-ml-0 md:tw-ml-2 lg:tw-ml-2"  *ngIf="!canSelect">
          <input
            [readonly]="canSelect"
            class="input-header"
            min="0"
            type="number"
            (click)="$event.target.select()"
            id="salesprice"
            required
            (keyup)="onValueDiscountChange($event.target.value, soitem, soitem.PDis)"
            [(ngModel)]="soitem.priceNumUnit"
          />
        
      </div>

      <div class="tw-flex tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0 tw-ml-0 sm:tw-ml-0 md:tw-ml-2 lg:tw-ml-2" *ngIf="!canSelect">
          <div class="tw-ml-auto" (click)="deleteItem(soitem.SOItemsID)">
  
            <svg id="Group_235" data-name="Group 235" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="24.255" height="29.82" viewBox="0 0 24.255 29.82">
              <defs>
                <style>
                  .cls-1 {
                    fill: #00adee;
                  }
            
                  .cls-2 {
                    clip-path: url(#clip-path);
                  }
                </style>
                <clipPath id="clip-path">
                  <rect id="Rectangle_99" data-name="Rectangle 99" class="cls-1" width="24.255" height="29.82"/>
                </clipPath>
              </defs>
              <g id="Group_40" data-name="Group 40" class="">
                <path id="Path_55" data-name="Path 55" class="cls-1" d="M14.513,0a2.979,2.979,0,0,1,1.865,1.289,3.193,3.193,0,0,1,.43,1.838c-.015.657-.087.574.563.576,1.493,0,2.987,0,4.48,0a2.316,2.316,0,0,1,2.4,2.4q0,1.556,0,3.113a.939.939,0,0,1-1.043,1.043c-.388,0-.4.019-.414.412q-.15,3.426-.307,6.853c-.072,1.577-.151,3.154-.226,4.732-.078,1.616-.146,3.232-.236,4.848a2.812,2.812,0,0,1-2.887,2.711q-7.011,0-14.022,0a2.84,2.84,0,0,1-2.9-2.853c-.084-2.217-.2-4.433-.3-6.649-.073-1.587-.153-3.174-.228-4.761q-.119-2.5-.234-4.993c-.01-.218-.1-.3-.322-.3C.311,10.251,0,9.926,0,9.1,0,8.086,0,7.068,0,6.05A2.313,2.313,0,0,1,2.344,3.706c1.581,0,3.161-.009,4.742.005.3,0,.383-.09.371-.38A4.029,4.029,0,0,1,7.506,2.2,2.78,2.78,0,0,1,9.465.09L9.741,0ZM12.148,10.264q-4.233,0-8.467,0c-.221,0-.338.033-.322.3.082,1.364.151,2.728.219,4.092q.119,2.4.229,4.791c.086,1.878.164,3.756.25,5.634.031.668.07,1.335.113,2a.856.856,0,0,0,.638.78,1.594,1.594,0,0,0,.46.057h13.7a1.623,1.623,0,0,0,.432-.046.863.863,0,0,0,.682-.836q.115-2.177.225-4.354.12-2.424.232-4.849c.085-1.868.164-3.737.248-5.605.024-.523.048-1.045.089-1.567.03-.381.029-.4-.353-.4h-8.38m-.02-1.9q4.946,0,9.891,0c.249,0,.344-.061.336-.325-.018-.572,0-1.144-.006-1.716s-.141-.716-.725-.716H2.6c-.544,0-.692.148-.693.689q0,.785,0,1.571c0,.536-.051.492.478.492h9.746M12.119,3.7c.833,0,1.666-.005,2.5,0,.213,0,.3-.064.286-.283-.014-.193,0-.387-.005-.581a.934.934,0,0,0-.961-.957q-1.815-.018-3.631,0a.93.93,0,0,0-.948.939c-.01.193.009.388-.006.581-.018.229.07.3.3.3.823-.011,1.646,0,2.469,0" transform="translate(0)"/>
                <path id="Path_56" data-name="Path 56" class="cls-1" d="M85.757,162.45q0,3.025,0,6.051a.924.924,0,0,1-.669.918.966.966,0,0,1-1.059-.36.882.882,0,0,1-.173-.544q0-6.066,0-12.131a.912.912,0,0,1,.94-.937.924.924,0,0,1,.958.952c0,2.017,0,4.034,0,6.051" transform="translate(-77.334 -143.36)"/>
                <path id="Path_57" data-name="Path 57" class="cls-1" d="M145.636,162.462q0,3.011,0,6.022a.925.925,0,0,1-.653.929.965.965,0,0,1-1.082-.364.9.9,0,0,1-.166-.546q0-6.051,0-12.1a.915.915,0,0,1,.953-.954.926.926,0,0,1,.946.964q.006,3.025,0,6.051" transform="translate(-132.557 -143.361)"/>
                <path id="Path_58" data-name="Path 58" class="cls-1" d="M205.506,162.412q0,3.011,0,6.022a.925.925,0,0,1-.657.926.97.97,0,0,1-1.081-.368,1.066,1.066,0,0,1-.164-.664q0-4.335,0-8.669c0-1.105,0-2.211,0-3.316a.922.922,0,0,1,.783-.942.952.952,0,0,1,1.076.645,1.268,1.268,0,0,1,.042.431q0,2.967,0,5.935" transform="translate(-187.771 -143.306)"/>
              </g>
            </svg>
          </div>
      </div> 

    </div>
  
  </div>

    
  </div>  
</div>

<div id="triangle-left" *ngIf="setVisible && !showMobile" class="tw-absolute tw-rounded-sm tw-z-10"  [ngStyle]="{'left': realXtop+'px' , 'top': realYtop+'px' }"></div>

<div  id="clickbox" [ngClass]="{'tw-invisible': !setVisible}" class="tw-z-20 tw-absolute tw-border-4 tw-bg-white tw-border-aedorangel tw-rounded-lg" [ngStyle]="{'left': Xtop+'px' , 'top': Ytop+'px' }"  *ngIf="showPODetails">
  <div *ngIf="itemsPO.length > 0" class=" tw-p-3 tw-mt-2">
  <div class="tw-mb-1 tw-text-lg tw-text-aedoranges tw-flex tw-justify-between tw-items-center"><span class="tw-font-bold"> PO#{{POselected?.POUUID}} {{POselected?.DeliveryType}} | {{POselected?.mfg | uppercase}} {{POselected?.UploadStatus}}  </span> <i (click)="showPODetails = false; setVisible = false; itemsPO = []" class="material-icons tw-cursor-pointer tw-text-aedorangel">cancel</i></div>
  
  <div *ngIf="POselected?.DeliveryType == 'Direct Shipment'">
    <div class="tw-mb-1 tw-text-lg tw-text-aedblue"><span class="tw-font-bold">Shipping Address</span></div>
    <div>
      <!-- {{PObody | json}} -->
      <div> <strong>{{ PObody.deliveryAttn}}</strong> {{ PObody.deliveryEmail}}</div>
      <div>{{ PObody.deliveryPhone}}</div>
      <div>{{ PObody.deliveryAddress1}} {{ PObody.deliveryAddress2}}</div>
      <div>{{ PObody.deliveryCity}} {{ PObody.deliveryState}} {{ PObody.deliveryZip}}</div>
    </div>
  </div>

  <div class="tw-mb-1 tw-text-lg tw-text-aedblue"><span class="tw-font-bold">Items</span></div>

    
    <div  *ngFor="let soitem of itemsPO;let impar = odd " class="tw-p-1" [ngClass]="impar ? 'tw-bg-gray-50' :'tw-bg-gray-100'">
      <!-- {{soitem | json}} -->
      <div>  
        <div [ngClass]="{'tw-text-aedoranges tw-animate-pulse': POselected?.POITID == soitem?.POITID && itemsPO.length > 1}" class="tw-grid tw-grid-cols-2">
            <div>
              <div class="tw-flex tw-flex-wrap">{{ soitem.SONumber}}</div>
              <div>{{ soitem.Type}} ({{ soitem.size}})</div>
              <div class="tw-font-bold">Qty: {{ soitem.Qty}}</div>
            </div>
            <div>
              <div>{{ soitem.styleName}}</div>
              <div>SKU: {{ soitem.VITEMID}}</div>
              <!-- <div class="tw-font-bold tw-text-end ">{{ soitem.Qty }} x ${{ soitem.soQtyNum }} | ${{ soitem.soQtyNum * soitem.Qty | number: "1.2-2" }}</div> -->
            </div>                        
        </div>           
      </div>
     

    </div>

  </div >

  <div *ngIf="itemsPO.length == 0" class="tw-text-center tw-font-semibold tw-text-gray-500">
    Loading...
  </div>
</div>