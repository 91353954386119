import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-pdf-viewer',
  templateUrl: './pdf-viewer.component.html',
  styleUrls: ['./pdf-viewer.component.scss']
})
export class PdfViewerComponent implements OnInit {

  pdfUrl:any = '';
  pdfName:any = '';
  constructor(public bsModalRef: BsModalRef,
    private modalService: BsModalService) { }

  ngOnInit(): void {
  }

  close(){
    this.modalService.hide();
  }
}
