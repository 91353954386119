<div class="col-xs-12 col-md-12">

    <div class="tw-text-2xl sm:tw-text-lg md:tw-text-3xl lg:tw-text-3xl tittle-0 mb-2">
      {{ 'DEFAULTS' | translate }}
    </div>

  
    <div class="settings-tabs">
      <div class="tabs">
        <div class="tabs-links">

          <div class="tabs-links-link" [ngClass]="{active: show === 'OpenCloseCashReg'}" (click)="show = 'OpenCloseCashReg'">
            <p class="m-0">{{ 'CASH_CONTROL' | translate }}</p>
          </div>

          <div class="tabs-links-link" [ngClass]="{active: show === 'devices'}" (click)="show = 'devices'">
            <p class="m-0">{{ 'DEVICES' | translate }}</p>
          </div>

          <div class="tabs-links-link" [ngClass]="{active: show === 'taxes'}" (click)="show = 'taxes'">
            <p class="m-0">{{ 'TAXES' | translate }}</p>
          </div>    

        </div>        
      </div>
    </div>


    <div *ngIf="show == 'OpenCloseCashReg'" class="bg-white tw-p-3">
        <app-openclosecashreg></app-openclosecashreg>
    </div>
    
    <div *ngIf="show == 'devices'" class="bg-white tw-p-3">
        <app-devicespos></app-devicespos>
    </div>

    <div *ngIf="show == 'taxes'" class="bg-white tw-p-3">
        <app-taxes></app-taxes>
    </div>


  </div>