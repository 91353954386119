import { ChangeDetectionStrategy, Component, OnInit, OnDestroy, ViewChild, ElementRef, TemplateRef, AfterViewInit } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthService } from './_services/auth.service';
import 'rxjs/add/operator/takeUntil';
import { ConnectionService } from 'ng-connection-service';
import { NavigationEnd, Router } from '@angular/router';
import ResizeObserver from 'resize-observer-polyfill';
import { install } from "resize-observer";
import {  Subject } from 'rxjs';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { GlobalsService } from './_services/globals.service';
import { SalesOrderService } from './_services/salesorder.service';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from './_services/language.service';

registerLocaleData(localeEs, 'es');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class AppComponent implements OnInit, OnDestroy,AfterViewInit {
  destroy$: Subject<boolean> = new Subject<boolean>();
  jwtHelper = new JwtHelperService();
  reportloadding:boolean = false
  showNav:any = undefined

  status = 1;
  messageError = 'Please check your Internet Connection'
  isConnected = true;
  // showWatting:boolean = false;
  @ViewChild('popTemplate', {static: false}) popTemplateiOs: TemplateRef<any>;
  languagePromise: any;

  constructor(private authService: AuthService,
    private connectionService: ConnectionService,
    private gtmService: GoogleTagManagerService,
    private global:GlobalsService,
    private router: Router,
    private salesService: SalesOrderService,
    private translate: TranslateService,
    private languageService: LanguageService,
    ) {}



  ngOnInit() {   

    const token = localStorage.getItem('auth_token');

    if (token != null && token != undefined) {  
      if(this.authService.decodeToken('user') != undefined){   
        let user = JSON.parse(this.authService.decodeToken('user').user); 
        let role = this.authService.decodeToken('role').role;
        let username = user.username;    
        if (user) {
          this.authService.currentUser = user;
        }
        let language = localStorage.getItem('language');
        if (language) {
          this.translate.setDefaultLang(language);
          this.translate.use(language);
        } else {
          if (role !== 'Dealer') {
            this.languageService.setLanguage(undefined, username);
          } else {
            this.languageService.setLanguage();
          }
        }
        }
    
      this.authService.decodedToken = this.jwtHelper.decodeToken(token);
    }

    setInterval(() => {
      let valToken = this.authService.loggedIn()
      
      // si esta logueado
      if(valToken){
        this.showNav = true      
      // ya no lo esta
      }else{
        // si estaba en el nav, lo sacamos, si no lo mandamos a login
        if(this.showNav){
          window.location.href =environment.linkLogin; 
        }else{
          this.showNav = false
        }
      }
    }, 1000);
    
    this.global.reportloadding.subscribe(
      (value)=>{
        this.reportloadding = value
      }
    )

  }

   
  ngAfterViewInit() {

    this.authService.matchVersion.subscribe((data)=>{
      if(data){
        if(localStorage.getItem('device') == 'Android'){
          this.authService.logout()
          this.messageError = this.translate.instant('NEW_UPDATES');
          this.status = 0
        }else{
          this.logouit()
        }      
      }
    })
    
          
    // TO APEXCHART IN IOS
    install();  
    const ro = new ResizeObserver((entries, observer) => {
      for (const entry of entries) {
          const {left, top, width, height} = entry.contentRect;
      }
  });
   
  ro.observe(document.body);

  this.gtmService.addGtmToDom();  
  this.router.events.forEach(item => {
    
    if (item instanceof NavigationEnd) {
        const gtmTag = {
            event: 'page',
            pageName: item.url
        };

         this.gtmService.pushTag(gtmTag);
    }
});

this.authService.matchVersion.subscribe((data)=>{
  if(data){
    if(localStorage.getItem('device') == 'Android'){
      this.authService.logout()
      this.messageError = this.translate.instant('NEW_UPDATES');
      this.status = 0
    }else{
      this.logouit()
    }

  }
})


  }

  async logouit (){
   const result = await this.authService.logoutPromise()
   if(result) {location.reload();window.location.href =environment.linkLogin; 
  }
  } 

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}