import 'rxjs/add/operator/map';
import 'rxjs/add/operator/takeUntil';

import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, OnDestroy, Output, EventEmitter, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs/Observable';
import { NrnValidators } from 'src/app/nrn.validators';

import { AlertifyService } from '../../../_services/alertify.service';
import { AuthService } from '../../../_services/auth.service';
import { CustomerService } from '../../../_services/customer.service';
import { Customer } from 'src/app/_modules/customers';
import { componentDestroyed } from '@w11k/ngx-componentdestroyed';
import { SettingsService } from 'src/app/_services/settings.service';
import { startWith, map, tap, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GlobalsService } from 'src/app/_services/globals.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { Address } from 'src/app/_modules/address';

@Component({
  selector: 'app-address-mobile',
  templateUrl: './address-mobile.component.html',
  styleUrls: ['./address-mobile.component.scss']
})
export class AddressMobileComponent implements OnInit, OnDestroy {
  @Input() so: any;
  @Input() customerComment: any;
  @Input() salesOrderToSave: any;
  @Input() deliveryRequest: any;
  @Input() showEditCustomer: boolean = true;
  @Output() showEditCustomerChange = new EventEmitter<boolean>();
  @Output() closeEditCustomerChange = new EventEmitter<boolean>();
  @ViewChild('popTemplateiOs', {static: false}) popTemplateiOs: TemplateRef<any>;
  destroy$: Subject<boolean> = new Subject<boolean>();
  storeSettings :any
  validatedAddress: Address[];
  modalRefAddress: BsModalRef;
  alsoSaveC:boolean = true;
  customerToUpdate: Customer = {
    Id : 0,
    eventId : "",
    firstName: "",
    middleInitial: "",
    lastName: "",
    billingAddress1: "",
    billingAddress2: "",
    phoneType: "",
    phone1: "",
    phone2Type: "",
    phone2:"",
    city: "",
    county: "",
    state: "",
    email: "",
    zip: "",
    fName: "",
    lName: "",
    deliveryAddress1:"",
    deliveryAddress2: "",
    phone3Type: "",
    phone3: "",
    deliveryCity: "",
    deliveryState: "",
    deliveryEmail: "",
    deliveryZip: "",
    comments: ""};
  form = new FormGroup({});

  optionsBilling: FormlyFormOptions = {
    formState: {
      submitted : false,
    },
  };

  optionsShipping: FormlyFormOptions = {
    formState: {
      submitted : false,
    },
  };

  fields: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      // lifecycle: {
      //   onInit: () => {
      //     this.form.disable();
      //   }
      // },
      fieldGroupClassName: 'row',
      fieldGroup: [
        {
          key: 'Id',
          type: 'input',
          className: 'col-md-12',
          hide: true,
        },
        {
      key: 'firstName',
      type: 'input',
      className: 'col-md-12 input-formly-SOmobille',
      templateOptions: {
      type: 'text',
      placeholder: 'First Name',
      required: true
    }
    },
  {
    key: 'lastName',
      type: 'input',
      className: 'col-md-12  input-formly-SOmobille',
      templateOptions: {
        type: 'text',
        placeholder: 'Last Name',
        required: true
      }
  },
  {
    key: 'phone1',
      type: 'phone',
      className: 'col-md-6 input-formly-SOmobille',
      templateOptions: {
        placeholder: 'Phone',
        required: true,
        pattern: NrnValidators.FORMATS.maskphone
      },
      validation: {
        messages: {
          pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Phone Number`,
        },
      },
  },
  {
    key: 'phoneType',
      type: 'select',
      className: 'col-md-6 select-formly-SOmobille tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0',
      templateOptions: {
        type: 'select',
        required: true,
        defaultValue: '',
        options: [
          {label: 'Select...', value: ''},
          {label: 'Home', value: 'Home'},
          {label: 'Cell', value: 'Cell'},
          {label: 'Work', value: 'Work'}
        ],
      },
      validation: {
        show: true
      }
  },
  {
    key: 'phone2',
      type: 'phone',
      className: 'col-md-6 input-formly-SOmobille',
      templateOptions: {
        placeholder: 'Phone2',
        pattern: NrnValidators.FORMATS.maskphone
      },
      validation: {
        messages: {
          pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Phone Number`,
        },
      },
  },
  {
    key: 'phone2Type',
      type: 'select',
      className: 'col-md-6 select-formly-SOmobille  tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0',
      templateOptions: {
        type: 'select',
        defaultValue: '',
        options: [
          {label: 'Select...', value: ''},
          {label: 'Home', value: 'Home'},
          {label: 'Cell', value: 'Cell'},
          {label: 'Work', value: 'Work'}
        ],
      }
  },
  {
    key: 'email',
      type: 'input',
      className: 'col-md-12 input-formly-SOmobille',
      templateOptions: {
        type: 'text',
        placeholder: 'Email',
        required: false,
        pattern: NrnValidators.FORMATS.email
      },
      validation: {
        messages: {
          pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Email`,
        },
      },


  },
  {
    key: 'billingAddress1',
      type: 'input',
      className: 'col-md-12 input-formly-SOmobille',
      templateOptions: {
        type: 'text',
        placeholder: 'Address',
        required: true
      }
  },
  {
    key: 'billingAddress2',
      type: 'input',
      className: 'col-md-12 input-formly-SOmobille' ,
      templateOptions: {
        type: 'text',
        placeholder: 'Address 2'
      }
  },
  {
    key: 'city',
      type: 'input',
      className: 'col-md-12 input-formly-SOmobille',
      templateOptions: {
        type: 'text',
        placeholder: 'City',
        required: true
      }
  },
  {
    key: 'state',
      type: 'select',
      className: 'col-md-6 select-formly-SOmobille',
      templateOptions: {
        required: true,
        options: this.getStates(),
        valueProp: 'abbreviation',
        labelProp: 'name'
      }
  },
  {
    key: 'zip',
      type: 'input',
      className: 'col-md-6 input-formly-SOmobille',
      templateOptions: {
        type: 'text',
        placeholder: 'Zip',
        maxLength: 5,
        minLength: 5,
        required: true, 
     
      },      
      validation: {
        messages: {
          minlength: (error, field: FormlyFieldConfig) => `Zip code must be ${error.requiredLength} digits.`
        },
      },
  },
  {
    key: 'customerComment',
      type: 'input',
      className: 'col-md-12 input-formly-SOmobille',
      templateOptions: {
        type: 'text',
        placeholder: 'comments'

      }
  },

],
  },
  ];

  formShipping = new FormGroup({});
  fieldsShipping: FormlyFieldConfig[] = [
  {
    wrappers: ['formwrap'],
    templateOptions: { label: '' },
    fieldGroupClassName: 'row',
    fieldGroup: [{
    key: 'fName',
    type: 'input',
    className: 'col-md-12 input-formly-SOmobille',
    templateOptions: {
    type: 'text',
    placeholder: 'First Name',
    required: true
  },
  },
{
  key: 'lName',
    type: 'input',
    className: 'col-md-12 input-formly-SOmobille',
    templateOptions: {
      type: 'text',
      placeholder: 'Last Name',
      required: true
    }
},
{
  key: 'phone3',
    type: 'phone',
    className: 'col-md-6 input-formly-SOmobille',
    templateOptions: {
      placeholder: 'Phone',
      pattern: NrnValidators.FORMATS.maskphone
    },
    validation: {
      messages: {
        pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Phone Number`,
      },
    },
},
{
  key: 'phone3Type',
    type: 'select',
    className: 'col-md-6 select-formly-SOmobille  tw-mt-2 sm:tw-mt-2 md:tw-mt-0 lg:tw-mt-0',
    templateOptions: {
      type: 'select',
      options: [
        {label: 'Home', value: 'Home'},
        {label: 'Cell', value: 'Cell'},
        {label: 'Work', value: 'Work'}
      ],
    }
},

{
  key: 'deliveryEmail',
    type: 'input',
    className: 'col-md-12 input-formly-SOmobille',
    templateOptions: {
      type: 'text',
      placeholder: 'Email',
      required: false,
      pattern: NrnValidators.FORMATS.email
    },
    validation: {
      messages: {
        pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Email`,
      },
    },
},
{
  key: 'deliveryAddress1',
    type: 'input',
    className: 'col-md-12 input-formly-SOmobille',
    templateOptions: {
      type: 'text',
      placeholder: 'Address',
      required: true
    }
},
{
  key: 'deliveryAddress2',
    type: 'input',
    className: 'col-md-12 input-formly-SOmobille',
    templateOptions: {
      type: 'text',
      placeholder: 'Address 2'
    }
},
{
  key: 'deliveryCity',
    type: 'input',
    className: 'col-md-12 input-formly-SOmobille',
    templateOptions: {
      type: 'text',
      placeholder: 'City',
      required: true
    }
},
{
  key: 'deliveryState',
    type: 'select',
    className: 'col-md-6 select-formly-SOmobille',
    templateOptions: {
      required: true,
      options: this.getStates(),
      valueProp: 'abbreviation',
      labelProp: 'name'
    }
},
{
  key: 'deliveryZip',
    type: 'input',
    className: 'col-md-6 input-formly-SOmobille',
    templateOptions: {
      type: 'text',
      placeholder: 'Zip',
      maxLength: 5,
      minLength: 5,
      required: true
    },
    validation: {
      messages: {
        minlength: (error, field: FormlyFieldConfig) => `Zip code must be ${error.requiredLength} digits.`
      },
    },
}],
},
];


  constructor(
    private authService: AuthService, private alertify: AlertifyService,
    private customerService: CustomerService, private route: ActivatedRoute,
    private fb: FormBuilder, private router: Router,
    public bsModalRef: BsModalRef, private http: HttpClient,
    private modalService: BsModalService,
    private settingsService: SettingsService,
    private salesOrderService: SalesOrderService,
    private global: GlobalsService,
  ) { }

  ngOnInit() {
    // this.so['customerComment'] = this.customerComment;

    this.customerService.customerComment
    .pipe(takeUntil(this.destroy$))   
      .subscribe( comment => {
        this.so['customerComment'] = comment
      }, error => {
        this.alertify.error(error);
      });
  

    this.settingsService.getSettings(0)
    .pipe(takeUntil(this.destroy$))   
    .subscribe( data => {
      this.storeSettings = data[0];
      if(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc')){
        // console.log('pasa')

        this.fields[0].fieldGroup.forEach((field:any, index)=>{
          if(index != 0 && index != 1 && index != 2 && index != 2){
            field.templateOptions.required = false;
          }
        })
        this.fieldsShipping[0].fieldGroup.forEach((field:any)=>{
          field.templateOptions.required = false;
        })
      }
      if(this.deliveryRequest == 'Pickup'){
        this.fields[0].fieldGroup.forEach((field:any, index)=>{
          if(index != 0 && index != 1 && index != 2){
            field.templateOptions.required = false;
          }
        })
        this.fieldsShipping[0].fieldGroup.forEach((field:any)=>{
          field.templateOptions.required = false;
        })
      }
    }, error => {
      this.alertify.error(error);
    });
    // console.log(this.so)
    this.customerToUpdate.Id =  this.so['customerId']



  }

  getStates(): Observable<any[]> {
    return this.http.get<any[]>('assets/states.json');
  }

  copyAddressToShipping() {
    this.so = Object.assign({}, this.form.value);
   // this.customerToUpdate.Id =  this.so['customerId']
    this.so['fName'] =  this.so['firstName']
    this.so['lName'] = this.so['lastName']
    this.so['deliveryAddress1'] = this.so['billingAddress1'];
    this.so['deliveryAddress2'] = this.so['billingAddress2'];
    this.so['deliveryEmail'] = this.so['email'];
    this.so['phone3Type'] = this.so['phoneType'];
    this.so['phone3'] = this.so['phone1'];
    this.so['deliveryCity'] = this.so['city'];
    this.so['deliveryState'] = this.so['state'];
    this.so['deliveryZip'] = this.so['zip'];

   // console.log(this.so)
  }


  updateSO(customer) {
    
    if (this.form.valid && this.formShipping.valid) {  
    this.salesOrderToSave['firstName'] = customer['firstName'];
    this.salesOrderToSave['middleInitial'] = customer['middleInitial'];
    this.salesOrderToSave['lastName'] = customer['lastName'];
    this.salesOrderToSave['billingAddress1'] = customer['billingAddress1'];
    this.salesOrderToSave['billingAddress2'] = customer['billingAddress2'];
    this.salesOrderToSave['phoneType'] = customer['phoneType'];
    this.salesOrderToSave['phone1'] = customer['phone1'];
    this.salesOrderToSave['phone2Type'] = customer['phone2Type'];
    this.salesOrderToSave['phone2'] = customer['phone2'];
    this.salesOrderToSave['city'] = customer['city'];
    this.salesOrderToSave['state'] = customer['state'];
    this.salesOrderToSave['email'] = customer['email'];
    this.salesOrderToSave['zip'] = customer['zip'];


    this.salesOrderToSave['fName'] = customer['fName'];
    this.salesOrderToSave['lName'] = customer['lName'];
    this.salesOrderToSave['deliveryAddress1'] = customer['deliveryAddress1'];
    this.salesOrderToSave['deliveryAddress2'] = customer['deliveryAddress2'];
    this.salesOrderToSave['phone3Type'] = customer['phone3Type'];
    this.salesOrderToSave['phone3'] = customer['phone3'];
    this.salesOrderToSave['deliveryCity'] = customer['deliveryCity'];
    this.salesOrderToSave['deliveryState'] = customer['deliveryState'];
    this.salesOrderToSave['deliveryEmail'] = customer['deliveryEmail'];
    this.salesOrderToSave['deliveryZip'] = customer['deliveryZip'];

    if(customer['fName'] == '' || customer['fName'] == undefined || customer['fName'] == null)
    {
      this.so = Object.assign({}, this.form.value);
      // console.log('it interexchange', this.so['firstName'] )
      this.salesOrderToSave['fName'] =  this.so['firstName']
      this.salesOrderToSave['lName'] = this.so['lastName']
      this.salesOrderToSave['deliveryAddress1'] = this.so['billingAddress1'];
      this.salesOrderToSave['deliveryAddress2'] = this.so['billingAddress2'];
      this.salesOrderToSave['deliveryEmail'] = this.so['email'];
      this.salesOrderToSave['phone3Type'] = this.so['phoneType'];
      this.salesOrderToSave['phone3'] = this.so['phone1'];
      this.salesOrderToSave['deliveryCity'] = this.so['city'];
      this.salesOrderToSave['deliveryState'] = this.so['state'];
      this.salesOrderToSave['deliveryZip'] = this.so['zip'];
      customer['fName'] =  this.so['firstName']
      customer['lName'] = this.so['lastName']
      customer['deliveryAddress1'] = this.so['billingAddress1'];
      customer['deliveryAddress2'] = this.so['billingAddress2'];
      customer['deliveryEmail'] = this.so['email'];
      customer['phone3Type'] = this.so['phoneType'];
      customer['phone3'] = this.so['phone1'];
      customer['deliveryCity'] = this.so['city'];
      customer['deliveryState'] = this.so['state'];
      customer['deliveryZip'] = this.so['zip'];

    }

    if(this.salesOrderToSave['soItems'] && this.salesOrderToSave['soItems'].length > 0){
      this.salesOrderToSave['soItems'].forEach((item:any) =>{
        if(item.itemDelivery == 'Direct Shipment' && 
        ((item.deliveryAttn == null || item.deliveryAttn == '' || item.deliveryAttn == 'null null'  || item.deliveryAttn == ' ') && 
        (item.deliveryAddress1 == null || item.deliveryAddress1 == "")  && 
        (item.deliveryCity == null || item.deliveryCity == "") && 
        (item.deliveryPhone == "" || item.deliveryPhone == null) && 
        (item.deliveryState == null || item.deliveryState == "") )){
          if(this.salesOrderToSave['fName'] == null){this.salesOrderToSave['fName'] = ''}
          if(this.salesOrderToSave['lName'] == null){this.salesOrderToSave['lName'] = ''}
          item.deliveryAttn = this.salesOrderToSave['fName']+' '+this.salesOrderToSave['lName'] 
          item.deliveryAddress1 = this.salesOrderToSave['deliveryAddress1']
          item.deliveryAddress2 = this.salesOrderToSave['deliveryAddress2']
          item.deliveryPhone = this.salesOrderToSave['phone3']
          item.deliveryEmail = this.salesOrderToSave['deliveryEmail']
          item.deliveryCity = this.salesOrderToSave['deliveryCity']
          item.deliveryState = this.salesOrderToSave['deliveryState']
          item.deliveryZip = this.salesOrderToSave['deliveryZip']
        }
      })
    }

   this.closeEditCustomerChange.emit(false)

    // Customer to be updated in case
    // this.customerToUpdate.Id = customer['customerId'];
    this.customerToUpdate.eventId = customer['eventId'];
    this.customerToUpdate.firstName = customer['firstName'];
    this.customerToUpdate.middleInitial = customer['middleInitial'];
    this.customerToUpdate.lastName = customer['lastName'];
    this.customerToUpdate.billingAddress1 = customer['billingAddress1'];
    this.customerToUpdate.billingAddress2 = customer['billingAddress2'];
    this.customerToUpdate.phoneType = customer['phoneType'];
    this.customerToUpdate.phone1 = customer['phone1'];
    this.customerToUpdate.phone2Type = customer['phone2Type'];
    this.customerToUpdate.phone2 = customer['phone2'];
    this.customerToUpdate.city = customer['city'];
    this.customerToUpdate.state = customer['state'];
    this.customerToUpdate.email = customer['email'];
    this.customerToUpdate.zip = customer['zip'];
    this.customerToUpdate.comments = customer['customerComment'];

    this.customerService.customerComment.next(customer['customerComment'])


    this.customerToUpdate.fName = customer['fName'];
    this.customerToUpdate.lName = customer['lName'];
    this.customerToUpdate.deliveryAddress1 = customer['deliveryAddress1'];
    this.customerToUpdate.deliveryAddress2 = customer['deliveryAddress2'];
    this.customerToUpdate.phone3Type = customer['phone3Type'];
    this.customerToUpdate.phone3 = customer['phone3'];
    this.customerToUpdate.deliveryCity = customer['deliveryCity'];
    this.customerToUpdate.deliveryState = customer['deliveryState'];
    this.customerToUpdate.deliveryEmail = customer['deliveryEmail'];
    this.customerToUpdate.deliveryZip = customer['deliveryZip'];
    this.customerService.salesOrderCustomer.next(0);

    //if( this.alsoSaveC == true){
      this.updateCustomer()
      // this.customerService.changeCustomer.next({customerId: this.customerToUpdate.Id, event: 'update'})

    //}

    this.cancelForm();
    }else{     
       this.optionsBilling.formState.submitted = true;
       this.optionsShipping.formState.submitted = true;
      // this.findInvalidControls();
    }
  }

//   public findInvalidControls() {
//     const invalid:any = [];

//     const controls = this.form.controls;
//     for (const name in controls) {
//         if (controls[name].invalid) {
//             invalid.push(name);
//         }
//     }
//     const controlsShi = this.formShipping.controls;
//     for (const name in controlsShi) {
//         if (controlsShi[name].invalid) {
//             invalid.push(name);
//         }
//     }

//     for(let i = 0; i < invalid.length; i++ ){
//       if(invalid[i] =='firstName'){
//         this.alertify.error('The billing field First Name is required'); 
//         }else if(invalid[i] =='lastName'){
//           this.alertify.error('The billing field Last Name is required'); 
//           }else if(invalid[i] =='phone1'){
//             this.alertify.error('The billing field Phone is required'); 
//             }else if(invalid[i] =='phoneType'){
//               this.alertify.error('The billing field Type Phone is required'); 
//               }else  if(invalid[i] =='billingAddress1'){
//                   this.alertify.error('The billing field Address is required'); 
//                   }else if(invalid[i] =='city'){
//                     this.alertify.error('The billing field City is required'); 
//                     }else if(invalid[i] =='state'){
//                       this.alertify.error('The billing field State is required'); 
//                       }else if(invalid[i] =='zip'){
//                         this.alertify.error('The billing field Zip is required'); 


//                         }else if(invalid[i] =='fName'){
//                           this.alertify.error('The shipping field First Name is required'); 
//                           }else if(invalid[i] =='lName'){
//                             this.alertify.error('The shipping field Last Name is required'); 
//                             }else if(invalid[i] =='deliveryAddress1'){
//                                 this.alertify.error('The shipping field Address is required'); 
//                                 }else if(invalid[i] =='deliveryCity'){
//                                   this.alertify.error('The shipping field City is required'); 
//                                   }else if(invalid[i] =='deliveryState'){
//                                     this.alertify.error('The shipping field State is required'); 
//                                     }else if(invalid[i] =='deliveryZip'){
//                                       this.alertify.error('The shipping field Zip is required'); 
//                                       }
//      }
    
// }



  cancelForm() {
    this.bsModalRef.hide();
    setTimeout(() => {
      this.showEditCustomerChange.emit(false)
    }, 1000);
    this.closeEditCustomerChange.emit(false)
  }

  validateAddress(){
    if(!this.formShipping.valid){
      this.alertify.error('Complete all shipping fields')
      return;
    }
    this.salesOrderService.validateAddress({customer: this.so, device: localStorage.getItem('device')})
    .pipe(
      takeUntil(this.destroy$))   
    .subscribe((a:any) =>{
      if(a?.messages){
        let show:string = '';
        a.messages.forEach(msg => {
          show = show+msg.summary +'<br> '
        });
        this.alertify.alert(show, ()=>{})
      }else if(a?.taxAuthorities){
        this.validatedAddress = a?.validatedAddresses as Address[];
        // console.log(this.validatedAddress)
        this.modalRefAddress = this.modalService.show(this.popTemplateiOs, { class: 'modal-lg'});
        // this.alertify.alert('the Address is Valid', ()=>{})
      }
    })
  }

  setAddressFromAvalara(address: Address | null):void{
    // console.log(address)
    if(address != null){
      // Persistance fields
      let persist = {
        fName: this.so['fName'], 
        lName: this.so['lName'], 
        phone3: this.so['phone3'], 
        phone3Type: this.so['phone3Type'], 
        deliveryEmail: this.so['deliveryEmail'], 
        entityCode: this.so['entityCode'], 
        eventId: this.so['eventId'], 
      }
      this.so = Object.assign({}, this.form.value, persist);
      this.so['deliveryAddress1'] = address.line1
      this.so['deliveryAddress2'] = address.line2
      this.so['deliveryState'] = address.region
      this.so['deliveryCity'] = address.city
      this.so['deliveryZip'] = address.postalCode.substring(0,5)
    }
    this.modalRefAddress.hide()
  }

  alsoSaveCustomer(event:any){
    if(event.target.checked){
      this.alsoSaveC = true
    }else{
      this.alsoSaveC = false
    }
    // console.log(this.alsoSaveC)
  }
  ngOnDestroy(): void {  
  }
  updateCustomer() {
    if (this.form.valid ) {
      this.customerToUpdate['updated_at'] = this.global.dNow();      
      this.customerService.updateCustomer(this.customerToUpdate.Id, this.customerToUpdate)
       .pipe(takeUntil(this.destroy$)) 
        .subscribe(() => {
          this.alertify.success('Customer Updated');
          this.customerService.salesOrderCustomer.next(this.customerToUpdate.Id);
        }, error => {
          this.alertify.error(error);
        });
    }

  }

}
