import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit, DoCheck, HostListener, Inject, ElementRef, ViewChildren, TemplateRef, Output, EventEmitter, NgZone } from '@angular/core';
import { FormGroup, FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { InvoicereportComponent } from 'src/app/reports/invoicereport/invoicereport.component';
import { AuthService } from 'src/app/_services/auth.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { SalesOrderService } from 'src/app/_services/salesorder.service';
import { SettingsService } from 'src/app/_services/settings.service';
import { AlertifyService } from '../../../_services/alertify.service';
import { CustomerService } from '../../../_services/customer.service';
import { EventsService } from '../../../_services/events.service';
import { SalesOrderItemsService } from '../../../_services/salesorderitems.service';
import { DummymodalComponent } from '../../dummymodal/dummymodal.component';
import { StoreproductsComponent } from '../../store/storeproducts/storeproducts.component';
import { AddsalesorderitemComponent } from '../addsalesorderitem/addsalesorderitem.component';
import { ChoosestatusComponent } from '../choosestatus/choosestatus.component';
import { ChoosestorestockComponent } from '../choosestorestock/choosestorestock.component';
import { DOCUMENT, Location } from '@angular/common';
import 'rxjs/add/operator/takeUntil';
import { take } from 'rxjs/operators/take';
import { SendemailComponent } from 'src/app/reports/sendemail/sendemail.component';
import { ReportsService } from 'src/app/_services/reports.service';
import * as _ from 'lodash';
import { SalesorderAlertComponent } from '../salesorder-alert/salesorder-alert.component';
import { CardReaderService } from 'src/app/_services/cardreader.service';
import { concatMap, debounceTime, takeUntil, tap } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ScanitemComponent } from '../../scanitem/scanitem.component';
import { UserService } from 'src/app/_services/user.service';
import { StoreproductsService } from 'src/app/_services/storeproducts.service';
import { DeliveryMobileComponent } from '../delivery-mobile/delivery-mobile.component';
import { PaymentsMobileComponent } from '../payments-mobile/payments-mobile.component';
import { SalesordertotalMobileComponent } from '../salesordertotal-mobile/salesordertotal-mobile.component';
import { HeaderMobileComponent } from '../header-mobile/header-mobile.component';
import { AddressCardMobileComponent } from '../address-card-mobile/address-card-mobile.component';
import { SalesorderitemsMobileComponent } from '../salesorderitems-mobile/salesorderitems-mobile.component';
import { environment } from '../../../../environments/environment';
import isOnline from 'is-online';
import { SendemailLocalComponent } from 'src/app/reports/sendemail-local/sendemail-local.component';
import { ScannitemsiosComponent } from '../../scannitemsios/scannitemsios.component';
import { TemplatemessagesService } from 'src/app/_services/templatemessages.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AvaRecord } from 'src/app/_modules/AvaRecord';
import moment from 'moment';
import { PreviousRouteService } from 'src/app/_services/previous-route.service';


interface Notification{
  SOItemsID : string;
  reason: string;
  confirmed: boolean
} 

@Component({
  selector: 'app-salesorder-formobile',
  templateUrl: './salesorder-formobile.component.html',
  styleUrls: ['./salesorder-formobile.component.scss']
})
export class SalesorderFormobileComponent implements OnInit, OnDestroy, AfterViewInit, DoCheck {

@ViewChild('divscroll') mydivscroll:any;
@ViewChild('avalaraDetails', {static: false}) avalaraDetailsModal: TemplateRef<any>;
modalAvalara = new BsModalRef;


  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() customer: any;
  @Input() event: any;
  @Input() salesOrder: any = {};
  
  @Output() valueSOForm = new EventEmitter<any>();
  @Output() valueSOFormO = new EventEmitter<any>();
  
  storeSettings: any;
  edit:boolean = false;
  show:any = false;
  focus:any = false;
  showMenu:any = true;
  isCollapsedItems = false;
  isCollapsedTotals = false;
  isCollapsedPayments = false;
  isCollapsedDelivery = false;
  seletectedCustomer: number;
  lastCustomer: number;
  selectedEvent: string;
  showFlorrItem:number = 0;
  fund:any
  defaultTax:any = 0
  idparam:any = '';
  customerComment = '';
  role:any
  firstautosave:boolean  = true  
  dirtyotouch:boolean = true;

  // email sent
  emailwasent:boolean = false;
  sofrom:string = '';

  //this disble save in new so or update so
  // disableButton  = false;


  //  VARS AUTOSAVED AND RECOVERY
    //make comparation with stringjson SO
    compareso:boolean = false;
    //disabled utton when is press
    changeSO:boolean = false
    //id autosaves table, if isnt so saved
    idautosaved:any
    // guarda el valor anterior del stringjson SO
    sOOLD:any = ''
    //para matar el set timeout
    recarga:any    
    //no me acuerdo 
    booleanload:any
    loadcomponenyPayments:boolean = false
    loadcomponenyDelivery:boolean = false
    loadcomponenyHeader:boolean = false

    platform = '';
    isIOS = false;
  tagStates : any = {
    'customers' : false,
    'events' : true,
    'items' : false,
    'marketing' : false,
    'delivery' : false,
    'payments' : false,
    'service' : false,
    'summary' : false,
  }

  warehouseExtraInfo: any;
  discountItems:any = {}
  showCalculateTaxes:boolean = true
  lastSalesOrderTotal: number = 0
  avalaraRecords : AvaRecord[];

  today: number = Date.now();
  soItems: any = [];
  payments: any = [];
  deliveryItems: any = [];
  SOItemsToDelete : any = [];
  SOItemsToUpdate : any = [];
  PaymentsToDelete : any = [];
  DeliveryToDelete : any = [];
  added = false;
  statusClicked = false; //Confirm Dialog if Draft or Order was chosen
  headerForm: FormGroup;
  salesForm: FormGroup;
  itemGroups: Array<any>;
  salesOrderNotChanged: any;
  salesOrderNotChangedItems: any;
  isEdit = false;
  showEditDelivery = true;
  showItems = false;
  showFloor = false;
  showRealFloor = false;
  showAddress = false;
  showFloorItems = false;
  showEditPayment = false;
  isNew = true;
  emailLoading = false;
  alert = true;
  warehouseAddress:any = [];
  openWarehouse = '';
  selectedWarehouse = new FormControl('')
  userRole:any
  savedSignature:string  = ''
  showViewVoid:boolean = false
  paymentsVOID:any = [];
  loadAvalaraPayment:any = false

  @ViewChild(HeaderMobileComponent, {static: false}) headerComponent: HeaderMobileComponent;
  @ViewChild(SalesordertotalMobileComponent, {static: false}) totalComponent: SalesordertotalMobileComponent;
  @ViewChild(SalesorderAlertComponent, {static: false}) alertComponent: SalesorderAlertComponent;
  @ViewChild(DeliveryMobileComponent, {static: false}) deliveryMobileComponent: DeliveryMobileComponent;
  @ViewChild('delivery') deliveryMobileComponentRef: ElementRef;
  @ViewChild(PaymentsMobileComponent, {static: false}) paymentMobileComponent: PaymentsMobileComponent;
  @ViewChild('payment') paymentMobileComponentRef: ElementRef;
  @ViewChild(AddressCardMobileComponent, {static: false}) addressCardMobileComponent: AddressCardMobileComponent;
  @ViewChild(SalesorderitemsMobileComponent, {static: false}) salesorderitemsMobileComponent: SalesorderitemsMobileComponent;
  @ViewChildren('customerContainer') customerDiv: ElementRef<any>;
  @ViewChildren('items') itemsDiv: ElementRef;

@HostListener('window:scroll', [])
onWindowScroll() {
    const win = window.scrollY
    const heightDelivery = this.deliveryMobileComponentRef.nativeElement.offsetHeight;
    const topDelivery = this.deliveryMobileComponentRef.nativeElement.offsetTop;
    const heightPayments = this.paymentMobileComponentRef.nativeElement.offsetHeight;
    const topPayments = this.paymentMobileComponentRef.nativeElement.offsetTop;
    
    if (((heightDelivery + topDelivery) < (win+30)) && this.showEditDelivery ) {
      // console.log('disapear delivery', this.showEditDelivery)
      this.saveDelivery(false)
      this.alertify.success('Deliveries saved')
    }
    if (((heightPayments + topPayments) < (win+10)) && this.showEditPayment ) {
      // console.log('disapear payments', this.showEditPayment)
      this.savePayment(false)
    }
}


  constructor(@Inject(DOCUMENT) private document: any,private customerService: CustomerService,
    private eventService: EventsService,
    private alertify: AlertifyService,
    private modalService: BsModalService,
    private userService: UserService,
    private itemsService: SalesOrderItemsService,
    public bsModalRef: BsModalRef,
    private route: ActivatedRoute,
    private salesSerivce: SalesOrderService,
    private global: GlobalsService,
    private authService: AuthService,
    private router: Router,
    private settingsService: SettingsService,
    private _location: Location,
    private reportService: ReportsService,
    private fb: FormBuilder,
    private routeService: PreviousRouteService,
    public cardReaderService: CardReaderService,
    public storeProductsService: StoreproductsService,
    public templateMeSvc: TemplatemessagesService
    //private _activatedRouteSnapshot: ActivatedRouteSnapshot
  ) {
  }
  
  // scroll(){
  //   this.mydivscroll.nativeElement.scrollTop = 
  //   this.mydivscroll.nativeElement.scrollGeight ;
  // }

  // scroll(el: HTMLElement) {
  //   el.scrollIntoView({ behavior: 'smooth',inline:'center',block:'center' });
  // }
  scrollNew(id:any) {
    let el = document.getElementById(id);
    window.scroll({top: el.offsetTop, behavior: 'smooth'});
  }
  showComponents(componentName:string){
    //console.log(componentName)
    this.focus = componentName
    this.tagStates[componentName] = true
    //console.log(this.focus)



    if(componentName == 'items'){
      this.tagStates['summary'] = true
    }

    let valores = Object.values(this.tagStates); 
    //console.log(valores.length)
    let verify = valores.filter((val:any)=> val == false);
    if(verify.length == 0){
      this.showMenu = false
      this.focus = 'finish'
    }
  }
  ngDoCheck() {    
    
    if(this.PaymentsToDelete != undefined && this.PaymentsToDelete != []){
      this.salesOrder['PaymentsToDelete'] = this.PaymentsToDelete;
    }
    this.valueSOForm.emit(this.salesOrder)

    if (this.isNew === false) {
      let compareSO = JSON.parse(JSON.stringify(this.salesOrder));

      delete compareSO['Balance'];
      delete compareSO['county'];
      delete compareSO['delivery'];
      delete compareSO['Id'];
      delete compareSO['MName'];
      delete compareSO['Payments'];
      delete compareSO['SubTotal'];
      delete compareSO['Total'];
      delete compareSO['Delivery'];
      delete compareSO['totalTaxNum'];

      if (compareSO['soItems']) {
        for (const value of compareSO['soItems']) {
          value['quantityNum'] = parseFloat(value['quantityNum']);
          delete value['lineAvalara']
        }
      }
      if (compareSO['deliveryItems']) {
        for (const value of compareSO['deliveryItems']) {
          delete value['lineAvalara']
        }
      }
      if (this.salesOrderNotChanged['soItems']) {
        for (const value of this.salesOrderNotChanged['soItems']) {
          value['quantityNum'] = parseFloat(value['quantityNum']);
          delete value['lineAvalara']
        }
      }
      if (this.salesOrderNotChanged['deliveryItems']) {
        for (const value of this.salesOrderNotChanged['deliveryItems']) {
          delete value['lineAvalara']

        }
      }


      compareSO['taxNumber'] = parseFloat(compareSO['taxNumber']);
      this.salesOrderNotChanged['taxNumber'] = parseFloat( this.salesOrderNotChanged['taxNumber'] );

      delete compareSO['dbEventDatets'];
      delete compareSO['middleInitial'];
      delete compareSO['billingAddress2'];
      delete compareSO['deliveryAddress2'];
      delete compareSO['phone2Type'];
      delete compareSO['phone2'];
      delete compareSO['sect1exp'];
      delete compareSO['sect2exp'];
      delete compareSO['sect3exp'];
      delete compareSO['disc1exp'];
      delete compareSO['disc2exp'];
      delete compareSO['disc3exp'];
      delete compareSO['disc4exp'];

      delete this.salesOrderNotChanged['Balance'];
      delete this.salesOrderNotChanged['county'];
      delete this.salesOrderNotChanged['delivery'];
      delete this.salesOrderNotChanged['Id'];
      delete this.salesOrderNotChanged['MName'];
      delete this.salesOrderNotChanged['Payments'];
      delete this.salesOrderNotChanged['SubTotal'];
      delete this.salesOrderNotChanged['Total'];
      delete this.salesOrderNotChanged['Delivery'];

      delete this.salesOrderNotChanged['dbEventDatets'];
      delete this.salesOrderNotChanged['middleInitial'];
      delete this.salesOrderNotChanged['billingAddress2'];
      delete this.salesOrderNotChanged['deliveryAddress2'];
      delete this.salesOrderNotChanged['phone2Type'];
      delete this.salesOrderNotChanged['phone2'];

      delete this.salesOrderNotChanged['sect1exp'];
      delete this.salesOrderNotChanged['sect2exp'];
      delete this.salesOrderNotChanged['sect3exp'];
      //delete this.salesOrderNotChanged['sect4exp'];
      delete this.salesOrderNotChanged['disc1exp'];
      delete this.salesOrderNotChanged['disc2exp'];
      delete this.salesOrderNotChanged['disc3exp'];
      delete this.salesOrderNotChanged['disc4exp'];
      delete this.salesOrderNotChanged['totalTaxNum'];



      if (!_.isEqual(JSON.stringify(compareSO).replace(':null', ':""'),
        JSON.stringify(this.salesOrderNotChanged).replace(':null', ':""'))) {
          this.salesForm.markAsDirty();
          this.compareso = true
          // this.alertComponent.closeAlert(true);
          //console.log(JSON.stringify(compareSO).replace(':null', ':""'));
          //console.log(JSON.stringify(this.salesOrderNotChanged).replace(':null', ':""'));
        } else {  
          this.compareso = false        
          this.salesForm.markAsPristine();
      }
    }
  }



  public openModalWithComponent() {
    this.bsModalRef = this.modalService.show(DummymodalComponent, { class: 'modal-xl', backdrop: true, ignoreBackdropClick: true });
  }

  public openStoreProducts() {
    const initialState = {
      salesOrderMode: true
    };
    this.bsModalRef = this.modalService.show(StoreproductsComponent, { initialState, class: 'modal-xl w-100', backdrop: true, ignoreBackdropClick: true });
  }

  public openAddSalesItem(soItem: any) {
    const initialState = {
      salesOrderMode: true,
      item: soItem,
      added: this.added
    };
    this.bsModalRef = this.modalService.show(AddsalesorderitemComponent, { initialState, class: '', backdrop: true, ignoreBackdropClick: true });
  }


  public openStockItems() {
    const initialState = {
      show: 'Store'
    };
    this.bsModalRef = this.modalService.show(ChoosestorestockComponent, { initialState, class: 'modal-xl', backdrop: true, ignoreBackdropClick: true });
  }

  closeFloor(id:any){
    this.showRealFloor = false
    let el = document.getElementById(id);
    window.scroll({top: el.offsetTop, behavior: 'smooth'});
    // el.scroll({ behavior: 'smooth',inline:'center',block:'center' });
  }

  public openAutoChooseItems() {

    if (this.event) {
      if(this.event.itemLayout == null){
      this.showFlorrItem =0;
      }else{
      let floritem = JSON.parse(this.event.itemLayout)
      let arrayFlorItems = JSON.parse(floritem[0].items)
      this.showFlorrItem = arrayFlorItems.length;
      }

        if (this.showFlorrItem > 0) {
          this.openChooseItemsLayout();      
        } else {
          this.openChooseItems();
        }
}
  }
 
  public openChooseItems() {
    this.showItems = true
    this.showFloor = false
    this.showRealFloor = false
    // const initialState = {
    //   show: 'Store'
    // };
    // this.bsModalRef = this.modalService.show(ChoosestorestockComponent, { initialState, class: 'modal-xl w-100 modal-background', backdrop: true, ignoreBackdropClick: true });
  
  }

  public openChooseItemsLayout(id?:any) {
    this.showFloor = true
    this.showItems = false
    this.showRealFloor = false    
    let el = document.getElementById(id);
    setTimeout(() => {
      window.scroll({top: el.offsetTop});      
    }, 500);
    // el.scrollIntoView({ behavior: 'smooth',inline:'center',block:'center' });
  }

  public openChooseFloorLayout(id?:any) {
    this.showFloor = false
    this.showItems = false
    this.showRealFloor = true    
    let el = document.getElementById(id); 
    setTimeout(() => {
      window.scroll({top: el.offsetTop});      
    }, 500);
    // el.scrollIntoView({ behavior: 'smooth',inline:'center',block:'center' });
  }

  

  public openScanner() {
    if(!this.isIOS){
      const initialState = {
        show: 'Store'
      };
      this.bsModalRef = this.modalService.show(ScanitemComponent, {class: 'modal-lg modal-background', backdrop: true, ignoreBackdropClick: true });
    }
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Scan Item',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
  }

  openscannitemsios(){
    const initialState = {
      // qrcValue: "B2AEE4C3-1279-11ED-AE44-0ABB5CE7E25C"
      qrcValue: (document.getElementById('valueQRCiOS') as HTMLInputElement).value
    };
    this.bsModalRef = this.modalService.show(ScannitemsiosComponent, {initialState,class: 'modal-lg w-100 modal-background', backdrop: true, ignoreBackdropClick: true });
    (document.getElementById('valueQRCiOS') as HTMLInputElement).value = ""
  }

  //esto se esta haceindo a cada rato
  ngAfterContentChecked() {
    // se hace solo si se desea comparar
      if(this.compareso){
        //comparo los string, y si son diferentes los guardo y le asigno el nuevo valor para que se dejen de comparar
        //y se lo debo de asignar tambien cuando se actualice la nueva sales order
        if(this.sOOLD != JSON.stringify(this.salesOrder)){
          this.sOOLD = JSON.stringify(this.salesOrder)
          this.compareso = false;
          this.autosave()
        }
      }
  }
  ngAfterViewInit(): void {
    // console.log(this.salesOrder)
    if (this.salesOrder['orderSigexp'] !== null  && this.salesOrder['orderSigexp'] !== undefined) {
      //GET DATA FROM DATABASE THEN TRANSFORM TO URI TO BE ABLE TO VIEW WITH IMG TAG
      try {
      const data_uri = atob(this.salesOrder['orderSigexp']);
      const encoded_image = data_uri.split(',')[1];
      const decoded_image = atob(encoded_image);
      this.savedSignature = data_uri;
      } catch {}
    }
    // to QRC
    const container = document.head || document.body
    const el = document.createElement('script')    
    el.type = 'text/javascript'
    el.async = true
    el.src = 'assets/conneccioios.js'
    el.addEventListener('load', () => {        
    })       
    container.appendChild(el)
  }

  ngOnInit() {
    let snapshot = this.route.snapshot;

    if(snapshot.params['edit']){
      this.show = true;
    }   

    this.platform = navigator.userAgent;
    if (this.platform.match(/iPhone|iPad|iPod/i)) {
      this.isIOS = true;
    }
    if (this.platform.includes(navigator.platform)||(navigator.userAgent.includes('Mac')&& "ontouchend" in document)) {
      this.isIOS = true;
    }

    this.salesSerivce.sofrom
    .pipe(takeUntil(this.destroy$))   
    .subscribe((res)=>
    {
      this.sofrom  =res
    })

    // if(this.role == 'Sales Person' || this.role == 'Cashier'){  
      document.getElementById('mainWithSideBar').style.backgroundColor = "#fff" 
      document.getElementById('sidebar').style.display = "none"; 
      document.getElementById('mainWithSideBar').className = "body-page-content-SalesPerson";
  // }
    
    this.idparam = this.route.snapshot.params.id
    // mandamos un observable para que en Sales-person-list-so no se carguen las ordenes
    this.eventService.loadsalesOrderEvent.next(false);


    //verifico si quiere cargar un autosaved, y solicito el json para qu lo cargue, sino no hay parametro
    // if(this.idparam == '' || this.idparam == null || this.idparam == undefined){
     
    // }
    // fin si parametros

    // activar el boton de guardado si vienen de autosave
    this.salesSerivce.fromautosavedlist
    .pipe(takeUntil(this.destroy$))   
    .subscribe((res)=>
    {
      this.dirtyotouch  =res
    })

    // this.salesSerivce.showSidebar.next(false)
    // document.getElementById('mainWithSideBar').className = "body-page-content-SalesPerson";
    this.userRole = this.authService.decodeToken('role').role

    this.salesForm = this.fb.group({
    });
    this.salesSerivce.changeSOHeder('');
    this.salesSerivce.salesTax.next(null);
    this.salesSerivce.triggerSaveSO    
      .pipe(takeUntil(this.destroy$))    
      .subscribe((res => {
        if (res === true) {
          this.salesSerivce.triggerSaveSO.next(false);
          this.assingSONumber();
        }
      }));

    this.reportService.dataStream.next('');

    this.customerService.salesOrderCustomer.next(0);

    this.settingsService.getSettings(0)
    .pipe(takeUntil(this.destroy$))   
    .subscribe( data => {
      this.storeSettings = data[0];
      this.settingsService.SettingsEvent$.next(data);
      if(!this.show){
        this.checkOtherUser()
      }
      if(data[0]['taxes'] != null) this.defaultTax =  JSON.parse(data[0]['taxes']).defaultTaxes || 0;
      if(data[0]['warehouseAddress'] != null) {
        this.warehouseAddress = JSON.parse(data[0]['warehouseAddress']);
        let defaultWh = this.warehouseAddress.find((a:any) => a?.default == true);
        this.warehouseExtraInfo = (defaultWh == undefined || defaultWh == null) ? this.warehouseAddress[0] : defaultWh;


        // console.log(this.salesOrder.pickup, this.warehouseAddress[0].AddressWH, (this.salesOrder.pickup == this.warehouseAddress[0].AddressWH))
        if(this.edit == false && this.warehouseAddress.length > 0){
          this.selectedWarehouse = (defaultWh == undefined || defaultWh == null) ? this.warehouseAddress[0].AddressWH : defaultWh.AddressWH; 
          if(this.salesOrder.pickup == null || this.salesOrder.pickup == undefined){
            this.salesOrder.pickup = (defaultWh == undefined || defaultWh == null) ? this.warehouseAddress[0].AddressWH : defaultWh.AddressWH; 
          }
        }
      }

      if(this.storeSettings['avaTax'] == 0 || this.storeSettings['avaTax'] == false){
        // this.alertify.warning('Avalara is disabled, calculating with regular sales order tax rate')
        this.showCalculateTaxes = false;
        return
      }
      
      // console.log(this.warehouseAddress)
    }, error => {
      this.alertify.error(error);
    });

    // this.eventService.salesOrderEvent
    // .pipe(takeUntil(this.destroy$))   
    // .subscribe((
    //     res => {
    //       this.selectedEvent = res;
    //       this.eventService.getEvent(this.selectedEvent)
    //       .pipe(takeUntil(this.destroy$))   
    //         .subscribe(data => {
    //           if (data[0] !== undefined) {
    //             this.event = null;
    //             this.event = data[0];


    //             if (this.event['CID'] != null) {
    //               this.salesOrder['eventId'] = this.event['CID'];
    //               this.salesOrder['dbEventDatets'] = this.event['dbDatets'];
    //               this.event['dbDatets'] = new Date(this.event['dbDatets'].replace(/\s/g, 'T'));

    //               if (this.salesOrder['status'] === 'Draft' || this.salesOrder['status'] === 'Order') {
                    
    //                 this.salesOrder['taxNumber'] = this.event['defaultTaxes'];
    //                 this.salesSerivce.salesTax.next({ status: 'ChangeEvent', value: this.event['defaultTaxes']});
    //               }

    //               if(data[0]){  
    //                 this.fund = ''  
    //                 this.fund =  "[{\"team\":\""+data[0]['program1']+"\"}";                    
    //                 if(data[0]['program2'] != null && data[0]['program2'] != "" ){
    //                 this.fund = this.fund + ",{\"team\":\""+data[0]['program2']+"\"}]";
    //                 } else{
    //                 this.fund = this.fund + "]";
    //                 }  
          
    //                 this.eventService.fundraiser.next({"status":"ChangeEvent","teams":this.fund});
    //                 }else{
    //                 this.eventService.fundraiser.next(0);
    //                 }
    //             }


    //             if (this.event) {
    //               if(this.event.itemLayout == null){
    //               this.showFlorrItem = 0;
    //               }else{
    //               let floritem = JSON.parse(this.event.itemLayout)
    //               let arrayFlorItems = JSON.parse(floritem[0].items)
    //               this.showFlorrItem = arrayFlorItems.length; 
    //               };                                
    //             }
    //           }
    //         }, error => {
    //           this.alertify.error(error);
    //         });          
    //       }
    // ));
    //bien solo trae el cero   



     this.eventService.salesOrderEvent.pipe(
      tap(event=>  {this.selectedEvent = event}),
      concatMap(event => { return this.eventService.getEvent(event) 
      }))
      .pipe(takeUntil(this.destroy$))   
      .subscribe(data => {
        if (data[0] !== undefined) {
          this.event = null;
          this.event = data[0];


          if (this.event['CID'] != null) {
            this.salesOrder['eventId'] = this.event['CID'];
            this.salesOrder['dbEventDatets'] = this.event['dbDatets'];
            this.event['dbDatets'] = new Date(this.event['dbDatets'].replace(/\s/g, 'T'));

            if (this.salesOrder['status'] === 'Draft' || this.salesOrder['status'] === 'Order') {
              
              this.salesOrder['taxNumber'] = this.event['defaultTaxes'];
              this.salesSerivce.salesTax.next({ status: 'ChangeEvent', value: this.event['defaultTaxes']});
            }

            if(data[0]){  
              this.fund = data[0]['program1'];
              // this.fund =  "[{\"team\":\""+data[0]['program1']+"\"}";                    
              // if(data[0]['program2'] != null && data[0]['program2'] != "" ){
              // this.fund = this.fund + ",{\"team\":\""+data[0]['program2']+"\"}]";
              // } else{
              // this.fund = this.fund + "]";
              // }  
    
              this.eventService.fundraiser.next({"status":"ChangeEvent","teams":this.fund});
              }else{
              this.eventService.fundraiser.next(0);
              }
          }


          if (this.event) {
            if(this.event.itemLayout == null){
            this.showFlorrItem = 0;
            }else{
            let floritem = JSON.parse(this.event.itemLayout)
            let arrayFlorItems = JSON.parse(floritem[0].items)
            this.showFlorrItem = arrayFlorItems.length; 
            };                                
          }
        }
      }, error => {
        this.alertify.error(error);
      }
      );
    //bien solo trae el cero  


   
    function extend(obj, src) {
      for (let key in src) {
        if (src.hasOwnProperty(key)) {
          obj[key] = src[key];
        }
      }
      return obj;
    }
    this.lastCustomer = this.seletectedCustomer;
    this.customerService.salesOrderCustomer
    .pipe(takeUntil(this.destroy$))   
    .subscribe((
        res => {
          this.seletectedCustomer = res;

          if (this.seletectedCustomer === 0) {
            this.salesOrder['firstName'] = '';
            this.salesOrder['middleInitial'] = '';
            this.salesOrder['lastName'] = '';
            this.salesOrder['billingAddress1'] = '';
            this.salesOrder['billingAddress2'] = '';
            this.salesOrder['phoneType'] = '';
            this.salesOrder['phone1'] = '';
            this.salesOrder['phone2Type'] = '';
            this.salesOrder['phone2'] = '';
            this.salesOrder['city'] = '';
            this.salesOrder['state'] = '';
            this.salesOrder['email'] = '';
            this.salesOrder['zip'] = '';

            this.salesOrder['fName'] = '';
            this.salesOrder['lName'] = '';
            this.salesOrder['deliveryAddress1'] = '';
            this.salesOrder['deliveryAddress2'] = '';
            this.salesOrder['phone3Type'] = '';
            this.salesOrder['phone3'] = '';
            this.salesOrder['deliveryCity'] = '';
            this.salesOrder['deliveryState'] = '';
            this.salesOrder['deliveryEmail'] = '';
            this.salesOrder['deliveryZip'] = '';
            this.salesOrder['customerId'] = 0;
          }


          if (this.salesOrder['customerId'] !== 0) {
            if (this.seletectedCustomer === this.salesOrder['customerId'] && this.salesOrder['status'] != 'Draft') {
              return;
            }
          }


          this.customerService.getCustomer(this.seletectedCustomer)
    .pipe(takeUntil(this.destroy$))   
            .subscribe(data => {


              this.customer = null;
              this.customer = data[0];

              if (this.customer !== undefined) {

                if (this.modalService) {
                  this.modalService.hide(1);
                  this.modalService.hide(0);
                }

                this.salesOrder['firstName'] = this.customer['firstName'];
                this.salesOrder['middleInitial'] = this.customer['middleInitial'];
                this.salesOrder['lastName'] = this.customer['lastName'];
                this.salesOrder['billingAddress1'] = this.customer['billingAddress1'];
                this.salesOrder['billingAddress2'] = this.customer['billingAddress2'];
                this.salesOrder['phoneType'] = this.customer['phoneType'];
                this.salesOrder['phone1'] = this.customer['phone1'];
                this.salesOrder['phone2Type'] = this.customer['phone2Type'];
                this.salesOrder['phone2'] = this.customer['phone2'];
                this.salesOrder['city'] = this.customer['city'];
                this.salesOrder['state'] = this.customer['state'];
                this.salesOrder['email'] = this.customer['email'];
                this.salesOrder['zip'] = this.customer['zip'];
                this.salesOrder['fName'] = this.customer['fName'];
                this.salesOrder['lName'] = this.customer['lName'];
                this.salesOrder['deliveryAddress1'] = this.customer['deliveryAddress1'];
                this.salesOrder['deliveryAddress2'] = this.customer['deliveryAddress2'];
                this.salesOrder['phone3Type'] = this.customer['phone3Type'];
                this.salesOrder['phone3'] = this.customer['phone3'];
                this.salesOrder['deliveryCity'] = this.customer['deliveryCity'];
                this.salesOrder['deliveryState'] = this.customer['deliveryState'];
                this.salesOrder['deliveryEmail'] = this.customer['deliveryEmail'];
                this.salesOrder['deliveryZip'] = this.customer['deliveryZip'];
                this.salesOrder['customerId'] = this.customer['Id'];
                this.salesOrder['entityCode'] = this.customer['entityCode'];
                this.customerComment = this.customer['comments'] || '';
                this.customerService.customerComment.next(this.customerComment)


                if(this.customer['Id'] != 0 && this.customer['Id'] != '0'){
                  let event: string = '';
                  if(this.lastCustomer == this.seletectedCustomer){
                    event = 'update';
                  }else{
                    event = 'change'
                  }
                  this.customerService.changeCustomer.next({customerId:  this.customer['Id'], event: event})
                  this.lastCustomer = this.customer['Id']
                }

              }
            }, error => {
              this.alertify.error(error);
            });
            

        }
    ));



    this.salesSerivce.
    currentHeder
    .pipe(takeUntil(this.destroy$)).subscribe(form => {
      if (form !== undefined) {
        this.headerForm = form;
        if (this.headerForm.value !== undefined) {
          if (this.salesOrder !== undefined) {
            this.salesOrder['fundraiser'] = this.headerForm.value['fundraiser'];
            this.salesOrder['salesPerson'] = this.headerForm.value['salesPerson'];
            this.salesOrder['referralName'] = this.headerForm.value['referralName'];
            this.salesOrder['soSource'] = this.headerForm.value['soSource'];
            this.salesOrder['deliveryRequest'] = this.headerForm.value['deliveryRequest'];
            this.salesOrder['taxNumber'] = this.headerForm.value['taxNumber'];
          }
        }
      }
    });

    // esto sirve cuando se ingresa un item se manda el push al json
    // ppor eso es por cada add
    this.itemsService.salesItemEvent
    .pipe(takeUntil(this.destroy$))   
    .subscribe((res) => {
        const soItem = this.itemsService.salesOrderItems.value;
        // console.log(soItem)
        // console.log(this.SOItemsToDelete)
        // console.log(this.DeliveryToDelete)
        // console.log(this.PaymentsToDelete)
        if (soItem['quantityNum'] >= 1) {
          let soItemNew: any = [];
          soItemNew['created_at'] = this.global.dNow();
          soItemNew['updated_at'] = this.global.dNow();
          soItemNew['createdBy'] = this.authService.decodeToken('username').username;
          soItemNew['modifiedBy'] = this.authService.decodeToken('username').username;
          soItemNew['foreignSOID'] = this.salesOrder['SOID'];
          soItemNew['identifierBackUp'] = this.salesOrder['identifierBackUp'];
          soItemNew['SOItemsID'] = this.global.guid();
          soItemNew['storeProductId'] = soItem['productsId'];
          soItemNew['quantityNum'] = soItem['quantityNum'];
          soItemNew['name'] = soItem['name'];
          soItemNew['size'] = soItem['sizeName'];
          soItemNew['status'] = 'insert';
          soItemNew['MFG'] = soItem['manufacturer'];
          soItemNew['priceNum'] = soItem['priceNum'];
          soItemNew['priceNumUnit'] = soItem['priceNum'];
          soItemNew['Type'] = soItem['Type'];
          if(this.storeSettings['avaTax'] == 1 || this.storeSettings['avaTax'] == true){
            if(!soItem['ataxcode'] ){
              let code = !!this.avalaraRecords.find((av: AvaRecord) => av.product == soItem['Type'])
              soItemNew['taxCode'] =  code ?  this.avalaraRecords.find((av: AvaRecord) => av.product == soItem['Type']).code : 'P0000000' 
            }else{
              soItemNew['taxCode'] =  soItem['ataxcode'];
            }
          }
          soItemNew['wholeSalePrice'] = soItem['wholeSalePriceNum'];
          try {
            if (soItem['minQtyNum'] === null) {
              soItem['minQtyNum'] = 1;
            }
            if (parseInt(soItem['minQtyNum'], 0) === 0) {
              soItem['minQtyNum'] = 1;
            }
            soItemNew['wholeSalePrice'] =
              this.global.pNumber(soItem['wholeSalePriceNum'] / parseInt(soItem['minQtyNum'].toString(), 0));
          } catch (e) {
            // console.log(e);
          }
          soItemNew['Style'] = soItem['styleId'];
          soItemNew['MFGID'] = soItem['MFGID'];
          soItemNew['supItemID'] = soItem['supItemID'];
          soItemNew['minQtyNum'] = soItem['minQtyNum'];
          soItemNew['manufacter'] = soItem['manufacturer'];
          soItemNew['styleName'] = soItem['styleName'];
          soItemNew['sizeName'] = soItem['sizeName'];
          soItemNew['storeType'] = soItem['storeType'];
          soItemNew['itemOrder'] = this.soItems.length;
          soItemNew['itemType'] = '';
          soItemNew['itemDelivery'] = soItem['itemDelivery'] || '';
          soItemNew['note'] = soItem['stockItemId'];
          soItemNew['SKU'] = soItem['SKU'];
          soItemNew['realQty'] = soItem['quantityNum'];
          soItemNew['customerID'] = '';
          soItemNew['deliveryAttn'] = '';
          soItemNew['deliveryPhone'] = '';
          soItemNew['deliveryAddress1'] = '';
          soItemNew['deliveryAddress2'] = '';
          soItemNew['deliveryCity'] = '';
          soItemNew['deliveryState'] = '';
          soItemNew['deliveryZip'] = '';
          soItemNew = Object.assign({}, soItemNew);
          this.soItems.push(soItemNew);
          this.salesOrder['soItems'] = this.soItems;
          // console.log(this.salesOrder['soItems'])
        }
    }
    );
      
    this.cardReaderService.paymentSaved
    .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any ) => {
          if (res === true) {
            this.totalComponent.updateTotals();
            this.ngDoCheck();
          this.submitSO();
          this.cardReaderService.paymentSaved.next(false);
          }
      }, error => {
        this.alertify.error(error);
      });

      this.loadStoreProductsLocal();
      let device:string = localStorage.getItem('device')
      if(device.includes('Android') || device.includes('iOS')){
        this.showMenu = false
        for (const tag in this.tagStates) {
          this.tagStates[tag] = true
        }
      }
      //carga bien el store products

      // THE COMOPONENT HAVE PARAM
      if(this.idparam != '' && this.idparam != null && this.idparam != undefined){
      
      this.route.data
      .pipe(takeUntil(this.destroy$))   
        .subscribe(
          data => {
            if (data['salesorder'] !== undefined) {

              clearTimeout(this.recarga)                      
              this.dirtyotouch = false
              this.salesForm.markAsPristine();


              this.salesOrder = data['salesorder']['SalesOrder'];
              this.soItems = data['salesorder']['SOItems'];
              this.payments = data['salesorder']['Payments'];
              this.deliveryItems = data['salesorder']['delivery'];     
              
              this.eventService.salesOrderEvent.next(this.salesOrder['eventId']);  
              
              this.payments.forEach(e => e.status = 'fromDB')
              this.deliveryItems.forEach(e => e.status = 'fromDB')
              this.soItems.forEach(e => e.status = 'fromDB')           
              this.salesOrder['soItems'] = this.soItems;
              this.salesOrder['payments'] = this.payments;
              this.salesOrder['deliveryItems'] = this.deliveryItems;
              this.valueSOFormO.emit(this.salesOrder)
              
              if (this.salesOrder['dbDatets']) {
                if (this.salesOrder['dbDatets'].includes('/')) {
                  this.salesOrder['dbDatets'] = this.salesOrder['dbDatets'].toString().replace(/-/g, '/');
                } else {
                  this.salesOrder['dbDatets'] = this.salesOrder['dbDatets'].toString().replace(/-/g, '/');
                }
              }

              let snapshot = this.route.snapshot;
              if(snapshot.params['edit']){
                this.show = true;
                this.showEditPayment = false
                this.showEditDelivery = false
              }          

              this.edit = true;
            
              this.customerService.salesOrderCustomer.next(this.salesOrder['customerId']);

              
              //cuando carga el componente, inicializado la variable de comparacion y habilito la comparacion
              this.sOOLD = JSON.stringify(this.salesOrder)
            

                // verifico si tiene recovery para dar la opcion de restaurar la informacion
                this.salesSerivce.getOrderAutosave({"id":this.salesOrder['SOID'],
                "SalesPerson":this.salesOrder['salesPerson'],
                "createdBy":this.salesOrder['createdBy']
                  })
                .pipe(takeUntil(this.destroy$))    
                .subscribe((res:any) => {
                  if(res?.length > 0 && !this.show){
                    
                        this.alertify.confirm('You have unsaved changes, Do you want to restore them?',()=>{      

                        let json = JSON.parse(res[0]['SOJson']);
                        //cargamos el header
                        this.salesSerivce.changeheaderFromUnsaved.next({
                          deliveryRequest: json['deliveryRequest'],
                          fundraiser: json['fundraiser'],
                          referralName:json['referralName'],
                          salesPerson: json['salesPerson'],
                          salesPersonOther: json['salesPersonOther'],
                          soSource: json['soSource'],
                          taxNumber: json['taxNumber']
                        })

                        this.eventService.salesOrderEvent.next(json['eventId'])
                       
                        //cargamos customers y warranty
                        this.salesOrder = json

                        //carganmos los items
                        this.soItems = json.soItems;
                        this.salesOrder['soItems'] = json.soItems;

                        //cargamos el delivery
                        this.salesOrder['deliveryItems'] = json.deliveryItems;
                        
                        //cargamos el payments
                        this.salesOrder['payments'] = json.payments;
                        this.valueSOFormO.emit(this.salesOrder)   
                        }
                        ,()=>{
                        })            
                            
                  }

                  }, error => {
                  this.alertify.error(error);
                });

                this.salesOrderNotChanged = JSON.parse(JSON.stringify(this.salesOrder));

                setTimeout(() => {                  
              this.loadcomponenyPayments = true
              this.loadcomponenyDelivery = true
              this.loadcomponenyHeader = true                
                }, 3000);

                setTimeout(() => {                
                this.isNew = false;                  
                }, 5000);
                this.salesForm.markAsDirty();

                
                let specificFields = {
                  values: [
                      "comments"
                  ]
                 }
              this.customerService.getCustomerSpecific(this.salesOrder['customerId'], specificFields )
              .pipe(takeUntil(this.destroy$))   
              .subscribe(data => {

                if (data[0] !== undefined) {
                  this.customerComment = data[0].comments || '';
                  this.customerService.customerComment.next(data[0].comments || '')
                }
              }, error => {
                this.alertify.error(error);
              });
                

            } 
          }
        );
    
      }else{
        // THE DOESNT COMPONENT HAVE PARAM
  
        this.salesSerivce.idautosaved
        .pipe(takeUntil(this.destroy$))   
        .subscribe((idd)=>
        {
          
          // console.log(idd)
          //IF THERE IS ID, COMPONENT LOAD FROM AUTOSAVED LIST
          if(idd != ''){              
          this.salesSerivce.getOrderAutosave({"id":idd,      
          "SalesPerson":JSON.parse(this.authService.decodeToken('user').user).name,
          "createdBy":this.authService.decodeToken('username').username
            })
          .pipe(takeUntil(this.destroy$))    
          .subscribe((res:any) => {
              if(res?.length > 0){                    
    
                  let json = JSON.parse(res[0]['SOJson']);
                  
                  // console.log(json)
    
                  //cargamos el header
                  this.salesSerivce.changeheaderFromUnsaved.next({
                    deliveryRequest: json['deliveryRequest'],
                    fundraiser: json['fundraiser'],
                    referralName:json['referralName'],
                    salesPerson: json['salesPerson'],
                    salesPersonOther: json['salesPersonOther'],
                    soSource: json['soSource'],
                    taxNumber: json['taxNumber']
                  })
    
                  this.eventService.salesOrderEvent.next(json['eventId'])
                  
                  //cargamos customers y warranty
                  this.salesOrder = json        
                  this.customerService.nameCustomer.next({'firstname':json?.firstname,'lastname':json?.lastName})

    
                  //carganmos los items
                  if(json.soItems != undefined){
                    this.soItems = json.soItems;
                    this.salesOrder['soItems'] = json.soItems;
                  }
    
                  //cargamos el delivery
                  if(json.deliveryItems != undefined){                
                    this.salesOrder['deliveryItems'] = json.deliveryItems;
                    this.salesOrder.deliveryItems = json.deliveryItems;
                  }
    
                  //cargamos el payments
                  if(json.payments != undefined){   
                    // console.log(json.payments)                 
                    this.salesOrder['payments'] = json.payments;
                    this.salesOrder.payments = json.payments;
                  }                  

                  this.valueSOFormO.emit(this.salesOrder)  
                  this.idautosaved  =idd     
                  
                  //mando a mostrar customer que se cargue la vista de mostrar el customer
                  this.customerService.showcustomermobile.next('showcustomer')
                  this.showEditPayment = false
                  this.showEditDelivery = false
                  this.loadcomponenyPayments = true
                  this.loadcomponenyDelivery = true   
                  this.loadcomponenyHeader = true
                  this.compareso = true 
  
  
            }
            }, error => {
            this.alertify.error(error);
          });
          // IF THERE ISNOT ID, COMPONENT LOAD FROM NEW SO
          }else{
              this.initSalesOrder();
              this.compareso = true              
              this.isNew = true;
              this.edit = false;      
              this.loadcomponenyPayments = true
              this.loadcomponenyDelivery = true
              this.loadcomponenyHeader = true
          } 
        },
          (err)=>{
          // console.log(err)
            this.initSalesOrder(); 
            this.compareso = true              
            this.isNew = true;
            this.edit = false;      
            this.loadcomponenyPayments = true
            this.loadcomponenyDelivery = true
            this.loadcomponenyHeader = true
          })
      }   
      
      //para guardar solo el nombre y el appellido en el autosaved

      this.customerService.nameCustomer
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (
          res=>{ 
            if(res != ''){
                if(res?.fromform != 0){
                  if(res?.firstname != undefined){this.salesOrder['firstName'] = res?.firstname;}
                  if(res?.lastname != undefined){this.salesOrder['lastName'] = res?.lastname;} 
                } 
            }           
          }
        )
      );

      this.role = this.authService.decodeToken('role').role

      if(!this.storeSettings == null){
        if(!this.show){
          this.checkOtherUser()
        }
      }

      // this.salesSerivce.check_so().pipe(takeUntil(this.destroy$)).subscribe((data: socketRes)=>{
      //   console.log(data)
      //   if(this.salesOrder['SOID'] == data.SOID && localStorage.getItem("socket_user_id") != data.socket_user_id){
      //     if(this.role == 'Sales Person' && data.role != 'Sales Person'){
      //       this.alertify.alert("A "+data.role+" is now modifying this Sales Order, you will be redirect to sales orders", ()=>{
      //         this.router.navigate(['/salesorder']);
      //       })
      //     }else{
      //       this.alertify.alert("Other "+data.role+" is now modifying this Sales Order", ()=>{
  
      //       })
      //     }
      //   }
      // })

      //actualizo los json despues de haber mandado el email
      this.reportService.SOIDemailsentSo.subscribe(
        (res:any)=>{          
          if(res != ''){   
              this.emailwasent = true
              this.salesOrder['notifications'] = res.notifications;            
              this.reportService.SOIDemailsentSo.next('');
          }
        })

          //conpruebo si el email fue enviado
          if(this.salesOrder['notifications'] != null && this.salesOrder['notifications'] != undefined && this.salesOrder['notifications'] != ""){
            if(JSON.parse(this.salesOrder['notifications']).emailsent != undefined){ 
              if(JSON.parse(this.salesOrder['notifications']).emailsent.length > 0){
                this.emailwasent = true                
              }
            }
          }

          this.storeProductsService.getAvalaraRecords().toPromise()
          .then((response: AvaRecord[]) => {
            // console.log(response)
            this.avalaraRecords = response
          })
          
        
    
  }//end ngOnInit

  checkOtherUser = () =>{
    if(this.salesOrder['salesPersonOther'] != null && (this.storeSettings['cashierStatus'] == 'vc' || this.storeSettings['cashierStatus'] == 'vsp')){
      // console.log(this.salesOrder['salesPersonOther'], this.authService.decodeToken('role').role);
      
      if(this.salesOrder['salesPersonOther'] != 'Sales Person' && this.authService.decodeToken('role').role == 'Sales Person'){
        this.alertify.confirm("You can't update this Sales Order, it has been taken for a "+this.salesOrder['salesPersonOther']+", Would you like to continue as read-only mode?",
        ()=>{

        }, 
        ()=>{
          this.goBackToList()
        })
        this.show = true
        this.showEditPayment = false
        this.showEditDelivery = false
      }


      if(this.salesOrder['salesPersonOther'] == 'Sales Person' && 
      (this.authService.decodeToken('role').role == 'Dealer' || this.authService.decodeToken('role').role == 'Cashier' || this.authService.decodeToken('role').role == 'Admin' || this.authService.decodeToken('role').role == 'Manager')){
        let values = {
          role : this.authService.decodeToken('role').role
        }
        this.salesSerivce.updateRoleInSO(this.salesOrder['SOID'], values).subscribe((data)=>{
          // console.log(data)
          this.salesOrder['salesPersonOther'] = this.authService.decodeToken('role').role
          // this.sendSsocket()
        })
      }
  }
  }

  // sendSsocket(){
  //   let values : socketRes = {
  //     SOID: this.salesOrder['SOID'],
  //     socket_user_id : localStorage.getItem("socket_user_id"),
  //     device: localStorage.getItem("device"), 
  //     role: this.authService.decodeToken('role').role
  //   }
  //   this.salesSerivce.sendMessage(values);
  // }

    
  sumTaxes(details: any, type: string):number{
    return details.reduce((acc, item) => acc + item[type] , 0)
  }

  hidemenu(){
    this.showMenu = false
    this.tagStates['customers'] = true
    this.tagStates['marketing'] = true
    this.tagStates['items'] = true
    this.tagStates['delivery'] = true
    this.tagStates['payments'] = true
    this.tagStates['service'] = true            
    this.tagStates['summary'] = true  
  }

  closeAlert() {
    this.alert = false;
  }

  addDiscount() {
    this.salesOrder['soItems'] = this.soItems;
    let soItemNew = [];
    soItemNew['SOItemsID'] = this.global.guid();
    soItemNew['created_at'] = this.global.dNow();
    soItemNew['updated_at'] = this.global.dNow();
    soItemNew['foreignSOID'] = this.salesOrder['SOID'];
    soItemNew['identifierBackUp'] = this.salesOrder['identifierBackUp'];
    soItemNew['manufacter'] = 'ORDER DISCOUNT';
    soItemNew['itemType'] = 'discount';

    soItemNew['styleName'] = this.salesOrder['soSource'];
    soItemNew['status'] = 'insert';
    soItemNew['priceNum'] = this.salesOrder['SubTotal'];
    soItemNew['priceNumUnit'] = 0;
    soItemNew['PDis'] = 0;
    soItemNew['itemOrder'] = this.soItems.length;
    soItemNew = Object.assign({}, soItemNew);
    this.soItems.push(soItemNew);

    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Add Order Discount',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }

  ngOnDestroy(): void {
    if(this.userRole != 'Sales Person' &&  this.userRole != 'Cashier' ){      
        document.getElementById('mainWithSideBar').className = "body-page-content";
        document.getElementById('sidebar').style.display = "inherit"; 
    }    
    document.getElementById('mainWithSideBar').style.backgroundColor = "#f0f3f4" 
    // document.getElementById('mainWithSideBar').className = "body-page-content-SalesPerson";

    this.destroy$.next(true);
    this.destroy$.unsubscribe();  
    this.salesSerivce.fromautosavedlist.next(false);  
    this.eventService.loadsalesOrderEvent.next(true);   
    this.salesSerivce.changeheaderFromUnsaved.next('')
    this.salesSerivce.idautosaved.next('')
    this.idautosaved = '';
    this.customerService.showcustomermobile.next('')
  }



  initSalesOrder() {
    this.salesSerivce.changeSOHeder('');
    this.salesSerivce.salesTax.next({ status: 'Init', value: 0});
    this.salesOrder['SOID'] = this.global.guid();
    this.salesOrder['identifierBackUp'] = this.global.guid(); 
    this.salesOrder['created_at'] = this.global.dNow();
    this.salesOrder['updated_at'] = this.global.dNow();
    this.salesOrder['dbDatets'] = this.global.dNow();

    this.salesOrder['status'] = 'Draft';
    this.salesOrder['SONumber'] = '';
    this.salesOrder['taxNumber'] = 0;
    this.salesOrder['deliveryRequest'] = 'Delivery';
    this.salesOrder['Total'] = 0;
    // let username = localStorage.getItem('user');
    let username = this.authService.decodeToken('user').user
    this.customerService.editCustomer.next(0)

    username = JSON.parse(username)['name'];
    if (username === undefined) {
      username = this.authService.decodedToken.unique_name;
    }
    this.salesOrder['salesPerson'] = username;
    this.soItems = [];


    this.settingsService.SettingsEvent$
    .pipe(takeUntil(this.destroy$))   
      .subscribe(data => {
        this.storeSettings = data[0];
        const settings = data[0];
        if (settings) {
          this.salesOrder['disc1exp'] = (settings['dis1exp']);
          this.salesOrder['disc2exp'] = (settings['dis2exp']);
          this.salesOrder['disc3exp'] = (settings['dis33exp']);
          this.salesOrder['disc4exp'] = (settings['dis4exp']);
        }
      }, error => {
        this.alertify.error(error);
      });

    this.salesSerivce.getDraftNumber()
    .pipe(takeUntil(this.destroy$))   
      .subscribe(data => {

        if (data[0] !== undefined) {
          const draftnumber = data[0]['DraftNum'];
          this.salesOrder['DraftNum'] = parseInt(draftnumber.toString(), 0) + 1;
        }
      }, error => {
        this.alertify.error(error);
      });
  }

  autosave(){
          //aqui no mas espero los 5 segundo por si se esta deditando demasiado
         clearTimeout(this.recarga)
         this.recarga = setTimeout(()=>{          
             this.saveSO('autosave');             
         }, 5000); 
   }

   scrollToCustomers(){
    // this.customerDiv = window.getElementById('customerContainer');
    this.scrollNew('customerContainer')
   }


  submitSO() {
      if (this.salesOrder['customerId'] === 0 || this.salesOrder['customerId'] === undefined) {
        this.alertify.error('Select a Customer');
        return;
      }
    if (this.salesOrder['eventId'] === 0 || this.salesOrder['eventId'] === undefined) {
      this.alertify.error('Select an Event');
      return;
    
    }
    if(this.showCalculateTaxes && (this.storeSettings['avaTax'] == 1 || this.storeSettings['avaTax'] == true)){

      if(this.edit && (this.salesOrder['avalaraActive'] == null || this.salesOrder['avalaraActive'] == undefined)){ 
         
      }else{
        if((this.salesOrder['soItems']?.length == undefined ? 0 : this.salesOrder['soItems']?.length) > 0 && !(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc')) ){
          this.alertify.warning('Calculing taxes...')
          this.sendAvalaraInfo(false)
          return
        }else{
          this.salesOrder['avalaraActive'] = true
        }
      }
    }

    // console.log(this.salesorderitemsMobileComponent.checkItemsWarning());
    // if(this.salesorderitemsMobileComponent != undefined ){
    //   if (this.salesorderitemsMobileComponent.checkItemsWarning()) {
    //     this.alertify.confirm('Items with direct ship address differs from sales order shipping address, Would you like to continuos?', 
    //     ()=>{
    //       let decrypt = JSON.parse(this.salesOrder['notifications'])
    //       let items:Notification[] = decrypt.warnings
    //       let itemsConverted = items.map((itm:Notification) => {return { ...itm, confirmed: true }});
    //       this.salesorderitemsMobileComponent.warnings = itemsConverted
    //       decrypt.warnings = itemsConverted
    //       this.salesOrder['notifications'] = JSON.stringify(decrypt)
    //       this.submitSO()
    //       }, 
    //     ()=>{
  
    //     })
    //     return;
    //   }
    // }

    if(!(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc'))){
      if (this.salesOrder['deliveryRequest'] === 'Delivery'
          && (this.salesOrder['fName'] === null || this.salesOrder['fName'] === undefined
          || this.salesOrder['billingAddress1'] === null || this.salesOrder['billingAddress1'] === undefined 
          || this.salesOrder['phone1'] === null || this.salesOrder['phone1'] === undefined 
          || this.salesOrder['phone3'] === null || this.salesOrder['phone3'] === undefined 
          )) {
          this.alertify.error('Please select a Customer with Shipping Address for Delivery or change the order to Pickup');
          this.addressCardMobileComponent.editSalesOrder()
          this.addressCardMobileComponent.showEditCustomer = true
          this.scrollToCustomers()
          return;
        }
    }


    if (this.headerForm.value !== undefined) {
      this.salesOrder['fundraiser'] = this.headerForm.value['fundraiser'];
        //valido si al menos un team viene en seleccionado 
      // this.thereAreTeam = 0;
      // if(this.headerForm.value['fundraiser'] != null){  
      //   for (const value of JSON.parse(this.headerForm.value['fundraiser'])) {
      //     if(value['checked']){
      //       this.thereAreTeam++;
      //     }
      //   }
      // }
      this.salesOrder['salesPerson'] = this.headerForm.value['salesPerson'];
      this.salesOrder['referralName'] = this.headerForm.value['referralName'];
      this.salesOrder['soSource'] = this.headerForm.value['soSource'];
      this.salesOrder['deliveryRequest'] = this.headerForm.value['deliveryRequest'];
      this.salesOrder['taxNumber'] = this.headerForm.value['taxNumber'];
    }


    if (this.salesOrder['soSource'] === 0 || this.salesOrder['soSource'] === undefined || this.salesOrder['soSource'] === "") {
      this.alertify.error('Select a Referral');
      return;
    }
    if (this.salesOrder['soItems']) {

      const items = this.salesOrder['soItems'].filter(item =>
        ((
          !item?.manufacter?.toString().toLowerCase().includes('discount')
          &&
          item?.itemType !== 'discount'

        ) &&
          (item['itemDelivery'] == null || item['itemDelivery'] === undefined || item['itemDelivery'] === ''))
      );

      const itemsDirectShipment = this.salesOrder['soItems'].filter(item =>
        (
          (item['itemDelivery'] == 'Direct Shipment' && item['itemType'] != 'discount') &&
          ((item['deliveryAttn'] == null || item['deliveryAttn'] == undefined || item['deliveryAttn'] == '') ||
          (item['deliveryAddress1'] == null || item['deliveryAddress1'] == undefined || item['deliveryAddress1'] == '') ||
          (item['deliveryPhone'] == null || item['deliveryPhone'] === undefined || item['deliveryPhone'] == '') ||
          (item['deliveryCity'] == null || item['deliveryCity'] == undefined || item['deliveryCity'] == '') ||
          (item['deliveryState'] == null || item['deliveryState'] == undefined || item['deliveryState'] == '') ||
          (item['deliveryZip'] == null || item['deliveryZip'] == undefined || item['deliveryZip'] == ''))
        ) 
        );

      if(!(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc'))){
        if (items.length > 0) {
          this.alertify.error('There are items without a Inventory Status.');
          this.scrollNew('itemsContainer')
          this.salesorderitemsMobileComponent.checkStatus()
          return;
        }

        if (itemsDirectShipment.length > 0) {
          this.alertify.error('There are items Direct Shipment with invalid address .');
          this.salesorderitemsMobileComponent.checkStatus()
          return;
        }
      }
    }
    //Close and save payments and delivery if the sales person havent
    this.saveDelivery(false)
    this.savePayment(false)
    this.totalComponent.updateTotals()
    // console.log(this.salesOrder['Balance'])
    if (this.salesOrder['status'] === 'Draft' || this.salesOrder['Balance'] > 0) {
      const initialState = {
        so: this.salesOrder,
        choose: this.statusClicked,
        ignoreBackdropClick: true,
        backdrop: false
      };

       if(this.salesOrder['soSource'] != null && this.salesOrder['soSource'] != ""  ){
          
        if(!(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc' || this.storeSettings['cashierStatus'] == 'vcsp'))){
          this.bsModalRef = this.modalService.show(ChoosestatusComponent, { initialState, class: '', backdrop: true, ignoreBackdropClick: true });
        }else{
          this.salesOrder['status'] = 'Draft' 
          this.statusClicked = true;
          this.salesSerivce.triggerSaveSO.next(true);
        }
      }else{
        if(!(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc'))){
          this.alertify.error('Referral is required');
          this.scrollNew('header-container')
          this.headerComponent.checkRefe()
        }else{
          if(!(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc' || this.storeSettings['cashierStatus'] == 'vcsp'))){
            this.bsModalRef = this.modalService.show(ChoosestatusComponent, { initialState, class: '', backdrop: true, ignoreBackdropClick: true });
          }else{
            this.salesOrder['status'] = 'Draft' 
            this.statusClicked = true;
            this.salesSerivce.triggerSaveSO.next(true);
          }
        }
      }
     
    } else {
      if(this.salesOrder['soSource'] != null && this.salesOrder['soSource'] != ""  ){
        this.assingSONumber();
      }else{
        if(!(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc'))){
          this.alertify.error('Referral is required');
          this.scrollNew('header-container')
          this.headerComponent.checkRefe()
        }else{
          this.assingSONumber();
        }
      }
      
    }


  }

  assingSONumber() {
    if (this.salesOrder['status'] !== 'Draft' && (this.salesOrder['SONumber'] == null || this.salesOrder['SONumber'] === '')) {
      this.salesSerivce.getSONumber(this.salesOrder['SOID'])
    .pipe(takeUntil(this.destroy$))   
        .subscribe(res => {
          this.salesOrder['SONumber'] = res[0]['ID'];         
          this.saveSO();
          
        });
    } else {
     this.saveSO();    

    }
  }


  saveSO(from?:any) {
    // console.log('entra en saveSO')
        if(from == 'autosave'){
    
    
          //si, no tengo parametro, estoy en autosave, y genero mi propio ID,
          if( this.idparam == '' || this.idparam == null || this.idparam == undefined){
            //si, no tenemos id de autosaved,vamos a guardarlo por primera vez y generaremos un id
            if(this.idautosaved == '' || this.idautosaved == null || this.idautosaved == undefined ){
              // console.log("save autosaved")
              let customer = ''
              if(this.salesOrder['firstName'] != undefined){
                customer = customer + this.salesOrder['firstName'];
              }
              if(this.salesOrder['lastName'] != undefined){
                customer = customer +' '+ this.salesOrder['lastName'];
              }
              this.salesSerivce.saveOrderAutosave({
                "id":this.global.guid(),
                "type":"autosaved",
                "SOjson": JSON.stringify(this.salesOrder),
                "customer": customer,    
                "SalesPerson":JSON.parse(this.authService.decodeToken('user').user).name,
                "createdBy":this.authService.decodeToken('username').username,
                "action":"save"
                })
              .pipe(takeUntil(this.destroy$))    
              .subscribe((res:any) => {
                // console.log(res)
                this.idautosaved = res
                this.compareso = true;
                }, error => {
                this.alertify.error(error);
              });
            //sino, mandaremos el id del autosaved
            }else{
              // console.log("edit autosaved")
              let customer = ''
              if(this.salesOrder['firstName'] != undefined){
                customer = customer + this.salesOrder['firstName'];
              }
              if(this.salesOrder['lastName'] != undefined){
                customer = customer +' '+ this.salesOrder['lastName'];
              }
              this.salesSerivce.saveOrderAutosave({
                "id":this.idautosaved,
                "type":"autosaved",
                "SOjson": JSON.stringify(this.salesOrder),      
                "customer": customer,    
                "SalesPerson":JSON.parse(this.authService.decodeToken('user').user).name,
                "createdBy":this.authService.decodeToken('username').username,
                "action":"edit"
                })
              .pipe(takeUntil(this.destroy$))    
              .subscribe(res => {
                this.compareso = true;
                }, error => {
                this.alertify.error(error);
              });
            }
    
          // sino estoy en recovery, y mando el id de la orden
          }else{
            //  console.log('save and edit recovery')
            this.salesSerivce.saveOrderAutosave({
              "id":this.idparam,
              "type":"recovery",
              "SOjson": JSON.stringify(this.salesOrder), 
              "customer": this.salesOrder['firstName']+' '+this.salesOrder['lastName'],    
              "SalesPerson":JSON.parse(this.authService.decodeToken('user').user).name,
              "createdBy":this.authService.decodeToken('username').username,
              "action":"recovery"
              })
            .pipe(takeUntil(this.destroy$))    
            .subscribe(res => {
              this.compareso = true;
              }, error => {
              this.alertify.error(error);
            });
          }
          
        
        }
        // fuera del autosaved and recovery
        else{
          
          delete this.salesOrder['disc1']
          delete this.salesOrder['disc2']
          delete this.salesOrder['disc4']
          delete this.salesOrder['disc3']
          delete this.salesOrder['customerComment']
          clearTimeout(this.recarga)
          this.compareso = false
          clearTimeout(this.recarga)
        // into save and edit, SalesOrder
    
    
            if (this.edit === false) {
              this.changeSO = true

              setTimeout(() => {
              this.changeSO = false                
              }, 3000);
              
              this.saveDelivery(false)
              this.savePayment(false)
              this.totalComponent.updateTotals()

              // console.log(this.salesOrder)
               // Verify sales order tax is not empty
              this.salesOrder['taxNumber'] = (this.salesOrder['taxNumber'] == "" || this.salesOrder['taxNumber'] == null ) ? "0" : this.salesOrder['taxNumber']
              const so = Object.assign({}, this.salesOrder);
              so['deliveryStatus'] = 'Pending'; 
              so['created_at'] = this.global.dNow();
              so['salesPersonOther'] = this.authService.decodeToken('role').role;
              so['createdBy'] = this.authService.decodeToken('username').username;
              so['modifiedBy'] = this.authService.decodeToken('username').username;
            
              if (so['soItems']) {
                for (const value of so['soItems']) {
                  value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
                  value['created_at'] = this.global.dNow();          
                  value['createdBy'] = this.authService.decodeToken('username').username;
                }
              }
            
              if (so['payments']) {
                for (const value of so['payments']) {
                  value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
                  value['created_at'] = this.global.dNow();
                  value['createdBy'] = this.authService.decodeToken('username').username;
                }
              }    
              if (so['deliveryItems']) {
                for (const value of so['deliveryItems']) {
                  value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
                  value['created_at'] = this.global.dNow();
                  value['createdBy'] = this.authService.decodeToken('username').username;
                  if (value['pretax'] === 0) {
                    value['pretax'] = null;
                  }
                }
              }
        
              // let data = {
              //   userId: '123',
              //   userName: this.authService.decodeToken('username').username,
              //   section: 'SO',
              //   action: 'Save SO',
              //   eventId: so['status'],
              //   device: localStorage.getItem('device'),
              // }
              
              // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
              // .subscribe(a => {});
              // console.log('esta es la ssales order cuando se guarda');
              //console.log(so)
              //let obs = this.salesSerivce.addSalesOrder(so).pipe(debounceTime(500));
            
              //this.salesSerivce.addSalesOrder(so)
              if((this.salesOrder['avalaraActive'] != null && this.salesOrder['avalaraActive'] != undefined) && (this.storeSettings['avaTax'] == 1 || this.storeSettings['avaTax'] == true) && ((this.salesOrder['soItems']?.length == undefined ? 0 : this.salesOrder['soItems']?.length) > 0) && !(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc')) ){
                this.sendAvalaraInfo(true)
              }
            this.salesSerivce.addSalesOrder(so).pipe(debounceTime(500))
            .pipe(takeUntil(this.destroy$))   
                .subscribe(res => {
                  this.alertify.success('Sales Order has been added');
                  // this.edit = true;
                  this.soItems.forEach(soItem => this.changeStatusFromDB(soItem));
                  if(this.salesOrder['soItems'] != undefined){
                    this.salesOrder['soItems'].forEach(soItem => this.changeStatusFromDB(soItem));            
                  }
                  if(this.salesOrder['deliveryItems'] != undefined){
                    this.salesOrder['deliveryItems'].forEach(soItem => this.changeStatusFromDB(soItem));            
                  }
                  if(this.salesOrder['payments'] != undefined){
                    this.salesOrder['payments'].forEach(soItem => this.changeStatusFromDB(soItem));            
                  }   
                 
                  this.salesForm.markAsPristine();
                  this.dirtyotouch = false
    
                  //borro si tenia autosaved, e inicializo la variable para que cargue los datos de la sales order
                  if(this.idautosaved != ''){
    
                   this.salesSerivce.deleteSOsAutoSaved(this.idautosaved)
                  .pipe(takeUntil(this.destroy$)) 
                  .subscribe(
                    (res1)=>{    
    
                    this.idautosaved = '';
                    this.changeSO = false
                    this.eventService.touchchooseevent.next(false);
                    this.router.navigate(['/salesorder/' + res['SOID']]);
                  },
                  (err)=>{console.log(err)}
                  )
                }else{
                    this.changeSO = false
                    this.router.navigate(['/salesorder/' + res['SOID']]);
                  }
              
                  this.changeSO = false                
    
                }, error => {
              this.changeSO = false                
              this.alertify.error('Problem 101');
                  console.log(error)
                });
            } else {
        
              this.saveDelivery(false)
              this.savePayment(false)
              this.totalComponent.updateTotals()

              this.changeSO = true
              setTimeout(() => {
                this.changeSO = false                
              }, 3000);

              clearTimeout(this.recarga)
              if (Array.isArray(this.salesOrder) === false) {
                if (this.salesOrder['SOID']) {
                  let identifierBackUp = this.global.guid()
                  
                   // Verify sales order tax is not empty
                  this.salesOrder['taxNumber'] = (this.salesOrder['taxNumber'] == "" || this.salesOrder['taxNumber'] == null ) ? "0" : this.salesOrder['taxNumber']
                  this.salesOrder['orderStatus'] = "" 
                  this.salesOrder['identifierBackUp'] = identifierBackUp; 
                  this.salesOrder['updated_at'] = this.global.dNow();
                  this.salesOrder['modifiedBy'] = this.authService.decodeToken('username').username;
                  
                  if(this.SOItemsToDelete != undefined && this.SOItemsToDelete != []){
                    this.salesOrder['SOItemsToDelete'] = this.SOItemsToDelete;
                  }

                  if(this.SOItemsToUpdate != undefined && this.SOItemsToUpdate != []){
                    this.salesOrder['SOItemsToUpdate'] = this.SOItemsToUpdate;
                  }
        
                  if(this.DeliveryToDelete != undefined && this.DeliveryToDelete != []){
                    this.salesOrder['DeliveryToDelete'] = this.DeliveryToDelete;
                  }
        
                  if(this.PaymentsToDelete != undefined && this.PaymentsToDelete != []){
                    this.salesOrder['PaymentsToDelete'] = this.PaymentsToDelete;
                  }
        
                if(this.salesOrder['soItems'] != undefined){  
                  for (const value of this.salesOrder['soItems']) {
                    value['identifierBackUp'] = identifierBackUp; 
                    value['updated_at'] = this.global.dNow();
                    value['modifiedBy'] = this.authService.decodeToken('username').username;
                  }
                }
        
                if(this.salesOrder['payments'] != undefined){ 
                  for (const value of this.salesOrder['payments']) {
                    value['identifierBackUp'] = identifierBackUp; 
                    value['updated_at'] = this.global.dNow();
                    value['modifiedBy'] = this.authService.decodeToken('username').username;
                  }
                }
        
                if(this.salesOrder['deliveryItems'] != undefined){ 
                  for (const value of this.salesOrder['deliveryItems']) {
                    value['identifierBackUp'] = identifierBackUp; 
                    value['updated_at'] = this.global.dNow();
                    value['modifiedBy'] = this.authService.decodeToken('username').username;
        
                    if (value['pretax'] === 0) {
                      value['pretax'] = null;
                    }
        
                  }
                }
                if(this.salesOrder['SOItemsToDelete'] != undefined){
                  if(this.salesOrder['SOItemsToDelete']){
                    for (const value of this.salesOrder['SOItemsToDelete']) {
                        value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
                        value['created_at'] = this.global.dNow();
                        value['createdBy'] = this.authService.decodeToken('username').username;
                    }
                  }
                }
                if(this.salesOrder['DeliveryToDelete'] != undefined){
                  if(this.salesOrder['DeliveryToDelete']){
                    for (const value of this.salesOrder['DeliveryToDelete']) {
                        value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
                        value['created_at'] = this.global.dNow();
                        value['createdBy'] = this.authService.decodeToken('username').username;
                        if (value['pretax'] === 0) {
                          value['pretax'] = null;
                    }
                      
                    }
                  }
                }
                  
                if(this.salesOrder['PaymentsToDelete'] != undefined){
                  if(this.salesOrder['PaymentsToDelete']){
                    for (const value of this.salesOrder['PaymentsToDelete']) {
                        value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
                        value['created_at'] = this.global.dNow();
                        value['createdBy'] = this.authService.decodeToken('username').username;  
                    }
                  }
                }
                  this.totalComponent.updateTotals();
        // por mientras
        
        //console.log('esta es la sales order')
        //console.log(this.salesOrder)
              //this.salesSerivce.updateSalesOrder(this.salesOrder['SOID'], this.salesOrder)
              // let data = {
              //   userId: '123',
              //   userName: this.authService.decodeToken('username').username,
              //   section: 'SO',
              //   action: 'Update SO',
              //   eventId: this.salesOrder['status'],
              //   device: localStorage.getItem('device'),
              // }
    
              //elimino el recovery de esta orden
              this.salesSerivce.deleteSOsAutoSaved(this.salesOrder['SOID'])
              .pipe(takeUntil(this.destroy$)) 
              .subscribe(
                (res1)=>{
              },
              (err)=>{console.log(err)
              this.changeSO = false                
              }
              )
              
              // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
              // .subscribe(a => {});
              if((this.salesOrder['avalaraActive'] != null && this.salesOrder['avalaraActive'] != undefined) && (this.storeSettings['avaTax'] == 1 || this.storeSettings['avaTax'] == true) && ((this.salesOrder['soItems']?.length == undefined ? 0 : this.salesOrder['soItems']?.length) > 0) && !(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc')) ){
                this.sendAvalaraInfo(true)
              }
        this.salesSerivce.updateSalesOrder(this.salesOrder['SOID'], this.salesOrder)
        .pipe(takeUntil(this.destroy$))    
                    .subscribe(res => {
                    clearTimeout(this.recarga)
                      
                      this.dirtyotouch = false
                      this.salesForm.markAsPristine();
                      this.soItems.forEach(soItem => this.changeStatusFromDB(soItem));
                      this.salesOrder['SONumber'] = res['SONumber'];
                      if(this.salesOrder['soItems'] != undefined || this.salesOrder['soItems'] != null){
                       this.salesOrder['soItems'].forEach(soItem => this.changeStatusFromDB(soItem));
                      }

                      if(res['POStatus'] != undefined || res['POStatus'] != null){
                        let itemsToWarn = [];
                        res['POStatus'].forEach(e => {
                          if(this.salesOrder['soItems'] != undefined || this.salesOrder['soItems'] != null){
                            this.salesOrder['soItems'].forEach(soItem => {
                              if(e?.SOItemsID == soItem?.SOItemsID && e?.status == 'Done'){
                                soItem.PO = e?.PO;
                                this.SOItemsToUpdate = this.SOItemsToUpdate.filter(el => el?.SOItemsID != soItem?.SOItemsID)
                                itemsToWarn.push(e)
                              }  
                            });
    
                            if(this.salesorderitemsMobileComponent != undefined ) this.salesorderitemsMobileComponent.copyToOriginal(itemsToWarn);
                           }
                        });
                       }
    
        
                      if(this.salesOrder['deliveryItems'] != undefined || this.salesOrder['deliveryItems'] != null){
                        this.salesOrder['deliveryItems'].forEach(soItem => this.changeStatusFromDB(soItem));
                      } 
                      if(this.salesOrder['payments'] != undefined || this.salesOrder['payments'] != null){
                        this.salesOrder['payments'].forEach(soItem => this.changeStatusFromDB(soItem));
                      }
        
                      this.salesOrderNotChanged = JSON.parse(JSON.stringify(this.salesOrder));
                      this.salesForm.markAsPristine();

                      // if(this.idparam != ''){
                      this.valueSOFormO.emit(this.salesOrder)
                      this.changeSO = false
                      this.alertify.success('Sales Order has been updated');
                      // }else{
                      //   this.alertify.success('Sales Order has been added');             
                      // }
                        //hasta que retorne la respuesta se podra volver a compara con los string ya igualados                    
                        this.router.navigate(['/salesorder/' + res['SOID']]);
                    }, error => {
                      this.changeSO = false
                          // console.log(error)
                      if(error.includes('is Sales Order has been tak')){
                        this.alertify.alert(error,
                      ()=>{
                        this.goBackToList(1)
                      });
                  }
                    });
                }
        
        
        
              
            }
            }
           
    }//fin else autosave  
    }//fin del save
    

//   saveSO(from?:any) {
//     if(from == 'autosave'){ 

//       // si no tengo parametro de editar, 
//       // para que solo funciones en el new so
//     if( this.idparam == '' || this.idparam == null || this.idparam == undefined){


//       if( this.firstautosave ){  
        
//         if(this.disclosureMobileComponent != undefined){
//           this.disclosureMobileComponent.submit()
//         }
//         // to delete this if customer let dicslosures open
//         delete this.salesOrder['disc1'];
//         delete this.salesOrder['disc2'];
//         delete this.salesOrder['disc3'];
//         delete this.salesOrder['disc4'];
        
//         //set when is auto saved
//         this.salesOrder['orderStatus'] = "autosaved" 

//         const so = Object.assign({}, this.salesOrder);
//         so['deliveryStatus'] = 'Pending'; 
//         so['created_at'] = this.global.dNow();
//         so['createdBy'] = this.authService.decodeToken('username').username;
//         so['modifiedBy'] = this.authService.decodeToken('username').username;
      
//         if (so['soItems']) {
//           for (const value of so['soItems']) {
//             value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
//             value['created_at'] = this.global.dNow();          
//             value['createdBy'] = this.authService.decodeToken('username').username;
//           }
//         }
      
//         if (so['payments']) {
//           for (const value of so['payments']) {
//             value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
//             value['created_at'] = this.global.dNow();
//             value['createdBy'] = this.authService.decodeToken('username').username;
//           }
//         }    
//         if (so['deliveryItems']) {
//           for (const value of so['deliveryItems']) {
//             value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
//             value['created_at'] = this.global.dNow();
//             value['createdBy'] = this.authService.decodeToken('username').username;
//             if (value['pretax'] === 0) {
//               value['pretax'] = null;
//             }
//           }
//         }     
        
//       this.salesSerivce.addSalesOrder(so).pipe(debounceTime(500))
//       .pipe(takeUntil(this.destroy$))   
//           .subscribe(res => {
//             this.edit = true;
//             this.soItems.forEach(soItem => this.changeStatusFromDB(soItem));
//             if(this.salesOrder['soItems'] !== undefined){
//               this.salesOrder['soItems'].forEach(soItem => this.changeStatusFromDB(soItem));            
//             }
//             if(this.salesOrder['deliveryItems'] !== undefined){
//               this.salesOrder['deliveryItems'].forEach(soItem => this.changeStatusFromDB(soItem));            
//             }
//             if(this.salesOrder['payments'] !== undefined){
//               this.salesOrder['payments'].forEach(soItem => this.changeStatusFromDB(soItem));            
//             } 
//             // console.log('save autosaved')                  

//             this.firstautosave = false
//           }, error => {
//             this.alertify.error(error);
//           });
//       }else{

//         if(this.disclosureMobileComponent != undefined){
//           this.disclosureMobileComponent.submit()
//         }
  
//         delete this.salesOrder['disc1'];
//         delete this.salesOrder['disc2'];
//         delete this.salesOrder['disc3'];
//         delete this.salesOrder['disc4'];

//         if (Array.isArray(this.salesOrder) === false) {
//           if (this.salesOrder['SOID']) {
//             let identifierBackUp = this.global.guid()
//             this.salesOrder['identifierBackUp'] = identifierBackUp; 
//             this.salesOrder['updated_at'] = this.global.dNow();
//             this.salesOrder['modifiedBy'] = this.authService.decodeToken('username').username;
            
//             if(this.SOItemsToDelete != undefined && this.SOItemsToDelete != []){
//               this.salesOrder['SOItemsToDelete'] = this.SOItemsToDelete;
//             }
  
//             if(this.DeliveryToDelete != undefined && this.DeliveryToDelete != []){
//               this.salesOrder['DeliveryToDelete'] = this.DeliveryToDelete;
//             }
  
//             if(this.PaymentsToDelete != undefined && this.PaymentsToDelete != []){
//               this.salesOrder['PaymentsToDelete'] = this.PaymentsToDelete;
//             }
  
//           if(this.salesOrder['soItems'] != undefined){  
//             for (const value of this.salesOrder['soItems']) {
//               value['identifierBackUp'] = identifierBackUp; 
//               value['updated_at'] = this.global.dNow();
//               value['modifiedBy'] = this.authService.decodeToken('username').username;
//             }
//           }
  
//           if(this.salesOrder['payments'] != undefined){ 
//             for (const value of this.salesOrder['payments']) {
//               value['identifierBackUp'] = identifierBackUp; 
//               value['updated_at'] = this.global.dNow();
//               value['modifiedBy'] = this.authService.decodeToken('username').username;
//             }
//           }
  
//           if(this.salesOrder['deliveryItems'] != undefined){ 
//             for (const value of this.salesOrder['deliveryItems']) {
//               value['identifierBackUp'] = identifierBackUp; 
//               value['updated_at'] = this.global.dNow();
//               value['modifiedBy'] = this.authService.decodeToken('username').username;
  
//               if (value['pretax'] === 0) {
//                 value['pretax'] = null;
//               }
  
//             }
//           }
//           if(this.salesOrder['SOItemsToDelete'] != undefined){
//             if(this.salesOrder['SOItemsToDelete']){
//               for (const value of this.salesOrder['SOItemsToDelete']) {
//                   value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
//                   value['created_at'] = this.global.dNow();
//                   value['createdBy'] = this.authService.decodeToken('username').username;
//               }
//             }
//           }
  
          
//           if(this.salesOrder['DeliveryToDelete'] != undefined){
//             if(this.salesOrder['DeliveryToDelete']){
//               for (const value of this.salesOrder['DeliveryToDelete']) {
//                   value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
//                   value['created_at'] = this.global.dNow();
//                   value['createdBy'] = this.authService.decodeToken('username').username;
//                   if (value['pretax'] === 0) {
//                     value['pretax'] = null;
//               }
                
//               }
//             }
//           }
            
//           if(this.salesOrder['PaymentsToDelete'] != undefined){
//             if(this.salesOrder['PaymentsToDelete']){
//               for (const value of this.salesOrder['PaymentsToDelete']) {
//                   value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
//                   value['created_at'] = this.global.dNow();
//                   value['createdBy'] = this.authService.decodeToken('username').username;  
//               }
//             }
//           }

//   this.salesSerivce.updateSalesOrder(this.salesOrder['SOID'], this.salesOrder)
//   .pipe(takeUntil(this.destroy$))    
//               .subscribe(res => {
//                 // console.log('updated autosaved')   
//                 this.soItems.forEach(soItem => this.changeStatusFromDB(soItem));
//                 if(this.salesOrder['soItems'] != undefined){
//                   this.salesOrder['soItems'].forEach(soItem => this.changeStatusFromDB(soItem));
//                 }
//                 if(this.salesOrder['deliveryItems'] != undefined){
//                 this.salesOrder['deliveryItems'].forEach(soItem => this.changeStatusFromDB(soItem));
//                 }
//                 if(this.salesOrder['payments'] != undefined){
//                 this.salesOrder['payments'].forEach(soItem => this.changeStatusFromDB(soItem));
//                 }
//                 this.salesOrderNotChanged = JSON.parse(JSON.stringify(this.salesOrder));
//               }, error => {
//                 this.alertify.error(error);
//               });
//           }
  
  
  
        
//       }
//       }
//     }

















      
//     }else{
   
//     if (this.edit === false) {
//       if(this.disclosureMobileComponent != undefined){
//         this.disclosureMobileComponent.submit()
//       }
//       // to delete this if customer let dicslosures open
//       delete this.salesOrder['disc1'];
//       delete this.salesOrder['disc2'];
//       delete this.salesOrder['disc3'];
//       delete this.salesOrder['disc4'];


//       this.salesOrder['orderStatus'] = "" 
//       const so = Object.assign({}, this.salesOrder);
//       so['deliveryStatus'] = 'Pending'; 
//       so['created_at'] = this.global.dNow();
//       so['createdBy'] = this.authService.decodeToken('username').username;
//       so['modifiedBy'] = this.authService.decodeToken('username').username;
    
//       if (so['soItems']) {
//         for (const value of so['soItems']) {
//           value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
//           value['created_at'] = this.global.dNow();          
//           value['createdBy'] = this.authService.decodeToken('username').username;
//         }
//       }
    
//       if (so['payments']) {
//         for (const value of so['payments']) {
//           value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
//           value['created_at'] = this.global.dNow();
//           value['createdBy'] = this.authService.decodeToken('username').username;
//         }
//       }    
//       if (so['deliveryItems']) {
//         for (const value of so['deliveryItems']) {
//           value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
//           value['created_at'] = this.global.dNow();
//           value['createdBy'] = this.authService.decodeToken('username').username;
//           if (value['pretax'] === 0) {
//             value['pretax'] = null;
//           }
//         }
//       }

//       let data = {
//         userId: '123',
//         userName: this.authService.decodeToken('username').username,
//         section: 'SO',
//         action: 'Save SO',
//         eventId: so['status'],
//         device: localStorage.getItem('device'),
//       }
      
//       this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
//       .subscribe(a => {});
//      // console.log('esta es la ssales order cuando se guarda');
// //console.log(so)
// //let obs = this.salesSerivce.addSalesOrder(so).pipe(debounceTime(500));
    
//       //this.salesSerivce.addSalesOrder(so)    
//     this.salesSerivce.addSalesOrder(so).pipe(debounceTime(500))
//     .pipe(takeUntil(this.destroy$))   
//         .subscribe(res => {
          
//           this.dirtyotouch = false
//           this.alertify.success('Sales Order has been added');
//           this.edit = true;
//           this.soItems.forEach(soItem => this.changeStatusFromDB(soItem));
//           if(this.salesOrder['soItems'] !== undefined){
//             this.salesOrder['soItems'].forEach(soItem => this.changeStatusFromDB(soItem));            
//           }
//           if(this.salesOrder['deliveryItems'] !== undefined){
//             this.salesOrder['deliveryItems'].forEach(soItem => this.changeStatusFromDB(soItem));            
//           }
//           if(this.salesOrder['payments'] !== undefined){
//             this.salesOrder['payments'].forEach(soItem => this.changeStatusFromDB(soItem));            
//           }   
//           this.salesForm.markAsPristine();
//           this.router.navigate(['/salesorder/' + res['SOID']]);
//         }, error => {
//           this.alertify.error(error);
//         });
//     } else {
//       if(this.disclosureMobileComponent != undefined){
//         this.disclosureMobileComponent.submit()
//       }

//       delete this.salesOrder['disc1'];
//       delete this.salesOrder['disc2'];
//       delete this.salesOrder['disc3'];
//       delete this.salesOrder['disc4'];

//       if (Array.isArray(this.salesOrder) === false) {
//         if (this.salesOrder['SOID']) {
//           let identifierBackUp = this.global.guid()
//           this.salesOrder['orderStatus'] = "" 
//           this.salesOrder['identifierBackUp'] = identifierBackUp; 
//           this.salesOrder['updated_at'] = this.global.dNow();
//           this.salesOrder['modifiedBy'] = this.authService.decodeToken('username').username;
          
//           if(this.SOItemsToDelete != undefined && this.SOItemsToDelete != []){
//             this.salesOrder['SOItemsToDelete'] = this.SOItemsToDelete;
//           }

//           if(this.DeliveryToDelete != undefined && this.DeliveryToDelete != []){
//             this.salesOrder['DeliveryToDelete'] = this.DeliveryToDelete;
//           }

//           if(this.PaymentsToDelete != undefined && this.PaymentsToDelete != []){
//             this.salesOrder['PaymentsToDelete'] = this.PaymentsToDelete;
//           }


//           if(this.salesOrder['soItems'] != undefined){ 
//           for (const value of this.salesOrder['soItems']) {
//             value['identifierBackUp'] = identifierBackUp; 
//             value['updated_at'] = this.global.dNow();
//             value['modifiedBy'] = this.authService.decodeToken('username').username;
//           }
//         }

//         if(this.salesOrder['payments'] != undefined){ 
//           for (const value of this.salesOrder['payments']) {
//             value['identifierBackUp'] = identifierBackUp; 
//             value['updated_at'] = this.global.dNow();
//             value['modifiedBy'] = this.authService.decodeToken('username').username;
//           }
//         }
        
//         if(this.salesOrder['deliveryItems'] != undefined){ 
//           for (const value of this.salesOrder['deliveryItems']) {
//             value['identifierBackUp'] = identifierBackUp; 
//             value['updated_at'] = this.global.dNow();
//             value['modifiedBy'] = this.authService.decodeToken('username').username;

//             if (value['pretax'] === 0) {
//               value['pretax'] = null;
//             }

//           }
//         }
        
//         if(this.salesOrder['SOItemsToDelete'] != undefined){
//           if(this.salesOrder['SOItemsToDelete']){
//             for (const value of this.salesOrder['SOItemsToDelete']) {
//                 value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
//                 value['created_at'] = this.global.dNow();
//                 value['createdBy'] = this.authService.decodeToken('username').username;
//             }
//           }
//         }

//         if(this.salesOrder['DeliveryToDelete'] != undefined){
//           if(this.salesOrder['DeliveryToDelete']){
//             for (const value of this.salesOrder['DeliveryToDelete']) {
//                 value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
//                 value['created_at'] = this.global.dNow();
//                 value['createdBy'] = this.authService.decodeToken('username').username;
//                 if (value['pretax'] === 0) {
//                   value['pretax'] = null;
//             }
              
//             }
//           }
//         }
          
//         if(this.salesOrder['PaymentsToDelete'] != undefined){
//           if(this.salesOrder['PaymentsToDelete']){
//             for (const value of this.salesOrder['PaymentsToDelete']) {
//                 value['identifierBackUp'] = this.salesOrder['identifierBackUp'];
//                 value['created_at'] = this.global.dNow();
//                 value['createdBy'] = this.authService.decodeToken('username').username;  
//             }
//           }
//         }
//           this.totalComponent.updateTotals();
// //console.log('esta es la sales order')
// //console.log(this.salesOrder)
//       //this.salesSerivce.updateSalesOrder(this.salesOrder['SOID'], this.salesOrder)
//       let data = {
//         userId: '123',
//         userName: this.authService.decodeToken('username').username,
//         section: 'SO',
//         action: 'Update SO',
//         eventId: this.salesOrder['status'],
//         device: localStorage.getItem('device'),
//       }
      
//       this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
//       .subscribe(a => {});
// this.salesSerivce.updateSalesOrder(this.salesOrder['SOID'], this.salesOrder)
// .pipe(takeUntil(this.destroy$))    
//             .subscribe(res => {
//               this.dirtyotouch = false
//               this.salesForm.markAsPristine();
//               if(this.idparam != ''){
//                 this.alertify.success('Sales Order has been updated');
//               }else{
//                 this.alertify.success('Sales Order has been added');             
//               }
//               this.soItems.forEach(soItem => this.changeStatusFromDB(soItem));
//               if(this.salesOrder['soItems'] !== undefined){
//                 this.salesOrder['soItems'].forEach(soItem => this.changeStatusFromDB(soItem));
//               }
//               if(this.salesOrder['deliveryItems'] !== undefined){
//                 this.salesOrder['deliveryItems'].forEach(soItem => this.changeStatusFromDB(soItem));
//               }

//               if(this.salesOrder['payments'] !== undefined){
//                 this.salesOrder['payments'].forEach(soItem => this.changeStatusFromDB(soItem));
//               }
//               this.salesOrderNotChanged = JSON.parse(JSON.stringify(this.salesOrder));
//               this.router.navigate(['/salesorder/' + res['SOID']]);
//             }, error => {
//               this.alertify.error(error);
//             });
//         }



      
//     }
//     }
   
//     }
//   }


  chooseStatus() {
    const initialState = {
      so: this.salesOrder,
      choose: this.statusClicked
    };
    this.bsModalRef = this.modalService.show(ChoosestatusComponent, { initialState, class: 'modal-sm', backdrop: true, ignoreBackdropClick: true });
  }


  editPayment() {
    if(this.salesOrder['Total'] <= 0){
      this.alertify.warning('You cannot add payment with an invalid Total')
      return
    }    
    if(this.showCalculateTaxes && (this.storeSettings['avaTax'] == 1 || this.storeSettings['avaTax'] == true)){
      if(this.edit && (this.salesOrder['avalaraActive'] == null || this.salesOrder['avalaraActive'] == undefined)){
         
      }else{
        if((this.salesOrder['soItems']?.length == undefined ? 0 : this.salesOrder['soItems']?.length) > 0 && !(this.authService.decodeToken('role').role == 'Sales Person' && (this.storeSettings['cashierStatus'] == 'vc')) ){
          this.alertify.warning('Calculing taxes...')
          this.sendAvalaraInfo(false)
          return
        }else{
          this.salesOrder['avalaraActive'] = true
        }
      }
    }

    this.showEditPayment = true










    const initialState = {
      passpayments: this.salesOrder['payments'],
      passSO: this.salesOrder,
      PaymentsToDelete: this.PaymentsToDelete,
      settings: this.storeSettings
    }; 

  }

  viewVOIDPayments(){   
    this.paymentsVOID = this.salesOrder.payments.filter((p:any)=> p.voidtype_tx != null && p.voidtype_tx != undefined);
    setTimeout(() => {
      this.showViewVoid = true
      this.showEditPayment = false
    }, 500);
  }

  areVOIDPayments(payments):any[]{    
    if(payments != null && payments != undefined){
      let areVOID = payments.filter((p:any)=> p.voidtype_tx != null && p.voidtype_tx != undefined);
      return areVOID;
    }else{
      return []
    }
  }

  editDelivery() {
    this.showEditDelivery = true
    this.deliveryMobileComponent.initData()
    // console.log(this.showEditDelivery)
    // const initialState = {
    //   passdiscounts: this.salesOrder['deliveryItems'],
    //   DeliveryToDelete: this.DeliveryToDelete,
    //   passSO: this.salesOrder,
    //   event: this.event
  // };

    // this.bsModalRef = this.modalService.show(DeliveryFormComponent, {initialState, class: 'modal-lg', backdrop: true, ignoreBackdropClick: true});
  }

  saveDelivery(scroll? : boolean){
    // console.log("execute")
    this.deliveryMobileComponent.submit();

    scroll ? this.scrollNew('delivery-container') : null
    //this.showEditDelivery = false
  }

  updateDelivery(event){
    // console.log(event)
    this.showEditDelivery = false
    
    this.deliveryMobileComponent.initData()
  }

  onOpenDic(warehouse) {
    if(warehouse == this.openWarehouse){
      this.openWarehouse = '';
    }else{
      this.openWarehouse = warehouse;
    }

  }

  savePayment(scroll? : boolean){
    if(this.showViewVoid){
      this.showViewVoid = false
      this.showEditPayment = false
      return
    }
    this.global.savePaymentFromMobile.next('savePayMobile')
    // this.showEditPayment = false
    // this.paymentMobileComponent.submit();
    // scroll ? this.scrollNew('payment-container') : null
  }

  closePaymentForm(e:any){
    this.showEditPayment = false
    this.showViewVoid = false

  }


  goBackToList(param? : number) {
    if(param == 1){
      if (this.routeService.getPreviousUrl().includes('add') || this.routeService.getPreviousUrl().includes('salesorder')) {
        this.router.navigate(['/salesorder']);
      } else if(this.sofrom == 'summary'){
        this.router.navigate(['/eventsummary/'+this.event['CID']+'/SalesOrder']);
      }else if(this.sofrom == 'customers'){
        this.router.navigate(['/customers']);     
      }else if(this.sofrom == 'stockitems'){
        this.router.navigate(['/stockitems']);     
      }else{
        this.router.navigate(['/salesorder']);        
      } 
      return;
    }
        clearTimeout(this.recarga) 
    // console.log(this.salesForm.dirty)
    if(this.salesForm.dirty && !this.show){
      this.alertify.confirm('Are you sure you want to continue? Any unsaved changes will be lost?', () => {
        
        if(this.idparam != '' && this.idparam != null && this.idparam != undefined){ 
          this.salesSerivce.deleteSOsAutoSaved(this.salesOrder['SOID'])
          .pipe(takeUntil(this.destroy$)) 
          .subscribe(
            (res)=>{                      
          },
          (err)=>{console.log(err)}
          )
        }
       
        if (this.routeService.getPreviousUrl().includes('add') || this.routeService.getPreviousUrl().includes('salesorder')) {
          this.router.navigate(['/salesorder']);
        } else if(this.sofrom == 'summary'){
          this.router.navigate(['/eventsummary/'+this.event['CID']+'/SalesOrder']);
        }else if(this.sofrom == 'customers'){
          this.router.navigate(['/customers']);     
        }else if(this.sofrom == 'stockitems'){
          this.router.navigate(['/stockitems']);     
        }else{
          this.router.navigate(['/salesorder']);        
        } 
        
       }
       ,()=>{
       }); 
    }else{

      if(this.idparam != '' && this.idparam != null && this.idparam != undefined){ 
        if(!this.show){
          this.salesSerivce.deleteSOsAutoSaved(this.salesOrder['SOID'])
          .pipe(takeUntil(this.destroy$)) 
          .subscribe(
            (res)=>{                      
          },
          (err)=>{console.log(err)}
          )
        }   
      }

      if (this.routeService.getPreviousUrl().includes('add') || this.routeService.getPreviousUrl().includes('salesorder')) {
        this.router.navigate(['/salesorder']);
      } else if(this.sofrom == 'summary'){
        this.router.navigate(['/eventsummary/'+this.event['CID']+'/SalesOrder']);
      }else if(this.sofrom == 'customers'){
        this.router.navigate(['/customers']);     
      }else if(this.sofrom == 'stockitems'){
        this.router.navigate(['/stockitems']);     
      }else{
        this.router.navigate(['/salesorder']);        
      } 
    }

  }
  addNewOrder() {
    this.router.navigate(['/salesorder/add']);
  }

  previewReport() {
    if ( this.idparam == undefined) {
      this.alertify.error('the order needs to be saved before printing')
    }else{
      if (this.salesOrder.status === 'Draft' ) {
        this.alertify.error( 'Parked Orders cannot be printed');     
      }else{
        this.openPreview();
      }
    }

      // this.alertify.error( this.edit ? 'Parked Orders cannot be printed' :'the order needs to be saved before printing')
   
    // let data = {
    //   userId: '123',
    //   userName: this.authService.decodeToken('username').username,
    //   section: 'SO',
    //   action: 'Preview SO',
    //   eventId: '*',
    //   device: localStorage.getItem('device'),
    // }
    
    // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
    // .subscribe(a => {});
  }

  onChangeWareHouse(warehouse, wh){
    // console.log(event.target , warehouse)
    this.selectedWarehouse = warehouse
    this.warehouseExtraInfo = wh
    this.salesOrder['pickup'] = warehouse
  }

  updateLastTotal(){
    this.totalComponent.lastSalesOrderTotal = this.totalComponent.salesOrderTotal
    this.totalComponent.updateTotals()
  }

  setTaxesAvalara(){

    if(this.storeSettings['avaTax'] == 0 || this.storeSettings['avaTax'] == false){
      this.alertify.warning('Avalara is disabled, calculating with regular sales order tax rate')
      this.showCalculateTaxes = false;
      return
    }

    if( this.salesOrder['deliveryRequest'] === 'Delivery' &&
    (this.salesOrder['deliveryAddress1'] === null || this.salesOrder['deliveryAddress1'] === "" 
    || this.salesOrder['deliveryCity'] === null || this.salesOrder['deliveryCity'] === "" 
    || this.salesOrder['deliveryState'] === null || this.salesOrder['deliveryState'] === "" 
    || this.salesOrder['deliveryState'] === null || this.salesOrder['deliveryState'] === "" 
    || this.salesOrder['deliveryZip'] === null || this.salesOrder['deliveryZip'] === "" )
  ){
    this.alertify.error('Please complete the customer information');
    return;
  }
  if(this.storeSettings['avaTax'] == '1' || this.storeSettings['avaTax'] == 1){
    if (this.salesOrder['eventId'] === 0 || this.salesOrder['eventId'] === undefined) {
      this.alertify.error('Select an Event');
      return;
    }

    this.sendAvalaraInfo(false)

  }else{
    // this.openPayment()
  }
  }


  refreshDiscounts(){
    this.salesOrder['soItems'].filter(soit => soit.itemType != 'discount').forEach(element => {
      let cant = this.salesOrder['soItems'].filter(item => item.noteExp == element.SOItemsID).reduce((acc, {priceNumUnit}) => acc + (priceNumUnit > 0 ? priceNumUnit : priceNumUnit*-1), 0)//.reduce((item, acc) => acc + item.priceNumUnit, 0)
      this.discountItems[element.SOItemsID] = cant
      // console.log(this.discountItems)  
    });
  }

  async sendAvalaraInfo(commit?: boolean){

    let checkItemDelivery = !!this.salesOrder['soItems'].find(item => item?.itemType != 'discount' &&  (item.itemDelivery == undefined || item.itemDelivery == null || item.itemDelivery == ""))
    if (checkItemDelivery){
      this.alertify.warning('There are items without inventory status')
      this.loadAvalaraPayment = false
      return
    }
    
    let customerToSubmit = {
      Id: (this.salesOrder['SONumber'] == null || this.salesOrder['SONumber'] == '') ? this.salesOrder['eventId'] : this.salesOrder['SONumber'] ,
      deliveryAddress1: this.salesOrder['deliveryAddress1'],
      deliveryAddress2: this.salesOrder['deliveryAddress2'],
      deliveryCity: this.salesOrder['deliveryCity'],
      deliveryState: this.salesOrder['deliveryState'],
      deliveryZip: this.salesOrder['deliveryZip'],
      entityCode : this.salesOrder['entityCode'],
    }
    this.refreshDiscounts()
    // console.log(this.discountItems)
    let addressSponsor = this.event['AddressSponsor'] != null ? JSON.parse(this.event['AddressSponsor']) : {};
      // If the sponsor address is undefined and the wh address exists
      if((addressSponsor == null || addressSponsor == undefined || Object.keys(addressSponsor).length == 0) &&
      (this.warehouseExtraInfo != null && this.warehouseExtraInfo != undefined && Object.keys(this.warehouseExtraInfo).length > 0)){
        addressSponsor = {
          line1: this.warehouseExtraInfo.line1,
          line2: this.warehouseExtraInfo.line2,
          city: this.warehouseExtraInfo.city,
          region: this.warehouseExtraInfo.region,
          country: "US",
          postalCode: this.warehouseExtraInfo.postalCode
        }
      }
    let online = await isOnline();
    if(online){                  
      // if(this.testAvalaraItems(this.salesOrder['soItems']) || commit){
        // console.log(this.warehouseExtraInfo)
        let dataItems = this.salesOrder['soItems']
        .filter(a =>  a.itemType != 'discount').map(e => {
            return {
              quantity : e.quantityNum,
              amount : (e.priceNumUnit * e.quantityNum) - this.discountItems[e.SOItemsID],
              taxCode : e.ataxcode,
              itemCode : e.SOItemsID,
              description : e.name,
              ...(e.itemDelivery == 'Taken' && { eventId: this.salesOrder['eventId'] } ),
              itemDelivery: e.itemDelivery,
              ...(this.discountItems[e.SOItemsID] > 0 && {ref1 : 'Discount of $'+this.discountItems[e.SOItemsID]}),
              ...((e.itemDelivery == 'Direct Shipment') && 
                {
                  addresses: {
                    ...((e.itemAddress != undefined && e.itemAddress == 1) && {
                      shipTo: {
                        line1: e.deliveryAddress1,
                        line2: e.deliveryAddress2,
                        city: e.deliveryCity,
                        region: e.deliveryState,
                        country: "US",
                        postalCode: e.deliveryZip
                      }
                    }),
                    ...((e.itemAddress == undefined || e.itemAddress == 0) && {
                      shipTo: {
                        line1: this.salesOrder['deliveryAddress1'],
                        line2: this.salesOrder['deliveryAddress2'],
                        city: this.salesOrder['deliveryCity'],
                        region: this.salesOrder['deliveryState'],
                        country: "US",
                        postalCode: this.salesOrder['deliveryZip']
                      }
                    }),
                    ...((this.warehouseExtraInfo != null && this.warehouseExtraInfo != undefined && Object.keys(this.warehouseExtraInfo).length > 0) && {
                      shipFrom : {
                        line1: this.warehouseExtraInfo.line1,
                        line2: this.warehouseExtraInfo.line2,
                        city: this.warehouseExtraInfo.city,
                        region: this.warehouseExtraInfo.region,
                        country: "US",
                        postalCode: this.warehouseExtraInfo.postalCode
                      }
                    } 
                    ),
                  }
                }
                ),
                ...((e.itemDelivery == 'Taken' && addressSponsor != null && addressSponsor != undefined && Object.keys(addressSponsor).length > 0) && 
                {
                  addresses: {
                      singleLocation : addressSponsor
                  }
                }
                ),
                ...((e.itemDelivery == 'NTO' || e.itemDelivery == 'In Stock') && 
                {
                  addresses: {
                    ...(this.salesOrder['deliveryRequest'] == 'Delivery' &&{
                    shipTo: {
                      line1: this.salesOrder['deliveryAddress1'],
                      line2: this.salesOrder['deliveryAddress2'],
                      city: this.salesOrder['deliveryCity'],
                      region: this.salesOrder['deliveryState'],
                      country: "US",
                      postalCode: this.salesOrder['deliveryZip'] 
                    }}),
                    ...((this.salesOrder['deliveryRequest'] == 'Delivery' 
                    && 
                    this.warehouseExtraInfo != null && this.warehouseExtraInfo != undefined && Object.keys(this.warehouseExtraInfo).length > 0) && {
                      shipFrom : {
                        line1: this.warehouseExtraInfo.line1,
                        line2: this.warehouseExtraInfo.line2,
                        city: this.warehouseExtraInfo.city,
                        region: this.warehouseExtraInfo.region,
                        country: "US",
                        postalCode: this.warehouseExtraInfo.postalCode
                      }
                    } 
                    ),
                    ...((this.salesOrder['deliveryRequest'] == 'Pickup' 
                    && 
                    this.warehouseExtraInfo != null && this.warehouseExtraInfo != undefined && Object.keys(this.warehouseExtraInfo).length > 0) && {
                      singleLocation : {
                        line1: this.warehouseExtraInfo.line1,
                        line2: this.warehouseExtraInfo.line2,
                        city: this.warehouseExtraInfo.city,
                        region: this.warehouseExtraInfo.region,
                        country: "US",
                        postalCode: this.warehouseExtraInfo.postalCode
                      }
                    } 
                    ),
                      // Just in case the delivery is pickup and there is no warehouse added
                      ...((this.salesOrder['deliveryRequest'] == 'Pickup' 
                      && 
                      (this.warehouseExtraInfo == null || this.warehouseExtraInfo == undefined)) && {
                        singleLocation : {
                          line1: this.salesOrder['deliveryAddress1'],
                          line2: this.salesOrder['deliveryAddress2'],
                          city: this.salesOrder['deliveryCity'],
                          region: this.salesOrder['deliveryState'],
                          country: "US",
                          postalCode: this.salesOrder['deliveryZip'] 
                        }
                      } 
                      )
                  }
                }
                ),
            }
        })
        if(this.salesOrder['deliveryItems'] != undefined){
          if(this.salesOrder['deliveryItems'].length > 0){
            this.salesOrder['deliveryItems'].filter(a =>  a.pretax == 1).forEach(e => {
                let item =  {
                  quantity : 1,
                  amount : e.deliveryAmountNum,
                  taxCode : e.taxCode == undefined || e.taxCode == null ? this.storeSettings['defaultFreightCode']: e.taxCode,
                  itemCode : e.deliveryId,
                  description : e.Type
                }
                dataItems.push(item)
              }
              )
          }
        }

        if(this.salesOrder['soItems'] != undefined){
          if(this.salesOrder['soItems'].length > 0){
            this.salesOrder['soItems'].filter(a => a.manufacter == "ORDER DISCOUNT").forEach(e => {
                let item =  {
                  quantity : 1,
                  amount : e.priceNumUnit,
                  taxCode : e.isItemTaxable == undefined || e.isItemTaxable == null ? 'NT': (e.isItemTaxable == true ? 'P0000000' : "NT"),
                  itemCode : e.SOItemsID,
                  description : e.manufacter
                }
                dataItems.push(item)
              }
              )
          }
        }

        let stateRequest = (this.salesOrder['status'] == 'PIF' 
        || this.salesOrder['status'] == 'Partial Payment/COD'
        || this.salesOrder['status'] == 'Layaway'
        ) ? true : false;

        let request = {

          ...( !this.edit && {date : moment().format('YYYY-MM-DD')}),
          lineItems : dataItems,
          customer: customerToSubmit,
          device: localStorage.getItem('device'),
          createdBy: this.authService.decodeToken('username').username,
          SOID: this.salesOrder['SOID'],
          commit: stateRequest
        }
        this.storeProductsService[!commit ? "queryManyLineItem" : "queryManyLineItemCommit"](request).
        subscribe(
          ((response:any)=>{
            if(response?.error?.code){
              this.loadAvalaraPayment = false
              this.alertify.warning(response?.error?.message)
              return;
            }
          if (response?.lines?.length > 0) {

            let itemsIndexed = response?.lines.reduce((acc, line) => ({ ...acc, [line.itemCode]: line}), {})
            // Items
            this.salesOrder['soItems']
            .filter(a =>  a.itemType != 'discount' || a.manufacter == "ORDER DISCOUNT")
            .forEach(item => {
              item.taxRateAvalara = itemsIndexed[item.SOItemsID].details.reduce((acc, item) => acc + item.rate, 0).toFixed(4);
              item.taxCode = itemsIndexed[item.SOItemsID].taxCode;
              item.taxCalculated = itemsIndexed[item.SOItemsID].taxCalculated;
              item.isItemTaxable = itemsIndexed[item.SOItemsID].isItemTaxable;
              item.lineAvalara = JSON.stringify(itemsIndexed[item.SOItemsID]);
            });
            if(this.salesOrder['deliveryItems'] != undefined){
              if(this.salesOrder['deliveryItems'].length > 0){
                // Delivery
                this.salesOrder['deliveryItems'].filter(a =>  a.pretax == 1).forEach(item => {
                  item.taxRateAvalara = itemsIndexed[item.deliveryId].details.reduce((acc, item) => acc + item.rate, 0).toFixed(4);
                  item.taxCode = itemsIndexed[item.deliveryId].taxCode;
                  item.isItemTaxable = itemsIndexed[item.deliveryId].isItemTaxable;
                  item.taxCalculated = itemsIndexed[item.deliveryId].taxCalculated;
                  item.lineAvalara = JSON.stringify(itemsIndexed[item.deliveryId]);
                });
              }
            }
            // console.log(this.salesOrder['soItems'], this.salesOrder['deliveryItems'])
            if(!commit){
              this.loadAvalaraPayment = false
              this.modalAvalara = this.modalService.show(this.avalaraDetailsModal, { initialState: { lines: response?.lines }, class: 'modal-lg', backdrop: true });
            }
            this.showCalculateTaxes = false
            this.lastSalesOrderTotal = this.salesOrder['Total']
            this.salesOrder['avalaraActive'] = true
            this.salesOrder['totalTaxSum'] = response?.totalTaxCalculated
            this.salesOrderNotChanged = JSON.parse(JSON.stringify(this.salesOrder));
            this.salesForm.markAsPristine();
            this.loadAvalaraPayment = false
            // console.log(this.lastSalesOrderTotal , this.salesOrder['Total'])
          }
        }), ((error: HttpErrorResponse) =>{
          this.loadAvalaraPayment = false
          // console.log(error)
          // console.error(error.message)
         
        }))
      // }else{
      //   // this.openPayment()
      // }
    }else{
      this.loadAvalaraPayment = false
      this.alertify.warning("Avalara: using sales order default tax because there is no internet connection")
    }
  }

  async openPreview() {
    let wait = await this.global.loadScript();
    if(wait){
      const initialState = {
        salesOrderMode: true,
        so: this.salesOrder,
        sponsor:this.event.sponsor,
        fromcomponent:"salesordermobile"
      };
      this.bsModalRef = this.modalService.show(InvoicereportComponent, { initialState, class: 'modal-lg', backdrop: true, ignoreBackdropClick: true });
    }
  }

  onOpenDelivery(data: boolean) {
    this.isCollapsedDelivery = data;
  }

  onOpenPayment(data: boolean) {
    if(this.salesOrder['Total'] <= 0){
      this.alertify.warning('You cannot add payment with an invalid Total')
      return
    }
    if(this.showCalculateTaxes && (this.storeSettings['avaTax'] == 1 || this.storeSettings['avaTax'] == true)){
      if(this.edit && (this.salesOrder['avalaraActive'] == null || this.salesOrder['avalaraActive'] == undefined)){
         
      }else{
        if(this.loadAvalaraPayment == false){
          this.loadAvalaraPayment = true
          this.alertify.warning('Calculing taxes...')
          this.sendAvalaraInfo(false)
        }
        return
      }
    }
    this.isCollapsedPayments = data;
  }



 async showSendEmail() {

    let online = await isOnline();

    if ( this.idparam == undefined) {
      this.alertify.error('the order needs to be saved before emailing')
    }else{
      if (this.salesOrder.status === 'Draft' ) {
        this.alertify.error( 'Parked Orders cannot be emailed');     
      }else{

        let wait = await this.global.loadScript(); 
        if(wait){      
        this.emailLoading = true;
        this.reportService.dataStream.next('');
        this.reportService.getInvoice(this.salesOrder['SOID'])
        .pipe(take(1)).
        subscribe(
          (res) => {
            if (res !== '') {
              const model = {};

              let stringTeams:any = 'All Teams  ';//2 space for the slice
          
              if(this.salesOrder['fundraiser'] != '' && this.salesOrder['fundraiser'] != null){
                stringTeams = '';
                JSON.parse(this.salesOrder['fundraiser']).forEach(element => {            
                  stringTeams = stringTeams+element.team+', ';             
                });
              }

              this.templateMeSvc.getTemplateMesSection('Email For Sales Order')
              .toPromise()
              .then((result) => {          
                
                var Obj = {
                  "<<OrderNumber>>": this.salesOrder['SONumber'],
                  "<<FirstName>>": this.salesOrder['firstName'],
                  "<<LastName>>": this.salesOrder['lastName'],
                  "<<Sponsor>>": this.event['sponsor'],
                  "<<Teams>>": stringTeams.slice(0,-2),
                  "<<Dealer>>": this.authService.decodeToken('dealername').dealername,
             
                };
                let subject:any = '';
                let msg:any = '';
    
                if (result[0].messages != '' && result[0].messages != null && result[0].messages != undefined) {
                  if(JSON.parse(result[0].messages).length > 0){              
                    JSON.parse(result[0].messages).forEach(element => {
                      if(element.title.toLowerCase() == 'subject'){
                        subject = element.content.replace(/<<OrderNumber>>|<<FirstName>>|<<LastName>>|<<Sponsor>>|<<Teams>>|<<Dealer>>/gi, function(matched){
                          return Obj[matched];
                        });
                      }else if(element.title.toLowerCase() == 'message'){
                        msg = element.content.replace(/<<OrderNumber>>|<<FirstName>>|<<LastName>>|<<Sponsor>>|<<Teams>>|<<Dealer>>/gi, function(matched){
                          return Obj[matched];
                        });
                      } 
                    });
                  }
                }

                if (this.reportService.roughSizeOfObject(res)) {

                        
                  if(subject == ''){
                    subject = 'Receipt for Order#' + this.salesOrder['SONumber'];
                  }
                  if(msg == ''){
                    msg = 'Please find attached Order#' + this.salesOrder['SONumber'];
                  }
                  

              //new array notifications
              let notifications:any = {};
              if(this.salesOrder['notifications'] != null && this.salesOrder['notifications'] != undefined && this.salesOrder['notifications'] != ""){
                notifications = JSON.parse(this.salesOrder['notifications'])

                if(JSON.parse(this.salesOrder['notifications']).emailsent != undefined){
                  if(notifications.emailsent.length == 0){
                    notifications.emailsent = [{"emailSentAt":""+(this.global.dNow())+""}];
                  }else{
                    notifications.emailsent.push({"emailSentAt":""+(this.global.dNow())+""});                 
                  }
                }else{                  
                  notifications.emailsent = [{"emailSentAt":""+(this.global.dNow())+""}];
                }     
              }else{
                notifications.emailsent =  [{"emailSentAt":""+(this.global.dNow())+""}];
              }



                  model['email'] = this.salesOrder['email'];
                  model['subject'] = subject;
                  model['message'] = msg;
                  model['SOID'] = this.salesOrder['SOID'];
                  model['notifications'] = JSON.stringify(notifications);
                  model['fromcomponent'] = "salesordermobile";
                  model['attachmentbase64'] = res;
                  model['attachmentfiletype'] = 'application/pdf';
                  model['attachmentfilename'] = 'Order ' + this.salesOrder['SONumber'] + '.pdf';
  
                  const initialState = {
                    model: model
                  };
                    if (this.modalService.getModalsCount() === 0 && model['attachmentbase64']) {
                    
                      if(online){
                        this.bsModalRef = this.modalService.show(SendemailComponent, { initialState, class: 'modal-lg', ignoreBackdropClick: true });
                        this.emailLoading = false;
                      }else{
                      if(environment.apiUrl == 'http://aedpay.api/api/'){ 
                        model['typeEmail'] = "Order";
                        model['eventId'] = this.salesOrder['eventId'];
                        model['SOID'] = this.salesOrder['SOID'];
                        
                        const initialState = {
                          model: model
                        }; 
  
                        this.emailLoading = false;        
                        this.bsModalRef = this.modalService.show(SendemailLocalComponent, {initialState, class: 'modal-lg', ignoreBackdropClick: true, backdrop : false});
                        }
                      }
  
                  }
                }

              }).catch((err) => {
                // console.log(err);
              });

            }
          });
          // let data = {
          //   userId: '123',
          //   userName: this.authService.decodeToken('username').username,
          //   section: 'SO',
          //   action: 'Send By Email from SO',
          //   eventId: '*',
          //   device: localStorage.getItem('device'),
          // }
          
          // this.userService.logTracking(data).pipe(takeUntil(this.destroy$))   
          // .subscribe(a => {});
        }
      }
  }

    
  }

  changeStatusFromDB(soItem: any) { 
    soItem['status'] = 'fromDB'; 
  }

  loadStoreProductsLocal() {
    this.storeProductsService.getStoreProducts(1,
      10000, '', ''
      , '', '')
      .pipe(takeUntil(this.destroy$))   
      .subscribe(
        (res: any) => {
          // console.log(res)
          localStorage.setItem('products', JSON.stringify(res));
        }, error => {
          this.alertify.error(error);
        });
  }
}
