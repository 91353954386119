
<div class="container">
  
  <app-closebutton></app-closebutton>
  <p class=""> Enter PIN Number
  </p>
    <div class="row">
        <div class="col-12  phone">
            <div class="row">
                <div class="col-12">
                <input type="password"  [(ngModel)]="pin" class="form-control tel" value="" />
                    <div class="num-pad row">
                      <div class="col-9">
                        <div class="row">
                    <div class="col-4" (click)="addCharacter('1')">
                        <div class="num">
                            <div class="txt">
                                1
                            </div>
                        </div>
                    </div>
                    <div class="col-4" (click)="addCharacter('2')">
                        <div class="num">
                            <div class="txt">
                                2 <span class="small">
                                    <p>
                                        ABC</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" (click)="addCharacter('3')">
                        <div class="num">
                            <div class="txt">
                                3 <span class="small">
                                    <p>
                                        DEF</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" (click)="addCharacter('4')">
                        <div class="num">
                            <div class="txt">
                                4 <span class="small">
                                    <p>
                                        GHI</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" (click)="addCharacter('5')">
                        <div class="num">
                            <div class="txt">
                                5 <span class="small">
                                    <p>
                                        JKL</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" (click)="addCharacter('6')">
                        <div class="num">
                            <div class="txt">
                                6 <span class="small">
                                    <p>
                                        MNO</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" (click)="addCharacter('7')">
                        <div class="num">
                            <div class="txt">
                                7 <span class="small">
                                    <p>
                                        PQRS</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" (click)="addCharacter('8')">
                        <div class="num">
                            <div class="txt">
                                8 <span class="small">
                                    <p>
                                        TUV</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" (click)="addCharacter('9')"> 
                        <div class="num">
                            <div class="txt">
                                9 <span class="small">
                                    <p>
                                        WXYZ</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" (click)="addCharacter('*')">
                        <div class="num">
                            <div class="txt">
                                *
                            </div>
                        </div>
                    </div>
                    <div class="col-4" (click)="addCharacter('0')">
                        <div class="num">
                            <div class="txt">
                                0 <span class="small">
                                    <p>
                                        +</p>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="col-4" (click)="addCharacter('#')">
                        <div class="num">
                            <div class="txt">
                                #
                            </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                    <div class="row">
                      <div class="col-12 " (click)="deleteCharacter()">
                        <div class="num text-dark">
                          <div class="txt">
                            <span class="small">
                                <p class="text-dark">
                                  <i class="fa fa-chevron-left"></i>
                                    Back</p>
                            </span>
                        </div>
                      </div>
                      </div>
                      <div class="col-12" (click)="clear()">
                        <div class="num text-dark">
                          <div class="txt">
                            <span class="small">
                                <p class="text-dark">
                                  <i class="fa fa-times-circle-o"></i>
                                    Clear</p>
                            </span>
                        </div>
                      </div>
                      </div>
                      <div class="col-12 " (click)="confirm()">
                        <div class="num text-success">
                          <div class="txt">
                            <span class="small">
                                <p class="text-success">
                                    Confirm</p>
                            </span>
                        </div>
                      </div>
                      </div>
                      <div class="col-12" (click)="cancel()">
                        <div class="num text-danger">
                          <div class="txt">
                            <span class="small">
                              <p class="text-black">
                                  Cancel</p>
                          </span>
                          </div>
                      </div>
                      </div>
                      
                      </div>

                 
                 
                  </div>
                 
                </div>
              </div>
        </div>
    </div>
</div>
</div>
