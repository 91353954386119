import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  UrlTree,
} from "@angular/router";
import { Observable } from "rxjs";
import { AlertifyService } from "../_services/alertify.service";
import { AuthService } from "../_services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AdminguardGuard implements CanActivate {
  role: any;
  constructor(private authService: AuthService, private alertify: AlertifyService) {
    this.role = this.authService.getRole();
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean>
    | Promise<boolean>
    | boolean
    {
    if (this.role == "Admin" || this.role == "Dealer") {
      return true;
    } else {
      this.alertify.error('You cannot enter this area');
      return false;
    }
  }
}
