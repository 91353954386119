import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { SalesOrderItemsService } from 'src/app/_services/salesorderitems.service';

@Component({
  selector: 'app-addsalesorderitem',
  templateUrl: './addsalesorderitem.component.html',
  styleUrls: ['./addsalesorderitem.component.scss']
})
export class AddsalesorderitemComponent implements OnInit {
  @Input() soItem: any;

  quantityNumTest = new FormControl('',[Validators.max(1000000),Validators.min(1)]);
  priceNumTest = new FormControl('',[Validators.max(1000000),Validators.min(0)]);
 

  item: any;
  added = false;
  constructor(  public bsModalRef: BsModalRef,
    private itemsService: SalesOrderItemsService,
    private alertify: AlertifyService
    ) { }

  ngOnInit() {
    this.soItem = this.item;
    // this.item['quantityNum'] = 1; 
    // this.item['priceNum'] = this.item['salesPriceNum'];     
    this.quantityNumTest.setValue(1); 
    this.priceNumTest.setValue(this.item['salesPriceNum']); 

    if (this.item['itemDelivery'] == null) {
    //this.item['itemDelivery'] = 'NTO';
    }
  }


  submit() {    
    if(this.quantityNumTest.valid && this.priceNumTest.valid){
      // if(){
        // console.log(this.soItem)
        // console.log(this.soItem.Stock)
        this.item['quantityNum'] = this.quantityNumTest.value;
        this.item['priceNum'] = this.priceNumTest.value;
        this.item['status'] = 'insert';
        this.bsModalRef.hide();
        // console.log('stock ityem')
        this.itemsService.raiseSoItemEvent(this.soItem);
        this.alertify.success('Item Added');
      // }else{
      //   this.alertify.error('The quantity cannot be greater than the inventory');
      // }

    }else{
    this.alertify.error('Incorrect data');
    }
  }


}
