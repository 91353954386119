
<div class="modal-body">
  <nav class="navbar mt-3" style="justify-content: flex-end;">
    <div class="form-inline input-group" style="max-width: 300px;">
      
      <div [ngClass]="inputSearchCss">
        <span class="material-icons" *ngIf="!specialcha">search</span>
        <span class="material-icons" style="color: #ffc107;" *ngIf="specialcha">filter_list_off</span>
       <input
        type="text"
        [formControl]="searchSalesOrder"
        (keydown)="onKeydown($event)"
        (input)="validateinput()"
        placeholder="Search by Order No, Bill To, Ship To, Email or Status"
      />    
         
      </div>
      <div class="input-group-append">
        <button class="input-group-text btn-outline-primary"  id="basic-addon1" (click)="search()">
          <span class="">Search</span>
        </button>
      </div>

     
      
    </div>
  </nav>
  
  <div class="w-100">
    <div class="mt-3" *ngIf="!forSummary">
      <div class="row">
        <div class="col-md-6">
          <app-event-info [searchBy]="'All'" [fromEvent]="true"></app-event-info>
        </div>
        <div class="col-md-6 mt-2 align-self-end">
          <div class="text-right">
            <span class="text-right text-secondary">
              <span *ngIf="statusFilter">Status:</span>
              {{ statusFilter }} Total:</span
            >
            <h4
              class="text-primary text-right"
              counto
              [step]="30"
              [countTo]="pagination.totalItems"
              [countFrom]="0"
              [duration]="1"
              (countoChange)="counto = $event"
            >
              {{ counto | number: "1.0" }}
            </h4>
          </div>
        </div>
      </div>
    </div>
  
    <div class="row">
      <div class="col-12">
        <div class="nav-display-options half">
          <div class="nav-display-options-filter mr-2">
            <div class="nav-display-options-filter-buttons-view btn btn-6 pl-3 pr-0 py-0" (click)="openViewByNav()" [ngClass]="{ '': !openView, 'hidden-active': openView }">
              <div class="nav-display-options-filter-buttons-view-text">
                <p>View:</p>
              </div>
              <div
                class="nav-display-options-filter-buttons-view-buttons hidden view-465"
                btnRadioGroup
                [(ngModel)]="dateFilter"
              >
                <button
                  class="btn btn-4 mr-1"
                  (click)="search()"
                  btnRadio=""
                  tabindex="0"
                  role="button"
                >
                  All
                </button>
                <button
                  class="btn btn-4 mr-1"
                  (click)="search()"
                  btnRadio="today"
                  tabindex="0"
                  role="button"
                >
                  Today
                </button>
                <button
                  class="btn btn-4 mr-1"
                  (click)="search()"
                  btnRadio="week"
                  tabindex="0"
                  role="button"
                >
                  This Week
                </button>
                <button
                  class="btn btn-4 mr-1"
                  (click)="search()"
                  btnRadio="month"
                  tabindex="0"
                  role="button"
                >
                  This Month
                </button>
                <button
                  class="btn btn-4 mr-1"
                  (click)="search()"
                  btnRadio="year"
                  tabindex="0"
                  role="button"
                >
                  This Year
                </button>
              </div>
            </div>
          </div>
          <div class="nav-display-options-filter">
            <div class="nav-display-options-filter-buttons-view btn btn-6 pl-3 pr-0 py-0" 
            (click)="openStatusNav()" [ngClass]="{ '': !openStatus, 'hidden-active': openStatus }">
              <div class="nav-display-options-filter-buttons-view-text">
                <p>Status:</p>
              </div>
              <div
                class="nav-display-options-filter-buttons-view-buttons hidden status-510"
                btnRadioGroup
                [(ngModel)]="statusFilter"
              >
                <button
                  class="btn btn-4 mr-1"
                  (click)="search()"
                  btnRadio=""
                  tabindex="0"
                  role="button"
                >
                  All
                </button>
                <button
                  class="btn btn-4 mr-1"
                  (click)="search()"
                  btnRadio="PIF"
                  tabindex="0"
                  role="button"
                >
                  PIF
                </button>
                <button
                  class="btn btn-4 mr-1"
                  (click)="search()"
                  btnRadio="Layaway"
                  tabindex="0"
                  role="button"
                >
                  Layaway
                </button>
                <button
                  class="btn btn-4 mr-1"
                  (click)="search()"
                  btnRadio="Partial"
                  tabindex="0"
                  role="button"
                >
                  Partial Payment
                </button>
                <button
                  class="btn btn-4 mr-1"
                  (click)="search()"
                  btnRadio="Order"
                  tabindex="0"
                  role="button"
                >
                  Estimate
                </button>
                <button
                  class="btn btn-4 mr-1"
                  (click)="search()"
                  btnRadio="Draft"
                  tabindex="0"
                  role="button"
                >
                  Parked
                </button>
              </div>
            </div>
          </div>

<!-- 
          <button class="btn btn-3 ml-2 mt-1" (click)="search()" btnRadio="VOID" tabindex="0" [(ngModel)]="statusFilter" role="button">VOID</button> -->

          <div class="nav-display-options-filter">
            <div class="nav-display-options-filter-buttons-view formatoggle ml-2" 
>
              <div class="nav-display-options-filter-buttons-view-text">
                <p>View Voided SO:</p>
              </div>
              <div
                class="nav-display-options-filter-buttons-view-buttons hidden status-510 statexcluded"
               
              >

  
              </div>
              
              <label class="switch">
                <input type="checkbox" [(ngModel)]="VOIDSalesOrders" (ngModelChange)="loadSalesOrder(0)" >
                <span class="slider round"></span>
              </label>
            </div>
          </div>


          <div class="nav-display-options-filter">
            <div class="nav-display-options-filter-buttons-view formatoggle ml-2" 
            >
              <div class="nav-display-options-filter-buttons-view-text">
                <p>View Excluded SOs:</p>
              </div>
              <div
                class="nav-display-options-filter-buttons-view-buttons hidden status-510 statexcluded"
               
              >

  
              </div>
              
              <label class="switch">
                <input type="checkbox" [(ngModel)]="excludedFilter" (ngModelChange)="loadSalesOrder(1)" >
                <span class="slider round"></span>
              </label>
            </div>
          </div>


        </div>
      </div>
    </div>
  </div>
  
  <div class="table-responsive mt-3">
    <table *ngIf="salesorders.length > 0" id="salestable" class="table table-light table-custom" cellspacing="0"    >
      <thead class="table-custom-header">
        <tr btnRadioGroup [(ngModel)]="orderField">
          
          <th></th>
          
          <th class="tw-cursor-pointer" uncheckable (click)="changeDirection('SONumber');search()" btnRadio="SONumber" tabindex="0" role="button">
            Order No.
            <label class="m-0">
              <i *ngIf="orderDir == '' && orderField == 'SONumber'" class="fa fa-arrow-up m-0" ></i>
              <i *ngIf="orderDir == 'desc' && orderField == 'SONumber'"class="fa fa-arrow-down m-0"></i>
            </label>
          </th>

          <th class="tw-cursor-pointer" uncheckable (click)="changeDirection('dbDatets');search()" btnRadio="dbDatets" tabindex="0" role="button">
            Date
            <label class="m-0">
              <i *ngIf="orderDir == '' && orderField == 'dbDatets'" class="fa fa-arrow-up m-0"></i>
              <i *ngIf="orderDir == 'desc' && orderField == 'dbDatets'" class="fa fa-arrow-down m-0" ></i>
            </label>
          </th>

          <th *ngIf="forSummary" class="tw-cursor-pointer" uncheckable (click)="changeDirection('deliveryStatus');search()" btnRadio="'deliveryStatus'" tabindex="0" role="button">
          Delivery status
          <label class="m-0">
            <i *ngIf="orderDir == '' && orderField == 'deliveryStatus'" class="fa fa-arrow-up m-0" ></i>
            <i *ngIf="orderDir == 'desc' && orderField == 'deliveryStatus'" class="fa fa-arrow-down m-0"></i>
          </label>
          </th>

          <th class="tw-cursor-pointer" (click)=" changeDirection('firstName');search() " btnRadio="CONCAT(firstName, ' ',lastName)" tabindex="0" role="button" >
            Bill To
            <label class="m-0">
              <i *ngIf=" orderDir == '' && orderField == 'CONCAT(firstName, \' \',lastName)' " class="fa fa-arrow-up m-0" ></i>
              <i *ngIf=" orderDir == 'desc' && orderField == 'CONCAT(firstName, \' \',lastName)' " class="fa fa-arrow-down m-0" ></i> 
            </label>
          </th>

          <th class="text-right">Item Sold</th>

          <th class="text-right">
            <div style="min-width: max-content;">Sales Price<span *ngIf="userRole != 'Manager'"> /</span></div>
            <span *ngIf="userRole != 'Manager'">WholeSale</span>  
          </th>

          <th class="text-right">Tax</th> 
          <th class="text-right">
            <div style="min-width: max-content;">Delivery and</div>
            <div style="min-width: max-content;">other charges</div>
          </th>
          <th class="text-right">Paid</th>
          <th class="text-right">Balance</th>
          <th class="text-right">
            <div style="min-width: max-content;">Order</div>
            <div style="min-width: max-content;">set for</div>             
          </th>
          <th>
            <div style="min-width: max-content;">Exclude</div>
            <div style="min-width: max-content;">from PO</div> 
          </th>
          <th></th>   
        </tr>
      </thead>

      <tbody *ngFor="let salesorder of salesorders">
        <tr class="m-1 p-1"  [ngClass]="{'tw-border-t-2 tw-border-l-2 tw-border-r-2 tw-border-aedorangel tw-rounded-md': soNumber == salesorder.SONumber && salesorder.status != 'Draft'}">
          <td colspan="1" style="padding-left: .5rem;" class="tdeventlist tw-cursor-pointer">
            <span *ngIf="salesorder?.SOID == SOIDSelected && salesorder?.items?.length > 0" (click)="showSalesOrders('', [])" class="material-icons md-24 tw-w-5 tw-h-5">expand_more</span>
            <span *ngIf="salesorder?.SOID != SOIDSelected && salesorder?.items?.length > 0" (click)="showSalesOrders(salesorder?.SOID , salesorder?.items )" class="material-icons md-24">chevron_right</span>
          </td>
          <td>{{ salesorder.SONumber }}</td>
          <td>
            <div *ngIf="editingDate?.SOID != salesorder.SOID" class="tw-flex">
              <span class="tw-my-auto">{{ salesorder.dbDatets | date: "short" }}</span> 
              <button
                *ngIf="salesorder.status != 'VOID'"
                [disabled]="salesorder.status==='VOID'"
                (click)="editDateSO(salesorder)"
                tooltip="Edit Sales Orders Date"
                class="btn btn-blue mr-3"
              >
                <span class="material-icons"> edit </span>
              </button>
            </div>

            <div class="tw-flex" *ngIf="editingDate?.SOID == salesorder.SOID" >
              <input class="tw-my-auto checkboxalign form-control " type="date" [value]="salesorder.dbDatets" [(ngModel)]="salesorder.dbDatets">
              <button
                *ngIf="salesorder.status != 'VOID'"
                [disabled]="salesorder.status==='VOID'"
                (click)="saveDateSO(salesorder)"
                tooltip="Save Sales Orders Date"
                class="btn btn-blue mr-1"
              >
                <span class="material-icons"> save </span>
              </button>
              <button
              *ngIf="salesorder.status != 'VOID'"
              [disabled]="salesorder.status==='VOID'"
              (click)="editingDate = null"
              tooltip="Cancel"
              class="btn btn-blue"
            >
              <span class="material-icons"> cancel </span>
            </button>
            </div> 
           <span class="tw-mt-1"> {{salesorder?.status?.trim() == 'Draft' ? 'Parked' : (salesorder?.status=='Order'?'Estimate':salesorder?.status)}}</span>
          </td>
          <td *ngIf="forSummary">
            <select
              class="form-control d-inline form-control-sm pull-right"
              [(ngModel)]="salesorder.deliveryStatus"
              (ngModelChange)="changeDeliveryStatus(salesorder)"
              placeholder="Delivery"
            >
              <option>{{ "Pending" }}</option>
              <option>{{ "Delivered" }}</option>
            </select>
          </td>
          <td>
            {{ salesorder.firstName }} {{ salesorder.middleInitial }}
            {{ salesorder.lastName }}
            <br />
            {{ salesorder.email }}
          </td>
          <td class="text-right">{{ salesorder.ItemSold | number: "1.0" }}</td>
          <td class="text-right" >            
            <span>{{ salesorder.SalesPrice |currency }}</span><span *ngIf="userRole != 'Manager'"> /</span>
            <br>
            <span >{{ salesorder.WholeSale | currency }}</span> 
          </td>       
          <td class="text-right">{{ salesorder.Tax | currency }}</td>
          <td class="text-right">{{ salesorder.AdditionalCharges | currency }}</td>
          <td class="text-right">{{ salesorder.Payments | currency }}</td>
          <td class="text-right">{{ salesorder.Balance | currency }}</td>
          <td class="text-right">{{ salesorder?.deliveryRequest }}</td>

          <td>
            <input class="checkboxalign form-control " type="checkbox" [(ngModel)]="salesorder.excludeFromPoB"
            (ngModelChange)="changeExcludeFromPO(salesorder)"
            >
          </td>

       

          <!-- show this if it is different from iPad  -->
          <td *ngIf="!isIOS" >
            <div class="d-flex justify-content-end">

              <button
              *ngIf="salesorder.status != 'VOID'"
              [disabled]="salesorder.status==='VOID'"
              (click)="selectSalesOrder(salesorder)"
              tooltip="Edit Sales Orders"
              class="btn btn-blue mr-3"
            >
              <span class="material-icons"> edit </span>
            </button>
              <button
                (click)="showSalesOrder(salesorder)"
                tooltip="View Sales Orders"
                class="btn btn-blue mr-1"
              >
                <span class="material-icons"> visibility </span>
              </button>

              <button
              *ngIf="salesorder.status != 'VOID'"
              [disabled]="salesorder.status==='Draft' || salesorder.status==='VOID'"
                (click)="previewReport(salesorder)"
                class="btn btn-blue mr-2 ml-2"
              >
                <span class="material-icons">
                  print
                </span>
              </button>
              <button
                *ngIf="forSummary && salesorder.status != 'VOID'"
                (click)="deleteSalesOrder(salesorder)"
                class="btn btn-delete"
              >
                <span class="material-icons">
                  delete
                </span>
              </button>
              <button
              *ngIf=" salesorder.status == 'VOID'"
              (click)="revertStatus(salesorder)"
              tooltip="Revert VOID to previous status"
              class="btn btn-blue"
            >
              <span class="material-icons"> undo </span>
            </button>
            </div>
          </td>

          <!-- show this if it is iPad  -->
          <td *ngIf="isIOS">
            <div class="d-flex justify-content-end">

              <button
              *ngIf="salesorder.status != 'VOID'"
              [disabled]="salesorder.status==='VOID'"
              (click)="selectSalesOrder(salesorder)"
              class="btn btn-blue mr-3"
            >
              <span class="material-icons"> edit </span>
            </button>
              <button
                (click)="showSalesOrder(salesorder)"
                class="btn btn-blue mr-1"
              >
                <span class="material-icons"> visibility </span>
              </button>

              <button
              *ngIf="salesorder.status != 'VOID'"
              [disabled]="salesorder.status==='Draft' || salesorder.status==='VOID'"
                (click)="previewReport(salesorder)"
                class="btn btn-blue mr-2 ml-2"
              >
                <span class="material-icons">
                  print
                </span>
              </button>
              <button
                *ngIf="forSummary && salesorder.status != 'VOID'"
                (click)="deleteSalesOrder(salesorder)"
                class="btn btn-delete"
              >
                <span class="material-icons">
                  delete
                </span>
              </button>
              <button
              *ngIf=" salesorder.status == 'VOID'"
              (click)="revertStatus(salesorder)"
              class="btn btn-blue"
            >
              <span class="material-icons"> undo </span>
            </button>
            </div>
          </td>
          
        </tr>

        <tr [ngClass]="{'tw-border-b-2 tw-border-l-2 tw-border-r-2 tw-border-aedorangel tw-rounded-md': soNumber == salesorder.SONumber}" *ngIf="salesorder?.SOID == SOIDSelected" >
          <td colspan="1"></td>
          <td class="" colspan="13">
            <div class="tw-mb-2 tw-bg-white tw-p-2 tw-rounded-lg tw-mr-2 " *ngFor="let item of SOItemsToShow">
              <div class="tw-flex tw-items-center"> 
                <div class="tw-w-5/12 tw-flex tw-flex-wrap">
                 {{item?.manufacter}} |
                 {{item?.styleName?.length > 24 ? item?.styleName.slice(0,24)+'...' : item?.styleName}} |
                 {{item?.itemDelivery}} | 
                 {{item?.Type }} | 
                 {{item?.sizeName?.length > 10 ? item?.sizeName.slice(0,9)+'...' : item?.sizeName}} | 
                 {{item?.quantityNum}}  
                </div>
                <div class="tw-ml-2">
                  <select
                  [disabled]="item?.itemDelivery == 'Taken'"
                  class="form-control d-inline form-control-sm tw-flex"
                  [(ngModel)]="item.tobeDelivered"
                  (ngModelChange)="changeDeliveryStatusItem(item)"
                  placeholder="Delivery"
                  >
                    <option>{{ "Pending" }}</option>
                    <option>{{ "Delivered" }}</option>
                  </select>              
                </div>
                <div class="tw-ml-2" *ngIf="item?.itemDelivery == 'Direct Shipment' || item?.itemDelivery == 'NTO'">
                  <span class="tw-font-bold">PO # </span> {{item?.POUUID == "no" ? "No converted" : item?.POUUID}}
                  <span class="tw-font-bold tw-ml-4">DDS PO # </span> {{item?.confirmationPOID.slice(5) == "no" ? "No Upload" : item?.confirmationPOID.slice(5)}}
                </div>

                <div *ngIf="!(item?.itemDelivery == 'Taken' || item?.itemDelivery == 'In Stock')"  class="ml-auto">  
                  <div class="tw-flex">
                    <div class="tw-ml-auto tw-mr-2"  *ngIf="editTracking != item?.SOItemsID">
                      <button (click)="editTracking = item?.SOItemsID;formTrackingNumber.setValue(item?.trackingNumber);formTrackingDate.setValue(item?.trackingDate)" 
                      class="btn btn-blue tw-mt-auto">   
                        <span class="material-icons">
                          edit
                        </span>
                      </button> 
                    </div>

                    <div class="tw-flex tw-ml-auto"  *ngIf="editTracking == item?.SOItemsID">
                      <button class="btn btn-blue tw-mt-auto" (click)="updateTrackingnumber(salesorder?.SOID,item?.SOItemsID)">   
                        <span class="material-icons">
                          save
                        </span>
                      </button>

                      <button class="btn btn-blue mx-2 tw-mt-auto" (click)="editTracking = ''" >   
                        <span class="material-icons">
                          cancel
                        </span>
                      </button> 
                    </div>
                    
                      <div class="tw-flex">                        
                        <div class="tw-flex"> <span class="tw-my-auto">Tracking #: </span></div>
                        <div class="tw-w-24 tw-pl-1 tw-ml-1 tw-flex" style="border: 1px solid rgb(255, 255, 255);border-radius: 5px;"  *ngIf="editTracking != item?.SOItemsID"><span class="tw-my-auto">{{item?.trackingNumber}}</span></div>
                        <input class="tw-w-24 tw-pl-1 tw-ml-1" style="border: 1px solid rgb(173, 170, 170);border-radius: 5px;" *ngIf="editTracking == item?.SOItemsID" type="text" [formControl]="formTrackingNumber">       
                        
                        <div class="tw-flex"> <span class="tw-ml-2 tw-my-auto">DDate: </span></div>
                        <div class="tw-w-24 tw-pl-1 tw-ml-1 tw-flex" style="border: 1px solid rgb(255, 255, 255);border-radius: 5px;"  *ngIf="editTracking != item?.SOItemsID"><span class="tw-my-auto">{{item?.trackingDate | date:'MM/dd/YYYY'}}</span></div>
                        <input class="tw-w-24 tw-pl-1 tw-ml-1" style="border: 1px solid rgb(173, 170, 170);border-radius: 5px;" *ngIf="editTracking == item?.SOItemsID" type="date" [formControl]="formTrackingDate">                            
                      </div>

                  </div>
                </div>

              </div>                  
            </div>
          </td>  
        </tr>

      </tbody>

      <div *ngIf="salesorders.length === 0">
        No Sales Order Found
      </div>
    </table>
  </div>
  <div class="col-12">
    <div class="pagination-container">
      <pagination
        *ngIf="showpagination"
        [disabled]="paginationMode"
        [boundaryLinks]="true"
        [totalItems]="pagination.totalItems"
        [(ngModel)]="pagination.currentPage"
        [itemsPerPage]="pagination.itemsPerPage"
        (pageChanged)="pageChanged($event)"
        [maxSize]="10"
        previousText="&lsaquo;"
        nextText="&rsaquo;"
        firstText="&laquo;"
        lastText="&raquo;"
      >
      </pagination>
    </div>
  </div>
  
</div>