import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { GlobalsService } from 'src/app/_services/globals.service';
import { StockItemsService } from 'src/app/_services/stockitems.service';

@Component({
  selector: 'app-po-history',
  templateUrl: './po-history.component.html',
  styleUrls: ['./po-history.component.scss']
})
export class PoHistoryComponent implements OnInit {
  
  destroy$: Subject<boolean> = new Subject<boolean>();

	@Output() hideHistory:any = new EventEmitter();
  stockItemsPO:any = []
  p2:any = 1	
  p:any = 1	
  pagination: any;
  recordList:any = []
  recordListItems:any = []

  listSelected:any = '';


  constructor(
		private stockItemsService: StockItemsService,
		private alertify: AlertifyService,
		private globals: GlobalsService,
		private authServices:AuthService,
	) {}

  ngOnInit(): void {
    this.p2 = 1
    this.p = 1
    this.pagination = {
      totalItems: 10,
      totalPages: 10,
      currentPage: 1,
      itemsPerPage: 10,
	  };

		this.loadHistories();

  }
	pageChanged(event:any){
		this.p2 = event.page
		this.loadHistories()
	}

  SeeItemsHistory(e){
    this.listSelected = e.groupCode
    this.stockItemsService.getHistoryListsItems({groupCode:e.groupCode,dbServerC:this.authServices.decodeToken('dbServerC').dbServerC})
		.pipe(takeUntil(this.destroy$))
		.subscribe(
			(res: any) => {				
        this.p = 1
				this.recordListItems = res			
			},
			(error) => {
				this.alertify.error(error);
			}
		);
  }

  loadHistories(){
		this.stockItemsService.getHistoryLists({page:this.p2,itemsPerPage:this.pagination.itemsPerPage,
      dbServerC:this.authServices.decodeToken('dbServerC').dbServerC,
      ROID:this.authServices.decodeToken('DID').DID
    })
		.pipe(takeUntil(this.destroy$))
		.subscribe(
			(res: any) => {				
				this.recordList = res.data 
				this.pagination.totalPages = res.last_page;
				this.pagination.totalItems = res.total;				
			},
			(error) => {
				this.alertify.error(error);
			}
		);
  }


  hideHistoryPO(){
    this.hideHistory.emit(false)
  }

}
