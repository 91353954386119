import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs/Observable';
import { publishReplay, refCount } from 'rxjs/operators';
import { map } from 'rxjs/operators/map';
import { environment } from '../../environments/environment';
import { Customer } from '../_modules/customers';
import { PaginatedResult } from '../_modules/pagination';


@Injectable({
  providedIn: 'root'
})
export class CustomerService {
baseUrl = environment.apiUrl;
laravel = environment.apiLaravel;
salesOrderCustomer = new BehaviorSubject<any>(0);
reloadCustomerList = new BehaviorSubject<any>('');
nameCustomer = new BehaviorSubject<any>('');
customerComment = new BehaviorSubject<string>('');
changeCustomer = new BehaviorSubject<any>({customerId: '0', event: 'first'});
editCustomer = new BehaviorSubject<any>(0);
customerEdited = new BehaviorSubject<any>(0);
showcustomermobile = new BehaviorSubject<any>('');

constructor(private http: HttpClient) { }


getCustomers(page?, itemsPerPage?, userParams?, likesParam?): Observable<PaginatedResult<Customer[]>> {
  const paginatedResult: PaginatedResult<Customer []> = new PaginatedResult<Customer []>();

  let params = new HttpParams();

  if ( page != null && itemsPerPage != null) {
    params = params.append('pageNumber', page);
    params = params.append('pageSize', itemsPerPage);
  }

  if (userParams != null) {
    params = params.append('orderBy', userParams.orderBy);
  }

    return this.http.get<Customer[]>(this.baseUrl + 'customers', {observe: 'response', params})
    .pipe(
      map( response => {



        paginatedResult.result = response.body;
        if (response.headers.get('Pagination') != null) {
          paginatedResult.pagination = JSON.parse(response.headers.get('Pagination'));
        }
        return paginatedResult;
      }

      )

    );
   


}

addCustomer(customer: Customer) {
  return this.http.post(this.laravel + 'customers', customer);
}

updateCustomer(id: any, customer: Customer) {
  return this.http.patch(this.laravel + 'customers/' + id, customer);
}

deleteCustomer(id: any) {
  return this.http.delete(this.laravel + 'customers/' + id);
}

selectedCustomer(id: any) {
  this.salesOrderCustomer.next(id);
}

changeC(id: any) {
  this.changeCustomer.next(id);
}

editedCustomer(id: any) {
  this.editCustomer.next(id);
}

getTotalCustomers() {
  let result = 0;
  return this.http.get<any>(this.laravel + 'customerstotal')
  .pipe(
    map( response => {
      result = (response);
      return result;
    })
  );
}

getCustomersLar(page?, itemsPerPage?, searchCustomer?, searchEvent?,from?) {
  let params = new HttpParams();
  const paginatedResult: PaginatedResult<any> = new PaginatedResult<any>();

  if ( page != null && itemsPerPage != null) {
    params = params.append('page', page);
    params = params.append('per_page', itemsPerPage);

  }

  if (searchEvent != null) {
    params = params.append('searchEvent', searchEvent);
  }

  if (searchCustomer != null) {
    params = params.append('searchCustomer', searchCustomer);
  }

  if (from != null) {
    params = params.append('from', from);
  }

  return this.http.get<any>(this.laravel + 'customers', {observe: 'response', params})
  .pipe(
    map( response => {
      paginatedResult.result = (response.body.data);
      if (response.body.total != null) {
        paginatedResult.pagination = {
          totalItems: JSON.parse(response.body.total),
          totalPages: JSON.parse(response.body.last_page),
          currentPage: JSON.parse(response.body.current_page),
          itemsPerPage: JSON.parse(response.body.per_page)
        };
      }
      return paginatedResult;
    })
  );
  }


  getCustomersByEvents(page?, itemsPerPage?, searchCustomer?, searchEvent?) {
    let params = new HttpParams();
    const paginatedResult: PaginatedResult<any> = new PaginatedResult<any>();
  
    if ( page != null && itemsPerPage != null) {
      params = params.append('page', page);
      params = params.append('per_page', itemsPerPage);
  
    }
  
    if (searchEvent != null) {
      params = params.append('searchEvent', searchEvent);
    }
  
    if (searchCustomer != null) {
      params = params.append('searchCustomer', searchCustomer);
    }
  
    return this.http.get<any>(this.laravel + 'getCustomersByEvents', {observe: 'response', params})
    .pipe(
      map( response => {
        paginatedResult.result = (response.body.data);
        if (response.body.total != null) {
          paginatedResult.pagination = {
            totalItems: JSON.parse(response.body.total),
            totalPages: JSON.parse(response.body.last_page),
            currentPage: JSON.parse(response.body.current_page),
            itemsPerPage: JSON.parse(response.body.per_page)
          };
        }
        return paginatedResult;
      })
    );
    }


  getCustomer(id:any): Observable<Customer> {
    return this.http.get<Customer>(this.laravel + 'customers/' + id);
  }

  getCustomerSpecific(id:any, fields: any){
    return this.http.post(this.laravel + 'getCustomerSpecific/' + id, fields);
  }

}
