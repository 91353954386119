import { Component, Input, OnInit } from '@angular/core';
import moment, { now } from 'moment';
import { AuthService } from 'src/app/_services/auth.service';
interface Comments {
  content: string;
  timestamp?: string;
  user?: string;
  userId?: string;
}


@Component({
  selector: 'app-customer-comments',
  templateUrl: './customer-comments.component.html',
  styleUrls: ['./customer-comments.component.scss']
})
export class CustomerCommentsComponent implements OnInit {
  @Input() customer: any;
  comments: Comments[] = [];
  comment: string = '';

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    // console.log(this.customer.comments)
    // console.log(this.isJson(this.customer))

    if(this.customer.comments != null && this.customer.comments != ''){
      if(this.isJson(this.customer.comments)){
        this.comments = JSON.parse(this.customer.comments)
      }else{
        this.comments.push({
          content: this.customer.comments, 
          user: JSON.parse(this.authService.decodeToken('user').user).name || '',
          userId: this.avatara(JSON.parse(this.authService.decodeToken('user').user).name),
          timestamp: moment().format('YYYY-MM-DD h:mm:ss')
        })
      }
    }
    
  }

  addComment(){
    this.comments.push({
      content: this.comment, 
      user: JSON.parse(this.authService.decodeToken('user').user).name || '',
      userId: this.avatara(JSON.parse(this.authService.decodeToken('user').user).name),
      timestamp: moment().format('YYYY-MM-DD h:mm:ss')
    })
    this.comment = ''
  }

  avatara(username){
    let numwords:any = username.split(" ");
    let word = '';
    if(numwords.length > 0){
      if(numwords.length > 1){
        word = numwords[0].slice(0,1) + numwords[1].slice(0,1);
      }else{
        word = numwords[0].slice(0,2)
      }
    }
    return word;
  }

  isJson(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

}
