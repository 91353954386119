import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig, FormlyFormOptions } from '@ngx-formly/core';
import { join } from 'path';
import { element } from 'protractor';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { environment } from '../../../environments/environment';
import { TemplatemessagesService } from 'src/app/_services/templatemessages.service';
import { AuthService } from 'src/app/_services/auth.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PdfViewerComponent } from '../pdf-viewer/pdf-viewer.component';

@Component({
  selector: 'app-template-messages',
  templateUrl: './template-messages.component.html',
  styleUrls: ['./template-messages.component.scss']
})
export class TemplateMessagesComponent implements OnInit {

  allinfo:any = []
  pdfs:any = []
  // pdfUrl:any = 'https://vadimdez.github.io/ng2-pdf-viewer/assets/pdf-test.pdf';
  pdfUrl:any = '';
  @ViewChild('inputFilPDF')  inputFilPDF: ElementRef | any;

  idcomponent:any = '';

  // messsagesSection:any = [];
  section = '';
  fields:any = [];
  
  // form = new FormGroup({});
  // options: FormlyFormOptions = {};
  // model: any = {};
  // fieldsform: FormlyFieldConfig[] = [
  //   {
  //     wrappers: ['formwrap'],
  //     templateOptions: { label: '' },
  //     fieldGroupClassName: 'row',
  //     fieldGroup: [
     
  //     ]
  //   },
  // ];

  constructor(
    private templateMeSvc:TemplatemessagesService, 
    private alertify: AlertifyService,
    private authService: AuthService,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    public bsModalRef: BsModalRef,
    private modalService: BsModalService,
    
    ) { }

  ngOnInit(): void {
    this.templateMeSvc.getTemplateMessages()
    .toPromise()
    .then((res:any)=>{      
      this.allinfo = res[0]          
      this.pdfs = res[1]          
      this.changeSection(res[0][0].section);
    })
    .catch((err:any)=>{console.log(err)});
  }

  changeSection(section:any){

    this.section = section
    let rowsection = this.allinfo.filter((x:any)=>x.section == section);

    let html = ''
    let mess = JSON.parse(rowsection[0].messages)
    mess.forEach(element => {
      html += '<div class="tw-mt-4 tw-text-sm">'+element.title+'</div>'
      if(element.component == 'input'){
        html += `<div><`+element.component+` id="`+element.id+`" class="tw-border-2 tw-w-full form-control"  value="`+element.content+`"/></div>`;    
      }else{
        html += `<div><`+element.component+` id="`+element.id+`" class="tw-border-2 tw-w-full form-control" rows="10" >`+element.content+`</`+element.component+`></div>`;   
      }
    });
    var fortem = (document.getElementById('formtemplatemesages') as HTMLInputElement);
    fortem.innerHTML = html;

    mess.forEach(element => {
      if (element.component == 'input') {
       (document.getElementById(element.id) as HTMLInputElement).addEventListener("click",()=>{this.idcomponent = element.id} );         
       (document.getElementById(element.id) as HTMLInputElement).addEventListener("input",()=>{this.idcomponent = element.id} );         
      }else{
        (document.getElementById(element.id) as HTMLTextAreaElement).addEventListener("click", ()=>{this.idcomponent = element.id});    
        (document.getElementById(element.id) as HTMLTextAreaElement).addEventListener("input", ()=>{this.idcomponent = element.id});    
      }
    });

    this.fields = JSON.parse(rowsection[0].fields);
  }

  putword(field:string){
    if (field != 'Default') {
      if (this.idcomponent != '') {  
        var input = (document.getElementById(this.idcomponent) as HTMLInputElement);      
        if(input != null){
          var cursor = input.selectionStart
          let string = input.value
          let text1 = string.slice(0,cursor)
          let text2 = string.slice(cursor,string.length)
          let text1revert = ((text1.split("")).reverse()).join("")
          
  
          let menorq = 0;
          let mayorq = 0;
          for (let i = 0; i < text2.length; i++) {          
            if (text2[i] == '<') {
              menorq++;
            }else if (text2[i] == '>') {
              if (menorq < 2) {
                this.alertify.error("can't be inserted into a variable")
                return;             
              }
            }
          }
  
          mayorq = 0
          menorq = 0
          for (let i = 0; i < text1revert.length; i++) {          
            if (text1revert[i] == '>') {
              mayorq++;
            }else if (text1revert[i] == '<') {
              if (mayorq < 2) {
                this.alertify.error("can't be inserted into a variable")
                return;             
              }
            }
          }
  
          input.value = input.value.substr(0,cursor)+ '<<'+field+'>>' +input.value.substr(cursor , input.value.length); 
          input.selectionStart = (cursor+field.length + 4);
          input.focus()      
          input.setSelectionRange((cursor+field.length + 4),(cursor+field.length + 4))
        }
  
      }
    } else {
        let sectionSelected:any = this.allinfo.filter((x:any)=>x.section == this.section);      
        let arraymessages = JSON.parse(sectionSelected[0].messages);
        let defaultMessage = JSON.parse(sectionSelected[0].defaultMessage); 
      
        // set the defaults
        let havemessa =0;
        arraymessages.forEach(element => {
            if((document.getElementById(element.id) as HTMLInputElement).value != ""){
              havemessa++;
            }
        });
      
        //if have message theres confirmation
        if(havemessa > 0){
          this.alertify.confirm("Are you sure to override the default messages?",()=>{
            arraymessages.forEach(element => {
                (document.getElementById(element.id) as HTMLInputElement).value = defaultMessage[element.title];  
            });
          },
          ()=>{})
        }else{
          arraymessages.forEach(element => {
            (document.getElementById(element.id) as HTMLInputElement).value = defaultMessage[element.title];  
          });
        }


      
        

      
    }

  }
  

  
  updateMessagesSection(){
    let rowsection = this.allinfo.filter((x:any) => x.section == this.section)    
    let mess = JSON.parse(rowsection[0].messages)
    mess.forEach(element => {
      if(element.component == 'input'){
        element.content = (document.getElementById(element.id) as HTMLInputElement).value
      }else{
        element.content = (document.getElementById(element.id) as HTMLTextAreaElement).value
      }
    });

    this.templateMeSvc.updateMessagesSection({"section":this.section,"messages": JSON.stringify(mess)})
    .toPromise()
    .then((result) => {
      this.alertify.success(result.section+ ' was updated');
      this.allinfo.forEach(element => {
        if(element.section == result.section){
          element.messages = result.messages
        }
      });
    }).catch((err) => {
      // console.log(err);      
    });    
  }


savePDF(event: any) {
  const file:File = event.target.files[0];
  if (file) {
    const isPdf = file.type === 'application/pdf';  
    if (isPdf) {
      const name = file.name.replace(/[^\w\s.]/gi, '');
      const exitsNameDoc:any = this.pdfs.filter((x:any)=>x.pdfName == name);
      if(exitsNameDoc.length > 0){
        this.alertify.error("You have a PDF with the same name");
        this.inputFilPDF.nativeElement.value = "";
      }else{
        const size = file.size;
        if(((size/1024)/1024) <= 3){
          const formData = new FormData();
          formData.append("file", file);
          this.http.post(environment.apiUrl+'savePdfInvoice',formData)
          .toPromise()
          .then((result) => {
            this.pdfs = result;
            this.alertify.success("file saved")
            this.inputFilPDF.nativeElement.value = "";
          }).catch((err) => {
            // console.log(err);
            this.inputFilPDF.nativeElement.value = "";
          });  
        }else{
          this.alertify.error("the maximum is 3 mb");
          this.inputFilPDF.nativeElement.value = "";
        }
      }
    } else {
      this.alertify.error("only PDF files");
      this.inputFilPDF.nativeElement.value = "";
    }
  }
}

  updateActivePDF(e:any,uuid:any){
    this.templateMeSvc.updateActivePDF({"status":e.target.checked,"uuid":uuid})
    .toPromise()
    .then((result:any) => {
      this.alertify.success(result);
    }).catch((err) => {
      // console.log(err);  
    });
  }

  previewPDF(pdf:any){
    const initialState = {
      pdfUrl: environment.urlLaravel+'storage/pdfs/'+this.authService.decodeToken('DID').DID + '_'+pdf.pdfName,
      pdfName:pdf.pdfName
    };
    this.bsModalRef = this.modalService.show(PdfViewerComponent,{ initialState, class: '', backdrop: true, ignoreBackdropClick: true });
  }

  deletePDF(pdf:any){
   
    this.alertify.confirm("Are you sure to delete this PDF?",
    ()=>{
      this.templateMeSvc.deleteInvoicePDF({"name":pdf['pdfName'],"uuid":pdf['uuid']})
      .toPromise()
      .then((result:any) => {
        let nodel = this.pdfs.filter((x:any)=> x.uuid != pdf.uuid)
        this.pdfs = nodel;
        this.alertify.success(result);
      }).catch((err) => {
        // console.log(err);  
      });
    },
    ()=>{}
    )

  }

}