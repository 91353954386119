import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { PurchaseOrderService } from 'src/app/_services/purchaseorder.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { EventsSMService } from 'src/app/_services/eventssm.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-purchasedetails',
  templateUrl: './purchasedetails.component.html',
  styleUrls: ['./purchasedetails.component.scss']
})
export class PurchasedetailsComponent implements OnInit, OnDestroy {
  destroy$: Subject<boolean> = new Subject<boolean>();
  @Input() eventid = 0;
  included: any[];
  excluded: any[];
  SOsummary: any;
  POsumItems: any;
  POsumTotal: any;
  POnum: any;
  show = 'Included';
  
  totalQtyExclude:any = 0
  totalWSPExclude:any = 0
  totalQtyInclude:any = 0
  totalWSPInclude:any = 0
  constructor(
    private purchaseOrderService: PurchaseOrderService,
    private alertify: AlertifyService,
    private eventSMService: EventsSMService
    ) { }

  ngOnInit() {
    this.loadDetails();
    this.summaryPO();
  }


  loadDetails() {
    //console.log(this.eventid)
    this.purchaseOrderService.getPurchaseOrdersIncluded(this.eventid)
    .pipe(takeUntil(this.destroy$))   
   
      .subscribe(
      (res: any) => {
        
        this.included = res.Included;
        this.excluded = res.Excluded;

        //sum totals details
        res.Included.forEach(element => {
          this.totalQtyInclude += parseInt(element.Qty)
          this.totalWSPInclude += ( parseInt(element.Qty) * parseFloat(element.WSP))
        });
        
        res.Excluded.forEach(element => {
          this.totalQtyExclude += parseInt(element.Qty)
          this.totalWSPExclude += (parseInt(element.Qty) * parseFloat(element.WSP))
        });

      }, error => {
        this.alertify.error(error);
      });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }


  summaryPO(){

    // Get all sales orders details
    this.eventSMService.getEventsReport(this.eventid)
    .pipe(takeUntil(this.destroy$))   
   
    .subscribe(
      (result) => {
        // console.log(result[0])
        this.SOsummary = result[0];
        ///console.log(this.SOsummary)
      });


      // get all po details
      this.purchaseOrderService.getPOSummary(this.eventid)
      .pipe(takeUntil(this.destroy$))   
     
        .subscribe(
        (res: any) => {
          // console.log(res)
          let sumTotal = 0;
          let numItems = 0;
          let numPo = res.length;
          res.forEach((element:any) => {
            if(element.TotalAmount != null){              
              sumTotal += parseFloat(element.TotalAmount);
            }
            if(element.TotalItems != null){ 
              numItems += parseInt(element.TotalItems);
            }
          });
         // console.log(sumTotal, numItems, numPo)
          this.POsumItems = numItems
          this.POnum = numPo
          this.POsumTotal = sumTotal
      }, error => {
        this.alertify.error(error);
      }); 
  }

}
