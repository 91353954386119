import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { FormGroup } from '@angular/forms';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ReportsService } from 'src/app/_services/reports.service';
import 'rxjs/add/operator/takeUntil';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { NrnValidators } from 'src/app/nrn.validators';
import { SettingsService } from 'src/app/_services/settings.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-sendemail-local',
  templateUrl: './sendemail-local.component.html',
  styleUrls: ['./sendemail-local.component.scss']
})
export class SendemailLocalComponent implements OnInit,OnDestroy {
  
  destroy$: Subject<boolean> = new Subject<boolean>();


  
  form = new FormGroup({});
  model:any
  fields: FormlyFieldConfig[] = [
    {
      wrappers: ['formwrap'],
      templateOptions: { label: '' },
      fieldGroupClassName: 'row',
      fieldGroup: [

        {
          key: 'email',
            type: 'input',
            className: 'col-md-12',
            templateOptions: {
              type: 'text',
              placeholder: 'Email',
              label: 'Email',
              required: true,
              pattern: NrnValidators.FORMATS.email
            },
            validation: {
              messages: {
                pattern: (error, field: FormlyFieldConfig) => `"${field.formControl.value}" is not a valid Email`,
              },
            },
        },
{
  key: 'subject',
    type: 'input',
    className: 'col-md-12',
    templateOptions: {
      type: 'email',
      label: 'Subject',
      placeholder: '',
      require: true
    }

},
{
  key: 'message',
  type: 'textarea',
  className: 'col-md-12',
  templateOptions: {
    label: 'Message',
    placeholder: '',
    rows: 4,
  },
}
  ]},
 ];





  constructor(
    public bsModalRef: BsModalRef,
    private reportService:ReportsService,
    private alertify: AlertifyService,
    private settingsService:SettingsService
     ) { }

  ngOnInit(): void {

    this.settingsService.getSettings(0)
    .pipe(takeUntil(this.destroy$),debounceTime(500))   
    .subscribe( data => {
      const settings = data[0];
      if (settings) {
        this.model['from'] = (settings['username']);
        this.model['store'] = (settings['companyName']);
        this.model['name'] = (settings['companyName']);
        // this.loadAttach();
      }
    }, error => {
      this.alertify.error(error);
    });
  }

  submit(model:any) {    
    this.reportService.emailReportSave(this.model)
    .pipe(takeUntil(this.destroy$))   
    .subscribe(res => {
      this.alertify.success('The Email was saved');
      this.reportService.dataStream.next('');
      this.bsModalRef.hide();
    }, error => {
      this.alertify.error('The email could not be saved. Please review that the email is valid and try again later.');
    });
    //
  }

  cancelForm(){
    this.bsModalRef.hide();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}
